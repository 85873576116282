import type { SafeHtml } from '@angular/platform-browser';
import { Entity, EntityBuilder } from '@decahedron/entity';

import type { IToolLink } from './tool-link';
import { ToolLink } from './tool-link';

export interface ITool {
  activated: boolean;
  category: { pk: string };
  description: string;
  links: IToolLink[];
  logo: string;
  name: string;
  pk: string;
  rank: number;
}

export class Tool extends Entity {
  id: string; // slugified title
  logo: string; // logo's uuid stored in S3 bucket on a specific path
  name: string;
  description: string;
  links: ToolLink[];
  rank: number; // tool rank order within the same category
  activated: boolean;
  logoDownloadUrl: string = null;
  isLoading = true;
  logoSVG: SafeHtml = null;
  category: string; // tool category ID
  isMockedTool = false; // just a mocked tool (used as a placeholder in UI)

  constructor(
    id: string,
    logo: string,
    name: string,
    description: string,
    links: ToolLink[],
    rank: number,
    activated: boolean,
    category: string,
    isMockedTool = false,
  ) {
    super();
    this.id = id;
    this.logo = logo;
    this.name = name;
    this.description = description;
    this.links = links;
    this.rank = rank;
    this.activated = activated;
    this.category = category;
    this.isMockedTool = isMockedTool;
  }

  fromJson(jsonData: ITool): Tool {
    this.id = jsonData.pk;
    this.logo = jsonData.logo;
    this.name = jsonData.name;
    this.description = jsonData.description;
    this.links = EntityBuilder.buildMany(ToolLink, jsonData.links);
    this.rank = jsonData.rank;
    this.activated = jsonData.activated;
    this.category = jsonData.category.pk;

    return super.fromJson(jsonData);
  }

  toJson(): ITool {
    return {
      pk: this.id,
      logo: this.logo,
      name: this.name,
      description: this.description,
      links: this.links.map((link) => link.toJson()),
      rank: this.rank,
      activated: this.activated,
      category: { pk: this.category },
    };
  }
}
