import { Component, EventEmitter, Input, Output } from '@angular/core';
import { of } from 'rxjs';
import { EnvironmentService } from '@dataportal/front-environment';

import { navbarLogos } from './navbar-default-logos';
import { NavbarItem } from './navbar-item/navbar-item.entity';

@Component({
  selector: 'dpg-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent {
  @Input() external = false;
  @Input() items: NavbarItem[];
  @Input() favorites: NavbarItem[];
  @Input() canSeeAdmin: boolean;
  @Input() canSeeLogout = false;
  @Output() messageEvent = new EventEmitter<boolean>();
  logoutItem = new NavbarItem('Sign Out', 'sign-out', '/logout', of(true), navbarLogos.get('sign-out'), false);

  constructor(public environmentService: EnvironmentService) {}
  open(): void {
    this.messageEvent.emit();
  }
}
