<div class="comment-item flex flex-row items-start">
  <div class="picture">
    <adl-loaded-item class="w-full" [loading]="isLoadingPicture" iconSize="160">
      <adl-image *ngIf="pictureSrc" [src]="pictureSrc" size="35px"></adl-image>
    </adl-loaded-item>
  </div>
  <div class="comment-content flex flex-col">
    <div class="comment-header flex flex-row items-start w-full">
      <div
        class="comment-header-left flex flex-row justify-start items-start"
        [ngClass]="{ 'smaller-item': isSmallerItem }"
      >
        <adl-text
          class="comment-header-title-infos name"
          [ngClass]="{ 'smaller-item': isSmallerItem }"
          color="secondary-500"
          size="12"
          [isLong]="true"
          [bold]="true"
          >{{ name }}</adl-text
        >
        <adl-text class="comment-header-title-infos date" color="primary-500" size="10" [isLong]="true">{{
          date | date: 'd/M/yy, h:mm a'
        }}</adl-text>
        <adl-text *ngIf="updatedDate" class="comment-header-title-infos date" color="primary-500" size="10" [italic]="true">
          Modified
        </adl-text>
        <ng-container>
          <ng-content select="[more-infos-part]"></ng-content>
        </ng-container>
      </div>
      <div class="comment-header-right flex flex-row justify-start items-center">
        <adl-loaded-item *ngIf="hasLikeBtn" class="like-container" [loading]="isLoadingLikeBtn" [iconSize]="20">
          <div class="flex flex-row justify-start items-center">
            <adl-text class="like-count" color="star" size="10">{{ nbLikes }}</adl-text>
            <adl-icon-button
              *ngIf="isLiked"
              class="like c-pointer"
              icon="like"
              (clicked)="toggleLike()"
            ></adl-icon-button>
            <adl-icon-button
              *ngIf="!isLiked"
              class="no-like c-pointer"
              icon="no-like"
              (clicked)="toggleLike()"
            ></adl-icon-button>
          </div>
        </adl-loaded-item>
        <adl-loaded-item class="options-container" [loading]="isLoadingOptionsBtn" iconSize="32">
          <adl-options-selector-button
            *ngIf="hasOptionsBtn && !hasToDisable"
            [choices]="optionsBtnOptions"
            triggerButtonType="etc"
            minWidth="50px"
            (onChoiceSelection)="selectOption($event)"
            (onToggleTooltip)="informOpenedOptionsSelector($event)"
          >
            <adl-text
              class="etc-icon-btn tac br5 c-pointer"
              [ngClass]="{ selected: isOptionsDisplayed }"
              type="accent"
              size="16"
              [bold]="true"
              >. . .</adl-text
            >
          </adl-options-selector-button>
        </adl-loaded-item>
      </div>
    </div>
    <div class="comment-message w-full">
      <div #messageElement>
        <span *ngIf="!isEditing" class="whitespace-normal break-word w-full">
          {{ message }}
        </span>
      </div>
      <adl-input-text-area
        *ngIf="isEditing"
        [control]="editMessageInput"
        [setCursorAtTheEndOfEditZone$]="setCursorAtTheEndOfEditZone$"
        fixedWidth="100%"
        [fixedHeight]="editMessageZoneHeightInPx"
        maxHeight="200px"
        placeholder="Type the new comment..."
        class="w-full"
      ></adl-input-text-area>
    </div>
    <div class="comment-footer flex flex-row" [ngClass]="{ 'justify-start': !isEditing, 'justify-end': isEditing }">
      <adl-text *ngIf="hasToDisplayReplyBtn" class="cursor-pointer" color="grey-500" size="10" (click)="reply()"
        >Reply</adl-text
      >
      <div *ngIf="hasHideBtn">
        <adl-text
          *ngIf="isHideBtnToggled"
          class="cursor-pointer ml-4"
          color="grey-add-new"
          size="10"
          [bold]="true"
          (click)="toggleHide()"
          >Show replies ({{ this.nbReplies || 0 }})</adl-text
        >
        <adl-text
          *ngIf="!isHideBtnToggled"
          class="cursor-pointer ml-4"
          color="grey-add-new"
          size="10"
          [bold]="true"
          (click)="toggleHide()"
          >Hide replies ({{ this.nbReplies || 0 }})</adl-text
        >
      </div>
      <div *ngIf="isEditing" class="flex flex-row">
        <adl-loaded-item class="flex flex-row" [loading]="isLoadingEditing" iconSize="32">
          <adl-icon-button class="cross-btn" icon="cross-thin" size="12" (clicked)="cancelEdit()"></adl-icon-button>
          <adl-icon-button class="check-btn" icon="check-thin" size="12" (clicked)="validateEdit()"></adl-icon-button>
        </adl-loaded-item>
      </div>
    </div>
  </div>
</div>
