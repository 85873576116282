import type { OnChanges, OnDestroy, OnInit } from '@angular/core';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil, tap } from 'rxjs/operators';
import { Logger } from '@dataportal/front-shared';

import { GroupsService } from '../../services/groups.service';

import { Group } from '../../entities/group';

@Component({
  selector: 'dpg-select-group',
  templateUrl: './select-group.component.html',
})
export class SelectGroupComponent implements OnInit, OnChanges, OnDestroy {
  // Attributes
  @Output() add: EventEmitter<Group> = new EventEmitter();
  @Input() default: Group = null;
  @Input() type: 'native' | 'all' | 'ad' = 'all';
  @Input() blacklist: string[] = [];

  list: Group[] = [];
  loading: boolean;
  query = '';
  min_size = 99;
  limit = 2000;
  searchSubject = new BehaviorSubject<string>('');
  requestId: string;

  private readonly _unsubscribe$ = new Subject();

  // Constructor
  constructor(private readonly _groupsService: GroupsService, private readonly _logger: Logger) {}

  // Lifecycle
  ngOnInit(): void {
    this._logger.debug('[dpg-select-group]');

    this._groupsService.results$.pipe(takeUntil(this._unsubscribe$)).subscribe((results) => {
      if (this.requestId === results.requesterId) {
        const toAdd = results.data.filter((group: Group) => this.type === 'all' || this.type === group.type);
        // .filter((group) => !this.blacklist.includes(group.id));
        this.list = [...this.list, ...toAdd];
        this._logger.debug('[dpg-select-group] List updated', this.list.length);
      }
    });

    this._groupsService.loading$.pipe(takeUntil(this._unsubscribe$)).subscribe((loading) => {
      if (loading.requesterId === this.requestId) {
        this._logger.debug('[dpg-select-group] loaded component', this.loading);
        this.loading = loading.data;
      }
    });

    this.searchSubject
      .pipe(
        tap(() => {
          this.list = [];
        }),
        distinctUntilChanged(),
        debounceTime(500),
      )
      .subscribe((value: string) => {
        if (value && value.length > 0) {
          this.query = value;
          this.refreshList();
        } else {
          this.clear();
        }
      });
  }

  ngOnChanges(): void {
    this._logger.debug('[dpg-select-group] Input changed');

    this.list = this.list
      .filter((group: Group) => this.type === 'all' || this.type === group.type)
      .filter((group) => !this.blacklist.includes(group.id));
  }

  ngOnDestroy(): void {
    this._groupsService.unsubscribe(this.requestId);
    this._unsubscribe$.next();
  }

  // Methods
  initList(): void {
    this._logger.debug('[dpg-select-group] Init list');
    this.list = [];
    this.requestId = this._groupsService.startListing({
      min_size: this.min_size,
      limit: this.limit,
    });
  }

  refreshList(): void {
    this._logger.debug('[dpg-select-group] Refresh list');
    this.list = [];
    this.requestId = this._groupsService.startListing({
      min_size: this.min_size,
      limit: this.limit,
      filters: this.query ? { name: this.query.toLocaleLowerCase() } : {},
    });
  }

  fetchMore(): void {
    this._logger.debug('[dpg-select-group] Fetch more');
    this._groupsService.fetchNextPage(this.requestId);
  }

  search($event: any): void {
    this._logger.debug('[dpg-select-group] Search');
    this.query = $event;
    this.refreshList();
  }

  clear(): void {
    this._logger.debug('[dpg-select-group] Clear');
    this.query = '';
    this.initList();
  }

  setSelectedUser($event: Group): void {
    this._logger.debug('[dpg-select-group] Selected group');
    this.add.emit($event);
  }
}
