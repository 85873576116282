<div
  *ngIf="this.guardianStatus?.isChecked && this.guardianStatus?.checkInfos"
  class="flex flex-col justify-center items-center w-full"
>
  <adl-text class="flex flex-col w-full text-left" type="accent" [bold]="false" size="12"
    >Here is the list of your file and post-actions configuration.</adl-text
  >
  <div class="flex flex-col justify-center items-center w-full mt-8 mb-6">
    <adl-table
      class="table-columns"
      minBodyHeight="300"
      maxBodyHeight="400"
      bodyPaddingBottom="50"
      [hasHoveringAnimation]="false"
      data-cy="guardian-check-infos-table-global"
    >
      <ng-container table-header>
        <th class="column-name-column-width column-name-column-padding"></th>
        <th class="constraint-column"></th>
      </ng-container>
      <ng-container table-body>
        <tr *ngIf="guardianStatus?.checkInfos?.name?.length" class="flex flex-row justify-start items-start">
          <td class="column-name-column-width flex flex-col justify-start items-start pt-4 pb-4 pl-0 pr-0">
            <adl-text class="w-full text-left" type="accent">Check title:</adl-text>
          </td>
          <td class="constraint-column separator-left flex flex-col justify-start items-start pt-4 pb-4 pr-0">
            <adl-text class="w-full text-left" color="primary-800">{{ guardianStatus?.checkInfos?.name }}</adl-text>
          </td>
        </tr>
        <tr *ngIf="guardianStatus?.checkInfos?.file_format?.length" class="flex flex-row justify-start items-start">
          <td class="column-name-column-width flex flex-col justify-start items-start pt-4 pb-4 pl-0 pr-0">
            <adl-text class="w-full text-left" type="accent">File format:</adl-text>
          </td>
          <td class="constraint-column separator-left flex flex-col justify-start items-start pt-4 pb-4 pr-0">
            <adl-text class="w-full text-left" color="primary-800">{{
              guardianStatus?.checkInfos?.file_format
            }}</adl-text>
          </td>
        </tr>
        <tr *ngIf="guardianStatus?.checkInfos?.sheet_name?.length" class="flex flex-row justify-start items-start">
          <td class="column-name-column-width flex flex-col justify-start items-start pt-4 pb-4 pl-0 pr-0">
            <adl-text class="w-full text-left" type="accent">Sheet name:</adl-text>
          </td>
          <td class="constraint-column separator-left flex flex-col justify-start items-start pt-4 pb-4 pr-0">
            <adl-text class="w-full text-left" color="primary-800">{{
              guardianStatus?.checkInfos?.sheet_name
            }}</adl-text>
          </td>
        </tr>
        <tr *ngIf="guardianStatus?.checkInfos?.delimiter?.length" class="flex flex-row justify-start items-start">
          <td class="column-name-column-width flex flex-col justify-start items-start pt-4 pb-4 pl-0 pr-0">
            <adl-text class="w-full text-left" type="accent">File delimiter:</adl-text>
          </td>
          <td class="constraint-column separator-left flex flex-col justify-start items-start pt-4 pb-4 pr-0">
            <adl-text [bold]="true" class="w-full text-left" color="primary-800">{{
              guardianStatus?.checkInfos?.delimiter
            }}</adl-text>
          </td>
        </tr>
        <tr *ngIf="guardianStatus?.checkInfos?.decimal?.length" class="flex flex-row justify-start items-start">
          <td class="column-name-column-width flex flex-col justify-start items-start pt-4 pb-4 pl-0 pr-0">
            <adl-text class="w-full text-left" type="accent">Decimal separator:</adl-text>
          </td>
          <td class="constraint-column separator-left flex flex-col justify-start items-start pt-4 pb-4 pr-0">
            <adl-text [bold]="true" class="w-full text-left" color="primary-800">{{
              guardianStatus?.checkInfos?.decimal
            }}</adl-text>
          </td>
        </tr>
        <tr *ngIf="guardianStatus?.isFlatfileDeactivate" class="flex flex-row justify-start items-start">
          <td class="column-name-column-width flex flex-col justify-start items-start pt-4 pb-4 pl-0 pr-0">
            <adl-text class="w-full text-left" type="accent">Uploaded file above 100 MB:</adl-text>
          </td>
          <td class="constraint-column separator-left flex flex-col justify-start items-start pt-4 pb-4 pr-0">
            <adl-text class="w-full text-left" color="primary-800">
              True
              </adl-text>
          </td>
        </tr>
        <tr *ngIf="hasToDisplayAlertRecipientsBox" class="separator-box">
          <td class="column-name-column-width p-0"></td>
          <td class="p-0"></td>
        </tr>
        <tr *ngIf="alertRecipientsOnSuccess?.length" class="flex flex-row justify-start items-start">
          <td class="column-name-column-width flex flex-col justify-start items-start pt-4 pb-4 pl-0 pr-0">
            <adl-text class="w-full text-left" type="accent">Alert recipients on success:</adl-text>
          </td>
          <td class="constraint-column separator-left flex flex-col justify-start items-start pt-4 pb-4 pr-0">
            <adl-text
              *ngFor="let alertRecipientOnSuccess of alertRecipientsOnSuccess"
              class="w-full text-left"
              color="primary-800"
              >{{ alertRecipientOnSuccess }}</adl-text
            >
          </td>
        </tr>
        <tr *ngIf="alertRecipientsOnFailure?.length" class="flex flex-row justify-start items-start">
          <td class="column-name-column-width flex flex-col justify-start items-start pt-4 pb-4 pl-0 pr-0">
            <adl-text class="w-full text-left" type="accent">Alert recipients on failure:</adl-text>
          </td>
          <td class="constraint-column separator-left flex flex-col justify-start items-start pt-4 pb-4 pr-0">
            <adl-text
              *ngFor="let alertRecipientOnFailure of this.alertRecipientsOnFailure"
              class="w-full text-left"
              color="primary-800"
              >{{ alertRecipientOnFailure }}</adl-text
            >
          </td>
        </tr>
        <tr *ngIf="hasToDisplayTargetFoldersBox" class="separator-box">
          <td class="column-name-column-width p-0"></td>
          <td class="p-0"></td>
        </tr>
        <tr *ngIf="targetFoldersIfOk?.length" class="flex flex-row justify-start items-start">
          <td class="column-name-column-width flex flex-col justify-start items-start pt-4 pb-4 pl-0 pr-0">
            <div class="flex flex-row justify-start items-start w-full">
              <adl-text class="text-left" type="accent">Target folder path if success:</adl-text>
              <adl-info-bubble class="ml-2">
                <adl-text type="tooltip" size="10" class="whitespace-normal break-all">
                  If the targeted file pass the set-up check, the file will be moved into the specified folder path in
                  the SAME bucket and data platform as set for the current path
                </adl-text>
              </adl-info-bubble>
            </div>
          </td>
          <td class="constraint-column separator-left flex flex-col justify-start items-start pt-4 pb-4 pr-0">
            <adl-text
              *ngFor="let targetFolderIfOk of this.targetFoldersIfOk"
              class="break-all w-full text-left"
              color="primary-800"
              >{{ this.getPathWithoutFormatName(targetFolderIfOk) }}</adl-text
            >
          </td>
        </tr>
        <tr *ngIf="targetFoldersIfError?.length" class="flex flex-row justify-start items-start">
          <td class="column-name-column-width flex flex-col justify-start items-start pt-4 pb-4 pl-0 pr-0">
            <div class="flex flex-row justify-start items-start w-full">
              <adl-text class="text-left" type="accent">Target folder path if error:</adl-text>
              <adl-info-bubble class="ml-2">
                <adl-text type="tooltip" size="10" class="whitespace-normal break-all">
                  If the targeted file does not pass the set-up check (at least one error), the file will be moved into
                  the specified folder path in the SAME bucket and data platform as set for the current path
                </adl-text>
              </adl-info-bubble>
            </div>
          </td>
          <td class="constraint-column separator-left flex flex-col justify-start items-start pt-4 pb-4 pr-0">
            <adl-text
              *ngFor="let targetFolderIfError of this.targetFoldersIfError"
              class="break-all w-full text-left"
              color="primary-800"
              >{{ this.getPathWithoutFormatName(targetFolderIfError) }}</adl-text
            >
          </td>
        </tr>
        <tr *ngIf="this.hasFileNameRenaming" class="flex flex-row justify-start items-start">
          <td class="column-name-column-width flex flex-col justify-start items-start pt-4 pb-4 pl-0 pr-0">
            <div class="flex flex-row justify-start items-start w-full">
              <adl-text class="w-full text-left" type="accent">Suffix filename with a timestamp:</adl-text>
            </div>
          </td>
          <td class="constraint-column separator-left flex flex-col justify-start items-start pt-4 pb-4 pr-0">
            <adl-text class="w-full text-left" color="primary-800">Yes</adl-text>
          </td>
        </tr>
        <tr
          *ngIf="this.guardianStatus?.checkInfos?.enforce_replacement"
          class="flex flex-row justify-start items-start"
        >
          <td class="column-name-column-width flex flex-col justify-start items-start pt-4 pb-4 pl-0 pr-0">
            <div class="flex flex-row justify-start items-start w-full">
              <adl-text class="w-full text-left" type="accent">Overwrite existing file:</adl-text>
            </div>
          </td>
          <td class="constraint-column separator-left flex flex-col justify-start items-start pt-4 pb-4 pr-0">
            <adl-text class="w-full text-left" color="primary-800">Yes</adl-text>
          </td>
        </tr>
        <tr *ngIf="hasToDisplayWriteResultBox" class="separator-box">
          <td class="column-name-column-width p-0"></td>
          <td class="p-0"></td>
        </tr>
        <tr *ngIf="guardianStatus?.checkInfos?.write_result" class="flex flex-row justify-start items-start">
          <td class="column-name-column-width flex flex-col justify-start items-start pt-4 pb-4 pl-0 pr-0">
            <div class="flex flex-row justify-start items-start w-full">
              <adl-text class="w-full text-left" type="accent">Generate result file:</adl-text>
            </div>
          </td>
          <td class="constraint-column separator-left flex flex-col justify-start items-start pt-4 pb-4 pr-0">
            <adl-text class="w-full text-left" color="primary-800">Yes</adl-text>
          </td>
        </tr>
        <tr *ngIf="hasToDisplaySchedulingBox" class="separator-box">
          <td class="column-name-column-width p-0"></td>
          <td class="p-0"></td>
        </tr>
        <tr
          *ngIf="guardianStatus?.checkInfos?.dag_schedule_interval?.length && schedulingInfos"
          class="flex flex-row justify-start items-start"
        >
          <td class="column-name-column-width flex flex-col justify-start items-start pt-4 pb-4 pl-0 pr-0">
            <div class="flex flex-row justify-start items-start w-full">
              <adl-text class="w-full text-left" type="accent">Scheduling option:</adl-text>
            </div>
          </td>
          <td class="constraint-column separator-left flex flex-col justify-start items-start pt-4 pb-4 pr-0">
            <adl-text class="break-all w-full text-left" color="primary-800"
              >{{ schedulingInfos?.schedulingPeriodicity }} at {{ schedulingStartingTime?.schedulingStartingHour }}:{{
                schedulingStartingTime?.schedulingStartingMinute
              }}
              {{ schedulingStartingTime?.schedulingStartingHourPeriod }}</adl-text
            >
            <ng-container [ngSwitch]="schedulingInfos.schedulingPeriodicity">
              <ng-container *ngSwitchCase="'daily'">
                <adl-text
                  *ngIf="schedulingInfos.schedulingDailyChoiceSelected === 'EVERY_N_DAYS'"
                  class="break-all w-full text-left"
                  color="primary-800"
                  >Every {{ schedulingInfos.schedulingDailyEveryNumberOfDays }} day(s)</adl-text
                >
                <adl-text
                  *ngIf="schedulingInfos.schedulingDailyChoiceSelected === 'EVERY_WORKING_DAY'"
                  class="break-all w-full text-left"
                  color="primary-800"
                  >Every work day</adl-text
                >
              </ng-container>
              <adl-text *ngSwitchCase="'weekly'" class="break-all w-full text-left" color="primary-800"
                >on {{ formattedSchedulingWeeklySelectedDays }}</adl-text
              >
              <adl-text *ngSwitchCase="'monthly'" class="break-all w-full text-left" color="primary-800"
                >on the {{ schedulingInfos.schedulingMonthlyDayNumber }} every
                {{ schedulingInfos.schedulingMonthlyEveryNumberOfMonths }} month(s)</adl-text
              >
              <adl-text *ngSwitchCase="'yearly'" class="break-all w-full text-left" color="primary-800"
                >on the {{ schedulingInfos.schedulingYearlyDayNumber }} {{ formattedSchedulingYearlyMonth }}</adl-text
              >
            </ng-container>
          </td>
        </tr>
        <tr *ngIf="this.guardianStatus?.checkInfos?.regex?.length" class="flex flex-row justify-start items-start">
          <td class="column-name-column-width flex flex-col justify-start items-start pt-4 pb-4 pl-0 pr-0">
            <div class="flex flex-row justify-start items-start w-full">
              <adl-text class="text-left" type="accent">File mask:</adl-text>
            </div>
          </td>
          <td class="constraint-column separator-left flex flex-col justify-start items-start pt-4 pb-4 pr-0">
            <adl-text class="regex-constraint whitespace-normal break-all text-left" color="primary-800">{{
              this.guardianStatus?.checkInfos?.regex
            }}</adl-text>
            <adl-text
              *ngIf="this.getLabelForNameFilterCheckRegex(this.guardianStatus?.checkInfos?.regex)?.length"
              class="regex-constraint break-all w-10/12 text-left"
              color="primary-800"
              >{{ this.getLabelForNameFilterCheckRegex(this.guardianStatus?.checkInfos?.regex) }}</adl-text
            >
          </td>
        </tr>
      </ng-container>
    </adl-table>
  </div>
</div>
