import type { AfterContentInit, ElementRef } from '@angular/core';
import { Component, ContentChildren, EventEmitter, Input, Output, QueryList } from '@angular/core';

@Component({
  selector: 'adl-sliding-tabs',
  templateUrl: './sliding-tabs.component.html',
  styleUrls: ['./sliding-tabs.component.scss'],
})
export class SlidingTabsComponent implements AfterContentInit {
  @ContentChildren('slidingTab', { descendants: true }) tabs: QueryList<ElementRef<HTMLElement>>;

  @Input() tabsTilteList: string[] = ['placeholder'];
  @Input() tabsPastilleList: number[];
  @Input() activeTab: string = this.tabsTilteList[0];
  @Input() tabSwitchingAnimation = 'fadein-tab';
  @Output() activeTabChanged = new EventEmitter();

  tabClicked(tabIndex: number) {
    this._moveSilder(this.tabsTilteList[tabIndex]);
    this._activateTab(tabIndex);
  }

  ngAfterContentInit(): void {
    this.tabs.forEach((tab) => tab.nativeElement.classList.add(this.tabSwitchingAnimation));
    const indexinitialActiveTab = this.tabsTilteList.findIndex((tabName) => tabName === this.activeTab);
    this.tabs.forEach((htmlTab) => htmlTab.nativeElement.classList.remove('active'));
    this.tabs.toArray()[indexinitialActiveTab].nativeElement.classList.add('active');
  }

  private _moveSilder(tab: string) {
    let index: number;
    let translateValue = 0;

    for (index = 0; tab != this.tabsTilteList[index]; index += 1) {
      translateValue += document.getElementById('sliding-tab-' + this.tabsTilteList[index] + index).offsetWidth;
    }

    const slider = document.getElementById('tabSlider');
    const tabElement = document.getElementById('sliding-tab-' + tab + index);
    slider.style.width = tabElement.offsetWidth.toString() + 'px';
    slider.style.transform = `translate(${translateValue}px, 0)`;
  }

  private _activateTab(tabIndexToActivate: number) {
    this.tabs.forEach((htmlTab) => htmlTab.nativeElement.classList.remove('active'));
    this.tabs.toArray()[tabIndexToActivate].nativeElement.classList.add('active');
    this.activeTab = this.tabsTilteList[tabIndexToActivate];
    this.activeTabChanged.emit(this.activeTab);
  }
}
