import { Component, Input } from '@angular/core';
import { otherNavbarLogos } from '@dataportal/layout';

@Component({
  selector: 'dpg-admin-button-v2',
  templateUrl: './admin-button-v2.component.html',
  styleUrls: ['./admin-button-v2.component.scss'],
})
export class AdminButtonV2Component {
  notificationsNamesToWatchForAdmin = ['accessRequestsNotification', 'publicationsNotification'];
  @Input() external: boolean;

  adminNavbarItem = otherNavbarLogos.get('admin');
}
