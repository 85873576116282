import { Injectable } from '@angular/core';
import type {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  RouterStateSnapshot,
  UrlSegment,
} from '@angular/router';
import { Router } from '@angular/router';
import type { Observable } from 'rxjs';
import { forkJoin, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Logger } from '@dataportal/front-shared';

import { PermissionsModule } from '../permissions.module';

import { PermissionsService } from '../services/permissions.service';

@Injectable({
  providedIn: PermissionsModule,
})
export class UserBackOfficeGuard implements CanActivate, CanLoad {
  constructor(
    private readonly _userService: PermissionsService,
    private readonly _router: Router,
    private readonly _logger: Logger,
  ) {}

  private _canAccess(): Observable<boolean> {
    return forkJoin([
      this._userService.isAuthorized('update', 'users'),
      this._userService.isAuthorized('impersonate', 'users'),
    ]).pipe(
      catchError((e: unknown) => {
        this._logger.error(e);

        return of([false, false]);
      }),
      map((result) => result[0] || result[1]),
      map((hasPermission) => {
        if (!hasPermission) {
          this._router.navigate(['error']);

          return false;
        }

        return true;
      }),
    );
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    this._logger.debug(route, state);

    return this._canAccess();
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> {
    this._logger.debug(route, segments);

    return this._canAccess();
  }
}
