import type { OnDestroy } from '@angular/core';
import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';

import { SwitchType } from '../../atoms/switch/switch.component';

@Component({
  selector: 'adl-mode-switch',
  templateUrl: './mode-switch.component.html',
  styleUrls: ['./mode-switch.component.scss'],
})
export class ModeSwitchComponent implements OnDestroy {
  @Input() adaptiveToggleTextColor = false;

  @Input() switchType: SwitchType = 'catalog';

  @Input() switchControl = new FormControl();

  private readonly _destroyed$ = new Subject<void>();

  ngOnDestroy() {
    this._destroyed$.next();
  }
}
