/* eslint-disable @typescript-eslint/explicit-function-return-type,@typescript-eslint/no-explicit-any */
import { Inject, Injectable } from '@angular/core';
import type { NGXLogger } from 'ngx-logger';
import { CustomNGXLoggerService, NgxLoggerLevel } from 'ngx-logger';

import { SharedModule } from '../shared.module';

import { ISharedOptions, SHARED_OPTIONS } from '../shared-options';

// Service
@Injectable({
  providedIn: SharedModule,
})
export class Logger {
  // Attributes
  private readonly _logger: NGXLogger;

  // Constructor
  constructor(customLogger: CustomNGXLoggerService, @Inject(SHARED_OPTIONS) options: ISharedOptions) {
    switch (options.environment) {
      case 'local':
      case 'test':
      case 'devlocal':
      case 'dev':
        this._logger = customLogger.create({ level: NgxLoggerLevel.TRACE });
        break;
      case 'prod':
        this._logger = customLogger.create({ level: NgxLoggerLevel.TRACE });
        break;
      default:
        this._logger = customLogger.create({ level: NgxLoggerLevel.TRACE });
        break;
    }
  }

  // Methods
  trace(message: any, ...additional: any[]): void {
    this._logger.trace(message, ...additional);
  }

  debug(message: any, ...additional: any[]): void {
    this._logger.debug(message, ...additional);
  }

  info(message: any, ...additional: any[]): void {
    this._logger.info(message, ...additional);
  }

  warn(message: any, ...additional: any[]): void {
    this._logger.warn(message, ...additional);
  }

  error(message: any, ...additional: any[]): void {
    this._logger.error(message, ...additional);
  }

  fatal(message: any, ...additional: any[]): void {
    this._logger.fatal(message, ...additional);
  }
}
