<div *ngIf="canDownload && (hasInternalDatalake || hasExternalDatalake)">
  <div class="pb20">
    <adl-button
      (clicked)="accessData()"
      class="row-flex j-center a-center"
      [fullWidth]="true"
      [disabled]="!isDatalakePathButtonReady"
    >
      <adl-loaded-item class="row-flex j-center a-center mr5" [loading]="isLoadingDatalakePath" iconSize="45">
        <adl-tooltip class="row-flex j-center a-center">
          <adl-icon-button
            tooltip-trigger
            *ngIf="!isSingleDatalakePathExistingStatus"
            class="row-flex j-center a-center"
            icon="warning"
            iconType="primary"
            [size]="20"
          ></adl-icon-button>
          <div tooltip-content>
            <adl-text class="w90 wb-all" type="tooltip" color="white"
            >The folder "{{ this.source?.datalakePath[0]?.path }}" does not exist anymore.
            </adl-text>
          </div>
        </adl-tooltip>
      </adl-loaded-item>
      <adl-text [bold]="true" color="white">{{ buttonLabel }}</adl-text>
    </adl-button>
  </div>
</div>
