<div *ngIf="title" class="flex text-left mb-4">
  <adl-text bold="700" color="blue" size="16">{{ title }}</adl-text>
</div>
<table
  [ngClass]="{ 'bordered': hasBorder, 'box-shadow': hasBoxShadow, 'table-fixed': layoutFixed, 'rounded-lg border-collapse': hasRoundedBorder }"
  class="adl-table">
  <thead
    *ngIf="hasTableHeaders"
    [ngClass]="{ 'bg-color-white': headColor === 'white', 'separated': hasHeadBodySeparator }"
    class="{{ headBodySeparatorColor }}"
  >
  <tr>
    <ng-content select="[table-header]"></ng-content>
  </tr>
  </thead>
  <tbody
    [ngClass]="{
      'bg-color-white': bodyColor === 'white',
      'shadow-hover': hasHoveringAnimation,
      'separated-full': hasBodyFullSeparators,
      'separated-half': hasBodyNotFullSeparators
    }"
    [style]="
        (minBodyHeight ? 'min-height: ' + minBodyHeight + 'px;' : '') +
        (maxBodyHeight ? 'max-height: ' + maxBodyHeight + 'px;' : '') +
        (bodyHorizontalPadding ? 'padding-left: ' + bodyHorizontalPadding + 'px;' : '') +
        (bodyHorizontalPadding ? 'padding-right: ' + bodyHorizontalPadding + 'px;' : '') +
        (bodyPaddingBottom ? 'padding-bottom: ' + bodyPaddingBottom + 'px;' : '')
      "
    class="w-full overflow-y-scroll"
  >
  <ng-content select="[table-body]"></ng-content>
  </tbody>
</table>

