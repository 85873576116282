<adl-modal>
  <div modal-header class="catalog-modal-header w-full p-12">
    <div class="table-name uppercase">{{ title }}</div>
  </div>

  <div modal-body class="catalog-modal-body w-full">
    <dpg-catalog-amundsen-data-table
      class="w-full"
      [tableData]="tablePreview"
      viewMode="data"
    ></dpg-catalog-amundsen-data-table>
  </div>
</adl-modal>
