import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from '@dataportal/front-api';
import { AlertService, DatalakeService, Logger, SlugifyService } from '@dataportal/front-shared';
import { Navigator } from '@dataportal/navigator';
import type { IDatalakePath } from '@dataportal/types';
import { EntityBuilder } from '@decahedron/entity';

import type { IPortalDatalakePaths } from '../entities/portal-datalake.entity';
import { PortalDatalake } from '../entities/portal-datalake.entity';

@Injectable()
export class PortalsDatalakeService {
  constructor(
    private readonly _api: ApiService,
    private readonly _navigator: Navigator,
    private readonly _slugify: SlugifyService,
    private readonly _alertService: AlertService,
    private readonly _loggerService: Logger,
  ) {}

  list(portalId: string): Observable<PortalDatalake[]> {
    return this._api
      .get<IPortalDatalakePaths[]>(`/v4/portals/${portalId}/datalake`)
      .pipe(map((result) => EntityBuilder.buildMany(PortalDatalake, result)));
  }

  addDatalakePath(portalId: string, datalakePath: IDatalakePath): Observable<void> {
    return this._api.post<void>(`/v4/portals/${portalId}/datalake`, datalakePath);
  }

  removeDatalakePath(portalId: string, datalakePath: IDatalakePath): Observable<void> {
    const datalakePathKey = DatalakeService.datalakePathToKey(datalakePath);

    if (!datalakePathKey?.length) {
      const errorMessage = 'Cannot convert datalake path to key';
      this._loggerService.debug(`[PORTALS_DATALAKE] ${errorMessage} - Invalid datalake path object : `, datalakePath);
      throw new Error(errorMessage);
    }

    return this._api.delete<void>(`/v4/portals/${portalId}/datalake`, {
      queryStringParameters: { datalakePathKey },
    });
  }
}
