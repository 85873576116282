import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { BehaviorSubject, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { ApiService } from '@dataportal/front-api';
import { Logger } from '@dataportal/front-shared';
import type { IGlossaryComponent } from '@dataportal/accor-glossary-types';
import { EntityBuilder } from '@decahedron/entity';

import { GlossaryComponentWithNotificationInfo } from '../entities/glossary-components';

@Injectable()
export class GlossaryComponentsService {
  private readonly _items: BehaviorSubject<IGlossaryComponent[]> = new BehaviorSubject([]);
  private readonly _domains: BehaviorSubject<IGlossaryComponent[]> = new BehaviorSubject([]);
  private readonly _subDomains: BehaviorSubject<IGlossaryComponent[]> = new BehaviorSubject([]);
  private readonly _glossaryAPIUrl = '/v4/glossary';

  items$: Observable<IGlossaryComponent[]> = this._items.asObservable();
  domains$: Observable<IGlossaryComponent[]> = this._domains.asObservable();
  subDomains$: Observable<IGlossaryComponent[]> = this._subDomains.asObservable();

  constructor(private readonly _apiService: ApiService, private readonly _logger: Logger) {}

  getComponents(): Observable<IGlossaryComponent[]> {
    const getAllEndpoint = this._glossaryAPIUrl + '/components';

    return this._apiService.get<IGlossaryComponent[]>(getAllEndpoint).pipe(
      catchError((err: unknown) => {
        this._logger.error(`[GLOSSARY API] Error while getting all glossary : ${err}`);

        return throwError(err);
      }),
      tap((components) => this._assignComponents(components)),
    );
  }

  getComponentsByAffiliateId(affiliateId: string): Observable<IGlossaryComponent[]> {
    const getByAffiliateEndpoint = `${this._glossaryAPIUrl}/affiliates/${affiliateId}/components`;

    return this._apiService.get<IGlossaryComponent[]>(getByAffiliateEndpoint).pipe(
      catchError((err: unknown) => {
        this._logger.error(`[GLOSSARY API] Error while getting glossary by affiliate (id: ${affiliateId}): ${err}`);

        return throwError(err);
      }),
      tap((components) => this._assignComponents(components)),
    );
  }

  getComponentById(glossaryComponentId: string): Observable<IGlossaryComponent> {
    const getByIdEndpoint = `${this._glossaryAPIUrl}/components/${glossaryComponentId}`;

    return this._apiService.get<IGlossaryComponent>(getByIdEndpoint).pipe(
      catchError((err: unknown) => {
        this._logger.error(`[GLOSSARY API] Error while getting glossary by id (${glossaryComponentId}): ${err}`);

        return throwError(err);
      }),
    );
  }

  deleteComponentById(glossaryComponentId: string, comment: string): Observable<IGlossaryComponent> {
    const deleteByIdEndpoint = `${this._glossaryAPIUrl}/components/${glossaryComponentId}`;
    const options = { body: { comment } };

    return this._apiService.delete<IGlossaryComponent>(deleteByIdEndpoint, options).pipe(
      catchError((err: unknown) => {
        this._logger.error(`[GLOSSARY API] Error while deleting glossary by id (${glossaryComponentId}): ${err}`);

        return throwError(err);
      }),
    );
  }

  createComponent(glossaryComponent: Partial<IGlossaryComponent>): Observable<IGlossaryComponent> {
    const createComponentEndpoint = this._glossaryAPIUrl + '/components';

    return this._apiService.post<IGlossaryComponent>(createComponentEndpoint, glossaryComponent).pipe(
      catchError((err: unknown) => {
        this._logger.error(`[GLOSSARY API] Error while creating glossary component (${glossaryComponent.pk}): ${err}`);

        return throwError(err);
      }),
    );
  }

  updateComponent(glossaryComponent: Partial<IGlossaryComponent>): Observable<IGlossaryComponent> {
    const updateComponentEndpoint = `${this._glossaryAPIUrl}/components/${glossaryComponent.pk}`;

    return this._apiService.put<IGlossaryComponent>(updateComponentEndpoint, glossaryComponent).pipe(
      catchError((err: unknown) => {
        this._logger.error(`[GLOSSARY API] Error while updating glossary component (${glossaryComponent.pk}): ${err}`);

        return throwError(err);
      }),
    );
  }

  getNotifications(): Observable<GlossaryComponentWithNotificationInfo[]> {
    return this._apiService.get(`${this._glossaryAPIUrl}/notifications`).pipe(
      map((response: any[]) =>
        EntityBuilder.buildMany<GlossaryComponentWithNotificationInfo>(GlossaryComponentWithNotificationInfo, response),
      ),
      catchError(() => of([])),
    );
  }

  updateNotification(componentId: string): Observable<void> {
    return this._apiService.put(`${this._glossaryAPIUrl}/components/${componentId}/notification/has-seen`, {});
  }

  private _assignComponents(components: IGlossaryComponent[]) {
    const sortedComponents = components.sort((a, b) => a.name.localeCompare(b.name));
    const sortedItems = sortedComponents.filter((component) => component.type === 'kpi' || component.type === 'term');
    const domains = sortedComponents.filter((component) => component.type === 'domain');
    const subDomains = sortedComponents.filter((component) => component.type === 'sub-domain');

    this._items.next(sortedItems);
    this._domains.next(domains);
    this._subDomains.next(subDomains);
  }
}
