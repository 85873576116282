import { Inject, Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { interval, Subject, timer } from 'rxjs';
import { map, mergeMap, shareReplay, skipWhile, switchMap, take, tap } from 'rxjs/operators';
import { ApiService } from '@dataportal/front-api';
import { IS_ACCOR } from '@dataportal/front-environment';
import { Logger } from '@dataportal/front-shared';
import type { ISourceStatistics } from '@dataportal/types';
import { EntityBuilder } from '@decahedron/entity';

import { SourceStatisticsModule } from '../source-statistics.module';

import { SourceStatistics } from '../entities/source-statistics';

// Constants
const TEN_MINUTES = 10 * 60 * 1000;

// Service
@Injectable({
  providedIn: SourceStatisticsModule,
})
export class SourceStatisticsService {
  // Attributes
  private readonly _load = new Subject<void>();

  stats$ = this._load.asObservable().pipe(
    // ACCOR - NO STATISTICS
    skipWhile(() => this._isAccor),
    switchMap(() => this._refresh()),
    tap((stats) => this._logger.debug('[SourceStatisticsService] Sources statistics refreshed', stats)),
    shareReplay(1),
  );

  // Constructor
  constructor(
    private readonly _apiService: ApiService,
    private readonly _logger: Logger,
    @Inject(IS_ACCOR) private readonly _isAccor: boolean,
  ) {
    // Setup autoreload
    timer(0, TEN_MINUTES).subscribe(() => this._load.next());
  }

  // Methods
  private _refresh(): Observable<Map<string, SourceStatistics>> {
    return this._apiService.get<ISourceStatistics[]>('/v4/statistics-reading', null, []).pipe(
      map((stats) =>
        stats.map<[string, SourceStatistics]>((stat) => [
          stat.pk,
          EntityBuilder.buildOne<SourceStatistics>(SourceStatistics, stat),
        ]),
      ),
      map((stats) => new Map(stats)),
    );
  }

  refresh(delay = 0): Observable<Map<string, SourceStatistics>> {
    this._logger.debug('[SourceStatisticsService] Requested to refresh statistics with delay', delay);

    if (delay) {
      return interval(delay).pipe(
        take(1),
        tap(() => this._logger.debug('[SourceStatisticsService] Delay elapsed')),
        tap(() => this._load.next()),
        mergeMap(() => this.stats$),
        take(1),
      );
    }

    this._load.next();

    return this.stats$.pipe(take(1));
  }

  get(sourceId: string, refresh = false): Observable<SourceStatistics> {
    this._logger.debug('[SourceStatisticsService] Requested to get source statistics');
    this._logger.debug('[SourceStatisticsService] Force refresh ?', refresh);

    if (refresh) {
      return this.refresh(0).pipe(
        take(1),
        map((stats) => stats.get(sourceId)),
        tap((stat) => this._logger.debug('[SourceStatisticsService] Fetched from API', stat)),
      );
    }

    return this.stats$.pipe(
      take(1),
      map((stats) => stats.get(sourceId)),
    );
  }
}
