import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { FormControl } from '@ngneat/reactive-forms';

@Component({
  selector: 'da-rich-text',
  templateUrl: './rich-text.component.html',
  styleUrls: ['./rich-text.component.scss'],
})
export class RichTextComponent {
  @Input() text: string;
  @Input() textControl: FormControl<string>;
  @Input() isEditMode = false;
  @Output() update = new EventEmitter<string>();

  wysiwygControl: FormControl<string>;
  private readonly _destroy$ = new Subject<void>();
}
