import { Injectable } from '@angular/core';
import { ApiPaginatedService } from '@dataportal/front-api';
import type { ICompany } from '@dataportal/types';
import { EntityBuilder } from '@decahedron/entity';

import { Company } from '../entities/company';

@Injectable()
export class CompaniesService extends ApiPaginatedService<Company> {
  protected url = '/v4/companies';

  protected buildOne(json: ICompany): Company {
    return EntityBuilder.buildOne(Company, {
      companyId: json.pk,
      companyName: json.companyName,
      relatedPortal: json.relatedPortal,
    });
  }

  protected buildMany(json: ICompany[]): Company[] {
    return EntityBuilder.buildMany(
      Company,
      json.map((j) => ({ companyId: j.pk, companyName: j.companyName, relatedPortal: j.relatedPortal })),
    );
  }
}
