export enum CurrentDashboardErrorCode {
  SOURCE_NOT_FOUND,
  DASHBOARD_NOT_FOUND,
  UNAUTHORIZED,
  NOT_IN_ALLOWED_GROUPS,
}

export class CurrentDashboardError extends Error {
  private readonly _code: CurrentDashboardErrorCode;
  get code() {
    return this._code;
  }
  constructor(message: string, code: CurrentDashboardErrorCode) {
    super(message);
    this._code = code;
  }
}
