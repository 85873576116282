<adl-loaded-item
  class="w-full"
  iconSize="140"
  [class.justify-center]="loadingContent"
  [class.justify-around]="!loadingContent"
  [loading]="loadingContent"
>
  <div class="flex">
    <div class="flex flex-col w-[40%] mr-20">
      <adl-text color="blue" size="24" weight="medium">About</adl-text>
      <adl-text *ngIf="product?.description" color="grey-800" size="14">
        <div [innerHTML]="product?.description | listCss | safeHtml" class="rich-text"></div>
      </adl-text>
    </div>
    <div *ngIf="product?.url_demo" class="flex w-[55%]">
      <iframe
        [src]="product?.url_demo | safeUrl"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
        class="w-full aspect-video rounded-lg"
        frameborder="0"
      ></iframe>
    </div>
  </div>

  <div class="flex w-full justify-between mt-32">
    <div class="flex flex-col" [ngClass]="isAccor ? 'w-full' : 'w-7/12 mr-[45px]'">
      <div class="flex cursor-pointer self-end mb-8">
        <a class="underline underline-offset-8 see-all-link" routerLink="faq" routerLinkActive="active">
          <adl-text size="16" type="blue">See All</adl-text>
        </a>
        <img alt="see all faq" class="rotate-180 ml-4" width="14" src="assets/dpg/imgs/icons/arrow-left-black.svg" />
      </div>
      <div *ngFor="let faqEntry of faqEntriesList" class="faq-entry flex items-center mb-4">
        <img
          class="w-[24px] mr-10"
          src="{{ 'assets/modules/data-academy/svg/file/' + faqEntry?.nature + '.svg' }}"
          [alt]="faqEntry.title"
        />

        <div class="faq-title flex-1 cursor-pointer px-4 mr-4" (click)="faqAction(faqEntry)">
          {{ faqEntry.title }}
        </div>

        <adl-document-download-button
          [title]="faqEntry.title"
          [url]="faqEntry.document?.url"
        ></adl-document-download-button>

        <adl-document-copy-clipboard-button
          [url]="!faqEntry.isInternal ? faqEntry.url : (faqEntry.id | url$ : 'documentation' | async)"
        ></adl-document-copy-clipboard-button>
      </div>
    </div>

    <div *ngIf="!isAccor">
      <adl-text [size]="24" color="blue" weight="medium" class="block mb-4">FAQ</adl-text>
      <adl-text [size]="14" color="black" class="block mb-6">
        Find the answers to your questions about the Accor Data Portal through our FAQ or by browsing our guidelines.
        Can't find the answer you're looking for? Please contact our friendly team.
      </adl-text>

      <adl-button type="teams" *ngIf="product?.updatesChannel" (click)="openTeamsUpdatesChannel()">
        <img src="assets/img/v2/icon/i-teams.svg" alt="teams icon" class="mr-4" />
        <span>Accor Data Portal Community</span>
      </adl-button>
    </div>
  </div>
</adl-loaded-item>
