import type { OnInit } from '@angular/core';
import { Component } from '@angular/core';

import { MultiAuthService } from '../../services/multi-auth.service';

// Component
@Component({
  selector: 'dpg-auth-logout',
  templateUrl: './auth-logout.component.html',
})
export class AuthLogoutComponent implements OnInit {
  // Constructor
  constructor(private readonly _multiAuthService: MultiAuthService) {}

  // Lifecycle
  ngOnInit() {
    this._multiAuthService.logout();
  }
}
