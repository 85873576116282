import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { BehaviorSubject, of } from 'rxjs';
import { map, mergeMap, reduce, shareReplay, switchMap, tap } from 'rxjs/operators';
import { ApiService } from '@dataportal/front-api';
import { Logger } from '@dataportal/front-shared';
import type { ResourceWithPermissions } from '@dataportal/types';

import type { Action } from '../entities/action';

// Types
export type Ressource =
  | Exclude<ResourceWithPermissions, 'custom-categories' | 'sub-categories'>
  | 'configurations'
  | 'leons';
export type Permissions = Partial<Record<ResourceWithPermissions, boolean>>;

interface ICanResult {
  data: Action | 'none';
}

interface IToCheck {
  action: Action;
  category: Ressource;
}

interface ICachedCan {
  can: boolean;
  until: number;
}

// Constants
const CACHE_TTL = 300000; // 5min

const PERMISSIONS_CHECKS: IToCheck[] = [
  { action: 'get', category: 'users' },
  { action: 'get', category: 'groups' },
  { action: 'update', category: 'portals' },
  { action: 'create', category: 'sources' },
  { action: 'update', category: 'configurations' },
];

const PERMISSIONS_ADMIN_CHECKS: IToCheck[] = [
  { action: 'update', category: 'groups' },
  { action: 'update', category: 'portals' },
  { action: 'update', category: 'sources' },
  { action: 'update', category: 'leons' },
  { action: 'update', category: 'configurations' },
  { action: 'update', category: 'users' },
  { action: 'update', category: 'access-requests' },
];

// Service
@Injectable()
export class PermissionsService {
  // Attributes
  private readonly _fetch$ = new BehaviorSubject(true);

  readonly permissions$ = this._fetch$.asObservable().pipe(
    tap(() => this._logger.debug('[PermissionService] fetching PERMISSIONS')),
    switchMap(() => this._checkPermissions(PERMISSIONS_CHECKS)),
    shareReplay(1),
  );

  readonly permissionsAdmin$ = this._fetch$.asObservable().pipe(
    tap(() => this._logger.debug('[PermissionService] fetching PERMISSIONS_ADMIN')),
    switchMap(() => this._checkPermissions(PERMISSIONS_ADMIN_CHECKS)),
    shareReplay(1),
  );

  private readonly _cache = new Map<string, ICachedCan>();

  // Constructor
  constructor(private readonly _apiService: ApiService, private readonly _logger: Logger) {}

  // Methods
  private _checkPermissions(toCheck: IToCheck[]): Observable<Permissions> {
    return of(...toCheck).pipe(
      mergeMap(({ action, category }) =>
        this.isAuthorized(action, category).pipe(
          map<boolean, [Ressource, boolean]>((authorized) => [category, authorized]),
        ),
      ),
      reduce((permissions, [category, authorized]) => ({ ...permissions, [category]: authorized }), {} as Permissions),
      shareReplay(1),
    );
  }

  private _getFromCache(action: Action, category: Ressource, path?: string): [string, ICachedCan | undefined] {
    // Compute ids
    const cacheId = `[${action}]-[${category}]` + (path?.length ? `-[${path}]` : '');

    // Search in cache
    const res = this._cache.get(cacheId);

    return [cacheId, res];
  }

  isAuthorized(action: Action, category: Ressource, path?: string): Observable<boolean> {
    const [cacheId, cached] = this._getFromCache(action, category, path);

    if (cached?.until > new Date().getTime()) {
      return of(cached.can);
    }

    this._logger.debug(`[PermissionService] Fetching ${cacheId}`);

    return this.getPermission(action, category, path).pipe(
      map((res) => res.data !== 'none'),
      tap((res) => this._cache.set(cacheId, { can: res, until: new Date().getTime() + CACHE_TTL })),
      tap((res) => this._logger.debug(`[PermissionService] ${cacheId} => ${res}`)),
    );
  }

  getPermission(action: Action, category: Ressource, path?: string): Observable<ICanResult> {
    const params: Record<string, string> = {};

    if (path) {
      params.id = path;
    }

    return this._apiService.get(`/v4/permissions/can/${action}/${category}`, { params });
  }

  refetchPermissions() {
    this._cache.clear();
    this._fetch$.next(true);
  }
}
