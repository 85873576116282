import { Injectable } from '@angular/core';

import { SharedModule } from '../shared.module';

import { Logger } from './logger.service';

// Service
@Injectable({
  providedIn: SharedModule,
})
export class LocalStorageService implements Storage {
  // Attributes
  private _keys: string[] = [];
  private _items: Record<string, string | null> = {};

  // Constructor
  constructor(private readonly _logger: Logger) {}

  // Methods
  clear(): void {
    try {
      this._keys = [];
      this._items = {};
      localStorage.clear();
    } catch (error) {
      this._logger.error('Cannot access local storage:', error.toString());
    }
  }

  key(index: number): string | null {
    return this._keys[index] ?? localStorage.key(index);
  }

  getItem(key: string): string | null {
    try {
      return key in this._items ? this._items[key] : localStorage.getItem(key);
    } catch (error) {
      this._logger.error('Cannot access local storage:', error.toString());

      return null;
    }
  }

  setItem(key: string, value: string): void {
    try {
      localStorage.setItem(key, value);
    } catch (error) {
      this._logger.error('Cannot access local storage:', error.toString());

      this._items[key] = value;
      this._keys.push(key);
    }
  }

  removeItem(key: string): void {
    try {
      delete this._items[key];
      localStorage.removeItem(key);
    } catch (error) {
      this._logger.error('Cannot access local storage:', error.toString());
    }
  }

  // Properties
  get length(): number {
    return this._keys.length;
  }

  saveItemToRecentSearch(itemName: string, recentSearchKey: string) {
    const recentSearchString = localStorage.getItem(recentSearchKey);

    if (recentSearchString) {
      const recentSearches: string[] = JSON.parse(recentSearchString);

      if (!recentSearches.includes(itemName)) {
        if (recentSearches.length > 4) {
          recentSearches.unshift(itemName);
          recentSearches.pop();
        } else {
          recentSearches.unshift(itemName);
        }
      } else {
        // if the itemName is already in array, delete & move it to top
        recentSearches.splice(recentSearches.indexOf(itemName), 1);
        recentSearches.unshift(itemName);
      }

      localStorage.setItem(recentSearchKey, JSON.stringify(recentSearches));
    } else {
      localStorage.setItem(recentSearchKey, JSON.stringify([itemName]));
    }
  }

  getStorage(): Storage {
    return localStorage;
  }
}
