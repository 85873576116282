import { Component, Input } from '@angular/core';
import { CommonSelectComponent } from '@dataportal/adl';

import { LimitedUsersService } from '../../services/limited-users.service';

import type { LimitedUser } from '../../entities/limited-user';

@Component({
  selector: 'dpg-limited-user-select',
  templateUrl: '../../../../../../modules/adl/src/lib/organisms/common-select/common-select.component.html',
  styleUrls: ['./limited-user-select.component.scss'],
})
export class LimitedUserSelectComponent extends CommonSelectComponent<LimitedUser> {
  @Input() placeholder = 'Select a user';
  @Input() minSize = 1;
  @Input() limit = 20;

  constructor(readonly limitedUsersService: LimitedUsersService) {
    super(limitedUsersService);
  }
}
