<adl-modal
        headerBackgroundColor="primary-500"
        [roundCorner]="false"
        closeButtonColor="white"
        [isAlignedToCross]="true"
        backgroundColor="grey-100"
>
  <div class="dp-admin--add-people-modal--header my-8" modal-header>
    <adl-text type="normal" [size]="20" color="white">Add people and choose their access</adl-text>
  </div>

  <div modal-body class="dp-admin--add-people-modal--body mt-5">
    <adl-text type="normal" [size]="14" color="blue" weight="medium">Add people and choose their access</adl-text>
    <div class="dp-admin--add-people-modal--user-selection">
      <dpg-user-select
        class="dp-admin--add-people-modal--user-select"
        [predicates]="limitedUserPredicates"
        (selected)="selectedLimitedUser = $event"
        [clear]="clearSelectList"
      ></dpg-user-select>
      <adl-button (clicked)="addUserToList()" [disabled]="!selectedLimitedUser">
        {{ addedUsersRoles?.length ? 'Add someone' : 'Add' }}
      </adl-button>
    </div>
    <div class="dp-admin--add-people-modal--permissions-list scrollbar shadow-md">
      <adl-table-v2 [headers]="usersPermissionsTableHeaders" tableWidth="850">
          <tr table-tr *ngIf="addedUsersRoles.length === 0">
              <td colspan="2" class="h-[130px] text-center pointer-events-none">
                  <adl-text>
                      No user roles to add yet.
                  </adl-text>
              </td>
          </tr>
        <tr *ngIf="addedUsersRoles.length >= 2" table-tr>
            <td>
              <adl-text class="dp-admin--add-people-modal--user-row-name" [size]="14" [italic]="true">All</adl-text>
            </td>
            <td class="flex justify-between">
                <adl-radio-selector
                        radioStyle="new"
                        (changed)="updateEachUserRole($event)"
                        class="dp-admin--add-people-modal--user-row-radio-buttons"
                        selectorName="all-roles"
                        [activeChoice]="defaultRole"
                        [choices]="choices"
                        containerClassList="flex gap-3"
                        [radioSelectorLabelSize]="12"
                >
                </adl-radio-selector>
                <div class="separator"></div>
            </td>
        </tr>
        <tr *ngFor="let user of addedUsersRoles" table-tr>
            <td class="max-w-[200px] truncate">
              <adl-text class="dp-admin--add-people-modal--user-row-name" [size]="14" title="{{ user.email }}">
                {{ user.email }}
              </adl-text>
            </td>
            <td class="flex justify-between items-center">
                <adl-radio-selector
                        radioStyle="new"
                        (changed)="updateUserRole(user.email, $event)"
                        class="dp-admin--add-people-modal--user-row-radio-buttons"
                        [selectorName]="user.email + '-roles'"
                        [activeChoice]="user.role"
                        [choices]="choices"
                        containerClassList="flex gap-3"
                        [radioSelectorLabelSize]="12"
                >
                </adl-radio-selector>
                <adl-icon-button
                        class="dp-admin--add-people-modal--user-row--delete"
                        (clicked)="removeUserFromList(user.email)"
                        icon="trash"
                ></adl-icon-button>
            </td>
        </tr>
      </adl-table-v2>
    </div>

  </div>
  <div modal-footer>
    <div class="dp-admin--btn-wrapper row-flex j-center">
      <adl-button (clicked)="cancel()" type="cancel">Cancel</adl-button>
      <adl-button (clicked)="confirm()">Save</adl-button>
    </div>
  </div>
</adl-modal>
