import { animate, state, style, transition, trigger } from '@angular/animations';
import type { OnDestroy, OnInit } from '@angular/core';
import { ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { of, Subject } from 'rxjs';
import { filter, mergeMap, takeUntil } from 'rxjs/operators';
import { SidebarService } from '@dataportal/adl';
import { GlobalBannerService } from '@dataportal/alerts';
import { CognitoError } from '@dataportal/auth';
import { AlertService, DeviceService, Logger } from '@dataportal/front-shared';
import { Navigator, NavigatorToken } from '@dataportal/navigator';

import { FavoritesSourcesService } from '../../../sources/services/favorites-sources.service';
import { CurrentDashboardService } from '../../services/current-dashboard.service';
import { DashboardCommentsService } from '../../services/dashboard-comments.service';
import { DashboardCommentsCacheService } from '../../services/dashboard-comments-cache.service';
import { DashboardPageRouteParamsService } from '../../services/dashboard-page-route-params.service';
import { DashboardsService } from '../../services/dashboards.service';
import { PowerBiBookmarksService } from '../../services/power-bi-bookmarks.service';
import { PowerBiEmbedService } from '../../services/power-bi-embed.service';
import { TableauService } from '../../services/tableau.service';

import type { Source } from '../../../sources/entities/source';
import type { Dashboard } from '../../entities/dashboard.model';

const DEFAULT_TRANSITION_DURATION_IN_MS = 250;

// Component
@Component({
  selector: 'dpg-dashboard-page',
  templateUrl: './dashboard-page.component.html',
  styleUrls: ['./dashboard-page.component.scss'],
  animations: [
    trigger('openClose', [
      state(
        'closed',
        style({
          'width': '0',
          'min-width': '0',
          'max-width': '0',
        }),
      ),
      state(
        'open',
        style({
          'width': '360px',
          'min-width': '360px',
          'max-width': '360px',
        }),
      ),
      transition('closed <=> open', [animate(DEFAULT_TRANSITION_DURATION_IN_MS)]),
    ]),
  ],
})
@NavigatorToken('catalog-dashboard')
export class DashboardPageComponent implements OnInit, OnDestroy {
  hasError = false;
  isLoading = true;
  isMobile = false;
  hasAlert = false;
  shouldDisplayToolbar = false;
  isBookmarkActivationInProgress = false;
  isOnMaintenance = false;
  isNavbarDeactivated = false;
  hasToDisplayCommentsPart = false;
  hasToTriggerTransitionInCommentsPart = false;
  isLoadingDashboardComments = true;
  nbDashboardComments = 0;

  @ViewChild('dashboardContainer') dashboardContainer: ElementRef<HTMLDivElement>;

  private _firstEmbed: boolean;
  // To set when this component is destroyed
  // When this component is destroyed, will no longer subscribe (so no memory leak)
  private readonly _destroy$ = new Subject<void>();

  // Constructor
  constructor(
    private readonly _titleService: Title,
    private readonly _currentDashboardService: CurrentDashboardService,
    private readonly _alertService: AlertService,
    private readonly _configurationService: GlobalBannerService,
    private readonly _powerBiEmbedService: PowerBiEmbedService,
    private readonly _powerBiFiltersService: PowerBiBookmarksService,
    private readonly _deviceService: DeviceService,
    private readonly _logger: Logger,
    private readonly _navigator: Navigator,
    private readonly _favoritesSourcesService: FavoritesSourcesService,
    private readonly _dashboardsService: DashboardsService,
    private readonly _dashboardRouteParamsService: DashboardPageRouteParamsService,
    private readonly _route: ActivatedRoute,
    private readonly _changeDetector: ChangeDetectorRef,
    private readonly _sidebarService: SidebarService,
    private readonly _router: Router,
    private readonly _dashboardCommentsService: DashboardCommentsService,
    private readonly _dashboardCommentCacheService: DashboardCommentsCacheService,
    private readonly _tableauService: TableauService,
  ) {}

  // Properties
  get source(): Source {
    return this._currentDashboardService.source;
  }

  get dashboard(): Dashboard {
    return this._currentDashboardService.dashboard;
  }

  get isOwner(): boolean {
    return this._currentDashboardService.isOwner;
  }

  get isCubeStarting(): boolean {
    return this._powerBiEmbedService && this._powerBiEmbedService.isCubeStarting;
  }

  // TODO: Remove duplication
  get visibleRelatedDashboards(): Dashboard[] {
    let visibleRelatedDashboards: Dashboard[] = [];

    if (this.source && this.source.powerbi.length) {
      visibleRelatedDashboards = this.source.powerbi.filter((dashboard) => !dashboard.isHidden);

      return visibleRelatedDashboards;
    }
  }

  // Lifecycle
  ngOnInit() {
    this._logger.debug('[DashboardPage] Initializing');
    this._firstEmbed = true;

    this._sidebarService.isVisible$.pipe(takeUntil(this._destroy$)).subscribe((isVisible) => {
      this.isNavbarDeactivated = !isVisible;
    });

    // We watch route parameters, when they are updated that means dashboard have changed
    this._dashboardRouteParamsService.watchRouteParameters(this._route);

    // Route params service will update current dashboard held by current dashboard service
    this._currentDashboardService.loading$.pipe(takeUntil(this._destroy$)).subscribe((loading) => {
      // When current dashboard service inform that loading of dashboard, sources, related permissions and so on is done
      // we can start dashboard rendering
      this._logger.debug('[DashboardPage] Loading state changed', loading);
      this.isLoading = loading;

      if (!loading && this._currentDashboardService.dashboard) {
        this.isOnMaintenance =
          !!this._currentDashboardService.dashboard.maintenanceMessage && !this._currentDashboardService.isOwner;
        this._logger.debug('[DashboardPage] Should display toolbar', {
          isMobile: this.isMobile,
          isPowerBI: this._currentDashboardService.dashboard.type === 'powerbi',
        });
        this.shouldDisplayToolbar = !this.isMobile;
        this._embedCurrentDashboard();
      }
    });

    // If something wrong happen during loading current dashboard, the service will inform us through error$ observable
    this._currentDashboardService.error$
      .pipe(
        filter((err) => !!err),
        takeUntil(this._destroy$),
      )
      .subscribe((error) => {
        // Thus we can handle the different error pages and react accordingly
        this._logger.error('[DashboardPage] Error happened when checking current dashboard pre-requisites', error);
        this._router.navigate(
          [
            '/404',
            {
              source: JSON.stringify(this.source),
            },
          ],
          { skipLocationChange: true },
        );
      });

    // We check here if there are bookmark being applied (e.g. if URL had a linkId and bookmark is being restored, or if
    // user has set default bookmark). This way we can display an overlay informing the end user that a bookmark
    // is being restored and preventing him from clicking on the dashboard components in the meantime
    this._currentDashboardService.dashboard$.pipe(takeUntil(this._destroy$)).subscribe((dashboard) => {
      this._logger.info('[DashboardPage] Current dashboard changed', dashboard);
      this.isLoadingDashboardComments = true;
      this._dashboardCommentCacheService.nbCachedDashboardComments$
        .pipe(takeUntil(this._currentDashboardService.dashboardChanged$), takeUntil(this._destroy$))
        .subscribe((nbCachedDashboardComments) => {
          this.nbDashboardComments = nbCachedDashboardComments;
        });

      if (this.source?.id?.length && dashboard?.id?.length && dashboard.hasComments) {
        this._dashboardCommentsService
          .listDashboardComments(this.source.id, dashboard.id)
          .pipe(
            takeUntil(this._currentDashboardService.dashboardChanged$),
            mergeMap((dashboardCommentsList) => {
              this.isLoadingDashboardComments = false;
              this._dashboardCommentCacheService.callToRefresh(true);
              this._dashboardCommentCacheService.initCachedDashboardComments(dashboardCommentsList);

              return of(null);
            }),
            takeUntil(this._destroy$),
          )
          .subscribe(
            () => {
              this.isLoadingDashboardComments = false;
            },
            () => {
              this.isLoadingDashboardComments = false;
            },
          );
      }

      this._powerBiFiltersService.isBookmarkActivationInProgress$
        .pipe(takeUntil(this._currentDashboardService.dashboardChanged$), takeUntil(this._destroy$))
        .subscribe((value) => {
          this._logger.debug('[DashboardPage] Is bookmark being applied on current dashboard ?', value);
          this.isBookmarkActivationInProgress = value;

          if (this.isBookmarkActivationInProgress) {
            this._alertService.info('Applying bookmark in progress, please wait...');
          }
        });
    });

    // We check here is there is an alert banner so we can adapt the layout by dynamically adding a class
    this._configurationService.show$.subscribe((hasAlert) => (this.hasAlert = hasAlert));

    // When current dashboard changes (because user has directly switch with the toolbar) embed the newly
    // selected dashboard
    this._currentDashboardService.dashboardChanged$.pipe(takeUntil(this._destroy$)).subscribe(() => {
      this._logger.info('[DashboardPage] Current dashboard changed');

      if (!this._firstEmbed) {
        this._logger.debug('[DashboardPage] Removing all event listeners from previous embed');
        this._powerBiEmbedService.removeEventListeners();
      }

      this._embedCurrentDashboard();

      if (this._firstEmbed) {
        // We also manage here a flag to know if it is the first embed (we don't need to perform some common actions)
        this._firstEmbed = false;
      }
    });
  }

  ngOnDestroy(): void {
    this._logger.debug('[DashboardPage] Destroying');
    this._dashboardRouteParamsService.stopWatchingRouteParameters();
    this._destroy$.next();
    this._powerBiEmbedService.removeEventListeners();
  }

  toggleDisplayCommentsPart(): void {
    const hasToDisplayCommentsPartUpdated = !this.hasToDisplayCommentsPart;

    if (hasToDisplayCommentsPartUpdated) {
      this.hasToDisplayCommentsPart = hasToDisplayCommentsPartUpdated;
      setTimeout(() => {
        this.hasToTriggerTransitionInCommentsPart = hasToDisplayCommentsPartUpdated;
      }, 1);
    } else {
      this.hasToTriggerTransitionInCommentsPart = hasToDisplayCommentsPartUpdated;
      setTimeout(() => {
        this.hasToDisplayCommentsPart = hasToDisplayCommentsPartUpdated;
      }, DEFAULT_TRANSITION_DURATION_IN_MS);
    }
  }

  goBackToHomePage(): void {
    this._router.navigate(['/']);
  }

  // Methods
  private _goToErrorPage(): void {
    this._logger.info('[DashboardPage] Navigating to error page');
    this._navigator
      .navigate(
        'error',
        {},
        {
          queryParams: {
            source: this.source.id,
            dashboard: this._route.snapshot.params.dashboard,
          },
        },
      )
      .then(() => this._logger.info('Navigated to', ['/error']));
  }

  private _returnToSourcePage(): void {
    this._logger.info('[DashboardPage] Returning to source page');
    this._navigator
      .navigate('catalog-source', { fileName: this.source.id })
      .then(() => this._logger.info('Navigated to', ['/sources/', this.source.id]));
  }

  private _embedCurrentDashboard(): void {
    if (!this.dashboard) return;

    if (this._firstEmbed) {
      this._logger.debug('[DashboardPage] Refreshing favorites');
      this._favoritesSourcesService.refreshFavoritesSources();
      this.isMobile = this._deviceService.isMobile();
      this._logger.debug('[DashboardPage] Is mobile', this.isMobile);
    }

    if (this.isOnMaintenance) {
      this._logger.warn('[DashboardPage] Dashboard in maintenance');

      return;
    }

    this._titleService.setTitle(this.dashboard.name + ' - Accor Data Portal');

    // Wait for the dashboardContainer to be re-mounted on the DOM after it is been removed
    // by *ngIf when previous dashboard was on maintenance and newly selected dashboard in toolbar
    // switch is not in maintenance
    this._changeDetector.detectChanges();

    switch (this.dashboard.type) {
      case 'qlik':
        return this._qlik(this.dashboard);
      case 'data_studio':
        return this._dataStudio(this.dashboard);
      case 'datorama':
        return this._datorama(this.dashboard);
      case 'metabase':
        return this._metabase(this.dashboard);
      case 'reeport':
        return this._reeport(this.dashboard);
      case 'custom':
        return this._customDashboard(this.dashboard);
      case 'tableau':
        return this._tableauService.init(this.dashboardContainer.nativeElement, this.dashboard);
      case 'powerbi':
        this._powerBiEmbedService
          .init(
            this.dashboardContainer.nativeElement,
            this.source,
            this.dashboard,
            this._currentDashboardService.currentUser,
            this.isMobile,
          )
          .then(() => {
            this._logger.debug('[DashboardPage] PowerBI Dashboard initialized, ready to listen events');
            this._dashboardRouteParamsService.restoreStateFromQueryParametersWhenLoaded();
          })
          .catch((err) => {
            this._logger.error('[DashboardPage] Error initializing dashboard', err);

            if (err instanceof CognitoError) {
              this.hasError = true;
              this._logger.debug('[DashboardPage] Cognito Error, PowerBI Dashboards are not available with cognito');
            } else {
              this._goToErrorPage();
            }
          });
        this._powerBiFiltersService.listBookmarks(this.source.id, this.dashboard.name);
        this._dashboardsService.getLastUpdate(this.source.id, this.dashboard);
        break;
      default:
        this._logger.error('[DashboardPage] Invalid dashboard type', this.dashboard.type);
    }
  }

  private _qlik(dashboard: Dashboard): void {
    this.dashboardContainer.nativeElement.innerHTML = `<iframe src="${dashboard.url}" style="height: 100%;width: 100%;">`;
  }

  // currently has the same behavior as qlik
  private _dataStudio(dashboard: Dashboard): void {
    this._qlik(dashboard);
  }

  // currently has the same behavior as qlik (but can be extended in the future in the dashboards.service.ts:auth part)
  private _datorama(dashboard: Dashboard): void {
    this._qlik(dashboard);
  }

  // currently has the same behavior as qlik with specific url verification
  private _metabase(dashboard: Dashboard): void {
    this._qlik(dashboard);
  }

  // currently has the same behavior as qlik
  private _reeport(dashboard: Dashboard): void {
    this._qlik(dashboard);
  }

  private _customDashboard(dashboard: Dashboard): void {
    this._qlik(dashboard);
  }
}
