import { Inject, Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import type { IAthenaQueryParams, IAthenaResponse } from '@dataportal/api-types';
import { ApiService } from '@dataportal/front-api';
import { IS_ACCOR } from '@dataportal/front-environment';

type AccorResponse = Record<string, string>[];

@Injectable()
export class AthenaService {
  private readonly _baseUrl: string;

  constructor(private readonly _apiService: ApiService, @Inject(IS_ACCOR) private readonly _isAccor: boolean) {
    // ACCOR - NO ATHENA
    this._baseUrl = this._isAccor ? '/v4/snowflake-explore/preview' : '/v4/athena';
  }

  queryAthena(params: IAthenaQueryParams): Observable<IAthenaResponse> {
    const getOptions = {
      queryStringParameters: {
        account: params.account as string,
        schema: params.schema,
        database: params.database,
        table: params.table,
        sourceId: params.sourceId,
      },
    };

    return this._apiService.get<IAthenaResponse | AccorResponse>(this._baseUrl, getOptions).pipe(
      map((res) => this._toAthenaResponse(res)),
      catchError((err: unknown) => {
        return throwError(err);
      }),
    );
  }

  private _toAthenaResponse(response: IAthenaResponse | AccorResponse): IAthenaResponse {
    if (!this._isAccor) {
      return response as IAthenaResponse;
    }

    const colNames: string[] = Object.keys((response as AccorResponse)[0]);
    const rows: string[][] = (response as AccorResponse).map(Object.values);

    return {
      detailedPreview: [],
      simplePreview: [colNames, ...rows],
    };
  }
}
