import { CommonModule } from '@angular/common';
import type { ModuleWithProviders } from '@angular/core';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AdlModule } from '@dataportal/adl';
import { SharedModule } from '@dataportal/front-shared';
import { IconsModule } from '@dataportal/icons';
import { ToolsModule } from '@dataportal/tools';

import { ToolsPageComponent } from './pages/tools-page/tools-page.component';

import type { IDataToolsOptions } from './data-tools-options';
import { DATA_TOOLS_OPTIONS } from './data-tools-options';

// Module
// @dynamic
@NgModule({
  imports: [CommonModule, RouterModule, IconsModule, ToolsModule, SharedModule, AdlModule],
  declarations: [ToolsPageComponent],
  exports: [ToolsPageComponent],
})
export class DataToolsModule {
  // Statics
  static forRoot(options: IDataToolsOptions = {}): ModuleWithProviders<DataToolsModule> {
    return {
      ngModule: DataToolsModule,
      providers: [{ provide: DATA_TOOLS_OPTIONS, useValue: options }],
    };
  }
}
