import type { OnDestroy, OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import type { SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import type { Observable } from 'rxjs';
import { forkJoin, of, ReplaySubject, Subject } from 'rxjs';
import { catchError, mergeMap, take, takeUntil, tap } from 'rxjs/operators';
import { GoogleTagManagerService } from '@dataportal/analytics';
import { CurrentUserService } from '@dataportal/auth';
import { AlertService, Logger } from '@dataportal/front-shared';
import { ImpersonateService, UsersService } from '@dataportal/users';

import { CurrentDashboardService } from '../../services/current-dashboard.service';
import { DashboardCommentsService } from '../../services/dashboard-comments.service';
import { DashboardCommentsCacheService } from '../../services/dashboard-comments-cache.service';
import { DashboardsService } from '../../services/dashboards.service';

import { Dashboard } from '../../entities/dashboard.model';

import { Source } from '../../../sources/entities/source';
import type { DashboardComment } from '../../entities/dashboard-comment';

@Component({
  selector: 'dpg-dashboard-comments-part',
  templateUrl: './dashboard-comments-part.component.html',
  styleUrls: ['./dashboard-comments-part.component.scss'],
})
export class DashboardCommentsPartComponent implements OnInit, OnDestroy {
  private static readonly _COMMENTS_PAGE_SIZE = 8;

  @Input() source: Source;
  @Input() currentDashboard: Dashboard;

  currentUserId = '';
  isCurrentUserAdmin = false;
  currentUserPictureBase64: SafeResourceUrl;
  isLoadingCurrentUserPictureBase64 = true;
  isLoadingDashboardComments = true;
  isPostingNewComment = false;
  dashboardCommentsList: DashboardComment[] = [];
  dashboardCommentsPaginatedList: DashboardComment[][] = [];
  shouldScrollToComment = false;
  commentIdToScrollTo: string;
  commentTypeToScrollTo: string;

  /* pagination */
  currentPageIndex = 1;
  nbDashboardComments = 0;

  clearComponentInput$ = new ReplaySubject<void>();
  setCursorAtTheEndOfCommentInput$ = new ReplaySubject<void>();

  private readonly _destroyed$ = new Subject<void>();
  private readonly _htmlComment$ = new Subject<HTMLElement>();

  constructor(
    private readonly _router: Router,
    private readonly _route: ActivatedRoute,
    private readonly _usersService: UsersService,
    private readonly _currentDashboardService: CurrentDashboardService,
    private readonly _dashboardsService: DashboardsService,
    private readonly _dashboardCommentService: DashboardCommentsService,
    private readonly _dashboardCommentCacheService: DashboardCommentsCacheService,
    private readonly _currentUserService: CurrentUserService,
    private readonly _impersonateService: ImpersonateService,
    private readonly _logger: Logger,
    private readonly _alertService: AlertService,
    private readonly _gtmService: GoogleTagManagerService,
  ) {
    this._dashboardCommentService.setPageSize(DashboardCommentsPartComponent._COMMENTS_PAGE_SIZE);
  }

  get hasToDisable(): boolean {
    return this._impersonateService.isImpersonating() && !this._impersonateService.isOriginUserAdmin();
  }

  get hasToDisablePostingNewComment(): boolean {
    return this.isLoadingDashboardComments || this.isPostingNewComment || this.hasToDisable;
  }

  ngOnInit(): void {
    if (
      !this.shouldScrollToComment &&
      this._route.snapshot.queryParams.comment &&
      this._route.snapshot.queryParams.comment_type
    ) {
      this.shouldScrollToComment = true;
      this.commentIdToScrollTo = this._route.snapshot.queryParams.comment;
      this.commentTypeToScrollTo = this._route.snapshot.queryParams.comment_type;
    }

    this.isLoadingCurrentUserPictureBase64 = true;
    this._dashboardCommentService.isLoadingDashboardComments$
      .pipe(takeUntil(this._destroyed$))
      .subscribe((isLoading) => {
        this.isLoadingDashboardComments = isLoading;
      });
    this._currentUserService.currentUser$
      .pipe(
        takeUntil(this._destroyed$),
        mergeMap((currentUser) => {
          this.currentUserId = currentUser.id;

          return this._usersService.getImage(this.currentUserId);
        }),
        catchError(() => {
          this.isLoadingCurrentUserPictureBase64 = false;

          return of(null);
        }),
      )
      .subscribe((currentUserPictureBase64) => {
        this.currentUserPictureBase64 = currentUserPictureBase64;
        this.isLoadingCurrentUserPictureBase64 = false;
      });
    this._currentUserService.isAdmin$.pipe(takeUntil(this._destroyed$)).subscribe((isAdmin) => {
      this.isCurrentUserAdmin = isAdmin;
    });
    this._currentDashboardService.dashboard$.pipe(takeUntil(this._destroyed$)).subscribe((dashboard) => {
      this._resetComponentAttributes();
    });
    this._dashboardCommentCacheService.nbCachedDashboardComments$
      .pipe(takeUntil(this._destroyed$))
      .subscribe((nbDashboardsComments) => {
        this.nbDashboardComments = nbDashboardsComments;
      });
    this._dashboardCommentCacheService.hasToRefresh$.pipe(takeUntil(this._destroyed$)).subscribe((isHardRefresh) => {
      if (isHardRefresh) {
        this._refreshDashboardCommentList();
      } else {
        this._logger.debug('[DASHBOARD_COMMENT_PART] Soft refresh');
        this._dashboardCommentCacheService.cachedDashboardComments$
          .pipe(take(1), takeUntil(this._destroyed$))
          .subscribe((cachedDashboardCommentsList) => {
            this.paginateDashboardComments(cachedDashboardCommentsList);
          });
      }
    });
    this._htmlComment$.pipe(takeUntil(this._destroyed$)).subscribe((htmlComment) => {
      if (htmlComment) {
        this.scrollToComment(htmlComment);
        this.shouldScrollToComment = false;
      }
    });
  }

  ngAfterViewChecked() {
    if (this.shouldScrollToComment) {
      const comment = document.getElementById(this.commentIdToScrollTo);

      if (comment) {
        this._htmlComment$.next(comment);
      }
    }
  }

  ngOnDestroy() {
    this._destroyed$.next();
  }

  /* comments part */

  cancelPostComment(): void {
    this.clearComponentInput$.next();
  }

  pushGTMCommentDashboardEvent() {
    const searchMode = this.getDCSearchMode();
    this._gtmService.pushEvent({
      event: 'dc_dashboard_commented',
      dc_dashboard_name: this.currentDashboard?.name,
      dc_mode: searchMode,
    });
  }

  getDCSearchMode(): string {
    const storedSearchMode = localStorage.getItem('dc-search-mode');

    return storedSearchMode ? storedSearchMode : 'advanced';
  }

  validatePostComment(message: string): void {
    this._postNewComment(message);
  }

  goToPage(pageIndex: number): void {
    if (pageIndex >= 1 && pageIndex <= this.dashboardCommentsPaginatedList?.length) {
      this.currentPageIndex = pageIndex;
    }
  }

  paginateDashboardComments(cachedDashboardCommentsList) {
    this.dashboardCommentsList = cachedDashboardCommentsList;
    this.dashboardCommentsPaginatedList = this._dashboardCommentService.getPaginatedDashboardComments(
      this.dashboardCommentsList,
    );

    if (this.shouldScrollToComment) {
      const pageIndex =
        this.commentTypeToScrollTo === 'comments'
          ? this.findComment(this.commentIdToScrollTo)
          : this.findReplyParent(this.commentIdToScrollTo);
      this.goToPage(pageIndex);
    }
  }

  findReplyParent(parentId: string): number {
    return this.dashboardCommentsPaginatedList.findIndex((page) => {
      const reply = page.find((comment) => parentId === comment.id);

      if (reply) {
        reply.hasToDisplayReplies = true;

        return true;
      }

      return false;
    });
  }

  findComment(commentId: string): number {
    return this.dashboardCommentsPaginatedList.findIndex((page) => {
      return page.find((comment) => commentId === comment.id);
    });
  }

  scrollToComment(comment: HTMLElement): void {
    comment.scrollIntoView(true);
    comment.setAttribute('class', 'highlight');
  }

  private _refreshDashboardCommentList() {
    this._logger.debug('[DASHBOARD_COMMENT_PART] Hard refresh');
    this._dashboardCommentService.dashboardComments$
      .pipe(
        take(1),
        takeUntil(this._destroyed$),
        mergeMap((dashboardCommentsList) => {
          this.paginateDashboardComments(dashboardCommentsList);

          return this._refreshCommentsPictures(this.dashboardCommentsList);
        }),
        tap(() => {
          // necessary to put this after mergeMap because we have to clone after initializing pictures
          this._dashboardCommentCacheService.initCachedDashboardComments(this.dashboardCommentsList);
        }),
        mergeMap(() => this._dashboardCommentCacheService.cachedDashboardComments$),
      )
      .subscribe((cachedDashboardCommentsList) => {
        this.paginateDashboardComments(cachedDashboardCommentsList);
      });
  }

  private _resetComponentAttributes(): void {
    this.isPostingNewComment = false;
    this.currentPageIndex = 1;
  }

  private _getRefreshCommentsPicturesObservablesRec(
    dashboardCommentsList: DashboardComment[],
    allObservables$: Observable<SafeResourceUrl>[],
  ): void {
    if (!dashboardCommentsList?.length) {
      return;
    }

    allObservables$.push(
      ...dashboardCommentsList.map((dashboardComment) => {
        dashboardComment.isLoadingPicture = true;

        return this._usersService.getImage(dashboardComment.authorId).pipe(
          take(1),
          takeUntil(this._destroyed$),
          tap((pictureBase64) => {
            dashboardComment.isLoadingPicture = false;
            dashboardComment.pictureBase64 = pictureBase64;
          }),
          catchError(() => {
            dashboardComment.isLoadingPicture = false;

            return of(null);
          }),
        );
      }),
    );
    dashboardCommentsList.forEach((dashboardComment) => {
      if (dashboardComment?.childrenComments?.length) {
        this._getRefreshCommentsPicturesObservablesRec(dashboardComment.childrenComments, allObservables$);
      }
    });
  }

  private _refreshCommentsPictures(dashboardCommentsList: DashboardComment[]): Observable<void> {
    const allObservables$: Observable<SafeResourceUrl>[] = [];
    this._getRefreshCommentsPicturesObservablesRec(dashboardCommentsList || [], allObservables$);

    return allObservables$?.length ? forkJoin(allObservables$) : of(null);
  }

  private _postNewComment(message: string): void {
    this.isPostingNewComment = true;
    this._dashboardCommentService
      .addNewDashboardComment(this.source?.id, this.currentDashboard?.id, null, message)
      .pipe(
        takeUntil(this._destroyed$),
        catchError((error: unknown) => {
          this._logger.error(`[DASHBOARD_COMMENTS_PART] Error while posting comment ${message} : `, error);
          this._alertService.error('An error occurred while posting this comment');

          return of({ hasError: true });
        }),
        mergeMap((result) => {
          let result$ = of(null);

          // TODO: [Revisit] (18.01.2024) - types
          if (!result || (result && !(result as { hasError: boolean }).hasError)) {
            result$ = this._dashboardCommentService.refreshDashboardComments(true);
            this.clearComponentInput$.next();
          }

          this.setCursorAtTheEndOfCommentInput$.next();
          this.isPostingNewComment = false;

          if (this.dashboardCommentsPaginatedList?.length > 0) {
            this.goToPage(1);
          }

          return result$;
        }),
      )
      .subscribe(() => {
        this.pushGTMCommentDashboardEvent();
      });
  }
}
