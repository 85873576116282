import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, combineLatest, merge, Subject } from 'rxjs';
import { filter, switchMap, takeUntil } from 'rxjs/operators';
import type { AccessRequestV2 } from '@dataportal/access-requests';
import { AccessRequestsV2Service } from '@dataportal/access-requests';
import type { IGenericFilter, IGenericFilterItem } from '@dataportal/adl';
import { AffiliatesService } from '@dataportal/affiliates';
import { AmundsenService } from '@dataportal/amundsen';
import { GoogleTagManagerService } from '@dataportal/analytics';
import { CurrentUserService } from '@dataportal/auth';
import type { RootCategory } from '@dataportal/categories';
import { CategoriesService } from '@dataportal/categories';
import type { Portal } from '@dataportal/portals';
import { PortalsService } from '@dataportal/portals';
import type { IAmundsenTableInfo } from '@dataportal/snowflake';
import type { Dashboard, Source } from '@dataportal/sources-dashboards-recommendations';
import { FavoritesSourcesService, SourcesService } from '@dataportal/sources-dashboards-recommendations';
import { FormControl } from '@ngneat/reactive-forms';
import Fuse from 'fuse.js';
import { cloneDeep } from 'lodash';

import {
  AFFILIATE_FILTER,
  BUSINESS_CATEGORY_FILTER,
  CERTIFICATION_FILTER,
  CONFIDENTIALITY_FILTER,
  CONTACT_FILTER,
  contactTypes,
  DATA_SOURCING_FILTER,
  DATA_STORAGE_FILTER,
  DATES_FILTER,
  FavoriteTypes,
  FiltersNamingIdsReference,
  forbiddenVisualizationItems,
  GTMCatalogEventType,
  IGTMCatalogData,
  IGTMCatalogEvent,
  METADATA_FILTER,
  PERMISSION_FILTER,
  RECENTLY_SEARCHED,
  SEARCH_MODE_PREFERENCE_NAME,
  SEARCH_MODE_USED_BEFORE_NEW_TAB,
  VISUALIZATION_FILTER,
} from './catalog.constants';

@Injectable()
export class CatalogV2SearchService {
  private _sources: Source[] = [];
  private _affiliates: IGenericFilterItem[] = [];
  private _rootCategories: RootCategory[] = [];
  private _accessRequests: AccessRequestV2[] = [];
  private _applyCookie = false;
  private _organizations: Portal[] = [];
  private _pernodRicardId: string;

  private readonly _filters$ = new BehaviorSubject<IGenericFilter[]>([]);
  filters$ = this._filters$.asObservable();
  private readonly _filteredSources$ = new BehaviorSubject<Source[]>([]);
  filteredSources$ = this._filteredSources$.asObservable();
  private readonly _searchTerm$ = new BehaviorSubject<string>('');
  searchTerm$ = this._searchTerm$.asObservable();
  private readonly _selectedSource$ = new BehaviorSubject<Source>(null);
  selectedSource$ = this._selectedSource$.asObservable();
  private readonly _searchMode$ = new BehaviorSubject<'simple' | 'advanced'>(null);
  searchMode$ = this._searchMode$.asObservable();
  private readonly _loading$ = new BehaviorSubject<boolean>(true);
  loading$ = this._loading$.asObservable();
  private readonly _destroyed$ = new Subject<void>();

  constructor(
    private readonly _route: ActivatedRoute,
    private readonly _categoriesService: CategoriesService,
    private readonly _sourcesService: SourcesService,
    private readonly _favoritesSourcesService: FavoritesSourcesService,
    private readonly _accessRequestsV2Service: AccessRequestsV2Service,
    private readonly _currentUserService: CurrentUserService,
    private readonly _amundsenService: AmundsenService,
    private readonly _affiliatesService: AffiliatesService,
    private readonly _gtmService: GoogleTagManagerService,
    private readonly _portalsService: PortalsService,
  ) {
    this._filters$.next([
      {
        title: AFFILIATE_FILTER,
        open: true,
        displayMore: false,
        displayCount: 4,
        searchPlaceholder: 'Typing business area or product family',
        filterItems: [],
        control: new FormControl(),
      },
      {
        title: VISUALIZATION_FILTER,
        open: false,
        displayMore: false,
        filterItems: [],
      },
      {
        title: DATA_STORAGE_FILTER,
        open: false,
        displayMore: false,
        filterItems: [],
      },
      {
        title: CERTIFICATION_FILTER,
        open: false,
        displayMore: false,
        type: 'radio',
        filterItems: [],
      },
      {
        title: PERMISSION_FILTER,
        open: false,
        displayMore: false,
        filterItems: [],
      },
      {
        title: CONFIDENTIALITY_FILTER,
        open: false,
        displayMore: false,
        type: 'radio',
        filterItems: [],
      },
    ]);

    // check if a user already has a search mode saved
    let storedSearchMode = localStorage.getItem(SEARCH_MODE_USED_BEFORE_NEW_TAB);

    // remove the search mode if it's set before going to a new tab
    if (storedSearchMode) {
      localStorage.removeItem(SEARCH_MODE_USED_BEFORE_NEW_TAB);
    } else {
      storedSearchMode = localStorage.getItem(SEARCH_MODE_PREFERENCE_NAME);
    }

    // load the actual search mod
    if (storedSearchMode) {
      this._applyCookie = true;
      this._searchMode$.next(storedSearchMode as 'simple' | 'advanced');
    } else {
      // advanced mode is set by default
      this._searchMode$.next('advanced');
    }

    // Do not show data storage filters if the user is in simple mode
    if (this._isSimpleMode) {
      this._removeDataStorageFromFilters();
    }

    this._currentUserService.currentUser$
      .pipe(
        filter((user) => !!user?.id),
        switchMap((user) => this._accessRequestsV2Service.listByUser(user.id)),
        takeUntil(this._destroyed$),
      )
      .subscribe();

    this._favoritesSourcesService.refreshFavoritesSources();

    // lots of logic in here but we need to synchronise all 4 observables
    combineLatest([
      this._sourcesService.allSources(),
      this._categoriesService.rootCategories$,
      this._affiliatesService.affiliatesFilter$,
      this._accessRequestsV2Service.currentUserAccessRequests$,
      this._portalsService.portals$,
    ])
      .pipe(takeUntil(this._destroyed$))
      .subscribe(([sources, rootCategories, affiliates, accessRequests, organizations]) => {
        const query = this._route.snapshot.queryParamMap.get('q');
        this._searchTerm$.next(query);
        this._sources = sources;
        this._affiliates = affiliates;
        this._rootCategories = rootCategories;
        this._accessRequests = accessRequests;
        this._filteredSources$.next(this._sources);
        this._organizations = organizations;

        this._addStoragesAndVisualizations();
        // method is not private because it's used elsewhere
        this.handleAffiliatesCount(affiliates);
        this._addSavedAdvancedFilters();
        this._addPermissions();
        this._addCertifications();
        this._addDataConfidentiality();
        this._filterSourceList();
        this._listAccessRequestedSourceIds();
        this._loading$.next(false);
      });

    merge(this._filters$, this._searchTerm$).subscribe(() => this._filterSourceList());

    this._searchTerm$.subscribe(() => {
      if (!this._loading$.value) {
        const filteredSources = this._filteredSources$?.value;
        const filters = this._filters$?.getValue();
        // this.pushGTMSearchEvent(!!filteredSources?.length, filters); TEMPORARY COMMENT OUT until Matomo DOM bug fix
      }
    });
  }

  get numberOfSourcesWithPermission(): number {
    return this._filteredSources$.getValue().filter((s) => this._sourcesService.isSourceComplete(s)).length;
  }

  private get _isSimpleMode() {
    return this._searchMode$.getValue() === 'simple' || !this._searchMode$.getValue();
  }

  private static _filterSourceListBySnowflake(sources: Source[]): Source[] {
    return sources.filter((s) => s.snowflakeTableKeys?.length > 0);
  }

  private static _filterSourceListByMSSQL(sources: Source[]): Source[] {
    return sources.filter((s) => s.mssqlTableKeys?.length > 0);
  }

  handleAffiliatesCount(affiliates: IGenericFilterItem[]): void {
    const filters = this._filters$.getValue();
    const affiliatesFilter = filters.find((genericFilter: IGenericFilter) => genericFilter.title === AFFILIATE_FILTER);

    // clone deep because we don't want to reference affiliates but copy then
    affiliatesFilter.filterItems = cloneDeep(affiliates);
    this._affiliatesService.removeDefaultGlossary(affiliatesFilter.filterItems);
    // update count for each
    affiliatesFilter.filterItems.forEach((affiliate: IGenericFilterItem) => {
      affiliate.count = this._filterSourceListByAffiliate(this._sources, [affiliate.value]).length;
      this._addCountDeep(affiliate);
    });
  }

  // GTM function used for sending statistics to Google Tag manager
  pushGTMSearchEvent(results: boolean, filters: IGenericFilter[]) {
    const searchTerm = this._searchTerm$.value;

    if (searchTerm?.length) {
      const eventFilters = {
        affiliate: [],
        visualization: [],
        data_storage: [],
        business_category: [],
        permission: [],
        confidentiality: [],
      };

      let isUsingAdvancedFilters = false;
      const eventAdvancedFilters = {
        contact: [],
        metadata: [],
        date: [],
        data_sourcing: [],
      };

      filters.forEach((genericFilter: IGenericFilter) => {
        // get all checked filters
        const selectedFilterItems = genericFilter.filterItems.filter((item) => item.checked);

        // handle event data depending on filter type
        switch (genericFilter.title) {
          case VISUALIZATION_FILTER: {
            eventFilters.visualization = selectedFilterItems.map((filterItem) => filterItem.value);
            break;
          }

          case DATA_STORAGE_FILTER: {
            eventFilters.data_storage = selectedFilterItems.map((filterItem) => filterItem.value);
            break;
          }

          case AFFILIATE_FILTER: {
            eventFilters.affiliate = this.flattenNestedFilterItems(genericFilter.filterItems)
              .filter((a) => a.checked)
              .map((filterItem) => filterItem.value);
            break;
          }

          case BUSINESS_CATEGORY_FILTER: {
            eventFilters.business_category = selectedFilterItems.map((filterItem) => filterItem.value);
            break;
          }

          case PERMISSION_FILTER: {
            eventFilters.permission = selectedFilterItems.map((filterItem) => filterItem.value);
            break;
          }

          case CONFIDENTIALITY_FILTER: {
            eventFilters.confidentiality = selectedFilterItems.map((filterItem) => filterItem.value);
            break;
          }

          case CONTACT_FILTER: {
            eventAdvancedFilters.contact = selectedFilterItems.map((filterItem) => filterItem.value);
            isUsingAdvancedFilters = isUsingAdvancedFilters || eventAdvancedFilters.contact.length > 0;
            break;
          }

          case METADATA_FILTER: {
            eventAdvancedFilters.metadata = selectedFilterItems.map((filterItem) => filterItem.value);
            isUsingAdvancedFilters = isUsingAdvancedFilters || eventAdvancedFilters.metadata.length > 0;
            break;
          }

          case DATES_FILTER: {
            eventAdvancedFilters.date = selectedFilterItems.map((filterItem) => filterItem.value);
            isUsingAdvancedFilters = isUsingAdvancedFilters || eventAdvancedFilters.date.length > 0;
            break;
          }

          case DATA_SOURCING_FILTER: {
            eventAdvancedFilters.data_sourcing = selectedFilterItems.map((filterItem) => filterItem.value);
            isUsingAdvancedFilters = isUsingAdvancedFilters || eventAdvancedFilters.data_sourcing.length > 0;
            break;
          }
        }
      });

      // push events
      this._gtmService.pushEvent({
        event: 'dc_search',
        dc_search_query: searchTerm,
        dc_search_results: results ? 'yes' : 'no',
        dc_mode: this._searchMode$.value,
        dc_filters: eventFilters,
      });

      if (isUsingAdvancedFilters) {
        this._gtmService.pushEvent({
          event: 'dc_advanced_filters_search',
          dc_mode: this._searchMode$.value,
          dc_advanced_filters: eventAdvancedFilters,
        });
      }
    }
  }

  // specific
  pushGTMDataAssetElementEvent(eventType: GTMCatalogEventType, gtmData: IGTMCatalogData) {
    const eventOptions: IGTMCatalogEvent = {
      event: eventType,
      dc_mode: this._searchMode$.value,
    };

    if (eventType === 'dc_datalake_asset_viewed') {
      eventOptions.dc_datalake_asset_name = gtmData.name;
    } else if (eventType === 'dc_dashboard_viewed') {
      eventOptions.dc_dashboard_name = gtmData.name;
    }

    this._gtmService.pushEvent(eventOptions);
  }

  parseRecentlySearched(): string[] {
    return localStorage.getItem(RECENTLY_SEARCHED) ? JSON.parse(localStorage.getItem(RECENTLY_SEARCHED)) : [];
  }

  makeRecentlySearchedSourcesList(sources: Source[], recentlySearchedIds: string[]): Source[] {
    const recentlyClickedSources = sources.filter((source) => recentlySearchedIds.includes(source.id));
    recentlyClickedSources.sort((a: Source, b: Source): number => {
      return Number(recentlySearchedIds.indexOf(a.id)) - Number(recentlySearchedIds.indexOf(b.id));
    });

    return recentlyClickedSources;
  }

  updateQuery(searchTerm: string): void {
    this._searchTerm$.next(searchTerm);
  }

  updateFilters(filters: IGenericFilter[]): void {
    this._filters$.next(filters);
  }

  updateSelectedSource(source: Source): void {
    this._selectedSource$.next(source);
  }

  updateCookieMode(applyCookie: boolean): void {
    this._applyCookie = applyCookie;
  }

  updateMode(mode: 'simple' | 'advanced'): void {
    this._searchMode$.next(mode);

    // there's no storage filter in simple mode
    if (mode === 'advanced') {
      this._addDataStorages();
    } else {
      this._removeDataStorageFromFilters();
    }

    this._filterSourceList();

    if (this._applyCookie) {
      localStorage.setItem(SEARCH_MODE_PREFERENCE_NAME, mode);

      return;
    }

    localStorage.removeItem(SEARCH_MODE_PREFERENCE_NAME);
  }

  // transform hierarchical structure into flat structure for easier affiliate filtering
  flattenNestedFilterItems(filterItems: IGenericFilterItem[]): IGenericFilterItem[] {
    let flatAffiliateFilters: IGenericFilterItem[] = [];

    filterItems.forEach((item: IGenericFilterItem) => {
      flatAffiliateFilters.push(item);

      if (item.subFilterItems?.length) {
        flatAffiliateFilters = flatAffiliateFilters.concat(this.flattenNestedFilterItems(item.subFilterItems));
      }
    });

    return flatAffiliateFilters.sort((a, b) => a.label.localeCompare(b.label));
  }

  removeSelfFilterItem(genericFilter: IGenericFilter, item: IGenericFilterItem) {
    const filterItems = genericFilter.filterItems;
    const index = filterItems.findIndex((filterItem) => filterItem.label === item.label);
    const cookieName = `dc-${genericFilter.title.replace(' ', '-').toLowerCase()}-filters`;
    filterItems[index].checked = false;
    filterItems.splice(index, 1);

    if (filterItems.length === 0) {
      localStorage.removeItem(cookieName);
    } else {
      const tmpFilter = cloneDeep(genericFilter);
      tmpFilter.filterItems.forEach((i) => (i.checked = false));

      localStorage.setItem(cookieName, JSON.stringify(tmpFilter));
    }
  }

  private _listAccessRequestedSourceIds(): string[] {
    return this._accessRequests.map((ar) => {
      if (ar.resource === 'sources') {
        if (ar.status === 'pending' || ar.status === 'inactive') {
          return ar.resourceId;
        }
      }
    });
  }

  private _addCertifications(): void {
    const filters = this._filters$.getValue();
    const certification = filters.find((genericFilter: IGenericFilter) => genericFilter.title === CERTIFICATION_FILTER);
    const certifiedSourceCount = this._sources.filter((source) => source.dataCertification?.isCertified).length;
    const notCertifiedSourceCount = this._sources.length - certifiedSourceCount;

    certification.filterItems = [
      { label: 'Certified data products', checked: false, count: certifiedSourceCount, value: 'certified' },
      {
        label: 'Non-certified data products',
        checked: false,
        count: notCertifiedSourceCount,
        value: 'not-certified',
      },
    ];
  }

  private _addPermissions(): void {
    const filters = this._filters$.getValue();
    const permission = filters.find((genericFilter: IGenericFilter) => genericFilter.title === PERMISSION_FILTER);
    const hasAccessSourceCount = this._sources.filter((source) => this._sourcesService.isSourceComplete(source)).length;
    const restrictedSourceCount = this._sources.filter((source) => source.isLimited).length;

    permission.filterItems = [
      { label: 'I have access', checked: false, count: hasAccessSourceCount },
      {
        label: "I don't have access",
        checked: false,
        count: restrictedSourceCount,
      },
    ];
  }

  // This function add and retrieve sources data storages and powerbi categories and count.
  private _addStoragesAndVisualizations(): void {
    const filters = this._filters$.getValue();
    const visualization = filters.find((genericFilter: IGenericFilter) => genericFilter.title === VISUALIZATION_FILTER);
    const dataStorage = filters.find((genericFilter: IGenericFilter) => genericFilter.title === DATA_STORAGE_FILTER);

    // empty filter list to avoid duplication
    if (dataStorage) {
      dataStorage.filterItems = [];
    }

    if (visualization) {
      visualization.filterItems = [];
    }

    this._sources.forEach((source: Source) => {
      /*
      if (dataStorage) {
        const dataStorageItem = dataStorage.filterItems.find(
          (ds: IGenericFilterItem) =>
            ds.value === source.datalakeProvider || ds.label === FiltersNamingIdsReference[source.datalakeProvider],
        );

        // Find and add source datalake provider to _filters
        if (!dataStorageItem && source.datalakeProvider && source.datalakeProvider !== 'none') {
          const provider = FiltersNamingIdsReference[source.datalakeProvider]
            ? FiltersNamingIdsReference[source.datalakeProvider]
            : source.datalakeProvider;

          dataStorage.filterItems.push({
            label: provider,
            value: source.datalakeProvider,
            checked: false,
            count: this._filterSourceListByDataStorage(this._sources, [provider]).length,
          });
        }
      }
       */

      // Find and add source powerBI types to _filters
      source.powerbi.forEach((pbi: Dashboard) => {
        const visualizationItem = visualization.filterItems.find((v: IGenericFilterItem) => v.value === pbi.type);

        if (!visualizationItem && forbiddenVisualizationItems.findIndex((i) => i === pbi.type) === -1) {
          const type = FiltersNamingIdsReference[pbi.type] ? FiltersNamingIdsReference[pbi.type] : pbi.type;

          visualization.filterItems.push({
            label: type,
            value: pbi.type,
            checked: false,
            count: this._filterSourceListByVisualization(this._sources, [pbi.type]).length,
          });
        }
      });

      visualization.filterItems.sort((a, b) => a.label.localeCompare(b.label));
    });

    if (dataStorage) {
      dataStorage.filterItems.push({
        label: 'Snowflake',
        value: 'snowflake',
        checked: false,
        count: CatalogV2SearchService._filterSourceListBySnowflake(this._sources).length,
      });

      /*
      dataStorage.filterItems.push({
        label: 'SQL DB',
        value: 'mssql',
        checked: false,
        count: CatalogV2SearchService._filterSourceListByMSSQL(this._sources).length,
      });
       */
    }
  }

  private _addBusinessCategories(): void {
    const filters = this._filters$.getValue();
    const categories = filters.find(
      (genericFilter: IGenericFilter) => genericFilter.title === BUSINESS_CATEGORY_FILTER,
    );

    this._rootCategories.forEach((category: RootCategory) => {
      if (categories.filterItems.findIndex((item: IGenericFilterItem) => item.label === category.name) === -1) {
        categories.filterItems.push({
          label: category.name,
          value: category.id,
          checked: false,
          count: this._filterSourceListByBusinessCategory(this._sources, [category.id]).length,
        });
      }
    });

    categories.filterItems.sort((a, b) => a.label.localeCompare(b.label));
  }

  // Deep count affiliates
  private _addCountDeep(affiliate: IGenericFilterItem): void {
    affiliate.count = this._filterSourceListByAffiliate(this._sources, [affiliate.value]).length;
    affiliate.subFilterItems.forEach((childFilterItem: IGenericFilterItem) => {
      this._addCountDeep(childFilterItem);
    });
  }

  private _addDataStorages() {
    const curFilters = this._filters$.getValue();
    const existingStorage = curFilters.find(
      (genericFilter: IGenericFilter) => genericFilter.title === DATA_STORAGE_FILTER,
    );

    if (!existingStorage) {
      const dataStorage: IGenericFilter = {
        title: DATA_STORAGE_FILTER,
        open: false,
        displayMore: false,
        filterItems: [],
      };
      curFilters.splice(1, 0, dataStorage);
    }

    const visualization = curFilters.find(
      (genericFilter: IGenericFilter) => genericFilter.title === VISUALIZATION_FILTER,
    );
    visualization.filterItems = [];
    this._addStoragesAndVisualizations();

    this._filters$.next(curFilters);
  }

  // create filter from local storage string
  private _generateFilterItemObjectFromString(stringifiedFilter: string, curFilters: IGenericFilter[]): void {
    if (stringifiedFilter) {
      const parsedFilter: IGenericFilter = JSON.parse(stringifiedFilter);

      if (curFilters.some((f) => f.title === parsedFilter.title)) {
        return;
      }

      parsedFilter.filterItems.forEach(
        (item: IGenericFilterItem) => (item.removeSelf = () => this.removeSelfFilterItem(parsedFilter, item)),
      );

      curFilters.push(parsedFilter);
    }
  }

  private _addDataConfidentiality() {
    const curFilters = this._filters$.getValue();
    const dataCat = curFilters.find((genericFilter) => genericFilter.title === CONFIDENTIALITY_FILTER);

    dataCat.filterItems = [];

    this._sources.forEach((source) => {
      const sourceDataConfidentiality = source.dataClassification?.dataConfidentiality;

      if (sourceDataConfidentiality) {
        const existingItem = dataCat.filterItems.find((item) => item.value === sourceDataConfidentiality);

        if (existingItem) {
          existingItem.count++;
        } else {
          dataCat.filterItems.push({
            label: sourceDataConfidentiality.charAt(0).toUpperCase() + sourceDataConfidentiality.slice(1),
            value: sourceDataConfidentiality,
            checked: false,
            count: 1,
          });
        }
      }
    });

    this._filters$.next(curFilters);
  }

  // retrieve and add advanced filters from local storage
  private _addSavedAdvancedFilters() {
    const curFilters = this._filters$.getValue();

    this._generateFilterItemObjectFromString(localStorage.getItem('dc-contact-filters'), curFilters);
    this._generateFilterItemObjectFromString(localStorage.getItem('dc-metadata-filters'), curFilters);
    this._generateFilterItemObjectFromString(localStorage.getItem('dc-dates-filters'), curFilters);
    this._generateFilterItemObjectFromString(localStorage.getItem('dc-data-sourcing-filters'), curFilters);

    this._filters$.next(curFilters);
  }

  private _removeDataStorageFromFilters(): void {
    const curFilters = this._filters$.getValue();
    const idxToRemove = curFilters.findIndex((genericFilter) => genericFilter.title === DATA_STORAGE_FILTER);
    if (idxToRemove > -1) curFilters.splice(idxToRemove, 1);
    this._filters$.next(curFilters);
  }

  // filtering functions by filter type begin HERE

  private _filterSourceListByVisualization(sources: Source[], visFilters: string[]): Source[] {
    if (!visFilters?.length) return sources;

    return sources.filter((source) => {
      const sourceDashboardsTypes: string[] = source.powerbi.map((dashboard) => dashboard.type);

      return sourceDashboardsTypes.some((dashboardType) => visFilters.includes(dashboardType));
    });
  }

  private _filterSourceListByBusinessCategory(sources: Source[], businessCategoryIds: string[]): Source[] {
    if (!businessCategoryIds?.length) return sources;

    return sources.filter((s) => {
      return s.categories.some((category) => businessCategoryIds.includes(category));
    });
  }

  private _filterSourceListByAffiliate(sources: Source[], affiliateIds: string[]): Source[] {
    if (!affiliateIds?.length) return sources;

    return sources.filter((source) => {
      return source.organizations.some((organization) => {
        const isCertifiedPRSource =
          source.organizations.includes(this._pernodRicardId) && source.dataCertification?.isCertified;

        const isSourceShouldBeIncluded = isCertifiedPRSource && this._isNonAffiliateFilterApplied;

        return isSourceShouldBeIncluded || affiliateIds.includes(organization);
      });
    });
  }

  private _filterSourceListByCertified(sources: Source[], certifiedValue: string): Source[] {
    return sources.filter((s) =>
      certifiedValue === 'certified' ? s.dataCertification?.isCertified : !s.dataCertification?.isCertified,
    );
  }

  private _filterSourceListByDataStorage(sources: Source[], dataStorageFilters: string[]): Source[] {
    if (!dataStorageFilters?.length) return sources;

    return sources.filter(
      (source) => dataStorageFilters.includes('Snowflake') && source.snowflakeTableKeys?.length > 0,
    );

    /*
    return sources.filter((source) => {
      const sourceProvider = FiltersNamingIdsReference[source.datalakeProvider]
        ? FiltersNamingIdsReference[source.datalakeProvider]
        : source.datalakeProvider;

      let hasSnowflake: boolean;
      let hasMSSQL: boolean;
      const hasOtherDataStorage = dataStorageFilters.includes(sourceProvider);

      if (dataStorageFilters.includes('Snowflake')) {
        hasSnowflake = source.snowflakeTableKeys?.length > 0;
      }

      if (dataStorageFilters.includes('SQL DB')) {
        hasMSSQL = source.mssqlTableKeys?.length > 0;
      }

      return hasSnowflake || hasMSSQL || hasOtherDataStorage;
    });
     */
  }

  private _filterSourceListByAuthorized(sources: Source[], permissionFilters: IGenericFilterItem[]): Source[] {
    if (!permissionFilters?.length) return sources;

    const hasAccessChecked = permissionFilters.find((f) => f.label === 'I have access');
    const doesNotHaveAccessChecked = permissionFilters.find((f) => f.label === "I don't have access");
    const accessRequestChecked = permissionFilters.find((f) => f.label === 'I have requested access');
    const sourceIdsWithAccessRequested = this._listAccessRequestedSourceIds();

    let hasAccess: boolean;
    let doesNotHaveAccess: boolean;
    let isAccessRequested: boolean;

    return sources.filter((source) => {
      if (doesNotHaveAccessChecked) {
        doesNotHaveAccess = source.isLimited;
      }

      if (hasAccessChecked) {
        hasAccess = this._sourcesService.isSourceComplete(source);
      }

      if (accessRequestChecked) {
        isAccessRequested = sourceIdsWithAccessRequested.includes(source.id);
      }

      return hasAccess || doesNotHaveAccess || isAccessRequested;
    });
  }

  private _filterSourceListByOwnerAndContact(sources: Source[], filters: IGenericFilterItem[]): Source[] {
    return sources.filter((source) => {
      return filters.some((genericFilter) => {
        return !!this._fuzzyMatchSingleSource(
          source,
          genericFilter.value as
            | contactTypes.DATA_OWNER
            | contactTypes.TECHNICAL_OWNERS
            | contactTypes.FUNCTIONAL_OWNERS
            | contactTypes.DATA_ASSET_CREATOR,
          genericFilter.label,
        );
      });
    });
  }

  private _filterSourceListByMetadata(sources: Source[], filterItem: IGenericFilterItem): Source[] {
    return sources.filter(
      (s) => !!this._fuzzyMatchSingleSource(s, filterItem.value as keyof IAmundsenTableInfo, filterItem.label),
    );
  }

  private _filterSourceListByDates(sources: Source[], dates: string[], dateComparisonType: 'from' | 'to'): Source[] {
    const firstDate = new Date(dates[0]).getTime();
    const lastDate = new Date(dates[1]).getTime();

    return sources.filter((s) => {
      const lastUpdatedDate = new Date(s.updatedAt).getTime();

      return !!(
        (!dateComparisonType && lastUpdatedDate >= firstDate && lastUpdatedDate <= lastDate) ||
        (dateComparisonType === 'from' && lastUpdatedDate >= firstDate) ||
        (dateComparisonType === 'to' && lastUpdatedDate <= firstDate)
      );
    });
  }

  private _filterSourceListByDataSourcing(sources: Source[], dataSourcing: string): Source[] {
    return sources.filter((s) => s.dataClassification?.dataSourcing === dataSourcing);
  }

  private _filterSourceListByConfidentiality(sources: Source[], confidentiality: string): Source[] {
    return sources.filter((s) => s.dataClassification?.dataConfidentiality === confidentiality);
  }

  /**
   * @description This function sorts the sourceList parameter with unique values using those rules:
   *              First the sources with access by name, description, category and owners, the favorites
   *              Then, the sources without access using the exact same order
   *
   * @param sourceList
   * @private
   */
  private _filterBySearchAndSortSourceList(sourceList: Source[]): Source[] {
    let filteredSources = [...sourceList];

    if (this._isSimpleMode) {
      filteredSources = filteredSources.filter(
        (source) =>
          source.powerbi?.length ||
          source.externalLinks?.length ||
          source.dashboardFolders?.length ||
          source.datalakeProvider === 'external',
      );
    }

    filteredSources = this.fuzzyMatchSources(filteredSources);

    if (this._isNonAffiliateFilterApplied) {
      filteredSources = filteredSources.sort((sourceA, sourceB) => this._sortByCertification(sourceA, sourceB));
    }

    return filteredSources
      .sort((sourceA, sourceB) => this._sortByFavorite(sourceA, sourceB))
      .sort((sourceA, sourceB) => this._sortByAccess(sourceA, sourceB));
  }

  private _sortByCertification(sourceA: Source, sourceB: Source) {
    const isCertifiedA = sourceA.dataCertification?.isCertified || false;
    const isCertifiedB = sourceB.dataCertification?.isCertified || false;

    if (isCertifiedA !== isCertifiedB) {
      return isCertifiedA ? -1 : 1;
    }

    if (isCertifiedA && isCertifiedB) {
      const hasPrIdA = sourceA.organizations.includes(this._pernodRicardId);
      const hasPrIdB = sourceB.organizations.includes(this._pernodRicardId);

      if (hasPrIdA !== hasPrIdB) {
        return hasPrIdA ? 1 : -1;
      }

      return sourceA.name.localeCompare(sourceB.name);
    }

    return 0;
  }

  private _sortByFavorite(sourceA: Source, sourceB: Source) {
    return (
      Number(this._favoritesSourcesService.isFavoriteSource(sourceB.id)) -
      Number(this._favoritesSourcesService.isFavoriteSource(sourceA.id))
    );
  }

  private _sortByAccess(sourceA: Source, sourceB: Source) {
    return (
      Number(this._sourcesService.isSourceComplete(sourceB)) - Number(this._sourcesService.isSourceComplete(sourceA))
    );
  }

  // uses fuse.js library for fuzzy matching
  // search parameter weights are defined here
  fuzzyMatchSources(
    sources: Source[],
    query = this._searchTerm$.getValue(),
    type: FavoriteTypes = FavoriteTypes.DATA_ASSET,
  ): Source[] {
    if (!query) {
      return sources;
    }

    const options = {
      distance: 100,
      threshold: 0.15,
      minMatchCharLength: 1,
      shouldSort: true,
      useExtendedSearch: true,
      ignoreLocation: true,
      includeScore: true,
      includeMatches: true,
      ignoreFieldNorm: true,
      keys: this._getFuzzyMatchKeys(type),
    };

    if (!this._isSimpleMode) {
      options.keys.push({ name: 'snowflakeTableKeys', weight: 0.05 }, { name: 'mssqlTableKeys', weight: 0.05 });
    }

    options.keys.push({
      name: 'organizations',
      getFn: (src: Source) =>
        src.organizations.map(
          (organizationId) => this._organizations.find((organisation) => organisation.id === organizationId)?.name,
        ),
      weight: 0.3,
    });

    // sanitize query, remove 'dangerous' characters
    query = query.replace(/[='!^.$|]/g, ' ').trim();
    if (query?.length === 0) return sources;

    // fuse.js library
    const fuse = new Fuse(sources, options);

    return fuse.search(query).map((item) => item.item);
  }

  private _getFuzzyMatchKeys(
    type: FavoriteTypes,
  ): { name: string; getFn?: (src: Source) => string[]; weight: number }[] {
    if (type === FavoriteTypes.DASHBOARD) {
      return [
        {
          name: 'powerbi',
          getFn: (src: Source) => src.powerbi.map((pbi) => pbi.name),
          weight: 1,
        },
        {
          name: 'categories',
          getFn: (src: Source) =>
            src.categories.map((category) => this._rootCategories.find((rc) => rc.id === category)?.name),
          weight: 0.3,
        },
        { name: 'technicalOwners', weight: 0.1 },
        { name: 'dataOwner', weight: 0.1 },
        { name: 'functionalOwners', weight: 0.1 },
      ];
    }

    if (type === FavoriteTypes.DATALAKE) {
      return [
        {
          name: 'datalakePath',
          getFn: (src: Source) =>
            src.datalakePath.map((path) => (path.name || '') + path.path + (path.tenant ? `(${path.tenant})` : '')),
          weight: 1,
        },
        { name: 'name', weight: 0.4 },
        {
          name: 'categories',
          getFn: (src: Source) =>
            src.categories.map((category) => this._rootCategories.find((rc) => rc.id === category)?.name),
          weight: 0.3,
        },
        { name: 'technicalOwners', weight: 0.1 },
        { name: 'dataOwner', weight: 0.1 },
        { name: 'functionalOwners', weight: 0.1 },
      ];
    }

    if (type === FavoriteTypes.DATA_ASSET) {
      return [
        { name: 'name', weight: 1 },
        {
          name: 'categories',
          getFn: (src: Source) =>
            src.categories.map((category) => this._rootCategories.find((rc) => rc.id === category)?.name),
          weight: 0.4,
        },
        {
          name: 'datalakePath',
          getFn: (src: Source) =>
            src.datalakePath.map((path) => (path.name || '') + path.path + (path.tenant ? `(${path.tenant})` : '')),
          weight: 0.4,
        },
        {
          name: 'powerbi',
          getFn: (src: Source) => src.powerbi.map((pbi) => pbi.name),
          weight: 0.4,
        },
        { name: 'technicalOwners', weight: 0.1 },
        { name: 'dataOwner', weight: 0.1 },
        { name: 'functionalOwners', weight: 0.1 },
      ];
    }
  }

  // Same as previous function but used for single source
  private _fuzzyMatchSingleSource(source: Source, key: keyof Source | keyof IAmundsenTableInfo, query: string): Source {
    if (!query) {
      return source;
    }

    const tmpSource = cloneDeep(source);
    const options = {
      distance: 100,
      threshold: 0.15,
      shouldSort: true,
      ignoreLocation: true,
      includeScore: true,
      includeMatches: true,
      keys: [key],
    };

    if (
      tmpSource.snowflakeTableKeys?.length > 0 &&
      (key === 'tableName' || key === 'databaseName' || key === 'schemaName')
    ) {
      const tableData: string[] = [];
      tmpSource.snowflakeTableKeys.forEach((tableKey) => {
        tableData.push(this._amundsenService.splitAmundsenTableKey(tableKey)[key]);
      });

      tmpSource[key] = tableData;
    }

    if (
      tmpSource.mssqlTableKeys?.length > 0 &&
      (key === 'tableName' || key === 'databaseName' || key === 'schemaName')
    ) {
      const tableData: string[] = [];
      tmpSource.mssqlTableKeys.forEach((tableKey) => {
        tableData.push(this._amundsenService.splitAmundsenTableKey(tableKey)[key]);
      });

      tmpSource[key] = tableData;
    }

    const fuse = new Fuse([tmpSource], options as unknown);

    return fuse.search(query)?.length ? source : null;
  }

  // THE MAIN FILTERING FUNCTION
  // The logic BETWEEN filters is 'AND' while the logic INSIDE filters is 'OR'
  private _filterSourceList(): void {
    let filteredSources = this._sources;
    const filters = this._filters$.getValue();

    filters.forEach((genericFilter: IGenericFilter) => {
      const selectedFilterItems = genericFilter.filterItems.filter((item) => item.checked);
      const filterValues = selectedFilterItems.map((filterItem: IGenericFilterItem) => filterItem.value);

      switch (genericFilter.title) {
        case VISUALIZATION_FILTER:
          filteredSources = this._filterSourceListByVisualization(filteredSources, filterValues);
          break;
        case DATA_STORAGE_FILTER:
          filteredSources = this._filterSourceListByDataStorage(
            filteredSources,
            selectedFilterItems.map((filterItem: IGenericFilterItem) => filterItem.label),
          );
          break;
        case AFFILIATE_FILTER:
          const flattenedNestedAffiliates = this.flattenNestedFilterItems(genericFilter.filterItems)
            .filter((affiliate) => affiliate.checked)
            .map((checkAffiliate) => checkAffiliate.value);

          filteredSources = this._filterSourceListByAffiliate(filteredSources, flattenedNestedAffiliates);
          break;
        case CERTIFICATION_FILTER:
          selectedFilterItems.forEach(
            (filterItem) => (filteredSources = this._filterSourceListByCertified(filteredSources, filterItem.value)),
          );
          break;
        case BUSINESS_CATEGORY_FILTER:
          filteredSources = this._filterSourceListByBusinessCategory(filteredSources, filterValues);
          break;
        case PERMISSION_FILTER:
          filteredSources = this._filterSourceListByAuthorized(filteredSources, selectedFilterItems);
          break;
        case CONFIDENTIALITY_FILTER:
          selectedFilterItems.forEach(
            (filterItem) =>
              (filteredSources = this._filterSourceListByConfidentiality(filteredSources, filterItem.value)),
          );
          break;
        case CONTACT_FILTER:
          if (selectedFilterItems?.length) {
            filteredSources = this._filterSourceListByOwnerAndContact(filteredSources, selectedFilterItems);
          }

          break;
        case METADATA_FILTER:
          selectedFilterItems.forEach(
            (item) => (filteredSources = this._filterSourceListByMetadata(filteredSources, item)),
          );
          break;
        case DATES_FILTER:
          selectedFilterItems.forEach(
            (item) =>
              (filteredSources = this._filterSourceListByDates(
                filteredSources,
                item.value.split(' - '),
                item.description as 'from' | 'to',
              )),
          );
          break;
        case DATA_SOURCING_FILTER:
          selectedFilterItems.forEach(
            (item) => (filteredSources = this._filterSourceListByDataSourcing(filteredSources, item.value)),
          );
          break;
      }
    });

    this._pernodRicardId = this._affiliates.find((affiliate) => affiliate.label === 'PERNOD RICARD')?.value;

    this._filteredSources$.next(this._filterBySearchAndSortSourceList(filteredSources));
  }

  private get _isNonAffiliateFilterApplied(): boolean {
    const isFilterApplied = this._filters$
      .getValue()
      .some(
        (genericFilter) =>
          genericFilter.title !== AFFILIATE_FILTER && genericFilter.filterItems.some((item) => item.checked),
      );

    return !!this._searchTerm$.getValue() || isFilterApplied;
  }
}
