import type { DatalakeOnlyProvider } from '@dataportal/datalake-parsing';
import type { DatalakePath } from '@dataportal/front-shared';

import type { IBucket } from './datalake';

const filesystemNameToBucket = (filesystemName: string): IBucket => {
  return { name: filesystemName, primary: true, category: null };
};

export const filterByName = <T extends { name: string }>(array: T[], name: string): T[] => {
  const lowerCaseName = name.toLowerCase();

  return array.filter((item) => item.name.toLowerCase().includes(lowerCaseName));
};

export const datalakePathToFolder = (
  datalakePath: DatalakePath,
  isLoading: boolean,
  isExisting: boolean,
  hasToRemoveName = true,
): IObject => {
  const splitPath = datalakePath.path.split('/');

  const [bucketName, ...restOfThePath] = splitPath;

  const name = restOfThePath.pop();

  const path = (hasToRemoveName ? restOfThePath : [...restOfThePath, name]).join('/');

  return {
    isFilesystem: false,
    isFolder: true,
    filesystem: filesystemNameToBucket(bucketName),
    path,
    name,
    provider: datalakePath.provider,
    tenant: datalakePath.tenant,
    isLoading,
    isExisting,
  };
};

interface IListedObject {
  isFilesystem: boolean;
  isFolder: boolean;
  filesystem: IBucket;
  name: string;
}

export interface IObject extends IListedObject {
  isFilesystem: false;
  path: string | null; // empty for root, null for filesystems, does not contain folder name
  provider: DatalakeOnlyProvider;
  tenant: string;
  isLoading?: boolean;
  isExisting?: boolean;
  key?: string;
}

export interface IObjectRow extends IObject {
  isSelected: boolean;
}

export interface IDatalakeObjectSelectorModalData {
  isSelectingFiles: boolean;
  isMultipleSelect: boolean;
  isListingUsingOwnToken: boolean;
  defaultFolder: IObject;
  selectedObjects: IObject[];
  doNotPreCharge: boolean;
  title: string;
}
