<adl-tooltip
  [ngClass]="{ 'column-flex': isColumnFlex }"
  [placement]="placement"
  (mouseOver)="this.onMouseHover($event)"
  (mouseOut)="this.onMouseOut($event)"
  [maxContentSize]="maxContentSize"
  [minContentSize]="minContentSize"
>
  <adl-icon *ngIf="!bubbleSvgIcon" tooltip-trigger [iconType]='iconType' [size]="size" [icon]="bubbleIcon"></adl-icon>
  <adl-svg-icon *ngIf="bubbleSvgIcon" tooltip-trigger [iconType]='iconType' [size]="size" [icon]="bubbleSvgIcon"></adl-svg-icon>
  <ng-content ngProjectAs="[tooltip-content]"></ng-content>
</adl-tooltip>
