import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'dpg-catalog-switch',
  templateUrl: './catalog-switch.component.html',
})
export class CatalogSwitchComponent {
  @Input() switchControl = new FormControl();
}
