<div *ngIf="!hasError" class="overlay" [ngClass]="{ 'overlay-visible': this.isBookmarkActivationInProgress }">
  <section class="dashboard-app">
    <dpg-dashboard-sidebar
      [source]="source"
      [currentDashboard]="dashboard"
      class="side-bar"
    ></dpg-dashboard-sidebar>

    <!-- If the dashboard is on maintenance display maintenance message -->
    <dpg-dashboard-maintenance></dpg-dashboard-maintenance>

    <ng-container *ngIf="!isOnMaintenance">
      <!-- If user uses a mobile device display custom navbar -->
      <dpg-dashboard-mobile-navbar></dpg-dashboard-mobile-navbar>
      <div
        class="col-dashboard-container"
        [class.is-navbar-deactivated]="isNavbarDeactivated"
        [class.configuration]="hasAlert"
        [class.has-toolbar]="shouldDisplayToolbar"
      >
        <!-- Dashboard Toolbar -->
        <dpg-dashboard-toolbar
          *ngIf="shouldDisplayToolbar"
          [isLoadingDashboardComments]="isLoadingDashboardComments"
          [nbDashboardComments]="nbDashboardComments"
          (toggleDisplayCommentsPartEvent)="this.toggleDisplayCommentsPart()"
        ></dpg-dashboard-toolbar>
        <adl-loaded-item [loading]="isLoading">
          <div class="dashboard flex flex-row justify-start items-start w-full h-full">
            <div class="dashboard-container" [hidden]="!isCubeStarting">
              <adl-lottie-animation [speed]="2"></adl-lottie-animation>
            </div>
            <div #dashboardContainer class="dashboard-container" [class.has-powerbi-navmob]="isMobile" [hidden]="isCubeStarting"></div>
            <dpg-dashboard-comments-part
              [@openClose]="hasToTriggerTransitionInCommentsPart ? 'open' : 'closed'"
              [source]="source"
              [currentDashboard]="dashboard"
              class="side-bar h-full"
              *ngIf="hasToDisplayCommentsPart"
            ></dpg-dashboard-comments-part>
          </div>
        </adl-loaded-item>
      </div>
    </ng-container>
  </section>
</div>
<div class="error-page column-flex a-center" *ngIf="hasError">
  <adl-loaded-item class="column-flex a-center w80" [loading]="isLoading">
    <adl-title type="secondary"
               class="m40 error-message"> You don't have access to this dashboard, please contact your administrator.
    </adl-title>
    <adl-button type="secondary" (clicked)="goBackToHomePage()"> Go back to the home page</adl-button>
  </adl-loaded-item>
</div>
