import type { OnDestroy } from '@angular/core';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { GoogleTagManagerService } from '@dataportal/analytics';
import { AlertService } from '@dataportal/front-shared';

import { RecommendationsModule } from '../recommendations.module';

import { RecommendationsService } from './recommendations.service';

import { RecommendationModalComponent } from '../components/recommendation-modal/recommendation-modal.component';

import type { Dashboard } from '../../dashboards/entities/dashboard.model';
import type { Source } from '../../sources/entities/source';
import type { IRecommendationCreation } from '../components/recommendation-modal/recommendation-modal.component';

// Service
@Injectable({
  providedIn: RecommendationsModule,
})
export class RecommendationModalService implements OnDestroy {
  private readonly _destroyed$ = new Subject<void>();

  // Constructor
  constructor(
    private readonly modalMatService: MatDialog,
    private readonly recommendationsService: RecommendationsService,
    private readonly alertService: AlertService,
    private readonly _gtmService: GoogleTagManagerService,
  ) {}

  ngOnDestroy() {
    this._destroyed$.next();
  }

  // Methods
  openRecommendationModal(source: Source, dashboard?: Dashboard): void {
    const recoModal = this.modalMatService.open(RecommendationModalComponent, {
      width: '600px',
      minWidth: '600px',
      maxHeight: '90vh',
      backdropClass: 'modal-backdrop',
      data: {
        source: source,
        dashboard: dashboard,
      },
    });
    recoModal
      .afterClosed()
      .pipe(take(1), takeUntil(this._destroyed$))
      .subscribe((recommendationCreation: IRecommendationCreation) => {
        this.recommendationsService
          .createUserRecommendation(
            recommendationCreation.targetUser,
            recommendationCreation.sourceId,
            recommendationCreation.customMessage,
            recommendationCreation.dashboardName,
          )
          .subscribe(() => {
            this.alertService.success('Recommendation has been successfully sent');
            this.pushGTMShareDashboardEvent(dashboard.name);
            this.recommendationsService.refreshUserRecommendations();
          });
      });
  }

  pushGTMShareDashboardEvent(dashboardName: string) {
    const searchMode = this.getDCSearchMode();
    this._gtmService.pushEvent({
      event: 'dc_dashboard_shared',
      dc_dashboard_name: dashboardName,
      dc_mode: searchMode,
      dc_share_format: 'recommend',
    });
  }

  getDCSearchMode(): string {
    const storedSearchMode = localStorage.getItem('dc-search-mode');

    return storedSearchMode ? storedSearchMode : 'advanced';
  }
}
