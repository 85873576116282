import { Injectable } from '@angular/core';

const DEFAULT_DURATION_IN_DAYS = 14;
const USER_PREFERENCE_PREFIX = 'user-preferences';

@Injectable({
  providedIn: 'root',
})
export class UserPreferencesService {
  getUserPreferenceValue(userPreferenceName: string): string {
    const cookieNameToFin = `${USER_PREFERENCE_PREFIX}_${userPreferenceName}`;
    const cookies = decodeURIComponent(document.cookie).split(';');

    for (const cookie of cookies) {
      const croppedCookie = cookie.replace(/^ +/, '');

      if (croppedCookie.startsWith(cookieNameToFin)) {
        return croppedCookie.replace(/^.*=/, '');
      }
    }

    return undefined;
  }

  setUserPreference(userPreferenceName: string, preferenceValue: string): void {
    const expirationTimestamp = Date.now() + DEFAULT_DURATION_IN_DAYS * 24 * 60 * 60 * 1000;
    const UTCExpirationDate = new Date(expirationTimestamp).toUTCString();

    document.cookie = `${USER_PREFERENCE_PREFIX}_${userPreferenceName}=${preferenceValue};expires=${UTCExpirationDate};secure;samesite=Strict`;
  }

  removeUserPreference(userPreferenceName: string): void {
    const nullDate = new Date(0).toUTCString();
    document.cookie = `${USER_PREFERENCE_PREFIX}_${userPreferenceName}=;expires=${nullDate};secure;samesite=Strict`;
  }
}
