import type { OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Subject } from 'rxjs';

import { PortalsService } from '../../services/portals.service';

import type { Portal } from '../../entities/portal.entity';

@Component({
  selector: 'adl-portal-select',
  templateUrl: './portal-select.component.html',
})
export class PortalSelectComponent implements OnInit, OnChanges {
  @Output() selected: EventEmitter<Portal> = new EventEmitter();

  @Input() blacklist: string[] = [];
  @Input() label = 'name';
  @Input() value = 'id';
  @Input() placeholder = 'Select a product family';
  @Input() searchText = 'Type to search';
  @Input() activated = true;
  @Input() clear: Subject<void>;

  list: Portal[] = [];
  loading = false;
  searchSubject = new Subject<string>();

  private _list: Portal[] = [];

  constructor(private readonly _portalsService: PortalsService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.blacklist && changes.blacklist.currentValue) {
      this.refreshList();
    }
  }

  ngOnInit(): void {
    this.initList();
  }

  initList(): void {
    this.loading = true;
    this._portalsService.list().subscribe(
      (result) => {
        this._list = result.orgs;
        this.refreshList();
        this.loading = false;
      },
      () => {
        this.loading = false;
      },
    );
  }

  refreshList(query?: string): void {
    this.list = [...this._list];

    if (this.activated) {
      this.list = this.list.filter((a) => a.activated);
    }

    if (this.blacklist.length > 0) {
      this.list = this.list.filter((orga) => !this.blacklist.includes(orga.id));
    }

    if (query) {
      this.list = this.list.filter((portal) => portal.name.toLowerCase().includes(query));
    }
  }

  search($event: string): void {
    const query = $event.toLowerCase();
    this.refreshList(query);
  }

  cleared(): void {
    this.loading = false;
    this.searchSubject.next('');
    this.refreshList();
  }

  changed($event: Portal): void {
    this.selected.emit($event);
  }
}
