import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { EnvironmentService } from '@dataportal/front-environment';
import { AlertService } from '@dataportal/front-shared';
import { Navigator } from '@dataportal/navigator';
import { Dashboard, RecommendationsService, Source } from '@dataportal/sources-dashboards-recommendations';
import type { LimitedUser } from '@dataportal/users';
import { FormControl } from '@ngneat/reactive-forms';

// Types
export interface IRecommendationCreation {
  sourceId: string;
  dashboardName?: string;
  targetUser: string;
  customMessage: string;
}

// Components
@Component({
  selector: 'dpg-catalog-recommendation-modal',
  templateUrl: './catalog-recommendation-modal.component.html',
  styleUrls: ['./catalog-recommendation-modal.component.scss'],
})
export class CatalogRecommendationModalComponent {
  // Attributes
  @Input() source: Source;
  @Input() dashboard: Dashboard;

  limitedSelectedUser: LimitedUser;
  clearSelectList = new Subject<void>();
  customMessageControl = new FormControl<string>('');

  linkCopied = false;
  sentRecommendation = false;

  private _recommendationCreation: IRecommendationCreation;

  // Constructor
  constructor(
    private readonly _activeMatModal: MatDialogRef<CatalogRecommendationModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    data: {
      source: Source;
      dashboard: Dashboard;
    },
    private readonly _recommendationsService: RecommendationsService,
    private readonly _alertService: AlertService,
    private readonly _router: Router,
    private readonly _navigator: Navigator,
    readonly environmentService: EnvironmentService,
  ) {
    this.source = data?.source ? data.source : this.source;
    this.dashboard = data?.dashboard ? data.dashboard : this.dashboard;
  }

  // Methods

  copyLink(): void {
    let url = window.location.href;

    if (this.dashboard) {
      url = '';

      if (!this.dashboard.isExternal) {
        url = window.location.origin;
      }

      url += this.dashboard.buildUrl(this.source.id, this.dashboard.name, this._navigator, this._router, true);
    } else if (this.source) {
      url = window.location.origin + '/sources/' + this.source.id;
    }

    navigator.clipboard.writeText(url).then();
    this.linkCopied = true;
    this._alertService.success('Link successfully copied');
  }

  share(): void {
    this._recommendationCreation = {
      targetUser: this.limitedSelectedUser.id,
      sourceId: this.source.id,
      customMessage: this.customMessageControl.value,
    };

    if (this.dashboard) {
      this._recommendationCreation.dashboardName = this.dashboard.name;
    }

    this._recommendationsService
      .createUserRecommendation(
        this._recommendationCreation.targetUser,
        this._recommendationCreation.sourceId,
        this._recommendationCreation.customMessage,
        this._recommendationCreation.dashboardName,
      )
      .pipe(take(1))
      .subscribe(() => {
        this._alertService.success('Recommendation has been successfully sent');
        this._recommendationsService.refreshUserRecommendations();
      });

    this.sentRecommendation = true;
  }

  closeModal(): void {
    this._activeMatModal.close();
  }
}
