import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'adl-select-checkboxes',
  templateUrl: './select-checkboxes.component.html',
  styleUrls: ['./select-checkboxes.component.scss'],
})
export class SelectCheckboxesComponent<T> {
  @Input() options: { value: T; label: string }[] = [];
  @Input() control: AbstractControl;
  @Input() placeholder = '';
  @Input() isDisabled: boolean;
  @Input() fixedSize: string;
  @Input() noBorder = false;
  @Input() contentSize?: string;
  @Input() choiceLimit: number;
  @Input() defaultValue: T[];
  @Input() selectStyle: 'old' | 'new' = 'old';
  @Input() addAllOption = false;
  @Input() allCheckboxLabel?: string;
  @Input() monoLine = false;
  @Input() hasPaddingRight = true;
  @Output() changed: EventEmitter<T> = new EventEmitter<T>();

  get btnText(): string[] {
    if (this.selectStyle === 'old') {
      if (this.options && this.control && this.control.value?.length) {
        const chosenValueLabel = this.options.find((option) => option.value === this.control?.value[0])?.label;

        if (chosenValueLabel && this.control.value.length > 1) {
          return [
            `${chosenValueLabel}`,
            `+ ${this.control.value.length - 1} ${this.control.value.length > 2 ? 'others' : 'other'}`,
          ];
        } else if (this.defaultValue?.length) {
          return [this.options.find((option) => option.value === this.defaultValue[0])?.label];
        } else if (chosenValueLabel) {
          return this.control.value?.length === 1 ? [chosenValueLabel] : [this.placeholder];
        } else {
          return [this.placeholder];
        }
      }

      return [this.placeholder];
    } else if (this.selectStyle === 'new') {
      const firstValueSelected = (this.control.value as string[]).find((value) => !!value);
      if (!firstValueSelected) return ['No values selected'];

      const selectedValues = (this.control.value as string[]).filter((value) => !!value);

      if (selectedValues) {
        if (selectedValues.length === 2) {
          return [firstValueSelected, '+ 1 other'];
        } else if (selectedValues.length > 2) {
          return [firstValueSelected, '+ ' + (selectedValues.length - 1) + ' others'];
        } else return [firstValueSelected];
      }
    }

    return [''];
  }
}
