<div *ngIf="loading; else content">
  <div [ngSwitch]="loaderType">
    <div *ngSwitchCase="'da'" class="loading-zone">
      <adl-lottie-animation [style.width]="daAnimationSize" [animationData]="daLoaderPath"></adl-lottie-animation>
    </div>
    <div *ngSwitchDefault class="loading-zone">
      <img
        src="assets/dpg/imgs/loading.gif"
        [style]="'width: ' + adlAnimationSize"
        alt="loading"
      />
      <adl-text [italic]="true" [size]="14" *ngIf="loadingText" type="blue">{{ loadingText }}</adl-text>
    </div>
  </div>
</div>
<ng-template #content>
  <ng-content></ng-content>
</ng-template>
