import type { OnInit } from '@angular/core';
import { Component, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, map, startWith, takeUntil } from 'rxjs/operators';

import { ButtonSize } from '../../atoms/button/button.component';

import { IconType, SupportedFaIcon } from '../../atoms/icons';
import type { ISearchFilterContainer } from './search-bar-typing';
import { SearchBarType } from './search-bar-typing';

@Component({
  selector: 'adl-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['search-bar.component.scss'],
})
export class SearchBarComponent implements OnInit {
  @Input() placeholder = 'Type to search';
  @Input() debounceTime = 500;
  @Input() icon: SupportedFaIcon = 'search';
  @Input() iconType: IconType = 'inherit';
  @Input() control = new FormControl<string>('');
  @Input() baseValue: string;
  @Input() searchBarType: SearchBarType = 'classic';
  @Input() searchBarSize = '100%';
  @Input() searchBarHeight: string;
  @Input() hasBorder = true;
  @Input() hasCaret = false;
  // Recent Search and Suggestion
  @Input() recentSearchKey = '';
  @Input() suggestedItems: unknown[];
  @Input() isLoadingItem: boolean;
  // Basic Filter
  private _searchFilterContainerList: ISearchFilterContainer[] | undefined;
  private readonly _searchFilterContainerListChanged$ = new Subject<void>();
  private readonly _someFilterControlValueChanged$ = new BehaviorSubject<void>(undefined);

  readonly filterCount$ = this._someFilterControlValueChanged$.pipe(
    debounceTime(50),
    map(() =>
      this._searchFilterContainerList.reduce(
        (total, filterContainer) => total + filterContainer.filters.filter((filter) => filter.isActive).length,
        0,
      ),
    ),
  );
  readonly hasFiltersApplied$ = this.filterCount$.pipe(map((count) => count > 0));

  @Input()
  get searchFilterContainerList(): ISearchFilterContainer[] {
    return this._searchFilterContainerList;
  }
  set searchFilterContainerList(containers: ISearchFilterContainer[]) {
    // clean up previous subscriptions
    if (this._searchFilterContainerList) {
      this._searchFilterContainerListChanged$.next(undefined);
    }

    this._searchFilterContainerList = containers;

    if (!containers) {
      // no subscription to set up
      return;
    }

    const filters = containers.map((container) => container.filters).flat();

    for (const filter of filters) {
      const control = filter.control;
      control.valueChanges
        .pipe(debounceTime(200), startWith(control.value), takeUntil(this._searchFilterContainerListChanged$))
        .subscribe((controlValue) => {
          if (Array.isArray(controlValue) && Array.isArray(filter.defaultValue)) {
            const isControlDefaultValueNotEmpty = filter.defaultValue.length !== 0;

            if (isControlDefaultValueNotEmpty) {
              filter.isActive = !filter.defaultValue.every((defaultValueItem) =>
                controlValue.includes(defaultValueItem),
              );
            } else {
              const isControlValueNotEmpty = controlValue.length !== 0;
              filter.isActive = isControlValueNotEmpty;
            }
          } else {
            let identifier: undefined | null | string;

            // null indicates that the filter is not active
            if (typeof controlValue === 'string' || controlValue === null) {
              identifier = controlValue;
            } else if (
              // if an object is received, it must comply to the { value: string } type
              // or the filter will not be considered as active (and thus not added in the filter count)
              controlValue instanceof Object &&
              'value' in controlValue &&
              typeof controlValue.value === 'string'
            ) {
              identifier = controlValue.value;
            }

            if (identifier !== undefined) {
              filter.isActive = identifier !== filter.defaultValue;
            }
          }

          this._someFilterControlValueChanged$.next(undefined);
        });
    }
  }

  @Input() filterCardSize: string;
  @Input() basicFilterTitle = 'Filters';
  @Input() filterClearException: string[] = [];
  // Additional Filter
  @Input() isLoadingAdditionalFilter = false;
  @Input() additionalSelectedFilter;
  @Input() additionalFilterList;
  // Search Button
  @Input() hasToDisplaySearchButton = false;
  @Input() isStorageKeyValue = false;
  @Input() buttonSize?: ButtonSize;
  // Dropdown
  @Input() dropdown?: ElementRef;

  @Output() changed = new EventEmitter<string>();
  @Output() searchButtonClicked = new EventEmitter<string>();
  @Output() updatedSelectedAdditionalFilter = new EventEmitter<unknown>();
  @Output() openSuggestionItems = new EventEmitter<string>();

  isAdditionalFilterOpen = false;
  isSuggestionsOpen = false;
  isFilterOpen = false;

  constructor(private readonly _eRef: ElementRef) {}

  @HostListener('document:click', ['$event'])
  clickOut(event) {
    if (!this._eRef.nativeElement.contains(event.target)) {
      this.isSuggestionsOpen = false;
      this.isFilterOpen = false;
      this.isAdditionalFilterOpen = false;
    }
  }

  ngOnInit(): void {
    if (this.baseValue) {
      this.control.setValue(this.baseValue);
    }

    this.control.valueChanges.pipe(debounceTime(this.debounceTime)).subscribe((searchTerms) => {
      this.isAdditionalFilterOpen = false;
      this.isFilterOpen = false;
      this.changed.emit(searchTerms);
    });
  }

  onSearchButtonClick(): void {
    this.searchButtonClicked.emit(this.control.value);
    this.isSuggestionsOpen = false;
    this.isAdditionalFilterOpen = false;
  }

  openFilter($event) {
    this.isFilterOpen = !this.isFilterOpen;
    this.isSuggestionsOpen = false;
    this.isAdditionalFilterOpen = false;
    $event.stopPropagation();
  }

  openAdditionalFilter($event) {
    this.isAdditionalFilterOpen = !this.isAdditionalFilterOpen;
    this.isSuggestionsOpen = false;
    this.isFilterOpen = false;
    $event.stopPropagation();
  }

  inputClicked($event) {
    if (this.recentSearchKey) {
      this.isSuggestionsOpen = true;
      this.isFilterOpen = false;
      this.isAdditionalFilterOpen = false;
    }

    $event.stopPropagation();
  }

  updateAdditionalFilter($event) {
    this.additionalSelectedFilter = $event;
    this.updatedSelectedAdditionalFilter.emit($event);
    this.isAdditionalFilterOpen = false;
  }

  onSuggestionItemsOpened($event) {
    this.openSuggestionItems.emit($event);
  }
}
