import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { ISelectPredicates } from '@dataportal/adl';
import { EnvironmentService } from '@dataportal/front-environment';
import type { User } from '@dataportal/users';
import { LimitedUser } from '@dataportal/users';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'dpg-user-select',
  templateUrl: './user-select.component.html',
  styleUrls: ['./user-select.component.scss'],
})
export class UserSelectComponent {
  @Input() mode: 'graph-api' | 'dataportal';
  @Input() predicates: ISelectPredicates<LimitedUser | User> = {};
  @Input() clear: Subject<void>;
  @Input() placeholder = 'Select a user...';
  @Output() selected = new EventEmitter<LimitedUser>();

  constructor(private readonly _environmentService: EnvironmentService) {
    if (!this.mode) {
      this.mode =
        this._environmentService.options.supportedAuthenticators.length > 1 ||
        this._environmentService.options.supportedAuthenticators[0] !== 'azureAD'
          ? 'dataportal'
          : 'graph-api';
    }
  }

  forwardLegacyUserEvent(selected: User) {
    const limitedUser = new LimitedUser();
    limitedUser.id = selected?.id.toLowerCase();
    limitedUser.name = selected?.displayName;
    limitedUser.jobTitle = selected?.adInformation?.jobTitle;
    this.selected.emit(limitedUser);
  }

  forwardLimitedUserEvent(selected: LimitedUser) {
    this.selected.emit(selected);
  }
}
