import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { BehaviorSubject, Subject, zip } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import type {
  IAPIDatalakeReferential,
  IAPITableReferential,
  IExternalReferential,
  IGuardianAPIChecksMap,
} from '@dataportal/front-api';
import type { FormGroup } from '@ngneat/reactive-forms';
import { FormBuilder } from '@ngneat/reactive-forms';
import { v4 as uuid } from 'uuid';

import { GuardianService } from './guardian.service';

import type { CheckRegex } from '../entities/check-regex';
import type {
  DatalakeColumnType,
  DecimalSeparator,
  ExternalReferential,
  FileDelimiter,
  FileFormat,
  IBannerRecipient,
  IColumnField,
  IGuardianFormData,
  IGuardianStatus,
  IGuardianStepCheckBasicInfos,
  IGuardianStepCheckColumns,
  IGuardianStepCheckTriggers,
  ISupportedGuardianChecksResource,
  RegexReferential,
  SnowflakeColumnType,
} from '../entities/guardian-form';
import { datalakeColumnTypes, SchedulingDailyChoices, snowflakeColumnTypes } from '../entities/guardian-form';

// Service
@Injectable({
  providedIn: 'root',
})
export class GuardianFormService {
  constructor(private readonly _guardianService: GuardianService, private readonly _formBuilder: FormBuilder) {}

  private _guardianForm: FormGroup<IGuardianFormData>;
  private readonly _destroyed$ = new Subject<void>();
  private readonly _formReady$ = new BehaviorSubject<boolean>(false);
  formReady$ = this._formReady$.asObservable();
  preparedRegexValues: Array<{ label: string; value: string }> = [];
  snowflakeReferentialsValues: Array<{ label: string; value: IAPITableReferential }> = [];
  baseLengthToRemove = 2;

  get guardianForm(): FormGroup<IGuardianFormData> {
    return this._guardianForm;
  }

  get guardianFormStepOne(): FormGroup<IGuardianStepCheckBasicInfos> {
    return this._guardianForm?.get<'step1'>(['step1']) as unknown as FormGroup;
  }

  get guardianFormStepTwo(): FormGroup<IGuardianStepCheckColumns> {
    return this._guardianForm?.get<'step2'>(['step2']) as unknown as FormGroup;
  }

  get guardianFormStepThree(): FormGroup<IGuardianStepCheckTriggers> {
    return this._guardianForm?.get<'step3'>(['step3']) as unknown as FormGroup;
  }

  getFormByStep(step: number): FormGroup {
    return this._guardianForm?.get(`step${step}`) as unknown as FormGroup;
  }

  initGuardianForm<R extends ISupportedGuardianChecksResource>(
    guardianStatus: IGuardianStatus<R>,
    fieldCheckReferentialMap: {
      [key: string]: { externalReferential: ExternalReferential; regexReferential: RegexReferential };
    },
    resource: R,
  ): void {
    const step1: FormGroup<IGuardianStepCheckBasicInfos> =
      resource === 'datalakePath'
        ? this._basicInfoDatalakePathStepForm(guardianStatus as IGuardianStatus<'datalakePath'>)
        : this._basicInfoSnowflakeStepForm(guardianStatus as IGuardianStatus<'snowflake'>);
    const step2: FormGroup<IGuardianStepCheckColumns> = this._addConstraintStepForm(
      guardianStatus,
      fieldCheckReferentialMap,
    );
    const step3: FormGroup<IGuardianStepCheckTriggers> =
      resource === 'datalakePath'
        ? this._checkInfoDatalakePathStepForm(guardianStatus as IGuardianStatus<'datalakePath'>)
        : this._checkInfoSnowflakePathStepForm(guardianStatus);
    this._guardianForm = this._formBuilder.group({ step1, step2, step3 });
  }

  initGuardianModal<R extends ISupportedGuardianChecksResource>(
    guardianStatus: IGuardianStatus<R>,
    defaultPreparedRegexValues: Array<{ label: string; value: string }>,
    resource: R,
  ): void {
    this._formReady$.next(false);
    zip(this._guardianService.listAllCheckRegex(), this._guardianService.listExternalReferentials())
      .pipe(
        takeUntil(this._destroyed$),
        catchError(() => {
          return [[], []] as [CheckRegex[], IExternalReferential[]];
        }),
      )
      .subscribe((result: [CheckRegex[], IExternalReferential[]]) => {
        const fieldCheckRegexList = (result[0] ? result[0] : []) as CheckRegex[];
        const externalReferentialsList = (result[1] ? result[1] : []) as IExternalReferential[];
        this.preparedRegexValues = fieldCheckRegexList.length
          ? fieldCheckRegexList.map((checkRegex) => ({
              label: checkRegex.label,
              value: `/${checkRegex.regex}/`,
            }))
          : defaultPreparedRegexValues;

        // TODO: [Revisit] (18.01.2024) - Fixed types
        if (externalReferentialsList && externalReferentialsList.length) {
          this.snowflakeReferentialsValues = externalReferentialsList
            .filter((datalakeRefential) => datalakeRefential.category === 'mdh')
            .map((mdhReferential) => ({
              label: mdhReferential.label,
              value: (mdhReferential.infos as IAPITableReferential).sql_engine
                ? (mdhReferential.infos as IAPITableReferential)
                : ({} as IAPITableReferential),
            }));
        } else {
          this.snowflakeReferentialsValues = [];
        }

        const fieldCheckReferentialMap: {
          [key: string]: { externalReferential: ExternalReferential; regexReferential: RegexReferential };
        } = { externalReferential: null, regexReferential: null };
        Object.keys(guardianStatus?.checkInfos?.validation_schema_columns || []).forEach((key) => {
          const fieldCheckSchema = guardianStatus.checkInfos.validation_schema_columns[key];
          const isSnowflakeReferential = GuardianService.hasSnowflakeReferential(
            {
              ...fieldCheckSchema,
              column_name: null,
            },
            true,
          );
          const isDatalakeWhitelistedReferential = GuardianService.hasDatalakeReferential(
            {
              ...fieldCheckSchema,
              column_name: null,
            },
            true,
          );
          const isDatalakeBlacklistedReferential = GuardianService.hasDatalakeReferential(
            {
              ...fieldCheckSchema,
              column_name: null,
            },
            false,
          );
          const isRegex = !!fieldCheckSchema.regex?.length;
          const isPreparedRegex =
            isRegex && fieldCheckRegexList.some((checkRegex) => checkRegex.regex === fieldCheckSchema.regex);
          fieldCheckReferentialMap[key] = { externalReferential: null, regexReferential: null };

          if (isSnowflakeReferential || isDatalakeWhitelistedReferential || isDatalakeBlacklistedReferential) {
            fieldCheckReferentialMap[key].externalReferential = isSnowflakeReferential ? 'mdh' : 'datalake';
          }

          if (isRegex) {
            fieldCheckReferentialMap[key].regexReferential = isPreparedRegex ? 'prepared-regex' : 'manual-regex';
          }
        });
        this.initGuardianForm(guardianStatus, fieldCheckReferentialMap, resource);
        this._formReady$.next(true);
      });
  }

  correctlyAlertRecipientsFromGuardianAPI<R extends ISupportedGuardianChecksResource>(
    checkInfos: IGuardianAPIChecksMap[R],
  ): IBannerRecipient[] {
    const emailsIfSuccessArray = GuardianService.convertGuardianStringArrayToArray(checkInfos?.email_if_ok);
    const _emailsIfNotOkArray = GuardianService.convertGuardianStringArrayToArray(checkInfos?.email_if_not_ok);
    const _emailsIfExceptionArray = GuardianService.convertGuardianStringArrayToArray(checkInfos?.email_if_exception);
    const emailsIfFailureArray = [
      ..._emailsIfNotOkArray,
      ..._emailsIfExceptionArray.filter((emailIfException) => !_emailsIfNotOkArray.includes(emailIfException)),
    ];
    const allEmails = [
      ...emailsIfSuccessArray,
      ...emailsIfFailureArray.filter((emailIfFailure) => !emailsIfSuccessArray.includes(emailIfFailure)),
    ];

    return allEmails.map((email) => ({
      userId: email,
      isAlertedOnSuccess: emailsIfSuccessArray.includes(email),
      isAlertedOnFailure: emailsIfFailureArray.includes(email),
    }));
  }

  correctlyFormatOnMovingPathFromGuardianAPI(
    checkInfos: IGuardianAPIChecksMap['datalakePath'],
    pathOnMoving: string,
  ): string {
    // parsing path on moving array from Guardian API
    const pathOnMovingArray = GuardianService.convertGuardianStringArrayToArray(pathOnMoving);
    const parsedPathOnMoving = pathOnMovingArray?.length ? pathOnMovingArray[0] : '';
    // removing potential file renaming part from path
    const cleanedPathOnMoving = parsedPathOnMoving.replace(/^\//, '').replace(/\/$/, '');
    const lengthToRemove =
      this.baseLengthToRemove +
      (this._guardianService?.fileNameRenamingFormat?.length || 0) +
      (checkInfos?.file_format.length || 0);
    const cleanedPathOnMovingLength = cleanedPathOnMoving?.length || 0;
    const cleanedPathOnMovingLastIndexToKeep = cleanedPathOnMovingLength - lengthToRemove;
    const cleanedPathOnMovingWithoutFileRenamingPart = cleanedPathOnMoving?.length
      ? cleanedPathOnMoving.includes(this._guardianService.fileNameRenamingFormat)
        ? cleanedPathOnMoving.substring(0, cleanedPathOnMovingLastIndexToKeep)
        : cleanedPathOnMoving
      : '';
    // adding bucket name to path
    const fetchedBucketNameSegments = checkInfos.bucket_name.split('-');

    if (fetchedBucketNameSegments.length > 1) {
      fetchedBucketNameSegments.shift();
    }

    const dpFormattedBucketName = fetchedBucketNameSegments.join('-');

    return `${dpFormattedBucketName}/${cleanedPathOnMovingWithoutFileRenamingPart}`;
  }

  private _basicInfoSnowflakeStepForm(
    guardianStatus: IGuardianStatus<'snowflake'>,
  ): FormGroup<IGuardianStepCheckBasicInfos> {
    return this._formBuilder.group({
      name: [guardianStatus?.checkInfos?.name || ''],
      alertRecipients: this._formBuilder.array(
        this.correctlyAlertRecipientsFromGuardianAPI<'snowflake'>(guardianStatus?.checkInfos),
      ),
    });
  }

  updateRegexValueForConstraint(): void {
    this.guardianFormStepTwo.value.columnsToCheck.map((field) => {
      field.regex = field.addedConstraints.includes('regex')
        ? field.regexReferential === 'manual-regex'
          ? field.manualRegex
          : field.regexReferential === 'prepared-regex'
          ? field.preparedRegex
          : null
        : null;
    });
  }

  private _basicInfoDatalakePathStepForm(
    guardianStatus: IGuardianStatus<'datalakePath'>,
  ): FormGroup<IGuardianStepCheckBasicInfos> {
    return this._formBuilder.group(
      {
        name: [guardianStatus?.checkInfos?.name || ''],
        checkType: ['file-content', [Validators.required]],
        fileFormat: [(guardianStatus?.checkInfos?.file_format as FileFormat) || 'xlsx', [Validators.required]],
        isFilteringFilesForCheck: [!!guardianStatus?.checkInfos?.regex?.length],
        filteringRegex: [
          (guardianStatus?.checkInfos?.regex?.length ? `/${guardianStatus.checkInfos.regex}/` : null) || '',
        ],
        isFlatfileDeactivate: [!!guardianStatus?.isFlatfileDeactivate || false],
        alertRecipients: this._formBuilder.array(
          this.correctlyAlertRecipientsFromGuardianAPI<'datalakePath'>(guardianStatus?.checkInfos),
        ),
        fileDelimiter: [(guardianStatus?.checkInfos?.delimiter as FileDelimiter) || ';'],
        decimalSeparator: [(guardianStatus?.checkInfos?.decimal as DecimalSeparator) || '.'],
        sheetName: [guardianStatus?.checkInfos?.sheet_name || ''],
      },
      {
        validators: (group: FormGroup<IGuardianStepCheckBasicInfos>) => {
          let requirements = {};
          const isFilteringFilesForCheck = group.get('isFilteringFilesForCheck').value;
          const filteringRegex = group.get('filteringRegex').value;

          if (isFilteringFilesForCheck === true) {
            if (!filteringRegex?.length) {
              requirements = { ...requirements, regexRequired: true };
            } else if (this._guardianService.convertInputRegexToRegex(filteringRegex.toString()) === null) {
              requirements = { ...requirements, invalidRegex: true };
            }
          }

          const fileFormat = group.get('fileFormat')?.value;
          const sheetName = group.get('sheetName')?.value;
          const fileDelimiter = group.get('fileDelimiter')?.value;
          const decimalSeparator = group.get('decimalSeparator')?.value;

          switch (fileFormat) {
            case 'csv':
              if (fileDelimiter === decimalSeparator) {
                requirements = { ...requirements, fileDelimiterAndDecimalSeparatorMustBeDifferent: true };
              }

              break;
            case 'xlsx':
              if (!sheetName?.length) {
                requirements = { ...requirements, sheetNameRequired: true };
              }

              break;
            case 'json':
            default:
              break;
          }

          return Object.keys(requirements).length > 0 ? requirements : null;
        },
      },
    );
  }

  private _addConstraintStepForm(
    guardianStatus: IGuardianStatus,
    fieldCheckReferentialMap: {
      [key: string]: { externalReferential: ExternalReferential; regexReferential: RegexReferential };
    },
  ): FormGroup<IGuardianStepCheckColumns> {
    const orderedValidationSchemaColumnsArray = guardianStatus?.checkInfos?.validation_schema_columns
      ? GuardianService.getOrderedValidationSchemaColumnsArray(guardianStatus.checkInfos.validation_schema_columns)
      : [];

    const mappingBetweenColumnNameAndPreparedId = new Map<string, string>();
    orderedValidationSchemaColumnsArray.forEach((fieldCheckSchema) =>
      mappingBetweenColumnNameAndPreparedId.set(fieldCheckSchema.column_name, uuid()),
    );

    return this._formBuilder.group({
      columnsToCheck: this._formBuilder.array(
        orderedValidationSchemaColumnsArray?.length
          ? [
              ...orderedValidationSchemaColumnsArray.map((fieldCheckSchema) => {
                const key = fieldCheckSchema.column_name;
                const type = fieldCheckSchema?.type?.length
                  ? (fieldCheckSchema.type as DatalakeColumnType)
                  : guardianStatus?.type === 'datalakePath'
                  ? (datalakeColumnTypes[0].value as DatalakeColumnType)
                  : (snowflakeColumnTypes[0].value as SnowflakeColumnType);
                const formattedRegex = fieldCheckSchema.regex?.length ? `/${fieldCheckSchema.regex}/` : '';
                const addedConstraints =
                  GuardianService.getAddedConstraintsFromCurrentFieldCheckSchema(fieldCheckSchema);

                return this._formBuilder.group({
                  columnId: mappingBetweenColumnNameAndPreparedId.get(fieldCheckSchema.column_name),
                  columnName: key,
                  type: type,
                  isRequired: fieldCheckSchema?.required_values || false,
                  isUnique: fieldCheckSchema?.unique || false,
                  minValue: fieldCheckSchema?.min,
                  maxValue: fieldCheckSchema?.max,
                  greaterThanColumnId: fieldCheckSchema?.pair_greater
                    ? mappingBetweenColumnNameAndPreparedId.get(fieldCheckSchema.pair_greater)
                    : null,
                  greaterThanColumnName: fieldCheckSchema?.pair_greater,
                  regexReferential: fieldCheckReferentialMap[key].regexReferential,
                  regex:
                    fieldCheckReferentialMap[key].regexReferential === 'manual-regex' ||
                    fieldCheckReferentialMap[key].regexReferential === 'prepared-regex'
                      ? formattedRegex
                      : null,
                  manualRegex:
                    fieldCheckReferentialMap[key].regexReferential === 'manual-regex' ? formattedRegex : null,
                  preparedRegex:
                    fieldCheckReferentialMap[key].regexReferential === 'prepared-regex' ? formattedRegex : null,
                  snowflakeExternalReferential:
                    fieldCheckReferentialMap[key].externalReferential === 'mdh'
                      ? (fieldCheckSchema.values_in as IAPITableReferential)
                      : null,
                  datalakeInExternalReferential:
                    fieldCheckReferentialMap[key].externalReferential === 'datalake'
                      ? (fieldCheckSchema.values_in as IAPIDatalakeReferential)
                      : null,
                  datalakeNotInExternalReferential:
                    fieldCheckReferentialMap[key].externalReferential === 'datalake'
                      ? (fieldCheckSchema.values_not_in as IAPIDatalakeReferential)
                      : null,
                  datalakePathIn: null,
                  datalakePathNotIn: null,
                  addedConstraints: [addedConstraints],
                  availableConstraints: [
                    GuardianService.getAvailableConstraintsFromAddedConstraints(addedConstraints, type),
                  ],
                }) as FormGroup<IColumnField>;
              }),
            ]
          : [],
      ),
    });
  }

  private _checkInfoDatalakePathStepForm(
    guardianStatus: IGuardianStatus<'datalakePath'>,
  ): FormGroup<IGuardianStepCheckTriggers> {
    const schedulingInfos = GuardianService.convertToSchedulingInfos(
      guardianStatus?.checkInfos?.dag_schedule_interval || null,
    );
    const targetFolderIfOk = guardianStatus?.checkInfos?.target_folder_if_ok;
    const targetFolderIfError = guardianStatus?.checkInfos?.target_folder_if_error;

    return this._formBuilder.group(
      {
        hasToUseItrack: [!!guardianStatus?.isItrackEnabled],
        hasToMoveIfSuccess: [!!targetFolderIfOk?.length || false],
        successPath: [
          targetFolderIfOk
            ? this.correctlyFormatOnMovingPathFromGuardianAPI(guardianStatus?.checkInfos, targetFolderIfOk)
            : '',
        ],
        hasToMoveIfFailure: [!!targetFolderIfError?.length || false],
        failurePath: [
          targetFolderIfError
            ? this.correctlyFormatOnMovingPathFromGuardianAPI(guardianStatus?.checkInfos, targetFolderIfError)
            : '',
        ],
        hasToEnforceReplacementIfSameName: [!!guardianStatus?.checkInfos?.enforce_replacement],
        hasToRenameDestinationFile: [
          guardianStatus?.isItrackEnabled ||
            targetFolderIfOk?.includes(this._guardianService.fileNameRenamingFormat) ||
            targetFolderIfError?.includes(this._guardianService.fileNameRenamingFormat),
        ],
        hasToGenerateResultFile: [!!guardianStatus?.checkInfos?.write_result || false],
        hasToScheduleChecks: [!!schedulingInfos],
        schedulingInfos: this._formBuilder.group({
          schedulingPeriodicity: [schedulingInfos?.schedulingPeriodicity || 'daily'],
          schedulingStartingTime: this._formBuilder.group({
            schedulingStartingHour: [schedulingInfos?.schedulingStartingTime?.schedulingStartingHour || '1'],
            schedulingStartingMinute: [schedulingInfos?.schedulingStartingTime?.schedulingStartingMinute || 0],
            schedulingStartingHourPeriod: [
              schedulingInfos?.schedulingStartingTime?.schedulingStartingHourPeriod || 'pm',
            ],
          }),
          schedulingDailyChoiceSelected: [
            schedulingInfos?.schedulingDailyChoiceSelected || SchedulingDailyChoices.EVERY_N_DAYS,
          ],
          schedulingDailyEveryNumberOfDays: [schedulingInfos?.schedulingDailyEveryNumberOfDays || null],
          schedulingWeeklySelectedDays: this._formBuilder.array(schedulingInfos?.schedulingWeeklySelectedDays || []),
          schedulingMonthlyDayNumber: [schedulingInfos?.schedulingMonthlyDayNumber || null],
          schedulingMonthlyEveryNumberOfMonths: [schedulingInfos?.schedulingMonthlyEveryNumberOfMonths || '1'],
          schedulingYearlyDayNumber: [schedulingInfos?.schedulingYearlyDayNumber || null],
          schedulingYearlySelectedMonth: [schedulingInfos?.schedulingYearlySelectedMonth || 'jan'],
        }),
      },
      {
        validators: (group: FormGroup<IGuardianStepCheckTriggers>) => {
          let requirements = {};
          const hasToMoveIfSuccess = group.get('hasToMoveIfSuccess').value;
          const successPath = group.get('successPath')?.value;

          if (hasToMoveIfSuccess && !successPath?.length) {
            requirements = { ...requirements, successPathRequired: true };
          }

          const hasToMoveIfFailure = group.get('hasToMoveIfFailure').value;
          const failurePath = group.get('failurePath')?.value;

          if (hasToMoveIfFailure && !failurePath?.length) {
            requirements = { ...requirements, failurePathRequired: true };
          }

          return Object.keys(requirements).length > 0 ? requirements : null;
        },
      },
    );
  }

  private _checkInfoSnowflakePathStepForm(guardianStatus: IGuardianStatus): FormGroup<IGuardianStepCheckTriggers> {
    const schedulingInfos = GuardianService.convertToSchedulingInfos(
      guardianStatus?.checkInfos?.dag_schedule_interval || null,
    );

    return this._formBuilder.group({
      hasToScheduleChecks: [!!schedulingInfos],
      schedulingInfos: this._formBuilder.group({
        schedulingPeriodicity: [schedulingInfos?.schedulingPeriodicity || 'daily'],
        schedulingStartingTime: this._formBuilder.group({
          schedulingStartingHour: [schedulingInfos?.schedulingStartingTime?.schedulingStartingHour || '1'],
          schedulingStartingMinute: [schedulingInfos?.schedulingStartingTime?.schedulingStartingMinute || 0],
          schedulingStartingHourPeriod: [schedulingInfos?.schedulingStartingTime?.schedulingStartingHourPeriod || 'pm'],
        }),
        schedulingDailyChoiceSelected: [
          schedulingInfos?.schedulingDailyChoiceSelected || SchedulingDailyChoices.EVERY_N_DAYS,
        ],
        schedulingDailyEveryNumberOfDays: [schedulingInfos?.schedulingDailyEveryNumberOfDays || null],
        schedulingWeeklySelectedDays: this._formBuilder.array(schedulingInfos?.schedulingWeeklySelectedDays || []),
        schedulingMonthlyDayNumber: [schedulingInfos?.schedulingMonthlyDayNumber || null],
        schedulingMonthlyEveryNumberOfMonths: [schedulingInfos?.schedulingMonthlyEveryNumberOfMonths || '1'],
        schedulingYearlyDayNumber: [schedulingInfos?.schedulingYearlyDayNumber || null],
        schedulingYearlySelectedMonth: [schedulingInfos?.schedulingYearlySelectedMonth || 'jan'],
      }),
    });
  }
}
