import type { OnInit } from '@angular/core';
import { Component } from '@angular/core';

import { SidebarService } from './sidebar.service';

@Component({
  selector: 'adl-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  isActivated = true;

  constructor(private readonly _sidebarService: SidebarService) {}

  ngOnInit(): void {
    this._sidebarService.isVisible$.subscribe((isVisible) => {
      this.isActivated = isVisible;
    });
  }

  toggleVisiblity() {
    this._sidebarService.toggleVisibility();
  }
}
