<adl-card class="h-fit absolute z-[2]" [ngStyle]="{ width: cardSize }">
  <adl-icon *ngIf="hasCaret" icon="caret-up" class="search-caret-up absolute"></adl-icon>

  <div class="text-[12px]">
    <div class="flex items-center">
      <span class="clear-btn c-pointer hover:underline" (click)="resetFilters()">Clear filter</span>
    </div>
  </div>
  <div class="filter-container-card w-full">
    <div class="ml-[20px] filter-container" *ngFor="let filterContainer of filterContainers">
      <adl-text [bold]="true" size="16" color="blue">{{ filterContainer.title }}</adl-text>
      <div class="basic-filter-separator mt-[10px] mb-[20px]"></div>
      <ng-container *ngFor="let filter of filterContainer.filters">
        <ng-container [ngSwitch]="filter.type">
          <adl-select-v2
            *ngSwitchCase="'select'"
            class="block width-[217px] pb-6"
            [bindLabel]="filter.bindLabel"
            [bindValue]="filter.bindValue"
            [items]="filter.options"
            [placeholder]="filter.placeholder"
            [isDisabled]="filter.isDisabled"
            [value]="filter.control.value"
            (change)="filter.control.setValue($event)"
            [closeOnSelect]="false"
          >
          </adl-select-v2>

          <adl-checkboxes-filter *ngSwitchCase="'checkbox'" [filter]="filter"></adl-checkboxes-filter>

          <adl-select-checkboxes
            *ngSwitchCase="'multiselect'"
            selectStyle="new"
            contentSize="250px"
            [addAllOption]="filter.showAllCheckbox"
            [allCheckboxLabel]="filter.allCheckboxLabel"
            [options]="filter.options"
            [control]="filter.control"
            [placeholder]="filter.placeholder"
            [monoLine]="true"
            [hasPaddingRight]="false"
          >
          </adl-select-checkboxes>
        </ng-container>
      </ng-container>
    </div>
  </div>
</adl-card>
