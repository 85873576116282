import type { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import type { BehaviorSubject } from 'rxjs';
import type { Editor } from 'tinymce';

/* TODO: use async validator instead when our forms properly support async validators
https://www.tiny.cloud/blog/angular-rich-text-editor/ */
export const minLength = (editorSubject: BehaviorSubject<Editor | null>, characterLimit: number): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const editor = editorSubject.getValue();
    const trueMessageLength = (control.value as string).length;

    if (!editor) {
      return trueMessageLength >= characterLimit
        ? null
        : {
            minLength: {
              requiredLength: characterLimit,
              actualLength: trueMessageLength,
            },
          };
    }

    const characterCount = editor.plugins.wordcount.body.getCharacterCount();

    if (characterCount === 0) {
      // Editor has not counted the message yet (probably)
      return trueMessageLength >= characterLimit
        ? null
        : {
            minLength: {
              requiredLength: characterLimit,
              actualLength: trueMessageLength,
            },
          };
    }

    return characterCount >= characterLimit
      ? null
      : {
          minLength: {
            requiredLength: characterLimit,
            actualLength: characterCount,
          },
        };
  };
};
