import type { OnInit } from '@angular/core';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import type { ISelectPredicates } from '@dataportal/adl';
import type { LimitedUser } from '@dataportal/users';

import type { UserPermission } from '../../entities/user-permission';
import type { UserPermissionV2 } from '../../entities/user-permission-v2';
import type { DPGRole } from '../../roles-data';
import { getRoleData, ROLES } from '../../roles-data';

// Types
export interface IFormattedUserRole<R extends DPGRole = DPGRole> {
  email: string;
  role: R;
  name?: string;
  fromGroup?: string;
  groupName?: string;
}

// Utils
export function formatUsersRoles<R extends DPGRole = DPGRole>(userRole: UserPermission): IFormattedUserRole<R>[] {
  const formatted: IFormattedUserRole<R>[] = [];

  for (const actualRole of userRole.userRoles) {
    formatted.push({
      email: userRole.userId,
      role: actualRole as R,
    });
  }

  for (const groupId of Object.keys(userRole.groupRoles)) {
    const groupRoles = userRole.groupRoles[groupId];

    for (const actualRole of groupRoles.roles) {
      formatted.push({
        email: userRole.userId,
        role: actualRole as R,
        fromGroup: groupRoles.group_name,
      });
    }
  }

  return formatted;
}

export function formatUsersRolesV2<R extends DPGRole = DPGRole>(userRole: UserPermissionV2): IFormattedUserRole<R>[] {
  return userRole.userRoles.map((actualRole) => ({
    email: userRole.userId,
    name: userRole.userName,
    role: actualRole as R,
    ...(userRole.fromGroupInfo && {
      fromGroup: userRole.fromGroupInfo.groupId,
      groupName: userRole.fromGroupInfo.groupName,
    }),
  }));
}

// Component
@Component({
  selector: 'dpg-add-user-role-modal',
  templateUrl: './add-user-role-modal.component.html',
  styleUrls: ['./add-user-role-modal.component.scss'],
})
export class AddUserRoleModalComponent implements OnInit {
  usersPermissionsTableHeaders = ['Name', 'Role'];
  // Attributes
  defaultRole: DPGRole = 'dashboardReader';

  selectedLimitedUser: LimitedUser;
  addedUsersRoles: IFormattedUserRole[] = [];
  limitedUserPredicates: ISelectPredicates<LimitedUser>;

  clearSelectList: Subject<void> = new Subject<void>();

  private _roles = ROLES;
  choices: { label: string; value: DPGRole }[] = [];

  // Constructor
  constructor(
    private readonly _activeMatModal: MatDialogRef<AddUserRoleModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    data: {
      defaultRole: DPGRole;
      roles: DPGRole[];
    },
  ) {
    this.defaultRole = data?.defaultRole?.length ? data.defaultRole : this.defaultRole;
    this.roles = data?.roles?.length ? data.roles : this.roles;
  }

  // Lifecycle
  ngOnInit(): void {
    this._setPredicates();
  }

  // Methods
  private _setPredicates(): void {
    const isNotAlreadySelected = (limitedUser: LimitedUser): boolean => {
      return !this.addedUsersRoles.find((addedUserRole) => addedUserRole.email === limitedUser.id);
    };

    this.limitedUserPredicates = { isNotAlreadySelected };
  }

  removeUserFromList(userId: string): void {
    this.addedUsersRoles = this.addedUsersRoles.filter((addedUserRole) => addedUserRole.email !== userId);
  }

  addUserToList(): void {
    if (!this.selectedLimitedUser) return;

    this.addedUsersRoles.push({
      email: this.selectedLimitedUser.id,
      role: this.defaultRole,
    });
    this.clearSelectList.next();
  }

  updateEachUserRole(newRole: DPGRole): void {
    this.addedUsersRoles.forEach((addedUserRole) => (addedUserRole.role = newRole));
  }

  updateUserRole(userId: string, newRole: DPGRole): void {
    this.addedUsersRoles.find((userRole) => userRole.email === userId).role = newRole;
  }

  cancel(): void {
    this._activeMatModal.close();
  }

  confirm(): void {
    this._activeMatModal.close(this.addedUsersRoles);
  }

  // Properties
  get roles(): DPGRole[] {
    return this._roles;
  }

  set roles(roles: DPGRole[]) {
    this._roles = roles;
    this.choices = getRoleData(...roles).map((d) => ({ label: d.label, value: d.role }));
  }
}
