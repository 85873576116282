import { Injectable } from '@angular/core';
import type { MatDialogRef } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import type { IAccessRequestResource, ISupportedARResource } from '@dataportal/access-requests';
import type { IAthenaResponse } from '@dataportal/api-types';
import { LocalStorageService } from '@dataportal/front-shared';
import type { Dashboard, Source } from '@dataportal/sources-dashboards-recommendations';

import { CatalogV2SearchService } from './search.service';

import { CatalogAdvancedFiltersModalComponent } from '../../components/v2/modals/catalog-advanced-filters-modal/catalog-advanced-filters-modal.component';
import { CatalogRecommendationModalComponent } from '../../components/v2/modals/catalog-recommendation-modal/catalog-recommendation-modal.component';
import { CatalogRequestAccessModalComponent } from '../../components/v2/modals/catalog-request-access-modal/catalog-request-access-modal.component';
import { CatalogSnowflakePreviewModalComponent } from '../../components/v2/modals/catalog-snowflake-preview-modal/catalog-snowflake-preview-modal.component';

import { RECENTLY_SEARCHED, SEARCH_MODE_USED_BEFORE_NEW_TAB } from './catalog.constants';

@Injectable()
export class CatalogV2NavigationService {
  private _searchMode: 'simple' | 'advanced';

  constructor(
    private readonly _router: Router,
    private readonly _matModalService: MatDialog,
    private readonly _localStorageService: LocalStorageService,
    private readonly _searchService: CatalogV2SearchService,
  ) {
    this._searchService.searchMode$.subscribe((searchMode) => {
      this._searchMode = searchMode;
    });
  }

  private _saveSourceToRecentSearch(source: Source) {
    // this is used to store recently searched data assets to local storage (3 max)
    const recentSearchString = localStorage.getItem(RECENTLY_SEARCHED);

    if (recentSearchString) {
      const recentSearches: string[] = JSON.parse(recentSearchString);

      // check if the source is already in recent search string
      if (!recentSearches.includes(source.id)) {
        if (recentSearches.length > 4) {
          recentSearches.unshift(source.id);
          recentSearches.pop();
        } else {
          recentSearches.unshift(source.id);
        }
      } else {
        // if the source id is already in array, delete & move it to top
        recentSearches.splice(recentSearches.indexOf(source.id), 1);
        recentSearches.unshift(source.id);
      }

      localStorage.setItem(RECENTLY_SEARCHED, JSON.stringify(recentSearches));
    } else {
      localStorage.setItem(RECENTLY_SEARCHED, JSON.stringify([source.id]));
    }
  }

  openDataAssetDetails(
    source: Source,
    activeTab = '',
    mustOpenInNewTab = true,
    queryParams?: { [key: string]: string },
  ) {
    // Source is only added to recent search when a user clicks on it
    this._saveSourceToRecentSearch(source);

    // We set the active tab to user local storage to load it later and open the data asset on the desired tab
    if (activeTab == '') {
      this._localStorageService.removeItem('dc-active-source-tab');
    } else {
      this._localStorageService.setItem('dc-active-source-tab', activeTab);
    }

    localStorage.setItem(SEARCH_MODE_USED_BEFORE_NEW_TAB, this._searchMode);

    // keep the query params when opening a new page
    let queryParamsString = '';

    if (queryParams) {
      queryParamsString = '?';
      queryParamsString += Object.entries(queryParams)
        .map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
        .join('&');
    }

    if (mustOpenInNewTab) {
      window.open(`/sources/${source.id}${queryParamsString}`, '_blank');
    } else {
      this._router.navigate([`/sources/${source.id}${queryParamsString}`]).then();
    }
  }

  // Various functions to open different modals
  openAccessModal(
    kind: ISupportedARResource,
    resource: IAccessRequestResource,
  ): MatDialogRef<CatalogRequestAccessModalComponent> {
    return this._matModalService.open(CatalogRequestAccessModalComponent, {
      width: '60vw',
      maxWidth: '800px',
      maxHeight: '90vh',
      backdropClass: 'modal-backdrop',
      data: {
        resource: resource,
        kind: kind,
      },
    });
  }

  openShareAssetModal(source: Source, dashboard: Dashboard = null): MatDialogRef<CatalogRecommendationModalComponent> {
    return this._matModalService.open(CatalogRecommendationModalComponent, {
      width: '60vw',
      maxWidth: '1000px',
      maxHeight: '90vh',
      backdropClass: 'modal-backdrop',
      data: {
        source,
        dashboard,
      },
    });
  }

  openAdvancedFiltersModal(): MatDialogRef<CatalogAdvancedFiltersModalComponent> {
    return this._matModalService.open(CatalogAdvancedFiltersModalComponent, {
      width: '50vw',
      maxWidth: '700px',
      maxHeight: '90vh',
      backdropClass: 'modal-backdrop',
    });
  }

  openSnowflakePreviewModal(
    title: string,
    tableData: IAthenaResponse,
  ): MatDialogRef<CatalogSnowflakePreviewModalComponent> {
    return this._matModalService.open(CatalogSnowflakePreviewModalComponent, {
      width: '80vw',
      maxHeight: '70vh',
      backdropClass: 'modal-backdrop',
      data: { title: title, tablePreview: tableData },
    });
  }
}
