import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AdlModule } from '@dataportal/adl';
import { ApiModule } from '@dataportal/front-api';
import { SharedModule } from '@dataportal/front-shared';
import { NgSelectModule } from '@ng-select/ng-select';

import { LimitedUsersService } from './services/limited-users.service';
import { UsersService } from './services/users.service';

import { LimitedUserSelectComponent } from './components/limited-user-select/limited-user-select.component';
import { ToggleAdminShortcutComponent } from './components/toggle-admin-shortcut/toggle-admin-shortcut.component';

// Module
// @dynamic
@NgModule({
  imports: [CommonModule, FormsModule, NgSelectModule, AdlModule, ApiModule, SharedModule],
  declarations: [LimitedUserSelectComponent, ToggleAdminShortcutComponent],
  providers: [UsersService, LimitedUsersService],
  exports: [LimitedUserSelectComponent, ToggleAdminShortcutComponent],
})
export class UsersModule {}
