import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'adl-generic-error-page',
  templateUrl: './generic-error-page.component.html',
  styleUrls: ['./generic-error-page.component.scss'],
})
export class GenericErrorPageComponent {
  @Input() title: string;
  @Input() errorLink: string;
  @Input() description: string;
  @Input() imageType: 'not-found' | 'no-permissions' = 'not-found';

  @Input() blueButtonLabel: string;
  @Input() yellowButtonLabel: string;

  @Output() blueButtonClicked: EventEmitter<void> = new EventEmitter();
  @Output() yellowButtonClicked: EventEmitter<void> = new EventEmitter();
}
