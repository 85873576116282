import type { IRecommendedSource, IUserRecommendations } from '@dataportal/types';

export class UserRecommendation {
  constructor(readonly userId: string, readonly source: IRecommendedSource) {}

  static createUserRecommendations(input: IUserRecommendations) {
    return input.recommended_sources
      .filter((source) => source.author_id !== undefined && source.author_id !== null)
      .map((source) => new UserRecommendation(input.user_id, source));
  }
}
