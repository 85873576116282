import { Injectable } from '@angular/core';
import { Logger } from '@dataportal/front-shared';

import { IndexedDbCacheService } from './indexed-db-cache.service';

import type { IBucket } from '../entities/datalake';

/**
 * Manage IndexedDB cache for buckets list
 */
@Injectable()
export class DatalakeBucketsCacheService {
  static readonly key = 'datalake_buckets';

  constructor(private readonly _indexedDb: IndexedDbCacheService, private readonly _logger: Logger) {}

  /**
   * Set buckets list in IndexedDB cache.
   * @param buckets - The buckets list to save
   * @param ttl - Time to live, default to 5 minutes
   */
  async setBucketList(buckets: IBucket[], ttl?: number): Promise<void> {
    try {
      await this._indexedDb.set(DatalakeBucketsCacheService.key, buckets, ttl);
    } catch (e) {
      this._logger.warn('[DatalakeBucketsCacheService] Buckets could not be set in cache', e);
    }
  }

  /**
   * Retrieve buckets list from cache
   */
  async getBucketList(): Promise<Array<IBucket> | null> {
    try {
      return await this._indexedDb.get<Array<IBucket> | null>(DatalakeBucketsCacheService.key);
    } catch (e) {
      this._logger.warn('[DatalakeBucketsCacheService] Buckets could not be read from cache', e);

      return null;
    }
  }
}
