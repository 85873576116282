import type { IFavorite } from '@dataportal/types';
import { Entity } from '@decahedron/entity';

// Entity
export class Favorite extends Entity {
  // Attributes
  userId: string = null;
  sourceId: string = null;
  createdAt: string = null;
  order: number = null;

  // Statics
  static fake(sourceId: string): Favorite {
    const favorite = new Favorite();
    favorite.userId = Math.random().toString();
    favorite.sourceId = sourceId;

    return favorite;
  }

  // Methods
  fromJson(data: IFavorite): Favorite {
    super.fromJson(data);
    this.sourceId = data.pk;
    this.createdAt = data.created_at;
    this.order = data.order;

    return this;
  }

  toJson(): any {
    return {
      sourceId: this.sourceId,
      userId: this.userId,
      created_at: this.createdAt,
      order: this.order,
    };
  }
}
