<div
  class='adl-checkboxes'
  [ngClass]="{ 'column-flex': isColumnFlex, 'j-evenly': isColumnFlex, 'row-flex': !isColumnFlex }"
  [style]="
    (heightForColumnFlex?.length ? 'height: ' + heightForColumnFlex + ';' : '') +
    (marginTop?.length ? 'margin-top: ' + marginTop + ';' : '')
  "
>
  <div class='contents' *ngFor='let checkbox of checkboxes'>
    <adl-checkbox
      class='adl-checkboxes--item'
      [ngStyle]="{
        'padding-top': checkBoxesPaddingTop + 'px',
        'padding-bottom': checkBoxesPaddingBottom + 'px'
      }"
      [checkboxSize]='checkboxSize'
      [checkboxColor]='checkboxColor'
      [checked]='isChecked(checkbox.value)'
      (changed)='onChange(checkbox.value, $event)'
      [disabled]='isDisabled(checkbox.value)'
    >
      <adl-text  [size]='labelSize' [type]='labelType' [bold]='isLabelBold'>{{ checkbox.label }}</adl-text>
    </adl-checkbox>
    <div *ngIf='hasSeparator' class='checkboxes-separator'></div>
  </div>
  <div
    *ngIf='!!otherChoice'
    class='adl-checkboxes--item column-flex j-start h100'
    [ngStyle]="{
      'padding-top': this.checkBoxesPaddingTop + 'px',
      'padding-bottom': this.checkBoxesPaddingBottom + 'px'
    }"
  >
    <adl-checkbox [checkboxSize]='checkboxSize'
                  [checkboxColor]='checkboxColor'
                  [checked]='hasSelectedOther'
                  (changed)='onChangeSelectedOther($event)'>
      <adl-text [size]='labelSize'>{{ otherChoice.label }}</adl-text>
    </adl-checkbox>
    <adl-input
      *ngIf='hasSelectedOther'
      [ngStyle]="{
        'padding-top': this.checkBoxesPaddingTop + 'px',
        'padding-bottom': this.checkBoxesPaddingBottom + 'px'
      }"
      [control]='otherChoiceControl'
      fixedSize='10px'
    ></adl-input>
  </div>
</div>
