<div class="content" *ngIf="showUnauthorize">
  <div class="logo">
    <img src="/assets/dpg/imgs/icons/logo.png" alt="" />
  </div>
  <ng-container>
    You are not authorized to access this application
    <ng-container *ngIf="options.supportLink">
      please <a [href]="options.supportLink">contact the support</a>
    </ng-container>
  </ng-container>
</div>
