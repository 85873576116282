import type { OnDestroy, OnInit } from '@angular/core';
import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import type { Observable } from 'rxjs';
import { forkJoin, of, Subject } from 'rxjs';
import { catchError, first, map, takeUntil } from 'rxjs/operators';
import type { SupportedFaIcon } from '@dataportal/adl';
import { DatalakeObjectExistenceCacheService } from '@dataportal/datalake-and-guardian';
import type { DatalakePath, IDatalakeMetadata, IObjectExistenceResult } from '@dataportal/front-shared';
import { DatalakeService, Logger } from '@dataportal/front-shared';
import { PermissionsService } from '@dataportal/permissions';

import { Source } from '../../entities/source';

// Types
interface IAuthorizedPath {
  path: DatalakePath;
  icon: SupportedFaIcon;
  environment: string;
}

interface IAllowedPath {
  path: string;
  canDownload: boolean;
}

// Component
@Component({
  selector: 'dpg-folder-selector-modal',
  templateUrl: './folder-selector-modal.component.html',
  styleUrls: ['./folder-selector-modal.component.scss'],
})
export class FolderSelectorModalComponent implements OnInit, OnDestroy {
  // Attributes
  @Input() source: Source;

  authorizedPath: IAuthorizedPath[] = [];
  private readonly _datalakePathsWithExistenceStatus: Map<
    string,
    { isLoading: boolean; isExisting: boolean; metadata: IDatalakeMetadata }
  > = new Map<string, { isLoading: boolean; isExisting: boolean; metadata: IDatalakeMetadata }>();

  readonly _destroyed$ = new Subject<void>();

  // Constructor
  constructor(
    private readonly _router: Router,
    private readonly _permissionsService: PermissionsService,
    private readonly _datalakeObjectExistenceCacheService: DatalakeObjectExistenceCacheService,
    private readonly _logger: Logger,
    @Inject(MAT_DIALOG_DATA)
    data: {
      source: Source;
    },
  ) {
    this.source = data?.source ? data.source : this.source;
  }

  // Lifecycle
  ngOnInit(): void {
    this._logger.debug('[FolderSelectorModalComponent] Checking permissions');

    // fetching paths status
    this._fetchPathsExistence();

    const canDownload$: Observable<IAllowedPath>[] = [];

    for (const path of this.source.datalakePath) {
      canDownload$.push(
        this._permissionsService.isAuthorized('explore', 'sources', this.source.id).pipe(
          map((can) => ({
            path: path.path,
            canDownload: can,
          })),
        ),
      );
    }

    forkJoin(canDownload$).subscribe((permissions) => {
      this._logger.debug('[FolderSelectorModalComponent] Permissions retrieved', permissions);

      this.authorizedPath = permissions
        .filter((p) => p.canDownload)
        .map((p) => {
          const path = this.source.datalakePath.find((s) => s.path === p.path);
          const environment = FolderSelectorModalComponent._pathToEnvironmentName(path);
          const icon = FolderSelectorModalComponent._getIcon(environment);

          return { path, environment, icon };
        });
    });
    // Otherwise ask the user to choose an path. (1 path <=> 1 environment)
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('modal-open');
  }

  ngOnDestroy(): void {
    // Fix the ability to scroll after closing a stacked modal (fixed in a later version of angular-bootstrap)
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('modal-open');
  }

  // Methods
  private _initAzurePathsWithExistenceStatus(): void {
    this.source.datalakePath?.forEach((datalakePath) => {
      if (!this._datalakePathsWithExistenceStatus.has(DatalakeService.datalakePathEntityToKey(datalakePath))) {
        this._datalakePathsWithExistenceStatus.set(DatalakeService.datalakePathEntityToKey(datalakePath), {
          isLoading: true,
          isExisting: false,
          metadata: undefined,
        });
      }
    });
  }

  private _fetchPathsExistence(): void {
    this._initAzurePathsWithExistenceStatus();
    forkJoin(
      ...this.source.datalakePath.map((datalakePath) => {
        const datalakePathWithExistenceStatus = this.getDatalakePathExistenceStatus(datalakePath);
        datalakePathWithExistenceStatus.isLoading = true;

        return this._datalakeObjectExistenceCacheService.getExtract(datalakePath).pipe(
          takeUntil(this._destroyed$),
          first(),
          catchError(() => {
            return of(null as IObjectExistenceResult);
          }),
          map((objectExistencyResult) => {
            datalakePathWithExistenceStatus.isExisting = !!objectExistencyResult?.isExisting;
            datalakePathWithExistenceStatus.metadata = objectExistencyResult?.metadata || undefined;
            datalakePathWithExistenceStatus.isLoading = false;
          }),
        );
      }),
    ).subscribe();
  }

  private static _pathToEnvironmentName(datalakePath: DatalakePath) {
    const datalakeName = datalakePath.path.split('/')[0];

    return datalakeName.startsWith('datalake-') ? datalakeName.slice(9) : datalakeName;
  }

  getDatalakePathExistenceStatus(datalakePath: DatalakePath): {
    isLoading: boolean;
    isExisting: boolean;
    metadata: IDatalakeMetadata;
  } {
    return this._datalakePathsWithExistenceStatus.get(DatalakeService.datalakePathEntityToKey(datalakePath));
  }

  openDownloadLink(datalakePath: DatalakePath): void {
    this._logger.debug('[FolderSelectorModalComponent] Request to open path', datalakePath);
    this._logger.debug('[FolderSelectorModalComponent] Extracting bucket from path');

    const matcher = datalakePath.path.match(/^([^/]+)\/(.+)$/);
    const [bucket, path] = matcher != null ? matcher.slice(1) : [datalakePath.path, '/'];

    this._logger.debug('[FolderSelectorModalComponent] Extracted bucket from path');
    const provider = datalakePath.provider;
    const tenant = datalakePath.tenant;

    const url = this._router
      .createUrlTree(['datalake', bucket], {
        queryParams: {
          path: path.replace(/\/$/, ''),
          provider,
          tenant,
        },
      })
      .toString();

    window.open(url, '_blank');
  }

  private static _getIcon(environment: string): SupportedFaIcon {
    switch (environment) {
      case 'landing':
        return 'download';
      case 'raw':
        return 'cog';
      case 'raw-dev':
        return 'dev';
      case 'project-play':
        return 'dev';
      case 'project-staging':
        return 'staging';
      case 'project-production':
        return 'prod';
      case 'business':
        return 'business';
      default:
        return 'download';
    }
  }

  trimEnvironment(path: string) {
    return path.split('/').slice(1).join('/');
  }

  // Properties
  get hasPermissions(): boolean {
    return this.authorizedPath && this.authorizedPath.length > 0;
  }

  getPathName(path: IAuthorizedPath): string {
    return (
      path.environment +
      '/' +
      this.trimEnvironment(path.path.path) +
      (path?.path?.tenant?.length ? ' (' + path.path.tenant + ') ' : '')
    );
  }
}
