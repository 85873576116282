import { Component, Input } from '@angular/core';
import type { FormArray } from '@ngneat/reactive-forms';
import { FormControl } from '@ngneat/reactive-forms';

import type { ISearchFilterContainer } from '../search-bar/search-bar-typing';

@Component({
  selector: 'adl-basic-filter',
  templateUrl: './basic-filter.component.html',
  styleUrls: ['./basic-filter.component.scss'],
})
export class BasicFilterComponent {
  @Input() filterContainers: ISearchFilterContainer[];
  @Input() hasCaret: boolean;
  @Input() cardSize = 'fit-content';
  @Input() clearException: string[] = [];

  resetFilters() {
    this.filterContainers.map((filterContainer) => {
      filterContainer.filters.map((filter) => {
        const filterControl = filter.control as FormArray;

        if (filter.type === 'multiselect') {
          filter.control.reset();

          return;
        }

        if (Array.isArray(filter.defaultValue)) {
          filterControl.value
            .filter((value) => !this.clearException.includes(value))
            .forEach((value) => {
              filterControl.remove(value);
            });
          filter.defaultValue
            .filter((value) => !filterControl.value.includes(value))
            .forEach((value) => {
              filterControl.push(new FormControl(value));
            });
        } else if (filter.type === 'checkbox' && this.clearException) {
          filterControl.value
            .filter((value) => !this.clearException.includes(value))
            .forEach((value) => {
              filterControl.remove(value);
            });
        } else {
          filter.control.reset(filter.defaultValue);
        }

        if (filterControl.value?.length === 0) {
          filter.isActive = false;
        }
      });
    });
  }
}
