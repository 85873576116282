import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { CurrentUserService } from '@dataportal/auth';
import * as CryptoJS from 'crypto-js';

import { AnalyticsModule } from '../analytics.module';

export interface IDataLayerEvent {
  event: string;
  pageUrl: string;
  user_hashed_id: string;
  user_departement: string;
  user_affiliate: string;
}

// Service
@Injectable({
  providedIn: AnalyticsModule,
})
export class GoogleTagManagerService {
  constructor(private readonly _router: Router, private readonly _currentUserService: CurrentUserService) {}

  pushEvent(eventOptions: any) {
    const eventOptionsStringified = {};

    // eslint-disable-next-line guard-for-in
    for (const key in eventOptions) {
      eventOptionsStringified[key] = JSON.stringify(eventOptions[key])?.replace(/^"|"$/g, '');
    }

    this._currentUserService.currentUser$.pipe(first((currentUser) => !!currentUser)).subscribe((currentUser) => {
      const hashedId = CryptoJS.SHA256(currentUser.id).toString();
      const dataLayerEvent: Partial<IDataLayerEvent> = {
        pageUrl: this._router.url,
        user_hashed_id: hashedId,
        user_departement: currentUser.adInformation.department,
        user_affiliate: currentUser.adInformation.companyName,
        ...eventOptionsStringified,
      };

      (window as any)?.dataLayer.push(dataLayerEvent);
    });
  }
}
