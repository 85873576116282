import { Component, Input } from '@angular/core';

@Component({
  selector: 'adl-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent {
  @Input() minBodyHeight: number;
  @Input() maxBodyHeight: number;
  @Input() headColor: string;
  @Input() bodyColor: string;
  @Input() bodyHorizontalPadding: number;
  @Input() bodyPaddingBottom: number;
  @Input() hasTableHeaders = true;
  @Input() hasHoveringAnimation = true;
  @Input() hasBorder = false;
  @Input() hasRoundedBorder = false;
  @Input() hasHeadBodySeparator = true;
  @Input() headBodySeparatorColor = 'dark-grey';
  @Input() hasBodyFullSeparators = false;
  @Input() hasBodyNotFullSeparators = false;
  @Input() title: string;
  @Input() hasBoxShadow = false;
  @Input() layoutFixed = false;
}
