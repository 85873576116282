<adl-modal [hasCloseButton]="false" class="flatfile-upload-progress-bar">
  <div modal-header>
    <img src="assets/dpg/imgs/loading.gif" alt="loading" class="progress-loading" />
  </div>
  <div modal-body class="flex flex-col items-center">
    <adl-text size="16" class="progress-title mb-10">The analysis of your file may take a few minutes. Please be patient.</adl-text>
    <div class="flex flex-row justify-center items-center w-full">
      <div class="progress-bars w-11/12 flex items-center">
        <div class="progress-bars-body" [ngStyle]="{ width: progress + '%' }"></div>
      </div>
      <adl-text class="w-1/12">{{progress}} %</adl-text>
    </div>
  </div>
</adl-modal>
