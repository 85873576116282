<adl-stepper-form-modal
  [title]="modalTitle"
  [secondaryTitle]="datasetName"
  [isLoading]="!(guardianFormService.formReady$ | async)"
  [stepLabels]="stepLabels"
  [canGoNextStep$]="canGoNextStep$"
  [lastStepButton]="lastStepButton"
  [secondaryLastStepButton]="secondaryLastStepButton"
  iconLink="assets/dpg/imgs/icons/guardian-logo.svg"
  (giveCurrentStep)="updateCurrentStep($event)"
  (nextStep)="goNextStep($event)"
  (lastStep)="lastStep($event)">
    <app-guardian-step-check-basic-infos
      modal-step-1
      *ngIf="(guardianFormService.formReady$ | async) && currentStep === 1"
      [formGroup]="guardianFormService.guardianFormStepOne"
      [existingChecksNameInSource]="existingChecksNameInSource"
      [guardianStatus]="guardianStatus"
      [importGuardianSpecFileInGuardianModal]="
                  importGuardianSpecificationsFileInGuardianModal.bind(this)
                "
      [checkType]="dataset.type"
      class="flex-col items-center w-full pl-[10px] pr-[10px]"
    ></app-guardian-step-check-basic-infos>
  <ng-container modal-step-2 *ngIf="guardianFormService.formReady$ | async">
  <app-guardian-step-check-columns
      *ngIf="currentStep === GUARDIAN_STEP_NUMBER_IN_CHECK_COLUMN"
      [formReady$]="guardianFormService.formReady$"
      [formGroup]="guardianFormService.guardianFormStepTwo"
      [dataset]="dataset"
      [sourceId]="sourceId"
      [preparedRegexValues]="guardianFormService.preparedRegexValues"
      [snowflakeReferentialsValues]="guardianFormService.snowflakeReferentialsValues"
      [checkType]="dataset.type"
      class="flex-col items-center w-full pl-[10px] pr-[10px]"
    ></app-guardian-step-check-columns>
  </ng-container>
  <ng-container modal-step-3 *ngIf="guardianFormService.formReady$ | async">
  <app-guardian-step-check-triggers
      *ngIf="currentStep === GUARDIAN_STEP_NUMBER_IN_CHECK_TRIGGER"
      [formReady$]="guardianFormService.formReady$"
      [formGroup]="guardianFormService.guardianFormStepThree"
      [currentPath]="dataset"
      [checkType]="dataset.type"
      class="column-flex items-center w-full pl-[10px] pr-[10px]"
    ></app-guardian-step-check-triggers>
  </ng-container>
</adl-stepper-form-modal>
