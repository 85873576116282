import type { AfterViewInit, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';

import { IconType, SupportedFaIcon } from '../icons';

@Component({
  selector: 'adl-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class InputComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() type = 'text';
  @Input() control: FormControl;
  @Input() defaultValue: string = undefined;
  @Input() placeholder = '';
  @Input() icon: SupportedFaIcon;
  @Input() iconType: IconType = 'inherit';
  @Input() readonly: boolean;
  @Input() isDisabled: boolean;
  @Input() horizontalPadding = '5px';
  @Input() succeeded: boolean;
  @Input() variant: 'light' | 'normal' | 'new' = 'normal';
  @Input() fixedSize: string;
  @Input() iconAsPrefix = false;
  @Input() clickableIcon = false;
  @Input() customContent = false;
  @Input() cursorAtTheStart = false;
  @Input() opacity = 0.2;
  @Input() cursorAtTheEnd = false;
  @Input() step?: string = 'any';
  @Input() fontSize = '100%';
  @Input() min?;
  @Input() max?;
  @Input() hasBorder?: boolean = true;
  @Input() isRounded?: boolean = false;
  @Input() fixedHeight?: string;
  @Input() fixedWidth?: string;
  @Input() value: string;
  @Input() isAutoTrim = false;

  @Output() clickedIcon = new EventEmitter<unknown>();

  @ViewChild('nameInput') nameInput: ElementRef;

  ngOnInit(): void {
    this._setDefaultValue();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.defaultValue?.currentValue !== null && changes.defaultValue?.currentValue !== undefined) {
      this._setDefaultValue();
    }
  }

  ngAfterViewInit(): void {
    if (this.cursorAtTheStart || this.cursorAtTheEnd) {
      const cursorIndex = this.cursorAtTheStart
        ? 0
        : this.cursorAtTheEnd
        ? this.control?.value?.toString()?.length || 0
        : 0;
      this.nameInput.nativeElement.setSelectionRange(cursorIndex, cursorIndex);
      this.nameInput.nativeElement.focus();
    }
  }

  get styles(): Record<string, string | number> {
    const styles: Record<string, string | number> = {};

    if (this.fixedSize) {
      styles.width = this.fixedSize;
    }

    if (this.opacity && this.isDisabled) {
      styles.opacity = this.opacity;
    }

    return styles;
  }

  get hasError(): boolean {
    return (
      this.control &&
      (!this.control.valid || !!Object.keys(this.control.errors || {}).length) &&
      (this.control.dirty || !this.control.pristine)
    );
  }

  private _setDefaultValue(): void {
    if (this.defaultValue !== undefined && (this.control.value === null || this.control.value === undefined)) {
      this.control?.setValue(this.defaultValue);
    }
  }

  onClickIcon(): void {
    this.clickedIcon.emit(this.control.value);
  }

  autoTrim(): void {
    this.control?.setValue(this.control.value.trim());
  }
}
