import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { AdlModule } from '@dataportal/adl';
import { AuthModule } from '@dataportal/auth';
import { DatalakeModule } from '@dataportal/datalake-and-guardian';
import { ApiModule } from '@dataportal/front-api';
import { SharedModule } from '@dataportal/front-shared';
import { GuardianUtilsModule } from '@dataportal/guardian-utils';
import { SourceStatisticsModule } from '@dataportal/source-statistics';

import { SourcesService } from './services/sources.service';

import { FolderSelectorModalComponent } from './components/folder-selector-modal/folder-selector-modal.component';
import { ThumbnailUploadComponent } from './components/thumbnail-upload/thumbnail-upload.component';

// Module
@NgModule({
  declarations: [ThumbnailUploadComponent, FolderSelectorModalComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    ApiModule,
    SharedModule,
    AuthModule,
    SourceStatisticsModule,
    AdlModule,
    DatalakeModule,
    GuardianUtilsModule,
  ],
  providers: [SourcesService, { provide: MAT_DIALOG_DATA, useValue: {} }],
  exports: [ThumbnailUploadComponent, FolderSelectorModalComponent],
})
export class SourcesModule {}
