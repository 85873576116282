import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { BehaviorSubject, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { ApiService } from '@dataportal/front-api';
import { Logger } from '@dataportal/front-shared';
import { EntityBuilder } from '@decahedron/entity';

import { DashboardCommentsCacheService } from './dashboard-comments-cache.service';

import { DashboardComment } from '../entities/dashboard-comment';

@Injectable({
  providedIn: 'root',
})
export class DashboardCommentsService {
  private static readonly BASE_URL = 'v4/dashboards/comments';

  private _pageSize = 0;
  private readonly _dashboardId$ = new BehaviorSubject<string>('');
  private readonly _sourceId$ = new BehaviorSubject<string>('');
  private readonly _isLoadingDashboardComments$ = new BehaviorSubject<boolean>(false);
  private readonly _dashboardComments$ = new BehaviorSubject<DashboardComment[]>([]);

  dashboardId$ = this._dashboardId$.asObservable();
  sourceId$ = this._sourceId$.asObservable();
  isLoadingDashboardComments$ = this._isLoadingDashboardComments$.asObservable();
  dashboardComments$ = this._dashboardComments$.asObservable();

  constructor(
    private readonly _dashboardCommentsCacheService: DashboardCommentsCacheService,
    private readonly _apiService: ApiService,
    private readonly _logger: Logger,
  ) {}

  setPageSize(pageSize: number): void {
    if (pageSize > 0) {
      this._pageSize = pageSize;
    }
  }

  getPaginatedDashboardComments(dashboardCommentsList: DashboardComment[]): DashboardComment[][] {
    if (!dashboardCommentsList?.length) {
      return [];
    }

    if (!this._pageSize) {
      return [dashboardCommentsList];
    }

    const paginatedDashboardComments = [] as DashboardComment[][];
    let isNewPage, currentPageIndex;
    dashboardCommentsList.forEach((dashboardComment, index) => {
      isNewPage = !(index % this._pageSize);

      if (isNewPage) {
        paginatedDashboardComments.push([dashboardComment]);
      } else {
        currentPageIndex = Math.floor(index / this._pageSize);
        paginatedDashboardComments[currentPageIndex].push(dashboardComment);
      }
    });

    return paginatedDashboardComments;
  }

  private _updateCurrentSourceAndDashboard(sourceId: string, dashboardId: string): void {
    this._sourceId$.next(sourceId);
    this._dashboardId$.next(dashboardId);
  }

  refreshDashboardComments(isHardRefresh: boolean): Observable<DashboardComment[]> {
    return (
      isHardRefresh
        ? this.listDashboardComments(this._sourceId$.value, this._dashboardId$.value, isHardRefresh)
        : of(null)
    ).pipe(
      tap(() => {
        this._dashboardCommentsCacheService.callToRefresh(isHardRefresh);
      }),
    );
  }

  private _setLoading(refreshLoading: boolean, isLoading): void {
    if (refreshLoading) {
      this._isLoadingDashboardComments$.next(isLoading);
    }
  }

  listDashboardComments(sourceId: string, dashboardId: string, refreshLoading = true): Observable<DashboardComment[]> {
    this._setLoading(refreshLoading, true);
    const url = `${DashboardCommentsService.BASE_URL}/sources/${sourceId}/dashboards/${dashboardId}`;

    return this._apiService.get<DashboardComment[]>(url).pipe(
      map((results) => {
        const dashboardComments = EntityBuilder.buildMany<DashboardComment>(DashboardComment, results);
        this._updateCurrentSourceAndDashboard(sourceId, dashboardId);
        this._setLoading(refreshLoading, false);
        this._dashboardComments$.next(dashboardComments);

        return dashboardComments;
      }),
      catchError((error: unknown) => {
        this._logger.error(
          `[DASHBOARD_COMMENTS_SERVICE] Error while fetching current dashboard comments for source ${sourceId} and dashboard ${dashboardId} : `,
          error,
        );
        this._updateCurrentSourceAndDashboard(sourceId, dashboardId);
        this._setLoading(refreshLoading, false);
        this._dashboardComments$.next([]);

        return of([]);
      }),
    );
  }

  listDashboardCommentsByContacts(refreshLoading = true): Observable<DashboardComment[]> {
    this._setLoading(refreshLoading, true);
    const url = `${DashboardCommentsService.BASE_URL}/contacts`;

    return this._apiService.get<DashboardComment[]>(url).pipe(
      map((results) => {
        const dashboardComments = EntityBuilder.buildMany<DashboardComment>(DashboardComment, results);
        this._setLoading(refreshLoading, false);
        this._dashboardComments$.next(dashboardComments);

        return dashboardComments;
      }),
      catchError((error: unknown) => {
        this._logger.error(
          `[DASHBOARD_COMMENTS_SERVICE] Error while fetching current user's contacts dashboard comments: `,
          error,
        );
        this._setLoading(refreshLoading, false);
        this._dashboardComments$.next([]);

        return of([]);
      }),
    );
  }

  listDashboardCommentsByreplies(refreshLoading = true): Observable<DashboardComment[]> {
    this._setLoading(refreshLoading, true);
    const url = `${DashboardCommentsService.BASE_URL}/replies`;

    return this._apiService.get<DashboardComment[]>(url).pipe(
      map((results) => {
        const dashboardComments = EntityBuilder.buildMany<DashboardComment>(DashboardComment, results);
        this._setLoading(refreshLoading, false);
        this._dashboardComments$.next(dashboardComments);

        return dashboardComments;
      }),
      catchError((error: unknown) => {
        this._logger.error(
          `[DASHBOARD_COMMENTS_SERVICE] Error while fetching current user's contacts dashboard comments: `,
          error,
        );
        this._setLoading(refreshLoading, false);
        this._dashboardComments$.next([]);

        return of([]);
      }),
    );
  }

  hasSeen(commentId: string, sourceId: string, dashboardId: string): Observable<void> {
    return this._apiService.put(`${DashboardCommentsService.BASE_URL}/has-seen`, {
      comment_id: commentId,
      source_id: sourceId,
      dashboard_id: dashboardId,
    });
  }

  addNewDashboardComment(
    sourceId: string,
    dashboardId: string,
    parentCommentId: string,
    message: string,
  ): Observable<DashboardComment> {
    const url = `${DashboardCommentsService.BASE_URL}/sources/${sourceId}/dashboards/${dashboardId}`;

    return this._apiService
      .post<DashboardComment>(url, JSON.stringify({ parent_id: parentCommentId, message: message }))
      .pipe(
        map((result) => (result ? EntityBuilder.buildOne<DashboardComment>(DashboardComment, result) : null)),
        catchError((error: unknown) => {
          this._logger.error(
            `[DASHBOARD_COMMENTS_SERVICE] Error while adding new dashboard comment for source ${sourceId} and dashboard ${dashboardId} : `,
            error,
          );

          return of(null);
        }),
      );
  }

  updateDashboardComment(
    commentId: string,
    sourceId: string,
    dashboardId: string,
    newMessage: string,
  ): Observable<DashboardComment> {
    const url = `${DashboardCommentsService.BASE_URL}/${commentId}/sources/${sourceId}/dashboards/${dashboardId}`;

    return this._apiService.put<DashboardComment>(url, JSON.stringify({ message: newMessage })).pipe(
      map((result) => (result ? EntityBuilder.buildOne<DashboardComment>(DashboardComment, result) : null)),
      catchError((error: unknown) => {
        this._logger.error(
          `[DASHBOARD_COMMENTS_SERVICE] Error while updating dashboard comment ${commentId} for source ${sourceId} and dashboard ${dashboardId} : `,
          error,
        );

        return of(null);
      }),
    );
  }

  removeDashboardComment(commentId: string, sourceId: string, dashboardId: string): Observable<DashboardComment> {
    const url = `${DashboardCommentsService.BASE_URL}/${commentId}/sources/${sourceId}/dashboards/${dashboardId}`;

    return this._apiService.delete<DashboardComment>(url).pipe(
      map((result) => (result ? EntityBuilder.buildOne<DashboardComment>(DashboardComment, result) : null)),
      catchError((error: unknown) => {
        this._logger.error(
          `[DASHBOARD_COMMENTS_SERVICE] Error while removing dashboard comment ${commentId} for source ${sourceId} and dashboard ${dashboardId} : `,
          error,
        );

        return of(null);
      }),
    );
  }
}
