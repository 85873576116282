<ng-select
  bindLabel="name"
  bindValue="id"
  placeholder="Select a group"
  typeToSearchText="Type to search"
  [items]="list"
  [loading]="loading"
  [virtualScroll]="true"
  (clear)="clear()"
  (change)="setSelectedUser($event)"
  [typeahead]="searchSubject"
  [(ngModel)]="default"
  (scrollToEnd)="fetchMore()"
>
</ng-select>
