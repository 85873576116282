<div class="adl-form-control w-full">
  <div class="flex flex-col justify-start" [ngClass]="computedParentLabelClasses">
    <div class="adl-form-control--label" *ngIf="label" [style]="(labelWidth ? 'width: ' + labelWidth : '')"
      [ngClass]="{ 'self-end': isLabelCentered }">
      <label class="row-flex a-center">
        <adl-text class="tal" [type]="hasError ? 'error' : labelType" [bold]="isLabelBold">
          {{ label }}<sup *ngIf="required" class="text-red-500">*</sup>
          <adl-info-bubble class="ml5" *ngIf="hint" [placement]="placement" [iconType]="hintType">
            {{ hint }}
          </adl-info-bubble>
          <adl-info-bubble *ngIf="!hint && hasCustomHint" class="ml5" [iconType]="hintType" [placement]="placement">
            <ng-content select="[hint]"></ng-content>
          </adl-info-bubble>
        </adl-text>
      </label>
      <div>
        <ng-content select=".right"></ng-content>
      </div>
    </div>
    <div class="adl-form-control--input" [ngStyle]="{ 'width': inputWidth}">
      <ng-content></ng-content>
    </div>
  </div>
  <div class="adl-form-control--errors mt5">
    <ul *ngIf="hasError && messages.length > 0">

      <li *ngFor="let msg of messages" class='flex'>
        <img src='assets/dpg/imgs/icons/error-form-icon.svg' class='flex px-[6px]'
          alt="error icon" />
        <span>{{ msg.text }}</span>
        <adl-info-bubble class="ml5" *ngIf="msg.hint" [placement]="placement">
          {{ msg.hint }}
        </adl-info-bubble>
      </li>
    </ul>
  </div>
  <div class="adl-form-control--loading row-flex mt5" *ngIf="loadingMessage">
    <span>{{ loadingMessage }}</span>
    <img src="assets/dpg/imgs/loading.gif" style="width: 25px" alt="loading" />
  </div>
  <div class="adl-form-control--success mt5" *ngIf="successMessage">
    <span>{{ successMessage }}</span>
  </div>
</div>
