import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'adl-tool-card',
  templateUrl: './tool-card.component.html',
  styleUrls: ['./tool-card.component.scss'],
})
export class ToolCardComponent {
  @Input() title: string;
  @Input() url: string;
  @Input() description: string;
  @Input() hasStar: boolean;
  @Input() isExternalUrl = false;
  @Output() navigating = new EventEmitter();

  constructor(private readonly _router: Router) {}

  navigate(event: Event): void {
    event.preventDefault();
    this.navigating.emit(this.url);

    if (!this.isExternalUrl) {
      this._router.navigate([this.url]);
    } else {
      window.open(this.url, '_blank');
    }
  }
}
