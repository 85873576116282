<div class="notification-center">
  <div class="tooltip-header flex items-center justify-between">
    <adl-text type="home" [bold]="true" class="ml-8" size="16"
      >Notifications center <adl-text color="light-blue" size="16"></adl-text
    ></adl-text>
    <div class="flex items-center mr-[50px]">
      <adl-switch
        [standalone]="true"
        (changed)="toggleSwitch()"
        [active]="onlyNewNotification"
        class="mr-3"
      ></adl-switch>
      <adl-text [color]="switchTextColor" [bold]="onlyNewNotification" size="12">Unread only</adl-text>
    </div>
  </div>
  <div class="notification-content-separator ml-[10px]"></div>
  <adl-sliding-tabs [tabsTilteList]="tabs" [tabsPastilleList]="tabsNotificationNumber" [activeTab]="activeTab">
    <div class="flex flex-col items-center notification-content-list overflow-y-auto">
      <div class="notification-header-separator"></div>
      <div #slidingTab>
        <dpg-notification-content
          *ngFor="let notification of tabsContent[0]"
          [content]="notification"
          (onClick)="openNotification($event)"
        ></dpg-notification-content>
      </div>
      <div #slidingTab>
        <dpg-notification-content
          *ngFor="let notification of tabsContent[1]"
          [content]="notification"
          (onClick)="openNotification($event)"
        ></dpg-notification-content>
      </div>
      <div #slidingTab>
        <dpg-notification-content
          *ngFor="let notification of tabsContent[2]"
          [content]="notification"
          (onClick)="openNotification($event)"
        ></dpg-notification-content>
      </div>
    </div>
  </adl-sliding-tabs>
  <div class="notification-content-separator ml-[10px]"></div>
  <div class="py-5 px-10 text-center">
    <adl-text size="13" class="mt-[10px] mb-[15px]">You have no other notifications for the last 15 days</adl-text>
  </div>
</div>
