import { NgModule } from '@angular/core';
import type { Routes } from '@angular/router';
import { RouterModule } from '@angular/router';
import { AUTH_ROUTES, AuthGuard } from '@dataportal/auth';
import { AdminTopbarComponent } from '@dataportal/layout';
import { LayoutAccorComponent } from '@dataportal/layout-accor';
import { BackOfficeGuard } from '@dataportal/permissions';

import { RoutableDocumentationDialogComponent } from '../../../../libs/dataportal-data-academy/src/lib/shared/components/dialog/routable-documentation-dialog.component';
import { AboutComponent } from './help-center/about/about.component';
import { FaqComponent } from './help-center/faq/faq.component';
import { HelpCenterComponent } from './help-center/help-center.component';
import { HelpSearchComponent } from './help-center/search/help-search.component';
import { RefreshComponent } from './refresh/refresh.component';
import { SupportComponent } from './support/support.component';

const documentationDialogRoute = {
  path: ':id',
  component: RoutableDocumentationDialogComponent,
  outlet: 'documentation',
  data: { title: 'Help Center - FAQ' },
};

const routes: Routes = [
  ...AUTH_ROUTES,
  {
    path: 'refresh',
    children: [{ path: '', component: RefreshComponent, outlet: 'layout' }],
  },
  // Admin V2 Main categories
  {
    path: 'admin',
    canActivate: [AuthGuard, BackOfficeGuard],
    children: [
      {
        path: '',
        component: AdminTopbarComponent,
        outlet: 'layout',
      },
      {
        path: '',
        canLoad: [BackOfficeGuard],
        loadChildren: () => import('@dataportal/back-office').then((m) => m.BackOfficeModule),
      },
      {
        path: 'glossary',
        canLoad: [BackOfficeGuard],
        loadChildren: () => import('@dataportal/business-glossary').then((m) => m.BusinessGlossaryBackOfficeModule),
      },
    ],
  },
  {
    path: '',
    canActivate: [AuthGuard],
    data: { breadcrumbLabel: 'Accor Data Portal' },
    children: [
      {
        path: '',
        canLoad: [AuthGuard],
        loadChildren: () => import('@dataportal/front-office').then((m) => m.FrontOfficeModule),
      },
      {
        path: 'academy',
        canLoad: [AuthGuard],
        loadChildren: () => import('@dataportal/data-academy').then((m) => m.DataAcademyModule),
        data: { breadcrumbLabel: 'Data Academy' },
      },
      {
        path: 'catalog',
        canLoad: [AuthGuard],
        loadChildren: () =>
          import('@dataportal/data-catalog-front-office-catalog-v2').then(
            (m) => m.DataCatalogFrontOfficeCatalogV2Module,
          ),
      },
      {
        path: 'sources/:filename',
        canLoad: [AuthGuard],
        loadChildren: () =>
          import('@dataportal/data-catalog-front-office-source-v2').then((m) => m.DataCatalogFrontOfficeSourceV2Module),
      },
      {
        path: 'glossary',
        canLoad: [AuthGuard],
        loadChildren: () => import('@dataportal/business-glossary').then((m) => m.BusinessGlossaryFrontOfficeModule),
      },
      {
        path: 'global-loading-interface',
        canLoad: [AuthGuard],
        loadChildren: () => import('@dataportal/gli').then((m) => m.dashboardRoutes),
      },
      {
        path: 'help-center',
        data: { breadcrumb: 'Help Center', title: 'Help Center' },
        component: HelpCenterComponent,
        children: [
          documentationDialogRoute,
          {
            path: '',
            data: { title: 'Help Center', breadcrumb: '' },
            component: AboutComponent,
            children: [documentationDialogRoute],
          },
          {
            path: 'faq',
            data: { title: 'Faq', breadcrumb: 'FAQ' },
            component: FaqComponent,
            children: [documentationDialogRoute],
          },
          {
            path: 'search',
            data: { title: 'Search', breadcrumb: 'Search results' },
            component: HelpSearchComponent,
            children: [documentationDialogRoute],
          },
        ],
      },
      {
        path: 'support',
        component: SupportComponent,
        data: { title: 'Support' },
      },
      {
        path: ':portal/sources/:filename',
        redirectTo: 'sources/:filename',
      },
      {
        path: 'portal/sources/:filename/dashboard/:dashboard',
        redirectTo: 'sources/:filename/dashboard/:dashboard',
      },
      { path: '', component: LayoutAccorComponent, outlet: 'layout' },
    ],
  },
  { path: '**', redirectTo: '/error' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      anchorScrolling: 'enabled',
      scrollOffset: [0, 70],
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
