<router-outlet name="documentation"></router-outlet>

<section class="flex flex-col w-full">
  <div class="w-full">
    <adl-text [size]="24" color="blue" weight="medium" class="block mb-4">FAQ</adl-text>

    <div class="flex justify-between">
      <adl-text [size]="14" color="black" class="block mb-6 w-[55%]">
        Find the answers to your questions about the Accor Data Portal through our FAQ or by browsing our guidelines.
        Can't find the answer you're looking for? Please contact our friendly team.
      </adl-text>
      <adl-button type="teams" *ngIf="product?.updatesChannel" (click)="openTeamsUpdatesChannel()">
        <img src="assets/img/v2/icon/i-teams.svg" alt="teams icon" class="mr-4" />
        <span>Accor Data Portal Community</span>
      </adl-button>
    </div>
  </div>

  <div class="mt-8">
    <adl-loaded-item *ngIf="product?.user_doc_faq" [loading]="loadingFAQ" iconSize="240">
      <div class="grid grid-rows-4 grid-cols-2 gap-8">
        <div *ngFor="let faqEntry of product.user_doc_faq" class="faq-entry flex items-center mb-4">
          <img
            class="w-[24px] mr-10"
            src="{{ 'assets/modules/data-academy/svg/file/' + faqEntry?.nature + '.svg' }}"
            [alt]="faqEntry.title"
          />

          <div class="faq-title flex-1 cursor-pointer px-4 mr-4" (click)="faqAction(faqEntry)">
            {{ faqEntry.title }}
          </div>

          <adl-document-download-button
            [title]="faqEntry.title"
            [url]="faqEntry.document?.url"
          ></adl-document-download-button>

          <adl-document-copy-clipboard-button
            [url]="!faqEntry.isInternal ? faqEntry.url : (faqEntry.id | url$ : 'documentation' | async)"
          ></adl-document-copy-clipboard-button>
        </div>
      </div>
    </adl-loaded-item>
  </div>
</section>
