import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AdlModule } from '@dataportal/adl';
import { AuthModule } from '@dataportal/auth';
import { ApiModule } from '@dataportal/front-api';

import { DatabricksService } from './services/databricks.service';

import { DatabricksPageComponent } from './pages/databricks-page/databricks-page.component';

@NgModule({
  imports: [ApiModule, AuthModule, CommonModule, FormsModule, AdlModule],
  declarations: [DatabricksPageComponent],
  providers: [DatabricksService],
  exports: [DatabricksPageComponent],
})
export class DatabricksModule {}
