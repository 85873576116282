import type { OnInit } from '@angular/core';
import { Component, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, forkJoin, Subject } from 'rxjs';
import { first } from 'rxjs/operators';
import type { ISelectPredicates } from '@dataportal/adl';
import { CurrentUserService } from '@dataportal/auth';
import { AlertService, ISharedOptions, SHARED_OPTIONS } from '@dataportal/front-shared';
import type { IGroup } from '@dataportal/types';
import type { LimitedUser } from '@dataportal/users';
import { FormControl } from '@ngneat/reactive-forms';

import { GroupsService } from '../../services/groups.service';
import { GroupsMembersService } from '../../services/groups-members.service';

import type { GroupAd } from '../../entities/group-ad';
import { GroupMember } from '../../entities/groupJoin';

// Types
export interface IGroupCreation {
  isImporting: boolean;
  groupName?: string;
  groupOwners?: Set<string>;
  groupMembers?: Set<string>;
  adGroupId?: string;
  adGroupName?: string;
}

// Component
@Component({
  selector: 'dpg-create-or-import-group-modal',
  templateUrl: './create-or-import-group-modal.component.html',
  styleUrls: ['./create-or-import-group-modal.component.scss'],
})
export class CreateOrImportGroupModalComponent implements OnInit {
  tabToChange = new BehaviorSubject<string>(undefined);
  actualGroupCreation: IGroupCreation = {
    isImporting: false,
    groupMembers: new Set<string>(),
    groupOwners: new Set<string>(),
  };

  groupNameFormControl: FormControl<string> = new FormControl<string>('');
  selectedLimitedUser: LimitedUser;
  limitedUserPredicates: ISelectPredicates<LimitedUser> = {
    isNotAlreadySelected: (limitedUser: LimitedUser): boolean => {
      return !this.actualGroupCreation.groupMembers.has(limitedUser.id);
    },
  };
  clearSelectSubject: Subject<void> = new Subject<void>();

  selectedAdGroup: GroupAd;

  isLoading = false;

  private _currentUserid: string;

  // Constructor
  constructor(
    private readonly _activeMatModal: MatDialogRef<CreateOrImportGroupModalComponent>,
    private readonly _currentUserService: CurrentUserService,
    private readonly _groupsService: GroupsService,
    private readonly _alertService: AlertService,
    private readonly _groupsMembersService: GroupsMembersService,
    private readonly _activatedRoute: ActivatedRoute,
    @Inject(SHARED_OPTIONS) private readonly _sharedOptions: ISharedOptions,
  ) {}

  // Lifecycle
  ngOnInit(): void {
    this._currentUserService.currentUser$.subscribe((currentUser) => {
      this._currentUserid = currentUser.id;
    });

    this._activatedRoute.queryParamMap.pipe(first()).subscribe((queryParamMap) => {
      const tabToOpen = queryParamMap.get('currentTab');

      if (tabToOpen) {
        this.tabToChange.next(tabToOpen);
      }
    });
  }

  // Methods
  addNewMember(): void {
    if (!this.selectedLimitedUser) return;

    this.actualGroupCreation.groupMembers.add(this.selectedLimitedUser.id);

    if (this.isCurrentUser(this.selectedLimitedUser.id)) {
      // Current user is owner by default
      this.actualGroupCreation.groupOwners.add(this.selectedLimitedUser.id);
    }

    this.clearSelectSubject.next();
  }

  toggleMemberStatus(groupMember: string): void {
    if (this.actualGroupCreation.groupOwners.has(groupMember)) {
      this.actualGroupCreation.groupOwners.delete(groupMember);
    } else {
      this.actualGroupCreation.groupOwners.add(groupMember);
    }
  }

  removeMember(groupMemberIdToRemove: string): void {
    this.actualGroupCreation.groupMembers.delete(groupMemberIdToRemove);
    this.actualGroupCreation.groupOwners.delete(groupMemberIdToRemove);
    this.clearSelectSubject.next();
  }

  isUserFromPR(member: string): boolean {
    return !!member.match(this._sharedOptions.internalUserEmail);
  }

  isCurrentUser(member: string): boolean {
    return member === this._currentUserid;
  }
  setGroupType(changeInfo): void {
    this.actualGroupCreation.isImporting = changeInfo.new === 'Import from AD';
  }

  cancel(): void {
    this._activeMatModal.close();
  }

  confirm(): void {
    if (this.selectedAdGroup) {
      this.actualGroupCreation.adGroupId = this.selectedAdGroup.adId;
      this.actualGroupCreation.adGroupName = this.selectedAdGroup.name;
    }

    this.actualGroupCreation.groupName = this.groupNameFormControl.value;

    if (this.actualGroupCreation.isImporting && this.actualGroupCreation.adGroupId) {
      this.isLoading = true;
      this._groupsService.createFromAd(this.actualGroupCreation.adGroupId).subscribe(
        () => {
          this._alertService.success(`Group ${this.actualGroupCreation.adGroupName} has been imported.`);
          this.isLoading = false;
        },
        () => {
          this.isLoading = false;
        },
      );
    } else if (
      !this.actualGroupCreation.isImporting &&
      this.actualGroupCreation.groupName &&
      this.actualGroupCreation.groupMembers.size
    ) {
      this.isLoading = true;
      this._groupsService
        .post({
          name: this.actualGroupCreation.groupName,
          owners: Array.from(this.actualGroupCreation.groupOwners),
        })
        .subscribe(
          (createdGroup: IGroup) => {
            this._alertService.info(
              `${this.actualGroupCreation.groupName} group has been created. Adding members to the group.`,
            );
            const groupMembers = Array.from(this.actualGroupCreation.groupMembers).map(
              (member) =>
                new GroupMember({
                  groupId: createdGroup.pk,
                  userId: member,
                  name: member,
                }),
            );
            forkJoin(groupMembers.map((member) => this._groupsMembersService.addMember(member))).subscribe(
              () => {
                this._alertService.success(`${this.actualGroupCreation.groupName} members have been added.`);
                this.isLoading = false;
                this._activeMatModal.close(true);
              },
              () => {
                this.isLoading = false;
              },
            );
          },
          () => {
            this.isLoading = false;
          },
        );
    }
  }

  memberStatus(groupMember: string): string {
    return this.actualGroupCreation.groupOwners.has(groupMember) ? 'Owner' : 'Member';
  }

  get ready(): boolean {
    if (this.actualGroupCreation.isImporting) {
      return !!this.selectedAdGroup;
    }

    return this.groupNameFormControl.value !== '' && this.actualGroupCreation.groupMembers.size > 0;
  }
}
