<svg
  class="dpg-icon"
  width="13"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  viewBox="0 0 512 512"
>
  <path
    class="dpg-fg-light-fill"
    d="M497.6,0L334.4,0.2c-8,0-14.4,6.4-14.4,14.4v33.3c0,8,6.4,14.4,14.4,14.4c0.1,0,0.2,0,0.3,0l73.6-2.7l2.1,2.1L131.5,340.5
                            	c-4.7,4.7-4.7,12.3,0,17c0,0,0,0,0,0l23,23c4.7,4.7,12.3,4.7,17,0c0,0,0,0,0,0l278.9-278.9l2.1,2.1l-2.7,73.6
                            	c-0.2,8,6.2,14.5,14.1,14.7c0.1,0,0.2,0,0.3,0h33.3c8,0,14.4-6.4,14.4-14.4L512,14.4C512,6.4,505.6,0,497.6,0z M432,288h-16
                            	c-8.8,0-16,7.2-16,16v154c0,3.3-2.7,6-6,6H54c-3.3,0-6-2.7-6-6V118c0-3.3,2.7-6,6-6h154c8.8,0,16-7.2,16-16V80c0-8.8-7.2-16-16-16
                            	H48C21.5,64,0,85.5,0,112v352c0,26.5,21.5,48,48,48l0,0h352c26.5,0,48-21.5,48-48l0,0V304C448,295.2,440.8,288,432,288z"
  />
</svg>
