import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiService } from '@dataportal/front-api';
import { EntityBuilder } from '@decahedron/entity';

import type { ISnowflakeAccount } from '../entities/snowflake-account';
import { SnowflakeAccountEntity } from '../entities/snowflake-account';

// Services
@Injectable()
export class SnowflakeService {
  private readonly _accountList$ = new BehaviorSubject<SnowflakeAccountEntity[]>([]);
  accountList$ = this._accountList$.asObservable();

  // Constructor
  constructor(private readonly apiService: ApiService) {}

  listAccounts(): Observable<SnowflakeAccountEntity[]> {
    return this.apiService.get<ISnowflakeAccount[]>('/v4/snowflake/accounts').pipe(
      map((snowflakeAccount) => {
        const accounts = EntityBuilder.buildMany<SnowflakeAccountEntity>(SnowflakeAccountEntity, snowflakeAccount);
        this._accountList$.next(accounts);

        return accounts;
      }),
      catchError(() => {
        if (!this._accountList$.getValue()?.length) {
          this._accountList$.next([]);
        }

        return [];
      }),
    );
  }
}
