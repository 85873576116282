import { Injectable } from '@angular/core';

import type { Dashboard } from '../entities/dashboard.model';
import type { IDashboardFolder } from '../entities/dashboard-folder.model';

// Service
@Injectable()
export class DashboardExplorerService {
  // both paths must be synced
  private _currentPathInternal = ''; // path with IDs (for logical purpose)
  private _currentPathToDisplay = ''; // path with names (for displaying purpose)

  private _lastDashboardFoldersRemoved: IDashboardFolder[] = []; // the last folders removed when tree shaking useless first folders

  get currentPathToDisplay(): string {
    return this._currentPathToDisplay;
  }

  private _resetPath(): void {
    this._currentPathInternal = '';
    this._currentPathToDisplay = '';
  }

  isRoot(path: string): boolean {
    return path === '';
  }

  getLevelFromPath(path: string): number {
    return this.isRoot(path) ? 0 : path.split('/').length;
  }

  goBackPath(path: string): string {
    const goBackPathSegments = path.split('/');
    goBackPathSegments.pop();
    const newPath = goBackPathSegments.join('/');

    return this.isRoot(path) ? null : newPath;
  }

  getCurrentFolderIdFromPath(path: string): string {
    return this.isRoot(path) ? null : path.split('/').pop();
  }

  compareDashboardFolder(f1: IDashboardFolder, f2: IDashboardFolder): number {
    // TODO : define another sorting order
    return f1.name > f2.name ? 1 : f1.name < f2.name ? -1 : 0;
  }

  sortDashboardFolder(dashboardFolders: IDashboardFolder[]): IDashboardFolder[] {
    return dashboardFolders.sort(this.compareDashboardFolder);
  }

  buildDashboardFoldersTree(dashboardFolders: IDashboardFolder[]): IDashboardFolder[][] {
    const dashboardFoldersTree: IDashboardFolder[][] = [];
    let tmpDashboardFolders: IDashboardFolder[] = dashboardFolders.filter((dashboardFolder) => !dashboardFolder.parent);
    let level = 0;
    dashboardFoldersTree.push(tmpDashboardFolders);

    while (dashboardFoldersTree[level].length) {
      dashboardFoldersTree.push(
        dashboardFoldersTree[level].reduce(
          (currentDashboardFolders: IDashboardFolder[], currentDashboardFolder: IDashboardFolder) => {
            const childrenIds = currentDashboardFolder.dashboardFolderChildren.map(
              (dashboardFolder) => dashboardFolder.id,
            );
            // filtering children
            tmpDashboardFolders = dashboardFolders.filter((dashboardFolder) =>
              childrenIds.includes(dashboardFolder.id),
            );
            currentDashboardFolders = [...currentDashboardFolders, ...tmpDashboardFolders];

            return currentDashboardFolders;
          },
          [],
        ),
      );
      level += 1;
    }

    dashboardFoldersTree.pop(); // to remove the last empty array

    return dashboardFoldersTree;
  }

  private _treeShakeEmptyFoldersForEachRootRec(
    dashboardFoldersTree: IDashboardFolder[][],
    dashboardsToDisplay: Dashboard[],
    currentDashboardFolder: IDashboardFolder,
    currentLevel: number,
  ): IDashboardFolder[][] {
    if (currentDashboardFolder.dashboardFolderChildren.length) {
      const childrenIds = currentDashboardFolder.dashboardFolderChildren.map((dashboardFolder) => dashboardFolder.id);
      // currentLevel + 1 is not null there because currentLevel has children
      dashboardFoldersTree[currentLevel + 1]
        .filter((dashboardFolder) => childrenIds.includes(dashboardFolder.id)) // filtering children
        .forEach((dashboardFolder) => {
          // recursive build
          dashboardFoldersTree = this._treeShakeEmptyFoldersForEachRootRec(
            dashboardFoldersTree,
            dashboardsToDisplay,
            dashboardFolder,
            currentLevel + 1,
          );
        });
    }

    const hasDashboardInCurrentFolder = dashboardsToDisplay.some(
      (dashboard) => dashboard.parentFolder && dashboard.parentFolder.id === currentDashboardFolder.id,
    );
    const hasChildrenByDefault = currentDashboardFolder.dashboardFolderChildren.length > 0;
    const hasNoMoreChildrenAfterRecursiveChecking =
      currentLevel + 1 >= dashboardFoldersTree.length ||
      (currentLevel + 1 < dashboardFoldersTree.length &&
        !dashboardFoldersTree[currentLevel + 1].some(
          (dashboardFolder) => dashboardFolder.parent.id === currentDashboardFolder.id,
        ));
    const wasEmptyCurrentFolder = !hasDashboardInCurrentFolder && !hasChildrenByDefault;
    const isNowEmptyCurrentFolder = !hasDashboardInCurrentFolder && hasNoMoreChildrenAfterRecursiveChecking;

    if (wasEmptyCurrentFolder || isNowEmptyCurrentFolder) {
      dashboardFoldersTree[currentLevel] = dashboardFoldersTree[currentLevel].filter(
        (dashboardFolder) => dashboardFolder.id !== currentDashboardFolder.id,
      );
    }

    return dashboardFoldersTree;
  }

  treeShakeEmptyFolders(
    dashboardFoldersTree: IDashboardFolder[][],
    dashboardsToDisplay: Dashboard[],
  ): IDashboardFolder[][] {
    if (dashboardFoldersTree[0]) {
      dashboardFoldersTree[0].forEach((dashboardFolder) => {
        dashboardFoldersTree = this._treeShakeEmptyFoldersForEachRootRec(
          dashboardFoldersTree,
          dashboardsToDisplay,
          dashboardFolder,
          0,
        );
      });
    }

    return dashboardFoldersTree;
  }

  resetLastDashboardFoldersRemoved(): void {
    this._lastDashboardFoldersRemoved = [];
  }

  treeShakeFirstUselessLevels(
    dashboardFoldersTree: IDashboardFolder[][],
    dashboardsToDisplay: Dashboard[],
  ): IDashboardFolder[][] {
    let currentLevelDashboardFolders = dashboardFoldersTree[0] ? dashboardFoldersTree[0] : [];
    let currentLevelDashboards;
    let isDefaultRoot = true;

    while (currentLevelDashboardFolders.length === 1) {
      currentLevelDashboards = isDefaultRoot
        ? dashboardsToDisplay.filter((dashboard) => !dashboard.parentFolder)
        : dashboardsToDisplay.filter(
            (dashboard) =>
              dashboard.parentFolder && dashboard.parentFolder.id === currentLevelDashboardFolders[0].parent.id,
          );

      if (!currentLevelDashboards.length) {
        // removing root level
        this._lastDashboardFoldersRemoved = dashboardFoldersTree.shift();
      } else {
        // we don't go further
        break;
      }

      // updating root level
      currentLevelDashboardFolders = dashboardFoldersTree[0] ? dashboardFoldersTree[0] : [];
      isDefaultRoot = false;
    }

    return dashboardFoldersTree;
  }

  private _getDashboardsInsideFolder(dashboardFolder: IDashboardFolder, dashboardsToDisplay: Dashboard[]): Dashboard[] {
    return dashboardsToDisplay.filter(
      (dashboard) => dashboard.parentFolder && dashboard.parentFolder.id === dashboardFolder.id,
    );
  }

  private _getDashboardFolderByLevelAndId(
    dashboardFoldersTree: IDashboardFolder[][],
    level: number,
    id: string,
  ): IDashboardFolder {
    return dashboardFoldersTree[level].find((dashboardFolderSameLevel) => dashboardFolderSameLevel.id === id);
  }

  private _refreshPathToDisplay(dashboardFolder: IDashboardFolder): void {
    const currentPathToDisplaySegments = this._currentPathToDisplay.split('/');
    currentPathToDisplaySegments.pop();
    currentPathToDisplaySegments.push(dashboardFolder.name);
    this._currentPathToDisplay = currentPathToDisplaySegments.join('/');
  }

  getNbDashboardsToDisplayInsideFolder(dashboardFolder: IDashboardFolder, dashboardsToDisplay: Dashboard[]): number {
    return this._getDashboardsInsideFolder(dashboardFolder, dashboardsToDisplay)?.length;
  }

  refreshCurrentPath(
    dashboardFoldersTree: IDashboardFolder[][],
    dashboardsToDisplay: Dashboard[],
  ): {
    dashboardFolders: IDashboardFolder[];
    dashboards: Dashboard[];
  } {
    if (this.isRoot(this._currentPathInternal)) {
      return this.exploreRoot(dashboardFoldersTree, dashboardsToDisplay);
    } else {
      const currentDashboardFolderId = this.getCurrentFolderIdFromPath(this._currentPathInternal);
      const currentDashboardFolder = this._getDashboardFolderByLevelAndId(
        dashboardFoldersTree,
        this.getLevelFromPath(this._currentPathInternal) - 1,
        currentDashboardFolderId,
      );

      return this.exploreFolder(currentDashboardFolder, true, dashboardFoldersTree, dashboardsToDisplay);
    }
  }

  exploreRoot(
    dashboardFoldersTree: IDashboardFolder[][],
    dashboardsToDisplay: Dashboard[],
  ): {
    dashboardFolders: IDashboardFolder[];
    dashboards: Dashboard[];
  } {
    this._resetPath();
    const currentLevelDashboardFolders = dashboardFoldersTree[0] ?? [];
    const lastDashboardFoldersRemovedIds = this._lastDashboardFoldersRemoved.map(
      (dashboardFolder) => dashboardFolder.id,
    );
    const currentLevelDashboards = dashboardsToDisplay.filter((dashboard) =>
      !this._lastDashboardFoldersRemoved.length
        ? !dashboard.parentFolder
        : dashboard.parentFolder && lastDashboardFoldersRemovedIds.includes(dashboard.parentFolder.id),
    );

    return {
      dashboardFolders: currentLevelDashboardFolders,
      dashboards: currentLevelDashboards,
    };
  }

  exploreFolder(
    dashboardFolder: IDashboardFolder,
    isBacking = false,
    dashboardFoldersTree: IDashboardFolder[][],
    dashboardsToDisplay: Dashboard[],
  ): {
    dashboardFolders: IDashboardFolder[];
    dashboards: Dashboard[];
  } {
    if (!isBacking) {
      this._currentPathInternal = this.isRoot(this._currentPathInternal)
        ? dashboardFolder.id
        : `${this._currentPathInternal}/${dashboardFolder.id}`;
      this._currentPathToDisplay = this.isRoot(this._currentPathToDisplay)
        ? dashboardFolder.name
        : `${this._currentPathToDisplay}/${dashboardFolder.name}`;
    } else if (!this.isRoot(this._currentPathToDisplay)) {
      // potentially refreshing path to display
      this._refreshPathToDisplay(dashboardFolder);
    }

    const depth = this.getLevelFromPath(this._currentPathInternal);
    const currentLevelDashboardFolders =
      depth < dashboardFoldersTree.length
        ? dashboardFoldersTree[depth].filter(
            (dashboardFolderFromTree) => dashboardFolderFromTree.parent.id === dashboardFolder.id,
          )
        : [];
    const currentLevelDashboards = this._getDashboardsInsideFolder(dashboardFolder, dashboardsToDisplay);

    return {
      dashboardFolders: currentLevelDashboardFolders,
      dashboards: currentLevelDashboards,
    };
  }

  goBackFolder(
    dashboardFoldersTree: IDashboardFolder[][],
    dashboardsToDisplay: Dashboard[],
  ): {
    dashboardFolders: IDashboardFolder[];
    dashboards: Dashboard[];
  } {
    if (this.isRoot(this._currentPathToDisplay)) {
      return null;
    }

    const currentDashboardFolderId = this.getCurrentFolderIdFromPath(this._currentPathInternal);
    const currentDashboardFolder = this._getDashboardFolderByLevelAndId(
      dashboardFoldersTree,
      this.getLevelFromPath(this._currentPathInternal) - 1,
      currentDashboardFolderId,
    );
    this._currentPathInternal = this.goBackPath(this._currentPathInternal);
    this._currentPathToDisplay = this.goBackPath(this._currentPathToDisplay);

    if (this.isRoot(this._currentPathToDisplay)) {
      return this.exploreRoot(dashboardFoldersTree, dashboardsToDisplay);
    } else {
      const dashboardFolderParent = this._getDashboardFolderByLevelAndId(
        dashboardFoldersTree,
        this.getLevelFromPath(this._currentPathInternal) - 1,
        currentDashboardFolder.parent.id,
      );

      return this.exploreFolder(dashboardFolderParent, true, dashboardFoldersTree, dashboardsToDisplay);
    }
  }
}
