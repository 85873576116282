import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { GlobalBannerService } from '../../services/global-banner.service';

@Component({
  selector: 'dpg-alert-modal',
  templateUrl: './alert-modal.component.html',
  styleUrls: ['./alert-modal.component.scss'],
})
export class AlertModalComponent {
  constructor(
    private readonly _activeModal: MatDialogRef<AlertModalComponent>,
    readonly alertsService: GlobalBannerService,
  ) {}

  ignore(): void {
    this._activeModal.close();
  }
}
