import { Component, EventEmitter, Input, Output } from '@angular/core';

import { IconType } from '../../atoms/icons';

@Component({
  selector: 'adl-toggleable-content-button',
  templateUrl: './toggleable-content-button.component.html',
  styleUrls: ['./toggleable-content-button.scss'],
})
export class ToggleableContentButtonComponent {
  @Input() hasShadow = false;
  @Input() buttonBorderRadius: string = null;
  @Input() contentBorderRadius: string = null;
  @Input() buttonTitle = 'More';
  @Input() buttonTextColor = 'blue';
  @Input() buttonSize: string = null;
  @Input() buttonBackgroundColor = 'white';
  @Input() chevronIconType: IconType = 'primary-light';
  @Input() buttonPaddingTop: string = null;
  @Input() buttonPaddingBottom: string = null;
  @Input() buttonPaddingLeftRight: string = null;
  @Input() hasLeftBorder = false;
  @Input() hasToShowToggleableContent = false;

  @Output() contentToggled = new EventEmitter<boolean>();

  defaultPadding = '10px';

  get buttonBackgroundColorClassName(): string {
    return `color-${this.buttonBackgroundColor}`;
  }

  toggleShowoggleableContent($event): void {
    $event.stopPropagation();
    this.hasToShowToggleableContent = !this.hasToShowToggleableContent;
    this.contentToggled.emit(this.hasToShowToggleableContent);
  }
}
