<ul
  class="nav-tabs flex flex-wrap justify-center"
  [class]="classToAdd"
  [ngClass]="{
    'resize-tab': resizeToSelect,
    'j-start': justifyType === 'start',
    'j-center': justifyType === 'center',
    'j-end': justifyType === 'end'
  }"
  [class.vertical-tabs]="vertical"
  [class.column-flex]="vertical"
  [style.width]="width"
  [style.margin-left]="marginLeft"
>
  <li
    class="tabs__tab"
    *ngFor="let tab of tabs"
    [class.active]="tab.active"
    [hidden]="tab.hidden"
    (click)="selectTab(tab)"
    [ngClass]="{ 'stretched': stretched }"
  >
    <a class="tabs__tab__tab-name flex items-center" [class.disabled]="tab.disabled">
      {{ tab.tabTitle }}
      <adl-info-bubble class="ml5" *ngIf="tab.tabTooltip">{{ tab.tabTooltip }}</adl-info-bubble>
    </a>
  </li>
</ul>
<div class="w-full flex justify-end">
  <adl-select-v2
    *ngIf="resizeToSelect"
    class="resize-select"
    (change)="_selectTabByName($event)"
    [items]="shownTabsTitles"
    [value]="activeTabName"
  >
  </adl-select-v2>
</div>
<ng-content></ng-content>
