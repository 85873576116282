import { NgModule } from '@angular/core';
import { AdlModule } from '@dataportal/adl';
import { ApiModule } from '@dataportal/front-api';
import { SharedModule } from '@dataportal/front-shared';

import { PortalsService } from './services/portals.service';
import { PortalsDatalakeService } from './services/portals-datalake.service';

import { PortalSelectComponent } from './components/portal-select/portal-select.component';
import { SelectPortalComponent } from './components/select-portal/select-portal.component';

// Module
// @dynamic
@NgModule({
  imports: [SharedModule, AdlModule, ApiModule],
  declarations: [PortalSelectComponent, SelectPortalComponent],
  exports: [PortalSelectComponent, SelectPortalComponent],
  providers: [PortalsService, PortalsDatalakeService],
})
export class PortalsModule {}
