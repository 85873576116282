import type { AfterViewInit } from '@angular/core';
import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import type { AnimationItem } from 'lottie-web';
import lottie from 'lottie-web';

@Component({
  selector: 'adl-lottie-animation',
  templateUrl: './lottie-animation.component.html',
})
export class LottieAnimationComponent implements AfterViewInit {
  // Attributes
  @Input()
  animationData = '/assets/dpg/animations/loader-green-it/data.json';

  @Input() speed = 1;
  @Input() autoplay = true;

  @ViewChild('animation')
  container: ElementRef<HTMLDivElement>;

  animation: AnimationItem;

  // Lifecycle
  ngAfterViewInit(): void {
    this.animation = lottie.loadAnimation({
      container: this.container.nativeElement,
      renderer: 'svg',
      loop: true,
      autoplay: this.autoplay,
      path: this.animationData,
    });

    this.animation.setSpeed(this.speed || 1);
  }

  play(): void {
    this.animation.play();
  }

  stop(): void {
    this.animation.stop();
  }
}
