<adl-modal>
  <header modal-header class="column-flex j-center">
    <adl-icon icon="request" [size]="50"></adl-icon>
    <adl-text type="accent" [size]="20">Request access for {{ resource?.name }}</adl-text>
  </header>
  <div modal-body>
    <div class="column-flex j-center a-center" *ngIf="accessRequestAllowed">
      <adl-text class="mb10">
        Thanks for your interest in this {{ singularKind }}. If you click on confirm, the {{ singularKind }}
        {{ kindOwners }} will be notified of your access request.
      </adl-text>
      <adl-radio-selector
        (changed)="updateRequestedRole($event)"
        class="row-flex j-evenly roles-list"
        selectorName="all-roles"
        [activeChoice]="requestedRole"
        [choices]="availableRoles"
      >
      </adl-radio-selector>
    </div>
    <div *ngIf="!accessRequestAllowed">
      <div [innerHTML]="resource?.accessRequestInstructions"></div>
    </div>
  </div>
  <footer modal-footer>
    <div class="row-flex j-center" *ngIf="accessRequestAllowed">
      <adl-button class="mr10" (clicked)="request()" type="success"> Confirm </adl-button>
      <adl-button class="ml10" (clicked)="cancel()" type="cancel"> Cancel </adl-button>
    </div>
    <div class="row-flex j-center" *ngIf="!accessRequestAllowed">
      <adl-button class="mr10" (clicked)="ok()" type="primary"> Ok </adl-button>
    </div>
  </footer>
</adl-modal>
