<adl-modal>
  <adl-title modal-header>Referential "{{ this.label?.length ? this.label : 'Unknown' }}" values overview</adl-title>

  <div modal-body class="column-flex a-center mt5 w100">
    <adl-loaded-item [loading]="this.isLoading" class="column-flex a-center w80">
      <adl-tooltip>
        <adl-text tooltip-content type="tooltip" class="tac wb-all">Download values list as CSV</adl-text>
        <adl-icon-button tooltip-trigger (click)="downloadCsvFile()" icon="download"></adl-icon-button>
      </adl-tooltip>
      <div class="column-flex a-center mt20 w100">
        <adl-text [bold]="true" class="tac w50">
          {{ this.displayedValues?.length || 0 }} / {{ this.extractedValues?.length || 0 }} values displayed
        </adl-text>
        <adl-text class="tal wb-all mt20 w100">{{ this.displayedValuesStr }}</adl-text>
        <adl-button *ngIf="this.hasToDisplayMoreButton" class="mt25" size="small" (click)="this.displayMore()">
          Display more
        </adl-button>
      </div>
    </adl-loaded-item>
  </div>
</adl-modal>
