import type { SafeResourceUrl } from '@angular/platform-browser';
import type { IUser } from '@dataportal/types';
import { Entity, EntityBuilder } from '@decahedron/entity';

import { AdInformation } from './ad_information';

export interface IUserPayload {
  user_id: string;
  active: boolean;
  name: string;
}

export interface IProfile {
  email: string;
  title: string;
  name: string;
  picture: SafeResourceUrl;
}

export class User extends Entity {
  id: string = null;
  name: string = null;
  type: string = null;
  active = false;
  adInformation: AdInformation = new AdInformation();
  favoriteOrganization: string = null;
  intro: boolean = null;
  role: 'admin' | 'user';

  // Methods
  fromJson(json: IUser): User {
    this.id = json.pk;
    this.name = json.name;
    this.type = json.sk;
    this.active = json.active;
    this.adInformation = EntityBuilder.buildOne<AdInformation>(AdInformation, json.ad_information);
    this.favoriteOrganization = json.favorite_organization;
    this.intro = json.intro;
    this.role = json.role;

    return this;
  }

  toJson(): any {
    return {
      user_id: this.id,
      name: this.name,
      active: this.active,
    };
  }

  // Properties
  get activeToString(): 'ACTIVE' | 'INACTIVE' {
    return this.active ? 'ACTIVE' : 'INACTIVE';
  }

  get displayName(): string {
    return this.name ? `${this.name} (${this.id})` : this.id;
  }
}
