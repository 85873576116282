export function removeFragment(url: string): string {
  return url.replace(/#[^#]+$/g, '');
}

export function removeQueryParameters(url: string): string {
  return url.replace(/\?[^?]+$/g, '');
}

export function extractQueryParameters(url: string): string[] {
  return url.split('?');
}
