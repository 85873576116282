import type { IAccessRequestResource } from '@dataportal/access-requests';
import type { ISourcePayload } from '@dataportal/api-types';
import { DatalakePath } from '@dataportal/front-shared';
import type { Portal } from '@dataportal/portals';
import type { DatalakeProvider, ISource, SourceDataType, SourceType } from '@dataportal/types';
import { Entity, EntityBuilder, Type } from '@decahedron/entity';

import { Dashboard } from '../../dashboards/entities/dashboard.model';

import type { IDashboardFolder } from '../../dashboards/entities/dashboard-folder.model';
import { DataCertification } from './data-certification';
import { DataClassification } from './data-classification';
import { DataProvider } from './data-provider';
import { DocumentationLink } from './documentation-link';
import { Link } from './link';
import { SourceBanner } from './source-banner';
import type { ISourceStepForm, SourceLoad, SourceStorage } from './source-form';
import { SourceImage } from './source-image';

// Types
export interface IRank {
  portalId: string;
  rank: number;
}

/**
 * @deprecated LEGACY not used anymore.
 */
export interface ISourceMetadata {
  provider: string;
  storages: SourceStorage[];
  load: SourceLoad;
  dataTypes: {
    default: string[];
    custom: string[];
  };
}

export interface ISourceLinks {
  linkName: string;
  linkUrl: string;
}

// Constants
const DEFAULT_SCOPE = 'ACCOR';
const NO_SCOPE = 'No organization';

// Entity
export class Source extends Entity implements IAccessRequestResource {
  // Attributes
  id: string = null;
  name: string = null;
  createdAt: string = null;
  createdBy: string = null;

  isLimited: true | undefined = null;

  icon?: string = null;
  mainCategory?: string = null;
  supersetUrl?: string = null;
  coverageMarkets: string[] = [];
  coverageBrands: string[] = [];
  organizations: string[] = [];
  ranks: IRank[] = [];
  updatedAt?: string = null;
  updatedBy?: string = null;
  banner?: SourceBanner = null;
  status?: string = null;
  type?: SourceType = null;
  fileGranularity?: string = null;
  incrementSize?: string = null;
  updateFrequency?: string = null;
  accessRequestInstructions?: string = null;
  datalakeProvider?: DatalakeProvider = null;
  @Type(Link) externalLinks: Link[] = [];
  @Type(Dashboard) powerbi: Dashboard[] = [];
  @Type(DatalakePath) datalakePath: DatalakePath[] = [];

  categories: string[] = [];
  technicalOwners: string[] = [];
  functionalOwners: string[] = [];
  dataOwner: string[] = [];
  isConfidential = false;
  allowAccessRequest = true;
  description?: string = null;
  /** @deprecated LEGACY not used anymore */
  /* metadata: ISourceMetadata = null;*/
  snowflakeTableKeys: string[] = [];
  mssqlTableKeys: string[] = [];
  linkedProject?: string = null;

  allowAmundsenPreview?: boolean = null;

  dataType: SourceDataType = null;

  /** @deprecated LEGACY not used anymore */
  @Type(DataProvider) dataProvider = new DataProvider();

  /** @deprecated LEGACY not used anymore */
  @Type(SourceImage) image = new SourceImage();

  @Type(DocumentationLink) documentationLinks: DocumentationLink[] = [];

  dashboardFolders: IDashboardFolder[] = [];

  @Type(DataClassification) dataClassification: DataClassification = null;
  masterData = false;

  @Type(DataCertification) dataCertification: DataCertification = null;

  // Properties
  get hasInternalDashboard(): boolean {
    return this.powerbi?.length > 0;
  }

  get hasDashboards() {
    return this.powerbi?.length || this.hasExternalDashboards || this.dashboardFolders?.length;
  }

  get hasDatalakes() {
    return this.datalakeProvider === 'aws' || this.datalakeProvider === 'azure' || this.hasExternalDatalake;
  }

  get hasExternalDashboards(): boolean {
    return this.externalLinks?.some((link) => link.type === 'dashboard');
  }

  get hasExternalDatalake(): boolean {
    return this.datalakeProvider === 'external' && this.externalLinks?.some((link) => link.type === 'download');
  }

  get hasSnowflake(): boolean {
    return this.snowflakeTableKeys?.length > 0;
  }

  get hasMSSQL(): boolean {
    return this.mssqlTableKeys?.length > 0;
  }

  /**
   * @deprecated LEGACY not used anymore.
   */
  get hasMetadata(): boolean {
    return this.dataProvider?.name?.length > 0;
  }

  fromStepForm(formData: ISourceStepForm): Source {
    // Basic info
    this.name = formData.basicInfos.stepGeneralInformation.name;
    this.icon = formData.basicInfos.stepGeneralInformation.icon;
    this.categories = formData.basicInfos.stepGeneralInformation.categories;
    this.isConfidential = !formData.basicInfos.stepGeneralInformation.isNotConfidential;
    this.allowAccessRequest = !formData.basicInfos.stepGeneralInformation.useCustomAccessRequests;
    this.accessRequestInstructions = formData.basicInfos.stepGeneralInformation.accessRequestInstructions;

    if (formData.basicInfos.stepGeneralInformation.mainCategory) {
      this.mainCategory = formData.basicInfos.stepGeneralInformation.mainCategory;
    }

    this.description = formData.basicInfos.stepGeneralInformation.description || undefined;

    if (formData.basicInfos.stepGeneralInformation.hasBanner) {
      this.banner = EntityBuilder.buildOne(SourceBanner, {
        message: formData.basicInfos.stepGeneralInformation.banner?.message,
        color: formData.basicInfos.stepGeneralInformation.banner?.color,
        startDate: formData.basicInfos.stepGeneralInformation.banner?.hasStartAndEndDates
          ? formData.basicInfos.stepGeneralInformation.banner?.startDate
          : null,
        endDate: formData.basicInfos.stepGeneralInformation.banner?.hasStartAndEndDates
          ? formData.basicInfos.stepGeneralInformation.banner?.endDate
          : null,
      });
    } else {
      delete this.banner;
    }

    this.documentationLinks = formData.basicInfos.stepLinks.documentationLinks || [];

    this.technicalOwners = formData.basicInfos.stepContacts.technicalOwners;
    this.functionalOwners = formData.basicInfos.stepContacts.functionalOwners;
    this.dataOwner = formData.basicInfos.stepContacts.dataOwner;

    // Dashboards
    const dashboardFromForm = formData.dataContent.dashboards.map((dashboardData) =>
      Dashboard.fromStepForm(dashboardData),
    );
    // FIXME: adding implicit forwarded informations -> put inside form data ?
    this.powerbi = dashboardFromForm.map((updatedDashboard) => {
      const newDashboard = this.powerbi.find((dashboard) => dashboard.id === updatedDashboard.id);
      updatedDashboard.fieldsToIgnore = newDashboard?.fieldsToIgnore || null;
      updatedDashboard.hasToIgnoreSpecifiedFieldsInFilters = newDashboard?.hasToIgnoreSpecifiedFieldsInFilters || false;

      return updatedDashboard;
    });

    // Snowflake
    this.snowflakeTableKeys = formData.dataContent.snowflake.snowflakeTableKeys || [];

    // MSSQL
    this.mssqlTableKeys = formData.dataContent.mssql.mssqlTableKeys || [];

    // Datalake
    this.datalakeProvider = formData.dataContent.datalake.provider;

    if (this.datalakeProvider === 'none') {
      // Remove datalake paths and external links when no provider is checked
      this.datalakePath = [];
      this.externalLinks = []; // Only download links are supported anyway
    } else if (this.datalakeProvider === 'external') {
      // Remove datalake path when external data is checked
      this.datalakePath = [];
      const newDatalakeExternalLink = new Link();
      newDatalakeExternalLink.type = 'download';
      newDatalakeExternalLink.url = formData.dataContent.datalake.externalURL;
      this.externalLinks = [newDatalakeExternalLink];
    } else {
      // Remove external links when provider is aws or azure
      this.externalLinks = [];

      if (formData.dataContent.datalake.paths) {
        this.datalakePath = formData.dataContent.datalake.paths.filter(
          (datalakePath) => datalakePath.provider === this.datalakeProvider,
        );
      }

      for (const path of this.datalakePath) {
        if (path.provider !== 'azure') {
          delete path.tenant;
        }
      }
    }

    // Data Management
    // Part Data Classification
    this.dataClassification = formData.dataManagement.dataClassification?.dataSourcing
      ? DataClassification.fromStepForm(formData.dataManagement.dataClassification)
      : undefined;

    this.masterData = formData.dataManagement.masterData.isMasterData;

    this.dataType = formData.dataManagement.dataType.dataType;

    this.dataCertification = DataCertification.fromStepForm(formData.dataManagement.dataCertification);

    return this;
  }

  fromJson(jsonData: ISource): Source {
    // Prepare data
    if (!jsonData.powerbi) {
      jsonData.powerbi = [];
    }

    if (!jsonData.external_links) {
      jsonData.external_links = [];
    }

    // Parse data
    super.fromJson(jsonData);

    this.ranks = jsonData.ranks?.map((jR) => ({ portalId: jR.portal_id, rank: jR.rank })) || [];
    this.id = jsonData.pk;
    this.mainCategory = jsonData.main_category;
    this.banner = jsonData.banner ? EntityBuilder.buildOne(SourceBanner, jsonData.banner) : null;

    // TODO remove this after migration
    if (this.categories?.includes('Open Data')) {
      this.categories = this.categories?.filter((category) => category !== 'Open Data');
      this.categories.push('Other');
    }

    if (this.categories?.includes('IT')) {
      this.categories = this.categories?.filter((category) => category !== 'IT');
      this.categories.push('Other');
    }

    this.dashboardFolders = jsonData.dashboard_folders?.map((dashboardFolder) => ({
      id: dashboardFolder.id,
      name: dashboardFolder.name,
      parent: dashboardFolder.parent ? dashboardFolder.parent : null,
      dashboardFolderChildren: dashboardFolder.dashboard_folder_children,
    }));

    this.snowflakeTableKeys = jsonData.snowflake_table_keys as string[];
    this.allowAmundsenPreview = jsonData.allow_amundsen_preview as boolean;

    this.mssqlTableKeys = jsonData.mssql_table_keys as string[];

    this.masterData = jsonData.is_master_data ? jsonData.is_master_data : false;

    this.dataType = jsonData.data_type ? jsonData.data_type : null;

    return this;
  }

  toJson(): ISourcePayload & {
    data_certification: { is_certified: boolean; certification_date: string; business_certifier: string };
    allow_amundsen_preview: boolean;
  } {
    return {
      name: this.name,
      icon: this.icon,
      main_category: this.mainCategory,
      superset_url: this.supersetUrl || null,
      coverage_markets: this.coverageMarkets || [],
      coverage_brands: this.coverageBrands || [],
      organizations: this.organizations || [],
      status: this.status || null,
      type: this.type || null,
      file_granularity: this.fileGranularity || null,
      increment_size: this.incrementSize || null,
      update_frequency: this.updateFrequency || null,
      access_request_instructions: this.accessRequestInstructions || null,
      datalake_provider: this.datalakeProvider,
      external_links: this.externalLinks.length === 0 ? null : this.externalLinks.map((link) => link.toJson()),
      powerbi: this.powerbi?.map((dashboard: Dashboard) => dashboard.toJson()) || [],
      datalake_path: this.datalakePath?.map((path) => path.toJson()) || [],

      categories: this.categories || [],
      technical_owners: this.technicalOwners,
      functional_owners: this.functionalOwners,
      data_owner: this.dataOwner,
      is_confidential: this.isConfidential,
      allow_access_request: this.allowAccessRequest,
      description: this.description,
      banner: this.banner?.toJson(),
      documentation_links: this.documentationLinks?.map((documentationLink) => documentationLink.toJson()),

      data_provider: this.dataProvider.toJson(),
      image: this.image.toJson(),

      dashboard_folders: this.dashboardFolders
        ? this.dashboardFolders.map((dashboardFolder) => ({
            id: dashboardFolder.id,
            name: dashboardFolder.name,
            parent: dashboardFolder.parent ? dashboardFolder.parent : null,
            dashboard_folder_children: dashboardFolder.dashboardFolderChildren,
          }))
        : null,

      snowflake_table_keys: this.hasSnowflake ? this.snowflakeTableKeys || [] : [],
      allow_amundsen_preview: this.allowAmundsenPreview || false,

      mssql_table_keys: this.mssqlTableKeys.length ? this.mssqlTableKeys : [],

      data_type: this.dataType || null,

      data_classification: this.dataClassification ? this.dataClassification.toJson() : null,
      is_master_data: this.masterData,
      data_certification: this.dataCertification ? this.dataCertification.toJson() : null,
    };
  }

  computeProvider(): DatalakeProvider {
    const hasDatalakePath = this.datalakePath && this.datalakePath.length > 0;
    const isAWS = hasDatalakePath && this.datalakePath.every((p) => p.provider === 'aws');
    const hasExternalLink = this.externalLinks && this.externalLinks.length > 0;

    if (hasDatalakePath) {
      return isAWS ? 'aws' : 'azure';
    } else {
      return hasExternalLink ? 'external' : 'none';
    }
  }

  getProvider(): DatalakeProvider {
    if (this.datalakeProvider) {
      return this.datalakeProvider;
    }

    return this.computeProvider();
  }

  displayOrganization(organizationList: Portal[]): string {
    // Get scopes
    const scopes = this.organizations
      .map((id) => organizationList.find((org) => org.id === id))
      .filter((portal) => !!portal);

    if (scopes.length > 0) {
      if (scopes.some((scope) => scope.name === DEFAULT_SCOPE)) {
        return DEFAULT_SCOPE;
      }

      return scopes[0].name;
    }

    return NO_SCOPE;
  }

  hasOwner(userId: string): boolean {
    return this.technicalOwners.includes(userId) || this.functionalOwners.includes(userId);
  }
}
