import type { CdkDragDrop } from '@angular/cdk/drag-drop';
import type { OnDestroy, OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { Validators } from '@angular/forms';
import { Observable, of, Subject } from 'rxjs';
import { mergeMap, take, takeUntil } from 'rxjs/operators';
import { DialogsService } from '@dataportal/adl';
import { AmundsenService } from '@dataportal/amundsen';
import type { IAmundsenTableMetadata } from '@dataportal/api-types';
import type { APIReferential, IAPITableReferential } from '@dataportal/front-api';
import { Logger } from '@dataportal/front-shared';
import type {
  DatalakeColumnType,
  GuardianCheckConstraint,
  IColumnField,
  IGuardianChecksDatasetMap,
  IGuardianStepCheckColumns,
  ISnowflakeTableInfo,
  SnowflakeColumnType,
} from '@dataportal/guardian-utils';
import {
  datalakeColumnTypes,
  DatalakeConfirmModalComponent,
  GuardianFormService,
  GuardianService,
  ISupportedGuardianChecksResource,
  snowflakeColumnTypes,
} from '@dataportal/guardian-utils';
import type { FormArray } from '@ngneat/reactive-forms';
import { FormBuilder, FormGroup } from '@ngneat/reactive-forms';
import { ToastrService } from 'ngx-toastr';
import { v4 as uuid } from 'uuid';

@Component({
  selector: 'app-guardian-step-check-columns',
  templateUrl: './guardian-step-check-columns.component.html',
  styleUrls: ['./guardian-step-check-columns.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GuardianStepCheckColumnsComponent<R extends ISupportedGuardianChecksResource>
  implements OnInit, OnDestroy
{
  // inputs
  @Input() formReady$: Observable<boolean>;
  @Input() formGroup: FormGroup<IGuardianStepCheckColumns>;
  @Input() sourceId: string;
  @Input() dataset: IGuardianChecksDatasetMap[R];
  @Input() checkType: ISupportedGuardianChecksResource;
  @Input() preparedRegexValues: Array<{ label: string; value: string }> = [];
  @Input() snowflakeReferentialsValues: Array<{ label: string; value: IAPITableReferential }> = [];

  isFormReady = false;
  isSnowflakeReferentialEqual: (value1: APIReferential, value2: APIReferential) => boolean;

  private readonly _destroyed$ = new Subject<void>();

  constructor(
    private readonly _logger: Logger,
    private readonly _dialogsService: DialogsService,
    private readonly _amundsenService: AmundsenService,
    readonly guardianFormService: GuardianFormService,
    private readonly _toastrService: ToastrService,
    private readonly _formBuilder: FormBuilder,
  ) {
    this.isSnowflakeReferentialEqual = GuardianService.isSnowflakeReferentialEqual;
  }

  ngOnInit(): void {
    this.formReady$.pipe(takeUntil(this._destroyed$)).subscribe((isFormReady) => {
      this.isFormReady = isFormReady;
    });

    if (!this.fieldsChecksFormArray.length) {
      this._appendEmptyFieldsChecks();
    }
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
  }

  get fieldsChecksFormArray(): FormArray<IColumnField> {
    return this.formGroup.get<'columnsToCheck'>(['columnsToCheck']) as FormArray;
  }

  trackByFunction(index: number, item: FormGroup<IColumnField>) {
    return item.value.columnId;
  }

  private _getEmptyFieldCheckFormGroup(): FormGroup<IColumnField> {
    return this._formBuilder.group({
      columnId: uuid(),
      columnName: ['', [Validators.required]],
      type:
        this.checkType === 'datalakePath'
          ? (datalakeColumnTypes[0].value as DatalakeColumnType)
          : (snowflakeColumnTypes[0].value as SnowflakeColumnType),
      isRequired: false,
      isUnique: false,
      minValue: null,
      maxValue: null,
      greaterThanColumnId: null,
      greaterThanColumnName: null,
      regexReferential: 'manual-regex',
      regex: '',
      manualRegex: '',
      preparedRegex: '',
      snowflakeExternalReferential: this.snowflakeReferentialsValues[0].value,
      datalakeInExternalReferential: null,
      datalakeNotInExternalReferential: null,
      datalakePathIn: null,
      datalakePathNotIn: null,
      addedConstraints: [[] as GuardianCheckConstraint[]],
      availableConstraints: [GuardianService.getAvailableConstraintsFromAddedConstraints([], null)],
    }) as FormGroup<IColumnField>;
  }

  private _getDuplicatedFieldCheckFormGroup(fieldsChecks: FormGroup<IColumnField>): FormGroup<IColumnField> {
    const previousColumnName = fieldsChecks.get('columnName')?.value as string;
    const columnNameToPut = previousColumnName?.length ? `${previousColumnName} -` : '';

    return this._formBuilder.group({
      columnId: uuid(),
      columnName: [columnNameToPut, [Validators.required]],
      type: fieldsChecks.get('type').value,
      isRequired: fieldsChecks.get('isRequired').value,
      isUnique: fieldsChecks.get('isUnique').value,
      minValue: fieldsChecks.get('minValue').value,
      maxValue: fieldsChecks.get('maxValue').value,
      greaterThanColumnId: fieldsChecks.get('greaterThanColumnId').value,
      greaterThanColumnName: fieldsChecks.get('greaterThanColumnName').value,
      regexReferential: [fieldsChecks.get('regexReferential').value],
      regex: fieldsChecks.get('regex').value,
      manualRegex: fieldsChecks.get('manualRegex').value,
      preparedRegex: fieldsChecks.get('preparedRegex').value,
      snowflakeExternalReferential: fieldsChecks.get('snowflakeExternalReferential').value,
      datalakeInExternalReferential: fieldsChecks.get('datalakeInExternalReferential').value,
      datalakeNotInExternalReferential: fieldsChecks.get('datalakeNotInExternalReferential').value,
      datalakePathIn: fieldsChecks.get('datalakePathIn').value,
      datalakePathNotIn: fieldsChecks.get('datalakePathNotIn').value,
      addedConstraints: [fieldsChecks.get('addedConstraints').value],
      availableConstraints: [fieldsChecks.get('availableConstraints').value],
    }) as FormGroup<IColumnField>;
  }

  private _setFocusToColumnAt(index: number): void {
    setTimeout(() => {
      const elements = document.getElementsByClassName('column-name-input');
      const currentElement = elements?.length ? elements[index] : null;
      const currentElementHTMLInputs = currentElement ? currentElement.getElementsByTagName('input') : null;

      if (currentElementHTMLInputs?.length) {
        currentElementHTMLInputs[0].focus();
      }
    }, 1); // wait a tick until DOM is updated
  }

  private _appendFieldsChecksAtIndex(index: number, fieldsChecks: FormGroup<IColumnField>): void {
    this.fieldsChecksFormArray.insert(index, fieldsChecks);
  }

  private _appendEmptyFieldsChecksAtIndex(index: number): void {
    const emptyFieldCheck = this._getEmptyFieldCheckFormGroup();
    this._appendFieldsChecksAtIndex(index, emptyFieldCheck);
    emptyFieldCheck.markAllAsDirty();
    emptyFieldCheck.markAsDirty();
    this._setFocusToColumnAt(index);
  }

  private _appendEmptyFieldsChecks(): void {
    this._appendEmptyFieldsChecksAtIndex(this.fieldsChecksFormArray.length);
  }

  removeColumnCheck(index: number) {
    this.fieldsChecksFormArray.removeAt(index);
  }

  addColumnCheckAfterIndex(index: number): void {
    this._appendEmptyFieldsChecksAtIndex(index + 1);
  }

  duplicateColumnCheckAfterIndex(index: number): void {
    const newIndex = index + 1;
    const duplicatedFieldCheck = this._getDuplicatedFieldCheckFormGroup(
      this.fieldsChecksFormArray.at(index) as FormGroup<IColumnField>,
    );
    this._appendFieldsChecksAtIndex(newIndex, duplicatedFieldCheck);
    duplicatedFieldCheck.markAllAsDirty();
    duplicatedFieldCheck.markAsDirty();
    this._setFocusToColumnAt(newIndex);
  }

  private _swapInArray(array: IColumnField[], index1: number, index2: number): IColumnField[] {
    const arrayCopy = [...array];
    const elemTmp = arrayCopy[index1];
    arrayCopy[index1] = arrayCopy[index2];
    arrayCopy[index2] = elemTmp;

    return arrayCopy;
  }

  moveUp(index: number): void {
    if (index > 0) {
      const oldFormArrayValue = this.fieldsChecksFormArray.value;
      const newFormArrayValue = this._swapInArray(oldFormArrayValue, index - 1, index);
      this.fieldsChecksFormArray.setValue(newFormArrayValue);
    }
  }

  moveDown(index: number): void {
    const oldFormArrayValue = this.fieldsChecksFormArray.value;

    if (index < oldFormArrayValue.length - 1) {
      const newFormArrayValue = this._swapInArray(oldFormArrayValue, index, index + 1);
      this.fieldsChecksFormArray.setValue(newFormArrayValue);
    }
  }

  private _insertInArray(array: IColumnField[], fromIndex: number, toIndex: number): IColumnField[] {
    const arrayCopy = [...array];
    const elemTmp = arrayCopy[fromIndex];
    arrayCopy.splice(fromIndex, 1);
    arrayCopy.splice(toIndex, 0, elemTmp);

    return arrayCopy;
  }

  reorderField(dropEvent: CdkDragDrop<unknown>): void {
    const oldFormArrayValue = this.fieldsChecksFormArray.value;

    if (
      dropEvent.previousIndex === dropEvent.currentIndex ||
      dropEvent.previousIndex < 0 ||
      dropEvent.previousIndex >= oldFormArrayValue.length ||
      dropEvent.currentIndex < 0 ||
      dropEvent.currentIndex >= oldFormArrayValue.length
    ) {
      return;
    }

    const newFormArrayValue = this._insertInArray(oldFormArrayValue, dropEvent.previousIndex, dropEvent.currentIndex);
    this.fieldsChecksFormArray.setValue(newFormArrayValue);
  }

  private _addColumnFromMetadata(tableMetadata: IAmundsenTableMetadata, columnToKeep?: IColumnField<'snowflake'>[]) {
    if (tableMetadata?.columns) {
      if (tableMetadata?.columns.length > 500) {
        this._toastrService.error(
          'This table contains more than 500 columns. Due to performance reasons, prefill feature is not available.',
        );
      } else {
        tableMetadata.columns.map((column) => {
          const columnType =
            column.col_type === 'number' ? 'BIGINT' : (column.col_type.toUpperCase() as SnowflakeColumnType);
          const alreadyExistingConstraint = columnToKeep?.filter(
            (field) => field.columnName.toUpperCase() === column.name.toUpperCase(),
          );
          let tableColumns;

          if (!alreadyExistingConstraint?.length) {
            tableColumns = this._formBuilder.group({
              columnId: uuid(),
              columnName: [column.name, [Validators.required]],
              type: columnType,
              isRequired: !column.is_nullable,
              isUnique: false,
              minValue: null,
              maxValue: null,
              greaterThanColumnId: null,
              greaterThanColumnName: null,
              regexReferential: 'manual-regex',
              regex: '',
              manualRegex: '',
              preparedRegex: '',
              snowflakeExternalReferential: null,
              datalakeInExternalReferential: null,
              datalakeNotInExternalReferential: null,
              datalakePathIn: null,
              datalakePathNotIn: null,
              addedConstraints: [['type', 'isRequired'] as GuardianCheckConstraint[]],
              availableConstraints: [
                GuardianService.getAvailableConstraintsFromAddedConstraints(['type', 'isRequired'], columnType),
              ],
            }) as FormGroup<IColumnField<'snowflake'>>;
          } else {
            const addedConstraint: GuardianCheckConstraint[] = [
              ...alreadyExistingConstraint[0].addedConstraints,
              'type',
              'isRequired',
            ];
            tableColumns = this._formBuilder.group({
              columnId: alreadyExistingConstraint[0].columnId,
              columnName: [alreadyExistingConstraint[0].columnName, [Validators.required]],
              type: alreadyExistingConstraint[0].type ?? columnType,
              isRequired: alreadyExistingConstraint[0].isRequired
                ? alreadyExistingConstraint[0].isRequired
                : !column.is_nullable,
              isUnique: alreadyExistingConstraint[0].isUnique,
              minValue: alreadyExistingConstraint[0].minValue,
              maxValue: alreadyExistingConstraint[0].maxValue,
              greaterThanColumnId: alreadyExistingConstraint[0].greaterThanColumnId,
              greaterThanColumnName: alreadyExistingConstraint[0].greaterThanColumnName,
              regexReferential: alreadyExistingConstraint[0].regexReferential,
              regex: alreadyExistingConstraint[0].regex,
              manualRegex: alreadyExistingConstraint[0].manualRegex,
              preparedRegex: alreadyExistingConstraint[0].preparedRegex,
              snowflakeExternalReferential: alreadyExistingConstraint[0].snowflakeExternalReferential,
              datalakeInExternalReferential: alreadyExistingConstraint[0].datalakeInExternalReferential,
              datalakeNotInExternalReferential: alreadyExistingConstraint[0].datalakeNotInExternalReferential,
              datalakePathIn: alreadyExistingConstraint[0].datalakePathIn,
              datalakePathNotIn: alreadyExistingConstraint[0].datalakePathNotIn,
              addedConstraints: [addedConstraint],
              availableConstraints: [
                GuardianService.getAvailableConstraintsFromAddedConstraints(addedConstraint, columnType),
              ],
            }) as FormGroup<IColumnField<'snowflake'>>;
          }

          this.fieldsChecksFormArray.push(tableColumns);
        });
      }
    } else {
      this._toastrService.error("This table doesn't exist anymore. There is no metadata to fetch anymore.");
    }
  }

  openPrefillColumns() {
    const tableKey = this._amundsenService
      .getAmundsenTableKey(this.dataset as ISnowflakeTableInfo)
      .replace('Table:', '');

    if (this.fieldsChecksFormArray.length === 1 && !this.fieldsChecksFormArray.value[0].columnName.length) {
      this._amundsenService
        .getAmundsenTableMetadata(tableKey, this.sourceId)
        .pipe(take(1), takeUntil(this._destroyed$))
        .subscribe((tableMetadata) => {
          if (tableMetadata?.columns) {
            this.fieldsChecksFormArray.removeAt(0);
            this._addColumnFromMetadata(tableMetadata);
          }
        });
    } else {
      this._dialogsService
        .open<DatalakeConfirmModalComponent, boolean>(
          DatalakeConfirmModalComponent,
          {
            headerMsg: 'Prefill table columns',
            headerMsgSize: '20',
            msgCenter: false,
            singleChoiceBodyMsg:
              'Prefilling your table columns will remove columns and constraints that are not matching as well as empty ones.',
            secondarySingleChoiceBodyMsg: 'Are you sure you want to proceed ?',
            isSquareButton: false,
            validateButtonText: 'Confirm',
            headerBackgroundColor: 'blue-dark',
            closeButtonColor: 'white',
          },
          {
            width: '601px',
            minWidth: '601px',
            maxHeight: '98vh',
            backdropClass: 'modal-nested',
          },
        )
        .pipe(
          take(1),
          takeUntil(this._destroyed$),
          mergeMap((hasToPrefill) => {
            return hasToPrefill ? this._amundsenService.getAmundsenTableMetadata(tableKey, this.sourceId) : of(null);
          }),
        )
        .subscribe((tableMetadata) => {
          if (tableMetadata?.columns) {
            const columnToKeep = this.fieldsChecksFormArray.value.filter((actualColumn) =>
              tableMetadata.columns.some(
                (metadataColumn) => actualColumn.columnName.toUpperCase() === metadataColumn.name.toUpperCase(),
              ),
            ) as IColumnField<'snowflake'>[];
            this.fieldsChecksFormArray.clear();
            this._addColumnFromMetadata(tableMetadata, columnToKeep);
          }
        });
    }
  }
}
