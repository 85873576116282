import type { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { of } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { Logger } from '@dataportal/front-shared';
import { ProfileService } from '@dataportal/profile';

import { DocumentationService } from '../../../../../../libs/dataportal-data-academy/src/lib/services/documentation.service';
import { ProductService } from '../../../../../../libs/dataportal-data-academy/src/lib/services/product.service';
import { HelpCenterService } from '../help-center.service';

import type { IDocumentation } from '../../../../../../libs/dataportal-data-academy/src/lib/models/documentation.model';
import type { OneProductModel } from '../../../../../../libs/dataportal-data-academy/src/lib/models/product.model';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
})
export class FaqComponent implements OnInit {
  product: OneProductModel;
  loadingFAQ: boolean;
  faqEntriesList: IDocumentation[];

  constructor(
    private readonly _profileService: ProfileService,
    private readonly _logger: Logger,
    private readonly _productService: ProductService,
    private readonly _route: ActivatedRoute,
    private readonly _documentationService: DocumentationService,
    private readonly _helpCenterService: HelpCenterService,
  ) {}

  ngOnInit() {
    this._productService
      .getProduct$(this._helpCenterService.dataPortalID)
      .pipe(
        take(1),
        catchError(() => {
          this.loadingFAQ = false;

          return of(null);
        }),
      )
      .subscribe((product) => {
        this.product = product;
        this.faqEntriesList = this.product.user_doc_faq;
        this.loadingFAQ = false;
      });
  }

  faqAction(item: IDocumentation) {
    this._documentationService.openDocumentation(item);
  }

  openTeamsUpdatesChannel() {
    window.open(this.product.updatesChannel, '_blank');
  }
}
