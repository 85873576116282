<adl-table
  [bodyHorizontalPadding]="bodyHorizontalPadding"
  [hasBoxShadow]="true"
  [hasHoveringAnimation]="hasHoveringAnimation"
  [hasRoundedBorder]="true"
  [maxBodyHeight]="tableWidth"
  [title]="tableTitle"
  bodyColor="white"
  class=""
  data-cy="admin-portal-table"
  headBodySeparatorColor="light-grey"
  headColor="white"
>
  <ng-container *ngFor="let header of headers; let i = index" class="flex" table-header>
    <th *ngIf="!hasCustomHeader" class="text-left" [style.width]="colWidths[i] ? colWidths[i] : 'auto'">
      {{ header }}
    </th>
  </ng-container>
  <ng-container *ngIf="hasCustomHeader" table-header>
    <ng-content select="[table-header]"></ng-content>
  </ng-container>
  <ng-container table-body>
    <ng-content select="[table-tr]"></ng-content>
  </ng-container>
</adl-table>
