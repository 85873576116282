import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ApiService } from '@dataportal/front-api';
import type { ISubCategory } from '@dataportal/types';
import { EntityBuilder } from '@decahedron/entity';

import { CategoriesModule } from '../categories.module';

import { CategoriesService } from './categories.service';

import type {
  CustomCategory,
  ISubCategoryEager,
  ISubCategoryGroupedByParent,
  RootCategory,
} from '../entities/category';
import { SubCategory } from '../entities/category';

@Injectable({
  providedIn: CategoriesModule,
})
export class SubCategoriesService {
  constructor(private readonly apiService: ApiService, private readonly categoriesService: CategoriesService) {
    this.categoriesService.rootCategories$.subscribe((rc) => {
      this._rootCategories = rc;
      this._formatCategories();
    });
    this.categoriesService.customCategories$.subscribe((cc) => {
      this._customCategories = cc;
      this._formatCategories();
    });
    this._allCategories$.subscribe(() => this._formatCategories());
    this._enabledCategories$.subscribe(() => this._formatCategories());
  }

  private _rootCategories: RootCategory[] = [];

  private _customCategories: CustomCategory[] = [];

  private readonly _allCategories$: BehaviorSubject<SubCategory[]> = new BehaviorSubject([]);

  private readonly _enabledCategories$: BehaviorSubject<SubCategory[]> = new BehaviorSubject([]);

  private readonly _formattedCategories$: BehaviorSubject<ISubCategoryGroupedByParent[]> = new BehaviorSubject([]);

  allCategories$ = this._allCategories$.asObservable();
  portalCategories$ = this._enabledCategories$.asObservable();
  formattedCategories$ = this._formattedCategories$.asObservable();

  list(): Observable<SubCategory[]> {
    return this.apiService
      .get<ISubCategoryEager[]>('/v4/sub-categories')
      .pipe(
        map((response) => {
          return EntityBuilder.buildMany<SubCategory>(
            SubCategory,
            response.map((r) => ({ ...r.category, portals: r.portals })),
          );
        }),
      )
      .pipe(
        tap((subcategories) => {
          SubCategoriesService._sortSubcategories(subcategories);
          this._allCategories$.next(subcategories);
        }),
      );
  }

  listByPortal(portalId: string): Observable<SubCategory[]> {
    return this.apiService.get<ISubCategory[]>(`/v4/sub-categories/portals/${portalId}`).pipe(
      map((response) => EntityBuilder.buildMany<SubCategory>(SubCategory, response)),
      tap((subcategories) => {
        SubCategoriesService._sortSubcategories(subcategories);
        this._enabledCategories$.next(subcategories);
      }),
    );
  }

  enable(subcategoryId: string, portalId: string): Observable<void> {
    return this.apiService.post(`/v4/sub-categories/${subcategoryId}/portals/${portalId}`, {});
  }

  disable(subcategoryId: string, portalId: string): Observable<void> {
    return this.apiService.delete(`/v4/sub-categories/${subcategoryId}/portals/${portalId}`);
  }

  create(subCategory: { name: string; parent: string }): Observable<void> {
    return this.apiService.post('/v4/sub-categories', subCategory);
  }

  delete(id: string): Observable<any> {
    return this.apiService.delete(`/v4/sub-categories/${id}`);
  }

  update(
    id: string,
    subCategory: {
      name: string;
      parent: string;
    },
  ): Observable<any> {
    return this.apiService.put(`/v4/sub-categories/${id}`, subCategory);
  }

  private _formatCategories(): void {
    const allCategories: Map<string, SubCategory> = new Map();
    const enabledCategories: Map<string, SubCategory> = new Map();
    const disabledCategories: Map<string, SubCategory> = new Map();

    this._fillmap(allCategories, this._allCategories$.value);
    this._fillmap(enabledCategories, this._enabledCategories$.value);

    allCategories.forEach((subcategory, id) => {
      if (!enabledCategories.has(id)) {
        disabledCategories.set(id, subcategory);
      }
    });

    const categories = this._formatCategory(
      Array.from(enabledCategories.values()),
      Array.from(disabledCategories.values()),
    );

    this._sortFormattedSubcategories(categories);
    this._formattedCategories$.next(categories);
  }

  private static _sortSubcategories(subcategories: SubCategory[]): void {
    subcategories.sort((c1, c2) => c1.name.localeCompare(c2.name));
    subcategories.forEach((c) => {
      if (c.portals) {
        c.portals.sort((p1, p2) => p1.portal.name.localeCompare(p2.portal.name));
      }
    });
  }

  private _sortFormattedSubcategories(formattedSubcategories: ISubCategoryGroupedByParent[]): void {
    formattedSubcategories.sort((f1, f2) => f1.parent.name.localeCompare(f2.parent.name));
    formattedSubcategories.forEach((f) => {
      SubCategoriesService._sortSubcategories(f.disabled);
      SubCategoriesService._sortSubcategories(f.enabled);
    });
  }

  private _fillmap(toFill: Map<string, SubCategory>, subcategories: SubCategory[]): void {
    for (const subcategory of subcategories) {
      toFill.set(subcategory.id, subcategory);
    }
  }

  private _formatCategory(enabled: SubCategory[], disabled: SubCategory[]): ISubCategoryGroupedByParent[] {
    const categories: Map<string, { enabled: SubCategory[]; disabled: SubCategory[] }> = new Map();

    this._initParentCategoryMap(categories);
    this._fillParentCategoryMap(categories, enabled, 'enabled');
    this._fillParentCategoryMap(categories, disabled, 'disabled');

    const formattedCategories: ISubCategoryGroupedByParent[] = [];
    categories.forEach((sortedSubcategories, parent) => {
      if (this.categoriesService.findCategory(parent)) {
        formattedCategories.push({
          parent: this.categoriesService.findCategory(parent),
          enabled: sortedSubcategories.enabled,
          disabled: sortedSubcategories.disabled,
        });
      }
    });

    return formattedCategories;
  }

  private _initParentCategoryMap(parentMap: Map<string, { enabled: SubCategory[]; disabled: SubCategory[] }>): void {
    for (const rootCategory of this._rootCategories) {
      parentMap.set(rootCategory.id, { enabled: [], disabled: [] });
    }

    for (const customCategory of this._customCategories) {
      parentMap.set(customCategory.id, {
        enabled: [],
        disabled: [],
      });
    }
  }

  private _fillParentCategoryMap(
    parentMap: Map<string, { enabled: SubCategory[]; disabled: SubCategory[] }>,
    subcategories: SubCategory[],
    key: 'enabled' | 'disabled',
  ): void {
    for (const subcategory of subcategories) {
      if (parentMap.has(subcategory.parent)) {
        const categoryChildren = parentMap.get(subcategory.parent);

        if (categoryChildren[key] && Array.isArray(categoryChildren[key])) {
          categoryChildren[key].push(subcategory);
        } else {
          categoryChildren[key] = [subcategory];
        }
      } else {
        const categoryChidlren: {
          enabled: SubCategory[];
          disabled: SubCategory[];
        } = {
          enabled: [],
          disabled: [],
        };
        categoryChidlren[key] = [subcategory];
        parentMap.set(subcategory.parent, categoryChidlren);
      }
    }
  }
}
