import type { OnDestroy } from '@angular/core';
import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import type { IGenericFilter, IGenericFilterItem } from '@dataportal/adl';
import { SourceDataSourcing } from '@dataportal/types';
import type { LimitedUser } from '@dataportal/users';
import { FormControl } from '@ngneat/reactive-forms';
import { cloneDeep } from 'lodash';

import { CatalogV2SearchService } from '../../../../services/v2/search.service';

import { contactTypes } from '../../../../services/v2/catalog.constants';

@Component({
  selector: 'dpg-catalog-advanced-filters-modal',
  templateUrl: './catalog-advanced-filters-modal.component.html',
  styleUrls: ['./catalog-advanced-filters-modal.component.scss'],
})
export class CatalogAdvancedFiltersModalComponent implements OnDestroy {
  contactTypes = [];
  contactInfos: FormControl<LimitedUser> = new FormControl(null);
  schema = new FormControl('');
  column = new FormControl('');
  database = new FormControl('');
  tableName = new FormControl('');
  dateFrom = new FormControl('');
  dateTo = new FormControl('');
  dataSourcing = new FormControl('');
  contactTypeNameMapping: { value: string; label: string }[] = [
    {
      label: 'Data Product Owner',
      value: contactTypes.FUNCTIONAL_OWNERS,
    },
    {
      label: 'Tribe Product Lead',
      value: contactTypes.TECHNICAL_OWNERS,
    },
    {
      label: 'Data Business Owner',
      value: contactTypes.DATA_OWNER,
    },
    {
      label: 'Data Product Creator',
      value: contactTypes.DATA_ASSET_CREATOR,
    },
  ];
  dataSourcingOptions: { value: SourceDataSourcing; label: string }[] = [
    {
      label: 'Open Data',
      value: SourceDataSourcing.OPEN,
    },
    {
      label: 'Accor Data',
      value: SourceDataSourcing.INTERNAL_PR,
    },
    {
      label: 'Third Party Data',
      value: SourceDataSourcing.THIRD_PARTY,
    },
    {
      label: 'Data Mix',
      value: SourceDataSourcing.MIX,
    },
  ];

  contactTypesEnum: typeof contactTypes = contactTypes;

  private readonly _destroyed$ = new Subject<void>();

  // Constructor
  constructor(
    private readonly _activeMatModal: MatDialogRef<CatalogAdvancedFiltersModalComponent>,
    private readonly _searchService: CatalogV2SearchService,
  ) {}

  ngOnDestroy() {
    this._destroyed$.next();
  }

  private static _uncheckAllFilters(filterItems: IGenericFilterItem[]) {
    filterItems.forEach((item) => (item.checked = false));
  }

  private static _createDate(date: string): string {
    if (!date) {
      return '';
    }

    return new Date(date).toLocaleDateString('fr');
  }

  clearContacts() {
    this.contactTypes = [];
    this.contactInfos.setValue(null);
  }

  clearDates() {
    this.dateFrom.setValue('');
    this.dateTo.setValue('');
  }

  clearDataSourcing() {
    this.dataSourcing.setValue('');
  }

  clearMetadata() {
    this.schema.setValue('');
    this.column.setValue('');
    this.database.setValue('');
    this.tableName.setValue('');
  }

  applyFilters() {
    this._searchService.filters$.pipe(take(1)).subscribe((filters: IGenericFilter[]) => {
      // create initial filters in the advanced filters modal
      this.createContactFilterItem(filters);
      this.createMetadataFilterItem(filters);
      this.createDateFilterItem(filters);
      this.createDataSourcingFilterItem(filters);
      this._searchService.updateFilters(filters);

      this.closeModal();
    });
  }

  handleContactTypeChange(changedType: string, isChecked: boolean) {
    if (!isChecked) {
      const itemIndex = this.contactTypes.findIndex((type) => type !== changedType);
      this.contactTypes.splice(itemIndex, 1);
    } else {
      this.contactTypes.push(changedType);
    }
  }

  createContactFilterItem(allFilters: IGenericFilter[]): void {
    if (this.contactTypes && this.contactInfos.value) {
      let ownerContactFilter = allFilters.find((filter: IGenericFilter) => filter.title === 'CONTACT');

      if (this.contactTypes.length === 0) {
        this.contactTypes = [
          contactTypes.FUNCTIONAL_OWNERS,
          contactTypes.TECHNICAL_OWNERS,
          contactTypes.DATA_OWNER,
          contactTypes.DATA_ASSET_CREATOR,
        ];
      }

      this.contactTypes.forEach((type) => {
        const contactTypeObject = this.contactTypeNameMapping.find((typeOption) => type === typeOption.value);

        const ownerContactFilterItem: IGenericFilterItem = {
          label: this.contactInfos.value.id,
          value: contactTypeObject.value,
          description: contactTypeObject.label,
          checked: true,
        };

        if (!ownerContactFilter) {
          allFilters.push({
            title: 'CONTACT',
            open: true,
            displayMore: false,
            advancedFilter: true,
            type: 'checkbox',
            filterItems: [ownerContactFilterItem],
          });

          ownerContactFilter = allFilters[allFilters.length - 1];
        }

        const isAlreadyContainsItem = ownerContactFilter.filterItems.some(
          (filterItem: IGenericFilterItem) =>
            filterItem.value === ownerContactFilterItem.value && filterItem.label === ownerContactFilterItem.label,
        );

        if (!isAlreadyContainsItem) {
          ownerContactFilter.filterItems.push(ownerContactFilterItem);
        }

        ownerContactFilterItem.removeSelf = () =>
          this._searchService.removeSelfFilterItem(ownerContactFilter, ownerContactFilterItem);
      });

      const cookieFilterItem = cloneDeep(ownerContactFilter);
      CatalogAdvancedFiltersModalComponent._uncheckAllFilters(cookieFilterItem.filterItems);
      // We want to save and load the filters everytime the user visits the page
      localStorage.setItem('dc-contact-filters', JSON.stringify(cookieFilterItem));
    }
  }

  createMetadataFilterItem(filters: IGenericFilter[]): void {
    let metadataFilter = filters.find((filter: IGenericFilter) => filter.title === 'METADATA');

    if (this.tableName.value || this.schema.value || this.database.value) {
      if (!metadataFilter) {
        filters.push({
          title: 'METADATA',
          open: true,
          displayMore: false,
          advancedFilter: true,
          filterItems: [],
        });
        metadataFilter = filters[filters.length - 1];
      }

      if (
        this.schema.value &&
        metadataFilter.filterItems.findIndex(
          (filterItem) => filterItem.label === this.schema.value && filterItem.description === 'Schema',
        ) === -1
      ) {
        const metadataFilterItem: IGenericFilterItem = {
          label: this.schema.value,
          value: 'schemaName',
          description: 'Schema',
          checked: true,
        };
        metadataFilterItem.removeSelf = () =>
          this._searchService.removeSelfFilterItem(metadataFilter, metadataFilterItem);

        metadataFilter.filterItems.push(metadataFilterItem);
      }

      if (
        this.database.value &&
        metadataFilter.filterItems.findIndex(
          (filterItem) => filterItem.label === this.database.value && filterItem.description === 'Database',
        ) === -1
      ) {
        const metadataFilterItem: IGenericFilterItem = {
          label: this.database.value,
          value: 'databaseName',
          description: 'Database',
          checked: true,
        };
        metadataFilterItem.removeSelf = () =>
          this._searchService.removeSelfFilterItem(metadataFilter, metadataFilterItem);

        metadataFilter.filterItems.push(metadataFilterItem);
      }

      if (
        this.tableName.value &&
        metadataFilter.filterItems.findIndex(
          (filterItem) => filterItem.label === this.tableName.value && filterItem.description === 'Table name',
        ) === -1
      ) {
        const metadataFilterItem: IGenericFilterItem = {
          label: this.tableName.value,
          value: 'tableName',
          description: 'Table name',
          checked: true,
        };
        metadataFilterItem.removeSelf = () =>
          this._searchService.removeSelfFilterItem(metadataFilter, metadataFilterItem);

        metadataFilter.filterItems.push(metadataFilterItem);
      }

      const cookieFilterItem = cloneDeep(metadataFilter);
      CatalogAdvancedFiltersModalComponent._uncheckAllFilters(cookieFilterItem.filterItems);
      localStorage.setItem('dc-metadata-filters', JSON.stringify(cookieFilterItem));
    }
  }

  createDateFilterItem(filters: IGenericFilter[]): void {
    if (this.dateFrom.value || this.dateTo.value) {
      const dateFrom = CatalogAdvancedFiltersModalComponent._createDate(this.dateFrom.value);
      const dateTo = CatalogAdvancedFiltersModalComponent._createDate(this.dateTo.value);
      let separator = '';
      let dateTypeIdentifier = '';

      if (dateFrom && dateTo) {
        separator = ' - ';
      } else {
        dateTypeIdentifier = dateFrom ? 'from' : 'to';
      }

      let dateFilter = filters.find((filter: IGenericFilter) => filter.title === 'DATES');
      const filterName = `${dateFrom}${separator}${dateTo}`;
      const dateIdentifiers = `${this.dateFrom.value}${separator}${this.dateTo.value}`;

      if (!dateFilter) {
        filters.push({
          title: 'DATES',
          open: true,
          displayMore: false,
          advancedFilter: true,
          filterItems: [],
        });

        dateFilter = filters[filters.length - 1];
      }

      if (
        dateFilter.filterItems.findIndex(
          (filterItem) => filterItem.label === filterName && filterItem.value === dateIdentifiers,
        ) === -1
      ) {
        const dateFilterItem: IGenericFilterItem = {
          label: filterName,
          value: dateIdentifiers,
          description: dateTypeIdentifier,
          checked: true,
        };
        dateFilterItem.removeSelf = () => this._searchService.removeSelfFilterItem(dateFilter, dateFilterItem);

        dateFilter.filterItems.push(dateFilterItem);
      }

      const cookieFilterItem = cloneDeep(dateFilter);
      CatalogAdvancedFiltersModalComponent._uncheckAllFilters(cookieFilterItem.filterItems);
      localStorage.setItem('dc-dates-filters', JSON.stringify(cookieFilterItem));
    }
  }

  createDataSourcingFilterItem(filters: IGenericFilter[]): void {
    if (this.dataSourcing.value) {
      let dataSourcingFilter = filters.find((filter: IGenericFilter) => filter.title === 'DATA SOURCING');
      const sourcingName = this.dataSourcingOptions.find((c) => c.value === this.dataSourcing.value).label;

      if (!dataSourcingFilter) {
        filters.push({
          title: 'DATA SOURCING',
          open: true,
          displayMore: false,
          advancedFilter: true,
          filterItems: [],
        });

        dataSourcingFilter = filters[filters.length - 1];
      }

      if (dataSourcingFilter.filterItems.findIndex((filterItem) => filterItem.label === sourcingName) === -1) {
        const sourcingFilterItem: IGenericFilterItem = {
          label: sourcingName,
          value: this.dataSourcing.value,
          checked: true,
        };
        sourcingFilterItem.removeSelf = () =>
          this._searchService.removeSelfFilterItem(dataSourcingFilter, sourcingFilterItem);

        dataSourcingFilter.filterItems.push(sourcingFilterItem);
      }

      const cookieFilterItem = cloneDeep(dataSourcingFilter);
      CatalogAdvancedFiltersModalComponent._uncheckAllFilters(cookieFilterItem.filterItems);
      localStorage.setItem('dc-data-sourcing-filters', JSON.stringify(cookieFilterItem));
    }
  }

  closeModal() {
    this._activeMatModal.close();
  }
}
