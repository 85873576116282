<div class="adl-selectable-form-control column-flex j-start a-center">
  <div
    class="row-flex j-start a-center w100"
    [ngClass]="{
      disabled: isDisabled
    }"
  >
    <adl-tooltip
      *ngIf="hint?.length"
      class="column-flex j-center a-start br5 c-default w30"
      [ngStyle]="labelWidth?.length && { width: labelWidth }"
      [isTooltipTriggerFullWidth]="true"
    >
      <adl-text tooltip-content type="tooltip" size="12" class="w-full tal whitespace-normal break-words">
        {{ hint }}
      </adl-text>
      <div tooltip-trigger class="adl-selectable-form-control--label column-flex j-center a-start br5 tal w100">
        <adl-text class="tal w100" [color]="labelColor" size="12">{{ label }}</adl-text>
      </div>
    </adl-tooltip>
    <div *ngIf="!hint?.length" class="column-flex j-center a-start br5 c-default w30">
      <div class="adl-selectable-form-control--label column-flex j-center a-start br5 tal w100">
        <adl-text class="tal w100" [color]="labelColor" size="12">{{ label }}</adl-text>
      </div>
    </div>

    <div *ngIf="hasRemoveBtn" class="column-flex j-center a-center w5 mt5">
      <adl-icon-button
        class="column-flex j-center a-center w100"
        icon="circle-crossed"
        iconType="second"
        size="15"
        (clicked)="removeFormControl()"
      ></adl-icon-button>
    </div>

    <div class="column-flex j-center a-center pl15 w65">
      <div class="row-flex j-start a-center w100">
        <ng-content></ng-content>
      </div>
      <div class="column-flex j-center a-start w100">
        <ul *ngIf="hasError && messages.length > 0" class="column-flex j-center a-start w100">
          <li *ngFor="let msg of messages" class="row-flex j-start a-center w100 tal">
            <adl-text class="tal" type="error" size="14">{{ msg.text }}</adl-text>
            <adl-info-bubble class="ml5" *ngIf="msg.hint" [placement]="placement">
              {{ msg.hint }}
            </adl-info-bubble>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
