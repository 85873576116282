import type { OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { tap } from 'rxjs/operators';
import type { IBucket } from '@dataportal/datalake-and-guardian';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ObjectsBrowserFacade } from '../../services/objects-browser-facade.service';

import type { IObject } from '../../entities/objects-browser';

@UntilDestroy()
@Component({
  selector: 'dpg-objects-browser',
  templateUrl: './objects-browser.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ObjectsBrowserComponent implements OnInit {
  @Input() set selectedObjects(objects: IObject[]) {
    this._facade.setObjectsSelection(objects);
  }

  private readonly _facade = inject(ObjectsBrowserFacade);

  protected readonly vm$ = this._facade.vm$;

  protected readonly objectsSearchFormControl = new FormControl();

  protected onBucketSelect(bucket: IBucket): void {
    this._facade.chooseBucket(bucket);
  }

  protected onObjectClick(object: IObject): void {
    this._facade.chooseObject(object);
  }

  protected onSelectBreadcrumb(breadcrumb: string): void {
    this._facade.navigateToBreadcrumb(breadcrumb);
  }

  protected onReturnToBucket(currentBucket: IBucket) {
    this._facade.chooseBucket(currentBucket);
  }

  protected onObjectSelectionToggle([isSelected, object]: [boolean, IObject]): void {
    this._facade.toggleObjectSelection(isSelected, object);
  }

  protected onBucketsSearchQueryChange(query: string): void {
    this._facade.setBucketsSearchQuery(query);
  }

  protected onObjectsSearchQueryChange(query: string): void {
    this._facade.setObjectsSearchQuery(query);
  }

  protected onRemoveSelectedObject(object: IObject): void {
    this._facade.toggleObjectSelection(false, object);
  }

  protected onAllObjectsSelectionToggle(shouldSelect: boolean): void {
    if (shouldSelect) {
      this._facade.selectAllCurrentObjects();
    } else {
      this._facade.deselectAllCurrentObjects();
    }
  }

  ngOnInit(): void {
    this._facade.resetBucketSelected();
    this._facade.objectsSearchQuery$
      .pipe(
        tap((query) => this.objectsSearchFormControl.setValue(query)),
        untilDestroyed(this),
      )
      .subscribe();
  }
}
