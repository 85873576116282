import type { OnDestroy } from '@angular/core';
import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Source } from '@dataportal/sources-dashboards-recommendations';

@Component({
  selector: 'dpg-source-metadata-modal',
  templateUrl: './source-metadata-modal.component.html',
  styleUrls: ['./source-metadata-modal.component.scss'],
})
export class SourceMetadataModalComponent implements OnDestroy {
  // Attributes
  @Input() source: Source;

  // Constructor
  constructor(
    private readonly _activeModal: MatDialogRef<SourceMetadataModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    data: { source: Source },
  ) {
    this.source = data?.source;
  }

  // Lifecycle
  ngOnDestroy(): void {
    // Fix the ability to scroll after closing a stacked modal (fixed in a later version of angular-bootstrap)
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('modal-open');
  }

  dismiss(): void {
    this._activeModal.close();
  }
}
