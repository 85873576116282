import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Subject } from 'rxjs';

import type { SupportedIcon } from '../../atoms/icons';

export interface IOptionsSelectorButtonChoice {
  label: string;
  value: string;
  icon?: SupportedIcon;
  tooltip?: string;
  isDisabled?: boolean;
}

@Component({
  selector: 'adl-options-selector-button',
  templateUrl: './options-selector-button.component.html',
  styleUrls: ['./options-selector-button.component.scss'],
})
export class OptionsSelectorButtonComponent {
  @Input() choices: IOptionsSelectorButtonChoice[];
  @Input() emptyMessage = '(Empty)';
  @Input() minWidth = '150px';
  @Input() triggerButtonType = 'primary';

  @Output() onChoiceSelection = new EventEmitter<string>();

  private _hasToDisableNextClick = false;
  private readonly _isHidden$ = new Subject<boolean>();
  isHidden$ = this._isHidden$.asObservable();

  @Output() onToggleTooltip = new EventEmitter<boolean>();

  selectionChoice(choice: IOptionsSelectorButtonChoice): void {
    if (choice.isDisabled) {
      this._hasToDisableNextClick = true;

      return;
    }

    this._isHidden$.next(true);
    this.onChoiceSelection.emit(choice.value);
  }
}
