<div [hidden]="!isActivated" class="sidebar">
  <ol>
    <li class="toggle-visibility">
      <a (click)="toggleVisiblity()">
        <svg xmlns="http://www.w3.org/2000/svg" width="29" height="34.03" viewBox="0 0 57 34" xml:space="preserve">
          <path
            class="svg_fill"
            d="m29.07 0.0702h-0.52s-17.81-2.1202-28.55 16.9398c10.74 19.02 28.55 16.95 28.55 16.95h0.52s17.81 2.07 28.55-16.95c-10.74-19.06-28.55-16.9398-28.55-16.9398zm0 7.8798c3.07 0 5.56 2.52 5.56 5.62 0 3.11-2.49 5.62-5.56 5.62s-5.56-2.52-5.56-5.62 2.49-5.62 5.56-5.62zm0.1 20.35h-0.34s-11.43 1.34-18.32-10.67c1.91-3.34 4.17-5.63 6.45-7.23-0.31 1.1-0.48 2.24-0.48 3.44 0 7.03 5.64 12.73 12.59 12.73 6.96 0 12.59-5.7 12.59-12.73 0-1.15-0.16-2.26-0.44-3.31 2.21 1.58 4.41 3.85 6.28 7.1-6.9 12.01-18.33 10.67-18.33 10.67z"
          />
        </svg>
        <span class="sidebar-label">Hide</span>
      </a>
    </li>
    <ng-content></ng-content>
  </ol>
</div>
<adl-button
  style="position: fixed; z-index: 250"
  class="toggle-button mt15 ml15"
  (clicked)="toggleVisiblity()"
  [hidden]="isActivated"
>
  <adl-icon icon="menu"></adl-icon>
</adl-button>
