import { Component, ContentChildren, Directive, EventEmitter, Input, Output, TemplateRef } from '@angular/core';

@Directive({
  selector: '[radioContentSelectorMarker]',
})
export class RadioContentSelectorMarkerDirective {}

@Component({
  selector: 'adl-radio-content-selector',
  templateUrl: './radio-content-selector.component.html',
  styleUrls: ['../radio-selector/radio-selector.component.scss', './radio-content-selector.component.scss'],
})
export class RadioContentSelectorComponent<T = unknown> {
  @ContentChildren(RadioContentSelectorMarkerDirective, { read: TemplateRef }) choicesContents: Array<TemplateRef<any>>;
  @Input() selectorName: string;
  @Input() choices: Array<T>;
  @Input() activeChoice: T;
  @Input() choiceComponentClassList: string[] = [];
  @Input() valueEqualityCheck: (value1: T, value2: T) => boolean = this.defaultEqualityCheck;

  @Output() changed = new EventEmitter<T>();

  get choicesContentsArray(): Array<TemplateRef<any>> {
    return Array.from(this.choicesContents || []);
  }

  defaultEqualityCheck(value1: T, value2: T): boolean {
    return value1 === value2;
  }

  selectRadio(value: T): void {
    this.activeChoice = value;
    this.changed.emit(value);
  }
}
