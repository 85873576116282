import { Component, EventEmitter, forwardRef, Input, Output, ViewChild } from '@angular/core';
import type { ControlValueAccessor } from '@angular/forms';
import { DefaultValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject } from 'rxjs';

@Component({
  selector: 'adl-select-v2',
  styleUrls: ['./select-v2.component.scss'],
  templateUrl: './select-v2.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectV2Component),
      multi: true,
    },
  ],
})
export class SelectV2Component<T> implements ControlValueAccessor {
  @Input() bindLabel: string;
  @Input() bindValue: string;
  @Input() placeholder: string;
  @Input() items: T[] = [];
  @Input() clear: Subject<void>;
  @Input() clearable = false;
  @Input() searchable = false;
  @Input() value: unknown;
  @Input() styling: 'basic' | 'cockpit' = 'basic';
  @Input() loading = false;

  @Input() multiSelect = false;
  @Input() isDisabled = false;
  @Input() closeOnSelect = true;

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() change = new EventEmitter<unknown>();
  @Output() cleared = new EventEmitter<void>();
  @Output() scrollToEnd = new EventEmitter<void>();

  @ViewChild(DefaultValueAccessor) private readonly _valueAccessor: DefaultValueAccessor;

  private readonly _destroyed$ = new Subject<void>();

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange: (_) => void = (_) => {};

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched: () => void = () => {};
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  updateChanges() {
    this.onChange(this.value);
    this.change.emit(this.value);
  }

  writeValue(value: string): void {
    this.value = value;
    this.updateChanges();
  }
}
