<router-outlet name="documentation"></router-outlet>

<div
  class="flex justify-center bg-cover relative help-center-header-content"
  [ngStyle]="{ 'background-image': 'url(' + coverImage + ')' }"
>
  <div class="blue-overlay w-full h-full absolute top-0 left-0"></div>

  <div class="help-center-header-content w-full max-w-[1620px]">
    <adl-breadcrumb
      [firstLink]="firstLink"
      breadcrumbTheme="white-yellow"
      firstLinkColor="white"
      class="absolute breadcrumb left-[50px] top-6"
    ></adl-breadcrumb>

    <div class="absolute profile-content flex items-center">
      <dpg-header-profile></dpg-header-profile>
    </div>

    <div class="flex flex-col">
      <div class="header-text flex flex-col">
        <h1 class="title">Accor Data Portal Help Center</h1>
        <span class="subtitle">Everything you need to know about the Accor Data Portal</span>
      </div>

      <div class="flex w-full justify-between items-center pl-[9%] pr-6">
        <div class="flex items-center w-5/6">
          <adl-search-bar
            class="search-bar w-4/6"
            searchBarType="advanced"
            searchBarHeight="41px"
            placeholder="What are you looking for?"
            [hasToDisplaySearchButton]="true"
            buttonSize="small"
            (searchButtonClicked)="search($event)"
          ></adl-search-bar>
          <div class="ml-12" *ngIf="faqEntriesFound">{{ faqEntriesFound }} FAQ elements</div>
        </div>
        <adl-button (click)="openTicket()" type="primary" size="thin">
          <img alt="add icon" class="mr-2" src="assets/dpg/imgs/icons/plus-icon.svg" />
          {{ isAccor ? 'I need help' : 'Open a ticket' }}
        </adl-button>
      </div>
    </div>
  </div>
</div>

<div class="py-10 px-20">
  <router-outlet></router-outlet>
</div>
