import type { OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Component, Input } from '@angular/core';

interface ITypo {
  size: number;
  color: string;
  bold: boolean;
  italic: boolean;
}

export type TextType =
  | 'normal'
  | 'tooltip'
  | 'accent'
  | 'hint'
  | 'error'
  | 'tag'
  | 'blue'
  | 'cant-file-modal'
  | 'home'
  | 'alert';

export type WeightType = 'light' | 'normal' | 'medium' | 'bold' | 'extra-bold';

@Component({
  selector: 'adl-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss'],
})
export class TextComponent implements OnInit, OnChanges {
  /* inputs */
  // optional
  @Input() type: TextType = 'normal';
  @Input() size = null;
  @Input() color = null;
  @Input() bold = null;
  @Input() weight: WeightType = 'normal';
  @Input() italic = null;
  @Input() wrap: 'inherit' | 'anywhere' = 'inherit';
  @Input() isLong = false;
  @Input() truncate = false;
  @Input() uppercase = false;
  @Input() underline = false;

  classList: string[];
  fontSize: number;

  ngOnInit(): void {
    this._refreshClassList();
  }

  ngOnChanges(_changes: SimpleChanges): void {
    this._refreshClassList();
  }

  private _refreshClassList(): void {
    const typo = this._fromType();
    const properties = ['size', 'color', 'bold', 'italic'];

    for (const prop of properties) {
      if (this[prop] != null) {
        typo[prop] = this[prop];
      }
    }

    this.classList = [typo.color];
    ['bold', 'italic'].forEach((p) => {
      if (typo[p]) {
        this.classList.push(p);
      }
    });

    if (this.wrap === 'anywhere') {
      this.classList.push('wrap-anywhere');
    }

    if (this.isLong) {
      this.classList.push('whitespace-normal', 'break-all');
    }

    switch (this.weight) {
      case 'light': {
        this.classList.push('weight-light');
        break;
      }

      case 'medium': {
        this.classList.push('weight-medium');
        break;
      }

      case 'bold': {
        this.classList.push('weight-bold');
        break;
      }

      case 'extra-bold': {
        this.classList.push('weight-extra-bold');
        break;
      }
    }

    if (this.truncate) {
      this.classList.push('truncate');
    }

    if (this.uppercase) {
      this.classList.push('uppercase');
    }

    if (this.underline) {
      this.classList.push('underline');
    }

    this.fontSize = typo.size;
  }

  private _fromType(): ITypo {
    switch (this.type) {
      case 'accent':
        return {
          size: 16,
          color: 'blue',
          bold: true,
          italic: false,
        };
      case 'hint':
        return {
          size: 12,
          color: 'light-grey',
          bold: false,
          italic: false,
        };
      case 'tooltip':
        return {
          size: 15,
          color: 'white',
          bold: false,
          italic: false,
        };
      case 'error':
        return {
          size: 16,
          color: 'red',
          bold: false,
          italic: false,
        };
      case 'tag':
        return {
          size: 10,
          color: 'white',
          bold: true,
          italic: false,
        };
      case 'blue':
        return {
          size: 16,
          color: 'blue',
          bold: false,
          italic: false,
        };
      case 'cant-file-modal':
        return {
          size: 17,
          color: 'dark-grey',
          bold: false,
          italic: false,
        };
      case 'home':
        return {
          size: 14,
          color: 'home',
          bold: false,
          italic: false,
        };
      case 'alert':
        return {
          size: 16,
          color: 'alert',
          bold: false,
          italic: false,
        };
      default:
        return {
          size: 15,
          color: 'dark-grey',
          bold: false,
          italic: false,
        };
    }
  }
}
