import { Component, Input } from '@angular/core';

import { SupportedFaIcon } from '../icons';

export type LabelTheme = 'success' | 'primary' | 'grey' | 'success-secondary' | 'alert-secondary' | 'warning';

@Component({
  selector: 'adl-is-active-label',
  templateUrl: './is-active-label.component.html',
  styleUrls: ['./is-active-label.component.scss'],
})
export class IsActiveLabelComponent {
  @Input() isActive: boolean;
  @Input() activeText: string;
  @Input() inactiveText: string;
  @Input() activeIcon: SupportedFaIcon = 'check';
  @Input() inactiveIcon: SupportedFaIcon;
  @Input() activeTheme: LabelTheme = 'success';
  @Input() inactiveTheme: LabelTheme = 'grey';
  @Input() activeImgIcon: string;
  @Input() inactiveImgIcon: string;
  @Input() size = 14;
}
