<section class="h-full grid grid-rows-[auto_1fr]">
  <header class="flex items-center justify-between mb-[11px]">
    <adl-title type="modal" [hasToForceBold]="true" class="buckets-list-title">Buckets</adl-title>

    <adl-search-bar
      placeholder="Search a bucket"
      iconType="grey-300"
      searchBarType="advanced"
      [hasBorder]="false"
      [control]="bucketsSearchFormControl"
      (changed)="bucketsSearchQueryChange.emit($event)"
    ></adl-search-bar>
  </header>

  <ul
    class="buckets-list px-[10px] py-[15px] w-[310px] bg-white shadow-[0px_5px_10px_0px_rgba(24,68,135,0.05)] rounded-[6px] max-h-[360px] overflow-y-auto">
    <adl-loaded-item [loading]="isLoading">
      <li
        *ngFor="let bucket of buckets"
        class="h-[32px] py-[10px] pr-[10px] pl-[20px] flex justify-between items-center rounded-[4px] cursor-pointer"
        [class.selected]="selectedBucket?.name === bucket.name"
        (click)="bucketSelect.emit(bucket)"
      >
        <adl-text color="grey-800" [size]="12" weight="normal">{{ bucket.name }}</adl-text>

        <!-- TODO: use adl-icon-button after fixing it's styles -->
        <button>
          <!-- TODO: fix how svg-icon is customized -->
          <adl-svg-icon icon="chevron-right-thin" iconType="grey-600"></adl-svg-icon>
        </button>
      </li>
    </adl-loaded-item>
  </ul>
</section>
