import type { Router } from '@angular/router';
import type { Navigator } from '@dataportal/navigator';
import type { DashboardType, IAllowedGroup, IDashboard, SourceContactsToNotifyType } from '@dataportal/types';
import { Entity } from '@decahedron/entity';

import type { IDashboardFolder } from './dashboard-folder.model';
import type { IDashboardStepSettings } from './dashboard-form';

export interface ITableField {
  table: string;
  column: string;
}

export enum PowerBiReportType {
  REPORT = 'report',
  PAGINATED_REPORT = 'paginated-report',
  DASHBOARD = 'dashboard',
}

// Entity
export class Dashboard extends Entity {
  // Attributes
  name: string = null;
  id: string = null;
  description: string = null;
  type: DashboardType = null;
  url: string = null;
  isHidden = false;
  image: string = null;
  parentFolder?: IDashboardFolder = null;
  maintenanceMessage: string = null;
  dataset: string = null;
  role: string = null;
  isAnalysisServices: boolean = null;
  aasCubeUrl: string = null;
  hasComments = true;
  contactsToNotifyOnDashboardComments: SourceContactsToNotifyType[] = [];
  usingUserOwnsData: boolean = null;
  allowedGroups: IAllowedGroup[] = [];
  isLastUpdateDisabled: boolean = null;
  isFilterPaneHidden: boolean = null;
  reportId?: string = null;
  groupId?: string = null;
  appId?: string = null;
  fieldsToIgnore?: ITableField[] = [];
  hasToIgnoreSpecifiedFieldsInFilters? = false;
  supportLink: string = null;
  powerBIType: 'report' | 'paginated-report' | 'dashboard' = null;

  // Statics
  static fromStepForm(formData: IDashboardStepSettings): Dashboard {
    // Build dashboard
    const dashboard = new Dashboard();
    dashboard.id = formData.id ? formData.id : null;
    dashboard.name = formData.name;
    dashboard.image = formData.thumbnail;
    dashboard.type = formData.type;
    dashboard.isHidden = formData.isHidden;

    if (formData.hasToAddIntoFolder && formData.parentFolder && Object.keys(formData.parentFolder).length) {
      dashboard.parentFolder = formData.parentFolder;
    }

    dashboard.url = formData.url;
    dashboard.dataset = formData.hasDynamicRoleRLS ? formData.datasetId : null;
    dashboard.role = formData.hasDynamicRoleRLS ? formData.roleId : null;
    dashboard.aasCubeUrl = formData.cubeURL;
    dashboard.isAnalysisServices = formData.isAAS;
    dashboard.hasComments = formData.hasComments;
    dashboard.contactsToNotifyOnDashboardComments = formData.contactsToNotifyOnDashboardComments.filter(
      (contact) => contact,
    );

    dashboard.usingUserOwnsData = formData.useNativeRLS;
    dashboard.maintenanceMessage = formData.maintenanceOn ? formData.maintenanceMessage : null;
    dashboard.isLastUpdateDisabled = formData.disableLastUpdate;
    dashboard.isFilterPaneHidden = formData.hideFiltersPane;
    dashboard.supportLink = formData.hasSupportLink ? formData.supportLink : null;
    dashboard.allowedGroups = formData.restricted
      ? formData.allowedGroups.map((g) => ({ group_id: g.id, group_name: g.name }))
      : null;

    return dashboard;
  }

  get isApp(): boolean {
    return this.appId != null;
  }

  get isExternal(): boolean {
    return this.type === 'external_link' || this.type === 'source_ready_to_explore';
  }

  // Methods
  static areDashboardFoldersEqual(dashboardFolder1: IDashboardFolder, dashboardFolder2: IDashboardFolder): boolean {
    if (!dashboardFolder1 || !dashboardFolder2) {
      return false;
    }

    if (dashboardFolder1 === dashboardFolder2) {
      return true;
    }

    return dashboardFolder1.id === dashboardFolder2.id;
  }

  // TODO: [Revisit] (18.01.2024) - Remove any
  fromJson(jsonData: Record<string, any>): Dashboard {
    if (!jsonData.allowed_groups) {
      jsonData.allowed_groups = [];
    }

    super.fromJson(jsonData);

    this.allowedGroups = jsonData.allowed_groups as IAllowedGroup[];

    if (jsonData.parent_folder) {
      this.parentFolder = {
        id: jsonData.parent_folder.id,
        name: jsonData.parent_folder.name,
        parent: jsonData.parent_folder.parent ? jsonData.parent_folder.parent : undefined,
        dashboardFolderChildren: jsonData.parent_folder.dashboard_folder_children,
      };
    }

    this.fieldsToIgnore = jsonData.fields_to_ignore ? (jsonData.fields_to_ignore as ITableField[]) : [];

    return this;
  }

  toJson(): IDashboard {
    const dashboard: Partial<IDashboard> = {
      name: this.name,
      id: this.id,
      type: this.type || 'powerbi',
      description: this.description,
      url: this.url,
      is_hidden: this.isHidden,
      image: this.image || null,
      parent_folder: this.parentFolder
        ? {
            id: this.parentFolder.id,
            name: this.parentFolder.name,
            parent: this.parentFolder.parent ? this.parentFolder.parent : null,
            dashboard_folder_children: this.parentFolder.dashboardFolderChildren,
          }
        : null,
      allowed_groups: this.allowedGroups?.length > 0 ? this.allowedGroups : [],
      maintenance_message: this.maintenanceMessage,
      has_comments: this.hasComments,
      contacts_to_notify_on_dashboard_comments: this.contactsToNotifyOnDashboardComments,
    };

    switch (dashboard.type) {
      case 'powerbi':
        return {
          ...dashboard,
          dataset: this.dataset || null,
          role: this.role || null,
          is_analysis_services: this.isAnalysisServices ?? false,
          aas_cube_url: this.isAnalysisServices ? this.aasCubeUrl : null,
          using_user_owns_data: this.usingUserOwnsData ?? false,
          is_last_update_disabled: this.isLastUpdateDisabled ?? false,
          is_filter_pane_hidden: this.isFilterPaneHidden ?? false,
          fields_to_ignore: this.fieldsToIgnore ?? [],
          has_to_ignore_specified_fields_in_filters: this.hasToIgnoreSpecifiedFieldsInFilters ?? false,
          support_link: this.supportLink,
        } as IDashboard;
      case 'tableau':
        return {
          ...dashboard,
          support_link: this.supportLink,
        } as IDashboard;
      case 'qlik':
      case 'data_studio':
      case 'datorama':
      case 'reeport':
      case 'external_link':
      case 'source_ready_to_explore':
      case 'custom':
        return dashboard as IDashboard;
    }
  }

  buildUrl(id: string, name: string, navigator: Navigator, router?: Router, href = false): string {
    if (this.isExternal) {
      return this.url;
    }

    const url = `sources/${id}/dashboard/${name}`;

    if (!href) {
      return url;
    }

    return router.serializeUrl(router.createUrlTree(url?.split('/')));
  }
}
