import { Entity } from '@decahedron/entity';

// Interface
export interface IDataProvider {
  name: string;
  type: string;
}

// Entity
/** @deprecated LEGACY not used anymore */
export class DataProvider extends Entity {
  // Attributes
  name?: string = null;
  type?: string = null;

  // Constructor
  constructor(name?: string, type?: string) {
    super();
    this.name = name;
    this.type = type;
  }

  // Methods
  toJson(): IDataProvider {
    return {
      name: this.name || null,
      type: this.type || null,
    };
  }
}
