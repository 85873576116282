// FIXME: [guardian] remove this file after publishing @dataportal/accor-types and once Guardian API is stable

import type { ICheckCommon, IDataQuality, IRequestCheckFieldOptions } from '@dataportal/front-api';

import type { IGuardianChecksDatasetMap, ISupportedGuardianChecksResource } from './guardian-form';
/* Guardian check */

// Common types

/**
 * Cron "* *¨* * *" format
 */
type Cron = string;
/**
 * Date
 * i.e: 2021-10-07T13:30:49.707000
 */
type DatetimeISO = string;

export enum GUARDIAN_ACTIONS_BUTTONS {
  EDIT_SPECIFICATIONS = 'Edit specification',
  VISUALIZE_SPECIFICATIONS = 'Visualize Specification',
  VISUALIZE_EXECUTION_HISTORY = 'Visualize execution history',
  DELETE_SPECIFICATION = 'Delete specification',
}

// Custom types

/**
 * The check run status
 */

export interface IRequestCheckOptions extends ICheckCommon {
  /**
   * The array of the Guardian check configuration columns
   * - BE CAREFUL, columns are not ordered since this is a dict
   * - Order is only specified inside IRequestCheckFieldOptionsCommon.constraint_id
   */
  validation_schema_columns?: IRequestCheckFieldOptions[];
  /**
   * @see Guardian API documentation
   * Not used for now
   */
  validation_schema_extra?: unknown;
}

export interface ICheckRequests {
  /**
   * uuid
   */
  pk: string;
  /**
   * "checks|$id_check"
   */
  sk: string;
  /**
   * user ID that requested the check
   */
  id_user: string;
  /**
   * The Guardian check configuration ID
   */
  id_check: number;
  /**
   * The Guardian check configuration name/title
   */
  name?: string;
  /**
   * Date of check request
   * i.e: 2021-10-07T13:30:49.707000
   */
  requestedAt: DatetimeISO;
  /**
   * The Guardian type of check
   * 'dataset': Check on the file dataset
   * 'up-to-date': Check on the path freshness
   */
  check_type?: 'dataset' | 'up-to-date';
  /**
   * The Guardian check run status
   */
  status: 'in_progress' | 'crashed';
  /**
   * @see IAPIGuardianCallError.message
   */
  reason?: string;
  /**
   * @see IAPIGuardianCallError.type
   */
  error_type?: string;
  /**
   * The Guardian resource
   */
  resource: ISupportedGuardianChecksResource;
}

// API types

export interface IAPIRunCheckResponse {
  /**
   * @deprecated Not used anymore
   */
  id_run?: number;
  /**
   * Guardian check configuration ID
   */
  id_check: number;
  /**
   * Date when the check was run/executed by Guardian
   * i.e: 2021-10-07T13:30:49.707000
   */
  check_time_utc: string;
  /**
   * Indicates if the freshness Guardian check is up to date or not
   */
  up_to_date: boolean;
  /**
   * @see Guardian API documentation
   * @deprecated Not used anymore
   */
  dataset_folder: string;
  /**
   * @see Guardian API documentation
   * @deprecated Not used anymore
   */
  dataset_name: string;
  /**
   * Guardian check run errors result
   * @see Guardian API documentation
   * @deprecated Seem not to be sent by Guardian API anymore
   */
  data_quality: IDataQuality;
}

export interface IAPICheckResult {
  /**
   * Guardian check run ID
   */
  id_run: number;
  /**
   * Guardian check configuration ID
   */
  id_check: number;
  /**
   * Guardian check configuration name/title
   */
  name?: string;
  /**
   * Date when the check was run/executed by Guardian
   * i.e: 2021-10-07T13:30:49.707000
   */
  check_time_utc: DatetimeISO;
  /**
   * Guardian check run errors result
   */
  data_quality: IDataQuality;
  /**
   * The Guardian type of check
   * 'dataset': Check on the file dataset
   * 'up-to-date': Check on the path freshness
   * @see Guardian API documentation for more information
   * @deprecated Seem not to be sent by Guardian API anymore
   */
  check_type: 'dataset' | 'up-to-date';
}

export interface IAPICheckUpToDate {
  /**
   * Guardian check run ID
   */
  id_run: number;
  /**
   * Guardian check configuration ID
   */
  id_check: number;
  /**
   * Guardian check configuration name/title
   */
  name: string;
  /**
   * Current checked path last update date (when a file has been uploaded/updated)
   * i.e: 2021-10-07T13:30:49.707000
   * @see Guardian API documentation for more precise information
   */
  last_updated_at: DatetimeISO;
  /**
   * Date when the current checked path must be updated (file upload/update) to meet the freshness check requirements
   * i.e: 2021-10-07T13:30:49.707000
   */
  should_next_updated_at: DatetimeISO;
  /**
   * Date when the check was run/executed by Guardian
   * i.e: 2021-10-07T13:30:49.707000
   */
  check_time_utc: DatetimeISO;
  /**
   * Indicates if the freshness check requirements are up to date or not
   */
  up_to_date: boolean;
}

export interface IAPICheckRun {
  /**
   * The list of every dataset checks associated to the check configuration
   */
  check_result: IAPICheckResult[];
  /**
   * The list of every freshness checks associated to the check configuration
   * i.e: 2021-10-07T13:30:49.707000
   */
  check_up_to_date: IAPICheckUpToDate[];
}

/* Source and guardian checks relations */

export interface IFlatfileConfig {
  /**
   * Is the check concern only Guardian or also Flatfile
   */
  isFlatfileDeactivate: boolean;
}

export interface IBaseGuardianCheck<R extends ISupportedGuardianChecksResource = ISupportedGuardianChecksResource> {
  checkId: number;
  resource: R;
  dataset: IGuardianChecksDatasetMap[R];
  datasetKey: string;
}

export interface IGuardianChecksRelatedToSource<
  R extends ISupportedGuardianChecksResource = ISupportedGuardianChecksResource,
> {
  /**
   * The source ID related to this relation object
   */
  pk: string;
  /**
   * Equals to "guardian-checks|provider|${datalakePath.provider}|tenant|${datalakePath.tenant}|path|${datalakePath.path}"
   * or "guardian-checks|snowflake|database|${snowflake.database}|schema|${snowflake.schema}|table|${snowflake.table}"
   */
  sk: string;
  /**
   * Equals to "guardian-checks|${sourceId}"
   */
  ak: string;
  /**
   * The check IDs associated to the source related to this relation object
   */
  checksId: number[];
  ressource: R;
  /**
   * The datalake provider related to this relation object
   * The datalake full path (bucket + path) related to this relation object or the snwoflake table key
   */
  dataset: IGuardianChecksDatasetMap[R];
  /**
   * Is the check concern only Guardian or also Flatfile
   */
  flatfileConfig?: IFlatfileConfig;
  isItrackEnabled: boolean;
}
