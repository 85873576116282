<adl-table
  aria-label="Guardian checks list"
  [hasRoundedBorder]="true"
  headColor="white"
  bodyColor="white"
  *ngIf="checks && checks.length > 0; else noChecks"
>
  <ng-container table-header>
    <th class="w-[45%]">Event date</th>
    <th class="w-[40%]">Message</th>
    <th class="text-center w-[15%]">Status</th>
  </ng-container>
  <ng-container table-body>
    <tr *ngFor="let check of checks" (click)="showDetails(check)" class="cursor-pointer">
      <td class="w-[45%]">{{ check.createdAt | date : 'MMM dd, y, HH:mm (z)' }}</td>
      <td class="w-[40%]">
        <ng-container [ngSwitch]="check.status">
          <adl-text *ngSwitchCase="'in_progress'">Guardian check in progress</adl-text>
          <adl-text *ngSwitchCase="'crashed'">Guardian failed to run check</adl-text>
          <adl-text *ngSwitchCase="'errored'">Guardian found errors in data</adl-text>
          <adl-text *ngSwitchCase="'succeed'">Guardian found no errors in data</adl-text>
        </ng-container>
      </td>
      <td class="check-status flex justify-center align-center w-full">
        <em class="fa fa-refresh spinning" *ngIf="check.status === 'in_progress'"></em>
        <adl-image
          class="flex justify-center"
          src="assets/dpg/imgs/icons/guardian-shield-green.svg"
          shape="square"
          size="20px"
          *ngIf="check.status === 'succeed'"
        ></adl-image>
        <adl-image
          class="flex justify-center"
          src="assets/dpg/imgs/icons/guardian-shield-red.svg"
          shape="square"
          size="20px"
          *ngIf="check.status === 'errored' || check.status === 'crashed'"
        ></adl-image>
      </td>
    </tr>
  </ng-container>
</adl-table>
<ng-template #noChecks><adl-text>No checks found</adl-text></ng-template>
