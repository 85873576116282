<adl-modal
  class="column-flex a-center j-center w100" [isAlignedToCross]="true" [isFullWidth]="true"
>
  <div modal-header class="row-flex j-center a-center pt20 pb20 w100">
    <adl-text color="blue" [bold]="true" [size]="25">You cannot see the folders you want to add to your {{'dataAsset' | bizWording}}?
    </adl-text>
  </div>
  <div modal-body class="column-flex mt10 mb10 w100">
    <adl-text type="cant-file-modal" class="row-flex tal" [size]="14">Adding a directory to a {{'dataAsset' | bizWording}} means that the users of this {{'dataAsset' | bizWording}} will
      have access to that folder.
    </adl-text>
    <adl-text type="cant-file-modal" class="row-flex tal mb30" [size]="14">For this reason, only certain users have the permission to add paths to a
      {{'dataAsset' | bizWording}}.
    </adl-text>
    <adl-text type="cant-file-modal" class="row-flex tal mb15" [size]="14">Your Portal Owner probably has the rights to add these directories.
    </adl-text>
    <adl-loaded-item [loading]="this.isLoading">
    <div *ngIf="portalOwners?.length === 0 || !profile?.portal">
      <adl-text type="cant-file-modal" class="row-flex tal mb30" [size]="14">No Portal Owner is defined for you. Your user is probably attached to
        an Azure AD organization that has no Portal defined.
      </adl-text>
    </div>
      <div *ngIf="portalOwners?.length === 1">
        <div class="row-flex c-pointer a-center mb30" (click)="openMailToOwner(portalOwners[0]?.pk)">
          <adl-text type="cant-file-modal" class="row-flex tac" [size]="14" color="blue" [bold]="true"> Contact my Portal
          Owner:  {{portalOwners[0]?.name}} </adl-text>
          <adl-icon class="ml10" [size]="15" icon="envelope" iconType="primary"></adl-icon>
        </div>
      </div>
      <div *ngIf="portalOwners?.length > 1" class="mb30">
        <adl-text type="cant-file-modal" class="row-flex tal mb10" [size]="14">Contact one of my Portal Owners:</adl-text>
        <div class="column-flex" *ngFor="let owner of portalOwners">
          <div class="row-flex j-center a-center c-pointer" (click)="openMailToOwner(owner?.pk)">
            <adl-text type="cant-file-modal" class="row-flex tac" [size]="14" color="blue" [bold]="true">{{owner?.name}}</adl-text>
            <adl-icon class="ml10" [size]="15" icon="envelope" iconType="primary"></adl-icon>
          </div>
        </div>
      </div>
  </adl-loaded-item>
    <div class="row-flex mb10">
      <adl-text [size]="14"  class="tal">If your Portal Owner cannot help you, </adl-text>
      <adl-text type="cant-file-modal" class="tal remedy-link ml5" (click)="openTicketForAzure()" color="blue" [bold]="true"> create an issue</adl-text>
      <adl-text [size]="14"  class="tal ml5">:</adl-text>
    </div>
    <div class="row-flex mb40">
      <div class="column-flex w50">
        <adl-image src="assets/dpg/imgs/elements/cant-find-my-folder-login.png" shape="square" class="j-center a-center" size="450px"></adl-image>
        <adl-text class="tac" [size]="14">Connect with your {{this.environement.options.dpContext.name}} connection (SSO)</adl-text>
      </div>
      <div class="column-flex w50">
        <adl-image src="assets/dpg/imgs/elements/cant-find-my-folder-remedy.png" shape="square" class="j-center a-center" size="450px"></adl-image>
        <adl-text  class="tac" [size]="14">Go to Request a Service / DATA - Accor Data Portal - Request</adl-text>
      </div>
    </div>
    <adl-text type="cant-file-modal" class="row-flex tal mb15" [size]="14">Write down your request : 1) name and URL of the {{'dataAsset' | bizWording}} 2) paths you want to add.</adl-text>
  </div>
</adl-modal>
