<div class="post-comment-item flex flex-row items-start">
  <div class="picture">
    <adl-loaded-item class="w-full" [loading]="isLoadingPicture" iconSize="160">
      <adl-image *ngIf="pictureSrc" [src]="pictureSrc" size="35px"></adl-image>
    </adl-loaded-item>
  </div>
  <div class="comment-content flex flex-col">
    <adl-input-text-area
      [control]="postMessageInput"
      [isDisabled]="isInputZoneDisabled"
      [setCursorAtTheEndOfEditZone$]="setCursorAtTheEndOfEditZone$"
      fixedWidth="100%"
      [fixedHeight]="editMessageZoneHeightInPx"
      horizontalPadding="15px"
      verticalPadding="10px"
      maxHeight="200px"
      placeholder="Add a comment"
      class="w-full"
    ></adl-input-text-area>
    <div class="flex flex-col">
      <div class="flex">
        <adl-text color="grey-600" size="11" italic="true">
          Comments are visible to everyone with access to the dashboard.
        </adl-text>
      </div>
      <div class="comment-footer flex flex-row justify-end items-center">
        <adl-text class="cancel-btn" [ngStyle]="{'cursor': hasToDisabledButtons ? 'not-allowed' : 'pointer'}" color="grey-600" size="12" (click)="cancel()">{{
          cancelBtnText
          }}</adl-text>
        <adl-button class="validate-btn" type="primary-dark" [disabled]="hasToDisabledButtons" (clicked)="validate()">
          <adl-text color="white" size="14">{{ validateBtnText }}</adl-text></adl-button
        >
      </div>
    </div>
  </div>
</div>
