import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import type { Observable } from 'rxjs';
import { BehaviorSubject, from, of, throwError } from 'rxjs';
import { catchError, concatMap, map, take, tap } from 'rxjs/operators';
import type { IConfirmModalComponentData } from '@dataportal/adl';
import { ConfirmModalComponent, DialogsService } from '@dataportal/adl';
import { CurrentUserService } from '@dataportal/auth';
import { ApiService } from '@dataportal/front-api';
import { AlertService, Logger } from '@dataportal/front-shared';
import { Navigator } from '@dataportal/navigator';

import { PowerBiEmbedService } from './power-bi-embed.service';

import { DASHBOARDS_OPTIONS, IDashboardsOptions } from '../dashboards-options';

// Types
export interface IDashboardBookmark {
  pk: string;
  sk: string;
  name: string;
  data: string; // serialized IReportBookmark
  sharedBy?: string;
  sharedName?: string;
}

export interface IBookmarkStatus {
  status: 'ready' | 'loading' | 'success' | 'error';
  action?: 'save' | 'remove' | 'apply' | 'reset' | 'list' | 'update';
}

// TODO: should be in @dataportal/types
interface IGenerateLinkResponse {
  id: string;
}

// TODO: should be in @dataportal/types
interface ILinkDataResponse {
  currentPage: string;
  data: string;
}

// TODO: should be in @dataportal/types
interface IApplyDefaultBookmarkResponse {
  default: IDashboardBookmark;
}

// Service
@Injectable()
export class PowerBiBookmarksService {
  // Attributes
  private readonly _bookmarks$ = new BehaviorSubject<IDashboardBookmark[]>([]);
  bookmarks$ = this._bookmarks$.asObservable();

  private readonly _loading$ = new BehaviorSubject<IBookmarkStatus>({ status: 'ready' });
  loading$ = this._loading$.asObservable();

  private readonly _isBookmarkActivationInProgress$ = new BehaviorSubject<boolean>(false);
  isBookmarkActivationInProgress$ = this._isBookmarkActivationInProgress$.asObservable();

  private _linkId: string;

  get linkId(): string {
    return this._linkId;
  }

  set linkId(id: string) {
    this._linkId = id;
  }

  // Constructor
  constructor(
    private readonly _router: Router,
    private readonly _dialogsService: DialogsService,
    private readonly _apiService: ApiService,
    private readonly _currentUserService: CurrentUserService,
    private readonly _navigator: Navigator,
    private readonly _logger: Logger,
    private readonly _alertService: AlertService,
    private readonly _powerBiEmbedService: PowerBiEmbedService,
    @Inject(DASHBOARDS_OPTIONS) private readonly _options: IDashboardsOptions,
  ) {}

  // Methods
  generateLink(sourceId: string, pageId: string, dashboardName: string): Observable<string> {
    return from(this._powerBiEmbedService.extractState()).pipe(
      map((state) => JSON.stringify(state)),
      catchError((err: unknown) => {
        this._alertService.error('Cannot generate shareable link');

        return throwError(err);
      }),
      concatMap((data) =>
        this._apiService.post<IGenerateLinkResponse>(
          `v4/dashboards/sources/${sourceId}/dashboards/${dashboardName}/links`,
          {
            currentPage: pageId,
            data,
          },
        ),
      ),
      map(({ id }) => {
        const route = this._router.serializeUrl(
          this._router.createUrlTree([
            this._navigator.buildUrl('catalog-dashboard', { fileName: sourceId, dashboard: dashboardName }),
            { linkId: id },
          ]),
        );

        return this._options.baseUrl + route;
      }),
    );
  }

  restoreBookmarkFromLink(sourceId: string, dashboardName: string): Observable<ILinkDataResponse> {
    return this._apiService
      .get<ILinkDataResponse>(
        `v4/dashboards/sources/${sourceId}/dashboards/${encodeURIComponent(dashboardName)}/links/${this._linkId}`,
      )
      .pipe(
        tap(async (response) => {
          try {
            const pages = await this._powerBiEmbedService.getPages();
            const toSelect = pages.find((p) => p.name === response.currentPage);
            await toSelect?.setActive();

            const restoreBookmark = new Promise<void>((resolve, reject) => {
              const handler = async (): Promise<void> => {
                try {
                  const state = JSON.parse(response.data);
                  await this._powerBiEmbedService.restoreState(state);
                  this._powerBiEmbedService.removeEventListener('rendered', handler);

                  return resolve();
                } catch (e) {
                  return reject(e);
                }
              };

              this._powerBiEmbedService.addEventListener('rendered', handler);
            });

            await restoreBookmark;
            this._linkId = null;
          } catch (e) {
            this._alertService.warning("We couldn't restore report state from your shareable link");
          }
        }),
      );
  }

  listBookmarks(sourceId: string, dashboardName: string): void {
    this._loading$.next({ status: 'loading', action: 'list' });

    this._apiService
      .get<IDashboardBookmark[]>(`v4/dashboards/sources/${sourceId}/dashboards/${dashboardName}/filters`)
      .subscribe(
        (bookmarks) => {
          this._bookmarks$.next(bookmarks);
          this._loading$.next({ status: 'success', action: 'list' });
        },
        (error: unknown) => {
          this._logger.error('[PowerBiBookmarksService] Error fetching list', error);
          this._loading$.next({ status: 'error', action: 'list' });
        },
      );
  }

  listPossibleUsersToShareTo(sourceId: string, dashboardId: string) {
    return this._apiService.get<string[]>(`v4/dashboards/sources/${sourceId}/dashboards/${dashboardId}/read-users`);
  }

  shareBookmark(
    sourceId: string,
    dashboardId: string,
    bookmark: IDashboardBookmark,
    destineesIds: string[],
  ): Observable<void> {
    return this._apiService.post<void>(`v4/dashboards/sources/${sourceId}/dashboards/${dashboardId}/shared-bookmarks`, {
      name: bookmark.name,
      data: bookmark.data,
      destinees: destineesIds,
    });
  }

  async saveBookmark(sourceId: string, dashboardName: string, name: string): Promise<void> {
    const reportState = await this._powerBiEmbedService.extractState();

    try {
      this._logger.debug('[PowerBiBookmarksService] Saving state', { value: reportState });

      this._apiService
        .post(`v4/dashboards/sources/${sourceId}/dashboards/${dashboardName}/filters`, {
          name,
          data: reportState,
        })
        .subscribe(
          (added: IDashboardBookmark) => {
            const bookmarks = this._bookmarks$.getValue();

            this._alertService.success('Bookmark successfully saved');
            this._loading$.next({ status: 'success', action: 'save' });
            this._bookmarks$.next([...bookmarks, added]);
          },
          (e: unknown) => {
            this._logger.error('[PowerBiBookmarksService] Cannot save state', e);
            this._alertService.error('Error saving bookmark');
            this._loading$.next({ status: 'success', action: 'save' });
          },
        );

      this._logger.debug('[PowerBiBookmarksService] State saved');
    } catch (e) {
      this._logger.error('[PowerBiBookmarksService] Cannot store state', e);
      this._loading$.next({ status: 'error', action: 'save' });

      return;
    }
  }

  updateBookmark(sourceId: string, dashboardName: string, bookmarkToUpdate: IDashboardBookmark) {
    return this._apiService
      .put<IDashboardBookmark>(`v4/dashboards/sources/${sourceId}/dashboards/${dashboardName}/filters`, {
        id: bookmarkToUpdate.pk,
        name: bookmarkToUpdate.name,
        data: bookmarkToUpdate.data,
      })
      .pipe(
        tap((updated: IDashboardBookmark) => {
          const bookmarks = this._bookmarks$.getValue();
          this._alertService.success('Bookmark successfully updated');
          this._loading$.next({ status: 'success', action: 'update' });
          this._logger.debug('[PowerBiBookmarksService] Updated bookmarks' + '', updated);
          this._bookmarks$.next(
            bookmarks.map((bookmark) => {
              if (bookmark.pk === updated.pk) {
                bookmark = updated;
              }

              return bookmark;
            }),
          );
        }),
        catchError((e: unknown) => {
          this._logger.error('[PowerBiBookmarksService] Cannot update state', e);
          this._alertService.error('Error updating bookmark');
          this._loading$.next({ status: 'success', action: 'update' });
          throw e;
        }),
      );
  }

  async applyBookmark(bookmark: IDashboardBookmark): Promise<void> {
    const state = bookmark.data;

    try {
      this._isBookmarkActivationInProgress$.next(true);
      this._loading$.next({ status: 'loading', action: 'apply' });
      await this._powerBiEmbedService.restoreState(state);

      this._loading$.next({ status: 'success', action: 'apply' });
      this._alertService.success('Bookmark successfully applied');
    } catch (e) {
      this._loading$.next({ status: 'error', action: 'apply' });
      this._alertService.error('Error applying bookmark');
      this._logger.error('[PowerBiBookmarksService] Cannot apply state', e);
    } finally {
      this._isBookmarkActivationInProgress$.next(false);
    }
  }

  applyState(state: string): Promise<void> {
    return this._powerBiEmbedService.restoreState(state);
  }

  remove(sourceId: string, dashboardName: string, bookmark: IDashboardBookmark): void {
    this._remove(sourceId, dashboardName, [bookmark]);
  }

  removeAll(sourceId: string, dashboardName: string): void {
    this._remove(sourceId, dashboardName, this._bookmarks$.getValue());
  }

  async resetInitialState(): Promise<void> {
    try {
      await this._powerBiEmbedService.resetState();
    } catch (e) {
      this._loading$.next({ status: 'error', action: 'reset' });
      this._alertService.error('Error resetting bookmark');
    }
  }

  private _remove(sourceId: string, dashboardName: string, bookmarks: IDashboardBookmark[]): void {
    this._logger.debug('[PowerBiBookmarksService] Removing bookmarks', bookmarks);
    this._loading$.next({ status: 'loading', action: 'remove' });

    const ids = bookmarks.map((f) => f.pk);

    const confirmModal = this._dialogsService.open<IConfirmModalComponentData, boolean>(ConfirmModalComponent, {
      elements: bookmarks.map((f) => f.name),
      action: 'delete',
      kinds: 'bookmarks',
    });

    confirmModal
      .pipe(
        take(1),
        catchError((e: unknown) => {
          this._loading$.next({ status: 'error', action: 'remove' });
          this._logger.error('[PowerBiBookmarksService] Cannot remove bookmarks', e);
          this._alertService.error('Error removing bookmark');

          return of(null);
        }),
      )
      .subscribe((confirm: boolean) => {
        if (confirm) {
          this._apiService
            .delete<string[]>(`v4/dashboards/sources/${sourceId}/dashboards/${dashboardName}/filters`, {
              body: { ids },
            })
            .subscribe(
              (deleted) => {
                this._loading$.next({ status: 'success', action: 'remove' });
                this._logger.debug('[PowerBiBookmarksService] Deleted filters', deleted);
                this._alertService.success('Bookmark successfully removed');

                const newBookmarks = this._bookmarks$.getValue();
                this._bookmarks$.next(newBookmarks.filter((f) => !deleted.includes(f.pk)));
              },
              (e: unknown) => {
                this._loading$.next({ status: 'error', action: 'remove' });
                this._logger.error('[PowerBiBookmarksService] Cannot remove filters', e);
                this._alertService.error('Error removing bookmark');
              },
            );
        } else {
          this._loading$.next({ status: 'success', action: 'remove' });
        }
      });
  }

  applyDefaultBookmark(sourceId: string, dashboardName: string): Observable<{ default: IDashboardBookmark }> {
    this._logger.info('[PowerBiBookmarksService] Applying default bookmark if any');

    return this._apiService
      .get<IApplyDefaultBookmarkResponse>(
        `v4/dashboards/sources/${sourceId}/dashboards/${dashboardName}/filters/default`,
      )
      .pipe(
        tap(async (res) => {
          this._logger.debug('[PowerBiBookmarksService] Received default bookmark', res);

          if (res.default) {
            await this.applyBookmark(res.default);
          }
        }),
      );
  }

  setAsDefault(bookmark: IDashboardBookmark, dashboardName: string): Observable<void> {
    this._logger.info('[PowerBiBookmarksService] Set bookmark as default', bookmark);
    const pattern = /^dashboard-filters\|(.+)\|(.+)\|(.+)$/;
    const sourceId = bookmark.sk.match(pattern)[2];

    return this._apiService.post(
      `v4/dashboards/sources/${sourceId}/dashboards/${dashboardName}/filters/${bookmark.pk}/default`,
      {},
    );
  }
}
