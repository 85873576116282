<adl-modal
  class="guardian-modal"
  [isAlignedToCross]="true"
  [hasMarginBottom]="false"
  [hasBodySidesPadding]="false"
  [roundCorner]="true"
  headerBackgroundColor="blue-dark"
  backgroundColor="light"
  closeButtonColor="white"
>
  <dpg-guardian-check-modal-header
    modal-header
    class="w-full"
    [dataset]="dataset"
    [checkId]="guardianStatus?.checkId"
    [hasStepper]="false"
  ></dpg-guardian-check-modal-header>
  <dpg-guardian-check-infos-content
    modal-body
    class="guardian-modal-body flex flex-col justify-center items-center"
    [hasExportButton]="true"
    [guardianStatus]="guardianStatus"
    [dataset]="dataset"
  ></dpg-guardian-check-infos-content>
</adl-modal>
