import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

import { UsersModule } from '../users.module';

import type { LimitedUser } from '../entities/limited-user';
import type { User } from '../entities/user';

enum ImpersonateSessionStorageKeys {
  HIDE_MODAL_SESSION = 'hideModalSession',
}

enum ImpersonateLocalStorageKeys {
  IMPERSONATE_USER = 'impersonate',
  ORIGIN_USER_STATUS = 'origin-user-status',
}

@Injectable({
  providedIn: UsersModule,
})
export class ImpersonateService {
  private readonly _impersonatedUser$ = new BehaviorSubject<string | null>(null);
  impersonatedUser$ = this._impersonatedUser$.asObservable();

  private readonly _impersonationStopped$ = new Subject<void>();
  impersonationStopped$ = this._impersonationStopped$.asObservable();

  // Methods
  isImpersonating(): boolean {
    return !!this.getImpersonate();
  }

  getImpersonate(): string {
    return localStorage.getItem(ImpersonateLocalStorageKeys.IMPERSONATE_USER);
  }

  setImpersonate(user: User | LimitedUser, redirect = true): void {
    sessionStorage.removeItem(ImpersonateSessionStorageKeys.HIDE_MODAL_SESSION);
    localStorage.setItem(ImpersonateLocalStorageKeys.IMPERSONATE_USER, user.id);
    this._impersonatedUser$.next(user.id);

    if (!redirect) {
      window.location.reload();
    } else {
      window.location.assign('');
    }
  }

  stopImpersonation(): void {
    sessionStorage.removeItem(ImpersonateSessionStorageKeys.HIDE_MODAL_SESSION);
    localStorage.removeItem(ImpersonateLocalStorageKeys.IMPERSONATE_USER);
    this._impersonatedUser$.next(null);
    this._impersonationStopped$.next();
    window.location.reload();
  }

  isOriginUserAdmin() {
    return localStorage.getItem(ImpersonateLocalStorageKeys.ORIGIN_USER_STATUS) === 'admin';
  }

  isOriginUserPortalOwner() {
    return localStorage.getItem('origin-user-portal-owner') === 'true';
  }

  setOriginUserStatuses(user: User) {
    localStorage.setItem(ImpersonateLocalStorageKeys.ORIGIN_USER_STATUS, user.role);
    /* TODO in another service by making apiCall directly here
     this.isAuthorized('update', 'portals').subscribe((isPortalOwner) => {
     localStorage.setItem('origin-user-portal-owner', isPortalOwner ? 'true' : 'false');
     });
     */
  }
}
