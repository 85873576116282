<div class="dashboard-comments-part-container flex flex-col justify-start items-start">
  <div class="flex flex-col justify-start items-start w-full h-full">
    <div *ngIf="dashboardComment" class="comments-list">
      <div class="comment">
        <adl-comment-item
          [pictureSrc]="dashboardComment.pictureBase64"
          [isLoadingPicture]="dashboardComment.isLoadingPicture"
          [name]="dashboardComment.authorName"
          [date]="dashboardComment.createdAt"
          [updatedDate]="dashboardComment.updatedAt"
          [message]="dashboardComment.message"
          [isSmallerItem]="!!dashboardComment.nestingLevel || !!dashboardComment.updatedAt"
          [setCursorAtTheEndOfEditZone$]="setCursorAtTheEndOfEditZoneOnEditing$"
          [hasLikeBtn]="false"
          [isLoadingLikeBtn]="false"
          [isLiked]="dashboardComment.hasBeenLikedByCurrentUser"
          [nbLikes]="dashboardComment.likesCounter"
          [hasHideBtn]="!!dashboardComment?.childrenComments?.length"
          [isHideBtnToggled]="!dashboardComment?.hasToDisplayReplies"
          [hasToDisable]="hasToDisable"
          [nbReplies]="dashboardComment?.childrenComments?.length || 0"
          [hasOptionsBtn]="hasToShowOptionsBtn"
          [isLoadingOptionsBtn]="isLoadingOptionsBtn"
          [optionsBtnOptions]="actionsButtons"
          [hasReplyBtn]="hasReplyBtn"
          [isEditing]="isEditingComment"
          [isLoadingEditing]="isLoadingEditingComment"
          (onToggleHide)="toggleRepliesVisibility($event)"
          (onOptionSelection)="selectOption($event)"
          (onEditCancel)="cancelEdit()"
          (onClickReply)="displayReplyZone()"
          (onEditValidate)="validateEdit($event)"
        ></adl-comment-item>
      </div>
      <div
        class="flex flex-col justify-start items-start w-full"
        [ngClass]="{ flex: dashboardComment?.hasToDisplayReplies, hidden: !dashboardComment?.hasToDisplayReplies }"
        [ngStyle]="nextPaddingLeft?.length && { paddingLeft: nextPaddingLeft }"
      >
        <div *ngIf="dashboardComment?.childrenComments?.length" class="w-full">
          <dpg-dashboard-comments-thread
            *ngFor="let comment of dashboardComment.childrenComments"
            class="comments-thread"
            [attr.id]="comment.id"
            [level]="level + 1"
            [dashboardComment]="comment"
            [currentUserId]="currentUserId"
            [isCurrentUserAdmin]="isCurrentUserAdmin"
            [currentUserPictureBase64]="currentUserPictureBase64"
            [isLoadingCurrentUserPictureBase64]="isLoadingCurrentUserPictureBase64"
            [isLoadingDashboardComments]="isLoadingDashboardComments"
          ></dpg-dashboard-comments-thread>
        </div>
        <div *ngIf="dashboardComment?.hasToDisplayReplyZone" class="reply-zone w-full">
          <adl-post-comment-item
            [pictureSrc]="currentUserPictureBase64"
            [isLoadingPicture]="isLoadingCurrentUserPictureBase64"
            [hasToDisabledButtons]="hasToDisableReplyComment"
            cancelBtnText="Cancel"
            validateBtnText="Send"
            [isSmallerItem]="false"
            [clearInput$]="clearComponentInput$"
            [setCursorAtTheEndOfEditZone$]="setCursorAtTheEndOfCommentInput$"
            (onClickCancel)="cancelPostComment()"
            (onClickValidate)="validatePostComment($event)"
            [isInputZoneDisabled]="hasToDisable"
          >
          </adl-post-comment-item>
        </div>
      </div>
    </div>
  </div>
</div>
