<ul [ngSwitch]="breadcrumbType" class="flex flex-wrap">
  <ng-container *ngSwitchCase="'url'">
    <a *ngIf="firstLinkDifferent" [href]="firstLink.url">
      <adl-text [size]="breadcrumbsLabelSize" color="{{ firstLinkColor }}">{{firstLink.label}}</adl-text>
    </a>
    <li *ngFor="let breadcrumb of breadcrumbsUrl; let i = index" class="flex">
      <img
        *ngIf="firstLinkDifferent || i !== 0"
        alt="load"
        class="px-4"
        src="assets/dpg/imgs/icons/arrow-right-grey.svg"
      />
      <button (click)="goToRoute(breadcrumb)">
        <span
          class="breadcrumb--label {{ breadcrumbTheme }}"
          [ngStyle]="{'font-size': breadcrumbsLabelSize + 'px'}"
        >
          {{ breadcrumb.label }}
        </span>
      </button>
    </li>
  </ng-container>

  <ng-container *ngSwitchCase="'action'">
    <adl-text
      class="cursor-pointer"
      color="grey-500"
      (click)="selectBreadcrumbOrigin(firstLink.label)"
      [size]="breadcrumbsLabelSize"
    >
      {{ firstLink.label }}
    </adl-text>

    <li *ngFor="let breadcrumb of breadcrumbs" class="flex items-center">
      <img alt="load" class="px-4 breadcrumb-arrow" src="assets/dpg/imgs/icons/arrow-right-grey.svg"/>
      <span
        class="breadcrumb--label"
        (click)="selectBreadcrumb(breadcrumb)"
        [ngClass]="[breadcrumbTheme]"
        [ngStyle]="{'font-size': breadcrumbsLabelSize + 'px'}"
        [class.cursor-pointer]="cursorPointer"
      >
        {{ breadcrumb }}
      </span>
    </li>
  </ng-container>
</ul>
