import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import { MultiAuthService } from '@dataportal/auth';
import { Logger } from '@dataportal/front-shared';
import { LogsService } from '@dataportal/logs';
import { PermissionsService } from '@dataportal/permissions';
import { SourcesService } from '@dataportal/sources-dashboards-recommendations';
import { ImpersonateService } from '@dataportal/users';

@Injectable({
  providedIn: 'root',
})
export class RouterEventsService {
  private _previousUrlWithoutQueryParams = '';

  constructor(
    private readonly _router: Router,
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _titleService: Title,
    private readonly _logService: LogsService,
    private readonly _authService: MultiAuthService,
    private readonly _impersonateService: ImpersonateService,
    private readonly _permissionsService: PermissionsService,
    private readonly _logger: Logger,
    private readonly _sourcesService: SourcesService,
  ) {}

  activate(hasLogs = true): void {
    this._logger.info('[RouterEventsService] Activating router events');
    this._updatePageTitle();
    this._scrollToTop();
    this._stopSourceOwnersImpersonation();

    if (hasLogs) {
      this._sendPageView();
    }
  }

  private _updatePageTitle(): void {
    // router
    this._logger.info('[RouterEventsService] Activating page title update on route change');
    this._router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this._activatedRoute),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }

          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data),
      )
      .subscribe((event) => {
        const title = event.title + ' - Accor Data Portal';
        this._logger.debug('[RouterEventsService] Updating title', title);
        this._titleService.setTitle(title);
      });
  }

  private _scrollToTop(): void {
    this._logger.info('[RouterEventsService] Activating scroll to top on navigation end');
    this._router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      this._logger.debug('[RouterEventsService] Scroll to top');
      const contentApp = document.querySelector('.content-app');

      if (contentApp) {
        contentApp.scrollTop = 0;
      }
    });
  }

  private _sendPageView(): void {
    this._logger.info('[RouterEventsService] Activating send page views on navigation start');
    this._router.events.pipe(filter((e) => e instanceof NavigationStart)).subscribe((event: NavigationStart) => {
      const urlWithoutQueryPrams = this._router.url.replace(/\?(.*)/, '');

      if (
        !window.location.href.endsWith('/login') &&
        urlWithoutQueryPrams !== this._previousUrlWithoutQueryParams &&
        this._authService.isAuthenticated()
      ) {
        this._logger.debug('[RouterEventsService] Sending page view');
        this._logService
          .post({
            action: 'page_view',
            previous_url: event.id === 1 ? document.referrer : this._router.url,
            current_url: event.url,
          })
          .subscribe();
      }

      this._previousUrlWithoutQueryParams = urlWithoutQueryPrams;
    });
  }

  private _stopSourceOwnersImpersonation() {
    this._logger.info(
      '[RouterEventsService] Activating source owner stop impersonation when he navigates outside source page',
    );
    this._router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd),
        filter((e: NavigationEnd) => !e.urlAfterRedirects.startsWith('/sources/')),
      )
      .subscribe((event: NavigationEnd) => {
        if (this._impersonateService.isImpersonating()) {
          this._logger.debug('[RouterEventsService] Impersonating user is navigating outside /sources/');

          if (!this._impersonateService.isOriginUserAdmin() && !this._impersonateService.isOriginUserPortalOwner()) {
            this._logger.debug('[RouterEventsService] Stopping impersonation');
            this._impersonateService.stopImpersonation();
          }
        }
      });
  }
}
