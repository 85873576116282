import { Component, Inject } from '@angular/core';
import type { Observable } from 'rxjs';
import { EMPTY } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { IS_ACCOR, IS_JCD, IS_PR } from '@dataportal/front-environment';

import { DocumentationService } from '../../../../services/documentation.service';
import type { IDocumentation } from '../../../../models/documentation.model';

import { DialogRef } from '../dialog-ref';
import { DIALOG_DATA } from '../dialog-token';

interface IDocumentationDialogData {
  id: IDocumentation['id'];
  canCopy: boolean;
  canCheckViews: boolean;
}

@Component({
  templateUrl: './documentation-dialog.component.html',
  styleUrls: ['./documentation-dialog.component.scss'],
})
export class DocumentationDialogComponent {
  readonly getDocumentation$: Observable<IDocumentation>;

  constructor(
    private readonly _dialogRef: DialogRef,
    private readonly _documentationService: DocumentationService,
    @Inject(DIALOG_DATA) public data: IDocumentationDialogData,
    @Inject(IS_PR) readonly isPR: boolean,
    @Inject(IS_JCD) readonly isJCD: boolean,
    @Inject(IS_ACCOR) readonly isAccor: boolean,
  ) {
    this.getDocumentation$ = this._documentationService
      .getDocumentation$(Number(this.data.id))
      .pipe(catchError(() => EMPTY));
  }

  close() {
    this._dialogRef.close();
  }
}
