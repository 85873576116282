<ng-template>
  <div
    (click)="clickableContent || closed.emit()"
    class="dropdown-content"
    [style.width]="width"
    [style.height]="height"
    [style.gap]="contentGap"
    [style.padding]="padding"
    [style.margin]="margin"
  >
    <ng-content></ng-content>
  </div>
</ng-template>
