import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IAthenaResponse } from '@dataportal/api-types';

// Components
@Component({
  selector: 'dpg-catalog-snowflake-preview-modal',
  templateUrl: './catalog-snowflake-preview-modal.component.html',
  styleUrls: ['./catalog-snowflake-preview-modal.component.scss'],
})
export class CatalogSnowflakePreviewModalComponent {
  // Attributes
  @Input() title: string;
  @Input() tablePreview: IAthenaResponse = null;

  // Constructor
  constructor(
    @Inject(MAT_DIALOG_DATA)
    data: {
      title: string;
      tablePreview: IAthenaResponse;
    },
  ) {
    this.title = data?.title ? data.title : this.title;
    this.tablePreview = data?.tablePreview ? data.tablePreview : this.tablePreview;
  }
}
