import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { AdlModule } from '@dataportal/adl';
import { GraphApiModule } from '@dataportal/front-graph-api';
import { UsersModule } from '@dataportal/users';

import { ImpersonateModalComponent } from './components/impersonate-modal/impersonate-modal.component';
import { ImpersonateShortcutComponent } from './components/impersonate-shortcut/impersonate-shortcut.component';

@NgModule({
  declarations: [ImpersonateModalComponent, ImpersonateShortcutComponent],
  imports: [MatDialogModule, CommonModule, UsersModule, RouterModule, GraphApiModule, AdlModule],
  exports: [ImpersonateModalComponent, ImpersonateShortcutComponent],
})
export class ImpersonateModule {}
