import { Entity } from '@decahedron/entity';

// Interface
export interface ISourceImage {
  link?: string;
  source?: string;
}

// Entity
/** @deprecated LEGACY not used anymore */
export class SourceImage extends Entity implements ISourceImage {
  // Attributes
  link?: string = null;
  source?: string = null;

  // Constructor
  constructor(source?: string, link?: string) {
    super();
    this.link = link;
    this.source = source;
  }

  // Methods
  toJson(): ISourceImage {
    return {
      source: this.source,
      link: this.link,
    };
  }
}
