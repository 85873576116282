import type { OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component, ElementRef, Input, SecurityContext, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { IDocumentation } from '../../../../models/documentation.model';
import type { IMedia } from '../../../../models/media.model';

const viewerBaseUrl = 'https://view.officeapps.live.com/op/embed.aspx?src=';

enum MimeType {
  POWERPOINT = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  WORD = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  EXCEL = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  PDF = 'application/pdf',
  NONE = '',
}

@Component({
  selector: 'da-documentation-viewer-accor',
  templateUrl: './documentation-viewer-accor.component.html',
  styleUrls: ['./documentation-viewer-accor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentationViewerAccorComponent implements OnInit {
  @Input() documentation: IDocumentation;
  @Input() canCheckViews = false;
  @Input() isRichText = true;

  @ViewChild('iframe') iframeDoc: ElementRef<HTMLIFrameElement>;

  internalUrl = '';
  externalUrl = '';
  pdfViewerHeight = 50;
  mimeType = MimeType;
  isIframeLoading = false;
  isMsOfficeDoc = false;
  isVideoDoc = false;

  isTextDoc = false;
  sanitizedHtml: string;

  constructor(private readonly _sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    if (this.documentation.text) {
      this.isTextDoc = true;
      this.sanitizedHtml = this._sanitizer.sanitize(SecurityContext.HTML, this.documentation.text);

      return;
    }

    if (this.documentation.videos.length) {
      this.isVideoDoc = true;
    } else {
      const doc: IMedia = this.documentation.document;

      if (doc) {
        this.isMsOfficeDoc = doc.mime !== MimeType.PDF;

        this.internalUrl = this.isMsOfficeDoc ? `${viewerBaseUrl}${doc?.url}` : this.documentation.document.url;

        return;
      }

      // external url case
      this.externalUrl = this.documentation.url;
      this.isIframeLoading = this.isMsOfficeDoc || !!this.externalUrl;
    }
  }

  onLoad() {
    this.isIframeLoading = false;
  }
}
