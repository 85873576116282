import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AtomsModule } from '../atoms/atoms.module';
import { MoleculesModule } from '../molecules/molecules.module';
import { SidebarModule } from './sidebar/sidebar.module';

import { SidebarV2Service } from './sidebar-v2/sidebar-v2.service';
import { SidebarV3Service } from './sidebar-v3/sidebar-v3.service';

import { AlertTitleComponent } from './alert-title/alert-title.component';
import { BreadcrumbFooterComponent } from './breadcrumb-footer/breadcrumb-footer.component';
import { CommonSelectComponent } from './common-select/common-select.component';
import { DeleteModalComponent } from './delete-modal/delete-modal.component';
import { FilterComponent } from './filters/filter/filter.component';
import { FiltersComponent } from './filters/filters.component';
import { ImagePickerComponent } from './image-picker/image-picker.component';
import { PageHeaderComponent } from './page-header/page-header.component';
import { ResourceUploadComponent } from './resource-upload/resource-upload.component';
import { SidebarV2Component } from './sidebar-v2/sidebar-v2.component';
import { SidebarV3Component } from './sidebar-v3/sidebar-v3.component';
import { StepperFormModal } from './stepper-form-modal/stepper-form-modal.component';
import { ToasterComponent } from './toaster/toaster.component';

// Module
@NgModule({
  imports: [CommonModule, AtomsModule, MoleculesModule, SidebarModule],
  exports: [
    BreadcrumbFooterComponent,
    CommonSelectComponent,
    ImagePickerComponent,
    PageHeaderComponent,
    SidebarModule,
    FiltersComponent,
    FilterComponent,
    ResourceUploadComponent,
    AlertTitleComponent,
    ToasterComponent,
    SidebarV2Component,
    SidebarV3Component,
    StepperFormModal,
    DeleteModalComponent,
  ],
  declarations: [
    BreadcrumbFooterComponent,
    CommonSelectComponent,
    ImagePickerComponent,
    PageHeaderComponent,
    FiltersComponent,
    FilterComponent,
    AlertTitleComponent,
    ResourceUploadComponent,
    ToasterComponent,
    SidebarV2Component,
    SidebarV3Component,
    StepperFormModal,
    DeleteModalComponent,
  ],
  providers: [SidebarV2Service, SidebarV3Service],
})
export class OrganismsModule {}
