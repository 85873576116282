import type { OnInit } from '@angular/core';
import { Component, Input, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { DatalakeApiService } from '@dataportal/datalake-and-guardian';
import type { IExternalReferential, IRequestCheckFieldOptions } from '@dataportal/front-api';
import { DatalakeService } from '@dataportal/front-shared';
import type { CheckRegex } from '@dataportal/guardian-utils';
import { IGuardianStatus } from '@dataportal/guardian-utils';
import { DBSnowflakeService } from '@dataportal/snowflake';

import { GuardianService } from '../../services/guardian.service';

interface ICheckFieldOptionsWithColumnExtract extends IRequestCheckFieldOptions {
  extractedColumn?: string | string[];
}

/**
 * Component that show the column and constraint configuration of a Guardian Check
 */
@Component({
  selector: 'dpg-guardian-check-infos-columns',
  templateUrl: './guardian-check-infos-columns.component.html',
  styleUrls: ['./guardian-check-infos-columns.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class GuardianCheckInfosColumnsComponent implements OnInit {
  @Input() guardianStatus: IGuardianStatus;
  @Input() withTitle = false;
  @Input() allCheckRegex: CheckRegex[] = null;
  @Input() externalReferentials: IExternalReferential[] = null;

  // Variables
  formattedDatasSchemaWithExtractedColumn: ICheckFieldOptionsWithColumnExtract[] = [];
  isSnowflakeExtractLoading = true;
  snowflakeReferentialsValuesExtract: string[] = [];
  externalReferential: IExternalReferential[] = [];
  tooltipLoading = false;
  static NB_ROW_EXTERNAL_REFERENTIAL_LIMIT = 5;

  private readonly _destroyed$ = new Subject<void>();

  constructor(
    private readonly _guardianService: GuardianService,
    private readonly _dbSnowflakeService: DBSnowflakeService,
    private readonly _modalMatService: MatDialog,
    private readonly _datalakeApiService: DatalakeApiService,
    readonly datalakeService: DatalakeService,
  ) {}

  ngOnInit(): void {
    this.formattedDatasSchemaWithExtractedColumn = this.getDataSchema();
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
  }

  getDataSchema(): IRequestCheckFieldOptions[] {
    const formattedDataSchema: IRequestCheckFieldOptions[] = [];

    if (!this.guardianStatus?.isChecked || !this.guardianStatus?.checkInfos) {
      return formattedDataSchema;
    }

    if (this.guardianStatus) {
      const orderedValidationSchemaColumns = this.guardianStatus?.checkInfos?.validation_schema_columns
        ? GuardianService.getOrderedValidationSchemaColumnsArray(
            this.guardianStatus.checkInfos.validation_schema_columns,
          )
        : [];
      orderedValidationSchemaColumns.forEach((schemaColumn) => {
        formattedDataSchema.push({
          column_name: schemaColumn.column_name,
          type: schemaColumn.type,
          required_values: schemaColumn.required_values ? schemaColumn.required_values : false,
          unique: schemaColumn.unique ? schemaColumn.unique : false,
          min: schemaColumn.min !== null && schemaColumn.min !== undefined ? schemaColumn.min : null,
          max: schemaColumn.max !== null && schemaColumn.max !== undefined ? schemaColumn.max : null,
          pair_greater: schemaColumn.pair_greater?.length ? schemaColumn.pair_greater : '',
          regex: schemaColumn.regex?.length ? schemaColumn.regex : '',
          values_in: schemaColumn.values_in ? schemaColumn.values_in : null,
          values_not_in: schemaColumn.values_not_in ? schemaColumn.values_not_in : null,
        });
      });
    }

    return formattedDataSchema;
  }
}
