import type { OnDestroy, OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, first, takeUntil } from 'rxjs/operators';
import { GoogleTagManagerService } from '@dataportal/analytics';
import { CurrentUserService } from '@dataportal/auth';
import { Logger } from '@dataportal/front-shared';
import { NavigatorToken } from '@dataportal/navigator';
import type { Tool, ToolCategory, ToolLink } from '@dataportal/tools';
import { ToolsService } from '@dataportal/tools';

@Component({
  selector: 'dpg-tools-page',
  templateUrl: './tools-page.component.html',
  styleUrls: ['./tools-page.component.scss'],
})
@NavigatorToken('tools')
export class ToolsPageComponent implements OnInit, OnDestroy {
  // Attributes
  isLoading = true;

  dataTools: ToolCategory = null;
  dataServices: ToolCategory = null;
  dataServicesFlattened: Tool[] = [];

  tools: Tool[] = [];
  toolCategories: ToolCategory[][] = [];
  pageCategory: ToolCategory = null;
  // only for UI display (regroup sub-categories, which are in the same grid column, in the same sub-array)
  // (specific to this page, not generic -> does not display "correctly" if more sub-categories)
  toolsSubCategories: ToolCategory[][] = [];
  // Constructor
  favorites: Array<string> = [];
  private readonly _destroyed$ = new Subject<void>();

  constructor(
    private readonly _router: Router,
    private readonly _sanitizer: DomSanitizer,
    private readonly _toolsService: ToolsService,
    private readonly _currentUserService: CurrentUserService,
    private readonly _logger: Logger,
    private readonly _gtmService: GoogleTagManagerService,
  ) {}

  get toolSubcategoryWidth() {
    const toolsSubCategoriesLength = this.toolsSubCategories.length;

    return `w${100 / toolsSubCategoriesLength}`;
  }

  // Lifecycle
  ngOnInit(): void {
    this._logger.debug('[ToolsPageComponent]', 'Initializing');
    this._toolsService.categories$
      .pipe(
        filter((c) => !!c.length),
        takeUntil(this._destroyed$),
      )
      .subscribe((toolCategories) => {
        this._logger.debug('[ToolsPageComponent]', 'Categories', toolCategories);

        this.dataTools = toolCategories.find((c) => c.id === 'data-tools');

        if (!this.dataTools) {
          this._logger.warn('Data tools not enabled on this portal');
        }

        this.dataServices = toolCategories.find((c) => c.id === 'data-services');

        if (!this.dataServices) {
          this._logger.warn('Data services not enabled on this portal');
        }

        this.dataServices.subCategories
          .filter(Boolean)
          .map(
            (subCategory) =>
              (this.dataServicesFlattened = [...this.dataServicesFlattened, ...subCategory.tools.filter(Boolean)]),
          );

        this.isLoading = false;
      });

    // Fetch favorites
    this._currentUserService.currentUser$
      .pipe(
        filter((user) => user != null),
        first(),
      )
      .subscribe((user) => this._refreshFavorite(user.id));
  }

  toggleFavorite(toolId: string, toolSubCategoryId: string): void {
    this._currentUserService.currentUser$
      .pipe(
        filter((user) => user != null),
        first(),
      )
      .subscribe((user) => {
        this._toolsService
          .toggleFavorites(user.id, toolId, toolSubCategoryId, false)
          .subscribe(() => this._refreshFavorite(user.id));
      });
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
  }

  // Methods

  navigateTool(event: Event, toolLink: ToolLink): void {
    event.preventDefault();

    if (toolLink.isExternal) {
      window.open(toolLink.url, '_blank');
    } else {
      if (!toolLink.routerLink) {
        return;
      }

      this._router.navigate([toolLink.routerLink]);
    }
  }

  navigateDataService(event: Event, toolLink: ToolLink, tool: Tool): void {
    event.preventDefault();
    this.pushGTMOpenService(tool.name, tool.category);

    if (!toolLink.isExternal && toolLink.routerLink) {
      this._router.navigate([toolLink.routerLink]);
    } else {
      window.open(toolLink.url, '_blank');
    }
  }

  pushGTMOpenService(serviceName: string, serviceCategory: string) {
    this._gtmService.pushEvent({
      event: 'ds_data_service_opened',
      ds_data_service_name: serviceName,
      ds_data_service_category: serviceCategory,
    });
  }

  navigateOnSingleLink(event: Event, toolLinks: ToolLink[]): void {
    event.preventDefault();

    if (toolLinks.length !== 1) {
      return;
    }

    this.navigateTool(event, toolLinks[0]);
  }

  random(): void {
    window.open('https://google.com');
  }

  isFirstSubCategory(index: number, subIndex: number): boolean {
    return index === 0 && subIndex === 0;
  }

  private _refreshFavorite(userId: string) {
    this._toolsService.listFavorites(userId).subscribe((favorites) => {
      this.favorites = favorites;
    });
  }

  // (specific to this page, not generic -> does not display "correctly" if more sub-categories)
  private _setSubCategoriesForDisplay(): void {
    if (this.toolCategories[0]) {
      this.toolsSubCategories = this.toolCategories[0].reduce((subCategories, currentToolCategory, currentIndex) => {
        if (currentIndex === 0 || currentIndex === 1) {
          subCategories.push([currentToolCategory]);
        } else {
          subCategories[subCategories.length - 1].push(currentToolCategory);
        }

        return subCategories;
      }, []);
    }
  }
}
