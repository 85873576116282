<adl-modal>
  <div modal-header>
    <adl-title>Choose your path</adl-title>
  </div>
  <div modal-body>
    <div *ngIf="hasPermissions; else supportTemplate" class="flex flex-col items-center w-full">
      <div class="mr10 mt5 w80 flex flex-row" *ngFor="let path of authorizedPath">
        <div class="contents" *ngIf="!path.path.isHidden">
          <adl-loaded-item
            class="flex flex-col justify-center"
            [loading]="getDatalakePathExistenceStatus(path.path)?.isLoading"
            iconSize="35"
          >
            <adl-tooltip class="flex flex-col justify-center">
              <adl-icon-button
                tooltip-trigger
                *ngIf="!getDatalakePathExistenceStatus(path.path)?.isExisting"
                class="mr5"
                icon="warning"
                iconType="primary"
                [size]="20"
              ></adl-icon-button>
              <div tooltip-content>
                <adl-text class="w90" type="tooltip" color="white">This folder does not exist anymore. </adl-text>
              </div>
            </adl-tooltip>
          </adl-loaded-item>
          <adl-button
            (clicked)="openDownloadLink(path.path)"
            [disabled]="!getDatalakePathExistenceStatus(path.path)?.isExisting"
            [notClickable]="!getDatalakePathExistenceStatus(path.path)?.isExisting"
            [additionalClasses]="['tal', 'w100']"
            class="path-flex"
            [isAutoHeight]="true"
            type="datalake"
          >
            <div class="wb-all">
              {{ getPathName(path) }}
            </div>
          </adl-button>
        </div>
      </div>
    </div>
    <ng-template #supportTemplate>
      You need permissions for these folders please
      <a href="https://pernod-ricard.atlassian.net/servicedesk/customer/portal/9/group/118">contact the support</a>
    </ng-template>
  </div>
</adl-modal>
