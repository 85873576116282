export const environment = {
  name: 'oat',
  production: true,
  e2e: false,
  baseUrl: 'https://dataportal-oat.aws.accor.com',
  apiUrls: {
    v2: '',
    v3: '',
    v4: {
      accessRequest: 'https://api.dataportal-oat.aws.accor.com/access-requests',
      sources: 'https://api.dataportal-oat.aws.accor.com/sources',
      subCategories: 'https://api.dataportal-oat.aws.accor.com/categories',
      groups: 'https://api.dataportal-oat.aws.accor.com/groups',
      users: 'https://api.dataportal-oat.aws.accor.com/users',
      permissions: 'https://api.dataportal-oat.aws.accor.com/permissions',
      portals: 'https://api.dataportal-oat.aws.accor.com/portals',
      publications: 'https://api.dataportal-oat.aws.accor.com/publications',
      companies: 'https://api.dataportal-oat.aws.accor.com/companies',
      datalake: 'https://api.dataportal-oat.aws.accor.com/datalake',
      recommendations: 'https://api.dataportal-oat.aws.accor.com/recommendations',
      configurations: 'https://api.dataportal-oat.aws.accor.com/configurations',
      dashboards: 'https://api.dataportal-oat.aws.accor.com/dashboards',
      databricks: 'https://api.dataportal-oat.aws.accor.com/databricks',
      drafts: 'https://api.dataportal-oat.aws.accor.com/drafts',
      guardian: 'https://api.dataportal-oat.aws.accor.com/guardian',
      tools: 'https://api.dataportal-oat.aws.accor.com/tools',
      telemetry: 'https://api.dataportal-oat.aws.accor.com/telemetry',
      statistics: 'https://api.dataportal-oat.aws.accor.com/statistics-reading',
      relationships: 'https://api.dataportal-oat.aws.accor.com/relationships',
      magellan: '',
      leons: 'https://api.dataportal-oat.aws.accor.com/leons',
      miscellaneous: 'https://api.dataportal-oat.aws.accor.com/misc',
      notifications: 'https://api.dataportal-oat.aws.accor.com/notifications',
      snowflake: 'https://api.dataportal-oat.aws.accor.com/snowflake',
      snowflakeExplore: 'https://api.dataportal-oat.aws.accor.com/snowflake-explore',
      academy: 'https://api.dataportal-oat.aws.accor.com/data-academy',
      dbSnowflake: 'https://api.dataportal-oat.aws.accor.com/db-snowflake',
      profile: 'https://api.dataportal-oat.aws.accor.com/profile',
      amundsen: 'https://api.dataportal-oat.aws.accor.com/amundsen',
      papyrus: 'https://api.dataportal-oat.aws.accor.com/papyrus',
      athena: 'https://api.dataportal-oat.aws.accor.com/athena',
      glossary: 'https://api.dataportal-oat.aws.accor.com/glossary',
      gli: 'https://api.dataportal-oat.aws.accor.com/gli',
      cockpit: 'https://api.dataportal-oat.aws.accor.com/cockpit',
      chat: 'https://api.dataportal-oat.aws.accor.com/chat',
      homepage: '',
      news: '',
    },
    websocket: 'wss://websockets.dataportal-oat.aws.accor.com',
  },
  adminDatalakeSupportLink: '',
  adminV2HelpLink: '',
  version: 'DATAPORTAL_VERSION',
  documentationLink: '',
  sourceHelp: '',
  azureAD: {
    clientID: '84de15f2-0b2e-4423-9cad-6ec400a08511',
    authority: 'https://login.microsoftonline.com/3ee81190-954b-4064-8e7d-f12fd761fd39',
  },
  telemetry: {
    key: '',
  },
  sourcesAllowedToExport: ['*'],
  flatfileLicenseKey: '',
  internalUserEmail: /@accor\.com$/,
  prUserEmail: /(@accor\.com|@consulting-for\.accor\.com)$/gm,
  datalakeFileLimits: {},
  logoNotToPaint: [],
  requireImpersonationScopeRoutes: [],
  supportedAuthenticators: ['azureAD'],
  datalakeMetadataToDisplay: [],
  authUriSuffix: '',
  dpContext: {
    code: 'ACCOR' as const,
    name: 'Accor' as const,
    internalUserEmail: /@accor\.com$/,
    environment: 'int',
    appCode: undefined,
  },
  cognitoConfig: {
    region: '',
    userPoolId: '',
    userPoolWebClientId: '',
    responseType: 'code' as const,
    clientHash: '',
    oauth: {
      scope: ['email', 'openid'],
      domain: '',
      redirectSignIn: '',
    },
  },
  dataProviders: [],
  leon: {
    proxyBaseUrl: '',
  },
  dashboardTypesAndNames: [
    { label: 'Dashboard', value: 'tableau' },
    {
      label: '"Ready to Explore" Data sources',
      value: 'source_ready_to_explore',
      infoBubbleContent: 'Tableau sources for Self Data Exploration',
    },
    { label: 'Custom', value: 'custom', infoBubbleContent: 'Open the link inside Accor Data Portal' },
  ],
  msalOptions: {
    databricksScope: '',
    devopsImpersonationScope: '',
    userImpersonationScope: '',
  },
  snowflake: {
    accountNameMapping: {
      'accorhotels.eu-west-1': 'ACCOR HOTEL',
      'snowflake': 'UNKNOWN',
    },
    accounts: [{ value: 'accorhotels.eu-west-1', label: 'ACCOR HOTEL' }],
    accountOrder: ['accorhotels.eu-west-1', 'snowflake'],
  },
};
