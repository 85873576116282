<adl-modal>
  <adl-text class="select-sheet-header" type="accent" [size]="16" modal-header>Your Excel file contains several sheets, please select the sheet you want to upload.</adl-text>
  <div modal-body>
    <div class="flex flex-row justify-center align-center w100">
      <div class="flex flex-col justify-center align-center items-center w100">
        <adl-radio-selector [choices]="sheetsNamesOptions" (changed)="updateSelectedSheet($event)" [choiceComponentClassList]="['ml-2']">
        </adl-radio-selector>
        <adl-button type="home-access-data" [disabled]="!sheetSelected" (clicked)="selectSheetName(sheetSelected)" class="mt-10">
          <adl-text size="14" color="white">Continue</adl-text>
        </adl-button>
      </div>
    </div>
  </div>
</adl-modal>
