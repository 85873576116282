import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AdlModule } from '@dataportal/adl';
import { ApiModule } from '@dataportal/front-api';
import { SharedModule } from '@dataportal/front-shared';

import { AlertBannerComponent } from './components/alert-banner/alert-banner.component';
import { AlertModalComponent } from './components/alert-modal/alert-modal.component';

// Module
@NgModule({
  imports: [ApiModule, CommonModule, ReactiveFormsModule, AdlModule, SharedModule],
  declarations: [AlertBannerComponent, AlertModalComponent],
  exports: [AlertBannerComponent, AlertModalComponent],
})
export class AlertsModule {}
