<div class="column-flex a-center" [ngClass]="{ glossaryTheme: theme === 'bg', rectShape: stepShape === 'rect' }">
  <div class="df a-center j-between">
    <div class="df a-center j-center" *ngFor="let step of steps; let index = index" (click)="step.onClick()">
      <span
        [ngClass]="{
          active: step.label === activeStep || isBeforeActiveStep(step),
          currentActive: step.label === activeStep && useCurrentActive,
          smallCircle: circleSize === 'small',
          colorOne: step.specialColor === 1,
          colorTwo: step.specialColor === 2,
          colorThree: step.specialColor === 3,
          colorFour: step.specialColor === 4,
          colorFive: step.specialColor === 5
        }"
        class="stepper-step df a-center j-center"
      >
        <adl-icon
          *ngIf="useIcons && (step.label === activeStep || isBeforeActiveStep(step))"
          [icon]="useSpecificIcons(step)"
          class="mr-1.5"
          [size]="12"
        ></adl-icon>
        {{ insideText[index] }}
      </span>
      <div
        *ngIf="index + 1 < steps.length"
        [ngClass]="{ line: true, active: isBeforeActiveStep(step), smallLine: circleSize === 'small' }"
      ></div>
    </div>
  </div>
  <div class="df a-center j-between tac" *ngIf="stepShape === 'circle'">
    <span
      [ngClass]="{
        active: step.label === activeStep || isBeforeActiveStep(step),
        currentActive: step.label === activeStep && useCurrentActive,
        smallText: circleSize === 'small'
      }"
      *ngFor="let step of steps"
      class="step-name"
      >{{ step.label }}</span
    >
  </div>
</div>
