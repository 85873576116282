<adl-modal
  class="block relative"
  [isAlignedToCross]="true"
  [hasMarginBottom]="false"
  [hasBodySidesPadding]="false"
  [roundCorner]="true"
  headerBackgroundColor="blue-dark"
  backgroundColor="light"
  closeButtonColor="white"
>
  <div modal-header>
    <div class="flex mt-12 mb-12 ml-20">
      <img src="assets/dpg/imgs/icons/icon-business-glossary.svg" class="bg-logo mr-3" alt="bg-logo logo" />
      <div class="flex flex-col items-start">
        <adl-text [size]="20" color="white">Business Glossary</adl-text>
        <adl-text [size]="12" color="white">Comments on {{ componentsName }}</adl-text>
      </div>
    </div>
  </div>
  <div modal-body>
    <div class="flex items-start px-5">
      <div class="flex flex-col mr-4">
        <img [src]="picture" alt="user-icon" width="30px" class="mt-3 rounded-[50%]" />
      </div>
      <div class="message-box w-full py-4 px-6" [innerHTML]="comment"></div>
    </div>
  </div>
</adl-modal>
