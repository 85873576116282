import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ApiService } from '@dataportal/front-api';
import { AlertService, SlugifyService } from '@dataportal/front-shared';
import type { IPortal } from '@dataportal/types';
import { EntityBuilder } from '@decahedron/entity';

import type { IPortalTree } from '../entities/portal.entity';
import { Portal } from '../entities/portal.entity';

@Injectable()
export class PortalsService {
  constructor(
    private readonly _api: ApiService,
    private readonly _slugify: SlugifyService,
    private readonly _alertService: AlertService,
  ) {
    this.list().subscribe((result) => {
      this._portals$.next(result.orgs);
      this._portalsLoaded$.next(true);
      this._portalsTree$.next(result.list);
    });
  }

  get organizations(): Observable<Portal[]> {
    return this._portals$.asObservable();
  }
  private readonly _portals$: BehaviorSubject<Portal[]> = new BehaviorSubject([]);
  private readonly _portalsTree$: BehaviorSubject<IPortalTree[][]> = new BehaviorSubject([]);
  private readonly _portalsLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  portals$ = this._portals$.asObservable();
  portalsTree$ = this._portalsTree$.asObservable();
  portalsLoaded$ = this._portalsLoaded$.asObservable();

  private _sortPortals(orgs: Portal[]): Portal[] {
    return orgs.sort(function (org1, org2) {
      if (org1.status === org2.status) {
        return org1.name < org2.name ? -1 : org1.name > org2.name ? 1 : 0;
      } else {
        return org1.status < org2.status ? -1 : 1;
      }
    });
  }

  private _sortPortalsTree(orgs: IPortalTree[]): IPortalTree[] {
    return orgs.sort(function (org1, org2) {
      return org1.name < org2.name ? -1 : org1.name > org2.name ? 1 : 0;
    });
  }

  private _buildOrganizationTree(portals: Portal[]): IPortalTree[][] {
    const portalsTree: IPortalTree[][] = [];
    const visibleOrganizations: Portal[] = portals.filter((organization) => organization.visible);
    let tmpOrganizations: Portal[] = visibleOrganizations.filter((organization) => organization.parent === null);
    let level = 0;
    portalsTree.push(
      tmpOrganizations.map((organization) => ({
        id: organization.id,
        name: organization.name,
        level: level,
        parent: null,
      })),
    );
    this._sortPortalsTree(portalsTree[level]);

    while (portalsTree[level].length) {
      portalsTree.push(
        portalsTree[level].reduce((currentOrganizations: IPortalTree[], currentOrganization: IPortalTree) => {
          tmpOrganizations = visibleOrganizations.filter(
            (organization) => organization.parent !== null && organization.parent.id === currentOrganization.id,
          );
          currentOrganizations = [
            ...currentOrganizations,
            ...tmpOrganizations.map((organization) => ({
              id: organization.id,
              name: organization.name,
              level: level + 1,
              parent: {
                id: currentOrganization.id,
                name: currentOrganization.name,
              },
            })),
          ];

          return currentOrganizations;
        }, []),
      );
      level += 1;
      this._sortPortalsTree(portalsTree[level]);
    }

    portalsTree.pop(); // to remove the last empty array

    return portalsTree;
  }

  findById(id: string): IPortalTree {
    return this._findById(this._portalsTree$.getValue(), id);
  }

  searchByName(query: string): Portal[] {
    return this._portals$.getValue().filter((org) => {
      return org.name.toLowerCase().indexOf(query.toLowerCase()) !== -1;
    });
  }

  findOne(id: string): Portal {
    return this._portals$.getValue().find((o) => o.id === id);
  }

  findByName(name: string): Portal {
    return this._portals$.getValue().find((portal) => portal.name === name);
  }

  private _findById(organizations: IPortalTree[][], id: string): IPortalTree {
    let result: IPortalTree = null;
    organizations.forEach((organizationsOnLevel) => {
      for (const organization of organizationsOnLevel) {
        if (organization.id === id) {
          result = organization;
          break;
        }
      }
    });

    return result;
  }

  list(sort = false): Observable<{ orgs: Portal[]; list: IPortalTree[][] }> {
    return this._api.get<IPortal[]>('/v4/portals').pipe(
      map((orgs) => {
        const remoteOrgs: Portal[] = EntityBuilder.buildMany(Portal, orgs);
        const orgas = sort ? this._sortPortals(remoteOrgs) : remoteOrgs;

        return {
          orgs: orgas,
          list: this._buildOrganizationTree(orgas),
        };
      }),
    );
  }

  update(organization: Portal): Observable<void> {
    return this._api.put<void>(`/v4/portals/${organization.id}`, organization.toJson()).pipe(
      tap(() => {
        this._alertService.success('Business Area or Product Family updated with success');
      }),
    );
  }

  get(id: string): Observable<Portal> {
    return this._api.get<IPortal>(`/v4/portals/${id}`).pipe(map((result) => EntityBuilder.buildOne(Portal, result)));
  }

  search(input: string, orgs: Portal[]): Portal[] {
    return orgs.filter((organization: Portal) => {
      return (
        organization.name.toLowerCase().indexOf(input) !== -1 || organization.type.toLowerCase().indexOf(input) !== -1
      );
    });
  }

  find(organization: string, orgs: Portal[] = this._portals$.getValue()): Portal {
    let slug: string = organization;

    if (!slug || slug.length === 0) {
      slug = 'pernod-ricard';
    }

    return orgs.filter((org: Portal) => this._slugify.slugify(org.name) === slug)[0];
  }
}
