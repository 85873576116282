import { Location } from '@angular/common';
import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';
import type { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

import { removeFragment, removeQueryParameters } from '../utils';

@Pipe({ name: 'url$' })
export class UrlPipe implements PipeTransform {
  urlChanges$ = new BehaviorSubject<string>(window.location.href);

  constructor(private readonly _location: Location) {
    this._location.onUrlChange(() => {
      this.urlChanges$.next(window.location.href);
    });
  }

  transform(id: number, outlet = '', removeParameters = false): Observable<string> {
    return this.urlChanges$.pipe(
      map((absoluteUrl) => {
        absoluteUrl = removeFragment(absoluteUrl);

        if (removeParameters) {
          absoluteUrl = removeQueryParameters(absoluteUrl);
        }

        return `${absoluteUrl}${outlet ? `/(${outlet}:${id})` : ''}`;
      }),
    );
  }
}
