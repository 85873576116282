import type { OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';

import { IconType, SupportedSVGIcon } from '../icons';

@Component({
  selector: 'adl-svg-icon',
  templateUrl: './svg-icon.component.html',
})
export class SvgIconComponent implements OnInit {
  squareIcon = [
    'exclamation',
    'plus',
    'new-tab',
    'checked',
    'trash',
    'edit',
    'add-people',
    'csv',
    'xls',
    'xlsx',
    'json',
    'png',
    'jpeg',
    'pdf',
    'txt',
    'like',
    'no-like',
    'chevron-left-thin',
    'chevron-right-thin',
    'bubble',
    'check-thin',
    'cross-thin',
    'cross-medium',
    'send',
    'light-bulb',
    'history',
    'datalake-refresh-inactive',
    'datalake-refresh-active',
    'circular-information-icon',
    'warning',
    'world',
  ];

  viewBoxes = new Map<string, string>();

  ngOnInit(): void {
    this.viewBoxes.set('exclamation', '0 0 48 48');
    this.viewBoxes.set('plus', '0 0 20 20');
    this.viewBoxes.set('new-tab', '0 0 15 15');
    this.viewBoxes.set('checked', '0 0 11 9');
    this.viewBoxes.set('trash', '0 0 14 16');
    this.viewBoxes.set('edit', '0 0 20 20');
    this.viewBoxes.set('add-people', '0 0 55 55');
    this.viewBoxes.set('csv', '0 0 130 130');
    this.viewBoxes.set('xls', '0 0 1000 1000');
    this.viewBoxes.set('xlsx', '0 0 1000 1000');
    this.viewBoxes.set('json', '0 0 1300 1300');
    this.viewBoxes.set('png', '0 0 320 320');
    this.viewBoxes.set('jpeg', '0 0 320 320');
    this.viewBoxes.set('pdf', '0 0 550 550');
    this.viewBoxes.set('txt', '0 0 500 500');
    this.viewBoxes.set('like', '0 0 18 16');
    this.viewBoxes.set('no-like', '0 0 18 16');
    this.viewBoxes.set('chevron-left-thin', '0 0 7 12');
    this.viewBoxes.set('chevron-right-thin', '0 0 7 12');
    this.viewBoxes.set('bubble', '0 0 19 16');
    this.viewBoxes.set('check-thin', '0 0 12 11');
    this.viewBoxes.set('cross-thin', '0 0 11 11');
    this.viewBoxes.set('history', '0 0 18 18');
    this.viewBoxes.set('datalake-refresh-inactive', '0 0 30 30');
    this.viewBoxes.set('datalake-refresh-active', '0 0 30 30');
    this.viewBoxes.set('circular-information-icon', '0 0 11 11');
    this.viewBoxes.set('warning', '0 0 17 17');
    this.viewBoxes.set('world', '0 0 17 17');
  }

  // Required
  @Input() icon: SupportedSVGIcon;
  @Input() isIconSpinning = false;
  @Input() size = 16;
  @Input() iconType: IconType = 'primary';

  getFillClassName(): string {
    return `filled-${this.iconType}`;
  }

  getStrokeClassName(): string {
    return `stroked-${this.iconType}`;
  }
}
