import type { OnDestroy, OnInit } from '@angular/core';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { forkJoin, of, Subject } from 'rxjs';
import { catchError, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { DialogsService, ModalSizes } from '@dataportal/adl';
import { AmundsenService } from '@dataportal/amundsen';
import { Logger } from '@dataportal/front-shared';
import { PermissionsService } from '@dataportal/permissions';

import { GuardianService } from '../../services/guardian.service';

import { GuardianCheckInfosModalComponent } from '../guardian-check-infos-modal/guardian-check-infos-modal.component';

import type { GuardianCheck } from '../../entities/guardian.check';
import type {
  IGuardianChecksDatasetMap,
  IGuardianDatalakePath,
  IGuardianStatus,
  ISupportedGuardianChecksResource,
} from '../../entities/guardian-form';

@Component({
  selector: 'dpg-guardian-check-modal-v2',
  templateUrl: './guardian-check-modal-v2.component.html',
  styleUrls: ['./guardian-check-modal-v2.component.scss'],
})
/**
 * Modal that show all the runs of a specific check and allow the run a new check
 */
export class GuardianCheckModalV2Component<R extends ISupportedGuardianChecksResource> implements OnInit, OnDestroy {
  // Attributes
  selectedCheck: GuardianCheck;
  currentDataset: IGuardianChecksDatasetMap[R];
  checkResource: R;
  guardianStatus: IGuardianStatus;
  canRequestCheck = false;
  sourceId: string;

  checks: GuardianCheck[];
  isLoadingList = true;

  private readonly _destroyed$ = new Subject();

  // Constructor
  constructor(
    private readonly _activeMatModal: MatDialogRef<GuardianCheckModalV2Component<R>>,
    private readonly _amundsenService: AmundsenService,
    private readonly _dialogsService: DialogsService,
    private readonly _guardianService: GuardianService,
    private readonly _permissionsService: PermissionsService,
    private readonly _logger: Logger,
    @Inject(MAT_DIALOG_DATA)
    data: {
      selectedCheck: GuardianCheck;
      currentDataset: IGuardianChecksDatasetMap[R];
      checkResource: R;
      guardianStatus: IGuardianStatus;
      canRequestCheck: boolean;
      sourceId: string;
    },
  ) {
    this.selectedCheck = data?.selectedCheck ? data.selectedCheck : this.selectedCheck;
    this.currentDataset = data?.currentDataset ? data.currentDataset : this.currentDataset;
    this.checkResource = data?.checkResource ? data.checkResource : this.checkResource;
    this.guardianStatus = data?.guardianStatus ? data.guardianStatus : this.guardianStatus;
    this.canRequestCheck = data?.canRequestCheck ? data.canRequestCheck : this.canRequestCheck;
    this.sourceId = data?.sourceId ? data.sourceId : this.sourceId;
  }

  // Lifecycle
  ngOnInit(): void {
    this._guardianService
      .listChecksByDataset(this.checkResource, this.sourceId, this.currentDataset)
      .pipe(
        tap((guardianStatus) => {
          this.isLoadingList = false;
          this.guardianStatus = guardianStatus;
        }),
        mergeMap(() => {
          if (
            this._guardianService.currentDataset.type === 'datalakePath' &&
            this.currentDataset?.type === 'datalakePath'
          ) {
            const currentDatalakePath = this.currentDataset as IGuardianDatalakePath;
            const pathsToCheck = [this._guardianService.currentDataset.fullPath];
            this._logger.debug('[GUARDIAN_CHECK_MODAL] paths to check (write access) : ', pathsToCheck);
            const canWriteInPathsToCheck$ = pathsToCheck.map((fullPath) => {
              const fullDatalakePath: IGuardianDatalakePath = {
                path: fullPath.split('/').slice(1).join('/'),
                provider: currentDatalakePath?.provider,
                tenant: currentDatalakePath?.tenant,
                filesystem: fullPath.split('/')[0],
              };
              const fullDatalakePathKey = GuardianService.guardianDatalakePathToKey(fullDatalakePath);

              return fullDatalakePathKey?.length
                ? this._permissionsService.isAuthorized('upload', 'datalake', fullDatalakePathKey)
                : of(false);
            });

            return canWriteInPathsToCheck$?.length ? forkJoin(canWriteInPathsToCheck$) : of(null);
          } else {
            return of(this.canRequestCheck);
          }
        }),
        catchError((error: unknown) => {
          this._logger.error(
            '[GUARDIAN_CHECK_MODAL] Error while getting permissions about requesting current path Guardian check',
            error,
          );

          return of([] as boolean[]);
        }),
        takeUntil(this._destroyed$),
      )
      .subscribe((permissions) => {
        if (permissions?.length && permissions.every((permission) => permission)) {
          this.canRequestCheck = true;
        }
      });

    this._guardianService.checks$.pipe(takeUntil(this._destroyed$)).subscribe((checks) => {
      this.checks = checks;
    });
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
  }

  // Methods
  get isLoadingChecksList(): boolean {
    return !this.guardianStatus || this.isLoadingList;
  }

  get isReadyToOpenGuardianStatusModal(): boolean {
    return !!this.guardianStatus && !!this.currentDataset;
  }

  close(): void {
    this._activeMatModal.close();
  }

  requestCheck(): void {
    this._guardianService.requestCheck(this.guardianStatus.checkId, this.checkResource, this.currentDataset);
  }

  showDetails(check: GuardianCheck): void {
    if (check?.status === 'errored') {
      this.selectedCheck = check;
    }
  }

  back(): void {
    this.selectedCheck = null;
  }

  getModalTitle(): string {
    return this.guardianStatus ? 'Guardian check - ' + this.guardianStatus.checkInfos.name : 'Guardian check';
  }

  getModalSecondaryTitle(): string {
    return this.currentDataset.type === 'snowflake'
      ? this._amundsenService.getAmundsenTableKey(this.currentDataset)
      : '';
  }

  openGuardianInfos(): void {
    if (!this.isReadyToOpenGuardianStatusModal) {
      return;
    }

    this._dialogsService.open<GuardianCheckInfosModalComponent<R>>(
      GuardianCheckInfosModalComponent,
      {
        dataset: this.currentDataset,
        guardianStatus: { ...this.guardianStatus, type: this.checkResource },
      },
      ModalSizes.XL,
    );
  }
}
