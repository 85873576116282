import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MultiAuthService } from '@dataportal/auth';
import type { IBasePaginationRequesterState, IGraphAPIMetadata } from '@dataportal/front-api';
import { GenericApiPaginatedService } from '@dataportal/front-api';
import { Logger } from '@dataportal/front-shared';
import { Entity, EntityBuilder } from '@decahedron/entity';

export class IGraphAPIUser extends Entity {
  displayName: string;
  userPrincipalName: string;
  jobTitle: string;
  id: string;
}

interface IGraphAPIListUserResponse {
  '@odata.context': string;
  '@odata.nextLink': string;
  'value': IGraphAPIUser[];
}

@Injectable({
  providedIn: 'root',
})
/**
 Not used
 */
export class GraphApiService extends GenericApiPaginatedService<
  IGraphAPIUser,
  IGraphAPIMetadata,
  IBasePaginationRequesterState
> {
  constructor(
    private readonly _auth: MultiAuthService,
    private readonly _http: HttpClient,
    private readonly _logger: Logger,
  ) {
    super(null, _logger);
  }

  startListing(listOptions: IGraphAPIMetadata): string {
    // Initiate state
    const requesterId = super.initListing(listOptions);
    // Start requests
    setTimeout(() => this.fetchNextPage(requesterId), 0);

    return requesterId;
  }

  hasNext(requesterId: string): boolean {
    const { currentPage, metadata } = this.states.get(requesterId);

    return currentPage < 0 || !!metadata.nextPage;
  }

  protected nextResults(requesterId: string): Observable<void> {
    const { metadata } = this.states.get(requesterId);

    // Make request
    const searchTerm = metadata.query;
    const nextLink = metadata.nextPage;

    const url = searchTerm
      ? `https://graph.microsoft.com/v1.0/users?$search="displayName:${searchTerm}" OR "userPrincipalName:${searchTerm}"&$top=${metadata.limit}`
      : `https://graph.microsoft.com/v1.0/users?$top=${metadata.limit}`;

    return this._http
      .get<IGraphAPIListUserResponse>(nextLink || url, {
        headers: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          Authorization: `Bearer ${this._auth.accessToken}`,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          ConsistencyLevel: 'eventual',
        },
      })
      .pipe(
        map((response) => {
          // Update state
          const state = this.states.get(requesterId);
          const data = response.value;
          state.currentSize += data.length;
          state.metadata = {
            limit: metadata.limit,
            query: metadata.query,
            nextPage: response['@odata.nextLink'],
          };

          // Emit results
          this.logger.debug('[API Paginated Service] Next results received', response.value.length);
          this.results.next({ requesterId, data });

          return;
        }),
      );
  }

  protected buildOne(json: IGraphAPIUser): IGraphAPIUser {
    return EntityBuilder.buildOne(IGraphAPIUser, json);
  }

  protected buildMany(json: IGraphAPIUser[]): IGraphAPIUser[] {
    return EntityBuilder.buildMany(IGraphAPIUser, json);
  }
}
