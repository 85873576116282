import type { SafeResourceUrl } from '@angular/platform-browser';
import { Entity, Type } from '@decahedron/entity';

export interface IDashboardComment {
  id: string;
  author_id: string;
  author_name: string;
  message: string;
  created_at: string;
  updated_at: string;
  likes_counter: number;
  has_been_liked_by_current_user: boolean;
  nesting_level: number;
  source_id: string;
  dashboard_id: string;
  dashboard_name: string;
  parent_id: string;
  children_comments: IDashboardComment[];
  // used in front-end only
  pictureBase64?: SafeResourceUrl;
  isLoadingPicture?: boolean;
  hasToDisplayReplies?: boolean;
  hasToDisplayReplyZone?: boolean;
  seen_by?: string[];
  has_been_seen_by_current_user?: boolean;
}

export class DashboardComment extends Entity {
  id: string;
  authorId: string;
  authorName: string;
  message: string;
  createdAt: string;
  updatedAt: string;
  likesCounter: number;
  hasBeenLikedByCurrentUser: boolean;
  nestingLevel: number;
  sourceId: string;
  dashboardId: string;
  dashboardName: string;
  parentId?: string;
  @Type(DashboardComment)
  childrenComments?: DashboardComment[];
  seenBy?: string[];
  hasBeenSeenByCurrentUser?: boolean;
  // used in front-end only
  pictureBase64?: SafeResourceUrl;
  isLoadingPicture?: boolean;
  hasToDisplayReplies?: boolean;
  hasToDisplayReplyZone?: boolean;

  constructor(data: IDashboardComment) {
    super();
  }

  fromJson(data: IDashboardComment): DashboardComment {
    super.fromJson(data);
    this.id = data.id;
    this.authorId = data.author_id;
    this.authorName = data.author_name;
    this.message = data.message;
    this.createdAt = data.created_at;
    this.updatedAt = data.updated_at;
    this.likesCounter = data.likes_counter;
    this.hasBeenLikedByCurrentUser = data.has_been_liked_by_current_user;
    this.nestingLevel = data.nesting_level;
    this.sourceId = data.source_id;
    this.dashboardId = data.dashboard_id;
    this.dashboardName = data.dashboard_name;
    this.parentId = data.parent_id;
    this.pictureBase64 = data.pictureBase64;
    this.isLoadingPicture = data.isLoadingPicture;
    this.hasToDisplayReplies = data.hasToDisplayReplies;
    this.hasToDisplayReplyZone = data.hasToDisplayReplyZone;
    this.seenBy = data.seen_by;
    this.hasBeenSeenByCurrentUser = data.has_been_seen_by_current_user;

    return this;
  }

  toJson(): IDashboardComment {
    return {
      id: this.id,
      author_id: this.authorId,
      author_name: this.authorName,
      message: this.message,
      created_at: this.createdAt,
      updated_at: this.updatedAt,
      likes_counter: this.likesCounter,
      has_been_liked_by_current_user: this.hasBeenLikedByCurrentUser,
      nesting_level: this.nestingLevel,
      source_id: this.sourceId,
      dashboard_id: this.dashboardId,
      dashboard_name: this.dashboardName,
      parent_id: this.parentId,
      children_comments: this.childrenComments.map((comment) => comment.toJson()) || [],
      pictureBase64: this.pictureBase64,
      isLoadingPicture: this.isLoadingPicture,
      hasToDisplayReplies: this.hasToDisplayReplies,
      hasToDisplayReplyZone: this.hasToDisplayReplyZone,
      seen_by: this.seenBy,
      has_been_seen_by_current_user: this.hasBeenSeenByCurrentUser,
    };
  }

  toJsonUpdate() {
    return {
      message: this.message,
    };
  }

  toJsonCreate() {
    return {
      ...this.toJsonUpdate(),
      parent_id: this.parentId,
    };
  }
}
