import { CommonModule } from '@angular/common';
import type { ModuleWithProviders } from '@angular/core';
import { NgModule } from '@angular/core';
import type { IAuthOptions } from '@dataportal/auth';
import { AuthModule } from '@dataportal/auth';
import { DatalakeModule } from '@dataportal/datalake-and-guardian';
import type { IDatalakeModuleOptions } from '@dataportal/datalake-parsing';
import type { IFlatfileOptions } from '@dataportal/flatfile';
import { FlatfileModule } from '@dataportal/flatfile';
import type { IApiUrls } from '@dataportal/front-api';
import { ApiModule } from '@dataportal/front-api';
import type { IGraphApiOptions } from '@dataportal/front-graph-api';
import { GraphApiModule } from '@dataportal/front-graph-api';
import type { ISharedOptions } from '@dataportal/front-shared';
import { SharedModule } from '@dataportal/front-shared';
import { LogsModule } from '@dataportal/logs';
import { NavigatorModule } from '@dataportal/navigator';
import type { IPermissionsDefaultedOptions } from '@dataportal/permissions';
import { PermissionsModule } from '@dataportal/permissions';
import { SourceStatisticsModule } from '@dataportal/source-statistics';
import type { IDashboardsOptions } from '@dataportal/sources-dashboards-recommendations';
import { DashboardsModule } from '@dataportal/sources-dashboards-recommendations';
import { UsersModule } from '@dataportal/users';
import { ToastrModule } from 'ngx-toastr';

// Types
export interface ISetupOptions {
  api: IApiUrls;
  auth: IAuthOptions;
  dashboards: IDashboardsOptions;
  permissions?: IPermissionsDefaultedOptions;
  shared: ISharedOptions;
  flatfile: IFlatfileOptions;
  graphApi: IGraphApiOptions;
  datalake: IDatalakeModuleOptions;
}

// Module
// @dynamic
@NgModule({
  imports: [
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    }),
    ApiModule,
    CommonModule,
    GraphApiModule,
    AuthModule,
    DashboardsModule,
    LogsModule,
    NavigatorModule,
    PermissionsModule,
    SharedModule,
    SourceStatisticsModule,
    UsersModule,
    FlatfileModule,
  ],
  exports: [ToastrModule, SharedModule, GraphApiModule],
})
export class DataportalSetupModule {
  // Statics
  static forRoot(options: ISetupOptions): ModuleWithProviders<DataportalSetupModule> {
    return {
      ngModule: DataportalSetupModule,
      providers: [
        ...ApiModule.forRoot(options.api).providers,
        ...AuthModule.forRoot(options.auth).providers,
        ...DashboardsModule.forRoot(options.dashboards).providers,
        ...PermissionsModule.forRoot(options.permissions).providers,
        ...SharedModule.forRoot(options.shared).providers,
        ...FlatfileModule.forRoot(options.flatfile).providers,
        ...GraphApiModule.forRoot(options.graphApi).providers,
        ...DatalakeModule.forRoot(options.datalake).providers,
      ],
    };
  }
}
