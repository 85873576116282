<div [ngClass]="containerClassList">
  <div *ngFor="let choice of choices">
    <label class="adl--radio-selector--container mb5" [ngClass]="choiceComponentClassList">
      <div class="flex items-center" [ngClass]="choiceFlexClassList">
        <input
          type="radio"
          [name]="selectorName"
          [value]="choice.value"
          [checked]="valueEqualityCheck(choice.value, activeChoice)"
          (click)="selectRadio(choice.value)"
        />

        <span *ngIf="radioPosition === 'left'" class="adl--radio-selector--checkmark mr-[10px]"
              [class.new]="radioStyle === 'new'"></span>

        <div class="flex items-center radio-content-container flex-1">
          <ng-container [ngTemplateOutlet]="template"></ng-container>
          <adl-text
            [ngClass]="{'cursor-not-allowed' : choice.isLabelNotAllowed}"
            [class.new]="radioStyle === 'new'"
            [size]="radioSelectorLabelSize"
            [color]="radioSelectorLabelColor"
            [weight]="radioSelectorLabelWeight"
            (click)="onLabelClicked(choice.value)"
          >{{ choice.label }}</adl-text>

          <adl-info-bubble class="ml5 flex flex-col justify-center" *ngIf="choice.infoBubbleContent">
            {{ choice.infoBubbleContent }}
          </adl-info-bubble>

          <adl-icon-button
            *ngFor="let iconButton of iconButtons; let btnIndex = index"
            class="ml-[10px]"
            [icon]="iconButton"
            (clicked)="this.onIconButtonClicked($event, choice.value, btnIndex)"
          ></adl-icon-button>
        </div>

        <span *ngIf="radioPosition === 'right'" class="adl--radio-selector--checkmark ml-[10px] mr-[10px]"
              [class.new]="radioStyle === 'new'"></span>

      </div>
      <div *ngIf="hasSeparator" class="radio-selector-separator mt-[5px]"></div>
    </label>
  </div>
</div>

