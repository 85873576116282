import type { OnDestroy, OnInit } from '@angular/core';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AlertService, Logger } from '@dataportal/front-shared';
import { FormControl } from '@ngneat/reactive-forms';

import { PowerBiBookmarksService } from '../../services/power-bi-bookmarks.service';

import type { Source } from '../../../sources/entities/source';
import type { Dashboard } from '../../entities/dashboard.model';
import type { IDashboardBookmark } from '../../services/power-bi-bookmarks.service';

// Component
@Component({
  selector: 'dpg-dashboard-share-bookmark-modal',
  templateUrl: './dashboard-share-bookmark-modal.component.html',
  styleUrls: ['./dashboard-share-bookmark-modal.component.scss'],
})
export class DashboardShareBookmarkModalComponent implements OnInit, OnDestroy {
  // Attributes
  source: Source;
  dashboard: Dashboard;
  bookmark: IDashboardBookmark;
  selectedUserId: string;
  mailsList: string[] = [];
  selectableUsers: string[] = [];
  clearUserSelect$ = new Subject<void>();
  sharedNameControl = new FormControl<string>();

  private readonly _destroyed$ = new Subject<void>();

  constructor(
    private readonly _logger: Logger,
    private readonly _alertService: AlertService,
    private readonly _powerBiBookmarksService: PowerBiBookmarksService,
    private readonly _activeMatModal: MatDialogRef<DashboardShareBookmarkModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    data: {
      bookmark: IDashboardBookmark;
      source: Source;
      dashboard: Dashboard;
    },
  ) {
    this.bookmark = data?.bookmark ? data.bookmark : this.bookmark;
    this.source = data?.source ? data.source : this.source;
    this.dashboard = data?.dashboard ? data.dashboard : this.dashboard;
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
  }

  ngOnInit(): void {
    this._powerBiBookmarksService
      .listPossibleUsersToShareTo(this.source.id, this.dashboard.id)
      .pipe(takeUntil(this._destroyed$))
      .subscribe((userList) => {
        this.selectableUsers = userList;
      });
  }

  addMail(): void {
    if (this.selectedUserId) {
      this.mailsList.push(this.selectedUserId);
      this.clearUserSelect$.next();
    }
  }

  deleteMail(toDelete: string): void {
    const index = this.mailsList.findIndex((mail) => {
      return mail === toDelete;
    });

    if (index > -1) {
      this.mailsList.splice(index, 1);
    }
  }

  close(): void {
    this._activeMatModal.close();
  }

  get canShare() {
    return !!this.sharedNameControl?.value && this.mailsList.length > 0;
  }

  send(): void {
    if (this.canShare) {
      const bookmarkToShare: IDashboardBookmark = {
        ...this.bookmark,
        name: this.sharedNameControl.value,
        sharedName: this.sharedNameControl.value,
      };
      this._powerBiBookmarksService
        .shareBookmark(this.source.id, this.dashboard.id, bookmarkToShare, this.mailsList)
        .subscribe(() => {
          this._alertService.success('Bookmark successfully shared');
          this._activeMatModal.close();
        });
    }
  }

  setUser(selectedUserId: string): void {
    this.selectedUserId = selectedUserId;
  }
}
