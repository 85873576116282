import { CommonModule, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AccessRequestsModule } from '@dataportal/access-requests';
import { AdlModule } from '@dataportal/adl';
import { AlertsModule } from '@dataportal/alerts';
import { AnalyticsModule } from '@dataportal/analytics';
import { AuthInterceptor } from '@dataportal/auth';
import { GlossaryCommonModule } from '@dataportal/business-glossary-common';
import { CategoriesModule } from '@dataportal/categories';
import { CompaniesModule } from '@dataportal/companies';
import { DataCatalogCommonModule } from '@dataportal/data-catalog-common';
import { DataToolsModule } from '@dataportal/data-tools';
import { DatabricksModule } from '@dataportal/databricks';
import { DatalakeModule } from '@dataportal/datalake-and-guardian';
import { DataportalSetupModule } from '@dataportal/dataportal-setup';
import { ErrorToastsInterceptor } from '@dataportal/front-api';
import { EnvironmentModule } from '@dataportal/front-environment';
import { SharedModule } from '@dataportal/front-shared';
import { GroupsModule } from '@dataportal/groups';
import { ImpersonateModule } from '@dataportal/impersonate';
import { KpisModule } from '@dataportal/kpis';
import { LayoutModule } from '@dataportal/layout';
import { NotificationsModule } from '@dataportal/notifications';
import { PermissionsModule } from '@dataportal/permissions';
import { PortalsModule } from '@dataportal/portals';
import { ProfileModule } from '@dataportal/profile';
import { PublicationsModule } from '@dataportal/publications';
import { RelationshipsModule } from '@dataportal/relationships';
import { RouterEventsModule } from '@dataportal/router-events';
import { SnowflakeModule } from '@dataportal/snowflake';
import { RecommendationsModule, SourcesModule } from '@dataportal/sources-dashboards-recommendations';
import { SyncAdModule } from '@dataportal/sync-ad';
import { ToolsModule } from '@dataportal/tools';
import { UsersModule } from '@dataportal/users';
import { WebsocketModule } from '@dataportal/websocket';
import { WordingModule } from '@dataportal/wording';
import { NgSelectModule } from '@ng-select/ng-select';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';

import { AppRoutingModule } from './app-routing.module';

import { HelpCenterService } from './help-center/help-center.service';
import { LocaleService } from './locale.service';

import { AppComponent } from './app.component';
import { AboutComponent } from './help-center/about/about.component';
import { FaqComponent } from './help-center/faq/faq.component';
import { HelpCenterComponent } from './help-center/help-center.component';
import { HelpSearchComponent } from './help-center/search/help-search.component';
import { RefreshComponent } from './refresh/refresh.component';
import { SupportComponent } from './support/support.component';

import { environment } from '../environments/environment';

// the second parameter 'fr' is optional
registerLocaleData(localeFr, 'fr');

@NgModule({
  declarations: [
    AppComponent,
    RefreshComponent,
    SupportComponent,
    HelpCenterComponent,
    AboutComponent,
    FaqComponent,
    HelpSearchComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    SharedModule,
    CommonModule,
    NgSelectModule,
    AccessRequestsModule,
    AdlModule,
    CategoriesModule,
    CompaniesModule,
    AlertsModule,
    GroupsModule,
    KpisModule,
    WordingModule,
    PermissionsModule,
    PortalsModule,
    PublicationsModule,
    RouterEventsModule,
    RecommendationsModule,
    SyncAdModule,
    UsersModule,
    ToolsModule,
    RelationshipsModule,
    ProfileModule,
    DatalakeModule,
    AnalyticsModule,
    LayoutModule.forRoot({ logoNotToPaint: environment.logoNotToPaint, dpContext: environment.dpContext }),
    DataportalSetupModule.forRoot({
      api: environment.apiUrls,
      auth: {
        baseUrl: environment.baseUrl,
        authUriSuffix: environment.authUriSuffix,
        telemetryUrl: environment.apiUrls.v4.telemetry,
        telemetryKey: environment.telemetry.key,
        e2e: environment.e2e,
        production: environment.production,
        azureAD: environment.azureAD,
        supportLink:
          'https://pernod-ricard--bmcservicedesk.eu19.visual.force.com/apex/selfservicenew#/support/problem-requests/all',
        requireImpersonationScopeRoutes: environment.requireImpersonationScopeRoutes,
        supportedAuthenticators: environment.supportedAuthenticators as Array<'cognito' | 'azureAD'>,
        cognitoConfig: environment.cognitoConfig,
      },
      dashboards: {
        baseUrl: environment.baseUrl,
        sourcesAllowedToExport: environment.sourcesAllowedToExport,
      },
      shared: {
        environment: environment.name,
        internalUserEmail: environment.internalUserEmail,
        prUserEmail: environment.prUserEmail,
        adminDatalakeSupportLink: environment.adminDatalakeSupportLink,
        adminV2HelpLink: environment.adminV2HelpLink,
        sourceHelp: environment.sourceHelp,
        datalakeMetadataToDisplay: environment.datalakeMetadataToDisplay,
        leon: environment.leon,
      },
      flatfile: {
        environment: environment.name,
        flatfileLicenseKey: environment.flatfileLicenseKey,
      },
      datalake: environment.datalakeFileLimits,
      graphApi: {
        defaultMode: 'graphAPI',
      },
    }),
    WebsocketModule.forRoot(environment.apiUrls.websocket),
    SourcesModule,
    DataCatalogCommonModule,
    DatabricksModule,
    DataToolsModule.forRoot({
      datalakeRouterLink: '/datalake',
      notebooksRouterLink: '/notebooks',
    }),
    NotificationsModule,
    SnowflakeModule,
    ImpersonateModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    EnvironmentModule.forRoot({
      dpContext: environment.dpContext,
      dataProviders: environment.dataProviders,
      supportedAuthenticators: environment.supportedAuthenticators as Array<'cognito' | 'azureAD'>,
      dashboardTypesAndNames: environment.dashboardTypesAndNames,
      msalOptions: environment.msalOptions,
      snowflake: environment.snowflake,
    }),
    GlossaryCommonModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot({}),
    environment.production ? [] : StoreDevtoolsModule.instrument({ maxAge: 50 }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorToastsInterceptor,
      multi: true,
    },

    {
      provide: LOCALE_ID,
      useFactory: (localeService: LocaleService) => {
        return localeService.getLanguage();
      },
      deps: [LocaleService],
    },

    HelpCenterService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
