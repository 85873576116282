import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import type { Observable } from 'rxjs';
import { extractQueryParameters, removeFragment } from '@dataportal/adl';
import { ApiService } from '@dataportal/front-api';

import type { IArticleDocument } from '../models/article.model';
import type { IDocumentation } from '../models/documentation.model';

@Injectable({
  providedIn: 'root',
})
export class DocumentationService {
  constructor(private readonly _api: ApiService, private readonly _router: Router) {}

  getDocumentation$(id: IDocumentation['id']): Observable<IDocumentation> {
    if (isNaN(id)) throw Error('[getDocumentation] wrong parameter');

    return this._api.get<IDocumentation>(`v4/data-academy/documentation/${id}`);
  }

  openDocumentation(documentation: IDocumentation | IArticleDocument, checkViews = false) {
    if (documentation.isInternal) {
      // Unable to open doc if fragments in url
      const currentUrlWithoutFragments = removeFragment(this._router.url);
      const [urlWithoutParams, queryParams] = extractQueryParameters(currentUrlWithoutFragments);

      let url = `${urlWithoutParams}/(documentation:${documentation.id})?checkViews=${checkViews}`;

      if (queryParams && queryParams.length) {
        url = url + `&${queryParams}`;
      }

      this._router.navigateByUrl(url);

      return;
    }

    if (documentation.url) window.open(documentation.url, '_blank');
  }
}
