import type { OverlayRef } from '@angular/cdk/overlay';
import { Overlay } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import type { OnDestroy } from '@angular/core';
import { Directive, ElementRef, HostBinding, HostListener, Input, ViewContainerRef } from '@angular/core';
import type { Observable } from 'rxjs';
import { merge, Subscription } from 'rxjs';

import { IDropdownPanel } from './dropdown-panel.model';

@Directive({
  selector: '[dropdownTriggerFor]',
})
export class DropdownTriggerDirective implements OnDestroy {
  private _isDropdownOpen = false;
  private _overlayRef: OverlayRef;
  private _dropdownClosingActionsSub = Subscription.EMPTY;

  @Input('dropdownTriggerFor') dropdownPanel: IDropdownPanel;
  @HostBinding('style.cursor') cursorStyle = 'pointer';

  @HostListener('click') dropdownClicked() {
    if (this._isDropdownOpen) {
      this._destroyDropdown();

      return;
    }

    this.openDropdown();
  }

  constructor(
    private readonly _overlay: Overlay,
    private readonly _elementRef: ElementRef<HTMLElement>,
    private readonly _viewContainerRef: ViewContainerRef,
  ) {}

  openDropdown(): void {
    // todo: get width based on a parent element (later)
    if (this.dropdownPanel) {
      this._isDropdownOpen = true;
      this._overlayRef = this._overlay.create({
        hasBackdrop: true,
        backdropClass: 'cdk-overlay-transparent-backdrop',
        scrollStrategy: this._overlay.scrollStrategies.close(),
        positionStrategy: this._overlay
          .position()
          .flexibleConnectedTo(this._elementRef)
          .withPositions([
            {
              originX: 'start',
              originY: 'bottom',
              overlayX: 'start',
              overlayY: 'top',
              offsetY: 0,
            },
          ])
          .withFlexibleDimensions(true)
          .withPush(false),
      });

      const templatePortal = new TemplatePortal(this.dropdownPanel.templateRef, this._viewContainerRef);
      this._overlayRef.attach(templatePortal);

      this._dropdownClosingActionsSub = this._dropdownClosingActions().subscribe(() => this._destroyDropdown());
    }
  }

  private _dropdownClosingActions(): Observable<MouseEvent | void> {
    const backdropClick$ = this._overlayRef.backdropClick();
    const detachment$ = this._overlayRef.detachments();
    const dropdownClosed = this.dropdownPanel.closed;

    return merge(backdropClick$, detachment$, dropdownClosed);
  }

  private _destroyDropdown(): void {
    if (!this._overlayRef || !this._isDropdownOpen) {
      return;
    }

    this._dropdownClosingActionsSub.unsubscribe();
    this._isDropdownOpen = false;
    this._overlayRef.detach();
  }

  ngOnDestroy(): void {
    if (this._overlayRef) {
      this._overlayRef.dispose();
    }
  }
}
