import { Injectable } from '@angular/core';

import { SharedModule } from '../shared.module';

// Services
@Injectable({
  providedIn: SharedModule,
})
export class DeviceService {
  // Methods
  isMobile(): boolean {
    if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i) ||
      navigator.userAgent.match(/Touch/i) ||
      navigator.userAgent.match(/Tablet/i)
    ) {
      return true;
    }

    return false;
  }
}
