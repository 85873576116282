import type { IUnidirectionalLink } from '@dataportal/types';
import { LinkDirection } from '@dataportal/types';

import { SourceLink } from './sourceLink';

export class UnidirectionalLink extends SourceLink {
  // Attributes
  type = LinkDirection.UNIDIRECTIONAL;

  // Methods
  toJson(): IUnidirectionalLink {
    const common = super.toJson();

    return {
      ...common,
      type: LinkDirection.UNIDIRECTIONAL,
    };
  }
}
