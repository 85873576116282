import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, forkJoin, Observable, of, throwError } from 'rxjs';
import { catchError, first, flatMap, last, map, tap } from 'rxjs/operators';
import type { IResourceUploadService, IUploadedResource } from '@dataportal/adl';
import { ApiPaginatedService, ApiService } from '@dataportal/front-api';
import { Logger } from '@dataportal/front-shared';
import { SourceStatisticsService } from '@dataportal/source-statistics';
import type { ISource } from '@dataportal/types';
import { IProfile, UsersService } from '@dataportal/users';
import { EntityBuilder } from '@decahedron/entity';
import moment from 'moment';

import { FolderSelectorModalComponent } from '../components/folder-selector-modal/folder-selector-modal.component';

import { Source } from '../entities/source';
import { SourceDraft } from '../entities/source-draft';

// Types
export enum OwnersType {
  Technical = 'Technical',
  Functional = 'Functional',
}

export interface IOwnerProfile {
  userId: string;
  profile: IProfile;
  type: OwnersType;
}

export interface IThumbnail {
  id: string;
  url: string;
}

// Service
@Injectable()
export class SourcesService extends ApiPaginatedService<Source> implements IResourceUploadService {
  // Attributes
  url = 'v5/admin/sources';

  private readonly _isLoadingAllSources$ = new BehaviorSubject<boolean>(false);
  readonly isLoadingAllSources$ = this._isLoadingAllSources$.asObservable();
  private _cacheUntil?: moment.Moment;
  private readonly _cachedSources$ = new BehaviorSubject<Source[]>([]);

  private readonly _allSources$ = new BehaviorSubject<(Source | SourceDraft)[]>([]);
  readonly allSources$ = this._allSources$.asObservable();

  // Constructor
  constructor(
    logger: Logger,
    apiService: ApiService,
    private readonly _http: HttpClient,
    private readonly _modalMatService: MatDialog,
    private readonly _statsService: SourceStatisticsService,
    private readonly _usersService: UsersService,
  ) {
    super(apiService, logger);
  }

  // Methods
  protected buildOne(json: any): Source {
    return EntityBuilder.buildOne(Source, json);
  }

  protected buildMany(json: any[]): Source[] {
    return EntityBuilder.buildMany(Source, json);
  }

  invalidateCache(): void {
    this._cacheUntil = undefined;
  }

  allSources(): Observable<Source[]> {
    this.logger.info('[SourcesService] Fetching sources.');
    const isBeforeCacheUntil = !!this._cacheUntil && moment().isBefore(this._cacheUntil);
    const hasCachedResult = !!this._cachedSources$.getValue()?.length;
    const shouldFetchFromBackEnd = (!isBeforeCacheUntil || !hasCachedResult) && !this._isLoadingAllSources$.value;
    this.logger.info('[SourcesService] Cache information', {
      cacheUntil: this._cacheUntil?.toISOString(),
      isBeforeCacheUntil,
      hasCachedResult,
      isLoadingAllSources: this._isLoadingAllSources$.value,
      shouldFetchFromBackEnd,
    });

    if (shouldFetchFromBackEnd) {
      this.logger.info('[SourcesService] Cache invalidated, fetching from back-end');
      this._isLoadingAllSources$.next(true);

      return this.apiService.get<unknown[]>('/v4/sources').pipe(
        map((sources) => {
          this.logger.info('[SourcesService] Sources fetched', sources);
          this._cacheUntil = moment().add(5, 'minutes');
          const formattedSources: Source[] = EntityBuilder.buildMany(Source, sources);
          this._cachedSources$.next(formattedSources);
          this._isLoadingAllSources$.next(false);

          return formattedSources;
        }),
        catchError((err: unknown) => {
          this.logger.error('[SourcesService] Error fetching sources', err);
          this._cacheUntil = null;
          this._cachedSources$.next([]);
          this._isLoadingAllSources$.next(false);

          return throwError(err);
        }),
      );
    } else if (this._isLoadingAllSources$.value) {
      this.logger.info('[SourcesService] Sources are already being fetched. Waiting it.');
    } else {
      this.logger.info(
        '[SourcesService] Sources have been fetched less than 5 minutes ago. Using cache',
        this._cachedSources$.getValue(),
      );
    }

    return this._cachedSources$.pipe(first((sources) => !!sources.length));
  }

  nonPaginatedList(fromAdmin?: boolean): Observable<Source[]> {
    return this.apiService
      .get<any[]>(fromAdmin ? '/v4/admin/sources' : '/v4/sources')
      .pipe(map((sources) => EntityBuilder.buildMany<Source>(Source, sources)));
  }

  search(input: string): Observable<Source[]> {
    // Refactor into search once it's available
    return this.allSources().pipe(
      map((sources: Source[]) => {
        return sources.filter((source: Source) => {
          return source.id.indexOf(input) !== -1 || source.name.indexOf(input) !== -1;
        });
      }),
    );
  }

  private _findSource(sourceId: string, isLimited: boolean, isSilent = false): Observable<Source> {
    const options = isSilent
      ? {
          errorHandling: {
            level: 'silent',
          },
        }
      : {};

    return this.apiService
      .get(`/v4/sources/${sourceId}` + (isLimited ? '?limited=true' : ''), options)
      .pipe(map((source) => EntityBuilder.buildOne<Source>(Source, source)));
  }

  findSource(sourceId: string, fromCache = false, isSilent = false): Observable<Source> {
    if (fromCache && this._cachedSources$.getValue().some((s) => s.id === sourceId)) {
      return of(this._cachedSources$.getValue().find((s) => s.id === sourceId));
    }

    return this._findSource(sourceId, false, isSilent);
  }

  findSourceLimited(sourceId: string, isSilent = false): Observable<Source> {
    return this._findSource(sourceId, true, isSilent);
  }

  create(source: Source, originDraftId?: string): Observable<Source> {
    return this.apiService.post<Source>('/v4/sources', { ...source.toJson(), origin_draft_id: originDraftId }).pipe(
      map((result) => {
        this.flushCache();

        return EntityBuilder.buildOne<Source>(Source, result);
      }),
    );
  }

  update(source: Source): Observable<Source> {
    return this.apiService.put<Source>(`/v4/sources/${source.id}`, source.toJson()).pipe(
      map((result) => {
        this.flushCache();

        return result;
      }),
    );
  }

  nonPaginatedDelete(source: Source): Observable<Source> {
    return this.apiService.delete<Source>(`/v4/sources/${source.id}`).pipe(
      map((result) => {
        this.flushCache();

        return result;
      }),
    );
  }

  flushCache(): void {
    this._cacheUntil = undefined;
  }

  openDownloadModal(source: Source): void {
    if (source.datalakePath.length > 0) {
      this._modalMatService.open(FolderSelectorModalComponent, {
        width: '900px',
        minWidth: '900px',
        maxHeight: '90vh',
        backdropClass: 'modal-backdrop',
        data: {
          source: source,
        },
      });
    }
  }

  getProfiles(source: Source): Observable<IOwnerProfile[]> {
    const { functionalOwners, technicalOwners } = source;

    return forkJoin([
      ...functionalOwners.map((owner) =>
        this._usersService.getProfile(owner).pipe(
          map((profile) => {
            return {
              userId: owner,
              profile,
              type: OwnersType.Functional,
            };
          }),
        ),
      ),
      ...technicalOwners.map((owner) =>
        this._usersService.getProfile(owner).pipe(
          map((profile) => {
            return {
              userId: owner,
              profile,
              type: OwnersType.Technical,
            };
          }),
        ),
      ),
    ]);
  }

  getResourceUrl(id: string): Observable<string> {
    return this.getThumbnail(id);
  }

  uploadResource(file: File): Observable<IUploadedResource> {
    return this.uploadThumbnail(file);
  }

  getThumbnail(id: string): Observable<string> {
    if (!id) {
      return of(null);
    }

    this.logger.debug('[SourceService] Requesting thumbnail signed URL');

    return this.apiService
      .get<string>(`v4/sources/thumbnails/${encodeURIComponent(id)}`, {
        errorHandling: { level: 'silent' },
      })
      .pipe(
        tap(
          () => this.logger.debug('[SourceService] Thumbnail download signed URL generated'),
          catchError((err: unknown) => {
            this.logger.error('[SourceService] Error getting thumbnail download signed URL', err);

            return throwError(err);
          }),
        ),
      );
  }

  uploadThumbnail(file: File): Observable<IThumbnail> {
    this.logger.debug('[SourceService] Uploading thumbnail');
    this.logger.debug('[SourceService] Requesting signed URL');

    return this.apiService
      .put<{ id: string; url: string }>('v4/sources/thumbnails', {
        errorHandling: { level: 'silent' },
      })
      .pipe(
        flatMap((response) => {
          this.logger.debug('[SourceService] Signed URL retrieved');
          const req = new HttpRequest('PUT', response.url, file, {
            headers: new HttpHeaders().set('Content-Type', file.type),
            responseType: 'text' as const,
            reportProgress: true,
          });
          this.logger.debug('[SourceService] Uploading thumbnail');

          return this._http.request(req).pipe(
            last(),
            map(() => response.id),
            catchError((err: unknown) => {
              this.logger.error('[SourceService] Error uploading file using signed URL', err);

              return throwError(err);
            }),
          );
        }),
        catchError((err: unknown) => {
          this.logger.error('[SourceService] Error obtaining signed URL', err);

          return throwError(err);
        }),
      )
      .pipe(
        flatMap((id) => {
          this.logger.debug('[SourceService] Thumbnail uploaded');

          return this.getThumbnail(id).pipe(
            map((downloadUrl) => ({
              id,
              url: downloadUrl,
            })),
          );
        }),
      )
      .pipe(
        catchError((err: unknown) => {
          this.logger.error('[SourceService] Error uploading file', err);

          return throwError(err);
        }),
      );
  }

  isSourceComplete(source: Source): boolean {
    return !source.isLimited;
  }

  deleteSource(id: string): Observable<void> {
    return this.apiService.delete('/v4/sources/' + id);
  }

  getListSourcesV6() {
    this._isLoadingAllSources$.next(true);

    this.apiService.get<{ data: { sources: ISource[]; drafts: ISource[] } }>('/v4/admin/sources-all').subscribe(
      (response) => {
        const sources: Source[] = response.data.sources.map((source) => new Source().fromJson(source));
        const drafts: SourceDraft[] = response.data.drafts.map((draft) => new SourceDraft().fromJson(draft));
        const allSources: (Source | SourceDraft)[] = [...sources, ...drafts];

        this._allSources$.next(allSources);
        this._isLoadingAllSources$.next(false);
      },
      (err: unknown) => {
        this._isLoadingAllSources$.next(false);
      },
    );
  }

  getDatalakePathRestrictedGroups(sourceId: string, datalakePath: string) {
    return this.apiService.get<string[]>(
      `/v4/sources/${sourceId}/datalake/allowed-groups?datalakePath=${encodeURIComponent(datalakePath)}`,
    );
  }
}
