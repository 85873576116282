import { Injectable } from '@angular/core';
import type { CanActivate, CanLoad } from '@angular/router';
import { Logger } from '@dataportal/front-shared';

import { AuthModule } from '../auth.module';

import { MultiAuthService } from '../services/multi-auth.service';

// Guard
@Injectable({
  providedIn: AuthModule,
})
export class AuthGuard implements CanActivate, CanLoad {
  // Constructor
  constructor(private readonly _auth: MultiAuthService, private readonly _logger: Logger) {}

  // Methods
  private _canAccess(): boolean {
    if (!this._auth.isAuthenticated()) {
      this._logger.debug('[Auth Guard] Not authenticated !');
      this._auth.login();

      return false;
    } else {
      return true;
    }
  }

  canActivate(): boolean {
    return this._canAccess();
  }

  canLoad(): boolean {
    return this._canAccess();
  }
}
