import { CommonModule } from '@angular/common';
import type { ModuleWithProviders } from '@angular/core';
import { NgModule } from '@angular/core';
import { SharedModule } from '@dataportal/front-shared';

import type { IApiUrls } from './api-urls';
import { API_URLS } from './api-urls';

// Module
// @dynamic
@NgModule({
  imports: [CommonModule, SharedModule],
  providers: [],
})
export class ApiModule {
  // Statics
  static forRoot(urls: IApiUrls): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [{ provide: API_URLS, useValue: urls }],
    };
  }
}
