import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import type { ErrorHandlingOptions } from '@dataportal/front-api';
import { ApiService } from '@dataportal/front-api';
import type { DatalakePath, IAzureMetadata, IDatalakeMetadata, IObjectExistenceResult } from '@dataportal/front-shared';
import { Logger } from '@dataportal/front-shared';

import type {
  IBucket,
  IDatalakeAPIOptions,
  IDatalakeTarget,
  IDatalakeWithPermissionListResponse,
  IFormattedObjectPathWithMetadataInfos,
} from '../entities/datalake';
import { normalizedPath } from '../entities/datalake';

export interface IDatalakeAPIUploadResponse {
  url: string;
  currentMetadata: IAzureMetadata;
}

@Injectable({ providedIn: 'root' })
export class DatalakeApiService {
  constructor(
    private readonly _apiService: ApiService,
    private readonly _http: HttpClient,
    private readonly _logger: Logger,
  ) {}

  private static _encodeMetadataOptions(options: IDatalakeAPIOptions) {
    if (options.tokens) {
      for (const token of options.tokens) {
        Object.assign(options, {
          [`token-${token.provider}${token.tenant ? '-' + token.tenant : ''}`]: token.token,
        });
      }
    }
  }

  private static _pathToOptions(datalakePath: DatalakePath): IDatalakeAPIOptions {
    return {
      provider: datalakePath.provider,
      tenant: datalakePath.tenant,
      path: normalizedPath(datalakePath.path.split('/').splice(1).join('/')),
    };
  }

  private static _buildQueryParameters(options: IDatalakeAPIOptions): string {
    return Object.keys(options)
      .filter((key) => options[key] != null)
      .map(
        (key) =>
          `${key}=${encodeURIComponent(
            typeof options[key] === 'object' ? JSON.stringify(options[key]) : options[key],
          )}`,
      )
      .join('&');
  }

  /**
   * List all buckets
   */
  listBuckets(): Observable<IBucket[]> {
    return this._apiService.get<IBucket[]>('/v4/datalake/buckets');
  }

  /**
   * List every object in a given location, is paginated
   * @param bucketName
   * @param options
   * @param errorHandling
   */
  listObjects(
    bucketName: string,
    options: IDatalakeAPIOptions,
    errorHandling?: ErrorHandlingOptions,
  ): Observable<IDatalakeWithPermissionListResponse> {
    this._logger.info('[DatalakeApiService] Listing object from back-end');
    DatalakeApiService._encodeMetadataOptions(options);
    this._logger.info('[DatalakeApiService] Params:');
    this._logger.info(options);

    return this._apiService.get<IDatalakeWithPermissionListResponse>(
      `/v4/datalake/${encodeURIComponent(bucketName)}?${DatalakeApiService._buildQueryParameters(options)}`,
      errorHandling ? { errorHandling } : {},
    );
  }

  /**
   * Create a new folder
   * @param bucketName
   * @param options
   * @param folderName
   */
  createFolder(bucketName: string, options: IDatalakeAPIOptions, folderName: string): Observable<void> {
    return this._apiService.post(
      `/v4/datalake/${encodeURIComponent(bucketName)}/folders?${DatalakeApiService._buildQueryParameters(options)}`,
      {
        name: folderName,
      },
    );
  }

  /**
   * Delete an object
   * @param bucketName
   * @param options
   */
  deleteObject(bucketName: string, options: IDatalakeAPIOptions): Observable<void> {
    return this._apiService.delete<void>(
      `/v4/datalake/${encodeURIComponent(bucketName)}/objects?${DatalakeApiService._buildQueryParameters(options)}`,
    );
  }

  /**
   * Move an object
   * @param bucketName
   * @param options
   * @param target
   */
  moveObject(bucketName: string, options: IDatalakeAPIOptions, target: IDatalakeTarget): Observable<void> {
    return this._apiService.put<void>(
      `/v4/datalake/${encodeURIComponent(bucketName)}/objects/move?${DatalakeApiService._buildQueryParameters(
        options,
      )}`,
      target,
    );
  }

  /**
   * Copy an object
   * @param bucketName
   * @param options
   * @param target
   */
  copyObject(bucketName: string, options: IDatalakeAPIOptions, target: IDatalakeTarget): Observable<void> {
    return this._apiService.post<void>(
      `/v4/datalake/${encodeURIComponent(bucketName)}/objects/copy?${DatalakeApiService._buildQueryParameters(
        options,
      )}`,
      target,
    );
  }

  /**
   * Rename an object
   * @param bucketName
   * @param options
   * @param newObjectName
   */
  renameObject(bucketName: string, options: IDatalakeAPIOptions, newObjectName: string): Observable<void> {
    return this._apiService.put<void>(
      `/v4/datalake/${encodeURIComponent(bucketName)}/objects/rename-object?${DatalakeApiService._buildQueryParameters(
        options,
      )}`,
      {
        newName: newObjectName,
      },
    );
  }

  /**
   * Generate upload URL
   * @param bucketName
   * @param options
   * @param objectName
   * @param metadata
   */
  generateUploadURL(
    bucketName: string,
    options: IDatalakeAPIOptions,
    objectName: string,
    metadata?: Record<string, unknown>,
  ): Observable<IDatalakeAPIUploadResponse> {
    return this._apiService.post<IDatalakeAPIUploadResponse>(
      `/v4/datalake/${encodeURIComponent(bucketName)}/upload?${DatalakeApiService._buildQueryParameters(options)}`,
      {
        name: objectName,
        metadata,
      },
    );
  }

  /**
   * Get DP-formatted object path infos from PR/guardian-naming object path infos
   * @param bucket (=bucket) PR/Guardian stores '<tenant>-<bucket_name>', DP uses '<bucket_name>'
   * @param path (=path) should be the same modulo the bucket name
   * @param datalake (=provider) Guardian stores 'adls'/'s3', DP uses 'azure'/'aws'
   * @param storageAccount (=tenant) PR/Guardian store 'https://<account_name>.dfs.core.windows.net' whereas DP uses
   *   '<account_name>'
   */
  getFormattedObjectPathInfos(
    bucket: string,
    path: string,
    datalake: string,
    storageAccount: string,
  ): Observable<IFormattedObjectPathWithMetadataInfos> {
    return this._apiService.get<IFormattedObjectPathWithMetadataInfos>(
      `v4/datalake/path/infos?${DatalakeApiService._buildQueryParameters({
        bucket,
        path,
        datalake,
        storageAccount,
      })}`,
    );
  }

  getBucketFromPath(path: string): string {
    return path.split('/')[0];
  }

  getRelatedSources(
    datalakePath: DatalakePath,
    errorHandling?: ErrorHandlingOptions,
  ): Observable<Array<{ sourceId: string; sourceName: string }>> {
    const bucket = this.getBucketFromPath(datalakePath.path);
    const options = DatalakeApiService._pathToOptions(datalakePath);

    return this._apiService.get(
      `/v4/datalake/${encodeURIComponent(bucket)}/sources?${DatalakeApiService._buildQueryParameters(options)}`,
      errorHandling ? { errorHandling } : {},
    );
  }

  fetchMetadata(datalakePath: DatalakePath, errorHandling?: ErrorHandlingOptions): Observable<IDatalakeMetadata> {
    const bucket = this.getBucketFromPath(datalakePath.path);
    const options = DatalakeApiService._pathToOptions(datalakePath);

    return this._apiService.get(
      `/v4/datalake/${encodeURIComponent(bucket)}/metadata?${DatalakeApiService._buildQueryParameters(options)}`,
      errorHandling ? { errorHandling } : {},
    );
  }

  fetchObjectExistenceUrl(datalakePath: DatalakePath): string {
    const bucket = this.getBucketFromPath(datalakePath.path);
    const options = DatalakeApiService._pathToOptions(datalakePath);

    return `/v4/datalake/${encodeURIComponent(bucket)}/existence?${DatalakeApiService._buildQueryParameters(options)}`;
  }

  fetchObjectExistence(datalakePath, errorHandling?: ErrorHandlingOptions): Observable<IObjectExistenceResult> {
    return this._apiService.get(this.fetchObjectExistenceUrl(datalakePath), errorHandling ? { errorHandling } : {});
  }

  /**
   * Rename an object
   * @param bucketName
   * @param options
   */
  setOwnerMetadata(bucketName: string, options: IDatalakeAPIOptions): Observable<void> {
    return this._apiService.put<void>(
      `/v4/datalake/${encodeURIComponent(bucketName)}/metadata?${DatalakeApiService._buildQueryParameters(options)}`,
      {},
      { errorHandling: { 400: { level: 'silent' } } },
    );
  }
}
