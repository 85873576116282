<adl-button *ngIf="hasAPendingRequest; else requestButton" class="access-requested" type="data-catalog">
  <div class="flex items-center h-full">
    <span class="btn-text greyed mr-4">Access Requested</span>
    <div class="circular-icon flex justify-center items-center" (click)="modifyAccessRequest()">
      <img alt="circular information" src="/assets/modules/data-catalog/imgs/circular-information-icon.svg" />
    </div>
  </div>
</adl-button>

<ng-template #requestButton>
  <adl-button type="data-catalog" class="request-access" (click)="requestAccess()">
    <span class="btn-text">Request Access</span>
  </adl-button>
</ng-template>
