import { Inject, Injectable } from '@angular/core';
import type { CanActivate, CanLoad } from '@angular/router';
import { Router } from '@angular/router';
import type { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { PermissionsModule } from '../permissions.module';

import { PermissionsService } from '../services/permissions.service';

import { IPermissionsOptions, PERMISSIONS_OPTIONS } from '../permissions-options';
import type { Permissions } from '../services/permissions.service';

// Guard
@Injectable({
  providedIn: PermissionsModule,
})
export class BackOfficeGuard implements CanActivate, CanLoad {
  // Constructor
  constructor(
    private readonly _permissionsService: PermissionsService,
    protected readonly _router: Router,
    @Inject(PERMISSIONS_OPTIONS) protected readonly _options: IPermissionsOptions,
  ) {}

  protected _hasBackOfficeAccess(): Observable<boolean> {
    return this._permissionsService.permissionsAdmin$.pipe(
      map((permissions: Permissions) => {
        return permissions && (permissions.groups || permissions.portals || permissions.sources || permissions.leons);
      }),
    );
  }

  private get _redirectIfNoAccess(): Observable<boolean> {
    return this._hasBackOfficeAccess().pipe(
      tap((hasAccess) => {
        if (!hasAccess) {
          void this._router.navigate(this._options.errorRouterLink);
        }
      }),
    );
  }

  // Methods
  canActivate(): Observable<boolean> {
    return this._redirectIfNoAccess;
  }

  canLoad(): Observable<boolean> {
    return this._redirectIfNoAccess;
  }
}
