import type { HttpEvent } from '@angular/common/http';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { throwError } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { ApiService } from '@dataportal/front-api';
import type { IAzureMetadata } from '@dataportal/front-shared';
import { AlertService, Logger } from '@dataportal/front-shared';

import { DatalakeStoreService } from './datalake-store.service';

import type { IDatalakeAPIOptions } from '../entities/datalake';
import type { IDatalakeAPIUploadResponse } from './datalake-api.service';

export interface IDatalakeUploadResponse {
  event: HttpEvent<string>;
  metadata: IAzureMetadata;
}

@Injectable()
export class ExplorerDownloadUploadUrlService {
  constructor(
    private readonly _apiService: ApiService,
    private readonly _alertService: AlertService,
    private readonly _datalakeStoreService: DatalakeStoreService,
    private readonly _http: HttpClient,
    private readonly _logger: Logger,
  ) {}

  buildQueryParameters(options: IDatalakeAPIOptions): string {
    return Object.keys(options)
      .filter((key) => options[key] != null)
      .map((key) => `${key}=${encodeURIComponent(options[key])}`)
      .join('&');
  }

  downloadAction(downloadUrl: string, fileName?): void {
    const link = document.createElement('a');
    link.download = fileName ? fileName : name;
    link.href = downloadUrl;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  singleDownloadUrl(bucket: string, options: IDatalakeAPIOptions): Observable<string> {
    return this._apiService.get(
      `/v4/datalake/${encodeURIComponent(bucket)}/download?${this.buildQueryParameters(options)}`,
    );
  }

  multipleDownloadsUrl(bucket: string, options: IDatalakeAPIOptions[]): Observable<{ requestId: string }> {
    const resourcesParams = encodeURIComponent(JSON.stringify(options));

    return this._apiService.get<{ requestId: string }>(
      `/v4/datalake/${encodeURIComponent(bucket)}/download-zip?resourcesParams=${resourcesParams}`,
    );
  }

  abortDownloadZip(id: string) {
    return this._apiService.delete<{ zipId: string; status: string }>(`/v4/datalake/zip/${encodeURIComponent(id)}`);
  }

  downloadZip(id: string) {
    return this._apiService.get<{ downloaded: boolean; signedUrl: string; failedPath: string[] }>(
      `v4/datalake/zip/${encodeURIComponent(id)}`,
    );
  }

  uploadUrl(
    currentBucketName: string,
    currentProvider: string,
    options: IDatalakeAPIOptions,
    file: File | Blob,
    name: string,
    metadata?: Record<string, unknown>,
  ): Observable<IDatalakeUploadResponse> {
    const fileNameWithPath = (file as File)?.name;

    let fileToUse = file;
    let fileName = name;

    const splitFilePath = fileNameWithPath.split('/');

    if (splitFilePath.length > 1) {
      fileName = splitFilePath.pop();
      const fileAdditionalPath = splitFilePath.join('/');

      if (fileName && fileAdditionalPath) {
        options.path = options.path.concat(`/${fileAdditionalPath}`);
        fileToUse = new File([file], fileName, { type: file.type });
      }
    }

    return this._datalakeStoreService.generateUploadUrl(currentBucketName, options, fileName, metadata).pipe(
      mergeMap((datalakeAPIUploadResponse: IDatalakeAPIUploadResponse) => {
        const signedUrl = datalakeAPIUploadResponse?.url;
        const currentMetadata = datalakeAPIUploadResponse?.currentMetadata;

        // use signedUrl
        this._logger.debug('[ExplorerDownloadUploadUrlService] Generated upload signed URI', signedUrl);
        const headers = new HttpHeaders().set('Content-Type', fileToUse.type);
        const req = new HttpRequest('PUT', signedUrl, fileToUse, {
          headers: options.provider === 'azure' ? headers.set('x-ms-blob-type', 'BlockBlob') : headers,
          reportProgress: true,
          responseType: 'text' as const,
        });

        return this._http.request<string>(req).pipe(
          map((response) => ({
            event: response,
            metadata: currentMetadata,
          })),
          catchError((error: unknown) => {
            // TODO: [Revisit] (18.01.2024) - fix types
            this._alertService.error(error as string);

            return throwError(error);
          }),
        );
      }),
      catchError((err: unknown) => {
        this._logger.error('[DatalakeService] Error generating signed url', err);

        return throwError(err);
      }),
    );
  }
}
