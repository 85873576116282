<adl-modal  data-cy="confirmModal">
  <div class="modal-header" modal-header>
    <adl-title class="modal-title">Confirm</adl-title>
  </div>
  <div class="column-flex a-center" modal-body>
    <adl-text class="panel-modal--title" [size]="20" color="blue">
      You are about to {{ action }} the following {{ elements?.length > 1 ? 'elements' : 'element' }} ?
    </adl-text>
    <div class="panel-modal--content confirm-modal-content">
      <ul>
        <li *ngFor="let element of elements" [attr.data-cy]="element">
          {{ element }}
        </li>
      </ul>
    </div>
    <adl-text *ngIf="warningText" class="mt10" type="error">
      {{ warningText }}
    </adl-text>
    <div class="panel-modal--content confirm-modal-content">
      <ul>
        <li *ngFor="let warning of warnings">
          <adl-text [bold]="false" type="error">{{ warning }}</adl-text>
        </li>
      </ul>
    </div>
  </div>
  <div class="row-flex j-center a-center mt10" modal-footer>
    <adl-button data-cy="confirmButtonConfirmModal" class="ml20" (clicked)="confirm()">Confirm</adl-button>
    <adl-button data-cy="cancelButtonConfirmModal" type="cancel" class="ml20" (clicked)="dismiss()">Abort</adl-button>
  </div>
</adl-modal>
