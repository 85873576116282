import { InjectionToken } from '@angular/core';

// Constants
export const NOTIFICATIONS_OPTIONS = new InjectionToken<INotificationsOptions>(
  'dataportal-front:notifications:options',
);

// Types
export interface INotificationsOptions {
  // Options
  todo: string;
}
