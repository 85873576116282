import type { ICheckRun } from '@dataportal/front-api';
import type { GuardianCheckStatus } from '@dataportal/accor-types';

export interface IGuardianError {
  /**
   * Guardian API error column name
   */
  column: string;
  /**
   * Guardian API error code
   */
  label: string;
  /**
   * Guardian API number of incorrect values
   */
  count: number;
  /**
   * Guardian API incorrect values list
   */
  incorrectValues: (number | string)[];
  /**
   * Guardian API incorrect lines list
   */
  incorrectLines: number[];
}

export class GuardianCheck {
  private readonly _check: ICheckRun;
  private readonly _checkErrors: IGuardianError[];

  constructor(check: ICheckRun) {
    this._check = check;
    this._checkErrors = [];
    const checkErrorsFetched = this._check?.data_quality?.columns_errors;
    const columns = checkErrorsFetched && Object.keys(checkErrorsFetched).length ? Object.keys(checkErrorsFetched) : [];

    for (const column of columns) {
      const errorColumn = checkErrorsFetched[column];
      const errorColumnTypes = Object.keys(checkErrorsFetched[column]);

      for (const errorColumnType of errorColumnTypes) {
        const errorField = errorColumn[errorColumnType].error;
        const mismatchHintsField = errorColumn[errorColumnType].hint;
        const mismatchIndexField = errorColumn[errorColumnType].hint_index || null;
        this._checkErrors.push({
          column,
          label: errorColumnType,
          count: errorField ? (errorField === true ? 1 : (errorField as number)) : 0,
          incorrectValues: mismatchHintsField?.length
            ? Array.isArray(mismatchHintsField)
              ? (mismatchHintsField as (number | string)[])
              : [mismatchHintsField as string]
            : [],
          incorrectLines:
            mismatchIndexField && Array.isArray(mismatchHintsField) && mismatchHintsField.length
              ? mismatchIndexField
              : [],
        });
      }
    }
  }

  get status(): GuardianCheckStatus {
    return this._check.status;
  }

  get createdAt(): Date {
    return new Date(
      this._check.check_time_utc?.length
        ? !this._check.check_time_utc.includes('Z')
          ? `${this._check.check_time_utc}Z`
          : this._check.check_time_utc
        : null,
    );
  }

  get id(): number {
    return this._check.id_check;
  }

  get fileName(): string {
    return this._check.name;
  }

  get errors(): IGuardianError[] {
    return this._checkErrors;
  }
}
