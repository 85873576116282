import { Component, EventEmitter, Input, Output } from '@angular/core';
import type { Observable } from 'rxjs';
import { SidebarV3Service } from '@dataportal/adl';
import { EnvironmentService } from '@dataportal/front-environment';

import type { NavbarItemV2 } from './navbar-item/navbar-item-v2.entity';

@Component({
  selector: 'dpg-navbar-v2',
  templateUrl: './navbar-v2.component.html',
  styleUrls: ['./navbar-v2.component.scss'],
})
export class NavbarV2Component {
  @Input() external = false;
  @Input() items: NavbarItemV2[];
  @Input() favorites: NavbarItemV2[];
  @Input() canSeeAdmin: boolean;
  @Input() canSeeLogout = false;
  @Output() messageEvent = new EventEmitter<boolean>();

  isDeveloped$: Observable<boolean>;
  isVisible$: Observable<boolean>;

  constructor(public environmentService: EnvironmentService, private readonly _sidebarService: SidebarV3Service) {
    this.isDeveloped$ = this._sidebarService.isDeveloped$;
    this.isVisible$ = this._sidebarService.isVisible$;
  }

  open(): void {
    this.messageEvent.emit();
  }

  hideSidebar() {
    this._sidebarService.hideSidebar();
  }

  showSidebar() {
    this._sidebarService.showSidebar();
  }
}
