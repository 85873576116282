<adl-search-bar
  #searchBar
  class="w-full main-search-catalog"
  searchBarType="advanced"
  [placeholder]="placeholder"
  [control]="searchControl"
  [dropdown]="dropdown"
>
</adl-search-bar>
<adl-dropdown
  #dropdown
  [clickableContent]="true"
  [width]="searchBarWidth"
  height="fit-content"
  contentGap="0px"
  padding="0"
>
  <ng-container *ngIf="showDropdown">
    <div class="recent-search-container p-8" *ngIf="!searchControl.value">
      <span class="dc-recent-search-title">RECENT SEARCH</span>
      <div class="flex flex-wrap">
        <div *ngFor="let source of lastSourcesClicked" class="mr-4 mt-6">
          <dpg-catalog-source-label
            (click)="closeDropdown()"
            class="dc-recent-search-entry"
            [source]="source"
            searchMode="advanced"
          ></dpg-catalog-source-label>
        </div>
      </div>
    </div>

    <div *ngIf="searchControl?.value && showResultsInDropdown" class="p-4">
      <div *ngIf="suggestedSources?.length > 0" class="dropdown-results">
        <div class="dc-dropdown-search-result flex flex-row items-center pl-2" *ngFor="let source of suggestedSources">
          <dpg-catalog-source-label
            (click)="closeDropdown()"
            class="w-full"
            [source]="source"
            [searchMode]="searchMode"
          ></dpg-catalog-source-label>
        </div>
        <span
          *ngIf="totalSourcesNumber - suggestedSources.length > 0"
          (click)="navigateToSearch()"
          class="cursor-pointer dc-more-results-text"
        >
          And {{ totalSourcesNumber - suggestedSources.length }} more results
        </span>
      </div>

      <adl-loaded-item [loading]="isLoadingSources" [iconSize]="70">
        <div *ngIf="suggestedSources?.length === 0" class="dc-dropdown-search-result">
          <span class="no-results">No results</span>
        </div>
      </adl-loaded-item>
    </div>
  </ng-container>
</adl-dropdown>
