import { Directive, HostListener } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[dpgStopClickBubbling]',
})
export class StopClickBubblingDirective {
  // Methods
  @HostListener('click', ['$event'])
  clickEvent(event: Event): void {
    event.stopPropagation();
  }
}
