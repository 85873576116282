import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { Logger } from '@dataportal/front-shared';

interface IUpdateTokensRequest {
  accessToken?: string;
  idToken?: string;
  devOpsToken?: string;
  databricksToken?: string;
}

@Injectable({
  providedIn: 'root',
})
/**
 * This service is responsible to host latest auth token values.
 */
export class AuthTokenService {
  constructor(private readonly _logger: Logger) {}

  /**
   * @property {Subject<string>} _accessToken$
   * Subject used to store latest access token
   */
  private readonly _accessToken$ = new ReplaySubject<IUpdateTokensRequest['accessToken']>(1);

  /**
   * @property {Observable<string>} accessToken$
   * Emits on each accessToken newly retrieved by an authentication service
   */
  readonly accessToken$ = this._accessToken$.asObservable();

  /**
   * @property {Subject<string>} _idToken$
   * Subject used to store latest id token
   */
  private readonly _idToken$ = new ReplaySubject<IUpdateTokensRequest['idToken']>(1);

  /**
   * @property {Observable<string>} idToken$
   * Emits on each idToken newly retrieved by an authentication service
   */
  readonly idToken$ = this._idToken$.asObservable();

  /**
   * @property {Subject<string>} _devOpsToken$
   * Subject used to store latest devOps token
   */
  private readonly _devOpsToken$ = new ReplaySubject<IUpdateTokensRequest['devOpsToken']>(1);

  /**
   * @property {Observable<string>} devOpsToken$
   * Emits on each devOps token newly retrieved by an authentication service
   */
  readonly devOpsToken$ = this._devOpsToken$.asObservable();

  /**
   * @property {Subject<string>} _databricksToken$
   * Subject used to store latest databricks token
   */
  private readonly _databricksToken$ = new ReplaySubject<IUpdateTokensRequest['databricksToken']>(1);

  /**
   * @property {Observable<string>} databricksToken$
   * Emits on each databricks token newly retrieved by an authentication service
   */
  readonly databricksToken$ = this._databricksToken$.asObservable();

  // Update token: if one property is undefined, it won't be streamed as a new value
  updateTokens({ accessToken, idToken, devOpsToken, databricksToken }: IUpdateTokensRequest) {
    if (accessToken !== undefined) {
      this._logger.info('[TOKENSERVICE] Setting accessToken', accessToken);
      this._accessToken$.next(accessToken);
    }

    if (idToken !== undefined) {
      this._logger.info('[TOKENSERVICE] Setting idToken', idToken);
      this._idToken$.next(idToken);
    }

    if (devOpsToken !== undefined) {
      this._logger.info('[TOKENSERVICE] Setting devOpsToken', devOpsToken);
      this._devOpsToken$.next(devOpsToken);
    }

    if (databricksToken !== undefined) {
      this._logger.info('[TOKENSERVICE] Setting databricksToken', databricksToken);
      this._databricksToken$.next(databricksToken);
    }
  }
}
