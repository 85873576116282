import type { OnDestroy, OnInit } from '@angular/core';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { FormArray, FormControl } from '@ngneat/reactive-forms';

import { TextType } from '../../atoms/text/text.component';

interface ICheckboxValue<T> {
  label: string;
  value: T;
}

@Component({
  selector: 'adl-checkboxes',
  templateUrl: './checkboxes.component.html',
  styleUrls: ['./checkboxes.component.scss'],
})
export class CheckboxesComponent<T = unknown> implements OnInit, OnDestroy {
  @Input() checkboxes: ICheckboxValue<T>[] = [];
  @Input() control: FormArray;
  @Input() otherChoice: ICheckboxValue<string> = null;
  @Input() otherChoiceControl: FormControl = new FormControl('');
  // UI
  @Input() isColumnFlex = false;
  @Input() heightForColumnFlex;
  @Input() marginTop;
  @Input() checkBoxesPaddingTop = 10; // in px
  @Input() checkBoxesPaddingBottom = 10; // in px
  @Input() labelType: TextType;
  @Input() isLabelBold = null;
  @Input() hasSeparator = false;
  @Input() checkboxSize: 'small' | 'normal' = 'normal';
  @Input() checkboxColor: 'white' | 'blue' = 'white';
  @Input() labelSize = 14;
  @Input() choiceLimit = 0;
  @Input() defaultValue: T[] = [];
  @Output() changed = new EventEmitter<T[]>();
  @Output() otherChoiceSelectionChanged = new EventEmitter<boolean>();

  hasSelectedOther = false;

  private readonly _destroyed$ = new Subject();

  ngOnInit(): void {
    if (this.otherChoice) {
      this.hasSelectedOther = this.otherChoiceControl.value?.length;
    }

    if (this.defaultValue?.length) {
      if (!this.control.value?.length) {
        this.defaultValue.forEach((value) => {
          this.control.push(new FormControl(value));
        });
      }
    }
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
  }

  static _findControl(array: FormArray, value: any): number {
    for (let i = 0; i < array.length; i++) {
      if (array.at(i).value === value) {
        return i;
      }
    }

    return null;
  }

  onChange(value: T, $event: boolean): void {
    this.control.markAsDirty();

    if ($event) {
      if (this._findControl(null) !== null) {
        this.control.removeAt(this._findControl(null));
      }

      this.control.push(new FormControl(value));

      if (this.choiceLimit && this.control.length > this.choiceLimit) {
        this.control.removeAt(0);
      }
    } else {
      // TODO: Replace with removeIf()
      this.control.removeAt(this._findControl(value));
    }

    this.changed.emit(this._findAllChecked());
  }

  onChangeSelectedOther($event: boolean): void {
    this.control.markAsDirty();
    this.hasSelectedOther = $event;
    this.otherChoiceSelectionChanged.emit($event);
  }

  private _findAllChecked(): T[] {
    return this.control.getRawValue();
  }

  isDisabled(value: T): boolean {
    const control = this.control.at(this._findControl(value));

    return control ? control.disabled : null;
  }

  isChecked(value: T): boolean {
    return this._findControl(value) != null;
  }

  private _findControl(value: T): number {
    return CheckboxesComponent._findControl(this.control, value);
  }
}
