<div [ngClass]="{ 'has-not-see-background': !content.isSeen }" class="has-see">
  <div
    class="notification-content flex cursor-pointer pl-[17px] pt-[10px] pb-[10px] pr-[5px]"
    (click)="clickNotification()"
  >
    <img class="notifier-profile ml-3 mr-6" [src]="content.picture" alt="Profile picture" />
    <div class="flex flex-col text-left">
      <div class="flex flex-row">
        <div class="notification-context">
          {{ notificationText }}
          <span *ngIf="notificationSubText.length > 0" class="italic text-gray-400"> "{{ notificationSubText }}"</span>
        </div>
      </div>
      <div class="flex flex-row mt-[2px]">
        <adl-text *ngIf="!!content.updatingDate; else creationDate" type="hint" size="12">{{
          getTimeInterval(content.updatingDate)
        }}</adl-text>
        <ng-template #creationDate>
          <adl-text type="hint" size="12">{{ getTimeInterval(content.creationDate) }}</adl-text>
        </ng-template>
        <adl-text
          *ngIf="
            content.type === 'accessRequest' ||
            content.type === 'publication' ||
            content.type === 'glossary' ||
            content.type === 'dataAcademyFRUpdated'
          "
          type="hint"
          class="ml-[20px]"
          size="12"
        >
          Status:
          <adl-text [color]="this.statusColor" size="12">{{ content.status.toUpperCase() }}</adl-text>
        </adl-text>
      </div>
    </div>
    <div *ngIf="!content.isSeen" class="notification-circle flex self-center ml-[27px]"></div>
  </div>
  <div class="notification-content-separator ml-[10px]"></div>
</div>
