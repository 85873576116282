import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ApiService } from '@dataportal/front-api';
import { Logger } from '@dataportal/front-shared';
import type { IBanner, IBannerConfiguration } from '@dataportal/types';
import { UserPreferencesService } from '@dataportal/users';
import { EntityBuilder } from '@decahedron/entity';

import { AlertBanner } from '../entities/alertBanner';

const CLOSED_KEY = 'DP_BANNER_CLOSED';

@Injectable({
  providedIn: 'root',
})
export class GlobalBannerService {
  private readonly _show$ = new BehaviorSubject<boolean>(false);
  private readonly _alert$ = new BehaviorSubject<AlertBanner>(null);

  alert$ = this._alert$.asObservable();
  show$ = this._show$.asObservable();

  constructor(
    private readonly _apiService: ApiService,
    private readonly _logger: Logger,
    private readonly _userPreferences: UserPreferencesService,
  ) {}

  getAlertBanner(): Observable<AlertBanner> {
    return this._apiService.get<IBanner>('/v4/configurations/last').pipe(
      map((json) => (json ? EntityBuilder.buildOne(AlertBanner, json) : null)),
      tap(this._refreshSubjects.bind(this)),
    );
  }

  updateAlertBanner(alert: IBannerConfiguration): Observable<AlertBanner> {
    return this._apiService.post<IBanner>('/v4/configurations', alert).pipe(
      map((json) => EntityBuilder.buildOne(AlertBanner, json)),
      tap(this._refreshSubjects.bind(this)),
    );
  }

  close(): void {
    this._userPreferences.setUserPreference(CLOSED_KEY, this._alert$.getValue().id);
    this._show$.next(false);
  }

  private _refreshSubjects(alert: AlertBanner) {
    this._logger.info('[AlertBannerComponent] Alert to display updated', alert);
    this._alert$.next(alert);

    if (alert) {
      const isClosed = this._isClosed();
      const isInRange = this._isInRange();
      this._logger.debug('[AlertBannerComponent] Should display', { isClosed, isInRange });
      this._show$.next(isInRange && !isClosed);
    } else {
      this._show$.next(false);
    }
  }

  private _isInRange(): boolean {
    const alert = this._alert$.getValue();
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    this._logger.debug('[AlertsService] Today', today.toISOString());
    this._logger.debug('[AlertsService] Start date', new Date(alert.startDate).toISOString());
    this._logger.debug('[AlertsService] End date', new Date(alert.endDate).toISOString());
    this._logger.debug('[AlertsService] Today after start', new Date(alert.startDate) <= today);
    this._logger.debug('[AlertsService] Today before end', today < new Date(alert.endDate));

    return new Date(alert.startDate) <= today && today < new Date(alert.endDate);
  }

  private _isClosed(): boolean {
    return this._userPreferences.getUserPreferenceValue(CLOSED_KEY) === this._alert$.getValue().id;
  }
}
