import type { OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import { Logger } from '@dataportal/front-shared';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { CurrentDashboardService } from '../../services/current-dashboard.service';

import { Dashboard } from '../../entities/dashboard.model';

@UntilDestroy()
@Component({
  selector: 'dpg-dashboard-maintenance',
  templateUrl: './dashboard-maintenance.component.html',
  styleUrls: ['./dashboard-maintenance.component.scss'],
})
export class DashboardMaintenanceComponent implements OnInit {
  @Input() dashboard: Dashboard;

  message: string;

  private _isOwner = false;

  constructor(private readonly _currentDashboardService: CurrentDashboardService, private readonly _logger: Logger) {}

  ngOnInit(): void {
    this._currentDashboardService.dashboard$.pipe(untilDestroyed(this)).subscribe((dashboard) => {
      this.message = dashboard?.maintenanceMessage;
      this._logger.debug('[DashboardMaintenanceComponent] Maintenance', this.message);
    });
    this._currentDashboardService.permissions$.pipe(untilDestroyed(this)).subscribe((permissions) => {
      this._isOwner = permissions.isOwner;
      this._logger.debug('[DashboardMaintenanceComponent] Is current user owner', this._isOwner);
    });
  }

  get isOnMaintenance(): boolean {
    return this.message && !this._isOwner;
  }
}
