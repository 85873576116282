import type { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { forkJoin } from 'rxjs';
import { DialogsService, ModalSizes } from '@dataportal/adl';
import { Logger } from '@dataportal/front-shared';
import { PermissionsService } from '@dataportal/permissions';
import { ImpersonateService } from '@dataportal/users';

import { ImpersonateModalComponent } from '../impersonate-modal/impersonate-modal.component';

@Component({
  selector: 'dpg-impersonate-shortcut',
  templateUrl: './impersonate-shortcut.component.html',
  styleUrls: ['./impersonate-shortcut.component.scss'],
})
export class ImpersonateShortcutComponent implements OnInit {
  isImpersonating = true;
  canImpersonate = false;
  isPortalOwner = false;

  constructor(
    private readonly _dialogService: DialogsService,
    private readonly _impersonateService: ImpersonateService,
    private readonly _permissionsService: PermissionsService,
    private readonly _logger: Logger,
  ) {}

  ngOnInit(): void {
    this.isImpersonating = this._impersonateService.isImpersonating();
    const canImpersonate$ = this._permissionsService.isAuthorized('impersonate', 'users');
    const isPortalOwner$ = this._permissionsService.isAuthorized('update', 'portals');
    forkJoin(canImpersonate$, isPortalOwner$).subscribe(([canImpersonate, isPortalOwner]) => {
      this.canImpersonate = canImpersonate;
      this.isPortalOwner = isPortalOwner;
      this._logger.debug('[ImpersonateShortcutComponent] Can see impersonate shortcut', {
        canImpersonate,
        isPortalOwner,
        isImpersonating: this.isImpersonating,
        visible: canImpersonate && isPortalOwner && !this.isImpersonating,
      });
    });
  }

  openImpersonateModal(): void {
    this._dialogService.open(ImpersonateModalComponent, {}, { ...ModalSizes.M, panelClass: 'dialog-overflow-visible' });
  }
}
