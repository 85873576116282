import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { BehaviorSubject, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { ApiPaginatedService, ApiService } from '@dataportal/front-api';
import { Logger } from '@dataportal/front-shared';
import { EntityBuilder } from '@decahedron/entity';
import type { FormGroup } from '@ngneat/reactive-forms';
import { FormBuilder } from '@ngneat/reactive-forms';

import { AccessRequestV2 } from '../entities/access-request-v2';
import type { IARResourceRolesMap, ISupportedARResource } from '../entities/utils';

export interface IFilterForm {
  status: string[];
  type: string;
}

@Injectable()
export class AccessRequestsV2Service extends ApiPaginatedService<AccessRequestV2> {
  private readonly _currentUserAccessRequests$ = new BehaviorSubject<AccessRequestV2[]>([]);
  currentUserAccessRequests$ = this._currentUserAccessRequests$.asObservable();

  private readonly _createdAccessRequests$ = new BehaviorSubject<AccessRequestV2[]>([]);
  createdAccessRequests$ = this._createdAccessRequests$.asObservable();

  private readonly _currentOwnerAccessRequests$ = new BehaviorSubject<AccessRequestV2[]>([]);
  currentOwnerAccessRequests$ = this._currentOwnerAccessRequests$.asObservable();

  constructor(
    protected readonly apiService: ApiService,
    protected logger: Logger,
    private readonly _formBuilder: FormBuilder,
  ) {
    super(apiService, logger);
  }

  protected url = '/v4/access-requests-v2';

  private _globalForm: FormGroup<IFilterForm>;
  private readonly _formReady$ = new BehaviorSubject<boolean>(false);

  formReady$ = this._formReady$.asObservable();

  get form(): FormGroup<IFilterForm> {
    return this._globalForm;
  }

  private _initFilterForm(filterForm?: IFilterForm): FormGroup<IFilterForm> {
    return this._formBuilder.group({
      status: this._formBuilder.array(filterForm ? filterForm.status : ['all', 'pending', 'approved', 'denied'] || []),
      type: [filterForm ? filterForm.type : 'all'],
    });
  }

  init(itemForm?: IFilterForm): void {
    this._globalForm = this._initFilterForm(itemForm);
    this._formReady$.next(true);
  }

  protected buildOne<R extends ISupportedARResource = ISupportedARResource>(json: unknown): AccessRequestV2<R> {
    return EntityBuilder.buildOne(AccessRequestV2, json);
  }

  protected buildMany<R extends ISupportedARResource = ISupportedARResource>(json: unknown[]): AccessRequestV2<R>[] {
    return EntityBuilder.buildMany(AccessRequestV2, json);
  }

  countByUser(userId: string): Observable<number> {
    return this.apiService.get(`${this.url}/users/${userId}/count`);
  }

  countByResource(resource: ISupportedARResource, resourceId: string): Observable<number> {
    return this.apiService.get(`${this.url}/${resource}/${resourceId}/count`);
  }

  listByUser(userId: string): Observable<AccessRequestV2[]> {
    return this.apiService
      .get(`${this.url}/users/${userId}`)
      .pipe(
        map((response: any[]) => EntityBuilder.buildMany<AccessRequestV2>(AccessRequestV2, response)),
        catchError(() => []),
      )
      .pipe(tap((ar) => this._currentUserAccessRequests$.next(ar)));
  }

  listByResource<R extends ISupportedARResource>(resource: R, resourceId: string): Observable<AccessRequestV2<R>[]> {
    return this.apiService.get(`${this.url}/${resource}/${resourceId}`).pipe(
      map((response: any[]) => EntityBuilder.buildMany<AccessRequestV2<R>>(AccessRequestV2, response)),
      catchError(() => []),
    );
  }

  listByOwner<R extends ISupportedARResource>(): Observable<AccessRequestV2[]> {
    return this.apiService
      .get(`${this.url}/owner`)
      .pipe(
        map((response: any[]) => EntityBuilder.buildMany<AccessRequestV2>(AccessRequestV2, response)),
        catchError(() => of([])),
      )
      .pipe(tap((ar) => this._currentOwnerAccessRequests$.next(ar)));
  }

  hasSeen(userId: string, resource: ISupportedARResource, resourceId: string): Observable<void> {
    return this.apiService.put(
      `${this.url}/has-seen`,
      JSON.stringify({
        userId,
        resource,
        resourceId,
      }),
    );
  }

  create<R extends ISupportedARResource>(
    resource: R,
    resourceId: string,
    requestedRole: IARResourceRolesMap[R],
  ): Observable<AccessRequestV2<R>> {
    return this.apiService.post(this.url, JSON.stringify({ resource, resourceId, requestedRole })).pipe(
      map((res) => {
        const createdAR = this.buildOne<R>(res);
        const alreadyCreatedAccessRequests = this._createdAccessRequests$.value;
        alreadyCreatedAccessRequests.push(createdAR);
        this._createdAccessRequests$.next(alreadyCreatedAccessRequests);

        return createdAR;
      }),
    );
  }

  approve<R extends ISupportedARResource>(
    userId: string,
    resource: R,
    resourceId: string,
    approvedRole?: IARResourceRolesMap[R],
  ): Observable<void> {
    return this.apiService.put(
      this.url,
      JSON.stringify({
        userId,
        resource,
        resourceId,
        approvedRole,
      }),
    );
  }

  deny(userId: string, resource: ISupportedARResource, resourceId: string): Observable<void> {
    return this.apiService.patch(
      this.url,
      JSON.stringify({
        userId,
        resource,
        resourceId,
      }),
    );
  }

  cancel(resource: ISupportedARResource, resourceId: string): Observable<void> {
    return this.apiService.put(
      `${this.url}/cancel`,
      JSON.stringify({
        resource,
        resourceId,
      }),
    );
  }
}
