<div
  class="card"
  (click)="clicked.emit($event)"
  [ngClass]="{
    'active': active,
    'centered': centered,
    'grey-card': greyCard,
    'px-8': autoWidth,
    'p-8': paddingType === 'medium',
    'p-6': paddingType === 'small',
    'justify-start': justifyType === 'start',
    'justify-between': justifyType === 'between',
    'justify-center': justifyType === 'center',
  }"
  [ngStyle]="{
    'border-top': borderTopColor ? '4px solid ' + borderTopColor : ''
  }"
>
  <ng-content></ng-content>
</div>
