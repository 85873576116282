import { Component, Input, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import type { IRoleData } from '@dataportal/permissions';
import { getRoleData } from '@dataportal/permissions';
import type { SourceRole } from '@dataportal/types';

@Component({
  selector: 'dpg-catalog-source-roles-select',
  templateUrl: './source-roles-select.component.html',
  styleUrls: ['./source-roles-select.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SourceRolesSelectComponent {
  rolesList: SourceRole[] = ['dashboardReader', 'dataReader', 'dataDev', 'sourceOwner'];
  rolesSelectList: IRoleData[];

  @Input() roleControl = new FormControl(this.rolesList[0]);

  constructor() {
    this.rolesSelectList = getRoleData(...this.rolesList);
  }
}
