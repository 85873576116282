import type { OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'adl-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginatorComponent implements OnInit, OnChanges {
  private readonly _pageIndexFontSize = 12;
  private readonly _pageStepIndexFontSize = 1;
  private readonly _minNbPageButonsToDisplay = 4;
  private readonly _unknownTotalPageNumber = 1000;

  readonly first_page_index = 1;

  @Input() currentPageIndex = this.first_page_index;
  @Input() nbPages = 0;
  @Input() isTotalPagesUnknown = false;
  /**
   * Number of maximum page buttons to display before displaying "..."
   * Minimum number is 4 and must be an even number (4, 6, 8 etc.)
   * Default number is 6
   */
  @Input() nbMaxPageButtonsToDisplay = 6;
  @Input() textColor = 'pagination-text';

  @Output() clickedOnPrevious = new EventEmitter<number>();
  @Output() clickedOnNext = new EventEmitter<number>();
  @Output() clickedOnPageButton = new EventEmitter<number>();

  pages: number[] = [];
  pagesToDisplayBetweenEtc: number[] = [];

  ngOnInit() {
    if (this.isTotalPagesUnknown) {
      this.nbPages = this._unknownTotalPageNumber;
    }

    this.pages = this._getPagesArrayFromNbPages(this.nbPages);
    this._refreshPagesArrayToDisplayBetweenEtc();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.nbPages?.previousValue !== changes.nbPages?.currentValue && changes.nbPages.currentValue > 1) {
      this.pages = this._getPagesArrayFromNbPages(changes.nbPages.currentValue as number);
      this._refreshPagesArrayToDisplayBetweenEtc();
    }

    if (changes.currentPageIndex?.currentValue) {
      this._refreshPagesArrayToDisplayBetweenEtc();
    }
  }
  private _getPagesArrayFromNbPages(nbPages: number): number[] {
    return Array.from({ length: nbPages }, (_, index) => index + 1);
  }

  private _refreshPagesArrayToDisplayBetweenEtc(): void {
    let newPagesToDisplayBetweenEtc = [];
    const secondPageIndex = this.first_page_index + 1;

    if (this.hasToDisplayEtcLeft && this.hasToDisplayEtcRight) {
      newPagesToDisplayBetweenEtc.push(this.currentPageIndex);

      for (let index = 1; index <= this.defaultNbPageButtonsToDisplayBetweenEtcOnOneSideAroundCurrent; ++index) {
        newPagesToDisplayBetweenEtc.unshift(this.currentPageIndex - index);
        newPagesToDisplayBetweenEtc.push(this.currentPageIndex + index);
      }
    } else if (this.hasToDisplayEtcLeft) {
      for (let index = 1; index <= this.defaultNbPageButtonsToDisplayBetweenEtcIfOneEtc; ++index) {
        newPagesToDisplayBetweenEtc.unshift(this.nbPages - index);
      }
    } else if (this.hasToDisplayEtcRight) {
      for (let index = secondPageIndex; index <= this.defaultNbPageButtonsToDisplayBetweenEtcIfOneEtc; ++index) {
        newPagesToDisplayBetweenEtc.push(index);
      }
    } else {
      newPagesToDisplayBetweenEtc = []; // case where nbPages <= realNbMaxPageButtonsToDisplay
    }

    this.pagesToDisplayBetweenEtc = newPagesToDisplayBetweenEtc;
  }

  get realNbMaxPageButtonsToDisplay(): number {
    return this.nbMaxPageButtonsToDisplay >= this._minNbPageButonsToDisplay && !(this.nbMaxPageButtonsToDisplay % 2)
      ? this.nbMaxPageButtonsToDisplay
      : this._minNbPageButonsToDisplay;
  }

  get defaultNbPageButtonsToDisplayBetweenEtc(): number {
    return this.realNbMaxPageButtonsToDisplay - 1 - 2;
  }

  get defaultNbPageButtonsToDisplayBetweenEtcOnOneSideAroundCurrent(): number {
    return (this.defaultNbPageButtonsToDisplayBetweenEtc - 1) / 2;
  }

  get defaultNbPageButtonsToDisplayBetweenEtcIfOneEtc(): number {
    return this.realNbMaxPageButtonsToDisplay - 2;
  }

  get hasToDisplayEtcLeftOrRightTreshold(): number {
    return this.realNbMaxPageButtonsToDisplay / 2 + 1;
  }

  get hasToDisplayEtc(): boolean {
    return this.nbPages > this.realNbMaxPageButtonsToDisplay;
  }

  get hasToDisplayEtcLeft(): boolean {
    return (
      // asymmetric when nbPages is equal to realNbMaxPageButtonsToDisplay + 1
      this.isTotalPagesUnknown ||
      (this.hasToDisplayEtc &&
        this.currentPageIndex + (this.nbPages === this.realNbMaxPageButtonsToDisplay + 1 ? 1 : 0) >=
          this.hasToDisplayEtcLeftOrRightTreshold)
    );
  }

  get hasToDisplayEtcRight(): boolean {
    return (
      this.isTotalPagesUnknown ||
      (this.hasToDisplayEtc && this.nbPages - this.currentPageIndex >= this.hasToDisplayEtcLeftOrRightTreshold)
    );
  }

  get isPreviousButtonDisabled(): boolean {
    return this.currentPageIndex <= 1;
  }

  get isNextButtonDisabled(): boolean {
    return this.currentPageIndex >= this.nbPages;
  }

  getTextColor(pageIndex: number): string {
    return pageIndex === null || pageIndex === undefined || pageIndex !== this.currentPageIndex
      ? this.textColor
      : 'white';
  }

  getFontSizeForPageIndex(pageIndex: number): string {
    return `${this._pageIndexFontSize - this._pageStepIndexFontSize * (pageIndex.toString()?.length - 1 || 0)}`;
  }

  informClickOnPrevious(): void {
    if (this.currentPageIndex > this.first_page_index) {
      this.currentPageIndex -= 1;
      this._refreshPagesArrayToDisplayBetweenEtc();
      this.clickedOnPrevious.emit(this.currentPageIndex);
    }
  }

  informClickOnNext(): void {
    if (this.currentPageIndex < this.nbPages) {
      this.currentPageIndex += 1;
      this._refreshPagesArrayToDisplayBetweenEtc();
      this.clickedOnNext.emit(this.currentPageIndex);
    }
  }

  informClickOnPageButton(pageIndex: number): void {
    if (pageIndex >= this.first_page_index && pageIndex <= this.nbPages) {
      this.currentPageIndex = pageIndex;
      this._refreshPagesArrayToDisplayBetweenEtc();
      this.clickedOnPageButton.emit(pageIndex);
    }
  }
}
