import { NgModule } from '@angular/core';

import { AtomsModule } from '../../atoms/atoms.module';

import { SidebarComponent } from './sidebar.component';

@NgModule({
  declarations: [SidebarComponent],
  imports: [AtomsModule],
  exports: [SidebarComponent],
  providers: [],
})
export class SidebarModule {}
