import type { OnDestroy, OnInit } from '@angular/core';
import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { AccessRequestsModalService, AccessRequestsV2Service } from '@dataportal/access-requests';
import { DialogsService } from '@dataportal/adl';
import { GoogleTagManagerService } from '@dataportal/analytics';
import { CurrentUserService } from '@dataportal/auth';
import type { ISourceCategory } from '@dataportal/categories';
import { CategoriesService } from '@dataportal/categories';
import { EnvironmentService } from '@dataportal/front-environment';
import { ImpersonateModalComponent } from '@dataportal/impersonate';
import { PermissionsService } from '@dataportal/permissions';
import type { Portal } from '@dataportal/portals';
import {
  DashboardsService,
  FavoritesDashboardsService,
  FavoritesSourcesService,
  RecommendationModalService,
  Source,
  SourcesService,
} from '@dataportal/sources-dashboards-recommendations';
import { ImpersonateService } from '@dataportal/users';

import { SourceMetadataModalComponent } from '../source-metadata-modal/source-metadata-modal.component';

export interface ISourceModalComponentData {
  source: Source;
  portals: Portal[];
}

// Component
@Component({
  selector: 'dpg-source-modal',
  templateUrl: './source-modal.component.html',
  styleUrls: ['./source-modal.component.scss'],
})
export class SourceModalComponent implements OnInit, OnDestroy {
  // Attributes
  @Input() source: Source;
  @Input() portals: Portal[];

  hasAccess = false;
  hasAlreadyRequestAccess = false;
  hasAPendingRequest = false;
  loading = false;
  canEdit = false;
  canDownload = false;
  isFavorite = false;
  localCategories: ISourceCategory[] = [];
  favoriteDashboards: string[] = [];

  private _userId: string;
  private readonly _destroyed$ = new Subject<void>();

  // Constructor
  constructor(
    private readonly _router: Router,
    private readonly _currentUser: CurrentUserService,
    private readonly _categoriesService: CategoriesService,
    private readonly _dashboardService: DashboardsService,
    private readonly _permissionsService: PermissionsService,
    private readonly _recommendationsModalService: RecommendationModalService,
    private readonly _favoritesSourcesService: FavoritesSourcesService,
    private readonly _sourcesService: SourcesService,
    private readonly _favoritesDashboardsService: FavoritesDashboardsService,
    private readonly _accessRequestsModalService: AccessRequestsModalService,
    private readonly _accessRequestsV2Service: AccessRequestsV2Service,
    private readonly _impersonateService: ImpersonateService,
    private readonly _dialogService: DialogsService,
    private readonly _activeMatModal: MatDialogRef<SourceModalComponent>,
    private readonly _currentUserService: CurrentUserService,
    private readonly _gtmService: GoogleTagManagerService,
    readonly environmentService: EnvironmentService,
    @Inject(MAT_DIALOG_DATA) data: ISourceModalComponentData,
  ) {
    if (data) {
      Object.keys(data).forEach((key) => {
        this[key] = data[key] ? data[key] : this[key];
      });
    }
  }

  get isImpersonating(): boolean {
    return this._impersonateService.isImpersonating();
  }

  // Lifecycle
  ngOnInit(): void {
    this._currentUserService.currentUser$.pipe(takeUntil(this._destroyed$)).subscribe((user) => {
      this._gtmService.pushEvent({
        event: 'source_view',
        source_id: this.source.id,
        user: user,
      });

      if (user?.id) {
        this._userId = user.id;
        this._accessRequestsV2Service.listByUser(this._userId).pipe(takeUntil(this._destroyed$)).subscribe();
      }
    });

    this._permissionsService
      .isAuthorized('update', 'sources', this.source.id)
      .pipe(takeUntil(this._destroyed$))
      .subscribe((result) => (this.canEdit = result));

    this.hasAccess = this._sourcesService.isSourceComplete(this.source);

    this._accessRequestsV2Service.currentUserAccessRequests$.pipe(takeUntil(this._destroyed$)).subscribe((data) => {
      this.hasAlreadyRequestAccess = data
        .filter((ar) => ar.status === 'inactive')
        .filter((ar) => ar.resource === 'sources')
        .some((ar) => ar.resourceId === this.source.id);
      this.hasAPendingRequest = data
        .filter((ar) => ar.status === 'pending')
        .filter((ar) => ar.resource === 'sources')
        .some((ar) => ar.resourceId === this.source.id);
    });

    this._accessRequestsV2Service.createdAccessRequests$.pipe(takeUntil(this._destroyed$)).subscribe((data) => {
      this.hasAPendingRequest =
        this.hasAPendingRequest || data.some((ar) => ar.resource === 'sources' && ar.resourceId === this.source.id);
    });

    this._categoriesService.sourceCategories$.pipe(takeUntil(this._destroyed$)).subscribe((sourceCategories) => {
      this.localCategories = sourceCategories.has(this.source.id) ? sourceCategories.get(this.source.id) : [];
    });

    this._favoritesSourcesService.favoritesSources$.pipe(takeUntil(this._destroyed$)).subscribe(() => {
      this.isFavorite = this._favoritesSourcesService.isFavoriteSource(this.source.id);
    });

    this._permissionsService
      .isAuthorized('explore', 'sources', this.source.id)
      .pipe(takeUntil(this._destroyed$))
      .subscribe((canDownload) => {
        this.canDownload = canDownload;
      });

    if (this.source?.hasInternalDashboard) {
      this._currentUser.currentUser$.pipe(takeUntil(this._destroyed$)).subscribe((user) => {
        this._dashboardService.preloadSourceDashboards(this.source, user.id);
      });
    }

    this._favoritesDashboardsService.favoritesDashboards$
      .pipe(takeUntil(this._destroyed$))
      .subscribe((dashboardFavorites) => {
        this.favoriteDashboards = dashboardFavorites.map((dashboardFavorite) => dashboardFavorite.dashboardName);
      });
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
  }

  // Methods
  loaded(): void {
    this.loading = false;
  }

  toggleFavorite(): void {
    if (!this.isFavorite) {
      this._favoritesSourcesService.addFavoriteSource(this.source.id);
    } else {
      this._favoritesSourcesService.removeFavoriteSource(this.source.id);

      if (this.favoriteDashboards.length) {
        this.favoriteDashboards.forEach((favoriteDashboard) => {
          this._favoritesDashboardsService.removeFavoriteDashboard(this.source.id, favoriteDashboard);
        });
      }
    }
  }

  openAccessModal(): void {
    const accessModal = this._accessRequestsModalService.openAccessModal('sources', this.source);
    accessModal
      .afterClosed()
      .pipe(take(1), takeUntil(this._destroyed$))
      .subscribe((hasRequestedAccess) => {
        if (hasRequestedAccess) {
          this.hasAPendingRequest = true;
        }
      });
  }

  goToEditionForm(): void {
    this._activeMatModal.close(true);
    // TODO: customizable links !
    this._router.navigate(['/admin/sources/edit', this.source.id]);
  }

  hasExternal(type: string): boolean {
    const links = this.source.externalLinks.filter((link) => link.type === type);

    return links.length > 0 && links[0].url && links[0].url.length > 0;
  }

  getExternal(type: string): string {
    if (this.source.externalLinks.filter((link) => link.type === type).length > 0) {
      return this.source.externalLinks.filter((link) => link.type === type)[0].url;
    }
  }
  openRecommendationModal(): void {
    this._recommendationsModalService.openRecommendationModal(this.source);
  }

  openMetadataModal(): void {
    this._dialogService.open<{ source: Source }>(SourceMetadataModalComponent, {
      source: this.source,
    });
  }

  closeSourceModal(): void {
    this._activeMatModal.close();
  }

  // Properties
  get sourceCategories(): string {
    const names = this.source.categories
      .map((c) => this._categoriesService.findCategory(c))
      .filter((c) => c != null)
      .map((c) => c.name)
      .concat(this.localCategories.map((lc) => lc.name));

    return names.length > 0 ? names.join(' - ') : 'No category';
  }

  get cardColor(): string {
    if (this.source.isLimited) {
      return 'unauthorized';
    }

    this._categoriesService.getColor(this.source.categories, this.localCategories);
  }

  get organization(): string {
    return this.source.displayOrganization(this.portals);
  }

  get hasDatalake(): boolean {
    return this.source.datalakePath.length > 0;
  }

  impersonate(): void {
    this._dialogService.open(ImpersonateModalComponent);
  }

  close(): void {
    this._activeMatModal.close();
  }
}
