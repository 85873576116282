import type { OnDestroy, OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

import { CatalogV2SearchService } from '../../../services/v2/search.service';

@Component({
  selector: 'dpg-catalog-mode-switch',
  templateUrl: './catalog-mode-switch.component.html',
  styleUrls: ['./catalog-mode-switch.component.scss'],
})
export class CatalogModeSwitchComponent implements OnInit, OnDestroy {
  @Input() adaptiveToggleTextColor = false;

  switchControl = new FormControl();

  private readonly _destroyed$ = new Subject<void>();

  constructor(private readonly _searchService: CatalogV2SearchService) {}

  ngOnInit(): void {
    this._searchService.searchMode$
      .pipe(distinctUntilChanged(), takeUntil(this._destroyed$))
      .subscribe((searchMode) => {
        this.switchControl.setValue(searchMode === 'advanced');
      });

    this.switchControl.valueChanges
      .pipe(distinctUntilChanged(), takeUntil(this._destroyed$))
      .subscribe((switchValue: boolean) => {
        this._searchService.updateMode(switchValue ? 'advanced' : 'simple');
      });
  }

  ngOnDestroy() {
    this._destroyed$.next();
  }
}
