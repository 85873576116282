import type { OnChanges, OnDestroy, OnInit } from '@angular/core';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil, tap } from 'rxjs/operators';
import type {
  IGraphAPIMetadata,
  IGraphAPIRequesterState,
  IListingOptionMetadata,
  IPaginatedRequesterState,
} from '@dataportal/front-api';
import { GenericApiPaginatedService } from '@dataportal/front-api';
import type { Entity } from '@decahedron/entity';

export type SelectPredicate<T> = (result: T) => boolean;

export interface ISelectPredicates<T> {
  [name: string]: SelectPredicate<T>;
}

type SupportedMetadata = IListingOptionMetadata | IGraphAPIMetadata;
type SupportedRequesterState = IPaginatedRequesterState | IGraphAPIRequesterState;

@Component({
  templateUrl: './common-select.component.html',
})
export class CommonSelectComponent<T extends Entity> implements OnInit, OnChanges, OnDestroy {
  @Input() predicates: ISelectPredicates<T> = {};
  @Input() label = 'displayName';
  @Input() value = 'id';
  @Input() placeholder = 'Select an item';
  @Input() searchText = 'Type to Search...';
  @Input() minSize = 50;
  @Input() limit = 2000;
  @Input() clear: Subject<void>;

  @Output() selected = new EventEmitter<T>();

  loading = false;
  list: T[] = [];
  searchSubject = new Subject<string>();
  query = '';

  private _requestId: string;
  private readonly _destroyed$ = new Subject<void>();

  constructor(private readonly _service: GenericApiPaginatedService<T, SupportedMetadata, SupportedRequesterState>) {}

  ngOnInit(): void {
    this._initList();
    this._service.results$.pipe(takeUntil(this._destroyed$)).subscribe((results) => {
      if (this._requestId === results.requesterId) {
        const toAdd = this._filterList(results.data);
        this.list = [...this.list, ...toAdd];
      }
    });

    this._service.loading$.pipe(takeUntil(this._destroyed$)).subscribe((loading) => {
      if (loading.requesterId === this._requestId) {
        this.loading = loading.data;
      }
    });

    this.searchSubject
      .pipe(
        tap(() => {
          this.list = [];
        }),
        distinctUntilChanged(),
        debounceTime(500),
      )
      .subscribe((value: string) => {
        if (value && value.length > 0) {
          this.query = value;
          this._refreshList();
        } else {
          this.cleared();
        }
      });

    if (this.clear) {
      this.clear.subscribe(() => {
        this.cleared();
      });
    }
  }

  ngOnChanges(): void {
    this.list = this._filterList(this.list);
  }

  private _filterList(list: T[]): T[] {
    list = [...list];

    for (const predicateName of Object.keys(this.predicates)) {
      const predicate = this.predicates[predicateName];
      list = list.filter((item) => predicate(item));
    }

    return list;
  }

  cleared(): void {
    this.query = '';
    this._initList();
  }

  changed($event: T): void {
    this.selected.emit($event);
  }

  scrolledToEnd(): void {
    this._service.fetchNextPage(this._requestId);
  }

  private _initList(): void {
    this.loading = true;
    this.list = [];
    this._requestId = this._service.startListing({
      min_size: this.minSize,
      limit: this.limit,
    });
  }

  private _refreshList(): void {
    this.list = [];
    const options: IListingOptionMetadata = {
      limit: this.limit,
      sortBy: 'name',
      sort: 'asc',
      filters: {},
      min_size: this.minSize,
    };

    if (this.query) {
      // For non-ddb
      options.query = this.query.toLowerCase();
      // For ddb
      options.filters.name = this.query.toLowerCase();
    }

    this._requestId = this._service.startListing(options);
  }

  setPredicate(name: string, predicate: SelectPredicate<T>): void {
    this.predicates[name] = predicate;
  }

  addPredicate(name: string, predicate: SelectPredicate<T>): void {
    if (!this.predicates[name]) {
      this.setPredicate(name, predicate);
    }
  }

  removePredicate(name: string): void {
    if (this.predicates[name]) {
      delete this.predicates[name];
    }
  }

  setPredicates(predicates: ISelectPredicates<T>): void {
    Object.keys(predicates).forEach((n) => this.setPredicate(n, predicates[n]));
  }

  addPredicates(predicates: ISelectPredicates<T>): void {
    Object.keys(predicates).forEach((n) => this.addPredicate(n, predicates[n]));
  }

  removePredicates(predicates: string[]): void {
    predicates.forEach((n) => this.removePredicate(n));
  }

  ngOnDestroy(): void {
    this._service.unsubscribe(this._requestId);
    this._destroyed$.next();
  }
}
