<div class='column-flex j-center'>
  <adl-title class='row-flex j-center mt25 mb25 title'>Snowflake Account</adl-title>
  <adl-text size='20' class='row-flex j-center mb25 description'>
    Choose between those account the one that matches your needs
  </adl-text>
  <div class='column-flex j-start a-center w100'>
    <div class='row-flex j-center a-start w80'>
      <adl-tool-card
        *ngFor='let account of allAccount'
        title='{{ account.name }}'
        url='{{ account.url }}'
        (navigating)="pushGTMOpenSnowflakeEvent(account.name)"
        isExternalUrl='true'
        class='cards row-flex j-center a-start mt10'
      >
        <adl-image logo size='60px' src='assets/dpg/imgs/icons/snowflake-logo.svg' class='mb20'></adl-image>
      </adl-tool-card>
    </div>
  </div>
</div>
