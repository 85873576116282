import { CommonModule } from '@angular/common';
import type { ModuleWithProviders } from '@angular/core';
import { NgModule } from '@angular/core';
import { AdlModule } from '@dataportal/adl';
import { AuthModule } from '@dataportal/auth';
import { DatalakeParsingModule } from '@dataportal/datalake-parsing';
import { SharedModule } from '@dataportal/front-shared';
import { GuardianUtilsModule } from '@dataportal/guardian-utils';
import { LogsModule } from '@dataportal/logs';
import { SnowflakeModule } from '@dataportal/snowflake';
import { FlatfileAdapterModule } from '@flatfile/angular';

import { FlatfileService } from './services/flatfile.service';

import { FlatfileUploadButtonComponent } from './components/flatfile-upload-button/flatfile-upload-button.component';
import { FlatfileUploadLoaderModalComponent } from './components/flatfile-upload-loader/flatfile-upload-loader-modal.component';
import { FlatfileUploadProgressBarModalComponent } from './components/flatfile-upload-progress-bar/flatfile-upload-progress-bar-modal.component';
import { FlatfileUploadSheetsSelectModalComponent } from './components/flatfile-upload-sheets-select-modal/flatfile-upload-sheets-select-modal.component';

import type { IFlatfileOptions } from './flatfile-options';
import { FLATFILE_OPTIONS } from './flatfile-options';

// Module
@NgModule({
  imports: [
    CommonModule,
    AdlModule,
    AuthModule,
    DatalakeParsingModule,
    GuardianUtilsModule,
    LogsModule,
    SharedModule,
    FlatfileAdapterModule,
    SnowflakeModule,
  ],
  declarations: [
    FlatfileUploadButtonComponent,
    FlatfileUploadProgressBarModalComponent,
    FlatfileUploadSheetsSelectModalComponent,
    FlatfileUploadLoaderModalComponent,
  ],
  exports: [
    FlatfileUploadButtonComponent,
    FlatfileUploadProgressBarModalComponent,
    FlatfileUploadSheetsSelectModalComponent,
    FlatfileUploadLoaderModalComponent,
  ],
  providers: [FlatfileService],
})
export class FlatfileModule {
  // Statics
  static forRoot(options: IFlatfileOptions): ModuleWithProviders<FlatfileModule> {
    return {
      ngModule: FlatfileModule,
      providers: [{ provide: FLATFILE_OPTIONS, useValue: options }],
    };
  }
}
