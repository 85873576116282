<div
  class="sidebar-container"
  [style.width.px]="sidebarCollapse ? 50 : 250"
  [style.height]="sidebarCollapse ? '50px' : '100%'"
>
  <div class="collapse-icon">
    <adl-icon
      (click)="sidebarCollapse = !sidebarCollapse"
      icon="angle-double-{{ sidebarCollapse ? 'right' : 'left' }}"
    ></adl-icon>
  </div>

  <div class="sidebar-toggleable h90" *ngIf="!sidebarCollapse">
    <div class="header-text w100">
      <div class="img-container w100">
        <img src="../../../../../assets/dpg/imgs/commons/dp-logo.svg" alt="">
      </div>
      <p>{{ currentSource?.name }}</p>
    </div>

    <div class="tree w100">
      <div *ngFor="let category of object.keys(dashboardTree.nested)">
        <div class="folder df a-center" (click)="handleCollapse(category)">
          <p [class.category-selected]="currentDashboard?.parentFolder?.name === category">{{ category }}</p>
          <adl-icon
            icon="chevron-{{ categoryCollapseStatus.get(category) ? 'right' : 'down' }}"
          >
          </adl-icon>
        </div>
        <div *ngIf="!categoryCollapseStatus.get(category)">
          <div *ngFor="let dashboard of dashboardTree.nested[category]">
            <p
              class="dashboard"
              [class.dashboard-selected]="currentDashboard?.name === dashboard.name"
              (click)="openDashboardLocally(dashboard)"
            >
              {{ dashboard.name }}
            </p>
          </div>
        </div>
      </div>
      <div *ngFor="let other of dashboardTree.other">
        <p
          class="other"
          [class.dashboard-selected]="currentDashboard?.name === other.name"
          (click)="openDashboardLocally(other)"
        >
          {{ other.name }}
        </p>
      </div>
    </div>
    <a [href]="'sources/' + source?.id" class="go-back df a-center">
      <adl-icon icon="chevron-left"></adl-icon>
      <p>Go Back</p>
    </a>
  </div>
</div>
