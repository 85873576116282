import type { OnChanges, OnDestroy } from '@angular/core';
import { Component, Inject, Input } from '@angular/core';
import { Router } from '@angular/router';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SidebarV3Service } from '@dataportal/adl';
import { IPermissionsOptions, PERMISSIONS_OPTIONS, PermissionsService } from '@dataportal/permissions';

import { NavbarItemV2 } from './navbar-item-v2.entity';

@Component({
  selector: 'dpg-navbar-item-v2',
  templateUrl: './navbar-item-v2.component.html',
  styleUrls: ['./navbar-item-v2.component.scss'],
})
export class NavbarItemV2Component implements OnChanges, OnDestroy {
  @Input() external = false;
  @Input() item: NavbarItemV2;

  isDeveloped = false;
  isActivated = false;

  private readonly _reloaded$ = new Subject();
  private readonly _destroyed$ = new Subject();

  constructor(
    private readonly _router: Router,
    private readonly _sidebarService: SidebarV3Service,
    private readonly _permissionsService: PermissionsService,
    @Inject(PERMISSIONS_OPTIONS) protected readonly _options: IPermissionsOptions,
  ) {
    this._sidebarService.isDeveloped$.pipe(takeUntil(this._destroyed$)).subscribe((sidebarDeveloped) => {
      this.isDeveloped = sidebarDeveloped;
    });

    combineLatest([this._sidebarService.sidebarActiveUrl$, this._reloaded$])
      .pipe(takeUntil(this._destroyed$))
      .subscribe(([activeItemName]) => {
        if (this.item) {
          this.isActivated =
            !this.external &&
            !this.item.external &&
            typeof this.item.url === 'string' &&
            activeItemName === this.item.url.split('/')[1];
        }
      });
  }

  ngOnChanges() {
    this._reloaded$.next();
  }

  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
    this._reloaded$.complete();
  }

  redirectToAdminPages() {
    this._permissionsService.permissionsAdmin$.pipe(takeUntil(this._destroyed$)).subscribe((permissions) => {
      if (permissions) {
        if (permissions.sources) {
          this._router.navigateByUrl('/admin/sources');
        } else if (permissions.leons) {
          this._router.navigateByUrl('/admin/leons');
        } else if (permissions.groups) {
          this._router.navigateByUrl('/admin/groups');
        } else if (permissions.portals) {
          this._router.navigateByUrl('/admin/portals');
        } else if (permissions['access-requests']) {
          this._router.navigateByUrl('/admin/access-request');
        } else if ((permissions as { glossary: any }).glossary) {
          // TODO: [Revisit] (18.01.2024) - type
          this._router.navigateByUrl('/admin/glossary');
        } else if (permissions.users) {
          this._router.navigateByUrl('/admin/users');
        } else void this._router.navigate(this._options.errorRouterLink);
      } else {
        void this._router.navigate(this._options.errorRouterLink);
      }
    });
  }

  handleOpenLink() {
    if (this.item.name === 'admin') {
      this.redirectToAdminPages();
    } else if (this.item.external || this.external) {
      window.open(this.item.url as string, '_blank');
    } else {
      this._router.navigateByUrl(typeof this.item.url === 'string' ? this.item.url : this.item.url()).then();
    }
  }
}
