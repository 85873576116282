import { Inject, Injectable } from '@angular/core';
import type { AccountInfo } from '@azure/msal-common/dist/account/AccountInfo';
import { E2EService, LocalStorageService, Logger, SessionStorageService } from '@dataportal/front-shared';
import { AuthCookiesKeys, AuthenticationState, AuthLocalStorageKeys, AuthSessionStorageKeys } from '@dataportal/msal';
import { CookieService } from 'ngx-cookie-service';

import { AUTH_OPTIONS, IAuthOptions } from '../auth-options';

@Injectable({
  providedIn: 'root',
})
/**
 * This service handle interactions regarding the authentication storage with:
 * - local storage
 * - session storage
 * - cookies
 */
export class AuthStorageService {
  constructor(
    private readonly _localStorageService: LocalStorageService,
    private readonly _sessionStorageService: SessionStorageService,
    private readonly _cookieService: CookieService,
    private readonly _logger: Logger,
    private readonly _e2eService: E2EService,
    @Inject(AUTH_OPTIONS) private readonly _authOptions: IAuthOptions,
  ) {}

  decodeJwtToken(token: string): { exp: number } {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(''),
    );

    return JSON.parse(jsonPayload);
  }

  isTokenExpired(token: string): boolean {
    if (!token) {
      return true;
    }

    if (this._e2eService.isEnabled()) {
      return false;
    }

    try {
      return this._isTokenExpiredAfterDecoded(token);
    } catch (e) {
      this._logger.warn('[Auth Service] (decode) Cannot decode token, considering it expired', e);

      return true;
    }
  }

  private _isTokenExpiredAfterDecoded(token: string) {
    const payload = this.decodeJwtToken(token);

    return payload.exp < Math.floor(Date.now() / 1000);
  }

  isExpired(type: 'access' | 'id' | 'refresh'): boolean {
    switch (type) {
      case 'access':
        return this.isTokenExpired(this.accessToken);
      case 'id':
        return this.isTokenExpired(this.idToken);
      case 'refresh':
        return this.isTokenExpired(this.refreshToken);
    }
  }

  // Properties
  get provider(): string | null {
    return this._localStorageService.getItem(AuthLocalStorageKeys.AUTH_PROVIDER);
  }

  get idToken(): string | null {
    return this._localStorageService.getItem(AuthLocalStorageKeys.ID_TOKEN);
  }

  get accessToken(): string | null {
    return this._localStorageService.getItem(AuthLocalStorageKeys.ACCESS_TOKEN);
  }

  get refreshToken(): string | null {
    return this._localStorageService.getItem(AuthLocalStorageKeys.REFRESH_TOKEN);
  }

  get authAccount(): AccountInfo {
    return JSON.parse(this._localStorageService.getItem(AuthLocalStorageKeys.AUTH_ACCOUNT));
  }

  set authAccount(accountInfo: AccountInfo) {
    this._localStorageService.setItem(AuthLocalStorageKeys.AUTH_ACCOUNT, JSON.stringify(accountInfo));
  }

  get state(): AuthenticationState {
    return this._sessionStorageService.getItem(AuthSessionStorageKeys.AUTH_STATE) as AuthenticationState;
  }

  set state(state: AuthenticationState) {
    this._sessionStorageService.setItem(AuthSessionStorageKeys.AUTH_STATE, state);
  }

  get returnURL(): string {
    return this._sessionStorageService.getItem(AuthSessionStorageKeys.RETURN_URL);
  }

  set returnURL(url: string) {
    if (!url.endsWith('/login')) {
      this._sessionStorageService.setItem(AuthSessionStorageKeys.RETURN_URL, url);
    }
  }

  get devOpsToken(): string {
    return this._localStorageService.getItem(AuthLocalStorageKeys.DEVOPS_TOKEN);
  }

  set devOpsToken(token: string) {
    this._localStorageService.setItem(AuthLocalStorageKeys.DEVOPS_TOKEN, token);
  }

  get databricksToken(): string {
    return this._localStorageService.getItem(AuthLocalStorageKeys.DATABRICKS_TOKEN);
  }

  set databricksToken(token: string) {
    this._localStorageService.setItem(AuthLocalStorageKeys.DATABRICKS_TOKEN, token);
  }

  get redirectEdge(): string {
    return this._cookieService.get(AuthCookiesKeys.REDIRECT_EDGE);
  }

  doesRedirectEdgeExist(): boolean {
    return this._cookieService.check(AuthCookiesKeys.REDIRECT_EDGE);
  }

  deleteRedirectEdge(): void {
    this._cookieService.delete(AuthCookiesKeys.REDIRECT_EDGE);
  }

  removeAuthStorage() {
    this._sessionStorageService.removeItem(AuthSessionStorageKeys.RETURN_URL);
    this._sessionStorageService.setItem(AuthSessionStorageKeys.AUTH_STATE, AuthenticationState.NOT_AUTHENTICATED);

    this._localStorageService.removeItem(AuthLocalStorageKeys.ACCESS_TOKEN);
    this._localStorageService.removeItem(AuthLocalStorageKeys.AUTH_ACCOUNT);
    this._localStorageService.removeItem(AuthLocalStorageKeys.AUTH_PROVIDER);
    this._localStorageService.removeItem(AuthLocalStorageKeys.DATABRICKS_TOKEN);
    this._localStorageService.removeItem(AuthLocalStorageKeys.DEVOPS_TOKEN);
    this._localStorageService.removeItem(AuthLocalStorageKeys.ID_TOKEN);
    this._localStorageService.removeItem(AuthLocalStorageKeys.REFRESH_TOKEN);
  }
}
