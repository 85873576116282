import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Logger } from '@dataportal/front-shared';
import type { Entity } from '@decahedron/entity';

import { ApiService } from './api.service';
import { deserialize, GenericApiPaginatedService, serialize } from './generic-api-paginated.service';

import type { ApiOptions } from './api.service';
import type { IApiResult } from './generic-api-paginated.service';

// Types
interface IMetadata {
  limit: number;
  lastEvaluatedKey: Record<string, any> | null;
  sort: 'asc' | 'desc';
  sortBy: string;
  filters: Record<string, any>;
  projections: string[];
  query?: string;
  nextPage?: string;
}

export interface IPaginatedRequesterState {
  apiOptions: ApiOptions;
  ddbPage: number;
  currentPage: number;
  currentSize: number;
  maxSize: number;
  isAdmin: boolean;
  metadata: IMetadata;
}

export interface IListingOptionMetadata extends Partial<Omit<IMetadata, 'lastEvaluatedKey'>> {
  isAdmin?: boolean;
  min_size?: number;
}

// Utils
function defaultMetadata(): IMetadata {
  return {
    limit: Infinity,
    lastEvaluatedKey: null,
    sort: 'asc',
    sortBy: null,
    filters: {},
    projections: [],
  };
}

// Service
@Injectable()
export abstract class ApiPaginatedService<T extends Entity> extends GenericApiPaginatedService<
  T,
  IListingOptionMetadata,
  IPaginatedRequesterState
> {
  // Constructor
  constructor(protected readonly apiService: ApiService, protected readonly logger: Logger) {
    super(apiService, logger);
    this.results$.subscribe(({ requesterId }) => {
      const state = this.states.get(requesterId);

      if (!state) {
        return;
      }

      // Gather requester metadata
      const { currentSize, maxSize, metadata } = state;

      // Logging
      this.logger.debug('[API Paginated Service] Results fetched', `${currentSize}/${maxSize}`);

      if (!metadata.lastEvaluatedKey) {
        this.logger.debug('[API Paginated Service] List item reached on DDB');
      }

      if (currentSize >= maxSize) {
        this.logger.debug('[API Paginated Service] All targeted items fetched');
      }

      // Load next values
      if (metadata.lastEvaluatedKey && currentSize < maxSize) {
        this.logger.debug('[API Paginated Service] Not enough results, fetch one more DDB page');
        this.nextResults(requesterId).subscribe();
      } else {
        this.loading.next({ requesterId, data: false });
        this.logger.debug('[API Paginated Service] loading', false);
      }
    });
  }

  // Methods
  startListing(listOptions?: IListingOptionMetadata, apiOptions?: ApiOptions): string {
    this.logger.debug('[API Paginated Service] list', listOptions);
    // Get default initiated state
    const requesterId = super.initListing(listOptions, apiOptions);
    const initiatedState = this.states.get(requesterId);

    // Complete state
    const { min_size: maxSize = Infinity, isAdmin, ...metadata } = listOptions;

    const completedState: IPaginatedRequesterState = {
      ...initiatedState,
      ddbPage: 0,
      maxSize,
      isAdmin,
      metadata: Object.assign(defaultMetadata(), metadata),
    };

    this.states.set(requesterId, completedState);

    // Start requests
    setTimeout(() => this.fetchNextPage(requesterId), 0);

    return requesterId;
  }

  protected nextResults(requesterId: string): Observable<void> {
    const { apiOptions, ddbPage, metadata, isAdmin } = this.states.get(requesterId);
    this.logger.debug('[API Paginated Service] Fetching page', ddbPage);

    // Make request
    const options = Object.assign({ params: {} }, apiOptions);

    if (options.params instanceof HttpParams) {
      options.params.set('params', serialize(metadata));
    } else {
      options.params.params = serialize(metadata);
    }

    return this.apiService.get<IApiResult>(this.url + (isAdmin ? '/admin' : ''), options).pipe(
      map((res) => {
        const data = this.buildMany(res.data);

        // Update state
        const state = this.states.get(requesterId);

        if (state) {
          state.ddbPage += 1;
          state.currentSize += data.length;
          state.metadata = deserialize(res.metadata);
        }

        // Emit results
        this.logger.debug('[API Paginated Service] Next results received', data.length);
        this.results.next({ requesterId, data });
      }),
    );
  }

  hasNext(requesterId: string): boolean {
    const { currentPage, metadata } = this.states.get(requesterId);

    return currentPage < 0 || !!metadata.lastEvaluatedKey;
  }

  listAll(): Observable<T[]> {
    return this.apiService.get<any[]>(this.url, {}).pipe(map((res) => this.buildMany(res)));
  }

  get(id: string, options?: ApiOptions): Observable<T> {
    return this.apiService
      .get<any>(`${this.url}/${id}`, Object.assign({}, options))
      .pipe(map((res) => this.buildOne(res)));
  }

  delete<R>(id: string, options?: ApiOptions): Observable<R> {
    return this.apiService.delete<R>(`${this.url}/${id}`, Object.assign({}, options));
  }

  post<R, P = unknown>(body: P | null, options?: ApiOptions): Observable<R> {
    return this.apiService.post<R>(this.url, body, Object.assign({}, options));
  }

  put<R, P = unknown>(id: string, body: P | null, options?: ApiOptions): Observable<R> {
    return this.apiService.put<R>(`${this.url}/${id}`, body, Object.assign({}, options));
  }

  patch<R, P = unknown>(id: string, body: P | null, options?: ApiOptions): Observable<R> {
    return this.apiService.patch<R>(`${this.url}/${id}`, body, Object.assign({}, options));
  }
}
