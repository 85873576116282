import { Inject, Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
// FIXME: [guardian] import from @dataportal/accor-types after publishing
import { E2EService, Logger } from '@dataportal/front-shared';
import type { IWebSocketEvent } from '@dataportal/types';

import { WEBSOCKET_URL } from './websocket-url';

// Types
export interface IWebsocketMessageHandler {
  /**
   * Called each time websocket emits a message that was not handled by service
   * Shall return true if the message was handled and should now be ignored
   *
   * @param message Received message
   */
  onMessage(message: IWebSocketEvent): boolean;
}

const TIME_BETWEEN_RECONNECT_IN_MS = 10000;

type DatetimeISO = string;

export interface ICheckRequests {
  pk: string; // uuid
  sk: string; // checks|$id_check
  id_user: string;
  id_check: number;
  name?: string;
  requestedAt: DatetimeISO;
  check_type?: 'dataset' | 'up-to-date';
  status: 'in_progress' | 'crashed';
  reason?: string; // corresponds to IAPIGuardianCallError.message
  error_type?: string; // corresponds to IAPIGuardianCallError.type
  resource: 'datalakePath' | 'snowflake'; // The Guardian resource type
}

// Service
@Injectable()
export class WebsocketsService {
  // Attributes
  private _socket: WebSocket;
  private readonly _socketConnected$ = new ReplaySubject<void>();
  private _accessToken: string;
  private _impersonatedUser: string;
  private _isFirstWSConnection = true;

  private readonly _handlers = new Set<IWebsocketMessageHandler>();

  // Events
  private readonly _refreshGuardianChecks$: ReplaySubject<ICheckRequests> = new ReplaySubject<ICheckRequests>(1);
  private readonly _guardianMessageStatus$: ReplaySubject<{ status: string; message?: string; error?: unknown }> =
    new ReplaySubject<{
      status: string;
      message: string;
      error: unknown;
    }>(1);
  private readonly _hasCheckFinished$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  private readonly _linkCreated$: ReplaySubject<string> = new ReplaySubject<string>();
  private readonly _linkUpdated$: ReplaySubject<string> = new ReplaySubject<string>();
  private readonly _linkRemoved$: ReplaySubject<string> = new ReplaySubject<string>();
  private readonly _zipDownloadFailed$: ReplaySubject<{ requestId: string; reason: string }> = new ReplaySubject<{
    requestId: string;
    reason: string;
  }>();
  private readonly _zipDownloadSucceed$: ReplaySubject<{ requestId: string; zipId: string }> = new ReplaySubject<{
    requestId: string;
    zipId: string;
  }>();
  private readonly _zipDownloadAborted$: ReplaySubject<{ requestId: string }> = new ReplaySubject<{
    requestId: string;
  }>();
  private readonly _accessRequestsNotifications$: ReplaySubject<{ nbNotifications: number }> = new ReplaySubject<{
    nbNotifications: number;
  }>();
  private readonly _publicationsNotifications$: ReplaySubject<{ nbNotifications: number }> = new ReplaySubject<{
    nbNotifications: number;
  }>();

  refreshGuardianChecks$ = this._refreshGuardianChecks$.asObservable();
  guardianMessageStatus$ = this._guardianMessageStatus$.asObservable();
  hasCheckFinished$ = this._hasCheckFinished$.asObservable();
  linkCreated$ = this._linkCreated$.asObservable();
  linkUpdated$ = this._linkUpdated$.asObservable();
  linkRemoved$ = this._linkRemoved$.asObservable();
  zipDownloadFailed$ = this._zipDownloadFailed$.asObservable();
  zipDownloadSucceed$ = this._zipDownloadSucceed$.asObservable();
  zipDownloadAborted$ = this._zipDownloadAborted$.asObservable();
  accessRequestsNotifications$ = this._accessRequestsNotifications$.asObservable();
  publicationsNotifications$ = this._publicationsNotifications$.asObservable();

  socketConnected$ = this._socketConnected$.asObservable();

  constructor(
    @Inject(WEBSOCKET_URL) private readonly _websocketUrl: string,
    private readonly _logger: Logger,
    private readonly _e2eService: E2EService,
  ) {}

  private _connect() {
    if (this._socket) {
      this._logger.info('[WebsocketService] A websocket is already opened. Closing it.');
      this._socket.close();
    } else {
      this._logger.info('[WebsocketService] Establishing websocket connection', {
        url: this._websocketUrl,
        impersonatedUser: this._impersonatedUser,
      });
      let url = this._websocketUrl + '?token=' + this._accessToken;

      if (this._impersonatedUser) {
        url += `&impersonate=${encodeURIComponent(this._impersonatedUser)}`;
      }

      if (this._e2eService.isEnabled()) {
        const testUserId = this._e2eService.getTestUserId();
        url += `&e2e-enabled=true`;

        if (testUserId) {
          url += `&e2e-user-id=${encodeURIComponent(testUserId)}`;
        }
      }

      this._logger.debug('[WebsocketService] Connecting to', url);
      this._socket = new WebSocket(url);

      this._socket.onopen = () => {
        this._logger.info('[WebsocketService] Websocket connected');
        this._socketConnected$.next();
      };

      this._socket.onmessage = this._handleMessage.bind(this);

      this._socket.onerror = (errorEvent) => {
        this._logger.debug('Error event', errorEvent);
      };

      this._socket.onclose = (closeEvent) => {
        this._logger.debug('Close event', closeEvent);
        this._logger.info('[WebsocketService] Websocket closed !');
        this._socket = null;

        if (this._isFirstWSConnection) {
          this._isFirstWSConnection = false;
          this._connect();
        } else {
          setTimeout(() => this._connect(), TIME_BETWEEN_RECONNECT_IN_MS);
        }
      };
    }
  }

  reconnect(impersonatedUser?: string): void {
    this._impersonatedUser = impersonatedUser;
    this._connect();
  }

  private _handleMessage(response: MessageEvent<string>) {
    this._logger.debug(response);

    try {
      this._logger.debug('[WebsocketService] Parsing message', response);
      const message = this._parsePayload(response);

      switch (message.event) {
        case 'guardianCheckInProgress':
          this._hasCheckFinished$.next(false);
          this._refreshGuardianChecks$.next(message.data as ICheckRequests);
          break;
        case 'guardianCheckSucceed':
          this._hasCheckFinished$.next(true);
          this._refreshGuardianChecks$.next(message.data as ICheckRequests);
          this._guardianMessageStatus$.next({
            status: 'success',
            message: 'Check finished to run',
          });
          break;

        case 'guardianCheckFailed': {
          this._logger.debug(message.data);
          const guardianCheckFailedCheckRequest = (message.data || {}) as ICheckRequests;
          const crashError = {
            type: guardianCheckFailedCheckRequest.error_type,
            message: guardianCheckFailedCheckRequest.reason,
          };
          const guardianCheckFailedError = crashError || {};
          this._hasCheckFinished$.next(true);
          this._refreshGuardianChecks$.next(guardianCheckFailedCheckRequest);
          this._guardianMessageStatus$.next({
            status: 'error',
            error: guardianCheckFailedError,
          });
          break;
        }

        case 'linkCreated':
          this._linkCreated$.next((message.data as { linkId: string }).linkId);
          break;
        case 'linkUpdated':
          this._linkUpdated$.next((message.data as { linkId: string }).linkId);
          break;
        case 'linkRemoved':
          this._linkRemoved$.next((message.data as { linkId: string }).linkId);
          break;
        case 'zipDownloadFailed':
          this._logger.error('An error occurred generating zip file');
          this._logger.debug(message.data);
          this._zipDownloadFailed$.next(message.data as { requestId: string; reason: string });
          break;
        case 'zipDownloadGenerated':
          this._logger.info('Zip file generated successfully');
          this._logger.debug(message.data);
          this._zipDownloadSucceed$.next(message.data as { requestId: string; zipId: string });
          break;
        case 'zipDownloadAborted':
          this._logger.info('Zip file aborted');
          this._logger.debug(message.data);
          this._zipDownloadAborted$.next(message.data as { requestId: string });
          break;
        case 'accessRequestsNotification':
          this._logger.info('Access requests notification received');
          this._logger.debug(message.data);
          this._accessRequestsNotifications$.next(message.data as { nbNotifications: number });
          break;
        case 'publicationsNotification':
          this._logger.info('Publications notification received');
          this._logger.debug(message.data);
          this._publicationsNotifications$.next(message.data as { nbNotifications: number });
          break;

        default: {
          let wasHandled = false;

          for (const handler of this._handlers) {
            if (handler.onMessage(message)) {
              wasHandled = true;
              break;
            }
          }

          if (!wasHandled) {
            this._logger.warn('[WebsocketService] Unknown event', message.event);
          }

          break;
        }
      }
    } catch (e) {
      this._logger.error('[WebsocketService] Error handling message');
      this._logger.error(e);
      this._logger.error(response);
    }
  }

  private _parsePayload(response: MessageEvent<string>): IWebSocketEvent {
    try {
      this._logger.debug(response.data);
      this._logger.debug(typeof response.data);

      return JSON.parse(response.data);
    } catch (e) {
      this._logger.error('[WebsocketService] Received an non-parsable socket payload');

      return {
        event: null,
      };
    }
  }

  updatedAccessToken(token: string) {
    this._accessToken = token;
  }

  registerHandler(handler: IWebsocketMessageHandler): void {
    this._handlers.add(handler);
  }

  unregisterHandler(handler: IWebsocketMessageHandler): void {
    this._handlers.delete(handler);
  }
}
