<adl-modal>
  <div modal-body class="px-16">
    <div class="filter-section">
      <div class="header-section flex pb-10">
        <span class="tracking-widest pr-16">CONTACT</span>

        <a (click)="clearContacts()">Clear</a>
      </div>

      <div class="flex flex-row flex-wrap justify-between w-full mb-4">
        <adl-checkbox
          class="mr-4 mb-3 adv-filters-checkbox"
          checkboxSize="small"
          checkboxColor="blue"
          (changed)="handleContactTypeChange(contactTypesEnum.TECHNICAL_OWNERS, $event)"
        >
          <span class="checkbox-label">Tribe Product Lead</span>
        </adl-checkbox>
        <adl-checkbox
          class="mr-4 mb-3 adv-filters-checkbox"
          checkboxSize="small"
          checkboxColor="blue"
          (changed)="handleContactTypeChange(contactTypesEnum.FUNCTIONAL_OWNERS, $event)"
        >
          <span>Data Product Owner</span>
        </adl-checkbox>
        <adl-checkbox
          class="mr-4 mb-3 adv-filters-checkbox"
          checkboxSize="small"
          checkboxColor="blue"
          (changed)="handleContactTypeChange(contactTypesEnum.DATA_OWNER, $event)"
        >
          <span>Data Business Owner</span>
        </adl-checkbox>
        <adl-checkbox
          class="adv-filters-checkbox"
          checkboxSize="small"
          checkboxColor="blue"
          (changed)="handleContactTypeChange(contactTypesEnum.DATA_ASSET_CREATOR, $event)"
        >
          <span>Data Product Creator</span>
        </adl-checkbox>
      </div>

      <dpg-limited-user-select
        (selected)="contactInfos.setValue($event)"
        placeholder="Typing contact name"
      ></dpg-limited-user-select>
    </div>

    <div class="filter-section">
      <div class="header-section flex pb-10">
        <span class="tracking-widest pr-20">METADATA</span>

        <a (click)="clearMetadata()">Clear</a>
      </div>

      <div class="flex justify-between pb-6">
        <adl-input [control]="database" placeholder="Type database name" class="w-1/2 pr-4"></adl-input>
        <adl-input [control]="schema" placeholder="Type schema name" class="w-1/2 pl-4"> </adl-input>
      </div>
      <div class="flex justify-between pb-6">
        <adl-input [control]="tableName" placeholder="Type view name" class="w-1/2 pr-4"> </adl-input>
      </div>
    </div>

    <div class="filter-section">
      <div class="header-section flex pb-10">
        <span class="tracking-widest pr-20">DATE</span>

        <a (click)="clearDates()">Clear</a>
      </div>

      <div class="flex justify-between pb-6">
        <div class="flex items-center justify-between w-1/2 pr-4">
          <span>From</span>
          <!--TODO: update icon using svg-->
          <adl-input
            type="date"
            [control]="dateFrom"
            [max]="dateTo.value"
            placeholder="mm/dd/yyyy"
            class="w-full pl-20"
          ></adl-input>
        </div>

        <div class="flex items-center justify-between w-1/2 pl-12">
          <span>To</span>

          <adl-input type="date" [control]="dateTo" [min]="dateFrom.value" class="w-full pl-20"></adl-input>
        </div>
      </div>
    </div>

    <div class="filter-section">
      <div class="header-section flex pb-10">
        <span class="tracking-widest pr-20">DATA SOURCING</span>

        <a (click)="clearDataSourcing()">Clear</a>
      </div>

      <div class="flex justify-between">
        <adl-radio-selector
          class="data-sourcing-selector flex flex-wrap w-full"
          [choices]="dataSourcingOptions"
          [activeChoice]="dataSourcing.value"
          selectorName="data-sourcing-selector"
          radioStyle="new"
          (changed)="dataSourcing.setValue($event)"
        >
        </adl-radio-selector>
      </div>
    </div>
  </div>

  <div modal-footer class="flex flex-row align-center justify-center">
    <adl-button type="data-catalog" class="cancel-btn pr-6" (click)="closeModal()">CANCEL</adl-button>

    <adl-button type="data-catalog" (click)="applyFilters()">
      <div class="flex items-center">
        <span>SEARCH</span>
        <div class="arrow-right ml-4"></div>
      </div>
    </adl-button>
  </div>
</adl-modal>
