import type { OnDestroy, OnInit } from '@angular/core';
import { Component, HostBinding, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, timer } from 'rxjs';
import { mergeMap, take, takeUntil } from 'rxjs/operators';
import { DialogsService, SidebarV3Service, ToasterComponent } from '@dataportal/adl';
import { GoogleTagManagerService } from '@dataportal/analytics';
import { AlertService, Logger, RoutingService } from '@dataportal/front-shared';
import type { IAdminNotificationEvent } from '@dataportal/notifications';
import { NotificationsService } from '@dataportal/notifications';
import { RouterEventsService } from '@dataportal/router-events';
import { SourcesService } from '@dataportal/sources-dashboards-recommendations';
import { ImpersonateService } from '@dataportal/users';
import { WebsocketsService } from '@dataportal/websocket';
import * as FastClick from 'fastclick';
import { CookieService } from 'ngx-cookie-service';

const HAS_SEEN_INFO_COOKIE_NAME = 'HAS_SEEN_INFO';

declare global {
  interface Window {
    windowsOpenedGTM: any[];
  }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  @HostBinding('class.show-sidebar')
  showSidebar = false;

  isSidebarActive = true;
  isSidebarDeveloped = false;
  linksOpened = [];
  isAdminPage: boolean;

  private readonly _destroyed$ = new Subject<void>();

  constructor(
    private readonly _router: Router,
    private readonly _routerEvents: RouterEventsService,
    private readonly _sourcesService: SourcesService,
    routingState: RoutingService,
    private readonly _impersonateService: ImpersonateService,
    private readonly _dialogsService: DialogsService,
    private readonly _cookieService: CookieService,
    private readonly _websocketsService: WebsocketsService,
    private readonly _notificationsService: NotificationsService,
    private readonly _logger: Logger,
    private readonly _alertService: AlertService,
    private readonly _gtmService: GoogleTagManagerService,
    private readonly _sidebarV3Service: SidebarV3Service,
  ) {
    routingState.loadRouting();
    this._sidebarV3Service.isDeveloped$.pipe(takeUntil(this._destroyed$)).subscribe((isSidebarDeveloped) => {
      this.isSidebarDeveloped = isSidebarDeveloped;
    });
  }

  @HostListener('document:mouseup', ['$event']) mouseUpEvent(): void {
    timer(2000).subscribe(() => {
      this.watchForNewLinks();
    });
  }

  ngOnInit(): void {
    this._setupFastClick();
    this._checkBrowser();

    this._routerEvents.activate();

    this._setupSideBarVisibility();
    this._setupGlobalNotifications();
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
  }

  unsupportedBrowser(): void {
    const unsupportedBrowserModal = this._dialogsService.info({
      message:
        'Looks like you’re using an unsupported browser. To get the most out of Accor Data Portal, please upgrade to the latest version of Chrome, Safari or Edge.',
      confirmText: 'Got it',
    });
    const today = new Date();
    const nextWeekDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 7);
    unsupportedBrowserModal
      .afterClosed()
      .pipe(take(1), takeUntil(this._destroyed$))
      .subscribe(() => {
        this._cookieService.set(HAS_SEEN_INFO_COOKIE_NAME, 'true', nextWeekDate);
      });
  }

  isImpersonating(): boolean {
    return this._impersonateService.isImpersonating();
  }

  stopImpersonation(): void {
    this._impersonateService.stopImpersonation();
    this._sourcesService.flushCache();
  }

  closeSidebar(): void {
    this.showSidebar = false;
  }

  openSideBar(): void {
    this.showSidebar = true;
  }

  hasScrollbar(): boolean {
    return document.body.scrollHeight > window.innerHeight;
  }

  watchForNewLinks(): void {
    const windowsOpened = window.windowsOpenedGTM;

    if (windowsOpened?.length !== this.linksOpened.length) {
      for (let i = this.linksOpened.length; i < windowsOpened.length; i++) {
        const newLink = windowsOpened[i]?.arguments[0];

        if (this.isAuthPage(newLink) || this.isAuthPage(window.location.href)) {
          // Prevent the homepage auth redirection from triggering an internal link
          this.linksOpened.push(newLink);

          return;
        }

        let haveSameHosts: boolean;

        try {
          haveSameHosts = windowsOpened[i]?.window?.location?.hostname === window.location.hostname;
        } catch {
          const newUrl = new URL(newLink);
          haveSameHosts = newUrl.hostname === window.location.hostname;
        } finally {
          if (haveSameHosts) {
            const replaceDoubleSlashRegex = /[^:](\/\/)/;
            const fullLink = (window.location.href + newLink.toString()).replace(replaceDoubleSlashRegex, '/');
            this.linksOpened.push(fullLink);
            this.pushGTMInternalLink(window.location.href, fullLink);
          } else {
            this.linksOpened.push(newLink);
            this.pushGTMExternalLink(window.location.href, newLink);
          }
        }
      }
    }
  }

  isAuthPage(link: string): boolean {
    return link.includes('/auth');
  }

  pushGTMInternalLink(location: string, destination: string): void {
    this._gtmService.pushEvent({
      event: 'internal_link',
      link_location: location,
      link_destination: destination,
    });
  }

  pushGTMExternalLink(location: string, destination: string): void {
    this._gtmService.pushEvent({
      event: 'external_link',
      link_location: location,
      link_destination: destination,
    });
  }

  onActivate(): void {
    this.isAdminPage = this._router.url.includes('/admin') ?? false;
  }

  private _checkBrowser(): void {
    const isUsingUnsupportedNavigator = /msie\s|trident\/|firefox\//i.test(window.navigator.userAgent);

    if (isUsingUnsupportedNavigator) {
      const hasSeenUnsupportedNavigatorInfo = this._cookieService.get(HAS_SEEN_INFO_COOKIE_NAME);

      if (!hasSeenUnsupportedNavigatorInfo) {
        this.unsupportedBrowser();
      }
    }
  }

  private _setupSideBarVisibility(): void {
    this._sidebarV3Service.isVisible$.pipe(takeUntil(this._destroyed$)).subscribe((isSidebarVisible) => {
      this.isSidebarActive = isSidebarVisible;
    });
  }

  private _setupFastClick(): void {
    try {
      (FastClick as unknown as typeof FastClick.FastClick).attach(document.body);
    } catch (e) {
      this._logger.error('[AppComponent] FastClick is failed', e);
    }
  }

  private _setupGlobalNotifications(): void {
    const notificationConfig: IAdminNotificationEvent[] = [
      {
        name: 'accessRequestsNotification',
        event$: this._websocketsService.accessRequestsNotifications$,
        subscribeUntil$: this._destroyed$,
      },
      {
        name: 'publicationsNotification',
        event$: this._websocketsService.publicationsNotifications$,
        subscribeUntil$: this._destroyed$,
      },
    ];
    this._notificationsService.setConfiguration(notificationConfig);

    this._fetchCurrentUserNotifications();

    this._alertService.setToasterComponent(ToasterComponent);
  }

  private _fetchCurrentUserNotifications(): void {
    this._websocketsService.socketConnected$
      .pipe(
        mergeMap(() => this._notificationsService.fetchCurrentUserNotifications()),
        takeUntil(this._destroyed$),
      )
      .subscribe(() => {
        this._logger.debug('[AppComponent] Current user notifications successfully fetched');
      });
  }
}
