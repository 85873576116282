import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface IConfirmModalComponentData {
  elements: string[];
  action: string;
  kinds: string;
  warningText: string;
  warnings: string[];
}

@Component({
  selector: 'adl-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss'],
})
export class ConfirmModalComponent {
  @Input() elements: string[];
  @Input() action: string;
  @Input() kinds: string;
  @Input() warningText: string;
  @Input() warnings: string[];

  constructor(
    private readonly _activeMatModal: MatDialogRef<ConfirmModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    data: IConfirmModalComponentData,
  ) {
    if (data) {
      Object.keys(data).forEach((key) => {
        this[key] = data[key] ? data[key] : this[key];
      });
    }
  }

  confirm(): void {
    this._activeMatModal.close(true);
  }

  dismiss(): void {
    this._activeMatModal.close(false);
  }
}
