import { Inject, Injectable } from '@angular/core';
import type { CanActivate, CanLoad } from '@angular/router';
import { Router } from '@angular/router';

import { PermissionsModule } from '../permissions.module';

import { PermissionsService } from '../services/permissions.service';

import { IPermissionsOptions, PERMISSIONS_OPTIONS } from '../permissions-options';
import type { Permissions } from '../services/permissions.service';

// Guard
@Injectable({
  providedIn: PermissionsModule,
})
export class SourceOrPortalOwnerGuard implements CanActivate, CanLoad {
  // Constructor
  constructor(
    private readonly _permissionsService: PermissionsService,
    private readonly _router: Router,
    @Inject(PERMISSIONS_OPTIONS) private readonly _options: IPermissionsOptions,
  ) {}

  private _isSourceOrPortalOwner(): boolean {
    let userPermissions: Permissions;
    this._permissionsService.permissionsAdmin$.subscribe((permissions: Permissions) => (userPermissions = permissions));

    if (userPermissions && (userPermissions.sources || userPermissions.portals)) {
      return true;
    }

    this._router.navigate(this._options.errorRouterLink);

    return false;
  }

  // Methods
  canActivate(): boolean {
    return this._isSourceOrPortalOwner();
  }

  canLoad(): boolean {
    return this._isSourceOrPortalOwner();
  }
}
