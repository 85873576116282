import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { IFlatfileUploadSheetsSelectModalData } from '../../types/flatfile';

@Component({
  selector: 'dpg-flatfile-upload-sheets-select-modal',
  templateUrl: './flatfile-upload-sheets-select-modal.component.html',
  styleUrls: ['flatfile-upload-sheets-select-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FlatfileUploadSheetsSelectModalComponent {
  sheetsNamesOptions: Array<{ label: string; value: string }>;
  sheetSelected: string;

  constructor(
    private readonly _activeMatModal: MatDialogRef<FlatfileUploadSheetsSelectModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    data: IFlatfileUploadSheetsSelectModalData,
  ) {
    if (data) {
      Object.keys(data).forEach((key) => {
        this[key] = data[key] ? data[key] : this[key];
      });
    }
  }

  selectSheetName(sheetName: string): void {
    this._activeMatModal.close(sheetName);
  }

  updateSelectedSheet($event: string): void {
    this.sheetSelected = $event;
  }
}
