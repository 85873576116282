import type {
  GlossaryExpressionUnit,
  GlossaryRelatedUseCases,
  GlossaryStatus,
  GlossaryType,
  IGlossaryRelatedComponentInfo,
} from '@dataportal/accor-glossary-types';
import { Entity } from '@decahedron/entity';

export class GlossaryComponentWithNotificationInfo extends Entity {
  pk: string;
  /**
   * the Name of the domain, subDomain, term or KPI, might be duplicated
   */
  name: string;
  /**
   * The parent affiliate information (id and name)
   */
  affiliate: IGlossaryRelatedComponentInfo;
  /**
   *  The domain, subDomain, term and KPI definition/description
   */
  definition: string;
  /**
   * The type of the component
   */
  type: GlossaryType;
  /**
   * The validation status of the component
   */
  status: GlossaryStatus;
  /**
   * The list of the stewards emails
   */
  stewardsEmail: string[];
  /**
   * the list of the validators emails
   */
  validatorsEmail: string[];
  /**
   * The parent domain information (id and name)
   */
  domain: IGlossaryRelatedComponentInfo;
  /**
   * The parent subDomain information (id and name)
   */
  subDomain: IGlossaryRelatedComponentInfo;
  /**
   * The domain or subDomain comment that helps to understand the context if any
   */
  comment?: string;
  /**
   * An acronym to the term if any
   */
  acronym?: string;
  /**
   * An alternative name to the term if any
   */
  synonym?: string;
  /**
   * Some other domains information (id and name) that are related to the term or KPI if any
   */
  additionalDomains?: IGlossaryRelatedComponentInfo[];
  /**
   * Some other sub-domains information (id and name) that are related to the term or KPI if any
   */
  additionalSubDomains?: IGlossaryRelatedComponentInfo[];
  /**
   * An example of data or usecase that can be used to explain the term or KPI
   */
  example?: string;
  /**
   * This KPI required field is used to define the calculation rule if any
   */
  calculationRule?: string;
  /**
   * This KPI required field is used to define the expression unitfrom a list of predefined rules
   */
  expressionUnit?: GlossaryExpressionUnit;
  /**
   * A selection of usecases made from a predifined list
   */
  relatedUseCases?: GlossaryRelatedUseCases[];
  /**
   * The name of the related resource if any
   */
  resourceName?: string;
  /**
   * The link to the related resource if any
   */
  resourceLink?: string;
  /**
   * Some terms information (id and name) that are related to the term or KPI if any
   */
  relatedItems?: IGlossaryRelatedComponentInfo[];
  /**
   * "glossary|components"
   */
  sk: string;
  /**
   * "glossary|affiliates|$AFFILIATE_ID|components"
   */
  ak: string;
  /**
   * Record the user that have seen the current notification
   */
  seenBy?: string[];
  /**
   * Record the previous user that has updated the status of the component
   */
  previousStatusUpdatedBy?: string;
  /**
   * Record the previous status of the component
   */
  previousStatus?: string;
  /**
   * The creation date of the component
   */
  createdAt?: string;
  /**
   * The creator id
   */
  createdBy?: string;
  /**
   * The creator name
   */
  createdByName?: string;
  /**
   * The last update date
   */
  updatedAt?: string;
  /**
   * The id of the last contributor
   */
  updatedBy?: string;
  /**
   * The name of the last contributor
   */
  updatedByName?: string;
  /**
   * The soft delete status
   */
  archived?: boolean;

  /**
   * Boolean to know if the current user has seen the notification
   */
  hasCurrentUserSeenNotification?: boolean;

  constructor(data: GlossaryComponentWithNotificationInfo) {
    super();
  }

  fromJson(data: GlossaryComponentWithNotificationInfo): GlossaryComponentWithNotificationInfo {
    super.fromJson(data);
    this.pk = data.pk;
    this.name = data.name;
    this.affiliate = data.affiliate;
    this.definition = data.definition;
    this.type = data.type;
    this.status = data.status;
    this.stewardsEmail = data.stewardsEmail;
    this.validatorsEmail = data.validatorsEmail;
    this.domain = data.domain;
    this.subDomain = data.subDomain;
    this.comment = data.comment;
    this.acronym = data.acronym;
    this.synonym = data.synonym;
    this.additionalDomains = data.additionalDomains;
    this.additionalSubDomains = data.additionalSubDomains;
    this.example = data.example;
    this.calculationRule = data.calculationRule;
    this.expressionUnit = data.expressionUnit;
    this.relatedUseCases = data.relatedUseCases;
    this.resourceName = data.resourceName;
    this.resourceLink = data.resourceLink;
    this.relatedItems = data.relatedItems;
    this.sk = data.sk;
    this.ak = data.ak;
    this.seenBy = data.seenBy;
    this.previousStatusUpdatedBy = data.previousStatusUpdatedBy;
    this.previousStatus = data.previousStatus;
    this.createdAt = data.createdAt;
    this.createdBy = data.createdBy;
    this.createdByName = data.createdByName;
    this.updatedAt = data.updatedAt;
    this.updatedBy = data.updatedBy;
    this.updatedByName = data.updatedByName;
    this.archived = data.archived;
    this.hasCurrentUserSeenNotification = data.hasCurrentUserSeenNotification;

    return this;
  }
}
