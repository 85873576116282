import type { HttpErrorResponse } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, from, merge, Observable, of, Subject } from 'rxjs';
import { delay, filter, first, map, mergeAll, mergeMap, takeUntil, tap } from 'rxjs/operators';
import type { IDatalakeObject, IDatalakeObjectAPI } from '@dataportal/datalake-parsing';
import { Logger } from '@dataportal/front-shared';
import { ImpersonateService } from '@dataportal/users';
import { v4 as uuid } from 'uuid';

import { DatalakeBucketsCacheService } from './buckets-cache.service';
import { DatalakeApiService } from './datalake-api.service';
import { DatalakeObjectsCacheService } from './objects-cache.service';

import type {
  IBucket,
  IDatalakeAPIOptions,
  IDatalakeDeleteObjectEvent,
  IDatalakeObjectWithPermission,
  IDatalakeStoreOptions,
  IDatalakeTarget,
  IDatalakeUserPermission,
  IDatalakeWithPermissionListResponse,
  IObjectsStoreLine,
} from '../entities/datalake';
import { areObjectsEqual, normalizedPath } from '../entities/datalake';
import type { IDatalakeAPIUploadResponse } from './datalake-api.service';

type ObjectsStore = Map<string, BehaviorSubject<IObjectsStoreLine>>;

interface IFetchPageRequest {
  ticketId: string;
  bucketName: string;
  options: IDatalakeStoreOptions;
}

class FetchPageError extends Error {
  constructor(readonly apiOptions: IDatalakeAPIOptions, readonly httpError: HttpErrorResponse) {
    super();
  }
}

const DEFAULT_LINE = {
  isThereDataRemaining: true,
  objects: [],
  userPermissions: [],
  metadata: [],
  allChecked: false,
};
/**
 * Centralized service for datalake state management
 * This service uses indexedDB cache to improve performances. It also uses
 * a FIFO queue to avoid too many requests overwhelming the JS main thread
 * It exposes observables to consumers that hold the objects state for the different
 * bucket/path/provider/tenant.
 * When fresh data is available in cache it uses it instead fetching again from back-end
 * It also manage pagination and serve the result page per page for progressive display
 */
@Injectable({
  providedIn: 'root',
})
export class DatalakeStoreService {
  static READ_WRITE_PERMISSION = { canRead: true, canWrite: true };

  private readonly _objectsStore: ObjectsStore = new Map<string, BehaviorSubject<IObjectsStoreLine>>();
  private readonly _subscriptions = new Map<
    string,
    { abort$: Subject<void>; activeSubscriptions: Set<Subject<void>> }
  >();

  /**
   * Used to avoid loading objects if they already are being fetched
   * @private
   */
  private _fetchPageRequestsQueue$ = new Subject<IFetchPageRequest>();

  /**
   * Used to avoid memory leaks (triggered when an all cache invalidation is requested
   * @private
   */
  private readonly _hasToInvalidateAllCache$ = new Subject<void>();

  /**
   * Used to avoid loading buckets if they already are being fetched
   * @private
   */
  private _isLoadingBuckets = false;
  private readonly _bucketsStore$ = new BehaviorSubject<IBucket[]>([]);

  private static _objectToParentOptions(options: IDatalakeObjectAPI): IDatalakeStoreOptions {
    return {
      path: options.path,
      provider: options.provider,
      tenant: options.tenant,
    };
  }

  private static _objectToOptions(object: IDatalakeObject): IDatalakeAPIOptions {
    return {
      provider: object.provider,
      tenant: object.tenant,
      path: !object.name ? object.path : `${object.path}/${object.name}`.replace(/\/+/, '/').replace(/^\//, ''),
    };
  }

  constructor(
    private readonly _http: HttpClient,
    private readonly _datalakeApiService: DatalakeApiService,
    private readonly _datalakeBucketsCacheService: DatalakeBucketsCacheService,
    private readonly _datalakeObjectsCacheService: DatalakeObjectsCacheService,
    private readonly _impersonateService: ImpersonateService,
    private readonly _logger: Logger,
    private readonly _router: Router,
  ) {
    this._flushFetchPageRequestsQueue();
    merge(
      this._impersonateService.impersonatedUser$.pipe(filter((impersonatedUser) => !!impersonatedUser)),
      this._impersonateService.impersonationStopped$,
    ).subscribe(async () => await this._flushCache());
  }

  private async _flushCache() {
    await this._datalakeObjectsCacheService.invalidateCompleteCache();
    location.reload();
  }

  /**
   * Get all buckets
   */
  listBuckets(): Observable<Array<IBucket>> {
    this._logger.debug('[DatalakeStoreService] Listing buckets');

    if (!this._isLoadingBuckets) {
      return new Observable<Array<IBucket>>((obs) => {
        this._isLoadingBuckets = true;
        this._logger.debug('[DatalakeStoreService] Trying to fetch buckets from cache');

        const fetchFromBackend = () => {
          this._logger.debug('[DatalakeStoreService] Buckets need to be fetched from back-end');
          this._datalakeApiService.listBuckets().subscribe({
            next: async (buckets) => {
              await this._datalakeBucketsCacheService.setBucketList(buckets);
              this._bucketsStore$.next(buckets);
              this._isLoadingBuckets = false;
              this._logger.debug('[DatalakeStoreService] Buckets successfully fetched from back-end');
              obs.next(buckets);

              return obs.complete();
            },
            error: (err: unknown) => {
              this._logger.error('[DatalakeStoreService] Error fetching buckets from backend', err);
              this._isLoadingBuckets = false;
              this._bucketsStore$.next([]);
              obs.next([]);

              return obs.complete();
            },
          });
        };

        this._datalakeBucketsCacheService
          .getBucketList()
          .then((buckets) => {
            if (buckets) {
              this._bucketsStore$.next(buckets);
              this._isLoadingBuckets = false;
              this._logger.debug('[DatalakeStoreService] Buckets successfully fetched from cache');
              obs.next(buckets);

              return obs.complete();
            }

            fetchFromBackend();
          })
          .catch((err) => {
            this._logger.debug('[DatalakeStoreService] Error reading buckets from cache', err);
            fetchFromBackend();
          });
      });
    } else {
      this._logger.debug('[DatalakeStoreService] Already listing buckets');

      return this._bucketsStore$.asObservable().pipe(first((buckets) => !!buckets.length));
    }
  }

  //
  findBucket(bucketName: string): Observable<IBucket> {
    const buckets = this._bucketsStore$.getValue();

    if (buckets && buckets.length > 0) {
      return of(buckets.find((b) => b.name === bucketName));
    }

    return this.listBuckets().pipe(map((buckets) => buckets.find((b) => b.name === bucketName)));
  }

  async invalidateCache(bucketName: string, options: IDatalakeStoreOptions): Promise<void> {
    await this._datalakeObjectsCacheService.invalidate(bucketName, options);
    const datalakeKey = DatalakeObjectsCacheService.key(bucketName, options);
    const existingStore = this._objectsStore.get(datalakeKey);
    const newEmptyStore = {
      objects: [],
      userPermissions: [],
      isThereDataRemaining: true,
      metadata: [],
      allChecked: false,
    };

    if (existingStore) {
      existingStore.next(newEmptyStore);
    } else {
      this._objectsStore.set(datalakeKey, new BehaviorSubject<IObjectsStoreLine>(newEmptyStore));
    }

    this.listAll(bucketName, options).objects$.subscribe();
  }

  getObjectStore(bucketName: string, options: IDatalakeStoreOptions): Observable<IObjectsStoreLine> {
    const datalakeKey = DatalakeObjectsCacheService.key(bucketName, options);
    this._logger.debug(`[DatalakeStoreService] Fetching object store for ${datalakeKey}`);
    const existingStore = this._objectsStore.get(datalakeKey);

    if (existingStore) {
      this._logger.debug(`[DatalakeStoreService] Existing object store for ${datalakeKey}`);

      return existingStore.asObservable();
    }

    this._logger.debug(`[DatalakeStoreService] Create new object store for ${datalakeKey}`);
    this._objectsStore.set(datalakeKey, new BehaviorSubject<IObjectsStoreLine>(DEFAULT_LINE));

    return this._objectsStore.get(datalakeKey).asObservable().pipe(takeUntil(this._hasToInvalidateAllCache$));
  }

  private async _updateCache(
    response: IDatalakeWithPermissionListResponse,
    bucketName: string,
    options: IDatalakeStoreOptions,
  ): Promise<IObjectsStoreLine> {
    return this._datalakeObjectsCacheService.update(response, bucketName, options);
  }

  private _emitLine(bucketName: string, options: IDatalakeStoreOptions, line: IObjectsStoreLine): void {
    const datalakeKey = DatalakeObjectsCacheService.key(bucketName, options);
    const existingStore = this._objectsStore.get(datalakeKey);

    if (existingStore) {
      existingStore.next(line);
    } else {
      this._objectsStore.set(datalakeKey, new BehaviorSubject<IObjectsStoreLine>(line));
    }
  }

  private async _renameInCache(bucketName: string, object: IDatalakeObjectAPI, newObjectName: string): Promise<void> {
    const parentFolderOptions = DatalakeStoreService._objectToParentOptions(object);
    const datalakeKey = DatalakeObjectsCacheService.key(bucketName, parentFolderOptions);
    const updatedLine = await this._datalakeObjectsCacheService.renameObject(
      bucketName,
      parentFolderOptions,
      object,
      newObjectName,
    );
    const relatedStore = this._objectsStore.get(datalakeKey);

    if (relatedStore && updatedLine) {
      relatedStore.next(updatedLine);
    }
  }

  private async _appendInCache(
    bucketName: string,
    object: IDatalakeObjectAPI,
    userPermission: IDatalakeUserPermission,
  ): Promise<void> {
    const parentFolderOptions = DatalakeStoreService._objectToParentOptions(object);
    const updatedLine = await this._datalakeObjectsCacheService.append(
      bucketName,
      parentFolderOptions,
      object,
      userPermission,
    );
    const datalakeKey = DatalakeObjectsCacheService.key(bucketName, parentFolderOptions);
    const relatedStore = this._objectsStore.get(datalakeKey);

    if (updatedLine && relatedStore) {
      // DOUBT 549
      relatedStore.next(updatedLine);
    }
  }

  private async _removeFromCache(objects: Array<IDatalakeObject>): Promise<void> {
    const objectsGroupedByCacheKey = new Map<string, Array<IDatalakeObject>>();

    for (const object of objects) {
      const parentFolderOptions = DatalakeStoreService._objectToParentOptions(object);
      const cacheKey = DatalakeObjectsCacheService.key(object.bucket, parentFolderOptions);

      if (objectsGroupedByCacheKey.has(cacheKey)) {
        objectsGroupedByCacheKey.get(cacheKey).push(object);
      } else {
        objectsGroupedByCacheKey.set(cacheKey, [object]);
      }
    }

    for (const [key, objectsList] of objectsGroupedByCacheKey) {
      const updatedLine = await this._datalakeObjectsCacheService.removeObjects(key, objectsList);
      const relatedStore = this._objectsStore.get(key);

      if (updatedLine && relatedStore) {
        relatedStore.next(updatedLine);
      }
    }
  }

  private async _overrideInCache(
    bucketName: string,
    object: IDatalakeObjectAPI,
    userPermission: IDatalakeUserPermission,
  ): Promise<void> {
    const parentFolderOptions = DatalakeStoreService._objectToParentOptions(object);
    const updatedLine = await this._datalakeObjectsCacheService.override(
      bucketName,
      parentFolderOptions,
      object,
      userPermission,
    );
    const datalakeKey = DatalakeObjectsCacheService.key(bucketName, parentFolderOptions);
    const relatedStore = this._objectsStore.get(datalakeKey);

    if (updatedLine && relatedStore) {
      relatedStore.next(updatedLine);
    }
  }

  /**
   * Create a new folder
   * @param bucketName
   * @param options
   * @param folderName
   */
  createFolder(bucketName: string, options: IDatalakeStoreOptions, folderName: string): Observable<void> {
    return this._datalakeApiService.createFolder(bucketName, options, folderName).pipe(
      tap(async () => {
        const createdFolder: IDatalakeObjectAPI = {
          name: folderName,
          path: options.path,
          provider: options.provider,
          tenant: options.tenant,
          type: 'folder',
          lastModified: new Date().toString(),
        };
        // update in-memory value and indexedDB cache
        await this._appendInCache(bucketName, createdFolder, DatalakeStoreService.READ_WRITE_PERMISSION);
      }),
    );
  }

  /**
   * Delete an object
   * @param objects
   */
  deleteObjects(objects: IDatalakeObject[]): Observable<IDatalakeDeleteObjectEvent> {
    const MAX_CONCURRENCY = 4; // items per chunk
    const chunks = objects.reduce((acc, object, index) => {
      const chunkIndex = Math.floor(index / MAX_CONCURRENCY);

      if (!acc[chunkIndex]) {
        acc[chunkIndex] = [];
      }

      acc[chunkIndex].push(object);

      return acc;
    }, []);
    const batchProcessing$ = chunks.map((chunk) => {
      const succeeded = new Set<IDatalakeObject>();
      const deleteRequests$: Array<Observable<IDatalakeDeleteObjectEvent>> = chunk.map(
        (object) =>
          new Observable<IDatalakeDeleteObjectEvent>((obs) => {
            obs.next({ object, status: 'started' });
            const options = DatalakeStoreService._objectToOptions(object);
            this._datalakeApiService
              .deleteObject(object.bucket, options)
              .pipe(
                delay(100),
                map(() => {
                  succeeded.add(object);

                  return object;
                }),
              )
              .subscribe({
                next: (object) => obs.next({ object, status: 'succeeded' }),
                error: () => obs.next({ object, status: 'failed' }),
                complete: () => obs.complete(),
              });
          }),
      );

      return new Observable<IDatalakeDeleteObjectEvent>((obs) => {
        from(deleteRequests$)
          .pipe(mergeAll(MAX_CONCURRENCY))
          .subscribe({
            next: (evt) => obs.next(evt),
            error: (err: unknown) => obs.error(err),
            complete: async () => {
              try {
                await this._removeFromCache([...succeeded]);
              } finally {
                obs.complete();
              }
            },
          });
      });
    });

    return from(batchProcessing$).pipe(mergeAll(1));
  }

  /**
   * Rename a file
   */
  renameObject(bucketName: string, object: IDatalakeObject, newObjectName: string) {
    const options = DatalakeStoreService._objectToOptions(object);

    return this._datalakeApiService.renameObject(bucketName, options, newObjectName).pipe(
      tap(async () => {
        // update in-memory value and indexedDB cache
        await this._renameInCache(bucketName, object, newObjectName);
      }),
    );
  }

  /**
   * Generate upload URL
   * @param bucketName
   * @param options
   * @param fileName
   * @param metadata
   */
  generateUploadUrl(
    bucketName: string,
    options: IDatalakeAPIOptions,
    fileName: string,
    metadata?: Record<string, unknown>,
  ): Observable<IDatalakeAPIUploadResponse> {
    return this._datalakeApiService.generateUploadURL(bucketName, options, fileName, metadata);
  }

  /**
   * Upload an object on a provided signed URl
   * @param bucketName
   * @param options
   * @param file
   * @param fileName
   */
  async postUploadObject(
    bucketName: string,
    options: IDatalakeStoreOptions,
    file: File | Blob,
    fileName?: string,
  ): Promise<void> {
    let finalName = fileName || (file as File)?.name;

    const fileNameWithPath = (file as File)?.name;
    const splitFilePath = fileNameWithPath.split('/');

    if (splitFilePath.length > 1) {
      const createdFolder: IDatalakeObjectAPI = {
        name: splitFilePath[0],
        path: options.path,
        provider: options.provider,
        tenant: options.tenant,
        type: 'folder',
        lastModified: new Date().toString(),
      };
      await this._appendInCache(bucketName, createdFolder, DatalakeStoreService.READ_WRITE_PERMISSION);

      finalName = splitFilePath.pop();
      const fileAdditionalPath = splitFilePath.join('/');

      if (finalName && fileAdditionalPath) {
        options.path = options.path.concat(`/${fileAdditionalPath}`);
      }
    }

    const uploadedFile: IDatalakeObjectAPI = {
      name: finalName,
      path: options.path,
      provider: options.provider,
      tenant: options.tenant,
      type: 'file',
      lastModified: new Date().toString(),
      size: file.size,
      checked: true,
    };
    const setMetadataOptions = {
      ...options,
      path: options.path + '/' + finalName,
    };
    const setOwnerMetadata$ = await this._datalakeApiService
      .setOwnerMetadata(bucketName, setMetadataOptions)
      .toPromise();
    // In case of override
    await this._overrideInCache(bucketName, uploadedFile, DatalakeStoreService.READ_WRITE_PERMISSION);

    return setOwnerMetadata$;
  }

  /**
   * Move an object
   * @param bucketName
   * @param object
   * @param target
   */
  moveObject(bucketName: string, object: IDatalakeObject, target: IDatalakeTarget): Observable<void> {
    const options = DatalakeStoreService._objectToOptions(object);

    return this._datalakeApiService.moveObject(bucketName, options, target).pipe(
      tap(async () => {
        const objectPostMove: IDatalakeObject = {
          ...object,
          bucket: undefined,
          path: target.path,
          checked: false,
        };
        // In case of override
        await this._overrideInCache(target.bucket, objectPostMove, DatalakeStoreService.READ_WRITE_PERMISSION);
        await this._removeFromCache([object]);
      }),
    );
  }

  /**
   * Copy an object
   * @param bucketName
   * @param object
   * @param target
   */
  copyObject(bucketName: string, object: IDatalakeObject, target: IDatalakeTarget): Observable<void> {
    const options = DatalakeStoreService._objectToOptions(object);

    return this._datalakeApiService.copyObject(bucketName, options, target).pipe(
      tap(async () => {
        delete object.bucket;
        const objectPostCopy: IDatalakeObjectAPI = {
          ...object,
          path: target.path,
          checked: false,
        };
        // In case of override
        await this._overrideInCache(target.bucket, objectPostCopy, DatalakeStoreService.READ_WRITE_PERMISSION);
      }),
    );
  }

  private _registerSubscription(datalakeKey: string): { abort$: Subject<void>; subscription$: Subject<void> } {
    const subscription$ = new Subject<void>();
    subscription$.pipe(first()).subscribe({
      next: () => {
        const activeSubscriptions = this._subscriptions.get(datalakeKey)?.activeSubscriptions;

        if (activeSubscriptions) {
          activeSubscriptions.delete(subscription$);

          if (activeSubscriptions.size < 1) {
            this._subscriptions.get(datalakeKey).abort$.next();
          }
        }
      },
    });
    const registryEntry = this._subscriptions.get(datalakeKey);

    if (registryEntry) {
      registryEntry.activeSubscriptions.add(subscription$);

      return { subscription$, abort$: registryEntry.abort$ };
    } else {
      const _abort$ = new Subject<void>();
      const newSubscription = {
        abort$: _abort$,
        activeSubscriptions: new Set<Subject<void>>(),
      };
      this._subscriptions.set(datalakeKey, newSubscription);

      return { subscription$, abort$: _abort$ };
    }
  }

  listAll(
    bucketName: string,
    options: IDatalakeStoreOptions,
  ): { objects$: Observable<IObjectsStoreLine>; abort$: Subject<void> } {
    const datalakeKey = DatalakeObjectsCacheService.key(bucketName, options);
    this._logger.debug('[DatalakeStoreService]', '(listAll)', 'Request to list all objects', datalakeKey);
    const existingStore = this._objectsStore.get(datalakeKey);
    const activeSubscriptions = this._subscriptions.get(datalakeKey);

    if (existingStore && activeSubscriptions?.activeSubscriptions.size) {
      this._logger.debug('[DatalakeStoreService]', '(listAll)', 'Already has active subscriptions', datalakeKey);
      this._logger.debug('[DatalakeStoreService]', '(listAll)', 'Registering new subscription', datalakeKey);
      const { subscription$ } = this._registerSubscription(datalakeKey);

      return { abort$: subscription$, objects$: existingStore };
    } else {
      this._logger.debug('[DatalakeStoreService]', '(listAll)', 'Has no active subscription', datalakeKey);
      this.fetchPage(bucketName, options);
      const store$ = this.getObjectStore(bucketName, options);
      const { subscription$, abort$ } = this._registerSubscription(datalakeKey);

      const onPageReceived = (line: IObjectsStoreLine) => {
        if (line.isThereDataRemaining) {
          this._logger.debug('[DatalakeStoreService]', '(listAll)', 'Page received for path', datalakeKey, line);
          this._logger.debug(
            '[DatalakeStoreService]',
            '(listAll)',
            'Data remaining for path, fetching next page',
            datalakeKey,
          );
          this.fetchPage(bucketName, options);
        } else {
          this._logger.debug('[DatalakeStoreService]', '(listAll)', 'All pages successfully fetched', datalakeKey);
        }
      };

      const onError = (err: unknown) => {
        this._logger.error('[DatalakeStoreService]', '(listAll)', 'Error listing objects for path', datalakeKey, err);
      };

      store$.pipe(takeUntil(abort$)).subscribe({
        next: onPageReceived.bind(this),
        error: onError.bind(this),
      });

      return { objects$: store$, abort$: subscription$ };
    }
  }

  getCurrentlyListedFiles(bucketName: string, options: IDatalakeStoreOptions): IObjectsStoreLine {
    const datalakeKey = DatalakeObjectsCacheService.key(bucketName, options);

    return this._objectsStore.get(datalakeKey)?.getValue() || DEFAULT_LINE;
  }

  fetchPage(bucketName: string, options: IDatalakeStoreOptions): void {
    this._fetchPageRequestsQueue$.next({ ticketId: uuid(), bucketName, options });
  }

  private _flushFetchPageRequestsQueue() {
    this._logger.debug('[DatalakeStoreService]', 'Flushing page request queue');
    this._fetchPageRequestsQueue$ = new Subject<IFetchPageRequest>();
    this._processFetchPageRequestsQueue();
  }

  private _processFetchPageRequestsQueue(): void {
    this._fetchPageRequestsQueue$
      .pipe(mergeMap((request) => this._processFetchPageRequest(request).pipe(delay(100)), 1))
      .subscribe({
        error: (err: unknown) => {
          this._goToErrorPage(
            err instanceof FetchPageError ? err.apiOptions : undefined,
            err instanceof FetchPageError ? err.httpError : undefined,
          );
        },
      });
  }

  private _processFetchPageRequest(request: IFetchPageRequest): Observable<IObjectsStoreLine> {
    return new Observable<IObjectsStoreLine>((obs) => {
      const { bucketName, options, ticketId } = request;
      const datalakeKey = DatalakeObjectsCacheService.key(bucketName, options);
      const requestInfo = { datalakeKey, ticketId };
      this._logger.debug('[DatalakeStoreService]', 'Fetching next page', requestInfo);

      const fetchPageFromBackEnd = (apiOptions: IDatalakeAPIOptions): void => {
        this._datalakeApiService.listObjects(bucketName, apiOptions).subscribe({
          next: async (response) => {
            response.resources = response.resources.filter((resource) => !!resource.name);
            this._logger.debug(
              '[DatalakeStoreService]',
              'Data successfully fetched from back-end, storing it in cache',
              requestInfo,
            );
            const objectLine = await this._updateCache(response, bucketName, options);
            this._emitLine(bucketName, options, objectLine);
            obs.next(objectLine);
            obs.complete();
          },
          error: (err: unknown) => {
            this._logger.error('[DatalakeStoreService]', 'Error fetching page from back-end', requestInfo, err);
            obs.error(new FetchPageError({ ...apiOptions, bucket: bucketName }, err as HttpErrorResponse));
          },
        });
      };

      const fetchFirstPageFromBackend = (): void => {
        fetchPageFromBackEnd({
          path: normalizedPath(options.path),
          provider: options.provider,
          tenant: options.tenant,
          byUser: options.byUser,
        });
      };

      this._datalakeObjectsCacheService
        .get(bucketName, options)
        .then((cachedLine) => {
          if (cachedLine) {
            this._logger.debug('[DatalakeStoreService]', 'Cached hit for page', requestInfo);

            if (!cachedLine.isThereDataRemaining) {
              this._logger.debug('[DatalakeStoreService]', 'No more page to fetch', requestInfo);
              this._emitLine(bucketName, options, cachedLine);

              return obs.complete();
            }

            this._logger.debug('[DatalakeStoreService]', 'Fetching next page', requestInfo);
            fetchPageFromBackEnd({
              path: normalizedPath(options.path),
              provider: options.provider,
              tenant: options.tenant,
              byUser: options.byUser,
              tokens: cachedLine.metadata,
            });
          } else {
            this._logger.debug(
              '[DatalakeStoreService]',
              'Cache miss for requested options, fetching first page from back-end',
              requestInfo,
            );
            fetchFirstPageFromBackend();
          }
        })
        .catch((err) => {
          this._logger.warn('[DatalakeStoreService]', 'Error reading from cache', requestInfo, err);
          fetchFirstPageFromBackend();
        });
    });
  }

  // Methods
  private _goToErrorPage(options?: IDatalakeAPIOptions, httpError?: HttpErrorResponse): void {
    this._logger.info('[DatalakeStoreService] Navigating to datalake error page');
    this._router
      .navigate(['datalake/error'], {
        queryParams: {
          errorStatus: httpError?.status,
          path: options?.path,
          provider: options?.provider,
          tenant: options?.tenant,
          bucket: options?.bucket,
        },
      })
      .then(() => this._logger.info('Navigated to', '/datalake/error'));
  }

  checkOne(bucketName: string, options: IDatalakeStoreOptions, toToggle: IDatalakeObjectWithPermission): void {
    const datalakeKey = DatalakeObjectsCacheService.key(bucketName, options);
    const relatedStore$ = this._objectsStore.get(datalakeKey);
    this._logger.debug('[DatalakeStoreService]', '(check-one)', 'Checking/unchecking one object in', datalakeKey);

    if (relatedStore$) {
      this._logger.debug('[DatalakeStoreService]', '(check-one)', 'Related store found');
      const storedValue = relatedStore$.getValue();
      const isStillLoading = storedValue.isThereDataRemaining;
      this._logger.debug('[DatalakeStoreService]', '(check-one)', { isStillLoading });
      const updatedObjects = storedValue.objects.map((o) => {
        if (areObjectsEqual(o, toToggle)) {
          return { ...o, checked: !o.checked };
        }

        return { ...o };
      });
      const allChecked = isStillLoading ? false : updatedObjects.every((o) => o.checked);
      relatedStore$.next({
        ...storedValue,
        objects: updatedObjects,
        allChecked,
      });
    }
  }

  checkAll(bucketName: string, options: IDatalakeStoreOptions): void {
    const datalakeKey = DatalakeObjectsCacheService.key(bucketName, options);
    this._logger.debug('[DatalakeStoreService]', '(check-all)', 'Checking/unchecking all objects in', datalakeKey);
    const relatedStore$ = this._objectsStore.get(datalakeKey);
    const isStillLoading = relatedStore$?.getValue().isThereDataRemaining;

    if (relatedStore$) {
      this._logger.debug('[DatalakeStoreService]', '(check-all)', 'Related store found');
    }

    this._logger.debug('[DatalakeStoreService]', '(check-all)', { isStillLoading });

    if (isStillLoading) {
      this._logger.warn('[DatalakeStoreService]', '(check-all)', 'Cannot check all, still loading');

      return;
    }

    const storedValue = relatedStore$.getValue();
    const allChecked = storedValue.objects.every((o) => o.checked);
    this._logger.debug('[DatalakeStoreService]', '(check-all)', 'Are already all checked', allChecked);
    this._logger.debug('[DatalakeStoreService]', '(check-all)', allChecked ? 'Unchecking all' : 'Checking all');
    const updatedObjects = storedValue.objects.map((o) => ({
      ...o,
      checked: !allChecked,
    }));
    relatedStore$.next({
      ...storedValue,
      objects: updatedObjects,
      allChecked: !allChecked,
    });
  }
}
