import { Injectable } from '@angular/core';
import type { ComponentType, IndividualConfig } from 'ngx-toastr';
import { ToastrService } from 'ngx-toastr';

// Service
@Injectable({
  providedIn: 'root',
})
export class AlertService {
  // Attributes
  private readonly _defaultOptions: Partial<IndividualConfig> = {
    messageClass: 'wb-all',
  };
  toastComponent: ComponentType<unknown>;

  private _options: Partial<IndividualConfig> = {};

  // Constructor
  constructor(private readonly _toastr: ToastrService) {
    this._options = { ...this._defaultOptions };
  }

  // Methods
  private _clear(): void {
    this._setOptions();
    this._toastr.clear();
    const container = document.querySelector('.content-app');

    if (container) {
      container.scrollTop = 0;
    }
  }

  private _setOptions() {
    this._options = { ...this._defaultOptions };

    if (this.toastComponent) {
      this._options.toastComponent = this.toastComponent;
    }
  }

  setToasterComponent(component: ComponentType<unknown>) {
    this.toastComponent = component;
    this._setOptions();
  }

  success(message: string, clear = true, timeOut = 5000, title = 'Success'): void {
    if (clear) {
      this._clear();
    }

    this._options.timeOut = timeOut;
    this._toastr.success(message, title || 'Success', this._options);
  }

  error(message: string, requestId: string = null, clear = true, timeOut = 0, title = 'Error'): void {
    if (clear) {
      this._clear();
    }

    this._options.timeOut = timeOut;

    if (requestId) {
      message = message + '\nRequest id: ' + requestId;
    }

    this._toastr.error(message, title || 'Error', this._options);
  }

  info(message: string, clear = true, timeOut = 5000, title = 'Info'): void {
    if (clear) {
      this._clear();
    }

    this._options.timeOut = timeOut;
    this._toastr.info(message, title, this._options);
  }

  warning(message: string, clear = true, timeOut = 5000, title = 'Warning'): void {
    if (clear) {
      this._clear();
    }

    this._options.timeOut = timeOut;
    this._toastr.warning(message, 'Warning', this._options);
  }

  show(type: string, message: string, requestId: string = null): void {
    switch (type) {
      case 'success':
        this.success(message);
        break;
      case 'info':
        this.info(message);
        break;
      case 'warning':
        this.warning(message);
        break;
      case 'error':
      default:
        this.error(message, requestId);
        break;
    }
  }
}
