<div class="flex flex-row justify-start items-center w-full pt-5 pb-5">
  <adl-title
    class="modal-title flex flex-row justify-start items-center text-center w-7/12 pr-20"
    type="modal"
    color="#ffffff"
  >
    <div class="flex flex-row justify-start items-center text-center w-full">
      <adl-image class="mr-6" src="assets/dpg/imgs/icons/guardian-logo.svg" shape="square" size="50px"></adl-image>
      <div class="flex flex-col justify-start items-start w-full h-full">
        <span class="text-left w-full"> Guardian Specification <ng-container *ngIf="checkId">n° {{checkId}}</ng-container></span>
        <adl-text
          class="subtitle-path text-left leading-none whitespace-normal break-all w-full"
          type="hint"
          color="white"
          >{{ this.datasetName }}</adl-text
        >
      </div>
    </div>
  </adl-title>
  <adl-stepper
    *ngIf="hasStepper"
    class="modal-steps flex flex-row justify-start items-center w-5/12"
    [steps]="steps"
    [activeStep]="stepLabels[currentStep - 1]"
    [useCurrentActive]="true"
    [circleSize]="'small'"
  >
  </adl-stepper>
</div>
