<meta name="referrer" content="no-referrer-when-downgrade" />
<div class="content-app">
  <div
    [class]="!isAdminPage ? 'inner-app' : ''"
    [ngClass]="{ 'sidebar-developed': isSidebarDeveloped, 'sidebar-hidden': !isSidebarActive }"
  >
    <router-outlet name="layout" (activate)="onActivate()"></router-outlet>
    <router-outlet></router-outlet>
    <router-outlet name="overlay"></router-outlet>
  </div>
</div>

<button
  *ngIf="isImpersonating()"
  (click)="stopImpersonation()"
  type="button"
  class="btn btn-outline btn-lg btn-orange v-bottom btn-effect btn-impersonation"
>
  Stop view as
</button>
