import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import type { Observable } from 'rxjs';
import { BehaviorSubject, forkJoin, of, ReplaySubject, throwError } from 'rxjs';
import { catchError, flatMap, last, map, mergeMap, tap } from 'rxjs/operators';
import { GoogleTagManagerService } from '@dataportal/analytics';
import { ApiService } from '@dataportal/front-api';
import { AlertService, Logger } from '@dataportal/front-shared';
import { EntityBuilder } from '@decahedron/entity';

import type { ITool } from '../entities/tool';
import { Tool } from '../entities/tool';
import type { IRawToolCategory, IToolCategory } from '../entities/tool-category';
import { ToolCategory } from '../entities/tool-category';

@Injectable({
  providedIn: 'root',
})
export class ToolsService {
  private readonly _acceptedFileTypes: string[] = ['image/svg+xml'];

  private readonly _favoriteTools$ = new BehaviorSubject<Array<string>>([]);
  favoriteTools$ = this._favoriteTools$.asObservable();
  private readonly _tools$ = new BehaviorSubject<Array<Tool>>([]);
  tools$ = this._tools$.asObservable();
  private readonly _categories$ = new BehaviorSubject<Array<ToolCategory>>([]);
  categories$ = this._categories$.asObservable();
  private readonly _logoAvailable$ = new ReplaySubject<void>();
  logoAvailable$ = this._logoAvailable$.asObservable();

  constructor(
    private readonly _apiService: ApiService,
    private readonly _http: HttpClient,
    private readonly _logger: Logger,
    private readonly _sanitizer: DomSanitizer,
    private readonly _alert: AlertService,
    private readonly _gtmService: GoogleTagManagerService,
  ) {
    this._fetchTools();
  }

  downloadLogo(downloadUrl: string): Observable<string> {
    return this._http.get(downloadUrl, { responseType: 'text' }).pipe(
      tap(
        // Log the result or error
        (data) => {
          this._logger.debug('[ToolsService] Logo downloaded');

          return data;
        },
        (error: unknown) => {
          this._logger.error('[ToolsService] Error in downloading logo', error);

          return throwError(error);
        },
      ),
    );
  }

  getLogo(id: string): Observable<string> {
    if (!id) {
      return of(null);
    }

    this._logger.debug('[ToolsService] Requesting logo signed URL');

    return this._apiService
      .get<string>(`v4/tools/logos/${encodeURIComponent(id)}`, {
        errorHandling: { level: 'silent' },
      })
      .pipe(
        tap(
          () => this._logger.debug('[ToolsService] Logo download signed URL generated'),
          catchError((err: unknown) => {
            this._logger.error('[ToolsService] Error getting logo download signed URL', err);

            return throwError(err);
          }),
        ),
      );
  }

  listFavorites(userId: string): Observable<Array<string>> {
    return this._apiService.get<Array<string>>(`v4/tools/favorites/${userId}`).pipe(
      tap((favorites) => {
        this._favoriteTools$.next(favorites);
      }),
    );
  }

  toggleFavorites(
    userId: string,
    toolId: string,
    toolSubCategoryId: string,
    isToolAService?: boolean,
  ): Observable<void> {
    if (!this._favoriteTools$.getValue().includes(toolId) && this._favoriteTools$.getValue().length === 3) {
      this._alert.info('You cannot set more than 3 favorite tools');

      return of(null);
    }

    this.pushGTMFavoriteEvent(isToolAService, toolId, toolSubCategoryId);

    return this._apiService.post(`v4/tools/${toolId}/favorites/${userId}`, {});
  }

  pushGTMFavoriteEvent(isToolAService: boolean, toolId: string, toolSubCategoryId: string) {
    if (isToolAService) {
      this._gtmService.pushEvent({
        event: 'ds_favorite',
        ds_data_service_name: toolId,
        ds_data_service_category: toolSubCategoryId,
      });
    } else {
      this._gtmService.pushEvent({
        event: 'tl_favorite',
        tl_tool_name: toolId,
        tl_tool_category: toolSubCategoryId,
      });
    }
  }

  /**
   * DEPRECATED NOT USED ANYMORE
   */
  uploadLogo(file: File): Observable<{ id: string; url: string }> {
    this._logger.debug('[ToolsService] Uploading logo');
    this._logger.debug('[ToolsService] Requesting signed URL');

    return this._apiService
      .put<{ id: string; url: string }>('v4/tools/logos', {})
      .pipe(
        flatMap((response) => {
          this._logger.debug('[ToolsService] Signed URL retrieved');

          if (!this._acceptedFileTypes.includes(file.type)) {
            const req = new HttpRequest('PUT', response.url, file, {
              headers: new HttpHeaders().set('Content-Type', file.type),
              responseType: 'text' as const,
              reportProgress: true,
            });
            this._logger.debug('[ToolsService] Uploading logo');

            return this._http.request(req).pipe(
              last(),
              map(() => response.id),
              catchError((err: unknown) => {
                this._logger.error('[ToolsService] Error uploading file using signed URL', err);

                return throwError(err);
              }),
            );
          } else {
            const errorMsg = 'Forbidden file type';
            this._logger.error('[ToolsService] ' + errorMsg);

            return throwError(new Error(errorMsg));
          }
        }),
        catchError((err: unknown) => {
          this._logger.error('[ToolsService] Error obtaining signed URL', err);

          return throwError(err);
        }),
      )
      .pipe(
        flatMap((id) => {
          this._logger.debug('[ToolsService] Logo uploaded');

          return this.getLogo(id).pipe(
            map((downloadUrl) => ({
              id,
              url: downloadUrl,
            })),
          );
        }),
        catchError((err: unknown) => {
          this._logger.error('[SourceService] Error uploading file', err);

          return throwError(err);
        }),
      );
  }

  private _fetchTools() {
    forkJoin(this._listTools(), this._listToolsCategories()).subscribe((data) => {
      // Fetched concurrently flat tools and categories lists from the API
      const [rawTools, rawCategories] = data;

      // This raw data contains only the primary of categories tools and sub-categories
      // So we will resolve them (i.e. put the whole sub-category/tool instead the key)
      // We will use map to store already resolved entity and have an easy access in 0(1)
      const resolutions = {
        rawCategories: new Map<string, IRawToolCategory>(),
        categories: new Map<string, IToolCategory>(),
        tools: new Map<string, ITool>(),
      };

      // Resolve tools, this is straightforward we don't have field to populate
      for (const tool of rawTools) {
        resolutions.tools.set(tool.pk, tool);
      }

      for (const rawCategory of rawCategories) {
        resolutions.rawCategories.set(rawCategory.pk, rawCategory);
      }

      // For categories this is trickier, we perform a recursion to resolve them from children to roots
      const recursivelyResolve = (rawCategoriesId: Array<{ pk: string }>) => {
        for (const rawCategoryId of rawCategoriesId) {
          const rawCategory = resolutions.rawCategories.get(rawCategoryId.pk);
          // Resolve the children first
          recursivelyResolve(rawCategory.subCategories);
          // We can easily get resolved tools thanks to the map filled earlier
          const resolvedTools: ITool[] = rawCategory.tools.map((t) => resolutions.tools.get(t.pk));
          // The resolved sub-categories should exist in map as well as children are resolved first
          const resolvedSubCategories: IToolCategory[] = rawCategory.subCategories.map((c) =>
            resolutions.categories.get(c.pk),
          );
          // Finally we build current resolved category
          const resolvedCategory: IToolCategory = {
            ...rawCategory,
            tools: resolvedTools,
            subCategories: resolvedSubCategories,
          };
          // We store it in map
          resolutions.categories.set(resolvedCategory.pk, resolvedCategory);
          // And the recursion will process upper level
        }
      };

      const roots = rawCategories.filter((c) => !c.parentCategory);
      recursivelyResolve(roots);
      this._logger.debug('[ToolsService]', resolutions);

      // Now everything is resolved, we emit the built entity
      // Note that toJson method take care of sorting, and cleaning
      this._tools$.next(EntityBuilder.buildMany(Tool, rawTools));
      ToolCategory.setAllTools(this._tools$.getValue());
      this._categories$.next(EntityBuilder.buildMany(ToolCategory, Array.from(resolutions.categories.values())));

      // Resolve links and logos
      this._resolveLogos();
    });
  }

  private _resolveLogos() {
    let totalLogosFetched = 0;
    this._tools$.getValue().forEach((tool) => {
      this._logger.info('[ToolsService]', 'Fetching logo', tool.logo);
      this.getLogo(tool.logo)
        .pipe(
          mergeMap((downloadUrl) => {
            this._logger.info('[ToolsService]', 'Signed URL generated for logo', tool.logo);
            tool.logoDownloadUrl = downloadUrl;

            return this.downloadLogo(downloadUrl);
          }),
        )
        .subscribe(
          (logoSvg) => {
            tool.logoSVG = this._sanitizer.bypassSecurityTrustHtml(logoSvg);
            tool.isLoading = false;
            this._logger.info('[ToolsService]', 'Logo SVG received', tool);
          },
          (err: unknown) => {
            this._logger.error('[ToolsService]', 'Error fetching', tool.logo);
            this._logger.error('[ToolsService]', err);
          },
          () => {
            ++totalLogosFetched;
            this._logger.debug(
              '[ToolsService]',
              'Logo resolved',
              totalLogosFetched,
              '/',
              this._tools$.getValue().length,
            );

            if (totalLogosFetched === this._tools$.getValue().length) {
              this._logoAvailable$.next();
            }
          },
        );
    });
  }

  private _listTools(): Observable<Array<ITool>> {
    return this._apiService.get('/v4/tools').pipe(
      catchError((error: unknown) => {
        this._logger.error(error);

        return [];
      }),
    );
  }

  private _listToolsCategories(): Observable<Array<IRawToolCategory>> {
    return this._apiService.get('/v4/tools/categories').pipe(
      catchError((error: unknown) => {
        this._logger.error(error);

        return [];
      }),
    );
  }
}
