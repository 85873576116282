import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from '@dataportal/front-api';

import type { ICommentModel, IPostComment } from '../models/comment.model';
import type { ILastItem } from '../models/common.model';
import type { IGetAllProductTrainingCatalog, LastHowToDocumentation, OneProductModel } from '../models/product.model';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  selectedGTMfilter: BehaviorSubject<string> = new BehaviorSubject<string>('');
  constructor(private readonly _api: ApiService) {}

  getLastProducts$(lastNumber: string): Observable<ILastItem[]> {
    return this._api.get<ILastItem[]>(`v4/data-academy/product/last`, {
      errorHandling: { level: 'error', message: 'Could not get last products' },
      queryStringParameters: { lastNumber },
    });
  }

  // for the moment the last videos ar linked to product only
  getLastHowToDocumentations$(lastNumber: string): Observable<LastHowToDocumentation[]> {
    return this._api.get<LastHowToDocumentation[]>(`v4/data-academy/howtodoc/last`, {
      errorHandling: { level: 'error', message: 'Could not get last how to documentations' },
      queryStringParameters: { lastNumber },
    });
  }

  getProduct$(id: number): Observable<OneProductModel> {
    return this._api.get<OneProductModel>(`v4/data-academy/product/${id}`, {
      errorHandling: { level: 'error', message: 'Could not get product datas' },
    });
  }

  postComment$(productId: number, comment: IPostComment): Observable<ICommentModel> {
    return this._api.post<ICommentModel>(`v4/data-academy/product/${productId}/comments`, comment, {
      errorHandling: { level: 'error', message: 'Could not post your comment' },
    });
  }

  getAllComments$(productId: number): Observable<ICommentModel[]> {
    return this._api.get<ICommentModel[]>(`v4/data-academy/product/${productId}/comments`);
  }

  getTrainingCatalogFilters$(): Observable<IGetAllProductTrainingCatalog[]> {
    return this._api.get<IGetAllProductTrainingCatalog[]>(`v4/data-academy/product/catalogfilters`, {
      errorHandling: { level: 'error', message: 'Could not get products' },
    });
  }
}
