import { Injectable } from '@angular/core';
import type { IListingOptionMetadata } from '@dataportal/front-api';
import { ApiPaginatedService } from '@dataportal/front-api';
import type { IGroupJoin } from '@dataportal/types';
import { EntityBuilder } from '@decahedron/entity';

import { GroupMember } from '../entities/groupJoin';

@Injectable()
export class GroupsMembersService extends ApiPaginatedService<GroupMember> {
  protected buildOne(json: IGroupJoin): GroupMember {
    return EntityBuilder.buildOne(GroupMember, json);
  }

  protected buildMany(json: IGroupJoin[]): GroupMember[] {
    return EntityBuilder.buildMany(GroupMember, json);
  }

  listMembers(groupId: string, options: IListingOptionMetadata): string {
    this.url = '/v4/groups/' + groupId + '/users';

    return this.startListing(options);
  }

  addMember(join: GroupMember) {
    this.url = '/v4/groups/' + join.groupId + '/users';

    return this.post([join.toJson()]);
  }

  removeMember(groupId: string, userId: string) {
    this.url = '/v4/groups/' + groupId + '/users';

    return this.delete(userId);
  }
}
