import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'adl-profile-card',
  templateUrl: './profile-card.component.html',
  styleUrls: ['./profile-card.component.scss'],
})
export class ProfileCardComponent {
  @Input() title: string;
  @Input() header = false;
  @Input() editable: boolean;
  @Input() topRightButtonLabel = '';
  @Output() topRightButtonClicked = new EventEmitter();
  @Output() edited = new EventEmitter();
  @Output() saved = new EventEmitter();
  isEdited = false;

  save() {
    this.saved.next();
    this.isEdited = false;
  }

  edit() {
    this.edited.next();
    this.isEdited = !this.isEdited;
  }
}
