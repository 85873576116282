import type { OnDestroy } from '@angular/core';
import { Component, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { AlertService, Logger } from '@dataportal/front-shared';
import { PermissionsService } from '@dataportal/permissions';
import type { Portal } from '@dataportal/portals';
import { RelationshipsService } from '@dataportal/relationships';
import { Source, SourcesService } from '@dataportal/sources-dashboards-recommendations';

@Component({
  selector: 'dpg-source-content',
  templateUrl: './source-content.component.html',
  styleUrls: ['./source-content.component.scss'],
})
export class SourceContentComponent implements OnDestroy {
  @Input() source: Source;
  @Input() portals: Portal[] = [];

  areContactLoading = true;
  relatedSources = new Map<string, Source>();

  private readonly _destroyed$ = new Subject<void>();

  constructor(
    private readonly _sourceService: SourcesService,
    private readonly _relationshipsService: RelationshipsService,
    private readonly _permissionsService: PermissionsService,
    private readonly _alertService: AlertService,
    private readonly _logger: Logger,
  ) {}

  ngOnDestroy() {
    this._destroyed$.next();
  }

  get organization(): string {
    return this.source.displayOrganization(this.portals);
  }

  get sourceCreatedAt(): string {
    if (this.source?.createdAt?.length) {
      const date = new Date(this.source.createdAt);
      const formattedDate = date.toLocaleDateString('fr');

      return formattedDate.split('/').join('.');
    }

    return null;
  }

  // Methods
  loaded(): void {
    this.areContactLoading = false;
  }
}
