import type { OnDestroy } from '@angular/core';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Subject } from 'rxjs';

import type { IGenericFilter, IGenericFilterItem } from '../../models/filters';

@Component({
  selector: 'adl-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
})
export class FiltersComponent implements OnDestroy {
  @Input() filters: IGenericFilter[] = [];
  @Input() advancedFilterAction: Subject<null>;
  @Input() loading;
  @Output() filtersChange: EventEmitter<IGenericFilter[]> = new EventEmitter();

  private readonly _destroyed$ = new Subject<void>();

  ngOnDestroy() {
    this._destroyed$.next();
  }

  private _clearFilterItemsSelectionRecursively(filterItem: IGenericFilterItem) {
    filterItem.checked = false;

    if (filterItem.subFilterItems?.length) {
      filterItem.subFilterItems.forEach((filterSubItem) => this._clearFilterItemsSelectionRecursively(filterSubItem));
    }
  }

  private _findCheckedDeep(catalogItem: IGenericFilterItem): boolean {
    if (catalogItem.checked) return true;

    return catalogItem?.subFilterItems?.findIndex((filterItem) => this._findCheckedDeep(filterItem)) > -1;
  }

  clearFilterSelection(filter: IGenericFilter) {
    filter.filterItems.forEach((filterItem: IGenericFilterItem) =>
      this._clearFilterItemsSelectionRecursively(filterItem),
    );
    this.filtersChange.emit(this.filters);
  }

  clearAllFilters() {
    this.filters.forEach((filter: IGenericFilter) => this.clearFilterSelection(filter));
    this.filtersChange.emit(this.filters);
  }

  filterList(filter: IGenericFilter) {
    return filter.displayMore ? filter.filterItems : filter.filterItems.slice(0, 8);
  }

  updateFilters() {
    this.filtersChange.emit(this.filters);
  }

  hasCheckedItem(filterItems: IGenericFilterItem[]): boolean {
    return !!filterItems.find((item) => this._findCheckedDeep(item));
  }

  getFilterDisplayCount(filter: IGenericFilter) {
    let displayCount = 5;

    if (filter.displayMore) {
      displayCount = filter.filterItems.length;
    } else {
      displayCount = filter.displayCount || 5;
    }

    return displayCount;
  }
}
