import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from '@dataportal/front-api';
import type { IPublication } from '@dataportal/types';
import { EntityBuilder } from '@decahedron/entity';

import { PublicationsModule } from '../publications.module';

import { Publication } from '../entities/publication.entity';

@Injectable({
  providedIn: PublicationsModule,
})
export class PublicationsService {
  constructor(private readonly _apiService: ApiService) {}

  listPublicationsBySource(sourceId: string): Observable<Publication[]> {
    return this._apiService
      .get<IPublication[]>(`/v4/publications/sources/${sourceId}`)
      .pipe(map((result) => EntityBuilder.buildMany<Publication>(Publication, result)));
  }

  listPublicationsBySourceDraft(sourceDraftId: string): Observable<Publication[]> {
    return this._apiService
      .get<IPublication[]>(`/v4/publications/drafts/${sourceDraftId}`)
      .pipe(map((result) => EntityBuilder.buildMany<Publication>(Publication, result)));
  }

  listPublicationsByPortal(portalId: string): Observable<Publication[]> {
    return this._apiService
      .get<IPublication[]>(`/v4/publications/portals/${portalId}`)
      .pipe(map((result) => EntityBuilder.buildMany<Publication>(Publication, result)));
  }

  listPublicationsByOwner(): Observable<Publication[]> {
    return this._apiService
      .get<IPublication[]>(`/v4/publications/owner`)
      .pipe(map((result) => EntityBuilder.buildMany<Publication>(Publication, result)));
  }

  requestPublications(publication: Publication): Observable<Publication> {
    return this._apiService
      .post<IPublication>('/v4/publications', {
        custom_categories: publication.customCategories,
        portal_id: publication.portalId,
        source_id: publication.sourceId,
        sub_categories: publication.subCategories,
      })
      .pipe(map((result) => EntityBuilder.buildOne<Publication>(Publication, result)));
  }

  createDraftPublication(publication: Publication): Observable<Publication> {
    return this._apiService
      .post<IPublication>(`/v4/publications/drafts/${publication.sourceId}`, {
        custom_categories: publication.customCategories,
        portal_id: publication.portalId,
        source_id: publication.sourceId,
        sub_categories: publication.subCategories,
      })
      .pipe(map((result) => EntityBuilder.buildOne<Publication>(Publication, result)));
  }

  handlePublications(publication: Publication): Observable<Publication> {
    return this._apiService
      .put<IPublication>('/v4/publications', {
        portal_id: publication.portalId,
        source_id: publication.sourceId,
        status: publication.status,
      })
      .pipe(map((json) => EntityBuilder.buildOne(Publication, json)));
  }

  removePublication(sourceId: string, portalId: string): Observable<void> {
    return this._apiService.delete(
      `/v4/publications/portals/${encodeURIComponent(portalId)}/sources/${encodeURIComponent(sourceId)}`,
    );
  }

  removeDraftPublication(sourceDraftId: string, portalId: string): Observable<void> {
    return this._apiService.delete(
      `/v4/publications/drafts/${encodeURIComponent(sourceDraftId)}/portals/${encodeURIComponent(portalId)}`,
    );
  }

  publish(publication: Publication): Observable<Publication> {
    return this._apiService
      .put<IPublication>('/v4/publications', {
        custom_categories: publication.customCategories,
        portal_id: publication.portalId,
        source_id: publication.sourceId,
        sub_categories: publication.subCategories,
      })
      .pipe(map((json) => EntityBuilder.buildOne(Publication, json)));
  }

  hasSeen(sourceId: string, portalId: string): Observable<void> {
    return this._apiService.put('v4/publications/has-seen', {
      source_id: sourceId,
      portal_id: portalId,
    });
  }
}
