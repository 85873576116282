import { Component } from '@angular/core';
import type { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ExplorerPageService } from '../../services/explorer-page.service';

@Component({
  selector: 'dpg-datalake-browser-pagination',
  templateUrl: './browser-pagination.component.html',
  styleUrls: ['./browser-pagination.component.scss'],
})
export class BrowserPaginationComponent {
  readonly current$: Observable<number>;
  readonly total$: Observable<number>;
  readonly onFirst$: Observable<boolean>;
  readonly onLast$: Observable<boolean>;

  constructor(readonly explorerPage: ExplorerPageService) {
    this.current$ = this.explorerPage.pages$.pipe(map((page) => page.current));
    this.total$ = this.explorerPage.pages$.pipe(map((page) => page.total));
    this.onFirst$ = this.current$.pipe(map((current) => current === 1));
    this.onLast$ = this.explorerPage.pages$.pipe(map((page) => page.current === page.total));
  }
}
