<div class="row-flex j-center a-center" *ngIf="displayObjectType === 'folder'">
  <em [ngClass]="'icon-folder'"></em>
</div>
<div class="row-flex j-center a-center" *ngIf="displayObjectType === 'file'">
  <ng-container [ngSwitch]="extension.toLowerCase()">
    <adl-svg-icon class="row-flex j-center a-center" *ngSwitchCase="'csv'" icon="csv" size="18"></adl-svg-icon>
    <adl-svg-icon class="row-flex j-center a-center" *ngSwitchCase="'xls'" icon="xls" size="17"></adl-svg-icon>
    <adl-svg-icon class="row-flex j-center a-center" *ngSwitchCase="'xlsx'" icon="xlsx" size="17"></adl-svg-icon>
    <adl-svg-icon class="row-flex j-center a-center" *ngSwitchCase="'json'" icon="json" size="17"></adl-svg-icon>
    <adl-svg-icon class="row-flex j-center a-center" *ngSwitchCase="'png'" icon="png" size="20"></adl-svg-icon>
    <adl-svg-icon class="row-flex j-center a-center" *ngSwitchCase="'jpeg'" icon="jpeg" size="20"></adl-svg-icon>
    <adl-svg-icon class="row-flex j-center a-center" *ngSwitchCase="'pdf'" icon="pdf" size="18"></adl-svg-icon>
    <adl-svg-icon class="row-flex j-center a-center" *ngSwitchCase="'txt'" icon="txt" size="20"></adl-svg-icon>
    <adl-svg-icon class="row-flex j-center a-center" *ngSwitchCase="'jpg'" icon="jpeg" size="20"></adl-svg-icon>
    <adl-svg-icon class="row-flex j-center a-center" *ngSwitchCase="'jpe'" icon="jpeg" size="20"></adl-svg-icon>
    <adl-svg-icon class="row-flex j-center a-center" *ngSwitchCase="'jif'" icon="jpeg" size="20"></adl-svg-icon>
    <adl-svg-icon class="row-flex j-center a-center" *ngSwitchCase="'jfif'" icon="jpeg" size="20"></adl-svg-icon>
    <adl-svg-icon class="row-flex j-center a-center" *ngSwitchCase="'jfi'" icon="jpeg" size="20"></adl-svg-icon>
    <adl-svg-icon class="row-flex j-center a-center" *ngSwitchCase="'webp'" icon="jpeg" size="20"></adl-svg-icon>
    <adl-svg-icon class="row-flex j-center a-center" *ngSwitchCase="'tiff'" icon="jpeg" size="20"></adl-svg-icon>
    <adl-svg-icon class="row-flex j-center a-center" *ngSwitchCase="'tif'" icon="jpeg" size="20"></adl-svg-icon>
    <adl-svg-icon class="row-flex j-center a-center" *ngSwitchCase="'psd'" icon="jpeg" size="20"></adl-svg-icon>
    <adl-svg-icon class="row-flex j-center a-center" *ngSwitchCase="'raw'" icon="jpeg" size="20"></adl-svg-icon>
    <adl-svg-icon class="row-flex j-center a-center" *ngSwitchCase="'arw'" icon="jpeg" size="20"></adl-svg-icon>
    <adl-svg-icon class="row-flex j-center a-center" *ngSwitchCase="'cr2'" icon="jpeg" size="20"></adl-svg-icon>
    <adl-svg-icon class="row-flex j-center a-center" *ngSwitchCase="'nrw'" icon="jpeg" size="20"></adl-svg-icon>
    <adl-svg-icon class="row-flex j-center a-center" *ngSwitchCase="'k25'" icon="jpeg" size="20"></adl-svg-icon>
    <adl-svg-icon class="row-flex j-center a-center" *ngSwitchCase="'bmp'" icon="jpeg" size="20"></adl-svg-icon>
    <adl-svg-icon class="row-flex j-center a-center" *ngSwitchCase="'dib'" icon="jpeg" size="20"></adl-svg-icon>
    <adl-svg-icon class="row-flex j-center a-center" *ngSwitchCase="'heif'" icon="jpeg" size="20"></adl-svg-icon>
    <adl-svg-icon class="row-flex j-center a-center" *ngSwitchCase="'ind'" icon="jpeg" size="20"></adl-svg-icon>
    <adl-svg-icon class="row-flex j-center a-center" *ngSwitchCase="'indd'" icon="jpeg" size="20"></adl-svg-icon>
    <adl-svg-icon class="row-flex j-center a-center" *ngSwitchCase="'indt'" icon="jpeg" size="20"></adl-svg-icon>
    <adl-svg-icon class="row-flex j-center a-center" *ngSwitchCase="'jp2'" icon="jpeg" size="20"></adl-svg-icon>
    <adl-svg-icon class="row-flex j-center a-center" *ngSwitchCase="'j2k'" icon="jpeg" size="20"></adl-svg-icon>
    <adl-svg-icon class="row-flex j-center a-center" *ngSwitchCase="'jpf'" icon="jpeg" size="20"></adl-svg-icon>
    <adl-svg-icon class="row-flex j-center a-center" *ngSwitchCase="'jpx'" icon="jpeg" size="20"></adl-svg-icon>
    <adl-svg-icon class="row-flex j-center a-center" *ngSwitchCase="'jpm'" icon="jpeg" size="20"></adl-svg-icon>
    <adl-svg-icon class="row-flex j-center a-center" *ngSwitchCase="'mj2'" icon="jpeg" size="20"></adl-svg-icon>
    <adl-svg-icon class="row-flex j-center a-center" *ngSwitchCase="'svg'" icon="jpeg" size="20"></adl-svg-icon>
    <adl-svg-icon class="row-flex j-center a-center" *ngSwitchCase="'svgz'" icon="jpeg" size="20"></adl-svg-icon>
    <adl-svg-icon class="row-flex j-center a-center" *ngSwitchCase="'ai'" icon="jpeg" size="20"></adl-svg-icon>
    <adl-svg-icon class="row-flex j-center a-center" *ngSwitchCase="'eps'" icon="jpeg" size="20"></adl-svg-icon>
    <em *ngSwitchDefault [ngClass]="'icon-file-o'"></em>
  </ng-container>
</div>
