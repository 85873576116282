import { InjectionToken } from '@angular/core';

export const LAYOUT_OPTIONS = new InjectionToken<ILayoutOptions>('dataportal-front:layout:options');

// Types
export interface ILayoutOptions {
  logoNotToPaint: string[];
  dpContext: {
    code: 'PR' | 'JCD' | 'ACCOR';
    name: 'Pernod Ricard' | 'JCDecaux' | 'Accor';
    internalUserEmail: RegExp;
  };
}
