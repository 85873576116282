import type { LinkDirection,SourceRelationship as ISourceRelationship} from '@dataportal/types';
import { SourceRelationshipStatus } from '@dataportal/types';
import { Entity } from '@decahedron/entity';

export abstract class SourceRelationship extends Entity {
  sourceId: string = null;
  relatedSourceId: string = null;
  relatedSourceName: string = null;
  linkId: string = null;
  label: string = null;
  createdBy: string = null;
  lastUpdatedBy: string = null;
  type: LinkDirection = null;
  protected _status: SourceRelationshipStatus = null;

  fromJson(json: ISourceRelationship): SourceRelationship {
    this.sourceId = json.pk;
    this.relatedSourceId = json.relatedSourceId;
    this.relatedSourceName = json.relatedSourceName;
    this.linkId = json.linkId;
    this.label = json.label;
    this._status = json.status;
    this.createdBy = json.createdBy;
    this.lastUpdatedBy = json.lastUpdatedBy;

    return this;
  }

  abstract get status(): SourceRelationshipStatus;

  get statusColor(): string {
    switch (this.status) {
      case SourceRelationshipStatus.ACTIVE:
        return '#009624';
      case SourceRelationshipStatus.DELETION_PENDING:
        return '#ff6e40';
      case SourceRelationshipStatus.PENDING:
        return '#ffb52b';
      case SourceRelationshipStatus.REJECTED:
        return '#e94d3e';
    }
  }

  abstract get canBeDeleted(): boolean;
}
