import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { AdlModule } from '@dataportal/adl';
import { ApiModule } from '@dataportal/front-api';
import { GraphApiModule } from '@dataportal/front-graph-api';

import { GuardianImportExportCheckService } from './services/guardian-import-export-check.service';

import { GuardianCheckInfosColumnFieldComponent } from './components/guardian-check-infos-column-field/guardian-check-infos-column-field.component';
import { GuardianCheckInfosColumnsComponent } from './components/guardian-check-infos-columns/guardian-check-infos-columns.component';
import { GuardianCheckInfosContentComponent } from './components/guardian-check-infos-content/guardian-check-infos-content.component';
import { GuardianCheckInfosGlobalComponent } from './components/guardian-check-infos-global/guardian-check-infos-global.component';
import { GuardianCheckInfosModalComponent } from './components/guardian-check-infos-modal/guardian-check-infos-modal.component';
import { GuardianCheckModalComponent } from './components/guardian-check-modal/guardian-check-modal.component';
import { GuardianCheckModalHeaderComponent } from './components/guardian-check-modal-header/guardian-check-modal-header.component';
import { GuardianCheckModalV2Component } from './components/guardian-check-modal-v2/guardian-check-modal-v2.component';
import { GuardianCheckRunDetailsComponent } from './components/guardian-check-run-details/guardian-check-run-details.component';
import { GuardianCheckRunsListComponent } from './components/guardian-check-runs-list/guardian-check-runs-list.component';
import { DatalakeConfirmModalComponent } from './components/guardian-confirm-modal/datalake-confirm-modal.component';
import { GuardianFormControlComponent } from './components/guardian-modal/guardian-form-control/form-control.component';
import { GuardianModalComponent } from './components/guardian-modal/guardian-modal.component';
import { GuardianStepCheckBasicInfosComponent } from './components/guardian-modal/guardian-step-check-basic-infos/guardian-step-check-basic-infos.component';
import { GuardianStepCheckColumnFieldComponent } from './components/guardian-modal/guardian-step-check-columns/column-field/guardian-step-check-column-field.component';
import { GuardianStepCheckColumnsComponent } from './components/guardian-modal/guardian-step-check-columns/guardian-step-check-columns.component';
import { GuardianStepCheckTriggersComponent } from './components/guardian-modal/guardian-step-check-triggers/guardian-step-check-triggers.component';
import { GuardianPreviewTooltipComponent } from './components/guardian-preview-tooltip/guardian-preview-tooltip.component';
import { GuardianReferentialOverviewModalComponent } from './components/guardian-referential-overview-modal/guardian-referential-overview-modal.component';

// Modules
@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    ApiModule,
    AdlModule,
    FormsModule,
    ReactiveFormsModule,
    GraphApiModule,
    DragDropModule,
  ],
  declarations: [
    GuardianCheckInfosColumnsComponent,
    GuardianCheckInfosColumnFieldComponent,
    GuardianCheckInfosGlobalComponent,
    GuardianCheckInfosModalComponent,
    GuardianCheckInfosContentComponent,
    GuardianCheckModalComponent,
    GuardianCheckModalV2Component,
    GuardianCheckModalHeaderComponent,
    GuardianCheckRunDetailsComponent,
    GuardianCheckRunsListComponent,
    GuardianReferentialOverviewModalComponent,
    GuardianPreviewTooltipComponent,
    GuardianModalComponent,
    GuardianFormControlComponent,
    GuardianStepCheckBasicInfosComponent,
    GuardianStepCheckTriggersComponent,
    GuardianStepCheckColumnsComponent,
    GuardianStepCheckColumnFieldComponent,
    DatalakeConfirmModalComponent,
  ],
  exports: [
    GuardianCheckInfosColumnsComponent,
    GuardianCheckInfosColumnFieldComponent,
    GuardianCheckInfosGlobalComponent,
    GuardianCheckInfosModalComponent,
    GuardianCheckInfosContentComponent,
    GuardianCheckModalComponent,
    GuardianCheckModalV2Component,
    GuardianCheckModalHeaderComponent,
    GuardianCheckRunDetailsComponent,
    GuardianCheckRunsListComponent,
    GuardianReferentialOverviewModalComponent,
    GuardianPreviewTooltipComponent,
    GuardianStepCheckBasicInfosComponent,
    GuardianModalComponent,
    GuardianFormControlComponent,
    GuardianStepCheckBasicInfosComponent,
    GuardianStepCheckTriggersComponent,
    GuardianStepCheckColumnsComponent,
    GuardianStepCheckColumnFieldComponent,
    DatalakeConfirmModalComponent,
  ],
  providers: [GuardianImportExportCheckService],
})
export class GuardianUtilsModule {}
