import type { OnDestroy } from '@angular/core';
import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { of, Subject } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';
import type { IARResourceRolesMap, ISupportedARRole } from '@dataportal/access-requests';
import { ACCESS_REQUEST_ROLES, AccessRequestsV2Service, IAccessRequestResource } from '@dataportal/access-requests';
import { EnvironmentService } from '@dataportal/front-environment';
import { AlertService, Logger } from '@dataportal/front-shared';
import { getRoleData } from '@dataportal/permissions';
import type { ISupportedARResource } from '@dataportal/types';

// Types
interface IRoleValue {
  label: string;
  value: ISupportedARRole;
  infoBubbleContent?: string;
}

// Constants
const ROLES_DEFAULTS: { [R in ISupportedARResource]: IARResourceRolesMap[R] } = {
  sources: 'dashboardReader',
  leons: 'leonReader',
};

// Component
@Component({
  selector: 'dpg-catalog-request-access-modal',
  templateUrl: './catalog-request-access-modal.component.html',
  styleUrls: ['./catalog-request-access-modal.component.scss'],
})
export class CatalogRequestAccessModalComponent<R extends ISupportedARResource = ISupportedARResource>
  implements OnDestroy
{
  // Attributes
  private _kind: R;
  @Input() resource: IAccessRequestResource;

  availableRoles: IRoleValue[] = [];
  requestedRole?: IARResourceRolesMap[R];

  loadingRequest = false;

  accessRequested = false;

  private readonly _destroyed$ = new Subject<void>();

  // Constructor
  constructor(
    private readonly _activeMatModal: MatDialogRef<CatalogRequestAccessModalComponent>,
    private readonly _accessRequestsV2Service: AccessRequestsV2Service,
    private readonly _alertService: AlertService,
    private readonly _logger: Logger,
    @Inject(MAT_DIALOG_DATA)
    data: {
      resource: IAccessRequestResource;
      kind: R;
    },
    readonly environmentService: EnvironmentService,
  ) {
    this.resource = data?.resource ? data.resource : this.resource;
    this.kind = data?.kind ? data.kind : this.kind;
  }

  // Methods
  request(): void {
    this.loadingRequest = true;
    this._accessRequestsV2Service
      .create(this.kind, this.resource.id, this.requestedRole)
      .pipe(
        tap(() => {
          this.loadingRequest = false;
          this.accessRequested = true;
        }),
        take(1),
        catchError((err: unknown) => {
          this._logger.error(err);
          this.loadingRequest = false;

          return of(null);
        }),
      )
      .subscribe(() => {
        this._alertService.success('Your access request has been successfully sent !');
      });
  }

  closeModal(): void {
    this._activeMatModal.close();
  }

  updateRequestedRole($event: string): void {
    this.requestedRole = $event as IARResourceRolesMap[R];
  }

  // Properties
  @Input()
  get kind(): R {
    return this._kind;
  }

  set kind(kind: R) {
    this._kind = kind;

    this.requestedRole = ROLES_DEFAULTS[kind];
    this.availableRoles = getRoleData(...ACCESS_REQUEST_ROLES[kind]).map((data) => ({
      label: data.label,
      value: data.role,
      infoBubbleContent: data.description,
    }));
  }

  get accessRequestAllowed(): boolean {
    return this.resource && (this.resource.allowAccessRequest ?? true);
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }
}
