import type { OnChanges, OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { CommonSelectComponent } from '@dataportal/adl';

import { GroupsAdService } from '../../services/groups-ad.service';

import type { GroupAd } from '../../entities/group-ad';

@Component({
  selector: 'dpg-group-ad-select',
  templateUrl: '../../../../../../modules/adl/src/lib/organisms/common-select/common-select.component.html',
  styleUrls: ['./group-ad-select.component.scss'],
})
export class GroupAdSelectComponent extends CommonSelectComponent<GroupAd> implements OnInit, OnChanges {
  constructor(readonly groupAdsService: GroupsAdService) {
    super(groupAdsService);
    this.placeholder = 'Select an Azure AD group';
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnChanges(): void {
    super.ngOnChanges();
  }
}
