import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';

@Pipe({
  name: 'listCss',
})
export class ListCssPipe implements PipeTransform {
  transform(value: string): string {
    return (
      value +
      '<style>ul.wysiwig_class {\n' +
      '  list-style: circle;\n' +
      '}\n' +
      '\n' +
      'ol.wysiwig_class {\n' +
      '  list-style: decimal;\n' +
      '}\n' +
      '\n' +
      'ol.wysiwig_class, ul.wysiwig_class {\n' +
      '  padding-inline-start: 40px;\n' +
      '  display: block;\n' +
      '  margin-block-start: 1em;\n' +
      '  margin-block-end: 1em;\n' +
      '  margin-inline-start: 0;\n' +
      '  margin-inline-end: 0;\n' +
      '}\n' +
      '\n' +
      'li.wysiwig_class {\n' +
      '  display: list-item;\n' +
      '  text-align: -webkit-match-parent;\n' +
      '  list-style: inherit;\n' +
      '}\n' +
      '\n' +
      'img.wysiwig_class {\n' +
      '  display: inline;\n' +
      '}' +
      'table.wysiwig_class {\n' +
      '  border: 1px dashed #BBB\n' +
      '}\n' +
      'tr.wysiwig_class {\n' +
      '  border: inherit;\n' +
      '}\n' +
      'tbody.wysiwig_class {\n' +
      '  border: inherit;\n' +
      '}\n' +
      'td.wysiwig_class {\n' +
      '  border: inherit;\n' +
      '}</style>'
    );
  }
}
