import type { OnDestroy, OnInit } from '@angular/core';
import { Component, HostListener, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import type { SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, Subject } from 'rxjs';
import { distinctUntilChanged, filter, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import type { IBreadcrumbLink } from '@dataportal/adl';
import { CurrentUserService } from '@dataportal/auth';
import { EnvironmentService } from '@dataportal/front-environment';
import { PermissionsService } from '@dataportal/permissions';
import { ProfileService } from '@dataportal/profile';
import { UsersService } from '@dataportal/users';

import { CatalogV2NavigationService } from '../../../services/v2/navigation.service';
import { CatalogV2SearchService } from '../../../services/v2/search.service';

import { SEARCH_MODE_PREFERENCE_NAME } from '../../../services/v2/catalog.constants';

@Component({
  selector: 'dpg-catalog-header',
  templateUrl: './catalog-header.component.html',
  styleUrls: ['./catalog-header.component.scss'],
})
export class CatalogHeaderComponent implements OnInit, OnDestroy {
  @Input() search = false;
  @Input() switch = false;

  searchControl = new FormControl('');
  switchControl = new FormControl(false);
  username: string;
  userPicture: string | SafeResourceUrl = '/assets/img/icon/contact-default.png';
  showStickySearch = false;
  canUserCreateAsset = false;
  switchDataCatalogControl = new FormControl();

  coverImage = '';

  firstBreadcrumbLink: IBreadcrumbLink = { label: 'Accor Data Portal', url: '/' };

  private readonly _destroyed$ = new Subject<void>();

  constructor(
    private readonly _searchService: CatalogV2SearchService,
    private readonly _currentUserService: CurrentUserService,
    private readonly _usersService: UsersService,
    private readonly _router: Router,
    private readonly _route: ActivatedRoute,
    private readonly _permissionsService: PermissionsService,
    private readonly _navigationService: CatalogV2NavigationService,
    private readonly _profileService: ProfileService,
    readonly environmentService: EnvironmentService,
  ) {}

  ngOnInit() {
    const query = this._route.snapshot.queryParamMap.get('q');
    this.searchControl.setValue(query);

    this.switchDataCatalogControl.setValue(localStorage.getItem(SEARCH_MODE_PREFERENCE_NAME) === 'advanced');

    this.switchDataCatalogControl.valueChanges
      .pipe(distinctUntilChanged(), takeUntil(this._destroyed$))
      .subscribe((switchValue: boolean) => {
        this._searchService.updateMode(switchValue ? 'advanced' : 'simple');
      });

    this._currentUserService.currentUser$
      .pipe(
        filter((user) => !!user.id),
        tap((user) => (this.username = user.name)),
        switchMap((user) => {
          return this._usersService
            .getImage(user.id)
            .pipe(
              tap((image: SafeResourceUrl) => (this.userPicture = image || '/assets/img/icon/contact-default.png')),
            );
        }),
        takeUntil(this._destroyed$),
      )
      .subscribe();

    this._searchService.searchTerm$
      .pipe(
        filter((term) => term !== this.searchControl.value),
        takeUntil(this._destroyed$),
      )
      .subscribe((term) => {
        this.searchControl.setValue(term);
      });

    this._profileService
      .getUserPreferences()
      .pipe(
        tap((userPreferences) => {
          if (!userPreferences?.cover) {
            this.coverImage = `assets/dpg/imgs/covers/Default.png`;
          } else {
            this.coverImage = `assets/dpg/imgs/covers/${userPreferences.cover}.png`;
          }
        }),
      )
      .subscribe();

    this.checkUserAssetCreationAccessRoles();
  }

  ngOnDestroy() {
    this._destroyed$.next();
  }

  // Show sticky bar when the user scrolls down
  @HostListener('window:scroll', ['$event'])
  scrollEvent() {
    this.showStickySearch = document.documentElement.scrollTop > 190;
  }

  openAdvancedFiltersModal(): void {
    this._navigationService.openAdvancedFiltersModal();
  }

  openDataAssetCreationPage(): void {
    this._router.navigate(['admin/sources']).then();
  }

  checkUserAssetCreationAccessRoles(): void {
    // check if the user can create an asset
    forkJoin({
      sourcePermission: this._permissionsService.isAuthorized('create', 'sources').pipe(take(1)),
      portalPermission: this._permissionsService.isAuthorized('create', 'portals').pipe(take(1)),
      isAdmin: this._currentUserService.isAdmin$.pipe(take(1)),
    })
      .pipe(takeUntil(this._destroyed$))
      .subscribe(({ sourcePermission, portalPermission, isAdmin }) => {
        this.canUserCreateAsset = sourcePermission || portalPermission || isAdmin;
      });
  }
}
