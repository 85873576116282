import type { AccountInfo } from '@azure/msal-browser';

import { setAuthCookies } from './cookies.utils';
import { AuthenticationState } from './enums/authentification-state.enum';
import { AuthLocalStorageKeys, AuthSessionStorageKeys } from './enums/storage-keys.enum';

export interface IAuthToken {
  token: string;
  expiration?: Date;
}

interface IAzureTokens {
  provider: 'azure';
  idToken: string;
  accessToken?: string;
  account?: AccountInfo | null;
}

interface ICognitoTokens {
  provider: 'cognito';
  idToken?: string;
  accessToken: string;
  refreshToken?: string;
}

/**
 * Update tokens in local storage and in cookies (for external applications use)
 * @param tokens
 */
export const updateTokens = (tokens: IAzureTokens | ICognitoTokens) => {
  try {
    localStorage.setItem(AuthLocalStorageKeys.AUTH_PROVIDER, tokens.provider);
    const refreshToken = (tokens as ICognitoTokens).refreshToken;
    const accessToken = tokens.accessToken;
    const idToken = tokens.idToken;
    let account: unknown;

    if ('account' in tokens) {
      account = tokens.account;
    }

    if (refreshToken) {
      localStorage.setItem(AuthLocalStorageKeys.REFRESH_TOKEN, refreshToken);
    }

    if (accessToken) {
      localStorage.setItem(AuthLocalStorageKeys.ACCESS_TOKEN, accessToken);
    }

    if (idToken) {
      localStorage.setItem(AuthLocalStorageKeys.ID_TOKEN, idToken);
      setAuthCookies(idToken);
    }

    if (account) {
      localStorage.setItem(AuthLocalStorageKeys.AUTH_ACCOUNT, JSON.stringify(account));
    }
  } catch (e) {
    console.error('Error setting token in local storage and cookies', e);
    throw e;
  }
};

const getState = (): AuthenticationState | null => {
  try {
    return sessionStorage.getItem(AuthSessionStorageKeys.AUTH_STATE) as AuthenticationState;
  } catch (e) {
    return null;
  }
};

export const setErrorState = (): void => {
  try {
    return sessionStorage.setItem(AuthSessionStorageKeys.AUTH_STATE, AuthenticationState.ERRORED);
  } catch (e) {
    console.error('Could not set error state');
  }
};

export const setAuthenticatedState = (): void => {
  try {
    return sessionStorage.setItem(AuthSessionStorageKeys.AUTH_STATE, AuthenticationState.AUTHENTICATED);
  } catch (e) {
    console.error('Could not set error state');
  }
};

export const isInStateError = (): boolean => {
  const state = getState();
  // eslint-disable-next-line no-restricted-syntax
  console.info('Authentication state');

  return state === AuthenticationState.ERRORED;
};
