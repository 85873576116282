import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

import { SharedModule } from '../shared.module';

// Service
@Injectable({
  providedIn: SharedModule,
})
export class RoutingService {
  // Attributes
  private _history: string[] = [];

  // Constructor
  constructor(private readonly _router: Router) {}

  // Methods
  loadRouting(): void {
    this._router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(({ urlAfterRedirects }: NavigationEnd) => {
        this._history = [...this._history, urlAfterRedirects];
      });
  }

  getHistory(): string[] {
    return this._history;
  }

  getPreviousUrl(): string {
    return this._history[this._history.length - 2] || '/';
  }
}
