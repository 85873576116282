import { Component, Input } from '@angular/core';

@Component({
  selector: 'adl-table-v2',
  templateUrl: './table-v2.component.html',
  styleUrls: ['./table-v2.component.scss'],
})
export class TableV2Component {
  @Input() headers: string[] = [];
  @Input() hasCustomHeader = false;
  @Input() colWidths: string[] = [];
  @Input() tableTitle: string;
  @Input() contents: string[];
  @Input() tableWidth: number;
  @Input() hasHoveringAnimation = true;
  @Input() bodyHorizontalPadding = 10;
}
