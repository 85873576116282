import type { OnInit } from '@angular/core';
import { Component } from '@angular/core';

import { DatabricksService } from '../../services/databricks.service';

import type { DatabrickWorkspace } from '../../entities/databrick';

@Component({
  selector: 'dpg-databricks-page',
  templateUrl: './databricks-page.component.html',
  styleUrls: ['./databricks-page.component.scss'],
})
export class DatabricksPageComponent implements OnInit {
  // Attributes
  loading = false;
  searchText = '';
  databricksWorkspaces: DatabrickWorkspace[];
  filteredDatabricksWorkspaces: DatabrickWorkspace[];

  // Constructor
  constructor(private readonly _databricksService: DatabricksService) {}

  // Lifecycle
  ngOnInit() {
    this.loading = true;

    this._databricksService.listWorkspacesUrls().subscribe((workspaces: DatabrickWorkspace[]) => {
      this.databricksWorkspaces = workspaces;
      this.filteredDatabricksWorkspaces = workspaces;

      this.loading = false;
    });
  }

  // Methods
  search(): void {
    this.filteredDatabricksWorkspaces = this.databricksWorkspaces.filter((databrickWorkspace: DatabrickWorkspace) =>
      databrickWorkspace.name.toLowerCase().includes(this.searchText.toLowerCase()),
    );
  }

  openDatabricksWorkspace(databrickWorkspace: DatabrickWorkspace): void {
    window.open(`https://${databrickWorkspace.url}`, '_blank');
  }

  hasDatabricksWorkspaces(): boolean {
    return !!this.databricksWorkspaces?.length;
  }
}
