<adl-modal headerBackgroundColor="blue-dark" backgroundColor="light" [hasMarginBottom]="false" [isAlignedToCross]="true" [roundCorner]="true" [closeButtonColor]="'white'">
  <div class="modal-header w-full items-center" modal-header>
    <adl-title class="flex ml-8 my-7" [type]="'modal'" [color]="'#ffffff'">
      Share bookmark {{bookmark.name}}
    </adl-title>
  </div>

  <div modal-body class="modal-body flex flex-col items-start">
    <adl-text class="w-full text-left" type="accent" [size]="14"> Name of the bookmark to share </adl-text>
    <adl-text class="w-full text-left" [size]="13"> The bookmark you share will be visible under the name you set here. </adl-text>
    <adl-input class="w-full mt-3" [control]="sharedNameControl" [placeholder]="'Enter a name'"></adl-input>
    <adl-text class="w-full text-left mt-5" type="accent" [size]="14"> Add people to share this bookmark. </adl-text>
    <adl-text class="w-full text-left" type="cant-file-modal" [size]="13"> Please select one or multiple email addresses. They will be able to see the bookmark you shared in their “Shared to me” section. </adl-text>
    <div class="w-full flex mt-3">
      <adl-autocomplete
        placeholder="Select a user"
        class="w-full"
        [items]="selectableUsers"
        (changed)="setUser($event)"
        [clear]="clearUserSelect$"
      ></adl-autocomplete>
      <adl-button class="ml-8" (clicked)="addMail()">Add</adl-button>
    </div>
    <div *ngFor="let mail of mailsList" class="flex flex-col w-full">
      <div class="w-full flex items-center justify-between my-2.5">
        <adl-text [size]="14">{{mail}}</adl-text>
        <adl-icon icon="trash" (click)="deleteMail(mail)"></adl-icon>
      </div>
      <div *ngIf="mail !== mailsList[mailsList.length-1]" class="mail-separator"></div>
    </div>

  </div>
  <div modal-footer class="row-flex j-center pb-8">
    <adl-button class="mr-5" (clicked)="close()" type="cancel"> Cancel </adl-button>
    <adl-button (clicked)="send()" [disabled]="!canShare"> Send </adl-button>
  </div>
</adl-modal>
