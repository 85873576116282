<ng-select
  class="custom"
  bindValue="role"
  [items]="rolesSelectList"
  [formControl]="roleControl"
  [searchable]="false"
  [clearable]="false"
>
  <ng-template ng-label-tmp let-item="item">
    <adl-text class="block mt-2" [size]="14" color="blue">{{ item.label }}</adl-text>
    <adl-text class="block mb-2 -mt-3" [size]="12" color="grey-500">{{ item.description }}</adl-text>
  </ng-template>
  <ng-template ng-option-tmp let-item="item" let-index="index">
    <adl-text class="block" [size]="14" color="blue">{{ item.label }}</adl-text>
    <adl-text class="block -mt-3" [size]="12" color="grey-500">{{ item.description }}</adl-text>
  </ng-template>
</ng-select>
