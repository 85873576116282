import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, merge } from 'rxjs';
import { first } from 'rxjs/operators';
import { CurrentUserService } from '@dataportal/auth';
import type { CustomCategory, RootCategory, SourcesCategories, SubCategory } from '@dataportal/categories';
import { CategoriesService, SubCategoriesService } from '@dataportal/categories';
import { Logger } from '@dataportal/front-shared';
import type { IPortalTree, Portal } from '@dataportal/portals';
import { PortalsService } from '@dataportal/portals';
import type { Source } from '@dataportal/sources-dashboards-recommendations';
import { SourcesService } from '@dataportal/sources-dashboards-recommendations';
import Fuse from 'fuse.js';

import type {
  ICategoryFilter,
  IScopeFilter,
  ISourceFilters,
  ISubcategoryFilter,
  ITypeFilters,
} from '../models/source-filters';

// Types
interface IQueryParams {
  categories?: string[];
  subcategories?: string[];
  scopes?: string[];
  type?: string;
}

/**
 * Service for searching sources in the data catalog
 */
@Injectable()
export class SearchService {
  // Attributes
  private _favoriteOrganizationLoaded = false;
  private readonly _loading$ = new BehaviorSubject<boolean>(true);
  loading$ = this._loading$.asObservable();

  private _currentPortal: string;
  private _sourcesCategories: SourcesCategories;

  // - data
  private _allSources: Source[];

  private readonly _sources$ = new BehaviorSubject<Source[]>([]);
  private readonly _subcategories$ = new BehaviorSubject<SubCategory[]>([]);
  private readonly _customCategories$ = new BehaviorSubject<CustomCategory[]>([]); // Fixme: always empty !

  sources$ = this._sources$.asObservable();
  subcategories$ = this._subcategories$.asObservable();
  customCategories$ = this._customCategories$.asObservable();

  // - query
  private _hasQueryParamsBeenLoaded = false;
  private readonly _firstQueryParamsLoaded = {
    categories: false,
    subcategories: false,
    scopes: false,
    type: false,
  };

  private readonly _query$ = new BehaviorSubject<string>('');
  query$ = this._query$.asObservable();

  // - filters
  private _firstFilterLoaded = false;
  private _previousFilter: ISourceFilters = {
    categories: [],
    subcategories: [],
    scopes: [],
    type: { hasReports: false, hasDatalake: false },
  };

  private readonly _firstScopeFilter$ = new BehaviorSubject<IScopeFilter[]>([]);
  private readonly _firstCategoryFilter$ = new BehaviorSubject<ICategoryFilter[]>([]);
  private readonly _firstSubcategoryFilter$ = new BehaviorSubject<ISubcategoryFilter[]>([]);
  private readonly _firstTypeFilter$ = new BehaviorSubject<ITypeFilters>({ hasDatalake: false, hasReports: false });
  private readonly _filters$ = new BehaviorSubject<ISourceFilters>(this._previousFilter);

  firstScopeFilter$ = this._firstScopeFilter$.asObservable();
  firstCategoryFilter$ = this._firstCategoryFilter$.asObservable();
  firstSubcategoryFilter = this._firstSubcategoryFilter$.asObservable();
  firstTypeFilter$ = this._firstTypeFilter$.asObservable();
  filters$ = this._filters$.asObservable();

  /**
   * Convert html string to text string (replace html special characters)
   * @param htmlStr
   * @private
   */
  private static _convertHtmlCharsToStr(htmlStr: string): string {
    const div = document.createElement('div');
    div.innerHTML = htmlStr;

    return div.innerText;
  }

  // Statics
  /**
   * Searching method :
   * Every word (not case sensitive) from source ID, name and description (without html tags) are extracted into a
   * glossary and every query word (not case sensitive) should be contained in this glossary
   * (by being a part, or by matching directly one of the word in the glossary)
   * @param query
   * @param source
   * @private
   */
  private static _matchQuery(query: string, source: Source): boolean {
    const queryTerms = query
      .toLowerCase()
      .split(' ')
      .filter((term) => term.length);
    const allSearchableTerms = SearchService._convertHtmlCharsToStr(
      source.id + ' ' + source.name + ' ' + source.description,
    )
      .toLowerCase()
      .split(/[ \n,;.:_/\\]/gm);

    return (
      !query ||
      query.length === 0 ||
      queryTerms.every((term) => allSearchableTerms.some((searchableTerm) => searchableTerm.includes(term)))
    );
  }

  private static _removeDuplicate<T, P>(array: T[], getter: (obj: T) => P) {
    const index = new Set<P>();
    let i = 0;

    while (i < array.length) {
      const val = getter(array[i]);

      if (!index.has(val)) {
        // Index current object and move to the next
        index.add(val);
        ++i;
      } else {
        // Remove current object from array
        array.splice(i, 1);
      }
    }
  }

  private static _removeDuplicateFromSourceFilters(filter: ISourceFilters): void {
    SearchService._removeDuplicate(filter.scopes, (scope) => scope.name);
    SearchService._removeDuplicate(filter.categories, (scope) => scope.name);
    SearchService._removeDuplicate(filter.subcategories, (scope) => scope.name);
  }

  static organizationToScopeFilter(organization: IPortalTree): IScopeFilter {
    return {
      id: organization?.id,
      name: organization?.name,
    };
  }

  static categoryToCategoryFilter(category: RootCategory | CustomCategory): ICategoryFilter {
    return {
      id: category.id,
      name: category.name,
      root: category.root,
    };
  }

  static subcategoryToSubcategoryFilter(subcategory: SubCategory): ISubcategoryFilter {
    return {
      id: subcategory.id,
      name: subcategory.name,
    };
  }

  // Constructor
  constructor(
    private readonly _route: ActivatedRoute,
    private readonly _router: Router,
    private readonly _currentUserService: CurrentUserService,
    private readonly _categoriesService: CategoriesService,
    private readonly _subCategoriesService: SubCategoriesService,
    private readonly _portalsService: PortalsService,
    private readonly _sourcesService: SourcesService,
    private readonly _logger: Logger,
  ) {
    const params = _route.snapshot.queryParamMap;

    // Load sources
    this._fetchAllSources();

    // If query params have not been loaded yet
    if (!this._firstFilterLoaded) {
      // If there is no filters related query params or no params at all
      if (!params.keys.length || (!params.has('categories') && !params.has('subcategories') && !params.has('scopes'))) {
        this._hasQueryParamsBeenLoaded = true;
        this._currentUserService.currentUser$.subscribe((user) => {
          if (!this._favoriteOrganizationLoaded && user) {
            // Load user favorite organization
            if (user.favoriteOrganization) {
              this._portalsService.portalsLoaded$.pipe(first((hasLoaded) => hasLoaded)).subscribe(() => {
                const orga = this._portalsService.findOne(user.favoriteOrganization);
                this._sendFirstScopeFilters([
                  {
                    name: orga?.name,
                    id: orga?.id,
                  },
                ]);
              });
            } else {
              this._sendFirstScopeFilters([]);
            }

            this._favoriteOrganizationLoaded = true;
          }
        });
      } else if (params?.get('scopes')) {
        const scopesNames = params.getAll('scopes');
        const nextScopes: IScopeFilter[] = [];

        // Load scopes
        this._portalsService.portals$.subscribe((portals) => {
          if (portals.length) {
            scopesNames.forEach((scopeName) => {
              const correspondingScope = portals.find((portal) => portal.name === scopeName);

              if (correspondingScope) {
                nextScopes.push({
                  id: correspondingScope.id,
                  name: correspondingScope.name,
                });
              }
            });

            this._sendFirstScopeFilters(nextScopes);
          }
        });
      } else {
        this._sendFirstScopeFilters([]);
      }

      // Restore type filters
      if (params?.get('type')) {
        const type = params.get('type').split(',');

        this._sendFirstTypeFilters({
          hasReports: type.includes('hasReports'),
          hasDatalake: type.includes('hasDatalake'),
        });
      } else {
        this._sendFirstTypeFilters({ hasReports: false, hasDatalake: false });
      }
    }

    this.firstScopeFilter$.subscribe((scopeFilters) => {
      if (scopeFilters && this._firstQueryParamsLoaded.scopes && !this._firstFilterLoaded) {
        const firstCategoriesFilters: ICategoryFilter[] = [];

        if (params?.get('categories')) {
          if (scopeFilters.length !== 1) {
            // Load only root categories query params
            const categoriesNames = params.getAll('categories');

            this._categoriesService.rootCategories$
              .pipe(first((rootCategories) => !!rootCategories?.length))
              .subscribe((rootCategories) => {
                categoriesNames.forEach((categoryName) => {
                  const correspondingRootCategory = rootCategories.find((rC) => rC.name === categoryName);

                  if (correspondingRootCategory) {
                    firstCategoriesFilters.push(SearchService.categoryToCategoryFilter(correspondingRootCategory));
                  }
                });
                this._sendFirstCategoryFilters(firstCategoriesFilters);
              });
          } else {
            // Load root and custom categories query params
            let categoriesNames = params.getAll('categories');

            this._categoriesService.rootCategories$.subscribe((rootCategories) => {
              categoriesNames.forEach((categoryName) => {
                const correspondingRootCategory = rootCategories.find((rc) => rc.name === categoryName);

                if (correspondingRootCategory) {
                  firstCategoriesFilters.push(SearchService.categoryToCategoryFilter(correspondingRootCategory));
                }
              });

              // Remove already loaded ones
              categoriesNames = categoriesNames.filter(
                (name) => !firstCategoriesFilters.some((filter) => filter.name === name),
              );

              // If there are custom categories to be loaded
              if (categoriesNames.length) {
                this._categoriesService.customCategories$.subscribe((customCategories) => {
                  for (const name of categoriesNames) {
                    const cat = customCategories.find((cC) => cC.name === name);

                    if (cat) {
                      firstCategoriesFilters.push(SearchService.categoryToCategoryFilter(cat));
                    }
                  }

                  this._sendFirstCategoryFilters(firstCategoriesFilters);
                });
              } else {
                this._sendFirstCategoryFilters(firstCategoriesFilters);
              }
            });
          }
        } else {
          this._sendFirstCategoryFilters([]);
        }
      }
    });

    this.firstCategoryFilter$.subscribe((categoryFilters) => {
      if (categoryFilters && this._firstQueryParamsLoaded.categories && !this._firstFilterLoaded) {
        // Load sub-categories query params
        if (params?.get('subcategories')) {
          const subcategoriesNames = params.getAll('subcategories');
          const nextSubcategoriesFilters: ISubcategoryFilter[] = [];

          this._subCategoriesService.portalCategories$.subscribe((subCategories) => {
            for (const name of subcategoriesNames) {
              const cat = subCategories.find((sc) => sc.name === name);

              if (cat) {
                nextSubcategoriesFilters.push({
                  id: cat.id,
                  name: cat.name,
                });
              }
            }

            this._sendFirstSubcategoryFilters(nextSubcategoriesFilters);
          });
        } else {
          this._sendFirstSubcategoryFilters([]);
        }
      }
    });

    this.firstSubcategoryFilter.subscribe((subcategoryFilters) => {
      if (subcategoryFilters && this._firstQueryParamsLoaded.subcategories && !this._firstFilterLoaded) {
        this._firstUpdateFilters();
      }
    });

    // Manage filters
    merge(this._filters$, this._query$).subscribe(() => this._refreshSources());

    this._filters$.subscribe((filters) => {
      if (this._firstFilterLoaded) {
        if (this._hasQueryParamsBeenLoaded) {
          this._updateQueryParams(filters);
        } else {
          this._hasQueryParamsBeenLoaded = true;
        }

        this._refreshSubcategories(filters);
      }

      this._previousFilter = filters;
    });
  }

  // Methods
  private _firstUpdateFilters(): void {
    this._firstFilterLoaded = true;

    this._nextFilter({
      scopes: this._firstScopeFilter$.getValue(),
      categories: this._firstCategoryFilter$.getValue(),
      subcategories: this._firstSubcategoryFilter$.getValue(),
      type: this._firstTypeFilter$.getValue(),
    });
  }

  private _nextFilter(filter: ISourceFilters): void {
    if (this._firstFilterLoaded) {
      // Remove duplicates
      SearchService._removeDuplicateFromSourceFilters(filter);
      this._filters$.next(filter);
    }
  }

  private _sendFirstScopeFilters(scopeFilters: IScopeFilter[]): void {
    this._firstQueryParamsLoaded.scopes = true;
    this._firstScopeFilter$.next(scopeFilters);
  }

  private _sendFirstCategoryFilters(categoryFilters: ICategoryFilter[]): void {
    this._firstQueryParamsLoaded.categories = true;
    this._firstCategoryFilter$.next(categoryFilters);
  }

  private _sendFirstSubcategoryFilters(subcategoryFilters: ISubcategoryFilter[]): void {
    this._firstQueryParamsLoaded.subcategories = true;
    this._firstSubcategoryFilter$.next(subcategoryFilters);
  }

  private _sendFirstTypeFilters(typeFilters: ITypeFilters): void {
    this._firstQueryParamsLoaded.type = true;
    this._firstTypeFilter$.next(typeFilters);
  }

  private _updateQueryParams(filters: ISourceFilters): void {
    const params: IQueryParams = {};

    // Build params from filters
    if (filters.scopes.length > 0) {
      params.scopes = Array.from(new Set(filters.scopes.map((scopeFilter) => scopeFilter.name)));
    }

    if (filters.categories.length > 0) {
      params.categories = Array.from(new Set(filters.categories.map((categoryFilter) => categoryFilter.name)));
    }

    if (filters.subcategories.length > 0) {
      params.subcategories = Array.from(
        new Set(filters.subcategories.map((subcategoryFilter) => subcategoryFilter.name)),
      );
    }

    // Build query type
    const type: string[] = [];

    if (filters.type.hasDatalake) {
      type.push('hasDatalake');
    }

    if (filters.type.hasReports) {
      type.push('hasReports');
    }

    if (type.length > 0) {
      params.type = type.join(',');
    }

    this._router.navigate([], {
      relativeTo: this._route,
      queryParams: params,
      queryParamsHandling: '',
    });
  }

  private _refreshSubcategories(filters: ISourceFilters): void {
    if (filters.scopes.length === 1) {
      this._currentPortal = filters.scopes[0].id;
      this._subCategoriesService.listByPortal(filters.scopes[0].id).subscribe((subcategories) => {
        this._subcategories$.next(subcategories);
      });

      this._categoriesService.setPortal(filters.scopes[0].id);
      this._categoriesService.listSourcesCategories(filters.scopes[0].id).subscribe((sourceCategories) => {
        this._sourcesCategories = sourceCategories;
      });
    } else if (filters.scopes.length !== 1) {
      this._subcategories$.next([]);
      this._customCategories$.next([]);
      this._categoriesService.resetSourcesCategories();
    }
  }

  private _refreshSources(): void {
    if (this._allSources) {
      const options = {
        distance: 100,
        threshold: 0.5,
        shouldSort: true,
        keys: ['id', 'name', 'description'],
      };

      this._loading$.next(true);

      const filters = this._filters$.getValue();
      const query = this._query$.getValue();

      const filteredSources: Source[] = this._allSources.filter((source) => this._matchFilters(filters, source));
      const fuse = new Fuse(filteredSources, options);
      const searchResult = query
        ? fuse
            .search(query)
            .map((fsr) => fsr.item)
            .reverse()
        : filteredSources;
      this._sources$.next(searchResult);
      this._loading$.next(false);
    }
  }

  private _fetchAllSources(): void {
    this._loading$.next(true);
    this._sourcesService.allSources().subscribe((allSources) => {
      this._allSources = allSources;
      this._refreshSources();
    });
  }

  private _matchFilters(filters: ISourceFilters, source: Source): boolean {
    // Simple case
    if (!filters) {
      return true;
    }

    // Type tests
    const hasReports = source.powerbi.length > 0;
    const hasDatalake = source.datalakePath.length > 0;

    if (filters.type.hasReports && !hasReports) {
      return false;
    }

    if (filters.type.hasDatalake && !hasDatalake) {
      return false;
    }

    // Test root categories
    const rootCategories = filters.categories.filter((c) => c.root === true);

    if (rootCategories.length > 0) {
      const hasNoCategories = rootCategories.some((c) => c.id === 'NONE');

      if (!hasNoCategories || source.categories.length > 0) {
        const isSourceMatched = source.categories.some((sourceCategory) => {
          return rootCategories.some((selectedCategory) => sourceCategory === selectedCategory.id);
        });

        if (!isSourceMatched) {
          return false;
        }
      }
    }

    // Test custom categories
    const customCategories = filters.categories.filter((c) => c.root === false);

    if (customCategories?.length > 0) {
      if (!this._sourcesCategories?.items?.get(source.id)) {
        return false;
      }

      const isSourceMatched = this._sourcesCategories.items
        .get(source.id)
        .some((c) => c.parent == null && customCategories.some((fc) => fc.id === c.id));

      if (!isSourceMatched) {
        return false;
      }
    }

    // Test scopes
    const isFilterByScopesPractical = filters.scopes != null && filters.scopes.length > 0;

    if (isFilterByScopesPractical) {
      const isSourceMatched = source.organizations.some((sourceOrganization) => {
        return filters.scopes.some((selectedScope) => selectedScope.id === sourceOrganization);
      });

      if (!isSourceMatched) {
        return false;
      }
    }

    // Test subcategories
    const isFilterBySubcategoryPractical = filters.subcategories != null && filters.subcategories.length > 0;

    if (isFilterByScopesPractical && isFilterBySubcategoryPractical && filters.scopes.length === 1) {
      const sourceSubcategories = this._sourcesCategories.items.get(source.id);

      if (!sourceSubcategories) {
        return false;
      }

      const isSourceMatched = filters.subcategories.some((selectedSubcategory) =>
        sourceSubcategories.some((sourceSubcategory) => sourceSubcategory.id === selectedSubcategory.id),
      );

      if (!isSourceMatched) {
        return false;
      }
    }

    return true;
  }

  toggleCategory(category: ICategoryFilter): void {
    const filters = { ...this._filters$.getValue() };

    const idx = filters.categories.findIndex((c) => c.id === category.id);

    if (idx !== -1) {
      filters.categories.splice(idx, 1);
    } else {
      filters.categories.push(category);
    }

    this._nextFilter(filters);
  }

  toggleSubcategory(subcategory: ISubcategoryFilter): void {
    const filters = { ...this._filters$.getValue() };

    const idx = filters.subcategories.findIndex((sc) => sc.id === subcategory.id);

    if (idx !== -1) {
      filters.subcategories.splice(idx, 1);
    } else {
      filters.subcategories.push(subcategory);
    }

    this._nextFilter(filters);
  }

  updateQueryParams(): void {
    this._updateQueryParams(this._filters$.getValue());
  }

  resetService(): void {
    this._firstFilterLoaded = false;
    this._favoriteOrganizationLoaded = false;
    this._hasQueryParamsBeenLoaded = false;
    this._firstQueryParamsLoaded.scopes = false;
    this._firstQueryParamsLoaded.categories = false;
    this._firstQueryParamsLoaded.subcategories = false;
    this._firstQueryParamsLoaded.type = false;

    this._nextFilter({
      categories: [],
      subcategories: [],
      scopes: [],
      type: { hasReports: false, hasDatalake: false },
    });
  }

  clearCategories(): void {
    this._nextFilter({
      ...this._filters$.getValue(),
      categories: [],
    });
  }

  selectScope(portal: Portal): void {
    const newFilters = {
      ...this._filters$.getValue(),
      scopes: [],
    };

    const scope = SearchService.organizationToScopeFilter(this._portalsService.findById(portal?.id));

    if (!scope) {
      this._logger.error('[SearchService] Cannot find organization as a tree element:', portal);

      return;
    }

    this._setScope(scope, newFilters, true);
    this._nextFilter(newFilters);
  }

  toggleScope(scope: IScopeFilter): void {
    const newFilters = { ...this._filters$.getValue() };

    // Find scope previous state
    const wasThereAnOldScope = newFilters.scopes.some((org) => org.id === scope.id);

    // Then recursively toggle scope and children
    this._setScope(scope, newFilters, !wasThereAnOldScope);
    this._nextFilter(newFilters);
  }

  private _setScope(organization: IScopeFilter, newFilters: ISourceFilters, newState: boolean): void {
    if (newState === true) {
      newFilters.scopes.push(organization);
    } else {
      newFilters.scopes = newFilters.scopes.filter((scope) => scope.id !== organization.id);
    }
  }

  get getSources() {
    return this._sources$.getValue();
  }

  resetScopes(): void {
    this._nextFilter({
      categories: this._filters$.getValue().categories.filter((cat) => cat.root),
      scopes: [],
      subcategories: [],
      type: this._filters$.getValue().type,
    });
  }

  updateQuery(newQuery: string): void {
    this._query$.next(newQuery);
  }

  resetQuery(): void {
    this._query$.next('');
  }

  toggleType(key: keyof ITypeFilters): void {
    const filters = { ...this._filters$.getValue() };
    const wasToggled = !!filters.type[key];
    filters.type[key] = !wasToggled;

    this._nextFilter(filters);
  }
}
