import type { OnInit } from '@angular/core';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { CurrentUserService } from '@dataportal/auth';
import { AlertService } from '@dataportal/front-shared';
import { PermissionsService } from '@dataportal/permissions';
import type { LimitedUser } from '@dataportal/users';
import { ImpersonateService } from '@dataportal/users';

@Component({
  selector: 'dpg-impersonate-modal',
  templateUrl: './impersonate-modal.component.html',
  styleUrls: ['./impersonate-modal.component.scss'],
})
export class ImpersonateModalComponent implements OnInit {
  readonly dataAssetId: string;

  selectedLimitedUser: LimitedUser;
  isAdmin: boolean;
  canImpersonate: boolean;

  clearSelectList: Subject<void> = new Subject<void>();

  constructor(
    private readonly _activeMatModal: MatDialogRef<ImpersonateModalComponent>,
    private readonly _impersonateService: ImpersonateService,
    private readonly _currentUserService: CurrentUserService,
    private readonly _permissionsService: PermissionsService,
    private readonly _alertService: AlertService,
    @Inject(MAT_DIALOG_DATA) data: { dataAssetId: string },
  ) {
    this.dataAssetId = data.dataAssetId;
  }

  ngOnInit(): void {
    this._currentUserService.isAdmin$.subscribe((isAdmin) => (this.isAdmin = isAdmin));
  }

  cancel(): void {
    this._activeMatModal.close(true);
  }

  impersonate(): void {
    if (this.canImpersonate) {
      this._impersonateService.setImpersonate(this.selectedLimitedUser, false);
      this._activeMatModal.close(true);
    } else {
      this._alertService.warning(`You are not authorized to impersonate ${this.selectedLimitedUser.name}`);
    }
  }

  selectUser($event: LimitedUser): void {
    this.selectedLimitedUser = $event;
    this.canImpersonate = null;

    if (this.isAdmin) {
      this.canImpersonate = true;
    } else {
      this._permissionsService
        .isAuthorized('impersonate', 'users', this.dataAssetId ?? this.selectedLimitedUser.id)
        .subscribe((isAuthorized) => (this.canImpersonate = isAuthorized));
    }
  }

  close(exitStatus: boolean): void {
    this._activeMatModal.close(exitStatus);
  }
}
