import type { IBidirectionalLink } from '@dataportal/types';
import { LinkDirection } from '@dataportal/types';

import { SourceLink } from './sourceLink';

export const isBidirectionalLink = (link: SourceLink): link is BidirectionalLink => {
  return link.type === LinkDirection.BIDIRECTIONAL;
};

export class BidirectionalLink extends SourceLink {
  // Attributes
  type: LinkDirection = LinkDirection.BIDIRECTIONAL;
  reverseId: string = null;

  fromJson(jsonData: IBidirectionalLink): BidirectionalLink {
    super.fromJson(jsonData);
    this.reverseId = jsonData.reverseId;
    this.reverseLabel = jsonData.reverseLabel;
    this.reverseEditable = jsonData.reverseEditable;
    this.editable = jsonData.editable;
    this.approvalRequired = jsonData.approvalRequired;

    return this;
  }

  // Methods
  toJson(): IBidirectionalLink {
    const common = super.toJson();

    return {
      ...common,
      type: LinkDirection.BIDIRECTIONAL,
      reverseId: this.reverseId || null,
      reverseLabel: this.reverseLabel || null,
      editable: this.editable,
      reverseEditable: this.reverseEditable,
      approvalRequired: this.approvalRequired,
    };
  }
}
