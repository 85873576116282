import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AlertService, Logger } from '@dataportal/front-shared';
import { FormControl } from '@ngneat/reactive-forms';

import { PowerBiBookmarksService } from '../../services/power-bi-bookmarks.service';

import type { Source } from '../../../sources/entities/source';
import type { Dashboard } from '../../entities/dashboard.model';
import type { IDashboardBookmark } from '../../services/power-bi-bookmarks.service';

// Component
@Component({
  selector: 'dpg-dashboard-share-bookmark-modal',
  templateUrl: './dashboard-rename-bookmark-modal.component.html',
  styleUrls: ['./dashboard-rename-bookmark-modal.component.scss'],
})
export class DashboardRenameBookmarkModalComponent {
  // Attributes
  source: Source;
  dashboard: Dashboard;
  bookmark: IDashboardBookmark;

  newNameControl = new FormControl<string>();

  constructor(
    private readonly _logger: Logger,
    private readonly _alertService: AlertService,
    private readonly _powerBiBookmarksService: PowerBiBookmarksService,
    private readonly _activeMatModal: MatDialogRef<DashboardRenameBookmarkModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    data: {
      bookmark: IDashboardBookmark;
      source: Source;
      dashboard: Dashboard;
    },
  ) {
    this.bookmark = data?.bookmark ? data.bookmark : this.bookmark;
    this.source = data?.source ? data.source : this.source;
    this.dashboard = data?.dashboard ? data.dashboard : this.dashboard;
  }

  close(): void {
    this._activeMatModal.close();
  }

  save(): void {
    const updatedBookmark: IDashboardBookmark = { ...this.bookmark, name: this.newNameControl.value };
    this._powerBiBookmarksService.updateBookmark(this.source.id, this.dashboard.name, updatedBookmark).subscribe(() => {
      this._alertService.success('Bookmark successfully renamed');
      this._activeMatModal.close();
    });
  }
}
