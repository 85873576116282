<section class="logo-app">
  <img
    src="../../assets/img/v2/common/data-portal-by-pernod-ricard.svg"
    alt="Data Portal by Accor"
    style="height: 90px"
  />
</section>
<section class="support-app">
  <div class="flex flex-col justify-start items-center w-full">
    <h2 class="support-title w-full mb30">Help</h2>
    <div class="flex md:flex-row md:space-x-10 flex-col justify-center items-center w-1/2">
      <div class="support-card contact md:w-1/3 mb-8 w-full">
        <a (click)="openContact()">
          <em class="icon contact"></em>
          <div class="title">Contact</div>
          <div class="description">Contact us for any reasons</div>
        </a>
      </div>
      <div class="support-card documentation md:w-1/3 mb-8 w-full">
        <a (click)="openDocumentation()">
          <em class="icon documentation"></em>
          <div class="title">User Manual</div>
          <div class="description">Find the answer to your questions</div>
        </a>
      </div>
      <div class="support-card support md:w-1/3 mb-8 w-full">
        <a (click)="openSupport()">
          <em class="icon support"></em>
          <div class="title">Support</div>
          <div class="description">Ask us to solve your issues</div>
        </a>
      </div>
    </div>
  </div>
</section>
