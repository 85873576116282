import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiService } from '@dataportal/front-api';
import { EntityBuilder } from '@decahedron/entity';

import { DatabrickWorkspace } from '../entities/databrick';

// Services
@Injectable()
export class DatabricksService {
  // Constructor
  constructor(private readonly _apiService: ApiService) {}

  // Methods
  listWorkspacesUrls(): Observable<DatabrickWorkspace[]> {
    return this._apiService.get<DatabrickWorkspace[]>('/v4/databricks/workspaces').pipe(
      map((databrickWorkspace) => {
        return EntityBuilder.buildMany<DatabrickWorkspace>(DatabrickWorkspace, databrickWorkspace);
      }),
      catchError(() => of(Array<DatabrickWorkspace>())),
    );
  }
}
