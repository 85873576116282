import type { OverlayRef } from '@angular/cdk/overlay';
import type { Observable } from 'rxjs';
import { Subject } from 'rxjs';

export class DialogRef<R = undefined> {
  private readonly _afterClosedSubject = new Subject<R>();

  constructor(private readonly _overlayRef: OverlayRef) {}

  close(result?: R) {
    this._overlayRef.dispose();
    this._afterClosedSubject.next(result);
    this._afterClosedSubject.complete();
  }

  afterClosed(): Observable<R> {
    return this._afterClosedSubject.asObservable();
  }
}
