import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { ApiService } from '@dataportal/front-api';
import type { IGlossaryActiveAffiliate } from '@dataportal/accor-glossary-types';

@Injectable()
export class GlossaryActiveAffiliateService {
  private readonly _glossaryAPIUrl = '/v4/glossary';

  constructor(private readonly _apiService: ApiService) {}

  getActiveAffiliates(): Observable<IGlossaryActiveAffiliate[]> {
    return this._apiService.get(`${this._glossaryAPIUrl}/active-affiliates`);
  }
}
