<div class="card column-flex j-center a-center">
  <em
    dpgStopClickBubbling
    class="corner-fav icomoon mb5"
    (click)="this.toggleFavoriteDashboard()"
    [class.is-active]="this.isActive"
  ></em>
  <div class="title">{{ this.dashboard.name }}</div>
  <dpg-dashboard-image [dashboardImageSuffix]="this.dashboard.image"></dpg-dashboard-image>
  <adl-button (clicked)="this.openDashboard()">
    View
    <dpg-dashboard-new-tab [source]="this.source" [dashboardName]="this.dashboard.name" [isOwner]="isOwner"></dpg-dashboard-new-tab>
  </adl-button>
</div>
