import type { OnInit } from '@angular/core';
import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';

import { AlertType } from '../alert-title/alert-title.component';

const STRING_TO_TOASTER_TYPE: { [key in string]: AlertType } = {
  'toast-success': 'success',
  'toast-error': 'error',
  'toast-warning': 'warning',
  'toast-info': 'info',
};

@Component({
  selector: 'adl-toaster',
  templateUrl: './toaster.component.html',
  styleUrls: ['./toaster.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ToasterComponent extends Toast implements OnInit {
  type: AlertType;

  constructor(protected toastrService: ToastrService, public toastPackage: ToastPackage) {
    super(toastrService, toastPackage);
  }
  ngOnInit(): void {
    this.type = STRING_TO_TOASTER_TYPE[this.toastPackage.toastType];
    this.title = this._getTitle();
    this.message = this.toastPackage.message;
  }

  private _getTitle() {
    return this.toastPackage.title || this.type.charAt(0).toUpperCase() + this.type.slice(1);
  }
}
