import { InjectionToken } from '@angular/core';

// Constants
export const GRAPH_API_OPTIONS = new InjectionToken<IGraphApiOptions>('dataportal-front:graph-api:options');

// Types
export interface IGraphApiOptions {
  // Options
  defaultMode: 'native' | 'graphAPI';
}
