import { Component, Input } from '@angular/core';

import { IconType, SupportedFaIcon } from '../icons';

export type IconAnimation = 'bounce';

@Component({
  selector: 'adl-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
})
export class IconComponent {
  /* inputs */
  // required
  @Input() icon: SupportedFaIcon;
  // optional
  @Input() isIconSpinning = false;
  @Input() size = 18;
  @Input() bold = false;
  @Input() disabled = false;
  @Input() iconType: IconType = 'inherit';
  @Input() animation: IconAnimation;

  getColorClassName(): string {
    return `color-${this.iconType}`;
  }
}
