<div class="is-active-label cursor-default" [ngClass]="activeTheme" [style.fontSize]="size + 'px'" *ngIf="isActive; else inactive">
  <img *ngIf="activeImgIcon" class="w-[12px] mr-2" src="assets/dpg/imgs/icons/{{ activeImgIcon }}.svg"/>
  <adl-icon class="mr-2" *ngIf="activeIcon" [icon]="activeIcon" iconType="inherit" [size]="13" [bold]="false"></adl-icon>
  {{ activeText }}
</div>
<ng-template #inactive>
  <div class="is-active-label cursor-default" [ngClass]="inactiveTheme" [style.fontSize]="size + 'px'">
    <img *ngIf="inactiveImgIcon" class="w-[10px] mr-2" src="assets/dpg/imgs/icons/{{ inactiveImgIcon }}.svg"/>
    <adl-icon class="mr-2" *ngIf="inactiveIcon" [icon]="inactiveIcon" iconType="inherit" [size]="13"></adl-icon>
    {{ inactiveText }}
  </div>
</ng-template>
