import { LinkDirection, SourceRelationshipStatus } from '@dataportal/types';

import { SourceRelationship } from './source-relationship';

export class UnidirectionalSourceRelationship extends SourceRelationship {
  type = LinkDirection.UNIDIRECTIONAL;

  get status(): SourceRelationshipStatus {
    return this._status;
  }

  get canBeDeleted(): boolean {
    return this._status === SourceRelationshipStatus.ACTIVE;
  }
}
