// get from entities/guardian.ts --> // FIXME: [guardian] remove this file after publishing @dataportal/accor-types and once Guardian API is stable
import type { IBaseGuardianDatalakePath } from '@dataportal/front-shared';
/**
 * Cron "* *¨* * *" format
 */
type Cron = string;
/**
 * Date
 * i.e: 2021-10-07T13:30:49.707000
 */
type DatetimeISO = string;

export interface IErrorType {
  /**
   * The error indicator
   * Number: Number of errors
   * Boolean: If there is any error
   */
  error: number | boolean;
  /**
   * The error message linked to the error
   * String: The error message
   * (number | string)[]: The enumerated incorrect values
   */
  hint: (number | string)[] | string;
  /**
   * The different lines in which there are errors
   * Can be null depending of the error type
   */
  hint_index: number[];
}

export interface IDataQuality {
  /**
   * The number of lines of the checked dataset file
   */
  size: number;
  /**
   * Object containing every check error associated to the column name
   */
  columns_errors: {
    [column_name: string]: {
      [error_type: string]: IErrorType;
    };
  };
  /**
   * @see Guardian API documentation
   * Not used for now
   */
  extra_errors: unknown;
}

export interface IAPIGuardianCallError {
  /**
   * Guardian API response error type
   * i.e: 'GuardianNoFileFoundError' / 'GuardianEmptyFileError' / 'GuardianReadFileError' / 'GuardianSchemaError'
   * @see Guardian API documentation for more information
   */
  type?: string;
  /**
   * Guardian API response error detailed/technical message
   */
  message?: string;
}

export type GuardianCheckStatus = 'in_progress' | 'errored' | 'succeed' | 'crashed';

export interface ICheckRun {
  /**
   * Guardian check run ID
   */
  id_run?: number;
  /**
   * Guardian check configuration ID
   */
  id_check: number;
  /**
   * Guardian check configuration name/title
   */
  name?: string;
  /**
   * Date when the check was run/executed by Guardian
   * i.e: 2021-10-07T13:30:49.707000
   */
  check_time_utc: DatetimeISO;
  /**
   * Guardian check run errors result
   */
  data_quality?: IDataQuality;
  /**
   * The Guardian type of check
   * 'dataset': Check on the file dataset
   * 'up-to-date': Check on the path freshness
   */
  check_type: 'dataset' | 'up-to-date';
  /**
   * Guardian check run status
   */
  status: GuardianCheckStatus;
  /**
   * Request ID
   */
  id_request?: string;
  /**
   * Guardian check request/run error
   */
  crash_error?: IAPIGuardianCallError;
}

export interface IRequestCheckFieldOptions extends IRequestCheckFieldOptionsCommon {
  /**
   * The column name
   */
  column_name: string;
}

/* Guardian check regex */

export enum CheckRegexType {
  /**
   * The "regex" category for column check "regex" options
   */
  FIELD_CHECK = 'FIELD_CHECK',
  /**
   * The "regex" category for filtering file names to check "regex" options
   */
  NAME_FILTER_CHECK = 'NAME_FILTER_CHECK',
  /**
   * The "regex" category for renaming files "regex" options
   */
  RENAMING_FILE_CHECK = 'RENAMING_FILE_CHECK',
}

export interface ICheckRegexSchema {
  /**
   * Guardian check regex type
   */
  type: CheckRegexType;
  /**
   * Guardian check regex label
   */
  label: string;
  /**
   * Guardian check regex value
   */
  regex: string;
}

export interface ICheckRegex extends ICheckRegexSchema {
  /**
   * uuid
   */
  pk: string;
  /**
   * regex|checks|type|<type>
   */
  sk: string;
  /**
   * regex|checks
   */
  ak: string;
}

/* Guardian external referential */

/**
 * Guardian API external referential definition type
 */
export interface IExternalReferential {
  /**
   * slugified label
   */
  pk: string;
  /**
   * referential|checks|external|category|<category>
   */
  sk: string;
  /**
   * referential|checks|external
   */
  ak: string;
  /**
   * External referential label
   */
  label: string;
  /**
   * External referential slugified category name
   * i.e: mdh
   */
  category: string;
  /**
   * External referential infos
   */
  infos: APIReferential;
}
export type APIReferential = IAPIDatalakeReferential | IAPITableReferential | string[];

export interface IAPIDatalakeReferential extends IDatalakeReferentialDatalakeInfos {
  /**
   * Datalake referential file type/extension
   * i.e: 'csv' or 'xlsx'
   */
  file_format?: string;
  /**
   * Datalake referential file delimiter when speaking about CSV file
   */
  delimiter?: string;
  /**
   * File sheet name to reference when speaking about Excel file
   */
  sheet_name?: string;
  /**
   * File column name to reference
   */
  column?: string;
  /**
   * Indicates the datalake referential file encoding
   * i.e: 'utf-8'
   */
  encoding?: string;
}

export interface IDatalakeReferentialDatalakeInfos extends ICheckDatalakeCommon {
  /**
   * Datalake referential extra information
   */
  datalake_params?: {
    /**
     * Datalake storage account (Azure side)
     * i.e: https://stoasidatalakeprod.dfs.core.windows.net
     */
    azure_storage_account?: string;
    [param: string]: string;
  };
}

export interface IRequestCheckFieldOptionsCommon {
  /**
   * The column/field type
   * i.e: 'integer' or 'string'
   */
  type?: string;
  /**
   * The constraint ID in the Guardian check configuration to preserve the column order since their store a dict Guardian side
   */
  constraint_id?: number;
  /**
   * Indicates if every cell of this column is required or not
   */
  required_values?: boolean;
  /**
   * Indicates if each cell of this column must be unique or not
   */
  unique?: boolean;
  /**
   * Indicates the minimum value (when speaking about numbers or dates)
   */
  min?: number | string;
  /**
   * Indicates the maximum value (when speaking about numbers or dates)
   */
  max?: number | string;
  /**
   * Indicates the regex that will be used to check the cell value
   */
  regex?: string;
  /**
   * Specifies the external referential to check if column values are included in the specified referential set of values or not
   */
  values_in?: APIReferential;
  /**
   * The Data Portal formatted datalake path corresponding to Guardian "values_in" field
   */
  datalake_path_in?: IBaseGuardianDatalakePath;
  /**
   * Specifies the external referential to check if column values are NOT included in the specified referential set of values or not
   */
  values_not_in?: APIReferential;
  /**
   * The Data Portal formatted datalake path corresponding to Guardian "values_not_in" field
   */
  datalake_path_not_in?: IBaseGuardianDatalakePath;
  /**
   * Specifies the external referential to check if the column set of values is all included in the specified referential set of values or not
   */
  values_contain?: APIReferential;
  /**
   * Specifies the external referential to check if the column set of values is equal specified referential set of values or not
   */
  values_equal?: APIReferential;
  /**
   * Specifies the column/field name related to this the "greater or equal" check :
   * The "greater or equal" check : Checks if this current column/field is greater or equal than
   * the related column/field (by comparing row by row)
   */
  pair_greater?: string;
}

export interface IGuardianAPIRequestCheckFieldOptions {
  /**
   * The column name
   */
  [column_name: string]: IRequestCheckFieldOptionsCommon;
}

export interface ICheckDatalakeCommon {
  /**
   * Datalake bucket name (Azure or AWS side)
   * i.e: asia-datalake-project-play
   */
  bucket_name: string;
  /**
   * Datalake path to the file
   * i.e: pr-group/it/my-file
   */
  prefix: string;
  /**
   * Datalake provider name
   * i.e: 'adls' or 's3'
   */
  datalake: string;
}

export interface ICheckCommon {
  /**
   * Guardian check name/title
   */
  name: string;
  /**
   * Encoding that will be take into account when running Guardian check
   * i.e: 'utf-8'
   */
  encoding?: string;
  /**
   * Cron that indicates when the Guardian check will be run
   */
  dag_schedule_interval?: Cron;
  /**
   * Cron that indicates the Guardian check run frequency
   */
  frequency?: Cron;
  /**
   * The decimal separator when speaking about number in the checked file
   */
  decimal?: string;
  /**
   * Indicates if the file is compressed or not
   */
  is_zip: number;
  /**
   * Indicates the file type/extension
   * i.e: 'csv' / 'xlsx' / 'json'
   */
  file_format: string;
  /**
   * User mails to be alerted when check is ok
   * String containing either "email1" or "[email1,email2]" if multiple
   */
  email_if_ok: string;
  /**
   * User mails to be alerted when check is not ok
   * String containing either "email1" or "[email1,email2]" if multiple
   */
  email_if_not_ok: string;
  /**
   * User mails to be alerted when check has encountered a technical error
   * String containing either "email1" or "[email1,email2]" if multiple
   */
  email_if_exception: string;
  /**
   * Indicates the file delimiter when speaking about CSV files
   */
  delimiter?: string;
  /**
   * Indicates the sheet name to check when speaking about Excel files
   */
  sheet_name?: string;
  /**
   * String containing either "folder1" or "[folder1,folder2]" if multiple
   */
  target_folder_if_ok?: string;
  /**
   * String containing either "folder1" or "[folder1,folder2]" if multiple
   */
  target_folder_if_error?: string;
  /**
   * If check is ok, specifying the format in which the checked file will be renamed
   */
  file_renaming_format_if_ok?: string;
  /**
   * If check is not ok, specifying the format in which the checked file will be renamed
   */
  file_renaming_format_if_error?: string;
  /**
   * Indicates the number of rows to skip at the beginning of the checked file
   */
  skiprows?: number;
  /**
   * Indicates the number of rows to skip at the end of the checked file
   */
  skipfooter?: number;
  /**
   * Indicates if Guardian should force the file override or not when moving
   */
  enforce_replacement: boolean;
  /**
   * The regex that will be used when selecting the last file to check on the current folder path
   */
  regex?: string;
  /**
   * Indicates if Guardian will generate a check resulting file or not
   */
  write_result: boolean;
  /**
   * Indicates the creation date of the file
   * i.e: 2021-10-07T13:30:49.707000
   */
  row_created?: DatetimeISO;
  /**
   * Indicates the last update date of the file
   * i.e: 2021-10-07T13:30:49.707000
   */
  row_updated?: DatetimeISO;
  /**
   * Limit for error hint part, '-1' is equivalent to "no limit"
   */
  hint_limit?: number;
}

export interface ICheckDatalakeInfos extends ICheckDatalakeCommon {
  /**
   * Datalake storage account (Azure side)
   * i.e: https://stoasidatalakeprod.dfs.core.windows.net
   */
  storage_account: string;
}

export interface ICheckSnowflakeInfos extends IAPITableReferential {
  /**
   * role
   */
  role: string;
  /**
   * warehouse
   */
  warehouse: string;
}

export interface IGuardianAPIDatalakeRequestCheckOptions extends ICheckDatalakeInfos, ICheckCommon {
  /**
   * - BE CAREFUL, columns are not ordered since this is a dict
   * - Order is only specified inside IRequestCheckFieldOptionsCommon.constraint_id
   */
  validation_schema_columns?: IGuardianAPIRequestCheckFieldOptions;
  /**
   * @see Guardian API documentation
   * Not used for now
   */
  validation_schema_extra?: unknown;
}

export interface IGuardianAPISnowflakeRequestCheckOptions extends ICheckSnowflakeInfos, ICheckCommon {
  /**
   * - BE CAREFUL, columns are not ordered since this is a dict
   * - Order is only specified inside IRequestCheckFieldOptionsCommon.constraint_id
   */
  validation_schema_columns?: IGuardianAPIRequestCheckFieldOptions;
  /**
   * @see Guardian API documentation
   * Not used for now
   */
  validation_schema_extra?: unknown;
}

export interface IAPITableReferential {
  /**
   * The SQL database engine name
   * i.e: snowflake
   */
  sql_engine: string;
  /**
   * The SQL database table name
   */
  sql_table: string;
  /**
   * The SQL database schema name
   */
  sql_schema: string;
  /**
   * The SQL database name
   */
  sql_database: string;
  /**
   * Datalake referential extra information
   */
  engine_params: {
    /**
     * The SQL database warehouse name
     */
    warehouse: string;
    [param: string]: string;
  };
  /**
   * THe SQL database table column name
   */
  column: string;
}

export interface ISnowflakeAPICheck extends IGuardianAPISnowflakeRequestCheckOptions {
  /**
   * The Guardian check configuration ID
   */
  id_check: number;
}

export interface IDatalakeAPICheck extends IGuardianAPIDatalakeRequestCheckOptions {
  /**
   * The Guardian check configuration ID
   */
  id_check: number;
}

export interface IGuardianAPIChecksMap {
  datalakePath: IDatalakeAPICheck;
  snowflake: ISnowflakeAPICheck;
}
