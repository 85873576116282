import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';
import { DialogsService, ModalSizes } from '@dataportal/adl';
import { LogsService } from '@dataportal/logs';
import type { Portal } from '@dataportal/portals';
import type { Source } from '@dataportal/sources-dashboards-recommendations';

import { SourceModalComponent } from '../components/source/source-modal/source-modal.component';

@Injectable()
export class SourceModalsService {
  constructor(
    private readonly _dialogService: DialogsService,
    private readonly _location: Location,
    private readonly _logService: LogsService,
  ) {}

  openSourceModal(source: Source, organizations: Portal[], hasToUpdateUrl = true): void {
    if (hasToUpdateUrl) {
      this._location.go(`sources/${source.id}`);
    }

    this._logService
      .post({
        action: 'page_view',
        previous_url: '/',
        current_url: `sources/${source.id}`,
      })
      .subscribe();

    const modal = this._dialogService.open<{ source: Source; portals: Portal[] }, boolean>(
      SourceModalComponent,
      {
        source: source,
        portals: organizations,
      },
      ModalSizes.XL,
    );

    modal.pipe(take(1)).subscribe((hasNoRouteBack) => {
      if (!hasNoRouteBack) {
        this._location.back();
      }
    });
  }
}
