import { inject, Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BehaviorSubject } from 'rxjs';
import { Logger } from '@dataportal/front-shared';
import * as Papa from 'papaparse';
import * as XLSX from 'xlsx';

import { DATALAKE_OPTIONS } from '../datalake-options';
import type { IDatalakeObject } from '../entities/datalake-parse-object';

export type SupportedFileType = 'csv' | 'xls' | 'json' | 'txt' | 'unsupported' | 'xlsx';

export interface ISortEvent {
  column: {
    $$id: string;
    isFirstChange: boolean;
    isTreeColumn: boolean;
    name: string; // ex: B
    prop: string; // ex: b
  };
  newValue: 'asc' | 'desc';
  prevValue: 'asc' | 'desc' | undefined;
  sorts: Array<{ dir: 'asc' | 'desc'; prop: string }>;
}

const BOM_PREFIX_BASE_64 = '77u/';

/**
 * This service should not be used as singleton (should be set inside the host component's providers)
 */
@Injectable()
export class FileParserAndFormatterService {
  fileType = new Map([
    ['xls', ['application/vnd.ms-excel']],
    ['xlsx', ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']],
    ['xlsm', ['application/vnd.ms-excel', 'application/vnd.ms-excel.sheet.macroEnabled.12']],
    ['xlsb', ['application/vnd.ms-excel', 'application/vnd.ms-excel.sheet.binary.macroEnabled.12']],
    ['csv', ['application/vnd.ms-excel', 'application/octet-stream', 'text/csv']],
    ['json', ['application/json', 'text/x-json', 'text/plain']],
    ['txt', ['text/plain']],
  ]);
  // constants
  extensionsOverviewable = ['xls', 'xlsx', 'xlsm', 'xlsb', 'csv', 'json'];
  extensionsEditable = ['csv'];

  // globals
  private _datasets: { rows: unknown[]; columns: unknown[] }[] = [];
  private _csvDatasets: { content: string; sheetName?: string }[] = [];
  // for json overview
  private _jsonDataset: Record<string, unknown> = {};
  // for xls overview
  private _sheetNames: string[] = [];
  // for csv editing
  private _parsedCSVFile: unknown[] = [];
  private _parsedCSVFileDelimiter = ',';
  private _csvFile: string;
  // pagination
  private _currentRowsForPage: unknown[] = [];
  private _currentPageNumber = 0;
  // indicators
  private _hasErrorInParsing = false;
  private _hasFinishedParsingAndFormatting = false;
  // indicators as observable
  private readonly _hasFinishedParsingAndFormatting$ = new BehaviorSubject<boolean>(false);
  readonly hasFinishedParsingAndFormatting$ = this._hasFinishedParsingAndFormatting$.asObservable();

  // PARSING
  // used by papaparse
  // private readonly _regCSVElems = /("([^"]*",?)|(-?[\d]+[.[\d]+]?,?))|("([^"]*"[\t]?)|(-?[\d]+[.[\d]+]?[\t]?))|("([^"]*"\|?)|(-?[\d]+[.[\d]+]?\|?))|("([^"]*";?)|(-?[\d]+[.[\d]+]?;?))|("([^"]*"[\x1E]?)|(-?[\d]+[.[\d]+]?[\x1E]?))|("([^"]*"[\x1F]?)|(-?[\d]+[.[\d]+]?[\x1F]?))|[\n]/g;
  private readonly _parseCSVOptions = {
    // dynamicTyping: true, // to cast in types (number etc.)
    skipEmptyLines: true, // skipping empty lines
    header: false, // not the same JSON building behavior as lib XLSX (if false, using array)
  };
  // used by sheetJS xlsx
  private readonly _parseXLSOptions: unknown = {
    raw: false, // not formatted cases value
    header: 'A', // default first data row is set to 'A', 'B', ..., 'Z', 'AA', 'AB', ...
    defval: null, // allows blank cases (and blank cases value is set to 'null')
    blankrows: true, // allows blank rows
    dateNF: 'dd-mm-yyyy',
  };
  // used by sheetJS xlsx
  private readonly _readXLSOptions: XLSX.ParsingOptions = {
    type: 'base64',
    sheetStubs: true,
    cellDates: true,
  };

  private readonly _readXLSOptionsFlatFile: XLSX.ParsingOptions = {
    ...this._readXLSOptions,
  };

  // FORMATTING (for display)
  // used by lib ngx-datatable
  private readonly _fitToWidthMargin = 25;
  private readonly _calculatePaginationHeightMargin = 350;
  private readonly _calculatePaginationHeightNewTabMargin = 180;
  private readonly _differencePaginationHeightCsvXlsMargin = 80;
  private readonly _defaultFontFamily = '"Open Sans", sans-serif';
  private readonly _defaultFontSize = '14px';
  private readonly _defaultFontStyle = 'normal';
  private readonly _defaultFontWeight = '400';
  private readonly _paginationNbPerPageMax = 100;

  private readonly _logger = inject(Logger);
  private readonly _domSanitizer = inject(DomSanitizer);
  readonly options = inject(DATALAKE_OPTIONS);

  get parsedCSVFile(): unknown[] {
    return this._parsedCSVFile;
  }

  get csvFile(): string {
    return this._csvFile;
  }

  get datasets(): { rows: unknown[]; columns: unknown[] }[] {
    return this._datasets;
  }

  get csvDatasets(): { content: string; sheetName?: string }[] {
    return this._csvDatasets;
  }

  get jsonDataset(): Record<string, unknown> {
    return this._jsonDataset;
  }

  get sheetNames(): string[] {
    return this._sheetNames;
  }

  get csvDataset(): { rows: unknown[]; columns: unknown[] } {
    return this.datasets?.length ? this.datasets[0] : null;
  }

  get currentRowsForPage(): unknown[] {
    return this._currentRowsForPage;
  }

  get currentPageNumber(): number {
    return this._currentPageNumber;
  }

  get hasErrorInParsing(): boolean {
    return this._hasErrorInParsing;
  }

  get hasFinishedParsingAndFormatting(): boolean {
    return this._hasFinishedParsingAndFormatting;
  }

  get paginationNbPerPageMax(): number {
    return this._paginationNbPerPageMax;
  }

  private static _numToLettersColumn(num: number): string {
    let letters = '';
    let tmp;

    while (num > 0) {
      tmp = (num - 1) % 26;
      letters = String.fromCharCode(65 + tmp) + letters;

      /* eslint-disable-next-line no-bitwise */
      num = ((num - tmp) / 26) | 0;
    }

    return letters || undefined;
  }

  private static _lettersToNumColumn(letters: string): number {
    let num = 0;
    let tmp;

    for (const character of letters) {
      tmp = character.charCodeAt(0) - 'a'.charCodeAt(0) + 1;
      num = 26 * num + tmp;
    }

    return num;
  }

  getFileExtension(fileName: string): SupportedFileType {
    return fileName ? (fileName.split('.').pop() as SupportedFileType) : 'unsupported';
  }

  checkFileContentType(fileExtension: string, fileContentType: string): boolean {
    if (fileExtension === null || !this.fileType.get(fileExtension)) {
      return false;
    } else {
      return this.fileType
        .get(fileExtension)
        .some((contentType) => contentType.toLowerCase() === fileContentType.toLowerCase());
    }
  }

  isFileEditableOrOverviewable(fileExtension: string, fileContentType: string): boolean {
    return this.checkFileContentType(fileExtension, fileContentType)
      ? this.extensionsEditable.includes(fileExtension) || this.extensionsOverviewable.includes(fileExtension)
      : false;
  }

  calculatePaginationNbPerPage(
    fileType: SupportedFileType,
    isNewTab: boolean,
    headerHeight: number,
    footerHeight: number,
    rowHeight: number,
  ): number {
    const windowHeight = window.innerHeight;
    let margin = isNewTab ? this._calculatePaginationHeightNewTabMargin : this._calculatePaginationHeightMargin;
    margin -= fileType === 'csv' ? this._differencePaginationHeightCsvXlsMargin : 0;
    const heightAvailable = windowHeight - headerHeight - footerHeight - margin;

    return Math.floor(heightAvailable / rowHeight);
  }

  resetPaginationPage(): void {
    this._currentPageNumber = 0;
  }

  setPaginationPage(pageInfo: { offset: number }, index: number): void {
    this._currentPageNumber = pageInfo.offset;
    const nbRows = this.datasets[index].rows.length;
    const firstIndex = this._currentPageNumber * this._paginationNbPerPageMax;
    const lastIndex =
      firstIndex + this._paginationNbPerPageMax > nbRows ? nbRows : firstIndex + this._paginationNbPerPageMax;
    this._currentRowsForPage = this.datasets[index].rows.slice(firstIndex, lastIndex);
  }

  setDatasetsValue(sheetIndex: number, rowIndex: number, columnName: string, value: string): void {
    this._datasets[sheetIndex].rows[rowIndex][columnName] = value;
    // sync with original parsed file
    this._parsedCSVFile[rowIndex][FileParserAndFormatterService._lettersToNumColumn(columnName) - 1] = value;
  }

  setSortOption(newSort: ISortEvent): void {
    const sortedProp = newSort.sorts[0].prop;
    this._currentRowsForPage.sort((row1, row2) => {
      return row1[sortedProp].localeCompare(row2[sortedProp]);
    });
    this.datasets[this.currentPageNumber].rows.sort((row1, row2) => {
      return row1[sortedProp].localeCompare(row2[sortedProp]);
    });
  }

  getFileType(fileExtension: string): SupportedFileType {
    switch (fileExtension) {
      case 'csv':
        return 'csv';
      case 'xls':
      case 'xlsx':
      case 'xlsm':
      case 'xlsb':
        return 'xls';
      case 'json':
        return 'json';
      case 'txt':
        return 'txt';
      default:
        return 'unsupported';
    }
  }

  /**
   * @param result
   * @param fileType
   * @param hasToReformat
   * @param type of parsing you want for your dataset
   */

  parseFile(result: Blob, fileType: string, hasToReformat = true, type?: 'json' | 'csv'): void {
    const reader = new FileReader();
    reader.readAsDataURL(result);

    reader.onloadend = (): void => {
      if (this._datasets.length) {
        // datasets already exists
        return;
      }

      try {
        let resultBase64 = reader.result.toString().split(',')[1];
        // exception for BOM case
        const bomIndex = resultBase64.indexOf(BOM_PREFIX_BASE_64);

        if (bomIndex === 0) {
          resultBase64 = resultBase64.replace(BOM_PREFIX_BASE_64, '');
        }

        switch (this.getFileType(fileType)) {
          case 'txt':
          case 'csv':
            this._parseCSV(resultBase64, !hasToReformat, type);

            if (type !== 'csv') {
              this._parsedCSVFile = [...this.datasets[0].rows];
            }

            break;
          case 'xls':
          case 'xlsx':
            this._parseXLS(resultBase64, type);
            break;
          case 'json':
            this._parseJSON(resultBase64);
            break;
          case 'unsupported':
          default:
            return;
        }

        if (this._hasErrorInParsing) {
          this._hasErrorInParsing = true;
          this._hasFinishedParsingAndFormatting = true;
          this._hasFinishedParsingAndFormatting$.next(true);

          return;
        }

        if (hasToReformat) {
          this._reformatDatasets(this.getFileType(fileType));
        }
      } catch (error) {
        this._logger.debug('[FileParserAndFormatterService] Error : ', error);
        this._hasErrorInParsing = true;
      }

      this._hasFinishedParsingAndFormatting = true;
      this._hasFinishedParsingAndFormatting$.next(true);
    };
  }

  isMaxSizedOverview(object: IDatalakeObject): boolean {
    const extension = object?.name?.split('.').length > 1 ? object.name.split('.').pop() : '';

    return object.size > this.options.overview[extension] * this.options.sizeUnitFactor;
  }

  isMaxSizedEdit(object: IDatalakeObject): boolean {
    const extension = object?.name?.split('.').length > 1 ? object.name.split('.').pop() : '';

    return object.size > this.options.edit[extension] * this.options.sizeUnitFactor;
  }

  unparseCSVFile(delimiter: string): void {
    const parsingConfig = { delimiter: delimiter?.length ? delimiter : this._parsedCSVFileDelimiter };
    this._csvFile = Papa.unparse(this._parsedCSVFile, parsingConfig);
  }

  resetParsingStatus(fileType?: SupportedFileType): void {
    this._resetGeneralParsingState();

    switch (fileType) {
      case 'csv':
        this._resetCsvParsingState();
        break;
      case 'json':
        this._resetJsonParsingState();
        break;
      case 'xls':
        this._resetXlsParsingState();
        break;
      default:
        this._resetCsvParsingState();
        this._resetJsonParsingState();
        this._resetXlsParsingState();
    }
  }

  // get the displayed width of the text according to the default font family and size of lib ngx-datatable
  private _getTextWidth(text: string): number {
    const div = document.createElement('div');
    div.innerHTML = text;
    div.style.visibility = 'hidden';
    div.style.position = 'absolute';
    div.style.height = 'auto';
    div.style.width = 'auto';
    div.style.whiteSpace = 'nowrap';
    div.style.fontFamily = this._defaultFontFamily;
    div.style.fontSize = this._defaultFontSize;
    div.style.fontStyle = this._defaultFontStyle;
    div.style.fontWeight = this._defaultFontWeight;
    document.body.appendChild(div);
    const width = div.clientWidth;
    document.body.removeChild(div);

    return width + this._fitToWidthMargin;
  }

  // fit to width custom function
  private _maxWidthRowInColumn(rows: unknown[], numCol: number): number {
    const column = [];
    const columnNames = Object.keys(rows[0]);

    for (let numRow = 0; numRow < rows.length; ++numRow) {
      column.push(rows[numRow][columnNames[numCol]]);
    }

    const maxLengthFound = Math.max(...column.map((value: string) => (value ? value.toString().length : 0)));
    const textMaxLengthFound = column.find((value: string) => (value ? value.toString().length : 0) === maxLengthFound);

    return this._getTextWidth(textMaxLengthFound);
  }

  // setting the width property in each column with the optimal value
  private _setOptimalColumnWidth(): void {
    this._datasets = this.datasets.map((dataset) => {
      const nbCols = dataset.columns.length;

      for (let numCol = 0; numCol < nbCols; ++numCol) {
        const widthToSet = this._maxWidthRowInColumn(dataset.rows, numCol);
        (dataset.columns[numCol] as { width: number }).width = widthToSet;
      }

      return dataset;
    });
  }

  private _isEmptyRow(row: unknown): boolean {
    return !Object.keys(row).some((key) => row[key]);
  }

  private _isEmptyColumn(rows: unknown[], key: string): boolean {
    return !rows.some((row) => row[key]);
  }

  // after the last row index, every row is empty row
  private _getLastRowIndex(rows: unknown[]): number {
    for (let numRow = rows.length - 1; numRow >= 0; --numRow) {
      if (!this._isEmptyRow(rows[numRow])) {
        return numRow;
      }
    }
  }

  // after the last column index, every column is empty column
  private _getLastColumnIndex(rows: unknown[]): number {
    const keys = Object.keys(rows[0]);

    for (let numCol = keys.length - 1; numCol >= 0; --numCol) {
      if (!this._isEmptyColumn(rows, keys[numCol])) {
        return numCol;
      }
    }
  }

  // sanitizing worksheet
  private _sanitizeWorksheet(worksheet: unknown[]): unknown[] {
    return worksheet.map((object) =>
      JSON.parse(this._domSanitizer.sanitize(SecurityContext.NONE, JSON.stringify(object))),
    );
  }

  private _sanitizeCSVWorksheet(csvWorksheet: string): string {
    return this._domSanitizer.sanitize(SecurityContext.NONE, csvWorksheet);
  }

  /**
   * After reformatting, columns will look like this :
   * [{name: 'A', sortable: false, width: <number>}, {name: 'B', sortable: false, width: <number>}, ...]
   * And rows will look like this :
   * [{a: <value>, b: <value>, ...}, {a: <value>, b: <value>, ...}, {a: <value>, b: <value>, ...}, ...]
   */
  private _reformatDatasets(fileType: SupportedFileType): void {
    this._datasets = this._datasets.map((dataset) => {
      if (!dataset.rows.length) {
        return { rows: [], columns: [] };
      }

      // formatting columns title (for papaparse) :
      // replacing every field name by incremental letters (from lib papaparse)
      if (fileType === 'csv') {
        for (let rowIndex = 0; rowIndex < dataset.rows.length; ++rowIndex) {
          const newRow = {};

          for (let columnIndex = 0; columnIndex < Object.keys(dataset.rows[0]).length; ++columnIndex) {
            newRow[FileParserAndFormatterService._numToLettersColumn(columnIndex + 1)] =
              columnIndex < Object.keys(dataset.rows[rowIndex]).length ? dataset.rows[rowIndex][columnIndex] : '';
          }

          dataset.rows[rowIndex] = newRow;
        }
      }

      // formatting columns :
      // extracting keys as first row (from lib XLSX / papaparse)
      // corresponding to the default columns title (set by lib XLSX / papaparse)
      // disabling default sorting (set by lib ngx-datatable)
      const firstRowValues = Object.keys(dataset.rows[0]);
      const formattedColumns = [];

      for (let numCol = 0; numCol < firstRowValues.length; ++numCol) {
        formattedColumns.push({ name: firstRowValues[numCol], sortable: false });
      }

      // removing last empty rows
      let cleanedRows = dataset.rows.slice(0, this._getLastRowIndex(dataset.rows) + 1);

      // removing last empty columns
      const lastColumnIndex = this._getLastColumnIndex(cleanedRows);
      cleanedRows = cleanedRows.map((row) => {
        return Object.entries(row)
          .slice(0, lastColumnIndex + 1)
          .reduce((currentRow, item) => {
            currentRow[item[0]] = item[1];

            return currentRow;
          }, {});
      });
      const formattedCleanedColumns = formattedColumns.slice(0, lastColumnIndex + 1);

      // formatting rows :
      // setting rows object keys (according to the lib ngx-datatable)
      const formattedCleanedRows = cleanedRows.map((row) => {
        const formattedRow = {};
        const rowValues = Object.values(row);

        for (let numCol = 0; numCol < firstRowValues.length; ++numCol) {
          // lower the case (required by lib ngx-datatable)
          formattedRow[firstRowValues[numCol].toLowerCase()] = rowValues[numCol];
        }

        return formattedRow;
      });

      return { rows: formattedCleanedRows, columns: formattedCleanedColumns };
    });

    // setting the width property in each column with the optimal value
    this._setOptimalColumnWidth();
  }

  private _parseCSV(resultBase64: string, useHeader: boolean, type?: 'json' | 'csv'): void {
    const resultString = atob(resultBase64);
    const resultStringDecodedUtf8 = decodeURIComponent(escape(resultString));
    let parseResult;

    try {
      parseResult = Papa.parse(resultStringDecodedUtf8, { ...this._parseCSVOptions, header: useHeader });
    } catch (error) {
      this._logger.debug('[FileParserAndFormatterService] Error : ', error);
      this._hasErrorInParsing = true;

      return;
    }

    if (
      parseResult?.errors?.length &&
      parseResult.errors.some((parseError) => parseError.code !== 'UndetectableDelimiter')
    ) {
      this._logger.debug('[FileParserAndFormatterService] [CSV] Error : ', parseResult.errors);
      this._hasErrorInParsing = true;
    } else {
      const worksheet = parseResult.data;
      this._parsedCSVFileDelimiter = parseResult.meta.delimiter;
      const sanitizedWorksheet = this._sanitizeWorksheet(worksheet);
      this._datasets.push({ rows: sanitizedWorksheet, columns: [] });

      if (type === 'csv') {
        const sanitizeCSVWorksheet = this._sanitizeCSVWorksheet(resultStringDecodedUtf8);
        this._csvDatasets.push({ content: sanitizeCSVWorksheet });
      }
    }
  }

  private _parseXLS(resultBase64: string, type?: 'json' | 'csv'): void {
    let wb;

    try {
      wb = XLSX.read(resultBase64, type === 'csv' ? this._readXLSOptionsFlatFile : this._readXLSOptions);
    } catch (error) {
      this._logger.debug('[FileParserAndFormatterService] [XLSX] Error : ', error);
      this._hasErrorInParsing = true;

      return;
    }

    const workSheets = wb?.Sheets;

    if (Object.entries(workSheets || {}).length) {
      // if there is any sheet (not a single sheet document)
      // we don't display the hidden sheets
      this._sheetNames = wb.Workbook.Sheets.filter((sheet) => !sheet.Hidden).map((sheet) => sheet.name);
      this._sheetNames.forEach((sheetName) => {
        if (type === 'csv') {
          const csvWorksheet = XLSX.utils.sheet_to_csv(workSheets[sheetName], this._parseXLSOptions);
          const sanitizedCsvWorksheet = this._sanitizeCSVWorksheet(csvWorksheet);
          this._csvDatasets.push({ content: sanitizedCsvWorksheet, sheetName: sheetName });
          this._logger.debug('[FileParserAndFormatterService] csvWorksheet : ', sheetName, ' ', csvWorksheet);
        }

        const worksheet = XLSX.utils.sheet_to_json(workSheets[sheetName], this._parseXLSOptions);
        const sanitizedWorksheet = this._sanitizeWorksheet(worksheet);
        this._logger.debug('[FileParserAndFormatterService] worksheet : ', sheetName, ' ', worksheet);
        this._datasets.push({ rows: sanitizedWorksheet, columns: [] });
      });
    } else {
      this._hasErrorInParsing = true;
    }
  }

  private _parseJSON(resultBase64: string): void {
    try {
      this._jsonDataset = JSON.parse(atob(resultBase64));
    } catch (error) {
      this._logger.debug('[FileParserAndFormatterService] json parsing error : ', error);
      this._hasErrorInParsing = true;
    }
  }

  private _resetGeneralParsingState(): void {
    this._datasets = [];
    this._csvDatasets = [];
    this._currentRowsForPage = [];
    this._currentPageNumber = 0;
    this._hasErrorInParsing = false;
    this._hasFinishedParsingAndFormatting = false;
    this._hasFinishedParsingAndFormatting$.next(false);
  }

  private _resetCsvParsingState(): void {
    this._parsedCSVFile = [];
    this._parsedCSVFileDelimiter = ',';
    this._csvFile = undefined;
  }

  private _resetJsonParsingState(): void {
    this._jsonDataset = {};
  }

  private _resetXlsParsingState(): void {
    this._sheetNames = [];
  }
}
