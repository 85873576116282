import { EventEmitter, Injectable, Output } from '@angular/core';

@Injectable()
export class SidebarV2Service {
  isOpen = false;

  @Output() displaySideBar: EventEmitter<boolean> = new EventEmitter();

  toggle() {
    this.isOpen = !this.isOpen;
    this.displaySideBar.emit(this.isOpen);
  }
}
