import { CdkDropList } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@dataportal/front-shared';
import { NgxFileDropModule } from 'ngx-file-drop';

import { AtomsModule } from '../atoms/atoms.module';
import { DirectivesModule } from '../directives/directives.module';

import { AlertColorSelectorComponent } from './alert-color-selector/alert-color-selector.component';
import { BasicFilterComponent } from './basic-filter/basic-filter.component';
import { CheckboxesFilterComponent } from './basic-filter/checkboxes-filter/checkboxes-filter.component';
import { AdlBreadcrumbV2Component } from './breadcrumb-v2/breadcrumb-v2.component';
import { CardHeaderComponent } from './card-header/card-header.component';
import { CardSelectorComponent } from './card-selector/card-selector.component';
import { CheckboxesComponent } from './checkboxes/checkboxes.component';
import { CheckboxesSingleChoiceComponent } from './checkboxes-single-choice/checkboxes-single-choice.component';
import { CheckboxesV2Component } from './checkboxes-v2/checkboxes-v2.component';
import { CommentItemComponent } from './comment-item/comment-item.component';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { ContentLockerComponent } from './content-locker/content-locker.component';
import { DialogModalComponent } from './dialog-modal/dialog-modal.component';
import { DocumentCopyClipboardButtonComponent } from './document-copy-clipboard-button/document-copy-clipboard-button.component';
import { DocumentDownloadButtonComponent } from './document-download-button/document-download-button.component';
import { DownloadCSVButtonComponent } from './download-csv-button/download-csv-button.component';
import { FileIconComponent } from './file-icon/file-icon.component';
import { FormControlComponent } from './form-control/form-control.component';
import { GenericErrorPageComponent } from './generic-error-page/generic-error-page.component';
import { HeaderStepComponent } from './header-step/header-step.component';
import { InfoBubbleComponent } from './info-bubble/info-bubble.component';
import { InputModalComponent } from './input-modal/input-modal.component';
import { ListWithInputComponent } from './list-with-input/list-with-input.component';
import { LogoModalHeaderComponent } from './logo-modal-header/logo-modal-header.component';
import { ModeSwitchComponent } from './mode-switch/mode-switch.component';
import { OptionsSelectorButtonComponent } from './options-selector-button/options-selector-button.component';
import { PaginatorComponent } from './paginator/paginator.component';
import { PictureWithTogglesComponent } from './picture-with-toggles/picture-with-toggles.component';
import { PostCommentItemComponent } from './post-comment-item/post-comment-item.component';
import { ProfileCardComponent } from './profile-card/profile-card.component';
import {
  RadioContentSelectorComponent,
  RadioContentSelectorMarkerDirective,
} from './radio-content-selector/radio-content-selector.component';
import { RadioSelectorComponent } from './radio-selector/radio-selector.component';
import { SearchBarAdditionalFilterComponent } from './search-bar/additional-filter/additional-filter.component';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { SearchBarSuggestionsComponent } from './search-bar/suggestions/suggestions.component';
import { SelectCheckboxesComponent } from './select-checkboxes/select-checkboxes.component';
import { SelectableFormControlComponent } from './selectable-form-control/selectable-form-control.component';
import { ShowMoreWrapperComponent } from './show-more-wrapper/show-more-wrapper.component';
import { SwitchLabelComponent } from './switch-label/switch-label.component';
import { SlidingTabsComponent } from './tabs/sliding-tabs/sliding-tabs.component';
import { TabComponent } from './tabs/tab/tab.component';
import { TabsComponent } from './tabs/tabs.component';
import { TagsSelectorComponent } from './tags-selector/tags-selector.component';
import { TextInlineEditComponent } from './text-inline-edit/text-inline-edit.component';
import { TextTooltipComponent } from './text-tooltip/text-tooltip.component';
import { TimeCounterComponent } from './time-counter/time-counter.component';
import { ToggleableContentButtonComponent } from './toggleable-content-button/toggleable-content-button.component';
import { ToolCardComponent } from './tool-card/tool-card.component';
import { TooltipButtonsComponent } from './tooltip-buttons/tooltip-buttons.component';
import { UploadButtonComponent } from './upload-button/upload-button.component';
import { UploadDragAndDropComponent } from './upload-drag-and-drop/upload-drag-and-drop.component';

@NgModule({
  declarations: [
    SearchBarComponent,
    SelectCheckboxesComponent,
    HeaderStepComponent,
    CardHeaderComponent,
    InfoBubbleComponent,
    FormControlComponent,
    SelectableFormControlComponent,
    CheckboxesComponent,
    CheckboxesSingleChoiceComponent,
    CardSelectorComponent,
    DialogModalComponent,
    TagsSelectorComponent,
    ProfileCardComponent,
    RadioSelectorComponent,
    RadioContentSelectorComponent,
    RadioContentSelectorMarkerDirective,
    ConfirmModalComponent,
    UploadButtonComponent,
    UploadDragAndDropComponent,
    TextTooltipComponent,
    InputModalComponent,
    ToolCardComponent,
    TabsComponent,
    TabComponent,
    TimeCounterComponent,
    AlertColorSelectorComponent,
    FileIconComponent,
    ToggleableContentButtonComponent,
    ContentLockerComponent,
    OptionsSelectorButtonComponent,
    DownloadCSVButtonComponent,
    PictureWithTogglesComponent,
    SwitchLabelComponent,
    ListWithInputComponent,
    LogoModalHeaderComponent,
    CommentItemComponent,
    PostCommentItemComponent,
    PaginatorComponent,
    SearchBarSuggestionsComponent,
    SearchBarAdditionalFilterComponent,
    BasicFilterComponent,
    ModeSwitchComponent,
    CheckboxesFilterComponent,
    CheckboxesV2Component,
    TooltipButtonsComponent,
    SlidingTabsComponent,
    TextInlineEditComponent,
    DocumentCopyClipboardButtonComponent,
    DocumentDownloadButtonComponent,
    AdlBreadcrumbV2Component,
    GenericErrorPageComponent,
    ShowMoreWrapperComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    AtomsModule,
    NgxFileDropModule,
    DirectivesModule,
    SharedModule,
    RouterModule,
    CdkDropList,
  ],
  exports: [
    SearchBarComponent,
    HeaderStepComponent,
    CardHeaderComponent,
    InfoBubbleComponent,
    FormControlComponent,
    SelectableFormControlComponent,
    CheckboxesComponent,
    CheckboxesSingleChoiceComponent,
    CardSelectorComponent,
    DialogModalComponent,
    TagsSelectorComponent,
    ProfileCardComponent,
    RadioSelectorComponent,
    RadioContentSelectorComponent,
    RadioContentSelectorMarkerDirective,
    UploadButtonComponent,
    UploadDragAndDropComponent,
    TextTooltipComponent,
    InputModalComponent,
    ToolCardComponent,
    TabsComponent,
    TabComponent,
    TimeCounterComponent,
    AlertColorSelectorComponent,
    FileIconComponent,
    ToggleableContentButtonComponent,
    ContentLockerComponent,
    OptionsSelectorButtonComponent,
    DownloadCSVButtonComponent,
    PictureWithTogglesComponent,
    SwitchLabelComponent,
    ListWithInputComponent,
    LogoModalHeaderComponent,
    CommentItemComponent,
    PostCommentItemComponent,
    PaginatorComponent,
    SelectCheckboxesComponent,
    SearchBarSuggestionsComponent,
    SearchBarAdditionalFilterComponent,
    BasicFilterComponent,
    ModeSwitchComponent,
    CheckboxesFilterComponent,
    CheckboxesV2Component,
    TooltipButtonsComponent,
    SlidingTabsComponent,
    TextInlineEditComponent,
    DocumentCopyClipboardButtonComponent,
    DocumentDownloadButtonComponent,
    AdlBreadcrumbV2Component,
    GenericErrorPageComponent,
    ShowMoreWrapperComponent,
  ],
  providers: [{ provide: MAT_DIALOG_DATA, useValue: {} }],
  entryComponents: [ConfirmModalComponent, DialogModalComponent],
})
export class MoleculesModule {}
