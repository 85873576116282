import { Inject, Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { of } from 'rxjs';
import { BehaviorSubject, EMPTY } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ApiService } from '@dataportal/front-api';
import { IS_ACCOR } from '@dataportal/front-environment';
import type { DatalakePath } from '@dataportal/front-shared';
import { Logger } from '@dataportal/front-shared';
import { EntityBuilder } from '@decahedron/entity';

import { FavoriteDatalakePath } from '../entities/favorite-datalake';

// Service
@Injectable()
export class FavoriteDatalakeService {
  // Attributes
  private readonly _favoriteDatalakes$ = new BehaviorSubject<FavoriteDatalakePath[]>([]);
  favoriteDatalakePaths$ = this._favoriteDatalakes$.asObservable();

  // Constructor
  constructor(
    private readonly _apiService: ApiService,
    private readonly _logger: Logger,
    @Inject(IS_ACCOR) private readonly _isAccor: boolean,
  ) {}

  // Methods
  getFavoritesDatalakePaths(): Observable<FavoriteDatalakePath[]> {
    // ACCOR - NO DATALAKE
    if (this._isAccor) {
      return of([]);
    }

    return this._apiService.get<FavoriteDatalakePath[]>('/v4/datalake/favorites').pipe(
      map((datalakeFavorites) => {
        return EntityBuilder.buildMany<FavoriteDatalakePath>(FavoriteDatalakePath, datalakeFavorites);
      }),
    );
  }

  createFavoriteDatalakePath(sourceId: string, datalakePath: DatalakePath): Observable<FavoriteDatalakePath> {
    return this._apiService
      .post('/v4/datalake/favorites', { source_id: sourceId, related_datalake_path: datalakePath })
      .pipe(map((res) => EntityBuilder.buildOne(FavoriteDatalakePath, res)));
  }

  deleteFavoriteDatalake(sourceId: string, datalakePath: DatalakePath): Observable<null> {
    return this._apiService.delete('/v4/datalake/favorites', {
      body: { source_id: sourceId, related_datalake_path: datalakePath },
    });
  }

  refreshFavoritesDatalakePaths(): Observable<FavoriteDatalakePath[]> {
    this._logger.debug('[FavoritesDatalakesService] Refresh favorites datalake');

    return this.getFavoritesDatalakePaths().pipe(
      tap((favorites) => {
        this._logger.debug('[FavoritesDatalakesService] Favorites datalake paths refreshed', favorites);
        this._favoriteDatalakes$.next(favorites);
      }),
    );
  }

  isFavoriteDatalakePath(sourceId: string, datalakePath: DatalakePath): boolean {
    return this._favoriteDatalakes$
      .getValue()
      .some((favorite) => favorite.sourceId === sourceId && favorite.datalakePath.path === datalakePath.path);
  }

  addFavoriteDatalakePath(sourceId: string, datalakePath: DatalakePath): Observable<FavoriteDatalakePath> {
    this._logger.debug('[FavoritesDatalakesService] Add datalake path favorite', sourceId);

    return this.createFavoriteDatalakePath(sourceId, datalakePath).pipe(
      tap((favorite) => {
        this._logger.debug('[FavoritesDatalakesService] Favorite datalake added', favorite);
        const currentFavorites = this._favoriteDatalakes$.getValue();
        this._favoriteDatalakes$.next([...currentFavorites, favorite]);
      }),
    );
  }

  removeFavoriteDatalake(sourceId: string, datalakePath: DatalakePath): Observable<null> {
    this._logger.debug('[FavoritesDatalakesService] Remove datalake favorite', sourceId, datalakePath);

    if (!this.isFavoriteDatalakePath(sourceId, datalakePath)) {
      this._logger.error(
        `[FavoritesDatalakesService] Cannot remove datalake path ${sourceId} -- ${datalakePath} from user favorites`,
      );

      return EMPTY;
    }

    return this.deleteFavoriteDatalake(sourceId, datalakePath).pipe(
      tap(() => {
        this._logger.debug('[FavoritesDatalakesService] Datalake favorite removed', { sourceId, datalakePath });

        let favorites = this._favoriteDatalakes$.getValue();
        this._logger.debug('[FavoritesDatalakesService] Current state', favorites.length);

        favorites = favorites.filter((fav) => fav.sourceId !== sourceId || fav.datalakePath.path !== datalakePath.path);
        this._logger.debug('[FavoritesDatalakesService] Current state updated', favorites.length);
        this._favoriteDatalakes$.next(favorites);
      }),
    );
  }

  saveFavoritesOrder(favoriteDatalakePathsOrdered: FavoriteDatalakePath[]) {
    const favoriteDatalakePathsMapped = favoriteDatalakePathsOrdered.map((favPath) => favPath.toJson());

    return this._apiService.post('/v4/datalake/favorites/order', {
      favoriteDatalakePathsOrdered: favoriteDatalakePathsMapped,
    });
  }
}
