<adl-autocomplete
  [label]="label"
  [value]="value"
  [placeholder]="placeholder"
  [typeToSearchText]="searchText"
  [loading]="loading"
  [items]="list"
  [searchSubject]="searchSubject"
  [clear]="clear"
  (cleared)="cleared()"
  (change)="changed($event)"
  (scrollToEnd)="scrolledToEnd()"
>
</adl-autocomplete>
