<select
  class="mxw100 c-pointer"
  (ngModelChange)="onChange($event)"
  [(ngModel)]="selectedValue"
  [ngStyle]="this.fixedSize && { width: this.fixedSize }"
  [attr.disabled]="isDisabled ? '' : null"
  [ngClass]="{ disabled: isDisabled, 'border': !noBorder }"
>
  <option [selected]="!control.value" *ngIf="this.placeholder" value="" disabled>
    {{ this.placeholder }}
  </option>
  <option
    [selected]="valueEqualityCheck(control.value, option.value)"
    *ngFor="let option of this.options"
    [ngValue]="option.value"
  >
    {{ option.label }}
  </option>
</select>
