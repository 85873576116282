import { Component, Input } from '@angular/core';
import { CommonSelectComponent } from '@dataportal/adl';
import type { User } from '@dataportal/users';
import { UsersService } from '@dataportal/users';

@Component({
  selector: 'dpg-legacy-user-select',
  templateUrl: './legacy-user-select.component.html',
  styleUrls: ['./legacy-user-select.component.scss'],
})
export class LegacyUserSelectComponent extends CommonSelectComponent<User> {
  @Input() placeholder = 'Select a user';
  @Input() minSize = 1;
  @Input() limit = 20;

  constructor(readonly usersService: UsersService) {
    super(usersService);
  }
}
