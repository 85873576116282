<adl-modal
  headerBackgroundColor="primary-500"
  [roundCorner]="false"
  closeButtonColor="white"
  [isAlignedToCross]="true"
  backgroundColor="grey-100"
>
  <div class="dp-admin--add-group-modal--header my-8" modal-header>
    <adl-text type="normal" [size]="20" color="white">Add a group and choose their access</adl-text>
  </div>
  <div modal-body class="dp-admin--add-group-modal--body">
    <adl-text type="normal" [size]="14" color="blue" weight="medium">Add people and choose their access</adl-text>
    <div class="dp-admin--add-group-modal--group-selection">
      <adl-group-select
        class="dp-admin--add-group-modal--group-select"
        label="name"
        value="id"
        [predicates]="groupPredicates"
        (selected)="selectedGroup = $event"
        [clear]="clearSelectList"
      ></adl-group-select>
      <adl-button (clicked)="addGroupToList()" [disabled]="!selectedGroup">
        {{ addedGroupsRoles?.length ? 'Add another group' : 'Add' }}
      </adl-button>
      <adl-button (clicked)="openGroupCreationModal()" type="cancel">Create a new group</adl-button>
    </div>
    <div class="dp-admin--add-group-modal--permissions-list scrollbar shadow-md">
      <adl-table-v2 [headers]="groupsPermissionsTableHeaders">
        <tr *ngIf="addedGroupsRoles.length >= 2" table-tr>
          <td>
            <adl-text class="dp-admin--add-group-modal--group-row-name" [size]="14" [italic]="true">All</adl-text>
          </td>
          <td class="flex justify-between">
            <adl-radio-selector
              radioStyle="new"
              (changed)="updateEachGroupRole($event)"
              class="dp-admin--add-group-modal--group-row-radio-buttons"
              selectorName="all-roles"
              [activeChoice]="defaultRole"
              [choices]="choices"
              containerClassList="flex gap-3"
              radioSelectorLabelColor="blue"
              [radioSelectorLabelSize]="12"
            >
            </adl-radio-selector>
            <div class="separator"></div>
          </td>
        </tr>
        <tr *ngFor="let group of addedGroupsRoles" table-tr>
          <td class="truncate max-w-0 w-1/4">
            <adl-text class="dp-admin--add-group-modal--group-row-name" [size]="14">{{ group.name }}</adl-text>
          </td>
          <td class="flex justify-between">
            <adl-radio-selector
              (changed)="updateGroupRole(group.key, $event)"
              class="dp-admin--add-group-modal--group-row-radio-buttons"
              [selectorName]="group.key + '-roles'"
              [activeChoice]="group.role"
              [choices]="choices"
              containerClassList="flex gap-3"
              radioSelectorLabelColor="blue"
              [radioSelectorLabelSize]="12"
              radioStyle="new"
            >
            </adl-radio-selector>
            <adl-icon-button
              class="dp-admin--add-group-modal--group-row--delete"
              (clicked)="removeGroupFromList(group.key)"
              icon="trash"
            ></adl-icon-button>
          </td>
        </tr>
        <tr table-tr *ngIf="addedGroupsRoles.length === 0">
          <td colspan="2" class="h-[130px] text-center pointer-events-none">
            <adl-text> No group roles to add yet. </adl-text>
          </td>
        </tr>
      </adl-table-v2>
    </div>
  </div>
  <div modal-footer>
    <div class="dp-admin--btn-wrapper row-flex j-center">
      <adl-button (clicked)="cancel()" type="cancel">Cancel</adl-button>
      <adl-button (clicked)="confirm()">Save</adl-button>
    </div>
  </div>
</adl-modal>
