import type { OnDestroy } from '@angular/core';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SidebarV3Service implements OnDestroy {
  private readonly _isVisible$ = new BehaviorSubject<boolean>(true);
  isVisible$ = this._isVisible$.asObservable();
  private readonly _isDeveloped$ = new BehaviorSubject<boolean>(false);
  isDeveloped$ = this._isDeveloped$.asObservable();

  sidebarActiveUrl$ = new ReplaySubject<string>();
  private readonly _destroyed$ = new Subject<void>();

  constructor(private readonly _router: Router) {
    this._router.events.pipe(takeUntil(this._destroyed$)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.sidebarActiveUrl$.next(event.url.split('/')[1] ?? 'none');
      }
    });
  }

  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  toggleVisibility() {
    this._isVisible$.next(!this._isVisible$.value);
  }

  developSidebar() {
    this._isDeveloped$.next(true);
  }

  reduceSidebar() {
    this._isDeveloped$.next(false);
  }

  hideSidebar() {
    this._isDeveloped$.next(false);
    this._isVisible$.next(false);
  }

  showSidebar() {
    this._isVisible$.next(true);
    this._isDeveloped$.next(true);
  }
}
