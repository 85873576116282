import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { filter, first, map, shareReplay, startWith, switchMap, tap } from 'rxjs/operators';
import { GoogleTagManagerService } from '@dataportal/analytics';
import type { DatalakeOnlyProvider } from '@dataportal/datalake-parsing';
import { Logger } from '@dataportal/front-shared';

import { DatalakeStoreService } from './datalake-store.service';
import { ExplorerService } from './explorer.service';

import type { IDatalakeCurrentDirectory, IDatalakeObjectWithPermission, IObjectsStoreLine } from '../entities/datalake';
import { currentToStoreOptions } from '../entities/datalake';

interface IDisplayObjectsOptions {
  limit: number;
  sort: { field: string; direction: 'asc' | 'desc' };
  currentPage: number;
  searchTerm: string;
}

const DEFAULT_OPTIONS: IDisplayObjectsOptions = {
  limit: 100,
  sort: { field: 'name', direction: 'asc' as const },
  currentPage: 1,
  searchTerm: '',
};

const DEFAULT_STORE_LINE: IObjectsStoreLine = {
  objects: [],
  isThereDataRemaining: true,
  allChecked: false,
  metadata: [],
  userPermissions: [],
};

@Injectable()
export class ExplorerPageService {
  private readonly _explorer = inject(ExplorerService);
  private readonly _store = inject(DatalakeStoreService);
  private readonly _logger = inject(Logger);
  private readonly _gtmService = inject(GoogleTagManagerService);

  private readonly _isLoadingFirstPage$ = new BehaviorSubject<boolean>(false);
  private readonly _allPageLoaded$ = new BehaviorSubject<boolean>(false);
  private readonly _options$ = new BehaviorSubject<IDisplayObjectsOptions>(DEFAULT_OPTIONS);
  private readonly _createFolder$ = new BehaviorSubject<IDatalakeObjectWithPermission>(null);

  readonly current$ = this._explorer.current$.pipe(
    filter((current) => current !== null),
    tap(() => {
      this._options$.next(DEFAULT_OPTIONS);
    }),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  readonly store$ = this.current$.pipe(
    tap(() => {
      this._isLoadingFirstPage$.next(true);
    }),
    switchMap((current) =>
      this._store.getObjectStore(...currentToStoreOptions(current)).pipe(
        switchMap((line) => {
          if (line.isThereDataRemaining) {
            return this._store.listAll(...currentToStoreOptions(current)).objects$;
          }

          return of(line);
        }),
      ),
    ),
    tap((line) => {
      if (line.isThereDataRemaining === false) {
        this._isLoadingFirstPage$.next(false);
        this._allPageLoaded$.next(true);
      }
    }),
    startWith(DEFAULT_STORE_LINE),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  readonly options$ = this._options$.asObservable();
  readonly isLoadingFirstPage$ = this._isLoadingFirstPage$.asObservable();
  readonly allPageLoaded$ = this._allPageLoaded$.asObservable();

  readonly pages$ = combineLatest([this._options$, this.store$]).pipe(
    map(([options, line]) => this._getPagesInfos(options, line)),
    startWith({ current: 1, total: 1 }),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  readonly objects$ = combineLatest([this._options$, this.store$, this._createFolder$, this.current$]).pipe(
    map(([options, line, createFolder, current]) => this._getUpdatedObjects({ options, line, createFolder, current })),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  readonly allChecked$ = this.objects$.pipe(
    map((objects) => objects.length > 0 && objects.every((obj) => obj.checked)),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  readonly checkedObjects$ = this.objects$.pipe(
    map((objects) => objects.filter((obj) => obj.checked)),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  private get _createFolder(): IDatalakeObjectWithPermission {
    return this._createFolder$.getValue();
  }

  private set _createFolder(createFolder: IDatalakeObjectWithPermission) {
    this._createFolder$.next(createFolder);
  }

  sort(field: string, direction: 'asc' | 'desc'): void {
    this._options$.next({
      ...this._options$.getValue(),
      sort: { field, direction },
    });
  }

  search(searchTerm: string): void {
    this._options$.next({
      ...this._options$.getValue(),
      searchTerm,
    });

    if (searchTerm && searchTerm !== '') {
      this.objects$
        .pipe(
          first(),
          tap((objects) => {
            this.pushGTMSearchEvent(searchTerm, objects.length >= 1);
          }),
        )
        .subscribe();
    }
  }

  previous(): void {
    this._options$.next({
      ...this._options$.getValue(),
      currentPage: this._options$.getValue().currentPage - 1,
    });
  }

  next(): void {
    this._options$.next({
      ...this._options$.getValue(),
      currentPage: this._options$.getValue().currentPage + 1,
    });
  }

  page(offset: number): void {
    this._options$.next({
      ...this._options$.getValue(),
      currentPage: offset,
    });
  }

  first(): void {
    this.page(1);
  }

  last(): void {
    this.pages$
      .pipe(
        first(),
        tap((pages) => {
          this.page(pages.total);
        }),
      )
      .subscribe();
  }

  limit(pageSize: number): void {
    this._options$.next({
      ...this._options$.getValue(),
      limit: pageSize,
    });
  }

  private _getPagesInfos(options: IDisplayObjectsOptions, line: IObjectsStoreLine): { current: number; total: number } {
    const { limit, currentPage } = options;
    const filteredObjects = line.objects.filter((obj) =>
      !options?.searchTerm ? true : obj.name.toLowerCase().includes(options.searchTerm.toLowerCase()),
    );
    const total = Math.floor(filteredObjects.length / limit) + (filteredObjects.length % limit === 0 ? 0 : 1);
    this._logger.debug('[ExplorerPageService]', 'Updating current page state', {
      limit,
      currentPage,
      total,
    });

    return { current: currentPage, total };
  }

  private _getUpdatedObjects({
    options,
    line,
    createFolder,
    current,
  }: {
    options: IDisplayObjectsOptions;
    line: IObjectsStoreLine;
    createFolder: IDatalakeObjectWithPermission;
    current: IDatalakeCurrentDirectory;
  }): IDatalakeObjectWithPermission[] {
    const allObjects = line;
    const slice = [(options.currentPage - 1) * options.limit, options.currentPage * options.limit];
    const displayedObjects = allObjects.objects
      .map((obj, idx) => ({
        ...obj,
        bucket: current.bucket.name,
        userPermission: allObjects.userPermissions[idx],
      }))
      .filter((obj) =>
        !options?.searchTerm ? true : obj.name.toLowerCase().includes(options.searchTerm.toLowerCase()),
      )
      .sort((obj1, obj2) => {
        const desc = options.sort.direction === 'desc' ? -1 : 1;

        switch (options.sort.field) {
          case 'size':
            return desc * ((obj2.size || 0) - (obj1.size || 0));
          case 'lastModified':
            return desc * (new Date(obj2.lastModified || 0).valueOf() - new Date(obj1.lastModified || 0).valueOf());
          default:
            return desc * obj1.name.localeCompare(obj2.name);
        }
      })
      .slice(slice[0], slice[1]);
    this._logger.debug('[ExplorerPageService]', 'Updating objects', {
      total: allObjects.objects.length,
      slice,
      sort: options.sort,
      displayedObjects,
    });

    if (createFolder) {
      displayedObjects.unshift(this._createFolder);
    }

    return displayedObjects;
  }

  createDirectory(): void {
    this.current$
      .pipe(
        first(),
        tap((current) => {
          this._createFolder = {
            bucket: current.bucket.name,
            checked: false,
            name: undefined,
            path: current.path,
            provider: current.provider as DatalakeOnlyProvider,
            tenant: current.tenant,
            type: 'folder',
            userPermission: DatalakeStoreService.READ_WRITE_PERMISSION,
            folderCreation: true,
          };
        }),
      )
      .subscribe();
  }

  cancelFolderCreation() {
    this._createFolder = null;
  }

  pushGTMSearchEvent(query: string, results: boolean) {
    this._gtmService.pushEvent({
      event: 'tl_datalake_file_search',
      tl_file_search_query: query,
      tl_file_search_results: results ? 'yes' : 'no',
    });
  }
}
