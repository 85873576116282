<adl-generic-error-page
  blueButtonLabel="Go back to the Homepage"
  yellowButtonLabel="{{ errorType === 'dashboardNotFound' || 'sourceNotFound' ? 'Go to Data Product page' : null }}"
  title='Dashboard can’t be accessed.'
  imageType='no-permissions'
  [errorLink]="previousUrl"
  [description]="errorDescription"
  (blueButtonClicked)="backToHome()"
  (yellowButtonClicked)="goToDataAssetPage()"
></adl-generic-error-page>
