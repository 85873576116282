import type { OnDestroy, OnInit } from '@angular/core';
import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'adl-delete-modal',
  templateUrl: './delete-modal.component.html',
})
export class DeleteModalComponent implements OnInit, OnDestroy {
  @Input() title: string;
  @Input() iconLink: string;
  @Input() closeModal$: Subject<void> = new Subject<void>();

  private readonly _destroy$ = new Subject<void>();

  constructor(
    private readonly _activeMatModal: MatDialogRef<DeleteModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    data: {
      title: string;
      iconLink: string;
      closeModal$: Subject<void>;
    },
  ) {
    this.title = data?.title ? data.title : this.title;
    this.iconLink = data?.iconLink ? data.iconLink : this.iconLink;
    this.closeModal$ = data?.closeModal$ ? data.closeModal$ : this.closeModal$;
  }

  ngOnInit(): void {
    this.closeModal$.pipe(first(), takeUntil(this._destroy$)).subscribe(() => {
      this._activeMatModal.close();
    });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  closeModal(confirmValue: boolean) {
    this._activeMatModal.close(confirmValue);
  }
}
