import { Inject, Injectable } from '@angular/core';
import { Logger } from '@dataportal/front-shared';

import { ILazyLoadedEnvironmentOptions, LAZY_LOADED_MODULES_ENVIRONMENT_OPTIONS } from '../environment.options';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentService {
  constructor(
    private readonly _logger: Logger,
    @Inject(LAZY_LOADED_MODULES_ENVIRONMENT_OPTIONS) readonly options: ILazyLoadedEnvironmentOptions,
  ) {}
}
