import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GoogleTagManagerService } from '@dataportal/analytics';
import { CurrentUserService } from '@dataportal/auth';
import { AlertService } from '@dataportal/front-shared';
import { getRoleData } from '@dataportal/permissions';

import { AccessRequestsV2Service } from '../../services/access-requests-v2.service';

import type { IARResourceRolesMap, ISupportedARResource, ISupportedARRole } from '../../entities/utils';
import { IAccessRequestResource } from '../../entities/utils';
import { ACCESS_REQUEST_ROLES } from '../../roles-data';

// Types
interface IRoleValue {
  label: string;
  value: ISupportedARRole;
  infoBubbleContent?: string;
}

// Constants
const ROLES_DEFAULTS: { [R in ISupportedARResource]: IARResourceRolesMap[R] } = {
  sources: 'dashboardReader',
  leons: 'leonReader',
};

// Component
@Component({
  selector: 'dpg-resource-access-modal',
  templateUrl: './resource-access-modal.component.html',
  styleUrls: ['./resource-access-modal.component.scss'],
})
export class ResourceAccessModalComponent<R extends ISupportedARResource = ISupportedARResource> {
  @Input() gtmEvent = false;
  @Input() resource: IAccessRequestResource;
  availableRoles: IRoleValue[] = [];
  requestedRole?: IARResourceRolesMap[R];

  // Constructor
  constructor(
    private readonly _activeMatModal: MatDialogRef<ResourceAccessModalComponent>,
    private readonly _accessRequestsV2Service: AccessRequestsV2Service,
    private readonly _currentUserService: CurrentUserService,
    private readonly _gtmService: GoogleTagManagerService,
    private readonly _alertService: AlertService,
    @Inject(MAT_DIALOG_DATA)
    data: {
      resource: IAccessRequestResource;
      kind: R;
      gtmEvent?: boolean;
    },
  ) {
    this.resource = data?.resource ? data.resource : this.resource;
    this.kind = data?.kind ? data.kind : this.kind;
    this.gtmEvent = data?.gtmEvent ? data.gtmEvent : this.gtmEvent;
  }

  // Attributes
  private _kind: R;

  // Properties
  @Input()
  get kind(): R {
    return this._kind;
  }

  set kind(kind: R) {
    this._kind = kind;

    this.requestedRole = ROLES_DEFAULTS[kind];
    this.availableRoles = getRoleData(...ACCESS_REQUEST_ROLES[kind]).map((data) => ({
      label: data.label,
      value: data.role,
      infoBubbleContent: data.description,
    }));
  }

  get accessRequestAllowed(): boolean {
    return this.resource && (this.resource.allowAccessRequest ?? true);
  }

  get singularKind(): string {
    return this.kind.replace(/s$/, '');
  }

  get kindOwners(): string {
    if (this.kind === 'sources') {
      return 'functional owners';
    }

    return 'owners';
  }

  // Methods
  request(): void {
    this._accessRequestsV2Service.create(this.kind, this.resource.id, this.requestedRole).subscribe(() => {
      if (this.gtmEvent) this.pushGTMAccessRequested();
      this._alertService.success('Your access request has been successfully sent !');
      this._activeMatModal.close(true);
    });
  }

  cancel(): void {
    this._activeMatModal.close();
  }

  ok(): void {
    this._activeMatModal.close();
  }

  updateRequestedRole($event: string): void {
    this.requestedRole = $event as IARResourceRolesMap[R];
  }

  pushGTMAccessRequested() {
    if (this.kind === 'leons') {
      this._gtmService.pushEvent({
        event: 'ds_leon_access',
        ds_leon_name: this.resource.name,
        ds_leon_permission: this.requestedRole,
      });
    }
  }
}
