import type { OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Component, Input } from '@angular/core';
import type { Observable, Subscription } from 'rxjs';
import { EMPTY, merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import type { FormControl } from '@ngneat/reactive-forms';
import { AbstractControl } from '@ngneat/reactive-forms';
import type { ControlState } from '@ngneat/reactive-forms/lib/types';
import { Placement } from '@popperjs/core';

import { TextType } from '../../atoms/text/text.component';

import { IconType } from '../../atoms/icons';

@Component({
  selector: 'adl-form-control',
  templateUrl: './form-control.component.html',
  styleUrls: ['./form-control.component.scss'],
})
export class FormControlComponent implements OnInit, OnDestroy, OnChanges {
  @Input() label: string;
  @Input() hint: string;
  @Input() hasCustomHint = false;
  @Input() control: AbstractControl;
  @Input() placement: Placement = 'top';
  @Input() labelType: TextType = 'accent';
  @Input() isLabelBold = null;
  @Input() labelWidth = '100%';
  @Input() isLabelCentered = false;
  @Input() errorMessages: { [type: string]: string | { text: string; hint: string } };
  @Input() required: boolean;
  @Input() successMessage: string;
  @Input() loadingMessage: string;
  @Input() relatedControlsToCheck: FormControl[] = [];
  @Input() hintType: IconType = 'inherit';
  @Input() inputWidth: string;

  @Input() inline: boolean;

  @Input() parentLabelClass = 'gap-2';
  @Input() alignCentered: boolean;
  @Input() isDisabled: boolean;

  messages: { text: string; hint: string }[] = [];
  hasError = false;
  computedParentLabelClasses: { [className: string]: boolean } = {};

  private _currentSubscription: Subscription;

  private readonly _destroyed$ = new Subject<void>();

  ngOnInit(): void {
    this._refreshRelatedControlsEventsToCheck();
    this._refreshParentLabelClasses();
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.relatedControlsToCheck && this.relatedControlsToCheck.length) {
      this._refreshRelatedControlsEventsToCheck();
    }

    if (changes.parentLabelClass && !changes.parentLabelClass.firstChange) {
      this._refreshParentLabelClasses();
    }
  }

  private _refreshRelatedControlsEventsToCheck(): void {
    const allControlsToCheck = [];

    if (this.control) {
      allControlsToCheck.push(this.control);
    }

    allControlsToCheck.push(...this.relatedControlsToCheck);
    const allControlsEventsToCheck = allControlsToCheck.reduce(
      (allControlsEventsToCheckTmp: Observable<boolean | ControlState>[], currentControlToCheck) => {
        allControlsEventsToCheckTmp.push(
          currentControlToCheck.dirty$ || EMPTY,
          currentControlToCheck.touch$ || EMPTY,
          currentControlToCheck.touchChanges || EMPTY,
          currentControlToCheck.status$ || EMPTY,
          currentControlToCheck.valueChanges || EMPTY,
        );

        return allControlsEventsToCheckTmp;
      },
      [] as Observable<boolean>[],
    );

    this._currentSubscription?.unsubscribe();
    this._currentSubscription = merge(...allControlsEventsToCheck)
      .pipe(takeUntil(this._destroyed$))
      .subscribe(() => {
        this._refreshMessages();
        this.hasError =
          allControlsToCheck.reduce(
            (hasError, currentControl) => hasError || currentControl.dirty || currentControl.touched,
            false,
          ) && this.control.status === 'INVALID';
      });
  }

  private _refreshMessages(): void {
    this.messages = [];

    if (this.control.errors) {
      Object.keys(this.control.errors).forEach((type) => {
        if (this.errorMessages && this.errorMessages[type]) {
          this._pushMessage(this.errorMessages[type]);
        }
      });
    }
  }

  private _pushMessage(message: string | { text: string; hint: string }): void {
    if (typeof message === 'string') {
      this.messages.push({ text: message, hint: null });
    } else {
      this.messages.push(message);
    }
  }

  private _refreshParentLabelClasses() {
    this.computedParentLabelClasses = {
      'adl-form-control--main-inline': this.inline,
      'disabled': this.isDisabled,
      [this.parentLabelClass]: true,
    };
  }
}
