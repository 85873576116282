import type { OnDestroy } from '@angular/core';
import { Injectable } from '@angular/core';
import type { SafeResourceUrl } from '@angular/platform-browser';
import type { Observable } from 'rxjs';
import { BehaviorSubject, forkJoin, of, Subject } from 'rxjs';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';
import { AccessRequestsV2Service, AccessRequestV2 } from '@dataportal/access-requests';
import { GlossaryComponentsService, GlossaryComponentWithNotificationInfo } from '@dataportal/business-glossary-common';
import { ApiService } from '@dataportal/front-api';
import { Logger } from '@dataportal/front-shared';
import type { PublicationStatus } from '@dataportal/publications';
import { Publication, PublicationsService } from '@dataportal/publications';
import {
  DashboardComment,
  DashboardCommentsService,
  RecommendationsService,
  UserRecommendation,
} from '@dataportal/sources-dashboards-recommendations';
import type { IDataAcademyCommentNotification } from '@dataportal/types';
import { UsersService } from '@dataportal/users';
import { EntityBuilder } from '@decahedron/entity';

import type { INotificationV2, INotificationV2Display, NotificationResourceType } from '../entities/notifications';
import { DataAcademyCommentNotification } from '../entities/notifications';

@Injectable({ providedIn: 'root' })
export class NotificationsV2Service implements OnDestroy {
  private readonly _currentUserNotifications$ = new BehaviorSubject<INotificationV2Display[]>([]);
  currentUserNotifications$ = this._currentUserNotifications$.asObservable();
  private readonly _destroyed$ = new Subject<void>();

  constructor(
    private readonly _usersService: UsersService,
    private readonly _apiService: ApiService,
    private readonly _accessRequestsV2Service: AccessRequestsV2Service,
    private readonly _publicationService: PublicationsService,
    private readonly _dashboardCommentsService: DashboardCommentsService,
    private readonly _glossaryComponentsService: GlossaryComponentsService,
    private readonly _recommendationService: RecommendationsService,
    private readonly _logger: Logger,
  ) {
    forkJoin({
      accessRequest: this._accessRequestsV2Service.listByOwner(),
      publications: this._publicationService.listPublicationsByOwner(),
      dashboardCommentsContacts: this._dashboardCommentsService.listDashboardCommentsByContacts(),
      dashboardCommentsReplies: this._dashboardCommentsService.listDashboardCommentsByreplies(),
      businessGlossary: this._glossaryComponentsService.getNotifications(),
      dataAcademy: this.fetchCurrentUserDataAcademyNotifications(),
      recommendations: this.fetchCurrentUserRecommendations(),
    })
      .pipe(
        switchMap(
          ({
            accessRequest,
            publications,
            dashboardCommentsContacts,
            dashboardCommentsReplies,
            businessGlossary,
            dataAcademy,
            recommendations,
          }) => {
            return this.createNotifications([
              ...accessRequest,
              ...publications,
              ...dashboardCommentsContacts,
              ...dashboardCommentsReplies,
              ...businessGlossary,
              ...dataAcademy,
              ...recommendations,
            ]);
          },
        ),
        switchMap((notifications) => {
          return this.getDisplayInformation(notifications);
        }),
        takeUntil(this._destroyed$),
      )
      .subscribe((notifsFull) => {
        this._currentUserNotifications$.next([...notifsFull]);
      });
  }

  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  private _checkIfNotificationIsWithin15Days(creationDate: Date): boolean {
    const actualDate = new Date();
    const daysBetween = Math.abs(creationDate.getTime() - actualDate.getTime()) / (24 * 60 * 60 * 1000);

    return daysBetween < 15;
  }

  getPublicationStatus(status: PublicationStatus): 'pending' | 'validated' {
    if (status === 'waiting publication') {
      return 'pending';
    } else {
      return 'validated';
    }
  }

  createNotifications(resources: NotificationResourceType[]): Observable<INotificationV2[]> {
    const newNotifications: INotificationV2[] = [];
    resources.map((resource) => {
      if (resource instanceof UserRecommendation) {
        const {
          source: { author_id: notifierId, author_name: notifierName },
        } = resource;

        let sourceType = 'data product';
        let sourceName = resource.source.source_name;
        let link = `/sources/${resource.source.source_id}`;

        if (resource.source.dashboard_name) {
          sourceType = 'dashboard';
          sourceName = resource.source.dashboard_name;
          link += `/dashboard/${resource.source.dashboard_name}`;
        }

        const mainMessage = `${notifierName} recommended you the ${sourceType} [${sourceName}]`;

        let creationDate: Date;

        if (resource.source.created_at) {
          creationDate = new Date(resource.source.created_at);
        }

        const notification: INotificationV2 = {
          notifierId,
          notifierName,
          mainMessage,
          type: 'recommendation',
          isSeen: resource.source.seen_by_user,
          creationDate,
          resourceType: 'sources',
          tabToDisplay: 'notifications',
          message: resource.source.custom_message,
          linkToResource: link,
          resourceRequestedId: resource.source.source_id,
          secondaryResourceId: resource.source.dashboard_name,
        };

        if (creationDate && this._checkIfNotificationIsWithin15Days(notification.creationDate)) {
          newNotifications.push(notification);
        }
      }

      if (resource instanceof AccessRequestV2) {
        const accessRequest: AccessRequestV2 = resource;
        const newAccessRequestNotif: INotificationV2 = {
          notifierId: accessRequest?.userId,
          notifierName: accessRequest?.requesterName,
          type: 'accessRequest',
          creationDate: new Date(accessRequest?.createdAt),
          updatingDate: new Date(accessRequest?.updatedAt),
          isSeen: accessRequest.hasBeenSeenByCurrentUser || accessRequest?.status !== 'pending',
          status: accessRequest?.status === 'pending' ? 'pending' : accessRequest?.history[0]?.finalStatus,
          resourceRequestedName: accessRequest.resourceName,
          resourceRequestedId: accessRequest.resourceId,
          resourceType: accessRequest.resource,
          tabToDisplay: 'requestedActions',
        };

        if (
          (newAccessRequestNotif.creationDate &&
            this._checkIfNotificationIsWithin15Days(newAccessRequestNotif.creationDate)) ||
          (newAccessRequestNotif.updatingDate &&
            this._checkIfNotificationIsWithin15Days(newAccessRequestNotif.updatingDate))
        ) {
          newNotifications.push(newAccessRequestNotif);
        }
      }

      if (resource instanceof Publication) {
        const publication: Publication = resource;

        if (publication.status !== 'unpublished') {
          const newPublicationNotif: INotificationV2 = {
            notifierId: publication.applicant,
            type: 'publication',
            creationDate: new Date(publication.createdAt),
            isSeen: publication.hasBeenSeenByCurrentUser || publication.status !== 'waiting publication',
            status: this.getPublicationStatus(publication.status),
            resourceRequestedName: publication.sourceName,
            resourceRequestedId: publication.sourceId,
            secondaryResourceName: publication.portalName,
            secondaryResourceId: publication.portalId,
            tabToDisplay: 'requestedActions',
          };

          if (this._checkIfNotificationIsWithin15Days(newPublicationNotif.creationDate)) {
            newNotifications.push(newPublicationNotif);
          }
        }
      }

      if (resource instanceof DashboardComment) {
        const dashboardComment: DashboardComment = resource;
        const newDashboardCommentNotif: INotificationV2 = {
          notifierId: dashboardComment.authorId,
          notifierName: dashboardComment.authorName,
          type: dashboardComment.parentId ? 'dashboardCommentReply' : 'dashboardComment',
          creationDate: new Date(dashboardComment.createdAt),
          isSeen: dashboardComment.hasBeenSeenByCurrentUser,
          resourceRequestedId: dashboardComment.dashboardId,
          resourceRequestedName: dashboardComment.dashboardName,
          secondaryResourceId: dashboardComment.sourceId,
          commentId: dashboardComment.id,
          parentId: dashboardComment.parentId,
          message: dashboardComment.message,
          tabToDisplay: 'notifications',
        };

        if (this._checkIfNotificationIsWithin15Days(newDashboardCommentNotif.creationDate)) {
          newNotifications.push(newDashboardCommentNotif);
        }
      }

      if (resource instanceof GlossaryComponentWithNotificationInfo) {
        const glossaryComponent: GlossaryComponentWithNotificationInfo = resource;
        const newGlossaryNotif: INotificationV2 = {
          notifierId:
            glossaryComponent?.status === 'CANDIDATE' && !glossaryComponent.archived
              ? glossaryComponent?.createdBy
              : glossaryComponent?.updatedBy,
          notifierName:
            glossaryComponent?.status === 'CANDIDATE' && !glossaryComponent.archived
              ? glossaryComponent?.createdByName
              : glossaryComponent?.updatedByName,
          type: 'glossary',
          creationDate:
            glossaryComponent?.status === 'CANDIDATE' && !glossaryComponent.archived
              ? new Date(glossaryComponent?.createdAt)
              : new Date(glossaryComponent?.updatedAt),
          isSeen: glossaryComponent.hasCurrentUserSeenNotification,
          status: glossaryComponent.archived ? 'REJECTED' : glossaryComponent?.status,
          resourceRequestedName: glossaryComponent.name,
          resourceRequestedId: glossaryComponent.pk,
          message: glossaryComponent.comment,
          tabToDisplay: 'notifications',
        };

        let creationDate: Date;

        if (newGlossaryNotif.creationDate) {
          creationDate = new Date(newGlossaryNotif.creationDate);
        }

        if (creationDate && this._checkIfNotificationIsWithin15Days(creationDate)) {
          newNotifications.push(newGlossaryNotif);
        }
      }

      if (resource instanceof DataAcademyCommentNotification) {
        const daComment: DataAcademyCommentNotification = resource;
        const newDataAcademyNotif: INotificationV2 = {
          ...daComment,
          type: daComment.notificationType,
          creationDate: new Date(Number(daComment.creationDate)),
          resourceType: daComment.entityType,
          resourceRequestedId: daComment.entityId,
          resourceRequestedName: daComment.entityName,
          tabToDisplay:
            daComment.notificationType === 'dataAcademyFROwner' || daComment.notificationType === 'dataAcademyFRTagged'
              ? 'requestedActions'
              : 'notifications',
        };

        let creationDate: Date;

        if (newDataAcademyNotif.creationDate) {
          creationDate = new Date(newDataAcademyNotif.creationDate);
        }

        if (creationDate && this._checkIfNotificationIsWithin15Days(creationDate)) {
          newNotifications.push(newDataAcademyNotif);
        }
      }
    });

    return of(newNotifications);
  }

  getDisplayInformation(notifications: INotificationV2[]): Observable<INotificationV2Display[]> {
    const uniqueNotifiersIds = [...new Set(notifications.map((notification) => notification.notifierId))];
    const mappingNotifierDisplayInfo = new Map<string, { name: string; picture: SafeResourceUrl }>();

    return forkJoin(
      uniqueNotifiersIds.map((uniqueNotifierId) =>
        forkJoin({
          notifierId: of(uniqueNotifierId),
          notifierProfile: this._usersService.getProfile(uniqueNotifierId, null, true),
        }).pipe(
          switchMap(({ notifierId, notifierProfile }) => {
            mappingNotifierDisplayInfo.set(notifierId, {
              picture: notifierProfile.picture,
              name: notifierProfile.name,
            });

            return of(notifierId);
          }),
        ),
      ),
    ).pipe(
      switchMap(() => {
        return of(
          notifications.map((notification) => {
            return {
              ...notification,
              picture:
                notification.type === 'dataAcademyFRUpdated'
                  ? '/assets/dpg/imgs/icons/academy-notification.svg'
                  : mappingNotifierDisplayInfo.get(notification.notifierId).picture,
              notifierName: mappingNotifierDisplayInfo.get(notification.notifierId).name,
            };
          }),
        );
      }),
    );
  }

  fetchCurrentUserRecommendations(): Observable<UserRecommendation[]> {
    return this._recommendationService
      .getUserRecommendations()
      .pipe(map((raw) => UserRecommendation.createUserRecommendations(raw)));
  }

  fetchCurrentUserDataAcademyNotifications(): Observable<DataAcademyCommentNotification[]> {
    return this._apiService.get(`/v4/notifications/academy-comments`, {}).pipe(
      map((notifications: IDataAcademyCommentNotification[]) => {
        return EntityBuilder.buildMany<DataAcademyCommentNotification>(DataAcademyCommentNotification, notifications);
      }),
      catchError((err: unknown) => {
        this._logger.error(
          `[NotificationsService] Error while fetching current user data academy notifications : ${err}`,
        );

        return [];
      }),
    );
  }

  postHasSeenNotification(pk: string) {
    return this._apiService.post(`/v4/notifications/${pk}/seen`, {});
  }
}
