<adl-modal
  headerBackgroundColor="primary-500"
  [roundCorner]="false"
  closeButtonColor="white"
  [isAlignedToCross]="true"
  backgroundColor="grey-100"
>
  <div class="dp-admin--create-or-import-group-modal--header my-8 px-[30px] w-full" modal-header>
    <adl-text type="normal" [size]="20" bold="true" color="white">Groups</adl-text>
    <adl-text type="normal" [size]="14" color="white">Add group / import from Active Directory</adl-text>
  </div>
  <div modal-body class="dp-admin--create-or-import-group-modal--body">
    <app-tabs
      tabType="primary"
      justifyType="start"
      class="mb-8 mt-5 w-full"
      (tabChangeEvent)="setGroupType($event)"
      [tabToActivate]="tabToChange"
      [stretched]="true"
    >
      <app-tab tabTitle="Add group">
        <adl-profile-card [header]="true" title="NATIVE GROUP">
          <div class="p-10">
            <adl-form-control
              label="Group Name"
              [control]="groupNameFormControl"
              [inline]="true"
              labelWidth="30%"
              required
            >
              <adl-input [control]="groupNameFormControl" variant="new" fixedSize="300px"></adl-input>
            </adl-form-control>
            <div class="flex">
              <adl-form-control label="Group members(s)" labelWidth="100%" required class="flex flex-grow">
                <div class="flex">
                  <dpg-limited-user-select
                    class="w-full flex-grow"
                    placeholder="Choose your group member(s)"
                    [predicates]="limitedUserPredicates"
                    (selected)="selectedLimitedUser = $event"
                    [clear]="clearSelectSubject"
                  ></dpg-limited-user-select>
                  <adl-button
                    class="pl-4 w-[134px]"
                    type="primary"
                    (clicked)="addNewMember()"
                    [disabled]="!selectedLimitedUser"
                  >
                    + Add
                  </adl-button>
                </div>
              </adl-form-control>
            </div>
            <div class="dp-admin--create-or-import-group-modal--owners-list">
              <div
                *ngIf="actualGroupCreation.groupMembers.size"
                class="dp-admin--create-or-import-group-modal--owners-list-and-hint"
              >
                <ul class="scrollbar">
                  <li *ngFor="let groupMember of actualGroupCreation.groupMembers">
                    <adl-text>{{ groupMember }}</adl-text>
                    <div class="row-flex">
                      <adl-button
                        (clicked)="toggleMemberStatus(groupMember)"
                        type="secondary"
                        class="mr10"
                        [disabled]="!isUserFromPR(groupMember) || isCurrentUser(groupMember)"
                      >
                        {{ memberStatus(groupMember) }}
                      </adl-button>
                      <adl-icon-button
                        class="dp-admin--create-or-import-group-modal--delete"
                        (clicked)="removeMember(groupMember)"
                        icon="trash"
                        iconType="grey"
                      ></adl-icon-button>
                    </div>
                  </li>
                </ul>
              </div>
              <adl-text *ngIf="actualGroupCreation.groupMembers.size === 0"
                >The people you choose will appear here</adl-text
              >
            </div>
          </div>
        </adl-profile-card>
      </app-tab>
      <app-tab tabTitle="Import from AD">
        <adl-profile-card [header]="true" title="IMPORT FROM ACTIVE DIRECTORY">
          <div class="px-10 pt-10 pb-[55px]">
            <adl-form-control label="Select an Azure AD Group" [inline]="true" labelWidth="80%" required>
              <dpg-group-ad-select
                (selected)="selectedAdGroup = $event"
                label="name"
                value="adId"
              ></dpg-group-ad-select>
            </adl-form-control>
          </div>
        </adl-profile-card>
      </app-tab>
    </app-tabs>
  </div>
  <div modal-footer>
    <adl-loaded-item [loading]="isLoading">
      <div class="dp-admin--btn-wrapper row-flex j-center">
        <adl-button (clicked)="cancel()" type="cancel">Cancel</adl-button>
        <adl-button (clicked)="confirm()" [disabled]="!ready">
          {{ actualGroupCreation.isImporting ? 'Import' : 'Save' }}
        </adl-button>
      </div>
    </adl-loaded-item>
  </div>
</adl-modal>
