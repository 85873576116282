<div class='alert-modal'>
  <div class="df a-start j-space-between p-8 banner-header">
    <div class="mb0 banner-title">
      <span>{{ (alertsService.alert$ | async)?.text }}</span>
    </div>
    <button type="button" class="m0 close" aria-label="Close" (click)="ignore()">
      <span aria-hidden="true"><em class="icon-cross"></em></span>
    </button>
  </div>
  <div class="p-8 ml-4 banner-body pt0 pb15">
    <div class="panel-modal">
      <div class="panel-modal--content">
        <div [innerHTML]="(alertsService.alert$ | async)?.readMore | listCss | safeHtml"></div>
      </div>
    </div>
  </div>
</div>
