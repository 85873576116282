import type { OnChanges } from '@angular/core';
import { Component, Input, ViewEncapsulation } from '@angular/core';

export type AlertType = 'success' | 'error' | 'warning' | 'info';

@Component({
  selector: 'adl-alert-title',
  templateUrl: './alert-title.component.html',
  styleUrls: ['./alert-title.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AlertTitleComponent implements OnChanges {
  @Input() type: AlertType = 'info';
  @Input() title: string;
  @Input() showBorder = false;

  svgPath: string;
  crossPath: string;

  ngOnChanges(): void {
    this._updateSvgs();
  }

  private _updateSvgs() {
    this.svgPath = this._getSvgPath();
    this.crossPath = this._getCrossSvgPath();
  }

  private _getSvgPath() {
    return `assets/dpg/imgs/icons/alert-${this.type}.svg`;
  }

  private _getCrossSvgPath() {
    return `assets/dpg/imgs/icons/icon-cross-${this.type}.svg`;
  }
}
