import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ActualSizeIconComponent } from './components/actual-size-icon/actual-size-icon.component';
import { AirflowIconComponent } from './components/airflow-icon/airflow-icon.component';
import { AzuredevopsIconComponent } from './components/azuredevops-icon/azuredevops-icon.component';
import { BookmarkIconComponent } from './components/bookmark-icon/bookmark-icon.component';
import { DataAcademyIconComponent } from './components/data-academy-icon/data-academy-icon.component';
import { DatabricksIconComponent } from './components/databricks-icon/databricks-icon.component';
import { DatalakeIconComponent } from './components/datalake-icon/datalake-icon.component';
import { DssIconComponent } from './components/dss-icon/dss-icon.component';
import { ExportIconComponent } from './components/export-icon/export-icon.component';
import { ExternalLinkIconComponent } from './components/external-link-icon/external-link-icon.component';
import { FilterIconComponent } from './components/filter-icon/filter-icon.component';
import { FitToPageIconComponent } from './components/fit-to-page-icon/fit-to-page-icon.component';
import { FitToWidthIconComponent } from './components/fit-to-width-icon/fit-to-width-icon.component';
import { FullscreenIconComponent } from './components/fullscreen-icon/fullscreen-icon.component';
import { JupyterIconComponent } from './components/jupyter-icon/jupyter-icon.component';
import { PrintIconComponent } from './components/print-icon/print-icon.component';
import { RecommendIconComponent } from './components/recommend-icon/recommend-icon.component';
import { SaveIconComponent } from './components/save-icon/save-icon.component';
import { SelectIconComponent } from './components/select-icon/select-icon.component';
import { ShareIconComponent } from './components/share-icon/share-icon.component';
import { SnowflakeIconComponent } from './components/snowflake-icon/snowflake-icon.component';
import { SupersetIconComponent } from './components/superset-icon/superset-icon.component';
import { SupportIconComponent } from './components/support-icon/support-icon.component';
import { UploadIconComponent } from './components/upload-icon/upload-icon.component';
import { ViewIconComponent } from './components/view-icon/view-icon.component';
import { UploadBlueIconComponent } from './components/upload-blue-icon/upload-blue-icon.component';

@NgModule({
  imports: [CommonModule],
  declarations: [
    ActualSizeIconComponent,
    AirflowIconComponent,
    AzuredevopsIconComponent,
    BookmarkIconComponent,
    DatabricksIconComponent,
    DatalakeIconComponent,
    DataAcademyIconComponent,
    DssIconComponent,
    ExportIconComponent,
    ExternalLinkIconComponent,
    FilterIconComponent,
    FitToPageIconComponent,
    FitToWidthIconComponent,
    FullscreenIconComponent,
    JupyterIconComponent,
    PrintIconComponent,
    RecommendIconComponent,
    ShareIconComponent,
    SaveIconComponent,
    SelectIconComponent,
    SnowflakeIconComponent,
    SupportIconComponent,
    SupersetIconComponent,
    ViewIconComponent,
    UploadIconComponent,
    UploadBlueIconComponent,
  ],
  exports: [
    ActualSizeIconComponent,
    AirflowIconComponent,
    AzuredevopsIconComponent,
    BookmarkIconComponent,
    DatabricksIconComponent,
    DatalakeIconComponent,
    DataAcademyIconComponent,
    DssIconComponent,
    ExportIconComponent,
    ExternalLinkIconComponent,
    FilterIconComponent,
    FitToPageIconComponent,
    FitToWidthIconComponent,
    FullscreenIconComponent,
    JupyterIconComponent,
    PrintIconComponent,
    RecommendIconComponent,
    ShareIconComponent,
    SaveIconComponent,
    SelectIconComponent,
    SnowflakeIconComponent,
    SupportIconComponent,
    SupersetIconComponent,
    ViewIconComponent,
    UploadIconComponent,
    UploadBlueIconComponent,
  ],
})
export class IconsModule {}
