import { CommonModule } from '@angular/common';
import type { ModuleWithProviders } from '@angular/core';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { AdlModule } from '@dataportal/adl';
import { AuthModule } from '@dataportal/auth';
import { ApiModule } from '@dataportal/front-api';
import { SharedModule } from '@dataportal/front-shared';
import { IconsModule } from '@dataportal/icons';
import { NavigatorModule } from '@dataportal/navigator';
import { UsersModule } from '@dataportal/users';
import { NgxChartsModule } from '@swimlane/ngx-charts';

import { DashboardExplorerService } from './services/dashboard-explorer.service';
import { DashboardModalService } from './services/dashboard-modal.service';
import { DashboardsService } from './services/dashboards.service';
import { DashboardsCubeService } from './services/dashboards-cube.service';
import { DashboardsLogsService } from './services/dashboards-logs.service';
import { FavoritesDashboardsService } from './services/favorites-dashboards.service';
import { PowerBiBookmarksService } from './services/power-bi-bookmarks.service';
import { PowerBiEmbedService } from './services/power-bi-embed.service';
import { PowerBiService } from './services/power-bi.factory';
import { TableauService } from './services/tableau.service';

import { DashboardCardComponent } from './components/dashboard-card/dashboard-card.component';
import { DashboardCommentsPartComponent } from './components/dashboard-comments-part/dashboard-comments-part.component';
import { DashboardCommentsThreadComponent } from './components/dashboard-comments-thread/dashboard-comments-thread.component';
import { DashboardFolderCardComponent } from './components/dashboard-folder-card/dashboard-folder-card.component';
import { DashboardImageComponent } from './components/dashboard-image/dashboard-image.component';
import { DashboardMaintenanceComponent } from './components/dashboard-maintenance/dashboard-maintenance.component';
import { DashboardMobileNavbarComponent } from './components/dashboard-mobile-navbar/dashboard-mobile-navbar.component';
import { DashboardNewTabComponent } from './components/dashboard-new-tab/dashboard-new-tab.component';
import { DashboardPasteFiltersModalComponent } from './components/dashboard-paste-filters-modal/dashboard-paste-filters-modal.component';
import { DashboardRenameBookmarkModalComponent } from './components/dashboard-rename-bookmark-modal/dashboard-rename-bookmark-modal.component';
import { DashboardShareBookmarkModalComponent } from './components/dashboard-share-bookmark-modal/dashboard-share-bookmark-modal.component';
import { DashboardSidebarComponent } from './components/dashboard-sidebar/dashboard-sidebar.component';
import { DashboardToolbarComponent } from './components/dashboard-toolbar/dashboard-toolbar.component';
import { DashboardUrlModalComponent } from './components/dashboard-url-modal/dashboard-url-modal.component';
import { DashboardsListComponent } from './components/dashboards-list/dashboards-list.component';
import { DashboardErrorPageComponent } from './pages/dashboard-error-page/dashboard-error-page.component';
import { DashboardPageComponent } from './pages/dashboard-page/dashboard-page.component';

import type { IDashboardsOptions } from './dashboards-options';
import { DASHBOARDS_OPTIONS } from './dashboards-options';

// Module
// @dynamic
@NgModule({
  imports: [
    CommonModule,
    AdlModule,
    ApiModule,
    AuthModule,
    NavigatorModule,
    SharedModule,
    FormsModule,
    IconsModule,
    NgxChartsModule,
    MatDialogModule,
    UsersModule,
  ],
  declarations: [
    DashboardUrlModalComponent,
    DashboardShareBookmarkModalComponent,
    DashboardPageComponent,
    DashboardSidebarComponent,
    DashboardMaintenanceComponent,
    DashboardMobileNavbarComponent,
    DashboardToolbarComponent,
    DashboardCommentsPartComponent,
    DashboardCommentsThreadComponent,
    DashboardCardComponent,
    DashboardFolderCardComponent,
    DashboardImageComponent,
    DashboardsListComponent,
    DashboardNewTabComponent,
    DashboardRenameBookmarkModalComponent,
    DashboardPasteFiltersModalComponent,
    DashboardErrorPageComponent,
  ],
  providers: [
    DashboardExplorerService,
    DashboardsService,
    DashboardsCubeService,
    DashboardsLogsService,
    PowerBiService,
    PowerBiEmbedService,
    PowerBiBookmarksService,
    DashboardModalService,
    FavoritesDashboardsService,
    TableauService,
  ],
  exports: [
    DashboardUrlModalComponent,
    DashboardShareBookmarkModalComponent,
    DashboardPageComponent,
    DashboardErrorPageComponent,
    DashboardSidebarComponent,
    DashboardMaintenanceComponent,
    DashboardMobileNavbarComponent,
    DashboardToolbarComponent,
    DashboardCommentsPartComponent,
    DashboardCommentsThreadComponent,
    DashboardCardComponent,
    DashboardFolderCardComponent,
    DashboardImageComponent,
    DashboardsListComponent,
    DashboardNewTabComponent,
  ],
})
export class DashboardsModule {
  // Statics
  static forRoot(options: IDashboardsOptions): ModuleWithProviders<DashboardsModule> {
    return {
      ngModule: DashboardsModule,
      providers: [
        { provide: DASHBOARDS_OPTIONS, useValue: options },
        { provide: MAT_DIALOG_DATA, useValue: {} },
      ],
    };
  }
}
