<ng-container *ngIf="isTextDoc; else videoDoc">
  <div *ngIf="!isRichText" class="documentation-viewer--text-doc" [innerHtml]="sanitizedHtml"></div>
  <ng-container *ngIf="isRichText" class="documentation-viewer--text-doc">
    <da-rich-text [text]="documentation?.text"></da-rich-text>
  </ng-container>
</ng-container>

<ng-template #videoDoc>
  <da-video-player
    *ngIf="isVideoDoc; else otherDoc"
    [videos]="documentation.videos"
    [documentationId]="documentation.id"
    [checkViews]="canCheckViews"
  ></da-video-player>
</ng-template>

<ng-template #otherDoc>
  <adl-loaded-item
    loaderType="da"
    *ngIf="isIframeLoading"
    [iconSize]="50"
    [loading]="true"
    class="m-auto"
  ></adl-loaded-item>
  <iframe
    *ngIf="isMsOfficeDoc || externalUrl; else pdfViewer"
    title="product documentation"
    sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
    [src]="(internalUrl ? internalUrl : externalUrl) | safeUrl"
    (load)="onLoad()"
  ></iframe>

  <ng-template #pdfViewer>
    <pdf-viewer
      class="playbook__content-pdf"
      [src]="internalUrl"
      [render-text]="false"
      [original-size]="false"
      [external-link-target]="'blank'"
      [show-all]="true"
      [show-borders]="false"
      [style.width]="'100%'"
      [style.height]="'100%'"
    ></pdf-viewer>
  </ng-template>
</ng-template>
