import { NgForOf } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';

import { AtomsModule } from './atoms/atoms.module';
import { DirectivesModule } from './directives/directives.module';
import { MoleculesModule } from './molecules/molecules.module';
import { OrganismsModule } from './organisms/organisms.module';
import { PipesModule } from './pipes/pipes.module';

@NgModule({
  imports: [MatDialogModule, AtomsModule, MoleculesModule, OrganismsModule, DirectivesModule, PipesModule, NgForOf],
  exports: [AtomsModule, MoleculesModule, OrganismsModule, DirectivesModule, PipesModule],
  providers: [{ provide: MatDialogRef, useValue: {} }],
})
export class AdlModule {}
