<label
  class="adl-checkbox flex items-center"
  [ngClass]='[checkboxSize, (disabled ? "cursor-not-allowed" : "cursor-pointer"), (hasPaddingRight ? "": "pr-0")]'
  [class.disabled]="disabled"
>
  <input (change)="handleChange($event)" type="checkbox" [checked]="checked" [disabled]="disabled"/>
  <div
    class="adl-checkbox--checkmark"
    [ngClass]='[checkboxColor, checkboxSize, shape]'
  ></div>
  <span class="adl-checkbox--label" [ngClass]='checkboxSize'>
    <ng-content></ng-content>
  </span>
</label>
