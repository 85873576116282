import type { IGroupPermissions } from '@dataportal/types';
import { Entity } from '@decahedron/entity';

// Entity
export class GroupPermission extends Entity {
  // Attributes
  groupId: string;
  groupName: string;
  roles: string[];

  // Methods
  fromJson(jsonData: IGroupPermissions): GroupPermission {
    this.groupId = jsonData.group_id;
    this.groupName = jsonData.group_name;
    this.roles = jsonData.roles;

    return this;
  }

  toJson(): Omit<IGroupPermissions, 'pk' | 'sk'> {
    return {
      group_id: this.groupId,
      group_name: this.groupName,
      roles: this.roles,
    };
  }
}
