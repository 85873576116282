import type { OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import type { Observable, Subscription } from 'rxjs';
import { EMPTY, merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import type { FormControl } from '@ngneat/reactive-forms';
import { AbstractControl } from '@ngneat/reactive-forms';
import type { ControlState } from '@ngneat/reactive-forms/lib/types';
import { Placement } from '@popperjs/core';

@Component({
  selector: 'adl-selectable-form-control',
  templateUrl: './selectable-form-control.component.html',
  styleUrls: ['./selectable-form-control.component.scss'],
})
export class SelectableFormControlComponent implements OnInit, OnDestroy, OnChanges {
  // Inputs
  @Input() label: string;
  @Input() hint: string;
  @Input() control: AbstractControl;
  @Input() placement: Placement = 'top';
  @Input() labelColor = 'primary-800';
  @Input() labelWidth = '100px';
  @Input() isDisabled: boolean;
  @Input() hasRemoveBtn = true;
  @Input() errorMessages: { [type: string]: string | { text: string; hint: string } };
  @Input() relatedControlsToCheck: FormControl[] = [];
  // Outputs
  @Output() onRemove = new EventEmitter<boolean>();

  messages: { text: string; hint: string }[] = [];
  hasError = false;
  private _currentSubscription: Subscription;

  private readonly _destroyed$ = new Subject<void>();

  ngOnInit(): void {
    this._refreshRelatedControlsEventsToCheck();
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.relatedControlsToCheck && this.relatedControlsToCheck.length) {
      this._refreshRelatedControlsEventsToCheck();
    }
  }

  private _refreshRelatedControlsEventsToCheck(): void {
    const allControlsToCheck = this.control
      ? [this.control, ...this.relatedControlsToCheck]
      : [...this.relatedControlsToCheck];
    const allControlsEventsToCheck = allControlsToCheck.reduce(
      (allControlsEventsToCheckTmp: Observable<boolean | ControlState>[], currentControlToCheck) => {
        allControlsEventsToCheckTmp.push(
          currentControlToCheck.dirty$ ?? EMPTY,
          currentControlToCheck.touch$ ?? EMPTY,
          currentControlToCheck.status$ ?? EMPTY,
          currentControlToCheck.valueChanges ?? EMPTY,
        );

        return allControlsEventsToCheckTmp;
      },
      [] as Observable<boolean>[],
    );
    this._currentSubscription?.unsubscribe();
    this._currentSubscription = merge(...allControlsEventsToCheck)
      .pipe(takeUntil(this._destroyed$))
      .subscribe(() => {
        this._refreshMessages();
        this.hasError =
          allControlsToCheck.reduce(
            (hasError, currentControl) => hasError || currentControl.dirty || currentControl.touched,
            false,
          ) && this.control?.status === 'INVALID';
      });
  }

  private _refreshMessages(): void {
    this.messages = [];

    if (this.control?.errors) {
      Object.keys(this.control?.errors).forEach((type) => {
        if (this.errorMessages && this.errorMessages[type]) {
          this._pushMessage(this.errorMessages[type]);
        }
      });
    }
  }

  private _pushMessage(message: string | { text: string; hint: string }): void {
    if (typeof message === 'string') {
      this.messages.push({ text: message, hint: null });
    } else {
      this.messages.push(message);
    }
  }

  removeFormControl(): void {
    this.onRemove.emit(true);
  }
}
