import { Injectable } from '@angular/core';
import { EnvironmentService } from '@dataportal/front-environment';

import locales from './locales';

// Service
@Injectable({
  providedIn: 'root',
})
export class WordingService {
  constructor(private readonly _environmentService: EnvironmentService) {}

  translate(key: keyof (typeof locales)['PR']): string {
    const portalCode = this._environmentService.options.dpContext.code;
    const portalLocale = locales[portalCode];

    return portalLocale[key];
  }
}
