<div class="flex column-flex justify-center a-start w100">
  <div *ngFor="let choice of choices; let index = index" class="flex flex-row justify-start a-center w100 mt-[15px]">
    <label class="adl--radio-selector-content-container cursor-pointer pt-[2px] relative flex w-fit pl-[35px]" [ngClass]="choiceComponentClassList">
      <input
        class='absolute opacity-0 h-0 w-0'
        type="radio"
        [name]="selectorName"
        [value]="choice"
        [checked]="valueEqualityCheck(choice, activeChoice)"
        (click)="selectRadio(choice)"
      />
      <span class="adl--radio-selector--checkmark new"></span>
    </label>
    <div class="flex column-flex justify-center a-start w-11/12 ml-[40px]">
      <ng-container [ngTemplateOutlet]="choicesContentsArray[index]"></ng-container>
    </div>
  </div>
</div>
