import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { BehaviorSubject, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { ApiService } from '@dataportal/front-api';
import { AlertService, Logger } from '@dataportal/front-shared';
import type { DirectionalLink, EnabledLink } from '@dataportal/types';
import { LinkDirection } from '@dataportal/types';
import { EntityBuilder } from '@decahedron/entity';

import { BidirectionalLink } from '../entities/bidirectional-link';
import { EnabledLinks } from '../entities/enabled-links';
import type { SourceLink } from '../entities/sourceLink';
import { UnidirectionalLink } from '../entities/unidirectional-link';

export interface IUnidirectionalRelationshipPayload {
  label: string;
  type: LinkDirection.UNIDIRECTIONAL;
}

export interface IBidirectionalRelationshipPayload {
  label: string;
  type: LinkDirection.BIDIRECTIONAL;
  reverseLabel: string;
  editable: boolean;
  reverseEditable: boolean;
  approvalRequired: boolean;
}

export type RelationshipPayload = IUnidirectionalRelationshipPayload | IBidirectionalRelationshipPayload;

@Injectable()
export class SourceLinksService {
  private readonly _links$ = new BehaviorSubject<Array<SourceLink>>([]);
  links$ = this._links$.asObservable();

  constructor(
    private readonly _logger: Logger,
    private readonly _api: ApiService,
    private readonly _alertService: AlertService,
  ) {}

  private static _buildOne(json: DirectionalLink): SourceLink {
    if (json.type === LinkDirection.BIDIRECTIONAL) {
      return EntityBuilder.buildOne(BidirectionalLink, json);
    }

    return EntityBuilder.buildOne(UnidirectionalLink, json);
  }

  private static _buildMany(json: DirectionalLink[]): SourceLink[] {
    return json.map((data) => SourceLinksService._buildOne(data));
  }

  listLinks(): Observable<Array<SourceLink>> {
    this._logger.debug('[RelationshipsService] GET /v4/relationships/links');

    return this._api.get('/v4/relationships/links').pipe(
      catchError((err: unknown) => {
        this._logger.error('[RelationshipsService] GET /v4/relationships/links', err);

        return throwError(err);
      }),
      map((res: DirectionalLink[]) => SourceLinksService._buildMany(res)),
      tap((links) => {
        this._links$.next(links);
      }),
    );
  }

  listEnabled(): Observable<EnabledLinks> {
    return this._api.get<Array<EnabledLink>>('/v4/relationships/links?enabled=true').pipe(
      map((links) => {
        return new EnabledLinks(links);
      }),
    );
  }

  createLink(link: RelationshipPayload): Observable<SourceLink> {
    this._logger.debug('[RelationshipsService] POST /v4/relationships/links', link);

    return this._api.post('/v4/relationships/links', link).pipe(
      catchError((err: unknown) => {
        this._logger.error('[RelationshipsService] POST /v4/relationships/links', err);

        return throwError(err);
      }),
      map((res: DirectionalLink) => SourceLinksService._buildOne(res)),
      tap(() => this._alertService.success('Relationship successfully created')),
    );
  }

  updateLink(id: string, link: RelationshipPayload): Observable<void> {
    this._logger.debug(`[RelationshipsService] PUT /v4/relationships/links/${id}`, link);

    return this._api.put(`/v4/relationships/links/${id}`, link).pipe(
      catchError((err: unknown) => {
        this._logger.error('[RelationshipsService] POST /v4/relationships/links', err);

        return throwError(err);
      }),
      map(() => this._alertService.success('Relationship successfully updated')),
    );
  }

  removeLink(id: string): Observable<void> {
    this._logger.debug(`[RelationshipsService] DELETE /v4/relationships/links/${id}`);

    return this._api.delete(`/v4/relationships/links/${id}`).pipe(
      catchError((err: unknown) => {
        this._logger.error('[RelationshipsService] POST /v4/relationships/links', err);

        return throwError(err);
      }),
      map(() => {
        this._alertService.success('Relationship successfully removed');
        this._logger.info('Successfully deleted link', id);
      }),
    );
  }

  createCategory(title: string, priority: number) {
    return this._api.post('v4/relationships/links/enable', {
      priority,
      title,
    });
  }

  enableLink(linkId: string, priority: number, parent?: string) {
    return this._api.post('v4/relationships/links/enable', {
      priority,
      linkId,
      parent: parent || undefined,
    });
  }

  deleteCategory(linkId: string) {
    return this._api.delete(`v4/relationships/links/${linkId}/disable?isCategory=true`);
  }

  disableLink(linkId: string) {
    return this._api.delete(`v4/relationships/links/${linkId}/disable`);
  }
}
