import { Clipboard } from '@angular/cdk/clipboard';
import type { OnDestroy, OnInit } from '@angular/core';
import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, first, take, takeUntil } from 'rxjs/operators';
import { DialogsService, ModalSizes, SidebarService } from '@dataportal/adl';
import { GoogleTagManagerService } from '@dataportal/analytics';
import { CurrentUserService } from '@dataportal/auth';
import { AlertService, DeviceService, Logger } from '@dataportal/front-shared';
import { Navigator } from '@dataportal/navigator';
import type { User } from '@dataportal/users';

import { RecommendationModalService } from '../../../recommendations/services/recommendation-modal.service';
import { FavoritesSourcesService } from '../../../sources/services/favorites-sources.service';
import { CurrentDashboardService } from '../../services/current-dashboard.service';
import { DashboardsService } from '../../services/dashboards.service';
import { FavoritesDashboardsService } from '../../services/favorites-dashboards.service';
import { PowerBiBookmarksService } from '../../services/power-bi-bookmarks.service';
import { PowerBiEmbedService } from '../../services/power-bi-embed.service';

import { DashboardPasteFiltersModalComponent } from '../dashboard-paste-filters-modal/dashboard-paste-filters-modal.component';
import { DashboardRenameBookmarkModalComponent } from '../dashboard-rename-bookmark-modal/dashboard-rename-bookmark-modal.component';
import { DashboardShareBookmarkModalComponent } from '../dashboard-share-bookmark-modal/dashboard-share-bookmark-modal.component';
import { DashboardUrlModalComponent } from '../dashboard-url-modal/dashboard-url-modal.component';

import type { Source } from '../../../sources/entities/source';
import { DASHBOARDS_OPTIONS, IDashboardsOptions } from '../../dashboards-options';
import type { Dashboard } from '../../entities/dashboard.model';
import type { ExportType } from '../../services/dashboards.service';
import type { IDashboardBookmark } from '../../services/power-bi-bookmarks.service';

@Component({
  selector: 'dpg-dashboard-toolbar',
  templateUrl: './dashboard-toolbar.component.html',
  styleUrls: ['./dashboard-toolbar.component.scss'],
})
export class DashboardToolbarComponent implements OnInit, OnDestroy {
  @Input() isLoadingDashboardComments = true;
  @Input() nbDashboardComments = 0;

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() toggleDisplayCommentsPartEvent = new EventEmitter<boolean>();

  currentUser: User;
  source: Source;
  dashboard: Dashboard;
  isPowerBI = false;
  lastUpdate: string;
  openedDropdownId: string;
  openedNestedDropdownId: string;

  loadingBookmark = false;
  hasSavedBookmarks = false;
  bookmarkHasChanged = false;
  hasProcessedDefaultBookmark = false;
  newFiltersName: string;

  everyBookmarks: IDashboardBookmark[] = [];
  ownBookmarks: IDashboardBookmark[] = [];
  sharedBookmarks: IDashboardBookmark[] = [];
  defaultBookmark: IDashboardBookmark;
  appliedBookmark: IDashboardBookmark;

  exportOnlyCurrentPage = true;
  exportTypes: ExportType[] = ['pdf', 'pptx', 'png'];
  isSourceAuthorizedToExport = false;

  exportProgress: Record<ExportType, number | null> = {
    png: null,
    pdf: null,
    pptx: null,
  };

  isGeneratingSignedUrl: Record<ExportType, boolean> = {
    png: null,
    pdf: false,
    pptx: false,
  };

  isMobile = false;
  isSidebarVisible = true;
  isAlreadyExporting = false;

  // comments part
  hasToDisplayCommentsPart = false;

  // Constructor
  private readonly _destroyed$ = new Subject<void>();

  constructor(
    private readonly _dialogsService: DialogsService,
    private readonly _route: ActivatedRoute,
    private readonly _router: Router,
    private readonly _currentUserService: CurrentUserService,
    private readonly _currentDashboardService: CurrentDashboardService,
    private readonly _dashboardsService: DashboardsService,
    private readonly _favoritesDashboardsService: FavoritesDashboardsService,
    private readonly _powerBiBookmarksService: PowerBiBookmarksService,
    private readonly _powerBiEmbedService: PowerBiEmbedService,
    private readonly _recommendationsModalService: RecommendationModalService,
    private readonly _alertService: AlertService,
    private readonly _logger: Logger,
    private readonly _navigator: Navigator,
    private readonly _favoritesSourcesService: FavoritesSourcesService,
    private readonly _deviceService: DeviceService,
    private readonly _sidebarService: SidebarService,
    private readonly _gtmService: GoogleTagManagerService,
    private readonly _clipboard: Clipboard,
    @Inject(DASHBOARDS_OPTIONS) private readonly _options: IDashboardsOptions,
  ) {}

  get isTableau(): boolean {
    return this.dashboard.type === 'tableau';
  }

  get hasOnlyOneDashboard(): boolean {
    return this.visibleRelatedDashboards?.length === 1;
  }

  get isNotMobilePowerbi(): boolean {
    return !this.isMobile && this.isPowerBI;
  }

  get hasToDisplaySupportLinkButton(): boolean {
    return (this.isTableau || this.isNotMobilePowerbi) && !!this.dashboard?.supportLink?.length;
  }

  // Properties
  get savedBookmarksNestedDropdownPosition() {
    const getScroll = document.getElementById('saved-bookmarks');
    if (!getScroll?.scrollTop) return `margin-top: -0.5rem`;

    return `margin-top: calc(-${getScroll.scrollTop}px - 1.5rem)`;
  }

  get sharedBookmarksNestedDropdownPosition() {
    const getScroll = document.getElementById('shared-bookmarks');
    if (!getScroll?.scrollTop) return `margin-top: -0.5rem`;

    return `margin-top: calc(-${getScroll.scrollTop}px - 1.5rem)`;
  }

  get currentDashboardName(): string {
    return this.dashboard.name;
  }

  get isLastUpdateDisabled(): boolean {
    return this.dashboard ? this.dashboard.isLastUpdateDisabled : false;
  }

  get isFavoriteDashboard(): boolean {
    return (
      this.source &&
      this.dashboard &&
      this._favoritesDashboardsService.isFavoriteDashboard(this.source.id, this.dashboard.name)
    );
  }

  get isSourceFavorite(): boolean {
    return this.source && this._favoritesSourcesService.isFavoriteSource(this.source.id);
  }

  get visibleRelatedDashboards(): Dashboard[] {
    let visibleRelatedDashboards: Dashboard[] = [];

    if (this.source && this.source.powerbi.length) {
      visibleRelatedDashboards = this.source.powerbi.filter((dashboard) => !dashboard.isHidden);

      return visibleRelatedDashboards;
    }
  }

  get visibleRelatedDashboardsWithSelectedTop(): Dashboard[] {
    let visibleRelatedDashboardsWithSelectedTop: Dashboard[] = [];
    const visibleRelatedDashboards = this.visibleRelatedDashboards;

    if (this.source && this.source.powerbi.length) {
      visibleRelatedDashboardsWithSelectedTop = visibleRelatedDashboards.filter(
        (dashboard) => dashboard.name !== this.dashboard.name,
      );
      visibleRelatedDashboardsWithSelectedTop.unshift(
        visibleRelatedDashboards.find((dashboard) => dashboard.name === this.dashboard.name),
      );
    }

    return visibleRelatedDashboardsWithSelectedTop;
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
  }

  // Lifecycle
  ngOnInit() {
    // Current user
    this._currentUserService.currentUser$.subscribe((user) => {
      this.currentUser = user;
    });

    // Fetching
    this._sidebarService.isVisible$.subscribe((isVisible) => {
      this.isSidebarVisible = isVisible;
    });

    // Load favorites
    this._favoritesDashboardsService.refreshFavoritesDashboards();
    this._favoritesSourcesService.refreshFavoritesSources();

    this.source = this._currentDashboardService.source;

    this._currentDashboardService.dashboard$.pipe(filter((dashboard) => !!dashboard)).subscribe(() => {
      this.dashboard = this._currentDashboardService.dashboard;
      this.hasProcessedDefaultBookmark = false;

      this.isSourceAuthorizedToExport =
        this._options.sourcesAllowedToExport.includes(this.source.id) ||
        this._options.sourcesAllowedToExport.includes('*');

      if (this.dashboard.type === 'powerbi') {
        this.isPowerBI = true;
        this._watchStateChanges();
        this._powerBiBookmarksService.listBookmarks(this.source.id, this.dashboard.name);
        this._dashboardsService.getLastUpdate(this.source.id, this.dashboard);

        // Apply bookmark when rendered
        this._powerBiEmbedService.events$
          .pipe(
            filter((eventType) => eventType === 'rendered'),
            first(),
          )
          .subscribe(async () => {
            if (this.dashboard.hasToIgnoreSpecifiedFieldsInFilters && this.dashboard.fieldsToIgnore?.length) {
              await this._powerBiEmbedService.saveDefaultPowerBiFiltersSelectedValues();
            }

            this._powerBiBookmarksService.applyDefaultBookmark(this.source.id, this.dashboard.name).subscribe((res) => {
              this.defaultBookmark = res.default;
              this.appliedBookmark = res.default;
              this.hasProcessedDefaultBookmark = true;
            });
          });
      }

      const comment = this._route.snapshot.queryParamMap.get('comment');

      if (this.dashboard.hasComments && comment) {
        this.toggleCommentsPart();
      }
    });

    this._powerBiBookmarksService.bookmarks$.pipe(takeUntil(this._destroyed$)).subscribe((bookmarks) => {
      this.everyBookmarks = bookmarks.sort((bookmark1, bookmark2) => bookmark1.name.localeCompare(bookmark2.name));
      this.ownBookmarks = this.everyBookmarks.filter((bookmark) => !bookmark.sharedBy);
      this.sharedBookmarks = this.everyBookmarks.filter((bookmark) => !!bookmark.sharedBy);
      this.hasSavedBookmarks = this.everyBookmarks.length > 0;
    });

    this._dashboardsService.lastUpdate$.pipe(takeUntil(this._destroyed$)).subscribe((date) => {
      if (date) {
        const hoursFromUTC = -date.getTimezoneOffset() / 60;
        const timeZone = 'UTC' + (hoursFromUTC >= 0 ? '+' : '') + hoursFromUTC;
        this.lastUpdate = `${date.toLocaleDateString()} ${date.toLocaleTimeString()} ${timeZone}`;
      }
    });

    this._powerBiBookmarksService.loading$.pipe(takeUntil(this._destroyed$)).subscribe((state) => {
      if (state.status === 'success' && state.action === 'save') {
        this.newFiltersName = '';
      }

      this.loadingBookmark = state.status === 'loading';
    });

    this._powerBiEmbedService.events$.pipe(filter((eventType) => eventType === 'dataSelected')).subscribe(() => {
      this.appliedBookmark = null;
      this.bookmarkHasChanged = true;
    });

    this.isMobile = this._deviceService.isMobile();
  }

  // Methods
  isCurrentDashboard(dashboard: Dashboard): boolean {
    return this.currentDashboardName === dashboard.name;
  }

  handleToolbarClick(dropdownId: string): void {
    if (this.isMobile) {
      if (this.openedDropdownId === dropdownId) {
        this.openedDropdownId = null;
      } else {
        this.openedDropdownId = dropdownId;
      }
    }

    this._logger.info(this.isMobile, this.openedDropdownId);
  }

  handleDropdownClick(nestedDropdownId: string): void {
    if (this.isMobile) {
      if (this.openedNestedDropdownId === nestedDropdownId) {
        this.openedNestedDropdownId = null;
      } else {
        this.openedNestedDropdownId = nestedDropdownId;
      }
    }

    this._logger.info(this.isMobile, this.openedNestedDropdownId);
  }

  openRecommendationModal(): void {
    this._recommendationsModalService.openRecommendationModal(this.source, this.dashboard);
  }

  openGetUrlModal(): void {
    this._dialogsService.open(
      DashboardUrlModalComponent,
      { dashboard: this.dashboard, source: this.source },
      ModalSizes.S,
    );
  }

  // - open dashboard link
  openDashboardLocally(visibleRelatedDashboard: Dashboard): void {
    const urlToOpen = visibleRelatedDashboard.buildUrl(this.source.id, visibleRelatedDashboard.name, this._navigator);

    if (visibleRelatedDashboard.isExternal) {
      this._dashboardsService.emitExternalLinkPageViewEvent(this.source, 'dashboard');
      window.open(urlToOpen, '_blank');
    } else {
      this._router.navigate([urlToOpen]);
      this.dashboard = visibleRelatedDashboard;
      this._currentDashboardService.changeCurrentDashboard(this.source.id, visibleRelatedDashboard.name);
    }
  }

  openDashboardExternally(visibleRelatedDashboard: Dashboard): void {
    const urlToOpen = visibleRelatedDashboard.buildUrl(
      this.source.id,
      visibleRelatedDashboard.name,
      this._navigator,
      this._router,
      true,
    );

    this._dashboardsService.emitExternalLinkPageViewEvent(this.source, 'dashboard');
    window.open(urlToOpen, '_blank');
  }

  openSupportLink(): void {
    const urlToOpen = this.dashboard.supportLink;
    window.open(urlToOpen, '_blank');
  }

  // - view
  fitToPage(): void {
    try {
      this._powerBiEmbedService.fitToPage();
    } catch (e) {
      this._logger.error('Error found while asking browser to set iframe fitting to page');
    }
  }

  fitToWidth(): void {
    try {
      this._powerBiEmbedService.fitToWidth();
    } catch (e) {
      this._logger.error('Error found while asking browser to set iframe fitting to width');
    }
  }

  actualSize(): void {
    try {
      this._powerBiEmbedService.actualSize();
    } catch (e) {
      this._logger.error('Error found while asking browser to set iframe actual size');
    }
  }

  fullScreen(): void {
    try {
      this._powerBiEmbedService.fullscreen();
    } catch (e) {
      this._logger.error('Error found while asking browser to set iframe fullscreen');
      this._logger.error(e);
    }
  }

  // - print
  async print(): Promise<void> {
    return this._powerBiEmbedService.print();
  }

  async resetBookmark(): Promise<void> {
    try {
      this._alertService.info('Applying default view in progress, please wait...');
      await this._powerBiBookmarksService.resetInitialState();
      this._alertService.success('Default view has been applied');
      this.bookmarkHasChanged = false;
      this.appliedBookmark = null;
    } catch (e) {
      this._logger.error('[DashboardComponent] Error resetting bookmark', e);
    }
  }

  async deleteBookmark(bookmark: IDashboardBookmark): Promise<void> {
    try {
      this._powerBiBookmarksService.remove(this.source.id, this.dashboard.name, bookmark);
      this.hasSavedBookmarks = this.everyBookmarks.length > 0;
      this.pushGTMSaveOrDeleteBookmarkEvent('dc_dashboard_bookmark_deleted');
    } catch (e) {
      this._logger.error('[DashboardComponent] Error clearing bookmark', e);
    }
  }

  async removeAll(): Promise<void> {
    try {
      this._powerBiBookmarksService.removeAll(this.source.id, this.dashboard.name);
      this.hasSavedBookmarks = false;
    } catch (e) {
      this._logger.error('[DashboardComponent] Error clearing bookmark', e);
    }
  }

  // - bookmarks
  setAsDefault(bookmark: IDashboardBookmark): void {
    const isAlreadySelected = this.defaultBookmark && this.defaultBookmark.pk === bookmark.pk;
    this._powerBiBookmarksService.setAsDefault(bookmark, this.dashboard.name).subscribe(() => {
      if (isAlreadySelected) {
        this._alertService.success('Default bookmark removed');
        this.defaultBookmark = null;
      } else {
        this._alertService.success(
          `Bookmark ${bookmark.name} set as default. Next time you load the report, it will be applied.`,
        );
        this.defaultBookmark = bookmark;
      }
    });
  }

  async saveBookmark(): Promise<void> {
    let doesBookmarkAlreadyExist = false;
    let bookmarkToUpdate: IDashboardBookmark;
    this.everyBookmarks.forEach((bookmark) => {
      if (bookmark.name.toLowerCase() === this.newFiltersName.toLowerCase()) {
        doesBookmarkAlreadyExist = true;
        bookmarkToUpdate = bookmark;
      }
    });

    if (!doesBookmarkAlreadyExist) {
      try {
        await this._powerBiBookmarksService.saveBookmark(this.source.id, this.dashboard.name, this.newFiltersName);
        this.pushGTMSaveOrDeleteBookmarkEvent('dc_dashboard_bookmark_saved');
      } catch (e) {
        this._alertService.error('Error saving bookmark');
        this._logger.error('[DashboardComponent] Error saving bookmark', e);
      }
    } else {
      await this.updateWithCurrentStateBookmark(bookmarkToUpdate);
    }
  }

  async applyBookmark(bookmark: IDashboardBookmark): Promise<void> {
    if (!this.loadingBookmark) {
      try {
        await this._powerBiBookmarksService.applyBookmark(bookmark);
        this.bookmarkHasChanged = false;
        this.appliedBookmark = bookmark;
      } catch (e) {
        this._alertService.error('Error restoring bookmark');
        this._logger.error('[DashboardComponent] Error restoring bookmark', e);
      }
    }
  }

  async updateWithCurrentStateBookmark(bookmark: IDashboardBookmark) {
    const currentState = await this._powerBiEmbedService.extractState();

    const confirmUpdateModal = this._dialogsService.confirm({
      message: `Are you sure you want to update ${bookmark.name} with current state ?`,
      confirmText: 'Yes',
      cancelText: 'Cancel',
    });
    confirmUpdateModal
      .afterClosed()
      .pipe(
        take(1),
        takeUntil(this._destroyed$),
        filter((hasConfirmed) => hasConfirmed),
      )
      .subscribe(() => {
        const updatedBookmark: IDashboardBookmark = { ...bookmark, data: currentState };
        this._powerBiBookmarksService
          .updateBookmark(this.source.id, this.dashboard.name, updatedBookmark)
          .subscribe(() => {
            this._alertService.success(`${bookmark.name} bookmark has been successfully updated.`);
          });
      });
  }

  copyBookmarkFilters(bookmark: IDashboardBookmark): void {
    this._clipboard.copy(bookmark.data);
    this._alertService.success((bookmark.name || bookmark.sharedName) + ' filters have been copied');
  }

  shareBookmark(bookmark: IDashboardBookmark): void {
    this._dialogsService.open(
      DashboardShareBookmarkModalComponent,
      { bookmark: bookmark, dashboard: this.dashboard, source: this.source },
      {
        ...ModalSizes.S,
        panelClass: 'dialog-overflow-visible',
      },
    );
  }

  openPasteFiltersModal(): void {
    this._dialogsService.open(
      DashboardPasteFiltersModalComponent,
      {},
      {
        ...ModalSizes.S,
        panelClass: 'dialog-overflow-visible',
      },
    );
  }

  renameBookmark(bookmark: IDashboardBookmark): void {
    this._dialogsService.open(
      DashboardRenameBookmarkModalComponent,
      { bookmark: bookmark, dashboard: this.dashboard, source: this.source },
      {
        ...ModalSizes.S,
        panelClass: 'dialog-overflow-visible',
      },
    );
  }

  // - export
  toggleExportCurrentPageOnly(): void {
    this.exportOnlyCurrentPage = !this.exportOnlyCurrentPage;
  }

  async export(type: ExportType, saveCurrentState = true): Promise<void> {
    if (this.isAlreadyExporting) {
      this._logger.info(`[DashboardComponent] Already exporting`);

      return;
    }

    this._logger.info(`[DashboardComponent] Requested to export dashboard to ${type} file`);
    this.exportProgress[type] = 0;

    let state: string | undefined;

    if (saveCurrentState) {
      this._logger.info('Asking for state');
      state = await this._powerBiEmbedService.extractState();
      this._logger.info('state = ', state);
    }

    const specificPagesToExport: string[] = [];

    if (this.exportOnlyCurrentPage) {
      specificPagesToExport.push(this._powerBiEmbedService.getCurrentPage().name);
    }

    const token = this.dashboard.usingUserOwnsData
      ? (await this._powerBiEmbedService.getUserAccessToken()).token
      : null;
    this.isAlreadyExporting = true;
    this._dashboardsService
      .requestExport({
        type: type,
        sourceId: this.source.id,
        userId: this.currentUser.id,
        dashboard: this.dashboard,
        userAccessToken: token,
        optionalState: state,
        optionalPagesNameToExport: specificPagesToExport,
        isApp: this.dashboard.isApp,
      })
      .subscribe(
        (exportId) => {
          this._logger.info('[DashboardComponent] Request successfully sent', { exportId });
          this._alertService.info(
            'We are exporting your report. It may take up to two minutes.\nPlease do not change the page until the download is complete.',
          );
          this.exportProgress[type] = 0;
          this._watchExportProgress(type, exportId, token);
        },
        (err: unknown) => {
          this._logger.error(`[DashboardComponent] Error exporting dashboard to ${type}`, err);
          this.exportProgress[type] = null;
          this.isAlreadyExporting = false;
        },
      );
  }

  pushGTMSaveOrDeleteBookmarkEvent(gtmEvent: string) {
    const searchMode = this.getDCSearchMode();
    this._gtmService.pushEvent({
      event: gtmEvent,
      dc_dashboard_name: this.currentDashboardName,
      dc_mode: searchMode,
    });
  }

  pushGTMExportDashboardEvent(exportType: string, exportOnlyCurrentPage: boolean) {
    const searchMode = this.getDCSearchMode();
    this._gtmService.pushEvent({
      event: 'dc_dashboard_exported',
      dc_dashboard_name: this.currentDashboardName,
      dc_mode: searchMode,
      dc_export_pages: exportOnlyCurrentPage ? 'current' : 'all',
      dc_export_format: exportType,
    });
  }

  // - favorite state
  toggleFavoriteDashboard(): void {
    if (this.isSourceFavorite) {
      if (!this.isFavoriteDashboard) {
        this._favoritesDashboardsService.addFavoriteDashboard(this.source.id, this.dashboard.name);
      } else {
        this._favoritesDashboardsService.removeFavoriteDashboard(this.source.id, this.dashboard.name);
      }
    } else {
      this._favoritesSourcesService.addFavoriteSource(this.source.id);

      if (!this.isFavoriteDashboard) {
        this._favoritesDashboardsService.addFavoriteDashboard(this.source.id, this.dashboard.name);
      }
    }
  }

  toggleCommentsPart(): void {
    this.hasToDisplayCommentsPart = !this.hasToDisplayCommentsPart;
    this.toggleDisplayCommentsPartEvent.emit(this.hasToDisplayCommentsPart);
  }

  pushGTMSaveBookmarkEvent() {
    const searchMode = this.getDCSearchMode();
    this._gtmService.pushEvent({
      event: 'dc_dashboard_bookmark_saved',
      dc_dashboard_name: this.currentDashboardName,
      dc_mode: searchMode,
    });
  }

  getDCSearchMode(): string {
    const storedSearchMode = localStorage.getItem('dc-search-mode');

    return storedSearchMode ? storedSearchMode : 'advanced';
  }

  // - filters
  private _watchStateChanges(): void {
    const handler = this._powerBiEmbedService.addEventListener('dataSelected', async () => {
      this.bookmarkHasChanged = true;
      this.appliedBookmark = null;
      this._powerBiEmbedService.removeEventListener('dataSelected', handler);
    });
  }

  private _watchExportProgress(type: ExportType, exportId: string, token: string): void {
    this._dashboardsService.watchExportProgress(type, this.source.id, this.dashboard, exportId, token).subscribe(
      (percent) => {
        this.exportProgress[type] = percent;
        this._logger.info(`[DashboardComponent] Exporting dashboard to ${type} ${percent}%`);
      },
      (err: unknown) => {
        this.exportProgress[type] = null;
        this._logger.error(`[DashboardComponent] Error exporting dashboard to ${type}`, err);
        this.isAlreadyExporting = false;
        this._alertService.error(`Error exporting dashboard to ${type}`);
      },
      () => {
        this.exportProgress[type] = null;
        this.isAlreadyExporting = false;
        this._logger.info(`[DashboardComponent] dashboard successfully exported to ${type}`);
        this.isGeneratingSignedUrl[type] = true;
        this._requestSignedUrl(type, exportId, token);
        this.pushGTMExportDashboardEvent(type, this.exportOnlyCurrentPage);
      },
    );
  }

  private async _requestSignedUrl(type: ExportType, exportId: string, userToken?: string): Promise<void> {
    const powerBiPages = await this._powerBiEmbedService.getPages();
    const visiblePowerBiPages = powerBiPages.filter((page) => page.visibility === 0);

    const isMultiPage = visiblePowerBiPages.length > 1 && !this.exportOnlyCurrentPage;

    this._dashboardsService
      .requestSignedUrl(type, isMultiPage, this.source.id, this.dashboard, exportId, userToken)
      .subscribe(
        (response) => {
          this._logger.info('[DashboardComponent] Signing dashboard URL in progress', { taskId: response.taskId });
          this._downloadReport(type, response.taskId);
        },
        (err: unknown) => {
          this.isGeneratingSignedUrl[type] = false;
          this._logger.error(`[DashboardComponent] Error requesting signed URL for ${type}`, err);
          this._alertService.error(`Error exporting dashboard to ${type}`);
        },
      );
  }

  private _downloadReport(type: ExportType, taskId: string): void {
    this._dashboardsService.getReportSignedUrl(type, this.source.id, taskId).subscribe(
      (signedUrl) => {
        this._logger.info(`[DashboardComponent] Signed URL retrieved for ${type} export`, signedUrl);
        this._alertService.success(`Dashboard exported to ${type.toUpperCase()}`);
        this.isGeneratingSignedUrl[type] = false;

        const link = document.createElement('a');
        link.download = this.dashboard.name;
        link.href = signedUrl;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      },
      (err: unknown) => {
        this._logger.error(`[DashboardComponent] Error getting signed URL for ${type}`, err);
        this._alertService.error(`Error exporting dashboard to ${type}`);
        this.isGeneratingSignedUrl[type] = false;
      },
    );
  }
}
