import { DatePipe } from '@angular/common';
import type { OnDestroy, OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import { BehaviorSubject, forkJoin, of, Subject } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import type { IExternalReferential } from '@dataportal/front-api';
import type {
  CheckRegex,
  IGuardianChecksDatasetMap,
  ISnowflakeTableInfo,
  ISupportedGuardianChecksResource,
} from '@dataportal/guardian-utils';
import { IGuardianStatus } from '@dataportal/guardian-utils';
import { saveAs } from 'file-saver';

import { GuardianService } from '../../services/guardian.service';
import { GuardianImportExportCheckService } from '../../services/guardian-import-export-check.service';

/**
 * Component that regroup the information of the column and the global configuration of a Guardian Check
 */
@Component({
  selector: 'dpg-guardian-check-infos-content',
  templateUrl: './guardian-check-infos-content.component.html',
  styleUrls: ['./guardian-check-infos-content.component.scss'],
})
export class GuardianCheckInfosContentComponent<R extends ISupportedGuardianChecksResource>
  implements OnDestroy, OnInit
{
  static EXPORTED_JSON_FILE_NAME_DATE_FORMAT = 'yyyy-MM-dd_HH-mm-ss';
  static EXPORTED_JSON_FILE_NAME_PREFIX = 'Exported_constraints__';
  static EXPORTED_JSON_FILE_NAME_EXTENSION = '.txt';
  static TXT_MIME_TYPE = 'text/plain';

  @Input() hasExportButton = false;
  @Input() exportButtonPositionTop = '5px';
  @Input() dataset: IGuardianChecksDatasetMap[R];
  @Input() guardianStatus: IGuardianStatus;

  readonly tabsList = ['Datas & rules', 'Spec configuration'];
  activeTab: BehaviorSubject<string> = new BehaviorSubject<string>(this.tabsList[0]);
  isReadyToExport = false;
  allCheckRegex: CheckRegex[] = null;
  externalReferentials: IExternalReferential[] = null;

  private readonly _destroyed$ = new Subject<void>();

  constructor(
    private readonly _guardianService: GuardianService,
    private readonly _guardianImportExportCheckService: GuardianImportExportCheckService,
    private readonly _datePipe: DatePipe,
  ) {}

  ngOnInit(): void {
    forkJoin([this._guardianService.listAllCheckRegex(), this._guardianService.listExternalReferentials()])
      .pipe(
        takeUntil(this._destroyed$),
        catchError(() => {
          return of([[], []] as [CheckRegex[], IExternalReferential[]]);
        }),
      )
      .subscribe(([allCheckRegex, externalReferentials]) => {
        this.isReadyToExport = true;
        this.allCheckRegex = [...allCheckRegex];
        this.externalReferentials = [...externalReferentials];
      });
  }

  ngOnDestroy() {
    this._destroyed$.next();
  }

  private _getExportedJsonFileName(): string {
    if (this.guardianStatus) {
      const formattedDate = this._datePipe.transform(
        new Date(),
        GuardianCheckInfosContentComponent.EXPORTED_JSON_FILE_NAME_DATE_FORMAT,
      );
      let checkTypeSpecificInfo = '';

      if (this.guardianStatus.type === 'datalakePath') {
        const datalakeStatus = this.guardianStatus as IGuardianStatus<'datalakePath'>;
        const bucketName = datalakeStatus.checkInfos?.bucket_name;
        const folderName = datalakeStatus.checkInfos?.prefix?.split('/').pop();
        checkTypeSpecificInfo = `${bucketName}...${folderName}`;
      } else {
        // guardianStatus.type equals 'snowflake'
        const snowflakeDataset = this.dataset as ISnowflakeTableInfo;
        const snowflakeAccount = snowflakeDataset.accountName;
        const schemaName = snowflakeDataset.schemaName;
        const tableName = snowflakeDataset.tableName;
        checkTypeSpecificInfo = `${snowflakeAccount}-${schemaName}-${tableName}`;
      }

      const checkId = `check_${this.guardianStatus.checkId}`;

      return (
        `${GuardianCheckInfosContentComponent.EXPORTED_JSON_FILE_NAME_PREFIX}${checkTypeSpecificInfo}__${checkId}` +
        `__${this.guardianStatus.checkInfos?.name}__${formattedDate}${GuardianCheckInfosContentComponent.EXPORTED_JSON_FILE_NAME_EXTENSION}`
      );
    }

    return null;
  }

  exportConstraintsToJsonFile(): void {
    if (this.isReadyToExport && this.guardianStatus?.isChecked) {
      const datalakeStatus = this.guardianStatus as IGuardianStatus<'datalakePath'>;
      const checkInfosStr = JSON.stringify(
        this._guardianImportExportCheckService.exportCheckFromGuardianStatus(datalakeStatus),
        null,
        4,
      );
      const blob = new Blob([checkInfosStr], { type: 'text/plain' });
      saveAs(blob, this._getExportedJsonFileName());
    }
  }
}
