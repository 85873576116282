import type { IBidirectionalSourceRelationship } from '@dataportal/types';
import { LinkDirection, SourceRelationshipStatus } from '@dataportal/types';

import { SourceRelationship } from './source-relationship';

export class BidirectionalSourceRelationship extends SourceRelationship {
  type = LinkDirection.BIDIRECTIONAL;
  reverseStatus: SourceRelationshipStatus = null;

  fromJson(json: IBidirectionalSourceRelationship): BidirectionalSourceRelationship {
    super.fromJson(json);
    this.reverseStatus = json.reverseStatus;

    return this;
  }

  get isActive(): boolean {
    return this._status === SourceRelationshipStatus.ACTIVE && this.reverseStatus === SourceRelationshipStatus.ACTIVE;
  }

  get isPendingOtherOwnerValidation(): boolean {
    return this._status === SourceRelationshipStatus.ACTIVE && this.reverseStatus === SourceRelationshipStatus.PENDING;
  }

  get isPendingMyValidation(): boolean {
    return this._status === SourceRelationshipStatus.PENDING && this.reverseStatus === SourceRelationshipStatus.ACTIVE; // PA
  }

  get isRejectedByOtherOwner(): boolean {
    return this._status === SourceRelationshipStatus.ACTIVE && this.reverseStatus === SourceRelationshipStatus.PENDING; // AR
  }

  get isRejectedByMe(): boolean {
    return this._status === SourceRelationshipStatus.REJECTED && this.reverseStatus === SourceRelationshipStatus.ACTIVE; // RA
  }

  get isPendingOtherOwnerValidateDeletion(): boolean {
    return (
      this._status === SourceRelationshipStatus.ACTIVE &&
      this.reverseStatus === SourceRelationshipStatus.DELETION_PENDING
    ); // AD
  }

  get isPendingMyDeletionValidation(): boolean {
    return (
      this._status === SourceRelationshipStatus.DELETION_PENDING &&
      this.reverseStatus === SourceRelationshipStatus.ACTIVE
    );
  }

  get status(): SourceRelationshipStatus {
    if (this.isActive) {
      return SourceRelationshipStatus.ACTIVE;
    } else if (this.isPendingOtherOwnerValidation || this.isPendingMyValidation) {
      return SourceRelationshipStatus.PENDING;
    } else if (this.isPendingOtherOwnerValidateDeletion || this.isPendingMyDeletionValidation) {
      return SourceRelationshipStatus.DELETION_PENDING;
    } else if (this.isRejectedByOtherOwner || this.isRejectedByMe) {
      return SourceRelationshipStatus.REJECTED;
    }
  }

  get canBeDeleted(): boolean {
    return (
      this.isActive ||
      this.isPendingMyDeletionValidation ||
      this.isRejectedByOtherOwner ||
      this.isPendingOtherOwnerValidation
    );
  }
}
