import type { OnInit } from '@angular/core';
import { Component, ContentChild, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { Observable } from 'rxjs';

import type { SupportedIcon } from '../../atoms/icons';

@Component({
  selector: 'adl-radio-selector',
  templateUrl: './radio-selector.component.html',
  styleUrls: ['./radio-selector.component.scss'],
})
export class RadioSelectorComponent<T = unknown> implements OnInit {
  @ContentChild(TemplateRef) template: TemplateRef<any>;

  /**
   * BE CAREFUL: selectorName MUST be set inside and be unique the parent component
   * if dealing with at least 2 instances of radio-selector
   */
  @Input() selectorName: string;
  @Input() radioSelectorLabelSize = 16;
  @Input() radioSelectorLabelColor = null;
  @Input() radioSelectorLabelWeight = 'normal';
  @Input() choices: Array<{ label: string; value: T; infoBubbleContent?: string; isLabelNotAllowed?: boolean }>;
  @Input() activeChoice: T;
  @Input() choiceComponentClassList: string[] = [];
  @Input() choiceFlexClassList: string[] = [];
  @Input() containerClassList: string;
  @Input() iconButtons: SupportedIcon[];
  @Input() valueEqualityCheck: (value1: T, value2: T) => boolean = this.defaultEqualityCheck;
  @Input() nextValue: Observable<T>;
  @Input() radioStyle: 'old' | 'new' = 'old';
  @Input() radioPosition: 'left' | 'right' = 'left';
  @Input() hasSeparator = false;

  @Output() changed = new EventEmitter<T>();
  @Output() iconOnOneOfButtonClicked = new EventEmitter<{ value: T; btnIndex: number }>();
  @Output() labelClicked = new EventEmitter<T>();

  // TODO: enhance this part where you can enforce
  ngOnInit(): void {
    this.nextValue?.subscribe((nextValue) => {
      this.activeChoice = nextValue;
    });
  }

  defaultEqualityCheck(value1: T, value2: T): boolean {
    return value1 === value2;
  }

  selectRadio(value: T): void {
    this.activeChoice = value;
    this.changed.emit(value);
  }

  onIconButtonClicked($event: MouseEvent, value: T, btnIndex: number): void {
    $event.preventDefault();
    this.iconOnOneOfButtonClicked.emit({ value: value, btnIndex: btnIndex });
  }

  onLabelClicked(value: T): void {
    this.labelClicked.emit(value);
  }
}
