/* eslint-disable @typescript-eslint/naming-convention */
import jcdLocale from './locale.jcd';
import prLocale from './locale.pr';
import accorLocale from './locale.accor';

export default {
  JCD: jcdLocale,
  PR: prLocale,
  ACCOR: accorLocale,
};
