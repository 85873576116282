import type { ApiService } from '@dataportal/front-api';
import { ApiPaginatedService } from '@dataportal/front-api';
import type { Logger } from '@dataportal/front-shared';
import { EntityBuilder } from '@decahedron/entity';

import { UserPermissionV2 } from '../entities/user-permission-v2';

interface IFromBackEnd {
  user_id: string;
  roles: string[];
  from_group?: {
    group_name: string;
    group_id: string;
  };
}

export abstract class EntityUserRolesService extends ApiPaginatedService<UserPermissionV2> {
  // Constructor
  constructor(apiService: ApiService, logger: Logger) {
    super(apiService, logger);
  }

  // Methods
  protected buildOne(json: IFromBackEnd): UserPermissionV2 {
    return EntityBuilder.buildOne(UserPermissionV2, json);
  }

  protected buildMany(json: IFromBackEnd[]): UserPermissionV2[] {
    return EntityBuilder.buildMany(UserPermissionV2, json);
  }
}
