import type { OnInit } from '@angular/core';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'adl-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent implements OnInit {
  @Input() borderTopColor: string = null;
  @Input() active = false;
  @Input() centered: boolean;
  @Input() greyCard: boolean;
  @Input() autoWidth: boolean;
  @Input() justifyType: 'start' | 'between' | 'center' = 'start';
  @Input() paddingType: 'medium' | 'small' | 'none' = 'medium';

  @Output() clicked = new EventEmitter<MouseEvent>();

  ngOnInit() {
    this.justifyType = this.centered ? 'center' : this.justifyType;
  }
}
