import type { OnChanges, OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';

export type TitleType =
  | 'primary'
  | 'secondary'
  | 'modal'
  | 'card'
  | 'subtitle'
  | 'tooltip'
  | 'label'
  | 'admin'
  | 'admin-secondary';

@Component({
  selector: 'adl-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.scss'],
})
export class TitleComponent implements OnInit, OnChanges {
  @Input() type: TitleType = 'primary';
  @Input() color: string;
  @Input() alignedElements = false;
  @Input() hasToForceBold = false;

  classList: string[] = [];
  heading: number;
  styles = {};

  ngOnChanges(): void {
    this._refresh();
  }

  ngOnInit(): void {
    this._refresh();
  }

  private _refresh(): void {
    this.heading = this._getHeadingSize();

    switch (this.type) {
      case 'tooltip':
        this.classList = ['tooltip-title'];
        break;
      case 'admin':
        this.classList = ['admin-title'];
        break;
      case 'admin-secondary':
        this.classList = ['admin-title-secondary'];
        break;
      default:
        this.classList = [];
    }

    if (this.color && this.color.match(/^#[0-9A-Fa-f]{6}$/)) {
      this.styles = {
        color: this.color,
      };
    }

    if (this.alignedElements) {
      this.classList.push('row-flex');
      this.classList.push('a-center');
    }

    if (this.hasToForceBold) {
      this.classList.push('bold');
    }
  }

  private _getHeadingSize(): number {
    switch (this.type) {
      case 'secondary':
      case 'admin':
      case 'admin-secondary':
        return 2;
      case 'modal':
      case 'card':
      case 'tooltip':
        return 3;
      case 'subtitle':
        return 4;
      case 'label':
        return 5;
      default:
        return 1;
    }
  }
}
