<div class="content-wrapper--overlay" (click)="closeSidebar()"></div>
<dpg-alert-banner></dpg-alert-banner>
<dpg-navbar
  [external]="external"
  [items]="[]"
  [favorites]="[]"
  (messageEvent)="openSideBar()"
  [canSeeAdmin]="false"
>
</dpg-navbar>
<dpg-impersonate-shortcut></dpg-impersonate-shortcut>
<dpg-toggle-admin-shortcut></dpg-toggle-admin-shortcut>
