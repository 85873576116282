<div
  class="filter-container flex justify-between items-center"
  *ngFor="let filter of filters | slice: 0:displayCount; let index = index"
>
  <div class="overflow-hidden w-full">
    <div class="flex items-center">
      <ng-container *ngIf="filterType === 'checkbox'; else radioTemplate">
        <adl-checkbox
          [checked]="filter.checked"
          (changed)="updateFilter(filter)"
          checkboxColor="blue"
          checkboxSize="small"
        >
        </adl-checkbox>
        <div
          (click)="updateFilter(filter)"
          class="cursor-pointer filter-text text-ellipsis whitespace-nowrap overflow-hidden ml-[10px]"
          [class.filter-text-checked]=filter.checked
        >
          {{ (filter.description ? filter.description + ' - ' : '') | titlecase }} {{ filter.label }}
          {{ filterCount(filter.count) }}
        </div>
      </ng-container>
      <ng-template #radioTemplate>
        <adl-radio
          class="custom-radio-button"
          [name]="filterGroupName"
          [value]="filter.value"
          (changed)="handleRadioBtnChange($event)"
          [checked]="filter.checked"
          radioStyle="new"
        >
          <span
            class="cursor-pointer filter-text text-ellipsis whitespace-nowrap overflow-hidden"
            [class.filter-text-checked]=filter.checked
          >
            {{ (filter.description ? filter.description + ' - ' : '') | titlecase }} {{ filter.label }}
            {{ filterCount(filter.count) }}
          </span>
        </adl-radio>
      </ng-template>

      <adl-icon-button
        *ngIf="filter.subFilterItems?.length"
        (click)="filter.open = !filter.open"
        [size]="12"
        icon="{{ filter.open ? 'chevron-up' : 'chevron-down' }}"
        class="pl-4"
      >
      </adl-icon-button>
    </div>

    <div class="pl-12" *ngIf="filter.subFilterItems?.length && filter.open">
      <adl-filter
        class="flex flex-col"
        [filters]="filter.subFilterItems"
        (filtersChange)="(filter.subFilterItems = $event) && filtersChange.emit(filters)"
      ></adl-filter>
    </div>
  </div>

  <adl-icon
    *ngIf="advancedFilter"
    icon="cross"
    class="cursor-pointer"
    (click)="removeAdvancedFilter(filter)"
  ></adl-icon>
</div>
