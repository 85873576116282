import type { IDatalakePath, IDatalakePathFavorite } from '@dataportal/types';
import { Entity } from '@decahedron/entity';

// Entity
export class FavoriteDatalakePath extends Entity {
  // Attributes
  userId: string = null;
  sourceId: string = null;
  datalakePath: IDatalakePath = null;
  addDate: string = null;
  order: number = null;

  // Methods
  fromJson(data: IDatalakePathFavorite): FavoriteDatalakePath {
    this.userId = data.pk;
    this.sourceId = data.source_id;
    this.datalakePath = data.related_datalake_path;
    this.addDate = data.created_at;
    this.order = data.order;

    return this;
  }

  toJson() {
    return {
      user_id: this.userId,
      source_id: this.sourceId,
      related_datalake_path: this.datalakePath,
      order: this.order,
    };
  }
}
