import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { BehaviorSubject, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { ApiService } from '@dataportal/front-api';
import { AlertService, Logger } from '@dataportal/front-shared';
import type { EnabledRelationships, SourceRelationship as ISourceRelationship } from '@dataportal/types';
import { LinkDirection, SourceRelationshipStatus } from '@dataportal/types';
import { EntityBuilder } from '@decahedron/entity';

import { BidirectionalSourceRelationship } from '../entities/bidirectional-source-relationship';
import type { SourceRelationship } from '../entities/source-relationship';
import { UnidirectionalSourceRelationship } from '../entities/unidirectional-source-relationship';

@Injectable()
export class RelationshipsService {
  private readonly _relationships$ = new BehaviorSubject<Array<SourceRelationship>>([]);
  relationships$ = this._relationships$.asObservable();

  constructor(
    private readonly _logger: Logger,
    private readonly _api: ApiService,
    private readonly _alertService: AlertService,
  ) {}

  private static _buildOne(json: ISourceRelationship): SourceRelationship {
    if (json.type === LinkDirection.BIDIRECTIONAL) {
      return EntityBuilder.buildOne(BidirectionalSourceRelationship, json);
    }

    return EntityBuilder.buildOne(UnidirectionalSourceRelationship, json);
  }

  private static _buildMany(json: ISourceRelationship[]): SourceRelationship[] {
    return json.map((data) => RelationshipsService._buildOne(data));
  }

  listRelationships(sourceId: string): Observable<Array<SourceRelationship>> {
    this._logger.debug('[RelationshipsService] GET v4/relationships/sources/{sourceId}', {
      sourceId,
    });

    return this._api.get(`/v4/relationships/sources/${sourceId}`).pipe(
      catchError((err: unknown) => {
        this._logger.error('[RelationshipsService] GET v4/relationships/sources/{sourceId}', err);

        return throwError(err);
      }),
      map((res: ISourceRelationship[]) => RelationshipsService._buildMany(res)),
      tap((relationships) => this._relationships$.next(relationships)),
    );
  }

  listEnabledRelationships(sourceId: string): Observable<EnabledRelationships> {
    return this._api.get<EnabledRelationships>(`/v4/relationships/sources/${sourceId}?status=active&sorted=true`);
  }

  createRelationship(sourceId: string, relatedSourceId: string, linkId: string): Observable<Array<SourceRelationship>> {
    const body = {
      relatedSource: relatedSourceId,
      linkId,
    };
    this._logger.debug('[RelationshipsService] POST v4/relationships/sources/{sourceId}', {
      sourceId,
      body,
    });

    return this._api.post(`/v4/relationships/sources/${sourceId}`, body).pipe(
      catchError((err: unknown) => {
        this._logger.error('[RelationshipsService] POST v4/relationships/sources/{sourceId}', err);

        return throwError(err);
      }),
      map((res: ISourceRelationship | ISourceRelationship[]) => {
        if (Array.isArray(res)) {
          return RelationshipsService._buildMany(res);
        } else {
          return [RelationshipsService._buildOne(res)];
        }
      }),
      tap(() => this._alertService.success('Relationship successfully created')),
    );
  }

  updateRelationship(
    sourceId: string,
    relatedSourceId: string,
    linkId: string,
    status: SourceRelationshipStatus,
  ): Observable<Array<SourceRelationship>> {
    const body = {
      relatedSource: relatedSourceId,
      linkId,
      status,
    };
    this._logger.debug('[RelationshipsService] PUT v4/relationships/sources/{sourceId}', {
      sourceId,
      body,
    });

    return this._api.put(`/v4/relationships/sources/${sourceId}`, body).pipe(
      catchError((err: unknown) => {
        this._logger.error('[RelationshipsService] PUT v4/relationships/sources/{sourceId}', err);

        return throwError(err);
      }),
      map((res: ISourceRelationship[]) => RelationshipsService._buildMany(res)),
      tap(() => this._alertService.success('Relationship successfully updated')),
    );
  }

  requestDeletion(sourceId: string, relatedSourceId: string, linkId: string): Observable<Array<SourceRelationship>> {
    return this.updateRelationship(sourceId, relatedSourceId, linkId, SourceRelationshipStatus.DELETION_PENDING);
  }
}
