import type { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { DeviceService, Logger } from '@dataportal/front-shared';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import type { Page } from 'powerbi-client';

import { CurrentDashboardService } from '../../services/current-dashboard.service';
import { PowerBiEmbedService } from '../../services/power-bi-embed.service';

import type { Dashboard } from '../../entities/dashboard.model';

@UntilDestroy()
@Component({
  selector: 'dpg-dashboard-mobile-navbar',
  templateUrl: './dashboard-mobile-navbar.component.html',
  styleUrls: ['./dashboard-mobile-navbar.component.scss'],
})
export class DashboardMobileNavbarComponent implements OnInit {
  pages: Partial<Page>[];
  currentPageName = '-1';
  currentPageLabel = 'Select a page';

  private _isMobile = false;
  private _isOwner = false;
  private _dashboard: Dashboard;

  constructor(
    private readonly _deviceService: DeviceService,
    private readonly _currentDashboardService: CurrentDashboardService,
    private readonly _powerBiEmbedService: PowerBiEmbedService,
    private readonly _logger: Logger,
  ) {}

  ngOnInit(): void {
    this._isMobile = this._deviceService.isMobile();
    this._currentDashboardService.dashboard$.pipe(untilDestroyed(this)).subscribe((dashboard) => {
      this._dashboard = dashboard;
    });
    this._currentDashboardService.permissions$.pipe(untilDestroyed(this)).subscribe((permissions) => {
      this._isOwner = permissions.isOwner;
    });
    this._currentDashboardService.pages$.pipe(untilDestroyed(this)).subscribe((pages) => {
      this.pages = pages;
    });
    this._currentDashboardService.currentPage$.pipe(untilDestroyed(this)).subscribe((currentPage) => {
      this.currentPageName = currentPage?.name;
      this.currentPageLabel = currentPage?.displayName;
    });
  }

  get shouldDisplay(): boolean {
    const isOnMaintenance = this._dashboard?.maintenanceMessage && !this._isOwner;
    const isPowerBI = this._dashboard?.type === 'powerbi';

    return this._isMobile && isPowerBI && !isOnMaintenance;
  }

  handleCurrentPageChange(pageId: string): void {
    this._powerBiEmbedService.setPage(pageId).then(() => this._logger.info('Page set', pageId));

    if (this.pages) {
      for (const page of this.pages) {
        if (page.name === pageId) {
          this.currentPageLabel = page.displayName;
          break;
        }
      }
    } else {
      this.currentPageLabel = 'Select a page';
    }
  }
}
