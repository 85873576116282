import type { OnInit } from '@angular/core';
import { Component, EventEmitter, Input, Output } from '@angular/core';

import type { IGenericFilterItem } from '../../../models/filters';

@Component({
  selector: 'adl-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class FilterComponent implements OnInit {
  @Input() filters: IGenericFilterItem[];
  @Input() filterType;
  @Input() displayCount: number;
  @Input() advancedFilter = false;
  @Input() filterGroupName = '';
  @Output() filtersChange = new EventEmitter<IGenericFilterItem[]>();

  ngOnInit(): void {
    this.filterType = this.filterType || 'checkbox';
  }

  updateFilter(filter: IGenericFilterItem): void {
    filter.checked = !filter.checked;
    this.filtersChange.emit(this.filters);
  }

  handleRadioBtnChange(valueChecked: string) {
    for (const filterItem of this.filters) {
      filterItem.checked = filterItem.value === valueChecked && filterItem.checked === false;
    }

    this.filtersChange.emit(this.filters);
  }

  filterCount(count: number): string {
    return count !== undefined ? `(${count})` : '';
  }

  removeAdvancedFilter(filter: IGenericFilterItem) {
    filter.checked = false;
    this.filtersChange.emit(this.filters);
    filter.removeSelf();
  }
}
