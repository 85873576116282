<ng-select
  class='w-full'
  [class]="isDisabled ? 'cursor-not-allowed' : ''"
  [ngClass]="styling"
  [disabled]="isDisabled"
  [bindLabel]="bindLabel"
  [bindValue]="bindValue"
  [placeholder]="placeholder"
  [items]="items"
  [searchable]="searchable"
  [clearable]="clearable"
  [multiple]="multiSelect"
  [closeOnSelect]="closeOnSelect"
  [loading]="loading"
  [(ngModel)]="value"
  (clear)="cleared.emit()"
  (scrollToEnd)="scrollToEnd.emit()"
  (change)="updateChanges()"
>
</ng-select>
