<adl-banner
  *ngIf="alertsService.show$ | async"
  class="absolute z-[1000] top-[8px] mx-[10%]"
  [message]="(alertsService.alert$ | async)?.text"
  [readMore]="(alertsService.alert$ | async)?.readMore?.length > 0"
  [labelColor]="labelColor"
  [iconBanner]="'assets/modules/data-catalog/imgs/alert-' + labelColor + '.svg'"
  [isAlertComponent]="true"
  [hasIconBanner]="true"
  (closed)="alertsService.close()"
  (moreClick)="openReadMore()"
></adl-banner>
