import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import type { ApiOptions, IApiResult, IGraphAPIMetadata, IGraphAPIRequesterState } from '@dataportal/front-api';
import { ApiService, deserialize, GenericApiPaginatedService, serialize } from '@dataportal/front-api';
import { Logger } from '@dataportal/front-shared';
import { EntityBuilder } from '@decahedron/entity';

import type { ILimitedUser } from '../entities/limited-user';
import { LimitedUser } from '../entities/limited-user';

@Injectable()
export class LimitedUsersService extends GenericApiPaginatedService<
  LimitedUser,
  IGraphAPIMetadata,
  IGraphAPIRequesterState
> {
  protected url = '/v4/users-v2';

  constructor(protected apiService: ApiService, protected logger: Logger) {
    super(apiService, logger);
    this.results$.subscribe(({ requesterId }) => {
      const state = this.states.get(requesterId);

      if (!state) {
        return;
      }

      this.loading.next({ requesterId, data: false });
    });
  }

  startListing(listOptions: IGraphAPIMetadata, apiOptions?: ApiOptions): string {
    // Initiate state
    const requesterId = super.initListing(listOptions, apiOptions);

    // Start requests
    setTimeout(() => this.fetchNextPage(requesterId), 0);

    return requesterId;
  }

  hasNext(requesterId: string): boolean {
    const { currentPage, metadata } = this.states.get(requesterId);

    return currentPage < 0 || !!metadata.nextPage;
  }

  protected nextResults(requesterId: string): Observable<void> {
    const { apiOptions, metadata } = this.states.get(requesterId);

    // Make request
    const options = Object.assign({ params: {} }, apiOptions);

    if (options.params instanceof HttpParams) {
      options.params.set('params', serialize(metadata));
    } else {
      options.params.params = serialize(metadata);
    }

    return this.apiService.get<IApiResult>(this.url, options).pipe(
      map((res) => {
        const data = this.buildMany(res.data);

        // Update state
        const state = this.states.get(requesterId);
        state.currentSize += data.length;
        state.metadata = deserialize(res.metadata);

        // Emit results
        this.logger.debug('[API Paginated Service] Next results received', data.length);
        this.results.next({ requesterId, data });
      }),
    );
  }

  protected buildOne(json: ILimitedUser): LimitedUser {
    return EntityBuilder.buildOne(LimitedUser, json);
  }

  protected buildMany(json: ILimitedUser[]): LimitedUser[] {
    return EntityBuilder.buildMany(LimitedUser, json);
  }
}
