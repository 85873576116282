import { Inject, Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { E2EService } from '@dataportal/front-shared';
import type { IAuthErrorReport } from '@dataportal/msal';

import { BaseAuthService } from './base-auth.service';

import { AUTH_OPTIONS, IAuthOptions } from '../auth-options';
import type { IProfileFromAuth } from './base-auth.service';

@Injectable()
export class E2eMockedAuthService extends BaseAuthService {
  private readonly _userProfile$ = new BehaviorSubject<IProfileFromAuth>(undefined);
  readonly userProfile$: Observable<IProfileFromAuth> = this._userProfile$.asObservable();

  constructor(
    private readonly _e2eService: E2EService,
    @Inject(AUTH_OPTIONS) private readonly _authOptions: IAuthOptions,
  ) {
    super();
  }

  acquireTokenDashboard(): Promise<string | void> {
    throw new Error('Not implemented for E2E');
  }

  acquireTokenDatabricks(): Promise<string | void> {
    throw new Error('Not implemented for E2E');
  }

  acquireTokenDevops(): Promise<string | void> {
    throw new Error('Not implemented for E2E');
  }
  async handleError(report: IAuthErrorReport) {
    window.location.replace(`${this._authOptions.baseUrl}/login`);
  }

  isAuthenticated(): boolean {
    return true;
  }

  login(): void {
    return;
  }

  async logout(): Promise<void> {
    return;
  }

  async refreshCurrentUser(): Promise<void> {
    this._userProfile$.next({ username: this._e2eService.getTestUserId() });

    return;
  }

  async refreshTokens(): Promise<string> {
    return;
  }
}
