<div class="picture-with-toggles row-flex j-between w100 m0 mt10 p-8 br5">
  <div class="row-flex j-start a-center w100">
    <div class="row-flex a-center" [ngClass]="{ w100: areTogglesDownSide, w65: !areTogglesDownSide }">
      <adl-loaded-item
        class="picture-loading column-flex j-center"
        [ngClass]="{ w10: areTogglesDownSide, w20: !areTogglesDownSide }"
        [iconSize]="100"
        unit="%"
        [loading]="isLoadingPicture"
      >
        <img class="picture df j-center a-center" alt="Owner picture" [src]="pictureSrc" />
      </adl-loaded-item>
      <div class="column-flex j-center a-start" [ngClass]="{ w90: areTogglesDownSide, w80: !areTogglesDownSide }">
        <div class="flex justify-between w-full">
          <adl-text type="accent" class="tal wb-all">{{ title || '' }}</adl-text>
          <adl-icon-button *ngIf="areTogglesDownSide" icon="trash" (clicked)="onClickRemove()"></adl-icon-button>
        </div>
        <adl-text class="tal wb-all">{{ subTitle || '' }}</adl-text>
      </div>
    </div>
    <div *ngIf="areTogglesVisible" [ngClass]="{ w100: areTogglesDownSide, w35: !areTogglesDownSide }">
      <div *ngIf="!areTogglesDownSide; then togglesPart"></div>
    </div>
  </div>
  <div *ngIf="areTogglesVisible" class="w100">
    <div *ngIf="areTogglesDownSide; then togglesPart"></div>
  </div>
</div>
<ng-template #togglesPart>
  <div class="row-flex j-center a-center w100" [ngClass]="{ 'picture-toggles': areTogglesDownSide }">
    <div
      class="w-full"
      [ngClass]="{
              'flex': !areTogglesDownSide,
            }"
    >
      <div
        class="j-between a-center w100"
        [ngClass]="{
              'row-flex': areTogglesDownSide,
              'j-between': areTogglesDownSide,
              'a-center': areTogglesDownSide,
              'column-flex': !areTogglesDownSide,
              'j-center': !areTogglesDownSide,
              'a-start': !areTogglesDownSide,
            }"
      >
        <div
          *ngFor="let toggleOption of togglesOptions; let index = index"
          class="row-flex j-start a-center"
          [ngClass]="{ mt5: !areTogglesDownSide }"
        >
          <adl-switch
            [disabled]="toggleOption.isDisabled"
            (changed)="onToggle(index)"
            [standalone]="true"
            [active]="toggleOption.isActive"
            size="small"
            type="data-asset-admin"
          ></adl-switch>
          <adl-text [color]="toggleOption?.isDisabled ? 'light-grey' : 'dark-grey'" class="ml10">
            {{ toggleOption.label || '' }}</adl-text
          >
          <adl-info-bubble *ngIf="toggleOption?.tooltip" class="ml5">
            {{ toggleOption?.tooltip }}
          </adl-info-bubble>
        </div>
      </div>
      <div *ngIf="!areTogglesDownSide" class="column-flex justify-center">
        <adl-icon-button icon="trash" (clicked)="onClickRemove()"></adl-icon-button>
      </div>
    </div>
    <div
      class="row-flex j-end a-center"
      [ngClass]="{
        w30: areTogglesDownSide,
        w5: !areTogglesDownSide
      }"
    ></div>
  </div>
</ng-template>
