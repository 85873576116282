<div
  *ngIf="source?.hasInternalDashboard"
  class="toggle-dashboards"
  [ngClass]="{ p25: isInModalMode, modalBgWhite: !isWithToggleButton }"
>
  <div *ngIf="!isWithToggleButton" class="flex justify-center w-full">
    <div class="flex w35">
      <em class="fa fa-times" (click)="closeActiveModal()"></em>
    </div>
    <adl-title class="w65 text-left">Dashboards</adl-title>
  </div>

  <button
    *ngIf="isWithToggleButton && visibleDashboards.length === 1"
    type="button"
    class="btn-portal pblue xlarge collapsed"
    (click)="openDashboard(source.powerbi[0].name)"
  >
    See dashboard
    <dpg-dashboard-new-tab
      class="ml5"
      [source]="source"
      [dashboardName]="source.powerbi[0].name"
    ></dpg-dashboard-new-tab>
  </button>

  <ng-container *ngIf="visibleDashboards.length > 1">
    <ng-container *ngIf="isWithToggleButton; else dashboardsList">
      <adl-toggleable-content-button
        id="toggleable-dashboard-button"
        chevronIconType="white"
        buttonSize="225px"
        buttonBorderRadius="50px"
        buttonPaddingTop="15px"
        buttonPaddingBottom="15px"
        buttonPaddingLeftRight="30px"
        buttonBackgroundColor="blue"
        buttonTextColor="white"
        buttonTitle="See dashboards"
      >
        <div toggleable-content id="list-dashboards" class="text-left">
          <div *ngIf="this.shouldDisplayPath" class="explorer mt40 ml10">
            <button
              [ngClass]="{ dn: this.isRoot }"
              type="button"
              class="btn btn-clg btn-outline blue-elec inverse btn-effect"
              (click)="this.goBackFolderAndDisplay()"
            >
              <em class="icon-chevron-left"></em>
            </button>
            <span>{{ this.currentWholeFormattedPathToDisplay.sourceName }}</span>
            <span *ngIf="this.currentWholeFormattedPathToDisplay.foldersName?.length"> > </span>
            <span class="pr10 folderName">{{ this.currentWholeFormattedPathToDisplay.foldersName }}</span>
          </div>
          <div class="dashboards-wrapper">
            <div class="list">
              <dpg-dashboard-folder-card
                class="list-item"
                *ngFor="let dashboardFolder of this.currentLevelToDisplay.dashboardFolders"
                [dashboardFolder]="dashboardFolder"
                (clickExploreFolderAndDisplay)="this.exploreFolderAndDisplay($event)"
              >
              </dpg-dashboard-folder-card>
              <dpg-dashboard-card
                class="list-item"
                *ngFor="let dashboard of this.currentLevelToDisplay.dashboards"
                [source]="this.source"
                [dashboard]="dashboard"
                [isFavoriteSource]="this.isFavorite"
                [isActive]="this.isActiveDashboard(dashboard)"
                [isOwner]="isSourceOwner"
                (clickToggleFavoriteDashboard)="this.toggleFavoriteDashboard($event.name)"
                (clickOpenDashboard)="this.openDashboard($event.name)"
              >
              </dpg-dashboard-card>
              <div *ngIf="!this.dashboardsToDisplay.length" class="list-item">
                <div class="card">
                  <div class="title">No dashboard to display.</div>
                  <img src="/assets/dpg/imgs/elements/no-dashboard.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </adl-toggleable-content-button>
    </ng-container>
  </ng-container>
</div>

<ng-template #dashboardsList>
  <div *ngIf="this.shouldDisplayPath" class="explorer mt40 ml10">
    <button
      [ngClass]="{ dn: this.isRoot }"
      type="button"
      class="btn btn-clg btn-outline blue-elec inverse btn-effect"
      (click)="this.goBackFolderAndDisplay()"
    >
      <em class="icon-chevron-left"></em>
    </button>
    <span>{{ this.currentWholeFormattedPathToDisplay.sourceName }}</span>
    <span *ngIf="this.currentWholeFormattedPathToDisplay.foldersName?.length"> > </span>
    <span class="pr10 folderName">{{ this.currentWholeFormattedPathToDisplay.foldersName }}</span>
  </div>
  <div class="dashboards-wrapper">
    <div class="list">
      <dpg-dashboard-folder-card
        class="list-item"
        *ngFor="let dashboardFolder of this.currentLevelToDisplay.dashboardFolders"
        [dashboardFolder]="dashboardFolder"
        (clickExploreFolderAndDisplay)="this.exploreFolderAndDisplay($event)"
      >
      </dpg-dashboard-folder-card>
      <dpg-dashboard-card
        class="list-item"
        *ngFor="let dashboard of this.currentLevelToDisplay.dashboards"
        [source]="this.source"
        [dashboard]="dashboard"
        [isFavoriteSource]="this.isFavorite"
        [isActive]="this.isActiveDashboard(dashboard)"
        [isOwner]="isSourceOwner"
        (clickToggleFavoriteDashboard)="this.toggleFavoriteDashboard($event.name)"
        (clickOpenDashboard)="this.openDashboard($event.name)"
      >
      </dpg-dashboard-card>
      <div *ngIf="!this.dashboardsToDisplay.length" class="list-item">
        <div class="card">
          <div class="title">No dashboard to display.</div>
          <img src="/assets/dpg/imgs/elements/no-dashboard.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</ng-template>
