<div [ngClass]="'grid grid-cols-' + checkboxesPerLine">
  <div *ngIf="addAllOption">
    <adl-checkbox
      class="adl-checkboxes--item"
      checkboxSize="small"
      checkboxColor="blue"
      [checked]="allCheckboxControl.value"
      [hasPaddingRight]="hasPaddingRight"
      (changed)="clickOnAllCheckbox($event)"
    >
      <adl-text [size]="12">{{ allOptionLabel }}</adl-text>
    </adl-checkbox>
  </div>

  <div *ngFor="let checkbox of checkboxes">
    <adl-checkbox
      class="adl-checkboxes--item"
      checkboxSize="small"
      checkboxColor="blue"
      [checked]="checkbox.control.value"
      [hasPaddingRight]="hasPaddingRight"
      (changed)="onChange(checkbox.value, $event)"
    >
      <adl-text [ngClass]="{'whitespace-nowrap': monoLine}" [size]="labelSize" [type]="labelType" [bold]="isLabelBold">{{ checkbox.label }}</adl-text>
    </adl-checkbox>
  </div>
</div>
