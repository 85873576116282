import { inject, Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { distinctUntilChanged, first, map, shareReplay, startWith, switchMap, tap } from 'rxjs/operators';
import { CurrentUserService } from '@dataportal/auth';
import type { DatalakeOnlyProvider } from '@dataportal/datalake-parsing';

import { DatalakeStoreService } from './datalake-store.service';
import { ExplorerService } from './explorer.service';

import type { IBucket, IDatalakeObjectWithPermission } from '../entities/datalake';
import { currentToStoreOptions } from '../entities/datalake';
import type { IObject, IObjectRow } from '../entities/objects-browser';
import { filterByName } from '../entities/objects-browser';

@Injectable()
export class ObjectsBrowserFacade {
  private readonly _explorerService = inject(ExplorerService);
  private readonly _datalakeStoreService = inject(DatalakeStoreService);
  private readonly _currentUserService = inject(CurrentUserService);

  private readonly _selectedObjectsMap$ = new BehaviorSubject<Map<string, IObject>>(new Map());
  private readonly _bucketsSearchQuery$ = new BehaviorSubject<string>('');
  private readonly _objectsSearchQuery$ = new BehaviorSubject<string>('');
  private readonly _providerFilter$ = new BehaviorSubject<DatalakeOnlyProvider | null>(null);
  private readonly _isLoadingBuckets$ = new BehaviorSubject<boolean>(true);
  private readonly _isLoadingObjects$ = new BehaviorSubject<boolean>(false);

  readonly bucketsSearchQuery$ = this._bucketsSearchQuery$.asObservable();
  readonly objectsSearchQuery$ = this._objectsSearchQuery$.asObservable().pipe(distinctUntilChanged());

  private readonly _isUsingOwnToken$ = this._currentUserService.isAdmin$.pipe(
    map((isUser) => !isUser),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  private readonly _selectedObjectsKeys$: Observable<Set<string>> = this._selectedObjectsMap$.pipe(
    map((selectedObjectsMap) => new Set(selectedObjectsMap.keys())),
    startWith(new Set<string>()),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  readonly selectedObjects$: Observable<IObject[]> = this._selectedObjectsMap$.pipe(
    map((selectedObjectsMap) => Array.from(selectedObjectsMap.values())),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  readonly isLoadingBuckets$ = this._isLoadingBuckets$.asObservable();
  readonly isLoadingObjects$ = this._isLoadingObjects$.asObservable();

  readonly buckets$: Observable<IBucket[]> = this._datalakeStoreService.listBuckets().pipe(
    tap(() => this._isLoadingBuckets$.next(false)),
    startWith([]),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  readonly filteredBuckets$: Observable<IBucket[]> = this._bucketsSearchQuery$.pipe(
    switchMap((searchQuery) => this.buckets$.pipe(map((buckets) => filterByName(buckets, searchQuery)))),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  readonly filteredObjectsList$: Observable<IObject[]> = this.objectsSearchQuery$.pipe(
    switchMap((searchQuery) =>
      this.objectsList$.pipe(
        map((objects) => {
          return filterByName(objects, searchQuery);
        }),
      ),
    ),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  readonly currentBucket$: Observable<IBucket | null> = this._explorerService.currentBucket$.pipe(
    tap(() => this._objectsSearchQuery$.next('')),
    startWith(null),
  );

  readonly allObjects$: Observable<IDatalakeObjectWithPermission[]> = combineLatest([
    this._explorerService.current$,
    this.currentBucket$,
  ]).pipe(
    switchMap(([currentDirectory, currentBucket]) => {
      if (!currentDirectory) return of([]);

      this._isLoadingObjects$.next(true);

      return this._datalakeStoreService.getObjectStore(...currentToStoreOptions(currentDirectory)).pipe(
        switchMap((objectStore) => {
          this._isLoadingObjects$.next(true);

          if (objectStore.isThereDataRemaining) {
            return this._datalakeStoreService.listAll(...currentToStoreOptions(currentDirectory)).objects$;
          }

          return of(objectStore);
        }),
        tap((objectStore) => {
          if (objectStore.isThereDataRemaining === false) this._isLoadingObjects$.next(false);
        }),
        map((objectStore) => {
          return objectStore.objects.map((object, index) => ({
            ...object,
            bucket: currentBucket?.name,
            userPermission: objectStore.userPermissions[index],
          }));
        }),
      );
    }),
    shareReplay({ bufferSize: 1, refCount: false }),
  );

  readonly objectsWithCorrectProvider$ = combineLatest([this.allObjects$, this._providerFilter$]).pipe(
    map(([objects, providerFilter]) => {
      if (!providerFilter) return objects;

      return objects.filter((object) => object.provider === providerFilter);
    }, shareReplay({ bufferSize: 1, refCount: true })),
  );

  readonly objectsList$: Observable<IObjectRow[]> = this.objectsWithCorrectProvider$.pipe(
    map((datalakeObjects) =>
      datalakeObjects
        .filter((datalakeObject) => datalakeObject.type === 'folder')
        .map((object) => this._convertToObject(object)),
    ),
    switchMap((objects) => {
      return this._selectedObjectsKeys$.pipe(
        map((selectedObjectsKeys) =>
          objects.map((object) => ({
            ...object,
            isSelected: selectedObjectsKeys.has(object.key),
          })),
        ),
      );
    }),
    startWith([]),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  readonly breadcrumbs$ = this._explorerService.current$.pipe(
    map((currentDirectory) => currentDirectory?.breadcrumbs.slice(1) ?? []),
    startWith([]),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  readonly firstBreadcrumbUrl$: Observable<{ label: string; url: string } | null> = this._explorerService.current$.pipe(
    map((currentDirectory) => {
      if (!currentDirectory) {
        return null;
      }

      const breadcrumbName = currentDirectory.breadcrumbs[0];

      return {
        label: breadcrumbName,
        url: '',
      };
    }),
    startWith(null),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  readonly areAllObjectsSelected$: Observable<boolean> = this.objectsList$.pipe(
    map((objects) => {
      if (objects.length === 0) return false;

      return objects.every((object) => object.isSelected);
    }),
    distinctUntilChanged(),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  readonly shouldHideCheckboxAll$: Observable<boolean> = combineLatest([
    this.objectsList$,
    this.objectsSearchQuery$,
  ]).pipe(
    map(([objectsList, searchQuery]) => objectsList.length === 0 || searchQuery !== ''),
    distinctUntilChanged(),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  readonly vm$ = combineLatest([
    this.filteredBuckets$,
    this.currentBucket$,
    this.filteredObjectsList$,
    this.breadcrumbs$,
    this.firstBreadcrumbUrl$,
    this.selectedObjects$,
    this.isLoadingBuckets$,
    this.isLoadingObjects$,
    this.bucketsSearchQuery$,
    this.objectsSearchQuery$,
    this.areAllObjectsSelected$,
    this.shouldHideCheckboxAll$,
  ]).pipe(
    map(
      ([
        buckets,
        currentBucket,
        objectsList,
        breadcrumbs,
        firstBreadcrumbUrl,
        selectedObjects,
        isLoadingBuckets,
        isLoadingObjects,
        bucketsSearchQuery,
        objectsSearchQuery,
        areAllObjectsSelected,
        shouldHideCheckboxAll,
      ]) => ({
        buckets,
        currentBucket,
        objectsList,
        breadcrumbs,
        firstBreadcrumbUrl,
        selectedObjects,
        isLoadingBuckets,
        isLoadingObjects,
        bucketsSearchQuery,
        objectsSearchQuery,
        areAllObjectsSelected,
        shouldHideCheckboxAll,
      }),
    ),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  chooseBucket(bucket: IBucket): void {
    this._setCurrentObject({ bucket });
  }

  resetBucketSelected(): void {
    this._explorerService.resetBucketSelected();
  }

  chooseObject(object: IObject): void {
    const bucket = object.filesystem;
    const path = `${object.path}/${object.name}`;
    const provider = object.provider;

    this._setCurrentObject({ bucket, isListingUsingOwnToken: false, path, provider, tenant: object.tenant });
  }

  navigateToBreadcrumb(breadcrumb: string): void {
    combineLatest([this.breadcrumbs$, this._explorerService.current$, this.currentBucket$, this._isUsingOwnToken$])
      .pipe(
        first(),
        tap(([allBreadcrumbs, currentObject, bucket, isListingUsingOwnToken]) => {
          const path = allBreadcrumbs.slice(0, allBreadcrumbs.indexOf(breadcrumb) + 1).join('/');

          this._setCurrentObject({
            bucket,
            isListingUsingOwnToken,
            path,
            provider: currentObject.provider,
            tenant: currentObject.tenant,
          });
        }),
      )
      .subscribe();
  }

  toggleObjectSelection(shouldSelect: boolean, object: IObject): void {
    const selectedObjectsMap = this._selectedObjectsMap$.getValue();

    if (shouldSelect) {
      selectedObjectsMap.set(object.key, object);
    } else {
      selectedObjectsMap.delete(object.key);
    }

    this._selectedObjectsMap$.next(selectedObjectsMap);
  }

  setObjectsSelection(objects: IObject[]): void {
    const selectedObjectsMap = this._convertObjectsToMap(objects);

    this._selectedObjectsMap$.next(selectedObjectsMap);
  }

  selectAllCurrentObjects(): void {
    this.filteredObjectsList$
      .pipe(
        first(),
        tap((objects) => {
          const selectedObjectsMap = this._selectedObjectsMap$.getValue();

          objects.forEach((object) => {
            selectedObjectsMap.set(object.key, object);
          });

          this._selectedObjectsMap$.next(selectedObjectsMap);
        }),
      )
      .subscribe();
  }

  deselectAllCurrentObjects(): void {
    this.objectsList$
      .pipe(
        first(),
        tap((objects) => {
          const selectedObjectsMap = this._selectedObjectsMap$.getValue();

          objects.forEach((object) => {
            selectedObjectsMap.delete(object.key);
          });

          this._selectedObjectsMap$.next(selectedObjectsMap);
        }),
      )
      .subscribe();
  }

  setBucketsSearchQuery(searchQuery: string): void {
    this._bucketsSearchQuery$.next(searchQuery);
  }

  setObjectsSearchQuery(searchQuery: string): void {
    this._objectsSearchQuery$.next(searchQuery);
  }

  setProviderFilter(provider: DatalakeOnlyProvider | null): void {
    this._providerFilter$.next(provider);
  }

  private _convertObjectsToMap(objects: IObject[]): Map<string, IObject> {
    const keyValuePairs: [string, IObject][] = objects.map((object) => {
      const key = this._getDatalakeObjectKey({ ...object, bucket: object.filesystem.name });

      return [key, { ...object, key }];
    });

    return new Map(keyValuePairs);
  }

  private _convertToObject(datalakeObject: IDatalakeObjectWithPermission): IObject {
    const filesystem = { name: datalakeObject.bucket, primary: true, category: null };

    const object: IObject = {
      isFilesystem: false,
      isFolder: datalakeObject.type === 'folder',
      filesystem,
      path: datalakeObject.path,
      name: datalakeObject.name,
      provider: datalakeObject.provider,
      tenant: datalakeObject.tenant,
      isLoading: false,
      isExisting: true,
      key: this._getDatalakeObjectKey(datalakeObject),
    };

    return object;
  }

  private _getDatalakeObjectKey(
    object: Pick<IDatalakeObjectWithPermission, 'bucket' | 'path' | 'name' | 'tenant'>,
  ): string {
    return `${object.bucket}/${object.path}/${object.name}/${object.tenant}`;
  }

  private _setCurrentObject(data: {
    bucket: IBucket;
    isListingUsingOwnToken?: boolean;
    path?: string;
    provider?: 'aws' | 'azure';
    tenant?: string;
  }): void {
    const { bucket, isListingUsingOwnToken, path, provider, tenant } = data;
    this._explorerService.setCurrent(bucket, isListingUsingOwnToken, path, provider, tenant);
  }
}
