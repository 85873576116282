import { Publication } from '@dataportal/publications';
import { Type } from '@decahedron/entity';

import { Source } from './source';

// Entity
export class SourceWithMetrics extends Source {
  // Attributes
  views: number;
  visits: number;
  favorites: number;
  canRead: number;

  @Type(Publication) publications: Publication[] = [];

  // Methods
  fromJson(jsonData: any): SourceWithMetrics {
    if (!jsonData.publications) {
      jsonData.publications = [];
    }

    super.fromJson(jsonData);

    this.views = jsonData.views || 0;
    this.visits = jsonData.visits || 0;

    this.favorites = jsonData.favorites || 0;
    this.canRead = jsonData.canRead || 0;

    return this;
  }

  toJson(): any {
    return {
      ...super.toJson(),

      views: this.views,
      visits: this.visits,
      favorites: this.favorites,
      canRead: this.canRead,

      publications: this.publications?.map((publication) => publication.toJson()) || [],
    };
  }
}
