<adl-card>
  <div class="sticky-searchbar-content df a-center j-space-between w100">
    <div *ngIf="!showBackButton" class="empty-filler"></div>
    <div *ngIf="showBackButton" class="back-btn df a-center c-pointer" (click)="goBack()">
      <img class="arrow-left mr5" alt="back btn" src="/assets/modules/data-catalog/imgs/arrow-left-icon.svg" />
      <span>Back</span>
    </div>

    <div class="search-bar">
      <dpg-catalog-searchbar
        [searchControl]="searchControl"
        (searchAction)="searchAction.emit()"
        [updateURLQuery]="updateURLQuery"
        [showDropdown]="showDropdown"
        [showResultsInDropdown]="showResultsInDropdown"
      >
      </dpg-catalog-searchbar>
    </div>

    <dpg-catalog-mode-switch [adaptiveToggleTextColor]="true"></dpg-catalog-mode-switch>

    <dpg-header-profile color="blue"></dpg-header-profile>
  </div>
</adl-card>
