import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ApiService } from '@dataportal/front-api';
import { Logger } from '@dataportal/front-shared';
import { FavoriteDashboard } from '@dataportal/sources-dashboards-recommendations';
import type { IDashboardFavorite } from '@dataportal/types';
import { EntityBuilder } from '@decahedron/entity';

// Service
@Injectable()
export class FavoritesDashboardsService {
  // Attributes
  private readonly _favoritesDashboards = new BehaviorSubject<FavoriteDashboard[]>([]);
  favoritesDashboards$ = this._favoritesDashboards.asObservable();

  // Constructor
  constructor(private readonly _apiService: ApiService, private readonly _logger: Logger) {}

  // Methods
  getFavoritesDashboards(): Observable<FavoriteDashboard[]> {
    return this._apiService.get<IDashboardFavorite[]>('/v4/dashboards/favorites').pipe(
      map((dashboardFavorites) => {
        return EntityBuilder.buildMany<FavoriteDashboard>(FavoriteDashboard, dashboardFavorites);
      }),
    );
  }

  refreshFavoritesDashboards(): void {
    this._logger.debug('[FavoritesDashboardsService] Refresh favorites dashboard');

    this.getFavoritesDashboards()
      .pipe(
        tap((favorites) =>
          this._logger.debug('[FavoritesDashboardsService] Favorites dashboards refreshed', favorites),
        ),
      )
      .subscribe((favorites) => {
        this._favoritesDashboards.next(favorites);
      });
  }

  isFavoriteDashboard(sourceId: string, dashboardName: string): boolean {
    return this._favoritesDashboards
      .getValue()
      .some((favorite) => favorite.sourceId === sourceId && favorite.dashboardName === dashboardName);
  }

  createFavoriteDashboard(sourceId: string, dashboardName: string): Observable<FavoriteDashboard> {
    return this._apiService
      .post('/v4/dashboards/favorites', { source_id: sourceId, dashboard_name: dashboardName })
      .pipe(map((res) => EntityBuilder.buildOne(FavoriteDashboard, res)));
  }

  addFavoriteDashboard(sourceId: string, dashboardName: string): void {
    this._logger.debug('[FavoritesDashboardsService] Add dashboard favorite', sourceId);

    this.createFavoriteDashboard(sourceId, dashboardName)
      .pipe(tap((favorite) => this._logger.debug('[FavoritesDashboardsService] Favorite dashboard added', favorite)))
      .subscribe((newFavorite) => {
        const currentFavorites = this._favoritesDashboards.getValue();
        this._favoritesDashboards.next([...currentFavorites, newFavorite]);
      });
  }

  deleteFavoriteDashboard(sourceId: string, dashboardName: string): Observable<null> {
    return this._apiService.delete('/v4/dashboards/favorites', {
      body: { source_id: sourceId, dashboard_name: dashboardName },
    });
  }

  removeFavoriteDashboard(sourceId: string, dashboardName: string): void {
    this._logger.debug('[FavoritesDashboardsService] Remove dashboard favorite', sourceId, dashboardName);

    if (!this.isFavoriteDashboard(sourceId, dashboardName)) {
      this._logger.error(
        `[FavoritesDashboardsService] Cannot remove dashboard ${sourceId} -- ${dashboardName} from user favorites`,
      );

      return;
    }

    this.deleteFavoriteDashboard(sourceId, dashboardName).subscribe(() => {
      this._logger.debug('[FavoritesDashboardsService] Dashboard favorite removed', { sourceId, dashboardName });

      try {
        let favorites = this._favoritesDashboards.getValue();
        this._logger.debug('[FavoritesDashboardsService] Current state', favorites.length);

        favorites = favorites.filter((fav) => fav.sourceId !== sourceId || fav.dashboardName !== dashboardName);
        this._logger.debug('[FavoritesDashboardsService] Current state updated', favorites.length);
        this._favoritesDashboards.next(favorites);
      } catch (err) {
        this._logger.warn('[FavoritesDashboardsService] Error happened when updating favorites dashboards');
        this.refreshFavoritesDashboards();
      }
    });
  }

  saveFavoritesOrder(favoriteDashboardsOrdered: { dashboard_name: string; source_id: string }[]) {
    return this._apiService.post('/v4/dashboards/favorites/order', {
      favoriteDashboardsOrdered,
    });
  }
}
