<adl-modal
  closeButtonColor="white"
  backgroundColor="grey-100"
  headerBackgroundColor="primary-500"
  [isAlignedToCross]="true"
>
  <div modal-header class="my-8">
    <adl-text *ngIf="!sentRecommendation" type="normal" [size]="20" color="white">
      Share {{'dataAsset' | bizWording }}
    </adl-text>
    <adl-text *ngIf="sentRecommendation" type="normal" [size]="20" color="white">
      <adl-icon icon="circle-checked" [size]="50"></adl-icon>
      <div class="success-title">
        You have shared the {{'dataAsset' | bizWording }}
      </div>
    </adl-text>
  </div>

  <div *ngIf="!sentRecommendation" modal-body class="catalog-modal-body">
    <div class="subtitle"> {{ dashboard ? 'Share to your colleague this dashboard so he or she can discover it. ' : 'Share to your colleague this '+ ('dataAsset' | bizWording) + ' page so they can discover it'}}</div>
    <adl-button type="data-catalog" class="float-right" (click)="copyLink()">COPY LINK</adl-button>

    <div class="input-title mt-8">Email</div>
    <dpg-limited-user-select
      class="w-100"
      (selected)="limitedSelectedUser = $event"
      [clear]="clearSelectList"
      placeholder="Who should have a look at this {{ dashboard ? 'dashboard' : 'source' }}?"
    ></dpg-limited-user-select>

    <div class="info mt-2 mb-4">
      {{ 'Please note that your colleague may not be able to access it if he or she does not have the right permissions over ' + (dashboard ? 'the dashboard.' : 'data product.') }}
    </div>

    <div class="input-title">Comment</div>
    <textarea
      rows="5"
      class="stylised-input w-100"
      placeholder="Any comment?"
      [formControl]="customMessageControl"
    ></textarea>
  </div>

  <div *ngIf="sentRecommendation" modal-body class="catalog-modal-body-success">
    <div>Your colleague has been notified</div>
  </div>

  <div *ngIf="!sentRecommendation" modal-footer class="flex justify-center">
    <adl-button (clicked)="closeModal()" type="cancel" class="cancel-btn mr-4">CANCEL</adl-button>
    <adl-button type="data-catalog" (click)="share()" *ngIf="limitedSelectedUser?.id">SHARE</adl-button>
  </div>

  <div *ngIf="sentRecommendation" modal-footer class="flex justify-center">
    <adl-button type="data-catalog" (click)="closeModal()">CLOSE</adl-button>
  </div>
</adl-modal>
