import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AdlModule } from '@dataportal/adl';
import { ApiModule } from '@dataportal/front-api';
import { SharedModule } from '@dataportal/front-shared';
import { NgSelectModule } from '@ng-select/ng-select';

import { ProfileService } from './services/profile.service';

// Module
// @dynamic
@NgModule({
  imports: [CommonModule, FormsModule, NgSelectModule, AdlModule, ApiModule, SharedModule],
  declarations: [],
  providers: [ProfileService],
  exports: [],
})
export class ProfileModule {}
