import type { OnDestroy, OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { indicate } from '@dataportal/adl';

import { DocumentationService } from '../../../../../../libs/dataportal-data-academy/src/lib/services/documentation.service';
import { ProductService } from '../../../../../../libs/dataportal-data-academy/src/lib/services/product.service';
import { HelpCenterService } from '../help-center.service';

import type { IDocumentation } from '../../../../../../libs/dataportal-data-academy/src/lib/models/documentation.model';

@Component({
  selector: 'app-help-search',
  templateUrl: './help-search.component.html',
  styleUrls: ['./help-search.component.scss'],
})
export class HelpSearchComponent implements OnInit, OnDestroy {
  loadingResults$: Subject<boolean> = new Subject<boolean>();
  searchQuery$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  private readonly _destroyed$ = new Subject();

  constructor(
    readonly helpCenterService: HelpCenterService,
    private readonly _route: ActivatedRoute,
    private readonly _router: Router,
    private readonly _documentationService: DocumentationService,
    private readonly _productService: ProductService,
  ) {
    this.helpCenterService.updateProductsFAQ();
  }

  ngOnInit() {
    this.helpCenterService.updateProductsFAQ();
    this._route.queryParams.subscribe((params) => {
      this.searchQuery$.next(params.search ?? '');
    });

    this.helpCenterService.faqEntriesFiltered$
      .pipe(indicate(this.loadingResults$), takeUntil(this._destroyed$))
      .subscribe();

    combineLatest([this.helpCenterService.faqEntriesResults$, this.searchQuery$])
      .pipe(takeUntil(this._destroyed$))
      .subscribe(([, searchQuery]) => {
        this.helpCenterService.updateFilteredEntries(searchQuery);
      });
  }

  ngOnDestroy() {
    this.helpCenterService.resetFilteredEntries();
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  faqAction(item: IDocumentation) {
    this._documentationService.openDocumentation(item);
  }
}
