import type { OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import { CheckRegexType } from '@dataportal/front-api';
import type { CheckRegex } from '@dataportal/guardian-utils';
import { IGuardianStatus } from '@dataportal/guardian-utils';

import { GuardianService } from '../../services/guardian.service';

import type {
  IGuardianCheckScheduling,
  IGuardianCheckSchedulingStartingTime,
  YearMonth,
} from '../../entities/guardian-form';

/**
 * Component that show the configuration of the Guardian Check
 */
@Component({
  selector: 'dpg-guardian-check-infos-global',
  templateUrl: './guardian-check-infos-global.component.html',
  styleUrls: ['./guardian-check-infos-global.component.scss'],
})
export class GuardianCheckInfosGlobalComponent implements OnInit {
  @Input() guardianStatus: IGuardianStatus;
  @Input() withTitle = false;
  @Input() allCheckRegex: CheckRegex[] = null;

  schedulingInfos: IGuardianCheckScheduling;

  constructor(private readonly _guardianService: GuardianService) {}

  ngOnInit(): void {
    this.schedulingInfos = GuardianService.convertToSchedulingInfos(
      this.guardianStatus?.checkInfos?.dag_schedule_interval,
    );
  }

  isTextField(type: string): boolean {
    return type === 'string';
  }

  get schedulingStartingTime(): IGuardianCheckSchedulingStartingTime {
    return this.schedulingInfos?.schedulingStartingTime;
  }

  get formattedSchedulingWeeklySelectedDays(): string {
    return (this.schedulingInfos?.schedulingWeeklySelectedDays || [])
      .map((cronWeekDay) => this._guardianService.convertWeekdayFromCronToWeekday(cronWeekDay))
      .join(', ');
  }

  get formattedSchedulingYearlyMonth(): string {
    return this._guardianService.convertYearMonthFromCronToYearMonth(
      (this.schedulingInfos?.schedulingYearlySelectedMonth || '') as YearMonth,
    );
  }

  get alertRecipientsOnSuccess(): string[] {
    return GuardianService.convertGuardianStringArrayToArray(this.guardianStatus?.checkInfos?.email_if_ok);
  }

  get alertRecipientsOnFailure(): string[] {
    const emailsIfNotOkArray = GuardianService.convertGuardianStringArrayToArray(
      this.guardianStatus?.checkInfos?.email_if_not_ok,
    );

    return [
      ...emailsIfNotOkArray,
      ...GuardianService.convertGuardianStringArrayToArray(this.guardianStatus?.checkInfos?.email_if_exception).filter(
        (email) => !emailsIfNotOkArray.includes(email),
      ),
    ];
  }

  get targetFoldersIfOk(): string[] {
    return this.guardianStatus?.checkInfos?.target_folder_if_ok?.length
      ? GuardianService.convertGuardianStringArrayToArray(this.guardianStatus.checkInfos.target_folder_if_ok)
      : [];
  }

  get targetFoldersIfError(): string[] {
    return this.guardianStatus?.checkInfos?.target_folder_if_error?.length
      ? GuardianService.convertGuardianStringArrayToArray(this.guardianStatus.checkInfos.target_folder_if_error)
      : [];
  }

  get hasToDisplayAlertRecipientsBox(): boolean {
    return !!this.alertRecipientsOnSuccess?.length || !!this.alertRecipientsOnFailure?.length;
  }

  get hasToDisplayTargetFoldersBox(): boolean {
    return (
      !!this.targetFoldersIfOk?.length ||
      !!this.targetFoldersIfError?.length ||
      this.hasFileNameRenaming ||
      this.guardianStatus?.checkInfos?.enforce_replacement
    );
  }

  get hasToDisplayWriteResultBox(): boolean {
    return this.guardianStatus?.checkInfos?.write_result;
  }

  get hasToDisplaySchedulingBox(): boolean {
    return (
      (!!this.guardianStatus.checkInfos.dag_schedule_interval?.length && !!this.schedulingInfos) ||
      !!this.guardianStatus?.checkInfos?.regex?.length
    );
  }

  get hasFileNameRenaming(): boolean {
    return [...this.targetFoldersIfOk, ...this.targetFoldersIfError].some((path) => {
      const pathWithoutFormatName = this.getPathWithoutFormatName(path);

      return path && pathWithoutFormatName && path.length !== pathWithoutFormatName.length;
    });
  }

  getLabelForNameFilterCheckRegex(regex: string): string {
    if (!this.allCheckRegex?.length || !regex?.length) {
      return '';
    }

    const regexFound =
      this.allCheckRegex?.find(
        (fetchedRegex) => fetchedRegex.type === CheckRegexType.NAME_FILTER_CHECK && fetchedRegex.regex === regex,
      ) || null;
    const nameFilterRegexLabel = regexFound ? regexFound.label : regex;

    return nameFilterRegexLabel?.length ? `-> Equivalent to : ${nameFilterRegexLabel}` : '';
  }

  getPathWithoutFormatName(path: string): string {
    if (!path) {
      return '';
    }

    const pathSegments = path.split('/');

    if (pathSegments[pathSegments.length - 1].includes(this._guardianService.fileNameRenamingFormat)) {
      pathSegments.pop();
    }

    return pathSegments.join('/');
  }

  getDelimiterToDisplay(): string {
    return GuardianService.getDelimiterToDisplay(this.guardianStatus?.checkInfos?.delimiter || '');
  }
}
