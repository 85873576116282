<div
  class="column-flex a-center adl-modal"
  [class]="classList"
  [ngClass]="{
    w100: isFullWidth,
    tal: textAlignment === 'left',
    tar: textAlignment === 'right',
    tac: textAlignment === 'center',
    pt30: !isAlignedToCross,
    pt0: isAlignedToCross,
    pb30: hasMarginBottom,
    pb0: !hasMarginBottom,
    br10: roundCorner
  }"
>
  <div
    class="header-modal row-flex w100"
    [class]="headerBackgroundColor ? headerBackgroundColorClassName : null"
    [ngClass]="{
      'round-corner': roundCorner,
      'justify-start': textAlignment === 'left',
      'justify-center': textAlignment === 'center',
      'justify-end': textAlignment === 'right'
    }"
  >
    <div
      *ngIf="this.hasCloseButton"
      class="adl-modal--close js-end"
      [class]="closeButtonColor ? closeButtonColorClassName : null"
      data-cy="adl-modal-close-button"
    >
      <em class="fa fa-times" (click)="close()"></em>
    </div>
    <ng-content select="[modal-header]"></ng-content>
  </div>

  <div
    class="adl-modal--body w100"
    [ngClass]="{
      pl30: hasBodySidesPadding && bodySidesPadding === 'normal',
      pr30: hasBodySidesPadding && bodySidesPadding === 'normal',
      pl15: hasBodySidesPadding && bodySidesPadding === 'small',
      pr15: hasBodySidesPadding && bodySidesPadding === 'small',
      pl0: !hasBodySidesPadding,
      pr0: !hasBodySidesPadding
    }"
  >
    <ng-content select="[modal-body]"></ng-content>
  </div>
  <div class="footer-modal w100">
    <ng-content select="[modal-footer]"></ng-content>
  </div>
</div>
