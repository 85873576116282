import { Inject, Injectable } from '@angular/core';
import type { ActivatedRouteSnapshot, CanActivate, CanLoad, RouterStateSnapshot } from '@angular/router';
import { Router } from '@angular/router';
import type { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PermissionsModule } from '../permissions.module';

import { PermissionsService } from '../services/permissions.service';

import type { Action } from '../entities/action';
import { ACTIONS } from '../entities/action';
import { IPermissionsOptions, PERMISSIONS_OPTIONS } from '../permissions-options';
import type { Permissions } from '../services/permissions.service';

// Guard
@Injectable({
  providedIn: PermissionsModule,
})
export class GroupOwnerGuard implements CanActivate, CanLoad {
  // Constructor
  constructor(
    private readonly _permissionsService: PermissionsService,
    private readonly _router: Router,
    @Inject(PERMISSIONS_OPTIONS) private readonly _options: IPermissionsOptions,
  ) {}

  // Methods
  canActivate(_: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const parts = new URL(state.url, location.origin).pathname.split('/');
    const action: Action = ACTIONS[parts[3]] || 'update';
    const path: string | undefined = parts[4] || (action === 'create' ? undefined : parts[3]);

    return this._permissionsService.isAuthorized(action, 'groups', path).pipe(map((authorized) => authorized));
  }

  // Check if the user has access to at least one group
  canLoad(): Observable<boolean> {
    return this._permissionsService.permissionsAdmin$.pipe(
      map((permissions: Permissions) => {
        if (permissions && permissions.groups) {
          return true;
        }

        this._router.navigate(this._options.errorRouterLink);

        return false;
      }),
    );
  }
}
