import { Component, Inject } from '@angular/core';

import { AUTH_OPTIONS, IAuthOptions } from '../../auth-options';

// Component
@Component({
  selector: 'dpg-auth-callback',
  templateUrl: './auth-callback.component.html',
  styleUrls: ['./auth-callback.component.scss'],
})
export class AuthCallbackComponent {
  // Attributes
  showUnauthorize = false;

  // Constructor
  constructor(@Inject(AUTH_OPTIONS) readonly options: IAuthOptions) {
    if (window.location.href.indexOf('error=unauthorized') !== -1) {
      this.showUnauthorize = true;
    }
  }
}
