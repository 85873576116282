import type { OnDestroy, OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import type { Observable } from 'rxjs';
import { BehaviorSubject, merge, of, Subject } from 'rxjs';
import { catchError, delay, distinctUntilChanged, take, takeUntil } from 'rxjs/operators';
import { DatalakeApiService, DatalakeObjectSelectorModalComponent } from '@dataportal/datalake-and-guardian';
import type { APIReferential, IAPIDatalakeReferential, IAPITableReferential } from '@dataportal/front-api';
import type { IBaseGuardianDatalakePath } from '@dataportal/front-shared';
import { DatalakePath, DatalakeService } from '@dataportal/front-shared';
import type {
  DatalakeColumnType,
  GuardianCheckConstraint,
  IColumnField,
  IGuardianChecksDatasetMap,
  IGuardianDatalakePath,
  ISupportedGuardianChecksResource,
  RegexReferential,
  SnowflakeColumnType,
} from '@dataportal/guardian-utils';
import {
  datalakeColumnTypes,
  globalDateType,
  GuardianReferentialOverviewModalComponent,
  GuardianService,
  snowflakeColumnTypes,
} from '@dataportal/guardian-utils';
import { DBSnowflakeService } from '@dataportal/snowflake';
import type { AbstractControl } from '@ngneat/reactive-forms';
import { FormArray, FormGroup } from '@ngneat/reactive-forms';

enum MinPlaceholder {
  datetime = 'E.g. 2021-02-15',
  DATE = datetime,
  DATETIME = 'E.g. 2021-02-15 11:08:30.012345679',
  TIMESTAMP = DATETIME,
  TIMESTAMP_NTZ = DATETIME,
  TIME = 'E.g. 11:08:30',
  DEFAULT = 'E.g. 1',
}

enum MaxPlaceholder {
  datetime = 'E.g. 2021-04-16',
  DATE = datetime,
  DATETIME = 'E.g. 2021-04-16 11:08:30.012345679',
  TIMESTAMP = DATETIME,
  TIMESTAMP_NTZ = DATETIME,
  TIME = 'E.g. 11:08:30',
  DEFAULT = 'E.g. 1000',
}

/**
 * Guardian-step-check-column-field component form error mechanism :
 * - It is done in different way depending on the form control type (input / select / other).
 * - For every control, inside ngOnInit() life cycle hook, we subscribe (in the dedicated functions) to the different controls to detect errors.
 * - How error detection works inside the dedicated functions : everytime a control is updated (dirty / touched / value changed) and if an error is present,
 * we set an error at the form control level. So we also need to spread the changes to the formGroup by marking it as pristine to change the form validity.
 * - Input : When adding a constraint, the related input control appears and is initialized with the value ''.
 * When removing a constraint, the related input control appears and its value is set to null which indicates that the control
 * does not exist anymore and should not be taken into consideration when checking for form errors.
 * - Select : The errors check are way simpler since there is always a default value for select controls.
 * - Other : When adding a constraint, the related control appears and we sometimes need to mark it as dirty to trigger the errors checks.
 */
@Component({
  selector: 'app-guardian-step-check-column-field',
  templateUrl: './guardian-step-check-column-field.component.html',
  styleUrls: ['./guardian-step-check-column-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GuardianStepCheckColumnFieldComponent<R extends ISupportedGuardianChecksResource>
  implements OnInit, OnDestroy
{
  // inputs
  @Input() allColumnsFormArray: FormArray<IColumnField>;
  @Input() formGroup: FormGroup<IColumnField>;
  @Input() formReady: boolean;
  @Input() dataset: IGuardianChecksDatasetMap[R];
  @Input() checkType: R;
  @Input() index: number;
  @Input() preparedRegexValues: Array<{ label: string; value: string }> = [];
  @Input() snowflakeReferentialsValues: Array<{ label: string; value: IAPITableReferential }> = [];
  @Input() isSnowflakeReferentialEqual: (value1: APIReferential, value2: APIReferential) => boolean;
  // outputs
  @Output() onClickRemove = new EventEmitter<number>();
  @Output() onClickAdd = new EventEmitter<number>();
  @Output() onClickDuplicate = new EventEmitter<number>();
  @Output() moveUp = new EventEmitter<number>();
  @Output() moveDown = new EventEmitter<number>();

  // form constants
  static NB_ROW_EXTERNAL_REFERENTIAL_LIMIT = 5;
  static NB_CHAR_PATH_TO_DISPLAY_LIMIT = 100;
  columnTypes: Array<{ label: string; value: string }>;
  requiredValues: Array<{ label: string; value: boolean }> = [
    { label: 'No', value: false },
    { label: 'Yes', value: true },
  ];
  uniqueValues: Array<{ label: string; value: boolean }> = [
    { label: 'No', value: false },
    { label: 'Yes', value: true },
  ];
  regexReferentialValues: Array<{ label: string; value: RegexReferential }> = [
    { label: 'Manual regex', value: 'manual-regex' },
    { label: 'Prepared regex', value: 'prepared-regex' },
  ];
  availableGreaterThanColumnValues: Array<{ label: string; value: string }> = []; // label: column name - value : column id
  availableConstraintsMap: Array<{
    label: string;
    value: GuardianCheckConstraint;
    tooltip?: string;
    isDisabled?: boolean;
  }> = [];

  // variables
  private readonly _isFieldColumnIncorrect$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private readonly _isMinValueGreaterThanMaxValue$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly _isRegexColumnRequired$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly _isRegexColumnIncorrect$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly _isDatalakeInReferentialEmpty$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private readonly _isDatalakeNotInReferentialEmpty$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  isFieldColumnIncorrect$: Observable<boolean> = this._isFieldColumnIncorrect$.asObservable();
  isRegexColumnRequired$: Observable<boolean> = this._isRegexColumnRequired$.asObservable();
  isRegexColumnIncorrect$: Observable<boolean> = this._isRegexColumnIncorrect$.asObservable();
  isDatalakeInReferentialEmpty$: Observable<boolean> = this._isDatalakeInReferentialEmpty$.asObservable();
  isDatalakeNotInReferentialEmpty$: Observable<boolean> = this._isDatalakeNotInReferentialEmpty$.asObservable();
  isUsingCustomRegex = true;
  isLoadingCurrentSnowflakeReferentialsValuesExtract = false;
  isLoadingCurrentFormattedDatalakeReferentials = false;
  hasDatalakeReferentialMetadataFetchingWarning = false;
  currentSnowflakeReferentialsValuesExtract: string[] = [];

  private readonly _destroyed$ = new Subject<void>();

  constructor(
    private readonly _datalakeService: DatalakeService,
    private readonly _guardianService: GuardianService,
    private readonly _dbSnowflakeService: DBSnowflakeService,
    private readonly _datalakeApiService: DatalakeApiService,
    private readonly _modalMatService: MatDialog,
    private readonly _router: Router,
  ) {}

  ngOnInit(): void {
    this.columnTypes = this.checkType === 'datalakePath' ? datalakeColumnTypes : snowflakeColumnTypes;
    this._initAvailableConstraintsMap();
    this._subscribeColumnNameErrors();
    this._subscribeMaxValueError();
    this._subscribeMinMaxValueError();
    this._subscribeRegexError();
    this._subscribeTypeErrors();
    this._subscribeDatalakeExternalReferentialError();
    this.initExternalReferentialValues();
    this.allColumnsFormArray.value$.pipe(take(1), takeUntil(this._destroyed$)).subscribe((columnFieldsValue) => {
      this._setAvailableGreaterThanColumnValues(columnFieldsValue);
    });
    this.addedConstraintsControl.valueChanges
      .pipe(distinctUntilChanged(), takeUntil(this._destroyed$))
      .subscribe(() => {
        this.updateAddedAndAvailableConstraints();
        this.initExternalReferentialValues();
      });
    this.availableConstraintsControl.valueChanges
      .pipe(distinctUntilChanged(), takeUntil(this._destroyed$))
      .subscribe(() => {
        this._refreshAvailableConstraintsMap();
      });
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
  }

  get selectedType(): DatalakeColumnType | SnowflakeColumnType {
    return this.addedConstraintsList.includes('type') ? this.formGroup.get('type').value : null;
  }

  get hasSelectedNumber(): boolean {
    return this.selectedType?.length && this.formGroup.get('type').value === 'integer';
  }

  get hasSelectedDecimal(): boolean {
    return this.selectedType?.length && this.formGroup.get('type').value === 'float';
  }

  get hasSelectedNumberOrDecimal(): boolean {
    return this.hasSelectedNumber || this.hasSelectedDecimal;
  }

  get hasSelectedText(): boolean {
    return this.selectedType?.length && this.selectedType === 'string';
  }

  get hasSelectedDate(): boolean {
    return this.selectedType?.length && globalDateType.includes(this.selectedType);
  }

  get hasSelectedRegexConstraint(): boolean {
    return this.addedConstraintsList.includes('regex');
  }

  get hasSelectedManualRegex(): boolean {
    return this.hasSelectedRegexConstraint && this.formGroup.get('regexReferential').value === 'manual-regex';
  }

  get hasSelectedPreparedRegex(): boolean {
    return this.hasSelectedRegexConstraint && this.formGroup.get('regexReferential').value === 'prepared-regex';
  }

  get hasSnowflakeReferentialValue(): boolean {
    return !!this.formGroup.get('snowflakeExternalReferential')?.value;
  }

  getSnowflakeReferential(): IAPITableReferential {
    return this.formGroup.get('snowflakeExternalReferential')?.value;
  }

  getDatalakeInReferential(): IAPIDatalakeReferential {
    return this.formGroup.get('datalakeInExternalReferential')?.value;
  }

  getDatalakeNotInReferential(): IAPIDatalakeReferential {
    return this.formGroup.get('datalakeNotInExternalReferential')?.value;
  }

  getFormattedDatalakeReferential(isWhitelisted: boolean): IBaseGuardianDatalakePath {
    return isWhitelisted
      ? (this.formGroup.get('datalakePathIn')?.value as IBaseGuardianDatalakePath)
      : (this.formGroup.get('datalakePathNotIn')?.value as IBaseGuardianDatalakePath);
  }

  getFullDatalakePathReferentialToDisplay(isWhitelisted: boolean): string {
    const formattedDatalakeReferential = this.getFormattedDatalakeReferential(isWhitelisted);
    const bucket = formattedDatalakeReferential?.bucket?.length ? formattedDatalakeReferential.bucket : null;
    const path = formattedDatalakeReferential?.path?.length ? formattedDatalakeReferential.path : null;

    return bucket?.length && path?.length ? `${bucket}/${path}` : null;
  }

  getPartialDatalakePathReferentialToDisplay(isWhitelisted: boolean): string {
    const datalakeReferential = isWhitelisted ? this.getDatalakeInReferential() : this.getDatalakeNotInReferential();
    const path = datalakeReferential?.prefix?.length ? datalakeReferential.prefix : null;

    if (path && path.length > GuardianStepCheckColumnFieldComponent.NB_CHAR_PATH_TO_DISPLAY_LIMIT) {
      const trimmedPath = path.substring(
        path.length - GuardianStepCheckColumnFieldComponent.NB_CHAR_PATH_TO_DISPLAY_LIMIT,
      );

      return `...${trimmedPath}`;
    }

    return path;
  }

  private static _hasDateFormatError(dateValue: string): boolean {
    return GuardianService.hasDateFormatError(dateValue);
  }

  private static _hasNumberComparisonError(minValue: string, maxValue: string): boolean {
    const minValueInt = Number(minValue);
    const maxValueInt = Number(maxValue);

    return minValue && maxValue && !isNaN(minValueInt) && !isNaN(maxValueInt) && minValueInt > maxValueInt;
  }

  private static _hasDateComparisonError(minDateValue: string, maxDateValue: string): boolean {
    if (
      !minDateValue.length ||
      !maxDateValue.length ||
      GuardianStepCheckColumnFieldComponent._hasDateFormatError(minDateValue) ||
      GuardianStepCheckColumnFieldComponent._hasDateFormatError(maxDateValue)
    ) {
      return false;
    }

    return GuardianService.compareDate(minDateValue, maxDateValue) > 0;
  }

  private _subscribeInputErrors(
    control: AbstractControl,
    subject$: Subject<boolean>,
    showCondition: () => boolean,
  ): void {
    merge(control.dirty$, control.touch$, control.status$, control.valueChanges)
      .pipe(distinctUntilChanged(), takeUntil(this._destroyed$), delay(1))
      .subscribe(() => {
        if ((control.dirty || control.touched) && showCondition()) {
          subject$.next(true);
          control.setErrors({ error: true });
        } else {
          subject$.next(false);
          control.setErrors(null);
        }
      });
  }

  private _availableGreaterThanColumnNamesFilteringCondition(columnFieldValueToCheck: IColumnField): boolean {
    const greaterThanConstraint = 'greaterThan';
    const currentColumnFieldColumnId = this.formGroup?.get('columnId')?.value || '';
    const columnFieldToCheckColumnId = columnFieldValueToCheck.columnId;
    const columnFieldToCheckColumnName = columnFieldValueToCheck.columnName;
    const columnFieldToCheckGreaterThanColumnId = columnFieldValueToCheck.greaterThanColumnId;
    const columnFieldToCheckColumnNameAvailableConstraints =
      columnFieldValueToCheck.availableConstraints || ([] as GuardianCheckConstraint[]);
    const columnFieldToCheckColumnNameAddedConstraints =
      columnFieldValueToCheck.addedConstraints || ([] as GuardianCheckConstraint[]);
    const currentFieldType = this.formGroup?.get('type')?.value || '';
    const columnFieldValueToCheckType = columnFieldValueToCheck.type || '';
    const currentFieldGlobalType = GuardianService.calculateFieldGlobalTypeFromColumnFieldForm(this.formGroup?.value);
    const columnFieldValueToCheckGlobalType =
      GuardianService.calculateFieldGlobalTypeFromColumnFieldForm(columnFieldValueToCheck);

    const isColumnFieldToCheckColumnNameNotEmpty =
      columnFieldToCheckColumnId?.length && columnFieldToCheckColumnName?.length;
    const isColumnFieldToCheckColumnNameNotTheSameAsCurrent = columnFieldToCheckColumnId !== currentColumnFieldColumnId;
    const isColumnFieldToCheckGreaterThanCurrentColumnName =
      columnFieldToCheckGreaterThanColumnId === currentColumnFieldColumnId;
    const isColumnFieldToCheckNotForbiddenByType =
      !currentFieldType?.length ||
      currentFieldGlobalType === 'unknown' ||
      !columnFieldValueToCheckType?.length ||
      columnFieldValueToCheckGlobalType === 'unknown' ||
      currentFieldType === columnFieldValueToCheckType;
    const isColumnFieldToCheckNotForbiddenByConstraints =
      columnFieldToCheckColumnNameAvailableConstraints.includes(greaterThanConstraint) ||
      (columnFieldToCheckColumnNameAddedConstraints.includes(greaterThanConstraint) &&
        !isColumnFieldToCheckGreaterThanCurrentColumnName);

    return (
      isColumnFieldToCheckColumnNameNotEmpty &&
      isColumnFieldToCheckColumnNameNotTheSameAsCurrent &&
      isColumnFieldToCheckNotForbiddenByType &&
      isColumnFieldToCheckNotForbiddenByConstraints
    );
  }

  private _removePotentialForbiddenGreaterThanConstraint(): void {
    const currentColumnFieldGreaterThanColumnIdInput = this.formGroup.get('greaterThanColumnId');
    const currentColumnFieldGreaterThanColumnIdValue = currentColumnFieldGreaterThanColumnIdInput?.value || '';
    const hasAddedGreaterThanConstraint = this.addedConstraintsList.includes('greaterThan');
    const hasAddedForbiddenGreaterThanColumnId =
      currentColumnFieldGreaterThanColumnIdValue.length &&
      !this.availableGreaterThanColumnValues
        .map((constraint) => constraint.value)
        .includes(currentColumnFieldGreaterThanColumnIdValue);

    if (hasAddedGreaterThanConstraint && hasAddedForbiddenGreaterThanColumnId) {
      const updatedAddedConstraintsList = this.addedConstraintsList.filter(
        (constraint) => constraint !== 'greaterThan',
      );
      currentColumnFieldGreaterThanColumnIdInput.setValue(null);
      this.addedConstraintsControl.setValue(updatedAddedConstraintsList);
      this._updateAvailableConstraints(updatedAddedConstraintsList);
    }
  }

  private _initAvailableConstraintsMap(): void {
    this.availableConstraintsMap = GuardianService.getContraintsMapObjectFromConstraintsList(
      this.availableConstraintsList,
    );
  }

  private _refreshAvailableConstraintsMap(): void {
    this.availableConstraintsMap = GuardianService.getContraintsMapObjectFromConstraintsList(
      this.availableConstraintsList,
    ).map((constraintObject) => ({
      ...constraintObject,
      isDisabled: constraintObject.value === 'greaterThan' && !this.availableGreaterThanColumnValues?.length,
    }));
  }

  private _subscribeAvailableGreaterThanColumnNames() {
    this.allColumnsFormArray.value$
      .pipe(distinctUntilChanged(), takeUntil(this._destroyed$))
      .subscribe((columnFieldsValue) => {
        this._setAvailableGreaterThanColumnValues(columnFieldsValue);
      });
  }

  private _subscribeColumnNameErrors(): void {
    const columnNameControl = this.formGroup.get('columnName');
    const conditionToShow = () => !columnNameControl.value.length;
    this._subscribeInputErrors(columnNameControl, this._isFieldColumnIncorrect$, conditionToShow);
  }

  private _subscribeMaxValueError(): void {
    const typeControl = this.formGroup.get('type');
    const maxValueControl = this.formGroup.get('maxValue');
    merge(
      typeControl.dirty$,
      typeControl.touch$,
      typeControl.status$,
      typeControl.valueChanges,
      maxValueControl.dirty$,
      maxValueControl.touch$,
      maxValueControl.status$,
      maxValueControl.valueChanges,
    )
      .pipe(distinctUntilChanged(), takeUntil(this._destroyed$), delay(1))
      .subscribe(() => {
        if (maxValueControl.value === null) {
          maxValueControl.setErrors(null);
        } else if ((maxValueControl.dirty || maxValueControl.touched) && !maxValueControl.value?.toString()?.length) {
          maxValueControl.setErrors({ requiredError: true });
        } else if (
          (typeControl.dirty || typeControl.touched || maxValueControl.dirty || maxValueControl.touched) &&
          ((this.hasSelectedNumberOrDecimal && isNaN(maxValueControl.value)) ||
            (this.hasSelectedNumber && !new RegExp(GuardianService.NUMBER_REGEX).test(maxValueControl.value)) ||
            (this.hasSelectedDate &&
              GuardianStepCheckColumnFieldComponent._hasDateFormatError(maxValueControl.value?.toString() || '')))
        ) {
          maxValueControl.setErrors({ maxValueIncorrectError: true });
        } else {
          maxValueControl.setErrors(null);
        }

        this.formGroup.markAsPristine();
      });
  }

  private _subscribeMinMaxValueError(): void {
    const typeControl = this.formGroup.get('type');
    const minValueControl = this.formGroup.get('minValue');
    const maxValueControl = this.formGroup.get('maxValue');
    merge(
      typeControl.dirty$,
      typeControl.touch$,
      typeControl.status$,
      typeControl.valueChanges,
      minValueControl.dirty$,
      minValueControl.touch$,
      minValueControl.status$,
      minValueControl.valueChanges,
      maxValueControl.dirty$,
      maxValueControl.touch$,
      maxValueControl.status$,
      maxValueControl.valueChanges,
    )
      .pipe(distinctUntilChanged(), takeUntil(this._destroyed$), delay(1))
      .subscribe(() => {
        if (minValueControl.value === null) {
          this._isMinValueGreaterThanMaxValue$.next(false);
          minValueControl.setErrors(null);
        } else if ((minValueControl.dirty || minValueControl.touched) && !minValueControl.value?.toString()?.length) {
          this._isMinValueGreaterThanMaxValue$.next(false);
          minValueControl.setErrors({ requiredError: true });
        } else if (
          (typeControl.dirty || typeControl.touched || minValueControl.dirty || minValueControl.touched) &&
          ((this.hasSelectedNumberOrDecimal && isNaN(minValueControl.value)) ||
            (this.hasSelectedNumber && !new RegExp(GuardianService.NUMBER_REGEX).test(minValueControl.value)) ||
            (this.hasSelectedDate &&
              GuardianStepCheckColumnFieldComponent._hasDateFormatError(minValueControl.value?.toString() || '')))
        ) {
          this._isMinValueGreaterThanMaxValue$.next(false);
          minValueControl.setErrors({ minValueIncorrectError: true });
        } else if (
          (typeControl.dirty ||
            typeControl.touched ||
            minValueControl.dirty ||
            minValueControl.touched ||
            maxValueControl.dirty ||
            maxValueControl.touched) &&
          ((this.hasSelectedNumberOrDecimal &&
            minValueControl?.value &&
            maxValueControl?.value &&
            GuardianStepCheckColumnFieldComponent._hasNumberComparisonError(
              minValueControl.value?.toString() || '',
              maxValueControl.value?.toString() || '',
            )) ||
            (this.hasSelectedDate &&
              GuardianStepCheckColumnFieldComponent._hasDateComparisonError(
                minValueControl.value?.toString() || '',
                maxValueControl.value?.toString() || '',
              )))
        ) {
          this._isMinValueGreaterThanMaxValue$.next(true);
          minValueControl.setErrors({ minValueGreaterThantMaxValueError: true });
        } else {
          this._isMinValueGreaterThanMaxValue$.next(false);
          minValueControl.setErrors(null);
        }

        this.formGroup.markAsPristine();
      });
  }

  private _subscribeRegexError(): void {
    const regexReferentialControl = this.formGroup.get('regexReferential');
    const regexControl = this.formGroup.get('manualRegex');
    merge(
      regexReferentialControl.dirty$,
      regexReferentialControl.touch$,
      regexReferentialControl.status$,
      regexReferentialControl.valueChanges,
      regexControl.dirty$,
      regexControl.touch$,
      regexControl.status$,
      regexControl.valueChanges,
    )
      .pipe(distinctUntilChanged(), takeUntil(this._destroyed$), delay(1))
      .subscribe(() => {
        if (regexControl.value === null) {
          this._isRegexColumnRequired$.next(false);
          this._isRegexColumnIncorrect$.next(false);
          regexControl.setErrors(null);
        } else if (
          (regexControl.dirty || regexControl.touched) &&
          this.hasSelectedManualRegex &&
          !regexControl.value?.toString()?.length
        ) {
          this._isRegexColumnIncorrect$.next(false);
          this._isRegexColumnRequired$.next(true);
          regexControl.setErrors({ error: true });
        } else if (
          (regexControl.dirty || regexControl.touched) &&
          this.hasSelectedManualRegex &&
          this._guardianService.convertInputRegexToRegex(regexControl.value?.toString() || '') === null
        ) {
          this._isRegexColumnRequired$.next(false);
          this._isRegexColumnIncorrect$.next(true);
          regexControl.setErrors({ error: true });
        } else {
          this._isRegexColumnRequired$.next(false);
          this._isRegexColumnIncorrect$.next(false);
          regexControl.setErrors(null);
        }

        this.formGroup.markAsPristine();
      });
  }

  private _subscribeTypeErrors(): void {
    const typeControl = this.formGroup.get('type');
    const minValueControl = this.formGroup.get('minValue');
    const maxValueControl = this.formGroup.get('maxValue');
    const regexControl = this.formGroup.get('regex');
    merge(typeControl.dirty$, typeControl.touch$, typeControl.status$, typeControl.valueChanges)
      .pipe(distinctUntilChanged(), takeUntil(this._destroyed$), delay(1))
      .subscribe(() => {
        switch (typeControl.value) {
          case 'integer':
          case 'float':
            regexControl.setValue('');
            break;
          case 'string':
            minValueControl.setValue(null);
            maxValueControl.setValue(null);
            break;
          case 'dateTime':
          default:
            break;
        }
      });
  }

  private _subscribeDatalakeExternalReferentialError(): void {
    const datalakeInExternalReferentialControl = this.formGroup.get('datalakeInExternalReferential');
    const datalakeNotInExternalReferentialControl = this.formGroup.get('datalakeNotInExternalReferential');
    merge(
      datalakeInExternalReferentialControl.dirty$,
      datalakeInExternalReferentialControl.touch$,
      datalakeInExternalReferentialControl.status$,
      datalakeInExternalReferentialControl.valueChanges,
      datalakeNotInExternalReferentialControl.dirty$,
      datalakeNotInExternalReferentialControl.touch$,
      datalakeNotInExternalReferentialControl.status$,
      datalakeNotInExternalReferentialControl.valueChanges,
    )
      .pipe(distinctUntilChanged(), takeUntil(this._destroyed$), delay(1))
      .subscribe(() => {
        const explorerDatalakePathInControl = this.formGroup.get('datalakePathIn');
        const explorerDatalakePathNotInControl = this.formGroup.get('datalakePathNotIn');
        const hasSelectedDatalakeIn = this.addedConstraintsList.includes('datalake_in');
        const hasSelectedDatalakeNotIn = this.addedConstraintsList.includes('datalake_not_in');

        if (!hasSelectedDatalakeIn) {
          explorerDatalakePathInControl.setValue(null); // reset datalake path
        }

        if (!hasSelectedDatalakeNotIn) {
          explorerDatalakePathNotInControl.setValue(null); // reset datalake path
        }

        if (hasSelectedDatalakeIn && !this.getDatalakeInReferential() && !explorerDatalakePathInControl?.value) {
          this._isDatalakeInReferentialEmpty$.next(true);
          explorerDatalakePathInControl.setErrors({ missingDatalakePathReferentialError: true });
        } else {
          this._isDatalakeInReferentialEmpty$.next(false);
          explorerDatalakePathInControl.setErrors(null);
        }

        if (
          hasSelectedDatalakeNotIn &&
          !this.getDatalakeNotInReferential() &&
          !explorerDatalakePathNotInControl?.value
        ) {
          this._isDatalakeNotInReferentialEmpty$.next(true);
          explorerDatalakePathNotInControl.setErrors({ missingDatalakePathReferentialError: true });
        } else {
          this._isDatalakeNotInReferentialEmpty$.next(false);
          explorerDatalakePathNotInControl.setErrors(null);
        }
      });
  }

  private _getLabelForExternalReferential(externalReferential: APIReferential): string {
    let label = '';
    Array.from(this.snowflakeReferentialsValues).forEach((referentialLabelValueMapping) => {
      if (this.isSnowflakeReferentialEqual(externalReferential, referentialLabelValueMapping.value)) {
        label = referentialLabelValueMapping.label;
      }
    });

    return label;
  }

  private _setAvailableGreaterThanColumnValues(columnFieldsValue: IColumnField[]): void {
    this.availableGreaterThanColumnValues = columnFieldsValue
      .filter((columnFieldValueToCheck) =>
        this._availableGreaterThanColumnNamesFilteringCondition(columnFieldValueToCheck),
      )
      .map((columnFieldValueToCheck) => ({
        label: columnFieldValueToCheck.columnName,
        value: columnFieldValueToCheck.columnId,
      }));
    this._removePotentialForbiddenGreaterThanConstraint();
  }

  get minErrorMessages(): unknown {
    const messageRequired = 'Required';
    const messageIncorrect = this.hasSelectedDate
      ? 'YYYY-MM-DD'
      : this.hasSelectedNumber
      ? 'Number only'
      : 'Decimal only';
    const messageMinMax = 'Min > max';

    return {
      requiredError: messageRequired,
      minValueIncorrectError: messageIncorrect,
      minValueGreaterThantMaxValueError: messageMinMax,
    };
  }

  get maxErrorMessages(): unknown {
    const messageRequired = 'Required';
    const messageIncorrect = this.hasSelectedDate
      ? 'YYYY-MM-DD'
      : this.hasSelectedNumber
      ? 'Number only'
      : 'Decimal only';

    return {
      requiredError: messageRequired,
      maxValueIncorrectError: messageIncorrect,
    };
  }

  get currentSnowflakeReferentialsValuesExtractPreview(): string {
    return this.currentSnowflakeReferentialsValuesExtract?.length
      ? this.currentSnowflakeReferentialsValuesExtract.map((value) => `"${value}"`).join(', ') + '...'
      : 'No value to display';
  }

  get canMoveRowUp(): boolean {
    return this.index !== 0;
  }

  get canMoveRowDown(): boolean {
    return this.index !== this.allColumnsFormArray.length - 1;
  }

  get addedConstraintsControl(): AbstractControl<GuardianCheckConstraint[]> {
    return this.formGroup?.get('addedConstraints');
  }

  get addedConstraintsList(): GuardianCheckConstraint[] {
    return this.addedConstraintsControl?.value || [];
  }

  get availableConstraintsControl(): AbstractControl<GuardianCheckConstraint[]> {
    return this.formGroup?.get('availableConstraints');
  }

  get availableConstraintsList(): GuardianCheckConstraint[] {
    return this.availableConstraintsControl?.value || [];
  }

  onMoveRowUp(): void {
    this.moveUp.emit(this.index);
  }

  onMoveRowDown(): void {
    this.moveDown.emit(this.index);
  }

  addColumnCheckAfterIndex(): void {
    this.onClickAdd.emit(this.index);
  }

  getConstraintLabelFromConstraint(constraint: GuardianCheckConstraint): string {
    return GuardianService.getConstraintLabelFromConstraint(constraint);
  }

  getConstraintTooltipFromConstraint(constraint: GuardianCheckConstraint): string {
    return GuardianService.getConstraintTooltipFromConstraint(constraint);
  }

  getMinPlaceholder(): string {
    if (this.hasSelectedDate) {
      return MinPlaceholder[this.selectedType];
    } else {
      return MinPlaceholder.DEFAULT;
    }
  }

  getMaxPlaceholder(): string {
    if (this.hasSelectedDate) {
      return MaxPlaceholder[this.selectedType];
    } else {
      return MaxPlaceholder.DEFAULT;
    }
  }

  checkValueToAddConstraint() {
    this._subscribeAvailableGreaterThanColumnNames();
  }

  private _removeColumnCheck(): void {
    this.onClickRemove.emit(this.index);
  }

  private _duplicateColumnCheckAfterIndex(): void {
    this.onClickDuplicate.emit(this.index);
  }

  private _hasSnowflakeReferentialConstraint(): boolean {
    return this.addedConstraintsList.includes('mdh');
  }

  private _hasDatalakeReferentialConstraint(): boolean {
    return this.addedConstraintsList.includes('datalake_in') || this.addedConstraintsList.includes('datalake_not_in');
  }

  private _hasDatalakeInReferentialConstraint(): boolean {
    return this.addedConstraintsList.includes('datalake_in');
  }

  private _hasDatalakeNotInReferentialConstraint(): boolean {
    return this.addedConstraintsList.includes('datalake_not_in');
  }

  duplicateOrRemoveColumnCheck(action: string): void {
    if (action === 'remove') {
      this._removeColumnCheck();
    } else if (action === 'duplicate') {
      this._duplicateColumnCheckAfterIndex();
    }
  }

  isPartOfTheAddedConstraints(constraintName: GuardianCheckConstraint): boolean {
    return this.addedConstraintsList.includes(constraintName);
  }

  private _resetInputs(constraint: GuardianCheckConstraint): void {
    switch (constraint) {
      case 'type':
        this.formGroup.get('type')?.setValue(this.columnTypes[0]?.value);
        break;
      case 'minValue':
        this.formGroup.get('minValue')?.setValue(null);
        break;
      case 'maxValue':
        this.formGroup.get('maxValue')?.setValue(null);
        break;
      case 'regex':
        this.formGroup.get('manualRegex')?.setValue(null);
        break;
      case 'greaterThan':
        this.formGroup.get('greaterThanColumnId')?.setValue(null);
        break;
      case 'mdh':
        this.formGroup.get('snowflakeExternalReferential')?.setValue(null);
        break;
      case 'datalake_in':
        this.formGroup.get('datalakeInExternalReferential').setValue(null);
        this.formGroup.get('datalakePathIn').setValue(null);
        break;
      case 'datalake_not_in':
        this.formGroup.get('datalakeNotInExternalReferential').setValue(null);
        this.formGroup.get('datalakePathNotIn').setValue(null);
        break;
      default:
        this.formGroup
          .get(constraint)
          ?.setValue(constraint !== 'isRequired' && constraint !== 'isUnique' ? null : false);
    }
  }

  removeConstraint(constraint: GuardianCheckConstraint): void {
    const updatedAddedConstraintsList = this.addedConstraintsList.filter(
      (addedConstraint) => addedConstraint !== constraint,
    );
    this.addedConstraintsControl.setValue(updatedAddedConstraintsList);
    this._resetInputs(constraint);
    const updatedAvailableConstraintsList = GuardianService.getAvailableConstraintsFromAddedConstraints(
      updatedAddedConstraintsList,
      this.selectedType,
    );
    this.availableConstraintsControl.setValue(updatedAvailableConstraintsList);
  }

  private _updateAvailableConstraints(addedConstraintsList: GuardianCheckConstraint[]): void {
    const updatedAvailableConstraintsList = GuardianService.getAvailableConstraintsFromAddedConstraints(
      addedConstraintsList,
      this.selectedType,
    );
    this.availableConstraintsControl.setValue(updatedAvailableConstraintsList);
  }

  updateAddedAndAvailableConstraints(): void {
    const updatedAddedConstraints = GuardianService.removeForbiddenConstraintsInAddedConstraints(
      this.addedConstraintsList,
      this.selectedType,
    );
    this._updateAvailableConstraints(updatedAddedConstraints);
  }

  addConstraint(constraint: string): void {
    const updatedAddedConstraintsList = [...this.addedConstraintsList, constraint as GuardianCheckConstraint];
    this.addedConstraintsControl.setValue(updatedAddedConstraintsList);
  }

  togglePreparedRegex(): void {
    this.isUsingCustomRegex = !this.isUsingCustomRegex;
  }

  initExternalReferentialValues(): void {
    if (this._hasSnowflakeReferentialConstraint()) {
      this.updateSnowflakeReferentialValues();
    } else if (this._hasDatalakeReferentialConstraint()) {
      this.updateDatalakeReferentialFormat();
    }
  }

  updateSnowflakeReferentialValues(): void {
    this.isLoadingCurrentSnowflakeReferentialsValuesExtract = true;
    this._dbSnowflakeService
      .getColumnExtract(
        this.formGroup.get('snowflakeExternalReferential').value,
        GuardianStepCheckColumnFieldComponent.NB_ROW_EXTERNAL_REFERENTIAL_LIMIT,
      )
      .pipe(takeUntil(this._destroyed$))
      .subscribe((extractedValues) => {
        if (extractedValues) {
          this.currentSnowflakeReferentialsValuesExtract = extractedValues;
          this.isLoadingCurrentSnowflakeReferentialsValuesExtract = false;
        }
      });
  }

  updateDatalakeReferentialFormat(): void {
    const datalakeInReferential = this.getDatalakeInReferential();
    const datalakeNotInReferential = this.getDatalakeNotInReferential();

    if (!datalakeInReferential) {
      this.formGroup.get('datalakeInExternalReferential').markAsDirty();
    }

    if (!datalakeNotInReferential) {
      this.formGroup.get('datalakeNotInExternalReferential').markAsDirty();
    }

    if (!datalakeInReferential && !datalakeNotInReferential) {
      return;
    }

    if (
      this._hasDatalakeInReferentialConstraint() &&
      datalakeInReferential?.datalake?.length &&
      datalakeInReferential?.datalake_params?.azure_storage_account?.length
    ) {
      this.isLoadingCurrentFormattedDatalakeReferentials = true;
      this._datalakeApiService
        .getFormattedObjectPathInfos(
          datalakeInReferential.bucket_name,
          datalakeInReferential.prefix,
          datalakeInReferential.datalake,
          datalakeInReferential.datalake_params?.azure_storage_account,
        )
        .pipe(
          takeUntil(this._destroyed$),
          catchError(() => of(null)),
        )
        .subscribe((formattedPath) => {
          if (formattedPath) {
            this.hasDatalakeReferentialMetadataFetchingWarning = !formattedPath.canFetchMetadata;
            this.formGroup.get('datalakePathIn').setValue({
              provider: formattedPath.provider,
              bucket: formattedPath.bucket.pk,
              path: formattedPath.path,
              tenant: formattedPath.tenant,
            });
          }

          this.isLoadingCurrentFormattedDatalakeReferentials = false;
        });
    }

    if (
      this._hasDatalakeNotInReferentialConstraint() &&
      datalakeNotInReferential?.datalake?.length &&
      datalakeNotInReferential?.datalake_params?.azure_storage_account?.length
    ) {
      this.isLoadingCurrentFormattedDatalakeReferentials = true;
      this._datalakeApiService
        .getFormattedObjectPathInfos(
          datalakeNotInReferential.bucket_name,
          datalakeNotInReferential.prefix,
          datalakeNotInReferential.datalake,
          datalakeNotInReferential.datalake_params?.azure_storage_account,
        )
        .pipe(
          takeUntil(this._destroyed$),
          catchError(() => of(null)),
        )
        .subscribe((formattedPath) => {
          if (formattedPath) {
            this.hasDatalakeReferentialMetadataFetchingWarning = !formattedPath.canFetchMetadata;
            this.formGroup.get('datalakePathNotIn').setValue({
              provider: formattedPath.provider,
              bucket: formattedPath.bucket.pk,
              path: formattedPath.path,
              tenant: formattedPath.tenant,
            });
          }

          this.isLoadingCurrentFormattedDatalakeReferentials = false;
        });
    }
  }

  openReferentialOverviewModal(): void {
    const currentExternalReferential = this.formGroup.get('snowflakeExternalReferential').value as APIReferential;
    this._modalMatService.open(GuardianReferentialOverviewModalComponent, {
      width: '1050px',
      minWidth: '1050px',
      maxHeight: '98vh',
      backdropClass: 'modal-nested',
      data: {
        externalReferential: currentExternalReferential,
        label: this._getLabelForExternalReferential(currentExternalReferential),
      },
    });
  }

  openDatalakeBrowser(isWhitelisted: boolean): void {
    const formattedPath = this.getFormattedDatalakeReferential(isWhitelisted);
    const formattedDatalakePath = new DatalakePath(
      formattedPath?.bucket ? `${formattedPath.bucket}/${formattedPath.path || ''}` : null,
      formattedPath?.provider ? formattedPath.provider : null,
      formattedPath?.tenant ? formattedPath.tenant : null,
    );
    let currentPath;

    if (this.checkType === 'datalakePath') {
      const currentDataset = this.dataset as IGuardianDatalakePath;
      currentPath = DatalakeObjectSelectorModalComponent.datalakePathToFolder(
        formattedPath
          ? formattedDatalakePath
          : new DatalakePath(
              `${currentDataset.filesystem}/${currentDataset.path}`,
              currentDataset.provider as 'aws' | 'azure',
              currentDataset.tenant,
            ),
        formattedPath ? this.isLoadingCurrentFormattedDatalakeReferentials : false,
        formattedPath ? !this.hasDatalakeReferentialMetadataFetchingWarning : true, // once we are here, current path exists
        !!formattedPath,
      );
    } else {
      currentPath = null;
    }

    const datalakeExplorerModal = this._modalMatService.open(DatalakeObjectSelectorModalComponent, {
      width: '1002px',
      minWidth: '1002px',
      maxHeight: '98vh',
      backdropClass: 'modal-nested',
      data: {
        isSelectingFiles: true,
        isMultipleSelect: false,
        isListingUsingOwnToken: false,
        defaultFolder: formattedPath
          ? !this.hasDatalakeReferentialMetadataFetchingWarning
            ? currentPath
            : null
          : currentPath,
        selectedObjects: formattedPath ? [currentPath] : [],
        title: 'Select your Excel/CSV file',
      },
    });
    datalakeExplorerModal
      .afterClosed()
      .pipe(take(1), takeUntil(this._destroyed$))
      .subscribe((selectedPaths: DatalakePath[]) => {
        const datalakePathControl = isWhitelisted
          ? this.formGroup.get('datalakePathIn')
          : this.formGroup.get('datalakePathNotIn');
        const datalakeInExternalReferentialControl = isWhitelisted
          ? this.formGroup.get('datalakeInExternalReferential')
          : this.formGroup.get('datalakeNotInExternalReferential');

        if (selectedPaths?.length) {
          const selectedFullPathSegments = selectedPaths[0].path.split('/');

          if (selectedFullPathSegments.length) {
            const selectedBucket = selectedFullPathSegments.shift();
            const selectedPath = selectedFullPathSegments.join('/');
            const formattedSelectedPath = {
              provider: selectedPaths[0].provider,
              bucket: selectedBucket,
              path: selectedPath,
              tenant: selectedPaths[0].tenant,
            };
            const formattedGuardianPath = {
              bucket_name: `${formattedSelectedPath.tenant}-${formattedSelectedPath.bucket}`,
              prefix: formattedSelectedPath.path,
              // not used
              datalake: null,
              // not used
              datalake_params: {
                azure_storage_account: null,
              },
            };
            datalakePathControl.setValue(formattedSelectedPath);
            datalakeInExternalReferentialControl.setValue(formattedGuardianPath);
            this.hasDatalakeReferentialMetadataFetchingWarning = false;
          }
        }

        datalakePathControl.markAsDirty();
      });
  }

  isDatalakeReferentialEmpty(isWhitelisted: boolean): Observable<boolean> {
    return isWhitelisted ? this.isDatalakeInReferentialEmpty$ : this.isDatalakeNotInReferentialEmpty$;
  }

  redirectToDatalake(isWhitelisted: boolean): void {
    const formattedDatalakeReferential = this.getFormattedDatalakeReferential(isWhitelisted);

    if (formattedDatalakeReferential) {
      this._datalakeService.redirectToDatalake(formattedDatalakeReferential);
    }
  }
}
