import { Component, EventEmitter, Input, Output } from '@angular/core';

import { IDashboardFolder } from '../../entities/dashboard-folder.model';

@Component({
  selector: 'dpg-dashboard-folder-card',
  templateUrl: './dashboard-folder-card.component.html',
  styleUrls: ['./dashboard-folder-card.component.scss'],
})
export class DashboardFolderCardComponent {
  // Attributes
  // Inputs
  @Input() dashboardFolder: IDashboardFolder;
  // Outputs
  @Output() clickExploreFolderAndDisplay = new EventEmitter<IDashboardFolder>();

  exploreFolderAndDisplay(): void {
    this.clickExploreFolderAndDisplay.emit(this.dashboardFolder);
  }
}
