import type { OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import type { IStepperStep } from '@dataportal/adl';

@Component({
  selector: 'adl-logo-modal-header',
  templateUrl: './logo-modal-header.component.html',
  styleUrls: ['./logo-modal-header.component.scss'],
})
export class LogoModalHeaderComponent implements OnInit {
  @Input() title: string;
  @Input() secondaryTitle: string;
  @Input() iconLink: string;
  // secondary part
  @Input() hasSecondaryPart = false;

  // Stepper inputs part
  @Input() hasStepper = false;
  @Input() currentStep = 0;
  @Input() stepLabels: string[] = [];
  @Input() steps: IStepperStep[] = [];

  showStepper: boolean;

  ngOnInit() {
    this.showStepper = this.hasStepper && !!this.steps.length && !this.hasSecondaryPart;
  }
}
