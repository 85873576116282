import type { OnDestroy, OnInit } from '@angular/core';
import { Component, EventEmitter, Inject, Input, Output, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { of, Subject } from 'rxjs';
import { first, map, switchMap, takeUntil } from 'rxjs/operators';
import { ButtonType, DialogsService } from '@dataportal/adl';
import { CurrentUserService } from '@dataportal/auth';
import { FileParserAndFormatterService } from '@dataportal/datalake-parsing';
import type { IGuardianAPIChecksMap } from '@dataportal/front-api';
import { AlertService, Logger } from '@dataportal/front-shared';
import { GuardianService } from '@dataportal/guardian-utils';
import type { CustomerObject, IPrimitive } from '@flatfile/adapter';
import { ISettings } from '@flatfile/adapter';
import type { InputObject } from '@flatfile/adapter/build/main/interfaces/obj.load-options';
import type {
  FieldHookCallback,
  FlatfileResults,
  IDataHookResponse,
  ScalarDictionaryWithCustom,
} from '@flatfile/angular';
import { FlatfileImporter } from '@flatfile/angular';
import * as Papa from 'papaparse';
import * as XLSX from 'xlsx';

import { FlatfileUploadLoaderModalComponent } from '../flatfile-upload-loader/flatfile-upload-loader-modal.component';
import { FlatfileUploadProgressBarModalComponent } from '../flatfile-upload-progress-bar/flatfile-upload-progress-bar-modal.component';
import { FlatfileUploadSheetsSelectModalComponent } from '../flatfile-upload-sheets-select-modal/flatfile-upload-sheets-select-modal.component';

import { FLATFILE_OPTIONS, IFlatfileOptions } from '../../flatfile-options';
import type { IFlatfileUploadSheetsSelectModalData } from '../../types/flatfile';

@Component({
  selector: 'dpg-flatfile-upload-button',
  templateUrl: './flatfile-upload-button.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class FlatfileUploadButtonComponent implements OnInit, OnDestroy {
  // inputs
  @Input() isDisabled = false;
  @Input() settingsFetched: ISettings = null;
  @Input() fieldHooks: Record<string, FieldHookCallback> = {};
  // on record init or change
  @Input() recordHooks: (
    record: ScalarDictionaryWithCustom,
    index: number,
  ) => IDataHookResponse | Promise<IDataHookResponse>;
  @Input() guardianCheckInfos: IGuardianAPIChecksMap['datalakePath'];
  @Input() type: ButtonType = 'primary';

  // Outputs
  @Output() onClickUpload = new EventEmitter<File>();

  // private
  private readonly _destroyed$ = new Subject();

  // constants
  readonly DEFAULT_LANGUAGE = 'en-US';
  readonly FLATFILE_XLSX_FILE_NAME_AND_SHEET_SEPARATOR = '#';
  readonly PROD_ENVS = ['staging', 'prod'];
  readonly GUARDIAN_AUTHORIZED_FILE_TYPES = ['csv', 'xlsx'];
  readonly FLATFILE_AUTHORIZED_FILE_TYPES = [...this.GUARDIAN_AUTHORIZED_FILE_TYPES, 'tsv', 'xls', 'txt'];
  fileTypesInfos = {
    csv: {
      type: 'csv',
      mimeType: 'text/csv',
      extension: '.csv',
    },
    xlsx: {
      type: 'xlsx',
      mimeType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      extension: '.xlsx',
    },
  };

  // global parameters
  licenseKey: string;

  // variables
  isFlatfileImporterReady = false;
  customer: CustomerObject = { userId: '' };
  importer: FlatfileImporter;

  constructor(
    @Inject(FLATFILE_OPTIONS) private readonly _options: IFlatfileOptions,
    private readonly _currentUser: CurrentUserService,
    private readonly _logger: Logger,
    private readonly _alertService: AlertService,
    private readonly _dialogService: DialogsService,
    private readonly _modalMatService: MatDialog,
    private readonly _domSanitizer: DomSanitizer,
    private readonly _fileParserAndFormatterService: FileParserAndFormatterService,
  ) {
    this.licenseKey = this._options.flatfileLicenseKey;
    this._currentUser.currentUser$.pipe(takeUntil(this._destroyed$)).subscribe((user) => {
      this.customer.userId = user.id;
      this.customer.name = user.name;
      this.customer.email = user.id;
      this.customer.companyName = user.adInformation?.companyName || '';
      this.customer.companyId = '';
    });
  }

  ngOnInit(): void {
    this._initFlatfileImporter();
  }

  ngOnDestroy(): void {
    this.importer.destroy();
    this._destroyed$.next();
  }

  // NOTE: if a csv file has less than 10 rows, flatfile will throw a "parsing error" for some reason
  private _convertToFlatfileSettings(settings: ISettings): ISettings {
    return {
      ...settings,
      type: 'Datalake explorer',
      allowInvalidSubmit: true,
      managed: false,
      disableManualInput: false,
      forceManualInput: false,
      allowCustom: false,
      preventAutoTrimming: true,
      title: settings?.title || undefined,
      maxRecords: undefined,
      encoding: 'utf-8',
      dateFormat: 'yyyy-mm-dd',
      devMode: !this.PROD_ENVS.includes(this._options.environment),
      ignoreColumns: undefined,
      i18nOverrides: {
        en: {
          otherLocales: ['en-US', 'en-CA', 'en-GB'],
          overrides: {
            header: '{{number}} rows',
            header2: 'Upload your file',
            rowsFail:
              '{{failed}}% of the analyzed sample has failed validation. Continue to the next step to get all the errors.',
            validationPass:
              'The analyzed sample has been validated. However, some errors may remain. Continue to the next step to continue the analysis.',
          },
        },
      },
      theme: undefined, // TODO : CSS theming
      integrations: undefined,
    };
  }

  private _initFlatfileImporter(): void {
    this.importer = new FlatfileImporter(
      this.licenseKey,
      this._convertToFlatfileSettings(this.settingsFetched),
      this.customer,
    );
    this.importer.setLanguage(this.DEFAULT_LANGUAGE);
    Object.keys(this.fieldHooks).forEach((fieldName) =>
      this.importer.registerFieldHook(fieldName, this.fieldHooks[fieldName]),
    );
    this.importer.registerRecordHook(this.recordHooks);
    this.importer.$ready.then(() => {
      if (this.guardianCheckInfos?.delimiter === ' ') {
        this.isFlatfileImporterReady = false;
        this.displayError(
          'Pre-check importer does not support space delimiter,' +
            ' please specify another delimiter in Guardian check configuration if you want to pre-check.',
        );
      } else {
        this.isFlatfileImporterReady = true;
      }
    });
  }

  displayError(errorMessage: string): void {
    this._logger.debug(errorMessage);
    this._alertService.error(errorMessage);
  }

  invalidGuardianFileTypeReason(): void {
    const errorMessage = this.guardianCheckInfos?.file_format?.length
      ? `Invalid file type in guardian check : '${
          this.guardianCheckInfos.file_format
        }', must be : ${this.GUARDIAN_AUTHORIZED_FILE_TYPES.map((extension) => "'" + extension + "'").join(' or ')}`
      : 'Error in Guardian check configuration : No file type has been provided (but required).';
    this.displayError(errorMessage);
  }

  invalidGuardianDelimiterReason(): void {
    const errorMessage = 'Error in Guardian check configuration : No delimiter has been provided (but required).';
    this.displayError(errorMessage);
  }

  invalidFlatfileFileNameReason(): void {
    const errorMessage = `Error in provided file name or in sheet names, the character '${this.FLATFILE_XLSX_FILE_NAME_AND_SHEET_SEPARATOR}' is forbidden.`;
    this.displayError(errorMessage);
  }

  invalidFlatfileFileTypeReason(): void {
    const errorMessage = `Error in provided file name, the file extension must be ${this.FLATFILE_AUTHORIZED_FILE_TYPES.map(
      (extension) => "'" + extension + "'",
    ).join(' or ')}.`;
    this.displayError(errorMessage);
  }

  invalidFlatfileSheetNameReason(): void {
    const errorMessage = this.guardianCheckInfos?.sheet_name?.length
      ? `Invalid sheet name selected, it should match the provided sheet name in the Guardian configuration : '${this.guardianCheckInfos.sheet_name}'`
      : 'Error in Guardian check configuration : No sheet name has been provided (but required).';
    this.displayError(errorMessage);
  }

  invalidDataReason(): void {
    const errorMessage = 'Error : You are not authorized to submit a file with invalid rows.';
    this.displayError(errorMessage);
  }

  invalidProvidedFileTypeReason(): void {
    const errorMessage =
      'Sorry, an error occurred with the file that you have provided. Please, provide only csv, txt, xls or xlsx files.';
    this.displayError(errorMessage);
  }

  importerNotReadyErrorReason(): void {
    const errorMessage = 'Internal error : The importer is not ready yet.';
    this.displayError(errorMessage);
  }

  encodingErrorReason(fileName: string): void {
    const errorMessage = `The file '${fileName}' could not be decoded. Please, save your sheet as a CSV file and upload it again.`;
    this.displayError(errorMessage);
  }

  formatCorrectFileName(fileName: string, extension: string): string {
    return fileName.replace(/\.[^.]+$/, extension);
  }

  hasSubmittedIncorrectRows(results: FlatfileResults): boolean {
    return results.rawOutput?.some((row) => !row.valid) || false;
  }

  onData(results: FlatfileResults, fileName: string): void {
    // guardian infos
    const guardianCheckFileType = this.guardianCheckInfos.file_format;
    const guardianCheckSheetName = this.guardianCheckInfos.sheet_name;
    const guardianCheckDelimiter = this.guardianCheckInfos.delimiter;

    // controls
    if (!this.GUARDIAN_AUTHORIZED_FILE_TYPES.includes(this.guardianCheckInfos.file_format)) {
      this.invalidGuardianFileTypeReason();

      return;
    }

    if (!this.FLATFILE_AUTHORIZED_FILE_TYPES.includes(this.guardianCheckInfos.file_format)) {
      this.invalidFlatfileFileTypeReason();

      return;
    }

    // getting Flatfile parsed and columns-sorted valid data
    const validData = results?.validData?.length ? results.validData : [];
    const orderedValidData = GuardianService.getOrderedFileDataColumns(
      validData,
      this.guardianCheckInfos.validation_schema_columns,
    );

    // converting for upload
    if (guardianCheckFileType === this.GUARDIAN_AUTHORIZED_FILE_TYPES[1]) {
      // XLSX
      this._logger.debug('[FlatfileUploadButton] Forcing upload to XLSX file');

      if (this.hasSubmittedIncorrectRows(results)) {
        this.invalidDataReason();

        return;
      }

      try {
        const workbook = XLSX.utils.book_new();
        const workSheet = XLSX.utils.aoa_to_sheet(orderedValidData);
        workbook.SheetNames.push(guardianCheckSheetName);
        workbook.Sheets[guardianCheckSheetName] = workSheet;
        const xlsxFileBits = XLSX.write(workbook, { compression: true, bookType: 'xlsx', type: 'array' });
        const name = this.formatCorrectFileName(fileName, this.fileTypesInfos.xlsx.extension);
        const xlsxFile = new File([xlsxFileBits], name, {
          type: this.fileTypesInfos.xlsx.mimeType,
        });
        this.onClickUpload.emit(xlsxFile);
      } catch (e) {
        this.displayError('Error in converting to xlsx file before uploading into the Datalake : ' + e);

        return;
      }
    } else if (guardianCheckFileType === this.GUARDIAN_AUTHORIZED_FILE_TYPES[0]) {
      // CSV
      this._logger.debug('[FlatfileUploadButton] CSV file with delimiter : ', guardianCheckDelimiter);

      if (!this.guardianCheckInfos?.delimiter?.length) {
        this.invalidGuardianDelimiterReason();

        return;
      }

      if (this.hasSubmittedIncorrectRows(results)) {
        this.invalidDataReason();

        return;
      }

      const delimiterToUse =
        guardianCheckDelimiter?.length && guardianCheckDelimiter === ' ' // papaparse does not support space delimiter
          ? ','
          : guardianCheckDelimiter;

      let parsingConfig = {};

      if (results.parsingConfig) {
        parsingConfig = { ...results.parsingConfig };
      }

      parsingConfig = { ...parsingConfig, delimiter: delimiterToUse };

      try {
        const csv = Papa.unparse(orderedValidData, parsingConfig);
        const name = this.formatCorrectFileName(fileName, this.fileTypesInfos.csv.extension);
        const csvFile = new File([csv], name, { type: this.fileTypesInfos.csv.mimeType });
        this.onClickUpload.emit(csvFile);
      } catch (e) {
        this.displayError('Error in converting to csv file before uploading into the Datalake : ' + e);

        return;
      }
    }
  }

  private _openFlatfileImporter(
    fileName: string,
    parsedCSVSheetData?: IPrimitive[][] | string | InputObject[],
    sheetName?: string,
  ): void {
    if (!this.isFlatfileImporterReady) {
      this.importerNotReadyErrorReason();

      return;
    }

    if (!parsedCSVSheetData?.length) {
      this.encodingErrorReason(fileName);

      return;
    }

    const loadOptionsObject = { source: parsedCSVSheetData };
    this.importer.requestDataFromUser(loadOptionsObject).then(
      (results) => {
        const uploadLoader = this._modalMatService.open(FlatfileUploadLoaderModalComponent);
        uploadLoader
          .afterOpened()
          .pipe(takeUntil(this._destroyed$))
          .subscribe(() => {
            this.onData.call(this, results, fileName, sheetName);
            uploadLoader.close();
          });
        this.importer.close();
      },
      () => this.importer.close(),
    );
  }

  openImporter(files: FileList) {
    const filesArray = Array.from(files || []);

    if (!filesArray?.length) {
      return;
    }

    const file = filesArray[0];
    this._dialogService.open(FlatfileUploadProgressBarModalComponent, {
      uploadFile: file,
    });
    const fileName = file.name;
    const fileExt = this._fileParserAndFormatterService.getFileExtension(fileName);

    if (this._fileParserAndFormatterService.checkFileContentType(fileExt, file.type)) {
      this._fileParserAndFormatterService.parseFile(file, fileExt, false, 'csv');
      this._fileParserAndFormatterService.hasFinishedParsingAndFormatting$
        .pipe(
          first((hasFinished) => hasFinished),
          takeUntil(this._destroyed$),
          map(() => {
            if (this._fileParserAndFormatterService.hasErrorInParsing) {
              this._fileParserAndFormatterService.resetParsingStatus(fileExt);
              this.encodingErrorReason(fileName);

              return { hasError: true };
            }
          }),
          switchMap((result) => {
            if (result?.hasError) {
              return of(null);
            }

            if (this._fileParserAndFormatterService.sheetNames.length > 1) {
              const sheetNamesOptions = this._fileParserAndFormatterService.sheetNames.map((sheet) => ({
                label: sheet,
                value: sheet,
              }));

              return this._dialogService.open<IFlatfileUploadSheetsSelectModalData, string>(
                FlatfileUploadSheetsSelectModalComponent,
                {
                  sheetsNamesOptions: sheetNamesOptions,
                },
              );
            } else {
              this._openFlatfileImporter(fileName, this._fileParserAndFormatterService.csvDatasets[0]?.content);
              this._fileParserAndFormatterService.resetParsingStatus(fileExt);

              return of(null);
            }
          }),
        )
        .subscribe((selectedSheetName) => {
          if (selectedSheetName?.length) {
            const parsedSheetData = this._fileParserAndFormatterService.csvDatasets.find(
              (sheet) => sheet.sheetName === selectedSheetName,
            );
            this._openFlatfileImporter(fileName, parsedSheetData.content, selectedSheetName);
            this._fileParserAndFormatterService.resetParsingStatus(fileExt);
          }
        });
    } else {
      this.invalidProvidedFileTypeReason();
    }
  }
}
