import { NgModule } from '@angular/core';
import { AdlModule } from '@dataportal/adl';
import { ApiModule } from '@dataportal/front-api';

import { GlossaryActiveAffiliateService } from './services/glossary-active-affiliates.service';
import { GlossaryComponentsService } from './services/glossary-components.service';
import { GlossaryRolesService } from './services/glossary-roles.service';
import { GlossaryUseCasesService } from './services/glossary-use-cases.service';

import { GlossaryCommentModalComponent } from './components/glossary-comment-modal/glossary-comment-modal.component';

@NgModule({
  imports: [ApiModule, AdlModule],
  declarations: [GlossaryCommentModalComponent],
  providers: [GlossaryComponentsService, GlossaryRolesService, GlossaryUseCasesService, GlossaryActiveAffiliateService],
})
export class GlossaryCommonModule {}
