import { CommonModule, NgOptimizedImage } from '@angular/common';
import type { ModuleWithProviders } from '@angular/core';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AdlModule } from '@dataportal/adl';
import { AlertsModule } from '@dataportal/alerts';
import { SharedModule } from '@dataportal/front-shared';
import { ImpersonateModule } from '@dataportal/impersonate';
import { NotificationsModule } from '@dataportal/notifications';
import { UsersModule } from '@dataportal/users';

import { AdminTopbarComponent } from './admin-topbar/admin-topbar.component';
import { LayoutComponent } from './front-office/layout.component';
import { AdminButtonComponent } from './front-office/navbar/admin-button/admin-button.component';
import { NavbarComponent } from './front-office/navbar/navbar.component';
import { NavbarItemComponent } from './front-office/navbar/navbar-item/navbar-item.component';
import { LayoutV2Component } from './front-office-v2/layout-v2.component';
import { AdminButtonV2Component } from './front-office-v2/navbar/admin-button/admin-button-v2.component';
import { NavbarItemV2Component } from './front-office-v2/navbar/navbar-item/navbar-item-v2.component';
import { NavbarV2Component } from './front-office-v2/navbar/navbar-v2.component';

import type { ILayoutOptions } from './layout_options';
import { LAYOUT_OPTIONS } from './layout_options';

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    UsersModule,
    AlertsModule,
    ImpersonateModule,
    SharedModule,
    AdlModule,
    NotificationsModule,
    NgOptimizedImage,
  ],
  declarations: [
    AdminTopbarComponent,
    LayoutComponent,
    NavbarComponent,
    NavbarItemComponent,
    AdminButtonComponent,
    LayoutV2Component,
    NavbarV2Component,
    NavbarItemV2Component,
    AdminButtonV2Component,
  ],
  providers: [],
  exports: [AdminTopbarComponent, LayoutComponent, NavbarComponent, NavbarV2Component],
})
export class LayoutModule {
  static forRoot(layoutOptions: ILayoutOptions): ModuleWithProviders<LayoutModule> {
    return {
      ngModule: LayoutModule,
      providers: [{ provide: LAYOUT_OPTIONS, useValue: layoutOptions }],
    };
  }
}
