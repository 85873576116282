import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from '@dataportal/front-api';
import type { ISourceDraft } from '@dataportal/types';
import { EntityBuilder } from '@decahedron/entity';

import { SourcesModule } from '../sources.module';

import type { Source } from '../entities/source';
import { SourceDraft } from '../entities/source-draft';

// Service
@Injectable({
  providedIn: SourcesModule,
})
export class SourcesDraftsService {
  // Constructor
  constructor(private readonly _apiService: ApiService) {}

  // Methods
  listDrafts(): Observable<SourceDraft[]> {
    return this._apiService
      .get<ISourceDraft[]>('/v4/drafts')
      .pipe(map((json) => EntityBuilder.buildMany(SourceDraft, json)));
  }

  getDraft(id: string): Observable<SourceDraft> {
    return this._apiService
      .get<ISourceDraft>(`/v4/drafts/${id}`)
      .pipe(map((json) => EntityBuilder.buildOne(SourceDraft, json)));
  }

  createDraft(source: Source): Observable<SourceDraft> {
    return this._apiService
      .post<ISourceDraft>('/v4/drafts', source.toJson())
      .pipe(map((json) => EntityBuilder.buildOne(SourceDraft, json)));
  }

  updateDraft(draft: SourceDraft): Observable<SourceDraft> {
    return this._apiService
      .put<ISourceDraft>(`/v4/drafts/${draft.id}`, draft.toJson())
      .pipe(map((json) => EntityBuilder.buildOne(SourceDraft, json)));
  }

  deleteDraft(id: string): Observable<void> {
    return this._apiService.delete(`/v4/drafts/${id}`);
  }
}
