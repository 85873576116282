<ng-container *ngIf="isFormReady">
  <div class="column-flex w100">

    <div class="row-flex justify-center">
      <adl-text type="accent" [bold]="false" size="12"
      >Here, list the columns that your data should contain and specify the rules it should follow.</adl-text
      >
      <adl-button
        *ngIf="checkType === 'snowflake'"
        class="ml-[20px]"
        type="guardian-light"
        size="normal"
        (clicked)="openPrefillColumns()"
      >
        Prefill table columns
      </adl-button>
    </div>
  </div>
  <div class="column-flex j-center a-center w100 mt30 mb20">
    <adl-guardian-modal-table
      class="table-columns"
      minBodyHeight="300"
      maxBodyHeight="300"
      bodyPaddingBottom="50"
      [hasHoveringAnimation]="false"
      data-cy="guardian-creation-check-table-columns"
    >
      <ng-container table-header>
        <th class="column-name-column-width column-name-column-padding j-start a-start tal">
          <adl-text type="accent" class="w100">COLUMN NAME</adl-text>
        </th>
        <th class="constraint-column j-start a-start tal">
          <adl-text type="accent" class="w100">CONSTRAINT</adl-text>
        </th>
      </ng-container>
      <ng-container table-body>
        <div cdkDropList class="column-fields-list" (cdkDropListDropped)="reorderField($event)">
          <app-guardian-step-check-column-field
            *ngFor="let fieldCheckFormGroup of fieldsChecksFormArray?.controls; let index = index; trackBy: trackByFunction"
            cdkDrag
            class="column-field-box"
            [index]="index"
            [allColumnsFormArray]="fieldsChecksFormArray"
            [formGroup]="fieldCheckFormGroup"
            [dataset]="dataset"
            [formReady]="isFormReady"
            [preparedRegexValues]="preparedRegexValues"
            [snowflakeReferentialsValues]="snowflakeReferentialsValues"
            [isSnowflakeReferentialEqual]="isSnowflakeReferentialEqual"
            [checkType]="checkType"
            (onClickRemove)="removeColumnCheck($event)"
            (onClickAdd)="addColumnCheckAfterIndex($event)"
            (onClickDuplicate)="duplicateColumnCheckAfterIndex($event)"
            (moveUp)="moveUp($event)"
            (moveDown)="moveDown($event)"
          ></app-guardian-step-check-column-field>
        </div>
      </ng-container>
    </adl-guardian-modal-table>
  </div>
</ng-container>
