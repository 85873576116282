import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { AdlModule } from '@dataportal/adl';
import { AuthModule } from '@dataportal/auth';
import { ApiModule } from '@dataportal/front-api';
import { SharedModule } from '@dataportal/front-shared';

import { AccessRequestsModalService } from './services/access-requests-modal.service';
import { AccessRequestsV2Service } from './services/access-requests-v2.service';

import { RequestAccessButtonComponent } from './components/request-access-button/request-access-button.component';
import { ResourceAccessModalComponent } from './components/resource-access-modal/resource-access-modal.component';

@NgModule({
  imports: [MatDialogModule, AdlModule, ApiModule, AuthModule, CommonModule, SharedModule],
  providers: [AccessRequestsV2Service, AccessRequestsModalService, { provide: MAT_DIALOG_DATA, useValue: {} }],
  declarations: [ResourceAccessModalComponent, RequestAccessButtonComponent],
  exports: [RequestAccessButtonComponent],
})
export class AccessRequestsModule {}
