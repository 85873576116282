<div
  (mouseover)="onMouseHover($event)"
  (mouseout)="onMouseOut($event)"
  (click)="onClick($event)"
  class="trigger-wrapper"
  [ngClass]="{
  'w-full': isTooltipTriggerFullWidth,
  'truncate block': truncateTrigger,
  'inline-block': !truncateTrigger
  }"
  #trigger
>
  <ng-content select="[tooltip-trigger]"></ng-content>
</div>
<div
  (mouseover)="onMouseHover($event)"
  (mouseout)="onMouseOut($event)"
  class="tooltip"
  [class]="backgroundColorClassName"
  [ngStyle]="{'max-width': maxContentSize,
              'min-width': minContentSize,
              'padding': fullContent ? '0' : '8px 12px'}"
  [ngClass]="{ 'tooltip-border': hasBorder, 'has-shadow': hasShadow }"
  #tooltip
>
  <ng-content select="[tooltip-content]"></ng-content>
  <div *ngIf="hasArrow" class="tooltip-arrow" data-popper-arrow [class]="arrowColorClassName"></div>
</div>
