import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { ReplaySubject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Logger } from '@dataportal/front-shared';

import { ApiService } from './api.service';

interface ICachedExtract {
  isLocked: boolean;
  expiresAt: number;
  data$: ReplaySubject<unknown | unknown[]>;
}

// Services
@Injectable()
export abstract class APIFetchCachingService<T> {
  static MINUTE_TO_MS_FACTOR = 60 * 1000;

  private readonly _timeOutInMs: number;
  private readonly _cachedExtracts = new Map<string, ICachedExtract>([]);

  // Constructor
  protected constructor(
    private readonly _timeOutInMinutes: number,
    private readonly _apiService: ApiService,
    private readonly _logger: Logger,
  ) {
    this._timeOutInMs = this._timeOutInMinutes * APIFetchCachingService.MINUTE_TO_MS_FACTOR;
  }

  private static _hasExpired(expiresAt: number): boolean {
    if (expiresAt === null || expiresAt === undefined) {
      return true;
    }

    return Date.now() > expiresAt;
  }

  private _getNewExpirationDate(): number {
    return Date.now() + this._timeOutInMs;
  }

  private _getCachedExtract(settings: T): ICachedExtract {
    return this._cachedExtracts?.get(this.cachedKey(settings));
  }

  private _initCachedExtract(settings: T): void {
    const key = this.cachedKey(settings);

    if (!this._cachedExtracts.has(key)) {
      this._cachedExtracts.set(key, {
        isLocked: false,
        expiresAt: null,
        data$: new ReplaySubject<unknown[]>(1),
      });
    }
  }

  private _lockCachedExtract(settings: T): void {
    const key = this.cachedKey(settings);

    if (this._cachedExtracts.has(key)) {
      this._cachedExtracts.get(key).isLocked = true;
    }
  }

  private _setCachedExtract(settings: T, data: unknown | unknown[]): ICachedExtract {
    if (!this._cachedExtracts) {
      return;
    }

    const key = this.cachedKey(settings);
    this._cachedExtracts.get(key).isLocked = false;
    this._cachedExtracts.get(key).expiresAt = this._getNewExpirationDate();
    this._cachedExtracts.get(key).data$.next(data);
  }

  protected getExtract(settings: T): Observable<unknown | unknown[]> {
    this._initCachedExtract(settings);
    const cachedExtract = this._getCachedExtract(settings);

    if (cachedExtract?.isLocked) {
      return cachedExtract?.data$?.asObservable();
    } else {
      const hasExpired = APIFetchCachingService._hasExpired(cachedExtract.expiresAt);

      if (!hasExpired) {
        return cachedExtract?.data$?.asObservable();
      } else {
        this._lockCachedExtract(settings);

        return this._apiService
          .get<unknown | unknown[]>(this.fetchUrl(settings), { errorHandling: { level: 'silent' } })
          .pipe(
            map((extract) => {
              if ((!Array.isArray(extract) && !!extract) || (Array.isArray(extract) && extract?.length)) {
                this._setCachedExtract(settings, extract);
                this._logger.debug(
                  '[API_FETCHING_CATCHING_SERVICE] Cache miss, fetching extract from back with settings : ',
                  settings,
                );

                return extract;
              }

              return [];
            }),
            catchError((error: unknown) => {
              this._logger.error(
                '[API_FETCHING_CATCHING_SERVICE] Error while getting extract with settings : ',
                settings,
                ' ',
                error,
              );
              throw error;
            }),
          );
      }
    }
  }

  protected abstract cachedKey(settings: T): string;
  protected abstract fetchUrl(settings: T): string;
}
