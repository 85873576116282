import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SidebarService {
  private readonly _isVisible$ = new BehaviorSubject<boolean>(true);
  isVisible$ = this._isVisible$.asObservable();

  toggleVisibility() {
    this._isVisible$.next(!this._isVisible$.value);
  }
}
