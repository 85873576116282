<div class="column-flex cursor-pointer">
  <em
    class="fa"
    [class]="getColorClassName() + ' ' + animation || ''"
    [ngClass]="{
      'disabled': disabled,
      'spinning': isIconSpinning,
      'fa-question-circle-o': icon === 'info',
      'fa-exclamation-circle': icon === 'warning',
      'fa-times': icon === 'cross',
      'fa-eye': icon === 'eye',
      'fa-chevron-right': icon === 'chevron-right',
      'fa-share': icon === 'share',
      'fa-external-link': icon === 'new-tab',
      'fa-chevron-left': icon === 'chevron-left',
      'fa-circle-o': icon === 'circle',
      'fa-check-circle-o': icon === 'circle-checked',
      'fa-times-circle-o': icon === 'circle-crossed',
      'fa-download': icon === 'download',
      'fa-wifi': icon === 'wifi',
      'icon-plus': icon === 'plus',
      'icon-cloud-upload': icon === 'upload',
      'fa-files-o': icon === 'copy',
      'fa-clipboard': icon === 'paste',
      'fa-trash-o': icon === 'trash',
      'fa-scissors': icon === 'cut',
      'fa-check': icon === 'check',
      'fa-pencil': icon === 'pencil',
      'fa-id-badge': icon === 'request',
      'fa-history': icon === 'history',
      'fa-question-circle': icon === 'question',
      'fa-shield': icon === 'shield',
      'icon-plus-circle': icon === 'plus-circle',
      'icon-minus': icon === 'minus',
      'fa-lock': icon === 'lock',
      'fa-unlock': icon === 'unlock',
      'fa-stop': icon === 'stop',
      'fa-refresh': icon === 'refresh',
      'fa-play': icon === 'play',
      'fa-flask': icon === 'staging',
      'fa-terminal': icon === 'dev',
      'fa-play-circle': icon === 'prod',
      'fa-cog': icon === 'cog',
      'fa-credit-card': icon === 'business',
      'fa-i-cursor': icon === 'text-cursor',
      'fa-search': icon === 'search',
      'fa-bars': icon === 'menu',
      'fa-folder': icon === 'folder',
      'fa-chevron-down': icon === 'chevron-down',
      'fa-chevron-up': icon === 'chevron-up',
      'fa-long-arrow-down': icon === 'long-arrow-down',
      'fa-long-arrow-up': icon === 'long-arrow-up',
      'fa-angle-double-left': icon === 'angle-double-left',
      'fa-angle-double-right': icon === 'angle-double-right',
      'fa-envelope': icon === 'envelope',
      'fa-clock-o': icon === 'clock',
      'fa-caret-up': icon === 'caret-up',
      'fa-comment-o': icon === 'comment',
      'fa-floppy-o': icon === 'floppy',
      'fa-sharp fa-regular fa-arrow-right': icon === 'arrow-right',
      'fa-sharp fa-regular fa-arrow-left': icon === 'arrow-left'
    }"
    [ngStyle]="{ 'font-size': size + 'px', 'font-weight': bold ? 'bold' : 'normal' }"
  ></em>
</div>
