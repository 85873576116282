import { Directive, ElementRef, Input, NgZone, Renderer2 } from '@angular/core';
import { fromEvent } from 'rxjs';
import { delay, filter, map, switchMap, throttleTime } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import type { Instance } from '@popperjs/core';
import { createPopper } from '@popperjs/core';

@UntilDestroy()
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[copy]',
})
export class CopyClipboardDirective {
  @Input() copy: string | null;

  constructor(
    private readonly _host: ElementRef<HTMLElement>,
    private readonly _zone: NgZone,
    private readonly _renderer: Renderer2,
  ) {}

  ngOnInit() {
    const untilTooltipDestroyed = 2000;
    this._zone.runOutsideAngular(() => {
      fromEvent(this._host.nativeElement, 'click')
        .pipe(
          filter(() => this.copy !== null),
          throttleTime(untilTooltipDestroyed),
          switchMap(() => navigator.clipboard.writeText(this.copy)),
          map(() => {
            const tooltip = this._createTooltip();
            const instance = this._createInstanceFor(tooltip);

            return [tooltip, instance];
          }),
          delay(untilTooltipDestroyed),
          untilDestroyed(this),
        )
        .subscribe(([tooltip, instance]: [HTMLSpanElement, Instance]) => {
          this._renderer.removeChild(this._host, tooltip);
          instance.destroy();
        });
    });
  }

  private _createTooltip(): HTMLSpanElement {
    const tooltip: HTMLSpanElement = this._renderer.createElement('span');
    const text = this._renderer.createText('Copied');

    this._renderer.setAttribute(
      tooltip,
      'style',
      `padding: 0.5em;
    background-color: #6296e2;
    font-size: 0.8em;
    color: white;
    border-radius: 0.3em;`,
    );

    this._renderer.appendChild(tooltip, text);
    this._renderer.appendChild(this._host.nativeElement.parentElement, tooltip);

    return tooltip;
  }

  private _createInstanceFor(tooltip: HTMLSpanElement): Instance {
    return createPopper(this._host.nativeElement, tooltip, {
      placement: 'top',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 10],
          },
        },
      ],
    });
  }
}
