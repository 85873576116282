import type { CdkDropList } from '@angular/cdk/drag-drop';
import type { AfterViewInit } from '@angular/core';
import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

const MARGIN_TOP_PIXELS = 30;

@Component({
  selector: 'adl-show-more-wrapper',
  templateUrl: './show-more-wrapper.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShowMoreWrapperComponent implements AfterViewInit {
  @Input() dropLists: CdkDropList[] = [];

  @Input() dropListId = 'show-more-drag-list';

  @Output() showMore = new EventEmitter<void>();

  @ViewChild('container') container: ElementRef<HTMLElement>;

  @ViewChild('showMoreButton') showMoreButton: ElementRef<HTMLElement>;

  private readonly _isOverflowing$ = new BehaviorSubject<boolean>(false);

  private readonly _offset$ = new BehaviorSubject<number>(0);

  private _maxHeight = 0;

  readonly marginFromBottom = 250;

  readonly offset$ = this._offset$.asObservable();

  readonly isOverflowing$ = this._isOverflowing$.asObservable();

  readonly currentMaxHeight$ = this.offset$.pipe(
    map((offset) => {
      const height = `calc((100vh - ${this._maxHeight}px) * ${offset + 1})`;

      return height;
    }),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  ngAfterViewInit(): void {
    const headerElement = document.getElementById('pr-dataportal--header');
    this._maxHeight = headerElement.getBoundingClientRect().height + MARGIN_TOP_PIXELS;
  }

  showMoreItems(shouldScroll = true): void {
    this._offset$.next(this._offset$.value + 1);

    setTimeout(() => {
      const showMoreButtonPosition = this.showMoreButton?.nativeElement?.getBoundingClientRect();

      if (showMoreButtonPosition) {
        const top = showMoreButtonPosition.top + window.scrollY;

        if (shouldScroll) {
          window.scrollTo({
            top,
            behavior: 'smooth',
          });
        }
      }
    }, 0);
  }

  onIsOverflowing(isOverflowing: boolean): void {
    this._isOverflowing$.next(isOverflowing);
  }

  resetOffset() {
    this._offset$.next(0);
  }
}
