import type { AfterContentChecked, OnDestroy, OnInit } from '@angular/core';
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { interval, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import type { Source } from '@dataportal/sources-dashboards-recommendations';
import { SourcesService } from '@dataportal/sources-dashboards-recommendations';

import { CatalogV2SearchService } from '../../../services/v2/search.service';

@Component({
  selector: 'dpg-catalog-searchbar',
  templateUrl: './catalog-searchbar.component.html',
  styleUrls: ['./catalog-searchbar.component.scss'],
})
export class CatalogSearchbarComponent implements OnInit, OnDestroy, AfterContentChecked {
  @Input() searchControl: FormControl;
  @Input() placeholder = '';
  @Input() updateURLQuery = true;
  @Input() filterSources = true;
  @Input() showDropdown = false;
  @Input() showResultsInDropdown = false;

  @Output() searchAction = new EventEmitter();
  @Output() searchControlChange = new EventEmitter();

  searchMode: 'advanced' | 'simple';
  allSources: Source[] = [];
  interval = interval(1500);
  suggestedSources: Source[] = [];
  totalSourcesNumber: number;
  searchBarWidth = '0';
  lastSourcesClickedIds: string[];
  lastSourcesClicked: Source[] = [];
  isLoadingSources = true;

  private readonly _destroyed$ = new Subject<void>();

  @ViewChild('searchBar', { read: ElementRef }) searchBarElement: ElementRef;

  constructor(
    private readonly _sourcesService: SourcesService,
    private readonly _router: Router,
    private readonly _searchService: CatalogV2SearchService,
  ) {}

  ngOnInit() {
    this.lastSourcesClickedIds = this._searchService.parseRecentlySearched();

    // once the sources are loaded, get recent searched sources from user's localstorage
    this._sourcesService
      .allSources()
      .pipe(takeUntil(this._destroyed$))
      .subscribe((allSources) => {
        this.allSources = allSources;
        this.lastSourcesClicked = this._searchService.makeRecentlySearchedSourcesList(
          this.allSources,
          this.lastSourcesClickedIds,
        );
      });

    this.interval.pipe(takeUntil(this._destroyed$)).subscribe(() => {
      this.lastSourcesClickedIds = this._searchService.parseRecentlySearched();
      this.lastSourcesClicked = this._searchService.makeRecentlySearchedSourcesList(
        this.allSources,
        this.lastSourcesClickedIds,
      );
    });

    this.searchControl.valueChanges.pipe(debounceTime(300), takeUntil(this._destroyed$)).subscribe((query) => {
      this.searchControlChange.emit(query);

      if (!query) {
        this.suggestedSources = [];
      }

      if (this.filterSources) {
        this.isLoadingSources = true;
        this._searchService.updateQuery(query);
      }

      // change the URL automatically if the user changes the search term
      if (this.updateURLQuery) this._router.navigate([], { queryParams: { q: query } }).then();
    });

    this._searchService.searchMode$.pipe(takeUntil(this._destroyed$)).subscribe((mode: 'advanced' | 'simple') => {
      this.searchMode = mode;
    });

    // limit the suggestions to 3
    this._searchService.filteredSources$.pipe(takeUntil(this._destroyed$)).subscribe((sources) => {
      if (this.searchControl.value) {
        this.suggestedSources = sources.slice(0, 3);
        this.totalSourcesNumber = sources.length;
        this.isLoadingSources = false;
      }
    });
  }

  ngAfterContentChecked() {
    this.searchBarWidth = this.searchBarElement ? this.searchBarElement.nativeElement.offsetWidth + 'px' : '0px';
  }

  ngOnDestroy() {
    this._destroyed$.next();
  }

  navigateToSearch() {
    this._router
      .navigate(['catalog/search'], {
        queryParams: { q: this.searchControl.value },
      })
      .then();
  }

  closeDropdown() {
    // Close the dropdown!
    this.searchBarElement.nativeElement.click();
  }
}
