import type { AfterViewInit } from '@angular/core';
import { Directive, ElementRef, EventEmitter, Input, NgZone, Output } from '@angular/core';

@Directive({
  selector: '[adlIsOverflowing]',
})
export class IsOverflowingDirective implements AfterViewInit {
  private _isOverflowing = false;

  @Input() marginFromBottom = 0;

  @Output() isOverflowing = new EventEmitter<boolean>();

  @Output() closeToBottom = new EventEmitter<void>();

  private readonly _resizeObserver = new ResizeObserver((entries) =>
    this._zone.runOutsideAngular(() => this._handleResize(entries)),
  );

  constructor(private readonly _container: ElementRef<HTMLElement>, private readonly _zone: NgZone) {}

  ngAfterViewInit() {
    this._resizeObserver.observe(this._container.nativeElement);
  }

  private _handleResize(entries: ResizeObserverEntry[]): void {
    const { height } = entries[0].contentRect;
    const { scrollHeight } = entries[0].target as HTMLElement;

    const isOverflowing = scrollHeight > Math.ceil(height);

    const isCloseToBottom = this.marginFromBottom > 0 && scrollHeight - height - this.marginFromBottom <= 0;

    if (isCloseToBottom) {
      this._isOverflowing = false;
      this.isOverflowing.emit(isOverflowing);
      this.closeToBottom.emit();

      return;
    }

    if (this._isOverflowing !== isOverflowing) {
      this._isOverflowing = isOverflowing;
      this.isOverflowing.emit(isOverflowing);
    }
  }
}
