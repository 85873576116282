<div
  *ngIf="this.guardianStatus?.isChecked && this.guardianStatus?.checkInfos"
  class="flex flex-col justify-center items-center w-full"
>
  <adl-text class="flex flex-col w-full text-left" type="accent" [bold]="false" size="12"
    >Here is the list of of columns and rules that your data should follow.</adl-text
  >
  <div class="flex flex-col justify-center items-center w-full mt-8 mb-6">
    <adl-guardian-modal-table
      class="table-columns"
      minBodyHeight="300"
      maxBodyHeight="400"
      bodyPaddingBottom="50"
      [hasHoveringAnimation]="false"
      data-cy="guardian-check-infos-table-columns"
    >
      <ng-container table-header>
        <th class="column-index"></th>
        <th class="column-name-column-width column-name-column-padding text-left">
          <adl-text type="accent" class="w-full">COLUMN NAME</adl-text>
        </th>
        <th class="constraint-column separator-left text-left">
          <adl-text type="accent" class="w-full">CONSTRAINT</adl-text>
        </th>
      </ng-container>
      <ng-container table-body>
        <ng-container *ngFor="let column of formattedDatasSchemaWithExtractedColumn; let index = index">
          <tr class="separator-box">
            <td class="column-index p-0"></td>
            <td class="column-name-column-width p-0"></td>
            <td class="p-0"></td>
          </tr>
          <dpg-guardian-check-infos-column-field
            class="separator-box"
            [resource]='guardianStatus?.type'
            [columnField]="column"
            [index]="index"
            [allCheckRegex]="allCheckRegex"
            [externalReferentials]="externalReferentials"
          ></dpg-guardian-check-infos-column-field>
        </ng-container>
      </ng-container>
    </adl-guardian-modal-table>
  </div>
</div>
