import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { AlertService, Logger } from '@dataportal/front-shared';
import type { IGuardianStatus } from '@dataportal/guardian-utils';
import { GuardianService } from '@dataportal/guardian-utils';

import { ExplorerService } from './explorer.service';
import { ItrackService } from './itrack.service';

import { ItrackBusinessSteps, ItrackStatus } from '../models/itrack.model';

import type { IDatalakeUploadProgress } from '../entities/datalake';

@Injectable()
export class UploadBrowserService {
  constructor(
    private readonly _logger: Logger,
    private readonly _explorerService: ExplorerService,
    private readonly _guardian: GuardianService,
    private readonly _itrackService: ItrackService,
    private readonly _alertService: AlertService,
  ) {}

  uploadProgress: IDatalakeUploadProgress[] = [];
  guardianStatus: IGuardianStatus<'datalakePath'>;

  isLoadingGuardianStatus = true;

  get isCurrentPathCheckedByGuardian(): boolean {
    return !this.isLoadingGuardianStatus && this.guardianStatus?.isChecked;
  }

  get isCurrentPathNotCheckedByGuardian(): boolean {
    return !this.isLoadingGuardianStatus && !this.guardianStatus?.isChecked;
  }

  get isFlatfileDeactivate(): boolean {
    return this.guardianStatus?.isFlatfileDeactivate;
  }

  subscribeToUpload(upload$: Observable<IDatalakeUploadProgress>, filesToUpload: File[]): void {
    upload$.subscribe(
      (progress) => {
        const toUpdate = this.uploadProgress.find((p) => p.name === progress.name);

        if (toUpdate) {
          toUpdate.progress = progress.progress;
          toUpdate.status = progress.status;
        } else {
          this.uploadProgress.push(progress);
        }
      },
      (err: unknown) => {
        this._alertService.error(`Error uploading file`);
        this._logger.error('[DatalakeBrowserComponent] error uploading files', err);
        this.uploadProgress = [];
      },
      () => {
        this.uploadProgress = [];

        if (this.isCurrentPathCheckedByGuardian) {
          const fileNameRegex = this.guardianStatus?.checkInfos?.regex
            ? new RegExp(this.guardianStatus.checkInfos.regex)
            : null;

          if (
            filesToUpload.length === 1 &&
            (!fileNameRegex || (fileNameRegex && fileNameRegex.test(filesToUpload[0].name)))
          ) {
            if (this.guardianStatus?.isItrackEnabled) {
              this._itrackService.updateFlow(ItrackBusinessSteps.CHECK_TRIGGERED, ItrackStatus.IN_PROGRESS);
            }

            this._guardian.requestCheck(this.guardianStatus.checkId, 'datalakePath', null, false, (error) =>
              this._itrackService.addFlowError({
                code: error.status,
                description: error.statusText,
                additionalInfo: error.message,
                details: error.url,
              }),
            );
          }
        }
      },
    );
  }

  onUploadFileChange(files: FileList): void {
    this._logger.debug('[DatalakeBrowserComponent] Preparing to upload', files);
    const filesArray = Array.from(files);

    const filesToUpload = filesArray.map((file) => {
      if (!file.webkitRelativePath) {
        return file;
      }

      const newFile = new File([file], file.webkitRelativePath, { type: file.type });

      return newFile;
    });

    if (this.guardianStatus?.isItrackEnabled) {
      const upload$ = this._itrackService.createFlowForNewFile(files[0]).pipe(
        mergeMap((file: File) => {
          return this._explorerService.upload([file]);
        }),
      );

      this.subscribeToUpload(upload$, filesToUpload);

      return;
    }

    this.subscribeToUpload(this._explorerService.upload(filesToUpload), filesToUpload);
  }
}
