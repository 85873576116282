import { Entity } from '@decahedron/entity';

// Types
export type LinkType = 'download' | 'dashboard';

// Entity
export class Link extends Entity {
  // Attributes
  url?: string = null;
  type?: LinkType = null;

  // Methods
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  toJson() {
    return {
      url: this.url || null,
      type: this.type,
    };
  }
}
