import { Component, Input } from '@angular/core';

@Component({
  selector: 'adl-file-icon',
  templateUrl: './file-icon.component.html',
})
export class FileIconComponent {
  @Input() displayObject: { name: string; type: string };

  private _extension: string;

  ngOnInit(): void {
    this._extension = this.displayObject.name.split('.').pop();
  }

  get extension(): string {
    return this._extension;
  }

  get displayObjectType(): string {
    return this.displayObject.type;
  }
}
