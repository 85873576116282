import type { OnDestroy, OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { of, Subject } from 'rxjs';
import { catchError, first, takeUntil } from 'rxjs/operators';
import { DatalakeObjectExistenceCacheService } from '@dataportal/datalake-and-guardian';
import type { IObjectExistenceResult } from '@dataportal/front-shared';
import { PermissionsService } from '@dataportal/permissions';
import { FolderSelectorModalComponent, Source, SourcesService } from '@dataportal/sources-dashboards-recommendations';

@Component({
  selector: 'dpg-source-data-access',
  templateUrl: './source-data-access.component.html',
  styleUrls: ['./source-data-access.component.scss'],
})
export class SourceDataAccessComponent implements OnInit, OnDestroy {
  @Input() source: Source;

  isLoadingDatalakePath = true;
  isSingleDatalakePathExistingStatus = true;

  private readonly _destroyed$ = new Subject<void>();

  constructor(
    private readonly _sourcesService: SourcesService,
    private readonly _permissionsService: PermissionsService,
    private readonly _modalMatService: MatDialog,
    private readonly _datalakeObjectExistenceCacheService: DatalakeObjectExistenceCacheService,
    private readonly _router: Router,
  ) {}

  canDownload: boolean;

  ngOnInit(): void {
    this._permissionsService.isAuthorized('explore', 'sources', this.source.id).subscribe((canDownload) => {
      this.canDownload = canDownload;
    });

    if (this.source?.datalakePath?.length === 1) {
      this._datalakeObjectExistenceCacheService
        .getExtract(this.source.datalakePath[0])
        .pipe(
          takeUntil(this._destroyed$),
          first(),
          catchError(() => {
            this.isLoadingDatalakePath = false;

            return of(null as IObjectExistenceResult);
          }),
        )
        .subscribe((objectExistencyResult) => {
          this.isSingleDatalakePathExistingStatus = !!objectExistencyResult?.isExisting;
          this.isLoadingDatalakePath = false;
        });
    } else {
      this.isLoadingDatalakePath = false;
    }
  }

  ngOnDestroy() {
    this._destroyed$.next();
  }

  get isDatalakePathButtonReady(): boolean {
    return !this.isLoadingDatalakePath && this.isSingleDatalakePathExistingStatus;
  }

  accessData(): void {
    if (!this.isDatalakePathButtonReady) {
      return;
    }

    if (this.hasInternalDatalake) {
      const visibleDatalakePath = this.source.datalakePath.filter((path) => !path.isHidden);

      if (visibleDatalakePath.length > 1) {
        this._modalMatService.open(FolderSelectorModalComponent, {
          width: '900px',
          minWidth: '900px',
          maxHeight: '90vh',
          backdropClass: 'modal-backdrop',
          data: {
            source: this.source,
          },
        });
      } else if (visibleDatalakePath.length === 1) {
        const path = visibleDatalakePath[0];
        const bucketName = path.path.split('/')[0];
        const pathRemaining = path.path.split('/').slice(1).join('/');
        const url = this._router
          .createUrlTree(['datalake', bucketName], {
            queryParams: {
              path: pathRemaining.replace(/\/$/, ''),
              provider: path.provider,
              tenant: path.tenant,
            },
          })
          .toString();

        window.open(url, '_blank');
      }
    } else if (this.hasExternalDatalake) {
      window.open(this._getExternalDownloadURL(), '_blank');
    }
  }

  private _openInternalDatalakeDownloadModal(): void {
    this._sourcesService.openDownloadModal(this.source);
  }

  get hasInternalDatalake(): boolean {
    return (
      this._hasDatalake() &&
      (this.source.datalakeProvider === 'azure' ||
        this.source.datalakeProvider === 'aws' ||
        !this.source.datalakeProvider)
    );
  }

  get hasExternalDatalake(): boolean {
    return this._hasExternalDownloadUrl();
  }

  get buttonLabel(): string {
    if (this.hasInternalDatalake) {
      const visibleDatalakePath = this.source.datalakePath;

      return `Access Data (${visibleDatalakePath.filter((path) => !path.isHidden).length})`;
    } else {
      return 'External download link';
    }
  }

  private _hasExternalDownloadUrl(): boolean {
    const links = this.source.externalLinks.filter((link) => link.type === 'download');

    return links.length > 0 && links[0].url && links[0].url.length > 0;
  }

  private _hasDatalake(): boolean {
    const visibleDatalakePath = this.source.datalakePath;

    return visibleDatalakePath.filter((path) => !path.isHidden).length > 0;
  }

  private _getExternalDownloadURL(): string {
    if (this.source.externalLinks.filter((link) => link.type === 'download').length > 0) {
      return this.source.externalLinks.filter((link) => link.type === 'download')[0].url;
    }
  }
}
