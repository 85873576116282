import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ApiService } from '@dataportal/front-api';
import type { ISourceCategory as SourceCategoryBack } from '@dataportal/types';
import { EntityBuilder } from '@decahedron/entity';

import { CategoriesModule } from '../categories.module';

import type { ISourceCategory, SourcesCategoriesMap } from '../entities/category';
import { CustomCategory, RootCategory, SourcesCategories } from '../entities/category';

@Injectable({
  providedIn: CategoriesModule,
})
export class CategoriesService {
  constructor(private readonly apiService: ApiService) {
    this._listRoot().subscribe((rootCategories) => {
      this._rootCategories$.next(rootCategories);
    });
    this._currentPortal$.subscribe((portal) => {
      if (portal) {
        this._listCustom(portal).subscribe((categories) => {
          this._customCategories$.next(categories);
        });
      }
    });
  }
  private readonly _rootCategories$: BehaviorSubject<RootCategory[]> = new BehaviorSubject([]);

  private readonly _customCategories$: BehaviorSubject<CustomCategory[]> = new BehaviorSubject([]);

  private readonly _currentPortal$: BehaviorSubject<string> = new BehaviorSubject(null);

  private readonly _sourcesCategories$: BehaviorSubject<SourcesCategoriesMap> = new BehaviorSubject(new Map());

  customCategories$ = this._customCategories$.asObservable();
  rootCategories$ = this._rootCategories$.asObservable();
  currentPortal$ = this._currentPortal$.asObservable();
  sourceCategories$ = this._sourcesCategories$.asObservable();

  private _listRoot(): Observable<RootCategory[]> {
    return this.apiService
      .get<RootCategory[]>('/v4/categories')
      .pipe(map((response) => EntityBuilder.buildMany<RootCategory>(RootCategory, response)));
  }

  findCategory(id: string): RootCategory | CustomCategory {
    const correspondingRootCategory = this._rootCategories$.getValue().find((o) => o.id === id);

    return correspondingRootCategory
      ? correspondingRootCategory
      : this._customCategories$.getValue().find((o) => o.id === id);
  }

  findByName(name: string): RootCategory | CustomCategory {
    const correspondingRootCategory = this._rootCategories$
      .getValue()
      .find((rootCategory) => rootCategory.name === name);

    return correspondingRootCategory
      ? correspondingRootCategory
      : this._customCategories$.getValue().find((customCategory) => customCategory.name === name);
  }

  getColor(rootCategoriesId: string[], localCategories: ISourceCategory[]): string {
    // Find color of first custom category that have one
    if (localCategories && Array.isArray(localCategories) && localCategories.length > 0) {
      for (const localCategory of localCategories) {
        if (localCategory.color != null) {
          return localCategory.color;
        }
      }
    }

    // If there is not, do the same with root categories
    if (rootCategoriesId && Array.isArray(rootCategoriesId) && rootCategoriesId.length > 0) {
      for (const categoryId of rootCategoriesId) {
        const category = this.findCategory(categoryId);

        if (category && category.color != null) {
          return category.color;
        }
      }
    }

    // Fallback on grey if no categories have colors
    return 'default';
  }

  setPortal(portalId: string): void {
    this._currentPortal$.next(portalId);
  }

  private _listCustom(portalId: string): Observable<CustomCategory[]> {
    return this.apiService
      .get<CustomCategory[]>(`/v4/categories/portals/${portalId}`)
      .pipe(map((response) => EntityBuilder.buildMany<CustomCategory>(CustomCategory, response)));
  }

  addCustomCategory(category: { name: string; color: string }, portalId: string): Observable<void> {
    return this.apiService.post(`/v4/categories/portals/${portalId}`, category);
  }

  removeCustomCategory(categoryId: string, portalId: string): Observable<void> {
    return this.apiService.delete(`/v4/categories/${categoryId}/portals/${portalId}`);
  }

  resetSourcesCategories(): void {
    this._sourcesCategories$.next(new Map());
  }

  listSourcesCategories(portalId: string): Observable<SourcesCategories> {
    return this.apiService.get<SourceCategoryBack[]>(`/v4/categories/portals/${portalId}/sources`).pipe(
      map((response) => EntityBuilder.buildOne<SourcesCategories>(SourcesCategories, response)),
      tap((response) => {
        this._sourcesCategories$.next(response.items);
      }),
    );
  }
}
