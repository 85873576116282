import { Component, Input } from '@angular/core';

@Component({
  selector: 'adl-loaded-item',
  templateUrl: './loaded-item.component.html',
  styleUrls: ['./loaded-item.component.scss'],
})
export class LoadedItemComponent {
  // Mandatory
  @Input() loading: boolean;
  @Input() loaderType: 'adl' | 'da' = 'adl';

  // Optional
  @Input() private set iconSize(value: number) {
    this.daAnimationSize = `${value}px`;
    this.adlAnimationSize = `${value + this.unit}`;
  }
  @Input() unit: '%' | 'px' | 'rem' = 'px';
  @Input() loadingText: string;

  daAnimationSize = '90rem';
  adlAnimationSize = '120px';

  daLoaderPath = '/assets/dpg/animations/loader/data.json';
}
