import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AdlModule } from '@dataportal/adl';
import { ApiModule } from '@dataportal/front-api';
import { SharedModule } from '@dataportal/front-shared';

import { RelationshipsService } from './services/relationships.service';
import { SourceLinksService } from './services/source-links.service';

// Module
@NgModule({
  declarations: [],
  imports: [CommonModule, ApiModule, SharedModule, AdlModule],
  providers: [SourceLinksService, RelationshipsService],
  exports: [],
})
export class RelationshipsModule {}
