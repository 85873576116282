<div class="flex flex-row py-1.5 w-full">
    <div
    *ngFor="let tab of tabsTilteList; let i = index"
    [attr.id]="'sliding-tab-'+tab+i"
    [class.active]="activeTab === tab"
    class="py-2 text-black text-center sliding-tab-header"
    [ngClass]="i === 0 ? 'flex-none w-[90px]': 'flex-auto'"
    (click)="tabClicked(i)"
    >
        {{tab}}
        <span 
            *ngIf="tabsPastilleList && tabsPastilleList[i] !== 0"
            [class.active]="activeTab === tab"
            class="inline-block ml-1 w-6 h-6 rounded-full sliding-tab-pastille"
        >
            {{tabsPastilleList[i]}}
        </span>
    </div>
</div>
<div [attr.id]="'tabSlider'" class="w-[90px] sliding-tab-slider"></div>
<ng-content></ng-content>

