import type { OnInit } from '@angular/core';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import type { INotificationV2 } from '@dataportal/notifications';
import { INotificationV2Display } from '@dataportal/notifications';
import { BackNotificationType, FeatureRequestStatus } from '@dataportal/types';
import { UsersService } from '@dataportal/users';

@Component({
  selector: 'dpg-notification-content',
  templateUrl: './notification-content.component.html',
  styleUrls: ['./notification-content.component.scss'],
})
export class NotificationContentComponent implements OnInit {
  @Input() content: INotificationV2Display;

  @Output() onClick = new EventEmitter<INotificationV2Display>();
  statusColor: string;

  notificationText = '';
  notificationSubText = '';

  constructor(private readonly _usersService: UsersService) {}

  ngOnInit() {
    switch (this.content.status) {
      case 'pending':
      case 'CANDIDATE':
      case FeatureRequestStatus.UNDER_REVIEW:
        this.statusColor = 'yellow';
        break;
      case 'COMPLETED':
      case FeatureRequestStatus.PLANNED:
        this.statusColor = 'primary-300';
        break;
      case 'TO COMPLETE':
        this.statusColor = 'tertiary-700';
        break;
      case 'approved':
      case 'validated':
      case 'VALIDATED':
      case FeatureRequestStatus.COMPLETED:
        this.statusColor = 'green';
        break;
      case FeatureRequestStatus.REJECTED:
      default:
        this.statusColor = 'red';
        break;
    }

    switch (this.content.type) {
      case 'recommendation':
        this.notificationText = this.content.mainMessage;
        this.notificationSubText = this.content.message;
        break;
      case 'accessRequest':
        this.notificationText = `${this.content.notifierName} has requested access to ${this.content.resourceRequestedName}`;
        break;
      case 'publication':
        this.notificationText = `${this.content.notifierName} wants to publish ${this.content.resourceRequestedName} on ${this.content.secondaryResourceName}`;
        break;
      case 'dashboardComment':
        this.notificationText = `${this.content.notifierName} commented the dashboard ${this.content.resourceRequestedName}`;
        this.notificationSubText = this.content.message;
        break;
      case 'dashboardCommentReply':
        this.notificationText = `${this.content.notifierName} replied to your comment on ${this.content.resourceRequestedName} dashboard :`;
        this.notificationSubText = this.content.message;
        break;
      case 'glossary':
        this.notificationText = this.getNotificationMessage(this.content);
        break;
      case BackNotificationType.DA_OWNER:
        this.notificationText = `${this.content.notifierName} has sent you a comment on ${this.content.resourceRequestedName}`;
        this.notificationSubText = this.content.message;
        break;
      case BackNotificationType.DA_TAGGED:
        this.notificationText = `${this.content.notifierName} has tagged you on a comment on ${this.content.resourceRequestedName}`;
        this.notificationSubText = this.content.message;
        break;
      case BackNotificationType.DA_FR_OWNER:
        this.notificationText = `${this.content.notifierName} has sent you a feature request on ${this.content.resourceRequestedName}`;
        this.notificationSubText = this.content.message;
        break;
      case BackNotificationType.DA_FR_TAGGED:
        this.notificationText = `${this.content.notifierName} has tagged you on a feature request on ${this.content.resourceRequestedName}`;
        this.notificationSubText = this.content.message;
        break;
      case BackNotificationType.DA_FR_UPDATED:
        this.notificationText = `Your feature request on ${this.content.resourceRequestedName} has changed status`;
        break;
      case BackNotificationType.DA_REPLIED:
        this.notificationText = `${this.content.notifierName} replied to your comment on ${this.content.resourceRequestedName}`;
        this.notificationSubText = this.content.message;
        break;
      case BackNotificationType.DA_FR_REPLIED:
        this.notificationText = `${this.content.notifierName} replied to your feature request on ${this.content.resourceRequestedName}`;
        this.notificationSubText = this.content.message;
        break;
      case BackNotificationType.DA_REPLIED_TAGGED:
        this.notificationText = `${this.content.notifierName} has tagged you in a reply on ${this.content.resourceRequestedName}`;
        this.notificationSubText = this.content.message;
        break;
      case BackNotificationType.DA_FR_REPLIED_TAGGED:
        this.notificationText = `${this.content.notifierName} has tagged you on the feature request on ${this.content.resourceRequestedName}`;
        this.notificationSubText = this.content.message;
        break;
    }
  }

  clickNotification(): void {
    this.onClick.emit(this.content);
  }

  getTimeInterval(creationDate: Date): string {
    const actualDate = new Date();
    const daysBetween = Math.round((actualDate.getTime() - creationDate.getTime()) / (24 * 60 * 60 * 1000));
    const hoursBetween = Math.round((actualDate.getTime() - creationDate.getTime()) / (60 * 60 * 1000));
    const minutesBetween = Math.ceil((actualDate.getTime() - creationDate.getTime()) / (60 * 1000));

    if (daysBetween >= 1) {
      return daysBetween + 'd ago';
    } else if (hoursBetween >= 1) {
      return hoursBetween + 'h ago';
    } else if (minutesBetween > 0) {
      return minutesBetween + 'min ago';
    }
  }

  getNotificationMessage(content: INotificationV2): string {
    if (content.type === 'glossary') {
      switch (content.status) {
        case 'CANDIDATE':
          return `${content?.notifierName} has submitted a new item ${content?.resourceRequestedName} in the Business Glossary that requires your approval`;
        case 'REJECTED':
          return `${content?.notifierName} has rejected your submission ${content?.resourceRequestedName} in the Business Glossary.`;
        case 'TO COMPLETE':
          return `${content?.notifierName} has refused the completed the item ${content?.resourceRequestedName} in the Business Glossary. Some information is required.`;
        case 'VALIDATED':
          return `${content?.notifierName} has approved the item ${content?.resourceRequestedName} in the Business Glossary.`;
        case 'COMPLETED':
          return `${content?.notifierName} has completed the item ${content?.resourceRequestedName} in the Business Glossary and requires your approval.`;
      }
    }
  }
}
