import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApiService } from '@dataportal/front-api';
import { AlertService } from '@dataportal/front-shared';

import type { IDocumentation, IVideo } from '../models/documentation.model';
import type { ITrainingCatalog } from '../models/training-catalog.model';
import type { IGetCatalogUserViewsModel, IGetUserDocumentationView, IUserView } from '../models/user-view.model';

@Injectable({
  providedIn: 'root',
})
export class UserViewService {
  constructor(private readonly _api: ApiService, private readonly _notify: AlertService) {}

  getView$(id: IDocumentation['id'], videoId: IVideo['id']): Observable<IGetUserDocumentationView> {
    if (isNaN(id)) throw Error('[getViewsByDocumentation] Incorrect query parameters');

    return this._api.get<IGetUserDocumentationView>('v4/data-academy/user-view', {
      queryStringParameters: {
        documentationId: id.toString(),
        videoId: videoId?.toString(),
      },
    });
  }

  updateCreateView$(
    userViewId: IUserView['id'],
    docId: IDocumentation['id'],
    videoId: IVideo['id'],
    isViewed: boolean,
  ): Observable<IGetUserDocumentationView> {
    if (isNaN(docId)) throw Error('[getViewsByDocumentation] Incorrect query parameters');

    return this._api
      .post<IGetUserDocumentationView>(`v4/data-academy/user-view`, {
        documentationId: docId.toString(),
        videoId: videoId?.toString(),
        userViewId: userViewId?.toString(),
        isViewed,
      })
      .pipe(
        catchError((e: unknown) => {
          this._notify.error(`Cannot mark documentation as ${isViewed ? 'viewed' : 'not viewed'} `);

          return throwError(e);
        }),
      );
  }

  isAllCatalogSelfLearningRead$(catalogId: ITrainingCatalog['id']): Observable<IGetCatalogUserViewsModel> {
    if (isNaN(catalogId)) throw Error('[isAllCatalogSelfLearningRead] Incorrect query parameters');

    return this._api.get<IGetCatalogUserViewsModel>(`v4/data-academy/user-view/catalog/${catalogId}`).pipe(
      catchError((e: unknown) => {
        this._notify.error(`Cannot get self learnings views`);

        return throwError(e);
      }),
    );
  }
}
