import type { ComponentType } from '@angular/cdk/overlay';
import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable, Injector } from '@angular/core';

import { DialogRef } from '../shared/components/dialog/dialog-ref';
import { DIALOG_DATA } from '../shared/components/dialog/dialog-token';

type BackdropType = 'medium' | 'light' | 'dark';
type PanelSize = 'medium' | 'small' | 'large';
type ResizeStrategy = 'flex';

export interface IDialogConfig<T> {
  data?: T;
  backdropType?: BackdropType;
  panelSize?: PanelSize;
  resizeStrategy?: ResizeStrategy;
}

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(private readonly overlay: Overlay, private readonly injector: Injector) {}

  open<T, D>(component: ComponentType<T>, config?: IDialogConfig<D>): DialogRef {
    // Globally centered position strategy
    const positionStrategy = this.overlay.position().global().centerHorizontally().centerVertically();

    const overlayRef = this.overlay.create({
      positionStrategy,
      hasBackdrop: true,
      backdropClass: `da-overlay-${config?.backdropType ?? 'medium'}-backdrop`,
      panelClass: `da-overlay-${config?.panelSize ?? 'large'}-panel${
        config.resizeStrategy ? `-${config.resizeStrategy}` : ''
      }`,
      disposeOnNavigation: true,
    });

    const dialogRef = new DialogRef(overlayRef);

    const injector = Injector.create({
      parent: this.injector,
      providers: [
        { provide: DialogRef, useValue: dialogRef },
        { provide: DIALOG_DATA, useValue: config?.data },
      ],
    });

    const portal = new ComponentPortal(component, null, injector);
    overlayRef.attach(portal);

    overlayRef.backdropClick().subscribe(() => {
      dialogRef.close();
    });

    return dialogRef;
  }
}
