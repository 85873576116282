<div class="guardian--step-check-triggers column-flex justify-center mt-[10px] mb-[10px] w60" *ngIf="this.isFormReady">
  <adl-form-control
    *ngIf="this.formGroup.get('hasToUseItrack')"
    class="mb0"
    label="Do you want to use Itrack?"
    [inline]="true"
    [control]="this.formGroup.get('hasToUseItrack')"
  >
    <adl-switch [control]="this.formGroup.get('hasToUseItrack')"
                (changed)="onItrackOptionChanged($event)"
    ></adl-switch>
  </adl-form-control>
  <adl-form-control
    *ngIf="this.formGroup.get('hasToMoveIfSuccess')"
    class="mt35 mb0"
    label="Do you want to move your file in case of success?"
    [inline]="true"
    [control]="this.formGroup.get('hasToMoveIfSuccess')"
    hint="If the targeted file pass the set-up check, the file will be moved into
     the specified folder path in the SAME bucket and data platform as set for the current path"
  >
    <adl-switch [control]="this.formGroup.get('hasToMoveIfSuccess')"
                [disabled]="this.formGroup.get('hasToUseItrack').value"
                data-cy="guardian-creation-check-move-success-switch"
    ></adl-switch>
  </adl-form-control>
  <adl-form-control
    *ngIf="this.hasSelectedMoveSuccess"
    class="mt0"
    label="Target folder"
    [control]="this.formGroup.get('successPath')"
    [errorMessages]="{ successPathRequired: 'Target folder is required.' }"
    required
  >
    <div class="row-flex-not-responsive w100 a-center j-between">
      <adl-text *ngIf="this.formGroup?.get('successPath')?.value?.length" class="w75 tal wb-all" data-cy="guardian-creation-check-target-success-folder">{{
        this.formGroup.get('successPath').value
      }}</adl-text>
      <adl-input
        *ngIf="!this.formGroup?.get('successPath')?.value?.length"
        class="w75"
        isDisabled="true"
        [control]="this.formGroup.get('successPath')"
        placeholder="E.g. /path/target/folder/success"
      ></adl-input>
      <adl-button (clicked)="openDatalakeExplorer(true)" class="w20" data-cy="guardian-creation-check-explore-success">Explore</adl-button>
    </div>
  </adl-form-control>

  <adl-form-control
    *ngIf="this.formGroup.get('hasToMoveIfFailure')"
    class="mt35 mb0"
    label="Do you want to move your file in case of error?"
    [inline]="true"
    [control]="this.formGroup.get('hasToMoveIfFailure')"
    hint="If the targeted file does not pass the set-up check (at least one error), the file will be moved into
     the specified folder path in the SAME bucket and data platform as set for the current path"
    hintType='primary'
  >
    <adl-switch [control]="this.formGroup.get('hasToMoveIfFailure')"
                [disabled]="this.formGroup.get('hasToUseItrack').value"
                data-cy="guardian-creation-check-move-failure-switch"></adl-switch>
  </adl-form-control>
  <adl-form-control
    *ngIf="this.hasSelectedMoveFailure"
    class="mt0"
    label="Target folder"
    [control]="this.formGroup.get('failurePath')"
    [errorMessages]="{ failurePathRequired: 'Target folder is required.' }"
    required
  >
    <div class="row-flex-not-responsive w100 a-center j-between">
      <adl-text *ngIf="this.formGroup?.get('failurePath')?.value?.length" class="w75 tal wb-all" data-cy="guardian-creation-check-target-fail-folder">{{
        this.formGroup.get('failurePath').value
      }}</adl-text>
      <adl-input
        *ngIf="!this.formGroup?.get('failurePath')?.value?.length"
        class="w75"
        isDisabled="true"
        [control]="this.formGroup.get('failurePath')"
        placeholder="E.g. /path/target/folder/error"
      ></adl-input>
      <adl-button (clicked)="openDatalakeExplorer(false)" class="w20" data-cy="guardian-creation-check-explore-failure">Explore</adl-button>
    </div>
  </adl-form-control>

  <ng-container *ngIf="this.hasSelectedMove">
    <adl-form-control
      class="mt35 mb0"
      label="Do you want to enforce file replacement?"
      [inline]="true"
      [control]="this.formGroup.get('hasToEnforceReplacementIfSameName')"
      hint="If yes, the potential file with the same file name in the destination folder (success path or failure path) will be overwritten"
    >
      <adl-switch [control]="this.formGroup.get('hasToEnforceReplacementIfSameName')"></adl-switch>
    </adl-form-control>
    <adl-form-control
      class="mt0"
      label="Activate automatic destination file renaming"
      [inline]="true"
      [control]="this.formGroup.get('hasToRenameDestinationFile')"
      hint="If yes, when moving the file in the destination folder (success path or failure path), the file will be renamed with the current
     timestamp as a suffix in order to ensure file name uniqueness ('my-file.csv' will for example be renamed to 'my-file_2021-06-29_12-56-48.csv')"
    >
      <adl-switch [control]="this.formGroup.get('hasToRenameDestinationFile')"
                  [disabled]="this.formGroup.get('hasToUseItrack').value"
                  data-cy="guardian-creation-check-rename-switch"
      ></adl-switch>
    </adl-form-control>
  </ng-container>

  <adl-form-control
    *ngIf="this.formGroup.get('hasToGenerateResultFile')"
    class="mt35 mb0"
    label="Generate the result file ?"
    [inline]="true"
    [control]="this.formGroup.get('hasToGenerateResultFile')"
    hint="If yes, if any error has been detected, in addition of recording the Guardian check result in a history (viewable on the Datalake),
    a CSV file containing the check result (named 'my-file-YYYY-MM-DD HH:MM:SS-results.csv') will also be generated inside a folder (named '.guardian_results')"
  >
    <adl-switch [control]="this.formGroup.get('hasToGenerateResultFile')"></adl-switch>
  </adl-form-control>

  <adl-form-control
    *ngIf="this.formGroup.get('hasToScheduleChecks')"
    class="mt35"
    [label]="this.checkType === 'datalakePath' ? 'Do you ALSO want to periodically run checks on the latest file in the directory?' : 'Do you want to schedule Guardian executions'"
    [inline]="true"
    alignCentered
    [control]="this.formGroup.get('hasToScheduleChecks')"
    hintType='primary'
    hint="Specify a period of time in which the Guardian checks are run"
  >
    <adl-switch [control]="this.formGroup.get('hasToScheduleChecks')"></adl-switch>
  </adl-form-control>
  <div *ngIf="this.hasSelectedScheduling" class="column-flex j-start a-start w100 mt0">
    <div class="row-flex j-start a-center w100">
      <app-guardian-form-control
        class="w40"
        label="Periodicity"
        [isLabelBold]="false"
        [hasToAlignLeft]="true"
        leftPartWidthClass="w30"
        rightPartWidthClass="w70"
        [control]="this.schedulingFormGroup.get('schedulingPeriodicity')"
      >
        <adl-select
          class="row-flex-not-responsive j-start w100"
          [options]="this.guardianService.schedulingPeriodicityIndex"
          [control]="this.schedulingFormGroup.get('schedulingPeriodicity')"
          [defaultValue]="this.schedulingFormGroup.get('schedulingPeriodicity')?.value"
          [fixedSize]="this.controlsFixedSize"
        ></adl-select>
      </app-guardian-form-control>
      <div class="row-flex j-start a-center w60 pl20">
        <adl-icon class="w5" icon="clock" size="30" iconType="accent"></adl-icon>
        <app-guardian-form-control
          class="w30 pl5"
          label="at"
          [isLabelBold]="false"
          leftPartWidthClass="w30"
          rightPartWidthClass="w70"
          spaceBetween="pl5"
          [control]="this.schedulingStartingTimeFormGroup.get('schedulingStartingHour')"
        >
          <adl-select
            class="row-flex-not-responsive j-start w100"
            [options]="this.guardianService.hoursMap"
            [control]="this.schedulingStartingTimeFormGroup.get('schedulingStartingHour')"
            [defaultValue]="this.schedulingStartingTimeFormGroup.get('schedulingStartingHour')?.value"
            [fixedSize]="this.controlsFixedSize"
          ></adl-select>
        </app-guardian-form-control>
        <app-guardian-form-control
          class="row-flex j-start a-center w20 pl5"
          label=":"
          [isLabelBold]="true"
          [hasToAlignLeft]="true"
          leftPartWidthClass="w5"
          rightPartWidthClass="w95"
          spaceBetween="pl5"
          [control]="this.schedulingStartingTimeFormGroup.get('schedulingStartingMinute')"
          [errorMessages]="{
            startingMinuteRequired: '',
            startingMinuteMustBePositiveNumber: '',
            startingMinuteMustBeAMultipleOf5: '',
            startingMinuteMustBeMax59: ''
          }"
        >
          <adl-input
            class="w100"
            [step]="this.minuteMultipleOf.toString()"
            [max]="this.minuteMaxValue.toString()"
            [min]="this.minuteMinValue.toString()"
            type="number"
            [control]="this.schedulingStartingTimeFormGroup.get('schedulingStartingMinute')"
            fixedSize="10px"
            placeholder="30"
          >
          </adl-input>
        </app-guardian-form-control>
        <app-guardian-form-control
          class="w25 pl5"
          [isLabelBold]="false"
          leftPartWidthClass="w0"
          rightPartWidthClass="w100"
          spaceBetween="pl0"
          [control]="this.schedulingStartingTimeFormGroup.get('schedulingStartingHourPeriod')"
        >
          <adl-select
            class="row-flex-not-responsive j-start w100"
            [options]="this.guardianService.hourPeriodsMap"
            [control]="this.schedulingStartingTimeFormGroup.get('schedulingStartingHourPeriod')"
            [defaultValue]="this.schedulingStartingTimeFormGroup.get('schedulingStartingHourPeriod')?.value"
            [fixedSize]="this.controlsFixedSize"
          ></adl-select>
        </app-guardian-form-control>
        <adl-text class="w20 pl5 tal" type="accent" [bold]="false">UTC+0</adl-text>
      </div>
    </div>
    <div [ngSwitch]="this.selectedSchedulingPeriodicity" class="column-flex j-start a-start w100 mt5">
      <div *ngSwitchCase="'daily'" class="column-flex j-start a-start w100">
        <adl-radio-content-selector
          class="column-flex-not-responsive j-start w100"
          [choices]="this.schedulingDailyChoices"
          [activeChoice]="this.schedulingDailyChoiceSelected"
          (changed)="this.updateSchedulingDailyChoiceSelected($event)"
        >
          <div class="negative-margin row-flex j-start a-center w100" *radioContentSelectorMarker>
            <div class="column-flex j-start a-center w100">
              <div class="row-flex j-start a-center w100">
                <app-guardian-form-control
                  class="row-flex j-start a-center w20"
                  label="Every"
                  [isLabelBold]="false"
                  [hasToAlignLeft]="true"
                  leftPartWidthClass="w40"
                  rightPartWidthClass="w60"
                  spaceBetween="pl5"
                  [control]="this.schedulingFormGroup.get('schedulingDailyEveryNumberOfDays')"
                  [errorMessages]="{
                    dailyEveryNumberOfDaysRequired: '',
                    dailyEveryNumberOfDaysMustBeStrictlyPositiveNumber: '',
                    dailyEveryNumberOfDaysMustBeMax31: ''
                  }"
                >
                  <adl-input
                    class="w100"
                    type="number"
                    [control]="this.schedulingFormGroup.get('schedulingDailyEveryNumberOfDays')"
                    fixedSize="10px"
                    placeholder="1"
                  >
                  </adl-input>
                </app-guardian-form-control>
                <adl-text class="row-flex j-start a-center w20 pl10 tal" type="accent" [bold]="false"
                  >day(s)
                  <adl-info-bubble iconType='primary' class="ml10">
                    <div class="column-flex w100">
                      <adl-text type="tooltip" size="10" class="w100 tal"
                        >It depends on the number of days of the current month and the day counter is reset every
                        month.</adl-text
                      >
                      <adl-text type="tooltip" size="10" class="w100 mt10 tal"
                        >Example 1: If current month has 31 days and scheduling is set to every 3 days, it will be
                        triggered on days : 1, 4, 7, 10, 13, 16, 19, 22, 25, 28, 31, 1 of next month, 4 of next month,
                        etc.</adl-text
                      >
                      <adl-text type="tooltip" size="10" class="w100 mt10 tal"
                        >Example 2: If current month has 30 days and scheduling is set to every 3 days, it will be
                        triggered on days : 1, 4, 7, 10, 13, 16, 19, 22, 25, 28, 1 of next month, 4 of next month,
                        etc.</adl-text
                      >
                      <adl-text type="tooltip" size="10" class="w100 mt10 tal"
                        >Example 3: If current month has 31 days and scheduling is set to every 14 days, it will be
                        triggered on days : 1, 15, 29, 1 of next month, 15 of next month, etc.</adl-text
                      >
                    </div>
                  </adl-info-bubble>
                </adl-text>
              </div>
              <div
                *ngIf="
                  this.hasDailyEveryNumberOfDaysRequiredError ||
                  this.hasDailyEveryNumberOfDaysMustBeStrictlyPositiveNumberError ||
                  this.hasDailyEveryNumberOfDaysMustBeMax31Error
                "
                class="row-flex j-start a-center w100"
              >
                <div class="row-flex j-start a-center w20">
                  <div class="w40"></div>
                  <adl-text color="red" class="w60 pl5 tal">{{
                    this.hasDailyEveryNumberOfDaysRequiredError
                      ? 'Required'
                      : this.hasDailyEveryNumberOfDaysMustBeStrictlyPositiveNumberError
                      ? '> 0'
                      : this.hasDailyEveryNumberOfDaysMustBeMax31Error
                      ? '<= 31'
                      : ''
                  }}</adl-text>
                </div>
              </div>
            </div>
          </div>
          <div class="row-flex j-start a-center w100 tal" *radioContentSelectorMarker>
            <adl-text class="row-flex j-start a-center tal" type="accent" [bold]="false">Every work day</adl-text
            ><adl-info-bubble iconType='primary' class="ml5">Monday to Friday</adl-info-bubble>
          </div>
        </adl-radio-content-selector>
      </div>

      <div *ngSwitchCase="'weekly'" class="column-flex j-start a-start w100">
        <adl-form-control
          class="column-flex j-start mt15 w85"
          label="Every week on:"
          [isLabelBold]="false"
          [control]="this.schedulingFormGroup.get('schedulingWeeklySelectedDays')"
          [errorMessages]="{ atLeastOneSelectedDayRequired: 'Please choose at least one' }"
        >
          <adl-checkboxes
            class="tal"
            [checkboxes]="this.weekDaysIndex"
            [control]="this.schedulingFormGroup.get('schedulingWeeklySelectedDays')"
            labelType="accent"
            [isLabelBold]="false"
            [isColumnFlex]="true"
            heightForColumnFlex="100px"
            marginTop="5px"
          ></adl-checkboxes>
        </adl-form-control>
      </div>

      <div *ngSwitchCase="'monthly'" class="column-flex j-start a-start w100">
        <div class="column-flex j-start a-center w100">
          <div class="row-flex j-start a-center w100 mt15">
            <app-guardian-form-control
              class="row-flex j-start a-center w20"
              label="On the"
              [isLabelBold]="false"
              [hasToAlignLeft]="true"
              leftPartWidthClass="w45"
              rightPartWidthClass="w55"
              spaceBetween="pl5"
              [control]="this.schedulingFormGroup.get('schedulingMonthlyDayNumber')"
              [errorMessages]="{
                monthlyDayNumberRequired: '',
                monthlyDayNumberMustBeStrictlyPositiveNumber: '',
                monthlyDayNumberMustBeMax31: ''
              }"
            >
              <adl-input
                class="w100"
                type="number"
                [control]="this.schedulingFormGroup.get('schedulingMonthlyDayNumber')"
                fixedSize="10px"
                placeholder="5"
              >
              </adl-input>
            </app-guardian-form-control>
            <div class="row-flex j-start a-center w40 ml10">
              <app-guardian-form-control
                class="row-flex j-start a-center w45"
                label="every"
                [isLabelBold]="false"
                [hasToAlignLeft]="true"
                leftPartWidthClass="w40"
                rightPartWidthClass="w60"
                spaceBetween="pl0"
                [control]="this.schedulingFormGroup.get('schedulingMonthlyEveryNumberOfMonths')"
              >
                <adl-select
                  class="w100 ml10"
                  [options]="this.guardianService.monthNumbersIndex"
                  [control]="this.schedulingFormGroup.get('schedulingMonthlyEveryNumberOfMonths')"
                  [defaultValue]="this.schedulingFormGroup.get('schedulingMonthlyEveryNumberOfMonths')?.value"
                  [isUsingFirstValueAsDefault]="
                    !this.schedulingFormGroup.get('schedulingMonthlyEveryNumberOfMonths')?.value
                  "
                  fixedSize="100%"
                ></adl-select>
              </app-guardian-form-control>
              <adl-text class="row-flex j-start a-center w50 pl10 tal" type="accent" [bold]="false"
                >month(s)
                <adl-info-bubble class="ml10"
                  ><div class="column-flex w100">
                    <adl-text type="tooltip" size="10" class="w100 tal"
                      >The month counter is reset every year.</adl-text
                    >
                    <adl-text type="tooltip" size="10" class="w100 mt10 tal"
                      >Example : If scheduling is set to every 3 months, it will be triggered on months : january,
                      april, july, october, january of next year, april of next year, etc.</adl-text
                    >
                  </div>
                </adl-info-bubble>
              </adl-text>
            </div>
          </div>
          <div
            class="row-flex j-start a-center w100"
            *ngIf="
              this.hasMonthlyDayNumberRequiredError ||
              this.hasMonthlyDayNumberMustBeStrictlyPositiveNumberError ||
              this.hasMonthlyDayNumberMustBeMax31Error
            "
          >
            <div class="row-flex j-start a-center w20">
              <div class="w45"></div>
              <adl-text color="red" class="w55 pl5 tal">{{
                this.hasMonthlyDayNumberRequiredError
                  ? 'Required'
                  : this.hasMonthlyDayNumberMustBeStrictlyPositiveNumberError
                  ? '> 0'
                  : this.hasMonthlyDayNumberMustBeMax31Error
                  ? '<= 31'
                  : ''
              }}</adl-text>
            </div>
          </div>
        </div>
      </div>

      <div *ngSwitchCase="'yearly'" class="column-flex j-start a-start w100">
        <div class="column-flex j-start a-center w100">
          <div class="row-flex j-start a-center w100 mt15">
            <app-guardian-form-control
              class="row-flex j-start a-center w35"
              label="Every year on the"
              [isLabelBold]="false"
              [hasToAlignLeft]="true"
              leftPartWidthClass="w65"
              rightPartWidthClass="w35"
              spaceBetween="pl5"
              [control]="this.schedulingFormGroup.get('schedulingYearlyDayNumber')"
              [errorMessages]="{
                yearlyDayNumberRequired: '',
                yearlyDayNumberMustBeStrictlyPositiveNumber: '',
                yearlyDayNumberOfMonthsMustBeMax12: ''
              }"
            >
              <adl-input
                class="w100"
                type="number"
                [control]="this.schedulingFormGroup.get('schedulingYearlyDayNumber')"
                fixedSize="10px"
                placeholder="1"
              >
              </adl-input>
            </app-guardian-form-control>
            <adl-select
              class="w25 ml10"
              [options]="this.guardianService.yearMonthsIndex"
              [control]="this.schedulingFormGroup.get('schedulingYearlySelectedMonth')"
              [defaultValue]="this.schedulingFormGroup.get('schedulingYearlySelectedMonth')?.value"
              [isUsingFirstValueAsDefault]="!this.schedulingFormGroup.get('schedulingYearlySelectedMonth')?.value"
              fixedSize="100%"
            ></adl-select>
          </div>

          <div
            class="row-flex j-start a-center w100"
            *ngIf="
              this.hasYearlyDayNumberRequiredError ||
              this.hasYearlyDayNumberMustBeStrictlyPositiveNumberError ||
              this.hasYearlyDayNumberMustBeMax31Error
            "
          >
            <div class="row-flex j-start a-center w35">
              <div class="w65"></div>
              <adl-text color="red" class="w35 pl5 tal">{{
                this.hasYearlyDayNumberRequiredError
                  ? 'Required'
                  : this.hasYearlyDayNumberMustBeStrictlyPositiveNumberError
                  ? '> 0'
                  : this.hasYearlyDayNumberMustBeMax31Error
                  ? '<= 31'
                  : ''
              }}</adl-text>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
