export interface IAuthErrorReport {
  origin: string;
  method?: string;
  reason: unknown;
}

const serializeAuthErrorReport = (report: IAuthErrorReport): string => {
  try {
    return JSON.stringify(report);
  } catch (e) {
    return JSON.stringify({ origin: report.origin, method: report.method, reason: 'Error serializing reason' });
  }
};

export const sendErrorReport = async (baseUrl: string, report: IAuthErrorReport, key: string) => {
  try {
    const response = await fetch(baseUrl + '/v4/telemetry', {
      method: 'post',
      headers: {
        'X-Api-Key': key,
      },
      body: serializeAuthErrorReport(report),
    });
    // eslint-disable-next-line no-restricted-syntax
    console.info('Error report sent', response.status);
  } catch (e) {
    console.error('Cannot send error report', e);
  }
};
