import { InjectionToken } from '@angular/core';

// Constants
export const SHARED_OPTIONS = new InjectionToken<ISharedOptions>('dataportal-front:shared:options');

// Types
export interface IMetadataToDisplay {
  name: string;
  label: string;
}

export interface ISharedOptions {
  // Options
  environment: string;
  internalUserEmail: RegExp;
  prUserEmail: RegExp;
  adminV2HelpLink: string;
  sourceHelp: string;
  adminDatalakeSupportLink: string;
  datalakeMetadataToDisplay: IMetadataToDisplay[];
  leon: {
    proxyBaseUrl: string;
  };
}
