import { Injectable } from '@angular/core';
import type { CanActivate, CanLoad } from '@angular/router';
import { Router } from '@angular/router';
import type { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CurrentUserService } from '@dataportal/auth';

import { PermissionsModule } from '../permissions.module';

// Guard
@Injectable({
  providedIn: PermissionsModule,
})
export class AdminOnlyGuard implements CanActivate, CanLoad {
  // Constructor
  constructor(private readonly _currentUserService: CurrentUserService, private readonly _router: Router) {}

  private _canAccess(): Observable<boolean> {
    return this._currentUserService.isAdmin$.pipe(tap((isAdmin) => isAdmin || this._router.navigate(['error'])));
  }

  // Methods
  canActivate(): Observable<boolean> {
    return this._canAccess();
  }

  canLoad(): Observable<boolean> {
    return this._canAccess();
  }
}
