import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { BehaviorSubject, from, ReplaySubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { MultiAuthService } from '@dataportal/auth';
import { AlertService, Logger } from '@dataportal/front-shared';
import type { IAuthToken } from '@dataportal/msal';
import { Navigator } from '@dataportal/navigator';
import type { IEmbedConfiguration } from 'embed';
import type { Page, Report, service, VisualDescriptor } from 'powerbi-client';
import { models } from 'powerbi-client';
import { SectionVisibility, TokenType } from 'powerbi-models';

import { DashboardsService } from './dashboards.service';
import { DashboardsCubeService } from './dashboards-cube.service';
import { DashboardsLogsService } from './dashboards-logs.service';

import { PowerBiReportType } from '../entities/dashboard.model';

import type { Source } from '../../sources/entities/source';
import type { Dashboard, ITableField } from '../entities/dashboard.model';
import { PowerBiService } from './power-bi.factory';

// Types
interface IConfiguration {
  embed: IEmbedConfiguration;
  token?: string;
  expiration?: Date;
}

export type PageNames = Pick<Page, 'name' | 'displayName'>;
export type VisualAndSelectedValuesByFieldMap = Map<
  ITableField,
  { slicer: VisualDescriptor; slicerState: models.ISlicerState; values: unknown[] }
>;

export interface ISlicerWithSlicerState {
  field: ITableField;
  slicer: VisualDescriptor;
  state: {
    filters: models.ISlicerFilter[];
    targets?: models.SlicerTarget[];
  };
}

// Constants
const FORWARDED_EVENTS = [
  'loaded',
  'saved',
  'rendered',
  'saveAsTriggered',
  'dataSelected',
  'buttonClicked',
  'filtersApplied',
  'pageChanged',
  'commandTriggered',
  'swipeStart',
  'swipeEnd',
  'bookmarkApplied',
  'dataHyperlinkClicked',
];

// Service
@Injectable()
export class PowerBiEmbedService {
  // Attributes
  private _report?: Report;
  private _element: HTMLElement;
  private _source: Source;
  private _dashboard: Dashboard;
  private _currentUserId: string;
  private _tokenExpiration: Date;
  private _defaultPowerBiFiltersSelectedValues: VisualAndSelectedValuesByFieldMap;

  private readonly _eventsHandled = new Set<string>();
  private readonly _pendingListeners = new Map<string, Set<service.IEventHandler<unknown>>>();

  private readonly _pages$ = new BehaviorSubject<PageNames[]>([]);
  pages$ = this._pages$.asObservable();

  private _currentPage: Page | null;
  private readonly _currentPage$ = new ReplaySubject<Page>(1);
  currentPage$ = this._currentPage$.asObservable();

  private readonly _events$ = new ReplaySubject<string>(1);
  events$ = this._events$.asObservable();

  private readonly _maxRetriesOnError = 3;

  private _isMobile: boolean;
  private _isCubeStarting: boolean;
  private _isCubeRestarted: boolean;
  private readonly _timestamps: {
    init?: number;
    loaded?: number;
    lastPageChange?: number;
    rendered?: number;
  } = {};

  // Constructor
  constructor(
    private readonly _multiAuthService: MultiAuthService,
    private readonly _logger: Logger,
    private readonly _alertService: AlertService,
    private readonly _navigator: Navigator,
    private readonly _dashboardsService: DashboardsService,
    private readonly _cubeService: DashboardsCubeService,
    private readonly _logsService: DashboardsLogsService,
    private readonly _powerBiService: PowerBiService,
  ) {}

  // Methods
  async init(
    element: HTMLElement,
    source: Source,
    dashboard: Dashboard,
    currentUserId: string,
    isMobile: boolean,
    shouldRecreate = false,
  ): Promise<void> {
    // Setup service
    this._timestamps.init = Date.now();
    this._element = element;
    this._source = source;
    this._dashboard = dashboard;
    this._currentUserId = currentUserId;
    this._isMobile = isMobile;

    // Get configuration
    const config = await this._getConfiguration();

    if (config.token) {
      // Setup token management
      this._tokenExpiration = config.expiration;

      if (config.expiration) {
        this._setTokenExpirationListener(this._tokenExpiration);
      }

      // Setup power-bi
      if (shouldRecreate) {
        this._powerBiService.reset(this._element);
      }

      // Paginated reports cannot be bootstrapped by PowerBI
      if (dashboard.powerBIType === PowerBiReportType.PAGINATED_REPORT || this._report) {
        this._report = this._powerBiService.embed(this._element, config.embed) as Report;
      } else {
        this._report = this._powerBiService.bootstrap(this._element, config.embed) as Report;
      }

      this._logger.info('[PowerBIEmbedService] The report is now embed with config', config);

      // Initiate service
      this._browsePages();
      this._reportLoadingTime();
      this._reportError();
      this._forwardEvents();
      this._reportPageRenderingTime();
      this._reportInitialized();
    } else {
      this._alertService.error(`Cannot access to ${dashboard.name}`);
      await this._navigator.navigate('catalog-source', { fileName: source.id });
    }
  }

  async getUserAccessToken(): Promise<IAuthToken> {
    const authToken = await this._multiAuthService.acquireTokenDashboard(true);

    return this._dashboardsService.convertTokenToIAuthToken(authToken);
  }

  async setPage(pageName: string): Promise<void> {
    // Avoid default <option> click
    if (pageName !== '-1') {
      try {
        await this._report.setPage(pageName);
      } catch (e) {
        this._logger.error('[PowerBIEmbedService] Error setting page', e);
      }
    }
  }

  async getPages(): Promise<Page[]> {
    return this._report.getPages();
  }

  getCurrentPage(): Page {
    return this._currentPage;
  }

  getCustomLayoutConfiguration(
    displayOption: keyof typeof models.DisplayOption,
    pageSize: models.IPageSize,
    pagesLayout: models.PagesLayout,
  ): models.ISettings {
    return {
      layoutType: models.LayoutType.Custom,
      customLayout: {
        pageSize: pageSize,
        displayOption: models.DisplayOption[displayOption],
        pagesLayout: pagesLayout,
      },
    };
  }

  fitToPage(): void {
    this._logger.debug('[PowerBIEmbedService] Fit to page click, report: ', this._report ? 'not null' : 'null');
    void this._report.updateSettings(this.getCustomLayoutConfiguration('FitToPage', null, null));
  }

  fitToWidth(): void {
    this._logger.debug('[PowerBIEmbedService] Fit to width click, report: ', this._report ? 'not null' : 'null');
    void this._report.updateSettings(this.getCustomLayoutConfiguration('FitToWidth', null, null));
  }

  actualSize(): void {
    this._logger.debug('[PowerBIEmbedService] Actual size click, report: ', this._report ? 'not null' : 'null');
    void this._report.updateSettings(this.getCustomLayoutConfiguration('ActualSize', null, null));
  }

  fullscreen(): void {
    this._logger.debug('[PowerBIEmbedService] Fullscreen click, report: ', this._report ? 'not null' : 'null');
    this._report.fullscreen();
  }

  async print(): Promise<void> {
    await this._report.print();
  }

  async resetState(): Promise<void> {
    await this._report.reload();
  }

  async extractState(): Promise<string> {
    try {
      const bookmark = await this._report.bookmarksManager.capture({ allPages: false, personalizeVisuals: true });
      this._logger.debug('[PowerBIEmbedService] Captured state', bookmark);

      await this._report.bookmarksManager.applyState(bookmark.state);

      return bookmark.state;
    } catch (e) {
      this._logger.error('[PowerBIEmbedService] Error extracting state', e);
      throw e;
    }
  }

  private async _getVisualAndSelectedValuesByField(
    slicers: VisualDescriptor[],
    requestedFields: ITableField[],
  ): Promise<VisualAndSelectedValuesByFieldMap> {
    const resultMap: VisualAndSelectedValuesByFieldMap = new Map<
      ITableField,
      { slicer: VisualDescriptor; slicerState: models.ISlicerState; values: unknown[] }
    >();
    await Promise.all(
      slicers.map(async (currentSlicer) => {
        const currentSlicerState = await currentSlicer.getSlicerState();
        const currentSlicerFilters = currentSlicerState.filters;
        const containsAtLeastOneRequestedField = currentSlicerFilters.some((filter) =>
          requestedFields.some(
            (field) =>
              // TODO: [Revisit] (18.01.2024) - Types
              field.table === (filter.target as { table: string }).table &&
              field.column === (filter.target as { column: string }).column,
          ),
        );

        if (!containsAtLeastOneRequestedField) {
          return;
        }

        const tableFieldsInMap = Array.from(resultMap.keys());
        currentSlicerFilters.forEach((filter) => {
          // TODO: [Revisit] (18.01.2024) - Types
          const tableName = (filter.target as { table: string }).table as string;
          const columnName = (filter.target as { column: string }).column as string;

          if (requestedFields.some((field) => field.table === tableName && field.column === columnName)) {
            if (!tableFieldsInMap.some((field) => field.table === tableName && field.column === columnName)) {
              resultMap.set(
                { table: tableName, column: columnName },
                {
                  slicer: currentSlicer,
                  slicerState: currentSlicerState,
                  values: (filter as { values: any }).values,
                },
              );
            } else {
              this._logger.warn(
                `ColumnName '${columnName}' appears more than one time (may be a mistake when configuring PowerBi filters)`,
              );
            }
          }
        });
      }),
    );

    return resultMap;
  }

  private async _setAllSlicersStateWithRetry(
    slicersWithModifiedSlicerState: ISlicerWithSlicerState[],
    nbRetries = 0,
  ): Promise<void> {
    await Promise.all(
      slicersWithModifiedSlicerState.map((slicerWithModifiedSlicerState) =>
        slicerWithModifiedSlicerState.slicer.setSlicerState(slicerWithModifiedSlicerState.state),
      ),
    ).catch(async (error) => {
      if (nbRetries < this._maxRetriesOnError) {
        let slicersWithModifiedSlicerStateUpdated: ISlicerWithSlicerState[];

        try {
          const visuals = await this._currentPage.getVisuals();
          const slicers = visuals.filter((visual) => visual.type === 'slicer');
          const requestedFields = slicersWithModifiedSlicerState.map(
            (slicerWithModifiedSlicerState) => slicerWithModifiedSlicerState.field,
          );
          const visualAndSelectedValuesByField = await this._getVisualAndSelectedValuesByField(
            slicers,
            requestedFields,
          );
          const visualAndSelectedValuesByFieldEntries = Array.from(visualAndSelectedValuesByField.entries());
          slicersWithModifiedSlicerStateUpdated = slicersWithModifiedSlicerState.map(
            (slicerWithModifiedSlicerState) => {
              slicerWithModifiedSlicerState.slicer = visualAndSelectedValuesByFieldEntries.find(
                (visualAndSelectedValuesByFieldEntry) =>
                  visualAndSelectedValuesByFieldEntry[0].table === slicerWithModifiedSlicerState.field.table &&
                  visualAndSelectedValuesByFieldEntry[0].column === slicerWithModifiedSlicerState.field.column,
              )[1].slicer;

              return slicerWithModifiedSlicerState;
            },
          );
        } catch (err) {
          this._logger.debug(
            '[PowerBIEmbedService] getVisual() failed, retrying _setAllSlicersStateWithRetry() with nbRetries : ',
            nbRetries,
            error,
          );
          await this._setAllSlicersStateWithRetry(slicersWithModifiedSlicerState, nbRetries + 1);

          return;
        }

        this._logger.debug(
          '[PowerBIEmbedService] setSlicerState() failed, retrying _setAllSlicersStateWithRetry() with nbRetries : ',
          nbRetries,
          error,
        );
        await this._setAllSlicersStateWithRetry(slicersWithModifiedSlicerStateUpdated, nbRetries + 1);
      } else {
        throw error;
      }
    });
  }

  private async _restorePowerbiFilters(resultMap: VisualAndSelectedValuesByFieldMap): Promise<void> {
    const slicersWithModifiedSlicerState: ISlicerWithSlicerState[] = Array.from(resultMap.entries()).map(
      (resultMapEntry) => {
        const slicerState = resultMapEntry[1].slicerState;
        const concernedSlicerFilters = slicerState.filters.filter(
          (filter) =>
            // TODO: [Revisit] (18.01.2024) - Check types
            (filter.target as { table: string }).table === resultMapEntry[0].table &&
            (filter.target as { column: string }).column === resultMapEntry[0].column,
        );
        const modifiedSlicerFilters = concernedSlicerFilters.map((concernedSlicerFilter) => {
          (concernedSlicerFilter as typeof concernedSlicerFilter & { values: unknown[] }).values =
            resultMapEntry[1].values;

          return concernedSlicerFilter;
        });
        const modifiedSlicerState = { ...slicerState, filters: modifiedSlicerFilters };

        return {
          field: { table: resultMapEntry[0].table, column: resultMapEntry[0].column },
          slicer: resultMapEntry[1].slicer,
          state: modifiedSlicerState,
        };
      },
    );

    try {
      await this._setAllSlicersStateWithRetry(slicersWithModifiedSlicerState);
    } catch (error) {
      this._logger.error('[PowerBIEmbedService] Cannot restore default powerbi filters', error);
    }
  }

  async saveDefaultPowerBiFiltersSelectedValues(): Promise<void> {
    try {
      const visuals = await this._currentPage.getVisuals();
      const slicers = visuals.filter((visual) => visual.type === 'slicer');
      const requestedFields = this._dashboard.fieldsToIgnore ? this._dashboard.fieldsToIgnore : [];
      this._defaultPowerBiFiltersSelectedValues = await this._getVisualAndSelectedValuesByField(
        slicers,
        requestedFields,
      );
    } catch (error) {
      this._logger.error('[PowerBIEmbedService] Cannot save default powerbi filters selected values', error);
    }
  }

  private async _applyStateWithRetry(state: string, nbRetries = 0): Promise<void> {
    await this._report.bookmarksManager.applyState(state).catch(async (error) => {
      if (nbRetries < this._maxRetriesOnError) {
        setTimeout(async () => this._applyStateWithRetry(state, nbRetries + 1), 2000);
      } else {
        this._logger.error('[PowerBIEmbedService] Cannot apply state (max retries reached)', error);
      }
    });
  }

  async restoreState(state: string): Promise<void> {
    try {
      // reset to DP selected bookmark state
      this._logger.info('[PowerBIEmbedService] Restoring state', state);
      await this._applyStateWithRetry(state);

      if (this._dashboard.hasToIgnoreSpecifiedFieldsInFilters && this._dashboard.fieldsToIgnore?.length) {
        // restore the specified PowerBi filters only
        await this._restorePowerbiFilters(this._defaultPowerBiFiltersSelectedValues);
      }
    } catch (e) {
      this._logger.error('[PowerBIEmbedService] Cannot restore state', e);
      throw e;
    }
  }

  /**
   * Add event listener to be triggered upon PowerBI Events
   * @param eventType event listened to (ex: loaded, error, rendered..)
   * @param handler predicate to execute upon event triggered
   */
  addEventListener<T>(eventType: string, handler: service.IEventHandler<T>): service.IEventHandler<T> {
    // Add to pending handlers if report is not initialized yet
    if (!this._report) {
      let handlers = this._pendingListeners.get(eventType);

      if (!handlers) {
        handlers = new Set<service.IEventHandler<unknown>>();
        this._pendingListeners.set(eventType, handlers);
      }

      handlers.add(handler);

      this._logger.warn('[PowerBIEmbedService] Postponing event registration: report is not initialized', {
        eventType,
        handler,
      });
    } else {
      this._report.on(eventType, handler);
      this._eventsHandled.add(eventType);
      this._logger.info('[PowerBIEmbedService] Event listener registered', { event: eventType, handler });
    }

    return handler;
  }

  removeEventListener(eventType: string, handler: service.IEventHandler<unknown>): void {
    this._logger.info('[PowerBIEmbedService] Removing event handler', { eventType, handler });
    this._report.off(eventType, handler);
  }

  removeEventListeners(): void {
    this._logger.info('[PowerBIEmbedService] Remove all listeners');

    for (const event of this._eventsHandled) {
      this._report.off(event);
    }
  }

  restartCubeIfNeeded(dashboard: Dashboard, source: Source): void {
    const isUsingAASCube = dashboard.isAnalysisServices && dashboard.aasCubeUrl;

    this._logger.info('[PowerBIEmbedService] (AAS) Using AAS cube', isUsingAASCube);
    this._logger.info('[PowerBIEmbedService] (AAS) AAS Cube state', {
      restarting: this._isCubeStarting,
      restarted: this._isCubeRestarted,
    });

    if (isUsingAASCube && !this._isCubeStarting && !this._isCubeRestarted) {
      this._logger.info('[PowerBIEmbedService] (AAS) Trying to restart AAS cube');

      const errorStarting = async (err?: Error): Promise<void> => {
        this._logger.warn(
          '[PowerBIEmbedService] (AAS) Error starting cube state, trying to init the dashboard anyway',
          err,
        );

        await this.init(this._element, source, dashboard, this._currentUserId, this._isMobile, true);
        // Avoid infinite loop trying to restart then fail, then init, then restart, then fail, then init etc...
        this._isCubeRestarted = true;
      };

      this._startDashboardCube(dashboard, source.id, dashboard.aasCubeUrl).subscribe(
        async (isStarting) => {
          if (isStarting) {
            this._pollStateAndRerender(dashboard, source);
          } else {
            await errorStarting();
          }
        },
        // TODO: [Revisit] (18.01.2024) - Fix type
        (err: unknown) => errorStarting(err as Error),
      );
    }
  }

  private async _getConfiguration(): Promise<IConfiguration> {
    if (this._dashboard.usingUserOwnsData) {
      const userAccessToken = await this.getUserAccessToken();
      const token = userAccessToken.token;
      const expiration = userAccessToken.expiration;

      return {
        token,
        expiration,
        embed: this._dashboardsService.getEmbedConfiguration(this._dashboard, TokenType.Aad, token, this._isMobile),
      };
    } else {
      const { token, expiration } = await this._dashboardsService
        .getEmbedToken(this._dashboard, this._currentUserId, this._source)
        .toPromise();

      return {
        token,
        expiration,
        embed: this._dashboardsService.getEmbedConfiguration(this._dashboard, TokenType.Embed, token, this._isMobile),
      };
    }
  }

  private _setTokenExpirationListener(tokenExpiration: Date): void {
    const timeout = this._dashboardsService.getTokenRefreshTimeout(tokenExpiration);

    if (timeout <= 0) {
      this._updateToken();
    } else {
      setTimeout(() => this._updateToken(), timeout);
    }
  }

  private _updateToken(): void {
    // if this dashboard is RLS
    if (this._dashboard.usingUserOwnsData) {
      from(this.getUserAccessToken()).subscribe((userAccessToken) => {
        const token = userAccessToken.token;
        const expiration = userAccessToken.expiration;

        if (token && expiration) {
          this._tokenExpiration = expiration;
          this._setTokenExpirationListener(this._tokenExpiration);

          return this._report.setAccessToken(token);
        }
      });
    } else {
      this._dashboardsService.getEmbedToken(this._dashboard, this._currentUserId, this._source).subscribe((data) => {
        if (data.token && data.expiration) {
          this._tokenExpiration = data.expiration;
          this._setTokenExpirationListener(this._tokenExpiration);

          return this._report.setAccessToken(data.token);
        }
      });
    }
  }

  private _browsePages(): void {
    const browsePages = (): void => {
      const actualPages: PageNames[] = [];

      this._report.getPages().then((pages: Page[]) => {
        for (const page of pages) {
          if (page.visibility === SectionVisibility.AlwaysVisible) {
            actualPages.push({
              name: page.name,
              displayName: page.displayName,
            });
          }
        }

        this._pages$.next(actualPages);
      });

      this.removeEventListener('loaded', browsePages);
    };

    this.addEventListener('loaded', browsePages);
  }

  private _reportLoadingTime(): void {
    const handler = (): void => {
      this._timestamps.loaded = Date.now();
      const loadingTime = this._timestamps.loaded - this._timestamps.init;

      this._logger.info('[PowerBIEmbedService] Loading Time (ms)', {
        dashboard: this._dashboard,
        loading: loadingTime,
      });

      this._logsService.logLoadingTime(this._source.id, this._dashboard.reportId, loadingTime).subscribe();

      this.removeEventListener('loaded', handler);
    };

    this.addEventListener('loaded', handler);
  }

  private _reportError(): void {
    this.addEventListener('error', () => {
      this.restartCubeIfNeeded(this._dashboard, this._source);
    });
  }

  private _forwardEvents(): void {
    for (const event of FORWARDED_EVENTS) {
      this.addEventListener(event, () => this._events$.next(event));
    }
  }

  private _reportPageRenderingTime(): void {
    this._report.on('rendered', () => {
      this._timestamps.rendered = Date.now();
      const renderingTime = this._timestamps.rendered - (this._timestamps.lastPageChange || this._timestamps.init);

      this._logger.info('[PowerBIEmbedService] Rendering Time (ms)', {
        dashboard: this._dashboard.reportId,
        page: this._currentPage.name,
        rendering: renderingTime,
      });

      this._logsService
        .logRenderingTime(this._source.id, this._dashboard.reportId, this._currentPage.name, renderingTime)
        .subscribe();
    });

    this._report.on<{ newPage: Page }>('pageChanged', (event) => {
      this._timestamps.lastPageChange = Date.now();

      this._currentPage = event?.detail?.newPage || null;
      this._currentPage$.next(this._currentPage);
    });
  }

  private _reportInitialized(): void {
    this._logger.info(
      '[PowerBIEmbedService] Report initialized: registering pending listeners',
      this._pendingListeners,
    );

    for (const [event, handlers] of this._pendingListeners) {
      for (const handler of handlers) {
        this.addEventListener(event, handler);
      }
    }
  }

  private _startDashboardCube(dashboard: Dashboard, sourceId: string, cubeUrl: string): Observable<boolean> {
    this._isCubeStarting = true;

    return this._cubeService.startDashboardCube(dashboard, sourceId, cubeUrl).pipe(
      tap((starting) => {
        this._isCubeStarting = starting;
      }),
    );
  }

  private _pollStateAndRerender(dashboard: Dashboard, source: Source): void {
    this._alertService.info('The server is resuming');

    this._cubeService.pollCubeState(dashboard, source.id).subscribe(
      (cubeState) => this._logger.debug('[PowerBIEmbedService] (AAS) AAS Cube is', cubeState),
      (err: unknown) => this._logger.error('[PowerBIEmbedService] (AAS) Error polling cube state', err),
      async () => {
        this._logger.info('[PowerBIEmbedService] (AAS) Cube restarted successfully');
        this._alertService.success('The dashboard will be available soon');
        this._logger.info(
          '[PowerBIEmbedService] (AAS) Calling init with',
          this._element,
          source,
          dashboard,
          this._currentUserId,
          this._isMobile,
        );
        await this.init(this._element, source, dashboard, this._currentUserId, this._isMobile, true);
        this._isCubeStarting = false;
        this._isCubeRestarted = true;
      },
    );
  }

  // Properties
  get isCubeStarting(): boolean {
    return this._isCubeStarting;
  }
}
