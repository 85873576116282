import type { Observable } from 'rxjs';
import type { IAuthErrorReport, IAuthToken } from '@dataportal/msal';

export interface IProfileFromAuth {
  username: string;
}

export abstract class BaseAuthService {
  abstract readonly userProfile$: Observable<IProfileFromAuth>;

  /**
   * @method isAuthenticated
   * Checks whether the user is authenticated.
   * Also used by AuthGuard to determine if he can access restricted page
   */
  abstract isAuthenticated(): boolean;

  /**
   * @method login
   * Logs the user in by redirecting him to related login form.
   */
  abstract login(): void;

  /**
   * @method logout
   * Logs the user out and redirect him to /
   */
  abstract logout(): Promise<void>;

  /**
   * @method refreshToken
   * Ask for a new access token.
   * Operation is done silently (i.e. without prompting the user)
   * It can be triggered either:
   * > when user successfully logs in the first time
   * > by the automatic refresh process (every 45 minutes)
   * > by the incoming request interceptor, when a 401 is received from backend
   * @returns promise resolving the newly acquired access token (if acquired silently). This allow
   * incoming requests interceptor to subscribe and retry the failed request with
   * the new access token.
   * If a request is already in progress or token cannot be acquire silently (this will cause a redirection)
   * returns null.
   */
  abstract refreshTokens(): Promise<string>;

  /**
   * @method refreshCurrentUser
   * Ask service for currently logged in user profile details.
   * If it succeed, emit the value in "userProfile" observable so subscribers
   * are notified.
   */
  abstract refreshCurrentUser(): void;

  abstract acquireTokenDashboard(allowRedirect?: boolean): Promise<string | void | IAuthToken>;
  abstract acquireTokenDevops(allowRedirect?: boolean): Promise<string | void | IAuthToken>;

  abstract acquireTokenDatabricks(): Promise<string | void | IAuthToken>;

  abstract handleError(report: IAuthErrorReport);
}
