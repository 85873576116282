import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import type { ModuleWithProviders } from '@angular/core';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { AdlModule } from '@dataportal/adl';
import { AuthModule } from '@dataportal/auth';
import type { IDatalakeModuleOptions } from '@dataportal/datalake-parsing';
import { DATALAKE_OPTIONS, FileParserAndFormatterService } from '@dataportal/datalake-parsing';
import { FlatfileModule } from '@dataportal/flatfile';
import { ApiModule } from '@dataportal/front-api';
import { SharedModule } from '@dataportal/front-shared';
import { GuardianUtilsModule } from '@dataportal/guardian-utils';
import { LogsModule } from '@dataportal/logs';
import { PermissionsModule } from '@dataportal/permissions';
import { WebsocketModule } from '@dataportal/websocket';
import { WordingModule } from '@dataportal/wording';
import { FlatfileAdapterModule } from '@flatfile/angular';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxJsonViewerModule } from 'ngx-json-viewer';

import { GuardianModule } from '../guardian/guardian.module';

import { DatalakeBucketsCacheService } from './services/buckets-cache.service';
import { DatalakeApiService } from './services/datalake-api.service';
import { DatalakeObjectExistenceCacheService } from './services/datalake-object-existence-cache.service';
import { DatalakeStoreService } from './services/datalake-store.service';
import { ExplorerService } from './services/explorer.service';
import { ExplorerDownloadUploadUrlService } from './services/explorer-download-upload-url.service';
import { ExplorerPageService } from './services/explorer-page.service';
import { ExplorerTreeService } from './services/explorer-tree.service';
import { IndexedDbCacheService } from './services/indexed-db-cache.service';
import { ItrackService } from './services/itrack.service';
import { DatalakeObjectsCacheService } from './services/objects-cache.service';
import { UploadBrowserService } from './services/upload-browser.service';

import { BrowserPaginationComponent } from './components/browser-pagination/browser-pagination.component';
import { BucketsListComponent } from './components/buckets-list/buckets-list.component';
import { CantFindYourFolderModalComponent } from './components/cant-find-your-folder-modal/cant-find-your-folder-modal.component';
import { CsvDatatableComponent } from './components/csv-datatable/csv-datatable.component';
import { CustomPagerComponent } from './components/custom-pager/custom-pager.component';
import { DatalakeObjectSelectorModalComponent } from './components/datalake-object-selector-modal/datalake-object-selector-modal.component';
import { DatalakeObjectSelectorModalV2Component } from './components/datalake-object-selector-modal-v2/datalake-object-selector-modal-v2.component';
import { DatalakePreviewComponent } from './components/datalake-preview/datalake-preview.component';
import { EditionModalComponent } from './components/edition-modal/edition-modal.component';
import { ObjectsBrowserComponent } from './components/objects-browser/objects-browser.component';
import { ObjectsTableComponent } from './components/objects-table/objects-table.component';
import { OverviewModalComponent } from './components/overview-modal/overview-modal.component';
import { SelectedObjectComponent } from './components/selected-object/selected-object.component';
import { UploadDatalakeModalComponent } from './components/upload-datalake-modal/upload-datalake-modal.component';

import { FavoriteDatalakeService } from './services/favorite-datalake-service';

// Module
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatDialogModule,
    AdlModule,
    ApiModule,
    AuthModule,
    FlatfileModule,
    GuardianModule,
    LogsModule,
    PermissionsModule,
    SharedModule,
    NgxDatatableModule,
    FlatfileAdapterModule,
    GuardianUtilsModule,
    NgxJsonViewerModule,
    WebsocketModule,
    InfiniteScrollModule,
    WordingModule,
    NgOptimizedImage,
    CdkTableModule,
  ],
  declarations: [
    EditionModalComponent,
    CantFindYourFolderModalComponent,
    OverviewModalComponent,
    UploadDatalakeModalComponent,
    DatalakeObjectSelectorModalComponent,
    DatalakePreviewComponent,
    BrowserPaginationComponent,
    DatalakeObjectSelectorModalV2Component,
    SelectedObjectComponent,
    ObjectsBrowserComponent,
    BucketsListComponent,
    ObjectsTableComponent,
    CustomPagerComponent,
    CsvDatatableComponent,
  ],
  exports: [
    EditionModalComponent,
    CantFindYourFolderModalComponent,
    OverviewModalComponent,
    UploadDatalakeModalComponent,
    DatalakeObjectSelectorModalComponent,
    DatalakePreviewComponent,
    BrowserPaginationComponent,
    DatalakeObjectSelectorModalV2Component,
  ],
})
export class DatalakeModule {
  static forRoot(options: IDatalakeModuleOptions = {}): ModuleWithProviders<DatalakeModule> {
    return {
      ngModule: DatalakeModule,
      providers: [
        DatalakeApiService,
        DatalakeObjectExistenceCacheService,
        DatalakeBucketsCacheService,
        DatalakeObjectsCacheService,
        IndexedDbCacheService,
        DatalakeStoreService,
        ExplorerService,
        ExplorerTreeService,
        ExplorerPageService,
        ExplorerDownloadUploadUrlService,
        FileParserAndFormatterService,
        UploadBrowserService,
        FavoriteDatalakeService,
        ItrackService,
        { provide: DATALAKE_OPTIONS, useValue: options },
        { provide: MAT_DIALOG_DATA, useValue: {} },
      ],
    };
  }
}
