import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ApiModule } from '@dataportal/front-api';
import { SharedModule } from '@dataportal/front-shared';

// Module
@NgModule({
  declarations: [],
  imports: [CommonModule, ApiModule, SharedModule],
})
export class SourceStatisticsModule {}
