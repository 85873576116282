import type { OnDestroy, OnInit } from '@angular/core';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import type { IStepperStep } from '@dataportal/adl';
import { AlertService, Logger } from '@dataportal/front-shared';
import type {
  IGuardianChecksDatasetMap,
  IGuardianForm,
  IGuardianStatus,
  ISupportedGuardianChecksResource,
} from '@dataportal/guardian-utils';
import { GuardianFormService, GuardianImportExportCheckService } from '@dataportal/guardian-utils';

/**
 * Modal of creation of a new Guardian Check
 */
@Component({
  selector: 'app-step-datalake-guardian-modal',
  templateUrl: './guardian-modal.component.html',
  styleUrls: ['./guardian-modal.component.scss'],
})
export class GuardianModalComponent<R extends ISupportedGuardianChecksResource> implements OnInit, OnDestroy {
  sourceName: string;
  sourceId: string;
  dataset: IGuardianChecksDatasetMap[R];
  guardianStatus: IGuardianStatus;
  resource: R;
  existingChecksNameInSource: string[] = [];
  currentStep = 1;
  modalTitle: string;
  datasetName: string;
  lastStepButton: string;
  secondaryLastStepButton: string;
  private readonly _canGoNextStep$ = new Subject<boolean>();
  canGoNextStep$ = this._canGoNextStep$.asObservable();

  readonly stepLabels = ['File configuration', 'Datas & rules', 'Post actions'];
  steps: IStepperStep[] = [{ label: this.stepLabels[0] }, { label: this.stepLabels[1] }, { label: this.stepLabels[2] }];

  defaultPreparedRegexValues: Array<{ label: string; value: string }> = [
    { label: 'NN-NN-NN (N: digit)', value: '/^[0-9]{2}-[0-9]{2}-[0-9]{2}$/' },
    { label: 'NN-NN-NNNN (N: digit)', value: '/^[0-9]{2}-[0-9]{2}-[0-9]{4}$/' },
    { label: 'NNNN-NN-NN (N: digit)', value: '/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/' },
  ];

  GUARDIAN_STEP_NUMBER_IN_CHECK_COLUMN = 2;
  GUARDIAN_STEP_NUMBER_IN_CHECK_TRIGGER = 3;

  private readonly _hasFinishedToImport$ = new Subject<void>();
  private readonly _destroyed$ = new Subject<void>();

  constructor(
    readonly guardianFormService: GuardianFormService,
    private readonly _guardianImportExportCheckService: GuardianImportExportCheckService,
    private readonly _alertService: AlertService,
    private readonly _logger: Logger,
    private readonly _activeMatModal: MatDialogRef<GuardianModalComponent<R>>,
    private readonly _modalMatService: MatDialog,
    @Inject(MAT_DIALOG_DATA)
    data: {
      sourceName: string;
      sourceId: string;
      dataset: IGuardianChecksDatasetMap[R];
      resource: R;
      guardianStatus: IGuardianStatus;
      existingChecksNameInSource: string[];
    },
  ) {
    this.sourceName = data?.sourceName?.length ? data.sourceName : this.sourceName;
    this.sourceId = data?.sourceId?.length ? data.sourceId : this.sourceId;
    this.dataset = data?.dataset ? data.dataset : this.dataset;
    this.resource = data?.resource ? data.resource : this.resource;
    this.guardianStatus = data?.guardianStatus ? data.guardianStatus : this.guardianStatus;
    this.existingChecksNameInSource = data?.existingChecksNameInSource?.length
      ? data.existingChecksNameInSource
      : this.existingChecksNameInSource;
  }

  ngOnInit(): void {
    this.guardianFormService.initGuardianModal(this.guardianStatus, this.defaultPreparedRegexValues, this.resource);
    this.modalTitle = this.guardianStatus?.checkId
      ? `Guardian Specification n°${this.guardianStatus?.checkId}`
      : 'Guardian Specification';
    this.datasetName =
      this.dataset.type === 'datalakePath' ? this.dataset?.path : this.dataset?.tableName.toUpperCase();
    this.lastStepButton = this.guardianStatus?.isChecked ? 'Update' : 'Create';
    this.secondaryLastStepButton = `${this.lastStepButton} and run`;
  }

  ngOnDestroy(): void {
    this._hasFinishedToImport$.next();
    this._destroyed$.next();
  }

  private _isCurrentStepValid(currentStep: number): boolean {
    return this.guardianFormService.getFormByStep(currentStep).valid;
  }

  private _revealErrors(currentStep): void {
    this.guardianFormService.getFormByStep(currentStep).markAllAsDirty();
  }

  updateCurrentStep(currentStep: number) {
    this.currentStep = currentStep;
  }

  goNextStep(currentStep: number) {
    if (!this._isCurrentStepValid(currentStep)) {
      this._revealErrors(currentStep);
      this._canGoNextStep$.next(false);
    } else {
      this._canGoNextStep$.next(true);
    }
  }

  async lastStep(hasToRun = false) {
    this.guardianFormService.updateRegexValueForConstraint();
    this._logger.debug('[GuardianConfig] Whole form is valid');
    const guardianForm: IGuardianForm<R> = {
      dataset: this.dataset,
      guardianStatus: this.guardianStatus,
      form: this.guardianFormService.guardianForm.value,
      hasToRun: hasToRun,
    };
    this._alertService.info('Saving check configuration...');
    this._activeMatModal.close(guardianForm);
  }

  importGuardianSpecificationsFileInGuardianModal(
    specificationsFileStr: string,
    checkType: ISupportedGuardianChecksResource,
  ): void {
    try {
      const parsedFile = JSON.parse(specificationsFileStr);

      if (!GuardianImportExportCheckService.isImportedCheckWellFormatted(parsedFile, checkType)) {
        throw new Error('Not well formatted parsed file');
      }

      this.guardianStatus = this._guardianImportExportCheckService.buildGuardianStatusFromImportedCheck(
        parsedFile,
        checkType,
        this.guardianStatus,
        this.guardianFormService.guardianFormStepOne?.get('name')?.value || null,
      );
      this.guardianFormService.initGuardianModal(this.guardianStatus, this.defaultPreparedRegexValues, this.resource);
      this.guardianFormService.formReady$.pipe(takeUntil(this._hasFinishedToImport$)).subscribe((isReady) => {
        if (isReady) {
          this._hasFinishedToImport$.next();
          this._alertService.success('Specifications successfully imported', false);
        }
      });
    } catch (e) {
      this._logger.debug('[GuardianConfig] Imported guardian specifications file is not well-formatted : ', e);
      this._alertService.error(
        'Imported guardian specifications file is not well-formatted,' +
          ' please make sure you have not modified the exported guardian specifications file',
        null,
        false,
        7000,
      );
    }
  }
}
