<div [class.hoverable]="!isMobile" class="toolbar">
  <div class="wrapper">
    <div class="box left">
      <div *ngIf="isSidebarVisible" class="item">
        <a [href]="'sources/' + source?.id">
          <em class="icon-chevron-left"></em>
          <span class="mob animate-underline-dash"> Back </span>
        </a>
      </div>
      <div *ngIf="lastUpdate && !isLastUpdateDisabled" class="item last-update">
        <span>Last update: {{ lastUpdate }} (local time)</span>
      </div>
    </div>
    <div class="box main">
      <div *ngIf="!isSidebarVisible" class="item">
        <a [href]="'sources/' + source?.id">
          <em class="icon-chevron-left"></em>
          <span class="mob"> Back </span>
        </a>
      </div>
      <div *ngIf="!dashboard?.isHidden" class="item">
        <a (click)="toggleFavoriteDashboard()">
          <em *ngIf="isSourceFavorite && isFavoriteDashboard" class="icon-star2"></em>
          <em *ngIf="!isFavoriteDashboard || !isSourceFavorite" class="icon-star"></em>
        </a>
      </div>
      <div *ngIf="isPowerBI" class="item">
        <a (click)="handleToolbarClick('manage-bookmarks')" [class.is-active]="openedDropdownId === 'manage-bookmarks'">
          <dpg-bookmark-icon class="mr5 dpg-icon-inverted"></dpg-bookmark-icon>
          <adl-text [size]="14" color="white"> Bookmarks <em class="icon-chevron-down"></em></adl-text>
        </a>
        <div class="dropdown expanded flex column-flex" style="max-width: 200px">
          <div class="dropdown-section">
            <adl-text [bold]="true" [size]="12" class="section-title mt-2.5 mx-3.5" color="white"
              >My bookmarks
            </adl-text>
            <div class="explanation-separator mt-2.5 mx-3.5"></div>
            <div id="saved-bookmarks" class="scrollable text-left">
              <div class="flex" *ngIf="ownBookmarks.length === 0">
                <adl-text color="white" [size]="12" class="m-2.5"> You don't have saved bookmarks.</adl-text>
              </div>
              <ul *ngIf="ownBookmarks.length > 0" id="saved-filters-list">
                <li class="saved-filters truncate-parent hoverable-item flex items-baseline py-2.5 px-3.5">
                  <div (click)="resetBookmark()" class="truncate bookmark-name section-item cursor-pointer">
                    <adl-text [italic]="true" [size]="12" class="mt-2.5 mr-3.5" color="white">Reset view</adl-text>
                  </div>
                </li>
                <li
                  *ngFor="let ownBookmark of ownBookmarks"
                  [class.is-nested-active]="openedNestedDropdownId === ownBookmark.pk"
                  class="saved-filters truncate-parent hoverable-item flex items-baseline py-2.5 px-3.5"
                >
                  <div
                    (click)="applyBookmark(ownBookmark)"
                    [class.applied]="appliedBookmark?.pk === ownBookmark.pk"
                    [ngClass]="{ disabled: !this.hasProcessedDefaultBookmark }"
                    [title]="ownBookmark.name"
                    class="truncate bookmark-name section-item cursor-pointer"
                  >
                    <adl-text [size]="12" class="mt-2.5 mr-3.5" color="white">{{ ownBookmark.name }}</adl-text>
                    <em class="fa fa-check"></em>
                  </div>
                  <div class="item-actions self-center">
                    <em
                      *ngIf="defaultBookmark?.pk === ownBookmark.pk"
                      [class.selected]="defaultBookmark?.pk === ownBookmark.pk"
                      class="fa fa-home"
                      title="Default bookmark"
                    ></em>
                    <em class="fa fa-caret-right ml-2"></em>
                  </div>
                  <div class="nested-dropdown pr-6" [style]="savedBookmarksNestedDropdownPosition">
                    <div (click)="updateWithCurrentStateBookmark(ownBookmark)" class="my-2 cursor-pointer">
                      <adl-text [size]="12" class="mt-2.5 mx-3.5" color="white">Update using current display</adl-text>
                    </div>
                    <div
                      (click)="setAsDefault(ownBookmark)"
                      *ngIf="defaultBookmark?.pk !== ownBookmark.pk"
                      class="my-2 cursor-pointer"
                    >
                      <adl-text [size]="12" class="mt-2.5 mx-3.5" color="white">Set as default</adl-text>
                    </div>
                    <div
                      (click)="setAsDefault(ownBookmark)"
                      *ngIf="defaultBookmark?.pk === ownBookmark.pk"
                      class="my-2 cursor-pointer"
                    >
                      <adl-text [size]="12" class="mt-2.5 mx-3.5" color="white">Unset as default</adl-text>
                    </div>
                    <div (click)="copyBookmarkFilters(ownBookmark)" class="my-2 cursor-pointer">
                      <adl-text [size]="12" class="mt-2.5 mx-3.5" color="white">Copy filters</adl-text>
                    </div>
                    <div (click)="shareBookmark(ownBookmark)" class="my-2 cursor-pointer">
                      <adl-text [size]="12" class="mt-2.5 mx-3.5" color="white">Share</adl-text>
                    </div>
                    <div (click)="renameBookmark(ownBookmark)" class="my-2 cursor-pointer">
                      <adl-text [size]="12" class="mt-2.5 mx-3.5" color="white">Rename</adl-text>
                    </div>
                    <div (click)="deleteBookmark(ownBookmark)" class="my-2 cursor-pointer">
                      <adl-text [size]="12" class="mt-2.5 mx-3.5" color="white">Delete</adl-text>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="section-separator"></div>
          <div class="dropdown-section">
            <adl-text [bold]="true" [size]="12" class="section-title mt-2.5 mx-3.5" color="white"
              >Shared to me
            </adl-text>
            <div class="explanation-separator mt-2.5 mx-3.5"></div>
            <div id="shared-bookmarks" class="scrollable text-left">
              <div class="flex">
                <adl-text [size]="12" color="white" *ngIf="sharedBookmarks.length === 0" class="m-2.5">
                  No bookmark has been shared to you.
                </adl-text>
              </div>
              <ul *ngIf="sharedBookmarks.length > 0" id="shared-filters-list">
                <li
                  *ngFor="let sharedBookmark of sharedBookmarks"
                  [class.is-nested-active]="openedNestedDropdownId === sharedBookmark.pk"
                  class="saved-filters truncate-parent hoverable-item flex items-baseline my-2.5 px-3.5"
                >
                  <div
                    (click)="applyBookmark(sharedBookmark)"
                    [class.applied]="appliedBookmark?.pk === sharedBookmark.pk"
                    [ngClass]="{ disabled: !this.hasProcessedDefaultBookmark }"
                    [title]="sharedBookmark.name"
                    class="truncate bookmark-name cursor-pointer"
                  >
                    <adl-text [size]="12" class="mt-2.5 mr-3.5" color="white">{{ sharedBookmark.name }}</adl-text>
                    <em class="fa fa-check"></em>
                  </div>
                  <div class="item-actions">
                    <em
                      *ngIf="defaultBookmark?.pk === sharedBookmark.pk"
                      [class.selected]="defaultBookmark?.pk === sharedBookmark.pk"
                      class="fa fa-home"
                      title="Default bookmark"
                    ></em>
                    <em class="fa fa-caret-right ml-2"></em>
                  </div>
                  <div class="nested-dropdown pl-3.5 pr-6" [style]="sharedBookmarksNestedDropdownPosition">
                    <div
                      (click)="setAsDefault(sharedBookmark)"
                      *ngIf="defaultBookmark?.pk !== sharedBookmark.pk"
                      class="my-2 cursor-pointer"
                    >
                      <adl-text [size]="12" class="mt-2.5 mx-3.5" color="white">Set as default</adl-text>
                    </div>
                    <div
                      (click)="setAsDefault(sharedBookmark)"
                      *ngIf="defaultBookmark?.pk === sharedBookmark.pk"
                      class="my-2 cursor-pointer"
                    >
                      <adl-text [size]="12" class="mt-2.5 mx-3.5" color="white">Unset as default</adl-text>
                    </div>
                    <div (click)="copyBookmarkFilters(sharedBookmark)" class="my-2 cursor-pointer">
                      <adl-text [size]="12" class="mt-2.5 mx-3.5" color="white">Copy filters</adl-text>
                    </div>
                    <div (click)="renameBookmark(sharedBookmark)" class="my-2 cursor-pointer">
                      <adl-text [size]="12" class="mt-2.5 mx-3.5" color="white">Rename</adl-text>
                    </div>
                    <div (click)="deleteBookmark(sharedBookmark)" class="my-2 cursor-pointer">
                      <adl-text [size]="12" class="mt-2.5 mx-3.5" color="white">Delete</adl-text>
                    </div>
                    <div class="section-separator"></div>
                    <div class="flex flex-col">
                      <adl-text [size]="12" class="mt-2.5 mx-3.5" color="white"> Shared by</adl-text>
                      <adl-text [italic]="true" [size]="12" class="mt-1 mb-2.5 mx-3.5" color="grey-500">
                        {{ sharedBookmark.sharedBy }}
                      </adl-text>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="section-separator"></div>
          <div class="dropdown-section">
            <adl-text [bold]="true" [size]="12" class="section-title mt-2.5 mx-3.5" color="white"
              >Capture this view
            </adl-text>
            <div class="explanation-separator mt-2.5 mx-3.5"></div>
            <form>
              <div class="mx-3.5 my-3 flex">
                <input
                  [(ngModel)]="newFiltersName"
                  [ngModelOptions]="{ standalone: true }"
                  class="form-control save-filter--input p-2 w-full"
                  placeholder="Enter name"
                  type="text"
                />
                <button (click)="saveBookmark()" class="btn-dropdown w-icon remove-filters ml-3">
                  <em class="fa fa-save" title="Set bookmark as default"></em>
                </button>
              </div>
            </form>
            <div class="explanation-separator mt-2.5 mx-3.5"></div>
            <div class="my-3 mx-3.5 cursor-pointer">
              <adl-text color="white" [bold]="true" [size]="12" (click)="openPasteFiltersModal()">
                Paste filters
              </adl-text>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="isPowerBI" [hidden]="!isSourceAuthorizedToExport && hasProcessedDefaultBookmark" class="item">
        <a (click)="handleToolbarClick('export')" [class.is-active]="openedDropdownId === 'export'">
          <dpg-export-icon class="mr5 dpg-icon-inverted"></dpg-export-icon>
          <adl-text [size]="14" color="white"> Export / Print <em class="icon-chevron-down"></em></adl-text>
        </a>
        <div class="dropdown export">
          <div class="dropdown-section">
            <adl-text [bold]="true" [size]="12" class="section-title mt-2.5 mx-3.5" color="white">Export</adl-text>
            <div class="explanation-separator mt-2.5 mx-3.5"></div>
            <adl-text [size]="12" class="mt-2.5 mx-3.5" color="white">Select pages...</adl-text>
            <ul>
              <li>
                <a (click)="!exportOnlyCurrentPage ? toggleExportCurrentPageOnly() : null" class="pt-2">
                  <em
                    [ngClass]="exportOnlyCurrentPage ? 'fa-check-circle-o' : 'fa-circle-o'"
                    class="fa"
                    style="height: 14px"
                  ></em>
                  <adl-text [size]="12" class="ml-1.5" color="white">Current page</adl-text>
                </a>
              </li>
              <li>
                <a (click)="exportOnlyCurrentPage ? toggleExportCurrentPageOnly() : null" class="pt-2">
                  <em
                    [ngClass]="!exportOnlyCurrentPage ? 'fa-check-circle-o' : 'fa-circle-o'"
                    class="fa"
                    style="height: 14px"
                  ></em>
                  <adl-text [size]="12" class="ml-1.5" color="white">All pages</adl-text>
                </a>
              </li>
            </ul>
          </div>
          <div class="dropdown-section">
            <adl-text [size]="12" class="mt-2.5 mx-3.5" color="white">...And export</adl-text>
            <ul>
              <li *ngFor="let exportType of exportTypes">
                <a
                  (click)="export(exportType)"
                  *ngIf="exportProgress[exportType] === null && !isGeneratingSignedUrl[exportType]"
                  [ngClass]="{ 'export-disabled': isAlreadyExporting }"
                >
                  <em [ngClass]="exportType"></em>
                  <adl-text [size]="12" class="ml-1.5" color="white">Export as {{ exportType.toUpperCase() }}</adl-text>
                </a>
                <a *ngIf="exportProgress[exportType] !== null && exportProgress[exportType] >= 0">
                  <em [ngClass]="exportType"></em>
                  <adl-text [bold]="true" [size]="12" class="ml-1.5" color="white">Exporting...</adl-text>
                </a>
                <a *ngIf="isGeneratingSignedUrl[exportType]">
                  <em [ngClass]="exportType"></em>
                  <adl-text [bold]="true" [size]="12" class="ml-1.5" color="white">Downloading...</adl-text>
                </a>
              </li>
            </ul>
          </div>
          <div class="section-separator"></div>
          <div class="dropdown-section mb-2.5">
            <adl-text [bold]="true" [size]="12" class="section-title mt-2.5 mx-3.5" color="white">Print</adl-text>
            <div class="explanation-separator mt-2.5 mx-3.5"></div>
            <adl-text (click)="print()" [size]="12" class="mt-2.5 mx-3.5 cursor-pointer" color="white"
              >Print this view
            </adl-text>
          </div>
        </div>
      </div>
      <div *ngIf="!isMobile" class="item">
        <a (click)="handleToolbarClick('share')" *ngIf="isPowerBI" [class.is-active]="openedDropdownId === 'share'">
          <img
            src="assets/dpg/imgs/icons/share-icon-white.svg"
            tooltip-trigger
            dpgStopClickBubbling
            class="cursor-pointer mr-2"
            width="12px"
            height="15px"
            alt="share-icon"
          />
          <adl-text [size]="14" color="white"> Share <em class="icon-chevron-down"></em></adl-text>
        </a>
        <div class="dropdown" style="min-width: 150px">
          <div class="dropdown-section flex flex-col">
            <adl-text [bold]="true" [size]="12" class="section-title mt-2.5 mx-3.5" color="white"> Recommend</adl-text>
            <div class="explanation-separator mt-2.5 mx-3.5"></div>
            <adl-text
              (click)="openRecommendationModal()"
              [size]="12"
              class="cursor-pointer my-2.5 mx-3.5"
              color="white"
            >
              Recommend this dashboard
            </adl-text>
          </div>
          <div class="section-separator"></div>
          <div class="dropdown-section flex flex-col">
            <adl-text [bold]="true" [size]="12" class="section-title mt-2.5 mx-3.5" color="white">
              Get a link
            </adl-text>
            <div class="explanation-separator mt-2.5 mx-3.5"></div>
            <adl-text (click)="openGetUrlModal()" [size]="12" class="cursor-pointer my-2.5 mx-3.5" color="white"
              >Share this view
            </adl-text>
          </div>
        </div>
      </div>
      <div *ngIf="isNotMobilePowerbi" class="item">
        <a>
          <img
            src="assets/dpg/imgs/icons/view-icon-white.svg"
            tooltip-trigger
            dpgStopClickBubbling
            class="cursor-pointer mr-2"
            width="20px"
            height="12px"
            alt="share-icon"
          />
          <adl-text [size]="14" color="white"> View <em class="icon-chevron-down"></em></adl-text>
        </a>
        <div class="dropdown scrollable svg">
          <ul>
            <li (click)="fitToPage()" class="dpg-icon-hover">
              <a>
                <dpg-fit-to-page-icon class="mr10 dpg-icon-inverted"></dpg-fit-to-page-icon>
                <adl-text [size]="12" [bold]="true" color="white"> Fit to Page</adl-text>
              </a>
            </li>
            <li (click)="fitToWidth()" class="dpg-icon-hover">
              <a>
                <dpg-fit-to-width-icon class="mr10 dpg-icon-inverted"></dpg-fit-to-width-icon>
                <adl-text [size]="12" [bold]="true" color="white"> Fit to Width</adl-text>
              </a>
            </li>
            <li (click)="actualSize()" class="dpg-icon-hover">
              <a>
                <dpg-actual-size-icon class="mr10 dpg-icon-inverted"></dpg-actual-size-icon>
                <adl-text [size]="12" [bold]="true" color="white"> Actual Size</adl-text>
              </a>
            </li>
            <li (click)="fullScreen()" class="dpg-icon-hover">
              <a>
                <dpg-fullscreen-icon class="mr10 dpg-icon-inverted"></dpg-fullscreen-icon>
                <adl-text [size]="12" [bold]="true" color="white"> Fullscreen</adl-text>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div *ngIf="hasToDisplaySupportLinkButton" class="item">
        <a (click)="openSupportLink()">
          <dpg-support-icon class="mr5 dpg-icon-inverted"></dpg-support-icon>
          <adl-text [size]="14" color="white"> Support</adl-text>
        </a>
      </div>
      <div *ngIf="isNotMobilePowerbi && this.dashboard.hasComments" class="item">
        <a (click)="toggleCommentsPart()">
          <adl-svg-icon icon="bubble" iconType="white" size="16"></adl-svg-icon>
          <div class="flex flex-row justify-start items-center pl-3.5">
            <adl-text [size]="14" color="white"> Comments</adl-text>
            <adl-text *ngIf="!isLoadingDashboardComments" class="pl-2" color="secondary-500"
              >({{ nbDashboardComments }})
            </adl-text>
            <adl-svg-icon
              [icon]="hasToDisplayCommentsPart ? 'chevron-left-thin' : 'chevron-right-thin'"
              class="pl-2"
              iconType="white"
              size="11"
            ></adl-svg-icon>
            <adl-svg-icon
              [icon]="hasToDisplayCommentsPart ? 'chevron-left-thin' : 'chevron-right-thin'"
              class="second-chevron"
              iconType="white"
              size="11"
            ></adl-svg-icon>
          </div>
        </a>
        <div class="scrollable"></div>
      </div>
    </div>
  </div>
</div>
