<adl-tooltip
  [isClickBehaviorActivated]="!isDisabled"
  [isClickOutsideBehavior]="true"
  [isMouseOverBehaviorActivated]="false"
  [isMouseOutBehaviorActivated]="false"
  [isTooltipTriggerFullWidth]="true"
  [fullContent]="true"
  [hasArrow]="false"
  [maxContentSize]="contentSize"
  placement="bottom"
  backgroundColor="white"
  class="tooltip-checkbox"
>
  <div tooltip-trigger [ngClass]="{ 'opacity-20': isDisabled, 'cursor-pointer': !isDisabled }">
    <div
      class="select-checkbox justify-between flex pt-3 pb-3"
      [ngClass]="{
      new: selectStyle === 'new',
      'border-none': noBorder,
    }"
    >
      <div class="w-10/12 flex">
        <adl-text
          class="w-full flex"
          [color]="selectStyle === 'new' && btnText[0] === 'No values selected' ? 'light-grey' : 'blue'"
          [truncate]="true"
          [size]="14"
        >
          {{ btnText[0] }}
        </adl-text>
        <adl-text
          *ngIf="btnText.length > 1"
          class="w-full flex pl-4"
          [color]="selectStyle === 'new' ? 'blue' : 'black'"
          [weight]="selectStyle === 'new' ? 'medium' : 'normal'"
          [truncate]="true"
          [size]="14"
        >
          {{ btnText[1] }}
        </adl-text>
      </div>
      <svg
        class="self-center mr-[10px]"
        width="8"
        height="8"
        viewBox="0 0 6 5"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6 1C6 0.79 5.835 0.625 5.625 0.625H0.375C0.165 0.625 0 0.79 0 1C0 1.0975 0.0375 1.18 0.0975 1.2475L2.7225 4.2475C2.79 4.3225 2.8875 4.375 3 4.375C3.1125 4.375 3.21 4.3225 3.2775 4.2475L5.9025 1.2475C5.9625 1.18 6 1.0975 6 1Z"
          fill="#8F95B2"
        />
      </svg>
    </div>
  </div>
  <div
    class="tooltip-checkbox-content overflow-y-scroll"
    tooltip-content
    [ngStyle]="{ width: contentSize ? contentSize : '100%' }"
    [class.new]="selectStyle === 'new'"
  >
    <adl-checkboxes
      *ngIf="selectStyle === 'old'"
      [control]="control"
      [checkboxes]="options"
      [isColumnFlex]="true"
      [hasSeparator]="true"
      [choiceLimit]="choiceLimit"
      [defaultValue]="defaultValue"
      (changed)="changed.emit($event)"
    >
    </adl-checkboxes>

    <adl-checkboxes-v2
      *ngIf="selectStyle === 'new'"
      [control]="control"
      [options]="options"
      [addAllOption]="addAllOption"
      [allOptionLabel]="allCheckboxLabel"
      [monoLine]="monoLine"
      [hasPaddingRight]="hasPaddingRight"
      (change)="changed.emit($event)"
    >
    </adl-checkboxes-v2>
  </div>
</adl-tooltip>
