import type { OnDestroy } from '@angular/core';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ButtonType } from '../../atoms/button/button.component';

enum UPLOAD_BUTTON_LIST {
  FILE = 'Upload file',
  FOLDER = 'Upload folder',
}

@Component({
  selector: 'adl-upload-button',
  templateUrl: './upload-button.component.html',
  styleUrls: ['upload-button.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UploadButtonComponent implements OnDestroy {
  @ViewChild('uploadButton') uploadButton: ElementRef;
  @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement>;
  @ViewChild('directoryInput') directoryInput: ElementRef<HTMLInputElement>;

  @Input() type: ButtonType = 'primary';
  @Input() size: 'normal' | 'small' = 'normal';
  @Input() multiple = false;
  @Input() disabled = false;
  @Input() isReadyToBrowse$: Observable<boolean> = of(true);
  @Input() restrictedFileTypes: string[] = [];
  @Input() isSquareButton = false;
  @Input() showDirectoryChoice = false;
  @Output() clicked = new EventEmitter<boolean>();
  @Output() changed = new EventEmitter<FileList>();

  readonly fileOrFolderButtonList = [{ name: UPLOAD_BUTTON_LIST.FILE }, { name: UPLOAD_BUTTON_LIST.FOLDER }];

  private readonly _destroyed$ = new Subject<void>();

  constructor(private readonly _renderer: Renderer2) {}

  ngOnDestroy() {
    this._destroyed$.next();
  }

  private _browseFile(inputElement: ElementRef<HTMLInputElement>): void {
    this.isReadyToBrowse$.pipe(takeUntil(this._destroyed$)).subscribe((isReadyToBrowse) => {
      this._destroyed$.next(); // to take only 1 subscription event

      if (isReadyToBrowse) {
        inputElement.nativeElement.click();
      }
    });
  }

  onUploadFileClick() {
    this._browseFile(this.fileInput);
    this.clicked.emit(true);
  }

  onUploadDirectoryClick() {
    this._browseFile(this.directoryInput);
    this.clicked.emit(true);
  }

  onFileChanged(files: FileList): void {
    this.changed.emit(files);
  }

  resetFileInput() {
    this.fileInput.nativeElement.value = '';
  }

  get formattedRestrictedFileTypes() {
    if (this.restrictedFileTypes?.length === 1) {
      return `.${this.restrictedFileTypes[0]}`;
    }

    return this.restrictedFileTypes.reduce((previousValue, currentValue) => `${previousValue},.${currentValue}`, '');
  }

  uploadButtonChosen($event: string) {
    if ($event === UPLOAD_BUTTON_LIST.FILE) {
      this.onUploadFileClick();
    } else if ($event === UPLOAD_BUTTON_LIST.FOLDER) {
      this.onUploadDirectoryClick();
    }
  }
}
