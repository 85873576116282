import type { IPublication } from '@dataportal/types';
import { Entity } from '@decahedron/entity';

type NestedCategories = Array<{ name: string; id: string }>;
export type PublicationStatus = 'published' | 'unpublished' | 'waiting publication';

export class Publication extends Entity {
  sourceId: string = null;
  portalId: string = null;
  portalName: string = null;
  sourceName: string = null;
  applicant: string = null;
  createdAt: string = null;
  status: PublicationStatus = null;
  subCategories: NestedCategories = [];
  customCategories: NestedCategories = [];
  hasBeenSeenByCurrentUser?: boolean = null;

  fromJson(data: IPublication): Publication {
    super.fromJson(data);
    this.customCategories = data.custom_categories;
    this.subCategories = data.sub_categories;
    this.portalId = data.pk;

    return this;
  }

  toJson(): any {
    return {
      source_id: this.sourceId,
      portal_id: this.portalId,
      portal_name: this.portalName,
      source_name: this.sourceName,
      applicant: this.applicant,
      status: this.status,
      sub_categories: this.subCategories,
      custom_categories: this.customCategories,
    };
  }
}

export interface IReadablePublication extends Publication {
  readableSubCategories?: string;
  readableCustomCategories?: string;
}
