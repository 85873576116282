import type { OnDestroy, OnInit } from '@angular/core';
import { Component, Inject, Input } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { CurrentUserService } from '@dataportal/auth';
import { IS_ACCOR, IS_PR } from '@dataportal/front-environment';
import { UrlService } from '@dataportal/front-shared';
import { PermissionsService } from '@dataportal/permissions';

@Component({
  selector: 'dpg-admin-topbar',
  templateUrl: './admin-topbar.component.html',
  styleUrls: ['./admin-topbar.component.scss'],
})
export class AdminTopbarComponent implements OnInit, OnDestroy {
  readonly adminEnvs: { name: string; link: string }[] = [
    {
      name: 'OAT',
      link: 'https://dataportal-oat.aws.accor.com',
    },
    {
      name: 'DEV',
      link: 'https://dataportal-dev.aws.accor.com',
    },
  ];
  readonly userEnv = this.adminEnvs[0];

  private readonly _topBarHiddenPages: string[] = [
    '/drafts/edit',
    '/sources/create',
    '/sources/edit',
    '/leons/update',
    '/leons/create',
  ];

  @Input() external = false;

  permissions: { [name: string]: boolean } = {};
  isAdmin: boolean;
  isFromV1Link = false;
  notificationsNamesToWatchForAccessRequests = ['accessRequestsNotification'];
  notificationsNamesToWatchForPublications = ['publicationsNotification'];
  isHidden: boolean;

  private readonly _destroyed$ = new Subject<void>();

  constructor(
    private readonly _permissionsService: PermissionsService,
    private readonly _currentUserService: CurrentUserService,
    private readonly _router: Router,
    readonly urlService: UrlService,
    @Inject(IS_ACCOR) readonly isAccor: boolean,
    @Inject(IS_PR) readonly isPR: boolean,
  ) {
    this._router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        tap(
          (event: NavigationEnd) => (this.isHidden = this._topBarHiddenPages.some((page) => event.url.includes(page))),
        ),
        takeUntil(this._destroyed$),
      )
      .subscribe();
  }

  get sourceLink(): string {
    this.isFromV1Link = this._router.url.split('/').includes('v1');

    return this.isFromV1Link ? '/admin/v1/sources' : '/admin/sources';
  }

  ngOnInit(): void {
    this._currentUserService.currentUser$
      .pipe(
        filter((val) => !!val),
        switchMap(() => this._permissionsService.permissionsAdmin$),
        tap((permissions) => (this.permissions = permissions)),
      )
      .subscribe();
    this._currentUserService.isAdmin$.subscribe((value) => {
      this.isAdmin = value;
    });
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
  }

  hasPermission(name: string): boolean {
    return this.isAdmin || (this.permissions && this.permissions[name]);
  }

  async goToSource() {
    if (this.external) {
      window.open(window.location.origin + this.sourceLink, '_self');
    } else {
      await this._router.navigate([this.sourceLink]);
    }
  }

  async navigateTo(url: string, forceExternal = false) {
    if (this.external || forceExternal) {
      window.open(window.location.origin + url, '_self');
    } else {
      await this._router.navigateByUrl(url);
    }
  }
}
