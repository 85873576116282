import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { Logger } from '@dataportal/front-shared';
import { EntityBuilder } from '@decahedron/entity';

import { DashboardComment } from '../entities/dashboard-comment';

@Injectable({
  providedIn: 'root',
})
export class DashboardCommentsCacheService {
  private readonly _hasToRefresh$ = new ReplaySubject<boolean>();
  private readonly _nbCachedDashboardComments$ = new BehaviorSubject<number>(0);
  private readonly _cachedDashboardComments$ = new BehaviorSubject<DashboardComment[]>([]);

  hasToRefresh$ = this._hasToRefresh$.asObservable();
  nbCachedDashboardComments$ = this._nbCachedDashboardComments$.asObservable();
  cachedDashboardComments$ = this._cachedDashboardComments$.asObservable();

  constructor(private readonly _logger: Logger) {}

  callToRefresh(isHardRefresh: boolean): void {
    this._hasToRefresh$.next(isHardRefresh);
  }

  private _getNbDashboardComments(dashboardCommentsList: DashboardComment[]): number {
    let nbDashboardComments = 0;

    if (!dashboardCommentsList?.length) {
      return 0;
    }

    nbDashboardComments = dashboardCommentsList.length;
    nbDashboardComments += dashboardCommentsList.reduce((currentNb, currentComment) => {
      if (currentComment.childrenComments?.length) {
        currentNb += this._getNbDashboardComments(currentComment.childrenComments);
      }

      return currentNb;
    }, 0);

    return nbDashboardComments;
  }

  initCachedDashboardComments(dashboardCommentsList: DashboardComment[]): void {
    const dashboardCommentsListCopy = EntityBuilder.buildMany<DashboardComment>(
      DashboardComment,
      dashboardCommentsList.map((dashboardComment) => dashboardComment.toJson()),
    );
    this._nbCachedDashboardComments$.next(this._getNbDashboardComments(dashboardCommentsListCopy));
    this._cachedDashboardComments$.next(dashboardCommentsListCopy);
  }

  private _removeCommentRec(
    cachedDashboardComments: DashboardComment[],
    commentId: string,
    parentId: string,
    commentNestingLevel: number,
    currentNestingLevel: number,
  ): void {
    if (!cachedDashboardComments?.length || currentNestingLevel >= commentNestingLevel) {
      return;
    }

    if (currentNestingLevel === commentNestingLevel - 1) {
      const parentComment = cachedDashboardComments.find((dashboardComment) => dashboardComment.id === parentId);

      if (parentComment) {
        parentComment.childrenComments = parentComment.childrenComments?.filter(
          (dashboardComment) => dashboardComment.id !== commentId,
        );
      }

      return;
    } else {
      cachedDashboardComments.forEach((dashboardComment) => {
        if (dashboardComment.childrenComments?.length) {
          this._removeCommentRec(
            dashboardComment.childrenComments,
            commentId,
            parentId,
            commentNestingLevel,
            currentNestingLevel + 1,
          );
        }
      });
    }
  }

  removeComment(commentId: string, parentId: string, commentNestingLevel: number): void {
    let cachedDashboardComments = this._cachedDashboardComments$.getValue();

    if (!cachedDashboardComments?.length) {
      return;
    }

    if (!parentId || !commentNestingLevel) {
      cachedDashboardComments = cachedDashboardComments.filter((dashboardComment) => dashboardComment.id !== commentId);
    } else {
      this._removeCommentRec(cachedDashboardComments, commentId, parentId, commentNestingLevel, 0);
    }

    this._nbCachedDashboardComments$.next(this._getNbDashboardComments(cachedDashboardComments));
    this._cachedDashboardComments$.next(cachedDashboardComments);
  }

  private _editCommentRec(
    newMessage: string,
    cachedDashboardComments: DashboardComment[],
    commentId: string,
    commentNestingLevel: number,
    currentNestingLevel: number,
  ): void {
    if (!cachedDashboardComments?.length || currentNestingLevel > commentNestingLevel) {
      return;
    }

    if (currentNestingLevel === commentNestingLevel) {
      const comment = cachedDashboardComments.find((dashboardComment) => dashboardComment.id === commentId);

      if (comment) {
        comment.message = newMessage;
        comment.updatedAt = new Date().toISOString();
      }

      return;
    } else {
      cachedDashboardComments.forEach((dashboardComment) => {
        if (dashboardComment.childrenComments?.length) {
          this._editCommentRec(
            newMessage,
            dashboardComment.childrenComments,
            commentId,
            commentNestingLevel,
            currentNestingLevel + 1,
          );
        }
      });
    }
  }

  editComment(newMessage: string, commentId: string, commentNestingLevel: number): void {
    const cachedDashboardComments = this._cachedDashboardComments$.getValue();

    if (!newMessage?.length || !cachedDashboardComments?.length) {
      return;
    }

    this._editCommentRec(newMessage, cachedDashboardComments, commentId, commentNestingLevel, 0);
    this._cachedDashboardComments$.next(cachedDashboardComments);
  }
}
