import type { SafeResourceUrl } from '@angular/platform-browser';
import type { BehaviorSubject, Observable, Subject } from 'rxjs';
import type { AccessRequestV2, ISupportedARResource } from '@dataportal/access-requests';
import type { GlossaryComponentWithNotificationInfo } from '@dataportal/business-glossary-common';
import type { Publication } from '@dataportal/publications';
import type { DashboardComment, UserRecommendation } from '@dataportal/sources-dashboards-recommendations';
import type {
  BackNotificationStatus,
  BackNotificationType,
  DANotificationResource,
  FeatureRequestStatus,
  IDataAcademyCommentNotification,
} from '@dataportal/types';
import { Entity } from '@decahedron/entity';

export type NotificationType =
  | 'accessRequest'
  | 'shared'
  | 'dashboardComment'
  | 'dashboardCommentReply'
  | 'publication'
  | 'glossary'
  | 'recommendation'
  | BackNotificationType;

export type NotificationResourceType =
  | UserRecommendation
  | AccessRequestV2
  | Publication
  | DashboardComment
  | GlossaryComponentWithNotificationInfo
  | DataAcademyCommentNotification;

export type NotificationStatus =
  | BackNotificationStatus
  | 'pending'
  | 'approved'
  | 'denied'
  | 'canceled'
  | 'validated'
  | 'CANDIDATE'
  | 'TO COMPLETE'
  | 'COMPLETED'
  | 'VALIDATED'
  | 'REJECTED'
  | 'OBSOLETE';

export interface IAdminNotificationSubjectAndObservable {
  subject$: BehaviorSubject<number>;
  observable$: Observable<number>;
}

export type AdminNotificationsList = Map<string, IAdminNotificationSubjectAndObservable>;

export interface IAdminNotificationEvent {
  name: string;
  event$: Observable<unknown>;
  subscribeUntil$?: Subject<void>;
}

export interface IAdminNotification {
  name: string;
  total: number;
}

export interface INotificationV2 {
  /**
   * The ID of the user at the origin of the notification
   */
  notifierId: string;
  /**
   * The name of the user at the origin of the notification
   */
  notifierName?: string;
  /**
   * The notification type
   */
  type: NotificationType;
  /**
   * The time when the notification has been created
   */
  creationDate: Date;
  /**
   * The time when the notification has last been updated
   */
  updatingDate?: Date;
  /**
   * Has notification already been seen by the user
   */
  isSeen: boolean;
  /**
   * The status of the resource link to the notification, used for Publication, Access Request and Glossary
   */
  status?: NotificationStatus;
  /**
   * Used only for Access Request Notification, specify if this a Data Asset or a Leon Access Request
   */
  resourceType?: ISupportedARResource | DANotificationResource;
  /**
   * The name of the potential resource linked to the Notification
   */
  resourceRequestedName?: string;
  /**
   * The ID of the potential resource linked to the Notification
   */
  resourceRequestedId?: string;
  /**
   * Only for Publication Notification, The name of the portal linked to the Notification
   */
  secondaryResourceName?: string;
  /**
   * Only for Publication Notification, The ID of the portal linked to the Notification
   */
  secondaryResourceId?: string;
  /**
   * Only for Dashboard Comment, Id of the resource to redirect correctly the user when he clicks the notification
   */
  commentId?: string;
  /**
   * Only for Dashboard Comment, Id of the resource to redirect correctly the user when he clicks the notification
   */
  parentId?: string;
  /**
   * Only for Dashboard Comment, Id of the resource to redirect correctly the user when he clicks the notification
   */
  message?: string;
  mainMessage?: string;
  linkToResource?: string;
  /**
   * Indicate in which tab the notification should be
   */
  tabToDisplay?: 'notifications' | 'requestedActions';
  pk?: string;
}

export class DataAcademyCommentNotification extends Entity implements IDataAcademyCommentNotification {
  pk: string;
  sk: string;
  ak: string;
  creationDate: string;
  entityId: string;
  entityName: string;
  entityType: DANotificationResource;
  message: string;
  notificationType: BackNotificationType;
  notifierId: string;
  isSeen: boolean;
  status?: FeatureRequestStatus;
}

export interface INotificationV2Display extends INotificationV2 {
  picture: SafeResourceUrl;
}
