import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { Logger } from '@dataportal/front-shared';
import type { FormControl, FormGroup } from '@ngneat/reactive-forms';
import { FormBuilder } from '@ngneat/reactive-forms';

import type { Source } from '../../../entities/source';
import type {
  IDataCategorizationFormData,
  IDataCertification,
  IDataManagementFormData,
  IDataType,
  IMasterData,
} from '../../../entities/source-form';

@Injectable({
  providedIn: 'root',
})
export class DataManagementFormService {
  private _dataManagementForm: FormGroup<IDataManagementFormData>;
  private _controllersEnablementSubscriptions = Subscription.EMPTY;
  private _relatedSource: Source;
  private _isPublished;

  private readonly _formReady$ = new BehaviorSubject<boolean>(false);
  formReady$ = this._formReady$.asObservable();

  constructor(private readonly _logger: Logger, private readonly _formBuilder: FormBuilder) {}

  getForm(): FormGroup<IDataManagementFormData> {
    return this._dataManagementForm;
  }

  init(source: Source, isPublished: boolean): void {
    this._relatedSource = source;
    this._isPublished = isPublished;

    this._dataManagementForm = this._initDataManagement();

    this._formReady$.next(true);
  }

  private _initDataManagement(): FormGroup<IDataManagementFormData> {
    this._controllersEnablementSubscriptions.unsubscribe();

    const dataClassificationDataForm = this._formBuilder.group({
      dataSourcing: [
        this._relatedSource ? this._relatedSource.dataClassification?.dataSourcing : undefined,
        Validators.required,
      ],
      sourceOrProviderNames: [
        this._relatedSource ? this._relatedSource.dataClassification?.sourceOrProviderNames : undefined,
        Validators.required,
      ],
      dataConfidentiality: [
        this._relatedSource ? this._relatedSource.dataClassification?.dataConfidentiality : undefined,
        Validators.required,
      ],
      gdprCompliance: [
        this._relatedSource ? this._relatedSource.dataClassification?.gdprCompliance : undefined,
        Validators.required,
      ],
      dataSharingLimits: [
        this._relatedSource ? this._relatedSource.dataClassification?.dataSharingLimits : undefined,
        Validators.required,
      ],
      limitationScope: [
        this._relatedSource ? this._relatedSource.dataClassification?.limitationScope : '',
        Validators.required,
      ],
    }) as FormGroup<IDataCategorizationFormData>;

    this._controllersEnablementSubscriptions = (
      dataClassificationDataForm.get('limitationScope') as FormControl
    ).enabledWhile(
      (dataClassificationDataForm.get('dataSharingLimits') as FormControl).value$.pipe(
        map((dataSharingLimit) => dataSharingLimit === 'restricted-usage'),
      ),
    );

    const isMasterData = this._formBuilder.group({
      isMasterData: this._relatedSource ? this._relatedSource.masterData : false,
    }) as FormGroup<IMasterData>;

    const sourceDataType = this._formBuilder.group({
      dataType: [this._relatedSource ? this._relatedSource.dataType : null, Validators.required],
    }) as FormGroup<IDataType>;

    const dataCertificationForm = this._formBuilder.group({
      isCertified: [this._relatedSource ? this._relatedSource.dataCertification?.isCertified : false],
      certificationDate: [
        this._relatedSource?.dataCertification?.certificationDate
          ? this._relatedSource.dataCertification?.certificationDate
          : null,
      ],
      businessCertifier: [
        this._relatedSource?.dataCertification?.businessCertifier
          ? this._relatedSource.dataCertification?.businessCertifier
          : null,
        [Validators.required, Validators.maxLength(25)],
      ],
    }) as FormGroup<IDataCertification>;

    this._controllersEnablementSubscriptions.add(
      (dataCertificationForm.get('businessCertifier') as FormControl).enabledWhile(
        (dataCertificationForm.get('isCertified') as FormControl).value$,
      ),
    );

    return this._formBuilder.group({
      dataClassification: dataClassificationDataForm,
      masterData: isMasterData,
      dataType: sourceDataType,
      dataCertification: dataCertificationForm,
    });
  }
}
