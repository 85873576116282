import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { merge, Observable, Subject } from 'rxjs';
import { delay, take, takeUntil } from 'rxjs/operators';
import { DatalakeObjectSelectorModalComponent } from '@dataportal/datalake-and-guardian';
import { DatalakePath, Logger } from '@dataportal/front-shared';
import type {
  IGuardianCheckScheduling,
  IGuardianCheckSchedulingStartingTime,
  IGuardianStepCheckTriggers,
  SchedulingPeriodicity,
  WeekDay,
} from '@dataportal/guardian-utils';
import {
  GuardianService,
  IGuardianDatalakePath,
  ISupportedGuardianChecksResource,
  SchedulingDailyChoices,
} from '@dataportal/guardian-utils';
import type { AbstractControl, FormArray } from '@ngneat/reactive-forms';
import { FormGroup } from '@ngneat/reactive-forms';

interface IErrorIdentification {
  errorName: string;
  errorAttribute: string;
}

interface IRequiredAndPositiveNumberError {
  required: IErrorIdentification;
  positive: IErrorIdentification & { canBeZero: boolean };
  multipleOf?: IErrorIdentification & { value: number };
  max: IErrorIdentification & { value: number };
}

@Component({
  selector: 'app-guardian-step-check-triggers',
  templateUrl: './guardian-step-check-triggers.component.html',
  styleUrls: ['./guardian-step-check-triggers.component.scss'],
})
export class GuardianStepCheckTriggersComponent {
  // inputs
  @Input() formReady$: Observable<boolean>;
  @Input() formGroup: FormGroup<IGuardianStepCheckTriggers>;
  @Input() currentPath: IGuardianDatalakePath;
  @Input() checkType: ISupportedGuardianChecksResource;

  // constants
  controlsFixedSizeNum = 35;
  controlsFixedSize = `${this.controlsFixedSizeNum}vw`;
  controlsFixedHalfSize = `calc(${this.controlsFixedSizeNum / 2}vw - 10px)`;
  minuteMultipleOf = 5;
  minuteMaxValue = 59;
  minuteMinValue = 0;

  // variables
  isFormReady = false;
  hasStartingMinuteRequiredError = false;
  hasStartingMinuteMustBePositiveNumberError = false;
  hasStartingMinuteMustBeMax59Error = false;
  hasStartingMinuteMustBeAMultipleOf5Error = false;
  hasDailyEveryNumberOfDaysRequiredError = false;
  hasDailyEveryNumberOfDaysMustBeStrictlyPositiveNumberError = false;
  hasDailyEveryNumberOfDaysMustBeMax31Error = false;
  hasMonthlyDayNumberRequiredError = false;
  hasMonthlyDayNumberMustBeStrictlyPositiveNumberError = false;
  hasMonthlyDayNumberMustBeMax31Error = false;
  hasYearlyDayNumberRequiredError = false;
  hasYearlyDayNumberMustBeStrictlyPositiveNumberError = false;
  hasYearlyDayNumberMustBeMax31Error = false;

  private readonly _destroyed$ = new Subject<void>();

  constructor(
    readonly guardianService: GuardianService,
    private readonly _logger: Logger,
    private readonly _modalMatService: MatDialog,
  ) {}

  ngOnInit(): void {
    this.formReady$.pipe(takeUntil(this._destroyed$)).subscribe((isFormReady) => {
      this.isFormReady = isFormReady;

      if (isFormReady) {
        if (this.checkType === 'datalakePath') {
          this._subscribeSuccessFailurePathsErrors();
        }

        this._subscribeShedulingStartingMinuteErrors();
        this._subscribeShedulingDailyEveryNumberOfDaysErrors();
        this._subscribeSchedulingWeeklySelectedDaysErrors();
        this._subscribeSchedulingMonthlyDayNumberErrors();
        this._subscribeSchedulingYearlyDayNumberErrors();
      }
    });
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
  }

  get hasSelectedMoveSuccess(): boolean {
    return this.formGroup?.get('hasToMoveIfSuccess')?.value === true;
  }

  get hasSelectedMoveFailure(): boolean {
    return this.formGroup?.get('hasToMoveIfFailure')?.value === true;
  }

  get hasSelectedMove(): boolean {
    return this.hasSelectedMoveSuccess || this.hasSelectedMoveFailure;
  }

  get hasSelectedScheduling(): boolean {
    return this.formGroup?.get('hasToScheduleChecks')?.value === true;
  }

  get schedulingFormGroup(): FormGroup<IGuardianCheckScheduling> {
    return this.formGroup?.get('schedulingInfos') as FormGroup<IGuardianCheckScheduling>;
  }

  get selectedSchedulingPeriodicity(): SchedulingPeriodicity {
    return this.schedulingFormGroup?.get('schedulingPeriodicity')?.value || 'daily';
  }

  get schedulingStartingTimeFormGroup(): FormGroup<IGuardianCheckSchedulingStartingTime> {
    return this.schedulingFormGroup?.get('schedulingStartingTime') as FormGroup<IGuardianCheckSchedulingStartingTime>;
  }

  get schedulingDailyChoices(): string[] {
    return Object.values(SchedulingDailyChoices);
  }

  get schedulingDailyChoiceSelected(): string {
    return this.schedulingFormGroup?.get('schedulingDailyChoiceSelected')?.value?.toString();
  }

  get weekDaysIndex(): { label: string; value: WeekDay }[] {
    const daysIndex = this.guardianService.weekDaysIndex;

    return [daysIndex[0], daysIndex[4], daysIndex[1], daysIndex[5], daysIndex[2], daysIndex[6], daysIndex[3]];
  }

  onItrackOptionChanged(hasToUseItrack: boolean): void {
    if (hasToUseItrack) {
      this.formGroup.controls.hasToMoveIfSuccess.setValue(true);
      this.formGroup.controls.hasToMoveIfFailure.setValue(true);
      this.formGroup.controls.hasToEnforceReplacementIfSameName.setValue(true);
      this.formGroup.controls.hasToRenameDestinationFile.setValue(true);
    }
  }

  private _subscribeSuccessFailurePathsErrors(): void {
    const successPathControl = this.formGroup.get<'successPath'>(['successPath']);
    const failurePathControl = this.formGroup.get<'failurePath'>(['failurePath']);
    merge(this.formGroup.value$, successPathControl.dirty$, failurePathControl.dirty$)
      .pipe(takeUntil(this._destroyed$), delay(1))
      .subscribe(() => {
        const errors = this.formGroup.errors || {};
        successPathControl.setErrors(errors.successPathRequired ? { successPathRequired: true } : null);
        failurePathControl.setErrors(errors.failurePathRequired ? { failurePathRequired: true } : null);
      });
  }

  private subscribeRequiredAndPositiveNumberAndMaxInputErrors(
    control: AbstractControl,
    errorAttributes: IRequiredAndPositiveNumberError,
    checkCondition: () => boolean,
  ): void {
    merge(this.formGroup.value$, control.dirty$)
      .pipe(takeUntil(this._destroyed$), delay(1))
      .subscribe((v) => {
        let hasError = false;
        const errors: { [key: string]: unknown } = {};

        if (checkCondition()) {
          if (!control?.value?.toString().length) {
            hasError = true;
            errors[errorAttributes.required.errorName] = true;
            this[errorAttributes.required.errorAttribute] = true;
            this[errorAttributes.positive.errorAttribute] = false;
            this[errorAttributes.max.errorAttribute] = false;
          } else if (
            isNaN(control.value) ||
            parseInt(control.value, 10) <= (errorAttributes.positive.canBeZero ? -1 : 0)
          ) {
            hasError = true;
            errors[errorAttributes.positive.errorName] = true;
            this[errorAttributes.positive.errorAttribute] = true;
            this[errorAttributes.required.errorAttribute] = false;
            this[errorAttributes.max.errorAttribute] = false;
          } else if (
            errorAttributes.max &&
            !isNaN(control.value) &&
            parseInt(control.value, 10) > errorAttributes.max.value
          ) {
            hasError = true;
            errors[errorAttributes.max.errorName] = true;
            this[errorAttributes.max.errorAttribute] = true;
            this[errorAttributes.required.errorAttribute] = false;
            this[errorAttributes.positive.errorAttribute] = false;
          } else if (!!errorAttributes.multipleOf && control.value % errorAttributes.multipleOf.value !== 0) {
            hasError = true;
            errors[errorAttributes.multipleOf.errorName] = true;
            this[errorAttributes.multipleOf.errorAttribute] = true;
            this[errorAttributes.required.errorAttribute] = false;
            this[errorAttributes.positive.errorAttribute] = false;
            this[errorAttributes.max.errorAttribute] = false;
          } else {
            this[errorAttributes.positive.errorAttribute] = false;
            this[errorAttributes.required.errorAttribute] = false;
            this[errorAttributes.max.errorAttribute] = false;
          }
        } else {
          this[errorAttributes.positive.errorAttribute] = false;
          this[errorAttributes.required.errorAttribute] = false;
          this[errorAttributes.max.errorAttribute] = false;
        }

        control.setErrors(hasError ? errors : null);
      });
  }

  private _subscribeShedulingStartingMinuteErrors(): void {
    const control = this.schedulingStartingTimeFormGroup?.get('schedulingStartingMinute');
    this.subscribeRequiredAndPositiveNumberAndMaxInputErrors(
      control,
      {
        required: {
          errorName: 'startingMinuteRequired',
          errorAttribute: 'hasStartingMinuteRequiredError',
        },
        positive: {
          errorName: 'startingMinuteMustBePositiveNumber',
          errorAttribute: 'hasStartingMinuteMustBePositiveNumberError',
          canBeZero: true,
        },
        multipleOf: {
          errorName: 'startingMinuteMustBeAMultipleOf5',
          errorAttribute: 'hasStartingMinuteMustBeAMultipleOf5Error',
          value: this.minuteMultipleOf,
        },
        max: {
          errorName: 'startingMinuteMustBeMax59',
          errorAttribute: 'hasStartingMinuteMustBeMax59Error',
          value: this.minuteMaxValue,
        },
      },
      () => this.hasSelectedScheduling,
    );
  }

  private _subscribeShedulingDailyEveryNumberOfDaysErrors(): void {
    const control = this.schedulingFormGroup?.get('schedulingDailyEveryNumberOfDays');
    this.subscribeRequiredAndPositiveNumberAndMaxInputErrors(
      control,
      {
        required: {
          errorName: 'dailyEveryNumberOfDaysRequired',
          errorAttribute: 'hasDailyEveryNumberOfDaysRequiredError',
        },
        positive: {
          errorName: 'dailyEveryNumberOfDaysMustBeStrictlyPositiveNumber',
          errorAttribute: 'hasDailyEveryNumberOfDaysMustBeStrictlyPositiveNumberError',
          canBeZero: false,
        },
        max: {
          errorName: 'dailyEveryNumberOfDaysMustBeMax31',
          errorAttribute: 'hasDailyEveryNumberOfDaysMustBeMax31Error',
          value: 31,
        },
      },
      () =>
        this.hasSelectedScheduling &&
        this.selectedSchedulingPeriodicity === 'daily' &&
        this.schedulingDailyChoiceSelected === 'EVERY_N_DAYS',
    );
  }

  private _subscribeSchedulingWeeklySelectedDaysErrors(): void {
    const control = this.schedulingFormGroup?.get('schedulingWeeklySelectedDays') as FormArray<WeekDay>;
    merge(this.formGroup.value$, control.dirty$)
      .pipe(takeUntil(this._destroyed$), delay(1))
      .subscribe((v) => {
        let hasError = false;
        const errors: { [key: string]: unknown } = {};

        if (this.hasSelectedScheduling && this.selectedSchedulingPeriodicity === 'weekly') {
          if (!control?.length) {
            hasError = true;
            errors.atLeastOneSelectedDayRequired = true;
          }
        }

        control.setErrors(hasError ? errors : null);
      });
  }

  private _subscribeSchedulingMonthlyDayNumberErrors(): void {
    const control = this.schedulingFormGroup?.get('schedulingMonthlyDayNumber');
    this.subscribeRequiredAndPositiveNumberAndMaxInputErrors(
      control,
      {
        required: {
          errorName: 'monthlyDayNumberRequired',
          errorAttribute: 'hasMonthlyDayNumberRequiredError',
        },
        positive: {
          errorName: 'monthlyDayNumberMustBeStrictlyPositiveNumber',
          errorAttribute: 'hasMonthlyDayNumberMustBeStrictlyPositiveNumberError',
          canBeZero: false,
        },
        max: {
          errorName: 'monthlyDayNumberMustBeMax31',
          errorAttribute: 'hasMonthlyDayNumberMustBeMax31Error',
          value: 31,
        },
      },
      () => this.hasSelectedScheduling && this.selectedSchedulingPeriodicity === 'monthly',
    );
  }

  private _subscribeSchedulingYearlyDayNumberErrors(): void {
    const control = this.schedulingFormGroup?.get('schedulingYearlyDayNumber');
    this.subscribeRequiredAndPositiveNumberAndMaxInputErrors(
      control,
      {
        required: {
          errorName: 'yearlyDayNumberRequired',
          errorAttribute: 'hasYearlyDayNumberRequiredError',
        },
        positive: {
          errorName: 'yearlyDayNumberMustBeStrictlyPositiveNumber',
          errorAttribute: 'hasYearlyDayNumberMustBeStrictlyPositiveNumberError',
          canBeZero: false,
        },
        max: {
          errorName: 'yearlyDayNumberMustBeMax31',
          errorAttribute: 'hasYearlyDayNumberMustBeMax31Error',
          value: 31,
        },
      },
      () => this.hasSelectedScheduling && this.selectedSchedulingPeriodicity === 'yearly',
    );
  }

  updateSchedulingDailyChoiceSelected(selectedChoice: string): void {
    this.schedulingFormGroup
      .get('schedulingDailyChoiceSelected')
      ?.setValue(selectedChoice?.length ? SchedulingDailyChoices[selectedChoice] : null);
  }

  openDatalakeExplorer(isSuccessPath: boolean): void {
    const control = isSuccessPath ? this.formGroup.get('successPath') : this.formGroup.get('failurePath');
    const currentFolder = DatalakeObjectSelectorModalComponent.datalakePathToFolder(
      new DatalakePath(
        `${this.currentPath.filesystem}/${this.currentPath.path}`,
        this.currentPath.provider as 'aws' | 'azure',
        this.currentPath.tenant,
      ),
      false,
      true, // once we are here, current path exists
      false,
    );
    const datalakeExplorerModal = this._modalMatService.open(DatalakeObjectSelectorModalComponent, {
      width: '1002px',
      minWidth: '1002px',
      maxHeight: '98vh',
      backdropClass: 'modal-nested',
      data: {
        title:
          'Select the relevant directory for ' + (isSuccessPath ? 'accepted' : 'rejected') + ' files by ticking it',
        isMultipleSelect: false,
        isListingUsingOwnToken: false,
        defaultFolder: currentFolder,
        selectedObjects: [],
      },
    });
    datalakeExplorerModal
      .afterClosed()
      .pipe(take(1), takeUntil(this._destroyed$))
      .subscribe(
        (selectedPaths: DatalakePath[]) => {
          if (selectedPaths?.length) {
            const selectedPath = selectedPaths[0].path;
            const selectedPathSegments = selectedPath.split('/');

            if (selectedPathSegments?.length) {
              selectedPathSegments.shift();
            }

            const selectedPathWithoutBucket = selectedPathSegments.join('/');
            // we replace by the real bucket that will be take into account during guardian check
            // it is better to open a datalake browser with filtered bucket/tenant according to the check's bucket/tenant
            const realSelectedPath = `${currentFolder.filesystem?.name}/${selectedPathWithoutBucket}`;
            control.setValue(realSelectedPath);
          }

          control.markAsDirty();
        },
        () => {
          this._logger.debug('[GuardianConfig] Exiting datalake explorer modal');
        },
      );
  }
}
