import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApiUrlService } from '@dataportal/front-api';
import { Logger } from '@dataportal/front-shared';

import { SyncAdModule } from '../sync-ad.module';

@Injectable({
  providedIn: SyncAdModule,
})
export class SyncAdService {
  constructor(
    private readonly _http: HttpClient,
    private readonly _urls: ApiUrlService,
    private readonly _logger: Logger,
  ) {}

  getLastSync(type: 'users' | 'groups'): Observable<string> {
    return this._http.get<string>(this._urls.buildUrl(`/v4/misc/syncad/${type}`)).pipe(
      catchError((e: unknown) => {
        this._logger.info('Error retrieving last sync-ad', e);

        return of('');
      }),
    );
  }
}
