<adl-modal
  class="adl-dialog-modal"
  headerBackgroundColor="blue-dark"
  backgroundColor="light"
  [isAlignedToCross]="true"
  [roundCorner]="false"
  closeButtonColor="white"
  textAlignment="left"
>
  <div modal-header class="flex gap-[20px] px-[54px] py-[20px] items-center">
    <img src="assets/dpg/imgs/icons/datalake-logo.svg" class="w-[27px] h-[37px]" />
    <adl-title type="modal" color="#ffffff">{{ fileName }}</adl-title>
  </div>
  <div modal-body class="pt-[17px] pb-[23px] px-[54px] w-full my-0">
    <div class="grid grid-row-[auto_1fr_auto] gap-[13px]">
      <img *ngIf="isLoading" src="assets/dpg/imgs/loading.gif" alt="loading" />

      <ng-container *ngIf="!isLoading">
        <h3 *ngIf="isFileContentTypeIncorrect" class="datalake-edit--info-msg">
          This following file content type is not allowed : "{{ fileContentType }}"
        </h3>

        <ng-container *ngIf="!isFileContentTypeIncorrect">
          <h3 *ngIf="isFileTooBig" class="datalake-edit--info-msg">
            File too big (max size for {{ fileExtension.toUpperCase() }} file is {{ maxSizeEditCSV }} MB)
          </h3>

          <ng-container *ngIf="!isFileTooBig">
            <h3 *ngIf="hasErrorInParsing" class="datalake-edit--info-msg">
              Unable to display file (wrong file encoding or format)
            </h3>

            <ng-container *ngIf="!hasErrorInParsing && csvDataset">
              <header class="flex items-center gap-4">
                <adl-text type="hint" class="grow">Double click to edit value</adl-text>

                <adl-button type="primary-dark" class="header-button" (click)="downloadFile()">
                  <img src="assets/dpg/imgs/icons/datalake-download-file.svg" alt="green check" class="flex" />
                </adl-button>

                <a [href]="openInNewTabUrl" target="_blank">
                  <adl-button type="primary-dark" class="header-button">
                    <img src="assets/dpg/imgs/icons/datalake-open-in-new-tab.svg" alt="green check" class="flex" />
                  </adl-button>
                </a>
              </header>

              <dpg-csv-datatable
                [rows]="currentRowsForPage"
                [columns]="csvDataset.columns"
                [rowsPerPage]="paginationNbPerPageMax"
                [totalRows]="csvDataset.rows.length"
                [currentPageNumber]="currentPageNumber"
                [editable]="true"
                (pageChange)="setPaginationPage($event, 0)"
                (cellEdit)="updateValue($event)"
              ></dpg-csv-datatable>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </div>
  <div modal-footer class="grid place-items-center">
    <adl-button
      (click)="upload()"
      [disabled]="!hasBeenModified"
      [ngClass]="{ 'datalake-edit--disabled': !hasBeenModified || isSaving }"
      >{{ isSaving ? 'Saving' : 'Save' }} modifications{{ isSaving ? '...' : '' }}</adl-button
    >
  </div>
</adl-modal>
