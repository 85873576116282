import type { OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import { EMPTY, Subject } from 'rxjs';
import { catchError, first, take, takeUntil, tap } from 'rxjs/operators';
import type { ISupportedARResource } from '@dataportal/access-requests';
import { AccessRequestsV2Service } from '@dataportal/access-requests';
import { DialogsService } from '@dataportal/adl';
import { GlossaryCommentModalComponent, GlossaryComponentsService } from '@dataportal/business-glossary-common';
import { Logger } from '@dataportal/front-shared';
import type { INotificationV2, INotificationV2Display } from '@dataportal/notifications';
import { NotificationsV2Service } from '@dataportal/notifications';
import { PublicationsService } from '@dataportal/publications';
import { DashboardCommentsService, RecommendationsService } from '@dataportal/sources-dashboards-recommendations';
import { BackNotificationType } from '@dataportal/types';

@Component({
  selector: 'dpg-notification-center',
  templateUrl: './notification-center.component.html',
  styleUrls: ['./notification-center.component.scss'],
})
export class NotificationCenterComponent implements OnInit {
  @Input() currentUserName: string;
  notifications: INotificationV2Display[];

  onlyNewNotification = false;
  tabs = ['All', 'Notifications', 'Requested actions'];
  tabsContent: INotificationV2Display[][] = [];
  tabsNotificationNumber = [0, 0, 0];
  activeTab = 'All';

  private readonly _destroyed$ = new Subject<void>();

  constructor(
    private readonly _accessRequestsV2Service: AccessRequestsV2Service,
    private readonly _publicationsService: PublicationsService,
    private readonly _dashboardCommentsService: DashboardCommentsService,
    private readonly _notificationV2Service: NotificationsV2Service,
    private readonly _glossaryComponentsService: GlossaryComponentsService,
    private readonly _recommendationService: RecommendationsService,
    private readonly _dialogsService: DialogsService,
    private readonly _logger: Logger,
  ) {}

  ngOnInit(): void {
    this._notificationV2Service.currentUserNotifications$
      .pipe(takeUntil(this._destroyed$))
      .subscribe((notifications) => {
        notifications.sort((firstNotif, secondNotif) => {
          let firstNotifDate = firstNotif.creationDate;
          let secondNotifDate = secondNotif.creationDate;

          if (firstNotif.updatingDate) {
            firstNotifDate = firstNotif.updatingDate;
          }

          if (secondNotif.updatingDate) {
            secondNotifDate = secondNotif.updatingDate;
          }

          return secondNotifDate.getTime() - firstNotifDate.getTime();
        });
        this.notifications = notifications;
        this.onlyNewNotification = true;
        this.tabsNotificationNumber[1] = this.notificationFilteredList.length;
        this.tabsNotificationNumber[2] = this.requestedActionsFilteredList.length;
        this.tabsNotificationNumber[0] = this.tabsNotificationNumber[1] + this.tabsNotificationNumber[2];
        this.onlyNewNotification = false;
        this.tabsContent = [notifications, this.notificationFilteredList, this.requestedActionsFilteredList];
      });
  }

  async openNotification(notification: INotificationV2) {
    let suffixLeadingToCommentSection = '';

    switch (notification.type) {
      case 'recommendation': {
        if (!notification.isSeen) {
          this._recommendationService
            .update(notification.resourceRequestedId, notification.notifierId, notification.secondaryResourceId, true)
            .pipe(
              tap(() => (notification.isSeen = true)),
              catchError((error: unknown) => {
                this._logger.error('Error while updating Recommendation : ', error);

                return EMPTY;
              }),
              takeUntil(this._destroyed$),
            )
            .subscribe();
        }

        if (notification.linkToResource) {
          window.open(notification.linkToResource);
        }

        break;
      }

      case 'accessRequest': {
        if (!notification.isSeen) {
          this._accessRequestsV2Service
            .hasSeen(
              notification.notifierId,
              notification.resourceType as ISupportedARResource,
              notification.resourceRequestedId,
            )
            .pipe(
              tap(() => (notification.isSeen = true)),
              catchError((error: unknown) => {
                this._logger.error('Error while updating Notification : ', error);

                return EMPTY;
              }),
              takeUntil(this._destroyed$),
            )
            .subscribe();
          const seenNotificationIndex = this.notifications.findIndex((notif) => notif === notification);
          this.notifications[seenNotificationIndex].isSeen = true;
          this.tabsNotificationNumber[2] -= 1;
          this.tabsNotificationNumber[0] -= 1;
        }

        window.open(`/admin/access-requests?source=${encodeURIComponent(notification.resourceRequestedId)}`);
        break;
      }

      case 'publication': {
        if (!notification.isSeen) {
          this._publicationsService
            .hasSeen(notification.resourceRequestedId, notification.secondaryResourceId)
            .pipe(
              tap(() => (notification.isSeen = true)),
              catchError((error: unknown) => {
                this._logger.error('Error while updating Notification : ', error);

                return EMPTY;
              }),
              takeUntil(this._destroyed$),
            )
            .subscribe();
          const seenNotificationIndex = this.notifications.findIndex((notif) => notif === notification);
          this.notifications[seenNotificationIndex].isSeen = true;
          this.tabsNotificationNumber[2] -= 1;
          this.tabsNotificationNumber[0] -= 1;
        }

        window.open(
          `/admin/portals/edit/${notification.secondaryResourceId}?tab=dataAssets&source=${notification.resourceRequestedName}`,
        );
        break;
      }

      case 'dashboardComment':

      case 'dashboardCommentReply': {
        if (!notification.isSeen) {
          this._dashboardCommentsService
            .hasSeen(notification.commentId, notification.secondaryResourceId, notification.resourceRequestedId)
            .pipe(
              tap(() => (notification.isSeen = true)),
              catchError((error: unknown) => {
                this._logger.error('Error while updating Notification : ', error);

                return EMPTY;
              }),
              takeUntil(this._destroyed$),
            )
            .subscribe();
          const seenNotificationIndex = this.notifications.findIndex((notif) => notif === notification);
          this.notifications[seenNotificationIndex].isSeen = true;
          this.tabsNotificationNumber[1] -= 1;
          this.tabsNotificationNumber[0] -= 1;
        }

        const commentType =
          notification.type === 'dashboardCommentReply' ? notification.parentId : notification.commentId;
        window.open(
          `/sources/${notification.secondaryResourceId}/dashboard/${notification.resourceRequestedName}?comment=${commentType}&comment_type=${notification.type}`,
        );
        break;
      }

      case 'glossary': {
        if (!notification.isSeen) {
          this._glossaryComponentsService
            .updateNotification(notification.resourceRequestedId)
            .pipe(
              tap(() => (notification.isSeen = true)),
              catchError((error: unknown) => {
                this._logger.error('Error while updating Notification : ', error);

                return EMPTY;
              }),
              takeUntil(this._destroyed$),
            )
            .subscribe();
          const seenNotificationIndex = this.notifications.findIndex((notif) => notif === notification);
          this.notifications[seenNotificationIndex].isSeen = true;
          this.tabsNotificationNumber[1] -= 1;
          this.tabsNotificationNumber[0] -= 1;
        }

        switch (notification.status) {
          case 'CANDIDATE':
          case 'TO COMPLETE':
          case 'VALIDATED':
          case 'COMPLETED':
            window.open(`/glossary/${notification.resourceRequestedId}`);
            break;
          case 'REJECTED':
            this._dialogsService
              .open<GlossaryCommentModalComponent>(GlossaryCommentModalComponent, {
                comment: notification.message,
                componentsName: notification.resourceRequestedName,
                notifierId: notification.notifierId,
              })
              .pipe(take(1))
              .subscribe();
            break;
        }

        break;
      }

      case BackNotificationType.DA_OWNER:
      case BackNotificationType.DA_TAGGED:
      case BackNotificationType.DA_FR_OWNER:
      case BackNotificationType.DA_FR_TAGGED:
      case BackNotificationType.DA_REPLIED:
      case BackNotificationType.DA_REPLIED_TAGGED:
      case BackNotificationType.DA_FR_REPLIED:
      case BackNotificationType.DA_FR_REPLIED_TAGGED:
      case BackNotificationType.DA_FR_UPDATED:
        switch (notification.resourceType) {
          case 'product':
            suffixLeadingToCommentSection = '?tab=Comments';
            break;
          case 'service':
          case 'article':
          case 'project':
            suffixLeadingToCommentSection = '#Comments';
            break;
        }

        if (notification.resourceType === 'service') {
          window.open(
            `/academy/products/service/${encodeURIComponent(
              notification.resourceRequestedId,
            )}${suffixLeadingToCommentSection}`,
          );
        } else {
          window.open(
            `/academy/${encodeURIComponent(notification.resourceType)}s/${encodeURIComponent(
              notification.resourceRequestedId,
            )}/${encodeURIComponent(notification.resourceRequestedName)}${suffixLeadingToCommentSection}`,
          );
        }

        if (!notification.isSeen) {
          this._notificationV2Service
            .postHasSeenNotification(notification?.pk)
            .pipe(
              tap(() => (notification.isSeen = true)),
              first(),
            )
            .subscribe();
        }

        break;
    }
  }

  toggleSwitch() {
    this.onlyNewNotification = !this.onlyNewNotification;
    this.tabsContent = [
      this.allNotificationsFilteredList,
      this.notificationFilteredList,
      this.requestedActionsFilteredList,
    ];
  }

  get allNotificationsFilteredList() {
    if (this.onlyNewNotification) {
      return this.notifications.filter((notification) => notification.isSeen === false);
    } else {
      return this.notifications;
    }
  }

  get notificationFilteredList() {
    const tmpNotifications = this.notifications.filter((notification) => notification.tabToDisplay === 'notifications');

    if (this.onlyNewNotification) {
      return tmpNotifications.filter((notification) => notification.isSeen === false);
    } else {
      return tmpNotifications;
    }
  }

  get requestedActionsFilteredList() {
    const tmpNotifications = this.notifications.filter(
      (notification) => notification.tabToDisplay === 'requestedActions',
    );

    if (this.onlyNewNotification) {
      return tmpNotifications.filter((notification) => notification.isSeen === false);
    } else {
      return tmpNotifications;
    }
  }

  get switchTextColor(): string {
    return this.onlyNewNotification ? 'yellow' : 'grey-600';
  }

  get numberOfUnseenNotification(): number {
    return this.notifications.filter((notification) => notification.isSeen === false).length;
  }
}
