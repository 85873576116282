import type { OnInit } from '@angular/core';
import { Component } from '@angular/core';

import { UsersService } from '../../services/users.service';

@Component({
  selector: 'dpg-toggle-admin-shortcut',
  templateUrl: './toggle-admin-shortcut.component.html',
  styleUrls: ['./toggle-admin-shortcut.component.scss'],
})
export class ToggleAdminShortcutComponent implements OnInit {
  shouldDisplayToggle = false;

  constructor(private readonly _usersService: UsersService) {}

  ngOnInit(): void {
    this._usersService.canToggleAdmin().subscribe((canToggle) => {
      this.shouldDisplayToggle = canToggle;
    });
  }

  toggle(): void {
    this._usersService.toggleAdmin().subscribe(() => {
      location.reload();
    });
  }
}
