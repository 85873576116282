<adl-modal data-cy="inputModal">
  <div modal-header>
    <adl-title class="modal-title">{{ title }}</adl-title>
  </div>
  <div class="column-flex a-center" modal-body>
    <adl-text [size]="20" color="blue">
      {{ text }}
    </adl-text>
    <adl-text class="mt5" *ngIf="errorMessage" type="error">{{ errorMessage }}</adl-text>
    <div class="mt5 row-flex a-center j-center">
      <adl-input
        data-cy= "inputFieldModal"
        class="mr10"
        placeholder="New name"
        cursorAtTheStart="cursorAtTheStart"
        [control]="inputControl"
      ></adl-input>
      <adl-button data-cy="inputModalConfirm" [disabled]="!!errorMessage" (clicked)="confirm()">{{ confirmText }}</adl-button>
    </div>
  </div>
</adl-modal>
