import type { ISourceBanner } from '@dataportal/types';
import { Entity } from '@decahedron/entity';
// @ts-ignore
import dayjs from 'dayjs';

// Entity
export class SourceBanner extends Entity {
  // Attributes
  message: string = null;
  color: string = null;
  startDate: string = null;
  endDate: string = null;

  // Methods
  static addTimezone(localDate: string): string {
    return localDate ? new Date(localDate).toISOString() : null;
  }

  fromJson(json: ISourceBanner): SourceBanner {
    // Prepare data
    const localISO = 'YYYY-MM-DDTHH:MM';
    this.message = json.message;
    this.color = json.color;
    this.startDate = json.startDate ? dayjs(json.startDate).format(localISO) : null;
    this.endDate = json.endDate ? dayjs(json.endDate).format(localISO) : null;

    return this;
  }

  toJson(): ISourceBanner {
    return {
      message: this.message,
      color: this.color,
      startDate: SourceBanner.addTimezone(this.startDate),
      endDate: SourceBanner.addTimezone(this.endDate),
    };
  }
}
