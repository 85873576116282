<div class="content-wrapper--overlay" (click)="closeSidebar()"></div>
<dpg-alert-banner class="flex justify-center"></dpg-alert-banner>
<dpg-navbar-v2
  [external]="external"
  [items]="items"
  [favorites]="favorites"
  [canSeeAdmin]="(isAdmin$ | async) || canSeeBackOffice"
  (messageEvent)="openSideBar()"
>
</dpg-navbar-v2>
<dpg-impersonate-shortcut></dpg-impersonate-shortcut>
<dpg-toggle-admin-shortcut></dpg-toggle-admin-shortcut>
