<adl-card class="h-fit absolute z-[2]">
  <div class="relative w-full">
    <adl-icon  *ngIf='hasCaret' icon="caret-up" class="search-caret-up absolute left-16"></adl-icon>

    <div class="recent-search-container px-6 py-4">
      <div class="flex items-center justify-between">
        <span class="suggests">SUGGESTS</span>
        <span class="clear-btn c-pointer" (click)="resetRecentSearch()">Clear History</span>
      </div>

      <div class="flex flex-wrap justify-center">
        <div *ngFor="let itemName of recentItems" class="mt-2 w-full" (click)="openItem(storageToSend(itemName))">
          <div class="c-pointer w-fit max-w-full flex items-center">
            <div class="chrono-icon mr-2" alt="chrono-icon"></div>
            <span class="item-text">{{ valueToDisplay(itemName) }}</span>
          </div>
        </div>

        <div *ngFor="let item of suggestedItems" class="mt-2 w-full" (click)="openItem(valueToSend(item))">
          <div class="c-pointer w-fit max-w-full flex items-center">
            <span class="item-text">{{ item.name }}</span>
          </div>
        </div>

        <div
          *ngIf="recentItems?.length === 0 && suggestedItems?.length === 0"
          class="flex items-center p-10"
        >
          <span class="no-data">No results.</span>
        </div>
      </div>
    </div>
  </div>
</adl-card>
