import { Entity } from '@decahedron/entity';

import type { IDataCertification } from './source-form';

export class DataCertification extends Entity {
  // Attributes
  isCertified = false;
  certificationDate: string = null;
  businessCertifier: string = null;

  static fromStepForm(formData: IDataCertification): DataCertification {
    const dataCertification = new DataCertification();
    dataCertification.isCertified = formData?.isCertified;
    dataCertification.certificationDate = formData?.certificationDate;
    dataCertification.businessCertifier = formData?.businessCertifier;

    return dataCertification;
  }

  // Methods
  toJson(): {
    is_certified: boolean;
    certification_date: string;
    business_certifier: string;
  } {
    return {
      is_certified: this.isCertified || false,
      certification_date: this.certificationDate || null,
      business_certifier: this.isCertified ? this.businessCertifier || 'Business' : null,
    };
  }
}
