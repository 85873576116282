<svg
  version="1.1"
  class="dpg-icon"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  preserveAspectRatio="xMidYMid meet"
  viewBox="0 0 1200 1200"
  width="24"
  height="24"
>
  <defs>
    <path
      d="M249.54 151.87C232.35 184.78 224.75 215.83 224.75 253C224.75 289.52 231.95 319.36 248.34 352.01C293.65 441.56 391.19 485.8 488.33 460.88C531.63 449.82 572.94 421.57 601.99 382.93C645.16 325.49 656.36 246.08 631.17 177.05C608.39 114.82 557.08 66.05 494.99 47.26C472.34 40.47 442.22 36.47 423.3 37.94C346.95 43.93 284.45 84.84 249.54 151.87Z"
      id="aYAjcWjdC"
    />
    <path
      d="M171.85 555.75C120.95 587.6 83.64 641.83 71.65 701.4C67.38 722.98 66.18 739.91 66.18 782.81C66.18 829.85 67.25 837.71 75.78 855.3C85.64 875.82 104.29 890.88 126.95 896.61C136.54 899.14 141.87 899.28 200.77 899.28C205.02 899.28 226.25 899.28 264.46 899.28C264.94 896.8 265.21 895.42 265.26 895.15C265.66 892.88 266.06 888.75 266.06 886.09C266.06 883.42 267 876.76 267.93 871.29C274.32 838.51 290.98 806.53 313.9 783.61C318.7 778.68 350.54 751.63 384.66 723.38C418.77 695.27 477.53 646.36 515.24 614.92C586.67 555.22 605.32 541.36 623.18 534.03C630.24 531.1 633.31 529.24 632.11 528.57C627.18 525.9 604.26 523.24 580.54 522.57C555.89 521.91 554.15 522.04 548.96 524.57C535.36 531.63 503.12 541.36 479.27 545.76C461.14 549.09 407.98 549.09 389.99 545.76C365.34 541.1 334.95 532.03 321.76 525.37C315.77 522.31 314.17 522.17 294.45 522.31C282.85 522.44 268.33 523.11 262.06 523.77C233.42 526.97 197.7 539.63 171.85 555.75Z"
      id="dksFP5BZb"
    />
    <path
      d="M982.69 536.17C978.83 543.23 979.09 547.62 984.96 572.01C997.08 621.98 1000.28 656.63 995.48 688.61C991.22 717.65 981.23 739.37 963.64 757.76C929.79 793.34 875.56 810.66 784.28 815.33C770.02 815.99 750.17 816.66 739.91 816.66C738.68 816.66 732.55 816.66 721.52 816.66C722.16 786.04 722.51 769.03 722.58 765.63C724.45 685.94 724.32 679.68 720.85 673.41C714.19 660.89 703.93 654.76 689.8 654.76C681.28 654.76 678.88 655.29 673.81 658.36C670.48 660.22 652.23 674.88 633.17 690.87C614.12 706.73 556.69 754.57 505.65 796.94C454.48 839.45 410.37 876.76 407.58 879.96C396.78 892.21 396.38 909.67 406.38 923C412.51 930.99 658.76 1156.19 665.15 1159.52C677.01 1165.78 696.87 1161.25 705.13 1150.46C712.86 1140.2 712.86 1140.73 714.46 1074.5C715.39 1040.79 716.19 1013.07 716.32 1012.81C716.32 1012.67 733.64 1012.54 754.83 1012.54C846.91 1012.54 922.6 1001.48 979.63 979.89C1055.98 950.98 1103.02 902.48 1122.74 832.12C1134.47 790.41 1137.13 740.31 1129.54 703.53C1121.27 663.29 1100.49 618.51 1073.3 582.67C1055.71 559.48 1027.86 532.17 1015.34 525.64C1010.01 522.97 999.48 522.97 994.29 525.77C989.35 528.3 984.56 532.7 982.69 536.17Z"
      id="b3ZtLlsib2"
    />
  </defs>
  <g>
    <g>
      <g>
        <use xlink:href="#aYAjcWjdC" class="dpg-fg-medium-fill" />
        <g>
          <use
            xlink:href="#aYAjcWjdC"
            opacity="1"
            fill-opacity="0"
            stroke="#000000"
            stroke-width="1"
            stroke-opacity="0"
          />
        </g>
      </g>
      <g>
        <use xlink:href="#dksFP5BZb" class="dpg-fg-medium-fill" />
        <g>
          <use
            xlink:href="#dksFP5BZb"
            opacity="1"
            fill-opacity="0"
            stroke="#000000"
            stroke-width="1"
            stroke-opacity="0"
          />
        </g>
      </g>
      <g>
        <use xlink:href="#b3ZtLlsib2" class="dpg-fg-medium-fill" />
        <g>
          <use
            xlink:href="#b3ZtLlsib2"
            opacity="1"
            fill-opacity="0"
            stroke="#000000"
            stroke-width="1"
            stroke-opacity="0"
          />
        </g>
      </g>
    </g>
  </g>
</svg>
