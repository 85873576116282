import type { ISourceStatistics } from '@dataportal/types';
import { Entity } from '@decahedron/entity';

// Entity
export class SourceStatistics extends Entity {
  // Attributes
  views: number | '-' = '-';
  visits: number | '-' = '-';
  favorites: number | '-' = '-';

  // Methods
  fromJson(data: ISourceStatistics): SourceStatistics {
    this.views = data.views ?? '-';
    this.visits = data.unique_visitors ?? '-';
    this.favorites = data.favorites ?? '-';

    return this;
  }

  toJson(): Omit<ISourceStatistics, 'pk' | 'sk'> {
    return {
      views: this.views === '-' ? 0 : this.views,
      unique_visitors: this.visits === '-' ? 0 : this.visits,
      favorites: this.favorites === '-' ? 0 : this.favorites,
    };
  }
}
