import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';
import type { IDatalakeCurrentPath } from '@dataportal/datalake-parsing';
import { DatalakeParseObjectService } from '@dataportal/datalake-parsing';
import type {
  APIReferential,
  IAPIDatalakeReferential,
  IAPITableReferential,
  IGuardianAPIChecksMap,
  IRequestCheckFieldOptions,
} from '@dataportal/front-api';
import { Logger } from '@dataportal/front-shared';
import type { DatalakeColumnType } from '@dataportal/guardian-utils';
import { GuardianService } from '@dataportal/guardian-utils';
import { DBSnowflakeService } from '@dataportal/snowflake';
import type { ISettings } from '@flatfile/adapter';
import type { IDataHookRecord, IValidator } from '@flatfile/adapter/build/main/interfaces';
import type { FieldHookCallback, IDataHookResponse, ScalarDictionaryWithCustom } from '@flatfile/angular';

@Injectable()
export class FlatfileService {
  // globals
  static REGEX_LENGTH_IN_ERROR_LIMIT = 50;
  private _flatfileSettings: ISettings = null;
  private _flatfileFieldHooks: Record<string, FieldHookCallback> = {};
  private _flatfileRecordHooks: (
    record: ScalarDictionaryWithCustom,
    index: number,
  ) => IDataHookResponse | Promise<IDataHookResponse> = null;

  private _fieldsToHookOnUpdate: {
    [fieldName: string]: {
      type?: DatalakeColumnType;
      isRequired: boolean;
      referential?: { type: 'snowflake' | 'datalake_in' | 'datalake_not_in' | 'unknown'; data: APIReferential };
      min?: number | string;
      max?: number | string;
      greaterThan?: string;
    };
  } = {};

  constructor(
    private readonly _guardianService: GuardianService,
    private readonly _dbSnowflakeService: DBSnowflakeService,
    private readonly _datalakeParseObjectService: DatalakeParseObjectService,
    private readonly _logger: Logger,
  ) {
    this._guardianService.listExternalReferentials().subscribe();
  }

  get flatfileSettings(): ISettings {
    return this._flatfileSettings;
  }

  get flatfileFieldHooks(): Record<string, FieldHookCallback> {
    return this._flatfileFieldHooks;
  }

  get flatfileRecordHooks(): (
    record: ScalarDictionaryWithCustom,
    index: number,
  ) => IDataHookResponse | Promise<IDataHookResponse> {
    return this._flatfileRecordHooks;
  }

  private _getExternalReferentialSnowflakeValues(tableReferential: IAPITableReferential): Promise<string[]> {
    return this._dbSnowflakeService.getColumnExtract(tableReferential).pipe(take(1)).toPromise();
  }

  private _getExternalReferentialDatalakeValues(
    datalakeCurrentPath: IDatalakeCurrentPath,
    datalakeReferential: IAPIDatalakeReferential,
    checkId: string,
  ): Promise<string[]> {
    return this._datalakeParseObjectService
      .getExtract(
        DatalakeParseObjectService.convertDatalakeReferentialToDatalakeParseObjectSettings(
          datalakeCurrentPath,
          datalakeReferential,
          checkId,
        ),
      )
      .pipe(take(1))
      .toPromise();
  }

  /**
   * Checks whether it is required to transform the scientific notation number
   * @param recordValue The value to check
   * @private
   * @returns True if required, else false
   */
  private _hasToTransformNumberScientificNotation(recordValue: unknown): boolean {
    const regex = new RegExp(GuardianService.SCIENTIFIC_NOTATION_REGEX);

    return recordValue && regex.test(recordValue.toString());
  }

  /**
   * Transforms the scientific notation number according to the expected format
   * @param recordValue The value to transform
   * @private
   * @returns The transformed value
   */
  private _transformNumberScientificNotationToFixedDecimal(recordValue: unknown): string {
    const numberOfDecimal = Math.abs(parseInt(recordValue.toString().split(new RegExp('[Ee]'))[1], 10));

    return Number(recordValue).toFixed(numberOfDecimal + 2);
  }

  /**
   * Getting the updated IDataHookRecord object to set that contains the new info/message to display on Flatfile
   * @param condition A function returning the condition for registering the datahook record
   * @param message The error message
   * @param datahookRecord The current IDataHookRecord object associated to the field name
   * @private
   * @returns The updated IDataHookRecord object
   */
  private _getDatahookRecord(
    condition: () => boolean,
    message: string,
    datahookRecord: IDataHookRecord,
  ): IDataHookRecord {
    return condition()
      ? {
          ...datahookRecord,
          info: [
            ...datahookRecord.info,
            {
              message: message,
              level: 'error',
            },
          ],
        }
      : datahookRecord?.info?.length
      ? datahookRecord
      : { ...datahookRecord, info: [] };
  }

  /**
   * Datetime format validation
   * @param fieldName The field name
   * @param currentRecordFieldValue The current record field value
   * @param datahookRecord The IDataHookResponse object
   * @private
   */
  private _registerDateFormatRecordHookCallbackOnChange(
    fieldName: string,
    currentRecordFieldValue: unknown,
    datahookRecord: IDataHookResponse,
  ): void {
    // datetime format validation
    if (currentRecordFieldValue.toString().length && this._fieldsToHookOnUpdate[fieldName].type === 'datetime') {
      const condition = () => GuardianService.hasDateFormatError(currentRecordFieldValue.toString());
      datahookRecord[fieldName] = this._getDatahookRecord(
        condition,
        'Value must be a valid date (YYYY-MM-DD or YYYY-M-D).',
        datahookRecord[fieldName],
      );
    }
  }

  /**
   * Min/max validation
   * @param fieldName The field name
   * @param currentRecordFieldValue The current record field value
   * @param datahookRecord The IDataHookResponse object
   * @private
   */
  private _registerMinMaxRecordHookCallbackOnChange(
    fieldName: string,
    currentRecordFieldValue: unknown,
    datahookRecord: IDataHookResponse,
  ): void {
    if (
      (this._fieldsToHookOnUpdate[fieldName].isRequired || currentRecordFieldValue.toString().length) &&
      (this._fieldsToHookOnUpdate[fieldName].min !== null || this._fieldsToHookOnUpdate[fieldName].max !== null)
    ) {
      if (this._fieldsToHookOnUpdate[fieldName].min !== null && this._fieldsToHookOnUpdate[fieldName].max !== null) {
        if (
          !this._fieldsToHookOnUpdate[fieldName].type?.length ||
          this._fieldsToHookOnUpdate[fieldName].type === 'integer' ||
          this._fieldsToHookOnUpdate[fieldName].type === 'float'
        ) {
          const condition = () =>
            currentRecordFieldValue < this._fieldsToHookOnUpdate[fieldName].min ||
            currentRecordFieldValue > this._fieldsToHookOnUpdate[fieldName].max;
          datahookRecord[fieldName] = this._getDatahookRecord(
            condition,
            `Value must be between ${this._fieldsToHookOnUpdate[fieldName].min} and ${this._fieldsToHookOnUpdate[fieldName].max}.`,
            datahookRecord[fieldName],
          );
        } else if (this._fieldsToHookOnUpdate[fieldName].type === 'datetime') {
          const condition = () =>
            GuardianService.compareDate(
              currentRecordFieldValue.toString(),
              this._fieldsToHookOnUpdate[fieldName].min.toString(),
            ) < 0 ||
            GuardianService.compareDate(
              currentRecordFieldValue.toString(),
              this._fieldsToHookOnUpdate[fieldName].max.toString(),
            ) > 0;
          datahookRecord[fieldName] = this._getDatahookRecord(
            condition,
            `This date value must be between ${this._fieldsToHookOnUpdate[fieldName].min} and ${this._fieldsToHookOnUpdate[fieldName].max}.`,
            datahookRecord[fieldName],
          );
        }
      } else if (this._fieldsToHookOnUpdate[fieldName].min !== null) {
        if (
          !this._fieldsToHookOnUpdate[fieldName].type?.length ||
          this._fieldsToHookOnUpdate[fieldName].type === 'integer' ||
          this._fieldsToHookOnUpdate[fieldName].type === 'float'
        ) {
          const condition = () => currentRecordFieldValue < this._fieldsToHookOnUpdate[fieldName].min;
          datahookRecord[fieldName] = this._getDatahookRecord(
            condition,
            `Value must be ≥ ${this._fieldsToHookOnUpdate[fieldName].min}.`,
            datahookRecord[fieldName],
          );
        } else if (this._fieldsToHookOnUpdate[fieldName].type === 'datetime') {
          const condition = () =>
            GuardianService.compareDate(
              currentRecordFieldValue.toString(),
              this._fieldsToHookOnUpdate[fieldName].min.toString(),
            ) < 0;
          datahookRecord[fieldName] = this._getDatahookRecord(
            condition,
            `This date value must be ≥ ${this._fieldsToHookOnUpdate[fieldName].min}.`,
            datahookRecord[fieldName],
          );
        }
      } else if (
        !this._fieldsToHookOnUpdate[fieldName].type?.length ||
        this._fieldsToHookOnUpdate[fieldName].type === 'integer' ||
        this._fieldsToHookOnUpdate[fieldName].type === 'float'
      ) {
        const condition = () => currentRecordFieldValue > this._fieldsToHookOnUpdate[fieldName].max;
        datahookRecord[fieldName] = this._getDatahookRecord(
          condition,
          `Value must be ≤ ${this._fieldsToHookOnUpdate[fieldName].max}.`,
          datahookRecord[fieldName],
        );
      } else if (this._fieldsToHookOnUpdate[fieldName].type === 'datetime') {
        const condition = () =>
          GuardianService.compareDate(
            currentRecordFieldValue.toString(),
            this._fieldsToHookOnUpdate[fieldName].max.toString(),
          ) > 0;
        datahookRecord[fieldName] = this._getDatahookRecord(
          condition,
          `This date value be ≤ ${this._fieldsToHookOnUpdate[fieldName].max}.`,
          datahookRecord[fieldName],
        );
      }
    }
  }

  private _getDecimalFromValue(decimalValue: unknown): number {
    return Number(decimalValue?.toString()?.replace(',', '.')) || null;
  }

  /**
   * Greater than validation
   * @param fieldNamesToHookOnUpdate The validation schema field names
   * @param currentFieldName The field name
   * @param currentRecordFieldValue The current record field value
   * @param record The hook input parameter ScalarDictionaryWithCustom object
   * @param datahookRecord The hook output parameter IDataHookResponse object
   * @param decimalSeparatorRegex The decimal separator regex
   * @private
   */
  private _registerGreaterThanRecordHookCallbackOnChange(
    fieldNamesToHookOnUpdate: string[],
    currentFieldName: string,
    currentRecordFieldValue: unknown,
    record: ScalarDictionaryWithCustom,
    datahookRecord: IDataHookResponse,
    decimalSeparatorRegex: string,
  ): void {
    const greaterThanFieldName = this._fieldsToHookOnUpdate[currentFieldName].greaterThan || '';
    const strictlyLowerThanFieldNames = fieldNamesToHookOnUpdate.filter(
      (fieldName) =>
        fieldName !== currentFieldName &&
        record[fieldName] !== null &&
        record[fieldName] !== undefined &&
        (this._fieldsToHookOnUpdate[fieldName].greaterThan || '') === currentFieldName,
    );

    if (currentRecordFieldValue?.toString()?.length) {
      if (greaterThanFieldName?.length && fieldNamesToHookOnUpdate.includes(greaterThanFieldName)) {
        let greaterThanFieldValue = record[greaterThanFieldName];

        if (this._hasToTransformNumberScientificNotation(greaterThanFieldValue)) {
          greaterThanFieldValue = this._transformNumberScientificNotationToFixedDecimal(greaterThanFieldValue);
        }

        if (
          (this._fieldsToHookOnUpdate[currentFieldName].type === 'integer' ||
            this._fieldsToHookOnUpdate[currentFieldName].type === 'float') &&
          (this._fieldsToHookOnUpdate[greaterThanFieldName].type === 'integer' ||
            this._fieldsToHookOnUpdate[greaterThanFieldName].type === 'float')
        ) {
          const condition = () =>
            currentRecordFieldValue?.toString()?.length &&
            greaterThanFieldValue?.toString()?.length &&
            this._getDecimalFromValue(currentRecordFieldValue) < this._getDecimalFromValue(greaterThanFieldValue);
          datahookRecord[currentFieldName] = this._getDatahookRecord(
            condition,
            `Value must be greater or equal than column '${greaterThanFieldName}'.`,
            datahookRecord[currentFieldName],
          );
        } else if (
          this._fieldsToHookOnUpdate[currentFieldName].type === 'datetime' &&
          this._fieldsToHookOnUpdate[greaterThanFieldName].type === 'datetime'
        ) {
          const condition = () =>
            currentRecordFieldValue?.toString()?.length &&
            greaterThanFieldValue?.toString()?.length &&
            GuardianService.compareDate(currentRecordFieldValue.toString(), greaterThanFieldValue.toString()) < 0;
          datahookRecord[currentFieldName] = this._getDatahookRecord(
            condition,
            `Value must be greater or equal than column '${greaterThanFieldName}'.`,
            datahookRecord[currentFieldName],
          );
        } else {
          const condition = () =>
            currentRecordFieldValue?.toString()?.length &&
            greaterThanFieldValue?.toString()?.length &&
            GuardianService.compareValue(currentRecordFieldValue, greaterThanFieldValue, decimalSeparatorRegex) < 0;
          datahookRecord[currentFieldName] = this._getDatahookRecord(
            condition,
            `Value must be greater or equal than column '${greaterThanFieldName}'.`,
            datahookRecord[currentFieldName],
          );
        }
      }

      strictlyLowerThanFieldNames.forEach((fieldName) => {
        let currentStrictlyLowerThanFieldValue = record[fieldName];

        if (this._hasToTransformNumberScientificNotation(currentStrictlyLowerThanFieldValue)) {
          currentStrictlyLowerThanFieldValue = this._transformNumberScientificNotationToFixedDecimal(
            currentStrictlyLowerThanFieldValue,
          );
        }

        if (
          (this._fieldsToHookOnUpdate[currentFieldName].type === 'integer' ||
            this._fieldsToHookOnUpdate[currentFieldName].type === 'float') &&
          (this._fieldsToHookOnUpdate[fieldName].type === 'integer' ||
            this._fieldsToHookOnUpdate[fieldName].type === 'float')
        ) {
          const condition = () =>
            currentRecordFieldValue?.toString()?.length &&
            currentStrictlyLowerThanFieldValue?.toString()?.length &&
            this._getDecimalFromValue(currentRecordFieldValue) >
              this._getDecimalFromValue(currentStrictlyLowerThanFieldValue);
          datahookRecord[currentFieldName] = this._getDatahookRecord(
            condition,
            `Value must be lower or equal than column '${fieldName}'.`,
            datahookRecord[currentFieldName],
          );
        } else if (
          this._fieldsToHookOnUpdate[currentFieldName].type === 'datetime' &&
          this._fieldsToHookOnUpdate[fieldName].type === 'datetime'
        ) {
          const condition = () =>
            currentRecordFieldValue?.toString()?.length &&
            currentStrictlyLowerThanFieldValue?.toString()?.length &&
            GuardianService.compareDate(
              currentRecordFieldValue.toString(),
              currentStrictlyLowerThanFieldValue.toString(),
            ) > 0;
          datahookRecord[currentFieldName] = this._getDatahookRecord(
            condition,
            `Value must be lower or equal than column '${fieldName}'.`,
            datahookRecord[currentFieldName],
          );
        } else {
          const condition = () =>
            currentRecordFieldValue?.toString()?.length &&
            currentStrictlyLowerThanFieldValue?.toString()?.length &&
            GuardianService.compareValue(
              currentRecordFieldValue,
              currentStrictlyLowerThanFieldValue,
              decimalSeparatorRegex,
            ) > 0;
          datahookRecord[currentFieldName] = this._getDatahookRecord(
            condition,
            `Value must be lower or equal than column '${fieldName}'.`,
            datahookRecord[currentFieldName],
          );
        }
      });
    }
  }

  /**
   * External referential validation
   * @param fieldNamesToHookOnUpdate The validation schema field names
   * @param fieldNamesWithExternalReferential The field names with external referential
   * @param record The hook input parameter ScalarDictionaryWithCustom object
   * @param datahookRecord The hook output parameter IDataHookResponse object
   * @param datalakeCurrentPath The current datalake path
   * @param checkId the id of the current check
   * @private
   */
  private async _registerExternalReferentialRecordHookCallbackOnChange(
    fieldNamesToHookOnUpdate: string[],
    fieldNamesWithExternalReferential: string[],
    record: ScalarDictionaryWithCustom,
    datahookRecord: IDataHookResponse,
    datalakeCurrentPath: IDatalakeCurrentPath,
    checkId: string,
  ): Promise<unknown> {
    return Promise.all(
      fieldNamesWithExternalReferential
        .filter(
          (fieldName) => this._fieldsToHookOnUpdate[fieldName].isRequired || !!record[fieldName]?.toString().length,
        )
        .map(async (fieldName) => {
          const externalReferentialObject = this._fieldsToHookOnUpdate[fieldName].referential;
          const externalReferential = externalReferentialObject.data;
          const isWhiteListedReferential =
            externalReferentialObject.type === 'datalake_in' || externalReferentialObject.type === 'snowflake';
          const isSnowflakeReferential = externalReferentialObject.type === 'snowflake';
          const isDatalakeReferential =
            externalReferentialObject.type === 'datalake_in' || externalReferentialObject.type === 'datalake_not_in';
          const referential = isSnowflakeReferential
            ? (externalReferential as IAPITableReferential)
            : isDatalakeReferential
            ? (externalReferential as IAPIDatalakeReferential)
            : null;
          const referentialLabelToDisplay = isSnowflakeReferential
            ? this._guardianService.getExternalReferentialSnowflakeLabel(referential as IAPITableReferential) ||
              (referential as IAPITableReferential).sql_table
            : isDatalakeReferential
            ? `${(referential as IAPIDatalakeReferential).bucket_name}/${
                (referential as IAPIDatalakeReferential).prefix
              }`
            : null;
          const extractedValues = isSnowflakeReferential
            ? await this._getExternalReferentialSnowflakeValues(referential as IAPITableReferential)
            : isDatalakeReferential
            ? await this._getExternalReferentialDatalakeValues(
                datalakeCurrentPath,
                referential as IAPIDatalakeReferential,
                checkId,
              )
            : ([] as string[]);
          const condition = () =>
            extractedValues?.length &&
            (isWhiteListedReferential
              ? !extractedValues?.includes(`${record[fieldName]}`)
              : extractedValues?.includes(`${record[fieldName]}`));
          datahookRecord[fieldName] = this._getDatahookRecord(
            condition,
            isWhiteListedReferential
              ? `This value must be included in the referential : '${referentialLabelToDisplay}'.`
              : `This value must not be included in the referential : '${referentialLabelToDisplay}'.`,
            datahookRecord[fieldName],
          );
        }),
    );
  }

  /**
   * For record hooks, it is necessary to check every field for the current record (line)
   * Checking on change for many cases :
   * -> Inside one column :
   * - String (nothing specifically)
   * - Number (min and max)
   * - Decimal (min and max)
   * - Date (min and max)
   * - Any type (referential)
   * -> Between two columns :
   * - Number (greater than)
   * - Decimal (greater than)
   * - Date (greater than)
   */
  private _registerRecordHookCallbackOnChange(
    datalakeCurrentPath: IDatalakeCurrentPath,
    decimalSeparatorRegex: string,
    checkId: string,
  ): void {
    this._flatfileRecordHooks = async (
      record: ScalarDictionaryWithCustom,
      index: number,
    ): Promise<IDataHookResponse> => {
      const datahookRecord: IDataHookResponse = {};
      const fieldNamesToHookOnUpdate = Object.keys(this._fieldsToHookOnUpdate);

      if (!fieldNamesToHookOnUpdate.length) {
        return datahookRecord;
      }

      fieldNamesToHookOnUpdate.forEach((fieldName) => {
        datahookRecord[fieldName] = {
          info: [],
        };

        let currentRecordFieldValue = record[fieldName];

        // current record field does not exist (input file does not contain this field)
        if (currentRecordFieldValue === null || currentRecordFieldValue === undefined) {
          return;
        }

        // scientific notation transformation
        if (
          this._fieldsToHookOnUpdate[fieldName].type === 'integer' ||
          this._fieldsToHookOnUpdate[fieldName].type === 'float'
        ) {
          if (this._hasToTransformNumberScientificNotation(currentRecordFieldValue)) {
            currentRecordFieldValue = this._transformNumberScientificNotationToFixedDecimal(currentRecordFieldValue);
            datahookRecord[fieldName] = {
              ...datahookRecord[fieldName],
              value: currentRecordFieldValue,
            };
          }
        }

        // Date format / min/max / greater than validations
        this._registerDateFormatRecordHookCallbackOnChange(fieldName, currentRecordFieldValue, datahookRecord);
        this._registerMinMaxRecordHookCallbackOnChange(fieldName, currentRecordFieldValue, datahookRecord);
        this._registerGreaterThanRecordHookCallbackOnChange(
          fieldNamesToHookOnUpdate,
          fieldName,
          currentRecordFieldValue,
          record,
          datahookRecord,
          decimalSeparatorRegex,
        );
      });

      // External referential validation (fetching asynchronously the hooks for potential external referential field checks)
      const fieldNamesWithExternalReferential = fieldNamesToHookOnUpdate.filter(
        (fieldName) => !!this._fieldsToHookOnUpdate[fieldName].referential,
      );

      if (fieldNamesWithExternalReferential?.length) {
        await this._registerExternalReferentialRecordHookCallbackOnChange(
          fieldNamesToHookOnUpdate,
          fieldNamesWithExternalReferential,
          record,
          datahookRecord,
          datalakeCurrentPath,
          checkId,
        );

        return datahookRecord;
      } else {
        return datahookRecord;
      }
    };
  }

  /**
   * PREREQUISITES
   * Constraints rules :
   * - Type is not require
   * - If type is integer, float or datetime, only min/max/greater-than constraints are available
   * - If type is string, only regex/MDH/datalake constraints are available
   * - If no type is precised, every constraints are available
   * - Once MDH or datalake constraint has been specified, the other constraint can not be specified
   * - We can combine regex constraint with MDH or datalake constraints
   * @param datalakeCurrentPath
   * @param schema
   * @param fieldName
   * @param decimalSeparator
   * @param checkId
   * @private
   */
  private _getFlatfileValidators(
    datalakeCurrentPath: IDatalakeCurrentPath,
    schema: IRequestCheckFieldOptions,
    fieldName: string,
    decimalSeparator: string,
    checkId: string,
  ): IValidator[] {
    const decimalSeparatorRegex = decimalSeparator?.length
      ? decimalSeparator
      : GuardianService.DEFAULT_DECIMAL_SEPARATORS_XLSX.join('');
    const decimalSeparatorInfo = decimalSeparator?.length
      ? `"${decimalSeparator}"`
      : `"${GuardianService.DEFAULT_DECIMAL_SEPARATORS_XLSX.join('" or "')}"`;
    const validators: IValidator[] = [];
    this._fieldsToHookOnUpdate[fieldName] = { isRequired: false };

    if (schema.required_values) {
      validators.push({
        validate: 'required',
        error: 'Field is required.',
      });
      this._fieldsToHookOnUpdate[fieldName].isRequired = schema.required_values;
    }

    if (schema.unique) {
      validators.push({
        validate: 'unique',
        error: 'Field value must be unique.',
      });
    }

    const getRegexValidator = (regex: string, errorMsg: string): IValidator => {
      return {
        validate: 'regex_matches',
        error: errorMsg,
        regex: regex,
        regexFlags: { ignoreCase: false, multiline: false, dotAll: false, global: false, unicode: false },
      };
    };

    const schemaType = (schema.type?.length ? schema.type : null) as DatalakeColumnType;
    this._fieldsToHookOnUpdate[fieldName].type = schemaType;
    const minToUse = schema.min !== null && schema.min !== undefined ? (schema.min as number) : null;
    const maxToUse = schema.max !== null && schema.max !== undefined ? (schema.max as number) : null;
    const greaterThanFieldName = schema.pair_greater?.length ? schema.pair_greater : null;

    switch (schemaType) {
      case 'integer':
        validators.push(getRegexValidator(GuardianService.NUMBER_REGEX, 'Value must be a number.'));
        this._fieldsToHookOnUpdate[fieldName].min = minToUse;
        this._fieldsToHookOnUpdate[fieldName].max = maxToUse;
        this._fieldsToHookOnUpdate[fieldName].greaterThan = greaterThanFieldName;
        break;
      case 'float':
        validators.push(
          getRegexValidator(
            GuardianService.getDecimalRegex(decimalSeparatorRegex),
            `Value must be a decimal number (with decimal separator ${decimalSeparatorInfo}).`,
          ),
        );
        this._fieldsToHookOnUpdate[fieldName].min = minToUse;
        this._fieldsToHookOnUpdate[fieldName].max = maxToUse;
        this._fieldsToHookOnUpdate[fieldName].greaterThan = greaterThanFieldName;
        break;
      case 'datetime':
        validators.push(
          getRegexValidator(GuardianService.DATE_REGEX, 'Value must be a valid date (YYYY-MM-DD or YYYY-M-D).'),
        );
        this._fieldsToHookOnUpdate[fieldName].min = minToUse;
        this._fieldsToHookOnUpdate[fieldName].max = maxToUse;
        this._fieldsToHookOnUpdate[fieldName].greaterThan = greaterThanFieldName;
        break;
      case 'string':
      default:
        // we could also add min/max here, but to specify in a Jira card
        this._fieldsToHookOnUpdate[fieldName].greaterThan = greaterThanFieldName;
        break;
    }

    if (schema.regex?.length) {
      // regex
      validators.push(
        getRegexValidator(
          schema.regex,
          `Value must match the regex : "${this._guardianService.getFieldCheckRegexLabel(
            schema.regex,
            FlatfileService.REGEX_LENGTH_IN_ERROR_LIMIT,
          )}".`,
        ),
      );
    }

    if (Object.keys(schema.values_in || {}).length) {
      // external authorize referential
      const schemaToUse = { column_name: null, ...schema };
      const referentialType = GuardianService.hasSnowflakeReferential(schemaToUse, true)
        ? 'snowflake'
        : GuardianService.hasDatalakeReferential(schemaToUse, true)
        ? 'datalake_in'
        : 'unknown';
      this._fieldsToHookOnUpdate[fieldName].referential = { type: referentialType, data: schema.values_in };
    }

    if (Object.keys(schema.values_not_in || {}).length) {
      // external unauthorized referential
      const schemaToUse = { column_name: null, ...schema };
      const referentialType = GuardianService.hasSnowflakeReferential(schemaToUse, false)
        ? 'snowflake'
        : GuardianService.hasDatalakeReferential(schemaToUse, false)
        ? 'datalake_not_in'
        : 'unknown';
      this._fieldsToHookOnUpdate[fieldName].referential = { type: referentialType, data: schema.values_not_in };
    }

    // registering on change callbacks
    this._registerRecordHookCallbackOnChange(datalakeCurrentPath, decimalSeparatorRegex, checkId);

    // returning validators
    return validators;
  }

  private _resetFlatfileSettings(): void {
    this._flatfileSettings = null;
    this._flatfileFieldHooks = {};
    this._flatfileRecordHooks = null;
  }

  convertGuardianCheckToFlatfileCheck(
    datalakeCurrentPath: IDatalakeCurrentPath,
    guardianCheck: IGuardianAPIChecksMap['datalakePath'],
  ): void {
    this._resetFlatfileSettings();

    if (!guardianCheck || !Object.keys(guardianCheck).length) {
      return;
    }

    this._fieldsToHookOnUpdate = {};
    const flatfileSettings: ISettings = {
      type: 'Datalake explorer',
      fields: [],
    };
    const orderedValidationSchemaColumnsArray = guardianCheck?.validation_schema_columns
      ? GuardianService.getOrderedValidationSchemaColumnsArray(guardianCheck.validation_schema_columns)
      : [];
    orderedValidationSchemaColumnsArray.forEach((fieldCheckSchema) => {
      flatfileSettings.fields.push({
        label: fieldCheckSchema.column_name,
        key: fieldCheckSchema.column_name,
        validators: this._getFlatfileValidators(
          datalakeCurrentPath,
          fieldCheckSchema,
          fieldCheckSchema.column_name,
          guardianCheck.decimal,
          guardianCheck.id_check.toString(),
        ),
      });
    });
    this._flatfileSettings = flatfileSettings;
  }
}
