import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { SharedModule } from '../shared.module';

@Injectable({
  providedIn: SharedModule,
})
export class UrlService {
  private readonly _urlSource = new BehaviorSubject('Url not set');
  currentUrl = this._urlSource.asObservable();

  constructor() {
    // do nothing
  }

  changeUrl(url: string) {
    this._urlSource.next(url);
  }

  currentPageIsLeon() {
    if (document.location.href.includes('/sources')) {
      return false;
    }

    return document.location.href.includes('leons');
  }
}
