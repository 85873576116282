import { NgModule } from '@angular/core';
import { ApiModule } from '@dataportal/front-api';

import { AmundsenService } from './services/amundsen.service';

@NgModule({
  imports: [ApiModule],
  providers: [AmundsenService],
})
export class AmundsenModule {}
