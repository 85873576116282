import type { OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { ReplaySubject } from 'rxjs';
import { FormControl } from '@ngneat/reactive-forms';

import type { IOptionsSelectorButtonChoice } from '../options-selector-button/options-selector-button.component';

@Component({
  selector: 'adl-comment-item',
  templateUrl: './comment-item.component.html',
  styleUrls: ['./comment-item.component.scss'],
})
export class CommentItemComponent implements OnInit, OnChanges {
  /* Inputs */
  // Required infos part
  @Input() pictureSrc: SafeResourceUrl;
  @Input() isLoadingPicture = false;
  @Input() name = '';
  @Input() date = '';
  @Input() updatedDate = '';
  @Input() message = '';
  @Input() isSmallerItem = false;
  @Input() setCursorAtTheEndOfEditZone$: ReplaySubject<void>;
  // Like part
  @Input() hasLikeBtn = false;
  @Input() isLoadingLikeBtn = false;
  @Input() isLiked = false;
  @Input() nbLikes = 0;
  // Hide part
  @Input() hasHideBtn = false;
  @Input() isHideBtnToggled = false;
  @Input() nbReplies = 0;
  // Options part
  @Input() hasOptionsBtn = false;
  @Input() isLoadingOptionsBtn = false;
  @Input() optionsBtnOptions: IOptionsSelectorButtonChoice[];
  // Reply part
  @Input() hasReplyBtn = false;
  // Edit part
  @Input() isEditing = false;
  @Input() isLoadingEditing = false;
  @Input() hasToDisable = false;

  /* Outputs */
  // Like part
  @Output() onToggleLike = new EventEmitter<boolean>();
  // Hide part
  @Output() onToggleHide = new EventEmitter<boolean>();
  // Options part
  @Output() onOptionSelection = new EventEmitter<string>();
  // Reply part
  @Output() onClickReply = new EventEmitter<boolean>();
  // Edit part
  @Output() onEditCancel = new EventEmitter<boolean>();
  @Output() onEditValidate = new EventEmitter<string>();

  @ViewChild('messageElement') messageElement: ElementRef<HTMLElement>;

  isOptionsDisplayed = false;
  editMessageInput = new FormControl<string>('');
  editMessageZoneHeightInPx = '100px';

  ngOnInit() {
    this._refreshEditMessage();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.message?.currentValue?.toString()?.length) {
      this._refreshEditMessage();
    } else if (changes?.isEditing?.previousValue === false && changes?.isEditing?.currentValue === true) {
      this._refreshEditMessageZoneHeight();
    }
  }

  private _refreshEditMessageInputValue(): void {
    this.editMessageInput.setValue(this.message);
  }

  private _refreshEditMessageZoneHeight(): void {
    this.editMessageZoneHeightInPx = this.messageElement?.nativeElement?.clientHeight
      ? `${this.messageElement.nativeElement.clientHeight}px`
      : this.editMessageZoneHeightInPx;
  }

  private _refreshEditMessage(): void {
    this._refreshEditMessageInputValue();
    this._refreshEditMessageZoneHeight();
  }

  get editMessageInputValue(): string {
    return this.editMessageInput?.value?.length ? this.editMessageInput.value : '';
  }

  get hasToDisplayReplyBtn(): boolean {
    return this.hasReplyBtn && !this.isEditing && !this.hasToDisable;
  }

  toggleLike(): void {
    this.isLiked = !this.isLiked;
    this.onToggleLike.emit(this.isLiked);
  }

  toggleHide(): void {
    this.isHideBtnToggled = !this.isHideBtnToggled;
    this.onToggleHide.emit(this.isHideBtnToggled);
  }

  selectOption(selectedValue: string): void {
    this.onOptionSelection.emit(selectedValue);
  }

  reply(): void {
    this.onClickReply.emit(true);
  }

  cancelEdit(): void {
    this.onEditCancel.emit(true);
  }

  validateEdit(): void {
    this.onEditValidate.emit(this.editMessageInputValue);
  }

  informOpenedOptionsSelector(isOpen: boolean): void {
    this.isOptionsDisplayed = isOpen;
  }
}
