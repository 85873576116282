import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export enum REMOVE_MODAL_RESULT {
  PATH = 'PATH',
  BOTH = 'BOTH',
}

@Component({
  selector: 'app-step-datalake-confirm-modal',
  templateUrl: './datalake-confirm-modal.component.html',
  styleUrls: ['./datalake-confirm-modal.component.scss'],
})
export class DatalakeConfirmModalComponent {
  hasMultipleChoices = false;
  headerMsg = '';
  headerMsgSize = null;
  headerSubtitleMsg = '';
  msgCenter = true;
  singleChoiceBodyMsg = '';
  secondarySingleChoiceBodyMsg = '';
  validateButtonText = 'Yes';
  isSquareButton = true;
  cancelButtonText = 'Cancel';
  closeButtonColor = 'black';
  headerBackgroundColor = 'primary-500';

  constructor(
    private readonly _activeMatModal: MatDialogRef<DatalakeConfirmModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    data: {
      hasMultipleChoices: boolean;
      headerMsg: string;
      headerMsgSize: string;
      headerSubtitleMsg: string;
      msgCenter: boolean;
      singleChoiceBodyMsg: string;
      secondarySingleChoiceBodyMsg: string;
      isSquareButton: boolean;
      validateButtonText: string;
      cancelButtonText: string;
      closeButtonColor: string;
      headerBackgroundColor: string;
    },
  ) {
    this.hasMultipleChoices = Object.keys(data || {}).includes('hasMultipleChoices')
      ? data.hasMultipleChoices
      : this.hasMultipleChoices;
    this.headerMsg = data?.headerMsg?.length ? data.headerMsg : this.headerMsg;
    this.headerMsgSize = data?.headerMsgSize?.length ? data.headerMsgSize : this.headerMsgSize;
    this.headerSubtitleMsg = data?.headerSubtitleMsg?.length ? data.headerSubtitleMsg : this.headerSubtitleMsg;
    this.msgCenter = Object.keys(data || {}).includes('msgCenter') ? data.msgCenter : this.msgCenter;
    this.singleChoiceBodyMsg = data?.singleChoiceBodyMsg?.length ? data.singleChoiceBodyMsg : this.singleChoiceBodyMsg;
    this.secondarySingleChoiceBodyMsg = data?.secondarySingleChoiceBodyMsg?.length
      ? data.secondarySingleChoiceBodyMsg
      : this.secondarySingleChoiceBodyMsg;
    this.isSquareButton = Object.keys(data || {}).includes('isSquareButton')
      ? data.isSquareButton
      : this.isSquareButton;
    this.validateButtonText = data?.validateButtonText?.length ? data.validateButtonText : this.validateButtonText;
    this.cancelButtonText = data?.cancelButtonText?.length ? data.cancelButtonText : this.cancelButtonText;
    this.closeButtonColor = data?.closeButtonColor?.length ? data.closeButtonColor : this.closeButtonColor;
    this.headerBackgroundColor = data?.headerBackgroundColor?.length
      ? data.headerBackgroundColor
      : this.headerBackgroundColor;
  }

  selectedPath(): void {
    this._activeMatModal.close(REMOVE_MODAL_RESULT.PATH);
  }

  selectedPathAndGuardian(): void {
    this._activeMatModal.close(REMOVE_MODAL_RESULT.BOTH);
  }

  validate(): void {
    this._activeMatModal.close(true);
  }

  cancel(): void {
    this._activeMatModal.close();
  }
}
