import type { OnDestroy } from '@angular/core';
import { Component, Input } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { of, ReplaySubject, Subject } from 'rxjs';
import { catchError, mergeMap, take, takeUntil } from 'rxjs/operators';
import type { IOptionsSelectorButtonChoice } from '@dataportal/adl';
import { DialogsService } from '@dataportal/adl';
import { GoogleTagManagerService } from '@dataportal/analytics';
import { CurrentUserService } from '@dataportal/auth';
import { AlertService, Logger } from '@dataportal/front-shared';
import { Navigator } from '@dataportal/navigator';
import { ImpersonateService, UsersService } from '@dataportal/users';

import { CurrentDashboardService } from '../../services/current-dashboard.service';
import { DashboardCommentsService } from '../../services/dashboard-comments.service';
import { DashboardCommentsCacheService } from '../../services/dashboard-comments-cache.service';
import { DashboardsService } from '../../services/dashboards.service';

import { Dashboard } from '../../entities/dashboard.model';

import { DashboardComment } from '../../entities/dashboard-comment';

@Component({
  selector: 'dpg-dashboard-comments-thread',
  templateUrl: './dashboard-comments-thread.component.html',
  styleUrls: ['./dashboard-comments-thread.component.scss'],
})
export class DashboardCommentsThreadComponent implements OnDestroy {
  static readonly DEFAULT_ACTIONS_BUTTONS: IOptionsSelectorButtonChoice[] = [
    {
      label: 'Modify',
      value: 'modify',
      icon: 'pencil',
    },
    { label: 'Delete', value: 'delete', icon: 'trash' },
  ];

  @Input() level: number;
  @Input() dashboardComment: DashboardComment;
  @Input() currentUserId: string;
  @Input() isCurrentUserAdmin: boolean;
  @Input() currentUserPictureBase64: SafeResourceUrl;
  @Input() isLoadingCurrentUserPictureBase64: boolean;
  @Input() isLoadingDashboardComments: boolean;
  @Input() currentDashboard: Dashboard;

  isEditingComment = false;
  isLoadingEditingComment = false;
  isLoadingRemovingComment = false;
  isLoadingReplyingComment = false;
  setCursorAtTheEndOfEditZoneOnEditing$ = new ReplaySubject<void>();
  clearComponentInput$ = new ReplaySubject<void>();
  setCursorAtTheEndOfCommentInput$ = new ReplaySubject<void>();

  private readonly _destroyed$ = new Subject<void>();

  constructor(
    private readonly _router: Router,
    private readonly _usersService: UsersService,
    private readonly _currentUserService: CurrentUserService,
    private readonly _impersonateService: ImpersonateService,
    private readonly _currentDashboardService: CurrentDashboardService,
    private readonly _dashboardsService: DashboardsService,
    private readonly _dashboardCommentService: DashboardCommentsService,
    private readonly _dashboardCommentCacheService: DashboardCommentsCacheService,
    private readonly _dialogsService: DialogsService,
    private readonly _navigator: Navigator,
    private readonly _logger: Logger,
    private readonly _alertService: AlertService,
    private readonly _gtmService: GoogleTagManagerService,
  ) {}

  get hasToShowOptionsBtn(): boolean {
    return (
      this.isCurrentUserAdmin || (this.currentUserId?.length && this.currentUserId === this.dashboardComment.authorId)
    );
  }

  get hasToDisable(): boolean {
    return this._impersonateService.isImpersonating() && !this._impersonateService.isOriginUserAdmin();
  }

  get isLoadingOptionsBtn(): boolean {
    return this.isLoadingEditingComment || this.isLoadingRemovingComment;
  }

  get nextPaddingLeft(): string {
    return '40px';
  }

  get hasReplyBtn(): boolean {
    return !this.level;
  }

  get hasToDisableReplyComment(): boolean {
    return (this.isLoadingDashboardComments || this.isLoadingReplyingComment) && this.hasToDisable;
  }

  get actionsButtons(): IOptionsSelectorButtonChoice[] {
    const actionsButtonsToReturn = [...DashboardCommentsThreadComponent.DEFAULT_ACTIONS_BUTTONS];

    if (this.dashboardComment.childrenComments?.length) {
      actionsButtonsToReturn.shift();
    }

    return actionsButtonsToReturn;
  }

  ngOnDestroy() {
    this._destroyed$.next();
  }

  toggleRepliesVisibility(hasToHide: boolean): void {
    this.dashboardComment.hasToDisplayReplies = !hasToHide;
  }

  selectOption(selectedAction: string): void {
    switch (selectedAction) {
      case 'modify':
        this._modifyAction();
        break;
      case 'delete':
        this._deleteAction();
        break;
      default:
        break;
    }
  }

  cancelEdit(): void {
    this.isEditingComment = false;
    this.isLoadingEditingComment = false;
  }

  displayReplyZone(): void {
    this.dashboardComment.hasToDisplayReplies = true;
    this.dashboardComment.hasToDisplayReplyZone = true;
    this.setCursorAtTheEndOfCommentInput$.next();
  }

  validateEdit(newMessage: string): void {
    if (!newMessage?.length || (newMessage?.length && newMessage === this.dashboardComment.message)) {
      return;
    }

    this._editCurrentComment(newMessage);
  }

  cancelPostComment(): void {
    this.dashboardComment.hasToDisplayReplyZone = false;
  }

  validatePostComment(message: string): void {
    if (!message?.length) {
      return;
    }

    this._replyToComment(message);
  }

  getDCSearchMode(): string {
    const storedSearchMode = localStorage.getItem('dc-search-mode');

    return storedSearchMode ? storedSearchMode : 'advanced';
  }

  pushGTMDeleteDashboardCommentEvent() {
    const searchMode = this.getDCSearchMode();
    this._gtmService.pushEvent({
      event: 'dc_dashboard_comment_deleted',
      dc_dashboard_name: this.currentDashboard.name,
      dc_mode: searchMode,
    });
  }

  private _removeCurrentComment(): void {
    this.isLoadingRemovingComment = true;
    this._dashboardCommentService
      .removeDashboardComment(
        this.dashboardComment.id,
        this.dashboardComment.sourceId,
        this.dashboardComment.dashboardId,
      )
      .pipe(
        takeUntil(this._destroyed$),
        catchError((error: unknown) => {
          this._logger.error(
            `[DASHBOARD_COMMENTS_THREAD] Error while removing comment : `,
            this.dashboardComment,
            error,
          );
          this._alertService.error('An error occurred while removing this comment');

          return of({ hasError: true });
        }),
        mergeMap((result) => {
          let result$ = of(null);

          if (!result || (result && !(result as { hasError: boolean }).hasError)) {
            this._dashboardCommentCacheService.removeComment(
              this.dashboardComment.id,
              this.dashboardComment.parentId,
              this.dashboardComment.nestingLevel,
            );
            result$ = this._dashboardCommentService.refreshDashboardComments(false);
          }

          this.isLoadingRemovingComment = false;
          this.pushGTMDeleteDashboardCommentEvent();

          return result$;
        }),
      )
      .subscribe();
  }

  private _modifyAction(): void {
    this.isEditingComment = true;
    this.setCursorAtTheEndOfEditZoneOnEditing$.next();
  }

  private _deleteAction(): void {
    const confirmDeleteModal = this._dialogsService.confirm({
      message: 'Are you sure you want to delete this comment?',
      confirmText: 'Yes',
      cancelText: 'Cancel',
    });
    confirmDeleteModal
      .afterClosed()
      .pipe(take(1), takeUntil(this._destroyed$))
      .subscribe((hasToDelete) => {
        if (hasToDelete) {
          this._removeCurrentComment();
        }
      });
  }

  private _editCurrentComment(newMessage): void {
    this.isLoadingEditingComment = true;
    this._dashboardCommentService
      .updateDashboardComment(
        this.dashboardComment.id,
        this.dashboardComment.sourceId,
        this.dashboardComment.dashboardId,
        newMessage,
      )
      .pipe(
        takeUntil(this._destroyed$),
        catchError((error: unknown) => {
          this._logger.error(
            `[DASHBOARD_COMMENTS_THREAD] Error while updating comment : `,
            this.dashboardComment,
            error,
          );
          this._alertService.error('An error occurred while updating this comment');

          return of({ hasError: true });
        }),
        mergeMap((result) => {
          let result$ = of(null);

          if (!result || (result && !(result as { hasError: boolean }).hasError)) {
            this._dashboardCommentCacheService.editComment(
              newMessage,
              this.dashboardComment.id,
              this.dashboardComment.nestingLevel,
            );
            result$ = this._dashboardCommentService.refreshDashboardComments(false);
          }

          this.cancelEdit();

          return result$;
        }),
      )
      .subscribe();
  }

  private _replyToComment(message: string) {
    this.isLoadingReplyingComment = true;
    this._dashboardCommentService
      .addNewDashboardComment(
        this.dashboardComment.sourceId,
        this.dashboardComment.dashboardId,
        this.dashboardComment.id,
        message,
      )
      .pipe(
        takeUntil(this._destroyed$),
        catchError((error: unknown) => {
          this._logger.error(
            `[DASHBOARD_COMMENTS_THREAD] Error while replying comment : `,
            this.dashboardComment,
            error,
          );
          this._alertService.error('An error occurred while replying this comment');

          return of({ hasError: true });
        }),
        mergeMap((result) => {
          let result$ = of(null);

          // TODO: [Revisit] (18.01.2024) - types
          if (!result || (result && !(result as { hasError: boolean }).hasError)) {
            result$ = this._dashboardCommentService.refreshDashboardComments(true);
          }

          this.isLoadingReplyingComment = false;

          return result$;
        }),
      )
      .subscribe();
  }
}
