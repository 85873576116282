import { Injectable } from '@angular/core';
import type { IDatalakeObjectAPI } from '@dataportal/datalake-parsing';
import { Logger } from '@dataportal/front-shared';

import { DATAPORTAL_DBS, IndexedDbCacheService } from './indexed-db-cache.service';

import type {
  IDatalakeCurrentDirMetadata,
  IDatalakeStoreOptions,
  IDatalakeUserPermission,
  IDatalakeWithPermissionListResponse,
  IObjectsStoreLine,
} from '../entities/datalake';
import { areObjectsEqual, formatPath } from '../entities/datalake';

@Injectable()
export class DatalakeObjectsCacheService {
  static key(bucketName: string, options: IDatalakeStoreOptions): string {
    const pathToUse = formatPath(options.path);
    let key = `[${bucketName}]-[${pathToUse}]-`;

    if (options.byUser) {
      key += `-[by-user]-`;
    }

    if (options.provider === 'aws' || options.provider === 'azure') {
      key += `[${options.provider}]`;

      if (options.provider === 'azure') {
        if (options.tenant) {
          key += `-[${options.tenant}]`;
        } else {
          key += `[default]`;
        }
      }
    } else {
      key += '[default]';
    }

    return key;
  }

  /**
   * Determine whether or not all data have been fetched for a given set of options
   * @param metadata - api response metadata object (continuation token per provider/tenant)
   */
  static isThereTokenRemaining(metadata: IDatalakeCurrentDirMetadata[]): boolean {
    if (metadata) {
      for (const token of metadata) {
        if (token.token) {
          return true;
        }
      }
    } else {
      return true;
    }

    return false;
  }

  private static _areMetadataEqual(
    metadataA: IDatalakeCurrentDirMetadata[],
    metadataB: IDatalakeCurrentDirMetadata[],
  ): boolean {
    for (const tokenA of metadataA) {
      const correspondingBToken = metadataB.find(
        (token) => token.provider === tokenA.provider && token.tenant === tokenA.tenant,
      );

      if (!correspondingBToken || tokenA.token !== correspondingBToken.token) {
        return false;
      }
    }

    return true;
  }

  constructor(private readonly _indexedDb: IndexedDbCacheService, private readonly _logger: Logger) {}

  async invalidate(bucketName: string, options: IDatalakeStoreOptions): Promise<void> {
    const cacheKey = DatalakeObjectsCacheService.key(bucketName, options);
    this._logger.debug('[DatalakeCacheService] Invalidating cache with key', cacheKey);
    await this._indexedDb.delete(cacheKey);
  }

  async invalidateCompleteCache(): Promise<void> {
    await this._indexedDb.removeDb(DATAPORTAL_DBS.DATALAKE);
  }

  async update(
    response: IDatalakeWithPermissionListResponse,
    bucketName: string,
    options: IDatalakeStoreOptions,
  ): Promise<IObjectsStoreLine> {
    const cacheKey = DatalakeObjectsCacheService.key(bucketName, options);
    this._logger.debug('[DatalakeCacheService] Updating cache with key', cacheKey);
    const existingCache = await this.get(bucketName, options);
    this._logger.debug('[DatalakeCacheService] Existing cache', existingCache);

    if (existingCache) {
      if (!DatalakeObjectsCacheService._areMetadataEqual(existingCache.metadata, response.metadata)) {
        this._logger.debug(
          '[DatalakeCacheService] Fetched metadata does not match the ones in cache. New objects are being added to cache line',
        );

        for (let index = 0; index < response.resources?.length; ++index) {
          const responseObject = response.resources[index];
          const responseObjectPermission = response.userPermissions[index];

          if (!existingCache.objects.some((object) => responseObject.name === object.name)) {
            existingCache.objects.push(responseObject);
            existingCache.userPermissions.push(responseObjectPermission);
          }
        }

        existingCache.metadata = response.metadata;
        existingCache.isThereDataRemaining = DatalakeObjectsCacheService.isThereTokenRemaining(response.metadata);
      }

      await this._set(cacheKey, existingCache);

      return existingCache;
    } else {
      this._logger.debug('[DatalakeCacheService] No existing cache, creating new line');
      const newObjectLine: IObjectsStoreLine = {
        objects: response?.resources,
        metadata: response?.metadata,
        userPermissions: response?.userPermissions,
        isThereDataRemaining: DatalakeObjectsCacheService.isThereTokenRemaining(response?.metadata),
        allChecked: false,
      };
      this._logger.debug('[DatalakeCacheService] New line', newObjectLine);
      await this._set(cacheKey, newObjectLine);

      return newObjectLine;
    }
  }

  private async _set(key: string, line: IObjectsStoreLine): Promise<void> {
    try {
      await this._indexedDb.set(key, line);
    } catch (e) {
      this._logger.warn('[DatalakeObjectsCacheService] Objects could not be set to cache', e);
    }
  }

  /**
   * Add an object to cache line (only if cache line already exists)
   * @param bucketName
   * @param options
   * @param object
   * @param userPermission
   */
  async append(
    bucketName: string,
    options: IDatalakeStoreOptions,
    object: IDatalakeObjectAPI,
    userPermission: IDatalakeUserPermission,
  ): Promise<IObjectsStoreLine | undefined> {
    const cacheKey = DatalakeObjectsCacheService.key(bucketName, options);
    const cachedLine = await this.get(bucketName, options);

    if (cachedLine) {
      const objectsLineWithoutNewOne = cachedLine.objects.filter(
        (existingObject) => existingObject.name !== object.name,
      );
      cachedLine.objects = objectsLineWithoutNewOne.concat(object);
      cachedLine.userPermissions.push(userPermission);
      await this._set(cacheKey, cachedLine);

      return cachedLine;
    }
  }

  /**
   * Override an object in cache line if exists, append it if not
   * @param bucketName
   * @param options
   * @param newObjectValue
   * @param userPermission
   */
  async override(
    bucketName: string,
    options: IDatalakeStoreOptions,
    newObjectValue: IDatalakeObjectAPI,
    userPermission: IDatalakeUserPermission,
  ): Promise<IObjectsStoreLine | undefined> {
    const cacheKey = DatalakeObjectsCacheService.key(bucketName, options);
    const cachedLine = await this.get(bucketName, options);

    if (cachedLine) {
      let shouldAppend = true;
      let relatedObjectIndex: number;
      cachedLine.objects = cachedLine.objects.map((existingObject, idx) => {
        if (areObjectsEqual(existingObject, newObjectValue)) {
          shouldAppend = false;
          relatedObjectIndex = idx;

          return newObjectValue;
        }

        return existingObject;
      });

      if (shouldAppend) {
        cachedLine.objects.push(newObjectValue);
        cachedLine.userPermissions.push(userPermission);
      } else {
        cachedLine.userPermissions[relatedObjectIndex] = userPermission;
      }

      await this._set(cacheKey, cachedLine);

      return cachedLine;
    }
  }

  /**
   * Rename an object in a cache line (only if cache line already exists)
   * @param bucketName
   * @param options
   * @param object
   * @param newObjectName
   */
  async renameObject(
    bucketName: string,
    options: IDatalakeStoreOptions,
    object: IDatalakeObjectAPI,
    newObjectName: string,
  ): Promise<IObjectsStoreLine | undefined> {
    const cacheKey = DatalakeObjectsCacheService.key(bucketName, options);
    const cachedLine = await this.get(bucketName, options);

    if (cachedLine) {
      cachedLine.objects.forEach((existingObject) => {
        if (areObjectsEqual(existingObject, object)) {
          existingObject.checked = true;
          existingObject.name = newObjectName;
        }
      });
      await this._set(cacheKey, cachedLine);

      return cachedLine;
    }
  }

  /**
   * Remove many objects to cache line (only if cache line already exists)
   * @param key
   * @param toRemove
   */
  async removeObjects(key: string, toRemove: IDatalakeObjectAPI[]): Promise<IObjectsStoreLine | undefined> {
    const cachedLine = await this._get(key);

    if (cachedLine) {
      cachedLine.objects = cachedLine.objects.filter((o1) => !toRemove.some((o2) => areObjectsEqual(o1, o2)));
      await this._set(key, cachedLine);

      return cachedLine;
    }
  }

  async get(bucketName: string, options: IDatalakeStoreOptions): Promise<IObjectsStoreLine | null> {
    const cacheKey = DatalakeObjectsCacheService.key(bucketName, options);

    return await this._get(cacheKey);
  }

  private async _get(key: string) {
    try {
      return await this._indexedDb.get<IObjectsStoreLine | null>(key);
    } catch (e) {
      this._logger.warn('[DatalakeObjectsCacheService] Objects could not be read from cache', e);

      return null;
    }
  }
}
