<svg class="dpg-icon" width="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
  <path class="dpg-fg-medium-fill" d="M32.5,165V80.6c0-8.8,7.2-16,16-16H165v-32H48.5c-26.5,0-48,21.5-48,48V165H32.5z" />
  <path
    class="dpg-fg-medium-fill"
    d="M32.5,432.6v-84.5h-32v84.5c0,26.5,21.5,48,48,48H165v-32H48.5C39.7,448.6,32.5,441.4,32.5,432.6z"
  />
  <path
    class="dpg-fg-medium-fill"
    d="M480,348.1v84.5c0,8.8-7.2,16-16,16H347.5v32H464c26.5,0,48-21.5,48-48v-84.5H480z"
  />
  <path
    class="dpg-fg-medium-fill"
    d="M480,80.6V165h32V80.6c0-26.5-21.5-48-48-48H347.5v32H464C472.8,64.6,480,71.8,480,80.6z"
  />
</svg>
