import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlValueAccessor } from '@ngneat/reactive-forms';

@Component({
  selector: 'adl-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  host: {
    '(blur)': 'onTouched()',
  },
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => CheckboxComponent), multi: true }],
})
export class CheckboxComponent extends ControlValueAccessor<boolean> {
  // Attributes
  /* inputs */
  @Input() checked = false;
  @Input() disabled = false;
  @Input() checkboxSize: 'small' | 'normal' = 'normal';
  @Input() checkboxColor: 'white' | 'blue' | 'green' = 'white';
  @Input() shape: 'square' | 'circle' = 'square';
  @Input() hasPaddingRight = true;
  /* outputs */
  @Output() changed = new EventEmitter<boolean>();

  // Methods
  handleChange(event: Event): void {
    const value = (event.target as HTMLInputElement).checked;
    this.changed.next(value);
    this.writeValue(value);

    if (this.onChange) {
      this.onChange(value);
    }
  }

  writeValue(value: boolean) {
    this.checked = value;
  }
}
