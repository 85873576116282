import { Entity } from '@decahedron/entity';

export class AdInformation extends Entity {
  id: string = null;
  companyName: string = null;
  department: string = null;
  country: string = null;
  displayName: string = null;
  jobTitle: string = null;
  mail: string = null;
  officeLocation: string = null;
  postalCode: string = null;
}
