import { CommonModule } from '@angular/common';
import type { ModuleWithProviders } from '@angular/core';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LoggerModule } from 'ngx-logger';
import { ToastrComponentlessModule } from 'ngx-toastr';

import { BytesPipe } from './pipes/bytes.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { ListCssPipe } from './pipes/list-css.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { SafeUrlPipe } from './pipes/safeUrl.pipe';

import { NgxDatatableResizeWatcherDirective } from './directives/ngx-datatable-resize-watcher.directive';
import { StopClickBubblingDirective } from './directives/stop-click-bubbling.directive';

import type { ISharedOptions } from './shared-options';
import { SHARED_OPTIONS } from './shared-options';

// Module
// @dynamic
@NgModule({
  imports: [CommonModule, LoggerModule, ToastrComponentlessModule, RouterModule],
  declarations: [
    BytesPipe,
    FilterPipe,
    ListCssPipe,
    SafePipe,
    SafeUrlPipe,
    StopClickBubblingDirective,
    NgxDatatableResizeWatcherDirective,
  ],
  exports: [
    BytesPipe,
    FilterPipe,
    ListCssPipe,
    SafePipe,
    SafeUrlPipe,
    StopClickBubblingDirective,
    NgxDatatableResizeWatcherDirective,
  ],
})
export class SharedModule {
  // Statics
  static forRoot(options: ISharedOptions): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [{ provide: SHARED_OPTIONS, useValue: options }],
    };
  }
}
