import { CommonModule } from '@angular/common';
import type { ModuleWithProviders } from '@angular/core';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AdlModule } from '@dataportal/adl';
import { ApiModule } from '@dataportal/front-api';
import { SharedModule } from '@dataportal/front-shared';
import { UsersModule } from '@dataportal/users';
import { NgSelectModule } from '@ng-select/ng-select';

import { GraphApiService } from './services/graph-api.service';

import { LegacyUserSelectComponent } from './components/legacy-user-select/legacy-user-select.component';
import { UserSelectComponent } from './components/user-select/user-select.component';

import type { IGraphApiOptions } from './graph-api-options';
import { GRAPH_API_OPTIONS } from './graph-api-options';

@NgModule({
  imports: [CommonModule, FormsModule, NgSelectModule, AdlModule, SharedModule, ApiModule, UsersModule],
  declarations: [LegacyUserSelectComponent, UserSelectComponent],
  providers: [GraphApiService],
  exports: [LegacyUserSelectComponent, UserSelectComponent],
})
export class GraphApiModule {
  static forRoot(options: IGraphApiOptions): ModuleWithProviders<GraphApiModule> {
    return {
      ngModule: GraphApiModule,
      providers: [{ provide: GRAPH_API_OPTIONS, useValue: options }],
    };
  }
}
