import type { OnInit } from '@angular/core';
import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GoogleTagManagerService } from '@dataportal/analytics';
import { AlertService, Logger } from '@dataportal/front-shared';

import { PowerBiBookmarksService } from '../../services/power-bi-bookmarks.service';
import { PowerBiEmbedService } from '../../services/power-bi-embed.service';

import type { Source } from '../../../sources/entities/source';
import type { Dashboard } from '../../entities/dashboard.model';

// Component
@Component({
  selector: 'dpg-dashboard-url-modal',
  templateUrl: './dashboard-url-modal.component.html',
  styleUrls: ['./dashboard-url-modal.component.scss'],
})
export class DashboardUrlModalComponent implements OnInit {
  // Attributes
  source: Source;
  dashboard: Dashboard;

  shareLink: string;
  isGeneratingShareLink = false;

  @ViewChild('linkInput') linkInput: ElementRef<HTMLInputElement>;

  // Constructor
  constructor(
    private readonly _logger: Logger,
    private readonly _alertService: AlertService,
    private readonly _powerBiEmbedService: PowerBiEmbedService,
    private readonly _powerBiFiltersService: PowerBiBookmarksService,
    private readonly _gtmService: GoogleTagManagerService,
    private readonly _activeMatModal: MatDialogRef<DashboardUrlModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    data: {
      source: Source;
      dashboard: Dashboard;
    },
  ) {
    this.source = data?.source ? data.source : this.source;
    this.dashboard = data?.dashboard ? data.dashboard : this.dashboard;
  }

  // Lifecycle
  ngOnInit(): void {
    this.share();
  }

  // Methods
  copyShareLinkToClipboard(event: MouseEvent): void {
    event.stopPropagation();

    this.linkInput.nativeElement.select();
    document.execCommand('copy');
    this.pushGTMShareDashboardEvent();
    this._alertService.success('Link copied');
  }

  share(): void {
    if (this.isGeneratingShareLink) {
      return;
    }

    try {
      this.isGeneratingShareLink = true;
      this._powerBiFiltersService
        .generateLink(this.source.id, this._powerBiEmbedService.getCurrentPage().name, this.dashboard.name)
        .subscribe(
          (url: string) => {
            this._logger.debug('shareLink', url);
            this.shareLink = url;
            this.isGeneratingShareLink = false;
          },
          (err: unknown) => {
            this._logger.debug('Could not get a share link', err);
            this.isGeneratingShareLink = false;
          },
        );
    } catch (err) {
      this._logger.debug('Could not request a share link', err);
      this.isGeneratingShareLink = false;
    }
  }

  close(): void {
    this._activeMatModal.close();
  }

  pushGTMShareDashboardEvent() {
    const searchMode = this.getDCSearchMode();
    this._gtmService.pushEvent({
      event: 'dc_dashboard_shared',
      dc_dashboard_name: this.dashboard.name,
      dc_mode: searchMode,
      dc_share_format: 'get_a_link',
    });
  }

  getDCSearchMode(): string {
    const storedSearchMode = localStorage.getItem('dc-search-mode');

    return storedSearchMode ? storedSearchMode : 'advanced';
  }
}
