<label #uploadButton>
  <adl-tooltip-buttons
      (buttonInputEvent)="uploadButtonChosen($event)"
      [buttonsList]="fileOrFolderButtonList"
      *ngIf="showDirectoryChoice"
  >
    <adl-button [disabled]="disabled" [type]="type" [size]="size" [isSquareButton]="isSquareButton" >
      <adl-icon [size]="24" icon="upload" class="mr5"></adl-icon>
      UPLOAD
    </adl-button>
  </adl-tooltip-buttons>

  <adl-button
      *ngIf="!showDirectoryChoice"
      [disabled]="disabled"
      (clicked)="onUploadFileClick()"
      [type]="type" [size]="size" [isSquareButton]="isSquareButton"
  >
    <ng-content></ng-content>
  </adl-button>

  <input
      #fileInput
      type="file"
      hidden
      (click)="resetFileInput()"
      (change)="onFileChanged(fileInput.files)"
      [multiple]="multiple"
      [accept]="formattedRestrictedFileTypes"
  />

  <input
      #directoryInput
      type="file"
      hidden
      webkitdirectory
      directory
      (click)="resetFileInput()"
      (change)="onFileChanged(directoryInput.files)"
      [multiple]="multiple"
      [accept]="formattedRestrictedFileTypes"
  />
</label>
