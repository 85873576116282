import type { OnDestroy } from '@angular/core';
import { Component, Inject, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IPermissionsOptions, PERMISSIONS_OPTIONS, PermissionsService } from '@dataportal/permissions';

@Component({
  selector: 'dpg-admin-button',
  templateUrl: './admin-button.component.html',
  styleUrls: ['./admin-button.component.scss'],
})
export class AdminButtonComponent implements OnDestroy {
  @Input() isVisible = false;
  @Input() external: boolean;

  notificationsNamesToWatchForAdmin = ['accessRequestsNotification', 'publicationsNotification'];

  private readonly _destroyed$ = new Subject();

  constructor(
    readonly _router: Router,
    private readonly _permissionsService: PermissionsService,
    @Inject(PERMISSIONS_OPTIONS) protected readonly _options: IPermissionsOptions,
  ) {}

  redirectToAdminPages() {
    this._permissionsService.permissionsAdmin$.pipe(takeUntil(this._destroyed$)).subscribe((permissions) => {
      if (permissions) {
        if (permissions.sources) {
          this._router.navigateByUrl('/admin/sources');
        } else if (permissions.leons) {
          this._router.navigateByUrl('/admin/leons');
        } else if (permissions.groups) {
          this._router.navigateByUrl('/admin/groups');
        } else if (permissions.portals) {
          this._router.navigateByUrl('/admin/portals');
        } else if (permissions['access-requests']) {
          this._router.navigateByUrl('/admin/access-request');
        } else if ((permissions as { glossary: any }).glossary) {
          // TODO: [Revisit] (18.01.2024) - type
          this._router.navigateByUrl('/admin/glossary');
        } else if (permissions.users) {
          this._router.navigateByUrl('/admin/users');
        } else void this._router.navigate(this._options.errorRouterLink);
      } else {
        void this._router.navigate(this._options.errorRouterLink);
      }
    });
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }
}
