import { CommonModule } from '@angular/common';
import type { ModuleWithProviders } from '@angular/core';
import { NgModule } from '@angular/core';
import { SharedModule } from '@dataportal/front-shared';
import { UsersModule } from '@dataportal/users';
import { WebsocketModule } from '@dataportal/websocket';

import { AuthRoutingModule } from './auth-routing.module';

import { AuthStorageService } from './services/auth-storage.service';
import { CognitoAuthService } from './services/cognito-auth.service';
import { CurrentUserService } from './services/current-user.service';
import { E2eMockedAuthService } from './services/e2e-mocked-auth.service';
import { MsalAuthService } from './services/msal-auth.service';
import { MultiAuthService } from './services/multi-auth.service';

import { AuthCallbackComponent } from './pages/auth-callback/auth-callback.component';
import { AuthLogoutComponent } from './pages/auth-logout/auth-logout.component';

import type { IAuthOptions } from './auth-options';
import { AUTH_OPTIONS } from './auth-options';

// Module
// @dynamic
@NgModule({
  declarations: [AuthCallbackComponent, AuthLogoutComponent],
  exports: [AuthCallbackComponent, AuthLogoutComponent],
  imports: [CommonModule, AuthRoutingModule, SharedModule, UsersModule, WebsocketModule],
  providers: [
    MsalAuthService,
    MultiAuthService,
    CurrentUserService,
    CognitoAuthService,
    AuthStorageService,
    E2eMockedAuthService,
  ],
})
export class AuthModule {
  // Statics
  static forRoot(options: IAuthOptions): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [{ provide: AUTH_OPTIONS, useValue: options }],
    };
  }
}
