import { Component, EventEmitter, Input, NgModule, Output, TemplateRef, ViewChild } from '@angular/core';

import { DropdownTriggerDirective } from './dropdown-trigger.directive';

import type { IDropdownPanel } from './dropdown-panel.model';

@Component({
  selector: 'adl-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent implements IDropdownPanel {
  @ViewChild(TemplateRef) templateRef: TemplateRef<any>;
  @Output() closed = new EventEmitter<void>();

  @Input() width = '200px';
  @Input() height = '200px';
  @Input() clickableContent = false;
  @Input() contentGap = '16px';
  @Input() padding = '0.8rem';
  @Input() margin = '0';
}

// Module
@NgModule({
  declarations: [DropdownComponent, DropdownTriggerDirective],
  exports: [DropdownComponent, DropdownTriggerDirective],
})
export class DropdownModule {}
