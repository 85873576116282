import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { first, tap } from 'rxjs/operators';
import type { IDatalakeObject, IDatalakeObjectAPI } from '@dataportal/datalake-parsing';

import { ExplorerDownloadUploadUrlService } from './explorer-download-upload-url.service';

import type { IDatalakeAPIOptions } from '../entities/datalake';

@Injectable()
export class FileViewService {
  constructor(
    private readonly _explorerDownloadUrlService: ExplorerDownloadUploadUrlService,
    private readonly _router: Router,
  ) {}

  downloadFile({ selectedFile, datalakePathName }: { selectedFile: IDatalakeObject; datalakePathName: string }): void {
    const downloadOptions = this.getDownloadOptions({ selectedFile, datalakePathName });

    this._explorerDownloadUrlService
      .singleDownloadUrl(downloadOptions.bucket, downloadOptions.options)
      .pipe(
        first(),
        tap((downloadUrl) => this._explorerDownloadUrlService.downloadAction(downloadUrl)),
      )
      .subscribe();
  }

  getDownloadOptions({ selectedFile, datalakePathName }: { selectedFile: IDatalakeObject; datalakePathName: string }): {
    bucket: string;
    options: IDatalakeAPIOptions;
  } {
    const currentFile = this._getCurrentFileFromUrl();

    const fileName = selectedFile.name ?? currentFile.name;

    const urlTree = this._router.parseUrl(this._router.url);
    const urlSegments = urlTree.root.children.primary.segments;
    const datalakePathNameIndex = urlSegments.map((segment) => segment.path).indexOf(datalakePathName);
    const bucket = urlSegments[datalakePathNameIndex + 1].toString(); // by default, bucket name is given after '.../datalake/'
    const path = selectedFile.path ?? currentFile.path;
    const options: IDatalakeAPIOptions = {
      provider: selectedFile?.provider ? selectedFile.provider : currentFile.provider,
      tenant: selectedFile && selectedFile.tenant ? selectedFile.tenant : currentFile.tenant,
      path: fileName ? `${path}/${fileName}`.replace(/\/+/, '/').replace(/^\//, '') : path,
    };

    return { bucket: bucket, options: options };
  }

  createNewTabUrl({
    selectedFile,
    datalakePathName,
    addedUrlRepo,
  }: {
    selectedFile: IDatalakeObject;
    datalakePathName: string;
    addedUrlRepo: string;
  }): string {
    const fileName = selectedFile.name;

    const downloadOptions = this.getDownloadOptions({
      selectedFile,
      datalakePathName,
    }); // path cleaned (duplicated '/' and first '/' have been removed)
    const currentPathSlugified = `${downloadOptions.bucket}-${downloadOptions.options.path.replace(/\//g, '-')}`;
    const addedUrl = `${addedUrlRepo}/${currentPathSlugified}`;
    const url = this._router.url;
    const splittedUrl = url.split('?');
    const urlWithoutParams = splittedUrl[0];
    const pathSegments = downloadOptions.options.path.split('/');
    pathSegments.pop(); // to remove the '/fileName'
    const pathWithoutFileName = pathSegments.join('/');
    const queryParams = [
      `path=${pathWithoutFileName}`,
      `provider=${downloadOptions.options.provider}`,
      `tenant=${downloadOptions.options.tenant}`,
    ];

    if (!downloadOptions.options.tenant) {
      queryParams.pop();
    }

    const urlTree = this._router.createUrlTree([`${urlWithoutParams}/${addedUrl}`]);
    let urlToOpen = this._router.serializeUrl(urlTree) + '?';
    urlToOpen += queryParams.join('&');
    urlToOpen += `&name=${fileName}`;

    return urlToOpen;
  }

  private _getCurrentFileFromUrl(): IDatalakeObjectAPI | null {
    const urlTree = this._router.parseUrl(this._router.url);
    const queryParams = urlTree.queryParams;

    const file: unknown = {
      provider: queryParams.provider,
      name: queryParams.name,
      tenant: queryParams.tenant,
      path: queryParams.path,
    };

    if (this._isCorrectFile(file)) {
      return file;
    } else {
      return null;
    }
  }

  private _isCorrectFile(file: unknown): file is IDatalakeObjectAPI {
    return (
      typeof file === 'object' &&
      file !== null &&
      'name' in file &&
      'provider' in file &&
      'tenant' in file &&
      'path' in file
    );
  }
}
