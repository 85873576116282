import { Component, Input } from '@angular/core';

import { GuardianCheck } from '../../entities/guardian.check';
import { IGuardianStatus } from '../../entities/guardian-form';

/**
 * Component that retrieve the result of a failed run check
 */
@Component({
  selector: 'dpg-guardian-check-run-details',
  templateUrl: './guardian-check-run-details.component.html',
  styleUrls: ['./guardian-check-run-details.component.scss'],
})
export class GuardianCheckRunDetailsComponent {
  // Inputs
  @Input() guardianStatus: IGuardianStatus;
  @Input() selectedCheck: GuardianCheck;

  static MAPPING_BETWEEN_ERROR_CODE_AND_DISPLAYED_ERROR = new Map<string, string>([
    ['PAIR_GREATER_ERROR', 'GREATER OR EQUAL'],
    // to fill when possible
  ]);
  static MAPPING_BETWEEN_ERROR_CODE_AND_DISPLAYED_INCORRECT_VALUES_FUNCTION = new Map<
    string,
    (incorrectValues: (number | string)[]) => string
  >(
    [
      [
        'PAIR_GREATER_ERROR',
        (incorrectValues: (number | string)[]): string =>
          GuardianCheckRunDetailsComponent._getFormattedIncorrectValues(
            incorrectValues.map((value) => value?.toString()?.replace(',', ' vs ')),
          ),
      ],
    ],
    // to fill when possible
  );

  private static _getCleanedErrorCode(errorCode: string): string {
    return errorCode?.replace('_ERROR', '');
  }

  private static _insertSlashSeparator(incorrectValues: (number | string)[]): string {
    return incorrectValues?.join(' / ') || '';
  }

  private static _getFormattedIncorrectValues(incorrectValues: (number | string)[]): string {
    return GuardianCheckRunDetailsComponent._insertSlashSeparator(incorrectValues || []);
  }

  private static _getFormattedIncorrectLines(incorrectValues: (number | string)[]): string {
    return GuardianCheckRunDetailsComponent._insertSlashSeparator(incorrectValues || []);
  }

  getDisplayedErrorFromErrorCode(errorCode: string): string {
    const displayedError = GuardianCheckRunDetailsComponent.MAPPING_BETWEEN_ERROR_CODE_AND_DISPLAYED_ERROR.get(
      errorCode || '',
    );

    return displayedError?.length ? displayedError : GuardianCheckRunDetailsComponent._getCleanedErrorCode(errorCode);
  }

  getDisplayedIncorrectValuesFromErrorCodeAndValues(errorCode: string, incorrectValues: (number | string)[]): string {
    const displayedIncorrectValuesFunction =
      GuardianCheckRunDetailsComponent.MAPPING_BETWEEN_ERROR_CODE_AND_DISPLAYED_INCORRECT_VALUES_FUNCTION.get(
        errorCode || '',
      );

    return displayedIncorrectValuesFunction
      ? displayedIncorrectValuesFunction(incorrectValues)
      : GuardianCheckRunDetailsComponent._getFormattedIncorrectValues(incorrectValues);
  }

  getDisplayedIncorrectLines(incorrectLines: number[]): string {
    return GuardianCheckRunDetailsComponent._getFormattedIncorrectLines(incorrectLines);
  }
}
