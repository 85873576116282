import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '@dataportal/front-shared';

import { DatalakeParseObjectService } from './services/datalake-parse-object.service';

// Module
@NgModule({
  imports: [CommonModule, SharedModule],
  declarations: [],
  exports: [],
  providers: [DatalakeParseObjectService],
})
export class DatalakeParsingModule {}
