import type { OnInit } from '@angular/core';
import { Component, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { EnvironmentService } from '@dataportal/front-environment';
import { ISharedOptions, Logger, SHARED_OPTIONS } from '@dataportal/front-shared';
import type { IMinimalUser, Profile } from '@dataportal/profile';
import { ProfileService } from '@dataportal/profile';

@Component({
  selector: 'app-cant-find-your-folder-modal',
  templateUrl: './cant-find-your-folder-modal.component.html',
  styleUrls: ['./cant-find-your-folder-modal.component.scss'],
  providers: [ProfileService],
})
export class CantFindYourFolderModalComponent implements OnInit {
  constructor(
    private readonly _activeMatModal: MatDialogRef<CantFindYourFolderModalComponent>,
    private readonly _profileService: ProfileService,
    private readonly _logger: Logger,
    public environement: EnvironmentService,
    @Inject(SHARED_OPTIONS) private readonly _options: ISharedOptions,
    readonly environmentService: EnvironmentService,
  ) {}

  isLoading = true;
  profile: Profile;
  portalOwners: IMinimalUser[] = [];

  ngOnInit(): void {
    this._profileService.getProfile().subscribe(
      (profile) => {
        this.profile = profile;
        this.portalOwners = this._profileService.handlePortals(this.profile);
        this.isLoading = false;
      },
      (error: unknown) => {
        this._logger.error('[GET PROFILE] Error', error);
      },
    );
  }

  cancel(): void {
    this._activeMatModal.close();
  }

  openTicketForAzure(): void {
    window.open(this._options.adminDatalakeSupportLink, '_blank');
  }

  openMailToOwner(ownerMail: string): void {
    window.location.assign(`mailto:${ownerMail}`);
  }
}
