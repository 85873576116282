import type {
  IAPIDatalakeReferential,
  IAPITableReferential,
  ICheckRun,
  IGuardianAPIChecksMap,
} from '@dataportal/front-api';
import type { IBaseGuardianDatalakePath } from '@dataportal/front-shared';

export type CheckType = 'file-content' | 'last-modification';
export type FileFormat = 'csv' | 'xlsx' | 'json';
export type FileDelimiter = ';' | ',' | '|' | ' ' | '\t';
export type DecimalSeparator = '.' | ',';
export type DatalakeColumnType = 'integer' | 'float' | 'string' | 'datetime';
export type SnowflakeColumnType =
  | 'BIGINT'
  | 'DECIMAL'
  | 'FLOAT'
  | 'INT'
  | 'INTEGER'
  | 'NUMERIC'
  | 'REAL'
  | 'SMALLINT'
  | 'TINYINT'
  | 'BINARY'
  | 'CHAR'
  | 'NCHAR'
  | 'NVARCHAR'
  | 'NTEXT'
  | 'TEXT'
  | 'VARBINARY'
  | 'VARCHAR'
  | 'BOOLEAN'
  | 'DATE'
  | 'DATETIME'
  | 'TIME'
  | 'TIMESTAMP'
  | 'TIMESTAMP_NTZ'
  | 'ARRAY'
  | 'OBJECT'
  | 'VARIANT';
export type ColumnGlobalType = 'numeric' | 'text' | 'unknown';
export type RegexOption = 'g' | 'm' | 'i' | 'x' | 'X' | 's' | 'u' | 'U' | 'A' | 'J' | 'D';
export type RegexReferential = 'manual-regex' | 'prepared-regex';
export type ExternalReferential = 'mdh' | 'datalake';
export type Referential = RegexReferential | ExternalReferential;
export type GuardianCheckConstraint =
  | 'type'
  | 'isRequired'
  | 'isUnique'
  | 'minValue'
  | 'maxValue'
  | 'greaterThan'
  | 'regex'
  | 'mdh'
  | 'datalake_in'
  | 'datalake_not_in';
export type SchedulingPeriodicity = 'daily' | 'weekly' | 'monthly' | 'yearly';
export enum SchedulingDailyChoices {
  EVERY_N_DAYS = 'EVERY_N_DAYS',
  EVERY_WORKING_DAY = 'EVERY_WORKING_DAY',
}
export type Hour = '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12';
export type HourPeriod = 'am' | 'pm';
export type FullHour = Hour | '13' | '14' | '15' | '16' | '17' | '18' | '19' | '20' | '21' | '22' | '23';
export type DividerOfNumberOfMonths = '1' | '2' | '3' | '4' | '6';
export type WeekDay = 'mon' | 'tue' | 'wed' | 'thu' | 'fri' | 'sat' | 'sun';
export type YearMonth = 'jan' | 'feb' | 'mar' | 'apr' | 'may' | 'jun' | 'jul' | 'aug' | 'sep' | 'oct' | 'nov' | 'dec';
export enum CronParts {
  MINUTES,
  HOURS,
  DAY_NUMBER,
  MONTH,
  DAY_NAME,
}
export const globalDateType = ['datetime', 'DATE', 'DATETIME', 'TIME', 'TIMESTAMP', 'TIMESTAMP_NTZ'];

export const snowflakeColumnTypes: Array<{ label: string; value: string }> = [
  { label: 'BIGINT', value: 'BIGINT' },
  { label: 'DECIMAL', value: 'DECIMAL' },
  { label: 'FLOAT', value: 'FLOAT' },
  { label: 'INT', value: 'INT' },
  { label: 'INTEGER', value: 'INTEGER' },
  { label: 'NUMERIC', value: 'NUMERIC' },
  { label: 'REAL', value: 'REAL' },
  { label: 'SMALLINT', value: 'SMALLINT' },
  { label: 'TINYINT', value: 'TINYINT' },
  { label: 'BINARY', value: 'BINARY' },
  { label: 'CHAR', value: 'CHAR' },
  { label: 'NCHAR', value: 'NCHAR' },
  { label: 'NVARCHAR', value: 'NVARCHAR' },
  { label: 'NTEXT', value: 'NTEXT' },
  { label: 'TEXT', value: 'TEXT' },
  { label: 'VARBINARY', value: 'VARBINARY' },
  { label: 'VARCHAR', value: 'VARCHAR' },
  { label: 'BOOLEAN', value: 'BOOLEAN' },
  { label: 'DATE', value: 'DATE' },
  { label: 'DATETIME', value: 'DATETIME' },
  { label: 'TIME', value: 'TIME' },
  { label: 'TIMESTAMP', value: 'TIMESTAMP' },
  { label: 'TIMESTAMP_NTZ', value: 'TIMESTAMP_NTZ' },
  { label: 'ARRAY', value: 'ARRAY' },
  { label: 'OBJECT', value: 'OBJECT' },
  { label: 'VARIANT', value: 'VARIANT' },
];

export const datalakeColumnTypes: Array<{ label: string; value: string }> = [
  { label: 'Number', value: 'integer' },
  { label: 'Decimal', value: 'float' },
  { label: 'Text', value: 'string' },
  { label: 'Date (YYYY-MM-DD)', value: 'datetime' },
];

/**
 * Interface used for the mapping of the available column type according to the resource
 */
export interface ColumnTypeMap {
  datalakePath?: DatalakeColumnType;
  snowflake?: SnowflakeColumnType;
}

/**
 * DatalakePath Dataset Basic Information
 */
export interface IGuardianDatalakePath {
  path: string;
  provider: 'aws' | 'azure';
  tenant: string;
  filesystem: string;
  name?: string;
  type?: 'datalakePath';
}

/**
 * Snowflake Dataset Basic Information
 */
export interface ISnowflakeTableInfo {
  accountName: string;
  tableName: string;
  schemaName: string;
  databaseName: string;
  type?: 'snowflake';
}

/**
 * Interface used for the mapping of the dataset information according to the resource
 */
export interface IGuardianChecksDatasetMap {
  datalakePath?: IGuardianDatalakePath;
  snowflake?: ISnowflakeTableInfo;
}

export type IDatasetMappingWithAzureStatus<R extends ISupportedGuardianChecksResource> = Map<
  string,
  { status: IGuardianStatus; dataset: IGuardianChecksDatasetMap[R]; isLoading: boolean; isRunning: boolean }
>;

export interface IGuardianStatus<R extends ISupportedGuardianChecksResource = ISupportedGuardianChecksResource> {
  /**
   * Indicates if the path is checked by Guardian or not
   */
  isChecked: boolean;
  /**
   * Is the check is on a Datalake Path or in a Snowflake table
   */
  type: R;
  /**
   * Is the check concern only Guardian or also Flatfile
   */
  isFlatfileDeactivate?: boolean;
  /**
   * The Guardian check configuration ID
   */
  checkId?: number;
  /**
   * The Guardian check configuration
   */
  checkInfos: IGuardianAPIChecksMap[R];
  /**
   * The Guardian checks list that have been run
   */
  checks: ICheckRun[];
  /**
   * Indication whether Itrack flow enabled
   */
  isItrackEnabled?: boolean;
}

export type ISupportedGuardianChecksResource = keyof IGuardianChecksDatasetMap;

export interface IBannerRecipient {
  userId: string;
  isAlertedOnSuccess: boolean;
  isAlertedOnFailure: boolean;
}

/**
 * Interface describing all the necessary information of a column in a guardian check
 */
export interface IColumnField<R extends ISupportedGuardianChecksResource = ISupportedGuardianChecksResource> {
  /**
   * ID only used for front-end purpose to allow distinction between columns in the form
   */
  readonly columnId: string;
  // Non type specific
  columnName: string;
  type: ColumnTypeMap[R];
  isRequired: boolean;
  isUnique: boolean;
  // Integer / float / datetime
  minValue?: number | string;
  maxValue?: number | string;
  /**
   * This field is containing the current "greater than" column ID
   * This field is used in the form management
   */
  greaterThanColumnId?: string;
  /**
   * This field is containing the "greater than" column name
   * This field is just containing the first received greaterThanColumnName from API
   * This field is NOT used in the form management
   * It is not necessary synchronized with 'greaterThanColumnId'
   */
  readonly greaterThanColumnName?: string;
  // string
  regexReferential?: RegexReferential; // drop down field for regex referential selection
  regex?: string; // fetched from Guardian API
  manualRegex?: string; // input field for manual regex
  preparedRegex?: string; // drop down field from prepared regex
  snowflakeExternalReferential?: IAPITableReferential;
  datalakeInExternalReferential?: IAPIDatalakeReferential;
  datalakeNotInExternalReferential?: IAPIDatalakeReferential;
  datalakePathIn?: IBaseGuardianDatalakePath;
  datalakePathNotIn?: IBaseGuardianDatalakePath;
  // constraints lists
  addedConstraints: GuardianCheckConstraint[];
  availableConstraints: GuardianCheckConstraint[];
}

/**
 * Interface describing all the necessary information of a the first step of the Guardian form
 */
export interface IGuardianStepCheckBasicInfos {
  name: string;
  checkType?: CheckType;
  fileFormat?: FileFormat;
  isFilteringFilesForCheck?: boolean;
  filteringRegex?: string;
  isFlatfileDeactivate?: boolean;
  alertRecipients: IBannerRecipient[];
  // csv / xlsx
  decimalSeparator?: DecimalSeparator;
  // csv
  fileDelimiter?: FileDelimiter;
  // xlsx
  sheetName?: string;
}

/**
 * Interface describing all the necessary information of a the second step of the Guardian form
 */
export interface IGuardianStepCheckColumns<
  R extends ISupportedGuardianChecksResource = ISupportedGuardianChecksResource,
> {
  columnsToCheck: IColumnField<R>[];
}

export interface IGuardianCheckSchedulingStartingTime {
  schedulingStartingHour?: Hour;
  schedulingStartingMinute?: number;
  schedulingStartingHourPeriod?: HourPeriod;
}

export interface IGuardianCheckScheduling {
  // global
  schedulingPeriodicity?: SchedulingPeriodicity;
  schedulingStartingTime?: IGuardianCheckSchedulingStartingTime;
  // daily
  schedulingDailyChoiceSelected?: SchedulingDailyChoices;
  schedulingDailyEveryNumberOfDays?: number;
  // weekly
  schedulingWeeklySelectedDays?: WeekDay[];
  // monthly
  schedulingMonthlyDayNumber?: number;
  schedulingMonthlyEveryNumberOfMonths?: DividerOfNumberOfMonths;
  // yearly
  schedulingYearlyDayNumber?: number;
  schedulingYearlySelectedMonth?: YearMonth;
}

/**
 * Interface describing all the necessary information of a the last step of the Guardian form
 */
export interface IGuardianStepCheckTriggers {
  // moving part
  hasToUseItrack?: boolean;
  hasToMoveIfSuccess?: boolean;
  successPath?: string;
  hasToMoveIfFailure?: boolean;
  failurePath?: string;
  hasToEnforceReplacementIfSameName?: boolean;
  hasToRenameDestinationFile?: boolean;
  // result file
  hasToGenerateResultFile?: boolean;
  // scheduling part
  hasToScheduleChecks?: boolean;
  schedulingInfos?: IGuardianCheckScheduling;
}

export interface IGuardianFormData<R extends ISupportedGuardianChecksResource = ISupportedGuardianChecksResource> {
  step1: IGuardianStepCheckBasicInfos;
  step2: IGuardianStepCheckColumns;
  step3: IGuardianStepCheckTriggers;
}

export interface IGuardianForm<R extends ISupportedGuardianChecksResource = ISupportedGuardianChecksResource> {
  dataset: IGuardianChecksDatasetMap[R];
  guardianStatus: IGuardianStatus;
  form: IGuardianFormData;
  hasToRun: boolean;
}

export interface IGuardianCheckRegexFormData {
  label: string;
  regex: string;
}
