import type { AfterContentInit, OnDestroy } from '@angular/core';
import { Component, ContentChildren, EventEmitter, HostBinding, Input, Output, QueryList } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { TabComponent } from './tab/tab.component';

export type TabType = 'primary' | 'catalog' | 'catalog-v2' | 'chat';
export type JustifyType = 'start' | 'center' | 'end';

export interface IChangeTabsInfo {
  previous: string;
  new: string;
}

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent implements AfterContentInit, OnDestroy {
  @ContentChildren(TabComponent) tabs: QueryList<TabComponent>;
  @Input() vertical = false;

  @HostBinding('style.display') get displayTabs() {
    return this.vertical ? 'flex' : 'block';
  }

  @Output() tabChangeEvent = new EventEmitter<IChangeTabsInfo>();
  @Input() tabType: TabType = 'primary';
  @Input() width = '100%';
  @Input() marginLeft = '0';
  @Input() justifyType: JustifyType = 'center';
  @Input() classList: string;

  @Input() resizeToSelect = false;

  // TODO: remove and use tabToActivateV2 instead
  // WHY?:
  // - component is tightly coupled with observable (software architecture concept)
  // - children can trigger a stream/actions from parent at anytime (bad practice)
  // - hinder the use of async pipe
  // - dangerous since life cycles can do anything with the subscription
  // - component is either smart or dumb, but neither a mix of both
  // - inputs should be primitives, not encapsuated by reactivity
  // - force to subscribe in a declarative way
  // - a well known bad paractice.
  @Input() tabToActivate: BehaviorSubject<string> = new BehaviorSubject<string>('');

  @Input() set tabToActivateV2(tabTitle: string) {
    this._selectTabByName(tabTitle);
  }

  @Input() headersClass = '';
  @Input() stretched = false;
  shownTabsTitles: string[];
  activeTabTitle: string;

  private readonly _destroyed$ = new Subject<void>();

  // contentChildren are set
  // but if tab children are conditionally rendered the list can be empty.
  ngAfterContentInit() {
    if (this.tabs.length > 0) {
      const activeTabs = this.tabs.filter((tab) => tab.active);

      if (activeTabs.length === 0) {
        this.selectTab(this.tabs.first);
      } else {
        this.activeTabTitle = activeTabs[0].tabTitle;
      }
    }

    this.refreshTabNames();
    this.tabs.changes.pipe(takeUntil(this._destroyed$)).subscribe(() => {
      this.refreshTabNames();
    });

    this.tabToActivate.pipe(filter<string>(Boolean), takeUntil(this._destroyed$)).subscribe((tabName) => {
      this._selectTabByName(tabName);
    });
  }

  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  refreshTabNames() {
    this.shownTabsTitles = this.tabs.filter((tab) => !tab.hidden).map((tab) => tab.tabTitle);
    this.tabs.forEach((tabComponent) => {
      tabComponent.titleChanged.pipe(takeUntil(this._destroyed$)).subscribe(() => {
        this.shownTabsTitles = this.tabs.filter((tab) => !tab.hidden).map((tab) => tab.tabTitle);
      });
    });
  }

  get classToAdd() {
    return `${this.tabType} ${this.headersClass}`;
  }

  private _selectTabByName(tabName: string) {
    const tabToOpen = this.tabs.find((tab) => tab.tabTitle === tabName);

    if (tabToOpen) {
      this.selectTab(tabToOpen);
    }
  }

  selectTab(tabToSelect: TabComponent) {
    const activeTabs = this.tabs.filter((tab) => tab.active);

    if (tabToSelect.disabled) {
      return;
    }

    this.tabs.toArray().forEach((item) => {
      item.active = false;

      if (this.vertical) {
        item.elem.nativeElement.style.flex = 0;
      }
    });

    tabToSelect.active = true;
    this.activeTabTitle = tabToSelect?.tabTitle;
    this.tabChangeEvent.emit({ previous: activeTabs[0]?.tabTitle, new: tabToSelect?.tabTitle });

    if (this.vertical) {
      tabToSelect.elem.nativeElement.style.flex = 11;
    }
  }
}
