<button
  *ngIf="item.visibility | async"
  class="favorite-tools w-full h-full flex flex-col items-center justify-center cursor-pointer relative"
  [ngClass]="{ developed: isDeveloped, activated: isActivated }"
  [id]="'favorite-tool-' + item.name"
  (click)="handleOpenLink()"
>
  <div class="logo" [innerHTML]="item?.logo?.toString() | safeHtml"></div>
  <span class="sidebar-label select-none">{{ item.nameDisplay }}</span>
  <div class="item-border absolute h-full"></div>
</button>
