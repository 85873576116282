import { Directive, HostListener, Input } from '@angular/core';
import { Params } from '@angular/router';

import { Navigator } from '../services/navigator.service';

// TODO: Token system wont work with lazy loading, have to be though again
@Directive({
  selector: ':not(a)[dpgNavigatorLink]',
})
export class NavigatorLinkDirective {
  // Attributes
  @Input('dpgNavigatorLink') token: string;
  @Input('dpgNavigatorParams') params: Params = {};
  @Input('dpgNavigatorQueryParams') queryParams: Params = {};
  @Input() disabled = false;

  // Constructor
  constructor(protected readonly _navigator: Navigator) {}

  // Events
  @HostListener('click', ['$event'])
  async onClick(event: Event) {
    event.preventDefault();

    if (!this.disabled) {
      await this._navigator.navigate(this.token, this.params, { queryParams: this.queryParams });
    }
  }
}
