<ng-container [ngSwitch]="heading">
  <h1 class="adl-title" [class]="classList" [ngStyle]="styles" *ngSwitchDefault>
    <ng-container *ngTemplateOutlet="projectedContent"></ng-container>
  </h1>
  <h2 class="adl-title" [class]="classList" [ngStyle]="styles" *ngSwitchCase="2">
    <ng-container *ngTemplateOutlet="projectedContent"></ng-container>
  </h2>
  <h3 class="adl-title" [class]="classList" [ngStyle]="styles" *ngSwitchCase="3">
    <ng-container *ngTemplateOutlet="projectedContent"></ng-container>
  </h3>
  <h4 class="adl-title" [class]="classList" [ngStyle]="styles" *ngSwitchCase="4">
    <ng-container *ngTemplateOutlet="projectedContent"></ng-container>
  </h4>
  <div class="adl-title" [class]="classList" [ngStyle]="styles" *ngSwitchCase="5">
    <ng-container *ngTemplateOutlet="projectedContent"></ng-container>
  </div>
  <ng-template #projectedContent>
    <ng-content></ng-content>
  </ng-template>
</ng-container>
