import { Injectable } from '@angular/core';
import type { MatDialogRef } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';

import { ResourceAccessModalComponent } from '../components/resource-access-modal/resource-access-modal.component';

import type { IAccessRequestResource, ISupportedARResource } from '../entities/utils';

// Service
@Injectable()
export class AccessRequestsModalService {
  constructor(private readonly _modalMatService: MatDialog) {}

  openAccessModal(
    kind: ISupportedARResource,
    resource: IAccessRequestResource,
    gtmEvent?: boolean,
  ): MatDialogRef<ResourceAccessModalComponent> {
    return this._modalMatService.open(ResourceAccessModalComponent, {
      width: '1050px',
      minWidth: '1050px',
      maxHeight: '90vh',
      backdropClass: 'modal-backdrop',
      data: {
        resource: resource,
        kind: kind,
        gtmEvent: gtmEvent,
      },
    });
  }
}
