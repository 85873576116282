import { CommonModule } from '@angular/common';
import type { ModuleWithProviders } from '@angular/core';
import { NgModule } from '@angular/core';
import { SharedModule } from '@dataportal/front-shared';

import { WebsocketsService } from './services/websockets.service';

import { WEBSOCKET_URL } from './services/websocket-url';

// Module
// @dynamic
@NgModule({
  imports: [CommonModule, SharedModule],
  providers: [],
})
export class WebsocketModule {
  // Statics
  static forRoot(websocketUrl: string): ModuleWithProviders<WebsocketModule> {
    return {
      ngModule: WebsocketModule,
      providers: [{ provide: WEBSOCKET_URL, useValue: websocketUrl }, WebsocketsService],
    };
  }
}
