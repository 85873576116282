<adl-modal
  headerBackgroundColor="blue-dark"
  backgroundColor="light"
  [hasMarginBottom]="false"
  [isAlignedToCross]="true"
  [roundCorner]="true"
  [closeButtonColor]="'white'"
>
  <div class="modal-header w-full items-center" modal-header>
    <adl-title class="flex ml-8 my-7" type="modal" color="#ffffff">Paste filters</adl-title>
  </div>

  <div class="text-left" modal-body>
    <adl-text class="block mb-6">You can paste any filters you exported from Accor Data Portal here, and they will be applied
      to your dashboard.
      However, if the original and target dashboard don’t have the same setup and data model, the results may be
      different.
    </adl-text>
    <adl-input-text-area
      class="w-full"
      fixedHeight="150px"
      horizontalPadding="15px"
      verticalPadding="10px"
      [control]="filterPaste"
      [placeholder]="'Paste your filters here'"
    ></adl-input-text-area>
  </div>
  <div modal-footer class="pb-8">
    <adl-loaded-item iconSize="32" [loading]="loadingState">
      <div class="flex justify-center items-center ">
        <adl-button class="mr-5" (clicked)="close()" type="cancel">Cancel</adl-button>
        <adl-button (clicked)="confirm()" [disabled]="!filterPaste">Confirm</adl-button>
      </div>
    </adl-loaded-item>
  </div>
</adl-modal>
