import type { AfterViewInit, OnDestroy } from '@angular/core';
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'adl-input-text-area',
  templateUrl: './input-text-area.component.html',
  styleUrls: ['./input-text-area.component.scss'],
})
export class InputTextAreaComponent implements AfterViewInit, OnDestroy {
  @Input() control: FormControl;
  @Input() placeholder = '';
  @Input() readonly: boolean;
  @Input() isDisabled: boolean;
  @Input() isResizeDisabled: boolean;
  @Input() horizontalPadding = '5px';
  @Input() verticalPadding = '5px';
  @Input() succeeded: boolean;
  @Input() variant: 'light' | 'normal' = 'normal';
  @Input() fixedWidth: string;
  @Input() fixedHeight: string;
  @Input() maxHeight: string;
  @Input() cursorAtTheStart = false;
  @Input() setCursorAtTheEndOfEditZone$: ReplaySubject<void>;
  @Input() noBorder?: boolean = false;
  @Input() value: string;
  @Output() clickedIcon = new EventEmitter<unknown>();
  @ViewChild('nameInput') nameInput: ElementRef;

  private readonly _destroyed$ = new Subject<void>();

  ngAfterViewInit() {
    if (this.cursorAtTheStart) {
      this._setCursorAtTheStart();
    }

    this.setCursorAtTheEndOfEditZone$?.pipe(takeUntil(this._destroyed$)).subscribe(() => {
      this._setCursorAtTheEnd();
    });
  }

  ngOnDestroy() {
    this._destroyed$.next();
  }

  private _setCursorAtTheStart(): void {
    this.nameInput?.nativeElement?.setSelectionRange(0, 0);
    this.nameInput?.nativeElement?.focus();
  }

  private _setCursorAtTheEnd(): void {
    const endIndex = this.control?.value?.length || 0;
    this.nameInput?.nativeElement?.setSelectionRange(endIndex, endIndex);
    this.nameInput?.nativeElement?.focus();
  }

  onClickIcon(): void {
    this.clickedIcon.emit(this.control.value);
  }
}
