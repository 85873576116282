import { Component, EventEmitter, Input, Output } from '@angular/core';

import { IconType, SupportedIcon } from '../icons';

@Component({
  selector: 'adl-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss'],
})
export class IconButtonComponent {
  /* inputs */
  // required
  @Input() icon: SupportedIcon;
  // optional
  @Input() size = 16;
  @Input() disabled = false;
  @Input() iconType: IconType = 'inherit';
  @Input() isIconSpinning = false;
  @Input() hasTooltip = false;
  @Input() tooltipMessage = '';
  /* outputs */
  @Output() clicked = new EventEmitter<MouseEvent>();
  @Output() mouseOver = new EventEmitter<MouseEvent>();
  @Output() mouseOut = new EventEmitter<MouseEvent>();

  iconButtonsFa = [
    'info',
    'warning',
    'cross',
    'eye',
    'chevron-right',
    'share',
    'chevron-left',
    'circle',
    'circle-checked',
    'circle-crossed',
    'download',
    `wifi`,
    'plus',
    'upload',
    'copy',
    'paste',
    'cut',
    'check',
    'pencil',
    'request',
    'history',
    'question',
    'shield',
    'plus-circle',
    'minus',
    'lock',
    'unlock',
    'stop',
    'refresh',
    'play',
    'staging',
    'dev',
    'prod',
    'cog',
    'business',
    'text-cursor',
    'search',
    'menu',
    'folder',
    'chevron-down',
    'chevron-up',
    'long-arrow-down',
    'long-arrow-up',
  ];

  onClick(event): void {
    if (!this.disabled) {
      this.clicked.emit(event);
    }
  }

  onMouseOver(event): void {
    this.mouseOver.emit(event);
  }

  onMouseOut(event): void {
    this.mouseOut.emit(event);
  }
}
