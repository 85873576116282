<div class="dp--alert-banner" [style.backgroundColor]="color" [class]="'alert-' + labelColor"
     [ngClass]="{ 'alert-component': isAlertComponent }">
  <em *ngIf="hasExclamation" class='icon mr10' [ngClass]="{'icon-exclamation' : hasExclamation}"></em>
  <div class="flex">
    <img *ngIf="hasIconBanner" src="{{ iconBanner }}" alt="icon banner" class="mr-5"/>
    <span>
      {{ message }}
      <button type="button" class="ml-[5px] p-0" *ngIf="readMore" (click)="moreClick.emit()">Read more</button>
    </span>
    <ng-content></ng-content>
  </div>
  <em *ngIf="closable" class="close cursor-pointer fa fa-times ml-[20px]" (click)="closed.emit()"></em>
</div>
