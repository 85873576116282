import type { OnInit } from '@angular/core';
import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'dpg-flatfile-upload-progress-bar-modal',
  templateUrl: './flatfile-upload-progress-bar-modal.component.html',
  styleUrls: ['flatfile-upload-progress-bar-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FlatfileUploadProgressBarModalComponent implements OnInit {
  uploadFile: Blob;
  progress = 0;

  constructor(
    private readonly _activeMatModal: MatDialogRef<FlatfileUploadProgressBarModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    data: {
      uploadFile: Blob;
    },
  ) {
    this.uploadFile = data?.uploadFile ? data.uploadFile : this.uploadFile;
  }

  ngOnInit() {
    const reader = new FileReader();
    reader.readAsDataURL(this.uploadFile);
    reader.addEventListener('progress', (event) => {
      this.progress = Math.round((event.loaded / event.total) * 100);
    });
    reader.addEventListener('loadend', () => {
      this._activeMatModal.close();
    });
  }
}
