<div class="flex items-center">
  <adl-tooltip
    *ngIf="!isOnlyNotificationCenterDisplayed"
    backgroundColor="white"
    [hasArrow]="false"
    placement="bottom"
    [hideAfter]="500"
  >
    <div tooltip-trigger class="flex items-center">
      <adl-text [size]="14" [color]="color === 'white' ? 'white' : 'primary-500'" class="mr20 user-name c-pointer">
        {{ name }}
      </adl-text>
      <img
        *ngIf="profilePictureUrl$ | async as pictureUrl"
        [src]="pictureUrl"
        class="welcome-avatar mr-8 c-pointer"
        alt="Profile picture"
      />
    </div>

    <div tooltip-content>
      <div class="flex column-flex profile-logout-tooltip">
        <div class="flex pb5 pt5 c-pointer logout-tooltip-hover" (click)="openProfile()">
          <img src="assets/dpg/imgs/icons/profile.svg" class="ml10 mr15" />
          <adl-text color="home">My profile</adl-text>
        </div>

        <div class="flex pb5 pt5 c-pointer logout-tooltip-hover" (click)="openHelpCenter()">
          <img src="assets/dpg/imgs/icons/help-center-icon.svg" class="mx-4" />
          <adl-text color="home">Help Center</adl-text>
        </div>

        <div class="flex pb5 pt5 c-pointer logout-tooltip-hover" (click)="logout()">
          <img src="assets/dpg/imgs/icons/sign-out.svg" class="ml10 mr15" />
          <adl-text color="home">Sign out</adl-text>
        </div>
      </div>
    </div>
  </adl-tooltip>

  <div class="pt-[5px]">
    <adl-tooltip
      class="notification-tooltip cursor-pointer"
      backgroundColor="white"
      [fullContent]="true"
      [hasArrow]="false"
      placement="bottom-end"
      maxContentSize="420px"
      [isMouseOutBehaviorActivated]="false"
      [isMouseOverBehaviorActivated]="false"
      [isClickBehaviorActivated]="true"
      [isClickOutsideBehavior]="true"
      [hasBorder]="true"
    >
      <div tooltip-trigger>
        <img
          class="mr-[20px] cursor-pointer"
          [class.blue-shadow]="color === 'blue'"
          src="assets/dpg/imgs/icons/{{ bellLink }}"
          width="14px"
          height="16px"
          alt="tooltip-icon"
        />
      </div>

      <div tooltip-content>
        <dpg-notification-center [currentUserName]="name"></dpg-notification-center>
      </div>
    </adl-tooltip>
  </div>
</div>
