import { Component, Input } from '@angular/core';
import type { IStepperStep } from '@dataportal/adl';

import type { IGuardianChecksDatasetMap, ISupportedGuardianChecksResource } from '../../entities/guardian-form';

/**
 * Modal Header that can be replaced by the component adl-stepper-form-modal that does the same thing with more genericity
 */
@Component({
  selector: 'dpg-guardian-check-modal-header',
  templateUrl: './guardian-check-modal-header.component.html',
  styleUrls: ['./guardian-check-modal-header.component.scss'],
})
export class GuardianCheckModalHeaderComponent<R extends ISupportedGuardianChecksResource> {
  @Input() dataset: IGuardianChecksDatasetMap[R];
  @Input() checkId: number;
  // Stepper inputs part
  @Input() hasStepper = false;
  @Input() currentStep = 0;
  @Input() stepLabels: string[] = [];
  @Input() steps: IStepperStep[] = [];

  get datasetName(): string {
    return this.dataset.type === 'datalakePath' ? this.dataset?.path : this.dataset?.tableName.toUpperCase();
  }
}
