<adl-modal
  closeButtonColor="white"
  backgroundColor="grey-100"
  headerBackgroundColor="primary-500"
  [isAlignedToCross]="true"
>
  <adl-text modal-header class="my-8" type="normal" color="white">
    Love this
    {{ dashboard ? 'dashboard' : isJCD ? 'data source' : isAccor ? 'data product' : 'data asset' }}
    ? Tell your colleagues !
  </adl-text>
  <div modal-body class="modal-body">
    <dpg-user-select
      style="width: 80%"
      class="w-10/12"
      (selected)="limitedSelectedUser = $event"
      [clear]="clearSelectList"
      placeholder="Who should have a look at this {{
        dashboard ? 'dashboard' : isJCD ? 'data source' : isAccor ? 'data product' : 'data asset'
      }}?"
    ></dpg-user-select>
    <adl-form-control class="mt-10 w-10/12" [control]="customMessageControl">
      <adl-input
        [control]="customMessageControl"
        variant="new"
        placeholder="Tell your colleague why you love it ?"
      ></adl-input>
    </adl-form-control>
  </div>
  <div modal-footer class="row-flex j-center">
    <adl-button (clicked)="cancel()" type="cancel" class="mr-10"> Cancel</adl-button>
    <adl-button (clicked)="confirm()"> Recommend</adl-button>
  </div>
</adl-modal>
