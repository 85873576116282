import { Injectable } from '@angular/core';
import { interval, Observable, of, ReplaySubject, Subject, throwError } from 'rxjs';
import { catchError, concatMap, map, takeUntil, tap } from 'rxjs/operators';
import { MultiAuthService } from '@dataportal/auth';
import { ApiService } from '@dataportal/front-api';
import { AlertService, DeviceService, LocalStorageService, Logger } from '@dataportal/front-shared';
import type { IAuthToken } from '@dataportal/msal';
import type { IAllowedGroup } from '@dataportal/types';
import type { IEmbedConfiguration } from 'powerbi-client';
import { BackgroundType, LayoutType, Permissions, TokenType, ViewMode } from 'powerbi-models';

import { PowerBiReportType } from '../entities/dashboard.model';

import type { Source } from '../../sources/entities/source';
import type { Dashboard } from '../entities/dashboard.model';
import { PowerBiService } from './power-bi.factory';

// Types
export interface IPowerBiTokenResponse {
  '@odata.context': string;
  'expiration': Date;
  'token': string;
  'tokenId': string;
}

export type ExportType = 'pdf' | 'pptx' | 'png';

export interface IExportParams {
  type: ExportType;
  sourceId: string;
  userId: string;
  dashboard: Dashboard;
  isApp: boolean;
  userAccessToken?: string;
  optionalState?: string;
  optionalPagesNameToExport?: string[];
}

// TODO: should be in @dataportal/types
export interface ITokenRetrievableResponse {
  token: 'retrieved' | 'not retrieved';
}

// TODO: should be in @dataportal/types
export interface IExportResponse {
  exportId: string;
}

// TODO: should be in @dataportal/types
interface IFetchProgress {
  percent: number;
  status: 'NotStarted' | 'Running' | 'Succeeded' | 'Failed';
}

// TODO: should be in @dataportal/types
export interface ISignedUrlResponse {
  taskId: string;
}

// TODO: should be in @dataportal/types
export interface IFetchSignedUrl {
  result: string;
  status: 'ready' | 'running' | 'completed' | 'failed';
}

// TODO: should be in @dataportal/types
export interface ILastUpdateResponse {
  lastUpdate: string | null;
}

// Constants
const MINUTE = 60 * 1000;

const POLL_TIME_FOR_EXPORT_PROGRESS = 5 * 1000;

// Service
@Injectable()
export class DashboardsService {
  private readonly _lastUpdate$ = new ReplaySubject<Date>();
  lastUpdate$ = this._lastUpdate$.asObservable();

  // Constructor
  constructor(
    private readonly _multiAuthService: MultiAuthService,
    private readonly _apiService: ApiService,
    private readonly _logger: Logger,
    private readonly _alertService: AlertService,
    private readonly _deviceService: DeviceService,
    private readonly _localStorage: LocalStorageService,
    private readonly _powerBiService: PowerBiService,
  ) {}

  // Methods
  /**
   * Build token retrieving url from Dashboard properties
   * @returns string url identifier
   * @param userId user requiring token
   * @param sourceId data asset of the Dashboard
   * @param dashboard Dashboard needing the token
   */
  private static _getDashboardTokenUrl(userId: string, sourceId: string, dashboard: Dashboard): string {
    return (
      `sources/${sourceId}/dashboards?group=${dashboard.groupId}` +
      `&report=${dashboard.reportId}` +
      `&userId=${userId}` +
      `&useCube=${dashboard.isAnalysisServices}` +
      `&cubeUrl=${dashboard.aasCubeUrl}` +
      `&isApp=${dashboard.isApp}` +
      (dashboard.dataset ? '&dataset=' + dashboard.dataset : '') +
      (dashboard.role ? '&role=' + dashboard.role : '')
    );
  }

  /**
   * Build dashboard key for local storage
   * @returns string key for local storage item
   * @param userId user requiring token
   * @param sourceId data asset of the Dashboard
   * @param dashboard Dashboard needing the token
   */
  private static _getDashboardKey(userId: string, sourceId: string, dashboard: Dashboard): string {
    return `powerbi_token:${DashboardsService._getDashboardTokenUrl(userId, sourceId, dashboard)}`;
  }

  /**
   * Get token type depending on RLS property
   * @returns TokenType type of the token
   * @param dashboard Dashboard needing the token
   */
  private static _getDashboardTokenType(dashboard: Dashboard): TokenType {
    return dashboard.usingUserOwnsData ? TokenType.Aad : TokenType.Embed;
  }

  private static _getReportEmbedUrl(dashboard: Dashboard): string {
    let endpoint: string;
    let idType: string;

    switch (dashboard.powerBIType) {
      case PowerBiReportType.REPORT:
        endpoint = 'reportEmbed';
        idType = 'reportId';
        break;
      case PowerBiReportType.PAGINATED_REPORT:
        endpoint = 'rdlEmbed';
        idType = 'reportId';
        break;
      case PowerBiReportType.DASHBOARD:
        endpoint = 'dashboardEmbed';
        idType = 'dashboardId';
        break;
      default:
        endpoint = 'reportEmbed';
        idType = 'reportId';
    }

    return `https://app.powerbi.com/${endpoint}?${idType}=${dashboard.reportId}&groupId=${dashboard.groupId}`;
  }

  /**
   * Get time before new refresh of the token
   * @returns number time in ms before token refresh call
   * @param expiration Date of expiration of the token
   * @param refreshMinutesBeforeExpiration safety interval
   */
  convertTokenToIAuthToken(authToken: IAuthToken | string | void): IAuthToken {
    if (authToken && typeof authToken === 'string') {
      return { token: authToken };
    } else if (typeof authToken != 'string') {
      return authToken || { token: '' };
    }
  }

  getTokenRefreshTimeout(expiration: Date, refreshMinutesBeforeExpiration = 1): number {
    const safetyInterval = refreshMinutesBeforeExpiration * MINUTE;

    return expiration.getTime() - Date.now() - safetyInterval;
  }

  /**
   * Call for embed token
   * @returns IPowerBiTokenResponse complete PowerBI token
   * @param dashboard Dashboard needing a token
   * @param userId user requiring the token
   * @param source data asset of the dashboard
   * @param hideErrors errors set silent
   */
  getEmbedToken(
    dashboard: Dashboard,
    userId: string,
    source: Source,
    hideErrors = false,
  ): Observable<IPowerBiTokenResponse> {
    // Local response
    this._logger.debug('[Dashboard Preload] Using service account');
    const localResponse = JSON.parse(
      this._localStorage.getItem(DashboardsService._getDashboardKey(userId, source.id, dashboard)),
    ) as IPowerBiTokenResponse;

    if (localResponse) {
      const storedToken = { ...localResponse, expiration: new Date(localResponse.expiration) };

      if (this._isValid(storedToken)) {
        this._logger.debug('[DashboardsService] Valid token found in local storage');

        return of(storedToken);
      }
    }

    // Request token
    return this._apiService
      .get<IPowerBiTokenResponse>(`/v4/dashboards/sources/${source.id}/dashboards/${dashboard.name}/token`, {
        errorHandling: hideErrors ? { level: 'silent' } : null,
      })
      .pipe(
        catchError((err: unknown) => {
          if ((err as { error: any }).error?.data?.includes('E_POWERBI') && source.hasOwner(userId)) {
            this._alertService.show('error', `PowerBI could not found the report "${dashboard.name}"`);
          }

          return of<IPowerBiTokenResponse>({
            '@odata.context': null,
            'expiration': null,
            'token': null,
            'tokenId': null,
          });
        }),
        map((powerBITokenResponse) => {
          if (powerBITokenResponse?.expiration) {
            powerBITokenResponse.expiration = new Date(powerBITokenResponse.expiration);
          }

          return powerBITokenResponse;
        }),
        tap((powerBITokenResponse) => {
          this._localStorage.setItem(
            DashboardsService._getDashboardKey(userId, source.id, dashboard),
            JSON.stringify(powerBITokenResponse),
          );
        }),
      );
  }

  /**
   * Build dashboard embed configuration
   * @returns IEmbedConfiguration complete PowerBI configuration
   * @param dashboard Dashboard needing a configuration
   * @param tokenType token type
   * @param token token value
   * @param isMobile display is mobile oriented
   * @param edit editing is available
   * */
  getEmbedConfiguration(
    dashboard: Dashboard,
    tokenType: TokenType,
    token: string,
    isMobile = false,
    edit = false,
  ): IEmbedConfiguration {
    let type: string;

    switch (dashboard.powerBIType) {
      case PowerBiReportType.DASHBOARD:
        type = 'dashboard';
        break;
      case PowerBiReportType.REPORT:
      case PowerBiReportType.PAGINATED_REPORT:
      default:
        type = 'report';
    }

    const config: IEmbedConfiguration = {
      type: type,
      tokenType: tokenType,
      permissions: edit ? Permissions.All : undefined,
      embedUrl: DashboardsService._getReportEmbedUrl(dashboard),
      accessToken: token,
      viewMode: edit ? ViewMode.Edit : ViewMode.View,
      settings: dashboard.isFilterPaneHidden ? { filterPaneEnabled: false } : {},
    };

    if (isMobile) {
      config.settings.filterPaneEnabled = true;
      config.settings.layoutType = LayoutType.MobilePortrait;
      config.settings.background = BackgroundType.Transparent;
    }

    return config;
  }

  checkThatEmbedTokenIsRetrievable(dashboard: Dashboard, userId: string): Observable<ITokenRetrievableResponse> {
    const params: Record<string, string> = {
      url: dashboard.url,
      userId: userId,
      useCube: dashboard.isAnalysisServices?.toString(),
    };

    if (dashboard.dataset) {
      params.dataset = dashboard.dataset;
    }

    if (dashboard.role) {
      params.role = dashboard.role;
    }

    return this._apiService.get<ITokenRetrievableResponse>('/v4/dashboards/check-token', { params });
  }

  preloadSourceDashboards(source: Source, userId: string): void {
    const isMobile = this._deviceService.isMobile();

    for (const dashboard of source.powerbi) {
      if (dashboard.type === 'powerbi') {
        this._preload(dashboard, userId, source, isMobile);
      }
    }
  }

  /**
   * Request to export a report
   * @returns the export ID to use in subsequent requests
   * @param params
   */
  requestExport(params: IExportParams): Observable<string> {
    return this._apiService
      .post<IExportResponse>(`/v4/dashboards/sources/${params.sourceId}/dashboards/${params.dashboard.name}/export`, {
        accessToken: params.userAccessToken,
        type: params.type,
        state: params.optionalState,
        pagesNameToExport: params.optionalPagesNameToExport,
      })
      .pipe(map((response) => response.exportId));
  }

  /**
   * Perform a long polling to fetch dashboard export status periodically.
   * @param type
   * @param sourceId
   * @param dashboard
   * @param exportId
   * @param userAccessToken
   * @returns an observable that emit percent done and complete when export is over.
   */
  watchExportProgress(
    type: ExportType,
    sourceId: string,
    dashboard: Dashboard,
    exportId: string,
    userAccessToken?: string,
  ): Observable<number> {
    return new Observable((subscriber) => {
      const end$ = new Subject();
      const params = {
        accessToken: userAccessToken || '',
        exportId,
        type,
      };

      interval(POLL_TIME_FOR_EXPORT_PROGRESS)
        .pipe(
          concatMap(() =>
            this._apiService.get<IFetchProgress>(
              `/v4/dashboards/sources/${sourceId}/dashboards/${dashboard.name}/export`,
              { params },
            ),
          ),
          tap((response) => {
            if (response.status === 'Succeeded') {
              end$.next();
              subscriber.complete();
            } else if (response.status === 'Failed') {
              end$.next();
              subscriber.error('error exporting report');
            } else {
              subscriber.next(response.percent);
            }
          }),
          takeUntil(end$),
        )
        .subscribe();
    });
  }

  /**
   * Request a signed url to download report.
   * (API Gateway cannot directly serve binary data).
   * @param type
   * @param isMultiPage
   * @param sourceId
   * @param dashboard
   * @param exportId
   * @param accessToken
   */
  requestSignedUrl(
    type: ExportType,
    isMultiPage: boolean,
    sourceId: string,
    dashboard: Dashboard,
    exportId: string,
    accessToken?: string,
  ): Observable<ISignedUrlResponse> {
    return this._apiService.post<ISignedUrlResponse>(
      `/v4/dashboards/sources/${sourceId}/dashboards/${dashboard.name}/export-signed-url`,
      {
        isMultiPage,
        exportId,
        type,
        accessToken,
      },
    );
  }

  /**
   * Perform a second polling to fetch the S3 report signed URL
   * @param type
   * @param sourceId
   * @param taskId
   */
  getReportSignedUrl(type: ExportType, sourceId: string, taskId: string): Observable<string> {
    return new Observable((subscriber) => {
      const end$ = new Subject();

      interval(POLL_TIME_FOR_EXPORT_PROGRESS)
        .pipe(
          concatMap(() =>
            this._apiService.get<IFetchSignedUrl>(`/v4/dashboards/sources/${sourceId}/export/${type}/${taskId}`),
          ),
          tap((response) => {
            this._logger.info('[DashboardService] Generating signed URL', response.status);

            if (response.status === 'completed') {
              this._logger.info('[DashboardService] URL generated', response.result);
              end$.next();

              const data = JSON.parse(response.result);
              subscriber.next(data.url);
              subscriber.complete();
            } else if (response.status === 'failed') {
              this._logger.info('[DashboardService] URL generation failed !', response.result);
              end$.next();

              try {
                const data = JSON.parse(response.result);
                subscriber.error(data.error);
              } catch (e) {
                subscriber.error('error exporting report');
              }
            }
          }),
          takeUntil(end$),
        )
        .subscribe();
    });
  }

  /**
   * Get last update date of the dashboard
   * @param sourceId id of the source
   * @param dashboard dashboard concerned
   */
  getLastUpdate(sourceId: string, dashboard: Dashboard): void {
    if (dashboard.type !== 'powerbi') {
      this._logger.error('Cannot fetch last update date for a non-powerbi dashboard');

      return;
    }

    this.getLastUpdateFromDashboard(sourceId, dashboard.name, dashboard.isApp).subscribe(
      ({ lastUpdate }) => {
        if (lastUpdate) {
          this._lastUpdate$.next(new Date(lastUpdate));
        }
      },
      (error: unknown) => {
        // TODO: [Revisit] (18.01.2024) - type
        const noDatasetFound = (error as { error: any })?.error?.data?.includes('Cannot resolve dataset ID');
        const hasDataset = !!dashboard.dataset;

        if (noDatasetFound && !hasDataset) {
          this._logger.info('[DashboardsService] No dataset found !');
        } else if (noDatasetFound) {
          this._logger.warn('[DashboardsService] Dataset not found for report');
          this._alertService.warning('Cannot resolve report dataset');
        }

        return throwError(error);
      },
    );
  }

  getLastUpdateFromDashboard(sourceId: string, dashboardName: string, isApp = false): Observable<ILastUpdateResponse> {
    return this._apiService.get<ILastUpdateResponse>(
      `/v4/dashboards/sources/${sourceId}/dashboards/${dashboardName}/updates/last?isApp=${isApp}`,
      {
        errorHandling: { level: 'silent' },
      },
    );
  }

  /**
   * @deprecated
   * Get last update date of the dashboard
   * @param source data asset origin
   * @param action action to emit
   */
  emitExternalLinkPageViewEvent(source: Source, action = 'dashboard'): void {
    return;
  }

  getDashboardAllowedGroups(sourceId: string, dashboardName: string): Observable<IAllowedGroup[]> {
    return this._apiService.get<IAllowedGroup[]>(
      `/v4/dashboards/sources/${sourceId}/dashboards/${dashboardName}/allowed-groups`,
      {
        errorHandling: { level: 'silent' },
      },
    );
  }

  /**
   * Check validity of response's token
   * @returns boolean validity of the token
   * @param response Response containing token
   */
  private _isValid(response?: IPowerBiTokenResponse): boolean {
    return response && response.token && this.getTokenRefreshTimeout(response.expiration) > 0;
  }

  /**
   * Get token from multi-auth service
   * @returns string value of the token
   * @param dashboard Dashboard needing the token
   * @param userId user requiring token
   * @param source data asset of the Dashboard
   * @param tokenType type of token to be retrieved
   * @param hideErrors turn errors silent
   */
  private _getDashboardTokenValue(
    dashboard: Dashboard,
    userId: string,
    source: Source,
    tokenType: TokenType,
    hideErrors = false,
  ): Observable<string | void> {
    this._logger.debug('[DashboardPreload] Acquiring token for dashboard preload', {
      dashboard,
      userId,
      source,
      tokenType,
      hideErrors,
    });
    this._logger.debug(
      '[DashboardPreload] Token type is AAD (= RLS, using own user data)',
      tokenType === TokenType.Aad,
    );

    return new Observable<string | void>((obs) => {
      if (tokenType === TokenType.Aad) {
        this._multiAuthService.acquireTokenDashboard(false).then((token) => {
          obs.next(token as string | null);

          return obs.complete();
        });
      } else {
        this.getEmbedToken(dashboard, userId, source, hideErrors).subscribe({
          next: (data) => obs.next(data.token),
          error: (err: unknown) => obs.error(err),
          complete: () => obs.complete(),
        });
      }
    });
  }

  /**
   * Preload to retrieve token faster
   * @param dashboard Dashboard needing a configuration
   * @param userId user performing preload
   * @param source data asset of the dashboard
   * @param isMobile display is mobile oriented
   * */
  private _preload(dashboard: Dashboard, userId: string, source: Source, isMobile: boolean): void {
    const tokenType = DashboardsService._getDashboardTokenType(dashboard);
    this._getDashboardTokenValue(dashboard, userId, source, tokenType, true).subscribe(
      (token) => {
        if (token) {
          const config = this.getEmbedConfiguration(dashboard, tokenType, token, isMobile);
          this._powerBiService.preload(config);
        }
      },
      (err: unknown) => {
        // FIXME 187 : If cognito is used as IDP and tokenType = AAD show error page
      },
    );
  }
}
