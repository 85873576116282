import { CommonModule } from '@angular/common';
import type { OnDestroy, OnInit } from '@angular/core';
import { Component, EventEmitter, forwardRef, Injectable, Input, NgModule, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlValueAccessor } from '@ngneat/reactive-forms';

// Registry
@Injectable()
export class RadioRegistry {
  // Attributes
  private readonly _registry = new Map<string, RadioComponent[]>();

  // Methods
  register(name: string, control: RadioComponent) {
    if (!this._registry.has(name)) {
      this._registry.set(name, []);
    }

    this._registry.get(name).push(control);
  }

  unregister(name: string, control: RadioComponent) {
    const controls = this._registry.get(name) || [];

    const idx = controls.indexOf(control);

    if (idx !== -1) {
      controls.splice(idx, 1);
    }
  }

  select(name: string, value: string | null) {
    const controls = this._registry.get(name) || [];

    for (const control of controls) {
      if (control.value !== value) {
        control.uncheck();
      }
    }
  }
}

// Component
@Component({
  selector: 'adl-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss'],
  host: {
    '(blur)': 'onTouched()',
  },
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => RadioComponent), multi: true }],
})
export class RadioComponent extends ControlValueAccessor<string> implements OnInit, OnDestroy {
  // Attributes
  @Input() name: string;
  @Input() value: string;
  @Input() checked = false;
  @Input() radioStyle: 'old' | 'new' = 'old';

  @Output() changed = new EventEmitter<string>();

  // Constructor
  constructor(private readonly _registry: RadioRegistry) {
    super();
  }

  // Lifecycle
  ngOnInit() {
    if (this.name) {
      this._registry.register(this.name, this);
    }
  }

  ngOnDestroy() {
    if (this.name) {
      this._registry.unregister(this.name, this);
    }
  }

  // Methods
  private _update(checked: boolean): void {
    this.checked = checked;

    if (this.name) {
      this._registry.select(this.name, this.checked ? this.value : null);
    }

    if (this.onChange) {
      this.onChange(this.checked ? this.value : null);
    }

    this.changed.emit(this.checked ? this.value : null);
  }

  handleChange(event: Event): void {
    this._update((event.target as HTMLInputElement).checked);
  }

  handleClick(): void {
    this._update(false);
  }

  uncheck() {
    this.checked = false;
  }

  writeValue(value: string): void {
    this.checked = this.value === value;
  }
}

// Module
@NgModule({
  imports: [CommonModule],
  declarations: [RadioComponent],
  providers: [RadioRegistry],
  exports: [RadioComponent],
})
export class RadioModule {}
