import { NgModule } from '@angular/core';
import { ApiModule } from '@dataportal/front-api';
import { NgSelectModule } from '@ng-select/ng-select';

import { CompaniesService } from './services/companies.service';

import { SelectCompanyComponent } from './components/select-company/select-company.component';

// Module
// @dynamic
@NgModule({
  imports: [NgSelectModule, ApiModule],
  declarations: [SelectCompanyComponent],
  exports: [SelectCompanyComponent],
  providers: [CompaniesService],
})
export class CompaniesModule {}
