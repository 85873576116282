<adl-tooltip class="w100 c-pointer" [hideAfter]="100">
  <ng-container tooltip-trigger [ngTemplateOutlet]="childTemplate"
                [ngTemplateOutletContext]="{isWhitelisted:isWhitelisted}">
    </ng-container>
  <adl-loaded-item tooltip-content [loading]="isLoading" class="w100">
    <div class="column-flex a-center w100">
      <adl-text type="tag" size="14" class="tac w50 whitespace-normal break-all">
        {{ title }}
      </adl-text>
      <adl-text type="tag" size="14" class="tal wb-all mt5 w100 whitespace-normal break-all">
        {{ description }}
      </adl-text>
      <adl-button size="small" class="column-flex a-center mt5 w50" (click)="triggerAction()">
        {{ actionBtnDescription }}
      </adl-button>
    </div>
  </adl-loaded-item>
</adl-tooltip>
