import { Component, Input } from '@angular/core';

import { ISearchFilter } from '../../search-bar/search-bar-typing';

@Component({
  selector: 'adl-checkboxes-filter',
  templateUrl: './checkboxes-filter.component.html',
})
export class CheckboxesFilterComponent {
  @Input() filter: ISearchFilter;
}
