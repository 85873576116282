<adl-modal
  headerBackgroundColor="primary-500"
  backgroundColor="light"
  [roundCorner]="false"
  closeButtonColor="white"
  [isAlignedToCross]="true"
  textAlignment="left"
>
  <div class="my-[28px] ml-[30px]" modal-header>
    <adl-text *ngIf="modalType === 'my-permissions'" [size]="20" color="white">Manage my permissions</adl-text>
    <adl-text *ngIf="modalType === 'requested-access'" [size]="20" color="white">Requested access</adl-text>
  </div>
  <div *ngIf="modalType === 'my-permissions' && resource.allowAccessRequest" modal-body class="mx-10 mt-6">
    <app-tabs class="permissions-tabs">
      <app-tab tabTitle="My Role">
        <ng-container *ngIf="userRoleOnSource">
          <adl-text class="uppercase" [size]="16" color="blue" weight="bold">my role</adl-text>
          <div class="flex mt-5 justify-between">
            <div class="flex flex-col">
              <adl-text size="16" color="blue">{{ userRoleOnSource?.label }}</adl-text>
              <adl-text size="14" color="grey-500">{{ userRoleOnSource?.description }}</adl-text>
            </div>
            <adl-is-active-label
              class="flex"
              [isActive]="true"
              activeText="Current"
              activeTheme="success-secondary"
              activeIcon=""
              activeImgIcon="green-check"
              size="12"
            ></adl-is-active-label>
          </div>
        </ng-container>
        <ng-container *ngIf="!userRoleOnSource">
          <adl-text size="14" color="grey-600" [italic]="true">No specific & dedicated user role.</adl-text>
        </ng-container>
        <ng-container *ngIf="accessRequestDate">
          <div class="flex mt-3 ml-4 pl-3 gap-x-3 h-[40px] items-center arrow">
            <adl-text class="leading-3 pl-4" size="12" color="secondary-500">
              change request made on {{ accessRequestDate.toLocaleDateString() }}
            </adl-text>
            <adl-button
              fixedHeight="24px"
              type="secondary"
              (clicked)="cancelAccessRequest()"
              [disabled]="loadingAccessRequest"
            >
              <adl-text class="leading-3" size="12" color="secondary-500">Cancel</adl-text>
            </adl-button>
          </div>
          <div class="flex mt-3 justify-between">
            <div class="flex flex-col">
              <adl-text size="16" color="blue">{{ accessRequestPending?.label }}</adl-text>
              <adl-text size="14" color="blue grey-500">{{ accessRequestPending?.description }}</adl-text>
            </div>
            <adl-is-active-label
              class="flex"
              [isActive]="true"
              activeText="Waiting for approval"
              activeTheme="warning"
              activeIcon="clock"
              size="12"
            ></adl-is-active-label>
          </div>
        </ng-container>

        <adl-text [size]="16" color="blue" weight="bold" class="flex mt-6">CHANGE REQUEST</adl-text>
        <div class="mt-5">
          <dpg-catalog-source-roles-select [roleControl]="roleControl"></dpg-catalog-source-roles-select>
        </div>
      </app-tab>
      <app-tab tabTitle="My Group(s) Role(s)">
        <div *ngFor="let groupRole of groupRoles" class="flex mb-6">
          <div class="w-4/6">
            <adl-text size="16" color="blue" class="block">{{ groupRole?.role.label }}</adl-text>
            <adl-text size="14" color="grey-500" class="block">{{ groupRole?.role.description }}</adl-text>
          </div>
          <adl-text class="w-2/6 text-right truncate uppercase" size="14" color="blue" [bold]="true">{{
            groupRole.group
          }}</adl-text>
        </div>
      </app-tab>
    </app-tabs>
  </div>

  <div *ngIf="modalType === 'my-permissions' && !resource.allowAccessRequest" modal-body class="py-[15px]">
    <adl-text size="14" color="light-grey"
      >This data product uses custom access requests. See below the instructions.</adl-text
    >

    <adl-title type="modal" class="access-request-title uppercase mt-[30px]">Access request instructions</adl-title>

    <adl-text size="14" color="grey-700">{{ resource.accessRequestInstructions }}</adl-text>
  </div>

  <div *ngIf="modalType === 'requested-access'" modal-body class="mx-10 mt-6">
    <adl-text class="uppercase" [size]="16" color="blue" weight="bold">access requested</adl-text>

    <div class="flex mt-3 justify-between">
      <div class="flex flex-col">
        <adl-text size="16" color="blue" weight="medium">{{ accessRequestPending?.label }}</adl-text>
        <adl-text size="14" color="blue grey-500">{{ accessRequestPending?.description }}</adl-text>
      </div>
      <adl-is-active-label
        class="flex"
        [isActive]="true"
        activeText="Waiting for approval"
        activeTheme="warning"
        activeIcon="clock"
        size="12"
      ></adl-is-active-label>
    </div>

    <adl-text class="block w-full text-right mb-12" size="10" color="grey-500">
      {{ accessRequestDate.toLocaleDateString() }}
    </adl-text>

    <adl-text class="uppercase mb-6" [size]="16" color="blue" weight="bold">Change request</adl-text>
    <div>
      <dpg-catalog-source-roles-select [roleControl]="roleControl"></dpg-catalog-source-roles-select>
    </div>

    <div class="mt-6 flex items-center">
      <adl-text class="uppercase mr-8" [size]="16" color="blue" weight="bold">OR</adl-text>
      <adl-checkbox checkboxColor="blue" checkboxSize="small" (changed)="handleCancelRequestCheckbox($event)">
        <span class="block text-[12px] height-[18px] cancel-request">Cancel Request</span>
      </adl-checkbox>
    </div>
  </div>

  <div modal-footer *ngIf="modalType !== 'my-permissions' || resource.allowAccessRequest">
    <div class="mt-4 flex justify-center gap-8">
      <adl-button (clicked)="cancel()" type="cancel">Cancel</adl-button>
      <adl-button
        *ngIf="modalType === 'my-permissions'"
        (clicked)="submitNewPermissions()"
        [disabled]="!canUpdate || loadingAccessRequest"
      >
        Update
      </adl-button>
      <adl-button
        *ngIf="modalType === 'requested-access'"
        (clicked)="submitAccessRequest()"
        [disabled]="!canUpdate || loadingAccessRequest"
      >
        Update
      </adl-button>
    </div>
  </div>
</adl-modal>
