import type { DatalakeOnlyProvider, IDatalakeObject, IDatalakeObjectAPI } from '@dataportal/datalake-parsing';
import type { DatalakePath, IDatalakeMetadata } from '@dataportal/front-shared';
import type { IDatalakeBucket } from '@dataportal/types';

export const formatPath = (path: string) => {
  return (path || '').replace(/\/+/, '/').replace(/^\//, '').replace(/\/$/, '');
};

export const arePathTheSame = (path1: string, path2: string) => {
  const formatedPath1 = formatPath(path1);
  const formatedPath2 = formatPath(path2);

  return formatedPath1 === formatedPath2;
};

export const areObjectsEqual = (o1: IDatalakeObjectAPI, o2: IDatalakeObjectAPI): boolean => {
  return (
    o1.name === o2.name && o1.provider === o2.provider && o1.tenant === o2.tenant && arePathTheSame(o1.path, o2.path)
  );
};

export type Provider = 'aws' | 'azure';

// TODO: should be in @dataportal/types
export interface IBucket {
  name: string;
  category: 'landing' | 'raw' | 'project' | 'business-other';
  primary?: boolean;
  sources?: boolean;
  isReadOnly?: boolean;
}

export interface IFormattedObjectPathInfos {
  provider: 'aws' | 'azure';
  bucket: IDatalakeBucket;
  tenant?: string;
  path: string;
}

export interface IFormattedObjectPathWithMetadataInfos extends IFormattedObjectPathInfos {
  canFetchMetadata: boolean;
}

export interface IDatalakeTarget {
  bucket: string;
  path: string;
}

export interface IDatalakeUserPermission {
  canRead: boolean;
  canWrite: boolean;
}

export interface IDatalakeObjectWithPermission extends IDatalakeObject {
  userPermission: IDatalakeUserPermission;
  folderCreation?: boolean;
}

export interface IDatalakeCurrentDirMetadata {
  token: string;
  tenant?: string;
  provider: DatalakeOnlyProvider;
}

// Fixme: same as IDatalakeResourcesList from @dataportal/type except IDatalakeFolder miss checked attribute
export interface IDatalakeListResponse {
  resources: IDatalakeObjectAPI[];
  metadata: IDatalakeCurrentDirMetadata[];
}

export interface IDatalakeWithPermissionListResponse extends IDatalakeListResponse {
  userPermissions: IDatalakeUserPermission[];
}

export interface IFetchPageOptions {
  options: IDatalakeStoreOptions;
  bucket: string;
}

/**
 * Parameters to use when listing objects somewhere
 */
export interface IDatalakeAPIOptions {
  [key: string]: any;

  bucket?: string;
  provider?: DatalakeOnlyProvider;
  tenant?: string;
  path?: string;
  tokens?: IDatalakeCurrentDirMetadata[];
  byUser?: boolean;
}

export interface IDatalakeSortOptions {
  field: 'name' | 'lastModified' | 'size';
  direction: 'asc' | 'desc';
}

export interface IDatalakeCurrentDirectory {
  bucket: IBucket;
  path: string;
  fullPath: string;
  breadcrumbs: string[];
  provider?: DatalakeOnlyProvider;
  tenant?: string;
  byUser?: boolean;
  doNotBuildTreeHierarchy?: boolean;
}

// FIXME: [Flatfile] should be in @dataportal/types

export type IDatalakeFileCustomMetadata = { name: string; value: string }[];

export interface IDatalakeFileMetadata extends IDatalakeMetadata {
  name: string;
  size: number;
  path: string;
}

export interface IDatalakeUploadProgress {
  name: string;
  progress: number;
  status: string;
  style?: string[];
}

export interface IDatalakeFoldersTreeElement extends IDatalakeObjectAPI {
  level: number;
  parent: { name: string; path: string; tenant?: string } | null;
  children: Array<IDatalakeFoldersTreeElement> | null;
  childrenLoaded: boolean;
  childrenLoading: boolean;
}

const _normalize = (path: string | undefined): string => {
  return !path
    ? path
    : path
        .replace(/^\//, '') // remove leading slash
        .replace(/\/$/, '') // remove trailing slash
        .replace(/\/+/, '/'); // avoid multiple slashes
};

export const normalizedPath = (path_or_object: IDatalakeObjectAPI | string) => {
  return _normalize(typeof path_or_object === 'string' ? path_or_object : path_or_object?.path);
};

export const normalizedFullPath = (object: IDatalakeObjectAPI) => {
  return _normalize(`${object.path}/${object.name}`);
};

export const currentToStoreOptions = (current: IDatalakeCurrentDirectory): [string, IDatalakeStoreOptions] => {
  return [
    current.bucket.name,
    {
      provider: current.provider,
      tenant: current.tenant,
      path: current.path,
      byUser: current.byUser,
    },
  ];
};

export type IDatalakeFoldersTree = Array<IDatalakeFoldersTreeElement>;

/**
 * Parameters to use when listing objects somewhere
 */
export interface IDatalakeStoreOptions {
  [key: string]: unknown;
  provider?: DatalakeOnlyProvider;
  tenant?: string;
  path: string;
  byUser?: boolean;
}

export interface IObjectsStoreLine {
  metadata: IDatalakeCurrentDirMetadata[];
  isThereDataRemaining: boolean;
  objects: IDatalakeObjectAPI[];
  userPermissions: IDatalakeUserPermission[];
  allChecked: boolean;
}

export interface IDatalakeDeleteObjectEvent {
  object: IDatalakeObject;
  status: 'started' | 'failed' | 'succeeded';
}

export type ZipProcessStatusType = 'started' | 'aborted' | 'aborting' | 'completed' | 'preparing zip' | 'errored';

export class IZipProcess {
  pk: string; // uuid
  sk: string; // 'datalake|zip|process|{userId}'
  name: string;
  status: ZipProcessStatusType;
  requestedBy: string;
  requestAt: string;
  sort_field: number;
}

export interface FavoriteDatalake {
  name?: string;
  path: DatalakePath;
  sourceId: string;
  url: string;
  guardianIcon?: string;
  addDate: string;
  order?: number;
}
