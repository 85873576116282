import type { IDashboardFavorite } from '@dataportal/types';
import { Entity } from '@decahedron/entity';

// Entity
export class FavoriteDashboard extends Entity {
  // Attributes
  userId: string = null;
  sourceId: string = null;
  dashboardName: string = null;
  createdAt: string = null;
  order: number = null;

  // Methods
  fromJson(data: IDashboardFavorite): FavoriteDashboard {
    this.userId = data.pk;
    this.sourceId = data.source_id;
    this.dashboardName = data.dashboard_name;
    this.createdAt = data.created_at;
    this.order = data.order;

    return this;
  }

  toJson() {
    return {
      user_id: this.userId,
      source_id: this.sourceId,
      dashboard_name: this.dashboardName,
      created_at: this.createdAt,
      order: this.order,
    };
  }
}
