import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import type { IDatalakePath } from '@dataportal/types';

import { SharedModule } from '../shared.module';

import type { IBaseGuardianDatalakePath } from '../types/datalake-path';
import { DatalakePath } from '../types/datalake-path';

// Service
@Injectable({
  providedIn: SharedModule,
})
export class DatalakeService {
  constructor(private readonly _router: Router) {}

  /**
   * Cleaning datalake path string :
   * - Removing deduplicated '/'
   * - Removing potential '/' at the beginning
   * - Removing potential '/' at the end
   * @param path : The datalake path string to clean
   * @returns The cleaned datalake path string
   */
  static formatPath(path: string): string {
    return (path || '').replace(/\/+/, '/').replace(/^\//, '').replace(/\/$/, '');
  }

  /**
   * Convert datalake path object to datalake path string key (which should be unique most of the time)
   * Datalke path key format :
   * - For Azure : "[PATH]-[PROVIDER]-[TENANT]" (-> "PROVIDER" is equal to "azure" here)
   * - For AWS : "[PATH]-[PROVIDER]" (-> "PROVIDER" is equal to "aws" here)
   * @param datalakePath : The datalake path object to convert
   * @returns THe datalake path string key, and null if invalid path
   */
  static datalakePathToKey(datalakePath: IDatalakePath): string {
    const pathToUse = DatalakeService.formatPath(datalakePath.path);
    let key = `[${pathToUse}]-`;

    if (datalakePath.provider === 'aws' || datalakePath.provider === 'azure') {
      key += `[${datalakePath.provider}]`;

      if (datalakePath.tenant && datalakePath.provider === 'azure') {
        key += `-[${datalakePath.tenant}]`;
      } else if (datalakePath.tenant && datalakePath.provider === 'aws') {
        return null;
      } else if (!datalakePath.tenant && datalakePath.provider === 'azure') {
        return null;
      }
    } else {
      return null;
    }

    return key;
  }

  /**
   * Convert datalake path entity object to datalake path string key (which should be unique most of the time)
   * Datalke path key format :
   * - For Azure : "[PATH]-[PROVIDER]-[TENANT]" (-> "PROVIDER" is equal to "azure" here)
   * - For AWS : "[PATH]-[PROVIDER]" (-> "PROVIDER" is equal to "aws" here)
   * @param datalakePath : The datalake path entity object to convert
   * @returns THe datalake path string key, and null if invalid path
   */
  static datalakePathEntityToKey(datalakePath: DatalakePath): string {
    return DatalakeService.datalakePathToKey({
      path: datalakePath?.path,
      provider: datalakePath?.provider,
      tenant: datalakePath?.tenant,
    });
  }

  /**
   * Redirect (by Angular routing) the user to the Datalake browser page with the current path set to the given path
   * @param datalakePath : The datalake path to redirect the user
   */

  formatDatalakeUrl(datalakePath: DatalakePath | IBaseGuardianDatalakePath) {
    const matcher = datalakePath.path.match(/^([^/]+)\/(.+)$/);
    let [bucket, path] = matcher != null ? matcher.slice(1) : [datalakePath.path, '/'];

    if (!(datalakePath instanceof DatalakePath)) {
      bucket = datalakePath.bucket;
      const pathSegments = datalakePath.path?.split('/') || null;
      pathSegments?.pop();
      path = pathSegments?.length ? pathSegments.join('/') : '';
    }

    const provider = datalakePath.provider;
    const tenant = datalakePath.tenant;

    return this._router
      .createUrlTree(['datalake', bucket], {
        queryParams: {
          path: path.replace(/\/$/, ''),
          provider,
          tenant,
        },
      })
      .toString();
  }

  redirectToDatalake(datalakePath: DatalakePath | IBaseGuardianDatalakePath, newWindow: boolean = false): void {
    const url = this.formatDatalakeUrl(datalakePath);

    if (!newWindow) {
      window.open(url, '_blank');
    } else {
      this._router.navigateByUrl(url);
    }
  }
}
