import type { OnDestroy, OnInit } from '@angular/core';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
  selector: 'adl-autocomplete',
  styleUrls: ['./autocomplete.component.scss'],
  templateUrl: './autocomplete.component.html',
})
export class AutocompleteComponent<T> implements OnInit, OnDestroy {
  @Input() label: string;
  @Input() value: string;
  @Input() placeholder: string;
  @Input() searchSubject: Subject<Array<T>>;

  @Input() typeToSearchText = 'Type to search';
  @Input() items: T[] = [];
  @Input() loading = false;
  @Input() clear: Subject<void>;

  @Output() cleared = new EventEmitter<void>();
  @Output() changed = new EventEmitter<T>();
  @Output() scrollToEnd = new EventEmitter<void>();
  @Output() search = new EventEmitter<{
    term: string;
    items: any[];
  }>();

  @ViewChild(NgSelectComponent) ngSelectComponent: NgSelectComponent;

  private readonly _destroyed$ = new Subject<void>();

  ngOnInit(): void {
    if (this.clear) {
      this.clear.pipe(takeUntil(this._destroyed$)).subscribe(() => {
        this.ngSelectComponent.clearModel();
      });
    }
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
  }
}
