// Angular
import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import type { Observable } from 'rxjs';
import { BehaviorSubject, concat, forkJoin, of, Subject, throwError } from 'rxjs';
import { catchError, debounceTime, filter, first, map, takeUntil, tap } from 'rxjs/operators';
// Project dependencies
import type { IStep } from '@dataportal/adl';
import { DialogsService } from '@dataportal/adl';
import { CurrentUserService } from '@dataportal/auth';
import { AlertService, Logger } from '@dataportal/front-shared';
// Project library
import { GuardianService } from '@dataportal/guardian-utils';
// Module dependencies
import { Publication, PublicationsService } from '@dataportal/publications';
import type { DatalakeProvider } from '@dataportal/types';
// External libs
import type { FormGroup } from '@ngneat/reactive-forms';
import { FormBuilder } from '@ngneat/reactive-forms';
import { remove } from 'lodash';

import { SourcesService } from '../sources.service';
import { SourcesDraftsService } from '../sources-drafts.service';
import { BasicInfosFormService } from './sub-forms/basic-infos-form.service';
import { DataContentFormService } from './sub-forms/data-content-form.service';
import { DataManagementFormService } from './sub-forms/data-management-form.service';

import type { IDashboardStepSettings } from '../../../dashboards/entities/dashboard-form';
import { Source } from '../../entities/source';
import type { SourceDraft } from '../../entities/source-draft';
import type {
  IBasicInfosFormData,
  IBasicInfosStepContacts,
  IBasicInfosStepGeneralInformation,
  IBasicInfosStepLinks,
  IDataCategorizationFormData,
  IDataCertification,
  IDataContentFormData,
  IDataManagementFormData,
  IDataType,
  IMasterData,
  ISourceStepForm,
} from '../../entities/source-form';

const STEPS: IStep[] = [
  { id: 'basic-infos', name: 'Basic Infos', progressPercent: 33 },
  { id: 'data-content', name: 'Data Content', progressPercent: 33 },
  { id: 'data-management', name: 'Data Management', progressPercent: 34 },
];
const DEFAULT_STEP = STEPS[0];

// in ms
const THIRTY_SECONDS = 30 * 1000;
const AUTOSAVE_DEBOUNCE_TIME = THIRTY_SECONDS;

export interface IPublicationFormData {
  id: string;
  customCategories: Array<{
    id: string;
    name: string;
  }>;
  subCategories: Array<{
    id: string;
    name: string;
  }>;
}

export enum SourceStatus {
  BRAND_NEW,
  DRAFT_DIRTY,
  SAVING_DRAFT,
  DRAFT_SAVED,
  SAVING,
  PUBLISHED,
  DIRTY,
}

@Injectable({
  providedIn: 'root',
})
export class SourceFormService {
  static NB_FORM_STEPS_IN_BASIC_INFOS = 3;
  static NB_FORM_STEPS_IN_DATA_MANAGEMENT = 2;

  private _isCurrentUserAdmin = false;
  private readonly _steps$ = new BehaviorSubject<IStep[]>([]);
  steps$ = this._steps$.asObservable();
  private readonly _edit$ = new BehaviorSubject<boolean>(false);
  edit$ = this._edit$.asObservable();
  private readonly _draft$ = new BehaviorSubject<boolean>(false);
  private readonly _sourceId$ = new BehaviorSubject<string>(null);
  sourceId$ = this._sourceId$.asObservable();
  private readonly _source$ = new BehaviorSubject<Source>(null);
  source$ = this._source$.asObservable();
  private readonly _currentStep$ = new BehaviorSubject<IStep>(null);
  currentStep$ = this._currentStep$.asObservable();
  private readonly _formReady$ = new BehaviorSubject<boolean>(false);
  formReady$ = this._formReady$.asObservable();
  private readonly _publications$ = new BehaviorSubject<Publication[]>([]);
  publications$ = this._publications$.asObservable();
  private readonly _state$ = new BehaviorSubject<SourceStatus>(null);
  state$ = this._state$.asObservable();
  private readonly _progress$ = new BehaviorSubject<number>(0);
  progress$ = this._progress$.asObservable();
  private readonly _stopAutoSave$ = new Subject<void>();
  private readonly _hasClearedNewDashboard$ = new Subject<void>();
  hasClearedNewDashboard$ = this._hasClearedNewDashboard$.asObservable();
  private readonly _isLoadingPublications$ = new BehaviorSubject<boolean>(false);
  isLoadingPublications$ = this._isLoadingPublications$.asObservable();
  private _globalForm: FormGroup<ISourceStepForm>;
  private _dirtyWhenSaving = false;
  private _reset: boolean;
  private _subStep: number;
  private _autoSaveEnabled: boolean;

  constructor(
    private readonly _router: Router,
    private readonly _logger: Logger,
    private readonly _titleService: Title,
    private readonly _formBuilder: FormBuilder,
    private readonly _sourcesService: SourcesService,
    private readonly _sourcesDraftsService: SourcesDraftsService,
    private readonly _alertService: AlertService,
    private readonly _publicationsService: PublicationsService,
    private readonly _dialogService: DialogsService,
    private readonly _dataContentFormService: DataContentFormService,
    private readonly _dataManagementFormService: DataManagementFormService,
    private readonly _basicInfosFormService: BasicInfosFormService,
    private readonly _currentUserService: CurrentUserService,
    private readonly _guardianService: GuardianService,
  ) {
    this._currentUserService.isAdmin$.subscribe((isAdmin) => {
      this._isCurrentUserAdmin = isAdmin;
    });
  }

  get form(): FormGroup<ISourceStepForm> {
    return this._globalForm;
  }

  get basicInfos(): FormGroup<IBasicInfosFormData> {
    return this.form?.get<'basicInfos'>(['basicInfos']) as unknown as FormGroup;
  }

  get basicInfosStepGeneralInformation(): FormGroup<IBasicInfosStepGeneralInformation> {
    return this._basicInfosFormService.basicInfosStepGeneralInformation;
  }

  get basicInfosStepLinks(): FormGroup<IBasicInfosStepLinks> {
    return this._basicInfosFormService.basicInfosStepLinks;
  }

  get basicInfosStepContacts(): FormGroup<IBasicInfosStepContacts> {
    return this._basicInfosFormService.basicInfosStepContacts;
  }

  get dataContent(): FormGroup<IDataContentFormData> {
    return this._dataContentFormService.getForm();
  }

  get dataManagement(): FormGroup<IDataManagementFormData> {
    return this._dataManagementFormService.getForm();
  }

  get dataClassification(): FormGroup<IDataCategorizationFormData> {
    return this.dataManagement?.get<'dataClassification'>(['dataClassification']) as unknown as FormGroup;
  }

  get masterData(): FormGroup<IMasterData> {
    return this.dataManagement?.get('masterData') as FormGroup;
  }

  get dataType(): FormGroup<IDataType> {
    return this.dataManagement?.get('dataType') as FormGroup;
  }

  get dataCertification(): FormGroup<IDataCertification> {
    return this.dataManagement?.get('dataCertification') as FormGroup;
  }

  get sourceId(): string {
    if (this.edit) {
      return this._sourceId$.getValue();
    }

    return this.sourceName || '';
  }

  get sourceName(): string {
    return this._basicInfosFormService.sourceName;
  }

  get categories(): string[] {
    return this._basicInfosFormService.categories;
  }

  get isDraft(): boolean {
    return this._draft$.getValue();
  }

  get publications(): Publication[] {
    return this._publications$.getValue();
  }

  get initialProvider(): DatalakeProvider {
    return this._dataContentFormService.initialProvider;
  }

  get edit(): boolean {
    return this._edit$.getValue();
  }

  get state(): SourceStatus {
    return this._state$.getValue();
  }

  get isPublished(): boolean {
    return this.edit && !this.isDraft;
  }

  get currentStep(): IStep {
    return this._currentStep$.value;
  }

  getBasicInfosStep(step: number): FormGroup {
    return this.basicInfos?.get(`step${step}`) as unknown as FormGroup;
  }

  reset(): void {
    this._reset = true;
  }

  isDirty(): boolean {
    return this._state$.getValue() === SourceStatus.DIRTY;
  }

  getExistingStep(stepId: string): IStep {
    return STEPS.find((existingStep) => existingStep.id === stepId);
  }

  initializeStepsCompletion(): void {
    STEPS.forEach((existingStep) => {
      this._markStepAsCompletedIfNeeded(existingStep.id);
    });
  }

  resetStepsCompletion(): void {
    STEPS.forEach((existingStep) => {
      existingStep.completed = false;
    });
  }

  async init(stepId?: string, sourceId?: string, isDraft = false, preview = false): Promise<void> {
    // This is called on each step routed component
    this._logger.debug('[SourceForm] Initializing form for source', { step: stepId, sourceId, isDraft });
    const currentSourceId = this._sourceId$.getValue();

    if (sourceId === currentSourceId && !this._reset) {
      this._logger.debug('[SourceForm] Already initialized', { step: stepId, sourceId });

      return;
    }

    this._reset = false;
    this._formReady$.next(false);
    this._dataContentFormService.reinitializeInitialProvider();
    const isEditing = sourceId != null;
    this._logger.debug('[SourceForm] Route params', { edit: isEditing, sourceId });
    this._resetPublications();
    this._edit$.next(isEditing);
    this._draft$.next(isDraft);
    this._sourceId$.next(sourceId);

    if (!stepId && !preview) {
      await this.navigateTo(DEFAULT_STEP);
    } else {
      this._currentStep$.next(this.getExistingStep(stepId));
    }

    const initFormWithSource = (source: Source): void => {
      this._source$.next(source);
      this._initForm(isDraft);
      this._basicInfosFormService.fetchOwnersData();
      this._basicInfosFormService.fetchProfiles({ type: 'technical', value: source.technicalOwners });
      this._basicInfosFormService.fetchProfiles({ type: 'functional', value: source.functionalOwners });
      this._basicInfosFormService.fetchProfiles({ type: 'data', value: source.dataOwner });

      if (source) {
        this._isLoadingPublications$.next(true);

        if (this.isDraft) {
          this._publicationsService.listPublicationsBySourceDraft(source.id).subscribe((publications) => {
            this._isLoadingPublications$.next(false);
            this._publications$.next(publications);
          });
        } else {
          this._publicationsService.listPublicationsBySource(source.id).subscribe((publications) => {
            this._isLoadingPublications$.next(false);
            this._publications$.next(publications);
          });
        }
      } else {
        this._resetPublications();
      }
    };

    if (isEditing && sourceId && isDraft) {
      this._logger.debug('[SourceForm] Edition mode, fetching draft');
      this._steps$.next(STEPS);
      this._sourcesDraftsService.getDraft(sourceId).subscribe((draft) => {
        this._logger.debug('[SourceForm] Draft fetched', draft);
        this._dataContentFormService.setDashboardFolders(draft.dashboardFolders?.length ? draft.dashboardFolders : []);
        initFormWithSource(draft);
        this.initializeStepsCompletion();
        this._steps$.next(STEPS);
        this.autoSaveDraft();
      });
    } else if (isEditing && sourceId) {
      this._logger.debug('[SourceForm] Edition mode, fetching source');
      this.stopAutoSave();
      this._steps$.next(STEPS);
      this._sourcesService.findSource(sourceId).subscribe((source) => {
        this._logger.debug('[SourceForm] Source fetched', source);
        this._dataContentFormService.setDashboardFolders(
          source.dashboardFolders?.length ? source.dashboardFolders : [],
        );
        initFormWithSource(source);
      });
    } else {
      this._logger.debug('[SourceForm] Creation mode');
      this.resetStepsCompletion();
      this._steps$.next(STEPS);
      this._source$.next(null);
      this._initForm();
      this._basicInfosFormService.fetchOwnersData();
    }

    this._logger.debug('[SourceForm] Form initialized');
  }

  async navigateTo(step: IStep | string, queryParams: { [key: string]: string | number } = {}): Promise<void> {
    const isEditing = this.edit;
    const isADraft = this.isDraft;
    const futureStep: IStep =
      typeof step === 'string' ? STEPS.find((existingSteps) => existingSteps.id === step) : step;
    let segments = ['/admin', isADraft ? 'drafts' : 'sources'];

    if (isEditing) {
      segments = segments.concat(['edit', this.sourceId]);
    } else {
      segments.push('create');
    }

    segments = segments.concat(['steps', futureStep.id]);

    if (
      (futureStep.id === 'portals' || futureStep.id === 'data-management') &&
      !this.isDraft &&
      !this.isPublished &&
      !this.edit &&
      !this.basicInfosStepGeneralInformation?.value?.name
    ) {
      this._dialogService.alert({
        message: 'You must give a name to your data product before accessing Permissions & Data Management step.',
      });

      return;
    }

    if (!this.isPublished && this._currentStep$?.value) {
      const currentStepId = this._currentStep$.value.id;
      this._markStepAsCompletedIfNeeded(currentStepId);
    }

    this._logger.debug('[SourceForm] Navigate to', segments, queryParams);
    await this._router.navigate(segments, { queryParams });
    this._currentStep$.next(futureStep);
    this._subStep = Number(queryParams.step);

    this._titleService.setTitle(`${futureStep.name} | Data Product Admin | Accor Data Portal`);

    if (
      this._dataContentFormService.isNewDashboard &&
      this._dataContentFormService.selectedDashboard &&
      (!queryParams?.id || queryParams.id !== this._dataContentFormService.selectedDashboard.value.id)
    ) {
      this._dataContentFormService.deselectDashboard();
      this._hasClearedNewDashboard$.next();
    } else if (
      !this._dataContentFormService.isNewDashboard &&
      this._dataContentFormService.selectedDashboard &&
      (!queryParams?.id || queryParams.id !== this._dataContentFormService.selectedDashboard.value.id)
    ) {
      this._dataContentFormService.deselectDashboard();
    }
  }

  async loveYourWork(): Promise<void> {
    const segments = this._edit$.getValue()
      ? ['/admin', 'drafts', 'publish', this.sourceId]
      : ['/admin', 'sources', 'create', 'steps', 'publish'];
    await this._router.navigate(segments, {
      queryParams: {
        from: this._currentStep$.getValue().id,
      },
    });
  }

  initPublication(id: string, name: string): FormGroup<IPublicationFormData> {
    return this._formBuilder.group({
      id: [id, Validators.required],
      name: [name, Validators.required],
      customCategories: this._formBuilder.array([]),
      subCategories: this._formBuilder.array([]),
    });
  }

  removePublication(publicationToRemove: Publication): Observable<void> {
    const postRemovePublicationRequestFunc = () => {
      this._logger.info('[SourceForm] Publication removed', publicationToRemove);

      if (this.isPublished) {
        this._alertService.success('Link removed');
      }

      const tempPublications = [...this.publications];
      this._logger.debug('[SourceForm] Current state', tempPublications);
      remove(
        tempPublications,
        (publication) =>
          publication.portalId === publicationToRemove.portalId &&
          publication.sourceId === publicationToRemove.sourceId,
      );
      this._logger.debug('[SourceForm] State updated', tempPublications);
      this._publications$.next(tempPublications);
    };

    if (this.isDraft) {
      return this._publicationsService
        .removeDraftPublication(publicationToRemove.sourceId, publicationToRemove.portalId)
        .pipe(tap(postRemovePublicationRequestFunc));
    } else {
      return this._publicationsService
        .removePublication(publicationToRemove.sourceId, publicationToRemove.portalId)
        .pipe(tap(postRemovePublicationRequestFunc));
    }
  }

  requestPublication(data: IPublicationFormData): Observable<void> {
    const publication = new Publication();
    publication.customCategories = data.customCategories;
    publication.subCategories = data.subCategories;
    publication.portalId = data.id;
    publication.sourceId = this._source$.getValue().id;

    const postPublicationRequestFunc = (requested) => {
      this._logger.info('[SourceForm] Link requested', requested);

      if (this.isPublished) {
        this._alertService.success('Link requested');
      }

      const tempPublications = [...this.publications];
      this._logger.debug('[SourceForm] Current state', tempPublications);
      tempPublications.push(requested);
      this._logger.debug('[SourceForm] State updated', tempPublications);
      this._publications$.next(tempPublications);
    };

    if (this.isDraft) {
      return this._publicationsService.createDraftPublication(publication).pipe(map(postPublicationRequestFunc));
    } else {
      return this._publicationsService.requestPublications(publication).pipe(map(postPublicationRequestFunc));
    }
  }

  updatePublicationStatus(publication: Publication): Observable<void> {
    publication.status = publication.status === 'unpublished' ? 'waiting publication' : 'unpublished';

    return this._publicationsService.handlePublications(publication).pipe(
      map((updated) => {
        this._logger.info('[SourceForm] Publication status updated', updated);
        const tempPublications = [...this.publications];
        this._logger.debug('[SourceForm] Current state', tempPublications);
        const toUpdate = tempPublications.find(
          (p) => p.portalId === updated.portalId && p.sourceId === updated.sourceId,
        );
        toUpdate.status = updated.status;
        this._logger.debug('[SourceForm] State updated', tempPublications);
        this._publications$.next(tempPublications);
      }),
    );
  }

  saveDraft(): Observable<SourceDraft> {
    if (!this.sourceName) {
      this._logger.warn('[SourceForm] Invalid draft !');
      this.navigateTo('basic-infos', { step: 1 }).then(() => {
        this._basicInfosFormService.sourceNameControl.markAsDirty();
      });

      return;
    }

    if (this._dataContentFormService.selectedDashboard?.invalid) {
      this._dataContentFormService.selectedDashboard.markAllAsDirty();

      this.scrollAndDisplayErrorElementWhenOnCorrectPage();

      return of(null);
    }

    this._dataContentFormService.mergeDraftIntoDashboardForms();

    const source = this._source$.getValue() != null ? this._source$.getValue() : new Source();

    source.fromStepForm(this._globalForm.getRawValue());
    source.dashboardFolders = this._dataContentFormService.currentDashboardFolders;
    this._logger.info('[SourceForm] Saving draft', source);

    const onSuccess = (created: SourceDraft): void => {
      this._edit$.next(true);
      this._draft$.next(true);
      this._dataContentFormService.setNewProvider(source.getProvider());
      this._removeChecksOnSave(source.id);
      this._steps$.next(STEPS);
      this._source$.next(created);
      this._sourceId$.next(created.id);
      this._state$.next(this._dirtyWhenSaving ? SourceStatus.DRAFT_DIRTY : SourceStatus.DRAFT_SAVED);
      this._logger.info('[SourceForm] Draft saved', created);
      this._logger.debug('[SourceForm] Has form been touched in the meantime ?', this._dirtyWhenSaving);
      this._logger.debug(
        '[SourceForm] Next state',
        this._dirtyWhenSaving ? SourceStatus.DRAFT_DIRTY : SourceStatus.DRAFT_SAVED,
      );
      this._dirtyWhenSaving = false;
      this._globalForm.markAsPristine();
    };

    const onError = (error: Error): Observable<never> => {
      this._logger.error('{SourceForm] Error saving draft', error);
      this._state$.next(SourceStatus.DRAFT_DIRTY);
      this._dirtyWhenSaving = false;

      return throwError(error);
    };

    if (this.state === SourceStatus.DRAFT_SAVED) {
      this._logger.info('[SourceForm] Draft already saved');

      return of(source);
    }

    if (this.state !== SourceStatus.DIRTY && this.state !== SourceStatus.DRAFT_DIRTY) {
      this._logger.error('[SourceForm] Cannot save draft: Invalid state', this.state);

      return throwError(new Error('Cannot save draft: Invalid state'));
    }

    this._state$.next(SourceStatus.SAVING_DRAFT);

    if (!this.isDraft) {
      const currentDataContentTab = this._dataContentFormService.currentTab;

      return this._sourcesDraftsService.createDraft(source).pipe(
        tap(async (created) => {
          onSuccess(created);
          await this.navigateTo(
            this._currentStep$.getValue(),
            isNaN(this._subStep)
              ? {
                  currentTab: currentDataContentTab,
                }
              : {
                  step: this._subStep,
                  id: this._dataContentFormService.selectedDashboard?.value?.id,
                  currentTab: currentDataContentTab,
                },
          );
        }),
        catchError((e: unknown) => onError(e as Error)),
      );
    } else {
      this._state$.next(SourceStatus.SAVING_DRAFT);

      return this._sourcesDraftsService.updateDraft(source).pipe(
        tap(
          (created) => onSuccess(created),
          catchError((e: unknown) => onError(e as Error)),
        ),
      );
    }
  }

  /**
   * @returns The saved source (can be null), or undefined if could not manage to save front-end side,
   * or throw error if could not manage to save back-end side
   */
  save(): Observable<Source | undefined> {
    if (this._dataContentFormService.selectedDashboard?.invalid) {
      this._dataContentFormService.selectedDashboard.markAllAsDirty();

      this.scrollAndDisplayErrorElementWhenOnCorrectPage();

      return of(null);
    }

    this._dataContentFormService.mergeDraftIntoDashboardForms();

    if (this.form.invalid) {
      this._logger.warn('[SourceForm] Invalid source !', this.form);

      return concat(this._revealFirstError(), of(undefined));
    }

    const formValue = this._globalForm.getRawValue();
    const source = this._source$.getValue() || new Source();

    source.fromStepForm(this._globalForm.getRawValue());
    source.dashboardFolders = this._dataContentFormService.currentDashboardFolders; // dashboard folders

    const hasDashboard = formValue.dataContent.dashboards?.length > 0;
    const hasDatalake = formValue.dataContent.datalake.provider !== 'none';
    const hasSnowflake = formValue.dataContent.snowflake.snowflakeTableKeys?.length > 0;

    if (!this._isCurrentUserAdmin && !hasDashboard && !hasDatalake && !hasSnowflake) {
      this._dialogService.alert({
        message: 'You source must have at least one data content before being published.',
        confirmText: 'OK',
      });

      return of(undefined);
    }

    const isAzureDatalakePathRequired =
      formValue.dataContent.datalake.provider === 'azure' && !formValue.dataContent.datalake.paths?.length;

    if (isAzureDatalakePathRequired) {
      this._dialogService.alert({
        message: 'You have selected Azure Datalake as data content provider but no datalake path has been added.',
        confirmText: 'OK',
      });

      return of(undefined);
    }

    this._logger.info('[SourceForm] Saving source', source);
    this._state$.next(SourceStatus.SAVING);

    const errorSaving = (error: unknown): Observable<never> => {
      this._logger.error('[SourceForm] Error saving source', error);
      this._state$.next(SourceStatus.DIRTY);
      this._dirtyWhenSaving = false;

      return throwError(error);
    };

    const successSaving = (): void => {
      this.resetStepsCompletion();
      this.stopAutoSave();
      this._steps$.next(STEPS);
      this._state$.next(this._dirtyWhenSaving ? SourceStatus.DIRTY : SourceStatus.PUBLISHED);
      this._removeChecksOnSave(source.id);
      this._dirtyWhenSaving = false;
      this._draft$.next(false);
      this._globalForm.markAsPristine();
    };

    if (!this.isPublished) {
      this._logger.info('[SourceForm] Publishing source for the first time');
      const sourceDraftId = this.isDraft ? this.sourceId : undefined;

      this._alertService.success('Data Product creation is in progress.');

      return this._sourcesService
        .create(source, sourceDraftId)
        .pipe(catchError(errorSaving.bind(this)))
        .pipe(
          tap(async (created) => {
            this._alertService.success('Data Product successfully published.');
            this._logger.info('[SourceForm] Source published for the first time !', created);
            this._source$.next(created);
            this._dataContentFormService.setNewProvider(source.getProvider());
            this._sourceId$.next(created.id);
            this._edit$.next(true);
            successSaving();

            if (this.isDraft) {
              this._logger.info('[SourceForm] A draft was saved before publishing, deleting it');
              this._sourcesDraftsService.deleteDraft(created.id).subscribe(() => {
                this._logger.info('[SourceForm] Source published, draft successfully deleted');
              });
            }

            await this.navigateTo(
              this._currentStep$.getValue(),
              isNaN(this._subStep)
                ? {}
                : {
                    step: this._subStep,
                    id: this._dataContentFormService.selectedDashboard?.value?.id,
                  },
            );
          }),
        );
    } else {
      this._logger.info('[SourceForm] Updating already published source');

      return this._sourcesService.update(source).pipe(
        catchError(errorSaving.bind(this)),
        tap(() => {
          this._alertService.success('Data Product successfully updated', false);
          this._dataContentFormService.setNewProvider(source.getProvider());
          successSaving();
        }),
      );
    }
  }

  stopAutoSave(): void {
    this._logger.info('[SourceForm] Stopping auto-save');
    this._stopAutoSave$.next();
    this._autoSaveEnabled = false;
  }

  autoSaveDraft(): void {
    if (!this._autoSaveEnabled) {
      this._autoSaveEnabled = true;
      this.form.value$.pipe(debounceTime(AUTOSAVE_DEBOUNCE_TIME), takeUntil(this._stopAutoSave$)).subscribe(() => {
        if (!this._dataContentFormService.selectedDashboard && !this._dataContentFormService.isNewDashboard) {
          if (this.state !== SourceStatus.SAVING && this.state !== SourceStatus.SAVING_DRAFT) {
            this._alertService.info('Draft has been automatically saved');
            this._logger.info('[SourceForm] Value changed, auto-saving draft');
            this.saveDraft().subscribe(() => this._logger.info('[SourceForm] Draft auto-saved'));
          } else {
            this._logger.warn('[SourceForm] Auto-save cancelled: already in saving state');
          }
        }
      });
    }
  }

  private _markStepAsCompletedIfNeeded(stepId: string): void {
    if (this._canStepBeMarkedAsCompleted(stepId)) {
      const relatedStep = this.getExistingStep(stepId);
      const currentProgress = this._progress$.getValue();

      if (!relatedStep.completed) {
        const nextValue = currentProgress + relatedStep.progressPercent;
        this._progress$.next(nextValue > 100 ? 100 : nextValue);
        STEPS.forEach((existingStep) => {
          if (existingStep.id === stepId) {
            existingStep.completed = true;
          }
        });
      }
    }
  }

  private _resetPublications(): void {
    this._publications$.next([]);
  }

  private _canStepBeMarkedAsCompleted(stepId: string) {
    switch (stepId) {
      case 'basic-infos':
        return this.basicInfos?.valid;
      case 'data-content':
        return (
          this.dataContent?.valid &&
          (this._dataContentFormService.datalakeForm?.value?.paths?.length ||
            this._dataContentFormService.datalakeForm?.value?.externalURL ||
            this._dataContentFormService.dashboardsForm?.length ||
            this._dataContentFormService.snowflakeForm?.value?.snowflakeTableKeys?.length)
        );
      case 'portals':
        return true;
      case 'data-management':
      default:
        return this.dataManagement?.valid && !!this.dataManagement?.value?.dataClassification?.dataSourcing;
    }
  }

  private _removeChecksOnSave(sourceId: string) {
    this._guardianService.allChecksToRemoveOnSave.forEach((check) =>
      this._guardianService.removeCheck(check.checkId, sourceId, check.dataset, check.resource).subscribe(),
    );
    this._guardianService.clearChecksToRemoveOnSave();
  }

  private _initForm(isDraft?: boolean): void {
    this._formReady$.next(false);
    const source = this._source$.getValue();
    const isEditing = this._edit$.getValue();

    if (isEditing && isDraft) {
      this._state$.next(SourceStatus.DRAFT_SAVED);
    } else if (isEditing) {
      this._state$.next(SourceStatus.PUBLISHED);
    } else {
      this._state$.next(SourceStatus.BRAND_NEW);
    }

    this._basicInfosFormService.init(source);
    this._dataContentFormService.init(source);
    this._dataManagementFormService.init(source, this.isPublished);

    forkJoin([
      this._basicInfosFormService.formReady$.pipe(first((isReady) => isReady)),
      this._dataContentFormService.formReady$.pipe(first((isReady) => isReady)),
      this._dataManagementFormService.formReady$.pipe(first((isReady) => isReady)),
    ]).subscribe(() => {
      this._globalForm = this._formBuilder.group({
        basicInfos: this._basicInfosFormService.getForm(),
        dataContent: this._dataContentFormService.getForm(),
        dataManagement: this._dataManagementFormService.getForm(),
      });
      this._formReady$.next(true);
    });

    this._globalForm.dirty$.pipe(filter((isDirty) => isDirty)).subscribe(() => {
      this._logger.debug('[SourceForm] Draft dirty !');

      switch (this._state$.getValue()) {
        case SourceStatus.BRAND_NEW:
        case SourceStatus.DRAFT_DIRTY:
        case SourceStatus.DRAFT_SAVED:
          this._state$.next(SourceStatus.DRAFT_DIRTY);
          break;
        case SourceStatus.PUBLISHED:
        case SourceStatus.DIRTY:
          this._state$.next(SourceStatus.DIRTY);
          break;
        case SourceStatus.SAVING_DRAFT:
        case SourceStatus.SAVING:
          this._dirtyWhenSaving = true;
          break;
      }
    });
  }

  private async _revealFirstError(): Promise<void> {
    this._logger.debug('[SourceForm] Finding and revealing first error');
    const screens: Array<{ step: IStep; id?: string; group: FormGroup }> = [
      { step: STEPS[0], group: this._basicInfosFormService.basicInfosStepGeneralInformation },
      { step: STEPS[0], group: this._basicInfosFormService.basicInfosStepLinks },
      { step: STEPS[0], group: this._basicInfosFormService.basicInfosStepContacts },
      { step: STEPS[2], group: this.dataClassification },
      { step: STEPS[2], group: this.dataType },
      { step: STEPS[2], group: this.dataCertification },
    ];

    for (let i = 0; i < this._dataContentFormService.dashboardsForm.length; ++i) {
      const currentDashboard = this._dataContentFormService.dashboardsForm.at(i) as FormGroup<IDashboardStepSettings>;
      screens.push({
        step: STEPS[1],
        id: currentDashboard.get('id').value as string,
        group: currentDashboard,
      });
    }

    screens.push({ step: STEPS[1], group: this.dataContent });

    for (const screen of screens) {
      if (!screen.group.valid) {
        this._logger.debug('[SourceForm] First error is', screen.group);
        let dataContentTabToOpen: string;

        if (screen.step.id === 'data-content') {
          if (screen.id && !screen.group.valid) {
            dataContentTabToOpen = 'Dashboard';
          } else if (!screen.group.get('datalake')?.valid) {
            dataContentTabToOpen = 'Datalake';
          } else if (!screen.group.get('snowflake')?.valid) {
            dataContentTabToOpen = 'Snowflake';
          }
        }

        await this.navigateTo(screen.step, { id: screen.id, currentTab: dataContentTabToOpen });
        screen.group.markAllAsTouched(); // to trigger native form validators
        screen.group.markAllAsDirty();
        this._alertService.warning(
          'Cannot publish Data Product. Some required information is missing. Please, fill the missing information and save your data product.',
        );

        return;
      }
    }
  }

  scrollAndDisplayErrorElementWhenOnCorrectPage() {
    const invalidControl = document.querySelector('.adl-form-control--errors > ul');

    if (invalidControl) {
      invalidControl.scrollIntoView({
        block: 'center',
      });

      const displayError = () => {
        let text = 'Some information is missing: ';
        invalidControl.querySelectorAll('li > span').forEach((error) => {
          text += error.textContent + ' - ';
        });
        this._alertService.warning(text, false);
      };

      displayError();
    }
  }
}
