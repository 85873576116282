import type { AfterViewInit, OnDestroy } from '@angular/core';
import { Directive, ElementRef } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[dpgNgxDatatableResizeWatcher]',
})
export class NgxDatatableResizeWatcherDirective implements AfterViewInit, OnDestroy {
  resizeObserver: ResizeObserver;

  constructor(private readonly _elementRef: ElementRef<HTMLElement>, private readonly _table: DatatableComponent) {}

  ngAfterViewInit(): void {
    this.resizeObserver = new ResizeObserver(() => {
      setTimeout(() => {
        this._table.recalculate();
        this._table.recalculateColumns();
        window.dispatchEvent(new Event('resize'));
      }, 200);
    });
    this.resizeObserver.observe(this._elementRef.nativeElement);
  }

  ngOnDestroy(): void {
    this.resizeObserver.unobserve(this._elementRef.nativeElement);
  }
}
