import { Component, Input } from '@angular/core';

/*
FIXME: Copied from old adl-table component
To use new table: figure out how to place cdkDrop attributes and classes
on the tbody element in adl-table component used by guardian-step-check-columns
while keeping the reorder function inside guardian-step-check-columns component
*/
@Component({
  selector: 'adl-guardian-modal-table',
  templateUrl: './guardian-step-condition-table.component.html',
  styleUrls: ['./guardian-step-condition-table.component.scss'],
})
export class GuardianStepConditionTableComponent {
  @Input() minBodyHeight: number;
  @Input() maxBodyHeight: number;
  @Input() headColor: string;
  @Input() bodyColor: string;
  @Input() bodyHorizontalPadding: number;
  @Input() bodyPaddingBottom: number;
  @Input() hasTableHeaders = true;
  @Input() hasHoveringAnimation = true;
  @Input() hasBorder = false;
  @Input() hasRoundedBorder = false;
  @Input() hasHeadBodySeparator = true;
  @Input() hasBodyFullSeparators = false;
  @Input() hasBodyNotFullSeparators = false;
  @Input() title: string;
  @Input() hasBoxShadow = false;
}
