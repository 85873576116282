import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

import type { IDocumentation, IVideo } from '../../../models/documentation.model';
import type { IGetUserDocumentationView } from '../../../models/user-view.model';

const DEFAULT_VIDEO: IVideo = { title: 'no video selected', url: 'about:blank' };
@Component({
  selector: 'da-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VideoPlayerComponent {
  @Input() documentationId: IDocumentation['id'];
  @Input() checkViews = false;
  @Input() set videos(value: IVideo[] | null) {
    this.videosList = value?.map((v) => ({
      ...v,
      url: v.url + '?showinfo=false',
    }));
    this.selectedVideo = value?.[0] ?? DEFAULT_VIDEO;
  }
  @Input() showMiniatures = true;
  @Output() allVideosViewChanged = new EventEmitter<IGetUserDocumentationView['isAllVideosViewed']>();

  videosList: IVideo[] = [];
  selectedVideo: IVideo = DEFAULT_VIDEO;

  private readonly _videoMarkAsViewSubject = new Subject<IGetUserDocumentationView['isAllVideosViewed']>();

  constructor() {
    this._videoMarkAsViewSubject
      .pipe(distinctUntilChanged())
      .subscribe((isAllVideosViewed) => this.allVideosViewChanged.emit(isAllVideosViewed));
  }

  selectVideo(event: Event, video: IVideo) {
    event.preventDefault();
    this.selectedVideo = video;
  }

  onVideoMarkAsViewed(isAllVideosViewed: IGetUserDocumentationView) {
    this._videoMarkAsViewSubject.next(isAllVideosViewed.isAllVideosViewed);
  }
}
