import type { OnDestroy, OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { Logger } from '@dataportal/front-shared';
import { Navigator } from '@dataportal/navigator';
import { CurrentDashboardService, DashboardsService } from '@dataportal/sources-dashboards-recommendations';

import { CurrentDashboardErrorCode } from '../../entities/current-dashboard-error.code';

@Component({
  selector: 'dpg-dashboard-error-page',
  templateUrl: './dashboard-error-page.component.html',
  styleUrls: ['./dashboard-error-page.component.css'],
})
export class DashboardErrorPageComponent implements OnInit, OnDestroy {
  previousUrl: string;
  errorType: string;
  errorDescription: string;
  allowedGroups: string[] = [];

  private readonly _destroy$ = new Subject<void>();

  constructor(
    private readonly _currentDashboardService: CurrentDashboardService,
    private readonly _logger: Logger,
    private readonly _route: ActivatedRoute,
    private readonly _router: Router,
    private readonly _navigator: Navigator,
    private readonly _dashboardsService: DashboardsService,
  ) {}

  ngOnInit(): void {
    this.previousUrl = window.location.href;
    this._currentDashboardService.error$
      .pipe(
        filter((err) => !!err),
        takeUntil(this._destroy$),
      )
      .subscribe((error) => {
        // Thus we can handle the different error pages and react accordingly
        this._logger.error('[DashboardPage] Error happened when checking current dashboard pre-requisites', error);

        switch (error.code) {
          case CurrentDashboardErrorCode.SOURCE_NOT_FOUND:
            this.errorType = 'sourceNotFound';
            break;
          case CurrentDashboardErrorCode.UNAUTHORIZED:
            this.errorType = 'unauthorized';
            break;
          case CurrentDashboardErrorCode.DASHBOARD_NOT_FOUND:
            this.errorType = 'dashboardNotFound';
            break;
          case CurrentDashboardErrorCode.NOT_IN_ALLOWED_GROUPS:
            this.errorType = 'notInAllowedGroups';
            break;
          default:
            this.errorType = 'unknown';
            break;
        }
      });
    this.handleAllowedGroups();
    this.handleErrorDescription();
  }

  handleErrorDescription() {
    if (this.errorType === 'sourceNotFound') {
      this.errorDescription = "This data product can't be found.";
    } else if (this.errorType === 'dashboardNotFound') {
      this.errorDescription = 'This dashboard doesn’t exist anymore.';
    } else if (this.errorType === 'unauthorized') {
      this.errorDescription =
        'Permissions on the data product are needed to reach this dashboard. Please either go to the data asset page where the dashboard is located to request access, or go back to the homepage.';
    } else if (this.errorType === 'notInAllowedGroups') {
      this.errorDescription = `Specific permissions are needed to access this dashboard. Only ${this.allowedGroups.toString()} are allowed to consult it. Please either go to the data product page where the dashboard is located and contact the owners, or go back to the homepage.`;
    } else {
      this.errorDescription = 'Unknown error :-(';
    }
  }

  handleAllowedGroups() {
    if (JSON.parse(this._route.snapshot.paramMap.get('source'))) {
      this._dashboardsService
        .getDashboardAllowedGroups(
          JSON.parse(this._route.snapshot.paramMap.get('source')).id,
          decodeURI(this.previousUrl.substring(this.previousUrl.lastIndexOf('/') + 1)),
        )
        .subscribe((groups) => {
          if (groups) {
            groups.forEach((group) => this.allowedGroups.push(group.group_name));
          }
        });
    }
  }

  backToHome() {
    this._router.navigate(['/']).then();
  }

  goToDataAssetPage() {
    if (JSON.parse(this._route.snapshot.paramMap.get('source'))?.id) {
      this._logger.info('[DashboardPage] Returning to source page');
      this._navigator
        .navigate('catalog-source', { fileName: JSON.parse(this._route.snapshot.paramMap.get('source'))?.id })
        .then(() =>
          this._logger.info('Navigated to', ['/sources/', JSON.parse(this._route.snapshot.paramMap.get('source'))?.id]),
        );
    } else {
      this._router.navigateByUrl(
        decodeURI(
          this.previousUrl.substring(this.previousUrl.indexOf('/sources/'), this.previousUrl.indexOf('/dashboard')),
        ),
      );
    }
  }

  ngOnDestroy(): void {
    this._logger.debug('[DashboardPage] Destroying');
    this._destroy$.next();
    this._destroy$.complete();
  }
}
