import { Entity } from '@decahedron/entity';

// Entity
export class DocumentationLink extends Entity {
  // Attributes
  linkName?: string = null;
  linkUrl?: string = null;

  constructor(linkName: string, linkUrl: string) {
    super();
    this.linkName = linkName;
    this.linkUrl = linkUrl;
  }

  // Methods
  toJson(): { link_url: string; link_name: string } {
    return {
      link_name: this.linkName || null,
      link_url: this.linkUrl || null,
    };
  }
}
