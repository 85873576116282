import { InjectionToken } from '@angular/core';

// Constants
export const API_URLS = new InjectionToken<IApiUrls>('dataportal-front:api:urls');

// Types
export interface IApiUrls {
  // Urls
  // - legacy
  v2: string;
  v3: string;

  // - v4
  v4: {
    accessRequest: string;
    subCategories: string;
    sources: string;
    groups: string;
    users: string;
    permissions: string;
    portals: string;
    publications: string;
    companies: string;
    datalake: string;
    recommendations: string;
    configurations: string;
    dashboards: string;
    databricks: string;
    drafts: string;
    guardian: string;
    magellan: string;
    tools: string;
    telemetry: string;
    relationships: string;
    statistics: string;
    leons: string;
    miscellaneous: string;
    notifications: string;
    snowflake: string;
    snowflakeExplore: string;
    academy: string;
    dbSnowflake: string;
    profile: string;
    amundsen: string;
    papyrus: string;
    homepage?: string;
    news?: string;
    athena: string;
    glossary: string;
    cockpit: string;
    chat: string;
    gli: string;
  };

  websocket: string;
}
