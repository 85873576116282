import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { APIFetchCachingService, ApiService } from '@dataportal/front-api';
import type { DatalakePath, IObjectExistenceResult } from '@dataportal/front-shared';
import { AlertService, Logger } from '@dataportal/front-shared';

import { DatalakeApiService } from './datalake-api.service';

// Services
@Injectable()
export class DatalakeObjectExistenceCacheService extends APIFetchCachingService<DatalakePath> {
  static DEFAULT_TIME_OUT_IN_MINUTES = 5;

  // Constructor
  constructor(
    private readonly _datalakeApiService: DatalakeApiService,
    readonly apiService: ApiService,
    readonly logger: Logger,
    private readonly _alertService: AlertService,
  ) {
    super(DatalakeObjectExistenceCacheService.DEFAULT_TIME_OUT_IN_MINUTES, apiService, logger);
  }

  getExtract(settings: DatalakePath): Observable<IObjectExistenceResult> {
    return super.getExtract(settings).pipe(
      catchError(() => {
        this.logger.debug('An error occured while getting object existence');

        return of(null);
      }),
    );
  }

  cachedKey(settings: DatalakePath): string {
    return this._datalakeApiService.fetchObjectExistenceUrl(settings);
  }

  fetchUrl(settings: DatalakePath): string {
    return this._datalakeApiService.fetchObjectExistenceUrl(settings);
  }
}
