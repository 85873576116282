import type { OnDestroy, OnInit } from '@angular/core';
import { ChangeDetectorRef, Directive, EventEmitter, Input, Output } from '@angular/core';
import { merge, of, Subject } from 'rxjs';
import { catchError, filter, switchMap, takeUntil } from 'rxjs/operators';
import { CheckboxComponent } from '@dataportal/adl';

import { UserViewService } from '../../services/user-view.service';

import type { IDocumentation } from '../../models/documentation.model';
import type { IGetUserDocumentationView } from '../../models/user-view.model';

interface IMarkAsView {
  documentationId: IDocumentation['id'];
  videoId?: number;
}

@Directive({
  selector: '[markAsView]',
})
export class MarkAsViewDirective implements OnInit, OnDestroy {
  @Input() markAsView: IMarkAsView;
  @Output() markAsViewChanged = new EventEmitter<IGetUserDocumentationView>();

  private readonly _destroySubject = new Subject<void>();
  private _userView: IGetUserDocumentationView;

  constructor(
    private readonly _userViewService: UserViewService,
    private readonly _host: CheckboxComponent,
    private readonly _ref: ChangeDetectorRef,
  ) {}

  ngOnDestroy(): void {
    this._destroySubject.next();
    this._destroySubject.complete();
  }

  ngOnInit() {
    const getUserView$ = this._userViewService
      .getView$(this.markAsView.documentationId, this.markAsView.videoId)
      .pipe(catchError((e: unknown) => of(this._userView)));

    const postViewOnChanged$ = this._host.changed.asObservable().pipe(
      switchMap((isViewed) => this._postView$(isViewed).pipe(catchError((e: unknown) => of(this._userView)))),
      takeUntil(this._destroySubject),
    );

    merge(getUserView$, postViewOnChanged$)
      .pipe(filter((userView) => userView !== undefined))
      .subscribe((userView) => {
        this._userView = userView;
        this._host.writeValue(this._isHostElementViewed(this._userView));
        this._ref.detectChanges();
        this.markAsViewChanged.emit(userView);
      });
  }

  private readonly _postView$ = (isViewed: boolean) =>
    this._userViewService.updateCreateView$(
      this._userView.id,
      this.markAsView.documentationId,
      this.markAsView.videoId,
      isViewed,
    );

  private readonly _isHostElementViewed = ({
    isDocumentViewed,
    isGivenVideoViewed,
    isAllVideosViewed,
  }: IGetUserDocumentationView): boolean =>
    this.markAsView.videoId ? isGivenVideoViewed : isDocumentViewed || isAllVideosViewed;
}
