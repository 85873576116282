import type { OnDestroy, OnInit } from '@angular/core';
import { Inject } from '@angular/core';
import { Component } from '@angular/core';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { IS_ACCOR } from '@dataportal/front-environment';

import { DocumentationService } from '../../../../../../libs/dataportal-data-academy/src/lib/services/documentation.service';
import { HelpCenterService } from '../help-center.service';

import type { IDocumentation } from '../../../../../../libs/dataportal-data-academy/src/lib/models/documentation.model';
import type { OneProductModel } from '../../../../../../libs/dataportal-data-academy/src/lib/models/product.model';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
})
export class AboutComponent implements OnInit, OnDestroy {
  product: OneProductModel;
  loadingContent = false;
  faqEntriesList: IDocumentation[];

  private readonly _destroyed$ = new Subject();

  constructor(
    private readonly _helpCenterService: HelpCenterService,
    private readonly _documentationService: DocumentationService,
    @Inject(IS_ACCOR) readonly isAccor: boolean,
  ) {}

  ngOnInit() {
    this.loadingContent = true;
    this._helpCenterService.updateProductsFAQ();

    combineLatest([this._helpCenterService.faqEntriesResults$, this._helpCenterService.mainProduct$])
      .pipe(
        tap(([faqEntries, product]) => {
          this.faqEntriesList = faqEntries
            .sort((entryA, entryB) => entryA.title.localeCompare(entryB.title))
            .slice(0, 4);
          this.product = product;
          this.loadingContent = false;
        }),
        takeUntil(this._destroyed$),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  faqAction(item: IDocumentation) {
    this._documentationService.openDocumentation(item);
  }

  openTeamsUpdatesChannel() {
    window.open(this.product.updatesChannel, '_blank');
  }
}
