import type { OnChanges, SimpleChanges } from '@angular/core';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@ngneat/reactive-forms';

@Component({
  selector: 'adl-wysiwyg-editor',
  templateUrl: './wysiwyg-editor.component.html',
})
export class WysiwygEditorComponent implements OnChanges {
  @Input() control: FormControl;
  @Input() placeholder?: string;
  @Output() init = new EventEmitter<any>();

  options: any = {
    base_url: '/tinymce',
    autoresize_max_height: 300,
    table_toolbar:
      'tableprops tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow ' +
      '| tableinsertcolbefore tableinsertcolafter tabledeletecol',
    content_style: 'body {padding: 0px; background-color: #fff; margin: 8px}',
    content_css: '/assets/dpg/css/wysiwyg-editor-content.css',
    plugin_preview_width: '1050',
    promotion: false,
    image_advtab: true,
    paste_merge_formats: true,
    paste_data_images: true,
    formats: {
      bold: { inline: 'b' },
      italic: { inline: 'i' },
      underline: { inline: 'u' },
    },
    extended_valid_elements: '@[class~wysiwyg_class],h1,h2,h3,h4,h5,h6,p,ul,ol,li,img[src|style],table,tbody,th,tr,td',
    invalid_elements: 'label',
    plugins: 'link autoresize lists preview table media image wordcount',
  };

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.placeholder && this.placeholder) {
      this.options.placeholder = this.placeholder;
    }
  }
}
