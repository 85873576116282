<div class="browser--table" [ngStyle]="{ 'pointer-events': disabled ? 'none' : 'auto' }">
  <ngx-datatable
    dpgNgxDatatableResizeWatcher
    class="material no-box-shadow datalake-table"
    [rows]="displayedObjects"
    [scrollbarH]="true"
    [externalSorting]="true"
    (sort)="sortChanged($event)"
    [columnMode]="columnMode.force"
    [messages]="{ emptyMessage: 'No file or directory.' }"
    (activate)="handleEvents($event)"
    [rowClass]="getRowClass"
  >
    <ngx-datatable-column *ngIf="editable" [sortable]="false" width="42" maxWidth="42">
      <ng-template ngx-datatable-header-template>
        <div class="checkbox row-flex a-center j-center m-0">
          <input
            data-cy="chooseAllCheckbox"
            [disabled]="(explorerPageService.allPageLoaded$ | async) === false"
            id="checkbox_all"
            type="checkbox"
            [(ngModel)]="allChecked"
            (change)="checkAllRows()"
          />
          <label class="p-O" for="checkbox_all"></label>
        </div>
      </ng-template>

      <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
        <div [attr.data-cy]="'checkbox-' + row.name" class="checkbox row-flex a-center j-center m-0">
          <input
            id="checkbox{{ rowIndex }}"
            type="checkbox"
            [(ngModel)]="displayedObjects[rowIndex].checked"
            (change)="checkOneRow(row)"
          />
          <label class="p-O" for="checkbox{{ rowIndex }}"></label>
        </div>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column prop="provider" width="70" minWidth="70" maxWidth="70" [sortable]="false" name="Cloud">
      <ng-template let-row="row" ngx-datatable-cell-template>
        <div class="cell-type-div row-flex a-center">
          <span
            title="{{ row.provider === 'aws' ? 'AWS' : 'Azure' }}"
            class="provider-icon"
            [ngClass]="row.provider"
          ></span>
          <adl-file-icon [displayObject]="row"></adl-file-icon>
        </div>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column prop="name" name="Name" [sortable]="explorerPageService.allPageLoaded$ | async">
      <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
        <div *ngIf="row.folderCreation">
          <label>
            <input
              #newDirectoryInput
              (keyup)="newDirectoryKeyUp($event)"
              (click)="$event.stopPropagation()"
              [(ngModel)]="newDirectoryName"
              type="text"
              class="form-control datalake-directory-form"
              placeholder="Directory name"
            />
          </label>
        </div>

        <adl-loaded-item
          [attr.data-cy]="row.name"
          *ngIf="!row.folderCreation"
          [iconSize]="50"
          [loading]="objectNameBeingRenamed === row.name"
        >
          {{ row.name }}
        </adl-loaded-item>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column prop="tenant" name="Data Platform" width="80" [sortable]="false">
      <ng-template let-row="row" ngx-datatable-cell-template>
        <span class="provider azure" *ngIf="row.tenant">
          {{ row.tenant }}
        </span>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column prop="size" name="Size" width="100" [sortable]="explorerPageService.allPageLoaded$ | async">
      <ng-template let-row="row" ngx-datatable-cell-template>
        <adl-loaded-item [iconSize]="50" [loading]="objectNamesBeingDeleted.has(row.name)">
          <span *ngIf="!row.size"> - </span>
          <span *ngIf="row.size">{{ row.size | bytes }}</span>
        </adl-loaded-item>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      prop="lastModified"
      name="Last Modified"
      [sortable]="explorerPageService.allPageLoaded$ | async"
    >
      <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
        <adl-loaded-item
          [iconSize]="50"
          [loading]="objectNamesBeingDeleted.has(row.name) || (isLoadingFolderCreation && rowIndex === 0)"
        >
          <span *ngIf="!row.lastModified"> - </span>
          <span *ngIf="row.lastModified">
            {{ row.lastModified | date : 'dd/MM/yy HH:mm' }}
          </span>
        </adl-loaded-item>
      </ng-template>
    </ngx-datatable-column>

    <ng-container *ngIf="editable">
      <ngx-datatable-column name="See" width="48" minWidth="48" maxWidth="48" [sortable]="false">
        <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template class="j-center">
          <div *ngIf="row.folderCreation" class="df j-center">
            <adl-icon-button
              dpgStopClickBubbling
              (clicked)="saveNewDirectory()"
              icon="check"
              iconType="primary"
            ></adl-icon-button>
          </div>
          <adl-loaded-item
            *ngIf="!row.folderCreation && hasToDisplay(row, 'overview')"
            [iconSize]="50"
            [loading]="
              objectNamesBeingDeleted.has(row.name) ||
              (this.currentlyLoadingOverviewName && this.currentlyLoadingOverviewName === row.name)
            "
            class="df j-center"
          >
            <adl-icon-button
              dpgStopClickBubbling
              (clicked)="openOverview(row)"
              icon="eye"
              [iconType]="this.fileParserAndFormatterService.isMaxSizedOverview(row) ? 'inherit' : 'primary'"
              [disabled]="this.fileParserAndFormatterService.isMaxSizedOverview(row)"
              [hasTooltip]="this.fileParserAndFormatterService.isMaxSizedOverview(row)"
              tooltipMessage="File size exceeds the maximum size"
            >
            </adl-icon-button>
          </adl-loaded-item>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column name="Edit" width="50" minWidth="50" maxWidth="50" [sortable]="false">
        <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
          <div *ngIf="row.folderCreation" class="df j-center">
            <adl-icon-button
              dpgStopClickBubbling
              (clicked)="removeNewDirectory($event)"
              icon="cross"
              iconType="primary"
            ></adl-icon-button>
          </div>
          <adl-loaded-item
            *ngIf="!row.folderCreation && hasToDisplay(row, 'edition')"
            [iconSize]="50"
            [loading]="
              objectNamesBeingDeleted.has(row.name) ||
              (this.currentlyLoadingEditionName && this.currentlyLoadingEditionName === row.name)
            "
            class="df j-center"
          >
            <adl-icon-button
              (clicked)="openEdition(row)"
              dpgStopClickBubbling
              [disabled]="this.fileParserAndFormatterService.isMaxSizedEdit(row)"
              [hasTooltip]="this.fileParserAndFormatterService.isMaxSizedEdit(row)"
              tooltipMessage="File size exceeds the maximum size"
              icon="pencil"
              [iconType]="this.fileParserAndFormatterService.isMaxSizedEdit(row) ? 'inherit' : 'primary'"
            ></adl-icon-button>
          </adl-loaded-item>
        </ng-template>
      </ngx-datatable-column>
    </ng-container>

    <ngx-datatable-column width="42" maxWidth="42" [sortable]="false" id="redirect-btn">
      <ng-template let-row="row" ngx-datatable-cell-template>
        <adl-loaded-item
          [loading]="this.currentlyLoadingFileName && this.currentlyLoadingFileName === row.name"
          [iconSize]="50"
          class="loading"
        >
          <em class="icon-chevron-right c-pointer"></em>
        </adl-loaded-item>
      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>
</div>
