import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import type { IObject, IObjectRow } from '../../entities/objects-browser';

@Component({
  selector: 'dpg-objects-table',
  templateUrl: './objects-table.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ObjectsTableComponent {
  @Input() objects: IObjectRow[] = [];
  @Input() isLoading = false;
  @Input() isBucketSelected = false;
  @Input() areAllObjectsSelected = false;
  @Input() shouldHideCheckboxAll = false;

  @Output() objectClick = new EventEmitter<IObject>();
  @Output() objectSelectionToggle = new EventEmitter<[boolean, IObject]>();
  @Output() toggleAllObjectsSelection = new EventEmitter<boolean>();

  protected readonly displayedColumns: string[] = ['checkbox', 'cloud', 'name', 'tenant'];

  protected trackByKey(_: number, object: IObjectRow) {
    return object.key;
  }
}
