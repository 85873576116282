<div *ngIf="viewMode === 'full'" class="database-section mb-5 flex w-full">
  <div class="w-1/2">
    <h3>{{ type === 'snowflake' ? 'Account' : 'Server' }}</h3>
    <adl-select-v2
      *ngIf="amundsenAccountsDatabases"
      class="db-select mr-8"
      placeholder="No accounts present"
      [clearable]="false"
      [items]="amundsenAccountsDatabases"
      (change)="filterDBList($event)"
      [value]="ngSelectAccount"
      bindLabel="label"
      bindValue="value"
    ></adl-select-v2>
  </div>

  <div class="w-1/2">
    <h3>Database</h3>
    <adl-select-v2
      *ngIf="filteredDatabasesList"
      class="db-select"
      placeholder="No databases present"
      [clearable]="false"
      [items]="filteredDatabasesList"
      (change)="applyAccountDBFilter(ngSelectAccount, $event)"
      [value]="ngSelectDatabase"
      bindLabel="label"
      bindValue="value"
    ></adl-select-v2>
  </div>
</div>

<div *ngIf="viewMode === 'compact'" class="w-full border-bottom compact pb-5 mb-5 flex items-center">
  <div class="w-1/2">
    <div class="mb-4">
      <div class="flex items-center">
        <div
          id="account-select-opener"
          class="compact-title cursor-pointer mr-2"
          (click)="accountSelectOpened = !accountSelectOpened"
        >
          {{ type === 'snowflake' ? 'Account' : 'Server' }}
        </div>
        <adl-icon
          *ngIf="amundsenAccountsDatabases.length > 1"
          class="cursor-pointer"
          icon="chevron-{{ accountSelectOpened ? 'up' : 'down' }}"
          [size]="13"
          (click)="accountSelectOpened = !accountSelectOpened"
        ></adl-icon>
      </div>

      <ng-select
        class="db-select"
        [placeholder]="'No ' + (type === 'snowflake' ? 'accounts' : 'servers') + ' present'"
        bindLabel="label"
        bindValue="value"
        [isOpen]="accountSelectOpened"
        [items]="amundsenAccountsDatabases"
        [clearable]="false"
        (change)="filterDBList($event.value); accountSelectOpened = false"
        [(ngModel)]="ngSelectAccount"
      ></ng-select>
      <span class="w-100 compact-db-name">
        {{ amundsenAccountNameMapping[ngSelectAccount] || ngSelectAccount | uppercase }}
      </span>
    </div>

    <div class="mb-4">
      <div class="flex items-center">
        <div id="db-select-opener" class="compact-title cursor-pointer mr-2" (click)="dbSelectOpened = !dbSelectOpened">
          Database
        </div>
        <adl-icon
          *ngIf="filteredDatabasesList.length > 1"
          class="cursor-pointer"
          icon="chevron-{{ dbSelectOpened ? 'up' : 'down' }}"
          [size]="13"
          (click)="dbSelectOpened = !dbSelectOpened"
        ></adl-icon>
      </div>

      <ng-select
        class="db-select"
        placeholder="No databases present"
        bindLabel="label"
        bindValue="value"
        [isOpen]="dbSelectOpened"
        [clearable]="false"
        [items]="filteredDatabasesList"
        (change)="applyAccountDBFilter(ngSelectAccount, $event.value); dbSelectOpened = false"
        [(ngModel)]="ngSelectDatabase"
      ></ng-select>
      <span class="compact-db-name">{{ ngSelectDatabase | uppercase }}</span>
    </div>
  </div>

  <div class="w-1/2 flex justify-end">
    <adl-button type="data-catalog" class="open-btn" size="thin" (click)="openAmundsenDataAsset(type)">
      {{ type === 'snowflake' ? 'SEE DATA AS A PRODUCT' : 'SEE SQL DB' }}
    </adl-button>
  </div>
</div>

<div *ngIf="currentDatabase && viewMode === 'full'" class="folders-section" [class.compact]="viewMode === 'compact'">
  <h3>Schemas ({{ currentDatabase.schemas.length }})</h3>
  <adl-card>
    <div
      *ngFor="let cat of currentDatabase.schemas; let i = index"
      class="schema-row w-full"
      [class.bordered]="i !== currentDatabase.schemas.length - 1"
    >
      <div
        class="cursor-pointer"
        (click)="toggleCollapseCategory(cat.schemaName)"
        [class.mt-3]="i !== 0"
        [class.mb-3]="i !== currentDatabase.schemas.length - 1"
      >
        <div class="flex justify-between items-center">
          <div class="flex">
            <img
              class="mr-3"
              alt="snowflake icon"
              src="assets/modules/data-catalog/imgs/{{ type === 'snowflake' ? 'snowflake-v2' : 'mssql' }}-icon.svg"
            />
            <span class="schema-title">{{ cat.schemaName }}</span>
          </div>
          <adl-icon
            icon="chevron-{{ expandedStatusCategories[cat.schemaName] ? 'up' : 'down' }}"
            [size]="16"
          ></adl-icon>
        </div>
        <div class="schema-subtitle">{{ cat.tables.length }} view{{ cat.tables.length > 1 ? 's' : '' }}</div>
      </div>
      <div class="collapsable-content" [@collapse]="!expandedStatusCategories[cat.schemaName]">
        <div *ngFor="let table of cat.tables; let i = index" class="mb-2">
          <div class="w-full mb-3 cursor-pointer" [class.mt-3]="i === 0">
            <div class="flex">
              <adl-radio
                class="mr-3"
                name="table"
                [value]="table.fullPath"
                (changed)="selectedTable$.next($event)"
                radioStyle="new"
              >
                <span class="schema-title cursor-pointer">{{ table.name }}</span>
                <div class="guardian-icon-container flex items-center justify-center">
                  <div
                    *ngIf="this.snowflakeTableGuardianChecksStatusMap.get(table.fullPath) as guardianStatus"
                    class="guardian-shield-icon"
                  >
                    <adl-tooltip *ngIf="guardianStatus.latestStatus === 'succeed'" backgroundColor="primary-800" arrowColor="primary-800">
                      <div tooltip-content class="p-3 tooltip-content">
                        <div class="flex items-center mb-4">
                          <dd class="circular-question-mark rounded-full font-bold">?</dd>
                          <h4>GUARDIAN STATUS</h4>
                        </div>
                        <p>Last run OK - {{ guardianStatus.statusDate }} ({{ guardianStatus.timeZone }})</p>
                      </div>

                      <img
                        tooltip-trigger
                        alt="guardian shield ok"
                        [src]="
                          guardianStatus.isScheduled
                            ? '/assets/dpg/imgs/icons/guardian-green-scheduled.svg'
                            : '/assets/dpg/imgs/icons/guardian-shield-green.svg'
                        "
                        (click)="openGuardianCheckModal(table.fullPath, guardianStatus.checkStatus)"
                      />
                    </adl-tooltip>

                    <adl-tooltip
                      *ngIf="guardianStatus.latestStatus === 'errored' || guardianStatus.latestStatus === 'crashed'"
                      backgroundColor="primary-800"
                      arrowColor="primary-800"
                    >
                      <div tooltip-content class="p-3 tooltip-content">
                        <div class="flex items-center mb-4">
                          <dd class="circular-question-mark rounded-full font-bold">?</dd>
                          <h4>GUARDIAN STATUS</h4>
                        </div>
                        <p>Last run KO - {{ guardianStatus.statusDate }} ({{ guardianStatus.timeZone }})</p>
                      </div>

                      <img
                        tooltip-trigger
                        alt="guardian shield ko"
                        [src]="
                          guardianStatus.isScheduled
                            ? '/assets/dpg/imgs/icons/guardian-red-scheduled.svg'
                            : '/assets/dpg/imgs/icons/guardian-shield-red.svg'
                        "
                        (click)="openGuardianCheckModal(table.fullPath, guardianStatus.checkStatus)"
                      />
                    </adl-tooltip>

                    <adl-tooltip
                      *ngIf="!guardianStatus.latestStatus"
                      backgroundColor="primary-800"
                      arrowColor="primary-800"
                    >
                      <div tooltip-content class="p-3 tooltip-content">
                        <div class="flex items-center mb-4">
                          <dd class="circular-question-mark rounded-full font-bold">?</dd>
                          <h4>GUARDIAN STATUS</h4>
                        </div>
                        <p>Guardian check configured but no files have been uploaded to it yet</p>
                      </div>

                      <img
                        tooltip-trigger
                        alt="guardian shield pending"
                        [src]="
                          guardianStatus.isScheduled
                            ? '/assets/dpg/imgs/icons/guardian-blue-scheduled.svg'
                            : '/assets/dpg/imgs/icons/guardian-shield-blue.svg'
                        "
                        (click)="openGuardianCheckModal(table.fullPath, guardianStatus.checkStatus)"
                      />
                    </adl-tooltip>
                  </div>
                </div>
              </adl-radio>
            </div>

            <div *ngIf="tableInformation[table.fullPath]" class="schema-subtitle">
              {{ displayTableStatus(tableInformation[table.fullPath]) }}
            </div>
          </div>

          <div *ngIf="expandedStatusTables[table.fullPath]" class="table-properties opened-full">
            <ng-container
              *ngIf="
                !tableInformation[table.fullPath].loadingMetadata && !tableInformation[table.fullPath].errorMetadata
              "
            >
              <ng-container *ngIf="type === 'snowflake'">
                <div class="title mb-3">Table description</div>
                <div class="table-desc mb-5">
                  {{ tableInformation[table.fullPath]?.description }}
                </div>
              </ng-container>

              <div class="flex justify-between items-center mb-4">
                <adl-button
                  *ngIf="source.allowAmundsenPreview"
                  [notClickable]="
                    tableInformation[table.fullPath].loadingPreview || tableInformation[table.fullPath].previewError
                  "
                  [disabled]="
                    tableInformation[table.fullPath].loadingPreview || tableInformation[table.fullPath].previewError
                  "
                  class="preview-btn"
                  (clicked)="
                    tableInformation[table.fullPath].preview
                      ? openTablePreviewModal(table.name, tableInformation[table.fullPath].preview)
                      : loadTablePreview(table.fullPath)
                  "
                >
                  <span class="mr-3">
                    {{ previewBtnDescription(tableInformation[table.fullPath]) }}
                  </span>
                  <adl-loaded-item class="preview-loading" [loading]="tableInformation[table.fullPath].loadingPreview">
                    <img
                      *ngIf="!tableInformation[table.fullPath].previewError"
                      class="btn-eye-icon"
                      alt="eye icon"
                      src="/assets/modules/data-catalog/imgs/eye-icon.svg"
                    />
                    <adl-icon *ngIf="tableInformation[table.fullPath].previewError" icon="warning"></adl-icon>
                  </adl-loaded-item>
                </adl-button>

                <div>
                  <div class="title">Last modified</div>
                  <div class="subtitle">{{ tableInformation[table.fullPath]?.lastModified }}</div>
                </div>
              </div>

              <div class="title mb-2">Metadata</div>
              <dpg-catalog-amundsen-data-table
                viewMode="details"
                [tableMetaData]="tableInformation[table.fullPath].colData"
              >
              </dpg-catalog-amundsen-data-table>
            </ng-container>

            <span *ngIf="tableInformation[table.fullPath].loadingMetadata" class="table-desc">
              Metadata is loading.
            </span>

            <span *ngIf="tableInformation[table.fullPath].errorMetadata" class="table-desc">
              {{ tableInformation[table.fullPath].errorMetadataMessage || 'Error loading metadata.' }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </adl-card>
</div>

<div
  *ngIf="currentDatabase && viewMode === 'compact'"
  class="border-bottom pb-3 mb-3"
  [class.compact]="viewMode === 'compact'"
>
  <h3>Schemas ({{ currentDatabase.schemas.length }})</h3>
  <div *ngFor="let cat of currentDatabase.schemas.slice(0, 5); let i = index" class="schema-row w-full mb-2">
    <div class="cursor-pointer" (click)="toggleCollapseCategory(cat.schemaName)">
      <div class="flex justify-between items-center">
        <div class="flex items-center w-11/12">
          <img
            class="mr-3 snowflake-icon"
            alt="snowflake icon"
            src="assets/modules/data-catalog/imgs/{{ type === 'snowflake' ? 'snowflake-v2' : 'mssql' }}-icon.svg"
          />
          <span class="schema-title">{{ cat.schemaName }}</span>
        </div>
        <adl-icon icon="chevron-{{ expandedStatusCategories[cat.schemaName] ? 'up' : 'down' }}" [size]="14"></adl-icon>
      </div>
    </div>
    <div [@collapse]="!expandedStatusCategories[cat.schemaName]" class="schema-collapse-content">
      <div
        *ngFor="let table of cat.tables; let i = index"
        class="p-2"
        [class.mb-3]="i !== cat.tables.length - 1"
        [class.table-entry-bordered]="selectedTable$.getValue() === table.fullPath"
      >
        <div class="w-full cursor-pointer flex items-center">
          <adl-radio name="table" [value]="table.fullPath" (changed)="selectedTable$.next($event)" radioStyle="new">
            <span class="schema-title cursor-pointer">{{ table.name }}</span>
          </adl-radio>
        </div>
        <div
          *ngIf="!tableInformation[table.fullPath]?.loadingMetadata && !tableInformation[table.fullPath]?.errorMetadata"
          class="table-properties flex justify-between"
          [class.opened-compact]="expandedStatusTables[table.fullPath]"
        >
          <div>
            <div class="title">Last modified</div>
            <div class="subtitle">
              {{ tableInformation[table.fullPath]?.lastModified }}
            </div>
          </div>
        </div>
        <span *ngIf="tableInformation[table.fullPath]?.loadingMetadata">Loading metadata</span>
        <span *ngIf="tableInformation[table.fullPath]?.errorMetadata">Error loading metadata</span>
      </div>
    </div>
  </div>

  <div
    *ngIf="currentDatabase.schemas.length > 5"
    class="see-more cursor-pointer w-full text-center"
    (click)="openDataAsset()"
  >
    See more
  </div>
</div>
