import { Entity } from '@decahedron/entity';

export interface ILimitedUser {
  id: string; // pk
  name: string;
  jobTitle: string;
}

export class LimitedUser extends Entity {
  id: string = null;
  name: string = null;
  jobTitle: string = null;

  // Methods
  fromJson(json: ILimitedUser): LimitedUser {
    this.id = json.id;
    this.name = json.name;
    this.jobTitle = json.jobTitle;

    return this;
  }

  toJson() {
    return {
      id: this.id,
      name: this.name,
      jobTitle: this.jobTitle,
    };
  }

  get displayName(): string {
    return this.name ? `${this.name} (${this.id})` : this.id;
  }

  isJcd() {
    return this.id.endsWith('@jcdecaux.com');
  }

  isPr() {
    return this.id.endsWith('@pernod-ricard.com');
  }

  isNeoxia() {
    return this.id.endsWith('@neoxia.com');
  }

  isAccor() {
    return ['@accor.com', '@consulting-for.accor.com'].some(domain => this.id.endsWith(domain));
  }

  isIntern() {
    // TODO: should be based on environment
    return this.isPr() || this.isJcd() || this.isNeoxia() || this.isAccor();
  }
}
