import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import type { IObject } from '../../entities/objects-browser';

@Component({
  selector: 'dpg-selected-object',
  templateUrl: './selected-object.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectedObjectComponent {
  @Input() selectedObject: IObject | null;

  @Input() canBeNavigatedTo = false;

  @Output() navigateTo = new EventEmitter<void>();
  @Output() deleteObject = new EventEmitter<void>();

  protected onObjectDelete(event: MouseEvent): void {
    event.stopPropagation();

    this.deleteObject.emit();
  }
}
