<div class="powerbi-navmob" *ngIf="shouldDisplay">
  <div class="powerbi-navmob-wrapper">
    <select [(ngModel)]="currentPageName" (ngModelChange)="handleCurrentPageChange($event)">
      <option value="-1" disabled>Select a page</option>
      <option *ngFor="let page of pages" [ngValue]="page.name">{{ page.displayName }}</option>
    </select>
    <div class="select-content truncate-parent">
      <span class="select-label truncate">{{ currentPageLabel }}</span>
      <span class="select-icon"></span>
    </div>
  </div>
</div>
