<adl-title class="text-center" type="modal">File : {{ selectedCheck.fileName }}</adl-title>
<adl-table [hasRoundedBorder]="true" headColor="white" bodyColor="white" aria-label="Guardian checks" class="mt-8">
  <ng-container table-header>
    <th class="w-[20%]">Column</th>
    <th class="w-[20%]">Error type</th>
    <th class="w-[10%]">Number of errors</th>
    <th class="w-[25%]">Examples</th>
    <th class="w-[25%]">Row ID of the examples</th>
  </ng-container>
  <ng-container table-body>
    <tr *ngFor="let row of selectedCheck.errors" class="cursor-pointer">
      <td class="w-[20%] break-all">{{ row.column }}</td>
      <td class="w-[20%] break-all">{{ getDisplayedErrorFromErrorCode(row.label) }}</td>
      <td class="w-[10%] break-all">{{ row.count }}</td>
      <td class="w-[25%] break-all">
        {{ getDisplayedIncorrectValuesFromErrorCodeAndValues(row.label, row.incorrectValues) }}
      </td>
      <td class="w-[25%]">{{ getDisplayedIncorrectLines(row.incorrectLines) }}</td>
    </tr>
  </ng-container>
</adl-table>
