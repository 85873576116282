<adl-modal
  class="stepper-modal"
  [isAlignedToCross]="true"
  [hasMarginBottom]="false"
  [hasBodySidesPadding]="false"
  [roundCorner]="true"
  headerBackgroundColor="blue-dark"
  backgroundColor="light"
  closeButtonColor="white"
>
  <adl-logo-modal-header
    modal-header
    class="w-full"
    hasStepper="true"
    [title]="title"
    [secondaryTitle]="secondaryTitle"
    [iconLink]="iconLink"
    [stepLabels]="stepLabels"
    [steps]="steps"
    [currentStep]="currentStep"
  ></adl-logo-modal-header>
  <div modal-body class="modal-body-stepper row-flex w-full mb-0">
    <section class="scrollbar w-full">
      <div class="w-full">
        <adl-loaded-item class="w-full" [loading]="isLoading">
          <ng-container *ngIf="!isLoading">
            <ng-container [ngSwitch]="currentStep">
              <ng-content *ngSwitchCase="'1'" select="[modal-step-1]"></ng-content>
              <ng-content *ngSwitchCase="'2'" select="[modal-step-2]"></ng-content>
              <ng-content *ngSwitchCase="'3'" select="[modal-step-3]"></ng-content>
            </ng-container>
          </ng-container>
        </adl-loaded-item>
      </div>
      <div class="stepper-modal-buttons row-flex-not-responsive justify-center items-center mt-[20px]">
        <adl-button (clicked)="this.goPreviousStep()" type="dark-cancel">
          {{ this.currentStep !== 1 ? 'Go back' : 'Cancel' }}
        </adl-button>
        <adl-button type="primary-dark" [disabled]="isNextButtonDisable" (clicked)="this.goNextStep(false)">{{
          this.currentStep === this.nbSteps ? lastStepButton : 'Continue'
        }}</adl-button>
        <adl-button
          type="primary-dark"
          *ngIf="this.currentStep === this.nbSteps && secondaryLastStepButton?.length"
          [isSquareButton]="true"
          (clicked)="this.goNextStep(true)"
          >{{ secondaryLastStepButton }}</adl-button
        >
        <div *ngIf="this.currentStep === this.nbSteps" class="dp-admin--penter">
          or
          <span>
            <img src="assets/dpg/imgs/icons/step-enter.svg" alt="step-enter" />
            Enter</span
          >
        </div>

        <ng-content select="[modal-footer-additional]"></ng-content>
      </div>
    </section>
  </div>
</adl-modal>
