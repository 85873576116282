import type { OnInit } from '@angular/core';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormControl } from '@ngneat/reactive-forms';

@Component({
  selector: 'adl-post-comment-item',
  templateUrl: './post-comment-item.component.html',
  styleUrls: ['./post-comment-item.component.scss'],
})
export class PostCommentItemComponent implements OnInit {
  static EDIT_MESSAGE_ZONE_HEIGHT_IN_PX = 90;

  /* Inputs */
  @Input() pictureSrc: SafeResourceUrl;
  @Input() isLoadingPicture = false;
  @Input() isInputZoneDisabled = false;
  @Input() hasToDisabledButtons = false;
  @Input() cancelBtnText = 'Cancel';
  @Input() validateBtnText = 'Send';
  @Input() isSmallerItem = false;
  @Input() clearInput$: ReplaySubject<void>;
  @Input() setCursorAtTheEndOfEditZone$: ReplaySubject<void>;
  /* Outputs */
  @Output() onClickCancel = new EventEmitter<boolean>();
  @Output() onClickValidate = new EventEmitter<string>();

  postMessageInput = new FormControl<string>('');

  private readonly _destroyed$ = new Subject<void>();

  ngOnInit() {
    this.clearInput$?.pipe(takeUntil(this._destroyed$)).subscribe(() => {
      this.postMessageInput?.setValue('');
    });
  }

  ngOnDestroy() {
    this._destroyed$.next();
  }

  get editMessageZoneHeightInPx(): string {
    return `${
      this.isSmallerItem
        ? PostCommentItemComponent.EDIT_MESSAGE_ZONE_HEIGHT_IN_PX / 2
        : PostCommentItemComponent.EDIT_MESSAGE_ZONE_HEIGHT_IN_PX
    }px`;
  }

  cancel(): void {
    if (this.hasToDisabledButtons) {
      return;
    }

    this.onClickCancel.emit(true);
  }

  validate(): void {
    if (this.hasToDisabledButtons) {
      return;
    }

    const message = this.postMessageInput?.value || '';
    this.onClickValidate.emit(message);
  }
}
