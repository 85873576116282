import { ChangeDetectionStrategy, Component, Inject, inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import type { Observable } from 'rxjs';
import { first, map, shareReplay, startWith, tap } from 'rxjs/operators';
import { DialogsService } from '@dataportal/adl';
import { DatalakeOnlyProvider } from '@dataportal/datalake-parsing';
import { EnvironmentService } from '@dataportal/front-environment';
import { DatalakePath } from '@dataportal/front-shared';

import { ObjectsBrowserFacade } from '../../services/objects-browser-facade.service';

import { CantFindYourFolderModalComponent } from '../cant-find-your-folder-modal/cant-find-your-folder-modal.component';

import type { IObject } from '../../entities/objects-browser';

@Component({
  selector: 'dpg-datalake-object-selector-modal-v2',
  templateUrl: './datalake-object-selector-modal-v2.component.html',
  providers: [ObjectsBrowserFacade],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatalakeObjectSelectorModalV2Component {
  private readonly _dialogServices = inject(DialogsService);
  private readonly _activeMatModal = inject(MatDialogRef);
  private readonly _environmentService = inject(EnvironmentService);
  private readonly _objectsBrowserFacade = inject(ObjectsBrowserFacade);

  @Input() selectedObjects: IObject[] = [];
  @Input() datalakeProvider: DatalakeOnlyProvider;

  protected title = 'Data Lake';
  protected isPr = false;
  protected canSubmit$: Observable<boolean> = this._objectsBrowserFacade.selectedObjects$.pipe(
    map((selectedObjects) => selectedObjects.length > 0),
    startWith(false),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  constructor(
    @Inject(MAT_DIALOG_DATA)
    data: {
      isSelectingFiles: boolean;
      isMultipleSelect: boolean;
      isListingUsingOwnToken: boolean;
      defaultFolder: IObject;
      selectedObjects: IObject[];
      doNotPreCharge: boolean;
      title: string;
      datalakeProvider: DatalakeOnlyProvider;
    },
  ) {
    this.isPr = this._environmentService?.options.dpContext.code === 'PR';

    this.selectedObjects = data.selectedObjects ?? [];

    this.title = data.title || this.title;

    this._objectsBrowserFacade.setProviderFilter(data.datalakeProvider);
  }

  openCantFindMyFolderModal(): void {
    this._dialogServices.open(
      CantFindYourFolderModalComponent,
      {},
      {
        width: '1050px',
        maxHeight: '98vh',
      },
    );
  }

  cancel(): void {
    this._activeMatModal.close();
  }

  confirm(): void {
    this._objectsBrowserFacade.selectedObjects$
      .pipe(
        first(),
        map((selectedObjects) => selectedObjects.map((object) => this._selectedObjectToDatalakePath(object))),
        tap((datalakePaths) => {
          this._activeMatModal.close(datalakePaths);
        }),
      )
      .subscribe();
  }

  private _selectedObjectToDatalakePath(selectedObject: IObject): DatalakePath {
    return new DatalakePath(this._buildFolderPath(selectedObject), selectedObject.provider, selectedObject.tenant);
  }

  private _buildFolderPath(folder: IObject): string {
    const builtPath = folder.filesystem.name;

    return builtPath + (folder.path ? `/${folder.path}/` : '/') + folder.name;
  }
}
