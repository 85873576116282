<label class="wrapper cursor-pointer">
  <input
    type="radio"
    [name]="name"
    [value]="value"
    [checked]="checked"
    (change)="handleChange($event)"
    (click)="handleClick()"
  />

  <span class="radio" [class.checked]="checked" [class.new]="radioStyle === 'new'">
    <span class="w100 h100"></span>
  </span>

  <ng-content></ng-content>
</label>
