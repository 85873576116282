<div class="filters-container">
  <div class="header-section pb10">
    <div class="df a-center j-space-between">
      <div class="title df">
        <adl-image
          class="pr30"
          [src]="'assets/modules/data-catalog/imgs/filter-icon.svg'"
          [alt]="'filter-icon'"
          [size]="'20px'"
          [shape]="'square'"
          [equalizeHeight]="true"
        >
        </adl-image>
        <span>Filter</span>
      </div>

      <a class="option-btn underline" (click)="clearAllFilters()"> Clear all </a>
    </div>
  </div>

  <ng-container *ngFor="let filter of filters; let index = index">
    <div
      class="filters-section"
      *ngIf="(filter.advancedFilter && filter.filterItems?.length > 0) || !filter.advancedFilter"
    >
      <div class="filter-header flex flex-row items-center justify-between">
        <div class="flex flex-row items-center">
          <p class="sub-title c-pointer bold pr10" (click)="filter.open = !filter.open">
            {{ filter.title }}
          </p>

          <adl-icon-button
            class="mr-4"
            (click)="filter.open = !filter.open"
            [size]="12"
            [icon]="filter.open ? 'chevron-up' : 'chevron-down'"
          >
          </adl-icon-button>
        </div>

        <a class="option-btn underline" (click)="clearFilterSelection(filter)"> Clear </a>
      </div>

      <ng-container *ngIf="filter.open">
        <div class="search-section pr2 pl2 pb15" *ngIf="filter.searchPlaceholder">
          <adl-search-bar
            [control]="filter.control"
            [placeholder]="filter.searchPlaceholder"
            icon="search"
            iconType="grey"
          >
          </adl-search-bar>
        </div>

        <div *ngIf="filter.searchPlaceholder && hasCheckedItem(filter.filterItems)" class="selected-affiliate mb-2">
          Selected {{ filter.title | titlecase }}
        </div>

        <adl-loaded-item [loading]="loading" [iconSize]="100">
          <adl-filter
            [filters]="filter.filterItems"
            [filterType]="filter.type"
            (filtersChange)="(filter.filterItems = $event) && updateFilters()"
            [displayCount]="getFilterDisplayCount(filter)"
            [advancedFilter]="filter.advancedFilter"
            [filterGroupName]="filter.title"
          ></adl-filter>
        </adl-loaded-item>

        <span *ngIf="!filter.filterItems?.length && !loading" class="p-10 flex justify-center">No match.</span>

        <a
          class="option-btn underline pt10"
          *ngIf="filter.filterItems.length > (filter.displayCount || 5)"
          (click)="filter.displayMore = !filter.displayMore"
        >
          {{ filter.displayMore ? 'Hide' : 'See more' }}
        </a>
      </ng-container>
    </div>
  </ng-container>

  <div class="pt-8 option-btn" *ngIf="advancedFilterAction" (click)="advancedFilterAction.next()">
    <a> See more filters </a> ->
  </div>
</div>
