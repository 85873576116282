<iframe
  [ngClass]="{ 'w-full': !showMiniatures, 'w-3/4': showMiniatures }"
  [src]="selectedVideo?.url | safeUrl"
  frameborder="0"
></iframe>

<ul *ngIf="showMiniatures" class="flex flex-col">
  <li *ngFor="let video of videosList" class="video__item flex flex-col">
    <span> {{ video.title }} </span>
    <iframe [src]="video.url | safeUrl"> </iframe>
    <div class="video__item__overlay" (click)="selectVideo($event, video)"></div>

    <adl-tooltip *ngIf="checkViews">
      <adl-checkbox
        tooltip-trigger
        *ngIf="video.id"
        shape="circle"
        checkboxColor="green"
        [markAsView]="{
          documentationId: documentationId,
          videoId: video.id
        }"
        (markAsViewChanged)="onVideoMarkAsViewed($event)"
      ></adl-checkbox>

      <adl-text tooltip-content color="white">Mark as viewed</adl-text>
    </adl-tooltip>
  </li>
</ul>
