<ng-template #hiddenSidebar>
  <adl-button class="toggle-button fixed mt-6 ml-6" (clicked)="showSidebar()">
    <adl-icon icon="menu"></adl-icon>
  </adl-button>
</ng-template>
<adl-sidebar-v3 *ngIf="isVisible$ | async; else hiddenSidebar" [ngClass]="{ developed: isDeveloped$ | async }">
  <div class="top-logo flex items-center justify-center w-full">
    <img alt="company-logo" ngSrc="assets/bar-item-icons/company.svg" width="20" height="20" />
  </div>
  <div class="eye-logo items-center justify-center w-full" (click)="hideSidebar()">
    <img alt="company-logo" ngSrc="assets/bar-item-icons/crossed-eye.svg" width="20" height="20" />
  </div>
  <ng-container *ngFor="let item of items">
    <dpg-navbar-item-v2 [item]="item" [external]="external"></dpg-navbar-item-v2>
  </ng-container>
  <div *ngIf="favorites?.length" class="favorites-delimiter mx-4 my-6">My favorites</div>
  <ng-container *ngFor="let item of favorites">
    <dpg-navbar-item-v2
      [item]="item"
      [external]="external"
      [ngClass]="{ 'favorite-logo': environmentService?.options.dpContext.code === 'PR' }"
    ></dpg-navbar-item-v2>
  </ng-container>
  <dpg-admin-button-v2
    *ngIf="canSeeAdmin"
    [external]="external"
    [ngClass]="{
      'developed': isDeveloped$ | async,
      'no-favorite': favorites.length === 0,
      'one-favorite': favorites.length === 1,
      'two-favorite': favorites.length === 2,
      'three-favorite': favorites.length === 3
    }"
  ></dpg-admin-button-v2>
</adl-sidebar-v3>
