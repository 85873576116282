<adl-modal
  class="adl-dialog-modal"
  headerBackgroundColor="blue-dark"
  backgroundColor="light"
  [isAlignedToCross]="true"
  [roundCorner]="false"
  closeButtonColor="white"
  textAlignment="left"
  data-cy="datalake-path-selector"
>
  <header modal-header class="flex gap-[20px] px-[54px] py-[20px] items-center w-[1000px]">
    <img src="assets/dpg/imgs/icons/datalake-logo.svg" class="w-[27px] h-[37px]" />
    <div>
      <adl-title type="modal" color="#ffffff">{{ title }}</adl-title>
      <!--      TODO: don't hardcode-->
      <adl-text [size]="12" color="white" class="mt-[2px]">Select the folder containing your source files</adl-text>
    </div>

    <adl-button
      *ngIf="isPr"
      class="open-missing-folder-modal-button ml-auto"
      (clicked)="openCantFindMyFolderModal()"
      type="cancel"
      >Can't find your folder?
    </adl-button>
  </header>

  <main modal-body>
    <dpg-objects-browser [selectedObjects]="selectedObjects"></dpg-objects-browser>
  </main>
  <div class="row-flex j-center" modal-footer>
    <adl-button type="secondary" (clicked)="cancel()">Cancel</adl-button>
    <adl-button class="ml10" (clicked)="confirm()" [disabled]="(canSubmit$ | async) === false"
      >Confirm and Save</adl-button
    >
  </div>
</adl-modal>
