import type { IGroupJoin } from '@dataportal/types';
import { Entity } from '@decahedron/entity';

export class GroupMember extends Entity {
  constructor(element?: { groupId: string; userId: string; name: string }) {
    super();

    if (element) {
      this.groupId = element.groupId;
      this.userId = element.userId;
      this.name = element.name;
    }
  }

  groupId: string = null;
  userId: string = null;
  name: string = null;

  fromJson(data: IGroupJoin) {
    this.groupId = data.pk;
    this.name = data.name;
    this.userId = data.user_id;
  }

  toJson(): any {
    return {
      name: this.name,
      pk: this.userId,
    };
  }
}
