import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { forkJoin } from 'rxjs';
import { ApiService } from '@dataportal/front-api';
import { Logger } from '@dataportal/front-shared';
import { PortalsService } from '@dataportal/portals';
import { UsersService } from '@dataportal/users';

import type { IMinimalUser, Profile } from '../entities/profile';
import type { AvailableCover, UserPreferences } from '../entities/user-preferences';

@Injectable()
export class ProfileService {
  protected url = '/v4/profile';

  constructor(
    private readonly _apiService: ApiService,
    private readonly _usersService: UsersService,
    private readonly _portalsService: PortalsService,
    private readonly _logger: Logger,
  ) {}

  getProfile(): Observable<Profile> {
    this._logger.debug('[ProfileService] Getting profile"');

    return this._apiService.get<Profile>(this.url);
  }

  getUserPreferences(): Observable<UserPreferences> {
    this._logger.debug('[ProfileService] Getting user preferences');

    return this._apiService.get<UserPreferences>(this.url + '/preference');
  }

  putUserPreferences(categories: string[], newsletter: boolean, cover: string): Observable<UserPreferences> {
    this._logger.debug('[ProfileService] Put user preferences');

    return this._apiService.put<UserPreferences>(this.url + '/preference', {
      categories: categories,
      newsletter: newsletter,
      cover: cover,
    });
  }

  getAvailableCovers(): Observable<AvailableCover[]> {
    this._logger.debug('[ProfileService] Getting available covers');

    return this._apiService.get<AvailableCover[]>('v4/profile/available-covers');
  }

  handlePortals(profile: Profile): IMinimalUser[] {
    if (profile.portal?.owners?.length) {
      return profile.portal?.owners;
    } else {
      const portalParent = this._portalsService.findByName(profile.portal?.name)?.parent;

      if (portalParent) {
        const portalOwners: IMinimalUser[] = [];
        forkJoin(
          this._portalsService.findOne(portalParent?.id)?.owners.map((owner) => this._usersService.getProfile(owner)),
        ).subscribe((ownerInformation) => {
          ownerInformation.map((owner) =>
            portalOwners.push({
              pk: owner?.email,
              name: owner?.name,
            }),
          );
        });

        return portalOwners;
      }
    }
  }
}
