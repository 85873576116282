<div
  class="adl-input"
  [class]="variant"
  [ngClass]="{ errored: hasError, succeeded: succeeded, border: hasBorder, rounded: isRounded }"
  [ngStyle]="{ height: fixedHeight, width: fixedWidth + ' !important' }"
>
  <ng-container *ngIf="iconAsPrefix && !customContent">
    <adl-icon
      *ngIf="!clickableIcon"
      class="flex mx-2 mb-0.5"
      [icon]="icon"
      [iconType]="iconType"
      [style.padding-left]="horizontalPadding"
    ></adl-icon>
    <adl-icon-button
      *ngIf="clickableIcon"
      class="flex mx-2 mb-0.5"
      [icon]="icon"
      [iconType]="iconType"
      [style.padding-left]="horizontalPadding"
      (clicked)="onClickIcon()"
    ></adl-icon-button>
  </ng-container>
  <div *ngIf="customContent" class="flex mr-2">
    <ng-content select="[prefix]"></ng-content>
  </div>

  <input
    #nameInput
    [ngClass]="{ disabled: isDisabled }"
    [attr.disabled]="isDisabled ? '' : null"
    [style.padding-left]="horizontalPadding"
    [style.padding-right]="horizontalPadding"
    [style.font-size]="fontSize"
    [ngStyle]="styles"
    [type]="type"
    [formControl]="control"
    [placeholder]="placeholder"
    [readonly]="readonly"
    [step]="step"
    [min]="min"
    [max]="max"
    [value]="value"
    (blur)="isAutoTrim && autoTrim()"
  />

  <div *ngIf="customContent" class="row-flex mr5">
    <ng-content class="row-flex mr5" select="[suffix]"></ng-content>
  </div>
  <ng-container *ngIf="!iconAsPrefix && !customContent && icon">
    <adl-icon *ngIf="!clickableIcon" class="row-flex ml5 mr5 mb-0.5" [icon]="icon" [iconType]="iconType"></adl-icon>
    <adl-icon-button
      *ngIf="clickableIcon"
      class="row-flex ml5 mr5 mb-0.5"
      [icon]="icon"
      [iconType]="iconType"
      (clicked)="onClickIcon()"
    ></adl-icon-button>
  </ng-container>
</div>
