import { Component } from '@angular/core';
import { GoogleTagManagerService } from '@dataportal/analytics';

import { environment } from '../../environments/environment';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss'],
})
export class SupportComponent {
  documentationLink = environment.documentationLink;
  supportLink =
    'https://pernod-ricard--bmcservicedesk.eu19.visual.force.com/apex/bmcservicedesk__ssredirect?type=tile&id=newsr&categoryId=a2T1i000000Y0rv';
  mailtoAddress = 'mailto: data.support@pernod-ricard.com';

  constructor(private readonly _gtmService: GoogleTagManagerService) {}

  openContact(): void {
    this._gtmService.pushEvent({ event: 'helpClickContactBtn' });
    window.open(this.mailtoAddress, '_blank');
  }

  openDocumentation(): void {
    this._gtmService.pushEvent({ event: 'helpClickUserManualBtn' });
    window.open(this.documentationLink, '_blank');
  }

  openSupport(): void {
    this._gtmService.pushEvent({ event: 'helpClickSupportBtn' });
    window.open(this.supportLink, '_blank');
  }
}
