import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { merge } from 'rxjs';
import { mergeMap, skip } from 'rxjs/operators';
import { ApiService } from '@dataportal/front-api';

@Injectable()
export class GlossaryRolesService {
  private readonly _glossaryAdminAPIUrl = '/v4/admin/glossary';

  constructor(private readonly _apiService: ApiService) {}

  private _getGlossaryPermissionsWithGroupId(groupId: string): string {
    return `${this._glossaryAdminAPIUrl}/${groupId}/permissions`;
  }

  // Methods
  getAll(): Observable<any> {
    return this._apiService.get(`${this._glossaryAdminAPIUrl}/permissions`);
  }

  grantUserRole(groupId: string, userId: string, role: any): Observable<void> {
    return this._apiService.post(this._getGlossaryPermissionsWithGroupId(groupId), {
      user_id: userId,
      role,
    });
  }

  revokeUserRole(groupId: string, userId: string, role: any): Observable<void> {
    return this._apiService.delete(this._getGlossaryPermissionsWithGroupId(groupId), {
      body: {
        user_id: userId,
        role,
      },
    });
  }

  revokeUserRoles(groupId: string, userId: string, roles: any[]): Observable<void> {
    const revokes = roles.map((role) => this.revokeUserRole(groupId, userId, role));

    return merge(revokes).pipe(
      mergeMap((revoke) => revoke, 1),
      skip(revokes.length - 1),
    );
  }

  grantGroupRole(groupId: string, role: any): Observable<void> {
    return this._apiService.post(this._getGlossaryPermissionsWithGroupId(groupId), {
      group_id: groupId,
      role,
    });
  }

  revokeGroupRole(groupId: string, role: any): Observable<void> {
    return this._apiService.delete(this._getGlossaryPermissionsWithGroupId(groupId), {
      body: {
        group_id: groupId,
        role,
      },
    });
  }
}
