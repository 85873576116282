import type { OnInit } from '@angular/core';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { Logger } from '@dataportal/front-shared';
import { NgSelectComponent } from '@ng-select/ng-select';

import { CompaniesService } from '../../services/companies.service';

import { Company } from '../../entities/company';

// TODO: Rebase this on portal-select
@Component({
  selector: 'dpg-select-company',
  templateUrl: './select-company.component.html',
})
export class SelectCompanyComponent implements OnInit {
  // Attributes
  @Output() add: EventEmitter<Company> = new EventEmitter();
  @Input() default: Company = null;
  @Input() blacklist: Company[] = [];
  @Input() clearCall: Subject<void>;

  @ViewChild(NgSelectComponent) companySelect: NgSelectComponent;

  label: 'companyName';
  list: Company[] = [];
  loading: boolean;
  query = '';
  searchSubject = new BehaviorSubject<string>('');

  // Constructor
  constructor(private readonly _companiesService: CompaniesService, private readonly _logger: Logger) {}

  // Lifecycle
  ngOnInit(): void {
    this._logger.debug('[dpg-select-company] Init component');
    this.refreshList();

    this.clearCall.subscribe(() => {
      this.companySelect.clearModel();
      this.refreshList();
    });

    this.searchSubject
      .pipe(
        tap(() => {
          this.list = [];
        }),
        distinctUntilChanged(),
        debounceTime(500),
      )
      .subscribe((value: string) => {
        if (value && value.length > 0) {
          this.query = value;
          this.refreshList();
        } else {
          this.clear();
        }
      });
  }

  // Methods
  initList(): void {
    this._logger.debug('[dpg-select-company] Init list');
    this.list = [];
    this.refreshList();
  }

  refreshList(): void {
    this._logger.debug('[dpg-select-company] Refresh list');
    this.loading = true;
    this._companiesService.listAll().subscribe(
      (result) => {
        this.list = result;
        this.removeBlacklisted();
        this.filterList();
        this.sortList();
        this.loading = false;
      },
      () => {
        this.loading = false;
      },
    );
  }

  sortList(): void {
    if (this.list.length > 1) {
      this.list.sort((c1, c2) => {
        return c1.companyName.localeCompare(c2.companyName);
      });
    }
  }

  removeBlacklisted(): void {
    this.list = this.list.filter((c) => !this.blacklist.map((bc) => bc.companyName).includes(c.companyName));
  }

  filterList(): void {
    if (this.query !== '') {
      this.list = this.list.filter(
        (c) => !c.relatedPortal && c.companyName.toLowerCase().includes(this.query.toLowerCase()),
      );
    }
  }

  search($event: any): void {
    this.query = $event;
    this.filterList();
  }

  clear(): void {
    this.query = '';
    this.initList();
  }

  setSelectedCompany($event: Company): void {
    this._logger.debug('[dpg-select-company] Set selected company');
    this.add.emit($event);
  }
}
