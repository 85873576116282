import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { ButtonType } from '../../atoms/button/button.component';
import { TextType } from '../../atoms/text/text.component';

import { SupportedSVGIcon } from '../../atoms/icons';

@Component({
  selector: 'adl-dialog-modal',
  templateUrl: './dialog-modal.component.html',
  styleUrls: ['./dialog-modal.component.scss'],
})
export class DialogModalComponent {
  @Input() headerText?: string;
  @Input() message: string | string[];
  @Input() messageType: TextType = 'normal';
  @Input() cancelText: string;
  @Input() confirmText = 'Ok';
  @Input() cancelType: ButtonType = 'primary';
  @Input() confirmType: ButtonType = 'cancel-v2';
  @Input() firstButton: 'confirm' | 'cancel' = 'confirm';
  @Input() icon: SupportedSVGIcon;
  @Input() result: unknown = true;
  @Input() innerHTML: string;

  constructor(
    private readonly _activeMatModal: MatDialogRef<DialogModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    data: {
      headerText: string;
      message: string | string[];
      messageType: TextType;
      cancelText: string;
      confirmText: string;
      cancelType: ButtonType;
      confirmType: ButtonType;
      firstButton: 'confirm' | 'cancel';
      icon: SupportedSVGIcon;
      result: unknown;
      innerHTML: string;
    },
  ) {
    this.headerText = data?.headerText?.length ? data.headerText : this.headerText;
    this.message = data?.message?.length ? data.message : this.message;
    this.messageType = data?.messageType?.length ? data.messageType : this.messageType;
    this.cancelText = data?.cancelText?.length ? data.cancelText : this.cancelText;
    this.confirmText = data?.confirmText?.length ? data.confirmText : this.confirmText;
    this.cancelType = data?.cancelType?.length ? data.cancelType : this.cancelType;
    this.confirmType = data?.confirmType?.length ? data.confirmType : this.confirmType;
    this.firstButton = data?.firstButton?.length ? data.firstButton : this.firstButton;
    this.icon = data?.icon?.length ? data.icon : this.icon;
    this.result = Object.keys(data || {}).includes('result') ? data.result : this.result;
    this.innerHTML = data?.innerHTML?.length ? data.innerHTML : this.innerHTML;
  }

  confirm(): void {
    this._activeMatModal.close(this.result);
  }

  cancel(): void {
    this._activeMatModal.close();
  }

  get messages(): string[] {
    return this.message ? (Array.isArray(this.message) ? this.message : [this.message]) : [];
  }
}
