<ng-container
  *ngIf="{
  canPrevious: canPrevious$ | async,
  canNext: canNext$ | async,
  currentPageNumber: currentPageNumber$ | async,
  totalPages: totalPages$ | async,
  pages: pages$ | async,
  } as state"
>
  <ul class="flex gap-4 text-xl items-center">
    <li [class.disabled]="!state.canPrevious">
      <a
        role="button"
        aria-label="go to previous page"
        href="javascript:void(0)"
        (click)="prevPage(state.currentPageNumber, state.totalPages)"
      >
        Previous
      </a>
    </li>
    <li
      role="button"
      [attr.aria-label]="'page ' + pg.number"
      *ngFor="let pg of state.pages"
      [class.active]="pg.number === state.currentPageNumber"
      class="w-[2.3rem] h-[2.3rem] rounded-full grid place-items-center"
      (click)="selectPage(state.currentPageNumber, pg.number, state.totalPages)"
    >
      <a href="javascript:void(0)">
        {{ pg.text }}
      </a>
    </li>
    <li [class.disabled]="!state.canNext">
      <a
        role="button"
        aria-label="go to next page"
        href="javascript:void(0)"
        (click)="nextPage(state.currentPageNumber, state.totalPages)"
      >
        Next
      </a>
    </li>
  </ul>
</ng-container>
