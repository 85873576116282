import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from '@dataportal/front-api';
import { Logger } from '@dataportal/front-shared';
import type { IRecommendedSource, IUserRecommendations, TrendingSource } from '@dataportal/types';

import { RecommendationsModule } from '../recommendations.module';

@Injectable({
  providedIn: RecommendationsModule,
})
export class RecommendationsService {
  constructor(private readonly _apiService: ApiService, private readonly _logger: Logger) {}

  private readonly _userRecommendations$ = new BehaviorSubject<IRecommendedSource[]>([]);
  userRecommendations$ = this._userRecommendations$.asObservable();

  getUserRecommendations(): Observable<IUserRecommendations> {
    return this._apiService.get('/v4/recommendations');
  }

  refreshUserRecommendations(): void {
    this._logger.debug('Refresh favorites');
    this.getUserRecommendations().subscribe((userRecommendations: IUserRecommendations) => {
      this._logger.debug('Favorites refreshed', userRecommendations);
      this._userRecommendations$.next(userRecommendations.recommended_sources);
    });
  }

  getTrendingSources(): Observable<TrendingSource[]> {
    return this._apiService.get('/v4/recommendations/trending-sources');
  }

  createUserRecommendation(
    userId: string,
    sourceId: string,
    customMessage: string,
    dashboardName?: string,
  ): Observable<IUserRecommendations> {
    return this._apiService.post('/v4/recommendations', {
      user_id: userId,
      source_id: sourceId,
      custom_message: customMessage,
      dashboard_name: dashboardName,
    });
  }

  update(sourceId: string, authorId: string, dashboardName: string, seenByUser?: boolean): Observable<boolean> {
    return this._apiService.patch('/v4/recommendations', {
      source_id: sourceId,
      author_id: authorId,
      dashboard_name: dashboardName,
      seen_by_user: seenByUser,
    });
  }

  deleteUserRecommendation(userId: string, sourceId: string, dashboardName?: string): Observable<void> {
    return this._apiService.delete('/v4/recommendations', {
      body: {
        author_id: userId,
        source_id: sourceId,
        dashboard_name: dashboardName,
      },
    });
  }
}
