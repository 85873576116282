import { InjectionToken } from '@angular/core';

// Constants
export const PERMISSIONS_OPTIONS = new InjectionToken('dataportal-front:permissions-options');

// Types
export interface IPermissionsDefaultedOptions {
  // Options
  errorRouterLink?: string[] | string;
}

export interface IPermissionsOptions extends IPermissionsDefaultedOptions {
  // Options
  errorRouterLink: string[];
}
