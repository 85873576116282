import type { OnDestroy, OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormArray, FormControl } from '@ngneat/reactive-forms';

import { TextType } from '../../atoms/text/text.component';

export interface ICheckboxValue<T> {
  label: string;
  value: T;
}

interface ICheckbox<T> extends ICheckboxValue<T> {
  control: FormControl<boolean>;
}

@Component({
  selector: 'adl-checkboxes-v2',
  templateUrl: './checkboxes-v2.component.html',
})
export class CheckboxesV2Component<T = unknown> implements OnInit, OnDestroy {
  @Input() options: ICheckboxValue<T>[] = [];
  @Input() control: FormControl<T[]>;
  @Input() addAllOption = false;
  @Input() allOptionLabel?: string;
  @Input() checkboxesPerLine = 1;

  @Input() labelSize = 12;
  @Input() labelType: TextType;
  @Input() isLabelBold = null;
  @Input() monoLine = false;
  @Input() hasPaddingRight = true;

  allCheckboxControl = new FormControl<boolean>();
  allPossibleValues: T[] = [];

  checkboxes: ICheckbox<T>[];
  checkboxesControl: FormArray<boolean>;

  private readonly _destroy$ = new Subject<void>();

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  ngOnInit(): void {
    this._initCheckboxesControl();

    if (this.addAllOption) {
      this._initAllCheckbox();
    }

    this.control.valueChanges.pipe(takeUntil(this._destroy$)).subscribe((values) => {
      const uncheckedValues = this.allPossibleValues.filter((value) => !values.includes(value));

      values.forEach((value, position) => {
        if (value === null) {
          this.checkboxesControl.controls[position].patchValue(false, { emitEvent: false });
        } else {
          const index = this.options.findIndex((option) => option.value === value);
          const control = this.checkboxesControl.controls[index];

          if (control) {
            control.patchValue(true, { emitEvent: false });
          }
        }
      });

      uncheckedValues.forEach((value) => {
        const index = this.options.findIndex((option) => option.value === value);
        const control = this.checkboxesControl.controls[index];

        if (control) {
          control.patchValue(false, { emitEvent: false });
        }
      });
    });
  }

  onChange(checkboxValue, isClicked) {
    const index = this.options.findIndex((option) => option.value === checkboxValue);
    const control = this.checkboxesControl.controls[index];
    control.patchValue(isClicked);
  }

  clickOnAllCheckbox(isChecked: boolean) {
    this.allCheckboxControl.patchValue(isChecked);
  }

  private _initCheckboxesControl() {
    this.checkboxes = [];
    this.checkboxesControl = new FormArray<boolean>([]);

    this.options.forEach((option) => {
      const control = new FormControl<boolean>(this.control.value.includes(option.value));
      this.checkboxesControl.push(control);
      this.checkboxes.push({ label: option.label, value: option.value, control });
    });

    this.checkboxesControl.valueChanges.pipe(takeUntil(this._destroy$)).subscribe((values) => {
      const newValue: T[] = values.map((isChecked, index) => {
        return isChecked ? this.options[index].value : null;
      });

      this.control.patchValue(newValue);
    });
  }

  private _initAllCheckbox() {
    this.allPossibleValues = this.options.map((option) => option.value);
    const defaultValue = this.control.value;

    const shouldAllCheckboxBeTicked =
      Array.isArray(defaultValue) && this.allPossibleValues.every((value) => defaultValue.includes(value));
    this.allCheckboxControl.setValue(shouldAllCheckboxBeTicked);

    this.allCheckboxControl.valueChanges.pipe(takeUntil(this._destroy$)).subscribe((isAllChecked) => {
      const newValue = isAllChecked ? this.allPossibleValues : this.allPossibleValues.map(() => null);
      this.control.patchValue(newValue);
    });

    this.control.valueChanges.pipe(takeUntil(this._destroy$)).subscribe((valueArray: T[]) => {
      const doesValueContainsAll = this.allPossibleValues.every((value) => valueArray.includes(value));
      this.allCheckboxControl.patchValue(doesValueContainsAll, { emitEvent: false });
    });
  }
}
