import { Entity, EntityBuilder } from '@decahedron/entity';

import type { ITool } from './tool';
import { Tool } from './tool';

export interface IRawToolCategory {
  name: string;
  parentCategory?: { pk: string };
  pk: string;
  rank: number;
  subCategories: Array<{ pk: string }>;
  tools: Array<{ pk: string }>;
  description?: string;
}

export interface IToolCategory {
  name: string;
  parentCategory?: { pk: string };
  pk: string;
  rank: number;
  subCategories: IToolCategory[];
  tools: ITool[];
  description?: string;
}

export class ToolCategory extends Entity {
  id: string; // slugified and lowered case tool name
  name: string;
  description?: string = null;
  rank: number; // category rank order on the same nested level of category
  tools: Tool[]; // tools list that are directly inside this category
  parentCategory?: ToolCategory; // parent category ID
  subCategories: ToolCategory[]; // sub categories IDs

  private static _allTools: Tool[];

  static setAllTools(tools: Tool[]) {
    ToolCategory._allTools = tools;
  }

  static byRank(tool1: Tool | ToolCategory, tool2: Tool | ToolCategory): number {
    return tool1.rank < tool2.rank ? -1 : tool1.rank > tool2.rank ? 1 : 0;
  }

  fromJson(jsonData: IToolCategory): ToolCategory {
    this.id = jsonData.pk;
    this.name = jsonData.name;
    this.description = jsonData.description ? jsonData.description : null;
    this.rank = jsonData.rank;
    this.parentCategory = this.parentCategory ? EntityBuilder.buildOne(ToolCategory, jsonData.parentCategory) : null;
    const tools: Tool[] = ToolCategory._allTools
      ? ToolCategory._allTools.filter((oneTool) =>
          jsonData.tools
            ?.filter(Boolean)
            ?.map((otherTool) => otherTool?.pk)
            ?.includes(oneTool?.id),
        )
      : EntityBuilder.buildMany(Tool, jsonData?.tools);
    const subCategories: ToolCategory[] = EntityBuilder.buildMany(ToolCategory, jsonData.subCategories);
    this.tools = tools.sort(ToolCategory.byRank).filter((t) => t.activated);
    this.subCategories = subCategories.sort(ToolCategory.byRank).filter((c) => c.tools.length);

    return super.fromJson(jsonData);
  }

  toJson(): IRawToolCategory {
    return {
      pk: this.id,
      name: this.name,
      description: this.description,
      rank: this.rank,
      tools: this.tools.map((tool) => ({ pk: tool.id })),
      parentCategory: { pk: this.parentCategory.id },
      subCategories: this.subCategories.map((sc) => ({ pk: sc.id })),
    };
  }
}
