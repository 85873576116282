import type { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { HttpContextToken, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { from, Subject, throwError } from 'rxjs';
import { catchError, finalize, first, map, mergeMap, tap } from 'rxjs/operators';
import { ApiUrlService, ServiceStatusManagerService } from '@dataportal/front-api';
import { E2EService, Logger } from '@dataportal/front-shared';
import { ImpersonateService } from '@dataportal/users';

import { MultiAuthService } from '../services/multi-auth.service';

// Types
type HttpHeaders = Record<string, string | string[]>;

// Constants
const MAX_AUTH_TRIES = 3;
export const BYPASS_ACCEPT_HEADER = new HttpContextToken(() => false);

// Utils
const addDataportalAPIAuthHeader = (request: HttpRequest<unknown>, token: string): HttpRequest<unknown> => {
  if (token) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  return request;
};

// Interceptor
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  // Attributes
  private _isRefreshTokenInProgress = false;

  private readonly _refreshedToken$ = new Subject<string>();
  refreshedToken$ = this._refreshedToken$.asObservable();

  // Constructor
  constructor(
    private readonly _authService: MultiAuthService,
    private readonly _impersonateService: ImpersonateService,
    private readonly _logger: Logger,
    private readonly _apiUrlService: ApiUrlService,
    private readonly _serviceStatusManagerService: ServiceStatusManagerService,
    private readonly _e2eService: E2EService,
  ) {}

  // Methods

  private _getToken(): string {
    if (this._authService.provider === 'cognito') {
      return this._authService.accessToken;
    }

    return this._authService.getTokenWithoutSideEffect();
  }

  private _getDataportalApiHttpHeaders(request: HttpRequest<unknown>): HttpHeaders {
    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this._getToken()}`,
    };

    if (!request.context.get(BYPASS_ACCEPT_HEADER)) {
      headers.Accept = 'application/json';
    }

    if (this._impersonateService.isImpersonating()) {
      headers['X-Impersonate'] = this._impersonateService.getImpersonate();
    }

    const isE2eEnabled = this._e2eService.isEnabled();

    if (isE2eEnabled) {
      headers['X-End-To-End-Enabled'] = String(isE2eEnabled);
      headers['X-End-To-End-User-Id'] = this._e2eService.getTestUserId();
    }

    return headers;
  }

  private _refreshToken(): Observable<string> {
    if (this._isRefreshTokenInProgress) {
      this._logger.info('[Auth Interceptor] Already refreshing access token');

      return this.refreshedToken$.pipe(first());
    }

    // Get a new token
    this._isRefreshTokenInProgress = true;
    this._logger.info('[Auth Interceptor] Request access token refresh');

    return from(this._authService.refreshTokens()).pipe(
      first(),
      tap((token) => {
        this._logger.info('[Auth Interceptor] Access token refreshed', token);
        this._refreshedToken$.next(token);
      }),
      catchError((err: unknown) => {
        this._logger.info('[Auth Interceptor] Error refreshing access token', err);
        this._refreshedToken$.error(err);

        return throwError(err);
      }),
      finalize(() => (this._isRefreshTokenInProgress = false)),
    );
  }

  private _doIntercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // Check if is api url
    const isDataportalBackendApi = this._apiUrlService.isDataportalBackEndApiUrl(request.url);

    if (isDataportalBackendApi) {
      request = request.clone({
        setHeaders: this._getDataportalApiHttpHeaders(request),
      });
    }

    // Retry request on 401 if service is considered down.
    // If sub-sequent request is still Unauthorized, there is a problem with token refreshing flow.
    // So we logout and let user sign in again (this should not happen often)
    const sendRequestAndHandleRetry = (req: HttpRequest<unknown>, originalError?: HttpErrorResponse, tryNumber = 0) => {
      if (tryNumber > 0) {
        this._logger.debug(`[Auth Interceptor] Requesting ${req.url} (try ${tryNumber + 1})`);
      }

      return next.handle(req).pipe(
        map((response: HttpResponse<never>) => {
          // If no 401 error occurs, service 401 count is reset
          if (response instanceof HttpResponse && response.status !== 401) {
            this._serviceStatusManagerService.reset401ToService(req.url);
          }

          return response;
        }),
        catchError((err: HttpErrorResponse) => {
          originalError = originalError || err;

          // Retry only for 401 on api url
          if (!isDataportalBackendApi || err.status !== 401) {
            return throwError(originalError);
          }

          // Notify service status manager that a 401 has been received
          this._serviceStatusManagerService.add401ToService(req.url);

          // If service is ignored by service status manager service, do not retry
          if (this._serviceStatusManagerService.isServiceIgnored(req.url)) {
            this._logger.error(
              `[Auth Interceptor] ${this._serviceStatusManagerService.getUrlRelatedAPI(
                req.url,
              )} service is ignored for unauthorized responses.`,
            );

            return throwError(originalError);
          }

          const isDataportalDownOrServiceUp =
            !this._serviceStatusManagerService.isServiceDown(req.url) || this._serviceStatusManagerService.isDPDown();

          if (isDataportalDownOrServiceUp) {
            // Retry only up to MAX_TRIES times
            if (tryNumber >= MAX_AUTH_TRIES) {
              this._logger.error('[Auth Interceptor] Maximum retries already done.');
              this._authService.handleError({
                origin: 'interceptor',
                reason: 'access token refresh on 401: max tries reached',
              });

              return throwError(originalError);
            }

            this._logger.warn('[Auth Interceptor] Refreshing token and retrying');

            return this._refreshToken().pipe(
              catchError((e: unknown) => {
                this._logger.error('[Auth Interceptor] Error requesting new token', e);
                this._logger.error('[Auth Interceptor] Retrying', e);
                this._authService.login();

                return throwError(originalError);
              }),
              mergeMap((newToken) => {
                if (!newToken) {
                  this._logger.warn(
                    '[Auth Interceptor] Not retrying request. Redirection needed to refresh access token',
                  );
                  this._authService.login();

                  return throwError(originalError);
                }

                this._logger.info('[Auth Interceptor] Retrying request with new token');
                req = addDataportalAPIAuthHeader(req, newToken);

                return sendRequestAndHandleRetry(req, originalError, tryNumber + 1);
              }),
            );
          } else {
            this._logger.warn(
              `[Auth Interceptor] Not retrying, service ${this._serviceStatusManagerService.getUrlRelatedAPI(
                req.url,
              )} is down but not DP`,
            );

            return throwError(originalError);
          }
        }),
      );
    };

    return sendRequestAndHandleRetry(request);
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler) {
    return this._doIntercept(request, next);
  }
}
