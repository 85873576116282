import { Entity, Type } from '@decahedron/entity';

import { AccessRequestArchive } from './access-request-archive';
import type { IARResourceRolesMap, ISupportedARResource } from './utils';

// Schemas
export interface IAccessRequestCreation<R extends ISupportedARResource = ISupportedARResource> {
  resource: R;
  resourceId: string;
  requestedRole: IARResourceRolesMap[R];
}

export interface IAccessRequestApprobation<R extends ISupportedARResource = ISupportedARResource> {
  resource: R;
  resourceId: string;
  userId: string;
  approvedRole: IARResourceRolesMap[R];
}

export interface IAccessRequestDeny {
  resource: ISupportedARResource;
  resourceId: string;
  userId: string;
}

// Types
export type ActualAccessRequestStatus = 'inactive' | 'pending';

// Model
export class AccessRequestV2<R extends ISupportedARResource = ISupportedARResource> extends Entity {
  userId: string = null; // userId
  requestDate: number = null; // timestamp
  requestedRole: IARResourceRolesMap[R] = null;
  status: ActualAccessRequestStatus = null;
  resource: R = null;
  resourceId: string = null;
  resourceName: string = null;
  resourceNameToSearch: string = null; // lower-cased
  requesterName: string = null; // user name
  requesterNameToSearch: string = null; // lower-cased
  company?: string = null; // Is updated when users are updated
  jobTitle?: string = null; // Is updated when users are updated
  createdAt: string = null;
  updatedAt: string = null;
  hasBeenSeenByCurrentUser?: boolean = null;
  @Type(AccessRequestArchive) history: AccessRequestArchive<R>[] = [];

  fromJson(jsonData: any): this {
    this.userId = jsonData.pk;

    return super.fromJson(jsonData);
  }

  get lastHistory(): AccessRequestArchive {
    return this.history[this.history.length - 1];
  }
}

// Tests
export function isAccessRequestFor<R extends ISupportedARResource>(
  res: R,
  ar: AccessRequestV2,
): ar is AccessRequestV2<R> {
  return ar.resource === res;
}
