import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AlertService } from '@dataportal/front-shared';
import { FormControl } from '@ngneat/reactive-forms';

import { PowerBiBookmarksService } from '../../services/power-bi-bookmarks.service';

// Component
@Component({
  selector: 'dpg-dashboard-paste-filters-modal',
  templateUrl: './dashboard-paste-filters-modal.component.html',
  styleUrls: ['./dashboard-paste-filters-modal.component.scss'],
})
export class DashboardPasteFiltersModalComponent {
  loadingState = false;
  filterPaste: FormControl<string> = new FormControl();

  constructor(
    private readonly _activeMatModal: MatDialogRef<DashboardPasteFiltersModalComponent>,
    private readonly _alertsService: AlertService,
    private readonly _powerBiBookmarksService: PowerBiBookmarksService,
  ) {}

  close(): void {
    this._activeMatModal.close();
  }

  async confirm(): Promise<void> {
    if (!this.filterPaste.value) return;

    this.loadingState = true;
    this._powerBiBookmarksService
      .applyState(this.filterPaste.value)
      .then(() => {
        this._alertsService.success('Successfully applied filters!');
        this.loadingState = false;
        this._activeMatModal.close();
      })
      .catch(() => {
        this._alertsService.success('Failed applying state!');
        this.loadingState = false;
      });
  }
}
