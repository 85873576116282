import type { EnabledLink, IEnabledLink } from '@dataportal/types';
import { isLinkCategory } from '@dataportal/types';

interface IRelationshipEnabled {
  id: string;
  label: string;
  priority: number;
}

export interface IRelationshipCategory {
  priority: number;
  id: string;
  title: string;
  links: Array<IRelationshipEnabled>;
}

export class EnabledLinks {
  private readonly _categories: Array<IRelationshipCategory> = [];
  private readonly _rootLinks: Array<IRelationshipEnabled> = [];

  get categories() {
    return this._categories.sort((l1, l2) => l1.priority - l2.priority);
  }

  get rootLinks() {
    return this._rootLinks.sort((l1, l2) => l1.priority - l2.priority);
  }

  constructor(links: EnabledLink[]) {
    for (const link of links) {
      if (isLinkCategory(link)) {
        this._categories.push({
          id: link.pk,
          priority: link.priority,
          title: link.categoryTitle,
          links: links
            .filter((l) => !isLinkCategory(l) && l.parent === link.pk)
            .map((l: IEnabledLink) => ({
              id: l.pk,
              label: l.label,
              priority: l.priority,
            }))
            .sort((l1, l2) => l1.priority - l2.priority),
        });
      } else if (!link.parent) {
        this._rootLinks.push({
          id: link.pk,
          label: link.label,
          priority: link.priority,
        });
      }
    }
  }
}
