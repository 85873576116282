import type { OnDestroy, OnInit } from '@angular/core';
import { Inject } from '@angular/core';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IS_ACCOR } from '@dataportal/front-environment';
import { Logger } from '@dataportal/front-shared';
import type { UserPreferences } from '@dataportal/profile';
import { ProfileService } from '@dataportal/profile';

import { HelpCenterService } from './help-center.service';

import type { OneProductModel } from '../../../../../libs/dataportal-data-academy/src/lib/models/product.model';

@Component({
  selector: 'app-help-center',
  templateUrl: './help-center.component.html',
  styleUrls: ['./help-center.component.scss'],
})
export class HelpCenterComponent implements OnInit, OnDestroy {
  userPreferences: UserPreferences;
  product: OneProductModel;
  firstLink: { label: string; url: string } = { label: 'Accor Data Portal', url: '/' };
  faqEntriesFound = 0;
  coverImage: string;

  private readonly _destroyed$ = new Subject();

  constructor(
    private readonly _helpCenterService: HelpCenterService,
    private readonly _profileService: ProfileService,
    private readonly _logger: Logger,
    private readonly _router: Router,
    @Inject(IS_ACCOR) readonly isAccor: boolean,
  ) {}

  ngOnInit() {
    this._profileService.getUserPreferences().subscribe(
      (userPreferences) => {
        this.userPreferences = userPreferences;
        this.coverImage = `assets/dpg/imgs/covers/${userPreferences?.cover || 'Default'}.png`;
      },
      (error: unknown) => {
        this._logger.error('[GET USER PREFERENCES] Error', error);
      },
    );

    this._helpCenterService.faqEntriesFiltered$.pipe(takeUntil(this._destroyed$)).subscribe((faqEntries) => {
      this.faqEntriesFound = faqEntries.length;
    });
  }

  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  openTicket() {
    window.open(
      this.isAccor
        ? 'mailto:data.portal.help@accor.com'
        : 'https://pernod-ricard--bmcservicedesk.vf.force.com/apex/SelfServiceNew?categoryId=a2T1i000000Y0rv&fromSiteUrl=#/support/problem-requests/all',
      '_blank',
    );
  }

  search(searchContent: string) {
    this._router.navigate(['/help-center/search'], { queryParams: { search: searchContent } });
  }
}
