import { InjectionToken } from '@angular/core';

// Constants
export const DATALAKE_OPTIONS = new InjectionToken<IDatalakeModuleOptions>('dataportal-front:datalake:options');

// Types
export interface IDatalakeModuleOptions {
  // unit in bits
  sizeUnitFactor?: number; // 1024 * 1024
  // overview
  overview?: {
    csv: number;
    xls: number;
    json: number;
  };
  // edit
  edit?: {
    csv: number;
  };
}
