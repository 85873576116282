<adl-loaded-item [loading]="isLoading" [iconSize]="150">
  <adl-page-header
    pageName="Data Tools & Services"
    pageDescription="Explore our data services and use all data applications and boost your data projects with our tools and in-house capabilities."
  ></adl-page-header>

  <ng-container>

    <div class="flex p-[20px] w-full">
      <div *ngIf="dataTools" class="mr-[20px] w-6/12">
        <adl-text class="block mb-4" size="20">DATA TOOLS</adl-text>
        <div class="box tools blue-dark">
          <div
            *ngFor="let tool of dataTools.tools"
            class="data-tool"
          >
            <a
              class="cursor-pointer"
              [ngClass]="{ 'cursor-pointer': tool.links?.length === 1 }"
              (click)="navigateOnSingleLink($event, tool.links)"
            >
              <adl-star
                class="mr-[10px]"
                dpgStopClickBubbling
                [active]="favorites.includes(tool.id)"
                (clicked)="toggleFavorite(tool.id, dataTools.id)"
              ></adl-star>

              <span class="icon">
              <adl-loaded-item [loading]="!tool.logoSVG" [iconSize]="40">
                <div [innerHTML]="tool.logoSVG"></div>
              </adl-loaded-item>
            </span>

              <span class="name">{{ tool.name }}</span>
              <div class="description column-flex items-center">
                <span class="w-full">{{ tool.description }}</span>
                <div *ngIf="tool.links.length > 1" class="mt-2 flex align-center">
                  <adl-button
                    *ngFor="let link of tool.links"
                    dpgStopClickBubbling
                    class="ml-2 mr-2"
                    size="small"
                    fixedHeight="50px"
                    (clicked)="navigateTool($event, link)"
                  >
                    {{ link.name }}
                  </adl-button>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>

      <div *ngIf="dataServices" class="w-6/12">
        <adl-text class="block mb-4" size="20">DATA SERVICES & CAPABILITIES</adl-text>
        <div class="data-services box blue-light flex flex-row flex-wrap">
          <div
            *ngFor="let tool of dataServicesFlattened"
            class="data-service"
            [ngClass]="{ 'not-visible': tool.isMockedTool, 'visible': !tool.isMockedTool }"
            (click)="navigateDataService($event, tool.links[0], tool)"
          >
            <adl-star
              class="star"
              dpgStopClickBubbling
              [active]="favorites.includes(tool.id)"
              (clicked)="toggleFavorite(tool.id, dataServices.id)"
            ></adl-star>

            <span class="icon">
              <div *ngIf="!tool.isLoading" class="svg-container" [innerHTML]="tool.logoSVG"></div>
              <img *ngIf="tool.isLoading" src="assets/dpg/imgs/loading.gif" alt="loading"/>
            </span>

            <span class="name">{{ tool.name }}</span>
            <span class="description">{{ tool.description }}</span>

            <div *ngIf="tool.links.length > 1" class="flex mt-4 items-center">
              <adl-button
                *ngFor="let link of tool.links"
                class="ml-4 mr-5"
                size="small"
                dpgStopClickBubbling
                (clicked)="navigateDataService($event, link, tool)"
              >
                {{ link.name }}
              </adl-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</adl-loaded-item>


