import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'safeUrl' })
export class SafeUrlPipe implements PipeTransform {
  constructor(private readonly _sanitizer: DomSanitizer) {}
  transform(url) {
    return this._sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
