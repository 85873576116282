import { Entity } from '@decahedron/entity';

// Entity
export class UserPermissionV2 extends Entity {
  // Attributes
  userId: string;
  userRoles: string[];
  fromGroupInfo: { groupId: string; groupName: string };
  userName?: string;

  // Methods
  fromJson(jsonData: {
    user_id: string;
    roles: string[];
    user_name?: string;
    from_group?: {
      group_name: string;
      group_id: string;
    };
  }): UserPermissionV2 {
    this.userId = jsonData.user_id;
    this.userName = jsonData.user_name;
    this.userRoles = jsonData.roles;

    if (jsonData.from_group) {
      this.fromGroupInfo = {
        groupId: jsonData.from_group.group_id,
        groupName: jsonData.from_group.group_name,
      };
    }

    return this;
  }
}
