import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import type { Observable, Subscription } from 'rxjs';
import { BehaviorSubject, Subject, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import type { AuthenticationResult, Configuration } from '@azure/msal-browser';
import { PublicClientApplication } from '@azure/msal-browser';
import { ApiUrlService } from '@dataportal/front-api';
import { EnvironmentService } from '@dataportal/front-environment';
import { E2EService, Logger } from '@dataportal/front-shared';
import type { IAuthErrorReport, IAuthToken } from '@dataportal/msal';
import {
  AuthenticationState,
  AuthSessionStorageKeys,
  sendErrorReport,
  setAuthCookies,
  updateTokens,
} from '@dataportal/msal';
import { WebsocketsService } from '@dataportal/websocket';

import { AuthStorageService } from './auth-storage.service';
import { AuthTokenService } from './auth-token.service';
import { BaseAuthService } from './base-auth.service';

import { mockMsal } from '../mocks/msal.mock';

import { AUTH_OPTIONS, IAuthOptions } from '../auth-options';
import type { IProfileFromAuth } from './base-auth.service';

// Constants
// FIXME: From envs
const SIXTY_MINUTES = 60 * 60 * 1000; // ms

/**
 * This service handle interactions with msal authentication system.
 */
@Injectable()
export class MsalAuthService extends BaseAuthService {
  // Attributes
  /**
   * "https://management.core.windows.net/user_impersonation" works too
   * but "https://management.azure.com" is for API purposes
   */
  static USER_IMPERSONATION_SCOPE;
  static DEVOPS_USER_IMPERSONATION_SCOPE;
  static DATABRICKS_SCOPE;

  private _loggingOut = false;
  private _accessTokenRequestPromise: Promise<string>;

  private readonly _userProfile$ = new BehaviorSubject<IProfileFromAuth>(null);
  private readonly _defaultScopes = ['openid', 'email'];

  /**
   * @property {PublicClientApplication} _msal:
   * User agent provided by AzureAD library for login.
   * @refers Documentation available at https://github.com/AzureAD/microsoft-authentication-library-for-js
   */
  private readonly _msal: PublicClientApplication;

  /**
   * @property {Observable<number>}_refreshTokenTimer
   * Timer used to refresh token regularly when user is logged in
   */
  private _accessTokenAutoRefresh$: Subscription;

  /**
   * @property {Subject<void>} _stopTimer
   * Subject used to stop previous refresh token timer when user logs out
   */
  private readonly _stopAccessTokenAutoRefresh$ = new Subject();

  /**
   * @property {Observable<User>} userProfile
   * Auth service exposes an observable that emit the currently authenticated user.
   * Used by CurrentUserService to determine the user when no impersonating.
   */
  readonly userProfile$: Observable<IProfileFromAuth> = this._userProfile$.asObservable();

  /**
   * If we try to access a CloudFront behavior protected by a Lambda@Edge, the lambda will check the end user's
   * AAD token. In case it is expired, the Lambda&Edge will answer a 302 redirection to Data Portal home and set
   * a DP_AUTH_EDGE_REDIRECT cookie with a 120 seconds TTL.
   * We need to restore this url after authentication. Two scenarios:
   * 1) either a redirection is needed: in that case we update the return URL in constructor so URL will be
   * automatically restored by auth-redirect micro-app
   * 2) no redirection is needed, token is acquired silently: in that case we must remember the return URI in
   * AuthService memory. That's why this _postTokenAcquisitionRedirect flag in made for.
   * @private
   */
  private readonly _postTokenAcquisitionRedirect: string;

  // Constructor
  constructor(
    private readonly _logger: Logger,
    private readonly _websocket: WebsocketsService,
    private readonly _router: Router,
    private readonly _authStorageService: AuthStorageService,
    private readonly _tokenService: AuthTokenService,
    private readonly _e2eService: E2EService,
    readonly environmentService: EnvironmentService,
    @Inject(AUTH_OPTIONS) private readonly _options: IAuthOptions,
  ) {
    super();

    // End-to-end tests
    if (this._e2eService.isEnabled()) {
      this._logger.warn('Cypress will manage the token for E2E user');
      this._msal = mockMsal();

      return;
    }

    // Setup MSAL
    const optionsMsal: Configuration = {
      cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: false,
      },
      auth: {
        authority: _options.azureAD.authority,
        clientId: _options.azureAD.clientID,
        navigateToLoginRequestUrl: false,
        postLogoutRedirectUri: window.location.origin,
        redirectUri: ApiUrlService.dedupSlashes(`${_options.baseUrl}/auth${_options.authUriSuffix}`),
      },
    };

    MsalAuthService.USER_IMPERSONATION_SCOPE = this.environmentService.options.msalOptions.userImpersonationScope;
    MsalAuthService.DEVOPS_USER_IMPERSONATION_SCOPE =
      this.environmentService.options.msalOptions.devopsImpersonationScope;
    MsalAuthService.DATABRICKS_SCOPE = this.environmentService.options.msalOptions.databricksScope;

    this._logger.debug('[Auth Service] (init) Creating MSAL instance', optionsMsal);
    this._msal = new PublicClientApplication(optionsMsal);

    if (this._authStorageService.doesRedirectEdgeExist()) {
      const redirectEdge = this._authStorageService.redirectEdge;
      this._logger.info('[Auth Service] (storeURI) Redirect URI set by Lambda@Edge', redirectEdge);
      this._authStorageService.returnURL = redirectEdge;
      this._postTokenAcquisitionRedirect = redirectEdge;
      this._authStorageService.deleteRedirectEdge();
    }

    if (this.isAuthenticated()) {
      if (!this._authStorageService.isExpired('access')) {
        this._logger.debug('[Auth Service] (init) Already authenticated');
        this._refreshTokenRegularly();
      } else {
        void this.refreshTokens();
      }
    }
  }

  // Methods
  /**
   * If it is not possible to acquire token silently (i.e. a manual intervention from the user is needed)
   * He is redirected to Microsoft login page.
   * In that case, token will be retrieve by redirect handler.
   */
  private async _acquireTokenRedirect(): Promise<void> {
    const scopesToUse = this._getScopesToUseInAppAuth();

    this._logger.info('[Auth Service] (refresh) Acquire token redirect now');
    this._logger.info('[Auth Service] (refresh) Auth scopes used : ', scopesToUse);
    this._storeCurrentURL();

    this._authStorageService.state = AuthenticationState.ACQUIRING_ACCESS_TOKEN;

    try {
      await this._msal.acquireTokenRedirect({ scopes: scopesToUse });
    } catch (e) {
      this.handleError({ origin: 'AuthService', method: 'acquireTokenRedirect', reason: e });
    }
  }

  private _getAccount(): IProfileFromAuth {
    if (!this.isAuthenticated()) {
      return null;
    }

    try {
      const payload = this._authStorageService.decodeJwtToken(this._authStorageService.idToken);

      return { username: (payload as unknown as { preferred_username: string }).preferred_username };
    } catch (e) {
      return null;
    }
  }

  /**
   * Update the value of Access token in local storage then refresh it every 45 minutes
   * (15 minutes before expiration) if it is not already the case.
   * Update cookies used for Superset/Airflow
   * @param response : msal callback response
   */
  private _updateToken(response: AuthenticationResult): void {
    this._logger.debug('[Auth Service] (refresh) Token', response);
    this._logger.debug('[Auth Service] (refresh) Access token V1.0', response.accessToken);
    this._logger.debug('[Auth Service] (refresh) Access token V2.0', response.idToken);
    updateTokens({
      provider: 'azure',
      accessToken: response.accessToken,
      idToken: response.idToken,
    });
    this._tokenService.updateTokens({
      accessToken: response.accessToken,
      idToken: response.idToken,
    });

    if (response.account) {
      this._authStorageService.authAccount = response.account;
    }

    if (response.accessToken) {
      this._authStorageService.state = AuthenticationState.AUTHENTICATED;

      if (this._authStorageService.doesRedirectEdgeExist()) {
        const redirectTo = window.location.origin + this._authStorageService.redirectEdge;
        this._logger.info('[Auth Service] (refresh) Redirected from Lambda@Edge', { redirectTo });
        this._authStorageService.returnURL = redirectTo;
        this._authStorageService.deleteRedirectEdge();
      }
    }

    this._logger.debug('[Auth Service] (redirectCallback) Tokens updated', {
      id: this._idToken,
      access: this._authStorageService.accessToken,
    });
    setAuthCookies(response.idToken);
    this._websocket.updatedAccessToken(response.idToken);
    this._refreshTokenRegularly();

    if (this._postTokenAcquisitionRedirect) {
      window.location.replace(this._postTokenAcquisitionRedirect);
    }
  }

  /**
   * Store the current URL so that when a redirection to microsoft auth website is needed,
   * we are able to restore the current to the user (only one redirect URI is supported
   * by AzureAD)
   */
  private _storeCurrentURL(): void {
    if (this._authStorageService.returnURL) {
      this._logger.warn('[Auth Service] (storeURI) Return URI already stored');

      return;
    }

    const currentURL = window.location.href;
    this._logger.debug('[Auth Service] (storeURI) Remembering current URI', currentURL);

    const hasTokenAppended = currentURL.match(/^(.+)#id_token=(.+)$/);
    this._logger.debug('[Auth Service] (storeURI) Has token appended', hasTokenAppended);

    const toStore = hasTokenAppended ? hasTokenAppended[0] : currentURL;
    this._logger.debug('[Auth Service] (storeURI) Storing return URL', toStore);

    this._authStorageService.returnURL = toStore;
  }

  private _refreshTokenRegularly(): void {
    if (this._accessTokenAutoRefresh$) {
      this._logger.debug('[Auth Service] (auto-refresh) Auto-refresh already enabled.');

      return;
    }

    // Setup auto refresh
    this._logger.debug('[Auth Service] (auto-refresh) Auto-refresh enabled.');
    this._accessTokenAutoRefresh$ = timer(SIXTY_MINUTES)
      .pipe(takeUntil(this._stopAccessTokenAutoRefresh$))
      .subscribe(async () => {
        this._logger.info(`[Auth Service] (auto-refresh) ${SIXTY_MINUTES} milliseconds elapsed, refreshing token.`);
        await this.refreshTokens();
        this._logger.info('[Auth Service] (auto-refresh) Token auto-refreshed.');
      });
  }

  private _stopAccessTokenAutoRefresh(): void {
    this._stopAccessTokenAutoRefresh$.next();
    this._accessTokenAutoRefresh$ = null;
  }

  private _getScopesToUseInAppAuth(): string[] {
    const requireImpersonationScopeRoutes = this._options.requireImpersonationScopeRoutes?.length
      ? this._options.requireImpersonationScopeRoutes
      : [];
    const scopesToUse = [...this._defaultScopes];

    if (requireImpersonationScopeRoutes.includes(this._router.url)) {
      scopesToUse.push(MsalAuthService.USER_IMPERSONATION_SCOPE);
    }

    return scopesToUse;
  }

  /**
   * @method isAuthenticated
   * Checks whether or not the user is authenticated to AzureAD.
   * Also used by AuthGuard to determine if he can access restricted page
   */
  isAuthenticated(): boolean {
    return this._authStorageService.provider === 'azure' && !this._authStorageService.isExpired('id');
  }

  isAccessTokenExpired(): boolean {
    return this._authStorageService.isExpired('access');
  }

  /**
   * @method login
   * Logs the user in by redirecting him to AzureAD login form.
   * If user cookies for login.microsoftonline.com are set, the form
   * is not displayed and the user is directly redirected.
   * After redirection his profile his available with UserAgentApplication.getUser.
   */
  login(): void {
    if (this._e2eService.isEnabled()) {
      this._logger.warn('[Auth Service] (login) MSAL Disabled in e2e environment');

      return;
    }

    if (this._authStorageService.state === AuthenticationState.LOGGING_IN) {
      this._logger.warn('[Auth Service] (login) Already logging in');

      return;
    }

    const scopesToUse = this._getScopesToUseInAppAuth();

    this._logger.info('[Auth Service] (login) Logging in');
    this._logger.info('[Auth Service] (login) Auth scopes used : ', scopesToUse);
    this._storeCurrentURL();
    this._authStorageService.state = AuthenticationState.LOGGING_IN;
    this._msal
      .loginRedirect({
        scopes: scopesToUse,
      })
      .then(() => {
        this._logger.info('[Auth Service] (login) Login requested');
      })
      .catch((e) => {
        this._logger.error('[Auth Service] (login) Error occured while loginRedirect', e);
        this.handleError({ origin: 'AuthService', method: 'login-redirect', reason: e });
      });
  }

  /**
   * @method logout
   * Logs the user out and redirect him to /
   */
  async logout(): Promise<void> {
    this._logger.info('[Auth Service] Logging out');
    this._loggingOut = true;
    this._stopAccessTokenAutoRefresh();

    this._logger.info('[Auth Service] Clearing local storage.');
    this._authStorageService.removeAuthStorage();

    await this._msal.logout();
    this._authStorageService.state = AuthenticationState.NOT_AUTHENTICATED;
  }

  /**
   * @method refreshToken
   * Ask AzureAD service for a new access token.
   * Operation is done silently (i.e. without prompting the user)
   * It can be triggered either:
   * > when user successfully logs in the first time
   * > by the automatic refresh process (every 45 minutes)
   * > by the incoming request interceptor, when a 401 is received from backend
   * @returns promise resolving the newly acquired access token (if acquired silently). This allow
   * incoming requests interceptor to subscribe and retry the failed request with
   * the new access token.
   * If a request is already in progress or token cannot be acquire silently (this will cause a redirection)
   * returns null.
   */
  async refreshTokens(): Promise<string> {
    this._logger.info('[Auth Service] (refresh) Token refresh requested');

    if (this._accessTokenRequestPromise) {
      this._logger.error('[Auth Service] (refresh) Access token already requested');

      return this._accessTokenRequestPromise;
    }

    // eslint-disable-next-line no-async-promise-executor
    this._accessTokenRequestPromise = new Promise<string>(async (resolve, reject) => {
      const scopesToUse = this._getScopesToUseInAppAuth();
      this._logger.info('[Auth Service] (refresh) Acquiring access token silently');
      this._logger.info('[Auth Service] (refresh) Auth scopes used : ', scopesToUse);

      try {
        this._authStorageService.state = AuthenticationState.ACQUIRING_ACCESS_TOKEN;
        const token = await this._msal.acquireTokenSilent({
          scopes: scopesToUse,
        });
        this._updateToken(token);
        this._accessTokenRequestPromise = null;

        return resolve(this._idToken);
      } catch (err) {
        this._logger.warn('[Auth Service] (refresh) Could not acquire token silently', err);
        await this._acquireTokenRedirect();
        this._accessTokenRequestPromise = null;

        return reject(err);
      }
    });

    return this._accessTokenRequestPromise;
  }

  /**
   * @method refreshCurrentUser
   * Ask AzureAD service for currently logged in user profile details.
   * If it succeed, emit the value in "userProfile" observable so subscribers
   * are notified.
   */
  async refreshCurrentUser(): Promise<void> {
    this._logger.debug('[Auth Service] (current-user) Get user profile');
    const profile = this._getAccount();

    if (profile) {
      this._logger.debug('[Auth Service] (current-user) User profile successfully retrieved', profile);
      this._userProfile$.next(profile);
    } else {
      this._logger.debug('[Auth Service] (current-user) Fetch user profile failed.');
      this.login();
    }
  }

  private async _acquireTokenWithScopes(
    scopesToUse: { [key: string]: string },
    allowRedirect = false,
  ): Promise<IAuthToken | void> {
    const scopesValues = Object.values(scopesToUse);

    try {
      this._logger.debug(
        `[Auth Service][Acquire tokens] Acquiring token silently with scope ${Object.keys(scopesToUse)}`,
      );
      this._logger.debug('[Auth Service][Acquire tokens] Auth scopes used : ', scopesValues);
      this._logger.debug('[Auth Service][Acquire tokens] Account used : ', this._authStorageService.authAccount);
      const silentToken = await this._msal.acquireTokenSilent({
        account: this._authStorageService.authAccount,
        scopes: scopesValues,
      });

      return { token: silentToken.accessToken, expiration: silentToken.expiresOn };
    } catch (e) {
      if (allowRedirect) {
        this._logger.warn('[Auth Service] could not acquire silent token, using token redirect');
        this._logger.debug('[Auth Service] Auth scopes used : ', scopesValues);
        sessionStorage.setItem(AuthSessionStorageKeys.RETURN_URL, window.location.href);
        await this._msal.acquireTokenRedirect({
          scopes: scopesValues,
          onRedirectNavigate: () => true,
        });
      } else {
        this._logger.error('[Auth Service][Error] : ', e);
        throw e;
      }
    }
  }

  async acquireTokenDevops(allowRedirect = true) {
    return await this._acquireTokenWithScopes(
      { 'Devops scope': MsalAuthService.DEVOPS_USER_IMPERSONATION_SCOPE },
      allowRedirect,
    );
  }

  async acquireTokenDashboard(allowRedirect = false): Promise<IAuthToken | void> {
    return await this._acquireTokenWithScopes(
      { PIB: 'https://analysis.windows.net/powerbi/api/Report.Read.All' },
      allowRedirect,
    );
  }

  async acquireTokenDatabricks(allowRedirect = false): Promise<IAuthToken | void> {
    const authToken = (await this._acquireTokenWithScopes(
      { 'Databricks scope': MsalAuthService.DATABRICKS_SCOPE },
      allowRedirect,
    )) as IAuthToken;
    this._tokenService.updateTokens({ databricksToken: authToken.token });

    return authToken;
  }

  async handleError(report: IAuthErrorReport): Promise<void> {
    this._addErrorToState();
    await sendErrorReport(this._options.telemetryUrl, report, this._options.telemetryKey);
    window.location.replace(`${this._options.baseUrl}/auth`);
  }

  private _addErrorToState() {
    this._authStorageService.state = AuthenticationState.ERRORED;
  }

  // Properties
  private get _idToken(): string | null {
    const token = this._authStorageService.idToken;

    if (!token) {
      this.login();

      return null;
    }

    return token;
  }
}
