import type { HttpResponse } from '@angular/common/http';
import { HttpClient, HttpEventType } from '@angular/common/http';
import type { OnDestroy, OnInit } from '@angular/core';
import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { of, Subject } from 'rxjs';
import { catchError, map, mergeMap, takeUntil } from 'rxjs/operators';
import type { DatalakeOnlyProvider, ISortEvent, SupportedFileType } from '@dataportal/datalake-parsing';
import { FileParserAndFormatterService, IDatalakeObject } from '@dataportal/datalake-parsing';
import type { IAzureMetadata } from '@dataportal/front-shared';
import { AlertService, Logger } from '@dataportal/front-shared';

import { DatalakeStoreService } from '../../services/datalake-store.service';
import { ExplorerDownloadUploadUrlService } from '../../services/explorer-download-upload-url.service';

import type { ICellEdit } from '../../models/cell-edit.model';

import type { IDatalakeStoreOptions } from '../../entities/datalake';
import type { IDatalakeUploadResponse } from '../../services/explorer-download-upload-url.service';
import { FileViewService } from '../../services/file-view.serivce';

@Component({
  selector: 'dpg-edition-modal',
  templateUrl: './edition-modal.component.html',
  providers: [FileParserAndFormatterService, FileViewService], // do not use FileParserAndFormatterService as singleton
})
export class EditionModalComponent implements OnInit, OnDestroy {
  @Input() selectedFile: IDatalakeObject; // file that has been selected
  @Input() fileContentType: string = null; // null for new tab
  @Input() isDownloadButtonShown: boolean;
  @Input() isReady = false;
  @Input() delimiterWhenUpload: string = null;

  // constants
  // path
  private readonly _datalakePathName = 'datalake'; // FIXME (IN DPG) : by default, name of the routing path for datalake (to generalize with subrouting)
  private readonly _addedUrlRepo = 'overview';

  // globals
  hasBeenModified = false;
  isSaving = false;
  downloadedBlob: Blob;
  currentFile: { name: string; provider: DatalakeOnlyProvider; tenant: string; path: string } = {
    name: '',
    provider: undefined,
    tenant: '',
    path: '',
  }; // current file (not necessary selected)
  isFileContentTypeIncorrect = true;
  isFileTooBig = false;
  fileExtension = '';
  fileType: SupportedFileType;

  openInNewTabUrl = '';

  private readonly _destroyed$ = new Subject<void>();

  constructor(
    private readonly _activeMatModal: MatDialogRef<EditionModalComponent>,
    private readonly _http: HttpClient,
    private readonly _logger: Logger,
    private readonly _alertService: AlertService,
    private readonly _explorerDownloadUrlService: ExplorerDownloadUploadUrlService,
    private readonly _fileParserAndFormatterService: FileParserAndFormatterService,
    private readonly _datalakeStoreService: DatalakeStoreService,
    private readonly _fileViewService: FileViewService,
    @Inject(MAT_DIALOG_DATA)
    data: {
      selectedFile: IDatalakeObject;
      fileContentType: string;
      isDownloadButtonShown: boolean;
      isReady: boolean;
      delimiterWhenUpload: string;
    },
  ) {
    this.selectedFile = data?.selectedFile ? data.selectedFile : this.selectedFile;
    this.fileContentType = data?.fileContentType?.length ? data.fileContentType : this.fileContentType;
    this.isDownloadButtonShown = Object.keys(data || {}).includes('isDownloadButtonShown')
      ? data.isDownloadButtonShown
      : this.isDownloadButtonShown;
    this.isReady = Object.keys(data || {}).includes('isReady') ? data.isReady : this.isReady;
    this.delimiterWhenUpload = data?.delimiterWhenUpload?.length ? data.delimiterWhenUpload : this.delimiterWhenUpload;
  }

  ngOnInit(): void {
    this._logger.debug('[Edition] ON INIT');
    this.loadData();

    this.openInNewTabUrl = this._fileViewService.createNewTabUrl({
      selectedFile: this.selectedFile,
      datalakePathName: this._datalakePathName,
      addedUrlRepo: this._addedUrlRepo,
    });
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
  }

  private _setFilesProperties(): void {
    this.fileType = this._fileParserAndFormatterService.getFileType(this.fileExtension);

    if (this.selectedFile) {
      this.isFileTooBig =
        this.selectedFile.size >
        this._fileParserAndFormatterService.options.edit.csv *
          this._fileParserAndFormatterService.options.sizeUnitFactor;
    }
  }

  private _showSaveError(): void {
    this._logger.debug('[EditionModalComponent] Error when saving modifications');
    this.isSaving = false;
    this._alertService.error('Error when saving modifications');
  }

  get datasets(): { rows: unknown[]; columns: unknown[] }[] {
    return this._fileParserAndFormatterService.datasets;
  }

  get csvDataset(): { rows: unknown[]; columns: unknown[] } {
    return this._fileParserAndFormatterService.csvDataset;
  }

  get currentRowsForPage(): unknown[] {
    return this._fileParserAndFormatterService.currentRowsForPage;
  }

  get currentPageNumber(): number {
    return this._fileParserAndFormatterService.currentPageNumber;
  }

  get hasErrorInParsing(): boolean {
    return this._fileParserAndFormatterService.hasErrorInParsing;
  }

  get paginationNbPerPageMax(): number {
    return this._fileParserAndFormatterService.paginationNbPerPageMax;
  }

  get isLoading(): boolean {
    return (
      !this.isReady &&
      !this.isFileContentTypeIncorrect &&
      !this.isFileTooBig &&
      !this._fileParserAndFormatterService.hasFinishedParsingAndFormatting
    );
  }

  get fileName(): string {
    return this.selectedFile && this.selectedFile.name ? this.selectedFile.name : this.currentFile.name;
  }

  get maxSizeEditCSV(): number {
    return this._fileParserAndFormatterService.options.edit.csv;
  }

  loadData(): void {
    this.fileExtension = this._fileParserAndFormatterService.getFileExtension(this.fileName);
    this.isFileContentTypeIncorrect = !this._fileParserAndFormatterService.isFileEditableOrOverviewable(
      this.fileExtension,
      this.fileContentType,
    );

    if (this.isFileContentTypeIncorrect) {
      return;
    }

    this._setFilesProperties();

    if (this.isFileTooBig) {
      return;
    }

    const downloadOptions = this._fileViewService.getDownloadOptions({
      selectedFile: this.selectedFile,
      datalakePathName: this._datalakePathName,
    });
    this._explorerDownloadUrlService
      .singleDownloadUrl(downloadOptions.bucket, downloadOptions.options)
      .pipe(
        catchError((err: unknown) => {
          this._logger.debug('[Edition] Error getting download URL : ', err);

          return of('');
        }),
        mergeMap((downloadUrl) => {
          this._logger.debug('[Edition] Download URL : ', downloadUrl);

          return this._http.get(downloadUrl, { responseType: 'blob', observe: 'response' }).pipe(
            map((result: HttpResponse<Blob>) => result.body),
            catchError((err: unknown) => {
              this._logger.debug('[Edition] Error downloading file via URL : ', err);

              return of(new Blob());
            }),
          );
        }),
        takeUntil(this._destroyed$),
      )
      .subscribe((result: Blob) => {
        this.downloadedBlob = result;
        this._fileParserAndFormatterService.parseFile(result, this.fileType);
      });
  }

  setPaginationPage(pageInfo, index: number) {
    this._fileParserAndFormatterService.setPaginationPage(pageInfo, index);
  }

  updateValue({ rowIndex, columnName, value }: ICellEdit) {
    this._fileParserAndFormatterService.setDatasetsValue(0, rowIndex, columnName, value);
    this.hasBeenModified = true;
  }

  upload(): void {
    if (!this.hasBeenModified) {
      return;
    }

    this._fileParserAndFormatterService.unparseCSVFile(this.delimiterWhenUpload);
    const toUpload = new File([this._fileParserAndFormatterService.csvFile], this.fileName, { type: 'text/csv' });
    const downloadOptions = this._fileViewService.getDownloadOptions({
      selectedFile: this.selectedFile,
      datalakePathName: this._datalakePathName,
    });
    const pathSegments = downloadOptions.options.path.split('/');
    pathSegments.pop();
    const pathWithoutFileName = pathSegments.join('/');
    const options = { ...downloadOptions.options, path: pathWithoutFileName, hasToFetchMetadata: true };

    this._logger.debug('[EditionModalComponent] Saving modifications...');
    this.isSaving = true;
    this._alertService.info('Saving modifications...');

    this._explorerDownloadUrlService
      .uploadUrl(downloadOptions.bucket, options.provider, options, toUpload, toUpload.name)
      .pipe(
        catchError(() => {
          this._showSaveError();

          return of(null);
        }),
        mergeMap((datalakeUploadResponse: IDatalakeUploadResponse) => {
          if (!datalakeUploadResponse?.event) {
            return;
          }

          let updatedMetadata: IAzureMetadata;
          let storeOptions: IDatalakeStoreOptions;

          switch (datalakeUploadResponse?.event?.type) {
            case HttpEventType.Sent:
            case HttpEventType.UploadProgress:
              return of(null);
            case HttpEventType.Response:
              if (!datalakeUploadResponse?.event?.ok) {
                this._showSaveError();

                return of(null);
              }

              this._logger.debug('[EditionModalComponent] Modifications have been successfully saved');
              this.isSaving = false;
              this._alertService.success('Modifications have been successfully saved');
              updatedMetadata = datalakeUploadResponse?.metadata || {};
              updatedMetadata.last_updated_by = '${currentUser}';
              storeOptions = {
                path: options.path,
                provider: options.provider,
                tenant: options.tenant,
                byUser: options.byUser,
                metadata: updatedMetadata,
              };
              this.hasBeenModified = false;

              return this._datalakeStoreService
                .postUploadObject(downloadOptions.bucket, storeOptions, toUpload)
                .then(() => {
                  this._activeMatModal.close(this.fileName);
                });
            default:
              this._logger.debug(
                `[EditionModalComponent] File surprising upload event: ${datalakeUploadResponse?.event.type}`,
              );

              return of(null);
          }
        }),
        takeUntil(this._destroyed$),
      )
      .subscribe();
  }

  sortIndications(sortEvent: ISortEvent): void {
    this._fileParserAndFormatterService.setSortOption(sortEvent);
  }

  downloadFile(): void {
    this._fileViewService.downloadFile({
      selectedFile: this.selectedFile,
      datalakePathName: this._datalakePathName,
    });
  }
}
