import type { OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractControl, FormControl } from '@ngneat/reactive-forms';

@Component({
  selector: 'adl-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
})
export class SelectComponent<T> implements OnChanges, OnInit {
  @Input() options: { value: T; label: string }[] = [];
  @Input() control: AbstractControl = new FormControl();

  @Input() placeholder = '';
  @Input() isDisabled: boolean;
  @Input() fixedSize: string;
  @Input() noBorder = false;

  @Input() isUsingFirstValueAsDefault = false;
  @Input() defaultValue: T;
  @Input() resetValueOnChange?: boolean = false;

  @Input() valueEqualityCheck: (value1: T, value2: T) => boolean = this.defaultEqualityCheck;

  @Input() forceDetectChange: Observable<boolean>;

  @Output() changed: EventEmitter<T> = new EventEmitter<T>();

  selectedValue: T;

  constructor(private readonly _changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    if (this.control.value !== null && this.control.value !== undefined) {
      this.selectedValue = this.findValueFromOptions(this.control.value);
    } else {
      this.selectedValue = '' as unknown as T;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.options) {
      this.selectedValue = this.findValueFromOptions(this.control.value);
    }

    if (changes.isUsingFirstValueAsDefault?.currentValue && this.options.length) {
      this.control.setValue(this.options[0].value);
    } else if (changes.defaultValue?.currentValue !== null && changes.defaultValue?.currentValue !== undefined) {
      this.control.setValue(this.defaultValue);
      this.selectedValue = this.findValueFromOptions(this.defaultValue);
    } else if (this.resetValueOnChange) {
      this.selectedValue = '' as unknown as T;
    }
  }

  findValueFromOptions(value: T): T {
    return this.options?.find((option) => this.valueEqualityCheck(option?.value, value))?.value;
  }

  defaultEqualityCheck(value1: T, value2: T): boolean {
    return value1 === value2;
  }

  onChange(event: T): void {
    this.control.setValue(event);
    this.control.markAsDirty();
    this.changed.next(event);
  }
}
