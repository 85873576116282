import { Injectable } from '@angular/core';

import { SharedModule } from '../shared.module';

import { LocalStorageService } from './local-storage.service';
import { Logger } from './logger.service';

export interface IE2EValue {
  enabled: boolean;
  testUserId: string;
}

@Injectable({
  providedIn: SharedModule,
})
export class E2EService {
  private static readonly _requiredFields: (keyof IE2EValue)[] = ['enabled', 'testUserId'];
  private static readonly _storageKey = 'E2E';

  private _memoryCache: IE2EValue;

  constructor(private readonly _localStorageService: LocalStorageService, private readonly _logger: Logger) {}

  isEnabled(): boolean {
    return !!this._getStorageValue()?.enabled;
  }

  getTestUserId(): string | null {
    return this._getStorageValue()?.testUserId ?? null;
  }

  private _getStorageValue(): IE2EValue | null {
    if (this._memoryCache) {
      this._logger.debug('[E2E Service][Storage] Memory cache is used.', JSON.stringify(this._memoryCache));

      return this._memoryCache;
    }

    try {
      const value = this._localStorageService.getItem(E2EService._storageKey);

      if (!value) {
        return null;
      }

      this._memoryCache = this._validate(JSON.parse(value));
      this._logger.debug('[E2E Service][Storage] New value is retrieved.', JSON.stringify(this._memoryCache));

      return this._memoryCache;
    } catch (error) {
      const finalError = new Error(`[E2E Service][Storage] ${error.message}.`);
      this._logger.error(finalError.message);

      throw finalError;
    }
  }

  private _validate(value: unknown): IE2EValue {
    if (!value || typeof value !== 'object') {
      throw new Error('The storage contains invalid value');
    }

    for (const field of E2EService._requiredFields) {
      // TODO Object.hasOwn should be used instead of hasOwnProperty once es2022 lib is applied (es2021 is used).
      // eslint-disable-next-line no-prototype-builtins
      if (!value.hasOwnProperty(field)) {
        throw new Error(`The required field "${field}" is missing`);
      }
    }

    return value as IE2EValue;
  }
}
