import type { IDirectionalLinkCommon, LinkDirection } from '@dataportal/types';
import { Entity } from '@decahedron/entity';

export abstract class SourceLink extends Entity {
  // Attributes
  id: string = null;
  label: string = null;
  createdBy: string = null;
  lastUpdatedBy: string = null;
  usedBy: number = null;
  type: LinkDirection;

  // Only for bidirectional, but set here to not have to always cast
  reverseLabel: string = null;
  editable: boolean = null;
  reverseEditable: boolean = null;
  approvalRequired: boolean = null;

  fromJson(jsonData: IDirectionalLinkCommon): SourceLink {
    this.id = jsonData.pk;
    this.label = jsonData.label;
    this.createdBy = jsonData.createdBy;
    this.lastUpdatedBy = jsonData.lastUpdatedBy;
    this.usedBy = jsonData.usedBy;

    return this;
  }

  // Methods
  toJson(): IDirectionalLinkCommon {
    return {
      pk: this.id,
      sk: 'sources|links',
      label: this.label,
      createdBy: this.createdBy,
      lastUpdatedBy: this.lastUpdatedBy,
      usedBy: this.usedBy,
    };
  }
}
