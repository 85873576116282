import { Injectable } from '@angular/core';
import type {
  IAPIDatalakeReferential,
  IAPITableReferential,
  IDatalakeAPICheck,
  IGuardianAPIChecksMap,
  IGuardianAPIRequestCheckFieldOptions,
  ISnowflakeAPICheck,
} from '@dataportal/front-api';

import { GuardianService } from './guardian.service';

import type {
  IGuardianCheckScheduling,
  IGuardianStatus,
  ISupportedGuardianChecksResource,
} from '../entities/guardian-form';
import type {
  IExportedCheckCommon,
  IExportedCheckDatalake,
  IExportedCheckSnowflake,
  IExportedDagScheduleInterval,
  IExportedDatalakeReferential,
  IExportedRequestCheckFieldOptions,
  IExportedTableReferential,
  IGuardianImportConfig,
} from '../entities/guardian-import-export';

// Service
@Injectable()
export class GuardianImportExportCheckService {
  static USER_FRIENDLY_MDH_REFERENTIAL_FIELD_NAME = 'MDH dataset';
  static USER_FRIENDLY_DATALAKE_IN_REFERENTIAL_FIELD_NAME = 'Excel / CSV file whitelist';
  static USER_FRIENDLY_DATALAKE_NOT_IN_REFERENTIAL_FIELD_NAME = 'Excel / CSV file blacklist';
  static INDEX_BETWEEN_API_FIELD_NAMING_AND_USER_FRIENDLY_NAMING_GLOBAL: Array<{
    apiNaming: string;
    userFriendlyNaming: string;
  }> = [
    /* global */
    { apiNaming: 'id_check', userFriendlyNaming: 'check_id' },
    { apiNaming: 'name', userFriendlyNaming: 'check_title' },
    /* step 1 */
    { apiNaming: 'file_format', userFriendlyNaming: 'file_format' },
    { apiNaming: 'delimiter', userFriendlyNaming: 'file_delimiter' },
    { apiNaming: 'sheet_name', userFriendlyNaming: 'sheet_name' },
    { apiNaming: 'decimal', userFriendlyNaming: 'decimal_separator' },
    { apiNaming: 'is_flatfile_deactivate', userFriendlyNaming: 'uploaded_file_above_100MB' },
    { apiNaming: 'regex', userFriendlyNaming: 'matching_file_regular_expression' },
    { apiNaming: 'email_if_ok', userFriendlyNaming: 'alert_recipients_if_check_success' },
    { apiNaming: 'email_if_not_ok', userFriendlyNaming: 'alert_recipients_if_check_failure' },
    { apiNaming: 'email_if_exception', userFriendlyNaming: 'alert_recipients_if_check_error' },
    /* step 2 */
    { apiNaming: 'validation_schema_columns', userFriendlyNaming: 'columns' },
    /* step 3 */
    { apiNaming: 'target_folder_if_ok', userFriendlyNaming: 'target_path_on_success' },
    { apiNaming: 'target_folder_if_error', userFriendlyNaming: 'target_path_on_error' },
    { apiNaming: 'enforce_replacement', userFriendlyNaming: 'enforce_replacement_on_moving' },
    { apiNaming: 'write_result', userFriendlyNaming: 'generate_result_file' },
    { apiNaming: 'dag_schedule_interval', userFriendlyNaming: 'scheduling' },
  ];
  static INDEX_BETWEEN_API_FIELD_NAMING_AND_USER_FRIENDLY_NAMING_DATALAKE_PATH: Array<{
    apiNaming: string;
    userFriendlyNaming: string;
  }> = [
    /* global */
    { apiNaming: 'prefix', userFriendlyNaming: 'check_path' },
    ...GuardianImportExportCheckService.INDEX_BETWEEN_API_FIELD_NAMING_AND_USER_FRIENDLY_NAMING_GLOBAL,
  ];
  static INDEX_BETWEEN_API_FIELD_NAMING_AND_USER_FRIENDLY_NAMING_SNOWFLAKE: Array<{
    apiNaming: string;
    userFriendlyNaming: string;
  }> = [
    /* global */
    { apiNaming: 'prefix', userFriendlyNaming: 'check_table' },
    ...GuardianImportExportCheckService.INDEX_BETWEEN_API_FIELD_NAMING_AND_USER_FRIENDLY_NAMING_GLOBAL,
  ];
  static INDEX_BETWEEN_FORM_SCHEDULING_FIELD_NAMING_AND_USER_FRIENDLY_NAMING: Array<{
    formNaming: string;
    userFriendlyNaming: string;
  }> = [
    { formNaming: 'schedulingPeriodicity', userFriendlyNaming: 'periodicity' },
    { formNaming: 'schedulingStartingTime', userFriendlyNaming: 'starting_time' },
    { formNaming: 'schedulingDailyChoiceSelected', userFriendlyNaming: 'daily_choice_selected' },
    { formNaming: 'schedulingDailyEveryNumberOfDays', userFriendlyNaming: 'every_n_days' },
    { formNaming: 'schedulingWeeklySelectedDays', userFriendlyNaming: 'selected_days' },
    { formNaming: 'schedulingMonthlyDayNumber', userFriendlyNaming: 'monthly_day_number' },
    { formNaming: 'schedulingMonthlyEveryNumberOfMonths', userFriendlyNaming: 'every_n_months' },
    { formNaming: 'schedulingYearlyDayNumber', userFriendlyNaming: 'yearly_day_number' },
    { formNaming: 'schedulingYearlySelectedMonth', userFriendlyNaming: 'selected_month' },
  ];
  static INDEX_BETWEEN_FORM_SCHEDULING_TIME_FIELD_NAMING_AND_USER_FRIENDLY_NAMING: Array<{
    formNaming: string;
    userFriendlyNaming: string;
  }> = [
    { formNaming: 'schedulingStartingHour', userFriendlyNaming: 'starting_hour' },
    { formNaming: 'schedulingStartingMinute', userFriendlyNaming: 'starting_minute' },
    { formNaming: 'schedulingStartingHourPeriod', userFriendlyNaming: 'starting_hour_period' },
  ];

  constructor(private readonly _guardianService: GuardianService) {}

  /* utils */

  // API naming

  static getAllAPIUserFriendlyNaming(checkType: ISupportedGuardianChecksResource): string[] {
    return checkType === 'datalakePath'
      ? GuardianImportExportCheckService.INDEX_BETWEEN_API_FIELD_NAMING_AND_USER_FRIENDLY_NAMING_DATALAKE_PATH.map(
          (indexEntry) => indexEntry.userFriendlyNaming,
        )
      : GuardianImportExportCheckService.INDEX_BETWEEN_API_FIELD_NAMING_AND_USER_FRIENDLY_NAMING_SNOWFLAKE.map(
          (indexEntry) => indexEntry.userFriendlyNaming,
        );
  }

  static getUserFriendlyNamingFromAPIFieldNaming(apiFieldNaming: string): string {
    if (!apiFieldNaming?.length) {
      return null;
    }

    const userFriendlyNamingFetched =
      GuardianImportExportCheckService.INDEX_BETWEEN_API_FIELD_NAMING_AND_USER_FRIENDLY_NAMING_GLOBAL.filter(
        (indexEntry) => indexEntry.apiNaming === apiFieldNaming,
      );

    return userFriendlyNamingFetched.length ? userFriendlyNamingFetched[0].userFriendlyNaming : null;
  }

  static getAPIFieldNamingFromUserFriendlyNaming(userFriendlyNaming: string): string {
    if (!userFriendlyNaming?.length) {
      return null;
    }

    const apiNamingFetched =
      GuardianImportExportCheckService.INDEX_BETWEEN_API_FIELD_NAMING_AND_USER_FRIENDLY_NAMING_GLOBAL.filter(
        (indexEntry) => indexEntry.userFriendlyNaming === userFriendlyNaming,
      );

    return apiNamingFetched.length ? apiNamingFetched[0].apiNaming : null;
  }

  // scheduling form naming

  static getAllFormSchedulingUserFriendlyNaming(): string[] {
    return GuardianImportExportCheckService.INDEX_BETWEEN_FORM_SCHEDULING_FIELD_NAMING_AND_USER_FRIENDLY_NAMING.map(
      (indexEntry) => indexEntry.userFriendlyNaming,
    );
  }

  static getUserFriendlyNamingFromFormSchedulingFieldNaming(formFieldNaming: string): string {
    if (!formFieldNaming?.length) {
      return null;
    }

    const userFriendlyNamingFetched =
      GuardianImportExportCheckService.INDEX_BETWEEN_FORM_SCHEDULING_FIELD_NAMING_AND_USER_FRIENDLY_NAMING.filter(
        (indexEntry) => indexEntry.formNaming === formFieldNaming,
      );

    return userFriendlyNamingFetched.length ? userFriendlyNamingFetched[0].userFriendlyNaming : null;
  }

  static getFormSchedulingFieldNamingFromUserFriendlyNaming(userFriendlyNaming: string): string {
    if (!userFriendlyNaming?.length) {
      return null;
    }

    const formNamingFetched =
      GuardianImportExportCheckService.INDEX_BETWEEN_FORM_SCHEDULING_FIELD_NAMING_AND_USER_FRIENDLY_NAMING.filter(
        (indexEntry) => indexEntry.userFriendlyNaming === userFriendlyNaming,
      );

    return formNamingFetched.length ? formNamingFetched[0].formNaming : null;
  }

  static getAllFormSchedulingTimeUserFriendlyNaming(): string[] {
    return GuardianImportExportCheckService.INDEX_BETWEEN_FORM_SCHEDULING_TIME_FIELD_NAMING_AND_USER_FRIENDLY_NAMING.map(
      (indexEntry) => indexEntry.userFriendlyNaming,
    );
  }

  static getUserFriendlyNamingFromFormSchedulingTimeFieldNaming(formFieldNaming: string): string {
    if (!formFieldNaming?.length) {
      return null;
    }

    const userFriendlyNamingFetched =
      GuardianImportExportCheckService.INDEX_BETWEEN_FORM_SCHEDULING_TIME_FIELD_NAMING_AND_USER_FRIENDLY_NAMING.filter(
        (indexEntry) => indexEntry.formNaming === formFieldNaming,
      );

    return userFriendlyNamingFetched.length ? userFriendlyNamingFetched[0].userFriendlyNaming : null;
  }

  static getFormSchedulingTimeFieldNamingFromUserFriendlyNaming(userFriendlyNaming: string): string {
    if (!userFriendlyNaming?.length) {
      return null;
    }

    const formNamingFetched =
      GuardianImportExportCheckService.INDEX_BETWEEN_FORM_SCHEDULING_TIME_FIELD_NAMING_AND_USER_FRIENDLY_NAMING.filter(
        (indexEntry) => indexEntry.userFriendlyNaming === userFriendlyNaming,
      );

    return formNamingFetched.length ? formNamingFetched[0].formNaming : null;
  }

  // other utils

  isUserFriendlyTextField(userfriendlyField: IExportedRequestCheckFieldOptions): boolean {
    return userfriendlyField?.type?.length && userfriendlyField.type === 'Text';
  }

  isUserFriendlyNumericField(userfriendlyField: IExportedRequestCheckFieldOptions): boolean {
    return (
      userfriendlyField?.type?.length &&
      (userfriendlyField.type === this._guardianService.getTypeToDisplay('integer', 'datalakePath') ||
        userfriendlyField.type === this._guardianService.getTypeToDisplay('float', 'datalakePath') ||
        userfriendlyField.type === this._guardianService.getTypeToDisplay('datetime', 'datalakePath'))
    );
  }

  static hasUserFriendlyRegexReferential(userfriendlyField: IExportedRequestCheckFieldOptions): boolean {
    return !!userfriendlyField.regex?.length;
  }

  static isUserFriendlySnowflakeReferential(userFriendlyField: IExportedRequestCheckFieldOptions): boolean {
    return (
      Object.keys(userFriendlyField.external_referential || {}).includes('type') &&
      userFriendlyField.external_referential.type ===
        GuardianImportExportCheckService.USER_FRIENDLY_MDH_REFERENTIAL_FIELD_NAME
    );
  }

  static isUserFriendlyDatalakeInReferential(userFriendlyField: IExportedRequestCheckFieldOptions): boolean {
    return (
      Object.keys(userFriendlyField.external_referential || {}).includes('type') &&
      userFriendlyField.external_referential.type ===
        GuardianImportExportCheckService.USER_FRIENDLY_DATALAKE_IN_REFERENTIAL_FIELD_NAME
    );
  }

  static isUserFriendlyDatalakeNotInReferential(userFriendlyField: IExportedRequestCheckFieldOptions): boolean {
    return (
      Object.keys(userFriendlyField.external_referential_blacklisted || {}).includes('type') &&
      userFriendlyField.external_referential_blacklisted.type ===
        GuardianImportExportCheckService.USER_FRIENDLY_DATALAKE_NOT_IN_REFERENTIAL_FIELD_NAME
    );
  }

  getExternalReferentialSnowflakeFromUserFriendlyReferentialSnowflake(
    userFriendlyExternalSnowflakeReferential: IExportedTableReferential,
  ): IAPITableReferential {
    const referentialFound = this._guardianService
      .getExternalReferentialsListCurrentValue()
      .find((fetchedReferential) => fetchedReferential.label === userFriendlyExternalSnowflakeReferential.label);

    return referentialFound?.infos ? (referentialFound.infos as IAPITableReferential) : null;
  }

  /* export */

  static getUserFriendlySchedulingFromAPICron(schedulingCron: string): IExportedDagScheduleInterval {
    if (!schedulingCron?.length) {
      return null;
    }

    const schedulingInfos = GuardianService.convertToSchedulingInfos(schedulingCron);

    if (!schedulingInfos) {
      return null;
    }

    const exportedDagScheduleInterval = {} as IExportedDagScheduleInterval;
    exportedDagScheduleInterval[
      GuardianImportExportCheckService.getUserFriendlyNamingFromFormSchedulingFieldNaming('schedulingPeriodicity')
    ] = schedulingInfos.schedulingPeriodicity;
    exportedDagScheduleInterval[
      GuardianImportExportCheckService.getUserFriendlyNamingFromFormSchedulingFieldNaming('schedulingStartingTime')
    ] = {
      [GuardianImportExportCheckService.getUserFriendlyNamingFromFormSchedulingTimeFieldNaming(
        'schedulingStartingHour',
      )]: schedulingInfos.schedulingStartingTime?.schedulingStartingHour,
      [GuardianImportExportCheckService.getUserFriendlyNamingFromFormSchedulingTimeFieldNaming(
        'schedulingStartingMinute',
      )]: schedulingInfos.schedulingStartingTime?.schedulingStartingMinute,
      [GuardianImportExportCheckService.getUserFriendlyNamingFromFormSchedulingTimeFieldNaming(
        'schedulingStartingHourPeriod',
      )]: schedulingInfos.schedulingStartingTime?.schedulingStartingHourPeriod,
    };

    switch (schedulingInfos.schedulingPeriodicity) {
      case 'daily':
        exportedDagScheduleInterval[
          GuardianImportExportCheckService.getUserFriendlyNamingFromFormSchedulingFieldNaming(
            'schedulingDailyChoiceSelected',
          )
        ] = schedulingInfos.schedulingDailyChoiceSelected;
        exportedDagScheduleInterval[
          GuardianImportExportCheckService.getUserFriendlyNamingFromFormSchedulingFieldNaming(
            'schedulingDailyEveryNumberOfDays',
          )
        ] = schedulingInfos.schedulingDailyEveryNumberOfDays;
        break;
      case 'weekly':
        exportedDagScheduleInterval[
          GuardianImportExportCheckService.getUserFriendlyNamingFromFormSchedulingFieldNaming(
            'schedulingWeeklySelectedDays',
          )
        ] = schedulingInfos.schedulingWeeklySelectedDays;
        break;
      case 'monthly':
        exportedDagScheduleInterval[
          GuardianImportExportCheckService.getUserFriendlyNamingFromFormSchedulingFieldNaming(
            'schedulingMonthlyDayNumber',
          )
        ] = schedulingInfos.schedulingMonthlyDayNumber;
        exportedDagScheduleInterval[
          GuardianImportExportCheckService.getUserFriendlyNamingFromFormSchedulingFieldNaming(
            'schedulingMonthlyEveryNumberOfMonths',
          )
        ] = parseInt(schedulingInfos.schedulingMonthlyEveryNumberOfMonths, 10);
        break;
      case 'yearly':
        exportedDagScheduleInterval[
          GuardianImportExportCheckService.getUserFriendlyNamingFromFormSchedulingFieldNaming(
            'schedulingYearlyDayNumber',
          )
        ] = schedulingInfos.schedulingYearlyDayNumber;
        exportedDagScheduleInterval[
          GuardianImportExportCheckService.getUserFriendlyNamingFromFormSchedulingFieldNaming(
            'schedulingYearlySelectedMonth',
          )
        ] = schedulingInfos.schedulingYearlySelectedMonth;
        break;
      default:
        return null;
    }

    return exportedDagScheduleInterval;
  }

  private _convertAPISchemaColumnsToUserFriendlySchemaColumns(
    columns: IGuardianAPIRequestCheckFieldOptions,
    checkType: ISupportedGuardianChecksResource,
  ): IExportedRequestCheckFieldOptions[] {
    const orderedColumns = GuardianService.getOrderedValidationSchemaColumnsArray(columns);

    return orderedColumns.map((currentColumn) => {
      // TODO: [Revisit] (18.01.2024) - Remove any
      const userFriendlySchemaColumn: any = { column_name: currentColumn.column_name };

      if (currentColumn.type?.length) {
        userFriendlySchemaColumn.type = this._guardianService.getTypeToDisplay(currentColumn.type, checkType);
      }

      if (currentColumn.required_values !== null && currentColumn.required_values !== undefined) {
        userFriendlySchemaColumn.must_be_filled = currentColumn.required_values;
      }

      if (currentColumn.unique !== null && currentColumn.unique !== undefined) {
        userFriendlySchemaColumn.unique = currentColumn.unique;
      }

      if (!GuardianService.isTextField(currentColumn)) {
        if (currentColumn.min !== null && currentColumn.min !== undefined) {
          userFriendlySchemaColumn.min = currentColumn.min;
        }

        if (currentColumn.max !== null && currentColumn.max !== undefined) {
          userFriendlySchemaColumn.max = currentColumn.max;
        }

        if (currentColumn.pair_greater?.length) {
          userFriendlySchemaColumn.greater_than = currentColumn.pair_greater;
        }
      }

      if (!GuardianService.isNumericField(currentColumn) && GuardianService.hasRegexReferential(currentColumn)) {
        userFriendlySchemaColumn.regex = this._guardianService.getFieldCheckRegexLabel(currentColumn.regex);
      }

      if (!GuardianService.isNumericField(currentColumn)) {
        if (GuardianService.hasSnowflakeReferential(currentColumn, true)) {
          const snowflakeReferential = currentColumn.values_in as IAPITableReferential;
          const snowflakeReferentialLabel =
            this._guardianService.getExternalReferentialSnowflakeLabel(snowflakeReferential);
          userFriendlySchemaColumn.external_referential = {
            type: GuardianImportExportCheckService.USER_FRIENDLY_MDH_REFERENTIAL_FIELD_NAME,
            label: snowflakeReferentialLabel,
          };
        } else {
          if (GuardianService.hasDatalakeReferential(currentColumn, true)) {
            const datalakeReferential = currentColumn.values_in as IAPIDatalakeReferential;
            userFriendlySchemaColumn.external_referential = {
              type: GuardianImportExportCheckService.USER_FRIENDLY_DATALAKE_IN_REFERENTIAL_FIELD_NAME,
              path: `${datalakeReferential.bucket_name}/${datalakeReferential.prefix}`,
              data_platform: datalakeReferential.datalake_params?.azure_storage_account,
              provider: datalakeReferential.datalake,
            };
          }

          if (GuardianService.hasDatalakeReferential(currentColumn, false)) {
            const datalakeReferential = currentColumn.values_not_in as IAPIDatalakeReferential;
            userFriendlySchemaColumn.external_referential_blacklisted = {
              type: GuardianImportExportCheckService.USER_FRIENDLY_DATALAKE_NOT_IN_REFERENTIAL_FIELD_NAME,
              path: `${datalakeReferential.bucket_name}/${datalakeReferential.prefix}`,
              data_platform: datalakeReferential.datalake_params?.azure_storage_account,
              provider: datalakeReferential.datalake,
            };
          }
        }
      }

      return userFriendlySchemaColumn;
    });
  }

  private _buildUserFriendlyDatalakePathCheckObject(currentCheck: IGuardianStatus) {
    // TODO: [Revisit] (18.01.2024) - remove any
    const userFriendlyObject: any = {};
    const datalakeCurrentCheckInfos = currentCheck.checkInfos as IDatalakeAPICheck;
    userFriendlyObject.check_path = `${datalakeCurrentCheckInfos.bucket_name}/${datalakeCurrentCheckInfos.prefix}`;
    userFriendlyObject.file_format =
      datalakeCurrentCheckInfos[
        GuardianImportExportCheckService.getUserFriendlyNamingFromAPIFieldNaming('file_format')
      ];
    userFriendlyObject[
      GuardianImportExportCheckService.getUserFriendlyNamingFromAPIFieldNaming('is_flatfile_deactivate')
    ] = currentCheck.isFlatfileDeactivate;

    // step 1
    if (datalakeCurrentCheckInfos.delimiter?.length) {
      userFriendlyObject[GuardianImportExportCheckService.getUserFriendlyNamingFromAPIFieldNaming('delimiter')] =
        GuardianService.getDelimiterToDisplay(datalakeCurrentCheckInfos.delimiter);
    }

    if (datalakeCurrentCheckInfos.sheet_name?.length) {
      userFriendlyObject[GuardianImportExportCheckService.getUserFriendlyNamingFromAPIFieldNaming('sheet_name')] =
        datalakeCurrentCheckInfos.sheet_name;
    }

    if (datalakeCurrentCheckInfos.decimal?.length) {
      userFriendlyObject[GuardianImportExportCheckService.getUserFriendlyNamingFromAPIFieldNaming('decimal')] =
        datalakeCurrentCheckInfos.decimal;
    }

    if (datalakeCurrentCheckInfos.regex?.length) {
      userFriendlyObject[GuardianImportExportCheckService.getUserFriendlyNamingFromAPIFieldNaming('regex')] =
        this._guardianService.getNameFilterRegexLabel(datalakeCurrentCheckInfos.regex);
    }

    // step 3
    if (datalakeCurrentCheckInfos.target_folder_if_ok?.length) {
      userFriendlyObject[
        GuardianImportExportCheckService.getUserFriendlyNamingFromAPIFieldNaming('target_folder_if_ok')
      ] = datalakeCurrentCheckInfos.target_folder_if_ok;
    }

    if (datalakeCurrentCheckInfos.target_folder_if_error?.length) {
      userFriendlyObject[
        GuardianImportExportCheckService.getUserFriendlyNamingFromAPIFieldNaming('target_folder_if_error')
      ] = datalakeCurrentCheckInfos.target_folder_if_error;
    }

    if (datalakeCurrentCheckInfos.target_folder_if_ok || datalakeCurrentCheckInfos.target_folder_if_error) {
      userFriendlyObject[
        GuardianImportExportCheckService.getUserFriendlyNamingFromAPIFieldNaming('enforce_replacement')
      ] = datalakeCurrentCheckInfos.enforce_replacement;
    }

    return userFriendlyObject;
  }

  exportCheckFromGuardianStatus(currentCheck: IGuardianStatus): IExportedCheckCommon {
    const guardianStatusCheckInfos = currentCheck.checkInfos;
    const userFriendlyGuardianStatusCheckInfos = {
      check_id: guardianStatusCheckInfos.id_check,
      check_title: guardianStatusCheckInfos.name,
      // step 2
      columns: this._convertAPISchemaColumnsToUserFriendlySchemaColumns(
        guardianStatusCheckInfos.validation_schema_columns,
        currentCheck.type,
      ),
    };

    // step 1
    if (guardianStatusCheckInfos.email_if_ok?.length > 2) {
      userFriendlyGuardianStatusCheckInfos[
        GuardianImportExportCheckService.getUserFriendlyNamingFromAPIFieldNaming('email_if_ok')
      ] = GuardianService.convertGuardianStringArrayToArray(guardianStatusCheckInfos.email_if_ok);
    }

    if (guardianStatusCheckInfos.email_if_not_ok?.length > 2) {
      userFriendlyGuardianStatusCheckInfos[
        GuardianImportExportCheckService.getUserFriendlyNamingFromAPIFieldNaming('email_if_not_ok')
      ] = GuardianService.convertGuardianStringArrayToArray(guardianStatusCheckInfos.email_if_not_ok);
    }

    if (guardianStatusCheckInfos.email_if_exception?.length > 2) {
      userFriendlyGuardianStatusCheckInfos[
        GuardianImportExportCheckService.getUserFriendlyNamingFromAPIFieldNaming('email_if_exception')
      ] = GuardianService.convertGuardianStringArrayToArray(guardianStatusCheckInfos.email_if_exception);
    }

    userFriendlyGuardianStatusCheckInfos[
      GuardianImportExportCheckService.getUserFriendlyNamingFromAPIFieldNaming('write_result')
    ] = guardianStatusCheckInfos.write_result;

    if (guardianStatusCheckInfos.dag_schedule_interval?.length) {
      userFriendlyGuardianStatusCheckInfos[
        GuardianImportExportCheckService.getUserFriendlyNamingFromAPIFieldNaming('dag_schedule_interval')
      ] = GuardianImportExportCheckService.getUserFriendlySchedulingFromAPICron(
        guardianStatusCheckInfos.dag_schedule_interval,
      );
    }

    if (currentCheck.type === 'datalakePath') {
      Object.assign(userFriendlyGuardianStatusCheckInfos, this._buildUserFriendlyDatalakePathCheckObject(currentCheck));
    } else {
      // currentCheck.type equals 'snowflake'
      const snowflakeCurrentCheckInfos = currentCheck.checkInfos as ISnowflakeAPICheck;
      // TODO: [Revisit] (18.01.2024) - Update type
      (
        userFriendlyGuardianStatusCheckInfos as typeof userFriendlyGuardianStatusCheckInfos & { check_table: string }
      ).check_table = snowflakeCurrentCheckInfos.sql_table;
    }

    return userFriendlyGuardianStatusCheckInfos;
  }

  /* import */

  static getAPICronFromUserFriendlyScheduling(userFriendlyScheduling: IExportedDagScheduleInterval): string {
    if (!userFriendlyScheduling) {
      return null;
    }

    const schedulingInfos = {} as IGuardianCheckScheduling;
    schedulingInfos[
      GuardianImportExportCheckService.getFormSchedulingFieldNamingFromUserFriendlyNaming('periodicity')
    ] = userFriendlyScheduling.periodicity;
    schedulingInfos[
      GuardianImportExportCheckService.getFormSchedulingFieldNamingFromUserFriendlyNaming('starting_time')
    ] = {
      [GuardianImportExportCheckService.getFormSchedulingTimeFieldNamingFromUserFriendlyNaming('starting_hour')]:
        userFriendlyScheduling.starting_time?.starting_hour,
      [GuardianImportExportCheckService.getFormSchedulingTimeFieldNamingFromUserFriendlyNaming('starting_minute')]:
        userFriendlyScheduling.starting_time?.starting_minute,
      [GuardianImportExportCheckService.getFormSchedulingTimeFieldNamingFromUserFriendlyNaming('starting_hour_period')]:
        userFriendlyScheduling.starting_time?.starting_hour_period,
    };

    switch (userFriendlyScheduling.periodicity) {
      case 'daily':
        schedulingInfos[
          GuardianImportExportCheckService.getFormSchedulingFieldNamingFromUserFriendlyNaming('daily_choice_selected')
        ] = userFriendlyScheduling.daily_choice_selected;
        schedulingInfos[
          GuardianImportExportCheckService.getFormSchedulingFieldNamingFromUserFriendlyNaming('every_n_days')
        ] = userFriendlyScheduling.every_n_days;
        break;
      case 'weekly':
        schedulingInfos[
          GuardianImportExportCheckService.getFormSchedulingFieldNamingFromUserFriendlyNaming('selected_days')
        ] = userFriendlyScheduling.selected_days;
        break;
      case 'monthly':
        schedulingInfos[
          GuardianImportExportCheckService.getFormSchedulingFieldNamingFromUserFriendlyNaming('monthly_day_number')
        ] = userFriendlyScheduling.monthly_day_number;
        schedulingInfos[
          GuardianImportExportCheckService.getFormSchedulingFieldNamingFromUserFriendlyNaming('every_n_months')
        ] = userFriendlyScheduling.every_n_months?.toString();
        break;
      case 'yearly':
        schedulingInfos[
          GuardianImportExportCheckService.getFormSchedulingFieldNamingFromUserFriendlyNaming('yearly_day_number')
        ] = userFriendlyScheduling.yearly_day_number;
        schedulingInfos[
          GuardianImportExportCheckService.getFormSchedulingFieldNamingFromUserFriendlyNaming('selected_month')
        ] = userFriendlyScheduling.selected_month;
        break;
      default:
        return null;
    }

    return GuardianService.convertToCron(schedulingInfos);
  }

  private _convertUserFriendlySchemaColumnsToAPISchemaColumns(
    importedColumns: (IExportedRequestCheckFieldOptions & { required?: null | boolean })[],
    checkType: ISupportedGuardianChecksResource,
  ): IGuardianAPIRequestCheckFieldOptions {
    if (!importedColumns?.length) {
      return null;
    }

    return importedColumns.reduce((currentObjectToReturn, currentColumn, index) => {
      const currentColumnName = currentColumn.column_name;
      // FIXME(2153): Supporting legacy with Jira card BIGWEBADMI-2153, but to remove 'currentMustBeFilledValue' because required will no longer be used after a period of time
      const currentMustBeFilledValue =
        currentColumn.must_be_filled !== null && currentColumn.must_be_filled !== undefined
          ? currentColumn.must_be_filled
          : currentColumn.required !== null && currentColumn.required !== undefined
          ? currentColumn.required
          : null;
      currentObjectToReturn[currentColumnName] = {
        type: this._guardianService.getTypeFromTypeToDisplay(currentColumn.type, checkType),
        required_values: currentMustBeFilledValue,
        unique: currentColumn.unique,
        constraint_id: index,
      };

      if (!this.isUserFriendlyTextField(currentColumn)) {
        if (currentColumn.min !== null && currentColumn.min !== undefined) {
          currentObjectToReturn[currentColumnName].min = currentColumn.min;
        }

        if (currentColumn.max !== null && currentColumn.max !== undefined) {
          currentObjectToReturn[currentColumnName].max = currentColumn.max;
        }

        if (currentColumn.greater_than?.length) {
          currentObjectToReturn[currentColumnName].pair_greater = currentColumn.greater_than;
        }
      }

      if (
        !this.isUserFriendlyNumericField(currentColumn) &&
        GuardianImportExportCheckService.hasUserFriendlyRegexReferential(currentColumn)
      ) {
        currentObjectToReturn[currentColumnName].regex =
          this._guardianService.getFieldCheckRegexFromFieldCheckRegexLabel(currentColumn.regex);
      }

      if (!this.isUserFriendlyNumericField(currentColumn)) {
        if (GuardianImportExportCheckService.isUserFriendlySnowflakeReferential(currentColumn)) {
          const userFriendlySnowflakeReferential = currentColumn.external_referential as IExportedTableReferential;
          currentObjectToReturn[currentColumnName].values_in =
            this.getExternalReferentialSnowflakeFromUserFriendlyReferentialSnowflake(userFriendlySnowflakeReferential);
        } else {
          if (GuardianImportExportCheckService.isUserFriendlyDatalakeInReferential(currentColumn)) {
            const userFriendlyDatalakeReferential = currentColumn.external_referential as IExportedDatalakeReferential;
            const pathSegments = userFriendlyDatalakeReferential.path?.split('/');
            const bucketName = pathSegments.shift();
            const pathWithoutBucketName = pathSegments.join('/');
            currentObjectToReturn[currentColumnName].values_in = {
              bucket_name: bucketName,
              prefix: pathWithoutBucketName,
              datalake: userFriendlyDatalakeReferential.provider,
              datalake_params: {
                azure_storage_account: userFriendlyDatalakeReferential.data_platform,
              },
            };
          }

          if (GuardianImportExportCheckService.isUserFriendlyDatalakeNotInReferential(currentColumn)) {
            const userFriendlyDatalakeReferential =
              currentColumn.external_referential_blacklisted as IExportedDatalakeReferential;
            const pathSegments = userFriendlyDatalakeReferential.path?.split('/');
            const bucketName = pathSegments.shift();
            const pathWithoutBucketName = pathSegments.join('/');
            currentObjectToReturn[currentColumnName].values_not_in = {
              bucket_name: bucketName,
              prefix: pathWithoutBucketName,
              datalake: userFriendlyDatalakeReferential.provider,
              datalake_params: {
                azure_storage_account: userFriendlyDatalakeReferential.data_platform,
              },
            };
          }
        }
      }

      return currentObjectToReturn;
    }, {} as IGuardianAPIRequestCheckFieldOptions);
  }

  /**
   * The check only made on the top-level fields of the imported JSON
   * @param importedCheck The imported JSON parsed file
   * @param checkType The type of the check can be 'datalakePath' or 'snowlflake'
   */
  static isImportedCheckWellFormatted(
    importedCheck: IExportedCheckCommon,
    checkType: ISupportedGuardianChecksResource,
  ): boolean {
    const importedCheckAPIKeys = Object.keys(importedCheck);
    const allAPIUserFriendlyNaming = GuardianImportExportCheckService.getAllAPIUserFriendlyNaming(checkType);
    const areImportedCheckAPIKeys = importedCheckAPIKeys.every((key) => allAPIUserFriendlyNaming.includes(key));
    const importedCheckFormSchedulingKeys = Object.keys(importedCheck.scheduling || {});
    const allFormSchedulingUserFriendlyNaming =
      GuardianImportExportCheckService.getAllFormSchedulingUserFriendlyNaming();
    const areImportedCheckFormSchedulingKeys = importedCheckFormSchedulingKeys?.length
      ? importedCheckFormSchedulingKeys.every((key) => allFormSchedulingUserFriendlyNaming.includes(key))
      : true;
    const importedCheckFormSchedulingTimeKeys = Object.keys(importedCheck.scheduling?.starting_time || {});
    const allFormSchedulingTimeUserFriendlyNaming =
      GuardianImportExportCheckService.getAllFormSchedulingTimeUserFriendlyNaming();
    const areImportedCheckFormSchedulingTimeKeys = importedCheckFormSchedulingTimeKeys?.length
      ? importedCheckFormSchedulingTimeKeys.every((key) => allFormSchedulingTimeUserFriendlyNaming.includes(key))
      : true;

    return areImportedCheckAPIKeys && areImportedCheckFormSchedulingKeys && areImportedCheckFormSchedulingTimeKeys;
  }

  private _buildBaseGuardianStatusFromImport(
    importedCheck: IExportedCheckCommon,
    config: IGuardianImportConfig,
    checkType: ISupportedGuardianChecksResource,
    currentCheck?: IGuardianStatus,
    checkName?: string,
  ) {
    return {
      isChecked: currentCheck?.isChecked || null,
      checkId: currentCheck?.checkId ? currentCheck.checkId : null,
      checks: currentCheck?.checks?.length ? currentCheck.checks : null,
      checkInfos: {
        name: checkName?.length ? checkName : null,
        /* not importable check configs */
        target_folder_if_ok: null,
        target_folder_if_error: null,
        enforce_replacement: null,
        file_renaming_format_if_ok: null,
        file_renaming_format_if_error: null,
        /* importable check configs */
        // step 1
        email_if_ok: GuardianService.convertArrayToGuardianStringArray(config.importedCheckEmailIfOk) || null,
        email_if_not_ok: GuardianService.convertArrayToGuardianStringArray(config.importedCheckEmailIfNotOk) || null,
        email_if_exception:
          GuardianService.convertArrayToGuardianStringArray(config.importedCheckEmailIfException) || null,
        // step 2
        dag_schedule_interval: GuardianImportExportCheckService.getAPICronFromUserFriendlyScheduling(
          importedCheck[
            GuardianImportExportCheckService.getUserFriendlyNamingFromAPIFieldNaming('dag_schedule_interval')
          ] || null,
        ),
        write_result:
          importedCheck[GuardianImportExportCheckService.getUserFriendlyNamingFromAPIFieldNaming('write_result')] ||
          null,
        // step 3
        validation_schema_columns: this._convertUserFriendlySchemaColumnsToAPISchemaColumns(
          importedCheck[
            GuardianImportExportCheckService.getUserFriendlyNamingFromAPIFieldNaming('validation_schema_columns')
          ],
          checkType,
        ),
      },
    };
  }

  private _buildDatalakeGuardianStatusFromImport(
    importedCheck: IExportedCheckDatalake,
    config: IGuardianImportConfig,
    currentCheck?: IGuardianStatus,
    checkName?: string,
  ): IGuardianStatus<'datalakePath'> {
    const baseGuardianStatus = this._buildBaseGuardianStatusFromImport(
      importedCheck,
      config,
      'datalakePath',
      currentCheck,
      checkName,
    );
    const currentCheckCheckInfos = (config.currentCheckInfos ?? {}) as IGuardianAPIChecksMap['datalakePath'];

    return {
      ...baseGuardianStatus,
      isFlatfileDeactivate: importedCheck?.uploaded_file_above_100MB || false,
      type: 'datalakePath',
      checkInfos: {
        /* current check configs */
        ...currentCheckCheckInfos,
        ...baseGuardianStatus.checkInfos,
        // step 1
        file_format:
          importedCheck[GuardianImportExportCheckService.getUserFriendlyNamingFromAPIFieldNaming('file_format')] ||
          null,
        delimiter: GuardianService.getDelimiterFromDelimiterToDisplay(
          importedCheck[GuardianImportExportCheckService.getUserFriendlyNamingFromAPIFieldNaming('delimiter')] || '',
        ),
        sheet_name:
          importedCheck[GuardianImportExportCheckService.getUserFriendlyNamingFromAPIFieldNaming('sheet_name')] || null,
        decimal:
          importedCheck[GuardianImportExportCheckService.getUserFriendlyNamingFromAPIFieldNaming('decimal')] || null,
        regex: this._guardianService.getNameFilterRegexFromNameFilterRegexLabel(
          importedCheck[GuardianImportExportCheckService.getUserFriendlyNamingFromAPIFieldNaming('regex')] || '',
        ),
      },
    };
  }

  private _buildSnowflakeGuardianStatusFromImport(
    importedCheck: IExportedCheckSnowflake,
    config: IGuardianImportConfig,
    currentCheck?: IGuardianStatus,
    checkName?: string,
  ): IGuardianStatus<'snowflake'> {
    const baseGuardianStatus = this._buildBaseGuardianStatusFromImport(
      importedCheck,
      config,
      'snowflake',
      currentCheck,
      checkName,
    );
    const currentCheckCheckInfos = (config.currentCheckInfos ?? {}) as IGuardianAPIChecksMap['snowflake'];

    return {
      ...baseGuardianStatus,
      type: 'snowflake',
      checkInfos: {
        /* current check configs */
        ...currentCheckCheckInfos,
        ...baseGuardianStatus.checkInfos,
      },
    };
  }

  buildGuardianStatusFromImportedCheck(
    importedCheck: IExportedCheckSnowflake | IExportedCheckDatalake,
    checkType: ISupportedGuardianChecksResource,
    currentCheck?: IGuardianStatus,
    checkName?: string,
  ): IGuardianStatus {
    const importCheckConfig: IGuardianImportConfig = {
      importedCheckEmailIfOk:
        importedCheck[GuardianImportExportCheckService.getUserFriendlyNamingFromAPIFieldNaming('email_if_ok')],
      importedCheckEmailIfNotOk:
        importedCheck[GuardianImportExportCheckService.getUserFriendlyNamingFromAPIFieldNaming('email_if_not_ok')],
      importedCheckEmailIfException:
        importedCheck[GuardianImportExportCheckService.getUserFriendlyNamingFromAPIFieldNaming('email_if_exception')],
      currentCheckInfos: currentCheck?.checkInfos,
    };

    if (checkType === 'datalakePath') {
      return this._buildDatalakeGuardianStatusFromImport(
        importedCheck as IExportedCheckDatalake,
        importCheckConfig,
        currentCheck,
        checkName,
      );
    } else {
      // checkType equals 'snowflake'
      return this._buildSnowflakeGuardianStatusFromImport(
        importedCheck as IExportedCheckSnowflake,
        importCheckConfig,
        currentCheck,
        checkName,
      );
    }
  }
}
