import { Injectable } from '@angular/core';
import { ApiPaginatedService } from '@dataportal/front-api';
import type { IGroupAd } from '@dataportal/types';
import { EntityBuilder } from '@decahedron/entity';

import { GroupAd } from '../entities/group-ad';

@Injectable()
export class GroupsAdService extends ApiPaginatedService<GroupAd> {
  protected url = '/v4/groups/ad';

  protected buildOne(json: IGroupAd): GroupAd {
    return EntityBuilder.buildOne(GroupAd, json);
  }

  protected buildMany(json: IGroupAd[]): GroupAd[] {
    return EntityBuilder.buildMany(GroupAd, json);
  }
}
