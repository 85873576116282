import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import type { IGenericFilterItem } from '@dataportal/adl';
import { IS_ACCOR } from '@dataportal/front-environment';
import type { IPortalTree } from '@dataportal/portals';
import { PortalsService } from '@dataportal/portals';

// libs/business-glossary/specific/src/front-office/services/glossary.constants.ts :: DEFAULT_AFFILIATE
const DEFAULT_GLOSSARY = 'GLOSSARY ACCOR';

@Injectable({
  providedIn: 'root',
})
export class AffiliatesService {
  private _portals: IPortalTree[][] = [];
  private readonly _affiliatesFilter$ = new BehaviorSubject<IGenericFilterItem[]>([]);
  private readonly _isLoadingAffiliates$ = new BehaviorSubject<boolean>(true);

  isLoadingAffiliates$ = this._isLoadingAffiliates$.asObservable();
  affiliatesFilter$ = this._affiliatesFilter$.asObservable();

  constructor(private readonly _portalsService: PortalsService, @Inject(IS_ACCOR) private readonly _isAccor: boolean) {
    this._portalsService.portalsTree$.subscribe((portalsTree) => {
      if (portalsTree.length) {
        this._isLoadingAffiliates$.next(true);
        this._portals = portalsTree;
        const affiliatesFilter: IGenericFilterItem[] = [];

        portalsTree[0].forEach((scope) => {
          affiliatesFilter.push({
            label: scope.name,
            value: scope.id,
            checked: false,
            open: false,
            subFilterItems: this._fetchChildren(scope),
          });
        });

        affiliatesFilter.sort((a, b) => a.label.localeCompare(b.label));

        this._isAccor && this.accorCustomSort(affiliatesFilter);

        this._affiliatesFilter$.next(affiliatesFilter);
        this._isLoadingAffiliates$.next(false);
      }
    });
  }

  private _fetchChildren(scope: IPortalTree): IGenericFilterItem[] {
    return this._getScopeChildren(scope).map((child) => {
      return {
        label: child.name,
        value: child.id,
        checked: false,
        subFilterItems: this._setScopeChildren(child),
      };
    });
  }

  private _getScopeChildren(scope: IPortalTree): IPortalTree[] {
    if (scope.level + 1 < this._portals.length) {
      return this._portals[scope.level + 1]
        .filter((org) => org.parent.id === scope.id)
        .sort((a, b) => a.name.localeCompare(b.name));
    }

    return [];
  }

  private _setScopeChildren(parentScope: IPortalTree): IGenericFilterItem[] {
    let scopeChildren: IGenericFilterItem[] = [];

    if (parentScope.level + 1 < this._portals.length) {
      scopeChildren = this._portals[parentScope.level + 1]
        .filter((org) => org.parent.id === parentScope.id)
        .map((scope) => {
          return {
            label: scope.name,
            value: scope.id,
            checked: false,
            subFilterItems: this._setScopeChildren(scope),
          };
        });
    }

    return scopeChildren;
  }

  findAffiliatePath(affiliateId: string, filterItems: IGenericFilterItem[]): IGenericFilterItem[] {
    const deepSearchPath = (
      idToSearch: string,
      catalogItem: IGenericFilterItem,
      level: number,
      curPath: IGenericFilterItem[] = [],
    ) => {
      // update current affiliate path
      curPath[level] = catalogItem;
      // value = id
      if (idToSearch === catalogItem.value) return curPath;

      for (const filterItem of catalogItem.subFilterItems) {
        const path = deepSearchPath(idToSearch, filterItem, level + 1, curPath);
        if (path) return path;
      }
    };

    for (const item of filterItems) {
      const tmpPath = deepSearchPath(affiliateId, item, 0);
      if (tmpPath) return tmpPath;
    }

    return undefined;
  }

  flattenAffiliatesFilter(affiliatesFilter: IGenericFilterItem[]): IGenericFilterItem[] {
    return affiliatesFilter
      .reduce((acc, item) => {
        if (item.subFilterItems.length) {
          return [...acc, item, ...this.flattenAffiliatesFilter(item.subFilterItems)];
        }

        return [...acc, item];
      }, [])
      .filter((value, index, self) => self.indexOf(value) === index)
      .sort((a, b) => a.label.localeCompare(b.label));
  }

  /**
   * Reorders filters in place according to ACCOR CUSTOM ORDER:
   * moves the filter for 'Others' business area to the end
   */
  accorCustomSort(affiliatesFilter: IGenericFilterItem[]): void {
    const otherBusinessAreaIndex = affiliatesFilter.findIndex(({ label }) => label === 'Others');

    if (otherBusinessAreaIndex !== -1) {
      const [otherBusinessArea] = affiliatesFilter.splice(otherBusinessAreaIndex, 1);
      affiliatesFilter.push(otherBusinessArea);
    }
  }

  /**
   * Removes filter for ACCOR DEFAULT GLOSSARY from business area filters
   */
  removeDefaultGlossary(affiliatesFilter: IGenericFilterItem[]): void {
    const glossaryIndex = affiliatesFilter.findIndex(({ label }) => label === DEFAULT_GLOSSARY);

    if (glossaryIndex !== -1) {
      affiliatesFilter.splice(glossaryIndex, 1);
    }
  }
}
