import { Injectable } from '@angular/core';
import type { IListingOptionMetadata } from '@dataportal/front-api';
import { ApiPaginatedService } from '@dataportal/front-api';
import type { IGroup } from '@dataportal/types';
import { EntityBuilder } from '@decahedron/entity';

import { GroupsModule } from '../groups.module';

import { Group } from '../entities/group';

@Injectable({
  providedIn: GroupsModule,
})
export class UsersGroupsService extends ApiPaginatedService<Group> {
  protected buildOne(json: IGroup): Group {
    return EntityBuilder.buildOne(Group, json);
  }

  protected buildMany(json: IGroup[]): Group[] {
    return EntityBuilder.buildMany(Group, json);
  }

  listGroups(userId: string, options: IListingOptionMetadata): string {
    this.url = '/v4/users/' + userId + '/groups';

    return this.startListing(options);
  }
}
