import type { OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import { Logger } from '@dataportal/front-shared';

import { SourcesService } from '../../../sources/services/sources.service';

@Component({
  selector: 'dpg-dashboard-image',
  templateUrl: './dashboard-image.component.html',
  styleUrls: ['./dashboard-image.component.scss'],
})
export class DashboardImageComponent implements OnInit {
  // Attributes
  @Input() dashboardImageSuffix: string;

  loaded = false;
  dashboardImage: string;

  private readonly _fallback = '/assets/dpg/imgs/elements/dataviz.png';

  // Constructor
  constructor(private readonly _logger: Logger, private readonly _sourcesService: SourcesService) {}

  // Lifecycle
  ngOnInit(): void {
    this._sourcesService.getThumbnail(this.dashboardImageSuffix).subscribe(
      (url) => {
        this.dashboardImage = url || this._fallback;
        this.loaded = true;
      },
      () => {
        this._logger.warn(
          '[dpg-dashboard-image] Error loading image for dashboard. Using fallback placeholder instead',
        );
        this.dashboardImage = this._fallback;
        this.loaded = true;
      },
    );
  }

  // Methods
  useFallback(event: Event): void {
    (event.target as HTMLImageElement).src = this._fallback;
  }
}
