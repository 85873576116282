import { Injectable } from '@angular/core';
import { TableauViz } from '@tableau/embedding-api';

import { Dashboard } from '../entities/dashboard.model';

@Injectable()
export class TableauService {
  private _container: HTMLElement;
  private _dashboard: Dashboard;

  init(container: HTMLElement, dashboard: Dashboard): void {
    this._container = container;
    this._dashboard = dashboard;

    const viz: TableauViz = this._createViz();

    this._embed(viz);
  }

  private _createViz(): TableauViz {
    const viz = new TableauViz();

    viz.src = this._dashboard.url;
    viz.iframeAuth = true;

    return viz;
  }

  private _embed(viz: TableauViz): void {
    this._clearContainer();

    this._container.appendChild(viz);
  }

  private _clearContainer(): void {
    this._container.innerHTML = '';
  }
}
