<div>
  <adl-checkboxes-v2
    *ngIf="filter.useV2; else oldCheckboxTemplate"
    [options]="filter.options"
    [control]="filter.control"
    [addAllOption]="filter.showAllCheckbox"
    [allOptionLabel]="filter.allCheckboxLabel"
    [checkboxesPerLine]="filter.checkboxesPerLine"
  ></adl-checkboxes-v2>
</div>

<ng-template #oldCheckboxTemplate>
  <adl-checkboxes
    [checkboxes]="filter.options"
    [control]="filter.control"
    [labelSize]="12"
    [isColumnFlex]="true"
    checkboxSize="small"
    checkboxColor="blue"
    checkBoxesPaddingTop="0"
    checkBoxesPaddingBottom="0"
    heightForColumnFlex="145px"
  >
  </adl-checkboxes>
</ng-template>
