<adl-modal
  class="adl-dialog-modal h-63"
  headerBackgroundColor="blue-dark"
  backgroundColor="light"
  [isAlignedToCross]="true"
  [roundCorner]="true"
  [closeButtonColor]="'white'"
>
  <div class="flex w-full h-32" modal-header>
    <adl-svg-icon *ngIf="icon" [icon]="icon" [size]="32" class="mr-4"></adl-svg-icon>
    <adl-title class="flex ml-12 my-8 mt-11" type="modal" color="#ffffff">{{ headerText }}</adl-title>
  </div>
  <div *ngIf="!innerHTML" class="column-flex" modal-body
>
    <adl-text *ngFor="let message of messages" [size]="14" [type]="messageType" class="w100 text-left
    ">
      {{ message }}
    </adl-text>
  </div>
  <div *ngIf="innerHTML" [innerHTML]="innerHTML" modal-body></div>
  <div class="adl-dialog-modal--buttons row-flex j-end mt10 pr-10" modal-footer *ngIf="cancelText || confirmText"
  >
    <ng-container *ngIf="firstButton === 'confirm'; else isConfirmButtonLast">
      <adl-button class="adl-dialog-modal--confirm" *ngIf="confirmText" [type]="confirmType" (click)="confirm()">
        {{ confirmText }}
      </adl-button>
      <adl-button class="adl-dialog-modal--cancel" *ngIf="cancelText" [type]="cancelType" (click)="cancel()">
        {{ cancelText }}
      </adl-button>
    </ng-container>
    <ng-template #isConfirmButtonLast>
      <adl-button class="adl-dialog-modal--cancel" *ngIf="cancelText" [type]="cancelType" (click)="cancel()">
        {{ cancelText }}
      </adl-button>
      <adl-button class="adl-dialog-modal--confirm" *ngIf="confirmText" [type]="confirmType" (click)="confirm()">
        {{ confirmText }}
      </adl-button>
    </ng-template>
  </div>
</adl-modal>
