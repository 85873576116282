import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@dataportal/front-shared';

import { Navigator } from './services/navigator.service';

import { NavigatorLinkDirective } from './directives/navigator-link.directive';
import { NavigatorLinkDirectiveWithHref } from './directives/navigator-link-with-href.directive';

@NgModule({
  imports: [RouterModule, SharedModule],
  providers: [Navigator],
  declarations: [NavigatorLinkDirective, NavigatorLinkDirectiveWithHref],
  exports: [NavigatorLinkDirective, NavigatorLinkDirectiveWithHref],
})
export class NavigatorModule {}
