import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { SharedModule } from '@dataportal/front-shared';
import { NgSelectModule } from '@ng-select/ng-select';
import { EditorModule } from '@tinymce/tinymce-angular';

import { AddCardPlaceholderComponent } from './add-card-placeholder/add-card-placeholder.component';
import { ApiSelectComponent } from './api-select/api-select.component';
import { AutocompleteComponent } from './autocomplete/autocomplete.component';
import { BannerComponent } from './banner/banner.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { ButtonComponent } from './button/button.component';
import { CardComponent } from './card/card.component';
import { CertificationBadgeComponent } from './certification-badge/certification-badge.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { ChipComponent } from './chip/chip.component';
import { DropdownModule } from './dropdown/dropdown.component';
import { FancySwitchModule } from './fancy-switch/fancy-switch.component';
import { GuardianStepConditionTableComponent } from './guardian-step-condition-table/guardian-step-condition-table.component';
import { IconComponent } from './icon/icon.component';
import { IconButtonComponent } from './icon-button/icon-button.component';
import { ImageComponent } from './image/image.component';
import { InputComponent } from './input/input.component';
import { InputGrowComponent } from './input-grow/input-grow.component';
import { InputTextAreaComponent } from './input-text-area/input-text-area.component';
import { IsActiveLabelComponent } from './is-active-label/is-active-label.component';
import { ListComponent } from './list/list.component';
import { LoadedItemComponent } from './loaded-item/loaded-item.component';
import { LottieAnimationComponent } from './lottie-animation/lottie-animation.component';
import { ModalComponent } from './modal/modal.component';
import { ProgressCircleComponent } from './progress-circle/progress-circle.component';
import { RadioModule } from './radio/radio.component';
import { RightClickContextMenuComponent } from './right-click-context-menu/right-click-context-menu.component';
import { RollingDigitComponent } from './rolling-digit/rolling-digit.component';
import { SelectComponent } from './select/select.component';
import { SelectV2Component } from './select-v2/select-v2.component';
import { SliderComponent } from './slider/slider.component';
import { SquaredTagComponent } from './squared-tag/squared-tag.component';
import { StarComponent } from './star/star.component';
import { StepperComponent } from './stepper/stepper.component';
import { SvgIconComponent } from './svg-icon/svg-icon.component';
import { SwitchComponent } from './switch/switch.component';
import { TableComponent } from './table/table.component';
import { TableV2Component } from './table-v2/table-v2.component';
import { TagComponent } from './tag/tag.component';
import { TextComponent } from './text/text.component';
import { TitleComponent } from './title/title.component';
import { TooltipComponent } from './tooltip/tooltip.component';
import { WysiwygEditorComponent } from './wysiwyg-editor/wysiwyg-editor.component';

@NgModule({
  declarations: [
    ButtonComponent,
    CheckboxComponent,
    InputComponent,
    TextComponent,
    ProgressCircleComponent,
    TitleComponent,
    IconButtonComponent,
    IsActiveLabelComponent,
    CardComponent,
    AddCardPlaceholderComponent,
    TooltipComponent,
    SelectComponent,
    LoadedItemComponent,
    SwitchComponent,
    ModalComponent,
    WysiwygEditorComponent,
    AutocompleteComponent,
    TagComponent,
    ChipComponent,
    LottieAnimationComponent,
    SvgIconComponent,
    IconComponent,
    ApiSelectComponent,
    TableComponent,
    StarComponent,
    RollingDigitComponent,
    ImageComponent,
    BannerComponent,
    StepperComponent,
    ListComponent,
    SliderComponent,
    RightClickContextMenuComponent,
    InputTextAreaComponent,
    TableV2Component,
    BreadcrumbComponent,
    SelectV2Component,
    InputGrowComponent,
    GuardianStepConditionTableComponent,
    SquaredTagComponent,
    CertificationBadgeComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    NgSelectModule,
    EditorModule,
    FancySwitchModule,
    RadioModule,
    DropdownModule,
    NgxSliderModule,
    SharedModule,
    RouterModule,
  ],
  exports: [
    ButtonComponent,
    CheckboxComponent,
    InputComponent,
    TextComponent,
    TitleComponent,
    ProgressCircleComponent,
    IconButtonComponent,
    IsActiveLabelComponent,
    CardComponent,
    AddCardPlaceholderComponent,
    TooltipComponent,
    SelectComponent,
    LoadedItemComponent,
    SwitchComponent,
    ModalComponent,
    WysiwygEditorComponent,
    AutocompleteComponent,
    TagComponent,
    ChipComponent,
    LottieAnimationComponent,
    SvgIconComponent,
    IconComponent,
    ApiSelectComponent,
    TableComponent,
    StarComponent,
    FancySwitchModule,
    RadioModule,
    RollingDigitComponent,
    OverlayModule,
    DropdownModule,
    ImageComponent,
    BannerComponent,
    StepperComponent,
    ListComponent,
    SliderComponent,
    RightClickContextMenuComponent,
    InputTextAreaComponent,
    TableV2Component,
    BreadcrumbComponent,
    SelectV2Component,
    InputGrowComponent,
    GuardianStepConditionTableComponent,
    SquaredTagComponent,
    CertificationBadgeComponent,
  ],
  providers: [{ provide: MAT_DIALOG_DATA, useValue: {} }],
})
export class AtomsModule {}
