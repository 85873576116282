import { Location } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { CatalogV2SearchService } from '../../../../services/v2/search.service';

@Component({
  selector: 'dpg-catalog-sticky-searchbar',
  templateUrl: './catalog-sticky-searchbar.component.html',
  styleUrls: ['./catalog-sticky-searchbar.component.scss'],
})
export class CatalogStickySearchbarComponent {
  @Input() searchControl: FormControl;
  @Input() showBackButton = false;
  @Input() updateURLQuery = false;
  @Input() showDropdown = false;
  @Input() showResultsInDropdown = false;

  @Output() searchAction = new EventEmitter();

  searchMode: 'advanced' | 'simple';

  private readonly _destroyed$ = new Subject<void>();

  constructor(
    private readonly _location: Location,
    private readonly _router: Router,
    private readonly _searchService: CatalogV2SearchService,
  ) {}

  ngOnInit() {
    this._searchService.searchMode$.pipe(takeUntil(this._destroyed$)).subscribe((mode: 'advanced' | 'simple') => {
      this.searchMode = mode;
    });
  }

  ngOnDestroy() {
    this._destroyed$.next();
  }

  goBack() {
    this._location.back();
  }

  navigateToSearch() {
    this._router
      .navigate(['catalog/search'], {
        queryParams: { q: this.searchControl.value },
      })
      .then();
  }
}
