import type { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { GoogleTagManagerService } from '@dataportal/analytics';

import { SnowflakeService } from '../../services/snowflake.service';

import type { SnowflakeAccountEntity } from '../../entities/snowflake-account';

@Component({
  selector: 'dpg-snowflake-page',
  templateUrl: './snowflake-page.component.html',
  styleUrls: ['./snowflake-page.component.scss'],
})
export class SnowflakePageComponent implements OnInit {
  allAccount: SnowflakeAccountEntity[] = [];

  // Constructor
  constructor(
    private readonly _accountList$: SnowflakeService,
    private readonly _gtmService: GoogleTagManagerService,
  ) {}

  // Lifecycle
  async ngOnInit() {
    this._accountList$.listAccounts().subscribe((accounts) => {
      this.allAccount = accounts;
    });
  }

  pushGTMOpenSnowflakeEvent(snowflakeName: string) {
    this._gtmService.pushEvent({
      event: 'tl_snowflake_opened',
      tl_snowflake_name: snowflakeName,
    });
  }
}
