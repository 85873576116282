import type { OnDestroy } from '@angular/core';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import type { Observable } from 'rxjs';
import { combineLatest, of, Subject } from 'rxjs';
import { concatMap, takeUntil, withLatestFrom } from 'rxjs/operators';
import { AlertService } from '@dataportal/front-shared';

import { DialogService } from '../../../services/dialog.service';

import { DocumentationDialogComponent } from './documentation-dialog/documentation-dialog.component';

import type { IDocumentation } from '../../../models/documentation.model';

@Component({
  template: '',
})
export class RoutableDocumentationDialogComponent implements OnDestroy {
  private readonly _destroySubject = new Subject();

  constructor(
    private readonly _dialogService: DialogService,
    private readonly _alertService: AlertService,
    route: ActivatedRoute,
    router: Router,
  ) {
    // URL examples
    // http://localhost:4200/academy/products/1/Leon/(documentation:4)

    route.params
      .pipe(
        withLatestFrom(route.queryParams),
        concatMap(([params, queryParams]) => {
          return combineLatest([
            of(queryParams),
            this.afterDocumentationDialogClosed$(Number(params.id), queryParams.checkViews),
          ]);
        }),
        takeUntil(this._destroySubject),
      )
      .subscribe(
        ([queryParams]) => {
          router.navigate(['..'], { relativeTo: route, queryParams: queryParams });
        },
        (e: unknown) => {
          this._alertService.error('Could not open documentation');
          router.navigate(['..'], { relativeTo: route });
        },
      );
  }

  afterDocumentationDialogClosed$(id: IDocumentation['id'], canCheckViews: boolean): Observable<undefined> {
    if (isNaN(id) || !id) throw Error('Wrong documentation id');

    const dialogRef = this._dialogService.open(DocumentationDialogComponent, {
      data: { id: id, canCopy: true, canCheckViews },
    });

    return dialogRef.afterClosed();
  }

  ngOnDestroy() {
    this._destroySubject.next();
    this._destroySubject.complete();
  }
}
