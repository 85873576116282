import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, EMPTY, Subject } from 'rxjs';
import { catchError, first } from 'rxjs/operators';
import { indicate } from '@dataportal/adl';
import { IS_ACCOR } from '@dataportal/front-environment';

import { DocumentationService } from '../../../../../libs/dataportal-data-academy/src/lib/services/documentation.service';
import { ProductService } from '../../../../../libs/dataportal-data-academy/src/lib/services/product.service';

import type { IDocumentation } from '../../../../../libs/dataportal-data-academy/src/lib/models/documentation.model';
import type { OneProductModel } from '../../../../../libs/dataportal-data-academy/src/lib/models/product.model';

@Injectable({
  providedIn: 'root',
})
export class HelpCenterService {
  retrievingProducts$ = new BehaviorSubject(false);
  faqEntriesResults$: BehaviorSubject<IDocumentation[]> = new BehaviorSubject<IDocumentation[]>([]);
  faqEntriesFiltered$: BehaviorSubject<IDocumentation[]> = new BehaviorSubject<IDocumentation[]>([]);
  mainProduct$ = new Subject<OneProductModel>();

  readonly dataPortalID: number;

  constructor(
    private readonly _documentationService: DocumentationService,
    private readonly _productService: ProductService,
    @Inject(IS_ACCOR) private readonly _isAccor: boolean,
  ) {
    this.dataPortalID = this._isAccor ? 1 : 5;

    this.updateProductsFAQ();
  }

  updateProductsFAQ() {
    this._productService
      .getProduct$(this.dataPortalID)
      .pipe(
        indicate(this.retrievingProducts$),
        first(),
        catchError(() => {
          this.faqEntriesResults$.next([]);

          return EMPTY;
        }),
      )
      .subscribe((product) => {
        this.mainProduct$.next(product);
        this.faqEntriesResults$.next(
          product.user_doc_faq.sort((entryA, entryB) => entryA.title.localeCompare(entryB.title)),
        );
      });
  }

  updateFilteredEntries(searchQuery: string) {
    const queryTerms: string[] = searchQuery.split(/[ ']+/g).filter((term) => term !== '');
    this.faqEntriesFiltered$.next(
      !queryTerms.length
        ? this.faqEntriesResults$.value
        : this.faqEntriesResults$.value.filter((result) => {
            return queryTerms.some((term) => result.title.toLowerCase().includes(term.toLowerCase()));
          }),
    );
  }

  resetFilteredEntries() {
    this.faqEntriesFiltered$.next([]);
  }
}
