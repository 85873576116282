import { Entity } from '@decahedron/entity';

export interface ISnowflakeAccount {
  name: string;
  url: string;
}

export class SnowflakeAccountEntity extends Entity {
  name: string = null;
  url: string = null;
}
