<adl-banner
  *ngIf="shouldBeVisible"
  [color]="source?.banner?.color"
  [message]="source?.banner?.message"
  [iconBanner]="'assets/modules/data-catalog/imgs/alert-' + labelColor + '.svg'"
  [labelColor]="labelColor"
  [closable]="false"
  [hasIconBanner]="true"
  [isAlertComponent]="true"
>
</adl-banner>

