import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class NavigateToService {
  constructor(private readonly _router: Router) {}

  async navigateTo(url: string | (() => string), external: boolean) {
    if (external) {
      window.open(window.location.origin + url, '_self');
    } else {
      await this._router.navigateByUrl(typeof url === 'string' ? url : url());
    }
  }
}
