<tr *ngIf="formGroup && formReady" class="row-flex j-start">
  <td
    class="column-name-column-width flex flex-col items-start p0"
    [attr.data-cy]="'guardian-creation-check-row-' + (index + 1)"
  >
    <div class="global-column-name-column-padding--field-check flex flex-row justify-start items-center mt-auto mb-auto w-full">
      <div class="row-number tal">
        <adl-text type="accent" size="12" [bold]="false">
          {{ index + 1 }}
        </adl-text>
      </div>
      <div class="up-down-arrows flex flex-row justify-start items-center">
        <adl-icon-button
          icon="long-arrow-down"
          [disabled]="!canMoveRowDown"
          [iconType]="canMoveRowDown ? 'primary' : 'second'"
          (clicked)="onMoveRowDown()"
          data-cy="guardian-creation-check-move-constraint-down"
        ></adl-icon-button>
        <adl-icon-button
          class="pl5"
          icon="long-arrow-up"
          [disabled]="!canMoveRowUp"
          [iconType]="canMoveRowUp ? 'primary' : 'second'"
          (clicked)="onMoveRowUp()"
          data-cy="guardian-creation-check-move-constraint-up"
        ></adl-icon-button>
      </div>
      <div class="column-name-column-padding--field-check justify-center items-center w70">
        <adl-input
          [control]="formGroup.get('columnName')"
          fixedSize="10px"
          placeholder="E.g. Column {{ index + 1 }}"
          class="column-name-input w-full"
          data-cy="guardian-creation-check-column-name"
        ></adl-input>
        <adl-text *ngIf="isFieldColumnIncorrect$ | async" color="red" class="w-full tac">Required</adl-text>
      </div>
    </div>
    <div class="flex flex-row justify-start items-center js-end plus-btn ml15 w100">
      <adl-icon-button
        (clicked)="addColumnCheckAfterIndex()"
        icon="plus-circle"
        iconType="primary"
        size="22"
        class="flex flex-col justify-center items-start w-full"
        data-cy="guardian-creation-check-table-add-columns"
      ></adl-icon-button>
    </div>
  </td>
  <td class="constraint-column flex flex-col justify-start items-start">
    <div class=" flex flex-col justify-start items-start w-full">
      <adl-selectable-form-control
        *ngIf="isPartOfTheAddedConstraints('type')"
        [control]="formGroup.get('type')"
        [label]="getConstraintLabelFromConstraint('type')"
        [hint]="getConstraintTooltipFromConstraint('type')"
        labelWidth="100px"
        (onRemove)="removeConstraint('type')"
        class="w100"
      >
        <adl-select
          [options]="columnTypes"
          [control]="formGroup.get('type')"
          [isUsingFirstValueAsDefault]="!formGroup.get('type').value?.toString()?.length"
          [defaultValue]="formGroup.get('type')?.value"
          fixedSize="60%"
          (changed)="updateAddedAndAvailableConstraints()"
          class="flex flew-row justify-start items-center w-full"
          data-cy="guardian-creation-check-column-type"
        ></adl-select>
      </adl-selectable-form-control>
      <adl-selectable-form-control
        *ngIf="isPartOfTheAddedConstraints('isRequired')"
        [control]="formGroup.get('isRequired')"
        [label]="getConstraintLabelFromConstraint('isRequired')"
        [hint]="getConstraintTooltipFromConstraint('isRequired')"
        labelWidth="100px"
        (onRemove)="removeConstraint('isRequired')"
        class="mt5 w-full"
      >
        <adl-select
          [options]="requiredValues"
          [control]="formGroup.get('isRequired')"
          [defaultValue]="formGroup.get('isRequired')?.value"
          fixedSize="30%"
          class="flex flex-row justify-start items-center w-full"
          data-cy="guardian-creation-check-column-is-required"
        ></adl-select>
      </adl-selectable-form-control>
      <adl-selectable-form-control
        *ngIf="isPartOfTheAddedConstraints('isUnique')"
        [control]="formGroup.get('isUnique')"
        [label]="getConstraintLabelFromConstraint('isUnique')"
        [hint]="getConstraintTooltipFromConstraint('isUnique')"
        labelWidth="100px"
        (onRemove)="removeConstraint('isUnique')"
        class="mt5 w-full"
      >
        <adl-select
          [options]="uniqueValues"
          [control]="formGroup.get('isUnique')"
          [defaultValue]="formGroup.get('isUnique')?.value"
          fixedSize="30%"
          class="flex flex-row justify-start items-center w-full"
        ></adl-select>
      </adl-selectable-form-control>
      <adl-selectable-form-control
        *ngIf="isPartOfTheAddedConstraints('minValue')"
        [control]="formGroup.get('minValue')"
        [label]="getConstraintLabelFromConstraint('minValue')"
        [hint]="getConstraintTooltipFromConstraint('minValue')"
        [errorMessages]="minErrorMessages"
        [relatedControlsToCheck]="[formGroup.get('maxValue')]"
        labelWidth="100px"
        (onRemove)="removeConstraint('minValue')"
        class="mt5 w-full"
      >
        <adl-input
          [control]="formGroup.get('minValue')"
          [defaultValue]="''"
          [cursorAtTheEnd]="true"
          [placeholder]="getMinPlaceholder()"
          fixedSize="70%"
          class="flex flex-row justify-start items-center w50"
        ></adl-input>
      </adl-selectable-form-control>
      <adl-selectable-form-control
        *ngIf="isPartOfTheAddedConstraints('maxValue')"
        [control]="formGroup.get('maxValue')"
        [label]="getConstraintLabelFromConstraint('maxValue')"
        [hint]="getConstraintTooltipFromConstraint('maxValue')"
        [errorMessages]="maxErrorMessages"
        [relatedControlsToCheck]="[formGroup.get('minValue')]"
        labelWidth="100px"
        (onRemove)="removeConstraint('maxValue')"
        class="mt5 w-full"
      >
        <adl-input
          [control]="formGroup.get('maxValue')"
          [defaultValue]="''"
          [cursorAtTheEnd]="true"
          [placeholder]="getMaxPlaceholder()"
          fixedSize="70%"
          class="flex flex-row justify-start items-center w50"
        ></adl-input>
      </adl-selectable-form-control>
      <adl-selectable-form-control
        *ngIf="isPartOfTheAddedConstraints('greaterThan') && availableGreaterThanColumnValues?.length"
        [control]="formGroup.get('greaterThanColumnId')"
        [label]="getConstraintLabelFromConstraint('greaterThan')"
        [hint]="getConstraintTooltipFromConstraint('greaterThan')"
        labelWidth="100px"
        (onRemove)="removeConstraint('greaterThan')"
        class="mt5 w-full"
      >
        <adl-select
          [options]="availableGreaterThanColumnValues"
          [control]="formGroup.get('greaterThanColumnId')"
          [isUsingFirstValueAsDefault]="!formGroup.get('greaterThanColumnId')?.value?.length"
          [defaultValue]="formGroup.get('greaterThanColumnId')?.value"
          fixedSize="70%"
          class="flex flex-row justify-start items-center w100"
        ></adl-select>
      </adl-selectable-form-control>
      <adl-selectable-form-control
        *ngIf="isPartOfTheAddedConstraints('regex')"
        [control]="formGroup.get('regex')"
        [label]="getConstraintLabelFromConstraint('regex')"
        [hint]="getConstraintTooltipFromConstraint('regex')"
        labelWidth="100px"
        (onRemove)="removeConstraint('regex')"
        class="mt5 w-full"
      >
        <div class="w50">
          <adl-select
            class="w-full"
            [options]="regexReferentialValues"
            [control]="formGroup.get('regexReferential')"
            [isUsingFirstValueAsDefault]="!formGroup.get('preparedRegex').value?.toString()?.length"
            fixedSize="100%"
          ></adl-select>
        </div>
        <div class="w50 pl10 tal">
          <div *ngIf="hasSelectedManualRegex" class="column-flex j-start a-center w100">
            <adl-input
              *ngIf="hasSelectedManualRegex"
              [control]="formGroup.get('manualRegex')"
              [defaultValue]="''"
              [cursorAtTheEnd]="true"
              fixedSize="50%"
              placeholder="E.g. /^[a-Z]+$/"
              class="flex flex-row justify-start items-center  w100"
            ></adl-input>
            <adl-text
              *ngIf="isRegexColumnRequired$ | async"
              type="error"
              size="14"
              class="column-flex j-start a-start w100 tal"
              >Required
            </adl-text>
            <adl-text
              *ngIf="isRegexColumnIncorrect$ | async"
              type="error"
              size="14"
              class="flex flex-col justify-start items-start"
              >Invalid regex
              <adl-info-bubble placement="bottom">
                <div class="whitespace-normal">
                Enter (or select) a regular expression (regex) in order to format the requested check for this field
                (You can validate your regex at https://regex101.com).
                </div>
              </adl-info-bubble>
            </adl-text>
          </div>
          <adl-select
            *ngIf="hasSelectedPreparedRegex"
            [options]="preparedRegexValues"
            [control]="formGroup.get('preparedRegex')"
            [isUsingFirstValueAsDefault]="!formGroup.get('preparedRegex').value?.toString()?.length"
            fixedSize="100%"
            class="flex flex-row justify-start items-center w-full"
          ></adl-select>
        </div>
      </adl-selectable-form-control>
      <adl-selectable-form-control
        *ngIf="isPartOfTheAddedConstraints('mdh')"
        [control]="formGroup.get('snowflakeExternalReferential')"
        [label]="getConstraintLabelFromConstraint('mdh')"
        [hint]="getConstraintTooltipFromConstraint('mdh')"
        labelWidth="100px"
        (onRemove)="removeConstraint('mdh')"
        class="mt5 w-full"
      >
        <dpg-guardian-preview-tooltip
          [isLoading]="isLoadingCurrentSnowflakeReferentialsValuesExtract"
          [title]="'Preview :'"
          [description]="currentSnowflakeReferentialsValuesExtractPreview"
          [actionBtnDescription]="'Display more'"
          [childTemplate]="MDHTooltipChildTemplate"
          (actionEvent)="openReferentialOverviewModal()"
          class="flex flex-row justify-start items-center w70"
        >
        </dpg-guardian-preview-tooltip>
      </adl-selectable-form-control>
      <adl-selectable-form-control
        *ngIf="isPartOfTheAddedConstraints('datalake_in')"
        [control]="formGroup.get('datalakeInExternalReferential')"
        [label]="getConstraintLabelFromConstraint('datalake_in')"
        [hint]="getConstraintTooltipFromConstraint('datalake_in')"
        labelWidth="100px"
        (onRemove)="removeConstraint('datalake_in')"
        class="mt5 w-full"
      >
        <ng-container
          [ngTemplateOutlet]="
            getPartialDatalakePathReferentialToDisplay(true)?
             datalake_referential_tooltip :
             datalake_browser_button
          "
          [ngTemplateOutletContext]="{isWhitelisted:true}">
        </ng-container>
      </adl-selectable-form-control>
      <adl-selectable-form-control
        *ngIf="isPartOfTheAddedConstraints('datalake_not_in')"
        [control]="formGroup.get('datalakeNotInExternalReferential')"
        [label]="getConstraintLabelFromConstraint('datalake_not_in')"
        [hint]="getConstraintTooltipFromConstraint('datalake_not_in')"
        labelWidth="100px"
        (onRemove)="removeConstraint('datalake_not_in')"
        class="mt5 w-full"
      >
        <ng-container
          [ngTemplateOutlet]="
            getPartialDatalakePathReferentialToDisplay(false)?
             datalake_referential_tooltip :
             datalake_browser_button
          "
          [ngTemplateOutletContext]="{isWhitelisted:false}">
        </ng-container>
      </adl-selectable-form-control>
      <div class="flex flex-row justify-start items-start w-full mt5" [ngClass]="{ mt15: !addedConstraintsList?.length }">
        <adl-options-selector-button
          [choices]="availableConstraintsMap"
          triggerButtonType="etc"
          (onChoiceSelection)="addConstraint($event)"
          class="js-start flex flex-row justify-start items-start w80"
          data-cy="addConstraint"
        >
          <div class="flex flex-row justify-start items-center w-full c-pointer">
            <adl-icon class="flex flex-row items-start" icon="plus" iconType="primary-light" size="16"></adl-icon>
            <adl-text
              class="row-flex items-start pl5"
              type="accent"
              color="secondary-500"
              size="14"
              data-cy="guardian-creation-check-constraint-text"
              (click)="checkValueToAddConstraint()"
              >Add a constraint</adl-text
            >
          </div>
        </adl-options-selector-button>
        <adl-options-selector-button
          [choices]="[
            { label: 'Remove column', value: 'remove', icon: 'trash' },
            { label: 'Duplicate column', value: 'duplicate', icon: 'copy' }
          ]"
          triggerButtonType="etc"
          (onChoiceSelection)="duplicateOrRemoveColumnCheck($event)"
          class="etc-btn js-end flex flex-row justify-end items-start w20"
        >
          <adl-text class="etc-icon-btn pt0 pb5 tac br5 c-pointer" type="accent" size="22">. . .</adl-text>
        </adl-options-selector-button>
      </div>
    </div>
  </td>
</tr>

<ng-template #datalake_referential_tooltip let-isWhitelisted="isWhitelisted">
  <div class="referential-path flex flex-row justify-start items-center pl5 pr5 w-full">
    <dpg-guardian-preview-tooltip
      [isLoading]="isLoadingCurrentFormattedDatalakeReferentials"
      [title]="'Location :'"
      [description]="getFullDatalakePathReferentialToDisplay(isWhitelisted)"
      [actionBtnDescription]="'Go to path'"
      [childTemplate]="DatalakeTooltipChildTemplate"
      [isWhitelisted]="isWhitelisted"
      (actionEvent)="redirectToDatalake(isWhitelisted)"
      class="w90 tal"
    >
    </dpg-guardian-preview-tooltip>
    <adl-loaded-item [loading]="isLoadingCurrentFormattedDatalakeReferentials" iconSize="40" class="w10">
      <adl-icon-button icon="pencil" (clicked)="openDatalakeBrowser(isWhitelisted)" class="w-full"></adl-icon-button>
    </adl-loaded-item>
  </div>
  <adl-text *ngIf="hasDatalakeReferentialMetadataFetchingWarning" type="error" size="14" class="w100 tal"
    >Warning : File not found</adl-text
  >
</ng-template>

<ng-template #datalake_browser_button let-isWhitelisted="isWhitelisted">
  <adl-button type="datalake" [fullWidth]="true" [additionalClasses]="['tac']" (clicked)="openDatalakeBrowser(isWhitelisted)">
    <p class="w-full tac">BROWSE</p>
  </adl-button>
  <adl-text *ngIf="isDatalakeReferentialEmpty(isWhitelisted) | async" color="red" class="w-full tal">Required</adl-text>
</ng-template>

<ng-template #MDHTooltipChildTemplate>
  <adl-select
    tooltip-trigger
    [options]="snowflakeReferentialsValues"
    [control]="formGroup.get('snowflakeExternalReferential')"
    [isUsingFirstValueAsDefault]="!hasSnowflakeReferentialValue"
    [valueEqualityCheck]="isSnowflakeReferentialEqual"
    (changed)="updateSnowflakeReferentialValues()"
    fixedSize="100%"
    class="flex flex-rox justify-start items-center w-full"
  ></adl-select>
</ng-template>

<ng-template #DatalakeTooltipChildTemplate let-isWhitelisted="isWhitelisted">
  <adl-text tooltip-trigger class="tal ws-normal wb-all w-full" [bold]="true" size="10">
    {{ getPartialDatalakePathReferentialToDisplay(isWhitelisted) }}
  </adl-text>
</ng-template>
