<div class="table-container w-full" #tableContainer>
  <adl-loaded-item *ngIf="viewMode === 'data'" [loading]="!renderTable">
    <ngx-datatable
      *ngIf="renderTable"
      class="datatable w-full"
      [scrollbarV]="true"
      [scrollbarH]="true"
      [columnMode]="'standard'"
      [rowHeight]="40"
      [rows]="tableDataFormatted"
    >
      <ngx-datatable-column
        *ngFor="let col of dataTableKeys; let first = first"
        [name]="col"
        [prop]="col"
        [width]="first ? 75 : dataColWidth"
        [draggable]="false"
      >
        <ng-template ngx-datatable-header-template>
          <span class="uppercase">{{ col }}</span>
        </ng-template>
        <ng-template let-value="value" ngx-datatable-cell-template>
          <span class="text-left">{{ value }}</span>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </adl-loaded-item>

  <adl-loaded-item *ngIf="viewMode === 'details'" [loading]="!renderTable">
    <ngx-datatable
      *ngIf="renderTable"
      class="datatable w-full"
      [scrollbarV]="true"
      [scrollbarH]="true"
      [columnMode]="'standard'"
      [rowHeight]="40"
      [rows]="tableMetaData"
    >
      <ngx-datatable-column
        *ngFor="let col of tableMetaDataColumns; let first = first"
        [name]="col"
        [prop]="col"
        [width]="first ? 130 : metadataColWidth"
        [draggable]="false"
      >
        <ng-template ngx-datatable-header-template>
          <span class="uppercase">{{ col }}</span>
        </ng-template>
        <ng-template let-value="value" ngx-datatable-cell-template>
          <span class="text-left">{{ value }}</span>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </adl-loaded-item>
</div>
