import type { IDatalakePath } from '@dataportal/types';
import { Entity } from '@decahedron/entity';

export const DATA_TYPE_REQUIRED_LABEL = 'dataTypeRequired';

// Entity
export class DatalakePath extends Entity {
  provider?: 'aws' | 'azure' = null;
  tenant?: string = null;
  path?: string = null;
  isHidden?: boolean = null;
  name?: string = null;
  groupsLimited?: string[];

  constructor(
    path: string,
    provider: 'aws' | 'azure',
    tenant: string,
    isHidden?: boolean,
    name?: string,
    groupsLimited?: string[],
  ) {
    super();
    this.path = path;
    this.provider = provider;
    this.tenant = tenant;
    this.isHidden = isHidden;
    this.name = name;
    this.groupsLimited = groupsLimited;
  }

  /**
   * Check datalake path equality (not checking the name nor the isHidden status)
   * @param otherPath
   */
  isEqualTo(otherPath: DatalakePath) {
    return this.path === otherPath.path && this.provider === otherPath.provider && this.tenant === otherPath.tenant;
  }
}

/**
 * IDatalakePath used in Guardian context
 * In fact this is just IDatalakePath with bucket and path in separated fields
 */
export interface IBaseGuardianDatalakePath extends IDatalakePath {
  bucket: string;
}

export interface IObjectExistenceResult {
  isExisting: boolean;
  metadata: IDatalakeMetadata;
}

export interface IAzureMetadata {
  [metadataKey: string]: string;
}

export interface IDatalakeMetadata {
  acceptRanges?: string;
  lastModified?: string;
  contentLength?: string;
  eTag?: string;
  version?: string;
  contentType?: string;
  replicationStatus?: string;
  properties?: IAzureMetadata;
}
