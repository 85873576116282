<div class="source-content mt25 pt25">
  <div class="source-infos">
    <adl-title>Description</adl-title>
    <adl-text class="tinymce source-description pt10" [innerHTML]="source.description | safeHtml"></adl-text>

    <adl-title *ngIf="portals.length > 0" class="mt25">Scope</adl-title>
    <adl-text *ngIf="portals.length > 0" class="pt10"> {{ organization }}</adl-text>

  </div>
  <div class="source-right-pannel ml25">
    <dpg-source-data-access [source]="source"></dpg-source-data-access>
    <div *ngIf="source?.supersetUrl">
      <div class="pb20">
        <a target="_blank" href="{{ source.supersetUrl }}" class="btn-portal pblue btn-block center">
          Custom visualizations
        </a>
      </div>
    </div>
    <dpg-source-contact-box [source]="source" (loaded)="loaded()"></dpg-source-contact-box>
  </div>
</div>
