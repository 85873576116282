import { DatalakePath } from '@dataportal/front-shared';
import type { IDatalakePath } from '@dataportal/types';
import { Entity, Type } from '@decahedron/entity';

export interface IPortalDatalakePaths {
  pk: string;
  sk: string;
  ak: 'portals|datalake';
  portal_name: string;
  formated_datalake_path: IDatalakePath;
}

export class PortalDatalake extends Entity {
  portalId: string = null;
  portalName: string = null;
  @Type(DatalakePath) formatedDatalakePath: DatalakePath = null;

  fromJson(data: IPortalDatalakePaths): PortalDatalake {
    super.fromJson(data);
    this.portalId = data.pk;
    this.portalName = data.portal_name;

    return this;
  }
}
