import type { IBanner } from '@dataportal/types';
import { Entity } from '@decahedron/entity';

export class AlertBanner extends Entity {
  id: string;
  color: string;
  endDate: string;
  readMore: string;
  startDate: string;
  text: string;

  fromJson(jsonData: IBanner): AlertBanner {
    this.id = jsonData.pk;
    this.color = jsonData.color;
    this.endDate = jsonData.endDate;
    this.readMore = jsonData.readMore;
    this.startDate = jsonData.startDate;
    this.text = jsonData.text;

    return this;
  }
}
