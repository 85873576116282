import { Component, Input } from '@angular/core';

import { NavigateToService } from '../navigate-to.service';

import { NavbarItem } from './navbar-item.entity';

@Component({
  selector: 'dpg-navbar-item',
  templateUrl: './navbar-item.component.html',
  styleUrls: ['./navbar-item.component.scss'],
})
export class NavbarItemComponent {
  @Input() external = false;
  @Input() item: NavbarItem;

  constructor(readonly router: NavigateToService) {}

  handleOpenLink() {
    if (this.item.external || this.external) {
      window.open(this.item.url as string, '_blank');
    } else {
      this.router.navigateTo(this.item.url, false).then();
    }
  }
}
