import type { OnDestroy, OnInit } from '@angular/core';
import { Component, ElementRef, EventEmitter, HostListener, Input, Output, Renderer2, ViewChild } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { createPopper, Placement } from '@popperjs/core';

interface ClickedEvent {
  event: MouseEvent;
  hasToHide: boolean;
}

@Component({
  selector: 'adl-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent implements OnInit, OnDestroy {
  @Input() hideAfter = 0;
  @Input() showAfter = 0;
  @Input() placement: Placement = 'top';
  @Input() backgroundColor = 'primary-500';
  @Input() arrowColor = 'primary-500';

  @Input() isMouseOverBehaviorActivated = true;
  @Input() isMouseOutBehaviorActivated = true;
  @Input() isClickBehaviorActivated = true;
  @Input() isClickOutsideBehavior = false;
  @Input() isDeactivated = false;
  @Input() hasArrow = true;
  @Input() isTooltipTriggerFullWidth = false;
  @Input() isOpenable = false;

  @Input() hasShadow = false;

  @Input() isHidden$: Observable<boolean> = of(false);

  // Optional
  @Input() maxContentSize? = '280px';
  @Input() minContentSize? = 'auto';
  @Input() fullContent?: boolean;
  @Input() hasBorder?: boolean = false;
  @Input() truncateTrigger?: boolean = false;

  @ViewChild('trigger') trigger: ElementRef;
  @ViewChild('tooltip') tooltip: ElementRef;

  @Output() clicked: EventEmitter<ClickedEvent> = new EventEmitter<ClickedEvent>();
  @Output() mouseOver: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();
  @Output() mouseOut: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  private _popper = null;
  private _hiding = false;
  private _delayPopup: NodeJS.Timer;

  private readonly _destroyed$ = new Subject<void>();

  constructor(private readonly _renderer: Renderer2) {}

  ngOnInit(): void {
    this.isHidden$.pipe(takeUntil(this._destroyed$)).subscribe((isHidden) => {
      if (isHidden) {
        this._hide();
      } else if (this.isOpenable) {
        this._show();
      }
    });
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
  }

  get backgroundColorClassName(): string {
    return `tooltip-color-${this.backgroundColor}`;
  }

  get arrowColorClassName(): string {
    return `tooltip-arrow-color-${this.arrowColor}`;
  }

  private _show(): void {
    this._hiding = false;
    this._renderer.setAttribute(this.tooltip.nativeElement, 'data-show', '');
    this._popper = createPopper(this.trigger.nativeElement, this.tooltip.nativeElement, {
      placement: this.placement,
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 8],
          },
        },
      ],
    });
  }

  private _hide(): void {
    this._hiding = true;
    setTimeout(() => {
      if (this._hiding) {
        this._renderer.removeAttribute(this.tooltip.nativeElement, 'data-show');

        if (this._popper) {
          this._popper.destroy();
          this._popper = null;
        }

        this._hiding = false;
      }
    }, this.hideAfter);
  }

  onClick(event): void {
    if (this.isClickBehaviorActivated && !this.isDeactivated) {
      let hasToHide = true;

      if (this._popper) {
        this._hide();
      } else {
        this._show();
        hasToHide = false;
      }

      this.clicked.emit({ event, hasToHide });
    }
  }

  onMouseHover(event): void {
    if (this.isMouseOverBehaviorActivated && !this.isDeactivated) {
      this.mouseOver.emit(event);
      this._delayPopup = setTimeout(() => {
        this._delayPopup = null;
        this._show();
      }, this.showAfter);
    }
  }

  onMouseOut(event): void {
    if (this._delayPopup) {
      clearTimeout(this._delayPopup);
    }

    if (this.isMouseOutBehaviorActivated) {
      this._hide();
      this.mouseOut.emit(event);
    }
  }

  @HostListener('document:click', ['$event'])
  onClickedOutside(event) {
    if (
      this.isClickOutsideBehavior &&
      !this.tooltip.nativeElement.contains(event.target) &&
      !this.trigger.nativeElement.contains(event.target)
    ) {
      this._hide();
    }
  }
}
