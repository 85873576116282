<div class="dashboard-comments-part flex flex-col justify-start items-center">
  <div class="dashboard-comments-part-container flex flex-col justify-start items-center">
    <div class="dashboard-comments-post">
      <adl-post-comment-item
        (onClickCancel)="cancelPostComment()"
        (onClickValidate)="validatePostComment($event)"
        [clearInput$]="clearComponentInput$"
        [hasToDisabledButtons]="hasToDisablePostingNewComment"
        [isInputZoneDisabled]="hasToDisable"
        [isLoadingPicture]="isLoadingCurrentUserPictureBase64"
        [isSmallerItem]="false"
        [pictureSrc]="currentUserPictureBase64"
        [setCursorAtTheEndOfEditZone$]="setCursorAtTheEndOfCommentInput$"
        cancelBtnText="Cancel"
        validateBtnText="Send"
      >
      </adl-post-comment-item>
    </div>
    <div class="line w-full"></div>
    <div class="dashboard-comments-tree flex flex-col justify-start items-center w-full">
      <adl-loaded-item [loading]="isLoadingDashboardComments" class="w-full">
        <div class="flex flex-col justify-start items-start w-full">
          <dpg-dashboard-comments-thread
            *ngFor="let comment of dashboardCommentsPaginatedList[currentPageIndex - 1]"
            [attr.id]="comment.id"
            [currentDashboard]="currentDashboard"
            [currentUserId]="currentUserId"
            [currentUserPictureBase64]="currentUserPictureBase64"
            [dashboardComment]="comment"
            [isCurrentUserAdmin]="isCurrentUserAdmin"
            [isLoadingCurrentUserPictureBase64]="isLoadingCurrentUserPictureBase64"
            [isLoadingDashboardComments]="isLoadingDashboardComments"
            [level]="0"
            class="w-full"
          ></dpg-dashboard-comments-thread>
        </div>
      </adl-loaded-item>
    </div>
    <adl-paginator
      (clickedOnNext)="goToPage($event)"
      (clickedOnPageButton)="goToPage($event)"
      (clickedOnPrevious)="goToPage($event)"
      *ngIf="!isLoadingDashboardComments"
      [currentPageIndex]="currentPageIndex"
      [nbMaxPageButtonsToDisplay]="6"
      [nbPages]="dashboardCommentsPaginatedList?.length || 0"
      class="dashboard-comments-pagination"
      textColor="pagination-text"
    ></adl-paginator>
  </div>
</div>
