import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { AdlModule } from '@dataportal/adl';
import { ApiModule } from '@dataportal/front-api';
import { GraphApiModule } from '@dataportal/front-graph-api';
import { PermissionsModule } from '@dataportal/permissions';
import { UsersModule } from '@dataportal/users';
import { NgSelectModule } from '@ng-select/ng-select';

import { GroupsService } from './services/groups.service';
import { GroupsAdService } from './services/groups-ad.service';
import { GroupsMembersService } from './services/groups-members.service';

import { AddGroupRoleModalComponent } from './components/add-group-role-modal/add-group-role-modal.component';
import { CreateOrImportGroupModalComponent } from './components/create-or-import-group-modal/create-or-import-group-modal.component';
import { GroupAdSelectComponent } from './components/group-ad-select/group-ad-select.component';
import { GroupSelectComponent } from './components/group-select/group-select.component';
import { SelectGroupComponent } from './components/select-group/select-group.component';

// Module
// @dynamic
@NgModule({
  imports: [
    CommonModule,
    GraphApiModule,
    FormsModule,
    NgSelectModule,
    MatDialogModule,
    AdlModule,
    ApiModule,
    UsersModule,
    PermissionsModule,
  ],
  declarations: [
    AddGroupRoleModalComponent,
    CreateOrImportGroupModalComponent,
    GroupAdSelectComponent,
    GroupSelectComponent,
    SelectGroupComponent,
  ],
  exports: [
    AddGroupRoleModalComponent,
    CreateOrImportGroupModalComponent,
    GroupAdSelectComponent,
    GroupSelectComponent,
    SelectGroupComponent,
  ],
  providers: [
    GroupsService,
    GroupsAdService,
    GroupsMembersService,
    SelectGroupComponent,
    { provide: MAT_DIALOG_DATA, useValue: {} },
  ],
})
export class GroupsModule {}
