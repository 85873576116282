import { Entity } from '@decahedron/entity';

export interface IToolLink {
  url: string;
  name: string;
  isExternal: boolean;
}

export class ToolLink extends Entity {
  url: string;
  name: string;
  isExternal: boolean;
  routerLink: string | null;

  constructor(url: string, name: string, isExternal: boolean) {
    super();
    this.url = url;
    this.isExternal = isExternal;
    this.name = name;
    this.routerLink = !isExternal && url ? new URL(url).pathname : null;
  }

  fromJson(jsonData: IToolLink): ToolLink {
    this.url = jsonData.url;
    this.isExternal = jsonData.isExternal;
    this.name = jsonData.name;
    this.routerLink = !this.isExternal && this.url ? new URL(this.url).pathname : null;

    return super.fromJson(jsonData);
  }

  toJson(): IToolLink {
    return {
      url: this.url,
      isExternal: this.isExternal,
      name: this.name,
    };
  }
}
