<tr class="row-flex j-start">
  <td class="column-index">
    <div class="row-number global-column-name-column-padding--field-check text-left">
      <adl-text type="accent" size="12" [bold]="false">
        {{ index + 1 }}
      </adl-text>
    </div>
  </td>
  <td
    class="column-name-column-width flex flex-col items-start p-0"
    [attr.data-cy]="'guardian-creation-check-row-' + (index + 1)"
  >
    <div class="global-column-name-column-padding--field-check flex flex-row justify-start items-center w-full">
      <div class="column-name-column-padding--field-check flex flex-row justify-center items-center">
        <div class="column-name flex flex-row justify-center items-center br-2 w-full">
          <adl-text class="text-left whitespace-normal break-all w-full" color="primary-800">{{
            columnField?.column_name
          }}</adl-text>
        </div>
      </div>
    </div>
  </td>
  <td class="constraint-column separator-left flex flex-col justify-start items-start">
    <div class="flex flex-col justify-start items-start w100">
      <adl-selectable-form-control
        *ngIf="columnField?.type?.length"
        [control]="null"
        [hasRemoveBtn]="false"
        [label]="getConstraintLabelFromConstraint('type')"
        [hint]="getConstraintTooltipFromConstraint('type')"
        labelColor="primary-800"
        labelWidth="100px"
        class="w-full"
      >
        <div class="text-input-disabled flex flex-row justify-center items-center">
          <adl-text>{{ getTypeToDisplay(columnField?.type) }}</adl-text>
        </div>
      </adl-selectable-form-control>
      <adl-selectable-form-control
        *ngIf="columnField?.required_values !== null && columnField?.required_values !== undefined"
        [control]="null"
        [hasRemoveBtn]="false"
        [label]="getConstraintLabelFromConstraint('isRequired')"
        [hint]="getConstraintTooltipFromConstraint('isRequired')"
        labelColor="primary-800"
        labelWidth="100px"
        class="mt-2 w-full"
      >
        <div class="text-input-disabled flex flex-row justify-center items-center">
          <adl-text>{{ getFormattedBoolean(columnField?.required_values) }}</adl-text>
        </div>
      </adl-selectable-form-control>
      <adl-selectable-form-control
        *ngIf="columnField?.unique !== null && columnField?.unique !== undefined"
        [control]="null"
        [hasRemoveBtn]="false"
        [label]="getConstraintLabelFromConstraint('isUnique')"
        [hint]="getConstraintTooltipFromConstraint('isUnique')"
        labelColor="primary-800"
        labelWidth="100px"
        class="mt-2 w-full"
      >
        <div class="text-input-disabled flex flex-row justify-center items-center">
          <adl-text>{{ getFormattedBoolean(columnField?.unique) }}</adl-text>
        </div>
      </adl-selectable-form-control>
      <adl-selectable-form-control
        *ngIf="columnField?.min !== null && columnField?.min !== undefined"
        [control]="null"
        [hasRemoveBtn]="false"
        [label]="getConstraintLabelFromConstraint('minValue')"
        [hint]="getConstraintTooltipFromConstraint('minValue')"
        labelColor="primary-800"
        labelWidth="100px"
        class="mt-2 w-full"
      >
        <div class="text-input-disabled flex flex-row justify-center items-center">
          <adl-text>{{ columnField?.min }}</adl-text>
        </div>
      </adl-selectable-form-control>
      <adl-selectable-form-control
        *ngIf="columnField?.max !== null && columnField?.max !== undefined"
        [control]="null"
        [hasRemoveBtn]="false"
        [label]="getConstraintLabelFromConstraint('maxValue')"
        [hint]="getConstraintTooltipFromConstraint('maxValue')"
        labelColor="primary-800"
        labelWidth="100px"
        class="mt-2 w-full"
      >
        <div class="text-input-disabled flex flex-row justify-center items-center">
          <adl-text>{{ columnField?.max }}</adl-text>
        </div>
      </adl-selectable-form-control>
      <adl-selectable-form-control
        *ngIf="columnField?.pair_greater?.length"
        [control]="null"
        [hasRemoveBtn]="false"
        [label]="getConstraintLabelFromConstraint('greaterThan')"
        [hint]="getConstraintTooltipFromConstraint('greaterThan')"
        labelColor="primary-800"
        labelWidth="100px"
        class="mt-2 w-full"
      >
        <div class="text-input-disabled flex flex-row justify-center items-center">
          <adl-text>{{ columnField?.pair_greater }}</adl-text>
        </div>
      </adl-selectable-form-control>
      <adl-selectable-form-control
        *ngIf="columnField?.regex?.length"
        [control]="null"
        [hasRemoveBtn]="false"
        [label]="getConstraintLabelFromConstraint('regex')"
        [hint]="getConstraintTooltipFromConstraint('regex')"
        labelColor="primary-800"
        labelWidth="100px"
        class="mt-2 w-full"
      >
        <adl-tooltip>
          <adl-loaded-item tooltip-content [loading]="!allCheckRegex">
            <div class="flex flex-col justify-center items-start w-full">
              <adl-text class="whitespace-normal break-all text-left w-full" type="tooltip" size="12"
                >Regular expression : {{ columnField?.regex }}</adl-text
              >
              <adl-text class="whitespace-normal break-all text-left w-full mt-2" type="tooltip" size="12">{{
                getLabelForFieldCheckRegex(columnField?.regex)
              }}</adl-text>
            </div>
          </adl-loaded-item>
          <div tooltip-trigger class="text-input-disabled flex flex-row justify-center items-center">
            <adl-text class="whitespace-normal break-all w-full text-left" size="12">{{
              shortenLabelForReferential(columnField?.regex)
            }}</adl-text>
          </div>
        </adl-tooltip>
      </adl-selectable-form-control>
      <adl-selectable-form-control
        *ngIf="isDatalakeReferential(true) || isSnowflakeReferential()"
        [control]="null"
        [hasRemoveBtn]="false"
        [label]="getConstraintLabelForExternalReferential(true)"
        [hint]="getConstraintTooltipForExternalReferential(true)"
        labelColor="primary-800"
        labelWidth="100px"
        class="mt-2 w-full"
      >
        <ng-container *ngIf="this.isSnowflakeReferential()">
          <dpg-guardian-preview-tooltip
            [isLoading]="isLoadingSnowflakeColumnExtract"
            [title]="'Preview :'"
            [description]="formattedSnowflakeColumnExtract"
            [actionBtnDescription]="'Display more'"
            [childTemplate]="MDHTooltipChildTemplate"
            (actionEvent)="openReferentialOverviewModal()"
          >
          </dpg-guardian-preview-tooltip>
        </ng-container>
        <div *ngIf="this.isDatalakeReferential(true)" class="flex flex-col w-full">
          <dpg-guardian-preview-tooltip
            [isLoading]="isLoadingDatalakeInReferential"
            [title]="'Location :'"
            [description]="getLabelForExternalReferential(true)"
            [actionBtnDescription]="'Go to path'"
            [childTemplate]="DatalakeInTooltipChildTemplate"
            (actionEvent)="redirectToDatalakePath(true)"
            class="text-left"
          >
          </dpg-guardian-preview-tooltip>
        </div>
      </adl-selectable-form-control>
      <adl-selectable-form-control
        *ngIf="isDatalakeReferential(false)"
        [control]="null"
        [hasRemoveBtn]="false"
        [label]="getConstraintLabelForExternalReferential(false)"
        [hint]="getConstraintTooltipForExternalReferential(false)"
        labelColor="primary-800"
        labelWidth="100px"
        class="mt-2 w-full"
      >
        <div *ngIf="this.isDatalakeReferential(false)" class="flex flex-col w-full">
          <dpg-guardian-preview-tooltip
            [isLoading]="isLoadingDatalakeNotInReferential"
            [title]="'Location :'"
            [description]="getLabelForExternalReferential(false)"
            [actionBtnDescription]="'Go to path'"
            [childTemplate]="DatalakeNotInTooltipChildTemplate"
            (actionEvent)="redirectToDatalakePath(false)"
            class="text-left"
          >
          </dpg-guardian-preview-tooltip>
        </div>
      </adl-selectable-form-control>
    </div>
  </td>
</tr>

<ng-template #MDHTooltipChildTemplate>
  <div class="text-input-disabled flex flex-row justify-center items-center w-full">
    <adl-text>{{ getLabelForExternalReferential(true) }}</adl-text>
  </div>
</ng-template>

<ng-template #DatalakeInTooltipChildTemplate>
  <div class="text-input-disabled flex flex-row justify-center items-center w-full">
    <adl-text class="whitespace-normal break-all w-full" size="12">{{
      shortenLabelForReferential(getLabelForExternalReferential(true))
    }}</adl-text>
  </div>
</ng-template>

<ng-template #DatalakeNotInTooltipChildTemplate>
  <div class="text-input-disabled flex flex-row justify-center items-center w-full">
    <adl-text class="whitespace-normal break-all w-full" size="12">{{
      shortenLabelForReferential(getLabelForExternalReferential(false))
    }}</adl-text>
  </div>
</ng-template>
