import type { OnInit } from '@angular/core';
import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import type { IAPITableReferential } from '@dataportal/front-api';
import { APIReferential } from '@dataportal/front-api';
import { AlertService } from '@dataportal/front-shared';
import { DBSnowflakeService } from '@dataportal/snowflake';
import { saveAs } from 'file-saver';

@Component({
  selector: 'dpg-guardian-referential-overview-modal',
  templateUrl: './guardian-referential-overview-modal.component.html',
})
export class GuardianReferentialOverviewModalComponent implements OnInit {
  // Attributes
  @Input() externalReferential: APIReferential;
  @Input() label: string;

  NB_VALUES_PER_PAGE = 100;

  extractedValues: string[] = [];
  displayedValues: string[] = [];
  isLoading = true;
  currentPage = 0;
  hasToDisplayMoreButton = false;

  private readonly _destroyed$ = new Subject<void>();

  // Constructor
  constructor(
    private readonly _activeModal: MatDialogRef<GuardianReferentialOverviewModalComponent>,
    private readonly _dbSnowflakeService: DBSnowflakeService,
    private readonly _alertService: AlertService,
    private readonly _activeMatModal: MatDialogRef<GuardianReferentialOverviewModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    data: {
      externalReferential: APIReferential;
      label: string;
    },
  ) {
    this.externalReferential = data?.externalReferential ? data.externalReferential : this.externalReferential;
    this.label = data?.label?.length ? data.label : this.label;
  }

  ngOnInit(): void {
    this.isLoading = true;

    if (this.externalReferential && Object.keys(this.externalReferential).includes('sql_engine')) {
      this._dbSnowflakeService
        .getColumnExtract(this.externalReferential as IAPITableReferential)
        .pipe(takeUntil(this._destroyed$))
        .subscribe((extractedValues) => {
          this.extractedValues = extractedValues;
          this.displayMore();
          this.isLoading = false;
        });
    }
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
  }

  get displayedValuesStr(): string {
    return this.displayedValues?.length
      ? this.displayedValues.map((value) => `"${value}"`).join(', ') + (this.hasToDisplayMoreButton ? '...' : '')
      : 'No value to display';
  }

  // Methods
  close(): void {
    this._activeModal.close();
  }

  displayMore(): void {
    const startValueIndex = this.currentPage * this.NB_VALUES_PER_PAGE;
    const endValueIndex = startValueIndex + this.NB_VALUES_PER_PAGE;
    const isLastPage = endValueIndex >= this.extractedValues.length;
    this.displayedValues.push(...this.extractedValues.slice(startValueIndex, isLastPage ? undefined : endValueIndex));
    this.currentPage += 1;
    this.hasToDisplayMoreButton = !isLastPage;
  }

  downloadCsvFile(): void {
    if (this.displayedValues?.length) {
      const valuesToExport = [...this.extractedValues];
      valuesToExport.unshift('ACCEPTED_VALUES');
      const csvArray = valuesToExport.join('\r\n');
      const blob = new Blob([csvArray], { type: 'text/csv' });

      saveAs(blob, `Whitelisting_${this.label}.csv`);

      return;
    }

    this._alertService.error('There is no data to download !');
  }
}
