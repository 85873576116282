import { Component, HostBinding, Input } from '@angular/core';
import type { SafeResourceUrl } from '@angular/platform-browser';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

type Shape = 'rounded' | 'square';

@Component({
  selector: 'adl-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
})
export class ImageComponent {
  /* inputs */
  @Input() src: string | SafeResourceUrl = '';
  @Input() alt = '';
  @Input() shape: Shape = 'rounded';
  @Input() size = '70px';
  @Input() equalizeHeight = false;
  @Input() flexGrow = 1;
  @HostBinding('style')
  get style(): SafeStyle {
    return this._sanitizer.bypassSecurityTrustStyle(`width: ${this.size}; flex: ${this.flexGrow}; `);
  }

  constructor(private readonly _sanitizer: DomSanitizer) {}
}
