import { Entity } from '@decahedron/entity';

import type { IARResourceRolesMap, ISupportedARResource } from './utils';

// Types
export type FinalAccessRequestStatus = 'approved' | 'denied' | 'canceled';

// Model
export class AccessRequestArchive<R extends ISupportedARResource = ISupportedARResource> extends Entity {
  requestedRole: IARResourceRolesMap[R] = null;
  attributedRole?: IARResourceRolesMap[R] = null;
  finalStatus: FinalAccessRequestStatus = null;
  hasExpired: boolean = null;
  validator: string = null; // validator userId
  requestDate: number = null; // timestamp
  archiveDate: number = null; // timestamp
}
