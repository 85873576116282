<adl-modal headerBackgroundColor="blue-dark" backgroundColor="light" [hasMarginBottom]="false" [isAlignedToCross]="true" [roundCorner]="true" [closeButtonColor]="'white'">
  <div class="modal-header w-full items-center" modal-header>
    <adl-title class="flex ml-8 my-7" [type]="'modal'" [color]="'#ffffff'">
      Rename bookmark {{ bookmark.name }}
    </adl-title>
  </div>

  <div modal-body class="modal-body flex flex-col items-start">
    <adl-text class="w-full text-left" type="accent" [size]="14">New bookmark name</adl-text>
    <adl-text class="w-full text-left" [size]="13">Please enter a new name for this bookmark.</adl-text>
    <adl-text *ngIf="bookmark.sharedBy" class="w-full text-left" [size]="13">Once the name is modified, it will appear in the section "My Bookmarks".</adl-text>
    <adl-input class="mt-3 w-full" [control]="newNameControl" [placeholder]="'Enter a name'"></adl-input>
  </div>
  <div modal-footer class="row-flex j-center pb-8">
    <adl-button class="mr-5" (clicked)="close()" type="cancel"> Cancel </adl-button>
    <adl-button (clicked)="save()" [disabled]="!newNameControl.value"> Save </adl-button>
  </div>
</adl-modal>
