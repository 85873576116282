import { Inject, Injectable } from '@angular/core';
import type { SafeResourceUrl } from '@angular/platform-browser';
import { DomSanitizer } from '@angular/platform-browser';
import type { Observable } from 'rxjs';
import { BehaviorSubject, forkJoin, of, throwError } from 'rxjs';
import { catchError, first, map, tap } from 'rxjs/operators';
import type { ErrorHandlingOptions } from '@dataportal/front-api';
import { ApiPaginatedService, ApiService } from '@dataportal/front-api';
import { IS_ACCOR } from '@dataportal/front-environment';
import { Logger } from '@dataportal/front-shared';
import type { IUser } from '@dataportal/types';
import { EntityBuilder } from '@decahedron/entity';
import type { Moment } from 'moment';
import moment from 'moment';

import type { IProfile, IUserPayload } from '../entities/user';
import { User } from '../entities/user';

@Injectable()
export class UsersService extends ApiPaginatedService<User> {
  // Attributes
  protected url = '/v4/users';

  private readonly _isLoadingCanToggle$ = new BehaviorSubject<boolean>(false);
  private _cacheUntil?: Moment;
  private readonly _cachedCanToggle$ = new BehaviorSubject<boolean>(undefined);

  private readonly _cachePictures = new Map<string, SafeResourceUrl>();
  private readonly _cacheProfiles = new Map<string, IProfile>();

  // Constructor
  constructor(
    private readonly _sanitizer: DomSanitizer,
    @Inject(IS_ACCOR) private readonly _isAccor: boolean,
    protected apiService: ApiService,
    protected logger: Logger,
  ) {
    super(apiService, logger);
  }

  // Methods
  protected buildOne(json: IUser): User {
    return EntityBuilder.buildOne(User, json);
  }

  protected buildMany(json: IUser[]): User[] {
    return EntityBuilder.buildMany(User, json);
  }

  toggleAdmin(): Observable<void> {
    return this.apiService.put<void>(`${this.url}/admin`, {});
  }

  canToggleAdmin(): Observable<boolean> {
    const isBeforeCacheUntil = !!this._cacheUntil && moment().isBefore(this._cacheUntil);
    const shouldFetchFromBackEnd = !isBeforeCacheUntil && !this._isLoadingCanToggle$.value;

    if (shouldFetchFromBackEnd) {
      this._isLoadingCanToggle$.next(true);

      return this.apiService.get<boolean>(`${this.url}/admin`).pipe(
        map((canToggle) => {
          this._cacheUntil = moment().add(30, 'seconds');
          this._cachedCanToggle$.next(canToggle);
          this._isLoadingCanToggle$.next(false);

          return canToggle;
        }),
        catchError((err: unknown) => {
          this._cacheUntil = null;
          this._cachedCanToggle$.next(false);
          this._isLoadingCanToggle$.next(false);

          return throwError(err);
        }),
      );
    }

    return this._cachedCanToggle$;
  }

  getProfile(id: string, errorHandling?: ErrorHandlingOptions, useCache = false): Observable<IProfile | null> {
    if (useCache) {
      const cached = this._cacheProfiles.get(id);

      if (cached) {
        return of(cached);
      }
    }

    return forkJoin([
      this.get(id, {
        errorHandling: errorHandling ?? {
          400: { level: 'silent' },
          404: { level: 'silent' },
          500: { level: 'silent' },
          '*': { level: 'error' },
        },
      }).pipe(catchError(() => of(new User()))),
      this.getImage(
        id,
        errorHandling ?? {
          404: { level: 'silent' },
          502: { level: 'silent' },
          504: { level: 'silent' },
          '*': { level: 'error' },
        },
      ),
    ]).pipe(
      map(([user, resourceUrl]) => {
        const profile: IProfile = {
          email: id,
          title: user.adInformation ? user.adInformation.jobTitle : null,
          name: user.name,
          picture: resourceUrl,
        };
        this._cacheProfiles.set(id, profile);

        return profile;
      }),
      // TODO: return a default profile object with default picture and id, instead of null
      catchError(() => of(null)),
    );
  }

  getImage(id: string, errorHandling?: ErrorHandlingOptions): Observable<SafeResourceUrl> {
    const cached = this._cachePictures.get(id);

    if (cached) {
      return of(cached);
    }

    return this.apiService
      .get<{ data: number[] }>(`${this.url}/${id}/picture`, {
        errorHandling: this._isAccor
          ? { '*': { level: 'silent' } }
          : errorHandling ?? {
              404: { level: 'silent' },
              '*': { level: 'error' },
            },
      })
      .pipe(
        first(),
        map((response) => {
          if (response && response.data) {
            let binary = '';
            const bytes = new Uint8Array(response.data);
            const len = bytes.byteLength;

            for (let i = 0; i < len; i++) {
              binary += String.fromCharCode(response.data[i]);
            }

            let base64 = window.btoa(binary);
            base64 = `data:image/jpeg;base64,${base64}`;

            return this._sanitizer.bypassSecurityTrustResourceUrl(base64);
          }

          return '/assets/img/icon/contact-default.png';
        }),
        catchError(() => of('/assets/img/icon/contact-default.png')),
        tap((url) => this._cachePictures.set(id, url)),
      );
  }

  update(user: IUserPayload): Observable<void> {
    const updated = { ...user };
    delete updated.user_id;

    return super.put(user.user_id, updated);
  }
}
