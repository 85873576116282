import type { OnDestroy, OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { SidebarV3Service } from './sidebar-v3.service';

@Component({
  selector: 'adl-sidebar-v3',
  templateUrl: './sidebar-v3.component.html',
  styleUrls: ['./sidebar-v3.component.scss'],
})
export class SidebarV3Component implements OnInit, OnDestroy {
  isActivated = true;
  isDeveloped = false;

  private readonly _destroyed$ = new Subject<void>();

  constructor(private readonly _sidebarService: SidebarV3Service) {}

  ngOnInit(): void {
    this.reduceSidebar();

    this._sidebarService.isVisible$.pipe(takeUntil(this._destroyed$)).subscribe((isVisible) => {
      this.isActivated = isVisible;
    });

    this._sidebarService.isDeveloped$.pipe(takeUntil(this._destroyed$)).subscribe((isDeveloped) => {
      this.isDeveloped = isDeveloped;
    });
  }

  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  toggleVisibility() {
    this._sidebarService.toggleVisibility();
  }

  developSidebar() {
    this._sidebarService.developSidebar();
  }

  reduceSidebar() {
    if (this.isDeveloped && this.isActivated) {
      this._sidebarService.reduceSidebar();
    }
  }
}
