<div class="content flex flex-col justify-center items-center">
  <app-tabs [tabToActivate]="activeTab" width="419px" marginLeft="0" justifyType="start">
    <app-tab [tabTitle]="tabsList[0]">
      <dpg-guardian-check-infos-columns
        [guardianStatus]="guardianStatus"
        [allCheckRegex]="allCheckRegex"
        [externalReferentials]="externalReferentials"
      ></dpg-guardian-check-infos-columns>
    </app-tab>
    <app-tab [tabTitle]="tabsList[1]">
      <dpg-guardian-check-infos-global
        [guardianStatus]="guardianStatus"
        [allCheckRegex]="allCheckRegex"
      ></dpg-guardian-check-infos-global>
    </app-tab>
  </app-tabs>
  <adl-tooltip
    *ngIf="hasExportButton"
    placement="bottom"
    backgroundColor="blue-dark"
    arrowColor="blue-dark"
    class="export-button"
    [ngStyle]="{ top: exportButtonPositionTop }"
  >
    <adl-text tooltip-content type="accent" [bold]="false" size="12" class="tac" [color]="'white'"
      >Export specifications to JSON</adl-text
    >
    <adl-button
      tooltip-trigger
      type="guardian-light"
      size="normal"
      (clicked)="this.exportConstraintsToJsonFile()"
      [isSquareButton]="true"
    >
      <div class="row-flex w100">
        <img alt="upload img" src="assets/dpg/imgs/commons/download.svg" class="mr-4" />
        <adl-text color="secondary-500">Export configuration</adl-text>
      </div></adl-button
    >
  </adl-tooltip>
</div>
