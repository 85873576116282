import type { OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Component, Input } from '@angular/core';

import { SupportedFaIcon } from '../icons';
import type { IStepperStep } from './stepper.interface';

@Component({
  selector: 'adl-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
})
export class StepperComponent implements OnInit, OnChanges {
  @Input() steps: IStepperStep[];
  @Input() activeStep: string;
  @Input() useCurrentActive = false;
  @Input() circleSize: 'small' | 'normal' = 'normal';
  @Input() stepShape: 'circle' | 'rect' = 'circle';
  @Input() theme: 'dp' | 'bg' = 'dp';
  @Input() useIcons = false;
  @Input() activeIcon: SupportedFaIcon = 'check';
  @Input() passiveIcon: SupportedFaIcon = 'clock';

  insideText = [];

  isBeforeActiveStep(currentStep: IStepperStep) {
    const activeIndex = this.steps.findIndex((step) => step.label === this.activeStep);
    const currentIndex = this.steps.findIndex((step) => step.label === currentStep.label);

    return currentIndex < activeIndex;
  }

  ngOnInit() {
    this._updateInsideTexts();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.steps) {
      this._updateInsideTexts();
    }
  }

  useSpecificIcons(step: IStepperStep): SupportedFaIcon {
    if (step.specialIcon) {
      return step.specialIcon;
    }

    return step.label === this.activeStep ? this.activeIcon : this.passiveIcon;
  }

  private _updateInsideTexts() {
    if (this.stepShape === 'rect') {
      this.insideText = this.steps.map((step) => {
        return step.label;
      });
    } else if (this.stepShape === 'circle') {
      this.insideText = [...Array(this.steps.length).keys()].map((i) => i + 1);
    }
  }
}
