<adl-tooltip
  [isMouseOverBehaviorActivated]="false"
  [isMouseOutBehaviorActivated]="false"
  [isClickBehaviorActivated]="true"
  [isClickOutsideBehavior]="true"
  [hasArrow]=false
  [isHidden$]='isHidden$'
  placement="bottom"
  backgroundColor="white"
>
  <ng-content tooltip-trigger class="c-pointer"></ng-content>
  <div tooltip-content class="tooltip-content" [ngStyle]="minWidth?.length && { 'min-width': minWidth }">
    <ng-container *ngIf="choices?.length">
      <div
        *ngFor="let choice of choices; let index = index"
        class="option row-flex j-start a-center w100 c-pointer"
        [ngClass]="{ 'option-disabled': choice?.isDisabled }"
        (click)="selectionChoice(choice)"
      >
        <adl-tooltip
          *ngIf="choice?.tooltip?.length"
          [isTooltipTriggerFullWidth]="true"
          [hasArrow]="false"
          placement="right"
          class="w100"
        >
          <ng-container tooltip-trigger>
            <div
              *ngIf="choice?.icon?.length"
              class="row-flex j-start a-center pt5 pb5 w100"
              [ngClass]="{ 'cursor-not-allowed': choice?.isDisabled }"
            >
              <adl-icon class="w10" [icon]="choice.icon" iconType="primary"></adl-icon>
              <adl-text class="pl15 tal w90" type="accent" [bold]="false" size="14">{{ choice.label }}</adl-text>
            </div>
            <div
              *ngIf="!choice?.icon?.length"
              class="row-flex j-center a-center pt5 pb5 pl5 w100"
              [ngClass]="{ 'cursor-not-allowed': choice.isDisabled }"
            >
              <adl-text class="tal w100" type="accent" [bold]="false" size="14">{{ choice.label }}</adl-text>
            </div>
          </ng-container>
          <ng-container tooltip-content
            ><adl-text type="tooltip" size="12" class="w-full tal whitespace-normal break-words">{{
              choice.tooltip
            }}</adl-text></ng-container
          >
        </adl-tooltip>
        <ng-container *ngIf="!choice?.tooltip?.length" class="w100">
          <div
            *ngIf="choice?.icon?.length"
            class="row-flex j-start a-center pt5 pb5 w100"
            [ngClass]="{ 'cursor-not-allowed': choice.isDisabled }"
          >
            <adl-icon class="w10" [icon]="choice.icon" iconType="primary"></adl-icon>
            <adl-text class="pl15 tal w90" type="accent" [bold]="false" size="14">{{ choice.label }}</adl-text>
          </div>
          <div
            *ngIf="!choice?.icon?.length"
            class="row-flex j-center a-center pt5 pb5 pl5 w100"
            [ngClass]="{ 'cursor-not-allowed': choice.isDisabled }"
          >
            <adl-text class="tal w100" type="accent" [bold]="false" size="14">{{ choice.label }}</adl-text>
          </div>
        </ng-container>
      </div>
    </ng-container>
    <div *ngIf="!choices?.length" class="row-flex j-center a-center pb5 pl5 w100 c-not-allowed">
      <adl-text class="tal w100" type="accent" [bold]="false" size="14">{{ emptyMessage }}</adl-text>
    </div>
  </div>
</adl-tooltip>
