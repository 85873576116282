import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import type { IGuardianChecksDatasetMap, ISupportedGuardianChecksResource } from '../../entities/guardian-form';
import { IGuardianStatus } from '../../entities/guardian-form';

/**
 *  Modal that regroup the information of the column and the global configuration of a Guardian Check + the modal header of Guardian
 */
@Component({
  selector: 'dpg-guardian-check-infos-modal',
  templateUrl: './guardian-check-infos-modal.component.html',
  styleUrls: ['./guardian-check-infos-modal.component.scss'],
})
export class GuardianCheckInfosModalComponent<R extends ISupportedGuardianChecksResource> {
  @Input() dataset: IGuardianChecksDatasetMap[R];
  @Input() guardianStatus: IGuardianStatus;

  // Constructor
  constructor(
    private readonly _activeMatModal: MatDialogRef<GuardianCheckInfosModalComponent<R>>,
    @Inject(MAT_DIALOG_DATA)
    data: {
      dataset: IGuardianChecksDatasetMap[R];
      guardianStatus: IGuardianStatus;
    },
  ) {
    this.dataset = data?.dataset ? data.dataset : this.dataset;
    this.guardianStatus = data?.guardianStatus ? data.guardianStatus : this.guardianStatus;
  }

  // Methods
  close(): void {
    this._activeMatModal.close();
  }
}
