import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { AccessRequestsModule } from '@dataportal/access-requests';
import { AdlModule } from '@dataportal/adl';
import { AmundsenModule } from '@dataportal/amundsen';
import { AthenaModule } from '@dataportal/athena';
import { AuthModule } from '@dataportal/auth';
import { CategoriesModule } from '@dataportal/categories';
import { DatalakeModule } from '@dataportal/datalake-and-guardian';
import { SharedModule } from '@dataportal/front-shared';
import { IconsModule } from '@dataportal/icons';
import { ImpersonateModule } from '@dataportal/impersonate';
import { NavigatorModule } from '@dataportal/navigator';
import { NotificationsModule } from '@dataportal/notifications';
import { PermissionsModule } from '@dataportal/permissions';
import { PortalsModule } from '@dataportal/portals';
import { DashboardsModule, SourcesModule } from '@dataportal/sources-dashboards-recommendations';
import { UsersModule } from '@dataportal/users';
import { WordingModule } from '@dataportal/wording';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { SearchService } from './services/search.service';
import { SourceModalsService } from './services/source-modals.service';
import { CatalogV2NavigationService } from './services/v2/navigation.service';
import { CatalogV2SearchService } from './services/v2/search.service';

import { PreviewV2Guard } from './guard/preview-v2.guard';

import { KpisChartsComponent } from './components/kpis-charts/kpis-charts.component';
import { ScopesTreeItemComponent } from './components/scopes-tree-item/scopes-tree-item.component';
import { SearchFiltersComponent } from './components/search/search-filters/search-filters.component';
import { SourceSearchComponent } from './components/search/source-search/source-search.component';
import { SourceBannerComponent } from './components/source/source-banner/source-banner.component';
import { SourceCardComponent } from './components/source/source-card/source-card.component';
import { SourceContactBoxComponent } from './components/source/source-contact-box/source-contact-box.component';
import { SourceContentComponent } from './components/source/source-content/source-content.component';
import { SourceDataAccessComponent } from './components/source/source-data-access/source-data-access.component';
import { SourceMetadataModalComponent } from './components/source/source-metadata-modal/source-metadata-modal.component';
import { SourceModalComponent } from './components/source/source-modal/source-modal.component';
import { CatalogAmundsenComponent } from './components/v2/catalog-amundsen/catalog-amundsen.component';
import { CatalogAmundsenDataTableComponent } from './components/v2/catalog-amundsen-data-table/catalog-amundsen-data-table.component';
import { CatalogContactComponent } from './components/v2/catalog-contact/catalog-contact.component';
import { CatalogDataAssetContactsComponent } from './components/v2/catalog-data-asset-contacts/catalog-data-asset-contacts.component';
import { CatalogHeaderComponent } from './components/v2/catalog-header/catalog-header.component';
import { CatalogStickySearchbarComponent } from './components/v2/catalog-header/catalog-sticky-searchbar/catalog-sticky-searchbar.component';
import { CatalogModeSwitchComponent } from './components/v2/catalog-mode-switch/catalog-mode-switch.component';
import { CatalogRequestAccessButtonComponent } from './components/v2/catalog-request-access-button/catalog-request-access-button.component';
import { CatalogSearchbarComponent } from './components/v2/catalog-searchbar/catalog-searchbar.component';
import { CatalogSourceLabelComponent } from './components/v2/catalog-source-label/catalog-source-label.component';
import { CatalogSwitchComponent } from './components/v2/catalog-switch/catalog-switch.component';
import { CatalogUserProfileComponent } from './components/v2/catalog-user-profile/catalog-user-profile.component';
import { CatalogAdvancedFiltersModalComponent } from './components/v2/modals/catalog-advanced-filters-modal/catalog-advanced-filters-modal.component';
import { CatalogRecommendationModalComponent } from './components/v2/modals/catalog-recommendation-modal/catalog-recommendation-modal.component';
import { CatalogRequestAccessModalComponent } from './components/v2/modals/catalog-request-access-modal/catalog-request-access-modal.component';
import { CatalogSnowflakePreviewModalComponent } from './components/v2/modals/catalog-snowflake-preview-modal/catalog-snowflake-preview-modal.component';
import { SourcePermissionModalComponent } from './components/v2/modals/source-permission-modal/source-permission-modal.component';
import { SourceRolesSelectComponent } from './components/v2/source-roles-select/source-roles-select.component';

// Module
// @dynamic
@NgModule({
  imports: [
    CommonModule,
    NgxChartsModule,
    MatDialogModule,
    AccessRequestsModule,
    AdlModule,
    AuthModule,
    CategoriesModule,
    DashboardsModule,
    DatalakeModule,
    PermissionsModule,
    FormsModule,
    IconsModule,
    NavigatorModule,
    SharedModule,
    SourcesModule,
    ReactiveFormsModule,
    PortalsModule,
    RouterModule,
    InfiniteScrollModule,
    ImpersonateModule,
    NgSelectModule,
    UsersModule,
    NgxDatatableModule,
    AthenaModule,
    AmundsenModule,
    WordingModule,
    NotificationsModule,
  ],
  declarations: [
    KpisChartsComponent,
    ScopesTreeItemComponent,
    SearchFiltersComponent,
    SourceSearchComponent,
    SourceCardComponent,
    SourceContactBoxComponent,
    SourceMetadataModalComponent,
    SourceModalComponent,
    SourceDataAccessComponent,
    SourceContentComponent,
    SourceBannerComponent,
    CatalogSwitchComponent,
    CatalogUserProfileComponent,
    CatalogHeaderComponent,
    CatalogSearchbarComponent,
    CatalogStickySearchbarComponent,
    CatalogRequestAccessButtonComponent,
    CatalogDataAssetContactsComponent,
    CatalogContactComponent,
    CatalogModeSwitchComponent,
    CatalogRequestAccessModalComponent,
    CatalogAmundsenComponent,
    CatalogRecommendationModalComponent,
    CatalogAdvancedFiltersModalComponent,
    CatalogSnowflakePreviewModalComponent,
    CatalogSourceLabelComponent,
    CatalogAmundsenDataTableComponent,
    SourcePermissionModalComponent,
    SourceRolesSelectComponent,
  ],
  providers: [
    SearchService,
    SourceModalsService,
    CatalogV2SearchService,
    PreviewV2Guard,
    CatalogV2NavigationService,
    { provide: MAT_DIALOG_DATA, useValue: {} },
  ],
  exports: [
    KpisChartsComponent,
    SourceCardComponent,
    SourceContactBoxComponent,
    SourceContentComponent,
    SourceBannerComponent,
    SourceSearchComponent,
    CatalogSwitchComponent,
    CatalogHeaderComponent,
    CatalogUserProfileComponent,
    CatalogSearchbarComponent,
    CatalogStickySearchbarComponent,
    CatalogRequestAccessButtonComponent,
    CatalogDataAssetContactsComponent,
    CatalogContactComponent,
    CatalogAmundsenComponent,
    SourcePermissionModalComponent,
    SourceRolesSelectComponent,
  ],
})
export class DataCatalogCommonModule {}
