import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

interface IPageEvent {
  page: number;
}

interface IPage {
  number: number;
  text: string;
}

@Component({
  selector: 'dpg-custom-pager',
  templateUrl: './custom-pager.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomPagerComponent {
  private readonly _count$ = new BehaviorSubject<number>(0);
  private readonly _currentPageNumber$ = new BehaviorSubject<number>(1);
  private readonly _size$ = new BehaviorSubject<number>(0);

  @Input()
  set size(val: number) {
    this._size$.next(val);
  }

  @Input()
  set count(val: number) {
    this._count$.next(val);
  }

  @Input()
  set page(val: number) {
    this._currentPageNumber$.next(val);
  }

  @Output() pageChange: EventEmitter<IPageEvent> = new EventEmitter();

  totalPages$ = combineLatest([this._count$, this._size$]).pipe(
    map(([count, size]) => {
      const totalPageCount = size < 1 ? 1 : Math.ceil(count / size);

      return Math.max(totalPageCount || 0, 1);
    }),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  currentPageNumber$ = this._currentPageNumber$.asObservable();

  count$ = this._count$.asObservable();

  size$ = this._size$.asObservable();

  canNext$ = combineLatest([this._currentPageNumber$, this.totalPages$]).pipe(
    map(([page, totalPages]) => page < totalPages),
  );

  canPrevious$ = this._currentPageNumber$.pipe(map((page) => page > 1));

  pages$ = combineLatest([this._currentPageNumber$, this.totalPages$]).pipe(
    map(([currentPageNumber, totalPages]) => {
      return this.calcPages(currentPageNumber, totalPages);
    }),
  );

  prevPage(currentPageNumber: number, totalPages: number): void {
    this.selectPage(currentPageNumber, currentPageNumber - 1, totalPages);
  }

  nextPage(currentPageNumber: number, totalPagesCount: number): void {
    this.selectPage(currentPageNumber, currentPageNumber + 1, totalPagesCount);
  }

  selectPage(currentPageNumber: number, newPageNumber: number, totalPages: number): void {
    if (newPageNumber > 0 && newPageNumber <= totalPages && newPageNumber !== currentPageNumber) {
      this.pageChange.emit({
        page: newPageNumber,
      });
    }
  }

  calcPages(page: number = 1, totalPages: number): IPage[] {
    const pages = [];
    let startPage = 1;
    let endPage = totalPages;
    const maxSize = 5;
    const isMaxSized = maxSize < totalPages;

    if (isMaxSized) {
      startPage = page - Math.floor(maxSize / 2);
      endPage = page + Math.floor(maxSize / 2);

      if (startPage < 1) {
        startPage = 1;
        endPage = Math.min(startPage + maxSize - 1, totalPages);
      } else if (endPage > totalPages) {
        startPage = Math.max(totalPages - maxSize + 1, 1);
        endPage = totalPages;
      }
    }

    for (let num = startPage; num <= endPage; num++) {
      pages.push({
        number: num,
        text: num.toString(),
      });
    }

    return pages;
  }
}
