<adl-modal
  class="adl-dialog-modal"
  headerBackgroundColor="blue-dark"
  backgroundColor="light"
  [isAlignedToCross]="true"
  [roundCorner]="false"
  closeButtonColor="white"
  textAlignment="left"
>
  <div modal-header class="px-[54px] py-[20px]">
    <div class="flex flex-row">
      <img alt="datalake logo" src="assets/dpg/imgs/icons/datalake-logo.svg" class="w-[27px] h-[37px] mr-[20px]"/>
      <adl-text size="20" bold="true" color="white">{{ fileName }}</adl-text>
    </div>
  </div>

  <div modal-body class="pt-[17px] pb-[23px] px-[54px] w-full my-0">
    <adl-loaded-item [loading]="isLoading">
      <div class="grid grid-row-[auto_1fr_auto] gap-[13px]">

        <ng-container *ngIf="!isLoading">
          <h3 *ngIf="isFileContentTypeIncorrect" class="datalake-edit--info-msg">
            This following file content type is not allowed : "{{ fileContentType }}"
          </h3>

          <ng-container *ngIf="!isFileContentTypeIncorrect">
            <div class="flex flex-row justify-end">
              <adl-button *ngIf="this.isDownloadButtonShown" type="primary-dark" class="header-button mr-4" (click)="downloadFile()">
                <img src="assets/dpg/imgs/icons/datalake-download-file.svg" alt="green check" class="flex" />
              </adl-button>

              <a [href]="openInNewTabUrl" target="_blank">
                <adl-button type="primary-dark" class="header-button">
                  <img src="assets/dpg/imgs/icons/datalake-open-in-new-tab.svg" alt="green check" class="flex" />
                </adl-button>
              </a>
            </div>

            <h3 *ngIf="isFileTooBig" class="datalake-edit--info-msg">
              File too big (max size for {{ fileExtension.toUpperCase() }} file is {{ maxFileSize }} MB)
            </h3>

            <ng-container *ngIf="!isFileTooBig">
              <h3 *ngIf="hasErrorInParsing" class="datalake-edit--info-msg">
                Unable to display file (wrong file encoding or format)
              </h3>

              <ng-container *ngIf="!hasErrorInParsing">
                <ngx-json-viewer
                  *ngIf="isJsonFile"
                  [json]="jsonDatasets"
                  [expanded]="false"
                ></ngx-json-viewer>

                <ng-container *ngIf="!isJsonFile">
                  <div class="w-full flex justify-between">
                    <adl-select-v2
                      *ngIf="hasSheet; else noSheets"
                      class="w-1/2"
                      [value]="sheetNames[0]"
                      [items]="sheetNames"
                      (change)="onTabChange($event)"
                    ></adl-select-v2>

                    <ng-template #noSheets>
                      <div></div>
                    </ng-template>
                  </div>

                  <ng-container *ngIf="!hasSheet">
                    <dpg-csv-datatable
                      [rows]="currentRowsForPage"
                      [columns]="datasets[0].columns"
                      [rowsPerPage]="paginationNbPerPageMax"
                      [totalRows]="datasets[0].rows.length"
                      [currentPageNumber]="currentPageNumber"
                      [editable]="false"
                      (pageChange)="setPaginationPage($event, 0)"
                    ></dpg-csv-datatable>
                  </ng-container>

                  <ng-container *ngIf="hasSheet">
                    <ng-container [ngSwitch]="selectedIndex">
                      <ng-container *ngFor="let sheetName of sheetNames; let index = index">
                        <dpg-csv-datatable
                          *ngSwitchCase="index"
                          [rows]="currentRowsForPage"
                          [columns]="datasets[index].columns"
                          [rowsPerPage]="paginationNbPerPageMax"
                          [totalRows]="datasets[index].rows.length"
                          [currentPageNumber]="currentPageNumber"
                          [editable]="false"
                          (pageChange)="setPaginationPage($event, index)"
                        ></dpg-csv-datatable>
                      </ng-container>
                    </ng-container>
                  </ng-container>

                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
    </adl-loaded-item>
  </div>
</adl-modal>
