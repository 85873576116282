import type { OnDestroy, OnInit } from '@angular/core';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { interval, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { NotificationsService } from '../../services/notifications.service';

import type { IAdminNotification } from '../../entities/notifications';

// Component
@Component({
  selector: 'dpg-section-notifier',
  templateUrl: './section-notifier.component.html',
  styleUrls: ['./section-notifier.component.scss'],
})
export class SectionNotifierComponent implements OnInit, OnDestroy {
  @Input() notificationsNamesToWatch: string[] = [];
  @Input() isHovering = false;
  @Input() hasToDisplayTotalCount = false;
  @Input() backgroundColor = 'bg-red';
  @Input() numberColor = 'white';
  @Output() onTotalNotificationChange = new EventEmitter<number>();

  TOTAL_COUNT_TO_DISPLAY_LIMIT = 100;
  totalCount = 0;

  private readonly _notificationsCounter = new Map<string, number>();

  private readonly _destroyed$ = new Subject<void>();

  constructor(private readonly _notificationsService: NotificationsService) {}

  get hasNotification(): boolean {
    return this.totalCount > 0;
  }

  get totalCountToDisplay(): string {
    return this.totalCount > 0
      ? this.totalCount >= this.TOTAL_COUNT_TO_DISPLAY_LIMIT
        ? '99+'
        : this.totalCount.toString()
      : '';
  }

  ngOnInit(): void {
    // here, notificationsNamesToWatch should be populated since it should be initialized in a static way in the parent component
    this._initNotificationsCounter();

    // subscribing to notifications
    interval(3000).subscribe(() => {
      this.notificationsNamesToWatch.forEach((notificationName) => {
        this._notificationsService
          .getCurrentNotification(notificationName)
          .pipe(takeUntil(this._destroyed$))
          .subscribe((notification) => {
            this._updateNotifications(notification);
            this._destroyed$.next();
          });
      });
    });
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
  }

  private _initNotificationsCounter(): void {
    this.notificationsNamesToWatch.forEach((notificationName) => {
      if (!this._notificationsCounter.has(notificationName)) {
        this._notificationsCounter.set(notificationName, 0);
      }
    });
  }

  private _updateNotifications(notification: IAdminNotification): void {
    this._notificationsCounter.set(notification.name, notification.total);
    this.totalCount = Array.from(this._notificationsCounter.values()).reduce(
      (totalCount, currentNotificationCount) => totalCount + currentNotificationCount,
      0,
    );

    if (this.hasNotification) {
      this.onTotalNotificationChange.emit(this.totalCount);
    }
  }
}
