// Enums
export enum AuthLocalStorageKeys {
  ID_TOKEN = 'DP_AUTH_ID_TOKEN',
  ACCESS_TOKEN = 'DP_AUTH_ACCESS_TOKEN',
  AUTH_PROVIDER = 'DP_AUTH_PROVIDER',
  REFRESH_TOKEN = 'DP_AUTH_REFRESH_TOKEN',
  AUTH_ACCOUNT = 'DP_AUTH_ACCOUNT',
  DEVOPS_TOKEN = 'DP_DEVOPS_TOKEN',
  DATABRICKS_TOKEN = 'DP_DATABRICKS_TOKEN',
}

export enum AuthSessionStorageKeys {
  RETURN_URL = 'DP_AUTH_RETURN_URL',
  AUTH_STATE = 'DP_AUTH_STATE',
}

export enum AuthCookiesKeys {
  REDIRECT_EDGE = 'DP_EDGE_AUTH_REDIRECT',
}
