import { Entity } from '@decahedron/entity';

class GaugeInfo extends Entity {
  textValue: string = null;
}

class GaugeValue extends Entity {
  max: number = null;
  min: number = null;
}

class Serie extends Entity {
  name: string = null;
  value: number = null;
}

class LineEntries extends Entity {
  name: string = null;
  series: Serie[];
}

export class KpiInfo extends Entity {
  text: string = null;
  value: string = null;
  textValue: string = null;
  units: string = null;
  lineEntries: LineEntries;
  gaugeInfo: GaugeInfo[];
  gaugeValue: GaugeValue;
}
