import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Subject } from 'rxjs';
import { mergeAll, takeUntil, tap } from 'rxjs/operators';
import { Logger } from '@dataportal/front-shared';

import { DatalakeStoreService } from './datalake-store.service';
import { ExplorerService } from './explorer.service';

import type {
  IBucket,
  IDatalakeCurrentDirectory,
  IDatalakeFoldersTree,
  IDatalakeFoldersTreeElement,
} from '../entities/datalake';
import { normalizedFullPath } from '../entities/datalake';

@Injectable()
export class ExplorerTreeService {
  private _isExplorerTreeActivated = true;
  private readonly _datalakeFoldersTree$: BehaviorSubject<IDatalakeFoldersTree> =
    new BehaviorSubject<IDatalakeFoldersTree>([]);
  private readonly _isLoadingTreeHierarchy$ = new BehaviorSubject<boolean>(false);
  private readonly _abort$ = new Subject<void>();
  private readonly _abortExpand$ = new Map<string, Subject<void>>();

  isLoadingTreeHierarchy$ = this._isLoadingTreeHierarchy$.asObservable();
  datalakeFoldersTree$ = this._datalakeFoldersTree$.asObservable();

  private static _compareDatalakeFolders(f1: IDatalakeFoldersTreeElement, f2: IDatalakeFoldersTreeElement): number {
    return f1.name.localeCompare(f2.name);
  }

  static sortDatalakeFolders(folders: IDatalakeFoldersTreeElement[]): IDatalakeFoldersTreeElement[] {
    return folders.sort(ExplorerTreeService._compareDatalakeFolders);
  }

  private _previousBucket: IBucket;

  constructor(
    private readonly _explorerService: ExplorerService,
    private readonly _datalakeStoreService: DatalakeStoreService,
    private readonly _logger: Logger,
  ) {
    this._explorerService.currentBucket$.subscribe((currentBucket) => {
      if (currentBucket?.name !== this._previousBucket?.name) {
        this._logger.info('[ExplorerService] Current bucket changed', currentBucket);
        this._previousBucket = currentBucket;
        this._logger.info('[ExplorerTreeService] Current bucket changed, tree must be rebuilt');
        this._abort$.next();
      }
    });
    this._explorerService.current$.subscribe({
      next: () => {
        this._logger.info('[ExplorerTreeService] Directory changed tree must be rebuilt');
        this._abort$.next();
      },
    });
    this._abort$.subscribe({
      next: () => {
        this._logger.info('[ExplorerTreeService] Directory changed tree must be rebuilt');
        this._clearFoldersTree();
      },
    });
  }

  private _clearFoldersTree(): void {
    this._logger.info('[ExplorerTreeService] Reset tree hierarchy service state');
    this._datalakeFoldersTree$.next([]);
    this._isLoadingTreeHierarchy$.next(false);
    this._abortExpand$.forEach((s) => s.next());
    this._abortExpand$.clear();
  }

  renderTree(): void {
    const current = this._explorerService.current;

    if (this._isExplorerTreeActivated && current) {
      this._logger.info('[ExplorerTreeService] Rendering folders tree for current directory', current);
      this._buildTree(current);
    }
  }

  toggleExplorerTree(show: boolean): void {
    this._logger.info('[ExplorerTreeService] Toggle folders tree', show);
    this._isExplorerTreeActivated = show;
    this.renderTree();
  }

  collapseFolder(folder: IDatalakeFoldersTreeElement): void {
    const folderKey = normalizedFullPath(folder);
    this._logger.debug('[ExplorerTreeService] Collapse requested. Abort expand subject key', folderKey);
    this._logger.debug('[ExplorerTreeService] Expand in progress', folderKey, this._abortExpand$.has(folderKey));
    const abortExpandInProgress$ = this._abortExpand$.get(folderKey);

    if (abortExpandInProgress$) {
      this._logger.info('[ExplorerTreeService] Expand folder still in progress, aborting...', folder);
      abortExpandInProgress$.next();
    }

    folder.childrenLoaded = false;
    folder.childrenLoading = false;
    folder.children = [];
  }

  expandFolder(folder: IDatalakeFoldersTreeElement): void {
    const current = this._explorerService.current;
    const folderKey = normalizedFullPath(folder);
    const options = {
      provider: folder.provider,
      tenant: folder.tenant,
      path: folderKey,
      byUser: current.byUser,
    };
    const folderExpanded$ = new Subject<void>();
    const abortExpand$ = new Subject<void>();
    this._logger.debug('[ExplorerTreeService] Registering abort expand subject key', folderKey);
    this._abortExpand$.set(folderKey, abortExpand$);
    folder.childrenLoading = true;
    const abortCondition$ = from([
      this._abort$.pipe(
        tap(() =>
          this._logger.debug('[ExplorerTreeService] Current directory changed, abort expanding folder', folder),
        ),
      ),
      folderExpanded$.pipe(
        tap(() => this._logger.debug('[ExplorerTreeService] Folder expanded. Unsubscribe receiving objects', folder)),
      ),
      abortExpand$.pipe(
        tap(() => this._logger.debug('[ExplorerTreeService] Folder collapse, abort expanding folder', folder)),
      ),
    ]).pipe(mergeAll());
    const { abort$, objects$ } = this._datalakeStoreService.listAll(current.bucket.name, options);
    objects$
      .pipe(
        takeUntil(
          abortCondition$.pipe(
            tap(() => {
              this._logger.debug('[ExplorerTreeService] Abort expanding folder', folder);
              abort$.next();
            }),
          ),
        ),
      )
      .subscribe({
        next: (objects) => {
          this._logger.debug(
            '[ExplorerTreeService]',
            'Page fetched for path',
            options.path,
            objects,
            `(level ${folder.level + 1})`,
          );

          if (!objects.isThereDataRemaining) {
            this._abortExpand$.delete(folderKey);
            folder.children = objects.objects
              .filter((object) => object.type === 'folder')
              .map((object) => ({
                ...object,
                level: folder.level + 1,
                children: [],
                parent: folder,
                childrenLoaded: false,
                childrenLoading: false,
              }));
            folder.childrenLoaded = true;
            folder.childrenLoading = false;
          }
        },
      });
  }

  /**
   * Build the tree hierarchy
   * Uses a recursive and reactive algorithm
   * @param current
   * @private
   */
  private _buildTree(current: IDatalakeCurrentDirectory): void {
    this._isLoadingTreeHierarchy$.next(true);
    const bucket = current.bucket.name;
    const path = current.path;
    const segments = path?.split('/') || [];
    this._logger.debug('[ExplorerTreeService]', 'Building folders tree for', { path, bucket, segments });
    const tree: IDatalakeFoldersTree = [];
    this._buildTreeLevel(current, tree, segments);
  }

  /**
   * Recursively build level for the tree hierarchy
   * @param current - the current directory focused by ExplorerService
   * @param tree - the tree structure being built passed in each recursion step
   * @param completeSegments - the segments of the current path
   * @param parent - the current parent for which we are building children
   * @param recursionDepth - the recursion depth
   * @private
   */
  private _buildTreeLevel(
    current: IDatalakeCurrentDirectory,
    tree: IDatalakeFoldersTree,
    completeSegments: string[],
    parent?: IDatalakeFoldersTreeElement,
    recursionDepth = 0,
  ): void {
    this._logger.debug('[ExplorerTreeService]', 'Building folders tree for level', recursionDepth);

    if (parent) {
      parent.childrenLoading = true;
    }

    if (completeSegments && completeSegments[0] !== '') {
      completeSegments.unshift('');
    }

    const path = recursionDepth ? completeSegments.slice(0, recursionDepth + 1).join('/') : '';
    const isAtRootLevel = recursionDepth === 0;
    const options = {
      provider: isAtRootLevel ? undefined : current.provider,
      tenant: isAtRootLevel ? undefined : current.tenant,
      path,
      byUser: current.byUser,
    };
    this._logger.debug(
      '[ExplorerTreeService]',
      'Fetching objects for path',
      current.bucket.name,
      options,
      `(level ${recursionDepth})`,
    );
    const levelBuilt$ = new Subject<void>();
    const abortCondition$ = from([
      this._abort$.pipe(
        tap(() => this._logger.debug('[ExplorerTreeService] Current directory changed, abort building tree')),
      ),
      levelBuilt$.pipe(
        tap(() =>
          this._logger.debug('[ExplorerTreeService] Tree level', recursionDepth, 'built. Stop receiving objects'),
        ),
      ),
    ]).pipe(mergeAll());

    const { abort$, objects$ } = this._datalakeStoreService.listAll(current.bucket.name, options);
    objects$
      .pipe(
        takeUntil(
          abortCondition$.pipe(
            tap(() => {
              abort$?.next();
              this._logger.debug('[ExplorerTreeService] Abort building tree');
            }),
          ),
        ),
      )
      .subscribe({
        next: (objects) => {
          this._logger.debug(
            '[ExplorerTreeService]',
            'Page fetched for path',
            path,
            objects,
            `(level ${recursionDepth})`,
          );

          if (!objects.isThereDataRemaining) {
            const levelFolders = objects.objects
              .filter((object) => object.type === 'folder')
              .map((object) => ({
                ...object,
                level: recursionDepth,
                children: [],
                parent: parent || null,
                childrenLoaded: false,
                childrenLoading: false,
              }));

            if (!parent && recursionDepth < completeSegments.length) {
              tree = levelFolders;
              this._isLoadingTreeHierarchy$.next(false);
              this._datalakeFoldersTree$.next(tree);
            } else if (parent) {
              parent.children = levelFolders;
              parent.childrenLoaded = true;
              parent.childrenLoading = false;
            }

            levelBuilt$.next();

            if (recursionDepth >= completeSegments.length) {
              this._logger.debug('[ExplorerTreeService]', 'All level built', tree);
            } else {
              const nextParent = levelFolders.find((o) => o.name === completeSegments[recursionDepth + 1]);
              this._logger.debug(
                '[ExplorerTreeService]',
                'All page fetched, building next level',
                nextParent,
                `(level ${recursionDepth})`,
              );
              this._buildTreeLevel(current, tree, completeSegments, nextParent, recursionDepth + 1);
            }
          }
        },
      });
  }
}
