import type { OnChanges, SimpleChanges } from '@angular/core';
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Navigator } from '@dataportal/navigator';

import { CurrentDashboardService } from '../../services/current-dashboard.service';
import { DashboardsService } from '../../services/dashboards.service';

import { Dashboard } from '../../entities/dashboard.model';

import { Source } from '../../../sources/entities/source';

interface IDashboardTree {
  nested: { [folderName: string]: Dashboard[] };
  other: Dashboard[];
}

@Component({
  selector: 'dpg-dashboard-sidebar',
  templateUrl: './dashboard-sidebar.component.html',
  styleUrls: ['./dashboard-sidebar.component.scss'],
})
export class DashboardSidebarComponent implements OnChanges {
  @Input() source: Source;
  @Input() currentDashboard: Dashboard;

  currentSource: Source;

  dashboardTree: IDashboardTree = {
    nested: {},
    other: [],
  };

  sidebarCollapse = false;

  // handle category collapse, true = collapsed
  categoryCollapseStatus: Map<string, boolean> = new Map<string, boolean>();

  object = Object;

  constructor(
    private readonly _router: Router,
    private readonly _currentDashboardService: CurrentDashboardService,
    private readonly _dashboardsService: DashboardsService,
    private readonly _navigator: Navigator,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.source && this.source) {
      this.currentSource = this.source;
      this.dashboardTree = { nested: {}, other: [] };
      this.handleDashboardTree(this.source);
    }
  }

  handleCollapse(categoryName: string) {
    this.categoryCollapseStatus.set(categoryName, !this.categoryCollapseStatus.get(categoryName));
  }

  buildDashboardTree(source: Source) {
    if (source.powerbi) {
      for (const dashboard of source.powerbi) {
        if (dashboard.isHidden) continue;

        if (dashboard.parentFolder) {
          if (this.dashboardTree.nested[dashboard.parentFolder.name]) {
            this.dashboardTree.nested[dashboard.parentFolder.name].push(dashboard);
          } else {
            this.dashboardTree.nested[dashboard.parentFolder.name] = [dashboard];
          }

          if (this.currentDashboard?.parentFolder?.name !== dashboard.parentFolder.name) {
            this.categoryCollapseStatus.set(dashboard.parentFolder.name, true);
          } else {
            this.categoryCollapseStatus.set(dashboard.parentFolder.name, false);
          }
        } else {
          this.dashboardTree.other.push(dashboard);
        }
      }
    }
  }

  handleDashboardTree(source: Source) {
    if (source.powerbi) {
      this.buildDashboardTree(this.source);
    }
  }

  openDashboardLocally(visibleRelatedDashboard: Dashboard): void {
    const urlToOpen = visibleRelatedDashboard.buildUrl(this.source.id, visibleRelatedDashboard.name, this._navigator);

    if (visibleRelatedDashboard.isExternal) {
      this._dashboardsService.emitExternalLinkPageViewEvent(this.source, 'dashboard');
      window.open(urlToOpen, '_blank');
    } else {
      this._router.navigate([urlToOpen]).then();
      this._currentDashboardService.changeCurrentDashboard(this.source.id, visibleRelatedDashboard.name);
    }
  }
}
