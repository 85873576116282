import { Inject, Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { minLength } from '@dataportal/adl';
import { EnvironmentService, IS_ACCOR, IS_JCD } from '@dataportal/front-environment';
import { Logger } from '@dataportal/front-shared';
import type { DashboardType, DatalakeProvider } from '@dataportal/types';
import { USER_PREFERENCES, UserPreferencesService } from '@dataportal/users';
import type { AbstractControl, FormArray, FormControl, FormGroup } from '@ngneat/reactive-forms';
import { FormBuilder } from '@ngneat/reactive-forms';
import type { ValidationErrors } from '@ngneat/reactive-forms/lib/types';
import { cloneDeep } from 'lodash';
import type { Editor } from 'tinymce';
import { v4 as uuid } from 'uuid';

import { DataManagementFormService } from './data-management-form.service';

import type { Dashboard } from '../../../../dashboards/entities/dashboard.model';
import type { IDashboardFolder } from '../../../../dashboards/entities/dashboard-folder.model';
import type { IDashboardStepSettings } from '../../../../dashboards/entities/dashboard-form';
import type { Source } from '../../../entities/source';
import type {
  DatalakeState,
  IDataContentFormData,
  IDatalakeFormData,
  IMssqlFormData,
  ISnowflakeFormData,
} from '../../../entities/source-form';
import { invalidCharactersValidator } from '../../validators';

export const POWERBI_BASE_URL = 'https://app.powerbi.com';

export type DataContentTab =
  | 'Dashboard'
  | 'Packaged Insights'
  | 'Datalake'
  | 'Snowflake'
  | 'Data as a Product'
  | 'SQL DB';

export function untrimmedValidator() {
  const regex = new RegExp(/^[\S](?:.*[\S])?$/);

  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value && control.value.length && !regex.test(control.value)) {
      return {
        noSpaceAround: true,
      };
    }

    return null;
  };
}

@Injectable({
  providedIn: 'root',
})
export class DataContentFormService {
  relatedSource: Source;

  private _dataContentForm: FormGroup<IDataContentFormData>;
  private readonly _formReady$ = new BehaviorSubject<boolean>(false);
  private _controllersEnablementSubscriptions = Subscription.EMPTY;

  formReady$ = this._formReady$.asObservable();

  private readonly _currentTab$ = new BehaviorSubject<DataContentTab>('Dashboard');
  currentTab$ = this._currentTab$.asObservable();

  private readonly _initialProvider$ = new BehaviorSubject<DatalakeProvider>(null);
  private readonly _sourceDashboardFolders$ = new BehaviorSubject<IDashboardFolder[]>([]);

  sourceDashboardFolders$ = this._sourceDashboardFolders$.asObservable();

  private _selectedDashboard: FormGroup<IDashboardStepSettings>;
  private readonly _enteredDashboardEdition$ = new BehaviorSubject<boolean>(false);
  enteredDashboardEdition$ = this._enteredDashboardEdition$.asObservable();

  private _isNewDashboard: boolean;

  private readonly editorSubject = new BehaviorSubject<Editor | null>(null);

  constructor(
    private readonly _logger: Logger,
    private readonly _userPreferencesService: UserPreferencesService,
    private readonly _formBuilder: FormBuilder,
    private readonly _dataManagementFormService: DataManagementFormService,
    private readonly _environmentService: EnvironmentService,
    @Inject(IS_ACCOR) private readonly _isAccor: boolean,
    @Inject(IS_JCD) private readonly _isJCD: boolean,
  ) {}

  get currentDashboardFolders() {
    return this._sourceDashboardFolders$.getValue();
  }

  handleEditorInit(e) {
    this.editorSubject.next(e.editor);
  }

  getForm() {
    return this._dataContentForm;
  }

  setDashboardFolders(dashboardsFolders: IDashboardFolder[]) {
    this._sourceDashboardFolders$.next(dashboardsFolders);
  }

  setIsNewDashboard(isNewDashboard: boolean): void {
    this._isNewDashboard = isNewDashboard;
  }

  get isNewDashboard(): boolean {
    return this._isNewDashboard;
  }

  get selectedDashboard() {
    return this._selectedDashboard;
  }

  setEnteredDashboardEdition(form: FormGroup<IDashboardStepSettings>): void {
    if (form) {
      this._enteredDashboardEdition$.next(true);
      this._selectedDashboard = form;
    } else {
      this._enteredDashboardEdition$.next(false);
      this._selectedDashboard = null;
    }
  }

  get datalakeForm() {
    return this._dataContentForm?.get('datalake') as FormGroup<IDatalakeFormData>;
  }

  get dashboardsForm(): FormArray<IDashboardStepSettings> {
    return this._dataContentForm?.get('dashboards') as FormArray<IDashboardStepSettings>;
  }

  get snowflakeForm(): FormGroup<ISnowflakeFormData> {
    return this._dataContentForm?.get('snowflake') as FormGroup<ISnowflakeFormData>;
  }

  get mssqlForm(): FormGroup<IMssqlFormData> {
    return this._dataContentForm?.get('mssql') as FormGroup<IMssqlFormData>;
  }

  reinitializeInitialProvider() {
    this._initialProvider$.next(null);
  }

  setNewProvider(datalakeProvider: DatalakeProvider) {
    this._initialProvider$.next(datalakeProvider);
  }

  get initialProvider(): DatalakeProvider {
    return this._initialProvider$.getValue();
  }

  get currentTab(): DataContentTab {
    return this._currentTab$.getValue();
  }

  setNewTab(tab: DataContentTab) {
    this._currentTab$.next(tab);
  }

  deselectDashboard(): void {
    this._selectedDashboard = null;

    if (this._isNewDashboard) {
      this.dashboardsForm.removeAt(this.dashboardsForm.length - 1);
    }

    this._isNewDashboard = false;
    this.setEnteredDashboardEdition(this._selectedDashboard);
  }

  openDashboard(id: string): FormGroup<IDashboardStepSettings> {
    this._isNewDashboard = false;

    return this.selectDashboard(id);
  }

  getDashboardDataById(id: string): IDashboardStepSettings {
    return this.dashboardsForm.controls.find((dashboardForm) => dashboardForm.value?.id === id)
      ?.value as IDashboardStepSettings;
  }

  getDashboardFormById(id: string): FormGroup<IDashboardStepSettings> {
    return (id
      ? this.dashboardsForm?.controls.find((dashboardForm) => dashboardForm.value?.id === id)
      : this.dashboardsForm.at(this.dashboardsForm.length - 1)) as unknown as FormGroup;
  }

  getDashboardIdxById(id: string): number {
    const idsFound = Array.from(this.dashboardsForm.controls.entries())
      .filter((entry) => entry[1].value.id === id)
      .map((entry) => entry[0]);

    return idsFound?.length ? idsFound[0] : null;
  }

  removeDashboard(id: string): void {
    this._logger.info('[DataContentForm] Removing dashboard by its id', id);
    this.removeDashboardByIndex(this.getDashboardIdxById(id));
  }

  removeDashboardByIndex(idx: number): void {
    this._logger.info('[DataContentForm] Removing dashboard by its index', idx);
    this.dashboardsForm.removeAt(idx);
    this.dashboardsForm.markAsDirty();
  }

  selectDashboard(id: string): FormGroup<IDashboardStepSettings> {
    const dashboardFormCopy = cloneDeep(this.getDashboardFormById(id));
    this.setEnteredDashboardEdition(dashboardFormCopy);

    return this.selectedDashboard;
  }

  addDashboard(): void {
    this.dashboardsForm.push(this.initDashboard());
    this.selectDashboard(this.dashboardsForm.at(this.dashboardsForm.length - 1).value.id);
    this._isNewDashboard = true;
  }

  addDashboardFolder(dashboardFolderName: string): IDashboardFolder {
    const dashboardFolders = this._sourceDashboardFolders$.getValue()?.length
      ? this._sourceDashboardFolders$.getValue()
      : [];
    const dashboardFolderToAdd = {
      id: uuid(),
      name: dashboardFolderName,
      parent: null,
      dashboardFolderChildren: [],
    } as IDashboardFolder;
    dashboardFolders.push(dashboardFolderToAdd);
    this._sourceDashboardFolders$.next(dashboardFolders);

    return dashboardFolderToAdd;
  }

  reorderDashboardsLevel(dashboards: IDashboardStepSettings[]) {
    const currentDashboardsWithoutThoseReordered = this.dashboardsForm
      .getRawValue()
      .filter((dashboardFiltered) =>
        dashboards.every((dashboardReordered) => dashboardReordered.id !== dashboardFiltered.id),
      );
    currentDashboardsWithoutThoseReordered.push(...dashboards);
    const mappingControlDashboard = new Map();
    this.dashboardsForm.controls.forEach((control) => {
      mappingControlDashboard.set(control.value.name, control);
    });
    this.dashboardsForm.clear();
    currentDashboardsWithoutThoseReordered.forEach((dashboardReordered, index) => {
      this.dashboardsForm.insert(index, mappingControlDashboard.get(dashboardReordered.name));
    });
    this.dashboardsForm.markAsDirty();
  }

  reorderDashboardsFolderLevel(dashboardFolders: IDashboardFolder[]) {
    const currentDashboardsFoldersWithoutThoseReordered = this.currentDashboardFolders.filter((folderFiltered) =>
      dashboardFolders.every((folderReordered) => folderReordered.id !== folderFiltered.id),
    );
    currentDashboardsFoldersWithoutThoseReordered.push(...dashboardFolders);
    this.setDashboardFolders(currentDashboardsFoldersWithoutThoseReordered);
    this.dashboardsForm.markAsDirty();
  }

  canEditDashboardFolderName(dashboardFolderId: string): boolean {
    const dashboardFolders = this._sourceDashboardFolders$.getValue()?.length
      ? this._sourceDashboardFolders$.getValue()
      : [];

    return dashboardFolders?.some((dashboardFolder) => dashboardFolder.id === dashboardFolderId);
  }

  init(source: Source): void {
    this.relatedSource = source;

    if (this.relatedSource) {
      this._initialProvider$.next(this.relatedSource.getProvider());
    } else {
      this._initialProvider$.next('none');
    }

    this._addExistingDashboardFolders(
      this.relatedSource?.dashboardFolders?.length ? this.relatedSource.dashboardFolders : [],
    );

    this._dataContentForm = this._formBuilder.group({
      dashboards: this._getInitiatedDashboardForm(),
      datalake: this._getInitiatedDatalakeForm(),
      snowflake: this._getInitiatedSnowflakeForm(),
      mssql: this._getInitiatedMssqlForm(),
    });

    this._formReady$.next(true);
  }

  private _getInitiatedDashboardForm(): FormArray<IDashboardStepSettings> {
    return this._formBuilder.array(
      this.relatedSource?.powerbi ? this.relatedSource.powerbi.map(this.initDashboard.bind(this)) : [],
    );
  }

  private _dashboardNameNotTakenValidator() {
    return (control: AbstractControl): ValidationErrors | null => {
      const existingDashboardsNames =
        this.dashboardsForm?.controls?.map((controlItem) => controlItem.value.name.toLowerCase()) ?? [];

      if (
        existingDashboardsNames.length &&
        ((this.isNewDashboard &&
          existingDashboardsNames.slice(0, existingDashboardsNames.length - 1).includes(control.value.toLowerCase())) ||
          (!this.isNewDashboard && new Set(existingDashboardsNames).size !== existingDashboardsNames.length))
      ) {
        return {
          dashboardNameTaken: true,
        };
      }

      return null;
    };
  }

  initDashboard(dashboard?: Dashboard): FormGroup<IDashboardStepSettings> {
    this._controllersEnablementSubscriptions.unsubscribe();

    const toAllowedGroup = (group: {
      group_id: string;
      group_name: string;
    }): FormGroup<{ id: string; name: string }> => {
      return this._formBuilder.group<{ id: string; name: string }>({
        id: group.group_id,
        name: group.group_name,
      });
    };

    const toAllowedGroups = (_dashboard: Dashboard): Array<FormGroup<{ id: string; name: string }>> => {
      return _dashboard?.allowedGroups ? _dashboard.allowedGroups.map((group) => toAllowedGroup(group)) : [];
    };

    const toAllowedGroupsArray = (_dashboard: Dashboard): FormArray<{ id: string; name: string }> => {
      return this._formBuilder.array(toAllowedGroups(_dashboard));
    };

    const dashboardForm = this._formBuilder.group(
      {
        id: [dashboard ? dashboard.id : uuid()],
        name: [
          dashboard ? dashboard.name : '',
          [
            Validators.required,
            invalidCharactersValidator,
            untrimmedValidator(),
            this._dashboardNameNotTakenValidator(),
          ],
        ],
        thumbnail: [dashboard ? dashboard.image : ''],
        type: [(dashboard?.type || (this._isAccor ? 'tableau' : 'powerbi')) as DashboardType, Validators.required],
        isHidden: [dashboard ? dashboard.isHidden : false, Validators.required],
        hasToAddIntoFolder: [dashboard ? !!dashboard.parentFolder : false],
        parentFolder: [dashboard ? dashboard.parentFolder : null],
        url: [dashboard ? dashboard.url : '', Validators.required],
        useNativeRLS: [dashboard ? dashboard.usingUserOwnsData : false],
        hasDynamicRoleRLS: [dashboard ? !!dashboard.dataset || !!dashboard.role : false],
        datasetId: [dashboard ? dashboard.dataset : '', Validators.required],
        roleId: [dashboard ? dashboard.role : '', Validators.required],
        isAAS: [dashboard ? dashboard.isAnalysisServices : false],
        hasComments: [dashboard ? dashboard.hasComments : !this._isAccor],
        cubeURL: [dashboard?.aasCubeUrl ?? '', [Validators.required, Validators.pattern(/^(aas?|asazure):\/\//)]],
        disableLastUpdate: [dashboard ? dashboard.isLastUpdateDisabled : false],
        hideFiltersPane: [dashboard ? dashboard.isFilterPaneHidden : false],
        maintenanceOn: [dashboard ? !!dashboard.maintenanceMessage : false, Validators.required],
        maintenanceMessage: [
          dashboard?.maintenanceMessage ?? '',
          [Validators.required, minLength(this.editorSubject, 30)],
        ],
        hasSupportLink: [dashboard ? !!dashboard.supportLink?.length : false, Validators.required],
        supportLink: [dashboard?.supportLink ?? '', Validators.required],
        restricted: [dashboard ? dashboard.allowedGroups?.length > 0 : false, Validators.required],
        allowedGroups: toAllowedGroupsArray(dashboard),
        contactsToNotifyOnDashboardComments: [dashboard ? dashboard.contactsToNotifyOnDashboardComments : []],
      },
      {
        validators: (group: FormGroup<IDashboardStepSettings>) => {
          const errors: { [key: string]: unknown } = {};
          let hasError = false;

          // FIXME remove as any when new DashboardType is publish
          const value = group.getRawValue() as any;
          const pattern = new RegExp(
            `^${POWERBI_BASE_URL}/groups/([A-Za-z0-9-]+)/(apps/([A-Za-z0-9-]+)/)?(rdlreports|reports|dashboards)/([A-Za-z0-9-]+)`,
          );

          if (value?.type === 'powerbi' && !!value.url && !value.url.match(pattern)) {
            errors.invalidPowerbiURL = { baseUrl: POWERBI_BASE_URL };
          }

          if (value?.type === 'qlik' && !!value.url && !value.url.match(/^https?:\/\//)) {
            errors.invalidQlikURL = { baseUrl: '' };
          }

          if (value?.type === 'data_studio' && !!value.url && !value.url.match(/^https?:\/\//)) {
            errors.invalidDataStudioURL = { baseUrl: '' };
          }

          if (value?.type === 'datorama' && !!value.url && !value.url.match(/^https?:\/\//)) {
            errors.invalidDatoramaURL = { baseUrl: '' };
          }

          if (this._isJCD) {
            if (
              value?.type === 'metabase' &&
              !!value.url &&
              !value.url.match(/^https?:\/\/dmp-jcd.displayce.com\/metabase\/public\//)
            ) {
              hasError = true;
              errors.invalidMetabaseURL = { baseUrl: this._environmentService.options.metabaseBaseUrl };
            }
          }

          if (value?.type === 'reeport' && !!value.url && !value.url.match(/^https?:\/\//)) {
            errors.invalidReeportURL = { baseUrl: '' };
          }

          if (
            (value?.type === 'external_link' || value?.type === 'source_ready_to_explore') &&
            !!value.url &&
            !value.url.match(/^https?:\/\//)
          ) {
            errors.invalidURL = true;
          }

          if (value?.type === 'tableau' && !!value.url && !value.url.match(/^https?:\/\//)) {
            errors.invalidTableauURL = { baseUrl: '' };
          }

          return hasError ? errors : null;
        },
      },
    );

    // handle the initial dashboard maintenance value
    (dashboardForm.get('maintenanceOn') as FormControl).value$
      .pipe(
        tap((value) => {
          if (value) {
            (dashboardForm.get('maintenanceMessage') as FormControl).enable();
          } else {
            (dashboardForm.get('maintenanceMessage') as FormControl).disable();
          }
        }),
        take(1),
      )
      .subscribe();

    this._controllersEnablementSubscriptions = (dashboardForm.get('supportLink') as FormControl).enabledWhile(
      (dashboardForm.get('hasSupportLink') as FormControl).value$,
    );

    this._controllersEnablementSubscriptions.add(
      (dashboardForm.get('cubeURL') as FormControl).enabledWhile((dashboardForm.get('isAAS') as FormControl).value$),
    );

    const hasDynamicRlsControlValue$ = (dashboardForm.get('hasDynamicRoleRLS') as FormControl).value$;
    this._controllersEnablementSubscriptions.add(
      (dashboardForm.get('datasetId') as FormControl).enabledWhile(hasDynamicRlsControlValue$),
    );
    this._controllersEnablementSubscriptions.add(
      (dashboardForm.get('roleId') as FormControl).enabledWhile(hasDynamicRlsControlValue$),
    );

    return dashboardForm;
  }

  private _getInitiatedSnowflakeForm(): FormGroup<ISnowflakeFormData> {
    return this._formBuilder.group({
      snowflakeTableKeys: [this.relatedSource?.snowflakeTableKeys?.length ? this.relatedSource.snowflakeTableKeys : []],
    });
  }

  private _getInitiatedMssqlForm(): FormGroup<IMssqlFormData> {
    return this._formBuilder.group({
      mssqlTableKeys: [this.relatedSource?.mssqlTableKeys?.length ? this.relatedSource.mssqlTableKeys : []],
    });
  }

  private _getInitiatedDatalakeForm(): FormGroup<IDatalakeFormData> {
    const getState = (): DatalakeState => {
      switch (this.relatedSource.type) {
        case 'Project':
          return 'processed';
        case 'Raw':
          return 'raw';
        case 'Business':
          return 'business';
        default:
          return null;
      }
    };

    const shouldDisplayMetadata =
      this._userPreferencesService.getUserPreferenceValue(
        USER_PREFERENCES.DATA_STORAGE_INFO_TOGGLE + '-' + this.relatedSource?.id,
      ) !== 'false';

    const datalakeForm = this._formBuilder.group({
      provider: [this.relatedSource ? this.relatedSource.getProvider() : 'none', Validators.required],
      state: [this.relatedSource ? getState() : 'processed'],
      externalURL: [
        this.relatedSource && this.relatedSource.getProvider() === 'external'
          ? this.relatedSource.externalLinks[0].url
          : '',
        [Validators.required, Validators.pattern(/^https?:\/\//)],
      ],
      paths: [this.relatedSource ? this.relatedSource.datalakePath || [] : []],
    }) as FormGroup<IDatalakeFormData>;

    (datalakeForm.get('externalURL') as FormControl).enabledWhile(
      (datalakeForm.get('provider') as FormControl).value$.pipe(map((provider) => provider === 'external')),
    );

    return datalakeForm;
  }

  private _addExistingDashboardFolders(dashboardFolders: IDashboardFolder[]): void {
    this._sourceDashboardFolders$.next(dashboardFolders);
  }

  canRemoveDashboardFolder(dashboardFolderId: string): boolean {
    return (
      this.canEditDashboardFolderName(dashboardFolderId) &&
      this.dashboardsForm.value.every((dashboard) => dashboard.parentFolder?.id !== dashboardFolderId)
    );
  }

  editDashboardFolderName(dashboardFolderId: string, newDashboardName: string): void {
    if (this.canEditDashboardFolderName(dashboardFolderId)) {
      const dashboardFolders = this._sourceDashboardFolders$.getValue()?.length
        ? this._sourceDashboardFolders$.getValue()
        : [];
      const updatedDashboardFolders = dashboardFolders.map((dashboardFolder) => {
        if (dashboardFolder.id === dashboardFolderId) {
          dashboardFolder.name = newDashboardName;
        }

        return dashboardFolder;
      });
      this._sourceDashboardFolders$.next(updatedDashboardFolders);
      this.dashboardsForm.markAsDirty();
    }
  }

  removeDashboardFolder(dashboardFolderId: string): void {
    if (this.canRemoveDashboardFolder(dashboardFolderId)) {
      const dashboardFolders = this._sourceDashboardFolders$.getValue()?.length
        ? this._sourceDashboardFolders$.getValue()
        : [];
      const updatedDashboardFolders = dashboardFolders.filter(
        (dashboardFolder) => dashboardFolder.id !== dashboardFolderId,
      );
      this._sourceDashboardFolders$.next(updatedDashboardFolders);
      this.dashboardsForm.markAsDirty();
    }
  }

  removeAllowedGroup(groupId: string): void {
    this.dashboardsForm.controls.forEach((dashboard) => {
      if (dashboard.value.restricted) {
        const index = dashboard.value.allowedGroups?.findIndex((group) => group.id === groupId);

        if (index >= 0) {
          (dashboard.get('allowedGroups') as FormArray<{ id: string; name: string }>).removeAt(index);

          if (!dashboard.get('allowedGroups')?.value?.length) {
            dashboard.get('restricted').setValue(false);
          }
        }
      }
    });
  }

  mergeDraftIntoDashboardForms() {
    if (!this._selectedDashboard) return;
    this._isNewDashboard = false;
    const dashboardToModifyIndex = this.dashboardsForm.value.findIndex(
      (dashboard) => dashboard.id === this._selectedDashboard.value.id,
    );

    this.dashboardsForm.removeAt(dashboardToModifyIndex);
    this.dashboardsForm.insert(dashboardToModifyIndex, this._selectedDashboard);
  }
}
