<da-cross-icon class="close-dialog" (click)="close()"></da-cross-icon>

<ng-template #loading>
  <adl-loaded-item loaderType="da" [loading]="true" [iconSize]="45" class="m-auto"></adl-loaded-item>
</ng-template>

<ng-container *ngIf="getDocumentation$ | async as documentation; else loading">
  <da-documentation-viewer-pr
    *ngIf="isPR"
    [documentation]="documentation"
    [canCheckViews]="data.canCheckViews"
  ></da-documentation-viewer-pr>
  <da-documentation-viewer-jcd
    *ngIf="isJCD"
    [documentation]="documentation"
    [canCheckViews]="data.canCheckViews"
  ></da-documentation-viewer-jcd>
  <da-documentation-viewer-accor
    *ngIf="isAccor"
    [documentation]="documentation"
    [canCheckViews]="data.canCheckViews"
  ></da-documentation-viewer-accor>

  <section>
    <adl-document-download-button
      class="da-dark"
      [url]="documentation.document?.url || documentation.url"
      [title]="documentation.title"
      [showLabel]="true"
    ></adl-document-download-button>

    <adl-document-copy-clipboard-button
      class="da-dark"
      [canCopy]="data.canCopy"
      [showLabel]="true"
      [url]="documentation.url ? documentation.url : (documentation.id | url$ | async)"
    ></adl-document-copy-clipboard-button>
  </section>
</ng-container>
