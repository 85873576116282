import { Inject, Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { of } from 'rxjs';
import { E2EService, Logger } from '@dataportal/front-shared';
import type { IAuthErrorReport, IAuthToken } from '@dataportal/msal';

import { AuthStorageService } from './auth-storage.service';
import { AuthTokenService } from './auth-token.service';
import { CognitoAuthService } from './cognito-auth.service';
import { E2eMockedAuthService } from './e2e-mocked-auth.service';
import { MsalAuthService } from './msal-auth.service';

import { AUTH_OPTIONS, IAuthOptions } from '../auth-options';
import type { BaseAuthService, IProfileFromAuth } from './base-auth.service';

export type AvailableProviders = 'azureAd' | 'cognito';

@Injectable()
export class MultiAuthService {
  private _currentService: BaseAuthService | null = null;

  constructor(
    private readonly _msalAuthService: MsalAuthService,
    private readonly _cognitoAuthService: CognitoAuthService,
    private readonly _mockedE2eAuthService: E2eMockedAuthService,
    private readonly _authStorageService: AuthStorageService,
    private readonly _logger: Logger,
    private readonly _tokenService: AuthTokenService,
    private readonly _e2eService: E2EService,
    @Inject(AUTH_OPTIONS) private readonly _authOptions: IAuthOptions,
  ) {
    if (this._authOptions.supportedAuthenticators.length === 1) {
      this._currentService =
        this._authOptions.supportedAuthenticators[0] === 'azureAD' ? this._msalAuthService : this._cognitoAuthService;
    }
  }

  get userAuthProfile$(): Observable<IProfileFromAuth> {
    this._initCurrentProvider();

    if (this._currentService) {
      void this._currentService.refreshCurrentUser();

      return this._currentService.userProfile$;
    }

    return of(null);
  }

  // Properties

  get provider(): string | null {
    const provider = this._authStorageService.provider;

    if (!provider) {
      this.login();

      return null;
    }

    return provider;
  }

  get idToken(): string | null {
    const token = this._authStorageService.idToken;

    if (!token) {
      this.login();

      return null;
    }

    return token;
  }

  // Properties
  get accessToken(): string | null {
    const token = this._authStorageService.accessToken;

    if (!token) {
      this.login();

      return null;
    }

    return token;
  }

  // Get the current token without any side effect, either accessToken or idToken depending on the provider
  getTokenWithoutSideEffect() {
    return this.provider === 'cognito' ? this.accessToken : this.idToken;
  }

  get isRefreshTokenExpired(): boolean {
    return this._authStorageService.isExpired('refresh');
  }

  isTokenExpired(type: 'refresh' | 'access' | 'id'): boolean {
    return this._authStorageService.isExpired(type);
  }

  private get _isInitialized(): boolean {
    return !!this._currentService;
  }

  useProvider(provider: AvailableProviders): void {
    this._currentService = this._mapProvider(provider);
  }

  async refreshCurrentUser(): Promise<void> {
    this._initCurrentProvider();

    return this._currentService.refreshCurrentUser();
  }

  login(): void {
    const isOnLoginPage = window.location.href.endsWith('/login');
    this._logger.info('[MultiAuthService] Requested to login', {
      enabledProviders: this._authOptions.supportedAuthenticators,
      initialized: this._isInitialized,
    });

    if (!isOnLoginPage) {
      this._authStorageService.returnURL = window.location.href;
      this._logger.info('[MultiAuthService] Storing Current URL', window.location.href);
    }

    if (this._isInitialized) {
      return this._currentService.login();
    } else if (!isOnLoginPage) {
      window.location.replace(window.location.origin + '/login');
    }
  }

  async logout(): Promise<void> {
    this._initCurrentProvider();

    return this._currentService.logout();
  }

  isAuthenticated(): boolean {
    this._initCurrentProvider();

    return this._currentService?.isAuthenticated() || false;
  }

  isAccessTokenExpired(): boolean {
    return this._authStorageService.isExpired('access');
  }

  updateTokenServiceCredentials() {
    this._tokenService.updateTokens({
      idToken: this._authStorageService.idToken,
      accessToken: this._authStorageService.accessToken,
    });
  }

  async refreshTokens(redirectTo?: string): Promise<string> {
    this._authStorageService.returnURL = redirectTo || window.location.href;
    this._initCurrentProvider();

    return this._currentService.refreshTokens();
  }

  async handleError(report: IAuthErrorReport): Promise<void> {
    this._initCurrentProvider();

    return this._currentService.handleError(report);
  }

  async acquireTokenDashboard(allowRedirect = false): Promise<IAuthToken | string | void> {
    this._initCurrentProvider();

    return this._currentService.acquireTokenDashboard(allowRedirect);
  }

  async acquireTokenDevops(allowRedirect = false): Promise<IAuthToken | string | void> {
    this._initCurrentProvider();
    const devopsToken = (await this._currentService.acquireTokenDevops(allowRedirect)) as IAuthToken;
    this._authStorageService.devOpsToken = devopsToken.token;
    this._tokenService.updateTokens({ devOpsToken: devopsToken.token });

    return devopsToken;
  }

  async acquireTokenDatabricks(): Promise<IAuthToken | string | void> {
    this._initCurrentProvider();

    return this._currentService.acquireTokenDatabricks();
  }

  async acquireCockpitTokens(allowRedirect = false): Promise<IAuthToken[]> {
    this._initCurrentProvider();
    const devopsToken = (await this.acquireTokenDevops(allowRedirect)) as IAuthToken;
    const databricksToken = (await this.acquireTokenDatabricks()) as IAuthToken;
    this._authStorageService.databricksToken = databricksToken.token;
    this._authStorageService.devOpsToken = devopsToken.token;
    this._tokenService.updateTokens({ databricksToken: databricksToken.token, devOpsToken: devopsToken.token });

    return [devopsToken, databricksToken];
  }

  private _initCurrentProvider(): void {
    if (this._e2eService.isEnabled()) {
      this._currentService = this._mockedE2eAuthService;
    }

    if (!this._isInitialized) {
      this._logger.info('[MultiAuthService] Initializing current provider');
      this._resolveCurrentProvider();
    } else {
      this._logger.debug(
        '[MultiAuthService] Current provider already initialized',
        this._currentService?.constructor.name,
      );
    }
  }

  private _resolveCurrentProvider() {
    this._logger.info('[MultiAuthService] Resolving current IDP');
    const isMsalAuthenticated = this._msalAuthService.isAuthenticated();
    const isCognitoAuthenticated = this._cognitoAuthService.isAuthenticated();
    this._logger.debug('[MultiAuthService] Is user already authenticated', {
      msal: isMsalAuthenticated,
      cognito: isCognitoAuthenticated,
    });

    if (isMsalAuthenticated) {
      this._logger.info('[MultiAuthService] Using MSAL as IDP');
      this._currentService = this._msalAuthService;
    } else if (isCognitoAuthenticated) {
      this._logger.info('[MultiAuthService] Using Cognito as IDP');
      this._currentService = this._cognitoAuthService;
    } else {
      this._logger.debug('[MultiAuthService] No authenticated logging in');
      this.login();
    }
  }

  private _mapProvider(provider: AvailableProviders): BaseAuthService {
    const mapper: {
      [k in AvailableProviders]: BaseAuthService;
    } = {
      azureAd: this._msalAuthService,
      cognito: this._cognitoAuthService,
    };

    return mapper[provider];
  }
}
