import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { merge } from 'rxjs';
import { mergeMap, skip } from 'rxjs/operators';
import { ApiService } from '@dataportal/front-api';
import type { IFormattedUserPermission, SourceRole } from '@dataportal/types';

import { SourcesModule } from '../sources.module';

// Types
// TODO: should be in @dataportal/types
export interface ISourceRoles {
  groups: IGroupRoles[];
  users: IUserRoles[];
}

// TODO: should be in @dataportal/types
export interface IGroupRoles {
  group_id: string;
  group_name: string;
  roles: SourceRole[];
}

// TODO: should be in @dataportal/types
export interface IUserRoles {
  group_roles: {
    [group_key: string]: {
      group_name: string;
      roles: SourceRole[];
    };
  };
  user_id: string;
  user_roles: SourceRole[];
}

// Service
@Injectable({
  providedIn: SourcesModule,
})
export class SourcesRolesService {
  // Constructor
  constructor(private readonly _apiService: ApiService) {}

  // Methods
  getSourceRoles(sourceId: string): Observable<ISourceRoles> {
    return this._apiService.get(`/v4/admin/sources/${sourceId}/permissions`);
  }

  grantUserRole(sourceId: string, userId: string, role: SourceRole): Observable<void> {
    return this._apiService.post(`/v4/admin/sources/${sourceId}/permissions`, {
      user_id: userId,
      role,
    });
  }

  updateUniqueUserRole(sourceId: string, userId: string, role: SourceRole): Observable<void> {
    return this._apiService.put(`/v4/admin/sources/${sourceId}/permissions`, {
      user_id: userId,
      role,
    });
  }

  updateUniqueGroupRole(sourceId: string, groupId: string, role: SourceRole): Observable<void> {
    return this._apiService.put(`/v4/admin/sources/${sourceId}/permissions`, {
      group_id: groupId,
      role,
    });
  }

  revokeUserRole(sourceId: string, userId: string, role: SourceRole): Observable<void> {
    return this._apiService.delete(`/v4/admin/sources/${sourceId}/permissions`, {
      body: {
        user_id: userId,
        role,
      },
    });
  }

  revokeUserRoles(sourceId: string, userId: string, roles: SourceRole[]): Observable<void> {
    const revokes = roles.map((role) => this.revokeUserRole(sourceId, userId, role));

    return merge(revokes).pipe(
      mergeMap((revoke) => revoke, 1),
      skip(revokes.length - 1),
    );
  }

  grantGroupRole(sourceId: string, groupId: string, role: SourceRole): Observable<void> {
    return this._apiService.post(`/v4/admin/sources/${sourceId}/permissions`, {
      group_id: groupId,
      role,
    });
  }

  revokeGroupRole(sourceId: string, groupId: string, role: SourceRole): Observable<void> {
    return this._apiService.delete(`/v4/admin/sources/${sourceId}/permissions`, {
      body: {
        group_id: groupId,
        role,
      },
    });
  }

  revokeGroupRoles(sourceId: string, groupId: string, roles: SourceRole[]): Observable<void> {
    const revokes = roles.map((role) => this.revokeGroupRole(sourceId, groupId, role));

    return merge(revokes).pipe(
      mergeMap((revoke) => revoke, 1),
      skip(revokes.length - 1),
    );
  }

  listRolesOnSource(sourceId: string): Observable<IFormattedUserPermission> {
    return this._apiService.get(`v4/sources/${sourceId}/current-user-roles`);
  }
}
