import { Component, Directive, forwardRef, HostBinding, HostListener, NgModule } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlValueAccessor } from '@ngneat/reactive-forms';

// Component
@Component({
  selector: 'adl-fancy-switch',
  templateUrl: './fancy-switch.component.html',
  styleUrls: ['./fancy-switch.component.scss'],
  host: {
    '(blur)': 'onTouched()',
  },
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => FancySwitchComponent), multi: true }],
})
export class FancySwitchComponent extends ControlValueAccessor<boolean> {
  // Attributes
  @HostBinding('class.on')
  value = false;

  // Events
  @HostListener('click')
  handleClick(): void {
    this.value = !this.value;

    if (this.onChange) {
      this.onChange(this.value);
    }
  }

  // Methods
  writeValue(value: boolean): void {
    this.value = value;
  }
}

// Directives
@Directive({
  selector: '[adl-fancy-on-icon]',
})
export class FancyOnIcon {
  // Attributes
  @HostBinding('style')
  style = 'display: block;';
}

// Module
@NgModule({
  declarations: [FancySwitchComponent, FancyOnIcon],
  exports: [FancySwitchComponent, FancyOnIcon],
})
export class FancySwitchModule {}
