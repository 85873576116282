import { Injectable } from '@angular/core';

const FIVE_MINUTES = 5 * 60 * 1000;
export const DEFAULT_TTL = FIVE_MINUTES;

export enum DATAPORTAL_DBS {
  DATALAKE = 'dataportal',
}

@Injectable()
export class IndexedDbCacheService {
  private static _isTTLExpired(updatedAt?: number, ttl?: number): boolean {
    return !updatedAt ? true : Date.now() - updatedAt > (ttl || DEFAULT_TTL);
  }

  constructor() {
    if (!('indexedDB' in window)) {
      throw new Error('This browser does not support IndexedDB');
    }
  }

  private async _commit<T>(
    operation: (store: IDBObjectStore) => Promise<T>,
    mode: 'readwrite' | 'readonly' = 'readonly',
  ): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      const request = indexedDB.open(DATAPORTAL_DBS.DATALAKE, 1);
      request.onerror = () => reject(request.error);

      request.onupgradeneeded = () => {
        const db = request.result;
        db.createObjectStore('cache');
      };

      request.onsuccess = () => {
        const db = request.result;
        const store = db.transaction('cache', mode).objectStore('cache');
        operation(store)
          .then((res) => resolve(res))
          .catch((err) => reject(err));
      };
    });
  }

  async set<T = unknown>(key: string, value: T, ttl = DEFAULT_TTL): Promise<void> {
    await this._commit<void>((store: IDBObjectStore) => {
      const operation = store.put(
        {
          value,
          updatedAt: Date.now(),
          ttl,
        },
        key,
      );

      return new Promise<void>((resolve, reject) => {
        operation.onerror = () => reject(operation.error);
        operation.onsuccess = () => resolve();
      });
    }, 'readwrite');
  }

  async get<T = unknown>(key: string): Promise<T | null> {
    return this._commit<T>((store: IDBObjectStore) => {
      const operation = store.get(key);

      return new Promise<T | null>((resolve, reject) => {
        operation.onerror = () => reject(operation.error);

        operation.onsuccess = () => {
          const result = operation.result;

          if (!result || IndexedDbCacheService._isTTLExpired(result.updatedAt, result.ttl)) {
            return resolve(null);
          }

          return resolve(result.value as T);
        };
      });
    }, 'readonly');
  }

  async delete(key: string): Promise<void> {
    return this._commit<void>((store) => {
      const operation = store.delete(key);

      return new Promise<void>((resolve, reject) => {
        operation.onerror = () => reject(operation.error);
        operation.onsuccess = () => resolve();
      });
    }, 'readwrite');
  }

  async removeDb(databaseName: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const request = indexedDB.deleteDatabase(databaseName);
      request.onerror = () => reject(request.error);

      request.onupgradeneeded = () => {
        const db = request.result;
        db.createObjectStore('cache');
      };

      request.onsuccess = () => {
        resolve();
      };
    });
  }
}
