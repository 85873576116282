import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { Placement } from '@popperjs/core/lib/enums';

interface ITooltipButton {
  name: string;
  icon?: string;
}

@Component({
  selector: 'adl-tooltip-buttons',
  templateUrl: './tooltip-buttons.component.html',
  styleUrls: ['./tooltip-buttons.component.scss'],
})
export class TooltipButtonsComponent {
  @Input() buttonsList: ITooltipButton[];
  @Input() tooltipCondition = true;
  @Input() tooltipPlacement: Placement = 'bottom';

  @Output() buttonInputEvent = new EventEmitter<string>();

  private readonly _isHidden$ = new Subject<boolean>();

  isHidden$ = this._isHidden$.asObservable();
  emitButton(buttonName: string) {
    this.buttonInputEvent.emit(buttonName);
    this._isHidden$.next(true);
  }
}
