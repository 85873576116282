import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Logger } from '@dataportal/front-shared';

import { ApiUrlService } from './api-url.service';

const MAX_UNAUTHORIZED_BEFORE_DOWN = 2;

const MAX_DOWN_SERVICES = 2;

const MAX_DAMAGED_SERVICES = 4;

const IGNORED_SERVICES = ['syncad', 'logs'];

@Injectable({ providedIn: 'root' })
export class ServiceStatusManagerService {
  private _downServices: string[] = [];
  private _damagedServices: string[] = [];

  private readonly _servicesStatus$ = new BehaviorSubject<Map<string, number>>(new Map<string, number>());
  servicesStatus$ = this._servicesStatus$.asObservable();

  constructor(private readonly _logger: Logger, private readonly _apiUrlService: ApiUrlService) {
    this._initServiceStatus();

    this.servicesStatus$.subscribe((newServicesStatus) => {
      this._refreshDamagedAndDownServices(newServicesStatus);
    });
  }

  reset401ToService(url: string): void {
    const relatedService = this.getUrlRelatedAPI(url);

    if (this._servicesStatus$.getValue().get(relatedService) > 0) {
      this._logger.debug('[Service Status Manager] Reinitializing', relatedService);
      this._resetService401Number(relatedService);
    }
  }

  add401ToService(url: string): void {
    const relatedService = this.getUrlRelatedAPI(url);
    this._logger.debug('[Service Status Manager] New 401 received by', relatedService);
    this._incrementService401Number(relatedService);
  }

  isDPDown(): boolean {
    return this._areTooManyServicesDown || this._areTooManyServicesDamaged;
  }

  isServiceDown(url: string): boolean {
    const relatedApi = this.getUrlRelatedAPI(url);

    return this._downServices.includes(relatedApi);
  }

  isServiceDamaged(url: string): boolean {
    const relatedApi = this.getUrlRelatedAPI(url);

    return this._damagedServices.includes(relatedApi);
  }

  isServiceIgnored(url: string): boolean {
    return IGNORED_SERVICES.includes(this.getUrlRelatedAPI(url));
  }

  private _initServiceStatus() {
    const initialServiceStatus = new Map<string, number>();
    this._servicesStatus$.next(initialServiceStatus);
  }

  getUrlRelatedAPI(url: string): string {
    return this._apiUrlService.findApiUrl(url);
  }

  private get _areTooManyServicesDamaged(): boolean {
    return this._damagedServices.length > MAX_DAMAGED_SERVICES;
  }

  private get _areTooManyServicesDown(): boolean {
    return this._downServices.length > MAX_DOWN_SERVICES;
  }

  private _refreshDamagedAndDownServices(newServiceStatus: Map<string, number>): void {
    const nextDamagedServices = [];
    const nextDownServices = [];
    newServiceStatus.forEach((nb401, serviceName) => {
      if (nb401 > 0) {
        nextDamagedServices.push(serviceName);
      }

      if (nb401 > MAX_UNAUTHORIZED_BEFORE_DOWN) {
        nextDownServices.push(serviceName);
      }
    });
    this._damagedServices = nextDamagedServices;
    this._logger.debug(`[Service Status Manager] Damaged services : [${this._damagedServices}]`);
    this._downServices = nextDownServices;
    this._logger.debug(`[Service Status Manager] Down services : [${this._downServices}]`);
  }

  private _incrementService401Number(serviceName: string): void {
    const currentValue = this._servicesStatus$.getValue().get(serviceName) || 0;
    this._setService401Number(serviceName, currentValue + 1);
  }

  private _resetService401Number(serviceName: string): void {
    this._setService401Number(serviceName, 0);
  }

  private _setService401Number(serviceName: string, numberOf401: number) {
    const serviceStatusValue = this._servicesStatus$.getValue();
    serviceStatusValue.set(serviceName, numberOf401);
    this._servicesStatus$.next(serviceStatusValue);
  }
}
