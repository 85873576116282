import type { OnInit } from '@angular/core';
import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import type { SafeResourceUrl } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UsersService } from '@dataportal/users';

@Component({
  selector: 'dpg-glossary-comment-modal',
  templateUrl: './glossary-comment-modal.component.html',
  styleUrls: ['./glossary-comment-modal.component.scss'],
})
export class GlossaryCommentModalComponent implements OnInit {
  // Attributes
  @Input() comment: string;
  @Input() componentsName: string;
  @Input() notifierId: string;
  picture: SafeResourceUrl;
  // Constructor
  constructor(
    private readonly _activeMatModal: MatDialogRef<GlossaryCommentModalComponent>,
    private readonly _usersService: UsersService,
    @Inject(MAT_DIALOG_DATA)
    data: {
      comment: string;
      componentsName: string;
      notifierId: string;
    },
  ) {
    this.comment = data?.comment ? data.comment : this.comment;
    this.componentsName = data?.componentsName ? data.componentsName : this.componentsName;
    this.notifierId = data?.notifierId ? data.notifierId : this.notifierId;
  }

  private readonly _destroyed$ = new Subject<void>();

  ngOnInit() {
    this._usersService
      .getImage(this.notifierId)
      .pipe(takeUntil(this._destroyed$))
      .subscribe((image) => {
        this.picture = image || '/assets/img/icon/contact-default.png';
      });
  }

  // Methods
  close(): void {
    this._activeMatModal.close();
  }
}
