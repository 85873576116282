import { Injectable } from '@angular/core';
import { of, ReplaySubject } from 'rxjs';
import { first, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { Logger } from '@dataportal/front-shared';
import type { User } from '@dataportal/users';
import { ImpersonateService, UsersService } from '@dataportal/users';
import { WebsocketsService } from '@dataportal/websocket';

import { MultiAuthService } from './multi-auth.service';

@Injectable()
/**
 * This service is the one holding the current user infos
 */
export class CurrentUserService {
  currentUser$ = new ReplaySubject<User>(1);

  isAdmin$ = this.currentUser$.asObservable().pipe(
    map((user) => user.role === 'admin'),
    tap((isAdmin) => this._logger.info('[CurrentUserService] Is admin', isAdmin)),
    shareReplay(1),
  );

  constructor(
    private readonly _multiAuthService: MultiAuthService,
    private readonly _impersonateService: ImpersonateService,
    private readonly _logger: Logger,
    private readonly _usersService: UsersService,
    private readonly _websocketService: WebsocketsService,
  ) {
    // Retrieve user
    if (this._impersonateService.isImpersonating()) {
      const id = this._impersonateService.getImpersonate();
      this._logger.debug('[CurrentUserService] Impersonating', id);

      this._usersService.get(id).subscribe((user) => {
        this._logger.info('[CurrentUserService] Impersonating user', user);
        this.currentUser$.next(user);
      });
    } else {
      this._logger.debug('[CurrentUserService] Not impersonating');

      this._multiAuthService.userAuthProfile$.pipe(first((authProfile) => authProfile != null)).subscribe(() => {
        this._logger.info('[CurrentUserService] Authenticated, initializing websocket');
      });

      this._multiAuthService.userAuthProfile$
        .pipe(
          switchMap((userProfile) => {
            this._logger.debug('[CurrentUserService] Profile retrieved on AzureAD', userProfile);

            if (userProfile) {
              const username = userProfile.username.toLowerCase();
              this._logger.debug('[CurrentUserService] Fetch current user', username);

              return this._usersService.get(username).pipe(
                tap((user) => {
                  this._logger.info('[CurrentUserService] Current user retrieved', user);
                  this._impersonateService.setOriginUserStatuses(user);
                  this.currentUser$.next(user);
                }),
              );
            } else {
              this._logger.debug('[CurrentUserService] Refresh AD profile');
              this._multiAuthService.refreshCurrentUser();

              return of();
            }
          }),
        )
        .subscribe();
    }

    this.currentUser$.subscribe((user) => {
      this._websocketService.updatedAccessToken(this._multiAuthService.getTokenWithoutSideEffect());

      // Once connected, update the initial tokens
      this._multiAuthService.updateTokenServiceCredentials();
      this._websocketService.reconnect(this._impersonateService.getImpersonate());

      this._setDatalayer(user);
    });
  }

  private _setDatalayer(user: User) {
    (window as any).dataLayer?.push({
      event: 'datalayer_employee',
      employee: {
        timestamp: Date.now(),
        favorite_portal: user.favoriteOrganization,
        company_name: user.adInformation ? user.adInformation.companyName : '',
        job_title: user.adInformation ? user.adInformation.jobTitle : '',
        department: user.adInformation ? user.adInformation.department : '',
        is_admin: user.role === 'admin' ? 'yes' : 'no',
      },
    });
  }
}
