import { InjectionToken } from '@angular/core';
import type { DatalakeProvider } from '@dataportal/types';

// Constants
export const LAZY_LOADED_MODULES_ENVIRONMENT_OPTIONS = new InjectionToken<ILazyLoadedEnvironmentOptions>(
  'dataportal-front:lazy-loading-environment:options',
);

export const IS_PR = new InjectionToken<boolean>('dataportal-front:lazy-loading-environment:options:is-pr');
export const IS_JCD = new InjectionToken<boolean>('dataportal-front:lazy-loading-environment:options:is-jcd');
export const IS_ACCOR = new InjectionToken<boolean>('dataportal-front:lazy-loading-environment:options:is-accor');

// Types
export interface ILazyLoadedEnvironmentOptions {
  // Options
  dpContext: {
    code: 'PR' | 'JCD' | 'ACCOR';
    name: 'Pernod Ricard' | 'JCDecaux' | 'Accor';
    internalUserEmail: RegExp;
    environment: string;
    appCode: string;
  };
  dashboardTypesAndNames: Array<{ label: string; value: string; infoBubbleContent?: string }>;
  dataProviders: Array<{ label: string; value: DatalakeProvider }>;
  supportedAuthenticators: Array<'cognito' | 'azureAD'>;
  metabaseBaseUrl?: string;
  msalOptions: {
    databricksScope: string;
    devopsImpersonationScope: string;
    userImpersonationScope: string;
  };
  snowflake: {
    accountNameMapping: Record<string, string>;
    accounts: Array<{ label: string; value: string }>;
    accountOrder: Array<string>;
  };
}
