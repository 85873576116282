<div class='item column-flex a-center j-center w90' (click)='navigate($event)'>
  <div class='column-flex j-between a-center'>
    <div class='row-flex a-center j-center'>
      <ng-content select='[logo]'></ng-content>
    </div>
    <div class='row-flex a-center j-center'>
      <adl-title type='subtitle' color='#174487' class='name tac'>{{ this.title }}</adl-title>
    </div>
    <p *ngIf='this.description?.length' class='description tac mt10'>{{ this.description }}</p>
  </div>
</div>
