import { Injectable } from '@angular/core';
import { ApiService } from '@dataportal/front-api';
import { Logger } from '@dataportal/front-shared';

import { PermissionsModule } from '../permissions.module';

import { EntityUserRolesService } from './entity-user-roles.service';

@Injectable({
  providedIn: PermissionsModule,
})
export class SourceUserRolesService extends EntityUserRolesService {
  // Attributes
  private _relatedSourceId: string;

  // Constructor
  constructor(apiService: ApiService, logger: Logger) {
    super(apiService, logger);
  }

  /**
   * Set source to be used when listing user permissions
   * @param sourceId
   */
  setSource(sourceId: string): void {
    this._relatedSourceId = sourceId;
    this.url = `v4/admin/sources/${this._relatedSourceId}/permissions`;
  }
}
