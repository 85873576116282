<nav class="top-bar--component z-10 flex px-8 h-[80px]" [class.hidden]="isHidden">
  <div class="w-full h-full flex items-center justify-between">
    <div class="flex items-center">
      <a (click)="navigateTo(this.urlService.currentPageIsLeon() ? '/leons' : '/')" class="flex mr-10 cursor-pointer">
        <img
          src="assets/dpg/imgs/icons/arrow-right-white.svg"
          alt="arrow back"
          class="rotate-180 mr-4"
          width="17px"
          height="17px"
        />
        <adl-text color="white" size="15">Back to Accor Data Portal</adl-text>
      </a>
      <a class="flex items-center">
        <img src="assets/dpg/imgs/icons/dp-white-logo.svg" height="31px" width="29px" alt="dp logo" class="mr-4" />
        <adl-text color="white" size="14" weight="bold">Admin</adl-text>
      </a>
      <div class="sub-menu--separator"></div>
      <div class="flex gap-8">
        <a
          *ngIf="hasPermission('sources')"
          routerLink="/admin/sources"
          routerLinkActive="active-link"
          class="white-link"
        >
          Data Product
        </a>
        <a
          *ngIf="isPR && hasPermission('leons')"
          routerLink="/admin/leons"
          routerLinkActive="active-link"
          class="white-link"
        >
          Leons
        </a>
        <a
          *ngIf="hasPermission('access-requests')"
          routerLink="/admin/access-requests"
          routerLinkActive="active-link"
          class="white-link flex items-center gap-3"
        >
          <span>Access Requests</span>
          <dpg-section-notifier
            [notificationsNamesToWatch]="this.notificationsNamesToWatchForAccessRequests"
            hasToDisplayTotalCount="true"
            backgroundColor="bg-yellow"
            numberColor="blue"
          >
          </dpg-section-notifier>
        </a>
        <a
          *ngIf="hasPermission('portals')"
          routerLink="/admin/portals"
          routerLinkActive="active-link"
          class="white-link flex items-center gap-3"
        >
          <span>Business Areas & Product Families</span>
          <dpg-section-notifier
            [notificationsNamesToWatch]="this.notificationsNamesToWatchForPublications"
            hasToDisplayTotalCount="true"
            backgroundColor="bg-yellow"
            numberColor="blue"
          >
          </dpg-section-notifier>
        </a>
        <div class="relative cursor-pointer item">
          <div
            *ngIf="hasPermission('users') || hasPermission('groups')"
            routerLinkActive="active-link"
            class="white-link sub--menu"
          >
            People
          </div>
          <div class="dropdown expanded flex column-flex" style="max-width: 200px">
            <div class="dropdown-section">
              <li class="sub-link">
                <a
                  *ngIf="hasPermission('groups')"
                  routerLink="/admin/groups"
                  routerLinkActive="active-link"
                  class="py-2 px-5 block sub-link-text"
                >
                  Groups
                </a>
              </li>
              <li *ngIf="hasPermission('users')" class="sub-link">
                <a routerLink="/admin/users" routerLinkActive="active-link" class="py-2 px-5 block sub-link-text">
                  Users
                </a>
              </li>
            </div>
          </div>
        </div>
        <a
          *ngIf="hasPermission('glossary')"
          routerLink="/admin/glossary"
          routerLinkActive="active-link"
          class="white-link"
        >
          Business Glossary
        </a>
        <a *ngIf="isAdmin" routerLink="/admin/configurations" routerLinkActive="active-link" class="white-link">
          Banner
        </a>
        <a *ngIf="isAdmin && isPR" routerLink="/admin/guardian" routerLinkActive="active-link" class="white-link">
          Guardian
        </a>
        <ng-container *ngIf="isPR || isAccor">
          <div class="relative cursor-pointer item" *ngIf="isAdmin">
            <div routerLinkActive="active-link" class="white-link sub--menu">Env</div>
            <div class="dropdown expanded flex column-flex" style="max-width: 200px">
              <div class="dropdown-section">
                <li *ngFor="let env of this.adminEnvs" class="sub-link">
                  <a [href]="env.link" class="py-2 px-5 block sub-link-text">
                    {{ env.name }}
                  </a>
                </li>
              </div>
            </div>
          </div>
          <a *ngIf="!isAdmin" [href]="userEnv.link" class="white-link">{{ userEnv.name }}</a>
        </ng-container>
      </div>
    </div>
    <dpg-header-profile size="small"></dpg-header-profile>
  </div>
</nav>
<dpg-impersonate-shortcut></dpg-impersonate-shortcut>
<dpg-toggle-admin-shortcut></dpg-toggle-admin-shortcut>
