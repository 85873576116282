<div
  class="object flex gap-6 p-[6px_12px_6px_10px] border rounded-[10px] cursor-pointer"
  *ngIf="!!selectedObject"
  (click)="navigateTo.emit()"
>
  <adl-text
    *ngIf="!!selectedObject.tenant"
    size="12"
    class="tenant px-[4px] pb-[1px] rounded-[4px]"
    color="primary-500"
  >
    {{ selectedObject.tenant }}
  </adl-text>

  <adl-text size="12" class="name">
    {{ selectedObject.name }}
  </adl-text>

  <adl-icon-button iconType="primary" size="10" icon="cross-medium" (click)="onObjectDelete($event)"></adl-icon-button>
</div>
