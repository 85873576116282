<ng-container *ngIf="mode === 'graph-api'; else useDataPortal">
  <dpg-limited-user-select
    [placeholder]="placeholder"
    [predicates]="predicates"
    (selected)="forwardLimitedUserEvent($event)"
    [clear]="clear"
  ></dpg-limited-user-select>
</ng-container>
<ng-template #useDataPortal>
  <dpg-legacy-user-select
    [placeholder]="placeholder"
    (selected)="forwardLegacyUserEvent($event)"
    [clear]="clear"
></dpg-legacy-user-select>
</ng-template>
