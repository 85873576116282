import type { OnDestroy, OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { of, Subject } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import type { IFormattedObjectPathWithMetadataInfos } from '@dataportal/datalake-and-guardian';
import { DatalakeApiService } from '@dataportal/datalake-and-guardian';
import type { IAPIDatalakeReferential, IAPITableReferential, IExternalReferential } from '@dataportal/front-api';
import { CheckRegexType, IRequestCheckFieldOptions } from '@dataportal/front-api';
import { DatalakeService } from '@dataportal/front-shared';
import type { CheckRegex } from '@dataportal/guardian-utils';
import { DBSnowflakeService } from '@dataportal/snowflake';

import { GuardianService } from '../../services/guardian.service';

import { GuardianReferentialOverviewModalComponent } from '../guardian-referential-overview-modal/guardian-referential-overview-modal.component';

import type { GuardianCheckConstraint } from '../../entities/guardian-form';
import { ISupportedGuardianChecksResource } from '../../entities/guardian-form';

/**
 * Component that retrieve the fields of a specific column
 */
@Component({
  selector: 'dpg-guardian-check-infos-column-field',
  templateUrl: './guardian-check-infos-column-field.component.html',
  styleUrls: ['./guardian-check-infos-column-field.component.scss'],
})
export class GuardianCheckInfosColumnFieldComponent implements OnDestroy, OnInit {
  private static readonly _NB_ROW_EXTERNAL_REFERENTIAL_LIMIT = 5;
  private static readonly _NB_CHAR_DATALAKE_REFERENTIAL_PREVIEW_LIMIT = 80;

  @Input() resource: ISupportedGuardianChecksResource;
  @Input() columnField: IRequestCheckFieldOptions;
  @Input() index: number;
  @Input() allCheckRegex: CheckRegex[] = null;
  @Input() externalReferentials: IExternalReferential[] = null;

  // Variables
  isLoadingSnowflakeColumnExtract = true;
  isLoadingDatalakeInReferential = false;
  isLoadingDatalakeNotInReferential = false;
  snowflakeColumnExtract: string[] = [];

  private readonly _destroyed$ = new Subject<void>();

  constructor(
    private readonly _guardianService: GuardianService,
    private readonly _dbSnowflakeService: DBSnowflakeService,
    private readonly _modalMatService: MatDialog,
    private readonly _datalakeApiService: DatalakeApiService,
    private readonly _datalakeService: DatalakeService,
  ) {}

  ngOnInit(): void {
    this.extractSnowflakeColumn();
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
  }

  isSnowflakeReferential(): boolean {
    return GuardianService.hasSnowflakeReferential(this.columnField, true);
  }

  isDatalakeReferential(isWhitelist: boolean): boolean {
    return GuardianService.hasDatalakeReferential(this.columnField, isWhitelist);
  }

  getTypeToDisplay(type: string): string {
    return this._guardianService.getTypeToDisplay(type, this.resource) || '';
  }

  getConstraintLabelFromConstraint(constraint: GuardianCheckConstraint): string {
    return GuardianService.getConstraintLabelFromConstraint(constraint)?.toUpperCase();
  }

  getConstraintTooltipFromConstraint(constraint: GuardianCheckConstraint): string {
    return GuardianService.getConstraintTooltipFromConstraint(constraint);
  }

  getReferentialConstraintToCheck(isWhiteList: boolean): GuardianCheckConstraint {
    const fieldToCheck = isWhiteList ? 'values_in' : 'values_not_in';
    const datalakeConstraintToCheck = isWhiteList ? 'datalake_in' : 'datalake_not_in';

    return this.isDatalakeReferential(isWhiteList)
      ? datalakeConstraintToCheck
      : this.isSnowflakeReferential()
      ? 'mdh'
      : null;
  }

  getConstraintLabelForExternalReferential(isWhiteList: boolean): string {
    const constraintToCheck = this.getReferentialConstraintToCheck(isWhiteList);

    return constraintToCheck?.length ? this.getConstraintLabelFromConstraint(constraintToCheck) : '';
  }

  getConstraintTooltipForExternalReferential(isWhiteList: boolean): string {
    const constraintToCheck = this.getReferentialConstraintToCheck(isWhiteList);

    return constraintToCheck?.length ? this.getConstraintTooltipFromConstraint(constraintToCheck) : '';
  }

  getFormattedBoolean(bool: boolean): string {
    return bool ? 'Yes' : 'No';
  }

  get formattedSnowflakeColumnExtract(): string {
    return this.snowflakeColumnExtract?.length
      ? this.snowflakeColumnExtract.map((value) => `"${value}"`).join(', ') + '...'
      : 'No value to display';
  }

  getLabelForFieldCheckRegex(regex: string): string {
    if (!this.allCheckRegex?.length || !regex?.length) {
      return '';
    }

    const regexFound =
      this.allCheckRegex?.find(
        (fetchedRegex) => fetchedRegex.type === CheckRegexType.FIELD_CHECK && fetchedRegex.regex === regex,
      ) || null;
    const fieldCheckRegexLabel = regexFound ? regexFound.label : regex;

    return fieldCheckRegexLabel?.length && regex !== fieldCheckRegexLabel
      ? `-> Equivalent to : ${fieldCheckRegexLabel}`
      : '(No label found for this regex)';
  }

  getLabelForExternalReferential(isWhitelist: boolean): string {
    const fieldToCheck = isWhitelist ? 'values_in' : 'values_not_in';

    if (this.isSnowflakeReferential()) {
      const snowflakeReferentialLabel = this._guardianService.getExternalReferentialSnowflakeLabel(
        this.columnField[fieldToCheck] as IAPITableReferential,
      );

      return snowflakeReferentialLabel?.length ? snowflakeReferentialLabel : '';
    } else if (this.isDatalakeReferential(isWhitelist)) {
      const datalakeReferential = this.columnField[fieldToCheck] as IAPIDatalakeReferential;
      const datalakePath = `${datalakeReferential.bucket_name}/${datalakeReferential.prefix}`;

      return datalakePath.length ? datalakePath : '';
    }

    return '';
  }

  shortenLabelForReferential(label: string): string {
    return label
      ? label.length > GuardianCheckInfosColumnFieldComponent._NB_CHAR_DATALAKE_REFERENTIAL_PREVIEW_LIMIT
        ? `...${label.substring(
            label.length - GuardianCheckInfosColumnFieldComponent._NB_CHAR_DATALAKE_REFERENTIAL_PREVIEW_LIMIT,
          )}`
        : label
      : '';
  }

  redirectToDatalakePath(isWhitelist: boolean): void {
    if (!this.isDatalakeReferential(isWhitelist)) {
      return;
    }

    const fieldToCheck = isWhitelist ? 'values_in' : 'values_not_in';
    const loaderToUpdate = isWhitelist ? 'isLoadingDatalakeInReferential' : 'isLoadingDatalakeNotInReferential';
    const datalakeReferential = (this.columnField ? this.columnField[fieldToCheck] : {}) as IAPIDatalakeReferential;
    this[loaderToUpdate] = true;
    this._datalakeApiService
      .getFormattedObjectPathInfos(
        datalakeReferential?.bucket_name,
        datalakeReferential?.prefix,
        datalakeReferential?.datalake,
        datalakeReferential?.datalake_params?.azure_storage_account,
      )
      .pipe(
        takeUntil(this._destroyed$),
        catchError(() => {
          this[loaderToUpdate] = false;

          return of(null);
        }),
      )
      .subscribe((resp: IFormattedObjectPathWithMetadataInfos) => {
        this[loaderToUpdate] = false;
        this._datalakeService.redirectToDatalake({
          tenant: resp.tenant,
          provider: resp.provider,
          path: resp.path,
          bucket: resp.bucket.pk,
        });
      });
  }

  extractSnowflakeColumn(): void {
    if (!this.isSnowflakeReferential()) {
      return;
    }

    this.isLoadingSnowflakeColumnExtract = true;
    this._dbSnowflakeService
      .getColumnExtract(
        this.columnField?.values_in as IAPITableReferential,
        GuardianCheckInfosColumnFieldComponent._NB_ROW_EXTERNAL_REFERENTIAL_LIMIT,
      )
      .pipe(takeUntil(this._destroyed$))
      .subscribe((values) => {
        this.snowflakeColumnExtract = values;
        this.isLoadingSnowflakeColumnExtract = false;
      });
  }

  openReferentialOverviewModal(): void {
    if (!this.isSnowflakeReferential()) {
      return;
    }

    this._modalMatService.open(GuardianReferentialOverviewModalComponent, {
      width: '1050px',
      minWidth: '1050px',
      maxHeight: '98vh',
      backdropClass: 'modal-nested',
      data: {
        externalReferential: this.columnField?.values_in,
        label: this.getLabelForExternalReferential(true),
      },
    });
  }
}
