<adl-tooltip
    [hasArrow]="false"
    [isMouseOverBehaviorActivated]="false"
    [isMouseOutBehaviorActivated]="false"
    [isClickOutsideBehavior]="true"
    backgroundColor="white"
    [placement]="tooltipPlacement"
    [hasShadow]="true"
    [isHidden$]='isHidden$'
>
  <div
      tooltip-trigger
      class="cursor-pointer"
  >
    <ng-content></ng-content>
  </div>

  <div tooltip-content class="flex flex-col" *ngIf="tooltipCondition">
    <div *ngFor="let button of buttonsList" class="flex tooltip-button cursor-pointer">
      <adl-icon *ngIf="button.icon" iconType="primary" size="12" [icon]="button.icon" class="self-center"></adl-icon>
      <span (click)="emitButton(button.name)" class="px-4 py-2">
      {{ button.name }}
      </span>
    </div>
  </div>
</adl-tooltip>
