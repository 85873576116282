import type { AuthenticationResult, PublicClientApplication } from '@azure/msal-browser';
import { AuthLocalStorageKeys } from '@dataportal/msal';

// Constants
const mockedAccessToken = localStorage.getItem(AuthLocalStorageKeys.ACCESS_TOKEN);

const mockedAuthResponse = {
  accessToken: mockedAccessToken,
  idToken: {
    rawIdToken: mockedAccessToken,
  },
} as unknown as AuthenticationResult;

// Mock
export function mockMsal(): PublicClientApplication {
  return {
    getAllAccounts: () => [
      {
        accountIdentifier: 'test-e2e',
        homeAccountIdentifier: 'test-e2e',
        username: 'test-e2e@pernod-ricard.com',
        name: 'test-e2e',
        idToken: 'mocked-id-token',
        sid: 'mocked-sid',
        environment: 'test',
      },
    ],
    async loginRedirect(): Promise<void> {
      // do nothing
    },
    async logout(): Promise<void> {
      // do nothing
    },
    async acquireTokenSilent(): Promise<AuthenticationResult> {
      return mockedAuthResponse;
    },
  } as unknown as PublicClientApplication;
}
