// FIXME: [guardian] import from @dataportal/accor-types after publishing
// import { ICheckRegex } from '@dataportal/accor-types';
import type { CheckRegexType, ICheckRegex, ICheckRegexSchema } from '@dataportal/front-api';
import { Entity } from '@decahedron/entity';

export class CheckRegex extends Entity {
  // Attributes
  pk: string;
  type: CheckRegexType;
  label: string;
  regex: string;

  constructor(type: CheckRegexType, label: string, regex: string, pk?: string) {
    super();
    this.type = type;
    this.label = label;
    this.regex = regex;
    this.pk = pk;
  }

  fromJson(jsonData: ICheckRegex): CheckRegex {
    this.pk = jsonData.pk;
    this.type = jsonData.type;
    this.label = jsonData.label;
    this.regex = jsonData.regex;

    return this;
  }

  // Methods
  toJson(): ICheckRegexSchema {
    return {
      type: this.type,
      label: this.label,
      regex: this.regex,
    };
  }
}
