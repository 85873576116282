import type { SafeHtml } from '@angular/platform-browser';
import type { Observable } from 'rxjs';

export class NavbarItem {
  constructor(
    readonly nameDisplay: string,
    readonly name: string,
    readonly url: string | (() => string),
    readonly visibility: Observable<boolean>,
    readonly logo: SafeHtml,
    readonly external: boolean,
  ) {}
}
