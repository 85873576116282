<div
  class="h-full w-full relative overflow-hidden min-h-[200px]"
  #container
  adlIsOverflowing
  (isOverflowing)="onIsOverflowing($event)"
  (closeToBottom)="showMoreItems(false)"
  [marginFromBottom]="marginFromBottom"
  [style.maxHeight]="(currentMaxHeight$ | async) ?? '0'"
>
  <ng-content></ng-content>
</div>

<div
  *ngIf="(isOverflowing$ | async) ?? false"
  class="w-full mt-[-80px] flex justify-center h-[80px] z-10 bg-gradient-to-t from-[#f4f8fb] relative cursor-pointer pt-[13px]"
  (click)="showMoreItems()"
  #showMoreButton
  cdkDropList
  (cdkDropListEntered)="showMoreItems()"
  [cdkDropListConnectedTo]="dropLists"
  [id]="dropListId"
>
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="26" height="26" rx="13" fill="#184487" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.85087 7.72056C8.70268 7.57744 8.50421 7.49824 8.2982 7.50003C8.09219 7.50182 7.89512 7.58445 7.74944 7.73013C7.60376 7.87581 7.52113 8.07288 7.51934 8.27889C7.51755 8.4849 7.59675 8.68337 7.73987 8.83156L12.4542 13.5458C12.6015 13.6931 12.8013 13.7759 13.0097 13.7759C13.218 13.7759 13.4178 13.6931 13.5652 13.5458L18.2794 8.83156C18.4226 8.68337 18.5018 8.4849 18.5 8.27889C18.4982 8.07288 18.4155 7.87581 18.2699 7.73013C18.1242 7.58445 17.9271 7.50182 17.7211 7.50003C17.5151 7.49824 17.3166 7.57744 17.1684 7.72056L13.0097 11.8793L8.85087 7.72056ZM8.85087 13.0063C8.77839 12.9312 8.69169 12.8714 8.59583 12.8302C8.49997 12.789 8.39687 12.7673 8.29254 12.7664C8.18822 12.7655 8.08475 12.7854 7.98819 12.8249C7.89163 12.8644 7.8039 12.9228 7.73013 12.9965C7.65636 13.0703 7.59802 13.158 7.55851 13.2546C7.519 13.3512 7.49912 13.4546 7.50003 13.5589C7.50094 13.6633 7.52261 13.7664 7.56379 13.8622C7.60497 13.9581 7.66483 14.0448 7.73987 14.1173L12.4542 18.8316C12.6015 18.9789 12.8013 19.0616 13.0097 19.0616C13.218 19.0616 13.4178 18.9789 13.5652 18.8316L18.2794 14.1173C18.4226 13.9691 18.5018 13.7706 18.5 13.5646C18.4982 13.3586 18.4155 13.1615 18.2699 13.0158C18.1242 12.8702 17.9271 12.7875 17.7211 12.7857C17.5151 12.784 17.3166 12.8631 17.1684 13.0063L13.0097 17.1651L8.85087 13.0063Z"
      fill="white"
    />
  </svg>
</div>
