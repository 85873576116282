export const setCookie = (name: string, value: string, days = 7, path = '/') => {
  const expires = new Date(Date.now() + days * 864e5).toUTCString();
  document.cookie = name + '=' + encodeURIComponent(value) + '; expires=' + expires + '; path=' + path;
};

export const getCookie = (name: string) => {
  return document.cookie.split('; ').reduce((r, v) => {
    const parts = v.split('=');

    return parts[0] === name ? decodeURIComponent(parts[1]) : r;
  }, '');
};

export const deleteCookie = (name: string, path = '/') => {
  setCookie(name, '', -1, path);
};

export const setAuthCookies = (idToken: string) => {
  // FIXME: From envs
  const COOKIES = ['superset', 'airflow', 'documentation'];

  for (const cookie of COOKIES) {
    setCookie(`auth0-${cookie}-token`, idToken);
  }
};
