import { Injectable } from '@angular/core';

import { SharedModule } from '../shared.module';

import { Logger } from './logger.service';

// Service
@Injectable({
  providedIn: SharedModule,
})
export class SessionStorageService implements Storage {
  // Attributes
  private _keys: string[] = [];
  private _items: Record<string, string | null> = {};

  // Constructor
  constructor(private readonly _logger: Logger) {}

  // Methods
  clear(): void {
    try {
      this._keys = [];
      this._items = {};
      sessionStorage.clear();
    } catch (error) {
      this._logger.error('Cannot access session storage:', error.toString());
    }
  }

  key(index: number): string | null {
    return this._keys[index] ?? sessionStorage.key(index);
  }

  getItem(key: string): string | null {
    try {
      return key in this._items ? this._items[key] : sessionStorage.getItem(key);
    } catch (error) {
      this._logger.error('Cannot access session storage:', error.toString());

      return null;
    }
  }

  setItem(key: string, value: string): void {
    try {
      sessionStorage.setItem(key, value);
    } catch (error) {
      this._logger.error('Cannot access session storage:', error.toString());

      this._items[key] = value;
      this._keys.push(key);
    }
  }

  removeItem(key: string): void {
    try {
      delete this._items[key];
      this._keys = this._keys.filter((k) => k !== key);

      sessionStorage.removeItem(key);
    } catch (error) {
      this._logger.error('Cannot access session storage:', error.toString());
    }
  }

  // Properties
  get length(): number {
    return this._keys.length;
  }
}
