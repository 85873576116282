<div class="w-full h-[100vh] flex justify-center items-center">
  <div class="w-4/10 flex flex-row-reverse">
    <img
      class="mr-[50px]"
      alt="404 not found image"
      [src]="'../../../../../assets/dpg/imgs/error-page/' + imageType + '.svg'"
    />
  </div>

  <div class="w-6/10 pr-10">
    <div class="w-8/12">
      <adl-text [bold]="true" color="blue" class="mb-4 block" size="20">
        {{ title }}
      </adl-text>
      <adl-text color="blue" class="mb-6 break-all block" weight="medium" size="16">{{ errorLink }}</adl-text>

      <adl-text class="block" color="blue" weight="medium" size="16">
        {{ description }}
      </adl-text>

      <div class="flex items-center mt-12">
        <adl-button
          *ngIf="blueButtonLabel"
          class="mr-12"
          type="primary-dark"
          (click)="blueButtonClicked.emit()"
        >
          {{ blueButtonLabel }}
        </adl-button>
        <adl-button
          *ngIf="yellowButtonLabel"
          class="block"
          type="tertiary"
          (click)="yellowButtonClicked.emit()"
        >
          {{ yellowButtonLabel }}
        </adl-button>
      </div>
    </div>
  </div>
</div>
