import type { SourceRole } from '@dataportal/types';

// Types
export type LeonRole = 'leonReader' | 'leonOwner';
export type DPGRole = SourceRole | LeonRole;

export interface IRoleData {
  role: DPGRole;
  label: string;
  description: string;
}

// Constants
export const ROLES: DPGRole[] = ['dashboardReader', 'dataReader', 'dataDev', 'sourceOwner', 'leonReader', 'leonOwner'];
export const ROLES_DATA: IRoleData[] = [
  // Sources roles
  {
    role: 'dashboardReader',
    label: 'Dashboard Reader',
    description: 'Can read the dashboards',
  },
  {
    role: 'dataReader',
    label: 'Data Reader',
    description: 'Can read the dashboards and only read in the datalake',
  },
  {
    role: 'dataDev',
    label: 'Data Read & Write',
    description: 'Can read the dashboards and read/write in the datalake',
  },
  {
    role: 'sourceOwner',
    label: 'Data Product Admin',
    description: 'Can access everything and manage the data product',
  },

  // Leons roles
  {
    role: 'leonReader',
    label: 'Leon Reader',
    description: 'Can access the leon',
  },
  {
    role: 'leonOwner',
    label: 'Leon Owner',
    description: 'Can access and manage the leon',
  },
];

// Utils
export function getRoleData(...roles: DPGRole[]): IRoleData[] {
  return ROLES_DATA.filter((d) => roles.includes(d.role));
}
