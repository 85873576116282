import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';

import { WordingService } from './wording.service';

import type locales from './locales';

@Pipe({
  name: 'bizWording',
})
export class WordingPipe implements PipeTransform {
  constructor(private readonly _wordingService: WordingService) {}

  transform(value: keyof (typeof locales)['PR']): string {
    return this._wordingService.translate(value);
  }
}
