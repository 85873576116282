import type { OnInit } from '@angular/core';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import type { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { BreadcrumbService } from './breadcrumb.service';

import type { Breadcrumb } from './breadcrumb.service';

export interface IBreadcrumbLink {
  label: string;
  url: string;
}

@Component({
  selector: 'adl-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit {
  @Input() firstLink: IBreadcrumbLink = { label: 'Accor Data Portal Admin', url: '/admin' };
  @Input() breadcrumbType: 'url' | 'action' = 'url';
  // Only for Breadcrumb url
  breadcrumbs$: Observable<Breadcrumb[]>;
  breadcrumbsUrl: Breadcrumb[];
  // Only for Breadcrumb action
  @Input() breadcrumbs: string[];
  @Input() breadcrumbsLabelSize = '15';
  @Input() breadcrumbTheme: 'grey-blue' | 'white-yellow' = 'grey-blue';
  @Input() firstLinkColor = 'grey-500';
  @Input() cursorPointer = false;
  @Output() hasSelectBreadcrumbOrigin = new EventEmitter<string>();
  @Output() hasSelectBreadcrumb = new EventEmitter<string>();

  constructor(private readonly _breadcrumbService: BreadcrumbService, private readonly _router: Router) {
    this.breadcrumbs$ = _breadcrumbService.breadcrumbs$;
  }

  ngOnInit() {
    this.breadcrumbs$.pipe(tap((breadcrumbs) => (this.breadcrumbsUrl = breadcrumbs))).subscribe();
  }

  get firstLinkDifferent() {
    return this.breadcrumbsUrl[0]?.url !== this.firstLink.url;
  }

  selectBreadcrumbOrigin(label: string) {
    this.breadcrumbs = [];
    this.hasSelectBreadcrumbOrigin.emit(label);
  }

  selectBreadcrumb(label: string) {
    this.hasSelectBreadcrumb.emit(label);
  }

  goToRoute(breadcrumb: Breadcrumb) {
    this._router.navigate([breadcrumb.url]);
  }
}
