<adl-modal
  [isAlignedToCross]="true"
  [hasMarginBottom]="false"
  [hasBodySidesPadding]="true"
  bodySidesPadding="small"
  headerBackgroundColor="blue"
  backgroundColor="light-gray"
  closeButtonColor="white"
>
  <div modal-header class="row-flex w100 pt10 pb10">
    <div class="w15 ml20">
      <adl-text color="white" [bold]="true">
        <ng-container>
          <img class="w15 mr15 di" src="assets/dpg/imgs/icons/upload-white.svg" alt="upload icon" />
        </ng-container>
        UPLOAD FILE
      </adl-text>
    </div>
    <div class="w70 j-center">
      <adl-text color="white">
        <ng-container>
          <img class="w2 mr5 di" src="assets/dpg/imgs/icons/guardian-logo.svg" alt="guardian logo" />
          File uploads in this directory are checked with Guardian for Business
          <img class="w2 ml5 di" src="assets/dpg/imgs/icons/guardian-logo.svg" alt="guardian logo" />
        </ng-container>
      </adl-text>
    </div>
  </div>
  <div modal-body class="flex flex-row justify-center items-center w-full h-full pb30">
    <dpg-guardian-check-infos-content class="flex flex-col" [hasExportButton]="false" [guardianStatus]="guardianStatus">
    </dpg-guardian-check-infos-content>
    <div class="separator-vertical-line flex flex-col ml-5 mr-5 h-full"></div>
    <div class="upload-button-zone flex flex-row justify-center items-center h-full">
      <div class="upload-button flex flex-row justify-center items-center">
        <dpg-flatfile-upload-button
          [isDisabled]="!this.guardianStatus?.isChecked || !this.guardianStatus?.checkInfos"
          [settingsFetched]="this.flatfileSettings"
          [fieldHooks]="this.flatfileFieldHooks"
          [recordHooks]="this.flatfileRecordHooks"
          [guardianCheckInfos]="this.guardianStatus?.checkInfos"
          (onClickUpload)="this.uploadFile($event)"
          (mouseover)="this.onMouseFlatfileHover()"
          (mouseout)="this.onMouseFlatfileOut()"
          type="modal-datalake"
          class="flex flex-row items-center w-full h-full mr-2"
        >
          <img class="button-icon mx-8 my-0" [src]="this.currentFlatfileSvgPath" alt="preview icon" />
          <div class="flex flex-col w-full">
            <adl-text color="blue" [bold]="true" size="12" class="flex flex-row w-full text-left mb-3"
              >PREVIEW, CORRECT AND CONVERT TO TARGET FORMAT
            </adl-text>
            <adl-text color="blue" [bold]="true" size="12" class="flex flex-row w-full text-left"
              >I can pre-check and correct data on-the-fly before storing a correctly formatted file
            </adl-text>
          </div>
        </dpg-flatfile-upload-button>
      </div>
    </div>
  </div>
</adl-modal>
