/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { Logger } from '@dataportal/front-shared';
import type { Entity } from '@decahedron/entity';
import { Buffer } from 'buffer';
import { v4 as uuid } from 'uuid';

import { ApiService } from './api.service';

import type { ApiOptions } from './api.service';

// Types
export interface IApiResult {
  data: any[];
  metadata: string;
}

export interface IResultPage<EntityType extends Entity> {
  requesterId: string;
  data: EntityType[];
}

export interface ILoading {
  requesterId: string;
  data: boolean;
}

export interface IBasePaginationMetadata {
  limit?: number;
}

export interface IBasePaginationRequesterState {
  metadata: any;
  apiOptions: ApiOptions;
  currentPage: number;
  currentSize: number;
}

const btoa = (toEncode: string): string => {
  return Buffer.from(toEncode, 'binary').toString('base64');
};

const atob = (encoded: string): string => {
  return Buffer.from(encoded, 'base64').toString('binary');
};

export const serialize = (metadata: unknown): string => {
  return btoa(encodeURIComponent(JSON.stringify(metadata)));
};

export const deserialize = (data: string): any => {
  return JSON.parse(decodeURIComponent(atob(data))) as unknown;
};

// Service
@Injectable()
export abstract class GenericApiPaginatedService<
  EntityType extends Entity,
  Metadata extends IBasePaginationMetadata,
  RequesterState extends IBasePaginationRequesterState,
> {
  // Attributes
  protected url: string;

  protected readonly states = new Map<string, RequesterState>();

  // Pagination helpers
  protected readonly results = new ReplaySubject<IResultPage<EntityType>>(1);
  results$ = this.results.asObservable();

  protected readonly loading = new ReplaySubject<ILoading>(1);
  loading$ = this.loading.asObservable();

  // Constructor
  constructor(protected readonly apiService: ApiService, protected readonly logger: Logger) {}

  // Abstract methods
  protected abstract buildOne(json: any): EntityType;
  protected abstract buildMany(json: any[]): EntityType[];

  protected abstract nextResults(requesterId: string);

  abstract hasNext(requesterId: string): boolean;

  /**
   * Initiate listing, fetch the first page, return requesterId
   */
  abstract startListing(listOptions: Metadata, apiOptions?: ApiOptions): string;

  /**
   * Create and return a requesterId and initialize state
   * @param listOptions
   * @param apiOptions
   */
  protected initListing(listOptions: Metadata, apiOptions?: ApiOptions): string {
    // Build state
    const { ...metadata } = listOptions;

    const state = {
      metadata,
      apiOptions,
      currentPage: -1,
      currentSize: 0,
    };

    // Register state
    const requesterId = uuid();
    this.states.set(requesterId, state as RequesterState);

    return requesterId;
  }

  /**
   * Fetch the next page
   * @param requesterId
   */
  fetchNextPage(requesterId: string): void {
    if (this.hasNext(requesterId)) {
      this.loading.next({ requesterId, data: true });

      // Update state
      const state = this.states.get(requesterId);
      state.currentPage += 1;

      this.nextResults(requesterId).subscribe();
    }
  }

  /**
   * Unsubscribe by deleting a specific state
   * @param requesterId
   */
  unsubscribe(requesterId: string): void {
    if (!requesterId) {
      return;
    }

    this.states.delete(requesterId);
  }

  /**
   * Get a state
   * @param requesterId
   */
  getState(requesterId: string): RequesterState {
    return this.states.get(requesterId);
  }
}
