import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { BehaviorSubject, EMPTY, of, throwError } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { GoogleTagManagerService } from '@dataportal/analytics';
import { ApiService } from '@dataportal/front-api';
import { Logger } from '@dataportal/front-shared';
import { SourceStatisticsService } from '@dataportal/source-statistics';
import type { IFavorite } from '@dataportal/types';
import { ImpersonateService } from '@dataportal/users';
import { EntityBuilder } from '@decahedron/entity';

import { SourcesModule } from '../sources.module';

import { Favorite } from '../entities/favorite';

// Service
@Injectable({
  providedIn: SourcesModule,
})
export class FavoritesSourcesService {
  // Attributes
  private readonly _favoritesSources = new BehaviorSubject<Favorite[]>([]);
  favoritesSources$ = this._favoritesSources.asObservable();

  // Constructor
  constructor(
    private readonly _logger: Logger,
    private readonly _apiService: ApiService,
    private readonly _statsService: SourceStatisticsService,
    private readonly _impersonateService: ImpersonateService,
    private readonly _gtmService: GoogleTagManagerService,
  ) {}

  // Methods
  getFavoritesSources(): Observable<Favorite[]> {
    return this._apiService
      .get<IFavorite[]>('/v4/sources/favorites')
      .pipe(map((favorites) => EntityBuilder.buildMany<Favorite>(Favorite, favorites)));
  }

  refreshFavoritesSources(): void {
    this._logger.debug('[FavoritesSourcesService] Refresh favorites sources');

    this.getFavoritesSources()
      .pipe(tap((favorites) => this._logger.debug('[FavoritesSourcesService] Favorites sources refreshed', favorites)))
      .subscribe((favorites) => {
        this._favoritesSources.next(favorites);
      });
  }

  isFavoriteSource(sourceId: string): boolean {
    return this._favoritesSources.getValue().some((favorite) => favorite.sourceId === sourceId);
  }

  createFavoriteSource(sourceId: string): Observable<Favorite> {
    return this._apiService
      .post('/v4/sources/favorites', { source_id: sourceId })
      .pipe(map((res) => EntityBuilder.buildOne(Favorite, res)));
  }

  addFavoriteSource(sourceId: string): void {
    if (!this._impersonateService.isImpersonating() || this._impersonateService.isOriginUserAdmin()) {
      this._logger.debug('[FavoritesSourcesService] Add source favorite', sourceId);
      this.createFavoriteSource(sourceId)
        .pipe(
          switchMap((favorite) => {
            this._logger.debug('[FavoritesSourcesService] Favorite source added', favorite);
            const currentFavorites = this._favoritesSources.getValue();
            this._favoritesSources.next([...currentFavorites, favorite]);
            this.pushGTMFavoriteEvent(sourceId);

            return this._statsService.refresh(1000);
          }),
        )
        .subscribe();
    }
  }

  deleteFavoriteSource(sourceId: string): Observable<string> {
    return this._apiService.delete(`/v4/sources/${sourceId}/favorites`).pipe(switchMap(() => of(sourceId)));
  }

  removeFavoriteSource(sourceId: string): void {
    if (!this._impersonateService.isImpersonating() || this._impersonateService.isOriginUserAdmin()) {
      this._logger.debug('[FavoritesSourcesService] Remove favorite', sourceId);

      if (!this.isFavoriteSource(sourceId)) {
        this._logger.error(`[FavoritesSourcesService] Cannot remove source ${sourceId} from user favorites`);

        return;
      }

      this.deleteFavoriteSource(sourceId)
        .pipe(
          withLatestFrom(this._favoritesSources),
          map(([, currentFavorites]) => currentFavorites.filter((fav) => fav.sourceId !== sourceId)),
          tap((newFavorites) => {
            this._favoritesSources.next(newFavorites);
            this._logger.debug('[SourcesService] Current state', newFavorites.length);
          }),
          switchMap(() =>
            this._statsService
              .refresh(1000)
              .pipe(
                catchError(() =>
                  throwError(() => new Error('[SourcesService] Error happened when updating favorites sources')),
                ),
              ),
          ),
          catchError((error: unknown) => {
            this._logger.warn(error);
            this.refreshFavoritesSources();

            return EMPTY;
          }),
        )
        .subscribe();
    }
  }

  saveFavoritesOrder(sourceIdsOrdered: string[]) {
    return this._apiService.post('/v4/sources/favorites/order', { sourceIdsOrdered });
  }

  pushGTMFavoriteEvent(sourceId: string) {
    const storedSearchMode = localStorage.getItem('dc-search-mode');
    const searchModeValue = storedSearchMode ? storedSearchMode : 'advanced';
    this._gtmService.pushEvent({
      event: 'dc_favorite',
      dc_data_asset_name: sourceId,
      dc_mode: searchModeValue,
    });
  }
}
