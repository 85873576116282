<adl-modal
  class="flex flex-col items-center justify-center w-full"
  backgroundColor="light-gray"
  [isAlignedToCross]="true"
  [isFullWidth]="true"
  [closeButtonColor]="closeButtonColor"
  [headerBackgroundColor]="headerBackgroundColor"
>
  <div modal-header class="row-flex j-center a-center pt20 pb20 w100">
    <div class="column-flex j-center a-center w100 pl10 pr10">
      <adl-text [size]="headerMsgSize" [ngClass]="{'tac' : msgCenter, 'tal': !msgCenter, 'pl-[30px]': !msgCenter}" class="w100" color="white">{{ this.headerMsg || '' }}</adl-text>
      <adl-text [ngClass]="{'tac' : msgCenter, 'tal': !msgCenter}" class="w100" color="white">{{ this.headerSubtitleMsg || '' }}</adl-text>
    </div>
  </div>
  <div modal-body class="row-flex j-center mt10 mb10 w100">
    <div *ngIf="this.hasMultipleChoices" class="row-flex j-center w100">
      <adl-button [isSquareButton]="true" (click)="this.selectedPath()">Path only</adl-button>
      <adl-button [isSquareButton]="true" class="ml10" (click)="this.selectedPathAndGuardian()"
        >Both path and check</adl-button
      >
    </div>
    <div *ngIf="!this.hasMultipleChoices">
      <adl-text [ngClass]="{'tac' : msgCenter, 'tal': !msgCenter, 'flex': !msgCenter}" class="w-full block">{{ this.singleChoiceBodyMsg || '' }}</adl-text>
      <adl-text [ngClass]="{'tac' : msgCenter, 'tal': !msgCenter, 'flex': !msgCenter}" class="w-full block mt-5">{{ this.secondarySingleChoiceBodyMsg || '' }}</adl-text>
    </div>
  </div>
  <div class="row-flex j-center w100" modal-footer>
    <div *ngIf="this.hasMultipleChoices">
      <adl-button [isSquareButton]="isSquareButton" type="cancel" (clicked)="cancel()">{{ this.cancelButtonText }}</adl-button>
    </div>
    <div *ngIf="!this.hasMultipleChoices" class="row-flex j-center w100">
      <adl-button [isSquareButton]="isSquareButton" (clicked)="validate()">{{ this.validateButtonText || '' }}</adl-button>
      <adl-button [isSquareButton]="isSquareButton" type="cancel" class="ml15" (clicked)="cancel()">{{
        this.cancelButtonText
      }}</adl-button>
    </div>
  </div>
</adl-modal>
