<div *ngIf="{ currentEdit: currentEdit$ | async, hasBeenEdited: hasBeenEdited$ | async } as state">
  <main class="p-6 bg-white rounded-[6px] shadow-[0px_5px_10px_0px_rgba(24,68,135,0.05)] mb-[17px]">
    <ngx-datatable
      class="material datable"
      #table
      [rows]="rows"
      [columnMode]="columnMode"
      [rowHeight]="rowHeight"
      [headerHeight]="headerHeight"
      [scrollbarH]="true"
      [scrollbarV]="true"
      [externalPaging]="true"
      [virtualization]="false"
      [count]="totalRows"
      [limit]="rowsPerPage"
      [offset]="currentPageNumber"
    >
      <ngx-datatable-column
        *ngFor="let column of columns"
        name="{{ column.name }}"
        [sortable]="column.sortable"
        [width]="column.width"
      >
        <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
          <span
            *ngIf="!editable || !isCurrentEdit(rowIndex, column.name, state.currentEdit); else editInput"
            [class.edited-cell]="hasCellBeenEdited(rowIndex, column.name, state.hasBeenEdited)"
            (dblclick)="onEdit({ rowIndex, columnName: column.name, value })"
            class="db w-full pointer min-h-[17px]"
            [title]="editable ? 'Double click to edit' : ''"
          >
            {{ value }}
          </span>
          <ng-template #editInput>
            <input
              class="rounded-lg w-full"
              autofocus
              type="text"
              [value]="state.currentEdit.value"
              (change)="onChangeEditValue($event.target.value, state.currentEdit)"
              (blur)="onEditComplete()"
            />
          </ng-template>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </main>

  <footer class="flex justify-between items-center w-full">
    <adl-text type="hint">{{ totalRows }} total</adl-text>

    <dpg-custom-pager
      [page]="currentPageNumber + 1"
      [size]="rowsPerPage"
      [count]="totalRows"
      (pageChange)="onPageChange($event)"
    >
    </dpg-custom-pager>
  </footer>
</div>
