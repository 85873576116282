<router-outlet name="documentation"></router-outlet>

<section class="flex flex-col w-full">
  <div class="w-full">
    <div class="flex">
      <adl-text [size]="24" color="blue" weight="medium" class="block mb-4">RESULTS FOR</adl-text>
      <adl-text [size]="24" color="black" weight="medium" class="block mb-4 ml-3">{{
        (searchQuery$ | async).toUpperCase()
      }}</adl-text>
    </div>
  </div>
  <div class="mt-8">
    <adl-loaded-item *ngIf="helpCenterService.faqEntriesFiltered$ | async" [loading]="loadingResults$ | async">
      <div
        *ngFor="let faqEntry of helpCenterService.faqEntriesFiltered$ | async"
        class="faq-entry flex items-center mb-4"
      >
        <img
          class="w-[24px] mr-10"
          src="{{ 'assets/modules/data-academy/svg/file/' + faqEntry?.nature + '.svg' }}"
          [alt]="faqEntry.title"
        />

        <div class="faq-title flex-1 cursor-pointer px-4 mr-4" (click)="faqAction(faqEntry)">
          {{ faqEntry.title }}
        </div>

        <adl-document-download-button
          [title]="faqEntry.title"
          [url]="faqEntry.document?.url"
        ></adl-document-download-button>

        <adl-document-copy-clipboard-button
          [url]="!faqEntry.isInternal ? faqEntry.url : (faqEntry.id | url$ : 'documentation' : true | async)"
        ></adl-document-copy-clipboard-button>
      </div>
    </adl-loaded-item>
  </div>
</section>
