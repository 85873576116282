import { Injectable } from '@angular/core';
import { LocalStorageService } from '@dataportal/front-shared';

// Service
@Injectable({
  providedIn: 'root',
})
export class GuardianCurrentUserService {
  static readonly GUARDIAN_UPDATE_CHECKS_LOCAL_STORAGE_KEY_NAME = 'GUARDIAN_UPDATE_CHECKS';

  constructor(private readonly _localStorageService: LocalStorageService) {}

  private _getGuardianUpdateChecksUserPreferenceValue(): string {
    return this._localStorageService.getItem(GuardianCurrentUserService.GUARDIAN_UPDATE_CHECKS_LOCAL_STORAGE_KEY_NAME);
  }

  private _setGuardianUpdateChecksUserPreference(value: string): void {
    this._localStorageService.setItem(GuardianCurrentUserService.GUARDIAN_UPDATE_CHECKS_LOCAL_STORAGE_KEY_NAME, value);
  }

  indicatesThatUserHasUpdatedChecks(): void {
    this._setGuardianUpdateChecksUserPreference('true');
  }

  resetIndicationThatUserHasUpdatedChecks(): void {
    this._localStorageService.removeItem(GuardianCurrentUserService.GUARDIAN_UPDATE_CHECKS_LOCAL_STORAGE_KEY_NAME);
  }

  hasUserUpdatedChecks(): boolean {
    const guardianUpdateChecksUserPreferenceValue = this._getGuardianUpdateChecksUserPreferenceValue();

    return !!guardianUpdateChecksUserPreferenceValue?.length && guardianUpdateChecksUserPreferenceValue === 'true';
  }
}
