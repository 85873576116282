import type { IGroup } from '@dataportal/types';
import { Entity } from '@decahedron/entity';

export class Group extends Entity {
  name: string = null;
  id: string = null;
  type: string = null;
  createdAt: string = null;
  updatedAt?: string = null;
  owners?: Array<string | { id: string; name: string }> = [];

  fromJson(data: IGroup & { owners: Array<{ id: string; name: string }> }) {
    super.fromJson(data);
    this.id = data.pk;
    const hasOwner = data.owners && Array.isArray(data.owners);

    if (hasOwner) {
      this.owners = data.owners;
    }
  }

  toJson(): { name: string; owners: string[] } {
    let owners: string[];

    if (this.owners && Array.isArray(this.owners)) {
      owners = this.owners.every((o) => (o as any).id !== undefined) ? this.owners.map((o: any) => o.id) : this.owners;
    }

    return {
      name: this.name,
      owners,
    };
  }

  isGroupDefault() {
    const defaultGroups = ['public_internal', 'public_external'];

    return defaultGroups.indexOf(this.id) > -1;
  }
}
