<adl-card class="h-fit absolute z-[2]">
  <div class="relative w-full">
    <adl-icon *ngIf='hasCaret' icon="caret-up" class="additional-filter-caret-up absolute right-20"></adl-icon>

    <div class="filter-container w-full whitespace-nowrap overflow-y-auto overflow-x-auto">
      <div
        class="filter flex w-full py-4 px-6 c-pointer"
        *ngFor="let filter of additionalFilterList; let i = index"
        [ngClass]="{ selected: filter === selectedFilter, divider: i !== additionalFilterList.length - 1 }"
        (click)="selectFilter(filter)"
      >
        <span>{{ filter }}</span>
      </div>
    </div>
  </div>
</adl-card>
