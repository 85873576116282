import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from '@dataportal/front-api';
import type { IKpi } from '@dataportal/types';
import { EntityBuilder } from '@decahedron/entity';

import { KpisModule } from '../kpis.module';

import { Kpi } from '../entities/kpi';

@Injectable({
  providedIn: KpisModule,
})
export class KpisService {
  constructor(private readonly apiService: ApiService) {}

  list(): Observable<Kpi[]> {
    return this.apiService.get<IKpi[]>('/v4/sources/kpis').pipe(map((kpis) => EntityBuilder.buildMany<Kpi>(Kpi, kpis)));
  }

  get(id: string): Observable<Kpi> {
    return this.apiService
      .get<IKpi>(`/v4/sources/kpis/${id}`)
      .pipe(map((kpi) => EntityBuilder.buildOne<Kpi>(Kpi, kpi)));
  }
}
