import { animate, AUTO_STYLE, state, style, transition, trigger } from '@angular/animations';
import { Inject, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Component, HostListener, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, forkJoin, from, of, Subject } from 'rxjs';
import { bufferCount, catchError, concatMap, map, mergeAll, take, takeUntil, tap } from 'rxjs/operators';
import { DialogsService } from '@dataportal/adl';
import { AmundsenService } from '@dataportal/amundsen';
import type { IAmundsenTableMetadata, IAthenaQueryParams, IAthenaResponse } from '@dataportal/api-types';
import type { IAmundsenTableColumn } from '@dataportal/api-types/lib/src/types/amundsen';
import { AthenaService } from '@dataportal/athena';
import type { IGuardianStatus, ISnowflakeTableInfo } from '@dataportal/guardian-utils';
import { GuardianCheckModalV2Component, GuardianService } from '@dataportal/guardian-utils';
import { Source, SourcesRolesService } from '@dataportal/sources-dashboards-recommendations';
import { ImpersonateService } from '@dataportal/users';
import { EnvironmentService, IS_ACCOR } from '@dataportal/front-environment';

import { CatalogV2NavigationService } from '../../../services/v2/navigation.service';

const DEFAULT_DURATION = 300;
const METADATA_BATCH_SIZE = 12;

// interface definitions
interface ISelectItem {
  label: string;
  value: string;
}

interface IAmundsenAccount {
  label: string;
  value: string;
  databases: IAmundsenDatabase[];
}

interface IAmundsenDatabase {
  databaseName: string;
  schemas: {
    schemaName: string;
    tables: {
      name: string;
      fullPath: string;
    }[];
  }[];
}

interface IAmundsenInformation extends IAthenaQueryParams {
  description: string;
  lastModified: string;
  rows: number;
  cols: number;
  colData: IAmundsenTableColumn[];
  loadingMetadata: boolean;
  errorMetadata: boolean;
  errorMetadataMessage?: string;
  preview: IAthenaResponse;
  loadingPreview: boolean;
  previewError: boolean;
  sourceId: string;
  isView: boolean;
}

interface IAmundsenMetadataWithError {
  tableKey: string;
  tableMetadata: IAmundsenTableMetadata & { message: string; error: unknown };
}

interface IAmundsenQueryParams {
  amundsenAccount: string;
  amundsenDatabase: string;
  type: 'mssql' | 'snowflake';
}

interface IGuardianLastStatus {
  latestStatus: string;
  isScheduled: boolean;
  statusDate: string;
  timeZone: string;
  checkStatus: IGuardianStatus<'snowflake'>;
}

// Component
@Component({
  selector: 'dpg-catalog-amundsen',
  templateUrl: './catalog-amundsen.component.html',
  styleUrls: ['./catalog-amundsen.component.scss'],
  animations: [
    trigger('collapse', [
      state('false', style({ height: AUTO_STYLE, visibility: AUTO_STYLE })),
      state('true', style({ height: '0', visibility: 'hidden' })),
      transition('false => true', animate(DEFAULT_DURATION + 'ms ease-in')),
      transition('true => false', animate(DEFAULT_DURATION + 'ms ease-out')),
    ]),
  ],
})
export class CatalogAmundsenComponent implements OnInit, OnChanges {
  @Input() source: Source;
  // This component handles both types, MSSQL and Snowflake since they're real similar
  @Input() type: 'snowflake' | 'mssql' = 'snowflake';
  // full = display for data asset page, compact = display for data asset overview in search page
  @Input() viewMode: 'compact' | 'full' = 'full';
  isAdmin = false;
  canRequestCheck = false;

  filteredDatabasesList: ISelectItem[] = [];
  amundsenAccountsDatabases: IAmundsenAccount[] = [];
  currentDatabase: IAmundsenDatabase;

  // Expanding and contracting tables/categories is handled by an object where the key is the tablekey
  expandedStatusCategories: { [key: string]: boolean } = {};
  expandedStatusTables: { [key: string]: boolean } = {};

  // Amundsen table metadata
  tableInformation: { [tableKey: string]: IAmundsenInformation } = {};

  dbSelectOpened = false;
  accountSelectOpened = false;

  ngSelectAccount: string;
  ngSelectDatabase: string;

  readonly amundsenAccountNameMapping: Record<string, string>;
  readonly snowflakeAccountOrder: Array<string>;
  amundsenQueryParams: IAmundsenQueryParams;

  snowflakeTableGuardianChecksIdMap: Map<string, number> = new Map<string, number>();
  snowflakeTableGuardianChecksStatusMap: Map<string, IGuardianLastStatus> = new Map<string, IGuardianLastStatus>();

  selectedTable$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private readonly _destroyed$ = new Subject<void>();

  // This host listener is used to close the dropdown by clicking outside out of it
  @HostListener('document:click', ['$event'])
  handleClickOutside(event) {
    const target = event.target;

    if (target.tagName !== 'EM' && target.id !== 'db-select-opener' && this.dbSelectOpened) {
      this.dbSelectOpened = false;
    }

    if (target.tagName !== 'EM' && target.id !== 'account-select-opener' && this.accountSelectOpened) {
      this.accountSelectOpened = false;
    }
  }

  constructor(
    private readonly _amundsenService: AmundsenService,
    private readonly _athenaService: AthenaService,
    private readonly _navigationService: CatalogV2NavigationService,
    private readonly _curRoute: ActivatedRoute,
    private readonly _guardianService: GuardianService,
    private readonly _dialogsService: DialogsService,
    private readonly _sourcesRolesService: SourcesRolesService,
    private readonly _impersonateService: ImpersonateService,
    private readonly _environmentService: EnvironmentService,
    @Inject(IS_ACCOR) readonly isAccor: boolean,
  ) {
    const { accountNameMapping, accountOrder } = this._environmentService.options.snowflake;
    this.amundsenAccountNameMapping = accountNameMapping;
    this.snowflakeAccountOrder = accountOrder;

    this.isAdmin = this._impersonateService.isOriginUserAdmin();
  }

  ngOnInit() {
    // ACCOR - NO GUARDIAN
    if (!this.isAccor) {
      // Retrieve latest guardian status = first item of the array
      // level: 'silent' is used to hide toaster errors if the request fails
      this._guardianService
        .listSourceRelatedChecksByResource(this.source.id, 'snowflake', { 403: { level: 'silent' } })
        .pipe(
          concatMap((checks) => {
            return checks.map((check) => {
              if (check.checksId[0]) {
                this.snowflakeTableGuardianChecksIdMap.set(
                  this._amundsenService.getAmundsenTableKey(check.dataset),
                  check.checksId[0],
                );

                return this._guardianService.listChecksByCheckId(check.checksId[0], 'snowflake', {
                  403: { level: 'silent' },
                });
              }
            });
          }),
          mergeAll(),
          takeUntil(this._destroyed$),
        )
        .subscribe((checkStatus: IGuardianStatus<'snowflake'>) => {
          const tableInfo = [...this.snowflakeTableGuardianChecksIdMap.entries()]
            .filter(({ 1: checkId }) => checkId === checkStatus.checkId)
            .map(([table]) => table)[0];
          let statusDate: string;
          let timeZone: string;
          const tmpDate = new Date(checkStatus.checks[0]?.check_time_utc);

          // Date formatting based on the UTC timezone
          if (tmpDate) {
            statusDate = tmpDate.toLocaleString();
            timeZone = tmpDate.getTimezoneOffset() ? `UTC ${tmpDate.getTimezoneOffset() / 60}` : 'Invalid timezone';
          }

          // format IGuardianLastStatus according to GuardianCheckStatus
          const statusInfo: IGuardianLastStatus = {
            latestStatus: checkStatus.checks[0]?.status,
            isScheduled: !!checkStatus.checkInfos.dag_schedule_interval,
            statusDate: statusDate,
            timeZone: timeZone,
            checkStatus,
          };
          this.snowflakeTableGuardianChecksStatusMap.set(tableInfo, statusInfo);
        });
    }

    this.selectedTable$
      .pipe(
        tap((value) => {
          Object.keys(this.expandedStatusTables).forEach((key) => {
            this.expandedStatusTables[key] = false;
          });
          this.expandedStatusTables[value] = true;
        }),
        takeUntil(this._destroyed$),
      )
      .subscribe();

    this.canRequestCheck = this.isAdmin && !this._impersonateService.isImpersonating();

    if (!this.canRequestCheck) {
      this._sourcesRolesService
        .listRolesOnSource(this.source.id)
        .pipe(takeUntil(this._destroyed$))
        .subscribe((role) => {
          this.canRequestCheck = role.user_roles.includes('dataDev') || role.user_roles.includes('sourceOwner');
        });
    }
  }

  // Lifecycle
  ngOnChanges(changes: SimpleChanges): void {
    // reset everything if the input source changes
    if (changes.source && this.source) {
      this.amundsenAccountsDatabases = [];
      this.currentDatabase = null;

      this.expandedStatusCategories = {};
      this.expandedStatusTables = {};

      this.handleAmundsenTables();

      this.dbSelectOpened = false;
      this.accountSelectOpened = false;
    }
  }

  toggleCollapseCategory(schema: string) {
    this.expandedStatusCategories[schema] = !this.expandedStatusCategories[schema];
  }

  handleAmundsenTables() {
    const referenceTables = this.type === 'snowflake' ? this.source.snowflakeTableKeys : this.source.mssqlTableKeys;

    if (referenceTables?.length) {
      referenceTables.forEach((tableKey) => {
        // extract database, schema, table name from the table key
        const tableInfo = this._amundsenService.splitAmundsenTableKey(tableKey);

        // Find existing accounts, databases, schemas and insert the table, else create new entry
        const existingAccount = this.amundsenAccountsDatabases.find((acc) => acc.value === tableInfo.accountName);

        if (existingAccount) {
          const existingDatabase = existingAccount.databases.find((cat) => cat.databaseName === tableInfo.databaseName);

          if (existingDatabase) {
            const existingSchema = existingDatabase.schemas.find((sch) => sch.schemaName === tableInfo.schemaName);

            if (existingSchema) {
              existingSchema.tables.push({ name: tableInfo.tableName, fullPath: tableKey });
            } else {
              existingDatabase.schemas.push({
                schemaName: tableInfo.schemaName,
                tables: [{ name: tableInfo.tableName, fullPath: tableKey }],
              });
            }
          } else {
            const dbToPush = {
              databaseName: tableInfo.databaseName,
              schemas: [
                {
                  schemaName: tableInfo.schemaName,
                  tables: [{ name: tableInfo.tableName, fullPath: tableKey }],
                },
              ],
            };

            existingAccount.databases.push(dbToPush);
          }
        } else {
          const accountToPush = {
            label: this.amundsenAccountNameMapping[tableInfo.accountName] || tableInfo.accountName,
            value: tableInfo.accountName,
            databases: [
              {
                databaseName: tableInfo.databaseName,
                schemas: [
                  {
                    schemaName: tableInfo.schemaName,
                    tables: [{ name: tableInfo.tableName, fullPath: tableKey }],
                  },
                ],
              },
            ],
          };

          this.amundsenAccountsDatabases.push(accountToPush);
        }

        // set category expansion statuses
        this.expandedStatusCategories[tableInfo.databaseName] = false;
        this.expandedStatusCategories[tableKey] = false;

        this.tableInformation[tableKey] = {
          description: '',
          lastModified: null,
          cols: null,
          colData: null,
          loadingMetadata: true,
          errorMetadata: false,
          rows: null,
          table: tableInfo.tableName,
          account: tableInfo.accountName,
          database: tableInfo.databaseName,
          schema: tableInfo.schemaName,
          preview: null,
          loadingPreview: false,
          previewError: false,
          sourceId: this.source.id,
          isView: false,
        };
      });

      // there's a specific account order for display in the dropdown
      this.amundsenAccountsDatabases.sort(
        (acc1, acc2) => this.snowflakeAccountOrder.indexOf(acc1.value) - this.snowflakeAccountOrder.indexOf(acc2.value),
      );

      // create observables to get metadata of every amundsen table present
      const amundsenMetadata$ = from(
        referenceTables.map((tableKey) =>
          this._amundsenService
            .getAmundsenTableMetadata(
              this.isAccor ? tableKey : encodeURIComponent(tableKey.replace('Table:', '')),
              this.source.id,
            )
            .pipe(
              map((data) => ({ tableKey, tableMetadata: data })),
              catchError((err: unknown) => of({ tableKey, tableMetadata: { error: err } })),
              take(1),
            ),
        ),
      );

      // this codes launches metadata requests in batches, so the backend isn't spammed with requests
      // once one batch is resolved, be it error or success, the next one is launched
      // items per batch is currently 12, this was the optimal value found during testing
      amundsenMetadata$
        .pipe(
          bufferCount(METADATA_BATCH_SIZE),
          concatMap((parts) => forkJoin([...parts])),
        )
        .subscribe({
          next: (metadata: IAmundsenMetadataWithError[]) => {
            metadata.forEach((row) => {
              // handle error messages or show the metadata
              if (row?.tableMetadata.error) {
                this.handleAmundsenMetadataError(row.tableKey, 'Unknown error');
              } else if (row?.tableMetadata.message) {
                this.handleAmundsenMetadataError(row.tableKey, row.tableMetadata.message);
              } else if (row?.tableMetadata.key && row?.tableMetadata.columns) {
                this.handleAmundsenMetadata(row.tableMetadata);
              }
            });
          },
          error: (err: unknown) => of(err),
        });

      this.parseAmundsenQueryParams();

      if (this.amundsenQueryParams && this.amundsenQueryParams.type === this.type) {
        this.ngSelectAccount = this.amundsenQueryParams.amundsenAccount;
      } else {
        this.ngSelectAccount = this.amundsenAccountsDatabases[0].value;
      }

      const dbToSelect =
        this.amundsenQueryParams?.type === this.type ? this.amundsenQueryParams.amundsenDatabase : null;

      this.filterDBList(this.ngSelectAccount, dbToSelect);
    }
  }

  filterDBList(account: string, selectDatabase?: string) {
    this.ngSelectAccount = account;
    const existingAccount = this.amundsenAccountsDatabases.find((acc) => acc.value === account);

    if (existingAccount) {
      this.filteredDatabasesList = existingAccount.databases.map((dbMap) => ({
        label: dbMap.databaseName.toUpperCase(),
        value: dbMap.databaseName,
      }));

      if (selectDatabase) {
        this.ngSelectDatabase = selectDatabase;
        this.currentDatabase = existingAccount.databases.find((database) => database.databaseName === selectDatabase);
      } else {
        this.ngSelectDatabase = this.filteredDatabasesList[0].value;
        this.currentDatabase = existingAccount.databases[0];
      }
    }
  }

  parseAmundsenQueryParams() {
    // used for when you open the data asset from overview - snowflake/mssql tab
    // query params are passed and database/schema/table is automatically selected
    if (
      this._curRoute.snapshot.queryParams?.amundsenAccount &&
      this._curRoute.snapshot.queryParams.amundsenDatabase &&
      this._curRoute.snapshot.queryParams.type
    ) {
      this.amundsenQueryParams = {
        type: this._curRoute.snapshot.queryParams.type,
        amundsenAccount: this._curRoute.snapshot.queryParams.amundsenAccount,
        amundsenDatabase: this._curRoute.snapshot.queryParams.amundsenDatabase,
      };
    }
  }

  applyAccountDBFilter(account: string, database: string) {
    this.ngSelectDatabase = database;
    this.currentDatabase = this.amundsenAccountsDatabases
      .find((acc) => acc.value === account)
      .databases.find((db) => db.databaseName === database);
  }

  openDataAsset(): void {
    this._navigationService.openDataAssetDetails(this.source);
  }

  handleAmundsenMetadata(amundsenData: IAmundsenTableMetadata) {
    // some amundsen tables might have tags used to display additional info
    const isLoaded = !!(this.isAccor ? amundsenData : amundsenData?.tags);

    if (isLoaded) {
      const {
        key,
        description = 'No description',
        last_updated_timestamp,
        columns,
        row_count,
        cluster,
        schema,
        is_view,
      } = amundsenData;

      const tableKey = this.isAccor ? key : 'Table:' + key;
      const tableInfo = this._amundsenService.splitAmundsenTableKey(tableKey);
      const lastModified = last_updated_timestamp
        ? new Date(this.isAccor ? last_updated_timestamp : last_updated_timestamp * 1000).toUTCString()
        : 'Not available';

      this.tableInformation[tableKey] = {
        description,
        lastModified,
        cols: columns?.length,
        colData: columns,
        loadingMetadata: false,
        errorMetadata: false,
        rows: row_count,
        table: tableInfo.tableName,
        account: tableInfo.accountName,
        database: cluster,
        schema,
        preview: null,
        loadingPreview: false,
        previewError: false,
        sourceId: this.source.id,
        isView: is_view,
      };
    } else {
      console.error('Error loading metadata!', amundsenData);
    }
  }

  handleAmundsenMetadataError(tableKey: string, errorMessage: string) {
    this.tableInformation[tableKey].loadingMetadata = false;
    this.tableInformation[tableKey].errorMetadata = true;
    this.tableInformation[tableKey].errorMetadataMessage = errorMessage;
  }

  openAmundsenDataAsset(type: 'snowflake' | 'mssql'): void {
    const params = { amundsenAccount: this.ngSelectAccount, amundsenDatabase: this.ngSelectDatabase, type };
    this._navigationService.openDataAssetDetails(this.source, type, true, params);
  }

  loadTablePreview(tableString: string) {
    this.tableInformation[tableString].loadingPreview = true;
    this._athenaService
      .queryAthena({
        database: this.tableInformation[tableString].database,
        table: this.tableInformation[tableString].table,
        account: this.tableInformation[tableString].account,
        schema: this.tableInformation[tableString].schema,
        sourceId: this.source.id,
      })
      .pipe(
        catchError((error: unknown) => of(error)),
        takeUntil(this._destroyed$),
      )
      .subscribe((resp: IAthenaResponse) => {
        this.tableInformation[tableString].loadingPreview = false;

        if (!resp.simplePreview && !resp.detailedPreview) {
          this.tableInformation[tableString].previewError = true;
        } else {
          this.tableInformation[tableString].preview = resp;
        }
      });
  }

  openTablePreviewModal(tableName: string, tableData: IAthenaResponse): void {
    const title = tableName + ' data preview';
    this._navigationService.openSnowflakePreviewModal(title, tableData);
  }

  previewBtnDescription(tableInformation: IAmundsenInformation): string {
    if (tableInformation.preview) {
      return 'SEE PREVIEW';
    } else if (tableInformation.previewError) {
      return 'ERROR LOADING PREVIEW';
    }

    return 'LOAD PREVIEW';
  }

  displayTableStatus(tableInformation: IAmundsenInformation): string {
    if (tableInformation.account === 'snowflake') {
      return 'Metadata unavailable';
    }

    if (tableInformation.loadingMetadata) {
      return 'Loading metadata';
    }

    if (tableInformation.errorMetadata) {
      return 'Metadata error';
    }

    if (tableInformation.isView && !this.isAccor) {
      return `${tableInformation.cols} columns`;
    }

    return `${tableInformation.cols} columns - ${tableInformation.rows} rows`;
  }

  openGuardianCheckModal(tablePath: string, checkStatus: IGuardianStatus): void {
    const snowflakeDataset: ISnowflakeTableInfo = {
      ...this._amundsenService.splitAmundsenTableKey(tablePath),
      type: 'snowflake',
    };

    this._dialogsService.open<GuardianCheckModalV2Component<'snowflake'>>(
      GuardianCheckModalV2Component,
      {
        checkResource: 'snowflake',
        selectedCheck: null,
        currentDataset: snowflakeDataset,
        canRequestCheck: this.canRequestCheck,
        sourceId: this.source.id,
      },
      {
        width: '950px',
        minWidth: '950px',
        maxHeight: '90vh',
        backdropClass: 'modal-backdrop',
        panelClass: 'overflowable-modal',
      },
    );
  }
}
