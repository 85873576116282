<div [ngClass]="{ shadow: this.hasShadow }">
  <button
    class="toggleable-button j-between"
    (click)="this.toggleShowoggleableContent($event)"
    [class]="
      this.buttonBackgroundColorClassName +
      (this.hasLeftBorder ? (!this.hasToShowToggleableContent ? ' left-border' : ' left-border-light') : '')
    "
    [ngStyle]="{
      'width': this.buttonSize?.length ? this.buttonSize : '100%',
      'padding-top': this.buttonPaddingTop?.length ? this.buttonPaddingTop : this.defaultPadding,
      'padding-bottom': this.buttonPaddingBottom?.length ? this.buttonPaddingBottom : this.defaultPadding,
      'padding-left': this.buttonPaddingLeftRight?.length ? this.buttonPaddingLeftRight : this.defaultPadding,
      'padding-right': this.buttonPaddingLeftRight?.length ? this.buttonPaddingLeftRight : this.defaultPadding,
      'border-top-left-radius': this.buttonBorderRadius?.length ? this.buttonBorderRadius : '0',
      'border-top-right-radius': this.buttonBorderRadius?.length ? this.buttonBorderRadius : '0',
      'border-bottom-left-radius': this.buttonBorderRadius?.length ? this.buttonBorderRadius : '0',
      'border-bottom-right-radius': this.buttonBorderRadius?.length ? this.buttonBorderRadius : '0'
    }"
  >
    <div class="df j-between a-center">
      <adl-text class="tal" size="18" [color]="this.buttonTextColor" [bold]="true">{{ this.buttonTitle }}</adl-text>
      <div>
        <adl-icon-button
          *ngIf="!this.hasToShowToggleableContent"
          icon="chevron-right"
          [iconType]="this.chevronIconType"
          (clicked)="this.toggleShowoggleableContent($event)"
        ></adl-icon-button>
        <adl-icon-button
          *ngIf="this.hasToShowToggleableContent"
          icon="chevron-down"
          [iconType]="this.chevronIconType"
          (clicked)="this.toggleShowoggleableContent($event)"
        ></adl-icon-button>
      </div>
    </div>
  </button>
  <div
    class="collapse column-flex"
    id="toggleable-content"
    [ngClass]="{
      show: this.hasToShowToggleableContent
    }"
    [ngStyle]="{
      'border-bottom-left-radius': this.contentBorderRadius?.length ? this.contentBorderRadius : '0',
      'border-bottom-right-radius': this.contentBorderRadius?.length ? this.contentBorderRadius : '0'
    }"
  >
    <ng-content select="[toggleable-content]"></ng-content>
  </div>
</div>
