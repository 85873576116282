import type { OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ColumnMode } from '@swimlane/ngx-datatable';

import type { ICellEdit } from '../../models/cell-edit.model';
import type { IPageChange } from '../../models/page-change.model';

@Component({
  selector: 'dpg-csv-datatable',
  templateUrl: './csv-datatable.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CsvDatatableComponent implements OnInit {
  @Input() rows: unknown[] = [];
  @Input() columns: unknown[] = [];

  @Input() totalRows = 0;
  @Input() rowsPerPage = 10;
  @Input() currentPageNumber = 1;

  @Input() editable = false;

  @Output() readonly pageChange = new EventEmitter<IPageChange>();
  @Output() readonly cellEdit = new EventEmitter<ICellEdit>();

  private readonly _currentEdit$ = new BehaviorSubject<ICellEdit | null>(null);
  private readonly _hasBeenEdited$ = new BehaviorSubject<Set<string>>(new Set());

  currentEdit$ = this._currentEdit$.asObservable();
  hasBeenEdited$ = this._hasBeenEdited$.asObservable();

  readonly columnMode = ColumnMode.standard;
  readonly rowHeight = 35;
  readonly headerHeight = 60;

  onPageChange({ page }: { page: number }): void {
    const pageChange: IPageChange = {
      limit: this.rowsPerPage,
      offset: page - 1,
      count: this.totalRows,
      pageSize: this.rowsPerPage,
    };

    this.onEditComplete();

    this.pageChange.emit(pageChange);
  }

  onEdit(cell: ICellEdit): void {
    if (!this.editable) return;

    this._currentEdit$.next(cell);
  }

  onEditComplete(): void {
    const currentEdit = this._currentEdit$.getValue();

    if (!currentEdit) return;

    const { columnName, rowIndex } = currentEdit;

    const oldValue = this.rows[rowIndex][columnName];

    if (oldValue === currentEdit.value) {
      this._currentEdit$.next(null);

      return;
    }

    this._addColumnToHasBeenEdited(currentEdit);

    const absoluteRowIndex = this._convertRowIndexToAbsolute(rowIndex);

    this.cellEdit.emit({
      ...currentEdit,
      rowIndex: absoluteRowIndex,
      columnName: columnName.toLowerCase(),
    });

    this._currentEdit$.next(null);
  }

  isCurrentEdit(rowIndex: number, columnName: string, currentEdit: ICellEdit): boolean {
    if (!currentEdit) return false;

    return currentEdit.columnName === columnName && currentEdit.rowIndex === rowIndex;
  }

  hasCellBeenEdited(rowIndex: number, columnName: string, hasBeenEdited: Set<string>): boolean {
    const key = this._getCellKey(rowIndex, columnName);

    return hasBeenEdited.has(key);
  }

  onChangeEditValue(value: string, currentEdit: ICellEdit): void {
    this._currentEdit$.next({
      ...currentEdit,
      value,
    });
  }

  private _getCellKey(rowIndex: number, columnName: string): string {
    const index = this._convertRowIndexToAbsolute(rowIndex);

    return `${index}_${columnName}`;
  }

  ngOnInit(): void {
    this.onPageChange({ page: 1 });
  }

  private _convertRowIndexToAbsolute(rowIndex: number): number {
    return this.currentPageNumber * this.rowsPerPage + rowIndex;
  }

  private _addColumnToHasBeenEdited(edit: ICellEdit): void {
    const hasBeenEdited = this._hasBeenEdited$.getValue();

    const key = this._getCellKey(edit.rowIndex, edit.columnName);

    hasBeenEdited.add(key);

    this._hasBeenEdited$.next(hasBeenEdited);
  }
}
