import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'adl-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
})
export class BannerComponent {
  @Input() color: string;
  @Input() labelColor: string;
  @Input() message: string;
  @Input() iconBanner: string;
  @Input() readMore: boolean;
  @Input() closable = true;
  @Input() hasExclamation = false;
  @Input() hasIconBanner = false;
  @Input() isAlertComponent = false;

  @Output() closed = new EventEmitter<void>();
  @Output() moreClick = new EventEmitter<void>();
}
