import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { ApiPaginatedService } from '@dataportal/front-api';
import type { IGroup } from '@dataportal/types';
import { EntityBuilder } from '@decahedron/entity';

import { Group } from '../entities/group';

@Injectable()
export class GroupsService extends ApiPaginatedService<Group> {
  protected url = '/v4/groups';

  protected buildOne(json: IGroup): Group {
    return EntityBuilder.buildOne(Group, json);
  }

  protected buildMany(json: IGroup[]): Group[] {
    return EntityBuilder.buildMany(Group, json);
  }

  createFromAd(adId: string): Observable<Group> {
    return this.post({ ad_id: adId });
  }
}
