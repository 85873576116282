<div class="flex flex-col">
  <div *ngIf="title" class="flex text-left mb-4">
    <adl-text size="16" bold="700" color="blue">{{ title }}</adl-text>
  </div>
  <table
    class="adl-table"
    [ngClass]="{ 'bordered': hasBorder, 'rounded-md': hasRoundedBorder, 'box-shadow': hasBoxShadow }"
  >
    <thead
      *ngIf="hasTableHeaders"
      [ngClass]="{ 'bg-color-white': headColor === 'white', 'separated': hasHeadBodySeparator }"
    >
      <tr>
        <ng-content select="[table-header]"></ng-content>
      </tr>
    </thead>
    <tbody
      [ngClass]="{
        'bg-color-white': bodyColor === 'white',
        'shadow-hover': hasHoveringAnimation,
        'separated-full': hasBodyFullSeparators,
        'separated-half': hasBodyNotFullSeparators
      }"
    >
      <div
        class="w-full overflow-y-scroll"
        [style]="
          (minBodyHeight ? 'min-height: ' + minBodyHeight + 'px;' : '') +
          (maxBodyHeight ? 'max-height: ' + maxBodyHeight + 'px;' : '') +
          (bodyHorizontalPadding ? 'padding-left: ' + bodyHorizontalPadding + 'px;' : '') +
          (bodyHorizontalPadding ? 'padding-right: ' + bodyHorizontalPadding + 'px;' : '') +
          (bodyPaddingBottom ? 'padding-bottom: ' + bodyPaddingBottom + 'px;' : '')
        "
      >
        <ng-content select="[table-body]"></ng-content>
      </div>
    </tbody>
  </table>
</div>
