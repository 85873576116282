<adl-modal>
  <header modal-header>
    <div *ngIf="!accessRequested">
      <adl-icon icon="request" [size]="50"></adl-icon>
      <adl-text type="accent" class="modal-title">Request access for {{ resource?.name }}</adl-text>
    </div>
    <div *ngIf="accessRequested">
      <adl-icon icon="circle-checked" [size]="50"></adl-icon>
      <adl-text type="accent" class="modal-title">You have requested access</adl-text>
    </div>
  </header>
  <div modal-body>
    <div *ngIf="accessRequestAllowed && !accessRequested">
      <adl-text>
        Thanks for your interest in this {{'dataAsset' | bizWording }}. If you click on confirm, functional owners
        will be notified of your access request.
      </adl-text>
      <adl-radio-selector
        (changed)="updateRequestedRole($event)"
        class="flex justify-evenly roles-list mt-3"
        selectorName="all-roles"
        radioStyle="new"
        [activeChoice]="requestedRole"
        [choices]="availableRoles"
      >
      </adl-radio-selector>
    </div>
    <div *ngIf="!accessRequestAllowed">
      <div [innerHTML]="resource?.accessRequestInstructions"></div>
    </div>
    <div *ngIf="accessRequested">
      <adl-text>Your colleague has been notified</adl-text>
    </div>
  </div>
  <footer modal-footer>
    <div class="flex justify-center" *ngIf="accessRequestAllowed && !accessRequested">
      <adl-loaded-item [loading]="loadingRequest" class="mr-4" iconSize="60">
        <adl-button type="data-catalog" (click)="request()">Confirm</adl-button>
      </adl-loaded-item>
      <adl-button class="mr-4" (click)="closeModal()" type="cancel">Cancel</adl-button>
    </div>
    <div class="flex justify-center" *ngIf="!accessRequestAllowed">
      <adl-button type="data-catalog" class="mr-4" (click)="closeModal()">Ok</adl-button>
    </div>
    <div class="flex justify-center" *ngIf="accessRequested">
      <adl-button type="data-catalog" (click)="closeModal()">Close</adl-button>
    </div>
  </footer>
</adl-modal>
