import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import type { ActivatedRoute, Params } from '@angular/router';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { Logger } from '@dataportal/front-shared';
import type { LogBody } from '@dataportal/logs';
import { LogsService } from '@dataportal/logs';
import type { Page } from 'powerbi-client';

import { CurrentDashboardService } from './current-dashboard.service';
import { PowerBiBookmarksService } from './power-bi-bookmarks.service';
import { PowerBiEmbedService } from './power-bi-embed.service';

// Types
interface IDashboardParams {
  pageId: string | null;
  pageName: string | null;
  dashboardId: string | null;
  sourceId: string | null;
  linkId: string | null;
}

@Injectable({
  providedIn: 'root',
})
export class DashboardPageRouteParamsService {
  private readonly _currentParams: IDashboardParams = {
    pageId: null,
    pageName: null,
    dashboardId: null,
    sourceId: null,
    linkId: null,
  };
  private readonly _stopWatching$ = new Subject<void>();
  private readonly _paramsChanged$ = new Subject<void>();
  private _route: ActivatedRoute | undefined;

  constructor(
    private readonly _location: Location,
    private readonly _router: Router,
    private readonly _currentDashboardService: CurrentDashboardService,
    private readonly _powerBiEmbedService: PowerBiEmbedService,
    private readonly _powerBiFiltersService: PowerBiBookmarksService,
    private readonly _logsService: LogsService,
    private readonly _logger: Logger,
  ) {}

  watchRouteParameters(route: ActivatedRoute): void {
    this._logger.info('[DashboardPageRouteParamsService] Watching route parameters');
    this._route = route;

    const routeUpdated = (p: Params) => {
      this._powerBiFiltersService.linkId = p.linkId;
      this._currentParams.pageId = decodeURIComponent(p.pageId);
      this._currentParams.pageName = decodeURIComponent(p.pageName);
      this._currentParams.dashboardId = decodeURIComponent(p.dashboard);
      this._currentParams.sourceId = decodeURIComponent(p.filename);
      this._currentParams.linkId = decodeURIComponent(p.linkId);
      this._logger.info('[DashboardPageRouteParamsService] Route parameters changed', this._currentParams);
      this._paramsChanged$.next();
      this._currentDashboardService.changeCurrentDashboard(
        this._currentParams.sourceId,
        this._currentParams.dashboardId,
      );
    };

    this._route.params.pipe(tap(routeUpdated.bind(this)), takeUntil(this._stopWatching$));
    routeUpdated(this._route.snapshot.params);
  }

  stopWatchingRouteParameters(): void {
    this._logger.info('[DashboardPageRouteParamsService] Stop watching route parameters');
    this._stopWatching$.next();
  }

  updateQueryParametersWhenPageChanges(): void {
    this._logger.info('[DashboardPageRouteParamsService] Watching current PowerBI dashboard page changes');
    this._powerBiEmbedService.addEventListener<{ newPage: Page }>('pageChanged', async (event) => {
      this._logger.info('[DashboardPageRouteParamsService] Page changed, updating query string parameters');
      const pageName = event && event.detail && event.detail.newPage ? event.detail.newPage.displayName : null;
      const pageId = event && event.detail && event.detail.newPage ? event.detail.newPage.name : null;

      if (pageId && pageName) {
        const url = this._router
          .createUrlTree([{ pageId: encodeURIComponent(pageId), pageName: encodeURIComponent(pageName) }], {
            relativeTo: this._route,
          })
          .toString();

        this._logger.info('[DashboardPageRouteParamsService] Sending page view for usage statistics');
        const pageView: LogBody = {
          action: 'page_view',
          previous_url: this._location.path(),
          current_url: url,
        };
        this._logsService.post(pageView).subscribe(
          () => {
            this._logger.debug('[DashboardPageRouteParamsService] Page view sent', pageView);
          },
          (err: unknown) => {
            this._logger.error('[DashboardPageRouteParamsService] Error sending page view', err);
          },
        );

        this._currentParams.pageId = pageId;
        this._currentParams.pageName = pageName;
        this._currentParams.linkId = null;
        this._location.go(url);
      }
    });
  }

  restoreStateFromQueryParametersWhenLoaded(): void {
    this._logger.info('[DashboardPageRouteParamsService] Waiting for dashboard to be loaded before restoring state');
    const handler = this._powerBiEmbedService.addEventListener('loaded', async () => {
      this._logger.info('[DashboardPageRouteParamsService] Dashboard loaded !');

      if (this._powerBiFiltersService.linkId) {
        this._logger.info('[DashboardPageRouteParamsService] Restoring state from link ID');
        this._currentDashboardService.restoreFiltersFromLink().subscribe(
          () => {
            this._logger.info('[DashboardPageRouteParamsService] State restored from link ID');
          },
          (err: unknown) => {
            this._logger.error('[DashboardPageRouteParamsService] Error restoring state from LinkID', err);
          },
          () => {
            this.updateQueryParametersWhenPageChanges();
          },
        );
      } else if (this._currentParams.pageId) {
        this._logger.info('[DashboardPageRouteParamsService] Focusing page given in parameters');
        const pages = await this._powerBiEmbedService.getPages();
        let hasBeenFound = false;

        for (const page of pages) {
          if (page.name === this._currentParams.pageId) {
            hasBeenFound = true;
            await page.setActive();
            break;
          }
        }

        if (!hasBeenFound) {
          this._logger.error('[DashboardPageRouteParamsService] Page to focus not found !', {
            toFocus: this._currentParams.pageId,
            available: pages,
          });
        }

        this.updateQueryParametersWhenPageChanges();
      } else {
        this.updateQueryParametersWhenPageChanges();
      }

      // Restore page from url
      this._powerBiEmbedService.removeEventListener('loaded', handler);
    });
  }
}
