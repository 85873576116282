import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AdlModule } from '@dataportal/adl';
import { ApiModule } from '@dataportal/front-api';

import { DBSnowflakeService } from './services/db-snowflake.service';
import { SnowflakeService } from './services/snowflake.service';

import { SnowflakePageComponent } from './pages/snowflake-page/snowflake-page.component';

@NgModule({
  imports: [ApiModule, CommonModule, FormsModule, AdlModule],
  declarations: [SnowflakePageComponent],
  providers: [DBSnowflakeService, SnowflakeService],
  exports: [SnowflakePageComponent],
})
export class SnowflakeModule {}
