import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Placement } from '@popperjs/core';

import { IconType, SupportedFaIcon, SupportedSVGIcon } from '../../atoms/icons';

@Component({
  selector: 'adl-info-bubble',
  templateUrl: './info-bubble.component.html',
  styleUrls: ['./info-bubble.component.scss'],
})
export class InfoBubbleComponent {
  @Input() placement: Placement = 'top';
  @Input() bubbleIcon: SupportedFaIcon = 'question';
  @Input() bubbleSvgIcon?: SupportedSVGIcon;
  @Input() size = 18;
  @Input() isColumnFlex = false;
  @Input() maxContentSize?: string;
  @Input() minContentSize?: string;
  @Input() iconType: IconType = 'inherit';

  @Output() mouseOver: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();
  @Output() mouseOut: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  onMouseHover(event): void {
    this.mouseOver.emit(event);
  }

  onMouseOut(event): void {
    this.mouseOut.emit(event);
  }
}
