import type { OnChanges, OnInit } from '@angular/core';
import { Component, EventEmitter, Input, Output } from '@angular/core';

export type ButtonType =
  | 'primary'
  | 'primary-dark'
  | 'secondary'
  | 'disabled'
  | 'clear'
  | 'dark'
  | 'dark-cancel'
  | 'cancel'
  | 'cancel-v2'
  | 'success'
  | 'error'
  | 'step'
  | 'datalake'
  | 'magellan'
  | 'modal-datalake'
  | 'guardian'
  | 'guardian-light'
  | 'edit-profile'
  | 'save-profile'
  | 'giant'
  | 'papyrus'
  | 'home-access-data'
  | 'home-more-dashboard'
  | 'data-catalog'
  | 'tertiary'
  | 'reject'
  | 'warning'
  | 'warning-white'
  | 'teams';

export type ButtonSize = 'customize' | 'thin' | 'normal' | 'small' | 'fluid-normal';

@Component({
  selector: 'adl-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnInit, OnChanges {
  // optional
  @Input() type: ButtonType = 'primary';
  @Input() disabled = false;
  @Input() notClickable = false;
  @Input() size: ButtonSize = 'normal';
  @Input() isAutoHeight = false;
  @Input() fullWidth = false;
  @Input() additionalClasses: string[] = [];
  @Input() fixedHeight: string;
  @Input() isSquareButton = false;
  @Input() hovered = false;
  @Input() hasInfoIcon = false;
  @Input() hasWarningIcon = false;

  @Output() clicked = new EventEmitter<MouseEvent>();

  classList: string[] = [];

  ngOnInit(): void {
    this._refreshClassList();
  }

  ngOnChanges(): void {
    this._refreshClassList();
  }

  private _refreshClassList(): void {
    this.classList = [this.type];
    this.additionalClasses.forEach((additionalClass) => this.classList.push(additionalClass));
    this.classList.push(this.size);

    if (this.disabled) {
      this.classList.push('disabled');
    }

    if (this.fullWidth) {
      this.classList.push('full-width');
    }

    if (this.isAutoHeight) {
      this.classList.push('auto');
    }

    if (this.isSquareButton) {
      this.classList.push('br5');
    }

    if (this.hovered) {
      this.classList.push('hovered');
    }

    if (this.hasInfoIcon) {
      this.classList.push('info-icon');
    }

    if (this.hasWarningIcon) {
      this.classList.push('warning-icon');
    }
  }

  onClick(event): void {
    this.clicked.emit(event);
  }
}
