import { Inject, Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import type { IAmundsenFilterOptions, IAmundsenGetTablesResponse, IAmundsenTableMetadata } from '@dataportal/api-types';
import type { ErrorHandlingOptions } from '@dataportal/front-api';
import { ApiService } from '@dataportal/front-api';
import { IS_ACCOR } from '@dataportal/front-environment';
import { Logger } from '@dataportal/front-shared';
import type { IAmundsenTableInfo } from '@dataportal/snowflake';

// Service
@Injectable()
export class AmundsenService {
  private readonly _baseUrl: string;

  // Constructor
  constructor(
    private readonly _apiService: ApiService,
    private readonly _logger: Logger,
    @Inject(IS_ACCOR) private readonly _isAccor: boolean,
  ) {
    // ACCOR - NO AMUNDSEN
    this._baseUrl = this._isAccor ? '/v4/snowflake-explore/tables' : '/v4/amundsen/tables';
  }

  getAmundsenTables(filters: IAmundsenFilterOptions, pageIndex = 0): Observable<IAmundsenGetTablesResponse> {
    return this._apiService
      .post<IAmundsenGetTablesResponse>(this._baseUrl, filters, {
        queryStringParameters: {
          page_index: JSON.stringify(pageIndex),
        },
      })
      .pipe(
        catchError((err: unknown) => {
          this._logger.error(`[AmundsenService] Error while fetching tables : ${err}`);

          return throwError(err);
        }),
      );
  }

  getAmundsenTableMetadata(
    tableKey: string,
    sourceId: string,
    errorHandling?: ErrorHandlingOptions,
  ): Observable<IAmundsenTableMetadata> {
    const metadataServiceUrl = `${this._baseUrl}/${encodeURIComponent(tableKey)}/metadata?sourceId=${sourceId}`;

    return this._apiService
      .get<IAmundsenTableMetadata>(metadataServiceUrl, {
        errorHandling: errorHandling ?? { '*': { level: 'silent' } },
      })
      .pipe(
        catchError((err: unknown) => {
          this._logger.error(`[AmundsenService] Error while fetching table metadata : ${err}`);

          return throwError(err);
        }),
      );
  }

  splitAmundsenTableKey(tableKey: string): IAmundsenTableInfo {
    const indexSplit = tableKey.split('://');

    if (indexSplit.length <= 1) {
      return {
        accountName: null,
        databaseName: null,
        schemaName: null,
        tableName: null,
      };
    }

    const accountName = this._isAccor ? indexSplit[0] : indexSplit[0].split(':')[1];
    const tableDatabaseAndSchemaSplit = indexSplit[1].split('/');
    const databaseName = tableDatabaseAndSchemaSplit[0].split('.')[0];
    const schemaName = tableDatabaseAndSchemaSplit[0].split('.')[1];
    const tableName = tableDatabaseAndSchemaSplit[1];

    return {
      accountName,
      databaseName,
      schemaName,
      tableName,
    };
  }

  getAmundsenTableKey(snowflakeInfo: IAmundsenTableInfo): string {
    return `${this._isAccor ? '' : 'Table:'}${snowflakeInfo.accountName || 'snowflake'}://${
      snowflakeInfo.databaseName
    }.${snowflakeInfo.schemaName}/${snowflakeInfo.tableName}`;
  }
}
