<adl-modal data-cy="datalake-path-selector">
  <adl-text type="accent" [size]="20" modal-header>{{ title }}</adl-text>
  <div modal-body>
    <div>
      <adl-text *ngIf="selectedObjects.length === 0" type="accent" class="no-selected"
        >No {{ isSelectingFiles ? 'file' : 'folder' }} selected yet.</adl-text
      >
      <div *ngIf="selectedObjects.length">
        <div class="mb5 text-al">
          <adl-text type="accent"
            >Selected {{ isSelectingFiles ? 'file' : 'folder' }}{{ isMultipleSelect ? '(s)' : '' }} :</adl-text
          >
        </div>
        <div class="selected-objects">
          <div
            *ngFor="let selectedObject of selectedObjects"
            class="selected-object-element"
            [ngClass]="{
              'c-pointer': !isSelectingFiles,
              'c-not-allowed': !isAllowedToNavigateToObject(selectedObject),
              'disabled': !isObjectExisting(selectedObject)
            }"
            (click)="navigate(selectedObject, true)"
            [attr.data-cy]="'datalake-path-selector-selected-path-'+getBuiltFolderRelatedPath(selectedObject)"
          >
            <span class="data-platform-tag mr-6" [ngClass]="{ disabled: !isObjectExisting(selectedObject) }">
              {{ selectedObject.tenant }}
            </span>
            {{ getBuiltFolderRelatedPath(selectedObject) }}
            <adl-icon-button
              iconType="primary"
              (clicked)="toggleSelection(selectedObject)"
              class="selected-object-element--cross ml5"
              [ngClass]="{ disabled: !isObjectExisting(selectedObject) }"
              icon="cross"
            >
            </adl-icon-button>
          </div>
        </div>
      </div>
    </div>
    <div class="row-flex j-between a-center">
      <dpg-datalake-browser-pagination *ngIf="!root && !(isLoadingFirstPage && !listedObjects.length)"></dpg-datalake-browser-pagination>
      <adl-title *ngIf="root" type="secondary" class="tal c-default mb10"
        >Filesystem choice :
      </adl-title>
      <div *ngIf="!root" class="breadcrumbs mb5">
        <adl-icon-button
          iconType="primary"
          icon="chevron-left"
          [size]="14"
          class="mr10 row-flex a-center"
          (clicked)="back()"
        ></adl-icon-button>
        <div class="flex flex-wrap">
          <div *ngFor="let breadcrumb of breadcrumbs" class="flex flex-nowrap items-center">
            <span>/</span>
            <span [attr.data-cy]="'datalake-path-selector-breadcrumb-'+breadcrumb" class="breadcrumb-element">{{ breadcrumb }}</span>
          </div>
        </div>
        <span *ngIf="actualTenant" class="data-platform-tag a-center ml-3">{{ actualTenant }}</span>
      </div>
      <adl-button *ngIf="this.environmentService?.options.dpContext.code === 'PR'"
        class="ml20 mb5" (clicked)="openCantFindMyFolderModal()" [isSquareButton]="true" type="cancel"
        >Can't find your folder?
      </adl-button>
    </div>
    <div class="flex-table">
      <div *ngIf="actualBucket" class="flex-table-row header-text">
        <adl-text type="tooltip" class="flex-table-cell check-cell row-flex a-center j-center c-default"
          >Select</adl-text
        >
        <adl-text type="tooltip" class="flex-table-cell name-cell c-default">Name</adl-text>
        <adl-text *ngIf="this.environmentService?.options.dpContext.code === 'PR'" type="tooltip" class="flex-table-cell data-platform-cell c-default">Data Platform</adl-text>
      </div>
      <div *ngIf="!actualBucket" class="flex-table-row header-text">
        <adl-text type="tooltip" class="flex-table-cell mono-cell c-default">Name</adl-text>
      </div>
      <adl-loaded-item
        class="flex-table-body"
        [iconSize]="250"
        unit="px"
        [loading]="isLoadingFirstPage || isLoadingBuckets"
      >
        <div *ngIf="listedObjects.length">
          <div *ngFor="let object of listedObjects" [attr.data-cy]="'datalake-path-selector-path-to-select-'+object.name">
            <div *ngIf="object.isFilesystem" class="flex-table-row c-pointer">
              <adl-text class="flex-table-cell mono-cell c-pointer" (click)="navigate(object)">
                {{ object.name }}
              </adl-text>
            </div>
            <div *ngIf="!object.isFilesystem" class="flex-table-row">
              <div
                class="flex-table-cell check-cell row-flex a-center j-center"
                [ngClass]="{ 'c-pointer': hasToEnableSelection(object) }"
                (click)="toggleSelection(object)"
              >
                <adl-icon
                  *ngIf="hasToEnableSelection(object)"
                  [icon]="isElementChecked(object) ? 'circle-checked' : 'circle'"
                  [size]="17"
                  data-cy="select-path-button"
                ></adl-icon>
              </div>
              <adl-text
                class="flex-table-cell name-cell c-pointer"
                [ngClass]="{ 'c-pointer': object.isFolder }"
                (click)="navigate(object)"
              >
                {{ object.name }}
              </adl-text>
              <div class="flex-table-cell data-platform-cell">
                <span class="data-platform-tag">{{ object.tenant }}</span>
              </div>
            </div>
          </div>
        </div>
        <adl-loaded-item
          class="flex-table-body"
          [iconSize]="250"
          unit="px"
          [loading]="isLoadingFirstPage && listedObjects.length === 0"
        ></adl-loaded-item>
        <adl-text *ngIf="listedObjects.length === 0 && !isLoadingFirstPage">No folder here.</adl-text>
      </adl-loaded-item>
    </div>
  </div>
  <div class="row-flex j-center" modal-footer>
    <adl-button (clicked)="cancel()">Cancel</adl-button>
    <adl-button class="ml10" (clicked)="confirm()">Confirm and Save</adl-button>
  </div>
</adl-modal>
