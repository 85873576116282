import { HttpClient, HttpHeaders } from '@angular/common/http';
import type { OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component, ElementRef, Input } from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { indicate } from '@dataportal/adl';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { saveAs } from 'file-saver';

@UntilDestroy()
@Component({
  selector: 'adl-document-download-button',
  templateUrl: './document-download-button.component.html',
  styleUrls: ['./document-download-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentDownloadButtonComponent implements OnInit {
  @Input() url: string; // safe url
  @Input() title: string;
  @Input() showLabel = false;

  downloading$ = new Subject<boolean>();

  constructor(private readonly _host: ElementRef<HTMLElement>, private readonly _api: HttpClient) {}

  ngOnInit(): void {
    fromEvent(this._host.nativeElement, 'click')
      .pipe(
        filter(() => !!this.url),
        switchMap(() => {
          const headers = new HttpHeaders({ Accept: 'application/pdf' });

          return this._api.get(this.url, { headers: headers, responseType: 'blob' }).pipe(indicate(this.downloading$));
        }),
        untilDestroyed(this),
      )
      .subscribe((blobData: Blob) => {
        saveAs(blobData, this.title);
      });
  }
}
