import { Inject, Injectable } from '@angular/core';
import { Logger } from '@dataportal/front-shared';

import { ApiModule } from '../api.module';

import { API_URLS, IApiUrls } from '../api-urls';

// Service
@Injectable({
  providedIn: ApiModule,
})
export class ApiUrlService {
  // Attributes
  private readonly _urlsMap: Map<RegExp, string>;

  static dedupSlashes(rawUrl: string): string {
    const [_all, protocol, url] = rawUrl.match(/(https?:\/\/)(.+)/);

    return protocol + url.replace(/\/\//g, '/');
  }

  // Constructor
  constructor(@Inject(API_URLS) urls: IApiUrls, private readonly _logger: Logger) {
    // Build map
    this._urlsMap = new Map<RegExp, string>([
      [/\/?v4\/access-requests/, urls.v4.accessRequest],
      [/\/?v4\/datalake/, urls.v4.datalake],
      [/\/?v4\/sub-categories/, urls.v4.subCategories],
      [/\/?v4\/categories/, urls.v4.subCategories],
      [/\/?v4\/sources/, urls.v4.sources],
      [/\/?v4\/admin\/sources/, urls.v4.sources],
      [/\/?v4\/admin\/v2\/sources/, urls.v4.sources],
      [/\/?v4\/users/, urls.v4.users],
      [/\/?v4\/groups/, urls.v4.groups],
      [/\/?v4\/permissions/, urls.v4.permissions],
      [/\/?v4\/misc/, urls.v4.miscellaneous],
      [/\/?v4\/portals/, urls.v4.portals],
      [/\/?v4\/publications/, urls.v4.publications],
      [/\/?v4\/companies/, urls.v4.companies],
      [/\/?v4\/configurations/, urls.v4.configurations],
      [/\/?v4\/recommendations/, urls.v4.recommendations],
      [/\/?v4\/dashboards/, urls.v4.dashboards],
      [/\/?v4\/databricks/, urls.v4.databricks],
      [/\/?v4\/drafts/, urls.v4.drafts],
      [/\/?v5\/admin/, urls.v4.sources],
      [/\/?v4\/guardian/, urls.v4.guardian],
      [/\/?v4\/magellan/, urls.v4.magellan],
      [/\/?v4\/tools/, urls.v4.tools],
      [/\/?v4\/statistics-reading/, urls.v4.statistics],
      [/\/?v4\/relationships/, urls.v4.relationships],
      [/\/?v4\/leons/, urls.v4.leons],
      [/\/?v4\/notifications/, urls.v4.notifications],
      [/\/?v4\/snowflake-explore/, urls.v4.snowflakeExplore],
      [/\/?v4\/snowflake/, urls.v4.snowflake],
      [/\/?v4\/data-academy/, urls.v4.academy],
      [/\/?v4\/db-snowflake/, urls.v4.dbSnowflake],
      [/\/?v4\/profile/, urls.v4.profile],
      [/\/?v4\/amundsen/, urls.v4.amundsen],
      [/\/?v4\/papyrus/, urls.v4.papyrus],
      [/\/?v4\/homepage/, urls.v4.homepage],
      [/\/?v4\/news/, urls.v4.news],
      [/\/?v4\/athena/, urls.v4.athena],
      [/\/?v4\/glossary/, urls.v4.glossary],
      [/\/?v4\/cockpit/, urls.v4.cockpit],
      [/\/?v4\/chat/, urls.v4.chat],
      [/\/?v4\/homepage/, urls.v4.homepage],
      [/\/?v4\/news/, urls.v4.news],
      [/\/?v1\/gli/, urls.v4.gli],
    ]);
  }

  // Methods
  findApiUrl(url: string): string | null {
    for (const [regex, api] of this._urlsMap) {
      if (url.match(regex)) {
        return api;
      }
    }

    return null;
  }

  buildUrl(url: string): string {
    const apiBaseUrl = this.findApiUrl(url);

    if (apiBaseUrl != null) {
      return ApiUrlService.dedupSlashes((apiBaseUrl.endsWith('/') ? apiBaseUrl : apiBaseUrl + '/') + url);
    }

    const msg = `Unable to find API base URL for ${url}`;
    this._logger.error(msg);
    throw new Error(msg);
  }

  isServiceAvailable(url: string): boolean {
    return !!this.findApiUrl(url);
  }

  isDataportalBackEndApiUrl(url: string): boolean {
    return this.findApiUrl(url) != null;
  }
}
