import type { AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';
import { Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss'],
})
export class TabComponent implements AfterViewInit, OnChanges {
  @Input() tabTitle: string;
  @Input() tabTooltip: string;
  @Input() active = false;
  @Input() disabled = false;
  @Input() hidden = false;
  @Output() viewInit = new EventEmitter();
  @Output() titleChanged = new EventEmitter<string>();

  constructor(public elem: ElementRef) {}

  ngAfterViewInit() {
    this.viewInit.emit();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.tabTitle) {
      this.titleChanged.emit(this.tabTitle);
    }
  }
}
