import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from '@dataportal/front-api';
import type { IGroupPermissions } from '@dataportal/types';
import { EntityBuilder } from '@decahedron/entity';

import { PermissionsModule } from '../permissions.module';

import { GroupPermission } from '../entities/group-permission';

@Injectable({
  providedIn: PermissionsModule,
})
export class SourceGroupRolesService {
  // Attributes
  private _relatedSourceId: string;

  // Constructor
  constructor(private readonly _apiService: ApiService) {}

  // Methods
  protected buildOne(json: IGroupPermissions): GroupPermission {
    return EntityBuilder.buildOne(GroupPermission, json);
  }

  protected buildMany(json: IGroupPermissions[]): GroupPermission[] {
    return EntityBuilder.buildMany(GroupPermission, json);
  }

  /**
   * Set source to be used when listing user permissions
   * @param sourceId
   */
  setSource(sourceId: string): void {
    this._relatedSourceId = sourceId;
  }

  list(): Observable<GroupPermission[]> {
    return this._apiService
      .get<IGroupPermissions[]>(`v4/admin/v2/sources/${this._relatedSourceId}/permissions/groups`)
      .pipe(
        map((permissions) => {
          return EntityBuilder.buildMany(GroupPermission, permissions);
        }),
      );
  }
}
