import { NgModule } from '@angular/core';

import { WordingPipe } from './wording.pipe';

@NgModule({
  imports: [],
  declarations: [WordingPipe],
  exports: [WordingPipe],
})
export class WordingModule {}
