<adl-title
    [ngClass]="{'w-7/12': showStepper, 'w-full': !showStepper}"
    class="modal-title flex flex-row justify-start items-center text-center pr-20"
    type="modal"
    color="#ffffff"
  >
    <div class="flex flex-row justify-start items-center text-center w-full">
      <adl-image class="mr-6" [src]="iconLink" shape="square" size="50px"></adl-image>
      <div class="flex flex-col justify-start items-start w-full h-full">
        <span class="text-left w-full"> {{ title }}</span>
        <adl-text
          class="subtitle-path text-left leading-none whitespace-normal break-all w-full"
          type="hint"
          color="white"
          >{{ secondaryTitle }}</adl-text
        >
      </div>
    </div>
</adl-title>
<adl-stepper
    *ngIf="showStepper"
    class="modal-steps flex flex-row justify-start items-center w-5/12"
    [steps]="steps"
    [activeStep]="stepLabels[currentStep - 1]"
    [useCurrentActive]="true"
    [circleSize]="'small'"
  >
</adl-stepper>
<ng-content *ngIf="hasSecondaryPart"></ng-content>
