import { Injectable } from '@angular/core';
import { interval, Observable, of, Subject } from 'rxjs';
import { catchError, concatMap, map, takeUntil, tap } from 'rxjs/operators';
import { ApiService } from '@dataportal/front-api';
import { Logger } from '@dataportal/front-shared';

import type { Dashboard } from '../entities/dashboard.model';

// Types
type AASCubeState = 'Paused' | 'Succeeded' | 'Resuming';

// Service
@Injectable()
export class DashboardsCubeService {
  // Constructor
  constructor(private readonly _apiService: ApiService, private readonly _logger: Logger) {}

  // Methods
  getDashboardCube(sourceId: string, cubeUrl: string): Observable<AASCubeState> {
    return this._apiService.get<AASCubeState>(`/v4/dashboards/sources/${sourceId}/dashboard-cube`, {
      params: {
        cubeUrl: encodeURIComponent(cubeUrl),
      },
    });
  }

  startDashboardCube(dashboard: Dashboard, sourceId: string, cubeUrl: string): Observable<boolean> {
    this._logger.info('[DashboardsCubeService] (AAS) Starting the AAS cube', dashboard.aasCubeUrl);

    return this._apiService.post(`/v4/dashboards/sources/${sourceId}/dashboard-cube`, { cubeUrl }).pipe(
      map(() => true),
      catchError((err: unknown) => {
        this._logger.error('[DashboardsCubeService] (AAS) Error starting the dashboard AAS cube', err);

        return of(false);
      }),
    );
  }

  pollCubeState(dashboard: Dashboard, sourceId: string): Observable<AASCubeState> {
    this._logger.info('[DashboardsCubeService] (AAS) Polling cube state');

    return new Observable((subscriber) => {
      const end$ = new Subject();

      interval(10000)
        .pipe(
          concatMap(() => this.getDashboardCube(sourceId, dashboard.aasCubeUrl)),
          catchError((err: unknown) => {
            this._logger.error('[DashboardsCubeService] (AAS) Error getting the dashboard AAS cube', err);

            return of<AASCubeState>('Paused');
          }),
          tap((state) => {
            subscriber.next(state);

            if (state === 'Succeeded') {
              end$.next();
              subscriber.complete();
            }
          }),
          takeUntil(end$),
        )
        .subscribe();
    });
  }
}
