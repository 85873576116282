<div class="source-modal">
  <dpg-source-banner [source]="source" class="source-banner"></dpg-source-banner>
  <div class="source-header p25">
    <div class="source-header--actions" *ngIf="canEdit">
      <a (click)="goToEditionForm()" class="edit df a-center">
        <em class="cogs"></em>
        <span>Edit</span>
      </a>
      <a *ngIf="!isImpersonating" (click)="impersonate()" class="impersonate df a-center">
        <em class="cogs"></em>
        <span>View as</span>
      </a>
    </div>

    <div *ngIf="!canEdit" class="mr15"></div>
    <div>
      <div class="source-title">
        {{ source.name }}
        <adl-star *ngIf="hasAccess" [active]="isFavorite" [size]="28" (clicked)="toggleFavorite()"></adl-star>
      </div>

      <div class="source-categories" [ngClass]="cardColor">
        {{ sourceCategories }}
      </div>
    </div>

    <div class="row-flex">
      <adl-tooltip *ngIf="hasAccess">
        <dpg-recommend-icon
          tooltip-trigger
          *ngIf="!source.isConfidential"
          class="mr15 recommend-icon"
          (click)="openRecommendationModal()"
        ></dpg-recommend-icon>
        <adl-text tooltip-content type="tooltip"> Recommend this {{'dataAsset' | bizWording }} </adl-text>
      </adl-tooltip>
      <adl-icon-button [size]="21" icon="cross" (clicked)="close()"></adl-icon-button>
    </div>
  </div>

  <div class="modal-body pt0">
    <div>
      <div *ngIf="!hasAccess">
        <adl-button *ngIf="!hasAPendingRequest" (clicked)="openAccessModal()" class="row-flex j-center">
          Request access {{ hasAlreadyRequestAccess ? 'again' : '' }}
        </adl-button>
        <adl-button *ngIf="hasAPendingRequest" type="secondary" [disabled]="true" class="row-flex j-center">
          Access has already been requested
        </adl-button>
      </div>
      <dpg-dashboards-list *ngIf="!source.isLimited" [isWithToggleButton]="true" [source]="source" [isSourceOwner]="canEdit"></dpg-dashboards-list>
      <dpg-source-content [source]="source"></dpg-source-content>
    </div>
  </div>
</div>
