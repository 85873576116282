import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import type { IAPITableReferential } from '@dataportal/front-api';
import { APIFetchCachingService, ApiService } from '@dataportal/front-api';
import { AlertService, Logger } from '@dataportal/front-shared';

import type { ISnowflakeDBExtractSettings } from '../entities/snowflake-db';

// Services
@Injectable()
export class DBSnowflakeService extends APIFetchCachingService<ISnowflakeDBExtractSettings> {
  static DEFAULT_TIME_OUT_IN_MINUTES = 60;

  // Constructor
  constructor(readonly apiService: ApiService, readonly logger: Logger, private readonly _alertService: AlertService) {
    super(DBSnowflakeService.DEFAULT_TIME_OUT_IN_MINUTES, apiService, logger);
  }

  private static _buildQueryParameters(settings: ISnowflakeDBExtractSettings): string {
    return Object.keys(settings)
      .filter((key) => settings[key] != null)
      .map(
        (key) =>
          `${key}=${encodeURIComponent(Array.isArray(settings[key]) ? JSON.stringify(settings[key]) : settings[key])}`,
      )
      .join('&');
  }

  static convertTableReferentialToSnowflakeDBExtractSettings(
    tableReferential: IAPITableReferential,
  ): ISnowflakeDBExtractSettings {
    return {
      database: tableReferential?.sql_database,
      schema: tableReferential?.sql_schema,
      table: tableReferential?.sql_table,
      select: [tableReferential?.column],
    } as ISnowflakeDBExtractSettings;
  }

  getExtract(settings: ISnowflakeDBExtractSettings): Observable<unknown[]> {
    return super.getExtract(settings).pipe(
      catchError(() => {
        this.logger.debug('An error occured while fetching snowflake external referentials');
        this._alertService.error(
          `Reference MDH table "${settings?.table}" is malformatted or simply not available anymore`,
          null,
          false,
          10000,
        );

        return [];
      }),
    );
  }

  getColumnExtract(tableReferential: IAPITableReferential, limit?: number): Observable<string[]> {
    const settings = DBSnowflakeService.convertTableReferentialToSnowflakeDBExtractSettings(tableReferential);

    return this.getExtract(settings).pipe(
      map((extract) => {
        const parsedColumn = Object.keys(extract?.length ? extract[0] : {}).find(
          (key) => key.toLowerCase() === tableReferential.column.toLowerCase(),
        );

        return (extract?.length ? (limit !== null && limit !== undefined ? extract.slice(0, limit) : extract) : [])
          .map((extractedRow) => (extractedRow[parsedColumn]?.length ? extractedRow[parsedColumn] : null))
          .filter((extractedValue) => !!extractedValue);
      }),
    );
  }

  cachedKey(settings: ISnowflakeDBExtractSettings): string {
    return this.fetchUrl(settings);
  }

  fetchUrl(settings: ISnowflakeDBExtractSettings): string {
    return `v4/db-snowflake/table/${settings.table}/extract?${DBSnowflakeService._buildQueryParameters(settings)}`;
  }
}
