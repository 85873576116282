<div class="column-flex w100">
  <div
    class="column-flex w100"
    [ngClass]="{
      disabled: isDisabled
    }"
  >
    <div class="row-flex-not-responsive a-center w100">
      <div
        class="row-flex-not-responsive a-center"
        [ngClass]="leftPartWidthClassList"
        [ngStyle]="this.leftPartWidth?.length && { width: this.leftPartWidth }"
      >
        <label
          class="row-flex-not-responsive a-center w100"
          [ngClass]="{ 'j-start': this.hasToAlignLeft, 'j-end': !this.hasToAlignLeft }"
        >
          <adl-text
            [type]="hasError ? 'error' : labelType"
            [bold]="isLabelBold"
            [ngClass]="{ tal: this.hasToAlignLeft, tar: !this.hasToAlignLeft }"
          >
            {{ label }}<sup *ngIf="required != null">*</sup>
          </adl-text>
          <adl-info-bubble class="pl5" *ngIf="hint" [placement]="placement" iconType='primary'>
            {{ hint }}
          </adl-info-bubble>
        </label>
      </div>
      <div
        class="row-flex-not-responsive a-center j-start"
        [ngClass]="rightPartWidthClassList"
        [ngStyle]="this.rightPartWidth?.length && { width: this.rightPartWidth }"
      >
        <ng-content></ng-content>
      </div>
    </div>
    <div class="row-flex-not-responsive a-center w100">
      <div
        class="row-flex-not-responsive a-center j-end"
        [ngClass]="leftPartWidthClassList"
        [ngStyle]="this.leftPartWidth?.length && { width: this.leftPartWidth }"
      ></div>
      <div
        class="row-flex-not-responsive a-center j-start"
        [ngClass]="rightPartWidthClassList"
        [ngStyle]="this.rightPartWidth?.length && { width: this.rightPartWidth }"
      >
        <div class="column-flex a-start w100" *ngIf="hasError && messages.length">
          <adl-text *ngFor="let msg of messages" color="red" class="w100 tal">{{ msg }}</adl-text>
        </div>
      </div>
    </div>
  </div>
</div>
