<ng-select
  [bindLabel]="label"
  [bindValue]="value"
  [placeholder]="placeholder"
  [typeToSearchText]="typeToSearchText"
  [items]="items"
  [loading]="loading"
  [virtualScroll]="true"
  (clear)="cleared.emit()"
  (change)="changed.emit($event)"
  (search)="search.emit($event)"
  [typeahead]="searchSubject"
  (scrollToEnd)="scrollToEnd.emit()"
  class='w100'
>
</ng-select>
