import type { OnChanges, OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'adl-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit, OnChanges {
  @Input() headerBackgroundColor = null;
  @Input() backgroundColor = 'white';
  @Input() hasCloseButton = true;
  @Input() closeButtonColor = 'black';
  @Input() textAlignment: 'left' | 'center' | 'right' = 'center';
  @Input() isAlignedToCross = false;
  @Input() hasMarginBottom = true;
  @Input() hasBodySidesPadding = true;
  @Input() bodySidesPadding: 'normal' | 'small' = 'normal';
  @Input() isFullWidth = false;
  @Input() roundCorner = true;
  @Input() closeValue = undefined;

  classList: string[] = [];

  constructor(private readonly _activeMatModal: MatDialogRef<ModalComponent>) {}

  ngOnInit(): void {
    this._refreshClassList();
  }

  ngOnChanges(): void {
    this._refreshClassList();
  }

  get headerBackgroundColorClassName(): string {
    return `header-color-${this.headerBackgroundColor}`;
  }

  get backgroundColorName(): string {
    return `bg-color-${this.backgroundColor}`;
  }

  get closeButtonColorClassName(): string {
    return `close-button-color-${this.closeButtonColor}`;
  }

  private _refreshClassList(): void {
    this.classList = [];

    if (this.backgroundColor?.length) {
      this.classList.push(this.backgroundColorName);
    }
  }

  close(): void {
    this._activeMatModal.close(this.closeValue);
  }
}
