import type { OnInit } from '@angular/core';
import { Component, Inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import type { Observable } from 'rxjs';
import { of, Subject } from 'rxjs';
import { first, switchMap, takeUntil } from 'rxjs/operators';
import { CurrentUserService } from '@dataportal/auth';
import { EnvironmentService } from '@dataportal/front-environment';
import { Logger } from '@dataportal/front-shared';
import {
  defaultNavbarLogos,
  ILayoutOptions,
  LAYOUT_OPTIONS,
  LayoutComponent,
  NavbarItemV2,
  otherNavbarLogos,
} from '@dataportal/layout';
import { PermissionsService } from '@dataportal/permissions';
import type { Tool } from '@dataportal/tools';
import { ToolsService } from '@dataportal/tools';
import type { User } from '@dataportal/users';

@Component({
  selector: 'accor-layout',
  templateUrl: './layout-accor.component.html',
  styleUrls: ['./layout-accor.component.scss'],
})
export class LayoutAccorComponent extends LayoutComponent implements OnInit {
  private _favoritesToolsId: Array<string> = [];
  items: NavbarItemV2[] = [];

  favorites: Array<NavbarItemV2> = [];
  isAdmin$: Observable<boolean>;
  favorite: string;
  user: User = null;
  notificationsNamesToWatchForAdmin = ['accessRequestsNotification', 'publicationsNotification'];
  favoritesTools: Array<Tool> = [];
  private readonly _destroyed$ = new Subject<void>();

  constructor(
    router: Router,
    permissionsService: PermissionsService,
    _currentUserService: CurrentUserService,
    readonly logger: Logger,
    public environment: EnvironmentService,
    private readonly _toolsService: ToolsService,
    private readonly _sanitizer: DomSanitizer,
    @Inject(LAYOUT_OPTIONS) private readonly _options: ILayoutOptions,
  ) {
    super(router, permissionsService, _currentUserService, logger);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this._buildItems();
    this._buildFavorites();
  }

  get canSeeBackOffice(): boolean {
    for (const value in this.permissions) {
      if (this.permissions[value]) {
        return true;
      }
    }

    return false;
  }

  private _buildItems() {
    this.isAdmin$ = this._currentUserService.isAdmin$;

    this.items = defaultNavbarLogos;
  }

  private _buildFavorites() {
    this._currentUserService.currentUser$
      .pipe(
        first((user) => user != null),
        switchMap((user) => this._toolsService.listFavorites(user.id)),
        takeUntil(this._destroyed$),
      )
      .subscribe();
    this._toolsService.favoriteTools$.pipe(takeUntil(this._destroyed$)).subscribe(async (favoritesTools) => {
      this._favoritesToolsId = favoritesTools;
      this.favorites = this._favoritesToolsId.map((toolId) => {
        return otherNavbarLogos.has(toolId)
          ? otherNavbarLogos.get(toolId)
          : new NavbarItemV2(
              toolId,
              toolId,
              '/',
              of(true),
              `<img alt="unknown-navbar" src="assets/bar-item-icons/company.svg" width="45" height="45">`,
              false,
            );
      });
    });
  }
}
