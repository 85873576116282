<adl-sidebar>
  <ng-container *ngFor="let item of items">
    <dpg-navbar-item [item]="item" [external]="external"></dpg-navbar-item>
  </ng-container>
  <li *ngIf="favorites?.length">
    <div class="favorites-delimiter">My favorites</div>
  </li>
  <ng-container *ngFor="let item of favorites">
    <dpg-navbar-item [item]="item" [external]="external" [ngClass]="{'favorite-logo': environmentService?.options.dpContext.code === 'PR'}"></dpg-navbar-item>
  </ng-container>
  <dpg-admin-button [external]="external" [isVisible]="canSeeAdmin"></dpg-admin-button>
  <ng-container *ngIf="canSeeLogout">
    <dpg-navbar-item [item]="logoutItem" [external]="false"></dpg-navbar-item>
  </ng-container>
</adl-sidebar>
