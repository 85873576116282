import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { BehaviorSubject, of, throwError } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';
import { ApiService } from '@dataportal/front-api';
import { Logger } from '@dataportal/front-shared';
import { WebsocketsService } from '@dataportal/websocket';

import type {
  AdminNotificationsList,
  IAdminNotification,
  IAdminNotificationEvent,
  IAdminNotificationSubjectAndObservable,
} from '../entities/notifications';

@Injectable({ providedIn: 'root' })
export class NotificationsService {
  private _currentNotifications: AdminNotificationsList;
  private _notificationsEvents: IAdminNotificationEvent[] = [];

  constructor(
    private readonly _websocketsService: WebsocketsService,
    private readonly _apiService: ApiService,
    private readonly _logger: Logger,
  ) {}

  private _setNotificationsEvents(notificationsEvent: IAdminNotificationEvent[]): void {
    this._notificationsEvents = [];
    this._notificationsEvents.push(...notificationsEvent);
  }

  private _initCurrentNotifications(): void {
    this._currentNotifications = new Map<string, IAdminNotificationSubjectAndObservable>(
      this._notificationsEvents.map((notificationEvent) => {
        const subject$ = new BehaviorSubject<number>(0);

        return [
          notificationEvent.name,
          {
            subject$: subject$,
            observable$: subject$.asObservable(),
          },
        ];
      }),
    );
  }

  private _subscribeToWSEvents(): void {
    this._notificationsEvents.forEach((notificationEvent) => {
      notificationEvent.event$
        .pipe(takeUntil(notificationEvent.subscribeUntil$))
        .subscribe((notificationResult: { nbNotifications: number }) => {
          this._logger.debug(
            `[NotificationsService] WS notification result is '${notificationResult}' for event : '${notificationEvent.name}'`,
          );

          if (this._currentNotifications.has(notificationEvent.name)) {
            this._currentNotifications.get(notificationEvent.name).subject$.next(notificationResult.nbNotifications);
          }
        });
    });
  }

  setConfiguration(notificationsEvent: IAdminNotificationEvent[]): void {
    this._logger.debug(
      `[NotificationsService] Init configuration for notifications service and subscribing to WS events`,
    );
    // register configuration
    this._setNotificationsEvents(notificationsEvent);
    this._initCurrentNotifications();
    // subscribe to events
    this._subscribeToWSEvents();
  }

  getCurrentNotification(notificationName: string): Observable<IAdminNotification> {
    const notificationToReturn: IAdminNotification = { name: notificationName, total: 0 };

    if (!this._currentNotifications?.get(notificationName)) {
      return of(notificationToReturn);
    }

    return this._currentNotifications?.get(notificationName).observable$.pipe(
      map((total) => {
        notificationToReturn.total = total;

        return notificationToReturn;
      }),
    );
  }

  fetchCurrentUserNotifications(): Observable<unknown> {
    return this._apiService.post(`/v4/notifications`, {}).pipe(
      catchError((err: unknown) => {
        this._logger.error(`[NotificationsService] Error while fetching current user notifications : ${err}`);

        return throwError(err);
      }),
    );
  }
}
