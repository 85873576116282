import type { OnInit } from '@angular/core';
import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { filter, switchMap } from 'rxjs/operators';
import { CurrentUserService } from '@dataportal/auth';
import { Logger } from '@dataportal/front-shared';
import { PermissionsService } from '@dataportal/permissions';
import type { User } from '@dataportal/users';

@Component({
  selector: 'dpg-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit {
  @Input() external = false;
  @Output() sidebarToggled = new EventEmitter<boolean>();
  @HostBinding('class.show-sidebar')
  showSidebar = false;
  permissions: { [name: string]: boolean } = {};
  user: User;

  constructor(
    protected readonly _router: Router,
    public permissionsService: PermissionsService,
    protected readonly _currentUserService: CurrentUserService,
    private readonly _logger: Logger,
  ) {}

  ngOnInit(): void {
    this._currentUserService.currentUser$
      .pipe(
        filter((currentUser) => !!currentUser),
        switchMap(() => this.permissionsService.permissionsAdmin$),
      )
      .subscribe((permissions) => {
        this.permissions = permissions;
      });
  }

  closeSidebar() {
    this.showSidebar = false;
    this.sidebarToggled.emit(false);
  }

  openSideBar() {
    this.showSidebar = true;
    this.sidebarToggled.emit(true);
  }
}
