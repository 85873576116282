import { Component, Input } from '@angular/core';
import { Source } from '@dataportal/sources-dashboards-recommendations';
import { USER_PREFERENCES, UserPreferencesService } from '@dataportal/users';

const DP_SOURCE_BANNERS_CLOSING_TTL_IN_DAYS = 2;
const NB_MS_IN_ONE_DAY = 24 * 60 * 60 * 1000;

interface IClosedSourcesBanners {
  [sourceId: string]: {
    message: string;
    timestamp: string;
  };
}

@Component({
  selector: 'dpg-source-banner',
  templateUrl: './source-banner.component.html',
  styleUrls: ['./source-banner.component.scss'],
})
export class SourceBannerComponent {
  @Input() source: Source;

  colorsBanner = [
    { label: 'info', color: '#3e94d3' },
    { label: 'success', color: '#77dd77' },
    { label: 'warning', color: '#ffb347' },
    { label: 'error', color: '#ff6961' },
  ];

  constructor(private readonly _userPreferences: UserPreferencesService) {}

  get alreadyClosedBanners(): IClosedSourcesBanners {
    const alreadyClosedBannersFetched = this._userPreferences.getUserPreferenceValue(
      USER_PREFERENCES.DP_SOURCE_BANNERS_CLOSED,
    );

    return (
      alreadyClosedBannersFetched?.length ? JSON.parse(alreadyClosedBannersFetched) : {}
    ) as IClosedSourcesBanners;
  }

  get labelColor(): string {
    if (this.source && this.source.banner) {
      return this.colorsBanner.find((el) => el.color === this.source?.banner?.color).label;
    }

    return null;
  }

  get shouldBeVisible(): boolean {
    if (this.source && this.source.banner) {
      const isAfterStartDate =
        !this.source.banner.startDate || Date.now() > new Date(this.source.banner.startDate).valueOf();
      const isBeforeEndDate =
        !this.source.banner.endDate || Date.now() < new Date(this.source.banner.endDate).valueOf();
      const isInTimeWindow = isAfterStartDate && isBeforeEndDate;
      const currentDate = new Date().getTime();

      return isInTimeWindow;
    }

    return false;
  }
}
