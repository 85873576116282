import { Component, Input } from '@angular/core';
import { Source } from '@dataportal/sources-dashboards-recommendations';

import { CatalogV2NavigationService } from '../../../services/v2/navigation.service';

@Component({
  selector: 'dpg-catalog-source-label',
  templateUrl: './catalog-source-label.component.html',
  styleUrls: ['./catalog-source-label.component.scss'],
})
export class CatalogSourceLabelComponent {
  @Input() source: Source;
  @Input() searchMode: 'advanced' | 'simple';

  constructor(private readonly _navigationService: CatalogV2NavigationService) {}

  clickSuggestResult(source: Source) {
    this._navigationService.openDataAssetDetails(source, '', false);
  }
}
