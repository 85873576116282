import { Inject, Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { of } from 'rxjs';
import { ApiService } from '@dataportal/front-api';
import { ISharedOptions, SHARED_OPTIONS } from '@dataportal/front-shared';

import { LogsModule } from '../logs.module';

// Types
interface ILogEvent {
  action: 'event';
  [key: string]: string | number;
}

interface ILogPageView {
  action: 'page_view';
  current_url: string;
  previous_url: string;
}

export type LogBody = ILogEvent | ILogPageView;

// Service
@Injectable({
  providedIn: LogsModule,
})
export class LogsService {
  // Constructor
  constructor(
    private readonly _apiService: ApiService,
    @Inject(SHARED_OPTIONS) private readonly _options: ISharedOptions,
  ) {}

  // Methods
  post(body: LogBody): Observable<void> {
    if (this._options.environment !== 'local') {
      return this._apiService.post('/v4/misc/logs', body, { errorHandling: { level: 'silent' } });
    } else {
      return of();
    }
  }
}
