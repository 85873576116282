import type { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Logger } from '@dataportal/front-shared';
import { v4 as uuid } from 'uuid';

// Types
export interface IErrorHandlingOptions {
  level: 'silent' | 'warning' | 'error' | 'info';
  message?: string;
  // FIXME: Type the expected callback better
  // eslint-disable-next-line @typescript-eslint/ban-types
  callback?: Function;
}

export interface IComplexErrorHandlingOptions {
  [code: number]: IErrorHandlingOptions;
  '*'?: IErrorHandlingOptions;
}

export type ErrorHandlingOptions = IErrorHandlingOptions | IComplexErrorHandlingOptions;

// Utils
function isSimpleOptions(opts: ErrorHandlingOptions): opts is IErrorHandlingOptions {
  return 'level' in opts;
}

// Services
@Injectable({
  providedIn: 'root',
})
export class ErrorHandlingService {
  // Attributes
  private readonly _register = new Map<string, ErrorHandlingOptions>();

  // Constructor
  constructor(private readonly logger: Logger) {}

  // Methods
  registerErrorHandling(options: ErrorHandlingOptions): string {
    const handlingId = uuid();
    this._register.set(handlingId, options);
    this.logger.debug('[Error Handling] Registred options', handlingId, options);

    return handlingId;
  }

  getErrorHandling(handlingId: string, response: HttpErrorResponse): IErrorHandlingOptions {
    if (!this._register.has(handlingId)) {
      this.logger.warn('[Error Handling] Cannot find error handling registration', handlingId);

      return null;
    }

    const options = this._register.get(handlingId);
    this._register.delete(handlingId);

    // simple case
    if (isSimpleOptions(options)) {
      return options;
    }

    // complex case
    const hasWildcard = '*' in options;
    const hasStatus = response.status in options;

    if (!hasWildcard && !hasStatus) {
      return null;
    }

    return hasStatus ? options[response.status] : options['*'];
  }
}
