<adl-page-header pageName="Databricks" pageDescription="Access your Databricks workspaces"></adl-page-header>

<div class="databricks-wrapper p-5 flex flex-col">
  <adl-text class="mb-4">
    Databricks is our new solution to build and schedule data pipelines for data engineers and data scientists. If
    you were using the legacy notebooks on the Accor Data Portal and you can't find it anymore, contact the
    <a href="mailto:data.support@pernod-ricard.com"><strong>support</strong></a>.
  </adl-text>
  <div *ngIf="hasDatabricksWorkspaces() && !loading; else hasLoading">
    <div class="panel panel-data">
      <div class="w-full">
        <div>
          <input
            type="text"
            class="w-full mb-2 databricks-page__workspaces_list__search_bar"
            placeholder="Search workspace by name"
            [(ngModel)]="searchText"
            (ngModelChange)="search()"
          />
        </div>
        <div class="tab-map tac table-responsive">
          <table aria-label="Databricks workspaces tables" class="w-full table table-front table-source">
            <thead class="thead-default">
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Created at</th>
              <th scope="col">Action</th>
            </tr>
            </thead>
            <tbody>
            <tr
              *ngFor="let databrickWorkspace of filteredDatabricksWorkspaces"
              (click)="openDatabricksWorkspace(databrickWorkspace)"
            >
              <td>{{ databrickWorkspace.name }}</td>
              <td>{{ databrickWorkspace.createdAt | date: 'dd/MM/yyyy HH:mm:SS' }}</td>
              <td>{{ databrickWorkspace.url }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <ng-template #hasLoading>
    <div *ngIf="loading; else hasNoDatabricksWorkspaces" class="">
      <div class="panel panel-data flex justify-center">
        <h3 style="text-align: center">
          <img src="assets/dpg/imgs/loading.gif" style="width: 100px" alt="loading" />
        </h3>
      </div>
    </div>
  </ng-template>
  <ng-template #hasNoDatabricksWorkspaces>
      <div class="panel panel-data flex justify-center">
        <h3 style="text-align: center">
          You do not have access to the databricks section. Please request access through
          <a target="_blank" href="https://pernod-ricard.atlassian.net/servicedesk/customer/portal/9/create/770">
            Data Support
          </a>
        </h3>
    </div>
  </ng-template>
</div>
