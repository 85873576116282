import { Inject, Injectable } from '@angular/core';
import type { CanActivate, CanLoad } from '@angular/router';
import { Router } from '@angular/router';
import type { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PermissionsModule } from '../permissions.module';

import { PermissionsService } from '../services/permissions.service';

import { IPermissionsOptions, PERMISSIONS_OPTIONS } from '../permissions-options';
import type { Permissions } from '../services/permissions.service';

// Guard
@Injectable({
  providedIn: PermissionsModule,
})
export class SourceOrLeonOwnerGuard implements CanActivate, CanLoad {
  // Constructor
  constructor(
    private readonly _permissionsService: PermissionsService,
    private readonly _router: Router,
    @Inject(PERMISSIONS_OPTIONS) private readonly _options: IPermissionsOptions,
  ) {}

  private _isSourceOrLeonOwner(): Observable<boolean> {
    let userPermissions: Permissions;

    return this._permissionsService.permissionsAdmin$.pipe(
      map((permissions: Permissions) => {
        userPermissions = permissions;

        if (userPermissions && (userPermissions.sources || userPermissions.leons)) {
          return true;
        }

        this._router.navigate(this._options.errorRouterLink);

        return false;
      }),
    );
  }

  // Methods
  canActivate(): Observable<boolean> {
    return this._isSourceOrLeonOwner();
  }

  canLoad(): Observable<boolean> {
    return this._isSourceOrLeonOwner();
  }
}
