<div
  [class]="'adl-input-text-area ' + variant"
  [ngClass]="{ errored: !control?.valid && control?.dirty, succeeded: succeeded,  'border': !noBorder }"
>
  <textarea
    #nameInput
    [ngClass]="{ 'disabled': isDisabled, 'resize-disabled': isResizeDisabled }"
    [attr.disabled]="isDisabled ? '' : null"
    [style.padding-left]="horizontalPadding"
    [style.padding-right]="horizontalPadding"
    [style.padding-top]="verticalPadding"
    [style.padding-bottom]="verticalPadding"
    [style.min-height]="fixedHeight"
    [style.height]="fixedHeight"
    [style.max-height]="maxHeight"
    [ngStyle]="{ width: this.fixedWidth || '100%' }"
    [formControl]="control"
    [placeholder]="placeholder"
    [readonly]="readonly"
    [value]="value"
  ></textarea>
</div>
