import { NgModule } from '@angular/core';

import { AnimatedCardDirective } from './animated-card.directive';
import { AnimatedCardListDirective } from './animated-card-list.directive';
import { CopyClipboardDirective } from './copy-clipboard.directive';
import { IsOverflowingDirective } from './is-overflowing.directive';

@NgModule({
  declarations: [AnimatedCardListDirective, AnimatedCardDirective, CopyClipboardDirective, IsOverflowingDirective],
  exports: [AnimatedCardListDirective, AnimatedCardDirective, CopyClipboardDirective, IsOverflowingDirective],
})
export class DirectivesModule {}
