<adl-modal
  class="stepper-modal"
  [isAlignedToCross]="true"
  [hasMarginBottom]="false"
  [hasBodySidesPadding]="false"
  [roundCorner]="true"
  headerBackgroundColor="blue-dark"
  backgroundColor="light"
  closeButtonColor="white"
>
  <adl-logo-modal-header
    modal-header
    class="w-full"
    hasStepper="false"
    [title]="title"
    [iconLink]="iconLink"
  ></adl-logo-modal-header>

  <div modal-body>
    <adl-text>Your entire workspace is about to be reset. All blocks will disappear.</adl-text>

    <div class="stepper-modal-buttons row-flex-not-responsive justify-center items-center mt-[20px]">
      <adl-button type="primary-dark" class="mr-4" (clicked)="closeModal(true)"> Confirm </adl-button>
      <adl-button (clicked)="closeModal(false)" type="dark-cancel"> Cancel </adl-button>
    </div>
  </div>
</adl-modal>
