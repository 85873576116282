import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { DialogsService } from '@dataportal/adl';
import { Navigator } from '@dataportal/navigator';

import { Source } from '../../../sources/entities/source';

@Component({
  selector: 'dpg-dashboard-new-tab',
  templateUrl: './dashboard-new-tab.component.html',
  styleUrls: ['./dashboard-new-tab.component.scss'],
})
export class DashboardNewTabComponent {
  // Attributes
  @Input() source: Source;
  @Input() dashboardName: string;
  @Input() color: 'main' | 'bg' = 'bg';
  @Input() isOwner? = false;

  // Constructor
  constructor(
    private readonly _router: Router,
    private readonly _navigator: Navigator,
    private readonly _dialogsService: DialogsService,
  ) {}

  // Methods
  openDashboardInNewTab(name: string): void {
    const dashboard = this.source.powerbi.find((dash) => dash.name === name);
    const urlToOpen = dashboard.buildUrl(this.source.id, name, this._navigator, this._router, true);

    if (dashboard.isExternal) {
      const dashboardMaintenanceMessage = dashboard?.maintenanceMessage;

      if (dashboardMaintenanceMessage && !this.isOwner) {
        this._dialogsService.info({ message: '', innerHTML: dashboardMaintenanceMessage });
      } else {
        window.open(urlToOpen, '_blank');
      }
    } else {
      window.open(urlToOpen, '_blank');
    }
  }
}
