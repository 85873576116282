<adl-tooltip placement="bottom" backgroundColor="blue-dark" arrowColor="blue-dark" class="import-button">
  <adl-text tooltip-content type="accent" [bold]="false" size="12" class="tac" [color]="'white'"
  >This imports the specs from the JSON file except for the check’s title {{ checkType === 'datalakePath' ? 'and target folders': '' }}</adl-text
  >
  <adl-upload-button
    tooltip-trigger
    type="guardian-light"
    size="normal"
    [multiple]="false"
    [isReadyToBrowse$]="this.isReadyToImport$"
    (clicked)="this.onClickImportButton()"
    (changed)="this.onImportFile($event)"
    [isSquareButton]="true"
  >
    <div class="row-flex w100">
      <img alt="upload img"
           src="assets/dpg/imgs/commons/upload.svg"
           class="mr-4"
      >Import configuration
    </div></adl-upload-button
  >
</adl-tooltip>
<div class="guardian--step-check-basic-infos column-flex w-full mb-[10px] pr-[50px]" *ngIf="this.formGroup">
  <app-guardian-form-control
    class="w100"
    label="Check title"
    [control]="this.formGroup.get('name')"
    [errorMessages]="{ checkNameRequiredError: 'Required', checkNameAlreadyTakenError: 'Check name already exist' }"
    hint="Short name, unique to this source, to facilitate the identification of this specification"
    required
  >
    <adl-input
      class="row-flex-not-responsive j-start w45"
      fixedSize="10px"
      [control]="this.formGroup.get('name')"
      placeholder="E.g. My check"
      data-cy="guardian-creation-check-title"
    ></adl-input>
  </app-guardian-form-control>
  <app-guardian-form-control
    *ngIf="false"
    class="w100"
    label="What you want to check ?"
    [control]="this.formGroup.get('checkType')"
    hint="File content : Guardian will check the fields corresponding to the set-up check / File modification : Guardian will check if the file has been correctly uploaded to the datalake"
    spaceBetween="pl10"
    isDisabled="true"
  >
    <adl-card-selector
      class="row-flex-not-responsive j-start w100"
      [control]="this.formGroup.get('checkType')"
      [choices]="this.checkTypes"
      [height]="40"
    ></adl-card-selector>
  </app-guardian-form-control>
  <app-guardian-form-control
    class="w100"
    *ngIf="this.formGroup.get('fileFormat')"
    label="Select your file format"
    required
    [control]="this.formGroup.get('fileFormat')"
  >
    <adl-select
      class="row-flex-not-responsive j-start w20"
      [options]="this.fileFormats"
      [control]="this.formGroup.get('fileFormat')"
      [defaultValue]="this.formGroup.get('fileFormat')?.value"
      data-cy="guardian-creation-check-format"
    ></adl-select>
  </app-guardian-form-control>
  <app-guardian-form-control
    *ngIf="this.hasSelectedXlsx"
    class="w100"
    label="Name of your sheet ?"
    [control]="this.formGroup.get('sheetName')"
    [errorMessages]="{ sheetNameRequired: 'Required' }"
    required
  >
    <adl-input
      class="row-flex-not-responsive j-start w45"
      fixedSize="10px"
      [control]="this.formGroup.get('sheetName')"
      placeholder="E.g. MySheet"
    ></adl-input>
  </app-guardian-form-control>
  <app-guardian-form-control
    *ngIf="this.hasSelectedCsv"
    class="w100"
    label="Select your delimiter"
    required
    [control]="this.formGroup.get('fileDelimiter')"
  >
    <adl-select
      class="row-flex-not-responsive j-start w40"
      [options]="this.fileDelimiters"
      [control]="this.formGroup.get('fileDelimiter')"
      [defaultValue]="this.formGroup.get('fileDelimiter')?.value"
    ></adl-select>
  </app-guardian-form-control>
  <app-guardian-form-control
    *ngIf="this.hasSelectedCsv"
    class="w100"
    label="Select your decimal separator"
    required
    [control]="this.formGroup.get('decimalSeparator')"
    [errorMessages]="{
      fileDelimiterAndDecimalSeparatorMustBeDifferent: 'File delimiter and decimal separator must be different.'
    }"
    [relatedControlsToCheck]="[this.formGroup.get('fileDelimiter')]"
  >
    <adl-select
      class="row-flex-not-responsive j-start w35"
      [options]="this.decimalSeparators"
      [control]="this.formGroup.get('decimalSeparator')"
      [defaultValue]="this.formGroup.get('decimalSeparator')?.value"
    ></adl-select>
  </app-guardian-form-control>
  <app-guardian-form-control
    *ngIf="this.formGroup.get('isFilteringFilesForCheck')"
    class="w100"
    [ngClass]="{ mb0: this.hasSelectedFilteringFiles }"
    label="Check only for specific files ?"
    [control]="this.formGroup.get('isFilteringFilesForCheck')"
    hint="If yes, you will be able to specify a regular expression indicating which files will trigger the following check"
  >
    <div class="row-flex-not-responsive a-center j-start w100">
      <adl-switch class="w10" [control]="this.formGroup.get('isFilteringFilesForCheck')"></adl-switch>
      <app-guardian-form-control
        *ngIf="this.hasSelectedFilteringFiles && this.isUsingCustomRegex"
        class="regex w90"
        label="Filtering regex"
        [control]="this.formGroup.get('filteringRegex')"
        hint="Enter (or select) a regular expression in order to format the requested check for this field (You can validate
        your regex at: https://regex101.com/)"
        required
        leftPartWidthClass="w40"
        rightPartWidthClass="w60"
      >
        <adl-input
          [control]="this.formGroup.get('filteringRegex')"
          class="w100"
          placeholder="E.g. /^[^\/.]+.csv$/"
        ></adl-input>
      </app-guardian-form-control>
      <app-guardian-form-control
        *ngIf="this.hasSelectedFilteringFiles && !this.isUsingCustomRegex && this.preparedRegexValues?.length"
        class="regex w90"
        label="Filtering regex"
        [control]="this.formGroup.get('filteringRegex')"
        hint="Enter (or select) a regular expression in order to format the requested check for this field (You can validate
        your regex at: https://regex101.com/)"
        required
        leftPartWidthClass="w40"
        rightPartWidthClass="w60"
      >
        <adl-select
          [options]="this.preparedRegexValues"
          [control]="this.formGroup.get('filteringRegex')"
          [isUsingFirstValueAsDefault]="true"
          fixedSize="100%"
          class="w100"
        ></adl-select>
      </app-guardian-form-control>
    </div>
  </app-guardian-form-control>
  <app-guardian-form-control
    *ngIf="this.formGroup.get('isFlatfileDeactivate')"
    class="w100"
    [ngClass]="{ mb0: true }"
    label="Uploaded file above 100 MB"
    [control]="this.formGroup.get('isFlatfileDeactivate')"
  >
    <div class="row-flex-not-responsive a-center j-start w100">
      <adl-switch class="w10" [control]="this.formGroup.get('isFlatfileDeactivate')"></adl-switch>
    </div>
  </app-guardian-form-control>
  <adl-banner *ngIf='this.hasSelectedFileOver100MB' [closable]='false' class="!ml-60" labelColor='warning' iconBanner='assets/imgs/alert-warning.svg'
              message="Warning: this option deactivates the instant analysis and correction Guardian feature. The file will be analyzed after having been uploaded to the datalake. Toggle this option only when the uploaded files are above the 100 MB threshold.">
  </adl-banner>
  <div *ngIf="this.hasSelectedFilteringFiles && this.isUsingCustomRegex" class="row-flex-not-responsive w100 mt5">
    <div class="w35"></div>
    <div class="row-flex-not-responsive j-start w65 pl20">
      <div class="w10"></div>
      <div class="row-flex-not-responsive j-start w90">
        <div class="row-flex-not-responsive j-end w40">
          <adl-text
            *ngIf="this.hasToShowRegexRequiredError || this.hasToShowInvalidRegexError"
            color="red"
            class="w100 tar"
            >{{
              this.hasToShowRegexRequiredError ? 'Required' : this.hasToShowInvalidRegexError ? 'Invalid' : ''
            }}</adl-text>
        </div>
        <div class="row-flex-not-responsive j-start w60 pl20">
          <a class="regex w100 tal" (click)="this.togglePreparedRegex()">Use prepared regex</a>
        </div>
      </div>
    </div>
  </div>
  <div
    *ngIf="this.hasSelectedFilteringFiles && !this.isUsingCustomRegex && this.preparedRegexValues?.length"
    class="row-flex-not-responsive w100 mt5"
  >
    <div class="w35"></div>
    <div class="row-flex-not-responsive j-start w65 pl20">
      <div class="w10"></div>
      <div class="row-flex-not-responsive j-start w90">
        <div class="row-flex-not-responsive j-end w40"></div>
        <div class="row-flex-not-responsive j-start w60 pl20">
          <a class="regex w100 tal" (click)="this.togglePreparedRegex()">Customize your Regex</a>
        </div>
      </div>
    </div>
  </div>
  <app-guardian-form-control
    [control]="this.formGroup.get('alertRecipients')"
    class="w100 mb0"
    label="Add an alert recipient"
    hint="The chosen user(s) will receive every mail if Guardian has some information to share about this check"
  >
    <div class="row-flex-not-responsive j-start w100">
      <div class="column-flex w100">
        <div class="row-flex-not-responsive j-start w100">
          <dpg-user-select
            class="w80"
            [predicates]="limitedUserPredicates"
            (selected)="selectedLimitedUser = $event"
            [clear]="clearSelectList"
            data-cy="guardian-creation-check-recipient"
          ></dpg-user-select>
          <div class="row-flex-not-responsive j-end w20">
            <adl-button (clicked)="this.addUserToList()" data-cy="guardian-creation-check-recipient-add">Add</adl-button>
          </div>
        </div>
      </div>
    </div>
  </app-guardian-form-control>
  <div class="row-flex-not-responsive w100 mt5">
    <div class="w35"></div>
    <div class="row-flex-not-responsive justify-start w65 pl20">
      <adl-text *ngIf="!this.potentialAlertRecipients.length" class="w100 tal">
        No alert recipient has been added yet.</adl-text
      >
      <div class="row-flex-not-responsive j-start w100">
        <div class="column-flex w100" *ngIf="this.potentialAlertRecipients.length">
          <adl-picture-with-toggles
            class="row-flex-not-responsive j-start a-center w100 mt10"
            *ngFor="let alertRecipient of this.potentialAlertRecipients"
            [areTogglesDownSide]="false"
            [element]="alertRecipient"
            [isLoadingPicture]="this.isFetchingProfiles && !alertRecipient.profile"
            [pictureSrc]="alertRecipient.profile?.picture"
            [title]="alertRecipient.profile?.name"
            [subTitle]="alertRecipient.userId"
            [togglesOptions]="[
              {
                onChanged: toggleOnSuccess,
                isActive: alertRecipient.isAlertedOnSuccess,
                label: 'On success'
              },
              {
                onChanged: toggleOnFailure,
                isActive: alertRecipient.isAlertedOnFailure,
                label: 'On failure'
              }
            ]"
            (onClickRemoveElement)="this.removePotentialAlertRecipient($event)"
            [attr.data-cy]="'guardian-creation-check-recipient-'+alertRecipient.profile?.email"
          ></adl-picture-with-toggles>
        </div>
      </div>
    </div>
  </div>
</div>
