import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import type { Observable } from 'rxjs';
import { BehaviorSubject, of, Subject, throwError } from 'rxjs';
import { catchError, concatMap, map, mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';
import { AmundsenService } from '@dataportal/amundsen';
import { DatalakeApiService, GuardianCurrentUserService } from '@dataportal/datalake-and-guardian';
import type {
  APIReferential,
  ErrorHandlingOptions,
  IAPIGuardianCallError,
  IAPITableReferential,
  ICheckRegex,
  IExternalReferential,
  IGuardianAPIChecksMap,
  IGuardianAPIRequestCheckFieldOptions,
  IRequestCheckFieldOptions,
  IRequestCheckFieldOptionsCommon,
} from '@dataportal/front-api';
import { ApiService } from '@dataportal/front-api';
import { IS_ACCOR } from '@dataportal/front-environment';
import { AlertService, DatalakePath, DatalakeService, Logger } from '@dataportal/front-shared';
import { CheckRegex } from '@dataportal/guardian-utils';
import { WebsocketsService } from '@dataportal/websocket';
import { EntityBuilder } from '@decahedron/entity';

// FIXME: [guardian] import from @dataportal/accor-types after publishing
import type { IAPIRunCheckResponse, IBaseGuardianCheck, IGuardianChecksRelatedToSource } from '../entities/guardian';
import { GuardianCheck } from '../entities/guardian.check';
import type {
  ColumnGlobalType,
  ColumnTypeMap,
  DatalakeColumnType,
  DividerOfNumberOfMonths,
  FullHour,
  GuardianCheckConstraint,
  Hour,
  HourPeriod,
  IColumnField,
  IDatasetMappingWithAzureStatus,
  IGuardianCheckScheduling,
  IGuardianChecksDatasetMap,
  IGuardianDatalakePath,
  IGuardianForm,
  IGuardianFormData,
  IGuardianStatus,
  ISnowflakeTableInfo,
  ISupportedGuardianChecksResource,
  SchedulingPeriodicity,
  WeekDay,
  YearMonth,
} from '../entities/guardian-form';
import {
  CronParts,
  datalakeColumnTypes,
  SchedulingDailyChoices,
  snowflakeColumnTypes,
} from '../entities/guardian-form';

// TODO: should be in @dataportal/types
// Types
export interface IEntityGuardianStatus {
  isCheckedByGuardian: boolean;
  lastCheck?: GuardianCheck;
}

export interface IBucket {
  name: string;
  category: 'landing' | 'raw' | 'project' | 'business-other';
  primary?: boolean;
  sources?: boolean;
}

export type CurrentDataset = ISnowflakeTableInfo | IDatalakeCurrentDirectory;

export interface IDatalakeCurrentDirectory {
  bucket: IBucket;
  path: string;
  fullPath: string;
  breadcrumbs: string[];
  provider?: 'aws' | 'azure';
  tenant?: string;
  byUser?: boolean;
  doNotBuildTreeHierarchy?: boolean;
  type?: 'datalakePath';
}

const RESPONSE_STATUS_CODE = [200, 201, 202];

// Service
@Injectable({
  providedIn: 'root',
})
export class GuardianService {
  /* Attributes */

  // Utils values
  static ALL_CHECK_CONSTRAINTS_LIST: Array<{ label: string; value: GuardianCheckConstraint; tooltip?: string }> = [
    { label: 'TYPE', value: 'type' },
    {
      label: 'MUST BE FILLED',
      value: 'isRequired',
      tooltip:
        "If 'must be filled' field is selected and set to 'yes', there must not be any cell value inside this column that is empty.",
    },
    { label: 'UNIQUE', value: 'isUnique' },
    { label: 'MINIMUM', value: 'minValue' },
    { label: 'MAXIMUM', value: 'maxValue' },
    {
      label: 'GREATER THAN',
      value: 'greaterThan',
      tooltip: 'Compare the value of a column with another of the same type.',
    },
    {
      label: 'REGEX',
      value: 'regex',
      tooltip:
        'Enter (or select) a regular expression (regex) in order to format the requested check for this field' +
        ' (You can validate your regex at https://regex101.com).',
    },
    {
      label: 'MASTER DATA HUB',
      value: 'mdh',
      tooltip:
        'You can select one of the MASTER DATA HUB Snowflake table. This will check that every cell value inside' +
        ' this column is contained inside the first column (txt_label) of the selected table.',
    },
    {
      label: 'EXCEL/CSV WHITELIST',
      value: 'datalake_in',
      tooltip:
        'You can select an EXCEL or CSV file located in the Datalake. This will check that every cell value inside' +
        ' this column is contained inside the first column of the selected file.',
    },
    {
      label: 'EXCEL/CSV BLACKLIST',
      value: 'datalake_not_in',
      tooltip:
        'You can select an EXCEL or CSV file located in the Datalake. This will check that every cell value inside' +
        ' this column is not contained inside the first column of the selected file.',
    },
  ];
  static SCIENTIFIC_NOTATION_REGEX = '\\b-?[1-9](?:\\.\\d+)?[Ee][-+]?\\d+\\b';
  static DEFAULT_DECIMAL_SEPARATORS_XLSX = [',', '.'];
  static NUMBER_REGEX = '^[-]?[0-9]+$';
  static DATE_REGEX = '^[0-9]{4}-[0-9]{1,2}-[0-9]{1,2}$';
  static NUMERIC_TYPES = [
    'integer',
    'float',
    'datetime',
    'BIGINT',
    'DECIMAL',
    'FLOAT',
    'INT',
    'INTEGER',
    'NUMERIC',
    'REAL',
    'SMALLINT',
    'TINYINT',
  ];
  static TEXT_TYPES = ['string', 'BINARY', 'CHAR', 'NCHAR', 'NVARCHAR', 'NTEXT', 'TEXT', 'VARBINARY', 'VARCHAR'];
  static FORBIDDEN_CONSTRAINTS_WHEN_NUMERIC_FIELD: GuardianCheckConstraint[] = [
    'regex',
    'mdh',
    'datalake_in',
    'datalake_not_in',
  ];
  static FORBIDDEN_CONSTRAINTS_WHEN_STRING_FIELD: GuardianCheckConstraint[] = ['minValue', 'maxValue', 'greaterThan'];
  schedulingPeriodicityIndex: Array<{ label: string; value: SchedulingPeriodicity }> = [
    { label: 'Daily', value: 'daily' },
    { label: 'Weekly', value: 'weekly' },
    { label: 'Monthly', value: 'monthly' },
    { label: 'Yearly', value: 'yearly' },
  ];
  hoursMap: Array<{ label: string; value: Hour }> = [
    { label: '1', value: '1' },
    { label: '2', value: '2' },
    { label: '3', value: '3' },
    { label: '4', value: '4' },
    { label: '5', value: '5' },
    { label: '6', value: '6' },
    { label: '7', value: '7' },
    { label: '8', value: '8' },
    { label: '9', value: '9' },
    { label: '10', value: '10' },
    { label: '11', value: '11' },
    { label: '12', value: '12' },
  ];
  hourPeriodsMap: Array<{ label: string; value: HourPeriod }> = [
    { label: 'am', value: 'am' },
    { label: 'pm', value: 'pm' },
  ];

  monthNumbersIndex: Array<{ label: string; value: DividerOfNumberOfMonths }> = [
    { label: '1', value: '1' },
    { label: '2', value: '2' },
    { label: '3', value: '3' },
    { label: '4', value: '4' },
    { label: '6', value: '6' },
  ];
  weekDaysIndex: Array<{ label: string; value: WeekDay }> = [
    { label: 'monday', value: 'mon' },
    { label: 'tuesday', value: 'tue' },
    { label: 'wednesday', value: 'wed' },
    { label: 'thursday', value: 'thu' },
    { label: 'friday', value: 'fri' },
    { label: 'saturday', value: 'sat' },
    { label: 'sunday', value: 'sun' },
  ];
  yearMonthsIndex: Array<{ label: string; value: YearMonth }> = [
    { label: 'january', value: 'jan' },
    { label: 'february', value: 'feb' },
    { label: 'march', value: 'mar' },
    { label: 'april', value: 'apr' },
    { label: 'may', value: 'may' },
    { label: 'june', value: 'jun' },
    { label: 'july', value: 'jul' },
    { label: 'august', value: 'aug' },
    { label: 'september', value: 'sep' },
    { label: 'october', value: 'oct' },
    { label: 'november', value: 'nov' },
    { label: 'december', value: 'dec' },
  ];

  // Guardian check
  private readonly _lastCheck$: BehaviorSubject<IEntityGuardianStatus> = new BehaviorSubject<IEntityGuardianStatus>({
    isCheckedByGuardian: false,
  });
  private readonly _checks$: BehaviorSubject<GuardianCheck[]> = new BehaviorSubject<GuardianCheck[]>([]);
  private readonly _currentGuardianStatus$: BehaviorSubject<IGuardianStatus> = new BehaviorSubject<IGuardianStatus>(
    null,
  );
  private readonly _currentChanged$ = new Subject<void>();
  private readonly _hasToShowModal$ = new Subject<void>();

  private _currentDataset: CurrentDataset;
  private _checkId: number;
  sourceId: string;

  // Guardian check regex
  private readonly _fieldCheckRegexList$ = new BehaviorSubject<Array<CheckRegex>>([]);
  private readonly _nameFilterCheckRegexList$ = new BehaviorSubject<Array<CheckRegex>>([]);
  private readonly _renamingFileCheckRegexList$ = new BehaviorSubject<Array<CheckRegex>>([]);

  // Guardian Check regex
  fieldCheckRegexList$ = this._fieldCheckRegexList$.asObservable();
  nameFilterCheckRegexList$ = this._nameFilterCheckRegexList$.asObservable();
  renamingFileCheckRegexList$ = this._renamingFileCheckRegexList$.asObservable();

  // Guardian Form associate with data asset form
  private readonly _checksToRemoveOnSave = new BehaviorSubject<Array<IBaseGuardianCheck>>([]);
  checksToRemoveOnSave$ = this._checksToRemoveOnSave.asObservable();

  // Guardian external referential
  private readonly _externalReferentialsList$ = new BehaviorSubject<Array<IExternalReferential>>([]);

  // Guardian external referential
  externalReferentialsList$ = this._externalReferentialsList$.asObservable();

  // Guardian
  lastCheck$ = this._lastCheck$.asObservable();
  checks$ = this._checks$.asObservable();
  currentGuardianStatus$ = this._currentGuardianStatus$.asObservable();
  hasToShowModal$ = this._hasToShowModal$.asObservable();

  // Constant
  fileNameRenamingFormat = '{filename}_{Y}-{m}-{d}_{H}-{M}-{S}';

  // Constructor
  constructor(
    private readonly _apiService: ApiService,
    private readonly _logger: Logger,
    private readonly _alert: AlertService,
    private readonly _websocket: WebsocketsService,
    private readonly _guardianCurrentUserService: GuardianCurrentUserService,
    private readonly _amundsenService: AmundsenService,
    private readonly _datalakeApiService: DatalakeApiService,
    private readonly _route: ActivatedRoute,
    private readonly _router: Router,
    @Inject(IS_ACCOR) private readonly _isAccor: boolean,
  ) {
    /**
     * We use websocket to follow up the state of the last run check and to indicate the user when the check has finished to run
     */
    this._websocket.hasCheckFinished$
      .pipe(
        concatMap(() => this._websocket.refreshGuardianChecks$),
        concatMap((checkRequest) => {
          this._logger.info('[GuardianService] Received web socket event', checkRequest);

          if (checkRequest && checkRequest.id_check === this._checkId) {
            this._logger.info('[GuardianService] Websocket update is on current directory, refreshing list');
            let dataToCheck;
            dataToCheck = checkRequest.resource === 'snowflake' ? this._currentDataset : null;

            return this.listChecksByDataset(checkRequest.resource, this.sourceId, dataToCheck);
          }

          return of(null);
        }),
      )
      .subscribe((guardianStatus) => {
        if (
          guardianStatus &&
          guardianStatus.checkId === this._checkId &&
          this._lastCheck$?.getValue()?.lastCheck?.status !== 'in_progress'
        ) {
          this._hasToShowModal$.next();
        }
      });

    this._websocket.guardianMessageStatus$.subscribe((messageStatus) => {
      if (messageStatus?.status === 'error') {
        const guardianError = messageStatus.error;
        const firstPartErrorMessage = 'Cannot request check';
        this.displayFormattedErrorMessage(firstPartErrorMessage, guardianError, false);
      } else if (messageStatus?.status === 'success') {
        this._alert.success(messageStatus.message, false, 5000);
      }
    });

    // ACCOR - NO GUARDIAN
    if (!this._isAccor) {
      this.listAllCheckRegex().subscribe();
    }
  }

  private static _cleanDatalakePath(path: string): string {
    return path?.length ? path.replace(/\/+/, '/').replace(/^\//, '').replace(/\/$/, '') : '';
  }

  private _getDatalakePathOptions(datalakePath?: IGuardianDatalakePath): IGuardianDatalakePath {
    const currentDatalakePathDataset = this._currentDataset as IDatalakeCurrentDirectory;
    const cleanedPath = GuardianService._cleanDatalakePath(datalakePath?.path || '');
    const bucketNameToCheck = datalakePath ? datalakePath.filesystem : currentDatalakePathDataset.bucket?.name;
    const tenantToCheck = datalakePath ? datalakePath.tenant : currentDatalakePathDataset.tenant;
    const providerToCheck = datalakePath ? datalakePath.provider : currentDatalakePathDataset.provider;
    const pathToCheck = GuardianService._cleanDatalakePath(
      datalakePath ? cleanedPath : currentDatalakePathDataset.path,
    );
    const isValidDatalakePath = bucketNameToCheck && tenantToCheck && pathToCheck;

    return isValidDatalakePath
      ? { filesystem: bucketNameToCheck, path: pathToCheck, provider: providerToCheck, tenant: tenantToCheck }
      : null;
  }

  /* Guardian checks */

  // Utils methods

  static isTextField(field: IRequestCheckFieldOptions): boolean {
    return field?.type?.length && GuardianService.TEXT_TYPES.includes(field.type as DatalakeColumnType);
  }

  static isNumericField(field: IRequestCheckFieldOptions): boolean {
    return field?.type?.length && GuardianService.NUMERIC_TYPES.includes(field.type as DatalakeColumnType);
  }

  static calculateFieldGlobalTypeFromColumnFieldForm(columnField: IColumnField): ColumnGlobalType {
    if (columnField.type?.length) {
      return GuardianService.NUMERIC_TYPES.includes(columnField.type)
        ? 'numeric'
        : GuardianService.TEXT_TYPES.includes(columnField.type)
        ? 'text'
        : 'unknown';
    }

    if (columnField.addedConstraints?.length) {
      return columnField.addedConstraints.some((addedConstraint) =>
        GuardianService.FORBIDDEN_CONSTRAINTS_WHEN_STRING_FIELD.includes(addedConstraint),
      )
        ? 'numeric'
        : columnField.addedConstraints.some((addedConstraint) =>
            GuardianService.FORBIDDEN_CONSTRAINTS_WHEN_NUMERIC_FIELD.includes(addedConstraint),
          )
        ? 'text'
        : 'unknown';
    }

    return 'unknown';
  }

  convertInputRegexToRegex(inputRegex: string): string {
    if (!inputRegex.length) {
      return '';
    } else if (
      inputRegex.length === 1 ||
      inputRegex.charAt(0) !== '/' ||
      inputRegex.charAt(inputRegex.length - 1) !== '/'
    ) {
      return null;
    }

    const inputRegexWithoutFirstAndEndSlashes = inputRegex?.substring(1, inputRegex.length - 1);

    try {
      new RegExp(inputRegexWithoutFirstAndEndSlashes);

      return inputRegexWithoutFirstAndEndSlashes;
    } catch (e) {
      return null;
    }
  }

  private static _getLocalHourOffsetInHour(): number {
    return Math.floor(new Date().getTimezoneOffset() / 60);
  }

  private static _parseHour(hour: Hour): number {
    if (!hour) {
      return -1;
    }

    const parsedHour = parseInt(hour, 10);

    return !isNaN(parsedHour) && parsedHour >= 0 && parsedHour < 24 ? parsedHour : -1;
  }

  static calculateUTCZeroHourFromFormLocalHour(formHour: Hour): Hour {
    const formHourParsed = GuardianService._parseHour(formHour);

    return formHourParsed >= 0
      ? (((formHourParsed + GuardianService._getLocalHourOffsetInHour()) % 24).toString() as Hour)
      : null;
  }

  static calculateFormLocalHourFromUTCZeroHour(utcZeroHour: Hour): Hour {
    const utcZeroHourParsed = GuardianService._parseHour(utcZeroHour);

    return utcZeroHourParsed >= 0
      ? (((utcZeroHourParsed - GuardianService._getLocalHourOffsetInHour()) % 24).toString() as Hour)
      : null;
  }

  static convertHourAndHourPeriodToFullHour(hour: Hour, hourPeriod: HourPeriod): FullHour {
    const emptyResult = '' as FullHour;

    if (!hour?.length || !hourPeriod?.length) {
      return emptyResult;
    }

    const hourNumber = parseInt(hour, 10);

    if (hourNumber < 0 || hourNumber > 12) {
      return emptyResult;
    }

    const hourPeriodOffset = hourPeriod === 'am' ? '0' : '12';
    const hourPeriodOffsetNumber = parseInt(hourPeriodOffset, 10);

    return hourNumber !== 12 ? ((hourNumber + hourPeriodOffsetNumber).toString() as FullHour) : hourPeriodOffset;
  }

  static convertFullHourToHourAndHourPeriod(fullHour: FullHour): { hour: Hour; hourPeriod: HourPeriod } {
    const emptyResult = { hour: '' as Hour, hourPeriod: '' as HourPeriod };

    if (!fullHour?.length) {
      return emptyResult;
    }

    const fullHourNumber = parseInt(fullHour, 10);

    if (fullHourNumber < 0 || fullHourNumber > 23) {
      return emptyResult;
    }

    const hourPeriod = fullHourNumber < 12 ? 'am' : 'pm';
    const hourPeriodOffsetNumber = hourPeriod === 'am' ? 0 : 12;

    return {
      hour: (fullHourNumber !== 0 && fullHourNumber !== 12
        ? fullHourNumber - hourPeriodOffsetNumber
        : 12
      ).toString() as Hour,
      hourPeriod: hourPeriod,
    };
  }

  static extractNumberFromCronEveryNumber(everyNumberPart: string): string {
    return everyNumberPart?.length ? everyNumberPart.replace('*/', '') : null;
  }

  static extractFromCron(schedulingIntervalCron: string, part: CronParts): string {
    const cronSegments = schedulingIntervalCron?.length ? schedulingIntervalCron.split(' ') : [];

    return cronSegments?.length === 5 ? cronSegments[part].toString().toLowerCase() : null;
  }

  static convertToSchedulingInfos(schedulingIntervalCron: string): IGuardianCheckScheduling {
    if (!schedulingIntervalCron?.length) {
      return null;
    }

    const schedulingIntervalCronParts = schedulingIntervalCron.split(' ');

    if (schedulingIntervalCronParts.length !== 5) {
      return null;
    }

    const schedulingInfos = {} as IGuardianCheckScheduling;
    const schedulingStartingHourAndHourPeriod = GuardianService.convertFullHourToHourAndHourPeriod(
      schedulingIntervalCronParts[CronParts.HOURS] as FullHour,
    );
    schedulingInfos.schedulingStartingTime = {
      schedulingStartingHour: schedulingStartingHourAndHourPeriod.hour,
      schedulingStartingMinute: parseInt(schedulingIntervalCronParts[CronParts.MINUTES], 10),
      schedulingStartingHourPeriod: schedulingStartingHourAndHourPeriod.hourPeriod,
    };

    if (schedulingIntervalCronParts[CronParts.MONTH] === '*') {
      // daily or weekly
      if (
        schedulingIntervalCronParts[CronParts.DAY_NAME] === '*' ||
        schedulingIntervalCronParts[CronParts.DAY_NAME] === 'mon-fri'
      ) {
        schedulingInfos.schedulingPeriodicity = 'daily';
        schedulingInfos.schedulingDailyChoiceSelected =
          schedulingIntervalCronParts[CronParts.DAY_NAME] === '*'
            ? SchedulingDailyChoices.EVERY_N_DAYS
            : SchedulingDailyChoices.EVERY_WORKING_DAY;
      } else {
        schedulingInfos.schedulingPeriodicity = 'weekly';
      }
    } else {
      // monthly or yearly
      schedulingInfos.schedulingPeriodicity = schedulingIntervalCronParts[CronParts.MONTH].startsWith('*/')
        ? 'monthly'
        : 'yearly';
    }

    const selectedDaysNames = schedulingIntervalCronParts[CronParts.DAY_NAME];

    switch (schedulingInfos.schedulingPeriodicity) {
      case 'daily':
        if (schedulingInfos.schedulingDailyChoiceSelected === SchedulingDailyChoices.EVERY_N_DAYS) {
          schedulingInfos.schedulingDailyEveryNumberOfDays = parseInt(
            GuardianService.extractNumberFromCronEveryNumber(schedulingIntervalCronParts[CronParts.DAY_NUMBER]),
            10,
          );
        }

        break;
      case 'weekly':
        schedulingInfos.schedulingWeeklySelectedDays = selectedDaysNames?.length
          ? (selectedDaysNames.split(',') as WeekDay[])
          : null;
        break;
      case 'monthly':
        schedulingInfos.schedulingMonthlyDayNumber = parseInt(schedulingIntervalCronParts[CronParts.DAY_NUMBER], 10);
        schedulingInfos.schedulingMonthlyEveryNumberOfMonths = GuardianService.extractNumberFromCronEveryNumber(
          schedulingIntervalCronParts[CronParts.MONTH],
        ) as DividerOfNumberOfMonths;
        break;
      case 'yearly':
        schedulingInfos.schedulingYearlyDayNumber = parseInt(schedulingIntervalCronParts[CronParts.DAY_NUMBER], 10);
        schedulingInfos.schedulingYearlySelectedMonth = schedulingIntervalCronParts[CronParts.MONTH] as YearMonth;
        break;
      default:
        break;
    }

    return schedulingInfos;
  }

  static convertToCron(schedulingInfos: IGuardianCheckScheduling): string {
    const fullHour = GuardianService.convertHourAndHourPeriodToFullHour(
      schedulingInfos.schedulingStartingTime.schedulingStartingHour,
      schedulingInfos.schedulingStartingTime.schedulingStartingHourPeriod,
    );
    // MIN  H  *  *  *
    const formattedCronParts = [
      schedulingInfos.schedulingStartingTime.schedulingStartingMinute,
      fullHour,
      '*',
      '*',
      '*',
    ];

    switch (schedulingInfos?.schedulingPeriodicity) {
      case 'daily':
        switch (schedulingInfos.schedulingDailyChoiceSelected) {
          case SchedulingDailyChoices.EVERY_N_DAYS:
            // MIN  H  */D  *  *
            formattedCronParts[CronParts.DAY_NUMBER] = `*/${schedulingInfos.schedulingDailyEveryNumberOfDays}`;
            break;
          case SchedulingDailyChoices.EVERY_WORKING_DAY:
            // MIN  H  *  *  mon-fri
            formattedCronParts[CronParts.DAY_NAME] = 'mon-fri';
            break;
          default:
            return null;
        }

        break;
      case 'weekly':
        // MIN  H  *  *  DN1,DN2...
        formattedCronParts[CronParts.DAY_NAME] = schedulingInfos.schedulingWeeklySelectedDays?.join(',');
        break;
      case 'monthly':
        // MIN  H  D  */M  *
        formattedCronParts[CronParts.DAY_NUMBER] = `${schedulingInfos.schedulingMonthlyDayNumber}`;
        formattedCronParts[CronParts.MONTH] = `*/${schedulingInfos.schedulingMonthlyEveryNumberOfMonths}`;
        break;
      case 'yearly':
        // MIN  H  D  MN  *
        formattedCronParts[CronParts.DAY_NUMBER] = `${schedulingInfos.schedulingYearlyDayNumber}`;
        formattedCronParts[CronParts.MONTH] = schedulingInfos.schedulingYearlySelectedMonth;
        break;
      default:
        return null;
    }

    return formattedCronParts.join(' ');
  }

  static convertGuardianStringArrayToArray(str: string): string[] {
    if (!str?.length) {
      return [];
    }

    return str.charAt(0) === '[' && str.charAt(str.length - 1) === ']'
      ? str.substring(1, str.length - 1).split(',')
      : [str];
  }

  static convertArrayToGuardianStringArray(array: string[]): string {
    if (!array?.length) {
      return '';
    }

    return `[${array.join(',')}]`;
  }

  static isGuardianError(error: unknown): boolean {
    return !!error && Object.keys(error).includes('guardianError');
  }

  static get allCheckConstraintsList(): GuardianCheckConstraint[] {
    return GuardianService.ALL_CHECK_CONSTRAINTS_LIST.map((constraintEntry) => constraintEntry.value);
  }

  private static getConstraintInMapFromConstraint(constraint: GuardianCheckConstraint): {
    label: string;
    value: GuardianCheckConstraint;
    tooltip?: string;
  } {
    const constraintFetched = GuardianService.ALL_CHECK_CONSTRAINTS_LIST.filter(
      (constraintEntry) => constraintEntry.value === constraint,
    );

    return constraintFetched.length ? constraintFetched[0] : null;
  }

  static getConstraintLabelFromConstraint(constraint: GuardianCheckConstraint): string {
    const constraintFetched = GuardianService.getConstraintInMapFromConstraint(constraint);

    return constraintFetched ? constraintFetched.label : null;
  }

  static getConstraintTooltipFromConstraint(constraint: GuardianCheckConstraint): string {
    const constraintFetched = GuardianService.getConstraintInMapFromConstraint(constraint);

    return constraintFetched ? (constraintFetched.tooltip?.length ? constraintFetched.tooltip : null) : null;
  }

  static getContraintsMapObjectFromConstraintsList(
    constraintList: GuardianCheckConstraint[],
  ): Array<{ label: string; value: GuardianCheckConstraint; tooltip?: string; isDisabled?: boolean }> {
    return GuardianService.ALL_CHECK_CONSTRAINTS_LIST.filter((constraintEntry) =>
      constraintList.includes(constraintEntry.value),
    );
  }

  private static _getRemainingConstraintsPartInAllConstraints(
    firstList: GuardianCheckConstraint[],
    secondList: GuardianCheckConstraint[],
  ): GuardianCheckConstraint[] {
    return GuardianService.allCheckConstraintsList.filter(
      (constraint) => !firstList.includes(constraint) && !secondList.includes(constraint),
    );
  }

  private static _getAvailableConstraintsFromAddedAndForbiddenConstraints(
    addedConstraints: GuardianCheckConstraint[],
    forbiddenConstraints: GuardianCheckConstraint[],
  ): GuardianCheckConstraint[] {
    return GuardianService._getRemainingConstraintsPartInAllConstraints(addedConstraints, forbiddenConstraints);
  }

  static getForbiddenConstraintsFromAddedAndAvailableConstraints(
    addedConstraints: GuardianCheckConstraint[],
    availableConstraints: GuardianCheckConstraint[],
  ): GuardianCheckConstraint[] {
    return GuardianService._getRemainingConstraintsPartInAllConstraints(addedConstraints, availableConstraints);
  }

  static getAddedConstraintsFromCurrentFieldCheckSchema(
    fieldCheckSchema: IRequestCheckFieldOptionsCommon,
  ): GuardianCheckConstraint[] {
    if (!fieldCheckSchema) {
      return [];
    }

    const addedConstraints = [] as GuardianCheckConstraint[];

    if (fieldCheckSchema.type?.length) {
      addedConstraints.push('type');
    }

    if (fieldCheckSchema.required_values !== null && fieldCheckSchema.required_values !== undefined) {
      addedConstraints.push('isRequired');
    }

    if (fieldCheckSchema.unique !== null && fieldCheckSchema.unique !== undefined) {
      addedConstraints.push('isUnique');
    }

    if (fieldCheckSchema.min !== null && fieldCheckSchema.min !== undefined) {
      addedConstraints.push('minValue');
    }

    if (fieldCheckSchema.max !== null && fieldCheckSchema.max !== undefined) {
      addedConstraints.push('maxValue');
    }

    if (fieldCheckSchema.pair_greater?.length) {
      addedConstraints.push('greaterThan');
    }

    if (fieldCheckSchema.regex?.length) {
      addedConstraints.push('regex');
    }

    if (fieldCheckSchema.values_in && GuardianService.isSnowflakeReferential(fieldCheckSchema.values_in)) {
      addedConstraints.push('mdh');
    }

    if (fieldCheckSchema.values_in && GuardianService.isDatalakeReferential(fieldCheckSchema.values_in)) {
      addedConstraints.push('datalake_in');
    }

    if (fieldCheckSchema.values_not_in && GuardianService.isDatalakeReferential(fieldCheckSchema.values_not_in)) {
      addedConstraints.push('datalake_not_in');
    }

    return addedConstraints;
  }

  private static _getForbiddenConstraintsFromType<R extends ISupportedGuardianChecksResource>(
    typeConstraint: ColumnTypeMap[R],
  ): GuardianCheckConstraint[] {
    const forbiddenConstraints = [] as GuardianCheckConstraint[];

    if (!typeConstraint?.length) {
      return forbiddenConstraints;
    }

    switch (typeConstraint) {
      case 'integer':
      case 'float':
      case 'datetime':
      case 'BIGINT':
      case 'DECIMAL':
      case 'FLOAT':
      case 'INT':
      case 'INTEGER':
      case 'NUMERIC':
      case 'REAL':
      case 'SMALLINT':
      case 'TINYINT':
      case 'DATE':
      case 'DATETIME':
      case 'TIME':
      case 'TIMESTAMP_NTZ':
      case 'TIMESTAMP':
        forbiddenConstraints.push(...GuardianService.FORBIDDEN_CONSTRAINTS_WHEN_NUMERIC_FIELD);
        break;
      case 'string':
      case 'BINARY':
      case 'CHAR':
      case 'NCHAR':
      case 'NVARCHAR':
      case 'NTEXT':
      case 'TEXT':
      case 'VARBINARY':
      case 'VARCHAR':
        forbiddenConstraints.push(...GuardianService.FORBIDDEN_CONSTRAINTS_WHEN_STRING_FIELD);
        break;
      case 'BOOLEAN':
      case 'ARRAY':
      case 'OBJECT':
      case 'VARIANT':
        forbiddenConstraints.push(...GuardianService.FORBIDDEN_CONSTRAINTS_WHEN_NUMERIC_FIELD);
        forbiddenConstraints.push(...GuardianService.FORBIDDEN_CONSTRAINTS_WHEN_STRING_FIELD);
        break;
      default:
        break;
    }

    return forbiddenConstraints;
  }

  private static _getForbiddenExternalReferentialConstraint(
    addedConstraints: GuardianCheckConstraint[],
  ): GuardianCheckConstraint[] {
    const datalakeConstraints = ['datalake_in', 'datalake_not_in'] as GuardianCheckConstraint[];

    if (addedConstraints.includes('mdh')) {
      return datalakeConstraints;
    } else if (addedConstraints.some((constraint) => datalakeConstraints.includes(constraint))) {
      return ['mdh'];
    } else {
      return [];
    }
  }

  /**
   * Constraints rules :
   * - Type is not require
   * - If type is integer, float or datetime, only min/max constraints are available
   * - If type is string, only regex/MDH/datalake constraints are available
   * - If no type is precised, every constraints are available
   * - Once MDH or datalake constraint has been specified, the other constraint can not be specified
   * - We can combine regex constraint with MDH or datalake constraints
   * @param addedConstraints
   * @param typeConstraint
   * @private
   */
  private static _getForbiddenConstraintsFromAddedConstraints<R extends ISupportedGuardianChecksResource>(
    addedConstraints: GuardianCheckConstraint[],
    typeConstraint: ColumnTypeMap[R],
  ): GuardianCheckConstraint[] {
    const forbiddenConstraints = GuardianService._getForbiddenConstraintsFromType(typeConstraint);

    if (
      addedConstraints.includes('regex') ||
      addedConstraints.includes('mdh') ||
      addedConstraints.includes('datalake_in') ||
      addedConstraints.includes('datalake_not_in')
    ) {
      forbiddenConstraints.push(...GuardianService.FORBIDDEN_CONSTRAINTS_WHEN_STRING_FIELD);
      forbiddenConstraints.push(...GuardianService._getForbiddenExternalReferentialConstraint(addedConstraints));
    }

    if (
      addedConstraints.includes('minValue') ||
      addedConstraints.includes('maxValue') ||
      addedConstraints.includes('greaterThan')
    ) {
      forbiddenConstraints.push(...GuardianService.FORBIDDEN_CONSTRAINTS_WHEN_NUMERIC_FIELD);
    }

    return forbiddenConstraints;
  }

  static removeForbiddenConstraintsInAddedConstraints<R extends ISupportedGuardianChecksResource>(
    addedConstraints: GuardianCheckConstraint[],
    typeConstraint: ColumnTypeMap[R],
  ): GuardianCheckConstraint[] {
    const forbiddenConstraints = GuardianService._getForbiddenConstraintsFromAddedConstraints(
      addedConstraints,
      typeConstraint,
    );

    return addedConstraints.filter((constraint) => !forbiddenConstraints.includes(constraint));
  }

  static getAvailableConstraintsFromAddedConstraints<R extends ISupportedGuardianChecksResource>(
    addedConstraints: GuardianCheckConstraint[],
    typeConstraint: ColumnTypeMap[R],
  ): GuardianCheckConstraint[] {
    const forbiddenConstraints = GuardianService._getForbiddenConstraintsFromAddedConstraints(
      addedConstraints,
      typeConstraint,
    );

    return GuardianService._getAvailableConstraintsFromAddedAndForbiddenConstraints(
      addedConstraints,
      forbiddenConstraints,
    );
  }

  getTypeToDisplay(type: string, resource: ISupportedGuardianChecksResource): string {
    if (!type?.length) {
      return null;
    }

    const columnTypes = resource === 'datalakePath' ? datalakeColumnTypes : snowflakeColumnTypes;
    const typeFetched = columnTypes.filter((typeEntry) => typeEntry.value === type);

    return typeFetched.length ? typeFetched[0].label : 'Unknown';
  }

  getTypeFromTypeToDisplay(typeToDisplay: string, resource: ISupportedGuardianChecksResource): string {
    if (!typeToDisplay?.length || typeToDisplay === 'Unknown') {
      return null;
    }

    const columnTypes = resource === 'datalakePath' ? datalakeColumnTypes : snowflakeColumnTypes;
    const typeFetched = columnTypes.filter((typeEntry) => typeEntry.label === typeToDisplay);

    return typeFetched.length ? typeFetched[0].value : null;
  }

  static getDelimiterToDisplay(delimiter: string): string {
    switch (delimiter) {
      case '\t':
        return '\\t';
      case ' ':
        return 'space';
      default:
        return delimiter;
    }
  }

  static getDelimiterFromDelimiterToDisplay(delimiterToDisplay: string): string {
    if (!delimiterToDisplay?.length) {
      return null;
    }

    switch (delimiterToDisplay) {
      case '\\t':
        return '\t';
      case 'space':
        return ' ';
      default:
        return delimiterToDisplay;
    }
  }

  static getFormattedErrorMessage(guardianError: IAPIGuardianCallError): string {
    const detailedError = guardianError?.message;
    const formattedDetailedError = `Technical details : ${detailedError}`;
    const errorType = guardianError?.type;

    if (!detailedError?.length && !errorType?.length) {
      return 'Unknown error';
    }

    let errorMessage = '';

    switch (errorType) {
      case 'GuardianNoFileFoundError':
        errorMessage = 'FILE TO CHECK NOT FOUND IN THE DATALAKE PATH';
        break;
      case 'GuardianEmptyFileError':
        errorMessage = 'FILE TO CHECK IS EMPTY';
        break;
      case 'GuardianReadFileError':
        errorMessage = 'FILE TO CHECK HAS SOME INCONSISTENCIES IN TYPE/FORMAT/EXTENSION OR ENCODING OR DELIMITER';
        break;
      case 'GuardianSchemaError':
        errorMessage = '[INTERNAL ERROR] CHECK CONFIGURATION HAS SOME INCONSISTENCIES';
        break;
      default:
        break;
    }

    return errorMessage?.length ? `${errorMessage} (${formattedDetailedError})` : formattedDetailedError;
  }

  displayFormattedErrorMessage(firstPartErrorMessage: string, _error: unknown, hasToCheckError = true): void {
    // TODO: [Revisit] (18.01.2024) - Refactor type
    const error = _error as { error: { data: unknown } };
    const errorFetched = hasToCheckError
      ? error
        ? Object.keys(error).includes('error')
          ? Object.keys(error.error).includes('data')
            ? error.error.data
            : null
          : null
        : null
      : error;
    // TODO: [Revisit] (18.01.2024) - Check types
    const secondPartErrorMessage = hasToCheckError
      ? GuardianService.isGuardianError(errorFetched)
        ? GuardianService.getFormattedErrorMessage(
            (errorFetched as { guardianError: IAPIGuardianCallError }).guardianError,
          )
        : 'Internal Server Error'
      : GuardianService.getFormattedErrorMessage(errorFetched);
    const errorMessage = `${firstPartErrorMessage} - ${secondPartErrorMessage}`;
    this._logger.error(`[GuardianService] ${errorMessage} : `, error);
    this._alert.error(errorMessage);
  }

  getPeriodicityLabelFromValue(periodicityValue: SchedulingPeriodicity): string {
    if (!periodicityValue?.length) {
      return '';
    }

    const periodicityFetched = this.schedulingPeriodicityIndex.filter(
      (periodicityEntry) => periodicityEntry.value === periodicityFetched,
    );

    return periodicityFetched.length ? periodicityFetched[0].label : '';
  }

  getPeriodicityValueFromLabel(periodicityLabel: string): SchedulingPeriodicity {
    if (!periodicityLabel?.length) {
      return null;
    }

    const periodicityFetched = this.schedulingPeriodicityIndex.filter(
      (periodicityEntry) => periodicityEntry.label === periodicityLabel,
    );

    return periodicityFetched.length ? periodicityFetched[0].value : null;
  }

  convertWeekdayFromCronToWeekday(weekdayFromCron: WeekDay): string {
    if (!weekdayFromCron?.length) {
      return '';
    }

    const weekdayFetched = this.weekDaysIndex.filter((weekdayEntry) => weekdayEntry.value === weekdayFromCron);

    return weekdayFetched.length ? weekdayFetched[0].label : '';
  }

  convertWeekdayFromWeekdayToCron(weekdayLabel: string): WeekDay {
    if (!weekdayLabel?.length) {
      return null;
    }

    const weekdayFetched = this.weekDaysIndex.filter((weekdayEntry) => weekdayEntry.label === weekdayLabel);

    return weekdayFetched.length ? weekdayFetched[0].value : null;
  }

  convertYearMonthFromCronToYearMonth(yearMonthFromCron: YearMonth): string {
    if (!yearMonthFromCron?.length) {
      return '';
    }

    const yearMonthFetched = this.yearMonthsIndex.filter(
      (yearMonthEntry) => yearMonthEntry.value === yearMonthFromCron,
    );

    return yearMonthFetched.length ? yearMonthFetched[0].label : '';
  }

  convertYearMonthFromYearMonthToCron(yearMonthLabel: string): YearMonth {
    if (!yearMonthLabel?.length) {
      return null;
    }

    const yearMonthFetched = this.yearMonthsIndex.filter((yearMonthEntry) => yearMonthEntry.label === yearMonthLabel);

    return yearMonthFetched.length ? yearMonthFetched[0].value : null;
  }

  // Navigation methods

  get currentDataset(): CurrentDataset {
    return this._currentDataset;
  }

  isCurrentDatasetEmpty(current: CurrentDataset): boolean {
    if (current.type === 'datalakePath') {
      return !current.tenant || !current.bucket?.name || !current.path;
    } else {
      return !current.accountName || !current.tableName || !current.schemaName || !current.databaseName;
    }
  }

  setCurrentDataset(current: CurrentDataset, resource: ISupportedGuardianChecksResource): void {
    this._logger.debug('[GuardianService] Changing current directory', current);
    this._checkId = null;
    this._lastCheck$.next({ isCheckedByGuardian: false });
    this._checks$.next([]);
    this._currentGuardianStatus$.next(null);
    this._currentDataset = current;
    this._currentChanged$.next();
    this._logger.debug('[GuardianService] Fetching last check on current path');

    if (!this.isCurrentDatasetEmpty(current)) {
      this.listChecksByDataset(resource).subscribe();
    }
  }

  get allChecksToRemoveOnSave(): IBaseGuardianCheck[] {
    return this._checksToRemoveOnSave.getValue();
  }

  addChecksToRemoveOnSave<R extends ISupportedGuardianChecksResource>(
    checkId: number,
    resource: R,
    dataset: IGuardianChecksDatasetMap[R],
    datasetKey: string,
  ) {
    const newChecksToRemove: IBaseGuardianCheck = {
      checkId: checkId,
      resource: resource,
      dataset: dataset,
      datasetKey: datasetKey,
    };
    const checksToRemove: IBaseGuardianCheck[] = this.allChecksToRemoveOnSave;
    checksToRemove.push(newChecksToRemove);
    this._checksToRemoveOnSave.next(checksToRemove);
  }

  removeChecksToRemoveOnSave<R extends ISupportedGuardianChecksResource>(
    checkId: number,
    resource: R,
    dataset: IGuardianChecksDatasetMap[R],
    datasetKey: string,
  ) {
    const checksToRemove: IBaseGuardianCheck[] = this.allChecksToRemoveOnSave;
    const newChecksToRemove = checksToRemove.filter((checkToRemove) => checkToRemove.datasetKey !== datasetKey);
    this._checksToRemoveOnSave.next(newChecksToRemove);
  }

  clearChecksToRemoveOnSave() {
    this._checksToRemoveOnSave.next([]);
  }

  // HTTP methods

  private _listBuckets(): Observable<IBucket[]> {
    this._logger.debug('[GuardianService] Listing buckets');

    return this._apiService.get<IBucket[]>('/v4/datalake/buckets').pipe(
      catchError(() => {
        return [];
      }),
    );
  }

  private _getEmailsStrFromAlertRecipients(guardianFormData: IGuardianFormData, type: 'success' | 'failure'): string {
    return (
      GuardianService.convertArrayToGuardianStringArray(
        guardianFormData?.step1?.alertRecipients
          ?.filter((alertRecipient) =>
            type === 'success' ? alertRecipient.isAlertedOnSuccess : alertRecipient.isAlertedOnFailure,
          )
          .map((alertRecipient) => alertRecipient.userId),
      ) || null
    );
  }

  private async _getPathWithoutBucket(path: string): Promise<string> {
    // FIXME: it is not a clean way to do it
    const bucketsList = (await this._listBuckets().toPromise()).map((bucket) => bucket.name);

    if (!path.length) {
      return null;
    }

    const pathSegments = path.split('/');

    if (!bucketsList.includes(pathSegments[0])) {
      return path;
    }

    if (pathSegments.length === 1) {
      return '';
    }

    pathSegments.shift();

    return pathSegments.join('/');
  }

  private async _getBodyFromForm(
    guardianFormData: IGuardianFormData,
    resource: ISupportedGuardianChecksResource,
  ): Promise<unknown> {
    // TODO: [Revisit] (18.01.2024) - Update type
    const body: any = {
      isItrackEnabled: guardianFormData.step3.hasToUseItrack,
      guardianCheck: {
        // step 1
        name: guardianFormData.step1.name?.length ? guardianFormData.step1.name : null,
        email_if_ok: this._getEmailsStrFromAlertRecipients(guardianFormData, 'success'),
        email_if_not_ok: this._getEmailsStrFromAlertRecipients(guardianFormData, 'failure'),
        email_if_exception: this._getEmailsStrFromAlertRecipients(guardianFormData, 'failure'),
        hint_limit: guardianFormData.step1.isFlatfileDeactivate ? 100 : 20,
        dag_schedule_interval: guardianFormData.step3.hasToScheduleChecks
          ? GuardianService.convertToCron(guardianFormData.step3.schedulingInfos)
          : null,
      },
    };

    if (resource === 'datalakePath') {
      body.flatfileConfig = {
        isFlatfileDeactivate: guardianFormData.step1.isFlatfileDeactivate,
      };
      body.guardianCheck = {
        ...body.guardianCheck,
        ...{
          // step 1
          is_zip: false,
          file_format: guardianFormData.step1.fileFormat,
          regex: guardianFormData.step1.isFilteringFilesForCheck
            ? guardianFormData.step1.filteringRegex?.length
              ? guardianFormData.step1.filteringRegex.substring(1, guardianFormData.step1.filteringRegex.length - 1)
              : null
            : null,
          delimiter: guardianFormData.step1.fileFormat === 'csv' ? guardianFormData.step1.fileDelimiter : null,
          decimal: guardianFormData.step1.fileFormat === 'csv' ? guardianFormData.step1.decimalSeparator : null,
          sheet_name: guardianFormData.step1.fileFormat === 'xlsx' ? guardianFormData.step1.sheetName : null,
          // step 3
          target_folder_if_ok: guardianFormData.step3.hasToMoveIfSuccess
            ? `[${GuardianService._cleanDatalakePath(
                await this._getPathWithoutBucket(guardianFormData.step3.successPath),
              )}]`
            : null,
          target_folder_if_error: guardianFormData.step3.hasToMoveIfFailure
            ? `[${GuardianService._cleanDatalakePath(
                await this._getPathWithoutBucket(guardianFormData.step3.failurePath),
              )}]`
            : null,
          file_renaming_format_if_ok:
            guardianFormData.step3.hasToRenameDestinationFile && !guardianFormData.step3.hasToUseItrack
              ? `${this.fileNameRenamingFormat}.${guardianFormData.step1.fileFormat}`
              : null,
          file_renaming_format_if_error:
            guardianFormData.step3.hasToRenameDestinationFile && !guardianFormData.step3.hasToUseItrack
              ? `${this.fileNameRenamingFormat}.${guardianFormData.step1.fileFormat}`
              : null,
          enforce_replacement: guardianFormData.step3.hasToEnforceReplacementIfSameName,
          write_result: guardianFormData.step3.hasToGenerateResultFile,
        },
      };
    }

    // step 2
    const mappingBetweenColumnNameAndId = new Map<string, string>();
    guardianFormData.step2.columnsToCheck.forEach((field) =>
      mappingBetweenColumnNameAndId.set(field.columnId, field.columnName),
    );
    body.guardianCheck.validation_schema_columns = guardianFormData.step2.columnsToCheck.map((field, index) => ({
      column_name: field.columnName,
      constraint_id: index,
      type: field.addedConstraints.includes('type') ? field.type : null,
      required_values: field.addedConstraints.includes('isRequired') ? field.isRequired : null,
      unique: field.addedConstraints.includes('isUnique') ? field.isUnique : null,
      min: field.addedConstraints.includes('minValue') && field.minValue !== '' ? field.minValue : null,
      max: field.addedConstraints.includes('maxValue') && field.maxValue !== '' ? field.maxValue : null,
      pair_greater:
        field.addedConstraints.includes('greaterThan') && field.greaterThanColumnId?.length
          ? mappingBetweenColumnNameAndId.get(field.greaterThanColumnId)
          : null,
      regex:
        field.addedConstraints.includes('regex') && field.regex?.length > 2
          ? field.regex.substring(1, field.regex.length - 1)
          : null,
      values_in: field.addedConstraints.includes('mdh')
        ? field.snowflakeExternalReferential
        : field.addedConstraints.includes('datalake_in')
        ? field.datalakeInExternalReferential
        : null,
      datalake_path_in: field.addedConstraints.includes('datalake_in') ? field.datalakePathIn : null,
      values_not_in: field.addedConstraints.includes('datalake_not_in') ? field.datalakeNotInExternalReferential : null,
      datalake_path_not_in: field.addedConstraints.includes('datalake_not_in') ? field.datalakePathNotIn : null,
    }));

    return body;
  }

  listChecksByCheckId<R extends ISupportedGuardianChecksResource>(
    checkId: number,
    resource: R,
    errorHandling?: ErrorHandlingOptions,
  ): Observable<IGuardianStatus> {
    return this._getSourceId().pipe(
      switchMap((sourceName) => {
        let url = `v4/guardian/resource/${resource}/checks/${checkId}`;

        if (sourceName) {
          url = `v4/guardian/resource/${resource}/checks/${checkId}?source=${sourceName}`;
        }

        return this._apiService
          .get<IGuardianStatus>(url, {
            errorHandling: errorHandling ?? {
              level: 'silent',
            },
          })
          .pipe(
            catchError((error: unknown) => {
              this._logger.debug('[GuardianService] Cannot list related checks by check ID :', error);

              return of(null);
            }),
          );
      }),
    );
  }

  listRelatedChecksBySourceId<R extends ISupportedGuardianChecksResource>(
    sourceId: string,
  ): Observable<IGuardianChecksRelatedToSource<R>[]> {
    return this._apiService
      .get<IGuardianChecksRelatedToSource<R>[]>(`v4/guardian/checks/source/${sourceId}`, {
        errorHandling: { level: 'silent' },
      })
      .pipe(
        catchError((error: unknown) => {
          this._logger.error(
            `[GuardianService] Listing related checks by source ID, cannot get check for the source ${sourceId} :`,
            error,
          );

          return of([] as IGuardianChecksRelatedToSource<R>[]);
        }),
        map((sourceRelatedGuardianChecks) => (sourceRelatedGuardianChecks?.length ? sourceRelatedGuardianChecks : [])),
      );
  }

  listSourceRelatedChecksByResource<R extends ISupportedGuardianChecksResource>(
    sourceId: string,
    resource: R,
    errorHandling?: ErrorHandlingOptions,
  ): Observable<IGuardianChecksRelatedToSource<R>[]> {
    return this._apiService
      .get<IGuardianChecksRelatedToSource<R>[]>(`v4/guardian/resource/${resource}/checks/source/${sourceId}`, {
        errorHandling: errorHandling ?? { level: 'silent' },
      })
      .pipe(
        catchError((error: unknown) => {
          this._logger.error(
            `[GuardianService] Listing source related checks by resource, cannot get check for the source ${sourceId} and the resource ${resource} :`,
            error,
          );

          return of([] as IGuardianChecksRelatedToSource<R>[]);
        }),
        map((sourceRelatedGuardianChecks) => (sourceRelatedGuardianChecks?.length ? sourceRelatedGuardianChecks : [])),
      );
  }

  listRelatedChecksByDataset<R extends ISupportedGuardianChecksResource>(
    dataset: IGuardianChecksDatasetMap[R],
    resource: R,
  ): Observable<IGuardianChecksRelatedToSource[]> {
    const datasetToInspect = dataset.type === 'datalakePath' ? this._getDatalakePathOptions(dataset) : dataset;

    return this._apiService
      .get<IGuardianChecksRelatedToSource[]>(`/v4/guardian/resource/${resource}/checks/dataset`, {
        queryStringParameters: {
          ...datasetToInspect,
        },
      })
      .pipe(
        catchError((error: unknown) => {
          this._logger.debug('[GuardianService] List related checks by path ID error:', error);
          this._alert.error(`Cannot get related check for this path`);

          return of(null);
        }),
      );
  }

  listChecksByDataset<R extends ISupportedGuardianChecksResource>(
    resource: R,
    sourceId?: string,
    dataset?: IGuardianChecksDatasetMap[R],
  ): Observable<IGuardianStatus> {
    let datasetToCheck;

    if (resource === 'datalakePath') {
      datasetToCheck = this._getDatalakePathOptions(dataset as IGuardianDatalakePath);
      const notInDatalakeRoot = datasetToCheck?.tenant && datasetToCheck?.filesystem && datasetToCheck?.path;

      if (!notInDatalakeRoot) {
        this._logger.warn('[GuardianService] Cannot list check for this dataset', {
          current: this._currentDataset,
        });

        return of(null);
      }
    }

    if (resource === 'snowflake') {
      datasetToCheck = dataset;
      this.sourceId = sourceId;
      this._currentDataset = datasetToCheck;
    }

    this.sourceId = sourceId ?? this.sourceId;
    let relatedChecks: IGuardianChecksRelatedToSource[];

    return this.listRelatedChecksByDataset(datasetToCheck, resource).pipe(
      tap((val) => (relatedChecks = val)),
      takeUntil(this._currentChanged$),
      mergeMap(() => {
        const defaultGuardianStatus = {
          isChecked: false,
          isFlatfileDeactivate: false,
          checkInfos: null,
          checks: [],
        } as IGuardianStatus;

        if (!relatedChecks.length) {
          return of(defaultGuardianStatus);
        }

        if (resource === 'snowflake') {
          const checksId = relatedChecks.find((check) => check.pk === sourceId).checksId[0];

          return checksId ? this.listChecksByCheckId(checksId, resource) : of(defaultGuardianStatus);
        }

        if (resource === 'datalakePath') {
          const checksId = relatedChecks[0].checksId[0];

          return checksId ? this.listChecksByCheckId(checksId, resource) : of(defaultGuardianStatus);
        }

        return of(defaultGuardianStatus);
      }),

      map((status) => {
        this._logger.debug('[GuardianService] Listed checks for current dataset', {
          current: this._currentDataset,
          status,
        });
        this._lastCheck$.next({
          isCheckedByGuardian: status?.isChecked,
          lastCheck: status?.checks?.length ? new GuardianCheck(status.checks[0]) : null,
        });
        this._checkId = status ? status.checkId : null;
        this._checks$.next(status?.checks?.length ? status.checks.map((c) => new GuardianCheck(c)) : null);
        this._currentGuardianStatus$.next(status);
        this._logger.debug('[GuardianService] relatedChecks', {
          relatedChecks,
        });

        return resource === 'datalakePath'
          ? { ...status, isFlatfileDeactivate: relatedChecks[0].flatfileConfig.isFlatfileDeactivate }
          : status;
      }),
      catchError((err: unknown) => {
        this._lastCheck$.next(null);
        this._checkId = null;
        this._checks$.next(null);
        this._logger.error('[GuardianService] Error fetching ten last checks for current dataset', {
          current: this.currentDataset,
          err,
        });

        return of(null);
      }),
    );
  }

  listChecksByPath(datalakePath?: IGuardianDatalakePath): Observable<IGuardianStatus> {
    const datalakePathToCheck = this._getDatalakePathOptions(datalakePath);
    const notInDatalakeRoot =
      datalakePathToCheck?.tenant && datalakePathToCheck?.filesystem && datalakePathToCheck?.path;

    if (!notInDatalakeRoot) {
      this._logger.warn('[GuardianService] Cannot list check for this directory', {
        current: this._currentDataset,
      });

      return of(null);
    }

    this._logger.debug('[GuardianService] Listing checks for current directory', { current: this._currentDataset });
    let relatedChecks: IGuardianChecksRelatedToSource[];

    return this.listRelatedChecksByDataset(datalakePathToCheck, 'datalakePath').pipe(
      tap((val) => (relatedChecks = val)),
      takeUntil(this._currentChanged$),
      mergeMap(() =>
        relatedChecks?.length && relatedChecks[0].checksId?.length
          ? this.listChecksByCheckId(relatedChecks[0].checksId[0], 'datalakePath')
          : of({
              isChecked: false,
              isFlatfileDeactivate: false,
              checkInfos: null,
              checks: [],
            } as IGuardianStatus),
      ),
      map((status) => {
        this._logger.debug('[GuardianService] Listed checks for current directory', {
          current: this._currentDataset,
          status,
        });
        this._lastCheck$.next({
          isCheckedByGuardian: status?.isChecked,
          lastCheck: status?.checks?.length ? new GuardianCheck(status.checks[0]) : null,
        });
        this._checkId = status ? status.checkId : null;
        this._checks$.next(status?.checks?.length ? status.checks.map((c) => new GuardianCheck(c)) : null);
        this._currentGuardianStatus$.next(status);

        return { ...status, isFlatfileDeactivate: relatedChecks[0].flatfileConfig.isFlatfileDeactivate };
      }),
      catchError((err: unknown) => {
        this._lastCheck$.next(null);
        this._checkId = null;
        this._checks$.next(null);
        this._logger.error('[GuardianService] Error fetching ten last checks for current path', {
          current: this.currentDataset,
          err,
        });

        return of(null);
      }),
    );
  }

  private _getSourceId(): Observable<string> {
    if (this._router.url.includes('/sources/edit/')) {
      const index = this._router.url.split('/').indexOf('edit');
      const sourceName = this._router.url.split('/')[index + 1];

      return of(sourceName);
    }

    if (this._router.url.includes('/datalake/')) {
      const datalakePath = this._getDatalakePath();

      return this._datalakeApiService.getRelatedSources(datalakePath).pipe(map((sources) => sources[0].sourceId));
    }

    return of(null);
  }
  private _getDatalakePath() {
    const queryParamMap = this._route.snapshot.queryParamMap;
    const path = queryParamMap.get('path');
    const tenant = queryParamMap.get('tenant');
    const provider = queryParamMap.get('provider') as 'aws' | 'azure';

    const bucketNameRegExp = this._router.url.match(/\/([^/?]+)(?:\?.*)?$/);
    const bucket = bucketNameRegExp ? bucketNameRegExp[1] : null;

    return new DatalakePath(bucket + '/' + path, provider, tenant);
  }

  private _updateCheck<R extends ISupportedGuardianChecksResource>(
    checkId: number,
    sourceId: string,
    resource: R,
    dataset: IGuardianChecksDatasetMap[R],
    body: unknown,
    hasToRun = false,
  ): Observable<IAPIRunCheckResponse> {
    return this._apiService.put<IAPIRunCheckResponse>(`/v4/guardian/checks/${checkId}/source/${sourceId}`, body, {
      queryStringParameters: {
        ...dataset,
        resource: resource,
        exec: encodeURIComponent(hasToRun),
      },
      errorHandling: {
        level: 'silent',
      },
    });
  }

  private _createCheck<R extends ISupportedGuardianChecksResource>(
    sourceId: string,
    resource: R,
    dataset: IGuardianChecksDatasetMap[R],
    body: unknown,
    hasToRun = false,
  ): Observable<IAPIRunCheckResponse> {
    return this._apiService.post<IAPIRunCheckResponse>(`/v4/guardian/checks/source/${sourceId}`, body, {
      queryStringParameters: {
        ...dataset,
        resource: resource,
        exec: encodeURIComponent(hasToRun),
      },
      errorHandling: {
        level: 'silent',
      },
    });
  }

  async setCheck<R extends ISupportedGuardianChecksResource>(
    guardianForm: IGuardianForm<R>,
    resource: R,
    sourceId: string,
  ): Promise<Observable<IAPIRunCheckResponse>> {
    let dataset: IGuardianChecksDatasetMap[R] = guardianForm.dataset;

    if (guardianForm.dataset.type === 'datalakePath') {
      dataset = this._getDatalakePathOptions(guardianForm.dataset) as IGuardianChecksDatasetMap[R];
    }

    const body = await this._getBodyFromForm(guardianForm.form, resource);

    return (
      guardianForm.guardianStatus?.isChecked
        ? this._updateCheck(guardianForm.guardianStatus.checkId, sourceId, resource, dataset, body)
        : this._createCheck(sourceId, resource, dataset, body)
    ).pipe(tap(() => this._guardianCurrentUserService.indicatesThatUserHasUpdatedChecks()));
  }

  private _prepareDatasetRequestCheck<R extends ISupportedGuardianChecksResource>(
    resource: R,
    dataset?: IGuardianChecksDatasetMap[R],
  ): { [param: string]: string } {
    if (resource === 'datalakePath') {
      const datalakePath = dataset as IGuardianDatalakePath;
      const currentDatalakePathDataset = this._currentDataset as IDatalakeCurrentDirectory;

      return {
        ...datalakePath,
        tenant: datalakePath?.tenant?.length ? datalakePath.tenant : currentDatalakePathDataset.tenant,
        filesystem: datalakePath?.filesystem?.length ? datalakePath.filesystem : currentDatalakePathDataset.bucket.name,
        provider: datalakePath?.provider?.length ? datalakePath.provider : currentDatalakePathDataset.provider,
        path: datalakePath?.path?.length ? datalakePath.path : currentDatalakePathDataset.path,
      };
    } else {
      return {
        ...dataset,
      };
    }
  }

  requestCheck<R extends ISupportedGuardianChecksResource>(
    checkId: number,
    resource: R,
    dataset?: IGuardianChecksDatasetMap[R],
    hasToForceRunning = false,
    onRequestError: (error: HttpErrorResponse) => void = () => {},
  ): void {
    if (this._currentGuardianStatus$.getValue()?.isChecked || hasToForceRunning) {
      this._alert.success('Check requested', false);
      this._apiService
        .post<IGuardianStatus>(`/v4/guardian/resource/${resource}/checks/requests/${checkId}`, null, {
          queryStringParameters: this._prepareDatasetRequestCheck(resource, dataset),
          errorHandling: {
            level: 'silent',
          },
        })
        .pipe(
          catchError((error: unknown) => {
            if (error instanceof HttpErrorResponse) {
              this._logger.debug('[GuardianService] Request check error :', error);
              const reason = error.message?.length ? error.message : 'Internal Server Error';
              this._alert.error(`Cannot request check (reason : ${reason})`);
              onRequestError(error);
            }

            return of(null);
          }),
        )
        .subscribe(async (checkResult) => {
          if (RESPONSE_STATUS_CODE.includes(checkResult.StatusCode)) {
            this._alert.success('Check run', false);

            return;
          }

          this._alert.error('Check could not run');
          onRequestError(checkResult);
        });
    }
  }

  removeCheck<R extends ISupportedGuardianChecksResource>(
    checkId: number,
    sourceId: string,
    dataset: IGuardianChecksDatasetMap[R],
    resource: R,
  ): Observable<IGuardianAPIChecksMap[R]> {
    const datasetToRemove = dataset.type === 'datalakePath' ? this._getDatalakePathOptions(dataset) : dataset;

    return this._apiService
      .delete<IGuardianAPIChecksMap[R]>(
        `/v4/guardian/resource/${resource}/checks/source/${sourceId}/check/${checkId}`,
        {
          queryStringParameters: {
            ...datasetToRemove,
          },
          errorHandling: {
            level: 'silent',
          },
        },
      )
      .pipe(tap(() => this._guardianCurrentUserService.indicatesThatUserHasUpdatedChecks()));
  }

  /* Guardian check regex */

  // Utils methods

  private static _buildOneCheckRegex(json: ICheckRegex): CheckRegex {
    return EntityBuilder.buildOne(CheckRegex, json);
  }

  private static _buildManyCheckRegex(json: ICheckRegex[]): CheckRegex[] {
    return json.map((data) => GuardianService._buildOneCheckRegex(data));
  }

  static hasRegexReferential(field: IRequestCheckFieldOptions): boolean {
    return !!field.regex?.length;
  }

  // HTTP methods

  listAllCheckRegex(): Observable<Array<CheckRegex>> {
    return this._apiService.get<ICheckRegex[]>('/v4/guardian/checks/regex', undefined, []).pipe(
      catchError((err: unknown) => {
        this._logger.error('[GuardianService] Error while listing all check regex', err);
        this._alert.error('Error while listing all guardian check regex');

        return throwError(err);
      }),
      map((res: ICheckRegex[]) => GuardianService._buildManyCheckRegex(res)),
      tap((checkRegexList) => this.refreshCheckRegexLists(checkRegexList)),
    );
  }

  createCheckRegex(checkRegex: CheckRegex): Observable<ICheckRegex> {
    return this._apiService.post<ICheckRegex>(`/v4/guardian/checks/regex`, checkRegex.toJson()).pipe(
      catchError((err: unknown) => {
        this._logger.error('[GuardianService] Error while creating this check regex', checkRegex, err);
        this._alert.error('Error while creating this guardian check regex');

        return throwError(err);
      }),
      tap(() => this.listAllCheckRegex().subscribe()),
    );
  }

  updateCheckRegex(checkRegex: CheckRegex): Observable<ICheckRegex> {
    return this._apiService.put<ICheckRegex>(`/v4/guardian/checks/regex/${checkRegex.pk}`, checkRegex.toJson()).pipe(
      catchError((err: unknown) => {
        this._logger.error('[GuardianService] Error while updating this check regex', checkRegex, err);
        this._alert.error('Error while updating this guardian check regex');

        return throwError(err);
      }),
      tap(() => this.listAllCheckRegex().subscribe()),
    );
  }

  deleteCheckRegex(checkRegex: CheckRegex): Observable<ICheckRegex> {
    return this._apiService.delete<ICheckRegex>(`/v4/guardian/checks/regex/${checkRegex.pk}`).pipe(
      catchError((err: unknown) => {
        this._logger.error('[GuardianService] Error while deleting this check regex', checkRegex, err);
        this._alert.error('Error while deleting this guardian check regex');

        return throwError(err);
      }),
      tap(() => this.listAllCheckRegex().subscribe()),
    );
  }

  /* Guardian external referential */

  // HTTP methods

  /** *
   * Get the existing checks names without the current check name
   * Wait until this.hasEveryPathBeenLoaded is truthy before
   */
  getExistingChecksNames<R extends ISupportedGuardianChecksResource>(
    dataset: IGuardianChecksDatasetMap[R],
    datasetMap: IDatasetMappingWithAzureStatus<R>,
  ): string[] {
    const entries = Array.from(datasetMap.entries());

    return entries
      .filter(
        (entry) =>
          entry[0]?.length &&
          entry[0] !==
            (dataset.type === 'datalakePath'
              ? GuardianService.guardianDatalakePathToKey(dataset)
              : this._amundsenService.getAmundsenTableKey(dataset)),
      )
      .map((entry) => entry[1]?.status?.checkInfos?.name)
      .filter((name) => name?.length);
  }

  hasFetchedDatasetFromGuardian<R extends ISupportedGuardianChecksResource>(
    dataset: IGuardianChecksDatasetMap[R],
    datasetMap: IDatasetMappingWithAzureStatus<R>,
  ): boolean {
    return (
      datasetMap.has(
        dataset.type === 'datalakePath'
          ? GuardianService.guardianDatalakePathToKey(dataset)
          : this._amundsenService.getAmundsenTableKey(dataset),
      ) &&
      !datasetMap.get(
        dataset.type === 'datalakePath'
          ? GuardianService.guardianDatalakePathToKey(dataset)
          : this._amundsenService.getAmundsenTableKey(dataset),
      ).isLoading
    );
  }

  getDatasetGuardianCheck<R extends ISupportedGuardianChecksResource>(
    dataset: IGuardianChecksDatasetMap[R],
    datasetMap: IDatasetMappingWithAzureStatus<R>,
  ): IGuardianStatus {
    return this.hasFetchedDatasetFromGuardian(dataset, datasetMap)
      ? datasetMap.get(
          dataset.type === 'datalakePath'
            ? GuardianService.guardianDatalakePathToKey(dataset)
            : this._amundsenService.getAmundsenTableKey(dataset),
        ).status
      : null;
  }

  isDatasetCheckedByGuardian<R extends ISupportedGuardianChecksResource>(
    dataset: IGuardianChecksDatasetMap[R],
    datasetMap: IDatasetMappingWithAzureStatus<R>,
  ): boolean {
    return this.getDatasetGuardianCheck(dataset, datasetMap)?.isChecked;
  }

  static guardianDatalakePathToKey(datalakePath: IGuardianDatalakePath): string {
    const pathToUse = DatalakeService.formatPath(datalakePath.path);
    let key = `[${datalakePath.filesystem}/${pathToUse}]-`;

    if (datalakePath.provider === 'aws' || datalakePath.provider === 'azure') {
      key += `[${datalakePath.provider}]`;

      if (datalakePath.tenant && datalakePath.provider === 'azure') {
        key += `-[${datalakePath.tenant}]`;
      } else if (datalakePath.tenant && datalakePath.provider === 'aws') {
        return null;
      } else if (!datalakePath.tenant && datalakePath.provider === 'azure') {
        return null;
      }
    } else {
      return null;
    }

    return key;
  }

  static sortByAlpha(firstElement: unknown, secondElement: unknown, fieldToCheck: string): number {
    if (
      ((!firstElement || !firstElement[fieldToCheck]?.length) &&
        secondElement &&
        secondElement[fieldToCheck]?.length) ||
      firstElement[fieldToCheck] < secondElement[fieldToCheck]
    ) {
      return -1;
    } else if (
      ((!secondElement || !secondElement[fieldToCheck]?.length) &&
        firstElement &&
        firstElement[fieldToCheck]?.length) ||
      firstElement[fieldToCheck] > secondElement[fieldToCheck]
    ) {
      return 1;
    } else {
      return 0;
    }
  }

  static isValidCalendarDate(day: number, month: number, year: number): boolean {
    if (day < 1 || month < 1 || month > 12 || year < 1970) {
      return false;
    }

    if (month !== 2) {
      return (
        day <=
        (month === 1 || month === 3 || month === 5 || month === 7 || month === 8 || month === 10 || month === 12
          ? 31
          : 30)
      );
    } else {
      return day <= (!(year % 400) || (!(year % 4) && !!(year % 100)) ? 29 : 28);
    }
  }

  static getDecimalRegex(decimalSeparatorRegex: string): string {
    return `^[-]?([0-9]*[${decimalSeparatorRegex}])?[0-9]+$`;
  }

  static getCalculatedTypeFromValue(value: string, decimalSeparatorRegex?: string): DatalakeColumnType {
    const decimalSeparatorRegexToUse = decimalSeparatorRegex?.length
      ? decimalSeparatorRegex
      : GuardianService.DEFAULT_DECIMAL_SEPARATORS_XLSX.join('');
    const numberRegex = new RegExp(GuardianService.NUMBER_REGEX);
    const decimalRegex = new RegExp(GuardianService.getDecimalRegex(decimalSeparatorRegexToUse));
    const dateRegex = new RegExp(GuardianService.DATE_REGEX);

    return dateRegex.test(value)
      ? 'datetime'
      : numberRegex.test(value)
      ? 'integer'
      : decimalRegex.test(value)
      ? 'float'
      : 'string';
  }

  /**
   * Best effort comparison with minimum type inference
   * @param firstValue The first value
   * @param secondValue The second value
   * @param decimalSeparatorRegex (optional) The decimal separator regex used by Guardian specification
   */
  static compareValue(firstValue: unknown, secondValue: unknown, decimalSeparatorRegex?: string): number {
    const firstValueStr = firstValue?.toString() || '';
    const secondValueStr = secondValue?.toString() || '';
    const decimalSeparatorRegexToUse = decimalSeparatorRegex?.length
      ? decimalSeparatorRegex
      : GuardianService.DEFAULT_DECIMAL_SEPARATORS_XLSX.join('');
    const firstValueCalculatedType = GuardianService.getCalculatedTypeFromValue(
      firstValueStr,
      decimalSeparatorRegexToUse,
    );
    const secondValueCalculatedType = GuardianService.getCalculatedTypeFromValue(
      secondValueStr,
      decimalSeparatorRegexToUse,
    );

    if (
      (firstValueCalculatedType === 'integer' || firstValueCalculatedType === 'float') &&
      (secondValueCalculatedType === 'integer' || secondValueCalculatedType === 'float')
    ) {
      const firstValueAsNumber = Number(firstValueStr);
      const secondValueAsNumber = Number(secondValueStr);

      return firstValueAsNumber - secondValueAsNumber;
    } else if (firstValueCalculatedType === 'datetime' && secondValueCalculatedType === 'datetime') {
      const firstValueAsDate = new Date(firstValueStr);
      const secondValueAsDate = new Date(secondValueStr);

      return firstValueAsDate.getTime() - secondValueAsDate.getTime();
    } else {
      return firstValueStr.localeCompare(secondValueStr);
    }
  }

  static compareDate(firstValue: string, secondValue: string): number {
    const firstValueSegments = firstValue.split('-');
    const firstValueYear = parseInt(firstValueSegments[0], 10);
    const firstValueMonth = parseInt(firstValueSegments[1], 10);
    const firstValueDay = parseInt(firstValueSegments[2], 10);
    const secondValueSegments = secondValue.split('-');
    const secondValueYear = parseInt(secondValueSegments[0], 10);
    const secondValueMonth = parseInt(secondValueSegments[1], 10);
    const secondValueDay = parseInt(secondValueSegments[2], 10);

    if (firstValueYear < secondValueYear) {
      return -1;
    } else if (firstValueYear > secondValueYear) {
      return 1;
    } else if (firstValueMonth < secondValueMonth) {
      return -1;
    } else if (firstValueMonth > secondValueMonth) {
      return 1;
    } else if (firstValueDay < secondValueDay) {
      return -1;
    } else if (firstValueDay > secondValueDay) {
      return 1;
    } else {
      return 0;
    }
  }

  static hasDateFormatError(dateValue: string): boolean {
    if (!dateValue.length) {
      return false;
    }

    if (!dateValue.match(new RegExp(GuardianService.DATE_REGEX))) {
      return true;
    }

    const dateValueSegments = dateValue?.split('-');
    const year = dateValueSegments[0];
    const month = dateValueSegments[1];
    const day = dateValueSegments[2];
    const yearInt = parseInt(year, 10);
    const monthInt = parseInt(month, 10);
    const dayInt = parseInt(day, 10);

    if (isNaN(yearInt) || isNaN(monthInt) || isNaN(dayInt)) {
      return true;
    }

    return !GuardianService.isValidCalendarDate(dayInt, monthInt, yearInt);
  }

  private static _sortExternalReferentialByAlpha(
    firstExternalReferential: IExternalReferential,
    secondExternalReferential: IExternalReferential,
  ): number {
    return GuardianService.sortByAlpha(firstExternalReferential, secondExternalReferential, 'label');
  }

  getExternalReferentialsListCurrentValue(): IExternalReferential[] {
    return this._externalReferentialsList$?.getValue() || [];
  }

  getExternalReferentialSnowflakeLabel(externalSnowflakeReferential: IAPITableReferential): string {
    const referentialFound = this.getExternalReferentialsListCurrentValue().find((fetchedReferential) =>
      GuardianService.isSnowflakeReferentialEqual(fetchedReferential.infos, externalSnowflakeReferential),
    );

    return referentialFound ? referentialFound.label : externalSnowflakeReferential.sql_table;
  }

  // TODO: [Revisit] (18.01.2024) - Update types
  static isSnowflakeReferential(referential: APIReferential): boolean {
    return (
      Object.keys(referential || {}).includes('sql_engine') &&
      (referential as APIReferential & { sql_engine: 'snowflake' }).sql_engine === 'snowflake'
    );
  }

  static isSnowflakeReferentialEqual(value1: APIReferential, value2: APIReferential): boolean {
    if (!value1 || !value2) {
      return false;
    }

    if (!GuardianService.isSnowflakeReferential(value1) || !GuardianService.isSnowflakeReferential(value2)) {
      return false;
    }

    const firstValue = value1 as IAPITableReferential;
    const secondValue = value2 as IAPITableReferential;

    return (
      firstValue.sql_engine === secondValue.sql_engine &&
      firstValue.sql_table === secondValue.sql_table &&
      firstValue.sql_schema === secondValue.sql_schema &&
      firstValue.sql_database === secondValue.sql_database &&
      firstValue.engine_params?.warehouse === secondValue.engine_params?.warehouse &&
      firstValue.column === secondValue.column
    );
  }

  private static _sortCheckRegexByAlpha(firstCheckRegex: CheckRegex, secondCheckRegex: CheckRegex): number {
    return GuardianService.sortByAlpha(firstCheckRegex, secondCheckRegex, 'label');
  }

  getNameFilterRegexLabel(regex: string): string {
    const regexFound = this._nameFilterCheckRegexList$.getValue().find((fetchedRegex) => fetchedRegex.regex === regex);

    return regexFound ? regexFound.label : regex;
  }

  getNameFilterRegexFromNameFilterRegexLabel(nameFilterRegexLabel: string): string {
    const regexFound = this._nameFilterCheckRegexList$
      .getValue()
      .find((fetchedRegex) => fetchedRegex.label === nameFilterRegexLabel);

    return regexFound ? regexFound.regex : null;
  }

  refreshCheckRegexLists(checkRegexList: CheckRegex[]): void {
    const sortedCheckRegexList = checkRegexList.sort(GuardianService._sortCheckRegexByAlpha);
    this._fieldCheckRegexList$.next(sortedCheckRegexList.filter((regex) => regex.type === 'FIELD_CHECK'));
    this._nameFilterCheckRegexList$.next(sortedCheckRegexList.filter((regex) => regex.type === 'NAME_FILTER_CHECK'));
    this._renamingFileCheckRegexList$.next(
      sortedCheckRegexList.filter((regex) => regex.type === 'RENAMING_FILE_CHECK'),
    );
  }

  getFieldCheckRegexLabel(regex: string, limit = 0): string {
    const regexFound = this._fieldCheckRegexList$.getValue().find((fetchedRegex) => fetchedRegex.regex === regex);

    return regexFound ? regexFound.label : limit && regex.length > limit ? regex.slice(0, limit) + '...' : regex;
  }

  getFieldCheckRegexFromFieldCheckRegexLabel(fieldCheckRegexLabel: string): string {
    const regexFound = this._fieldCheckRegexList$
      .getValue()
      .find((fetchedRegex) => fetchedRegex.label.startsWith(fieldCheckRegexLabel)); // because we can set a limit for getFieldCheckRegexLabel()

    return regexFound ? regexFound.regex : fieldCheckRegexLabel;
  }

  private static _compareSchemaColumnOrder(
    schemaColumn1: IRequestCheckFieldOptions,
    schemaColumn2: IRequestCheckFieldOptions,
  ): number {
    if (schemaColumn1.constraint_id === null || schemaColumn1.constraint_id === undefined) {
      return -1;
    }

    if (schemaColumn2.constraint_id === null || schemaColumn2.constraint_id === undefined) {
      return 1;
    }

    return schemaColumn1.constraint_id - schemaColumn2.constraint_id;
  }

  static getOrderedValidationSchemaColumnsArray(
    guardianCheckColumns: IGuardianAPIRequestCheckFieldOptions,
  ): IRequestCheckFieldOptions[] {
    const validationSchemaColumnsArray = [] as IRequestCheckFieldOptions[];
    Object.keys(guardianCheckColumns || {}).forEach((key) => {
      validationSchemaColumnsArray.push({
        column_name: key,
        ...guardianCheckColumns[key],
      });
    });

    return validationSchemaColumnsArray.sort(GuardianService._compareSchemaColumnOrder);
  }

  static getOrderedFileDataColumns(
    fileData: unknown[],
    guardianCheckColumns: IGuardianAPIRequestCheckFieldOptions,
  ): string[][] {
    const orderedData = [] as string[][];
    const orderedValidationSchemaColumnsNames = GuardianService.getOrderedValidationSchemaColumnsArray(
      guardianCheckColumns,
    ).map((column) => column.column_name);

    if (fileData.length) {
      orderedData.push(orderedValidationSchemaColumnsNames);
      fileData.forEach((row, index) => {
        orderedData.push([]);
        orderedValidationSchemaColumnsNames.map((columnName) =>
          orderedData[index + 1].push(fileData[index][columnName]),
        );
      });
    }

    return orderedData;
  }

  /* Guardian external referential */

  // Utils methods

  static isDatalakeReferential(referential: APIReferential): boolean {
    return Object.keys(referential || {}).includes('datalake');
  }

  static hasSnowflakeReferential(field: IRequestCheckFieldOptions, isWhitelisted: boolean): boolean {
    return (
      GuardianService.hasExternalReferential(field) &&
      GuardianService.isSnowflakeReferential(isWhitelisted ? field.values_in : field.values_not_in)
    );
  }

  static hasExternalReferential(field: IRequestCheckFieldOptions): boolean {
    return Object.keys(field || {}).includes('values_in') || Object.keys(field || {}).includes('values_not_in');
  }

  static hasDatalakeReferential(field: IRequestCheckFieldOptions, isWhitelisted: boolean): boolean {
    return (
      GuardianService.hasExternalReferential(field) &&
      GuardianService.isDatalakeReferential(isWhitelisted ? field.values_in : field.values_not_in)
    );
  }

  listExternalReferentials(): Observable<IExternalReferential[]> {
    return this._apiService.get<IExternalReferential[]>('/v4/guardian/checks/referentials', undefined, []).pipe(
      catchError((err: unknown) => {
        this._logger.error('[GuardianService] Error while listing all external referentials', err);
        this._alert.error('Error while listing all guardian external referentials');

        return throwError(err);
      }),
      tap((externalReferentialList: IExternalReferential[]) =>
        this._externalReferentialsList$.next(
          externalReferentialList.sort(GuardianService._sortExternalReferentialByAlpha),
        ),
      ),
    );
  }
}
