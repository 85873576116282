import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { PermissionsService } from '@dataportal/permissions';

// Guard
@Injectable()
export class PreviewV2Guard {
  // Constructor
  constructor(private readonly _currentUserService: PermissionsService) {}

  private _canAccess(): Observable<boolean> {
    return this._currentUserService.isAuthorized('update', 'sources', 'preview-catalog-v2-source-id');
  }

  // Methods
  canActivate(): Observable<boolean> {
    return this._canAccess();
  }

  canLoad(): Observable<boolean> {
    return this._canAccess();
  }
}
