import type { AfterContentChecked, OnInit } from '@angular/core';
import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { IAthenaResponse } from '@dataportal/api-types';
import type { IAmundsenTableColumn } from '@dataportal/api-types/lib/src/types/amundsen';

@Component({
  selector: 'dpg-catalog-amundsen-data-table',
  templateUrl: './catalog-amundsen-data-table.component.html',
  styleUrls: ['./catalog-amundsen-data-table.component.scss'],
})
export class CatalogAmundsenDataTableComponent implements OnInit, AfterContentChecked {
  // Attributes
  @Input() tableData: IAthenaResponse = null;
  @Input() tableMetaData: IAmundsenTableColumn[] = null;
  @Input() viewMode: 'data' | 'details' = 'data';

  // needed for ngx-datatable
  @ViewChild('tableContainer') tableContainer: ElementRef;
  dataColWidth = 150;
  metadataColWidth = 150;

  tableDataFormatted: { [key: string]: string }[] = [];

  dataTableKeys: string[] = [];

  tableMetaDataColumns: string[] = [];

  renderTable = false;

  metaDataSortOrder = [
    'sort_order',
    'name',
    'col_type',
    'is_nullable',
    'numeric_scale',
    'numeric_precision',
    'character_maximum_length',
    'column_default',
    'description',
  ];
  metaDataColsToHide = ['badges', 'stats', 'key', 'type_metadata', 'schema'];

  ngOnInit() {
    if (this.viewMode === 'data') {
      this.handleAthenaData();
    } else {
      this.handleAmundsenMetadata();
    }
  }

  ngAfterContentChecked(): void {
    if (this.tableContainer && !this.renderTable) {
      this.calculateColumnWidths();
    }
  }

  calculateColumnWidths() {
    if (this.tableData?.simplePreview?.length) {
      const maxDataColWidth = Math.max(
        150,
        (this.tableContainer.nativeElement.offsetWidth - 100) / this.tableData.simplePreview[0].length,
      );

      if (this.dataColWidth !== maxDataColWidth) {
        this.dataColWidth = maxDataColWidth;
      }
    }

    if (this.tableMetaData) {
      const maxMetaDataColWidth = Math.max(
        150,
        (this.tableContainer.nativeElement.offsetWidth - 40) / this.metaDataSortOrder.length,
      );

      if (this.metadataColWidth !== maxMetaDataColWidth) {
        this.metadataColWidth = maxMetaDataColWidth;
      }
    }

    // timeout is needed for the table to properly reload
    setTimeout(() => (this.renderTable = true), 250);
  }

  handleAthenaData() {
    // first row of tableData.simplePreview is column names
    const [colNames, ...rows] = this.tableData.simplePreview;

    this.dataTableKeys = ['row', ...colNames];

    rows.forEach((dataRow: string[], rowIndex: number) => {
      // init the object with row as key
      const tempTableDataObject = { row: `${rowIndex + 1}` };
      // iterate on each value of the row
      dataRow.forEach((value: string, valueIndex: number) => {
        // push the value into temp object
        const propertyKey = colNames[valueIndex];
        tempTableDataObject[propertyKey] = value;
      });

      // push the tableDataObject into formatted data array
      this.tableDataFormatted.push(tempTableDataObject);
    });
  }

  handleAmundsenMetadata() {
    this.tableMetaDataColumns = Object.keys(this.tableMetaData[0])
      .filter((col: string) => !this.metaDataColsToHide.includes(col))
      .sort((a, b) => this.metaDataSortOrder.indexOf(a) - this.metaDataSortOrder.indexOf(b));
  }
}
