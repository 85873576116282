import type {
  SourceDataConfidentiality,
  SourceDataSharingLimits,
  SourceDataSourcing,
  SourceGDPRCompliance,
} from '@dataportal/types';
import { Entity } from '@decahedron/entity';

import type { IDataCategorizationFormData } from './source-form';

// Entity
export class DataClassification extends Entity {
  // Attributes
  dataSourcing: SourceDataSourcing = null;
  sourceOrProviderNames: string[] = [];
  dataConfidentiality: SourceDataConfidentiality = null;
  gdprCompliance: SourceGDPRCompliance = null;
  dataSharingLimits: SourceDataSharingLimits = null;
  limitationScope: string = null;

  static fromStepForm(formData: IDataCategorizationFormData): DataClassification {
    const dataClassification = new DataClassification();
    dataClassification.dataSourcing = formData?.dataSourcing;
    dataClassification.sourceOrProviderNames = formData?.sourceOrProviderNames;
    dataClassification.dataConfidentiality = formData?.dataConfidentiality;
    dataClassification.gdprCompliance = formData?.gdprCompliance;
    dataClassification.dataSharingLimits = formData?.dataSharingLimits;
    dataClassification.limitationScope = formData?.limitationScope;

    return dataClassification;
  }

  // Methods
  toJson(): {
    data_sourcing: SourceDataSourcing;
    source_or_provider_names: string[];
    data_confidentiality: SourceDataConfidentiality;
    gdpr_compliance: SourceGDPRCompliance;
    data_sharing_limits: SourceDataSharingLimits;
    limitation_scope: string;
  } {
    return {
      data_sourcing: this.dataSourcing || null,
      source_or_provider_names: this.sourceOrProviderNames?.length ? this.sourceOrProviderNames : [],
      data_confidentiality: this.dataConfidentiality || null,
      gdpr_compliance: this.gdprCompliance || null,
      data_sharing_limits: this.dataSharingLimits || null,
      limitation_scope: this.dataSharingLimits === 'restricted-usage' ? this.limitationScope || null : null,
    };
  }
}
