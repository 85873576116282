import type { OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import type { SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import type { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { GoogleTagManagerService } from '@dataportal/analytics';
import { CurrentUserService, MultiAuthService } from '@dataportal/auth';
import { Logger } from '@dataportal/front-shared';
import { UsersService } from '@dataportal/users';

import { NotificationsV2Service } from '../../services/notifications-V2.service';

import type { INotificationV2 } from '../../entities/notifications';

@Component({
  selector: 'dpg-header-profile',
  templateUrl: './header-profile.component.html',
  styleUrls: ['./header-profile.component.scss'],
})
export class HeaderProfileComponent implements OnInit {
  @Input() color: 'white' | 'blue' = 'white';
  @Input() isOnlyNotificationCenterDisplayed = false;

  show = false;
  name = '';
  profilePictureUrl$: Observable<SafeResourceUrl>;
  notifications: INotificationV2[] = [];

  private readonly _destroyed$ = new Subject<void>();

  // Constructor
  constructor(
    private readonly _router: Router,
    private readonly _gtmService: GoogleTagManagerService,
    private readonly _multiAuthService: MultiAuthService,
    private readonly _logger: Logger,
    private readonly _usersService: UsersService,
    private readonly _currentUserService: CurrentUserService,
    private readonly _notificationV2Service: NotificationsV2Service,
  ) {}

  get bellLink(): string {
    return this.notifications.some((notification) => notification.isSeen === false)
      ? 'bell-new-notification.svg'
      : 'bell.svg';
  }

  // Lifecycle
  ngOnInit(): void {
    this.profilePictureUrl$ = this.getProfilePictureUrl();

    this._notificationV2Service.currentUserNotifications$
      .pipe(takeUntil(this._destroyed$))
      .subscribe((notifications) => (this.notifications = notifications));
  }

  openProfile(): void {
    this._gtmService.pushEvent({ event: 'homepageClickMyProfileBtn' });
    this._router.navigate(['profile']);
  }

  openHelpCenter(): void {
    this._gtmService.pushEvent({ event: 'homepageClickHelpCenterBtn' });
    this._router.navigate(['help-center']);
  }

  logout() {
    this._multiAuthService.logout();
  }

  private getProfilePictureUrl(): Observable<SafeResourceUrl> {
    return this._currentUserService.currentUser$.pipe(
      filter((user) => !!user.id),
      tap((user) => {
        this._logger.debug('[WelcomeHeaderComponent] Current user', user);
        this.name = user.name;
      }),
      switchMap((user) => {
        return this._usersService
          .getImage(user.id)
          .pipe(map((image) => image || '/assets/img/icon/contact-default.png'));
      }),
      takeUntil(this._destroyed$),
    );
  }
}
