import type { OnDestroy } from '@angular/core';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import type { ISelectPredicates } from '@dataportal/adl';
import { AlertService } from '@dataportal/front-shared';
import type { DPGRole, GroupPermission } from '@dataportal/permissions';
import { getRoleData, ROLES } from '@dataportal/permissions';
import type { SourceRole } from '@dataportal/types';

import { CreateOrImportGroupModalComponent } from '../create-or-import-group-modal/create-or-import-group-modal.component';

import type { Group } from '../../entities/group';

// Types
export interface IFormattedGroupRole<R extends DPGRole = DPGRole> {
  key: string;
  name: string;
  role: R;
}

// Utils
export function formatGroupsRoles<R extends DPGRole = DPGRole>(groupRole: GroupPermission): IFormattedGroupRole<R>[] {
  const formatted: IFormattedGroupRole<R>[] = [];

  for (const actualRole of groupRole.roles) {
    formatted.push({
      key: groupRole.groupId,
      name: groupRole.groupName,
      role: actualRole as R,
    });
  }

  return formatted;
}

// Component
@Component({
  selector: 'dpg-add-group-role-modal',
  templateUrl: './add-group-role-modal.component.html',
  styleUrls: ['./add-group-role-modal.component.scss'],
})
export class AddGroupRoleModalComponent implements OnDestroy {
  // Attributes
  defaultRole: DPGRole = 'dashboardReader';
  groupsPermissionsTableHeaders = ['Name', 'Role'];

  selectedGroup: Group;
  addedGroupsRoles: IFormattedGroupRole[] = [];
  groupPredicates: ISelectPredicates<Group> = {
    isNotAlreadySelected: (group: Group): boolean => {
      return !this.addedGroupsRoles.find((addedGroupRole) => addedGroupRole.key === group.id);
    },
  };

  clearSelectList: Subject<void> = new Subject<void>();

  private _roles = ROLES;
  choices: { label: string; value: DPGRole }[] = [];

  private readonly _destroyed$ = new Subject<void>();

  // Constructor
  constructor(
    private readonly _activeMatModal: MatDialogRef<AddGroupRoleModalComponent>,
    private readonly _modalMatService: MatDialog,
    private readonly _alertService: AlertService,
    @Inject(MAT_DIALOG_DATA)
    data: {
      defaultRole: DPGRole;
      roles: DPGRole[];
    },
  ) {
    this.defaultRole = data?.defaultRole?.length ? data.defaultRole : this.defaultRole;
    this.roles = data?.roles?.length ? data.roles : this.roles;
  }

  ngOnDestroy() {
    this._destroyed$.next();
  }

  // Methods
  removeGroupFromList(groupId: string): void {
    this.addedGroupsRoles = this.addedGroupsRoles.filter((addedGroupRole) => addedGroupRole.key !== groupId);
  }

  addGroupToList(): void {
    if (!this.selectedGroup) {
      return;
    }

    this.addedGroupsRoles.push({
      key: this.selectedGroup.id,
      name: this.selectedGroup.name,
      role: this.defaultRole,
    });
    this.clearSelectList.next();
  }

  updateEachGroupRole(newRole: SourceRole): void {
    this.addedGroupsRoles.forEach((addedGroupRole) => (addedGroupRole.role = newRole));
  }

  updateGroupRole(groupId: string, newRole: SourceRole): void {
    this.addedGroupsRoles.find((groupRole) => groupRole.key === groupId).role = newRole;
  }

  cancel(): void {
    this._activeMatModal.close();
  }

  confirm(): void {
    this._activeMatModal.close(this.addedGroupsRoles);
  }

  openGroupCreationModal(): void {
    this._modalMatService
      .open(CreateOrImportGroupModalComponent, {
        width: '700px',
        minWidth: '700px',
        maxHeight: '90vh',
        backdropClass: 'modal-nested',
        panelClass: 'overflowable-modal',
      })
      .afterClosed()
      .pipe(take(1), takeUntil(this._destroyed$))
      .subscribe((hasGroupBeenCreated?: boolean) => {
        if (hasGroupBeenCreated) {
          this.clearSelectList.next();
        }
      });
  }

  // Properties
  get roles(): DPGRole[] {
    return this._roles;
  }

  set roles(roles: DPGRole[]) {
    this._roles = roles;
    this.choices = getRoleData(...roles).map((d) => ({ label: d.label, value: d.role }));
  }
}
