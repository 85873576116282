<div class="content" [ngStyle]="{ width: searchBarSize }">
  <adl-input
    (click)="inputClicked($event)"
    [control]="control"
    [value]="control.value"
    [placeholder]="placeholder"
    [customContent]="searchBarType === 'advanced'"
    [icon]="icon"
    [iconAsPrefix]="true"
    [isRounded]="searchBarType === 'advanced'"
    [hasBorder]="hasBorder"
    [fixedHeight]="searchBarHeight"
    (keyup.enter)="onSearchButtonClick()"
    [dropdownTriggerFor]="dropdown"
  >
    <adl-icon prefix [size]="15" class="ml-[20px] search-icon" icon="search" [iconType]="iconType"></adl-icon>
    <div suffix *ngIf="searchBarType === 'advanced'" class="flex items-center justify-center w-fit cursor-pointer">
      <div
        *ngIf="searchFilterContainerList?.length"
        class="filter-btn w-fit flex items-center cursor-pointer text-[12px]"
        (click)="openFilter($event)"
      >
        <img src="assets/dpg/imgs/icons/filter-icon.svg" alt="filter-icon" />
        <span class="ml-3 mr-3">
          {{ basicFilterTitle }}
          <span *ngIf="hasFiltersApplied$ | async" class="pl-2"> ({{ filterCount$ | async }}) </span>
        </span>
      </div>
      <div *ngIf="searchFilterContainerList?.length && additionalFilterList?.length" class="divider ml-6 mr-6"></div>
      <ng-container *ngIf="additionalFilterList?.length">
        <div class="additional-btn w-fit flex items-center mr-12 cursor-pointer" (click)="openAdditionalFilter($event)">
          <ng-content select="[additionalFiltersIcon]"></ng-content>
          <adl-loaded-item [loading]="isLoadingAdditionalFilter" [iconSize]="40">
            <span class="ml-2">{{ additionalSelectedFilter }}</span>
          </adl-loaded-item>
          <ng-content select="[infoBubbleIcon]"></ng-content>
        </div>
      </ng-container>
      <adl-button
        *ngIf="hasToDisplaySearchButton"
        (click)="onSearchButtonClick()"
        [isAutoHeight]="true"
        [size]="buttonSize"
        type="papyrus"
      >
        Search
      </adl-button>
    </div>
  </adl-input>
</div>
<adl-basic-filter
  *ngIf="isFilterOpen && searchFilterContainerList?.length"
  [filterContainers]="searchFilterContainerList"
  [cardSize]="filterCardSize ? filterCardSize : searchBarSize"
  [hasCaret]="hasCaret"
  [clearException]="filterClearException"
></adl-basic-filter>
<adl-search-bar-suggestions
  *ngIf="isSuggestionsOpen && recentSearchKey"
  [recentSearchKey]="recentSearchKey"
  [suggestedItems]="suggestedItems"
  [isLoadingItems]="isLoadingItem"
  [hasCaret]="hasCaret"
  [isStorageKeyValue]="isStorageKeyValue"
  (openSuggestionItems)="onSuggestionItemsOpened($event)"
></adl-search-bar-suggestions>
<adl-search-bar-additional-filter
  *ngIf="isAdditionalFilterOpen && additionalFilterList?.length"
  [additionalFilterList]="additionalFilterList"
  [selectedFilter]="additionalSelectedFilter"
  [hasCaret]="hasCaret"
  (updateSelectedFilter)="updateAdditionalFilter($event)"
></adl-search-bar-additional-filter>
