import { CommonModule } from '@angular/common';
import type { ModuleWithProviders } from '@angular/core';
import { NgModule } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { AdlModule } from '@dataportal/adl';
import { ApiModule } from '@dataportal/front-api';
import { GraphApiModule } from '@dataportal/front-graph-api';
import { SharedModule } from '@dataportal/front-shared';
import { UsersModule } from '@dataportal/users';

import { PermissionsService } from './services/permissions.service';

import { RoleDataPipe } from './pipes/role-data.pipe';

import { AddUserRoleModalComponent } from './components/add-user-role-modal/add-user-role-modal.component';
import { RolesInfoBubbleComponent } from './components/roles-info-bubble/roles-info-bubble.component';

import { IsAuthorizedDirective } from './directives/is-authorized.directive';

import type { IPermissionsDefaultedOptions, IPermissionsOptions } from './permissions-options';
import { PERMISSIONS_OPTIONS } from './permissions-options';

// Utils
function applyDefaults(options: IPermissionsDefaultedOptions): IPermissionsOptions {
  let { errorRouterLink = ['error'] } = options;

  if (typeof errorRouterLink === 'string') {
    errorRouterLink = [errorRouterLink];
  }

  return { errorRouterLink };
}

// Module
// @dynamic
@NgModule({
  imports: [
    CommonModule,
    GraphApiModule,
    MatDialogModule,
    RouterModule.forChild([]),
    AdlModule,
    ApiModule,
    SharedModule,
    UsersModule,
  ],
  declarations: [AddUserRoleModalComponent, IsAuthorizedDirective, RolesInfoBubbleComponent, RoleDataPipe],
  providers: [],
  exports: [AddUserRoleModalComponent, IsAuthorizedDirective, RolesInfoBubbleComponent, RoleDataPipe],
})
export class PermissionsModule {
  // Statics
  static forRoot(options: IPermissionsDefaultedOptions = {}): ModuleWithProviders<PermissionsModule> {
    return {
      ngModule: PermissionsModule,
      providers: [
        PermissionsService,
        { provide: PERMISSIONS_OPTIONS, useValue: applyDefaults(options) },
        { provide: MAT_DIALOG_DATA, useValue: {} },
      ],
    };
  }
}
