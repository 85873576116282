import type { OnDestroy, OnInit } from '@angular/core';
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

@Component({
  selector: 'adl-search-bar-suggestions',
  templateUrl: './suggestions.component.html',
  styleUrls: ['./suggestions.component.scss'],
})
export class SearchBarSuggestionsComponent implements OnInit, OnDestroy {
  @Input() recentSearchKey: string;
  @Input() suggestedItems: any[] = [];
  @Input() isLoadingItems = true;
  @Input() hasCaret: boolean;
  @Input() isStorageKeyValue = false;
  @Output() openSuggestionItems = new EventEmitter<string>();

  private readonly _destroyed$ = new Subject<void>();
  recentItems: string[] | { label: string; value: string }[] = [];

  @ViewChild('searchBar', { read: ElementRef }) searchBarElement: ElementRef;

  constructor(private readonly _router: Router) {}

  ngOnInit() {
    this.recentItems = localStorage.getItem(this.recentSearchKey)
      ? JSON.parse(localStorage.getItem(this.recentSearchKey))
      : [];
  }

  ngOnDestroy() {
    this._destroyed$.next();
  }

  openItem(item: string) {
    this.openSuggestionItems.emit(item);
  }

  resetRecentSearch() {
    localStorage.removeItem(this.recentSearchKey);
    this.recentItems = [];
  }

  valueToDisplay(item): string {
    return this.isStorageKeyValue ? item.label : item;
  }

  valueToSend(item): string {
    return this.isStorageKeyValue ? item.pk : item.name;
  }

  storageToSend(item): string {
    return this.isStorageKeyValue ? item.value : item.name;
  }
}
