import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';

export interface IToggleOption {
  onChanged: (element: unknown) => void;
  isActive: boolean;
  label: string;
  isDisabled?: boolean;
  tooltip?: string;
}

@Component({
  selector: 'adl-picture-with-toggles',
  templateUrl: './picture-with-toggles.component.html',
  styleUrls: ['./picture-with-toggles.component.scss'],
})
export class PictureWithTogglesComponent<T> {
  @Input() areTogglesDownSide = true;
  @Input() element: T;
  @Input() isLoadingPicture = true;
  @Input() pictureSrc: SafeUrl;
  @Input() title = '';
  @Input() subTitle = '';
  @Input() togglesOptions: IToggleOption[];
  @Input() areTogglesVisible = true;

  @Output() onToggleOption: EventEmitter<IToggleOption> = new EventEmitter<IToggleOption>();
  @Output() onClickRemoveElement: EventEmitter<T> = new EventEmitter<T>();

  onToggle(index: number): void {
    const toggleOption = this.togglesOptions[index];
    toggleOption.onChanged(this.element);
    this.onToggleOption.emit(toggleOption);
  }

  onClickRemove(): void {
    this.onClickRemoveElement.emit(this.element);
  }
}
