<div *ngIf="vm$ | async as vm" class="grid grid-cols-[auto_1fr] grid-rows-[auto_auto] gap-x-[20px] gap-y-[15px]">
  <dpg-buckets-list
    [buckets]="vm.buckets"
    [selectedBucket]="vm.currentBucket"
    [isLoading]="vm.isLoadingBuckets"
    [bucketsSearchQuery]="vm.bucketsSearchQuery"
    (bucketSelect)="onBucketSelect($event)"
    (bucketsSearchQueryChange)="onBucketsSearchQueryChange($event)"
  ></dpg-buckets-list>

  <section>
    <header class="flex justify-between align-center mb-[11px]">
      <adl-breadcrumb
        *ngIf="!!vm.firstBreadcrumbUrl"
        [breadcrumbs]="vm.breadcrumbs"
        breadcrumbType="action"
        breadcrumbTheme="grey-blue"
        [firstLink]="vm.firstBreadcrumbUrl"
        [cursorPointer]="true"
        (hasSelectBreadcrumb)="onSelectBreadcrumb($event, vm.breadcrumbs)"
        (hasSelectBreadcrumbOrigin)="onReturnToBucket(vm.currentBucket)"
      ></adl-breadcrumb>

      <adl-search-bar
        placeholder="Search"
        iconType="grey-300"
        searchBarType="advanced"
        [hasBorder]="false"
        [control]="objectsSearchFormControl"
        (changed)="onObjectsSearchQueryChange($event)"
      ></adl-search-bar>
    </header>

    <dpg-objects-table
      [objects]="vm.objectsList"
      [selectedObjectsKeys]="vm.selectedObjectsKeys"
      [isLoading]="vm.isLoadingObjects"
      [isBucketSelected]="!!vm.currentBucket"
      [areAllObjectsSelected]="vm.areAllObjectsSelected"
      [shouldHideCheckboxAll]="vm.shouldHideCheckboxAll"
      (objectClick)="onObjectClick($event)"
      (objectSelectionToggle)="onObjectSelectionToggle($event)"
      (toggleAllObjectsSelection)="onAllObjectsSelectionToggle($event)"
    >
    </dpg-objects-table>
  </section>

  <div class="col-span-2">
    <adl-text *ngIf="vm.selectedObjects.length === 0; else selectedObjectsList" type="accent" class="no-selected"
      >No folder selected yet.
    </adl-text>

    <ng-template #selectedObjectsList>
      <div class="flex flex-col gap-4">
        <adl-text type="accent">
          Selected folder{{
            vm.selectedObjects.length > 1 || (vm.selectedObjects.length === 0 && isMultipleSelect) ? 's' : ''
          }}
          <span class="text-blue-400">({{ vm.selectedObjects.length }})</span>
        </adl-text>
      </div>

      <div class="gap-[10px] col-span-2 flex flex-wrap pt-[10px] max-h-[98px] overflow-auto">
        <dpg-selected-object
          *ngFor="let selectedObject of vm.selectedObjects"
          [selectedObject]="selectedObject"
          (navigateTo)="onObjectClick(selectedObject)"
          (deleteObject)="onRemoveSelectedObject(selectedObject)"
        ></dpg-selected-object>
      </div>
    </ng-template>
  </div>
</div>
