import type { OnDestroy, OnInit } from '@angular/core';
import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { filter, first, switchMap, takeUntil, tap } from 'rxjs/operators';
import { DialogsService } from '@dataportal/adl';
import { CurrentUserService } from '@dataportal/auth';
import { EnvironmentService } from '@dataportal/front-environment';
import { DatalakePath, Logger } from '@dataportal/front-shared';

import { DatalakeStoreService } from '../../services/datalake-store.service';
import { ExplorerService } from '../../services/explorer.service';
import { ExplorerPageService } from '../../services/explorer-page.service';
import { ExplorerTreeService } from '../../services/explorer-tree.service';

import { CantFindYourFolderModalComponent } from '../cant-find-your-folder-modal/cant-find-your-folder-modal.component';

import type { IBucket } from '../../entities/datalake';

type ListedObject = IObject | IFilesystem;

interface IListedObject {
  isFilesystem: boolean;
  isFolder: boolean;
  filesystem: IBucket;
  name: string;
}

export interface IObject extends IListedObject {
  isFilesystem: false;
  path: string; // empty for root, null for filesystems, does not contain folder name
  provider: 'aws' | 'azure';
  tenant: string;
  isLoading?: boolean;
  isExisting?: boolean;
}

interface IFilesystem extends IListedObject {
  isFilesystem: true;
  isFolder: false;
}

const buildFolderPath = (folder: IObject): string => {
  const builtPath = folder.filesystem.name;

  return builtPath + (folder.path ? `/${folder.path}/` : '/') + folder.name;
};

@Component({
  selector: 'dpg-datalake-object-selector-modal',
  templateUrl: './datalake-object-selector-modal.component.html',
  styleUrls: ['./datalake-object-selector-modal.component.scss'],
})
export class DatalakeObjectSelectorModalComponent implements OnInit, OnDestroy {
  @Input() isSelectingFiles = false; // only selecting folders by default
  @Input() isMultipleSelect = true;
  @Input() isListingUsingOwnToken = true;
  @Input() defaultFolder: IObject;
  @Input() selectedObjects: IObject[] = [];
  @Input() doNotPreCharge = false;
  @Input() title = 'Select the folder containing your source files';

  actualBucket: IBucket;
  actualTenant: string;
  breadcrumbs: string[] = [];
  filesystems: IBucket[];
  listedObjects: ListedObject[] = [];
  isLoadingFirstPage = false;
  isLoadingBuckets = false;
  root = true;

  private readonly _destroyed$ = new Subject<void>();

  constructor(
    private readonly _explorerService: ExplorerService,
    private readonly _explorerPageService: ExplorerPageService,
    private readonly _explorerTreeService: ExplorerTreeService,
    readonly environmentService: EnvironmentService,
    private readonly _logger: Logger,
    private readonly _currentUserService: CurrentUserService,
    private readonly _datalakeStoreService: DatalakeStoreService,
    private readonly _dialogServices: DialogsService,
    private readonly _activeMatModal: MatDialogRef<DatalakeObjectSelectorModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    data: {
      isSelectingFiles: boolean;
      isMultipleSelect: boolean;
      isListingUsingOwnToken: boolean;
      defaultFolder: IObject;
      selectedObjects: IObject[];
      doNotPreCharge: boolean;
      title: string;
    },
  ) {
    this.isSelectingFiles = Object.keys(data || {}).includes('isSelectingFiles')
      ? data.isSelectingFiles
      : this.isSelectingFiles;
    this.isMultipleSelect = Object.keys(data || {}).includes('isMultipleSelect')
      ? data.isMultipleSelect
      : this.isMultipleSelect;
    this.isListingUsingOwnToken = Object.keys(data || {}).includes('isListingUsingOwnToken')
      ? data.isListingUsingOwnToken
      : this.isListingUsingOwnToken;
    this.defaultFolder = data?.defaultFolder ? data.defaultFolder : this.defaultFolder;
    this.selectedObjects = data?.selectedObjects?.length ? data.selectedObjects : this.selectedObjects;
    this.doNotPreCharge = Object.keys(data || {}).includes('doNotPreCharge')
      ? data.doNotPreCharge
      : this.doNotPreCharge;
    this.title = data?.title?.length ? data.title : this.title;
  }

  ngOnInit(): void {
    this._explorerTreeService.toggleExplorerTree(false);

    this._currentUserService.isAdmin$
      .pipe(
        tap(
          (isCurrentUserAdmin) =>
            (this.isListingUsingOwnToken = this.isListingUsingOwnToken ? !isCurrentUserAdmin : false),
        ),
        switchMap(() => this._explorerService.current$),
        first(),
      )
      .subscribe((currentDatalakeDirectory) => {
        if (this.defaultFolder || this.existingSelectedObjects.length >= 1) {
          this.navigate(this.defaultFolder || this.existingSelectedObjects[0], true);
        } else if (!currentDatalakeDirectory || !currentDatalakeDirectory?.bucket || !currentDatalakeDirectory?.path) {
          this._listBuckets();
        } else {
          this.actualBucket = currentDatalakeDirectory.bucket;
          this.actualTenant = currentDatalakeDirectory.tenant;
          this.breadcrumbs = currentDatalakeDirectory.fullPath.split('/');
        }
      });

    this._explorerPageService.isLoadingFirstPage$.pipe(takeUntil(this._destroyed$)).subscribe((isLoading) => {
      this.isLoadingFirstPage = isLoading;
    });

    this._explorerPageService.objects$.pipe(takeUntil(this._destroyed$)).subscribe((objects) => {
      this.root = false;
      this.listedObjects = objects
        .filter((datalakeObject) => (this.isSelectingFiles ? true : datalakeObject.type === 'folder'))
        .map((datalakeObject) => ({
          isFilesystem: false,
          isFolder: datalakeObject.type === 'folder',
          filesystem: DatalakeObjectSelectorModalComponent.filesystemNameToBucket(datalakeObject.bucket),
          path: datalakeObject.path,
          name: datalakeObject.name,
          provider: datalakeObject.provider as 'aws' | 'azure',
          tenant: datalakeObject.tenant,
          isLoading: false,
          isExisting: true,
        }));
    });

    this._explorerService.current$
      .pipe(
        filter((current) => !!current),
        takeUntil(this._destroyed$),
      )
      .subscribe((current) => {
        this._logger.debug('Current updated');
        this.breadcrumbs = current.breadcrumbs;
        this._logger.debug({ breadcrumbs: this.breadcrumbs });
      });
  }

  get existingSelectedObjects(): IObject[] {
    return this.selectedObjects?.filter((object) => object.isExisting);
  }

  getBuiltFolderRelatedPath(folder: IObject): string {
    return buildFolderPath(folder);
  }

  isObjectExisting(selectedObject: IObject): boolean {
    return selectedObject && !selectedObject.isLoading && selectedObject.isExisting;
  }

  isAllowedToNavigateToObject(selectedObject: IObject): boolean {
    return !this.isLoadingFirstPage && this.isObjectExisting(selectedObject);
  }

  static filesystemNameToBucket(filesystemName: string): IBucket {
    return { name: filesystemName, primary: true, category: null };
  }

  static datalakePathToFolder(
    datalakePath: DatalakePath,
    isLoading: boolean,
    isExisting: boolean,
    hasToRemoveName = true,
  ): IObject {
    const splitPath = datalakePath.path.split('/');
    const name = splitPath.pop();
    const bucketName = splitPath.shift();

    return {
      isFilesystem: false,
      isFolder: true,
      filesystem: DatalakeObjectSelectorModalComponent.filesystemNameToBucket(bucketName),
      path: (hasToRemoveName ? splitPath : [...splitPath, name]).join('/'),
      name: name,
      provider: datalakePath.provider,
      tenant: datalakePath.tenant,
      isLoading: isLoading,
      isExisting: isExisting,
    };
  }

  static selectedObjectToDatalakePath(selectedObject: IObject): DatalakePath {
    return new DatalakePath(buildFolderPath(selectedObject), selectedObject.provider, selectedObject.tenant);
  }

  private _resetBuckets(buckets: IBucket[]): void {
    this.listedObjects = buckets
      .filter((bucket) => bucket.primary)
      .map((filesystem) => ({
        isFilesystem: true,
        isFolder: false,
        filesystem: filesystem,
        name: filesystem.name,
      }));
  }

  private _listBuckets(): void {
    this.isLoadingBuckets = true;
    this._datalakeStoreService
      .listBuckets()
      .pipe(takeUntil(this._destroyed$))
      .subscribe((buckets) => {
        this.filesystems = buckets;
        this._resetBuckets(buckets);
        this.root = true;
        this.isLoadingBuckets = false;
      });
  }

  navigate(object: ListedObject, fromDefault = false): void {
    if (!this.isLoadingFirstPage) {
      this.actualBucket = object.filesystem;
      let path = object.isFilesystem ? '' : (object as IObject).path;

      if (object.isFilesystem) {
        this.actualTenant = null;
        this._explorerService.setCurrent(
          this.actualBucket,
          this.isListingUsingOwnToken,
          path,
          this.environmentService.options.dpContext.code === 'JCD' ? 'aws' : 'azure',
          null,
        );
      } else if (object.isFolder) {
        if (!fromDefault) {
          path = path + '/' + object.name;
        }

        this.actualTenant = (object as IObject).tenant;
        this._explorerService.setCurrent(
          this.actualBucket,
          this.isListingUsingOwnToken,
          path,
          this.environmentService.options.dpContext.code === 'JCD' ? 'aws' : 'azure',
          (object as IObject).tenant,
        );
      }
    }
  }

  back(): void {
    if (!this.root && this.breadcrumbs.length > 1 && !this.isLoadingFirstPage) {
      const previous = this.breadcrumbs.slice(1, -1).join('/');

      if (previous === '') {
        this.actualTenant = null;
      }

      this._explorerService.setCurrent(
        this.actualBucket,
        this.isListingUsingOwnToken,
        previous,
        this.environmentService.options.dpContext.code === 'JCD' ? 'aws' : 'azure',
        this.actualTenant,
      );
    } else if (!this.root && this.breadcrumbs.length === 1) {
      this.breadcrumbs = [];
      this._listBuckets();
      this.actualTenant = null;
    }
  }

  private static _areObjectsDifferent(sObject: IObject, object: IObject) {
    return (
      sObject.path !== object.path ||
      sObject.name !== object.name ||
      sObject.tenant !== object.tenant ||
      sObject.filesystem.name !== object.filesystem.name
    );
  }

  isElementChecked(object: IObject): boolean {
    return !!this.selectedObjects.find(
      (selectedObject) => !DatalakeObjectSelectorModalComponent._areObjectsDifferent(object, selectedObject),
    );
  }

  hasToEnableSelection(object: IObject): boolean {
    return !this.isSelectingFiles || (this.isSelectingFiles && !object.isFolder);
  }

  toggleSelection(object: IObject): void {
    if (this.hasToEnableSelection(object)) {
      if (
        this.selectedObjects.find(
          (selectedObject) => !DatalakeObjectSelectorModalComponent._areObjectsDifferent(selectedObject, object),
        )
      ) {
        this.selectedObjects = this.selectedObjects.filter((sObject) =>
          DatalakeObjectSelectorModalComponent._areObjectsDifferent(sObject, object),
        );
      } else if (this.isMultipleSelect) {
        this.selectedObjects.push(object);
      } else {
        this.selectedObjects = [object];
      }
    }
  }

  openCantFindMyFolderModal(): void {
    this._dialogServices.open(
      CantFindYourFolderModalComponent,
      {},
      {
        width: '1050px',
        maxHeight: '98vh',
      },
    );
  }

  cancel(): void {
    this._activeMatModal.close();
  }

  confirm(): void {
    const resultingDatalakePaths = this.selectedObjects?.length
      ? this.selectedObjects.map((selectedObject) =>
          DatalakeObjectSelectorModalComponent.selectedObjectToDatalakePath(selectedObject),
        )
      : [];
    this._activeMatModal.close(resultingDatalakePaths);
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
  }
}
