import { Company } from '@dataportal/companies';
import type { IPortal } from '@dataportal/types';
import { Entity, Type } from '@decahedron/entity';

export interface IPortalTree {
  id: string;
  name: string;
  level: number;
  parent: { id: string; name: string };
}

export class Portal extends Entity {
  id: string = null;
  name: string = null;
  type: string = null;
  activated = false;
  background: string = null;
  owners: string[] = [];
  parent?: {
    id: string;
    name: string;
  };
  visible = false;

  @Type(Company)
  companies: Company[] = [];

  fromJson(data: IPortal): Portal {
    super.fromJson(data);
    this.id = data.pk;
    this.parent = Object.prototype.hasOwnProperty.call(data, 'parent') ? data.parent : null;

    return this;
  }

  toJson(): any {
    return {
      activated: this.activated,
      background: this.background && this.background.length > 0 ? this.background : null,
      owners: this.owners && this.owners.length > 0 ? this.owners : [],
      companies:
        this.companies && this.companies.length > 0
          ? this.companies.map((c) => ({
              companyId: c.companyId,
              companyName: c.companyName,
            }))
          : [],
    };
  }

  get status(): 'activated' | 'disabled' {
    return this.activated ? 'activated' : 'disabled';
  }
}
