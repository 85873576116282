import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Dashboard } from '../../entities/dashboard.model';

import { Source } from '../../../sources/entities/source';

@Component({
  selector: 'dpg-dashboard-card',
  templateUrl: './dashboard-card.component.html',
  styleUrls: ['./dashboard-card.component.scss'],
})
export class DashboardCardComponent {
  // Attributes
  // Inputs
  @Input() source: Source;
  @Input() dashboard: Dashboard;
  @Input() isFavoriteSource: boolean;
  @Input() isActive: boolean;
  @Input() isOwner? = false;
  // Outputs
  @Output() clickToggleFavoriteDashboard = new EventEmitter<Dashboard>();
  @Output() clickOpenDashboard = new EventEmitter<Dashboard>();

  toggleFavoriteDashboard(): void {
    this.clickToggleFavoriteDashboard.emit(this.dashboard);
  }

  openDashboard(): void {
    this.clickOpenDashboard.emit(this.dashboard);
  }
}
