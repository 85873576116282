import { CommonModule } from '@angular/common';
import type { ModuleWithProviders } from '@angular/core';
import { NgModule } from '@angular/core';
import { AccessRequestsModule } from '@dataportal/access-requests';
import { AdlModule } from '@dataportal/adl';
import { AuthModule } from '@dataportal/auth';
import { GlossaryCommonModule } from '@dataportal/business-glossary-common';
import { ApiModule } from '@dataportal/front-api';
import { SharedModule } from '@dataportal/front-shared';
import { PublicationsModule } from '@dataportal/publications';
import { WebsocketModule } from '@dataportal/websocket';

import { NotificationsService } from './services/notifications.service';
import { NotificationsV2Service } from './services/notifications-V2.service';

import { HeaderProfileComponent } from './components/header-profile/header-profile.component';
import { NotificationCenterComponent } from './components/header-profile/notification-center/notification-center.component';
import { NotificationContentComponent } from './components/header-profile/notification-center/notification-content/notification-content.component';
import { NotificationHeaderComponent } from './components/notification-header/notification-header.component';
import { SectionNotifierComponent } from './components/section-notifier/section-notifier.component';

import type { INotificationsOptions } from './notifications-options';
import { NOTIFICATIONS_OPTIONS } from './notifications-options';

// Module
@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    AdlModule,
    ApiModule,
    AuthModule,
    WebsocketModule,
    AccessRequestsModule,
    PublicationsModule,
    GlossaryCommonModule,
  ],
  declarations: [
    SectionNotifierComponent,
    NotificationContentComponent,
    NotificationCenterComponent,
    HeaderProfileComponent,
    NotificationHeaderComponent,
  ],
  exports: [SectionNotifierComponent, HeaderProfileComponent, NotificationHeaderComponent],
  providers: [NotificationsService, NotificationsV2Service],
})
export class NotificationsModule {
  // Statics
  static forRoot(options: INotificationsOptions): ModuleWithProviders<NotificationsModule> {
    return {
      ngModule: NotificationsModule,
      providers: [{ provide: NOTIFICATIONS_OPTIONS, useValue: options }],
    };
  }
}
