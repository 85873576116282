<adl-modal
  [isAlignedToCross]="true"
  [hasMarginBottom]="false"
  [hasBodySidesPadding]="false"
  [roundCorner]="true"
  headerBackgroundColor="blue-dark"
  backgroundColor="light"
  closeButtonColor="white"
>
  <adl-logo-modal-header
    modal-header
    class="w-full"
    iconLink="assets/dpg/imgs/icons/guardian-logo.svg"
    [hasSecondaryPart]="true"
    [title]="getModalTitle()"
    [secondaryTitle]="getModalSecondaryTitle()"
  >
    <adl-button
      type="guardian-light"
      class="mr-20 openGuardianInfosButtonBg"
      size="normal"
      (clicked)="this.openGuardianInfos()"
      [isSquareButton]="true"
    >
      <adl-text color="secondary-500">Show checks configurations</adl-text>
    </adl-button>
  </adl-logo-modal-header>
  <adl-loaded-item modal-body class="modal-body max-h-[33vw] pt-[30px] pb-[30px]" [loading]="isLoadingChecksList">
    <dpg-guardian-check-run-details
      *ngIf="selectedCheck; else showList"
      class="w-9/10"
      [guardianStatus]="guardianStatus"
      [selectedCheck]="selectedCheck"
    ></dpg-guardian-check-run-details>
    <ng-template #showList>
      <dpg-guardian-check-runs-list
        class="w-9/10"
        [guardianStatus]="guardianStatus"
        [checks]="checks"
        (selectedCheck)="this.showDetails($event)"
      ></dpg-guardian-check-runs-list>
    </ng-template>
  </adl-loaded-item>
  <div modal-footer class="row-flex justify-center pb-[30px]">
    <adl-button class="mr-[20px]" *ngIf="!selectedCheck && canRequestCheck" (clicked)="requestCheck()"
      >Run new check</adl-button
    >
    <adl-button class="mr-[20px]" *ngIf="selectedCheck" (clicked)="back()">Back</adl-button>
    <adl-button (clicked)="close()" type="cancel"> Close</adl-button>
  </div>
</adl-modal>
