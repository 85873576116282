<adl-modal>
  <div modal-header class="impersonate-modal-header w-full">
    <span>View as</span>
  </div>
  <div modal-body class="impersonate-modal-body">
    <div class="input-title mb-4">Select a user to view as:</div>
    <dpg-user-select class="w-full" (selected)="selectUser($event)" [clear]="clearSelectList"></dpg-user-select>
  </div>
  <div modal-footer class="flex justify-center">
    <adl-button (clicked)="cancel()" type="cancel" class="cancel-btn mr-4" size="fluid-normal">CANCEL</adl-button>
    <adl-button (clicked)="impersonate()" [disabled]="!selectedLimitedUser" class="mr-4" size="fluid-normal">VIEW AS...</adl-button>
  </div>
</adl-modal>
