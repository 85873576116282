import { Injectable } from '@angular/core';
import { factories, service } from 'powerbi-client';

// Types
@Injectable()
export class PowerBiService extends service.Service {
  // Constructor
  constructor() {
    super(factories.hpmFactory, factories.wpmpFactory, factories.routerFactory);
  }
}
