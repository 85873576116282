import type { OnInit } from '@angular/core';
import { Component, HostListener, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { debounceTime } from 'rxjs/operators';
import { FormControl } from '@ngneat/reactive-forms';

@Component({
  selector: 'adl-input-modal',
  templateUrl: './input-modal.component.html',
})
export class InputModalComponent implements OnInit {
  @Input() text: string;
  @Input() title: string;
  @Input() defaultText: string;
  @Input() confirmText: string;
  @Input() cursorAtTheStart = false;
  @Input() verificationMethod: (string) => { isValid: boolean; errorMessage?: string } = () => ({
    isValid: true,
  });

  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.confirm();
    }
  }

  errorMessage: string;
  inputControl: FormControl<string> = new FormControl<string>();

  constructor(
    private readonly _activeMatModal: MatDialogRef<InputModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    data: {
      text: string;
      title: string;
      defaultText: string;
      confirmText: string;
      cursorAtTheStart: boolean;
      verificationMethod: (string) => { isValid: boolean; errorMessage?: string };
    },
  ) {
    this.text = data?.text?.length ? data.text : this.text;
    this.title = data?.title?.length ? data.title : this.title;
    this.defaultText = data?.defaultText?.length ? data.defaultText : this.defaultText;
    this.confirmText = data?.confirmText?.length ? data.confirmText : this.confirmText;
    this.cursorAtTheStart = Object.keys(data || {}).includes('cursorAtTheStart')
      ? data.cursorAtTheStart
      : this.cursorAtTheStart;
    this.verificationMethod = data?.verificationMethod ? data.verificationMethod : this.verificationMethod;
  }

  ngOnInit(): void {
    if (this.defaultText?.length) {
      this.inputControl.setValue(this.defaultText);
    }

    this.inputControl.value$.pipe(debounceTime(250)).subscribe((newValue) => {
      if (newValue) {
        const checkResult = this.verificationMethod(newValue);

        if (!checkResult.isValid) {
          this.errorMessage = checkResult.errorMessage;
        } else {
          this.errorMessage = null;
        }
      } else {
        this.errorMessage = null;
      }
    });
  }

  confirm(): void {
    this._activeMatModal.close(this.inputControl.value);
  }
}
