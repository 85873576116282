<adl-tooltip
  [isClickBehaviorActivated]="false"
  [isMouseOverBehaviorActivated]="this.hasTooltip"
  [isMouseOutBehaviorActivated]="this.hasTooltip"
>
  <adl-text *ngIf="this.hasTooltip && this.tooltipMessage?.length" type="tooltip" size="12px" tooltip-content>{{
    this.tooltipMessage
  }}</adl-text>
  <adl-icon
    tooltip-trigger
    *ngIf="iconButtonsFa.includes(icon); else svg"
    (click)="this.onClick($event)"
    (mouseover)="this.onMouseOver($event)"
    (mouseout)="this.onMouseOut($event)"
    [size]="size"
    [icon]="icon"
    [isIconSpinning]='isIconSpinning'
    [iconType]="iconType"
    class="icon-button"
    data-cy="icon-button"
    [ngClass]="{ disabled: this.disabled }"
  >
  </adl-icon>

  <ng-template #svg tooltip-trigger>
    <adl-svg-icon
      (click)="this.onClick($event)"
      (mouseover)="this.onMouseOver($event)"
      (mouseout)="this.onMouseOut($event)"
      [size]="size"
      [icon]="icon"
      [isIconSpinning]='isIconSpinning'
      [iconType]="iconType"
      class="icon-button"
      data-cy="icon-button"
      [ngClass]="{ disabled: this.disabled }"
    ></adl-svg-icon>
  </ng-template>
</adl-tooltip>
