import type { OnInit } from '@angular/core';
import { Component, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import type { AccessRequestV2, IARResourceRolesMap } from '@dataportal/access-requests';
import { AccessRequestsV2Service } from '@dataportal/access-requests';
import { AlertService } from '@dataportal/front-shared';
import type { DPGRole, IRoleData } from '@dataportal/permissions';
import { getRoleData } from '@dataportal/permissions';
import type { Source } from '@dataportal/sources-dashboards-recommendations';

@Component({
  selector: 'dataportal-source-permission-modal',
  templateUrl: './source-permission-modal.component.html',
  styleUrls: ['./source-permission-modal.component.scss'],
})
export class SourcePermissionModalComponent implements OnInit {
  constructor(
    private readonly _activeMatModal: MatDialogRef<SourcePermissionModalComponent>,
    private readonly _accessRequestService: AccessRequestsV2Service,
    private readonly _alertService: AlertService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      resource: Source;
      currentRole: IRoleData | null;
      groupRoles: { group: string; role: IRoleData }[] | null;
      accessRequestPending: AccessRequestV2 | null;
      modalType: 'my-permissions' | 'requested-access';
    },
  ) {}

  roleControl = new FormControl();
  resource: Source;
  kind: keyof IARResourceRolesMap = 'sources';
  userRoleOnSource: IRoleData;
  groupRoles: { group: string; role: IRoleData }[] = [];
  accessRequestPending: IRoleData;
  accessRequestDate: Date;
  loadingAccessRequest = false;
  modalType: 'my-permissions' | 'requested-access';
  shouldCancelRequest = false;

  ngOnInit(): void {
    this.resource = this.data.resource;
    this.userRoleOnSource = this.data.currentRole || null;
    this.groupRoles = this.data.groupRoles;

    if (this.data.accessRequestPending) {
      this.accessRequestPending = getRoleData(this.data.accessRequestPending.requestedRole as DPGRole)[0];
      this.accessRequestDate = new Date(this.data.accessRequestPending.requestDate);
    }

    this.modalType = this.data.modalType || 'my-permissions';

    if (this.modalType === 'my-permissions') {
      this.roleControl.setValue(this.data.currentRole ? this.data.currentRole.role : 'dashboardReader');
    } else {
      this.roleControl.setValue(this.data.accessRequestPending.requestedRole);
    }
  }

  submitNewPermissions() {
    this.loadingAccessRequest = true;
    this._accessRequestService.create(this.kind, this.data.resource.id, this.roleControl.value).subscribe(
      (ar) => {
        this.loadingAccessRequest = false;
        this._alertService.success('Access successfully requested');
        this._activeMatModal.close(ar);
      },
      () => {
        this.loadingAccessRequest = false;
        this._alertService.error('Failed to request access.');
      },
    );
  }

  submitAccessRequest() {
    this.loadingAccessRequest = true;

    if (this.shouldCancelRequest) {
      this.cancelAccessRequest(true);
    } else {
      this._accessRequestService.create(this.kind, this.data.resource.id, this.roleControl.value).subscribe(
        (ar) => {
          this.loadingAccessRequest = false;
          this._alertService.success('Access successfully modified');
          this._activeMatModal.close(ar);
        },
        () => {
          this.loadingAccessRequest = false;
          this._alertService.error('Failed to request access.');
        },
      );
    }
  }

  cancelAccessRequest(closeModal = false) {
    this.loadingAccessRequest = true;
    this._accessRequestService.cancel('sources', this.resource.id).subscribe(
      () => {
        this.loadingAccessRequest = false;
        this.accessRequestPending = null;
        this.accessRequestDate = null;
        this._alertService.success('Successfully canceled the access request.');
        if (closeModal) this._activeMatModal.close('cancelledRequest');
      },
      () => {
        this.loadingAccessRequest = false;
        this._alertService.error('Failed to cancel the access request.');
      },
    );
  }

  get canUpdate(): boolean {
    if (
      this.userRoleOnSource &&
      this.roleControl.value === this.userRoleOnSource.role &&
      this.modalType === 'my-permissions'
    ) {
      return false;
    }

    return !(
      this.accessRequestPending &&
      this.roleControl.value === this.accessRequestPending.role &&
      !this.shouldCancelRequest
    );
  }

  handleCancelRequestCheckbox(value: boolean) {
    this.shouldCancelRequest = value;

    if (value) {
      this.roleControl.disable();
    } else {
      this.roleControl.enable();
    }
  }

  cancel(): void {
    this._activeMatModal.close(this.accessRequestPending);
  }
}
