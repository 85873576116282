import { DatePipe } from '@angular/common';
import type { HttpResponse } from '@angular/common/http';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import type { MatDialogRef } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import {
  BehaviorSubject,
  from,
  Observable,
  forkJoin as observableForkJoin,
  of,
  ReplaySubject,
  Subject,
  throwError,
} from 'rxjs';
import { catchError, finalize, first, map, mergeMap, take, takeUntil, tap } from 'rxjs/operators';
import { ConfirmModalComponent, DialogModalComponent, InputModalComponent } from '@dataportal/adl';
import { GoogleTagManagerService } from '@dataportal/analytics';
import type { DatalakeOnlyProvider, IDatalakeObject, IDatalakeObjectAPI } from '@dataportal/datalake-parsing';
import { FileParserAndFormatterService } from '@dataportal/datalake-parsing';
import { ApiService } from '@dataportal/front-api';
import type { IDatalakeMetadata } from '@dataportal/front-shared';
import { AlertService, Logger } from '@dataportal/front-shared';
import { GuardianService } from '@dataportal/guardian-utils';
import { LogsService } from '@dataportal/logs';
import { WebsocketsService } from '@dataportal/websocket';
import { ToastrService } from 'ngx-toastr';

import { DatalakeStoreService } from './datalake-store.service';
import { ExplorerDownloadUploadUrlService } from './explorer-download-upload-url.service';

import { EditionModalComponent } from '../components/edition-modal/edition-modal.component';
import { OverviewModalComponent } from '../components/overview-modal/overview-modal.component';

import type {
  IBucket,
  IDatalakeAPIOptions,
  IDatalakeCurrentDirectory,
  IDatalakeFileMetadata,
  IDatalakeObjectWithPermission,
  IDatalakeStoreOptions,
  IDatalakeTarget,
  IDatalakeUploadProgress,
  IZipProcess,
  ZipProcessStatusType,
} from '../entities/datalake';
import { formatPath } from '../entities/datalake';
import type { IDatalakeUploadResponse } from './explorer-download-upload-url.service';

/**
 * Datalake V4 service
 * All business logic around datalake is now centralized in this service.
 * Only these information can be updated:
 * 1. The current directory: when browsing from the explorer-page component or the datalake modal
 * 2. The current sorting/filtering options
 * 3. The currently selected objects: when checking the objects checkboxes.
 * 4. The currently selected file: when clicking on a specific file.
 * The Datalake service will manage to:
 * 1. build the API calls depending on the current state
 * 2. sort/filter the objects in the file explorer-page
 */
@Injectable()
export class ExplorerService {
  constructor(
    private readonly _http: HttpClient,
    private readonly _apiService: ApiService,
    private readonly _logger: Logger,
    private readonly _alertService: AlertService,
    private readonly _store: DatalakeStoreService,
    private readonly _logService: LogsService,
    private readonly _modalMatService: MatDialog,
    private readonly _explorerDownloadUploadUrlService: ExplorerDownloadUploadUrlService,
    private readonly _toastrService: ToastrService,
    private readonly _datePipe: DatePipe,
    private readonly _websockets: WebsocketsService,
    private readonly _fileParserAndFormatterService: FileParserAndFormatterService,
    private readonly _guardianService: GuardianService,
    private readonly _gtmService: GoogleTagManagerService,
  ) {}

  private readonly _isLoadingFolderCreation$ = new BehaviorSubject<boolean>(false);
  isLoadingFolderCreation$ = this._isLoadingFolderCreation$.asObservable();

  /**
   * Instead of using private objects to store the current state, this service
   * holds this information in private BehaviorSubject.
   * Thus it can expose read-only Observable on which component can subscribe to
   * know current state without being able to overwrite it.
   */
  private readonly _currentBucket$: BehaviorSubject<IBucket> = new BehaviorSubject<IBucket>(null);
  private readonly _current$: BehaviorSubject<IDatalakeCurrentDirectory> =
    new BehaviorSubject<IDatalakeCurrentDirectory>(null);
  private readonly _selected$: BehaviorSubject<IDatalakeObjectWithPermission> =
    new BehaviorSubject<IDatalakeObjectWithPermission>(null);

  /**
   *
   */
  isDisplayingCreateFolderOverrideModal = false;

  private readonly _clipboard$: BehaviorSubject<Array<{ object: IDatalakeObject; hasBeenCut: boolean }>> =
    new BehaviorSubject<Array<{ object: IDatalakeObject; hasBeenCut: boolean }>>([]);
  private readonly _loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private readonly _metadata$: BehaviorSubject<IDatalakeFileMetadata> = new BehaviorSubject<IDatalakeFileMetadata>(
    null,
  );
  private readonly _isLoadingMetadata$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  /**
   * for multiple download
   */
  private readonly _isLoadingDownload$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  /**
   * For Guardian
   */
  private readonly _hasToRunGuardianCheck$: Subject<IDatalakeObjectWithPermission> =
    new Subject<IDatalakeObjectWithPermission>();

  private readonly _objectNameBeingRenamed$ = new BehaviorSubject<string>(undefined);
  objectNameBeingRenamed$ = this._objectNameBeingRenamed$.asObservable();

  private readonly _objectNamesBeingDeleted$ = new BehaviorSubject<Set<string>>(new Set<string>());
  objectNamesBeingDeleted$ = this._objectNamesBeingDeleted$.asObservable();

  private readonly _isPasting$ = new BehaviorSubject<boolean>(false);
  isPasting$ = this._isPasting$.asObservable();

  private readonly _zip$ = new Subject<{
    zipId: string;
    name: string;
    status: ZipProcessStatusType;
  }>();
  zip$ = this._zip$.asObservable();

  get current(): IDatalakeCurrentDirectory | null {
    return this._current$.getValue();
  }
  /**
   * The following information are available for components
   * current$: the currently listed directory. Also holds information on provider, azure tenant and bucket
   * checked$: the currently selected files
   * selected$: the currently selected file
   * loading$: the loading state of API calls
   * sortOptions$: the currently used sort options
   * clipboard$: the files copied in clipboard and ready for paste
   * metadata$: the metadata of selected file
   * datalakeFoldersTree$: data structure container the datalake folders tree
   * isLoadingMultipleDownload$: indicator if downloading (multiple or single download)
   * hasToRunGuardianCheck$: indicating if we have to run a guardian check or not and which file is targeted
   */
  currentBucket$ = this._currentBucket$.asObservable();
  current$ = this._current$.asObservable();
  selected$ = this._selected$.asObservable();
  metadata$ = this._metadata$.asObservable();
  isLoadingMetadata$ = this._isLoadingMetadata$.asObservable();
  clipboard$ = this._clipboard$.asObservable();
  isLoadingDownload$ = this._isLoadingDownload$.asObservable();
  hasToRunGuardianCheck$ = this._hasToRunGuardianCheck$.asObservable();

  private static _objectToOptions(object: IDatalakeObjectAPI): IDatalakeAPIOptions {
    return {
      provider: object.provider,
      tenant: object.tenant,
      path: !object.name ? object.path : `${object.path}/${object.name}`.replace(/\/+/, '/').replace(/^\//, ''),
    };
  }

  private static _curDirToOptions(curDir: IDatalakeCurrentDirectory): IDatalakeAPIOptions {
    return {
      provider: curDir.provider,
      tenant: curDir.tenant,
      path: curDir.path ? curDir.path.replace(/\/+/, '/').replace(/^\//, '') : '',
      ...(curDir.byUser && { byUser: true }),
    };
  }

  private _buildQueryParameters(options: IDatalakeAPIOptions): string {
    return Object.keys(options)
      .filter((key) => options[key] != null)
      .map((key) => `${key}=${encodeURIComponent(options[key])}`)
      .join('&');
  }

  /**
   * Find a bucket by name.
   * Uses the cache if exists, otherwise make an API call to list buckets
   * @param bucketName {string}: the name of the bucket to find
   */
  findBucket(bucketName: string): Observable<IBucket> {
    return this._store.findBucket(bucketName);
  }

  /**
   * Update the current listed directory.
   * This will refresh the objects exposed to subscribers.
   * @param bucket {string}: this bucket where is located the current directory
   * @param byUser {boolean}: ad user permissions are being used instead of dp user ones
   * @param path {string}: path of current directory to use (default to bucket root /)
   * @param provider {string}: the provider AWS or Azure
   * @param tenant {string}: the Azure Account to use if provider is Azure
   */
  setCurrent(bucket: IBucket, byUser?: boolean, path?: string, provider?: DatalakeOnlyProvider, tenant?: string): void {
    this._logger.debug('Current directory updated', { bucket, byUser, path, provider, tenant });
    this._logger.debug('[ExplorerService] Updating current path', { bucket, byUser, path, provider, tenant });
    const fullPath = `${bucket.name}${path ? '/' + path : ''}`.replace(/\/+/, '/').replace(/^\//, '');
    const current: IDatalakeCurrentDirectory = {
      bucket,
      path,
      tenant,
      provider,
      fullPath,
      breadcrumbs: fullPath.split('/'),
      byUser,
    };
    this._currentBucket$.next(current.bucket);
    this._current$.next(current);
  }

  resetBucketSelected(): void {
    this._currentBucket$.next(null);
    this._current$.next(null);
  }

  async selectFile(file: IDatalakeObjectWithPermission): Promise<void> {
    if (file) {
      this._isLoadingMetadata$.next(true);
      const fileMetadata = (await this._fetchMetadata(file).toPromise()) as IDatalakeFileMetadata;
      const fileMetadataCompleted = {
        name: file.name,
        size: file.size,
        path: file.path,
        ...fileMetadata,
      };
      this._metadata$.next(fileMetadataCompleted);
      this._isLoadingMetadata$.next(false);
    }

    this._selected$.next(file);
  }

  createFolder(folderName: string): Observable<any> {
    const currentDir = this._current$.getValue();
    const options = ExplorerService._curDirToOptions(currentDir);

    const folderThatWillBeCreated: IDatalakeObject = {
      name: folderName,
      bucket: currentDir.bucket.name,
      path: options.path,
      type: 'folder',
      provider: options.provider,
    };

    const overrideFiles = this._getListOfFileThatWillBeOverride([folderThatWillBeCreated]);

    if (overrideFiles.length) {
      const infoModal = this._modalMatService.open(DialogModalComponent, {
        width: '900px',
        minWidth: '900px',
        maxHeight: '90vh',
        backdropClass: 'modal-backdrop',
        data: {
          message: `Cannot create folder with name "${folderName}" because it would override a file or a folder.`,
        },
      });
      this.isDisplayingCreateFolderOverrideModal = true;
      infoModal
        .afterClosed()
        .subscribe(() => setTimeout(() => (this.isDisplayingCreateFolderOverrideModal = false), 250));
    } else {
      this._isLoadingFolderCreation$.next(true);

      return this._store
        .createFolder(
          currentDir.bucket.name,
          { path: options.path, provider: options.provider, tenant: options.tenant, byUser: options.byUser },
          folderName,
        )
        .pipe(
          tap(() => {
            this._alertService.success(`Folder "${folderName}" successfully created.`, true, 5000);
            this._isLoadingFolderCreation$.next(false);
          }),
          catchError((err: unknown) => {
            this._isLoadingFolderCreation$.next(false);
            this._toastrService.error(`Error creating folder "${folderName}".`, '', { timeOut: 5000 });
            this._logger.error(err);

            return throwError(err);
          }),
          catchError((err: unknown) => {
            this._isLoadingFolderCreation$.next(false);
            this._toastrService.error(`Error creating folder "${folderName}".`, '', { timeOut: 5000 });
            this._logger.error(err);

            return throwError(err);
          }),
        );
    }
  }

  get isClipboardEmpty(): boolean {
    return !this._clipboard$.getValue()?.length;
  }

  get areClipboardPathAndCurDirPathDifferent(): boolean {
    if (!this.isClipboardEmpty) {
      const curDir = this._current$.getValue();

      return (
        curDir?.bucket.name !== this._clipboard$.getValue()[0].object.bucket ||
        curDir?.path?.replace(/^\//, '') !== this._clipboard$.getValue()[0].object.path.replace(/^\//, '')
      );
    }

    return false;
  }

  getClipboardPathInsideCurDirPath(): string {
    const clipboard = this._clipboard$.getValue();

    if (!clipboard?.length) {
      return null;
    }

    const foldersInClipboard = clipboard.filter((item) => item.object.type === 'folder');

    if (!foldersInClipboard.length) {
      return null;
    }

    const curDir = this._current$.getValue();

    if (clipboard[0].object.bucket !== curDir.bucket.name) {
      return null;
    }

    const curDirPathSegments = curDir.breadcrumbs.length > 1 ? curDir.breadcrumbs.slice(1) : [];

    if (!curDirPathSegments.length) {
      return null;
    }

    let clipboardPathInsideCurDirPath = null;
    const foldersInClipboardPaths = foldersInClipboard.map(
      (folderInClipboard) => `${folderInClipboard.object.path}/${folderInClipboard.object.name}`,
    );

    for (const folderInClipboardPath of foldersInClipboardPaths) {
      let isInside = true;
      const foldersInClipboardPathsSegments = folderInClipboardPath.split('/');

      if (foldersInClipboardPathsSegments.length <= curDirPathSegments.length) {
        for (let index = 0; index < foldersInClipboardPathsSegments.length; ++index) {
          if (foldersInClipboardPathsSegments[index] !== curDirPathSegments[index]) {
            isInside = false;
            break;
          }
        }

        if (isInside) {
          clipboardPathInsideCurDirPath = folderInClipboardPath;
          break;
        }
      }
    }

    return clipboardPathInsideCurDirPath;
  }

  removeObjectFromClipboard(clipboardObject: IDatalakeObject): void {
    const newClipboardItems = this._clipboard$
      .getValue()
      .filter(
        (item) =>
          item.object.bucket !== clipboardObject.bucket ||
          item.object.path !== clipboardObject.path ||
          item.object.name !== clipboardObject.name,
      );
    this._clipboard$.next(newClipboardItems);
  }

  private _getFileContentType(file: IDatalakeObjectWithPermission): Observable<string> {
    const extension = file?.name?.split('.').length > 1 ? file.name.split('.').pop() : '';

    if (!extension) {
      return of('');
    }

    return this._fetchMetadata(file).pipe(
      map((metadata) => metadata?.contentType || ''),
      catchError(() => of('')),
    );
  }

  private _openFileModal(
    mode: 'overview' | 'edition',
    isDownloadButtonShown: boolean,
    object?: IDatalakeObjectWithPermission,
  ) {
    this._logger.info('[ExplorerService] Opening overview modal');
    const selectedFile = object || this._selected$.getValue();

    if (selectedFile && selectedFile.type !== 'file') {
      return of(null);
    }

    let fileModal: MatDialogRef<OverviewModalComponent | EditionModalComponent>;

    return this._getFileContentType(selectedFile).pipe(
      mergeMap((fileContentType) => {
        const configToUse = {
          width: '900px',
          minWidth: '900px',
          maxHeight: '90vh',
          backdropClass: 'modal-backdrop',
          data: {
            selectedFile: selectedFile,
            fileContentType: fileContentType, // '' if error and null if new tab
            isDownloadButtonShown: isDownloadButtonShown,
          },
        };
        fileModal =
          mode === 'overview'
            ? this._modalMatService.open(OverviewModalComponent, configToUse)
            : this._modalMatService.open(EditionModalComponent, configToUse);
        this._logger.info('[ExplorerService] file : ', selectedFile);
        fileModal.componentInstance.loadData();

        if (mode === 'edition') {
          fileModal
            .afterClosed()
            .pipe(take(1))
            .subscribe((fileName: string) => {
              if (fileName) {
                this._hasToRunGuardianCheck$.next(selectedFile);
              }
            });
        }

        if (mode === 'edition') {
          (fileModal.componentInstance as EditionModalComponent).isReady = false;

          return this._guardianService.currentGuardianStatus$;
        } else {
          return of(null);
        }
      }),
      first(),
      map((currentGuardianStatus) => {
        if (currentGuardianStatus?.isChecked && currentGuardianStatus?.checkInfos?.file_format === 'csv') {
          // mode === 'edition'
          (fileModal.componentInstance as EditionModalComponent).delimiterWhenUpload =
            currentGuardianStatus?.checkInfos?.delimiter;
        }

        (fileModal.componentInstance as EditionModalComponent).isReady = true;
      }),
    );
  }

  openOverviewModal(isDownloadButtonShown: boolean, object?: IDatalakeObjectWithPermission): Observable<void> {
    return this._openFileModal('overview', isDownloadButtonShown, object);
  }

  openEditionModal(isDownloadButtonShown: boolean, object?: IDatalakeObjectWithPermission): Observable<void> {
    return this._openFileModal('edition', isDownloadButtonShown, object);
  }

  delete(items?: IDatalakeObject[]): Observable<'confirmed' | 'deleted'> {
    const delete$: ReplaySubject<'confirmed' | 'deleted'> = new ReplaySubject();
    const current = this._current$.getValue();
    const toDelete: IDatalakeObject[] = items ? items : [this._selected$.getValue()];
    const confirmModal = this._modalMatService.open(ConfirmModalComponent, {
      width: '900px',
      minWidth: '900px',
      maxHeight: '90vh',
      backdropClass: 'modal-backdrop',
      data: {
        elements: toDelete.map((element) => `/${current.bucket.name}/${element.path}/${element.name}`),
        action: 'delete',
        kinds: 'directories and files',
      },
    });
    confirmModal
      .afterClosed()
      .pipe(
        take(1),
        catchError((e: unknown) => {
          delete$.error(e);

          return of(null);
        }),
      )
      .subscribe((confirm: boolean) => {
        if (confirm) {
          delete$.next('confirmed');
          this._objectNamesBeingDeleted$.next(new Set());
          const failed = new Set<IDatalakeObject>();
          this._store.deleteObjects(items).subscribe({
            next: (result) => {
              const objectNamesBeingDeleted = this._objectNamesBeingDeleted$.getValue();
              objectNamesBeingDeleted[result.status === 'started' ? 'add' : 'delete'](result.object.name);
              this._objectNamesBeingDeleted$.next(objectNamesBeingDeleted);

              if (result.status === 'failed') {
                failed.add(result.object);
              }
            },
            error: (err: unknown) => {
              this._objectNamesBeingDeleted$.next(new Set());
              delete$.error(err);
            },
            complete: () => {
              delete$.next('deleted');
              delete$.complete();
              this._objectNamesBeingDeleted$.next(new Set());

              if (!failed.size) {
                this._alertService.success('All objects have been successfully deleted');
              } else {
                this._alertService.error(`Failed to delete ${failed.size}/${items.length} objects`);
              }
            },
          });
        }
      });

    return delete$.asObservable();
  }

  private _onDownloadSuccess(isMultiple: boolean, failedDownloadPathsRegistered: string[]): void {
    const nbFailedDownloadPathsRegistered = failedDownloadPathsRegistered.length;
    let successMessage = '';

    if (isMultiple) {
      if (!nbFailedDownloadPathsRegistered) {
        successMessage = 'Files zipped and downloaded successfully';
        this._alertService.success(successMessage, true, 5000);
      } else {
        const subWarningMessage =
          nbFailedDownloadPathsRegistered > 1
            ? `these ${nbFailedDownloadPathsRegistered} following files/folders`
            : 'this following file/folder';
        let warningMessage = `Downloading zip in progress, but ${subWarningMessage} could not be downloaded :\n\n`;
        warningMessage += failedDownloadPathsRegistered.map((path) => path.match(/.{1,35}/g).join('\n')).join('\n');
        this._alertService.warning(warningMessage, true, 100000);
      }
    } else {
      this._isLoadingDownload$.next(false);
      successMessage = 'File downloaded successfully';
      this._alertService.success(successMessage, true, 5000);
    }
  }

  private _multipleDownload(bucket: IBucket, options: IDatalakeAPIOptions[]): void {
    let formattedDate: string;
    let zipId: string;
    const failedDownloadPathsRegistered: string[] = [];

    // Perform an HTTP API call to request creation of ZIP containing all files to download
    const requestGenerateZip$: Observable<{
      requestId: string;
    }> = this._explorerDownloadUploadUrlService.multipleDownloadsUrl(bucket.name, options).pipe(
      tap((val) => {
        formattedDate = this._datePipe.transform(new Date(), 'yyyy-MM-dd_HH-mm-ss');
        zipId = val.requestId;
        this._zip$.next({ zipId: val.requestId, name: `dataportal-download_${formattedDate}.zip`, status: 'started' });
        this._alertService.success(
          `Generating zip file, this could take a few minutes. Then, the archive will be automatically downloaded`,
        );
      }),
      catchError((err: unknown) => {
        this._alertService.error('Error happened when generating zip file.');
        this._logger.error(err);

        return throwError(err);
      }),
    );

    // Wait for websocket the event indicating the zip file have been created, or that process have failed
    const waitZipProcessed$ = (response: { requestId: string }) =>
      new Observable<string>((obs) => {
        const _responseReceived$ = new Subject<void>();
        this._websockets.zipDownloadSucceed$.pipe(takeUntil(_responseReceived$)).subscribe((data) => {
          if (data.requestId === response.requestId) {
            this._zip$.next({
              zipId: data.requestId,
              name: `dataportal-download_${formattedDate}.zip`,
              status: 'preparing zip',
            });
            _responseReceived$.next();
            obs.next(data.zipId);
            obs.complete();
          }
        });
        this._websockets.zipDownloadAborted$.subscribe((data) => {
          if (data.requestId === response.requestId) {
            this._zip$.next({
              zipId: response.requestId,
              name: `dataportal-download_${formattedDate}.zip`,
              status: 'aborted',
            });
            this._alertService.success(`Zip file successfully aborted`);
          }
        });
        this._websockets.zipDownloadFailed$.pipe(takeUntil(_responseReceived$)).subscribe((data) => {
          if (data.requestId === response.requestId) {
            _responseReceived$.next();
            obs.error(data.reason);
            this._zip$.next({
              zipId: response.requestId,
              name: `dataportal-download_${formattedDate}.zip`,
              status: 'errored',
            });
          }
        });
        setTimeout(() => {
          _responseReceived$.next();
          obs.error(new Error('Timeout error: zip file could not have been fifteen minutes '));
        }, 15 * 60 * 1000);
      }).pipe(
        catchError((err: unknown) => {
          this._alertService.error('Error happened when generating zip file.');
          this._logger.error(err);

          return throwError(err);
        }),
      );

    // Get the generated ZIP details from back-end
    const getZipDetails$ = (zipId: string) =>
      this._explorerDownloadUploadUrlService.downloadZip(zipId).pipe(
        catchError((err: unknown) => {
          this._alertService.error('Error happened when downloading zip file.');
          this._logger.error(err);

          return throwError(err);
        }),
      );

    // If files has not been downloaded already, GET blob to download from signedUrl
    const getBlobFromSignedUrl$ = (zipInfos: { downloaded: boolean; signedUrl: string; failedPath: string[] }) => {
      this._logger.debug('Downloading zip', zipInfos);

      if (zipInfos.downloaded) {
        this._logger.debug('Already downloaded in another connection');

        return of(null);
      }

      if (zipInfos.failedPath?.length) {
        this._alertService.warning(
          `Zip file generated. ${zipInfos.failedPath} files could not have been processed and have been ignored`,
        );
      }

      return this._http.get(zipInfos.signedUrl, { responseType: 'blob', observe: 'response' }).pipe(
        map((result: HttpResponse<Blob>) => result.body),
        catchError(() => {
          this._alertService.error('Error occurred while downloading zip file');

          return of(null);
        }),
      );
    };

    // Put things together
    const getBlobFromZipId$ = (id: string) => getZipDetails$(id).pipe(mergeMap(getBlobFromSignedUrl$));
    const waitZipProcessedThenGetBlob$ = (response: { requestId: string }) =>
      waitZipProcessed$(response).pipe(mergeMap(getBlobFromZipId$));
    requestGenerateZip$.pipe(mergeMap(waitZipProcessedThenGetBlob$)).subscribe((blob) => {
      if (!blob.downloaded) {
        const fileName = `dataportal-download_${formattedDate}.zip`;
        const downloadUrl = window.URL.createObjectURL(blob as Blob);
        this._onDownloadSuccess(true, failedDownloadPathsRegistered);
        this._explorerDownloadUploadUrlService.downloadAction(downloadUrl, fileName);
        this._zip$.next({ zipId: zipId, name: `dataportal-download_${formattedDate}.zip`, status: 'completed' });
      }
    });
  }

  private _singleDownload(bucket: IBucket, options: IDatalakeAPIOptions): void {
    this._explorerDownloadUploadUrlService
      .singleDownloadUrl(bucket.name, options)
      .pipe(
        catchError(() => {
          return of(null);
        }),
        mergeMap((signedUrl) => {
          return signedUrl
            ? this._http.get(signedUrl, { responseType: 'blob', observe: 'response' }).pipe(
                map((result: HttpResponse<Blob>) => result.body),
                catchError(() => {
                  this._alertService.error('File download has failed');

                  return of(null);
                }),
              )
            : of(null);
        }),
        take(1),
      )
      .subscribe((blob) => {
        if (blob) {
          const downloadUrl = window.URL.createObjectURL(blob as Blob);
          this._onDownloadSuccess(false, []);
          const pathSegments = options.path.split('/');
          const fileName = pathSegments[pathSegments.length - 1];
          this._explorerDownloadUploadUrlService.downloadAction(downloadUrl, fileName);
        } else {
          this._isLoadingDownload$.next(false); // normally not necessary
        }
      });
  }

  private async _invalidateCache(bucketName: string, options: IDatalakeStoreOptions): Promise<void> {
    await this._store.invalidateCache(bucketName, options);
  }

  async invalidateCurrentCache(): Promise<void> {
    const current = this._current$.getValue();
    const options = {
      path: current.path,
      provider: current.provider,
      tenant: current.tenant,
      byUser: current.byUser,
    };
    await this._invalidateCache(current.bucket.name, options);
  }

  downloadSelected() {
    const currentFile = this._selected$.getValue();
    this._download([currentFile]);
  }

  downloadChecked() {
    const current = this._current$.getValue();
    this._store
      .getObjectStore(current.bucket.name, {
        provider: current.provider,
        tenant: current.tenant,
        path: current.path,
        byUser: current.byUser,
      })
      .pipe(first())
      .subscribe((line) => {
        this._download(line.objects.filter((o) => o.checked));
      });
  }

  private _download(files: IDatalakeObjectAPI[]): void {
    if (!files.length) {
      this._logger.warn('No selected file to download');

      return;
    }

    const toDownload = files;
    const bucket = this._current$.getValue().bucket;
    const options = toDownload.map((object) => ExplorerService._objectToOptions(object));
    const isMultiple = (toDownload.length === 1 && toDownload[0].type === 'folder') || toDownload.length > 1;
    this._isLoadingDownload$.next(true);
    toDownload.forEach((file) => {
      this.pushGTMFileEvent('tl_datalake_file_downloaded', file.name);
    });

    if (isMultiple) {
      this._alertService.info(
        'Zipping and downloading multiple files may take a little bit of time (depending on the number/size of the requested files)',
        true,
        15000,
      );
      this._multipleDownload(bucket, options);
    } else {
      this._isLoadingDownload$.next(true);
      this._singleDownload(bucket, options[0]);
    }
  }

  abortZipDownload(id: string): void {
    this._explorerDownloadUploadUrlService
      .abortDownloadZip(id)
      .pipe(
        catchError((err: unknown) => {
          this._alertService.error('Error happened when abort downloading zip file.');
          this._logger.error(err);

          return throwError(err);
        }),
      )
      .subscribe((val) => {
        this._logger.debug('[ExplorerService] Abort zip download id :', val.zipId);
      });
  }

  upload(files: File[] | File): Observable<IDatalakeUploadProgress> {
    const progress$: ReplaySubject<IDatalakeUploadProgress> = new ReplaySubject<IDatalakeUploadProgress>();
    const toUpload = Array.isArray(files) ? files : [files];

    const filesThatWillBeUploaded: IDatalakeObject[] = toUpload.map((file) => {
      const currentDir = this._current$.getValue();

      return {
        name: file.name,
        bucket: currentDir.bucket.name,
        path: currentDir.path,
        type: 'file',
        provider: currentDir.provider,
      };
    });

    const overrideFiles = this._getListOfFileThatWillBeOverride(filesThatWillBeUploaded);

    if (overrideFiles.length) {
      const confirmModal = this._modalMatService.open(ConfirmModalComponent, {
        width: '900px',
        minWidth: '900px',
        maxHeight: '90vh',
        backdropClass: 'modal-backdrop',
        data: {
          elements: toUpload.map((file) => file.name),
          action: 'upload',
          kinds: 'files here',
          warningText: 'Following ' + (overrideFiles.length > 1 ? 'files' : 'file') + ' will be overwritten:',
          warnings: overrideFiles,
        },
      });

      confirmModal
        .afterClosed()
        .pipe(
          first((confirm: boolean) => confirm),
          tap(() => this._startUpload(toUpload, progress$)),
          catchError((err: unknown) => {
            this._logger.error(err);

            return of(null);
          }),
        )
        .subscribe();

      return progress$.asObservable();
    } else {
      return this._startUpload(toUpload, progress$);
    }
  }

  private _startUpload(
    toUpload: File[],
    progress$: ReplaySubject<IDatalakeUploadProgress>,
  ): Observable<IDatalakeUploadProgress> {
    let nbUploadsProcessed = 0;
    this._logger.debug('[ExplorerService] Preparing uploads requests', toUpload);

    const uploads$ = Array.from(toUpload).map((file) =>
      this._upload(file, file.name).pipe(
        mergeMap((datalakeUploadResponse) => {
          switch (datalakeUploadResponse?.event?.type) {
            case HttpEventType.Sent:
              this._logger.debug('[ExplorerService] Uploading', { file, event: datalakeUploadResponse.event });
              progress$.next({
                name: file.name,
                progress: 0,
                status: 'Starting',
              });

              return of(null);

            case HttpEventType.UploadProgress: {
              const progress = Math.round(
                (100 * datalakeUploadResponse?.event?.loaded) / datalakeUploadResponse?.event?.total,
              );
              this._logger.debug(`[ExplorerService] File "${file.name}" is ${progress}% uploaded.`);
              progress$.next({
                name: file.name,
                progress,
                status: 'In progress',
              });

              return of(null);
            }

            case HttpEventType.Response: {
              if (!datalakeUploadResponse?.event?.ok) {
                this._logger.error(`[ExplorerService] Error uploading ${file.name}`);
                this._alertService.error(`Error uploading ${file.name}`);
                progress$.next({
                  name: file.name,
                  progress: 100,
                  status: 'Errored',
                });

                return of(null);
              }

              this._logger.debug(`[ExplorerService] ${file.name} successfully uploaded`);
              progress$.next({
                name: file.name,
                progress: 100,
                status: 'Completed',
              });
              const current = this._current$.getValue();
              const updatedMetadata = datalakeUploadResponse?.metadata || {};
              updatedMetadata.uploadedby = '${currentUser}';
              const storeOptions: IDatalakeStoreOptions = {
                path: current.path,
                provider: current.provider,
                tenant: current.tenant,
                byUser: current.byUser,
                metadata: updatedMetadata,
              };

              return from(this._store.postUploadObject(current.bucket.name, storeOptions, file)).pipe(
                tap(() => {
                  nbUploadsProcessed++;
                }),
              );
            }

            default:
              this._logger.debug(
                `[ExplorerService] File "${file.name}" surprising upload event: ${datalakeUploadResponse?.event?.type}.`,
              );

              return of(null);
          }
        }),
      ),
    );

    /**
     * Queue of files to upload is processed sequentially.
     * There is no huge gain of performance processing it in parallel and it is
     * easier and less screen-consuming in UI to print a single progress bar.
     */
    this._logger.debug('[ExplorerService] Running uploads requests sequentially', uploads$);
    observableForkJoin(uploads$).subscribe(
      async () => {
        if (nbUploadsProcessed === toUpload.length) {
          this._logger.debug(`[ExplorerService] ${toUpload.length} files successfully uploaded`);
          this._alertService.success(`${toUpload.length} files successfully uploaded`);
          const isFolderUpload = toUpload.some((file) => {
            const splitFilePath = file.name.split('/');

            return splitFilePath.length > 1;
          });

          if (isFolderUpload) {
            this._logger.debug(`[ExplorerService] Was a folder upload, clearing store and cache.`);
            await this.invalidateCurrentCache();
          }

          progress$.complete();
        }
      },
      (err: unknown) => {
        this._logger.error(err);
        progress$.error(err);
      },
    );

    return progress$.asObservable();
  }

  copy(objects?: IDatalakeObject[]): void {
    this._alertService.success('Copied to clipboard');
    const toCopy: Array<{ object: IDatalakeObject; hasBeenCut: boolean }> = objects
      ? objects.map((object) => ({ object, hasBeenCut: false }))
      : [this._selected$.getValue()].map((object) => ({ object, hasBeenCut: false }));
    toCopy.forEach((o) => (o.object.path = o.object.path.replace(/\/+/, '/').replace(/^\//, '')));
    this._clipboard$.next(toCopy);
  }

  cut(objects?: IDatalakeObject[]): void {
    this._alertService.success('Cut into clipboard');
    const toCut: Array<{ object: IDatalakeObject; hasBeenCut: boolean }> = objects
      ? objects.map((object) => ({ object, hasBeenCut: true }))
      : [this._selected$.getValue()].map((object) => ({ object, hasBeenCut: true }));
    toCut.forEach((o) => (o.object.path = o.object.path.replace(/\/+/, '/').replace(/^\//, '')));
    this._clipboard$.next(toCut);
  }

  private _getListOfFileThatWillBeOverride(filesToCheck: IDatalakeObject[]): string[] {
    return filesToCheck
      .map((file) => this._checkThatNoFileCorresponds(file))
      .filter((res) => !!res)
      .map((overrideFile) => {
        return formatPath(`${overrideFile.bucket}/${overrideFile.path}/${overrideFile.name}`);
      });
  }

  paste(): Observable<'confirmed' | 'deleted'> {
    const paste$: ReplaySubject<'confirmed' | 'deleted'> = new ReplaySubject();

    const filesToPaste = this._clipboard$.getValue().map((object) => object.object);
    const overrideFiles = this._getListOfFileThatWillBeOverride(filesToPaste);

    const confirmModal = this._modalMatService.open(ConfirmModalComponent, {
      width: '900px',
      minWidth: '900px',
      maxHeight: '90vh',
      backdropClass: 'modal-backdrop',
      data: {
        elements: filesToPaste.map((object) => `${object.bucket}/${object.path}/${object.name}`),
        action: 'paste',
        kinds: 'directories and files here',
        warningText: overrideFiles?.length
          ? 'Following ' + (overrideFiles.length > 1 ? 'files' : 'file') + ' will be overwritten:'
          : null,
        warnings: overrideFiles?.length ? overrideFiles : null,
      },
    });

    confirmModal
      .afterClosed()
      .pipe(
        take(1),
        catchError((err: unknown) => {
          paste$.error(err);

          return of(null);
        }),
      )
      .subscribe((confirm: boolean) => {
        if (confirm) {
          paste$.next('confirmed');
          this._isPasting$.next(true);
          observableForkJoin(
            this._clipboard$.getValue().map((element) => this._paste(element.object, element.hasBeenCut)),
          ).subscribe(
            () => {
              this._isPasting$.next(false);
              this._alertService.success('All the object have been successfully paste');
              paste$.next('deleted');
              paste$.complete();
              this._clipboard$.next([]);
            },
            (err: unknown) => paste$.error(err),
          );
        }
      });

    return paste$.asObservable();
  }

  private _paste(object: IDatalakeObject, isMoving: boolean): Observable<void> {
    const current = this._current$.getValue();

    if (object.provider !== current.provider || object.tenant !== current.tenant) {
      this._alertService.warning('You can only copy/paste from and to the same provider/tenant');

      return;
    }

    const dest: IDatalakeTarget = {
      bucket: current.bucket.name,
      path: current.path.replace(/\/+/, '/').replace(/^\//, ''),
    };

    if (isMoving) {
      return this._store.moveObject(object.bucket, object, dest);
    } else {
      return this._store.copyObject(object.bucket, object, dest);
    }
  }

  openRenameFileModal(object: IDatalakeObject): void {
    const extension = object.name?.split('.').length > 1 ? object.name.split('.').pop() : '';
    const inputModal = this._modalMatService.open(InputModalComponent, {
      width: '900px',
      minWidth: '900px',
      maxHeight: '90vh',
      backdropClass: 'modal-backdrop',
      data: {
        cursorAtTheStart: true,
        title: 'Renaming a file',
        text: `Choose a new name for ${object.name} :`,
        confirmText: 'Rename',
        defaultText: '.' + extension,
        verificationMethod: (newName: string) => {
          const current = this._current$.getValue();
          const currentlyListedFiles = this._store.getCurrentlyListedFiles(current.bucket.name, {
            path: current.path,
            provider: current.provider,
            tenant: current.tenant,
            byUser: current.byUser,
          });
          const isOverridingExistingFile = currentlyListedFiles.objects.some(
            (currentlyListedObject) => currentlyListedObject.name === newName,
          );

          return {
            isValid: !isOverridingExistingFile,
            errorMessage: 'There already are a file or folder with this name',
          };
        },
      },
    });

    inputModal
      .afterClosed()
      .pipe(take(1))
      .subscribe((newName: string) => {
        if (newName) {
          this.renameFile(object, newName).subscribe();
        }
      });
  }

  renameFile(object: IDatalakeObject, newFileName: string): Observable<void> {
    this._objectNameBeingRenamed$.next(object.name);

    return this._store.renameObject(object.bucket, object, newFileName).pipe(
      tap(() => {
        this._alertService.success(`File has been successfully renamed to ${newFileName}.`);
      }),
      catchError((err: unknown) => {
        this._alertService.error(`File has not been renamed due to: ${(err as Error).message}.`);

        throw err;
      }),
      finalize(() => this._objectNameBeingRenamed$.next(undefined)),
    );
  }

  private _upload(
    file: File | Blob,
    name: string,
    metadata?: Record<string, unknown>,
  ): Observable<IDatalakeUploadResponse> {
    const current = this._current$.getValue();
    this._logger.debug('[ExplorerService] Generating upload signed URI', file);
    const options = ExplorerService._curDirToOptions(current);

    return this._explorerDownloadUploadUrlService.uploadUrl(
      current.bucket.name,
      current.provider,
      options,
      file,
      name,
      metadata,
    );
  }

  private _checkThatNoFileCorresponds(copiedFile: IDatalakeObject): IDatalakeObject {
    const current = this._current$.getValue();
    const currentlyListedFiles = this._store.getCurrentlyListedFiles(current.bucket.name, {
      path: current.path,
      provider: current.provider,
      tenant: current.tenant,
      byUser: current.byUser,
    });
    const object = currentlyListedFiles.objects.find((object) => object.name === copiedFile.name);

    return object ? { ...object, bucket: this._currentBucket$.getValue().name } : undefined;
  }

  private _fetchMetadata(file: IDatalakeObjectWithPermission): Observable<IDatalakeMetadata> {
    const bucket = this._current$.getValue().bucket;
    const options = ExplorerService._objectToOptions(file);

    return this._apiService.get(
      `/v4/datalake/${encodeURIComponent(bucket.name)}/metadata?${this._buildQueryParameters(options)}`,
    );
  }

  checkOne(object: IDatalakeObjectWithPermission): void {
    const current = this._current$.getValue();
    this._logger.debug('[ExplorerService]', '(check-one)', 'Checking/unchecking one object in', current);
    this._store.checkOne(
      current.bucket.name,
      { path: current.path, byUser: current.byUser, tenant: current.tenant, provider: current.provider },
      object,
    );
  }

  checkAll(): void {
    const current = this._current$.getValue();
    this._logger.debug('[ExplorerService]', '(check-all)', 'Checking/unchecking all objects in', current);
    this._store.checkAll(current.bucket.name, {
      path: current.path,
      byUser: current.byUser,
      tenant: current.tenant,
      provider: current.provider,
    });
  }

  getDayLastZipProcess(userId: string): Observable<IZipProcess[]> {
    return this._apiService.get<IZipProcess[]>(`/v4/datalake/get-day-last-zip-process/${userId}`);
  }

  pushGTMFileEvent(eventType: 'tl_datalake_file_viewed' | 'tl_datalake_file_downloaded', fileName: string) {
    this._gtmService.pushEvent({
      event: eventType,
      tl_datalake_name: this._currentBucket$.value.name,
      tl_file_name: fileName,
    });
  }
}
