<adl-modal>
  <adl-title modal-header>Get a shareable link</adl-title>
  <div modal-body class="modal-body">
    <adl-text> You can create a shareable link of the current view of this report. </adl-text>
    <div class="row-flex j-center a-center mt20" *ngIf="shareLink || isGeneratingShareLink">
      <input
        #linkInput
        class="mr25"
        type="text"
        readonly
        (click)="copyShareLinkToClipboard($event)"
        [value]="isGeneratingShareLink ? 'Loading ...' : shareLink"
      />
      <em class="icon-sync-alt" (click)="share()"></em>
    </div>
  </div>
  <div modal-footer class="row-flex j-center">
    <adl-button (clicked)="close()" type="cancel" class="mr20"> Done </adl-button>
    <adl-button (clicked)="copyShareLinkToClipboard($event)"> Copy </adl-button>
  </div>
</adl-modal>
