import 'reflect-metadata';

// Constants
export const PAGE_METADATA = Symbol('dataportal:navigator:page');

// Decorator
export function NavigatorToken(token: string): ClassDecorator {
  return (constructor) => {
    Reflect.defineMetadata(PAGE_METADATA, token, constructor);

    return constructor;
  };
}
