import type { OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import type { AccessRequestV2 } from '@dataportal/access-requests';
import { AccessRequestsV2Service } from '@dataportal/access-requests';
import { Source } from '@dataportal/sources-dashboards-recommendations';
import { ISupportedARResource } from '@dataportal/types';

import { CatalogV2NavigationService } from '../../../services/v2/navigation.service';

import { SourcePermissionModalComponent } from '../modals/source-permission-modal/source-permission-modal.component';

@Component({
  selector: 'dpg-catalog-request-access-button',
  templateUrl: './catalog-request-access-button.component.html',
  styleUrls: ['./catalog-request-access-button.component.scss'],
})
export class CatalogRequestAccessButtonComponent implements OnInit, OnDestroy, OnChanges {
  @Input() kind: ISupportedARResource;
  @Input() source: Source;

  hasAPendingRequest = false;

  private readonly _destroyed$ = new Subject<void>();
  private _userAccessRequests: AccessRequestV2[] = [];
  private _userAccessRequestsOnSource: AccessRequestV2[] = [];
  private _userCreatedAccessRequests: AccessRequestV2[] = [];

  // Constructor
  constructor(
    private readonly _accessRequests: AccessRequestsV2Service,
    private readonly _navigationService: CatalogV2NavigationService,
    private readonly _modalMatService: MatDialog,
  ) {}

  // Lifecycle
  ngOnInit() {
    this._accessRequests.currentUserAccessRequests$.pipe(takeUntil(this._destroyed$)).subscribe((data) => {
      this._userAccessRequests = data;
      this._findAccessRights();
    });
    this._accessRequests.createdAccessRequests$.pipe(takeUntil(this._destroyed$)).subscribe((data) => {
      this._userCreatedAccessRequests = data;
      this._findPendingRequest();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.source && changes.source.currentValue) {
      this.source = changes.source.currentValue;
      this.hasAPendingRequest = false;
      this._findAccessRights();
      this._findPendingRequest();
    }
  }

  ngOnDestroy() {
    this._destroyed$.next();
  }

  private _findAccessRights() {
    for (const userAccessRequest of this._userAccessRequests) {
      if (userAccessRequest.resource === this.kind && userAccessRequest.resourceId === this.source.id) {
        if (userAccessRequest.status === 'pending') {
          this.hasAPendingRequest = true;
          this._userAccessRequestsOnSource.push(userAccessRequest);
        }
      }
    }
  }

  private _findPendingRequest() {
    if (this._userCreatedAccessRequests.length) {
      const createdRequestsForSource = this._userCreatedAccessRequests.filter(
        (accessRequest) => accessRequest.resource === this.kind && accessRequest.resourceId === this.source.id,
      );

      if (createdRequestsForSource.length) {
        this.hasAPendingRequest = true;
        this._userAccessRequestsOnSource.unshift(createdRequestsForSource[createdRequestsForSource.length - 1]);
      }
    }
  }

  // Methods
  requestAccess(): void {
    const modal = this._navigationService.openAccessModal(this.kind, this.source);

    modal
      .afterClosed()
      .pipe(take(1), takeUntil(this._destroyed$))
      .subscribe((hasRequestedAccess) => {
        if (hasRequestedAccess) {
          this.hasAPendingRequest = true;
        }
      });
  }

  modifyAccessRequest(): void {
    const modal = this._modalMatService.open(SourcePermissionModalComponent, {
      width: '900px',
      minWidth: '900px',
      maxHeight: '90vh',
      backdropClass: 'modal-backdrop',
      panelClass: 'overflowable-modal',
      data: {
        resource: this.source,
        accessRequestPending: this._userAccessRequestsOnSource[0],
        modalType: 'requested-access',
      },
    });

    modal.afterClosed().subscribe((accessRequest) => {
      if (accessRequest === 'cancelledRequest') {
        this.hasAPendingRequest = false;
        this._userAccessRequestsOnSource = [];
      }
    });
  }
}
