import { InjectionToken } from '@angular/core';

// Constants
export const DASHBOARDS_OPTIONS = new InjectionToken<IDashboardsOptions>('dataportal-front:dashboards:options');

// Types
export interface IDashboardsOptions {
  // Options
  baseUrl: string;
  sourcesAllowedToExport: string[];
}
