import type { Type } from '@angular/core';
import { Injectable } from '@angular/core';
import type { MatDialogConfig } from '@angular/material/dialog';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import type { Observable } from 'rxjs';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Logger } from '@dataportal/front-shared';

import { DialogModalComponent } from '../molecules/dialog-modal/dialog-modal.component';

import type { ButtonType } from '../atoms/button/button.component';

interface IInfoOptions<T = unknown> {
  message: string;
  confirmText?: string;
  confirmType?: ButtonType;
  result?: T;
  modalOptions?: MatDialogConfig;
  innerHTML?: string;
}

interface IConfirmOptions extends IInfoOptions {
  headerText?: string;
  messageType?: string;
  cancelText?: string;
  cancelType?: ButtonType;
  icon?: string;
  firstButton?: 'confirm' | 'cancel';
}

export class ModalSizes {
  static S = {
    width: '600px',
    maxHeight: '90vh',
    backdropClass: 'modal-backdrop',
  };

  static DEFAULT = ModalSizes.S;

  static M = {
    width: '700px',
    minWidth: '700px',
    maxHeight: '90vh',
    backdropClass: 'modal-backdrop',
  };

  static L = {
    width: '900px',
    minWidth: '900px',
    maxHeight: '90vh',
    backdropClass: 'modal-backdrop',
  };

  static XL = {
    width: '1050px',
    minWidth: '1050px',
    maxHeight: '90vh',
    backdropClass: 'modal-backdrop',
  };
}

@Injectable({
  providedIn: 'root',
})
export class DialogsService {
  constructor(
    private readonly _modalMatService: MatDialog,
    private readonly _activeMatModal: MatDialogRef<unknown>,
    private readonly _logger: Logger,
  ) {}

  alert(options: IInfoOptions): MatDialogRef<unknown> {
    return this.confirm({
      ...options,
      icon: 'exclamation',
    });
  }

  info(options: IInfoOptions): MatDialogRef<unknown> {
    return this.confirm({
      ...options,
      icon: 'fa fa-info-circle fa-3x',
    });
  }

  private _getMatModalConfigDataFromConfig(config: MatDialogConfig) {
    return Object.keys(config?.data || {}).reduce((currentData, key) => {
      if (config.data[key]) {
        currentData[key] = config.data[key];
      }

      return currentData;
    }, {});
  }

  confirm(options: IConfirmOptions): MatDialogRef<unknown> {
    const configs = {
      ...ModalSizes.DEFAULT,
      ...(options.modalOptions || {}),
      data: {
        ...Object.keys(options).reduce((currentData, key) => {
          if (options[key]) {
            currentData[key] = options[key];
          }

          return currentData;
        }, {}),
        ...this._getMatModalConfigDataFromConfig(options.modalOptions || {}),
      },
    };
    this._logger.debug('[DIALOGS_SERVICE] Opening modal with configs : ', configs);

    return this._modalMatService.open(DialogModalComponent, configs);
  }

  open<DataInput, DataOutput = void>(
    content: Type<DataInput>,
    dataInput?: Partial<DataInput>,
    options?: MatDialogConfig,
  ): Observable<DataOutput> {
    const modal = this._modalMatService.open<DataInput, Partial<DataInput>, DataOutput>(content, {
      ...ModalSizes.DEFAULT,
      ...options,
      data: {
        ...dataInput,
        ...this._getMatModalConfigDataFromConfig(options),
      },
    });

    // important: when user click on backdrop, press ESC, modal closed, dismissd or rejected
    return modal.afterClosed().pipe(
      catchError((error: unknown) => {
        this._logger.warn('[DIALOGS_SERVICE] [Modal Error Handling]', error);

        return of(undefined);
      }),
    );
  }

  dismissAll(): void {
    this._modalMatService.closeAll();
  }
}
