import type { HttpResponse } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import type { OnDestroy, OnInit } from '@angular/core';
import { Component, Inject, Input, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { of, Subject } from 'rxjs';
import { catchError, map, mergeMap, takeUntil } from 'rxjs/operators';
import type { DatalakeOnlyProvider, SupportedFileType } from '@dataportal/datalake-parsing';
import { FileParserAndFormatterService, IDatalakeObject } from '@dataportal/datalake-parsing';
import { Logger } from '@dataportal/front-shared';

import { ExplorerDownloadUploadUrlService } from '../../services/explorer-download-upload-url.service';

import type { IDatalakeAPIOptions } from '../../entities/datalake';
import { FileViewService } from '../../services/file-view.serivce';

@Component({
  selector: 'dpg-overview-modal',
  templateUrl: './overview-modal.component.html',
  providers: [FileParserAndFormatterService, FileViewService], // do not use FileParserAndFormatterService as singleton
  encapsulation: ViewEncapsulation.None,
})
export class OverviewModalComponent implements OnInit, OnDestroy {
  // inputs
  @Input() selectedFile: IDatalakeObject; // file that has been selected
  @Input() fileContentType: string = null; // null for new tab
  @Input() isDownloadButtonShown: boolean;

  // constants
  // path
  private readonly _datalakePathName = 'datalake'; // FIXME (IN DPG) : by default, name of the routing path for datalake (to generalize with subrouting)
  private readonly _addedUrlRepo = 'overview';

  selectedIndex = 0;
  rowHeight = 35;
  headerHeight = 50;
  footerHeight = 50;

  // globals
  currentFile: { name: string; provider: DatalakeOnlyProvider; tenant: string; path: string } = {
    name: '',
    provider: undefined,
    tenant: '',
    path: '',
  }; // current file (not necessary selected)
  isFileContentTypeIncorrect = true;
  isFileTooBig = false;
  fileExtension = '';
  fileType: SupportedFileType;
  maxFileSize: number;
  openInNewTabUrl = '';

  private readonly _destroyed$ = new Subject<void>();

  constructor(
    private readonly _activeMatModal: MatDialogRef<OverviewModalComponent>,
    private readonly _http: HttpClient,
    private readonly _router: Router,
    private readonly _logger: Logger,
    private readonly _explorerDownloadUrlService: ExplorerDownloadUploadUrlService,
    private readonly _fileParserAndFormatterService: FileParserAndFormatterService,
    private readonly _fileViewService: FileViewService,
    @Inject(MAT_DIALOG_DATA)
    data: {
      selectedFile: IDatalakeObject;
      fileContentType: string;
      isDownloadButtonShown: boolean;
    },
  ) {
    this.selectedFile = data?.selectedFile ? data.selectedFile : this.selectedFile;
    this.fileContentType = data?.fileContentType?.length ? data.fileContentType : this.fileContentType;
    this.isDownloadButtonShown = Object.keys(data || {}).includes('isDownloadButtonShown')
      ? data.isDownloadButtonShown
      : this.isDownloadButtonShown;
  }

  ngOnInit(): void {
    this._setCurrentFile();
    this.loadData();

    this.openInNewTabUrl = this._fileViewService.createNewTabUrl({
      selectedFile: this.selectedFile,
      datalakePathName: this._datalakePathName,
      addedUrlRepo: this._addedUrlRepo,
    });
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
  }

  private _getDownloadOptions(): { bucket: string; options: IDatalakeAPIOptions } {
    this._setCurrentFile();

    const urlTree = this._router.parseUrl(this._router.url);
    const urlSegments = urlTree.root.children.primary.segments;
    const datalakePathNameIndex = urlSegments.map((segment) => segment.path).indexOf(this._datalakePathName);
    const bucket = urlSegments[datalakePathNameIndex + 1].toString(); // by default, bucket name is given after '.../datalake/'
    const path = this.selectedFile && this.selectedFile.path ? this.selectedFile.path : this.currentFile.path;
    const options: IDatalakeAPIOptions = {
      provider:
        this.selectedFile && this.selectedFile.provider ? this.selectedFile.provider : this.currentFile.provider,
      tenant: this.selectedFile && this.selectedFile.tenant ? this.selectedFile.tenant : this.currentFile.tenant,
      path: !this.fileName ? path : `${path}/${this.fileName}`.replace(/\/+/, '/').replace(/^\//, ''),
    };
    this._logger.debug('[Overview] Downloading URL with params : ', {
      bucket: bucket,
      options: options,
    });

    return { bucket: bucket, options: options };
  }

  private _setCurrentFile(): void {
    const urlTree = this._router.parseUrl(this._router.url);
    const queryParams = urlTree.queryParams;
    this.currentFile.name = queryParams.name;
    this.currentFile.provider = queryParams.provider;
    this.currentFile.tenant = queryParams.tenant;
    this.currentFile.path = queryParams.path;
  }

  private _setFilesProperties(): void {
    this.fileType = this._fileParserAndFormatterService.getFileType(this.fileExtension);
    this.maxFileSize = this._fileParserAndFormatterService.options.overview[this.fileType];

    if (this.selectedFile) {
      this.isFileTooBig =
        this.selectedFile.size > this.maxFileSize * this._fileParserAndFormatterService.options.sizeUnitFactor;
    }
  }

  private _createNewTabUrl(): string {
    const downloadOptions = this._getDownloadOptions(); // path cleaned (duplicated '/' and first '/' have been removed)
    const currentPathSlugified = `${downloadOptions.bucket}-${downloadOptions.options.path.replace(/\//g, '-')}`;
    this._logger.debug('[Overview] Options path : ', downloadOptions.options.path);
    this._logger.debug('[Overview] Options path slugified : ', currentPathSlugified);
    const addedUrl = `${this._addedUrlRepo}/${currentPathSlugified}`;
    const url = this._router.url;
    const splittedUrl = url.split('?');
    const urlWithoutParams = splittedUrl[0];
    const pathSegments = downloadOptions.options.path.split('/');
    pathSegments.pop(); // to remove the '/fileName'
    const pathWithoutFileName = pathSegments.join('/');
    const queryParams = [
      `path=${pathWithoutFileName}`,
      `provider=${downloadOptions.options.provider}`,
      `tenant=${downloadOptions.options.tenant}`,
    ];

    if (!downloadOptions.options.tenant) {
      queryParams.pop();
    }

    const urlTree = this._router.createUrlTree([`${urlWithoutParams}/${addedUrl}`]);
    let urlToOpen = this._router.serializeUrl(urlTree) + '?';
    urlToOpen += queryParams.join('&');
    urlToOpen += `&name=${this.fileName}`;
    this._logger.debug('[Overview] URL to open : ', urlToOpen);

    return urlToOpen;
  }

  get datasets(): { rows: unknown[]; columns: unknown[] }[] {
    return this._fileParserAndFormatterService.datasets;
  }

  get jsonDatasets(): Record<string, unknown> {
    return this._fileParserAndFormatterService.jsonDataset;
  }

  get sheetNames(): string[] {
    return this._fileParserAndFormatterService.sheetNames;
  }

  get currentRowsForPage(): unknown[] {
    return this._fileParserAndFormatterService.currentRowsForPage;
  }

  get currentPageNumber(): number {
    return this._fileParserAndFormatterService.currentPageNumber;
  }

  get hasErrorInParsing(): boolean {
    return this._fileParserAndFormatterService.hasErrorInParsing;
  }

  get paginationNbPerPageMax(): number {
    return this._fileParserAndFormatterService.paginationNbPerPageMax;
  }

  get isLoading(): boolean {
    return (
      !this.isFileContentTypeIncorrect &&
      !this.isFileTooBig &&
      !this._fileParserAndFormatterService.hasFinishedParsingAndFormatting
    );
  }

  get hasSheet(): boolean {
    return !!this.sheetNames.length;
  }

  get fileName(): string {
    return this.selectedFile && this.selectedFile.name ? this.selectedFile.name : this.currentFile.name;
  }

  get isJsonFile(): boolean {
    return this._fileParserAndFormatterService.getFileType(this.fileExtension) === 'json';
  }

  loadData(): void {
    this.fileExtension = this._fileParserAndFormatterService.getFileExtension(this.fileName);
    this.isFileContentTypeIncorrect = !this._fileParserAndFormatterService.isFileEditableOrOverviewable(
      this.fileExtension,
      this.fileContentType,
    );

    if (this.isFileContentTypeIncorrect) {
      return;
    }

    this._setFilesProperties();

    if (this.isFileTooBig) {
      return;
    }

    const downloadOptions = this._fileViewService.getDownloadOptions({
      selectedFile: this.selectedFile,
      datalakePathName: this._datalakePathName,
    });

    this._explorerDownloadUrlService
      .singleDownloadUrl(downloadOptions.bucket, downloadOptions.options)
      .pipe(
        catchError((err: unknown) => {
          this._logger.debug('[Overview] Error getting download URL : ', err);

          return of('');
        }),
        mergeMap((downloadUrl) => {
          this._logger.debug('[Overview] Download URL : ', downloadUrl);

          return this._http.get(downloadUrl, { responseType: 'blob', observe: 'response' }).pipe(
            map((result: HttpResponse<Blob>) => result.body),
            catchError((err: unknown) => {
              this._logger.debug('[Overview] Error downloading file via URL : ', err);

              return of(new Blob());
            }),
          );
        }),
        takeUntil(this._destroyed$),
      )
      .subscribe((result: Blob) => {
        this._fileParserAndFormatterService.parseFile(result, this.fileType);
      });
  }

  setPaginationPage(pageInfo, index: number = 0) {
    this._fileParserAndFormatterService.setPaginationPage(pageInfo, index);
  }

  onTabChange(item: string): void {
    this.selectedIndex = this.sheetNames.findIndex((toFind) => toFind === item);
    this._fileParserAndFormatterService.resetPaginationPage();
  }

  downloadFile(): void {
    this._fileViewService.downloadFile({
      selectedFile: this.selectedFile,
      datalakePathName: this._datalakePathName,
    });
  }

  close(): void {
    this._activeMatModal.close(true);
  }
}
