import type { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { DialogsService, ModalSizes } from '@dataportal/adl';
import { Logger } from '@dataportal/front-shared';

import { GlobalBannerService } from '../../services/global-banner.service';

import { AlertModalComponent } from '../alert-modal/alert-modal.component';

@Component({
  selector: 'dpg-alert-banner',
  templateUrl: './alert-banner.component.html',
  styleUrls: ['./alert-banner.component.scss'],
})
export class AlertBannerComponent implements OnInit {
  constructor(
    readonly dialogsService: DialogsService,
    readonly alertsService: GlobalBannerService,
    private readonly _logger: Logger,
  ) {}

  colorsBanner = [
    { label: 'info', color: '#3e94d3' },
    { label: 'success', color: '#77dd77' },
    { label: 'warning', color: '#ffb347' },
    { label: 'error', color: '#ff6961' },
  ];

  labelColor = '';

  ngOnInit() {
    this.alertsService.getAlertBanner().subscribe((alert) => {
      this.labelColor = this.colorsBanner.find((el) => el.color === alert.color).label || '';
    });
  }

  openReadMore(): void {
    this.dialogsService.open(AlertModalComponent, {}, ModalSizes.XL);
  }
}
