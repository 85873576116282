import { Component, Input } from '@angular/core';
import { CategoriesService } from '@dataportal/categories';
import { Kpi } from '@dataportal/kpis';

@Component({
  selector: 'dpg-kpis-charts',
  templateUrl: './kpis-charts.component.html',
  styleUrls: ['./kpis-charts.component.scss'],
})
export class KpisChartsComponent {
  // Attributes
  @Input() kpi: Kpi;
  @Input() categories: string[] = [];

  view: number[] = [400, 120];
  viewline: number[] = [200, 50];

  colorsValue: Record<string, { domain: string[] }> = {
    ppurple: { domain: ['#0e2662'] },
    pyellow: { domain: ['#7FA5D0'] },
    pgrey: { domain: ['#a27148'] },
    pred: { domain: ['#fec12f'] },
    pbrown: { domain: ['#ee2a24'] },
    pturquoise: { domain: ['#9b9b9b'] },
    ppink: { domain: ['#0e2662'] },
    pindigo: { domain: ['#ee2a24'] },
    plightblue: { domain: ['#a27148'] },
    pteal: { domain: ['#ee2a24'] },
    plime: { domain: ['#ee2a24'] },
    porange: { domain: ['#0e2662'] },
    pdeeppurple: { domain: ['#fec12f'] },
    pdeeporange: { domain: ['#7FA5D0'] },
    pdarkgreen: { domain: ['#ee2a24'] },
  };
  autoScale = true;

  // Constructor
  constructor(private readonly _categoriesService: CategoriesService) {}

  // Properties
  get color() {
    const firstCategory = this.categories[0];

    if (!firstCategory) {
      return this.colorsValue.pturquoise;
    }

    const category = this._categoriesService.findCategory(this.categories[0]);

    return this.colorsValue[category?.color || 'pturquoise'];
  }
}
