<div
  class="catalog-header-container flex justify-center bg-cover relative"
  [ngStyle]="{ 'background-image': 'url(' + coverImage + ')' }"
>
  <div class="blue-overlay w-full h-full absolute top-0 left-0"></div>

  <div class="catalog-header-content w-full">

    <adl-breadcrumb
      class="absolute breadcrumb left-[50px] top-6"
      breadcrumbTheme="white-yellow"
      firstLinkColor="white"
      [firstLink]="firstBreadcrumbLink"
    ></adl-breadcrumb>

    <div class="absolute switch-and-profile flex items-center">
      <adl-mode-switch
        *ngIf="switch && !showStickySearch"
        class="mr-[35px]"
        [switchControl]="switchDataCatalogControl"
      ></adl-mode-switch>
      <dpg-header-profile></dpg-header-profile>
    </div>

    <div class="flex flex-col">
      <ng-container *ngIf="search; else prehome">
        <div class="header-text">
          <h1 class="title">What are you looking for?</h1>
          <span class="subtitle">The Data Marketplace is your tool to discover, understand, trust and access all data products
            you need.
          </span>
        </div>

        <div class="flex justify-between items-center pl-[9%] pr-6">
          <div class="flex items-end w-5/6">
            <dpg-catalog-searchbar
              *ngIf="!showStickySearch"
              class="search-container flex items-center w-4/6 mr-8"
              placeholder="What data product can we help you find?"
              [searchControl]="searchControl"
              [updateURLQuery]="true"
              [showDropdown]="true"
              [showResultsInDropdown]="false"
            ></dpg-catalog-searchbar>

            <span
              class="animate-underline-dash fancy-link cursor-pointer text-[14px] leading-[23px]"
              (click)="openAdvancedFiltersModal()"
            >
              Advanced search
            </span>
          </div>


          <div *ngIf="canUserCreateAsset">
            <adl-button
              type="data-catalog"
              (click)="openDataAssetCreationPage()"
            >
              + CREATE {{('dataAsset' | bizWording).toUpperCase() }}
            </adl-button>
          </div>
        </div>
      </ng-container>

      <ng-template #prehome>
        <div class="header-text">
          <h1 class="title">Welcome to the new Data Marketplace!</h1>
          <span class="subtitle">Your tool to discover, understand, trust and access the {{'dataAsset' | bizWording }} you need.</span>
        </div>
      </ng-template>
    </div>
  </div>
</div>

<div *ngIf="showStickySearch" class="sticky-search-bar">
  <dpg-catalog-sticky-searchbar
    [searchControl]="searchControl"
    [updateURLQuery]="true"
    [showDropdown]="true"
    [showResultsInDropdown]="false"
  ></dpg-catalog-sticky-searchbar>
</div>
