import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { BehaviorSubject, forkJoin, of, Subject } from 'rxjs';
import { catchError, filter, first, map, takeUntil, tap } from 'rxjs/operators';
import { CurrentUserService } from '@dataportal/auth';
import { Logger } from '@dataportal/front-shared';
import { PermissionsService } from '@dataportal/permissions';
import type { User } from '@dataportal/users';
import type { Page } from 'powerbi-client';

import { SourcesService } from '../../sources/services/sources.service';
import { PowerBiBookmarksService } from './power-bi-bookmarks.service';
import { PowerBiEmbedService } from './power-bi-embed.service';

import type { Source } from '../../sources/entities/source';
import { CurrentDashboardError, CurrentDashboardErrorCode } from '../entities/current-dashboard-error.code';
import type { Dashboard } from '../entities/dashboard.model';

@Injectable({
  providedIn: 'root',
})
export class CurrentDashboardService {
  private readonly _loading$ = new BehaviorSubject<boolean>(false);
  private readonly _permissions$ = new BehaviorSubject<{ canRead: boolean; isOwner: boolean }>({
    canRead: false,
    isOwner: false,
  });
  private readonly _currentUser$ = new BehaviorSubject<User>(null);
  private readonly _source$ = new BehaviorSubject<Source>(null);
  private readonly _dashboard$ = new BehaviorSubject<Dashboard>(null);
  private readonly _error$ = new BehaviorSubject<CurrentDashboardError>(null);
  private readonly _pages$ = new BehaviorSubject<Array<Page>>([]);
  private readonly _currentPage$ = new BehaviorSubject<{ name: string; displayName: string }>(null);

  private readonly _dashboardChanged$ = new Subject<void>();

  dashboardChanged$ = this._dashboardChanged$.asObservable();
  loading$ = this._loading$.asObservable();
  permissions$ = this._permissions$.asObservable();
  dashboard$ = this._dashboard$.asObservable();
  error$ = this._error$.asObservable();
  pages$ = this._pages$.asObservable();
  currentPage$ = this._currentPage$.asObservable();
  currentUser$ = this._currentUser$.asObservable();

  constructor(
    private readonly _sourcesService: SourcesService,
    private readonly _powerbiFiltersService: PowerBiBookmarksService,
    private readonly _permissionsService: PermissionsService,
    private readonly _currentUserService: CurrentUserService,
    private readonly _powerbiEmbedService: PowerBiEmbedService,
    private readonly _logger: Logger,
  ) {}

  get dashboard(): Dashboard {
    return this._dashboard$.getValue();
  }

  get source(): Source {
    return this._source$.getValue();
  }

  get isOwner(): boolean {
    return this._permissions$.getValue()?.isOwner;
  }

  get currentUser(): string {
    return this._currentUser$.getValue()?.id;
  }

  changeCurrentDashboard(sourceId: string, dashboardId: string) {
    // Reset state
    this._logger.info('[CurrentDashboardService] Requested to change current dashboard');

    this._logger.debug('[CurrentDashboardService] Resetting internal state');
    this._loading$.next(true);
    this._source$.next(null);
    this._dashboard$.next(null);
    this._dashboardChanged$.next();
    this._pages$.next([]);
    this._currentPage$.next(null);
    this._permissions$.next({
      canRead: false,
      isOwner: false,
    });

    const isReader$ = this._permissionsService
      .isAuthorized('get', 'sources', sourceId)
      .pipe(catchError(() => of(false)));
    const isOwner$ = this._permissionsService
      .isAuthorized('update', 'sources', sourceId)
      .pipe(catchError(() => of(false)));
    const findSource$ = this._sourcesService.findSource(sourceId, false, true).pipe(catchError(() => of(null)));

    const permissionsResolved = ([canRead, isOwner]: [boolean, boolean]) => {
      this._logger.info('[CurrentDashboardService] Permissions resolved', { isOwner, canRead });
      this._permissions$.next({ isOwner, canRead });

      if (!canRead) {
        this._throwError(
          new CurrentDashboardError(
            `Current user is not dashboard reader of source ${sourceId}`,
            CurrentDashboardErrorCode.UNAUTHORIZED,
          ),
        );
      } else {
        this._logger.info(
          '[CurrentDashboardService] All related data fetched successfully ! Updating loading state to false',
        );
        this._loading$.next(false);
      }
    };

    const watchPagesChanges = () => {
      this._powerbiEmbedService.pages$.pipe(takeUntil(this.dashboardChanged$)).subscribe((pageList) => {
        this._logger.info('[CurrentDashboardService] Page list resolved', pageList);
        this._pages$.next(pageList as Page[]);
        this._logger.info('[CurrentDashboardService] Watching PowerBI dashboard page changes');
        this._powerbiEmbedService.currentPage$.subscribe((page) => {
          this._logger.info('[CurrentDashboardService] Current dashboard page changed', page);

          if (page) {
            this._currentPage$.next({
              name: page.name,
              displayName: page.displayName,
            });
          }
        });
      });
    };

    const sourceResolved = (source: Source | null) => {
      this._logger.info('[CurrentDashboardService] Source resolved', source);

      if (source) {
        this._source$.next(source);
        const dashboard = source.powerbi?.find((d) => d.name === dashboardId);

        if (!dashboard) {
          this._logger.error('[CurrentDashboardService] Dashboard not found in source');
          this._throwError(
            new CurrentDashboardError(
              `Dashboard with name ${dashboardId} cannot be found in source ${sourceId}`,
              CurrentDashboardErrorCode.DASHBOARD_NOT_FOUND,
            ),
          );
        } else {
          this._logger.info('[CurrentDashboardService] Dashboard resolved', dashboard);
          this._dashboard$.next(dashboard);

          if (dashboard.type === 'powerbi') {
            watchPagesChanges();
          }

          this._resolveCurrentUser().subscribe(() => {
            this._logger.info(
              '[CurrentDashboardService] Current user resolved, fetching his permission',
              this.currentUser,
            );
            forkJoin([isReader$, isOwner$]).subscribe(permissionsResolved.bind(this));
          });
        }
      } else {
        this._logger.error('[CurrentDashboardService] Source not found');
        this._throwError(
          new CurrentDashboardError(`Source ${sourceId} does not exist`, CurrentDashboardErrorCode.SOURCE_NOT_FOUND),
        );
      }
    };

    this._logger.debug('[CurrentDashboardService] Fetching all data related to current dashboard');
    findSource$.subscribe(sourceResolved.bind(this));
  }

  private _resolveCurrentUser(): Observable<User> {
    this._logger.info('[CurrentDashboardService] Resolving current user');
    const alreadyResolved = this._currentUser$.getValue();

    if (alreadyResolved) {
      this._logger.debug('[CurrentDashboardService] Already resolved, using cached value', alreadyResolved);

      return of(alreadyResolved);
    }

    return this._currentUserService.currentUser$.pipe(
      filter((u) => u != null),
      first(),
      tap((user) => this._currentUser$.next(user)),
    );
  }

  restoreFiltersFromLink(): Observable<void> {
    this._logger.info('[CurrentDashboardService] Requested to restore current dashboard bookmark from LinkId');

    return this._powerbiFiltersService.restoreBookmarkFromLink(this._source$.getValue().id, this.dashboard.name).pipe(
      map(() => {
        this._logger.info('[CurrentDashboardService] Dashboard state restored from link ID');
      }),
    );
  }

  private _throwError(error: CurrentDashboardError) {
    this._logger.error('[CurrentDashboardService] Error happened', error);
    this._error$.next(error);
    this._loading$.next(false);
  }
}
