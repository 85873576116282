<svg class="dpg-icon" width="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
  <path
    class="dpg-fg-medium-fill"
    d="M150.6,328.8l2.3-2.3c1.6-1.6,1.6-4.1,0-5.6L94.1,262h124c2.2,0,4-1.8,4-4v-3.3c0-2.2-1.8-4-4-4h-124l58.9-58.9
                        		c1.6-1.6,1.6-4.1,0-5.6l-2.3-2.3c-1.6-1.6-4.1-1.6-5.6,0l-69.6,69.6c-1.6,1.6-1.6,4.1,0,5.6l69.6,69.6
                        		C146.6,330.3,149,330.3,150.6,328.8z"
  />
  <path
    class="dpg-fg-medium-fill"
    d="M147.8,337.4C147.8,337.4,147.8,337.4,147.8,337.4c-3.1,0-6-1.2-8.1-3.4L70,264.5c-4.5-4.5-4.5-11.7,0-16.2l69.6-69.6
                        		c4.5-4.5,11.7-4.5,16.2,0l2.3,2.3c4.5,4.5,4.5,11.7,0,16.2l-46.1,46.1h105.9c6.3,0,11.5,5.1,11.5,11.5v3.3
                        		c0,6.3-5.1,11.5-11.5,11.5H112.2l46.1,46c4.5,4.5,4.5,11.7,0,16.2l-2.3,2.3C153.7,336.3,150.9,337.4,147.8,337.4z"
  />
  <path
    class="dpg-fg-medium-fill"
    d="M359.9,183.9l-2.3,2.3c-1.6,1.6-1.6,4.1,0,5.6l58.9,58.9h-124c-2.2,0-4,1.8-4,4v3.3c0,2.2,1.8,4,4,4h124l-58.9,58.9
                        		c-1.6,1.6-1.6,4.1,0,5.6l2.3,2.3c1.6,1.6,4.1,1.6,5.6,0l69.6-69.6c1.6-1.6,1.6-4.1,0-5.6L365.5,184
                        		C363.9,182.4,361.4,182.4,359.9,183.9z"
  />
  <path
    class="dpg-fg-medium-fill"
    d="M362.7,337.4c-3.1,0-5.9-1.2-8.1-3.4l-2.3-2.3c-4.5-4.5-4.5-11.7,0-16.2l46.1-46.1H292.4c-6.3,0-11.5-5.1-11.5-11.5v-3.3
                        		c0-6.3,5.1-11.5,11.5-11.5h105.9l-46.1-46c-4.5-4.5-4.5-11.7,0-16.2l2.3-2.3c2.2-2.2,5-3.4,8.1-3.4c0,0,0,0,0,0
                        		c3,0,5.9,1.2,8.1,3.4l69.6,69.6c2.2,2.2,3.4,5,3.4,8.1c0,3.1-1.2,5.9-3.4,8.1l-69.6,69.6C368.6,336.3,365.8,337.4,362.7,337.4z"
  />
  <path
    class="dpg-fg-medium-fill"
    d="M67.5,448H47.9c-8.8,0-16-7.2-16-16V80c0-8.8,7.2-16,16-16h19.6V32H47.9c-26.5,0-48,21.5-48,48v352c0,26.5,21.5,48,48,48
                        		h19.6V448z"
  />
  <path
    class="dpg-fg-medium-fill"
    d="M463.9,32h-19.6v32h19.6c8.8,0,16,7.2,16,16v352c0,8.8-7.2,16-16,16h-19.6v32h19.6c26.5,0,48-21.5,48-48V80
                        		C511.9,53.5,490.4,32,463.9,32z"
  />
</svg>
