import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { AdlModule } from '@dataportal/adl';
import { ApiModule } from '@dataportal/front-api';
import { SharedModule } from '@dataportal/front-shared';
import { PermissionsModule } from '@dataportal/permissions';
import { SnowflakeModule } from '@dataportal/snowflake';
import { WebsocketModule } from '@dataportal/websocket';

import { GuardianCurrentUserService } from './services/guardian-current-user.service';

// Modules
@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    AdlModule,
    ApiModule,
    PermissionsModule,
    SharedModule,
    WebsocketModule,
    SnowflakeModule,
  ],
  declarations: [],
  exports: [],
  providers: [GuardianCurrentUserService, { provide: MAT_DIALOG_DATA, useValue: {} }],
})
export class GuardianModule {}
