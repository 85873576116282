import { Inject, Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import type { Observable } from 'rxjs';
import { BehaviorSubject, forkJoin, merge, of, Subscription } from 'rxjs';
import { catchError, debounceTime, map } from 'rxjs/operators';
import { minLength } from '@dataportal/adl';
import { IS_ACCOR } from '@dataportal/front-environment';
import { AlertService, Logger } from '@dataportal/front-shared';
import type { IProfile } from '@dataportal/users';
import { UsersService } from '@dataportal/users';
import type { FormArray, FormGroup } from '@ngneat/reactive-forms';
import { FormBuilder, FormControl } from '@ngneat/reactive-forms';
import type { Editor } from 'tinymce';

import type { IPotentialOwner } from '../../../entities/potential-owner';
import type { Source } from '../../../entities/source';
import type {
  IBannerConfig,
  IBasicInfosFormData,
  IBasicInfosStepContacts,
  IBasicInfosStepGeneralInformation,
  IBasicInfosStepLinks,
} from '../../../entities/source-form';
import type { OwnerType } from '../../owner-types.enum';

@Injectable({
  providedIn: 'root',
})
export class BasicInfosFormService {
  private _basicInfosForm: FormGroup<IBasicInfosFormData>;
  private _owners: { [id: string]: IProfile } = {};

  private readonly _formReady$ = new BehaviorSubject<boolean>(false);
  private readonly _loadingOwners$ = new BehaviorSubject<boolean>(false);

  formReady$ = this._formReady$.asObservable();
  loadingOwners$ = this._loadingOwners$.asObservable();
  private readonly _editorSubject = new BehaviorSubject<Editor | null>(null);
  private _controllersEnablementSubscriptions = Subscription.EMPTY;

  private _relatedSource: Source;

  constructor(
    private readonly _logger: Logger,
    private readonly _formBuilder: FormBuilder,
    private readonly _usersService: UsersService,
    private readonly _alertService: AlertService,
    @Inject(IS_ACCOR) private readonly _isAccor: boolean,
  ) {}

  get basicInfosStepGeneralInformation(): FormGroup<IBasicInfosStepGeneralInformation> {
    return this._basicInfosForm?.get<'stepGeneralInformation'>(['stepGeneralInformation']) as unknown as FormGroup;
  }

  get basicInfosStepLinks(): FormGroup<IBasicInfosStepLinks> {
    return this._basicInfosForm?.get<'stepLinks'>(['stepLinks']) as unknown as FormGroup;
  }

  get basicInfosStepContacts(): FormGroup<IBasicInfosStepContacts> {
    return this._basicInfosForm?.get<'stepContacts'>(['stepContacts']) as unknown as FormGroup;
  }

  get functionalOwners(): FormArray<string> {
    return this.basicInfosStepContacts?.get<'functionalOwners'>(['functionalOwners']) as FormArray;
  }

  get technicalOwners(): FormArray<string> {
    return this.basicInfosStepContacts?.get<'technicalOwners'>(['technicalOwners']) as FormArray;
  }

  get dataOwner(): FormArray<string> {
    return this.basicInfosStepContacts?.get<'dataOwner'>(['dataOwner']) as FormArray;
  }

  get sourceNameControl(): FormControl<string> {
    return this.basicInfosStepGeneralInformation?.get<'name'>(['name']);
  }

  get sourceName(): string {
    return this.sourceNameControl?.value;
  }

  get categories(): string[] {
    return (this.basicInfosStepGeneralInformation?.get('categories') as FormArray<string>)?.getRawValue() || [];
  }

  handleEditorInit(e) {
    this._editorSubject.next(e.editor);
  }

  private _initBasicInfosForm(source?: Source): FormGroup<IBasicInfosFormData> {
    this._controllersEnablementSubscriptions.unsubscribe();

    const stepGeneralInformation = this._initStepGeneralInformation(source);
    const stepLinks = this._initStepLinks(source);
    const stepContacts = this._initStepContacts(source);

    return this._formBuilder.group({ stepGeneralInformation, stepLinks, stepContacts });
  }
  private _initStepGeneralInformation(source: Source): FormGroup<IBasicInfosStepGeneralInformation> {
    const stepGeneralInformationFormGroup: FormGroup<IBasicInfosStepGeneralInformation> = this._formBuilder.group(
      {
        name: [source ? source.name : '', Validators.required],
        icon: [source ? source.icon : 'fa fa-glass', [Validators.required, Validators.pattern(/fa/)]],
        mainCategory: [source ? source.mainCategory : null],
        categories: this._formBuilder.array(
          source ? source.categories || [] : [],
          this._isAccor ? null : Validators.required,
        ),
        isNotConfidential: [source ? !source.isConfidential : true, [Validators.required]],
        useCustomAccessRequests: [source ? !source.allowAccessRequest : false, [Validators.required]],
        accessRequestInstructions: [source ? source.accessRequestInstructions : null, Validators.required],
        description: [source?.description ?? '', [Validators.required, minLength(this._editorSubject, 20)]],
        updateFrequency: [source ? source.updateFrequency : ''],
        hasBanner: [!!(source && source.banner)],
        banner: this._initBannerFormGroup(source),
      },
      {
        validator: (group: FormGroup<IBasicInfosStepGeneralInformation>) => {
          if (this._isAccor) {
            return;
          }

          const areThereMultipleCategories = (group.get('categories') as FormArray).length > 1;

          if (areThereMultipleCategories && !group.get('mainCategory').value) {
            return { mainCategoryRequired: true };
          }
        },
      },
    );

    this._controllersEnablementSubscriptions = (
      stepGeneralInformationFormGroup.get('accessRequestInstructions') as FormControl
    ).enabledWhile((stepGeneralInformationFormGroup.get('useCustomAccessRequests') as FormControl).value$);

    const bannerControl = stepGeneralInformationFormGroup.get('banner') as FormControl<string, IBannerConfig>;
    this._controllersEnablementSubscriptions.add(
      bannerControl.enabledWhile((stepGeneralInformationFormGroup.get('hasBanner') as FormControl).value$),
    );

    const hasStartAndEndDates$ = (bannerControl.get('hasStartAndEndDates') as FormControl).value$;
    this._controllersEnablementSubscriptions.add(
      (bannerControl.get('startDate') as FormControl).enabledWhile(hasStartAndEndDates$),
    );
    this._controllersEnablementSubscriptions.add(
      (bannerControl.get('endDate') as FormControl).enabledWhile(hasStartAndEndDates$),
    );

    return stepGeneralInformationFormGroup;
  }

  private _initBannerFormGroup(source: Source): FormGroup<IBannerConfig> {
    return this._formBuilder.group<IBannerConfig>(
      {
        color: [source && source.banner ? source.banner.color : '#3e94d3', Validators.required],
        message: [
          source && source.banner ? source.banner.message : '',
          [Validators.required, Validators.maxLength(120)],
        ],
        hasStartAndEndDates: [!!source?.banner?.startDate || false],
        startDate: [source && source.banner ? source.banner.startDate : ''],
        endDate: [source && source.banner ? source.banner.endDate : ''],
      },
      {
        validator: (bannerConfig: FormGroup<IBannerConfig>) => {
          const hasStartAndEndDates = bannerConfig.get('hasStartAndEndDates').value as boolean;

          if (hasStartAndEndDates && (!bannerConfig.get('startDate').value || !bannerConfig.get('startDate').value)) {
            return {
              missStartDate: bannerConfig.get('startDate').value ? !bannerConfig.get('startDate').value : true,
              missEndDate: bannerConfig.get('endDate').value ? !bannerConfig.get('endDate').value : true,
            };
          }

          return null;
        },
      },
    );
  }

  private _initStepLinks(source: Source): FormGroup<IBasicInfosStepLinks> {
    return this._formBuilder.group({
      documentationLinks: [source?.documentationLinks || []],
    }) as FormGroup<IBasicInfosStepLinks>;
  }

  private _initStepContacts(source: Source): FormGroup<IBasicInfosStepContacts> {
    return this._formBuilder.group({
      functionalOwners: this._formBuilder.array(source ? source.functionalOwners || [] : [], [
        Validators.required,
        Validators.maxLength(3),
      ]),
      technicalOwners: this._formBuilder.array(source ? source.technicalOwners || [] : [], [
        Validators.required,
        Validators.maxLength(3),
      ]),
      dataOwner: this._formBuilder.array(source ? source.dataOwner || [] : [], [Validators.required]),
    }) as FormGroup<IBasicInfosStepContacts>;
  }

  getForm(): FormGroup<IBasicInfosFormData> {
    return this._basicInfosForm;
  }

  init(source?: Source): void {
    this._relatedSource = source;
    this._basicInfosForm = this._initBasicInfosForm(source);
    this._formReady$.next(true);
  }

  fetchOwnersData(): void {
    const technicalChanged$: Observable<{
      value: string[];
      type: OwnerType;
    }> = this.technicalOwners.valueChanges.pipe(
      debounceTime(250),
      map((owners) => ({ value: owners, type: 'technical' })),
    );
    const functionalChanged$: Observable<{
      value: string[];
      type: OwnerType;
    }> = this.functionalOwners.valueChanges.pipe(
      debounceTime(250),
      map((owners) => ({ value: owners, type: 'functional' })),
    );
    const dataChanged$: Observable<{
      value: string[];
      type: OwnerType;
    }> = this.dataOwner.valueChanges.pipe(
      debounceTime(250),
      map((owners) => ({ value: owners, type: 'data' })),
    );

    merge(functionalChanged$, technicalChanged$, dataChanged$).subscribe((owners) => {
      this._logger.debug('[SourceForm] Technical/functional/data owners refreshed', owners);
      this.fetchProfiles(owners);
    });
  }

  fetchProfiles(owners: { type: OwnerType; value: string[] }): void {
    this._loadingOwners$.next(true);
    const requests$: Array<Observable<IProfile>> = [];
    const errored: Array<{ user: string; type: OwnerType }> = [];
    this._logger.debug('[SourceForm] Fetching owners profiles');
    this._logger.debug('[SourceForm] In cache', this._owners);

    for (const owner of owners.value) {
      if (!this._owners[owner]) {
        this._logger.debug('[SourceForm] Cache miss', owner);
        const fetchDetails$ = this._usersService.getProfile(owner).pipe(
          catchError((err: unknown) => {
            this._logger.error('[SourceForm] Error fetching user details', { user: owner, type: owners.type, err });
            errored.push({ user: owner, type: owners.type });

            return of(null);
          }),
        );
        requests$.push(fetchDetails$);
      } else {
        this._logger.debug('[SourceForm] Cache hit', owner);
      }
    }

    this._logger.debug('[SourceForm] Fetching owners profiles concurrently', requests$);

    if (requests$.length === 0) {
      this._loadingOwners$.next(false);
    }

    forkJoin(requests$).subscribe((profiles) => {
      this._logger.debug('[SourceForm] All requests returned', profiles);
      profiles.forEach((p) => {
        this._owners[p.email] = p;
      });
      this._logger.debug('[SourceForm] Owners refreshed', this._owners);
      this._logger.debug('[SourceForm] Errored requests', errored);
      errored.forEach((owner) => {
        this._alertService.error(`Error happened adding ${owner.user} as ${owner.type} owner`);
        this.removeOwner(owner.type, owner.user);
        delete this._owners[owner.user];
      });
      this._logger.debug('[SourceForm] Profile successfully retrieved');
      this._loadingOwners$.next(false);
    });
  }

  removeOwner(type: OwnerType, email: string): void {
    // FIXME: Refactor with https://github.com/ngneat/reactive-forms/issues/40
    const owners = type === 'technical' ? this.technicalOwners : this.functionalOwners;

    const findIndex = (): number => {
      for (let i = 0; i < owners.length; i++) {
        if (owners.at(i).value === email) {
          return i;
        }
      }

      return null;
    };

    const idx = findIndex();

    if (idx == null) {
      throw Error('Owner to remove does not exist');
    }

    owners.removeAt(idx);
  }

  addOwner(type: OwnerType, email: string): void {
    const owners = type === 'technical' ? this.technicalOwners : this.functionalOwners;
    owners.push(new FormControl<string>(email));
  }

  getFunctionalOwnersProfiles(): IProfile[] {
    return this.functionalOwners.getRawValue().map((id) => this._owners[id]);
  }

  getTechnicalOwnersProfiles(): IProfile[] {
    return this.technicalOwners.getRawValue().map((id) => this._owners[id]);
  }

  getDataOwnersProfiles(): IProfile[] {
    return this.dataOwner.getRawValue().map((id) => this._owners[id]);
  }

  getOwnersProfiles(): IProfile[] {
    return this.getFunctionalOwnersProfiles()
      .concat(this.getTechnicalOwnersProfiles())
      .concat(this.getDataOwnersProfiles());
  }

  getOwnerName(id: string): string {
    if (!this._owners[id]) {
      this._logger.error('User does not exists', id);

      return null;
    }

    return this._owners[id].name;
  }

  refreshOwners(toSave: IPotentialOwner[]): void {
    this._logger.debug('[SourceForm] Requested to refresh owners', toSave);
    const functional = this.functionalOwners;
    const technical = this.technicalOwners;
    const data = this.dataOwner;
    functional.clear();
    technical.clear();
    data.clear();
    toSave.forEach((potentialOwner) => {
      if (potentialOwner.isFunctionalOwner) {
        functional.push(new FormControl<string>(potentialOwner.userId));
      }

      if (potentialOwner.isTechnicalOwner) {
        technical.push(new FormControl<string>(potentialOwner.userId));
      }

      if (potentialOwner.isDataOwner) {
        data.push(new FormControl<string>(potentialOwner.userId));
      }
    });
    this.fetchOwnersData();
    this._logger.debug('[SourceForm] Functional owners refreshed', functional.getRawValue());
    this._logger.debug('[SourceForm] Technical owners refreshed', technical.getRawValue());
    this._logger.debug('[SourceForm] Data owner refreshed', data.getRawValue());
  }
}
