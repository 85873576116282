import type { OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { FileParserAndFormatterService } from '@dataportal/datalake-parsing';
import { AlertService, Logger } from '@dataportal/front-shared';
import { ColumnMode } from '@swimlane/ngx-datatable';

import { ExplorerService } from '../../services/explorer.service';
import { ExplorerPageService } from '../../services/explorer-page.service';

import type { IDatalakeObjectWithPermission } from '../../entities/datalake';

interface IBrowserActivateEvent {
  type: string | 'click';
  row: IDatalakeObjectWithPermission;
  cellIndex: number;
}

@Component({
  selector: 'dpg-datalake-preview',
  templateUrl: './datalake-preview.component.html',
  styleUrls: ['./datalake-preview.component.scss'],
})
export class DatalakePreviewComponent implements OnChanges, OnDestroy {
  @Input() displayedObjects: IDatalakeObjectWithPermission[] = [];
  @Input() disabled = true;
  @Input() editable = false;
  @Input() loading: {
    objects: boolean;
    permissions: boolean;
  } = { objects: false, permissions: false };
  @Input() permissions: {
    source: { create: boolean };
    datalake: { read: boolean; write: boolean };
  } = {
    source: { create: false },
    datalake: { read: false, write: false },
  };
  @Input() isCreatingDirectory = false;
  @Input() refreshList: () => void = null;
  @Input() refreshDisplayedObjectsInTable: () => void = null;
  @Input() isLoadingFolderCreation = false;
  @Input() objectNameBeingRenamed: string;
  @Input() objectNamesBeingDeleted: Set<string> = new Set<string>();
  @Input() allChecked = false;
  @Output() isCreatingDirectoryChange = new EventEmitter<boolean>();
  @Output() newDirectoryCreated = new EventEmitter<string>();

  private readonly _destroyed$ = new Subject<void>();

  columnMode = ColumnMode;
  currentlyLoadingEditionName: string;
  currentlyLoadingOverviewName: string;
  currentlyLoadingFileName: string;
  newDirectoryName: string;
  isCreatingRequestSent: boolean;
  newDirectoryInput: ElementRef;

  @ViewChild('newDirectoryInput') set setNewDirectoryInput(elementRef: ElementRef) {
    this.newDirectoryInput = elementRef;
  }

  constructor(
    readonly fileParserAndFormatterService: FileParserAndFormatterService,
    private readonly _router: Router,
    private readonly _explorerService: ExplorerService,
    readonly explorerPageService: ExplorerPageService,
    private readonly _route: ActivatedRoute,
    private readonly _logger: Logger,
    private readonly _alertService: AlertService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.displayedObjects?.currentValue?.some((o) => o.folderCreation)) {
      this._createDirectoryInputFocus();
    }
  }

  ngOnDestroy() {
    this._destroyed$.next();
  }

  get canWrite(): boolean {
    return !this.loading.permissions && this.permissions.datalake.write;
  }

  get canRead(): boolean {
    return !this.loading.permissions && this.permissions.datalake.read;
  }

  async open(object: IDatalakeObjectWithPermission): Promise<boolean | void> {
    if (object.type === 'folder') {
      if (this.isCreatingDirectory) {
        return;
      }

      this._logger.debug('[DatalakeBrowserComponent] Navigate to', object);
      this.explorerPageService.search(null);
      const path = [object.path, object.name].join('/');
      await this._refreshQueryParameters(path, object.provider, object.tenant);
    } else {
      this._logger.debug('[DatalakeBrowserComponent] Open file metadata', object);
      this.currentlyLoadingFileName = object.name;
      await this._explorerService.selectFile(object);
      this.currentlyLoadingFileName = null;
    }
  }

  private async _refreshQueryParameters(path: string, provider: string, tenant: string): Promise<boolean> {
    return this._router.navigate([], {
      relativeTo: this._route,
      queryParams: {
        path: !path ? undefined : path,
        provider: !provider ? undefined : provider,
        tenant: !tenant ? undefined : tenant,
      },
    });
  }

  hasToDisplay(object: IDatalakeObjectWithPermission, mode: 'overview' | 'edition'): boolean {
    if (object.type !== 'file') {
      return false;
    }

    const extension = object?.name?.split('.').length > 1 ? object.name.split('.').pop() : '';
    const isCorrectExtension = (
      mode === 'overview'
        ? this.fileParserAndFormatterService.extensionsOverviewable
        : this.fileParserAndFormatterService.extensionsEditable
    ).some((ext) => ext.toLowerCase() === extension.toLowerCase());
    const hasRight = mode === 'overview' ? object.userPermission?.canRead : object.userPermission?.canWrite;

    return object.provider && object.provider === 'azure' && isCorrectExtension && hasRight;
  }

  newDirectoryKeyUp(event: KeyboardEvent): void {
    if (
      event.key === 'Enter' &&
      !this.isCreatingRequestSent &&
      !this._explorerService.isDisplayingCreateFolderOverrideModal
    ) {
      this.saveNewDirectory();
    }

    if (event.key === 'Escape' && !this.isCreatingRequestSent) {
      this.removeNewDirectory();
      this._createDirectoryInputBlur();
    }
  }

  private _createDirectoryInputBlur(): NodeJS.Timer {
    return setTimeout(() => {
      if (this.newDirectoryInput && this.newDirectoryInput.nativeElement) {
        this.newDirectoryInput.nativeElement.blur();
      }
    }, 100);
  }

  private _createDirectoryInputFocus(): NodeJS.Timer {
    return setTimeout(() => {
      if (this.newDirectoryInput && this.newDirectoryInput.nativeElement) {
        this.newDirectoryInput.nativeElement.focus();
      }
    }, 100);
  }

  openEdition(object: IDatalakeObjectWithPermission): void {
    this.currentlyLoadingEditionName = object.name;
    this._explorerService.openEditionModal(this.canWrite, object).subscribe(() => {
      this.currentlyLoadingEditionName = null;
    });
  }

  saveNewDirectory(): void {
    this.isCreatingRequestSent = true;
    const createFolder$ = this._explorerService.createFolder(this.newDirectoryName);

    const afterCreated = (error?: unknown) => {
      if (error) {
        this._logger.error('[DatalakePreviewComponent]', 'Error creating directory', error);
        this._alertService.error(error.toString());
      } else {
        this.newDirectoryName = '';
      }

      this.isCreatingRequestSent = false;
      this.removeNewDirectory();
    };

    if (createFolder$) {
      createFolder$.subscribe(
        (result: any) => {
          const hasError = result && result.status && 200 <= result.status && result.status < 300;
          afterCreated(hasError ? result : undefined);
        },
        (err: unknown) => {
          afterCreated(err);
        },
      );
    } else {
      this.isCreatingRequestSent = false;
    }
  }

  removeNewDirectory(event: Event = null): void {
    if (event) {
      event.stopPropagation();
    }

    this.isCreatingDirectoryChange.emit(false);
    this.explorerPageService.cancelFolderCreation();
  }

  openOverview(object: IDatalakeObjectWithPermission): void {
    this.currentlyLoadingOverviewName = object.name;
    this._explorerService.openOverviewModal(this.canRead, object).subscribe(() => {
      this.currentlyLoadingOverviewName = null;
    });
  }

  handleEvents(event: IBrowserActivateEvent): void {
    if (event.type === 'click' && event.cellIndex !== 0 && !event.row.folderCreation) {
      void this.open(event.row);
    }
  }

  getRowClass() {
    return { 'c-pointer': true };
  }

  checkOneRow(row: IDatalakeObjectWithPermission): void {
    this._logger.debug('[DatalakePreviewComponent]', '(check-one)', 'Checking/unchecking one object', row);
    this._explorerService.checkOne(row);
  }

  checkAllRows(): void {
    this._logger.debug('[DatalakePreviewComponent]', '(check-all)', 'Checking/unchecking all objects');
    this._explorerService.checkAll();
  }

  sortChanged($event: { sorts: Array<{ dir: 'asc' | 'desc'; prop: string }> }) {
    if ($event.sorts.length === 1) {
      this.explorerPageService.sort($event.sorts[0].prop, $event.sorts[0].dir);
    }
  }
}
