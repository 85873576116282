import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DialogsService, ModalSizes } from '@dataportal/adl';
import { Navigator } from '@dataportal/navigator';

import { DashboardsService } from './dashboards.service';

import { DashboardsListComponent } from '../components/dashboards-list/dashboards-list.component';

import type { Source } from '../../sources/entities/source';
import type { IDashboardsListComponentData } from '../components/dashboards-list/dashboards-list.component';

// Service
@Injectable()
export class DashboardModalService {
  // Constructor
  constructor(
    private readonly _router: Router,
    private readonly _dialogsService: DialogsService,
    private readonly _dashboards: DashboardsService,
    private readonly _navigator: Navigator,
  ) {}

  // Methods
  open(source: Source, isOwner: boolean): void {
    if (source.powerbi.length === 1) {
      const dashboard = source.powerbi[0];
      const urlToOpen = dashboard.buildUrl(source.id, dashboard.name, this._navigator);

      if (dashboard.isExternal)
        if (dashboard.maintenanceMessage && !isOwner) {
          this._dialogsService.info({ message: '', innerHTML: dashboard.maintenanceMessage });
        } else {
          this._dashboards.emitExternalLinkPageViewEvent(source);
          window.open(urlToOpen.toString(), '_blank');
        }
      else {
        this._router.navigate([urlToOpen]);
      }
    } else if (source.powerbi.length > 1) {
      this._dialogsService.open<IDashboardsListComponentData>(
        DashboardsListComponent,
        {
          source: source,
          isWithToggleButton: false,
          isSourceOwner: isOwner,
        },
        ModalSizes.XL,
      );
    }
  }
}
