import { Injectable } from '@angular/core';
import type { SafeResourceUrl } from '@angular/platform-browser';
import { BehaviorSubject, of, Subject } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';
import { ApiPaginatedService, ApiService } from '@dataportal/front-api';
import { Logger } from '@dataportal/front-shared';
import { UsersService } from '@dataportal/users';
import { EntityBuilder } from '@decahedron/entity';

import { SourcesModule } from '../sources.module';

import { SourceWithMetrics } from '../entities/source-with-metrics';

@Injectable({
  providedIn: SourcesModule,
})
export class SourcesWithMetricsService extends ApiPaginatedService<SourceWithMetrics> {
  private readonly _pictures$ = new BehaviorSubject<Map<string, SafeResourceUrl>>(new Map());
  private readonly _pictures: Map<string, SafeResourceUrl> = new Map();
  private readonly _picturesQueue$ = new Subject<string>();

  pictures$ = this._pictures$.asObservable();

  constructor(
    protected apiService: ApiService,
    protected logger: Logger,
    private readonly _usersService: UsersService,
  ) {
    super(apiService, logger);
    this._picturesQueue$
      .pipe(
        mergeMap((user) => {
          if (this._pictures.has(user)) {
            return of(null);
          }

          return this._usersService.getImage(user, { level: 'silent' }).pipe(
            tap((picture) => {
              this._pictures.set(user, picture);
            }),
          );
        }, 4),
      )
      .subscribe(() => {
        this._pictures$.next(this._pictures);
      });

    this.results$.subscribe((sources) => {
      const toFetch = [
        ...new Set(
          sources.data
            .map((s) => s.technicalOwners.concat(s.functionalOwners))
            .reduce((acc, owners) => acc.concat(owners), [])
            .filter((user) => !this._pictures.has(user)),
        ),
      ];
      toFetch.forEach((owner) => this._picturesQueue$.next(owner));
    });
  }

  protected url = '/v5/admin/sources';

  protected buildOne(json: unknown): SourceWithMetrics {
    return EntityBuilder.buildOne(SourceWithMetrics, json);
  }

  protected buildMany(json: unknown[]): SourceWithMetrics[] {
    return EntityBuilder.buildMany(SourceWithMetrics, json);
  }
}
