<div
  *ngIf="this.hasNotification && this.hasToDisplayTotalCount"
  class="flex flex-col justify-center z-20 h-[20px] {{ backgroundColor }}"
  [ngClass]="{
    'notification-bubble-1-digit': this.totalCountToDisplay.length === 1,
    'notification-bubble-2-digits': this.totalCountToDisplay.length === 2,
    'notification-bubble-3-digits': this.totalCountToDisplay.length === 3
  }"
>
  <adl-text size="12" color="{{ numberColor }}"
            class="row-flex-not-responsive justify-center items-center w-full text-center">{{ this.totalCountToDisplay }}</adl-text>
</div>
