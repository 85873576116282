import type { OnDestroy, OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import type { Observable } from 'rxjs';
import { forkJoin, merge, of, Subject } from 'rxjs';
import { catchError, delay, take, takeUntil } from 'rxjs/operators';
import type { ISelectPredicates } from '@dataportal/adl';
import { EnvironmentService } from '@dataportal/front-environment';
import { AlertService, Logger } from '@dataportal/front-shared';
import type {
  CheckType,
  DecimalSeparator,
  FileDelimiter,
  FileFormat,
  IBannerRecipient,
  IGuardianStepCheckBasicInfos,
} from '@dataportal/guardian-utils';
import {
  DatalakeConfirmModalComponent,
  GuardianImportExportCheckService,
  GuardianService,
  IGuardianStatus,
  ISupportedGuardianChecksResource,
} from '@dataportal/guardian-utils';
import type { IProfile, LimitedUser } from '@dataportal/users';
import { UsersService } from '@dataportal/users';
import type { FormArray } from '@ngneat/reactive-forms';
import { FormBuilder, FormGroup } from '@ngneat/reactive-forms';
export interface IPotentialAlertRecipient extends IBannerRecipient {
  profile?: IProfile;
}

@Component({
  selector: 'app-guardian-step-check-basic-infos',
  templateUrl: './guardian-step-check-basic-infos.component.html',
  styleUrls: ['./guardian-step-check-basic-infos.component.scss'],
})
export class GuardianStepCheckBasicInfosComponent implements OnInit, OnDestroy {
  // inputs
  @Input() formGroup: FormGroup<IGuardianStepCheckBasicInfos>;
  @Input() existingChecksNameInSource: string[] = [];
  @Input() guardianStatus: IGuardianStatus;
  @Input() checkType: ISupportedGuardianChecksResource;
  @Input() importGuardianSpecFileInGuardianModal;

  // form constants
  checkTypes: Array<{ label: string; value: CheckType }> = [
    { label: 'File content', value: 'file-content' },
    { label: 'File last modification', value: 'last-modification' },
  ];
  fileCompressedChoices: Array<{ label: string; value: boolean }> = [
    { label: "Yes, it's compressed", value: true },
    { label: "No, it's not", value: false },
  ];
  fileFormats: Array<{ label: string; value: FileFormat }> = [
    { label: 'csv (recommended)', value: 'csv' },
    { label: 'xlsx', value: 'xlsx' },
  ];
  fileDelimiters: Array<{ label: string; value: FileDelimiter }> = [
    { label: 'semicolon ( ; )', value: ';' },
    { label: 'comma ( , )', value: ',' },
    { label: 'pipe ( | )', value: '|' },
    { label: 'space', value: ' ' },
    { label: 'tabulation ( \\t )', value: '\t' },
  ];
  decimalSeparators: Array<{ label: string; value: DecimalSeparator }> = [
    { label: 'dot ( . )', value: '.' },
    { label: 'comma ( , )', value: ',' },
  ];
  defaultPreparedRegexValues: Array<{ label: string; value: string }> = [
    { label: 'Every file ending with ".csv" extension', value: '/^[^\\/.]+.csv$/' },
    { label: 'Every file ending with ".xls" extension', value: '/^[^\\/.]+.xls$/' },
    { label: 'Every file ending with ".json" extension', value: '/^[^\\/.]+.json$/' },
  ];
  controlsFixedSizeNum = 35;

  // variables
  private _alertRecipientsAlreadyFetched: { [id: string]: IProfile } = {};
  preparedRegexValues: Array<{ label: string; value: string }> = [];
  isFetchingProfiles = false;
  limitedUserPredicates: ISelectPredicates<LimitedUser>;
  selectedLimitedUser: LimitedUser;
  potentialAlertRecipients: IPotentialAlertRecipient[] = [];
  clearSelectList: Subject<void> = new Subject<void>();
  isUsingCustomRegex = true;
  hasToShowRegexRequiredError = false;
  hasToShowInvalidRegexError = false;

  private readonly _isReadyToImport$ = new Subject<boolean>();
  isReadyToImport$ = this._isReadyToImport$.asObservable();

  private readonly _destroyed$ = new Subject<void>();

  constructor(
    private readonly _usersService: UsersService,
    private readonly _guardianService: GuardianService,
    private readonly _guardianImportExportCheckService: GuardianImportExportCheckService,
    private readonly _alertService: AlertService,
    private readonly _formBuilder: FormBuilder,
    private readonly _logger: Logger,
    public environement: EnvironmentService,
    private readonly _modalMatService: MatDialog,
  ) {}

  ngOnInit(): void {
    this._subscribeNameErrors();

    if (this.checkType === 'datalakePath') {
      this._subscribeRegexErrors();
    }

    this._setPredicates();
    this._refreshPotentialAlertRecipientsFromForm();
    this._refreshPotentialAlertRecipientsProfilesFromForm();
    this._fetchProfiles();
    this.formGroup.value$.pipe(takeUntil(this._destroyed$), delay(1)).subscribe(() => {
      const errors = this.formGroup.errors || {};

      if (this.checkType === 'datalakePath') {
        this.formGroup.get('sheetName').setErrors(errors.sheetNameRequired ? { sheetNameRequired: true } : null);
        this.formGroup
          .get('fileDelimiter')
          ?.setErrors(
            errors.fileDelimiterAndDecimalSeparatorMustBeDifferent
              ? { fileDelimiterAndDecimalSeparatorMustBeDifferent: true }
              : null,
          );
        this.formGroup
          .get('decimalSeparator')
          ?.setErrors(
            errors.fileDelimiterAndDecimalSeparatorMustBeDifferent
              ? { fileDelimiterAndDecimalSeparatorMustBeDifferent: true }
              : null,
          );
        this.formGroup
          .get('filteringRegex')
          ?.setErrors(
            errors.regexRequired ? { regexRequired: true } : errors.invalidRegex ? { invalidRegex: true } : null,
          );
      }

      this.formGroup
        .get('alertRecipients')
        ?.setErrors(errors.alertRecipientsRequired ? { alertRecipientsRequired: true } : null);
    });
    this._guardianService.nameFilterCheckRegexList$
      .pipe(
        takeUntil(this._destroyed$),
        catchError(() => []),
      )
      .subscribe((nameFilterCheckRegexList) => {
        const regexList = [];
        nameFilterCheckRegexList.map((checkRegex) => {
          regexList.push({ label: checkRegex.label, value: `/${checkRegex.regex}/` });
        });
        this.preparedRegexValues = regexList.length ? regexList : this.defaultPreparedRegexValues;
      });
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
  }

  private _subscribeNameErrors(): void {
    const nameControl = this.formGroup.get<'name'>(['name']);
    merge(this.formGroup.value$, nameControl.dirty$)
      .pipe(takeUntil(this._destroyed$), delay(1))
      .subscribe((v) => {
        let hasError = false;
        const errors: { [key: string]: unknown } = {};

        if (!nameControl?.value?.length) {
          hasError = true;
          errors.checkNameRequiredError = true;
        } else if (nameControl?.value?.length && this.existingChecksNameInSource.includes(nameControl.value)) {
          hasError = true;
          errors.checkNameAlreadyTakenError = true;
        }

        nameControl.setErrors(hasError ? errors : null);
      });
  }

  private _subscribeRegexErrors(): void {
    const regexControl = this.formGroup.get<'filteringRegex'>(['filteringRegex']);
    merge(this.formGroup.value$, regexControl.dirty$)
      .pipe(takeUntil(this._destroyed$), delay(1))
      .subscribe(() => {
        const errors = this.formGroup.errors || {};
        this.hasToShowInvalidRegexError = false;
        this.hasToShowRegexRequiredError = false;

        if (regexControl.dirty || regexControl.touched) {
          if (errors.regexRequired) {
            this.hasToShowRegexRequiredError = true;
            this.hasToShowInvalidRegexError = false;
          } else if (errors.invalidRegex) {
            this.hasToShowInvalidRegexError = true;
            this.hasToShowRegexRequiredError = false;
          }
        }
      });
  }

  private _setPredicates(): void {
    const isNotAlreadySelected = (limitedUser: LimitedUser): boolean => {
      return !this.potentialAlertRecipients.find((alertRecipient) => alertRecipient.userId === limitedUser.id);
    };

    this.limitedUserPredicates = { isNotAlreadySelected };
  }

  private _getAlertRecipientsProfilesFromForm(): IProfile[] {
    return this.alertRecipients
      .getRawValue()
      .map((alertRecipient) => this._alertRecipientsAlreadyFetched[alertRecipient.userId]);
  }

  private _refreshAlertRecipientsInForm(): void {
    this.alertRecipients.clear();
    this.potentialAlertRecipients.forEach((alertRecipient) => {
      this.alertRecipients.push(
        this._formBuilder.group({
          userId: alertRecipient.userId,
          isAlertedOnSuccess: alertRecipient.isAlertedOnSuccess,
          isAlertedOnFailure: alertRecipient.isAlertedOnFailure,
        }) as FormGroup<IBannerRecipient>,
      );
    });
  }

  private _refreshPotentialAlertRecipientsFromForm(): void {
    this.alertRecipients.getRawValue().forEach((alertRecipient) =>
      this.potentialAlertRecipients.push({
        userId: alertRecipient.userId,
        isAlertedOnSuccess: alertRecipient.isAlertedOnSuccess,
        isAlertedOnFailure: alertRecipient.isAlertedOnFailure,
      }),
    );
  }

  private _refreshPotentialAlertRecipientsProfilesFromForm(): void {
    const formProfiles = this._getAlertRecipientsProfilesFromForm();
    this.potentialAlertRecipients.forEach((potentialAlertRecipient) => {
      potentialAlertRecipient.profile = formProfiles.find(
        (profile) => profile?.email === potentialAlertRecipient.userId,
      );
    });
  }

  private _refreshPotentialAlertRecipientsProfilesFromCache(): void {
    this.potentialAlertRecipients.forEach((potentialAlertRecipient) => {
      if (!potentialAlertRecipient.profile) {
        potentialAlertRecipient.profile = this._alertRecipientsAlreadyFetched[potentialAlertRecipient.userId];
      }
    });
  }

  private _fetchProfiles(): void {
    this.isFetchingProfiles = true;
    const requests$: Array<Observable<IProfile>> = [];
    const erroredIds = [];
    this._logger.debug('[GuardianConfig] Fetching profiles');
    this._logger.debug('[GuardianConfig] In cache', this._alertRecipientsAlreadyFetched);
    const alertRecipientsIds = this.potentialAlertRecipients.map((alertRecipient) => alertRecipient.userId);

    for (const alertRecipientId of alertRecipientsIds) {
      if (!this._alertRecipientsAlreadyFetched[alertRecipientId]) {
        this._logger.debug('[GuardianConfig] Cache miss', alertRecipientId);
        const fetchDetails$ = this._usersService.getProfile(alertRecipientId).pipe(
          catchError((err: unknown) => {
            this._logger.error('[GuardianConfig] Error fetching user details', { user: alertRecipientId, err });
            erroredIds.push(alertRecipientId);

            return of(null);
          }),
        );
        requests$.push(fetchDetails$);
      } else {
        this._logger.debug('[GuardianConfig] Cache hit', alertRecipientId);
      }
    }

    if (requests$.length === 0) {
      this._refreshPotentialAlertRecipientsProfilesFromCache();
      this.isFetchingProfiles = false;

      return;
    }

    this._logger.debug('[GuardianConfig] Fetching profiles concurrently', requests$);
    forkJoin(requests$)
      .pipe(takeUntil(this._destroyed$))
      .subscribe((profiles) => {
        this._logger.debug('[GuardianConfig] All requests returned', profiles);
        profiles.forEach((profile) => {
          this._alertRecipientsAlreadyFetched[profile?.email] = profile;
        });
        this._refreshPotentialAlertRecipientsProfilesFromCache();
        this._logger.debug('[GuardianConfig] Alert recipients refreshed', this._alertRecipientsAlreadyFetched);
        this._logger.debug('[GuardianConfig] Errored requests', erroredIds);
        this._logger.debug('[GuardianConfig] Profile successfully retrieved');
        this.isFetchingProfiles = false;
      });
  }

  get hasSelectedCsv(): boolean {
    return this.formGroup.get('fileFormat')?.value === 'csv';
  }

  get hasSelectedXlsx(): boolean {
    return this.formGroup.get('fileFormat')?.value === 'xlsx';
  }

  get hasSelectedJson(): boolean {
    return this.formGroup.get('fileFormat')?.value === 'json';
  }

  get hasSelectedFilteringFiles(): boolean {
    return this.formGroup.get('isFilteringFilesForCheck')?.value === true;
  }

  get hasSelectedFileOver100MB(): boolean {
    return this.formGroup.get('isFlatfileDeactivate')?.value === true;
  }

  get alertRecipients(): FormArray<IBannerRecipient> {
    return this.formGroup.get<'alertRecipients'>(['alertRecipients']) as FormArray;
  }

  togglePreparedRegex(): void {
    this.isUsingCustomRegex = !this.isUsingCustomRegex;
  }

  addUserToList(): void {
    if (!this.selectedLimitedUser) {
      return;
    }

    this.potentialAlertRecipients.push({
      userId: this.selectedLimitedUser.id,
      isAlertedOnSuccess: true,
      isAlertedOnFailure: true,
    });
    this._refreshAlertRecipientsInForm();
    this._fetchProfiles();
    this.clearSelectList.next();
  }

  removePotentialAlertRecipient(alertRecipient: IPotentialAlertRecipient): void {
    this.potentialAlertRecipients = this.potentialAlertRecipients.filter(
      (potentialAlertRecipient) => potentialAlertRecipient.userId !== alertRecipient.userId,
    );
    this._refreshAlertRecipientsInForm();
  }

  toggleOnSuccess = (alertRecipient: IPotentialAlertRecipient): void => {
    alertRecipient.isAlertedOnSuccess = !alertRecipient.isAlertedOnSuccess;
    this._refreshAlertRecipientsInForm();
  };

  toggleOnFailure = (alertRecipient: IPotentialAlertRecipient): void => {
    alertRecipient.isAlertedOnFailure = !alertRecipient.isAlertedOnFailure;
    this._refreshAlertRecipientsInForm();
  };

  /* specifications import methods */

  private _notifyThatIsNotReadyToImport(): void {
    this._isReadyToImport$.next(false);
  }

  private _notifyThatIsReadyToImport(): void {
    this._isReadyToImport$.next(true);
  }

  onClickImportButton(): void {
    if (this.guardianStatus) {
      this._openOverwriteConfirmModal();
    } else {
      this._notifyThatIsReadyToImport();
    }
  }

  onImportFile(files: FileList): void {
    const filesArray = Array.from(files || []);

    if (!filesArray?.length) {
      return;
    }

    const file = filesArray[0];
    const reader = new FileReader();
    reader.readAsText(file);

    reader.onload = async (event) => {
      const specificationsFileStr = event?.target?.result?.toString() || '';
      this.importGuardianSpecFileInGuardianModal(specificationsFileStr, this.checkType);
    };
  }

  private _openOverwriteConfirmModal(): void {
    const overwriteConfirmModal = this._modalMatService.open(DatalakeConfirmModalComponent, {
      width: '501px',
      minWidth: '501px',
      maxHeight: '98vh',
      backdropClass: 'modal-nested',
      data: {
        headerMsg: `Specifications have already been defined for this ${
          this.checkType === 'datalakePath' ? 'directory' : 'table'
        }.`,
        validateButtonText: 'Overwrite',
        closeButtonColor: 'white',
      },
    });
    overwriteConfirmModal
      .afterClosed()
      .pipe(take(1), takeUntil(this._destroyed$))
      .subscribe(
        (hasToOverwrite) => {
          if (hasToOverwrite) {
            this._notifyThatIsReadyToImport();
          } else {
            this._notifyThatIsNotReadyToImport();
          }
        },
        () => this._notifyThatIsNotReadyToImport(),
      );
  }
}
