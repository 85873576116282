import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { mergeMap, takeUntil } from 'rxjs/operators';
import type { IExternalReferential } from '@dataportal/front-api';
import { Logger } from '@dataportal/front-shared';
import type { CheckRegex, IGuardianStatus } from '@dataportal/guardian-utils';
import { GuardianService } from '@dataportal/guardian-utils';
import type { ISettings } from '@flatfile/adapter';
import type { FieldHookCallback, IDataHookResponse, ScalarDictionaryWithCustom } from '@flatfile/angular';

import { ExplorerService } from '../../services/explorer.service';
import { ItrackService } from '../../services/itrack.service';
import { UploadBrowserService } from '../../services/upload-browser.service';

@Component({
  selector: 'dpg-upload-datalake-modal',
  templateUrl: './upload-datalake-modal.component.html',
  styleUrls: ['./upload-datalake-modal.component.scss'],
})
export class UploadDatalakeModalComponent {
  // Attributes
  guardianStatus: IGuardianStatus;
  flatfileSettings: ISettings = null;
  flatfileFieldHooks: Record<string, FieldHookCallback> = {};
  flatfileRecordHooks: (
    record: ScalarDictionaryWithCustom,
    index: number,
  ) => IDataHookResponse | Promise<IDataHookResponse> = null;

  // Variables
  fieldCheckRegexList: Array<CheckRegex> = [];
  nameFilterCheckRegexList: Array<CheckRegex> = [];
  renamingFileCheckRegexList: Array<CheckRegex> = [];
  externalReferentialsList: Array<IExternalReferential> = [];
  bool = false;
  hasToShowToggleableContentColumn = true;
  hasToShowToggleableContentGlobal = false;
  currentUploadSvgPath = 'assets/dpg/imgs/icons/upload-directly-OFF.svg';
  currentFlatfileSvgPath = 'assets/dpg/imgs/icons/preview-OFF.svg';

  private readonly _destroyed$ = new Subject<void>();

  // Constructor
  constructor(
    private readonly _guardianService: GuardianService,
    readonly uploadBrowserService: UploadBrowserService,
    private readonly _explorerService: ExplorerService,
    private readonly _logger: Logger,
    private readonly _activeMatModal: MatDialogRef<UploadDatalakeModalComponent>,
    private readonly _itrackService: ItrackService,
    @Inject(MAT_DIALOG_DATA)
    data: {
      guardianStatus: IGuardianStatus;
      flatfileSettings: ISettings;
      flatfileFieldHooks: Record<string, FieldHookCallback>;
      flatfileRecordHooks: (
        record: ScalarDictionaryWithCustom,
        index: number,
      ) => IDataHookResponse | Promise<IDataHookResponse>;
    },
  ) {
    this.guardianStatus = data?.guardianStatus ? data.guardianStatus : this.guardianStatus;
    this.flatfileSettings = data?.flatfileSettings ? data.flatfileSettings : this.flatfileSettings;
    this.flatfileFieldHooks = data?.flatfileFieldHooks ? data.flatfileFieldHooks : this.flatfileFieldHooks;
    this.flatfileRecordHooks = data?.flatfileRecordHooks ? data.flatfileRecordHooks : this.flatfileRecordHooks;
  }

  ngOnInit(): void {
    this._guardianService.fieldCheckRegexList$
      .pipe(takeUntil(this._destroyed$))
      .subscribe((regexList) => (this.fieldCheckRegexList = regexList));
    this._guardianService.nameFilterCheckRegexList$
      .pipe(takeUntil(this._destroyed$))
      .subscribe((regexList) => (this.nameFilterCheckRegexList = regexList));
    this._guardianService.renamingFileCheckRegexList$
      .pipe(takeUntil(this._destroyed$))
      .subscribe((regexList) => (this.renamingFileCheckRegexList = regexList));
    this._guardianService.listExternalReferentials().subscribe((externalReferentialsList) => {
      this.externalReferentialsList = externalReferentialsList;
    });
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
  }

  // Methods
  close(): void {
    this._activeMatModal.close();
  }

  contentToggledColumn(hasToShowToggleableContentFromColumn: boolean): void {
    this.hasToShowToggleableContentColumn = hasToShowToggleableContentFromColumn;

    if (hasToShowToggleableContentFromColumn && this.hasToShowToggleableContentGlobal) {
      this.hasToShowToggleableContentGlobal = false;
    }
  }

  contentToggledGlobal(hasToShowToggleableContentFromGlobal: boolean): void {
    this.hasToShowToggleableContentGlobal = hasToShowToggleableContentFromGlobal;

    if (hasToShowToggleableContentFromGlobal && this.hasToShowToggleableContentColumn) {
      this.hasToShowToggleableContentColumn = false;
    }
  }

  onMouseUploadHover(): void {
    this.currentUploadSvgPath = 'assets/dpg/imgs/icons/upload-directly-ON.svg';
  }

  onMouseUploadOut(): void {
    this.currentUploadSvgPath = 'assets/dpg/imgs/icons/upload-directly-OFF.svg';
  }

  onMouseFlatfileHover(): void {
    this.currentFlatfileSvgPath = 'assets/dpg/imgs/icons/preview-ON.svg';
  }

  onMouseFlatfileOut(): void {
    this.currentFlatfileSvgPath = 'assets/dpg/imgs/icons/preview-OFF.svg';
  }

  private _uploadFile(event: File): void {
    this._logger.debug('[DatalakeBrowserComponent] Preparing to upload', event);

    if (this.guardianStatus?.isItrackEnabled) {
      const upload$ = this._itrackService.createFlowForNewFile(event).pipe(
        mergeMap((file) => {
          return this._explorerService.upload([file]);
        }),
      );

      return this.uploadBrowserService.subscribeToUpload(upload$, [event]);
    }

    this.uploadBrowserService.subscribeToUpload(this._explorerService.upload(event), [event]);
  }

  uploadFile(event: File): void {
    this._uploadFile(event);
    this.close();
  }
}
