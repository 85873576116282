import type { OnInit } from '@angular/core';
import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import { filter, first, takeUntil } from 'rxjs/operators';
import type { IStepperStep } from '@dataportal/adl';
import { ConfirmModalComponent } from '@dataportal/adl';
import { AlertService, Logger } from '@dataportal/front-shared';

@Component({
  selector: 'adl-stepper-form-modal',
  templateUrl: './stepper-form-modal.component.html',
  styleUrls: ['./stepper-form-modal.component.scss'],
})
export class StepperFormModal implements OnInit {
  @Input() resourceName: string;
  @Input() title: string;
  @Input() secondaryTitle: string;
  @Input() iconLink: string;
  @Input() stepLabels: string[] = [];
  @Input() lastStepButton: string;
  @Input() secondaryLastStepButton: string;
  @Input() isLoading = true;
  @Input() canGoNextStep$: Observable<boolean>;
  @Input() isNextButtonDisable = false;
  @Input() hasConfirmExitModal = true;
  currentStep = 1;
  steps: IStepperStep[] = [];
  nbSteps = 3;

  @Output() giveCurrentStep = new EventEmitter<number>();
  @Output() nextStep = new EventEmitter<number>();
  @Output() lastStep = new EventEmitter<boolean>();

  private readonly _destroyed$ = new Subject<void>();

  constructor(
    private readonly _alertService: AlertService,
    private readonly _logger: Logger,
    private readonly _modalMatService: MatDialog,
    private readonly _activeMatModal: MatDialogRef<StepperFormModal>,
    @Inject(MAT_DIALOG_DATA)
    data: {
      resourceName: string;
      title: string;
      secondaryTitle: string;
      iconLink: string;
      stepLabels: string[];
      lastStepButton: string;
      secondaryLastStepButton: string;
      isLoading: boolean;
    },
  ) {
    this.resourceName = data?.resourceName?.length ? data.resourceName : this.resourceName;
    this.title = data?.title?.length ? data.title : this.title;
    this.secondaryTitle = data?.secondaryTitle?.length ? data.secondaryTitle : this.secondaryTitle;
    this.iconLink = data?.iconLink?.length ? data.iconLink : this.iconLink;
    this.stepLabels = data?.stepLabels?.length ? data.stepLabels : this.stepLabels;
    this.lastStepButton = data?.lastStepButton?.length ? data.lastStepButton : this.lastStepButton;
    this.secondaryLastStepButton = data?.secondaryLastStepButton?.length
      ? data.secondaryLastStepButton
      : this.secondaryLastStepButton;
    this.isLoading = data?.isLoading ? data.isLoading : this.isLoading;
  }

  ngOnInit() {
    this.steps = [{ label: this.stepLabels[0] }, { label: this.stepLabels[1] }, { label: this.stepLabels[2] }];
  }

  /* navigation methods */

  private _close(): void {
    if (this.hasConfirmExitModal) {
      const confirmExitModal = this._modalMatService.open(ConfirmModalComponent, {
        width: '601px',
        minWidth: '601px',
        maxHeight: '98vh',
        backdropClass: 'modal-nested',
        data: {
          headerMsg: 'Are you sure to leave the Guardian configuration?',
          closeButtonColor: 'white',
        },
      });
      confirmExitModal
        .afterClosed()
        .pipe(first(), takeUntil(this._destroyed$))
        .subscribe((hasToExit) => {
          if (hasToExit) {
            this._activeMatModal.close();
          }
        });
    } else {
      this._activeMatModal.close();
    }
  }

  async goPreviousStep(): Promise<void> {
    if (this.currentStep !== 1) {
      this.currentStep -= 1;
      this.giveCurrentStep.emit(this.currentStep);
    } else {
      this._close();
    }
  }

  goNextStep(isSecondLastStepButton: boolean) {
    this.canGoNextStep$
      .pipe(
        first(),
        filter((can) => can),
        takeUntil(this._destroyed$),
      )
      .subscribe(() => {
        if (this.currentStep !== this.nbSteps) {
          this.currentStep += 1;
          this.giveCurrentStep.emit(this.currentStep);
        } else {
          this.lastStep.emit(isSecondLastStepButton);
        }
      });
    this.nextStep.emit(this.currentStep);
  }
}
