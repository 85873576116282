<div class="flex items-center cursor-pointer" (click)="clickSuggestResult(source)">
  <img
    *ngIf="source.powerbi?.length || source.externalLinks?.length || source.dashboardFolders?.length || source.datalakeProvider === 'external'"
    alt="dashboard img"
    src="assets/modules/data-catalog/imgs/dashboard-icon.svg"
    class="mr-2"
  >
  <img *ngIf="searchMode === 'advanced' && source.hasSnowflake"
       alt="snowflake img"
       src="assets/modules/data-catalog/imgs/snowflake-v2-icon.svg"
       class="mr-2"
  >
  <img *ngIf="searchMode === 'advanced' && (source.datalakeProvider === 'aws' || source.datalakeProvider === 'azure')"
       alt="datalake img"
       src="assets/modules/data-catalog/imgs/database-icon.svg"
       class="mr-2"
  >
  <span class="ml-2">{{ source.name }}</span>
</div>
