import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { filter, first, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { CurrentUserService } from '@dataportal/auth';
import type { IDatalakeCurrentDirectory } from '@dataportal/datalake-and-guardian';
import { DatalakeApiService, ExplorerService } from '@dataportal/datalake-and-guardian';
import type { GuardianCheckStatus } from '@dataportal/front-api';
import { ApiService } from '@dataportal/front-api';
import { DatalakePath, Logger } from '@dataportal/front-shared';
import type { IGuardianChecksDatasetMap, IGuardianStatus } from '@dataportal/guardian-utils';
import { GuardianService } from '@dataportal/guardian-utils';
import { WebsocketsService } from '@dataportal/websocket';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import type {
  ITrackAddFlowError,
  ItrackCreateFlowResponse,
  ITrackErrorDetails,
  ItrackGuardianResultStatus,
  ItrackRequestType,
  ITrackUpdateFlow,
} from '../models/itrack.model';
import { ItrackAPIActions, ItrackBusinessSteps, ItrackStatus } from '../models/itrack.model';

@UntilDestroy()
@Injectable()
export class ItrackService {
  private _correlationId: string;
  private _fileName: string;
  private _currentBusinessStep: ItrackBusinessSteps;

  constructor(
    private readonly _apiService: ApiService,
    private readonly _datalakeApiService: DatalakeApiService,
    private readonly _explorerService: ExplorerService,
    private readonly _currentUserService: CurrentUserService,
    private readonly _websocket: WebsocketsService,
    private readonly _guardianService: GuardianService,
    private readonly _logger: Logger,
  ) {
    this._subscribeOnStatusUpdate();
  }

  createFlowForNewFile(file: File): Observable<File> {
    this._fileName = file.name;

    return this._sendRequest<ItrackCreateFlowResponse>(ItrackAPIActions.CREATE, {
      documentNumber: file.name,
    }).pipe(
      map((response) => {
        this._correlationId = response.itrackResponse.correlationId;

        return new File([file], `${this._correlationId}_${file.name}`, { type: file.type });
      }),
      tap(() => {
        this.updateFlow(ItrackBusinessSteps.UPLOAD_STARTED, ItrackStatus.IN_PROGRESS);
      }),
    );
  }

  createFlowForExistingFile(fileName: string): Observable<string> {
    this._fileName = fileName;

    return this._sendRequest<ItrackCreateFlowResponse>(ItrackAPIActions.CREATE, {
      documentNumber: fileName,
    }).pipe(
      map((response) => {
        this._correlationId = response.itrackResponse.correlationId;

        return `${this._correlationId}_${fileName}`;
      }),
    );
  }

  updateFlow(businessStep: ItrackBusinessSteps, status: ItrackStatus): void {
    this._explorerService.current$
      .pipe(
        withLatestFrom(this._currentUserService.currentUser$, this._guardianService.currentGuardianStatus$),
        first(),
        switchMap(([currentDir, currentUser, currentGuardianStatus]) => {
          this._currentBusinessStep = businessStep;

          const datalakePath = new DatalakePath(
            currentDir.bucket.name + '/' + currentDir.path,
            currentDir.provider,
            currentDir.tenant,
          );

          return this._datalakeApiService.getRelatedSources(datalakePath).pipe(
            switchMap((relatedSources) => {
              let payload: ITrackUpdateFlow = {
                correlationId: this._correlationId,
                status,
                businessStep,
                technicalStep: 'upload',
                messageContent: {
                  data_asset_name: relatedSources[0].sourceName,
                  original_file_name: this._fileName,
                  new_file_name: `${this._correlationId}_${this._fileName}`,
                  uploader: currentUser.id,
                  source_datalake_url: `${currentDir.bucket.name}${currentDir.path}`,
                  source_datalake_bucket: currentDir.bucket.name,
                  source_datalake_folders: currentDir.path,
                },
              };

              payload = this._appendUpdateFlowDetails(payload, businessStep, status, currentGuardianStatus, currentDir);

              return this._sendRequest(ItrackAPIActions.UPDATE, payload).pipe(
                tap(() => {
                  if (this._isFileCheckedWithErrors(businessStep, status)) {
                    this.addFlowError({
                      code: currentGuardianStatus.checkId,
                      description: 'Check run failed',
                      additionalInfo: null,
                      details: JSON.stringify(currentGuardianStatus.checks[0].data_quality.columns_errors),
                    });

                    this._resetFlow();
                  }

                  if (this._isFileCheckedSuccessfully(businessStep, status)) {
                    this._resetFlow();
                  }
                }),
              );
            }),
          );
        }),
      )
      .subscribe({
        next: () => this._logger.debug('[ItrackService] Update has been sent to Itrack'),
        error: (err: unknown) => this._logger.error('[ItrackService] Error while sending Itrack update', err),
      });
  }

  addFlowError(error: ITrackErrorDetails): void {
    if (!this._correlationId) {
      return;
    }

    const payload = this._getErrorPayload(error);

    this._sendRequest(ItrackAPIActions.ERROR, payload).subscribe({
      next: () => this._logger.debug('[ItrackService] Error report has been sent to Itrack'),
      error: (err: unknown) => this._logger.error('[ItrackService] Error while sending Itrack error report', err),
    });
  }

  private _sendRequest<T>(action: ItrackAPIActions, payload: ItrackRequestType): Observable<T> {
    return this._apiService.post('v4/guardian/itrack-api', {
      action,
      [action]: payload,
    });
  }

  private _appendUpdateFlowDetails(
    payload: ITrackUpdateFlow,
    businessStep: ItrackBusinessSteps,
    status: ItrackStatus,
    currentGuardianStatus: IGuardianStatus<keyof IGuardianChecksDatasetMap>,
    currentDir: IDatalakeCurrentDirectory,
  ): ITrackUpdateFlow {
    if (this._isCheckTriggered(businessStep, status)) {
      return {
        ...payload,
        messageContent: {
          ...payload.messageContent,
          guardian_check_id: currentGuardianStatus.checkId.toString(),
          guardian_configurations: JSON.stringify(currentGuardianStatus.checkInfos.validation_schema_columns),
        },
      };
    }

    if (this._isFileCheckedWithErrors(businessStep, status)) {
      return {
        ...payload,
        messageContent: {
          ...payload.messageContent,
          target_datalake_url: `${currentDir.bucket.name}${currentGuardianStatus.checkInfos.target_folder_if_ok}`,
          target_datalake_bucket: currentDir.bucket.name,
          target_datalake_folders: currentGuardianStatus.checkInfos.target_folder_if_ok,
          guardian_check_id: currentGuardianStatus.checkId.toString(),
          guardian_result_status: this._mapCheckStatusToItrack(currentGuardianStatus.checks[0].status),
          guardian_result_details: JSON.stringify(currentGuardianStatus.checks[0].data_quality.columns_errors),
        },
      };
    }

    return payload;
  }

  private _getErrorPayload({ code, description, additionalInfo, details }: ITrackErrorDetails): ITrackAddFlowError {
    return {
      correlationId: this._correlationId,
      businessStep: this._currentBusinessStep,
      technicalStep: 'upload',
      code: code.toString(),
      description: description,
      additionalInfo: additionalInfo,
      details: details,
    };
  }

  private _subscribeOnStatusUpdate(): void {
    this._websocket.hasCheckFinished$
      .pipe(
        untilDestroyed(this),
        withLatestFrom(this._websocket.refreshGuardianChecks$),
        filter(([hasCheckFinished, guardianChecks]) => hasCheckFinished && !!this._correlationId),
        switchMap(([hasCheckFinished, refreshGuardianChecks]) =>
          this._guardianService.listChecksByDataset(refreshGuardianChecks.resource),
        ),
        filter((guardianStatus) => this._shouldHandleUpdate(guardianStatus)),
      )
      .subscribe((guardianStatus) => {
        if (!guardianStatus) {
          this._logger.error('[ItrackService] Error while sending Itrack update');
        }

        switch (guardianStatus.checks[0]?.status) {
          case 'succeed':
            this.updateFlow(ItrackBusinessSteps.FILE_CHECKED, ItrackStatus.COMPLETED);
            break;

          case 'errored':
            this.updateFlow(ItrackBusinessSteps.FILE_CHECKED, ItrackStatus.IN_PROGRESS);
            break;

          case 'crashed':
            this.addFlowError({
              code: guardianStatus.checks[0].id_check,
              description: guardianStatus.checks[0].status,
              details: guardianStatus.checks[0].crash_error?.type,
              additionalInfo: guardianStatus.checks[0].crash_error?.message,
            });
            break;
        }
      });
  }

  private _shouldHandleUpdate(guardianStatus: IGuardianStatus<keyof IGuardianChecksDatasetMap>): boolean {
    return (
      guardianStatus?.checks[0]?.name === `${this._correlationId}_${this._fileName}` ||
      guardianStatus?.checks[0]?.status === 'crashed'
    );
  }

  private _resetFlow(): void {
    this._correlationId = null;
    this._fileName = null;
    this._currentBusinessStep = null;
  }

  private _isCheckTriggered(businessStep: ItrackBusinessSteps, status: ItrackStatus): boolean {
    return businessStep === ItrackBusinessSteps.CHECK_TRIGGERED && status === ItrackStatus.IN_PROGRESS;
  }

  private _isFileCheckedSuccessfully(businessStep: ItrackBusinessSteps, status: ItrackStatus): boolean {
    return businessStep === ItrackBusinessSteps.FILE_CHECKED && status === ItrackStatus.COMPLETED;
  }

  private _isFileCheckedWithErrors(businessStep: ItrackBusinessSteps, status: ItrackStatus): boolean {
    return businessStep === ItrackBusinessSteps.FILE_CHECKED && status === ItrackStatus.IN_PROGRESS;
  }

  private _mapCheckStatusToItrack(status: GuardianCheckStatus): ItrackGuardianResultStatus | null {
    switch (status) {
      case 'errored':
      case 'crashed':
        return 'Failed';

      case 'succeed':
        return 'Success';

      default:
        return null;
    }
  }
}
