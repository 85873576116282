import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import type { IAPIDatalakeReferential } from '@dataportal/front-api';
import { APIFetchCachingService, ApiService } from '@dataportal/front-api';
import { AlertService, Logger } from '@dataportal/front-shared';

import type { IDatalakeCurrentPath, IDatalakeParseObjectSettings } from '../entities/datalake-parse-object';

// Services
@Injectable()
export class DatalakeParseObjectService extends APIFetchCachingService<IDatalakeParseObjectSettings> {
  static DEFAULT_TIME_OUT_IN_MINUTES = 60;

  // Constructor
  constructor(readonly apiService: ApiService, readonly logger: Logger, private readonly _alertService: AlertService) {
    super(DatalakeParseObjectService.DEFAULT_TIME_OUT_IN_MINUTES, apiService, logger);
  }

  private static _buildQueryParameters(settings: unknown): string {
    return Object.keys(settings)
      .filter((key) => settings[key] != null)
      .map((key) => `${key}=${encodeURIComponent(settings[key])}`)
      .join('&');
  }

  static convertDatalakeReferentialToDatalakeParseObjectSettings(
    currentDatalakePath: IDatalakeCurrentPath,
    datalakeReferential: IAPIDatalakeReferential,
    checkId: string,
  ): IDatalakeParseObjectSettings {
    return {
      datalakeCurrentPath: { ...currentDatalakePath },
      parseObjectSettings: {
        bucketToParse: datalakeReferential.bucket_name,
        pathToParse: datalakeReferential.prefix,
        providerToParse: datalakeReferential.datalake,
        storageAccountToParse: datalakeReferential.datalake_params?.azure_storage_account,
        isOneRow: true,
        sheetName: null,
        columnName: null,
      },
      checkInformation: {
        checkId: checkId,
      },
    } as IDatalakeParseObjectSettings;
  }

  private static _getCommonBaseUrl(settings: IDatalakeParseObjectSettings): string {
    return `v4/guardian/${settings.datalakeCurrentPath.bucket}/parse?`;
  }

  getExtract(settings: IDatalakeParseObjectSettings): Observable<string[]> {
    return super.getExtract(settings).pipe(
      catchError(() => {
        this.logger.debug('An error occured while fetching datalake external referentials');
        const fullPath = `${settings?.parseObjectSettings?.bucketToParse}/${settings?.parseObjectSettings?.pathToParse}`;
        this._alertService.error(
          `Reference file "${fullPath}" is malformatted or simply not available anymore`,
          null,
          false,
          10000,
        );

        return [];
      }),
    );
  }

  cachedKey(settings: IDatalakeParseObjectSettings): string {
    return `${DatalakeParseObjectService._getCommonBaseUrl(settings)}${DatalakeParseObjectService._buildQueryParameters(
      `[Extract]-[${settings.parseObjectSettings.providerToParse}]-[${settings.parseObjectSettings.storageAccountToParse}]-[${settings.parseObjectSettings.pathToParse}]`,
    )}`;
  }

  fetchUrl(settings: IDatalakeParseObjectSettings): string {
    return `${DatalakeParseObjectService._getCommonBaseUrl(settings)}${DatalakeParseObjectService._buildQueryParameters(
      {
        ...settings.datalakeCurrentPath,
        ...settings.parseObjectSettings,
        ...settings.checkInformation,
      },
    )}`;
  }
}
