import type { OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Component, Input } from '@angular/core';
import type { Observable, Subscription } from 'rxjs';
import { EMPTY, merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TextType } from '@dataportal/adl';
import type { FormControl } from '@ngneat/reactive-forms';
import { AbstractControl } from '@ngneat/reactive-forms';
import type { ControlState } from '@ngneat/reactive-forms/lib/types';
import { Placement } from '@popperjs/core';

@Component({
  selector: 'app-guardian-form-control',
  templateUrl: './form-control.component.html',
  styleUrls: ['./form-control.component.scss'],
})
export class GuardianFormControlComponent implements OnInit, OnDestroy, OnChanges {
  @Input() label: string;
  @Input() hint: string;
  @Input() control: AbstractControl;
  @Input() placement: Placement = 'top';
  @Input() labelType: TextType = 'accent';
  @Input() isLabelBold = null;
  @Input() errorMessages: { [type: string]: string };
  @Input() required: boolean;
  @Input() relatedControlsToCheck: FormControl[] = [];

  @Input() hasToAlignLeft = false;
  @Input() leftPartWidthClass = 'w35';
  @Input() rightPartWidthClass = 'w65';
  @Input() leftPartWidth: string;
  @Input() rightPartWidth: string;
  @Input() spaceBetween = 'pl20';

  @Input() isDisabled: boolean;

  messages: string[] = [];
  hasError = false;

  leftPartWidthClassList: string[] = [];
  rightPartWidthClassList: string[] = [];

  private _currentSubscription: Subscription;

  private readonly _destroyed$ = new Subject<void>();

  ngOnInit(): void {
    this._refreshRelatedControlsEventsToCheck();
    this._initComponentsClasses();
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.relatedControlsToCheck && this.relatedControlsToCheck.length) {
      this._refreshRelatedControlsEventsToCheck();
    }
  }

  private _refreshRelatedControlsEventsToCheck(): void {
    const allControlsToCheck = [this.control, ...this.relatedControlsToCheck];
    const allControlsEventsToCheck = allControlsToCheck.reduce(
      (allControlsEventsToCheckTmp: Observable<boolean | ControlState>[], currentControlToCheck) => {
        allControlsEventsToCheckTmp.push(
          currentControlToCheck.dirty$ ?? EMPTY,
          currentControlToCheck.touch$ ?? EMPTY,
          currentControlToCheck.status$ ?? EMPTY,
          currentControlToCheck.valueChanges ?? EMPTY,
        );

        return allControlsEventsToCheckTmp;
      },
      [] as Observable<boolean>[],
    );
    this._currentSubscription?.unsubscribe();
    this._currentSubscription = merge(...allControlsEventsToCheck)
      .pipe(takeUntil(this._destroyed$))
      .subscribe(() => {
        this._refreshMessages();
        this.hasError = (this.control.dirty || this.control.touched) && this.control.status === 'INVALID';
      });
  }

  private _initComponentsClasses(): void {
    this.leftPartWidthClassList.push(this.hasToAlignLeft ? 'j-start' : 'j-end');

    if (!this.leftPartWidth?.length) {
      this.leftPartWidthClassList.push(this.leftPartWidthClass);
    }

    if (!this.rightPartWidth?.length) {
      this.rightPartWidthClassList.push(this.rightPartWidthClass);
    }

    this.rightPartWidthClassList.push(this.spaceBetween);
  }

  private _refreshMessages(): void {
    this.messages = [];

    if (this.control.errors) {
      Object.keys(this.control.errors).forEach((type) => {
        if (this.errorMessages && this.errorMessages[type]) {
          this.messages.push(this.errorMessages[type]);
        }
      });
    }
  }
}
