import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { ApiService } from '@dataportal/front-api';

// Service
@Injectable()
export class DashboardsLogsService {
  // Constructor
  constructor(private readonly _apiService: ApiService) {}

  // Methods
  logLoadingTime(sourceId: string, dashboardId: string, tookTime: number): Observable<void> {
    dashboardId = encodeURIComponent(dashboardId);

    return this._apiService.post(`/v4/dashboards/${dashboardId}/sources/${sourceId}/logs`, {
      tookTime,
    });
  }

  logRenderingTime(sourceId: string, dashboardId: string, pageName: string, tookTime: number): Observable<void> {
    dashboardId = encodeURIComponent(dashboardId);
    pageName = encodeURIComponent(pageName);

    return this._apiService.post(`/v4/dashboards/${dashboardId}/sources/${sourceId}/logs/pages/${pageName}`, {
      tookTime,
    });
  }
}
