import { CommonModule } from '@angular/common';
import type { ModuleWithProviders } from '@angular/core';
import { NgModule } from '@angular/core';

import type { ILazyLoadedEnvironmentOptions } from './environment.options';
import { IS_ACCOR, IS_JCD, IS_PR } from './environment.options';
import { LAZY_LOADED_MODULES_ENVIRONMENT_OPTIONS } from './environment.options';

function isDpContextFactory(
  options: ILazyLoadedEnvironmentOptions,
  dpContextCode: ILazyLoadedEnvironmentOptions['dpContext']['code'],
) {
  return () => options.dpContext.code === dpContextCode;
}

@NgModule({
  imports: [CommonModule],
  declarations: [],
  providers: [],
  exports: [],
})
export class EnvironmentModule {
  static forRoot(options: ILazyLoadedEnvironmentOptions): ModuleWithProviders<EnvironmentModule> {
    return {
      ngModule: EnvironmentModule,
      providers: [
        { provide: LAZY_LOADED_MODULES_ENVIRONMENT_OPTIONS, useValue: options },
        { provide: IS_PR, useFactory: isDpContextFactory(options, 'PR') },
        { provide: IS_JCD, useFactory: isDpContextFactory(options, 'JCD') },
        { provide: IS_ACCOR, useFactory: isDpContextFactory(options, 'ACCOR') },
      ],
    };
  }
}
