import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'adl-document-copy-clipboard-button',
  templateUrl: './document-copy-clipboard-button.component.html',
  styleUrls: ['./document-copy-clipboard-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentCopyClipboardButtonComponent {
  @Input() canCopy = true;
  @Input() url: string;
  @Input() showLabel = false;
}
