import type { OnChanges, OnDestroy, OnInit } from '@angular/core';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { startWith, takeUntil, tap } from 'rxjs/operators';
import { FormControl } from '@ngneat/reactive-forms';

export type SwitchType = 'normal' | 'catalog' | 'leon' | 'data-asset-admin';

@Component({
  selector: 'adl-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss'],
})
export class SwitchComponent implements OnInit, OnDestroy, OnChanges {
  @Input() control: FormControl<boolean>;
  @Input() standalone: boolean | '';
  @Input() active: boolean;
  @Input() disabled: boolean;
  @Input() size: 'large' | 'small' = 'large';
  @Input() type: SwitchType = 'normal';

  @Output() changed = new EventEmitter<boolean>();

  subActive: boolean;
  private readonly _destroyed$ = new Subject<void>();

  ngOnInit(): void {
    if (this._isStandalone()) {
      this.subActive = this.active;
    } else {
      this.control.valueChanges
        .pipe(
          startWith(this.control.value),
          tap((value) => {
            if (value !== this.subActive) {
              this.subActive = value;
            }
          }),
          takeUntil(this._destroyed$),
        )
        .subscribe();
    }
  }

  ngOnChanges(): void {
    if (this._isStandalone()) {
      this.subActive = this.active;
    }
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  toggle(): void {
    if (this.disabled) {
      return;
    }

    if (this._isStandalone()) {
      this.changed.emit(!this.subActive);
    } else {
      this.control.markAsDirty();
      this.subActive = !this.subActive;
      this.control.setValue(this.subActive);
      this.changed.emit(this.subActive);
    }
  }

  private _isStandalone(): boolean {
    return this.standalone === '' || this.standalone === true;
  }
}
