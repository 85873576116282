import { NgModule } from '@angular/core';
import { ApiModule } from '@dataportal/front-api';

import { AthenaService } from './services/athena.service';

@NgModule({
  imports: [ApiModule],
  providers: [AthenaService],
})
export class AthenaModule {}
