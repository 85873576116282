import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'adl-star',
  templateUrl: './star.component.html',
  styleUrls: ['./star.component.scss'],
})
export class StarComponent {
  @Input() active: boolean;
  @Input() size = 20;
  @Output() clicked = new EventEmitter<void>();

  onClick(): void {
    this.clicked.emit();
  }
}
