import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

import type { IBucket } from '../../entities/datalake';

@Component({
  selector: 'dpg-buckets-list',
  templateUrl: './buckets-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BucketsListComponent {
  @Input() buckets: IBucket[] = [];
  @Input() selectedBucket: IBucket | null = null;
  @Input() isLoading = false;
  @Input() set bucketsSearchQuery(query: string) {
    this.bucketsSearchFormControl.setValue(query);
  }

  @Output() bucketSelect = new EventEmitter<IBucket>();
  @Output() bucketsSearchQueryChange = new EventEmitter<string>();

  protected readonly bucketsSearchFormControl = new FormControl(this.bucketsSearchQuery);
}
