<section class="bg-white rounded-[6px] shadow-[0px_5px_10px_0px_rgba(24,68,135,0.05)] overflow-hidden">
  <div class="overflow-y-auto h-[360px] m-[10px]">
    <table cdk-table [dataSource]="objects" [fixedLayout]="true" [trackBy]="trackByKey" class="w-full border">
      <ng-container cdkColumnDef="checkbox">
        <th cdk-header-cell *cdkHeaderCellDef class="pl-[20px] w-[35px]">
          <adl-checkbox
            *ngIf="!shouldHideCheckboxAll"
            checkboxColor="blue"
            checkboxSize="small"
            [checked]="areAllObjectsSelected"
            (changed)="toggleAllObjectsSelection.emit($event)"
          ></adl-checkbox>
        </th>
        <td cdk-cell *cdkCellDef="let element" class="pl-[20px] cursor-default" (click)="$event.stopPropagation()">
          <adl-checkbox
            class="cursor-pointer"
            checkboxColor="blue"
            checkboxSize="small"
            [checked]="element.isSelected"
            (changed)="objectSelectionToggle.emit([$event, element])"
          >
          </adl-checkbox>
        </td>
      </ng-container>

      <ng-container cdkColumnDef="cloud">
        <th cdk-header-cell *cdkHeaderCellDef class="text-[12px] font-bold w-[75px] text-center">Cloud</th>
        <td cdk-cell *cdkCellDef="let element">
          <img *ngIf="element.provider === 'aws'" src="/assets/dpg/imgs/icons/aws-logo.svg" class="h-[15px] mx-auto" />
          <svg
            *ngIf="element.provider === 'azure'"
            class="mx-auto"
            width="17"
            height="15"
            viewBox="0 0 17 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <path
              d="M15.7904 1.6667H8.65146L7.23857 0.241639C7.08177 0.0856696 6.86946 -0.00124368 6.64852 1.345e-05H0.831038C0.610652 1.345e-05 0.39917 0.0877626 0.243342 0.24415C0.0875131 0.400399 0 0.612325 0 0.833287V14.1667C0 14.3877 0.0875131 14.5996 0.243342 14.7559C0.39917 14.9123 0.610634 15 0.831038 15H15.7906C16.011 15 16.2225 14.9123 16.3783 14.7559C16.5341 14.5996 16.6216 14.3877 16.6216 14.1667V2.50006C16.6216 2.27908 16.5341 2.06702 16.3783 1.91078C16.2225 1.75453 16.011 1.66678 15.7906 1.66678L15.7904 1.6667Z"
              fill="#09316E"
            />
            <rect x="2.70312" y="2.70264" width="11.3514" height="11.3514" fill="url(#pattern0)" />
            <defs>
              <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
                <image
                  id="image0_809_1055"
                  width="1"
                  height="1"
                  href="/assets/dpg/imgs/icons/microsoft-azure-logo.png"
                />
              </pattern>
            </defs>
          </svg>
        </td>
      </ng-container>

      <ng-container cdkColumnDef="name">
        <th cdk-header-cell *cdkHeaderCellDef class="text-[12px] font-bold">Name</th>
        <td cdk-cell *cdkCellDef="let element">
          <adl-text color="grey-800" [size]="12" weight="normal">{{ element.name }}</adl-text>
        </td>
      </ng-container>

      <ng-container cdkColumnDef="tenant">
        <th cdk-header-cell *cdkHeaderCellDef class="text-[12px] font-bold text-center w-[145px]">Data Platform</th>
        <td cdk-cell *cdkCellDef="let element" class="text-center">
          <adl-text
            *ngIf="!!element.tenant"
            size="12"
            class="tenant px-[4px] pb-[1px] rounded-[4px]"
            color="primary-500"
          >
            {{ element.tenant }}
          </adl-text>
        </td>
      </ng-container>

      <tr cdk-header-row *cdkHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr
        cdk-row
        *cdkRowDef="let row; columns: displayedColumns"
        (click)="objectClick.emit(row)"
        class="cursor-pointer"
      ></tr>
    </table>

    <adl-loaded-item [loading]="isLoading">
      <div *ngIf="objects.length === 0" class="flex justify-center mt-[34px]">
        <adl-text *ngIf="!isBucketSelected; else noObjects"> Please select a bucket to display folders.</adl-text>

        <ng-template #noObjects>
          <adl-text>No objects found</adl-text>
        </ng-template>
      </div>
    </adl-loaded-item>
  </div>
</section>
