import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { Logger } from '@dataportal/front-shared';
import type { IAuthErrorReport } from '@dataportal/msal';
import { AuthenticationState, sendErrorReport, updateTokens } from '@dataportal/msal';

import { AuthStorageService } from './auth-storage.service';
import { AuthTokenService } from './auth-token.service';
import { BaseAuthService } from './base-auth.service';

import { AUTH_OPTIONS, IAuthOptions } from '../auth-options';
import { CognitoError } from '../errors/cognito-error';
import type { IProfileFromAuth } from './base-auth.service';

@Injectable()
export class CognitoAuthService extends BaseAuthService {
  private readonly _userProfile$ = new BehaviorSubject<IProfileFromAuth>(undefined);
  readonly userProfile$: Observable<IProfileFromAuth> = this._userProfile$.asObservable();

  constructor(
    private readonly _authUtilsService: AuthStorageService,
    private readonly _http: HttpClient,
    private readonly _logger: Logger,
    private readonly _tokenService: AuthTokenService,
    @Inject(AUTH_OPTIONS) private readonly _authOptions: IAuthOptions,
  ) {
    super();
  }

  acquireTokenDashboard(): Promise<string | void> {
    throw new CognitoError('Not implemented for Cognito');
  }

  acquireTokenDevops(): Promise<string | void> {
    throw new CognitoError('Not implemented for Cognito');
  }

  acquireTokenDatabricks(): Promise<string | void> {
    throw new CognitoError('Not implemented for Cognito');
  }

  async handleError(report: IAuthErrorReport) {
    this._authUtilsService.state = AuthenticationState.ERRORED;
    await sendErrorReport(this._authOptions.telemetryUrl, report, this._authOptions.telemetryKey);
    window.location.replace(`${this._authOptions.baseUrl}/login`);
  }

  isAuthenticated(): boolean {
    const provider = this._authUtilsService.provider;

    return provider === 'cognito' && !this._authUtilsService.isExpired('access');
  }

  login(): void {
    const url = `https://${this._authOptions.cognitoConfig.oauth.domain}/login?client_id=${
      this._authOptions.cognitoConfig.userPoolWebClientId
    }&response_type=${
      this._authOptions.cognitoConfig.responseType
    }&scope=${this._authOptions.cognitoConfig.oauth.scope.join('+')}&redirect_uri=${
      this._authOptions.cognitoConfig.oauth.redirectSignIn
    }`;
    window.location.replace(url);
  }

  async logout(): Promise<void> {
    this._authUtilsService.removeAuthStorage();
    window.location.replace(window.location.origin + '/login');
  }

  async refreshCurrentUser(): Promise<void> {
    this._http
      .get<{ email: string }>(`https://${this._authOptions.cognitoConfig.oauth.domain}/oauth2/userInfo`, {
        headers: {
          Authorization: `Bearer ${this._authUtilsService.accessToken}`,
        },
      })
      .subscribe({
        next: (user) => {
          this._userProfile$.next({ username: user.email });
        },
        error: (error: unknown) => {
          this._logger.error('[CognitoAuthService] Error happened fetching current user profile', error);
          this._userProfile$.next(undefined);
        },
      });
  }

  async refreshTokens(): Promise<string> {
    try {
      const headers = new Headers();
      headers.append('Authorization', `Basic ${this._authOptions.cognitoConfig.clientHash}`);

      const body = new URLSearchParams();
      body.append('grant_type', 'refresh_token');
      body.append('refresh_token', this._authUtilsService.refreshToken);

      const response = await fetch(`https://${this._authOptions.cognitoConfig.oauth.domain}/oauth2/token`, {
        method: 'POST',
        headers,
        body,
        redirect: 'follow',
      });
      const payload: { id_token: string; access_token: string } = await response.json();

      if (payload.access_token) {
        updateTokens({ provider: 'cognito', accessToken: payload.access_token, idToken: payload.id_token });
        this._tokenService.updateTokens({
          accessToken: payload.access_token,
          idToken: payload.id_token,
        });

        return payload.access_token;
      } else {
        this._logger.error('Could not extract token from payload');
        this.login();

        return null;
      }
    } catch (e) {
      this._logger.error('Error refreshing token');
      this.login();

      return null;
    }
  }
}
