import type { SafeHtml } from '@angular/platform-browser';
import type { Observable } from 'rxjs';
import { of } from 'rxjs';

export class NavbarItemV2 {
  constructor(
    readonly nameDisplay: string,
    readonly name: string,
    readonly url: string | (() => string),
    readonly visibility: Observable<boolean>,
    readonly logo: SafeHtml,
    readonly external: boolean,
  ) {}
}

export const defaultNavbarLogos: NavbarItemV2[] = [
  new NavbarItemV2(
    'Home',
    'home',
    '/',
    of(true),
    `<img alt="home-navbar" src="assets/bar-item-icons/home.svg" width="45" height="45">`,
    false,
  ),

  new NavbarItemV2(
    'Data Marketplace',
    'data-catalog',
    '/catalog',
    of(true),
    `<img alt="catalog-navbar" src="assets/bar-item-icons/data-catalog.svg" width="45" height="45">`,
    false,
  ),

  new NavbarItemV2(
    'Business Glossary',
    'business-glossary',
    '/glossary',
    of(true),
    `<img alt="business-glossary-navbar" src="assets/bar-item-icons/glossary.svg" width="45" height="45">`,
    false,
  ),

  new NavbarItemV2(
    'Data Tools & Services',
    'data-services',
    '/data-services',
    of(true),
    `<img alt="data-services-navbar" src="assets/bar-item-icons/data-services.svg" width="45" height="45">`,
    false,
  ),
];
export const otherNavbarLogos: Map<string, NavbarItemV2> = new Map([
  [
    'datalake',
    new NavbarItemV2(
      'Datalake',
      'datalake',
      '/datalake',
      of(true),
      `<img alt="datalake-navbar" src="assets/bar-item-icons/datalake.svg" width="45" height="45">`,
      false,
    ),
  ],
  [
    'access-management',
    new NavbarItemV2(
      'Access Management',
      'access-management',
      'https://access-management.pernod-ricard.io',
      of(true),
      `<img alt="access-management-navbar" src="assets/bar-item-icons/access-management.svg" width="45" height="45">`,
      true,
    ),
  ],
  [
    'azure-devops',
    new NavbarItemV2(
      'Azure Devops',
      'azure-devops',
      'https://dev.azure.com/pernod-ricard-data',
      of(true),
      `<img alt="azure-devops-navbar" src="assets/bar-item-icons/azure-devops.svg" width="45" height="45">`,
      true,
    ),
  ],
  [
    'data-academy',
    new NavbarItemV2(
      'Data Academy',
      'data-academy',
      '/academy',
      of(true),
      `<img alt="data-academy-navbar" src="assets/bar-item-icons/data-academy.svg" width="45" height="45">`,
      false,
    ),
  ],
  [
    'guardian',
    new NavbarItemV2(
      'Guardian',
      'guardian',
      'https://data.pernod-ricard.io/academy/products/6/Guardian-by-Data-Portal',
      of(true),
      `<img alt="guardian-navbar" src="assets/bar-item-icons/guardian.svg" width="45" height="45">`,
      true,
    ),
  ],
  [
    'leon',
    new NavbarItemV2(
      'Leon',
      'leon',
      '/leons',
      of(true),
      `<img alt="leon-navbar" src="assets/bar-item-icons/leon.svg" width="45" height="45">`,
      false,
    ),
  ],
  [
    'master-data-hub',
    new NavbarItemV2(
      'MDH',
      'leon',
      'https://master-data-hub.pernod-ricard.io/semarchy/mdm-app/MDH/MDH/dashboard/Welcome',
      of(true),
      `<img alt="mdh-navbar" src="assets/bar-item-icons/mdh.svg" width="45" height="45">`,
      true,
    ),
  ],
  [
    'narwhal',
    new NavbarItemV2(
      'Narwhal',
      'narwhal',
      '/narwhal',
      of(true),
      `<img alt="narwhal-navbar" src="assets/bar-item-icons/company.svg" width="45" height="45">`,
      false,
    ),
  ],
  [
    'notebooks',
    new NavbarItemV2(
      'Databricks',
      'notebooks',
      '/notebooks',
      of(true),
      `<img alt="notebooks-navbar" src="assets/bar-item-icons/notebooks.svg" width="45" height="45">`,
      false,
    ),
  ],
  [
    'papyrus',
    new NavbarItemV2(
      'Papyrus',
      'papyrus',
      '/papyrus',
      of(true),
      `<img alt="papyrus-navbar" src="assets/bar-item-icons/papyrus.svg" width="45" height="45">`,
      false,
    ),
  ],
  [
    'picasso',
    new NavbarItemV2(
      'Picasso',
      'picasso',
      'https://pernodricard.sharepoint.com/sites/data-capabilities/SitePages/Picasso.aspx',
      of(true),
      `<img alt="picasso-navbar" src="assets/bar-item-icons/company.svg" width="45" height="45">`,
      true,
    ),
  ],
  [
    'snowflake',
    new NavbarItemV2(
      'Snowflake',
      'snowflake',
      '/snowflake',
      of(true),
      `<img alt="snowflake-navbar" src="assets/bar-item-icons/snowflake.svg" width="45" height="45">`,
      false,
    ),
  ],
  [
    'accor-tableau',
    new NavbarItemV2(
      'Tableau',
      'accor-tableau',
      'https://myaccor.service-now.com/sp?id=sc_cat_item&sys_id=df72f8051b94d154e459a75ee54bcba7&referrer=popular_items',
      of(true),
      `<img alt="accor-tableau-navbar" src="assets/bar-item-icons/accor-tableau.svg" width="45" height="45">`,
      true,
    ),
  ],
  [
    'accor-dataiku',
    new NavbarItemV2(
      'Dataiku',
      'accor-dataiku',
      'https://myaccor.service-now.com/sp?id=sc_cat_item&sys_id=1d1e2d281bfac590e459a75ee54bcbfe&sysparm_category=6ac21e7b1b34b014414821b2b24bcbc2&catalog_id=e0d08b13c3330100c8b837659bba8fb4',
      of(true),
      `<img alt="accor-dataiku-navbar" src="assets/bar-item-icons/accor-dataiku.svg" width="45" height="45">`,
      true,
    ),
  ],
  [
    'accor-data',
    new NavbarItemV2(
      'Data & Analytics...',
      'accor-data',
      'https://myaccor.service-now.com/sp?id=sc_cat_item&sys_id=1cde43f31b089410d46e1028bd4bcb11&sysparm_category=6ac21e7b1b34b014414821b2b24bcbc2',
      of(true),
      `<img alt="accor-data-navbar" src="assets/bar-item-icons/accor-data.svg" width="45" height="45">`,
      true,
    ),
  ],
  [
    'accor-snowflake',
    new NavbarItemV2(
      'Snowflake',
      'accor-snowflake',
      'https://myaccor.service-now.com/sp?id=sc_cat_item&sys_id=ce1d8b4f1b17c550e459a75ee54bcb6e&sysparm_category=6ac21e7b1b34b014414821b2b24bcbc2&catalog_id=e0d08b13c3330100c8b837659bba8fb4',
      of(true),
      `<img alt="accor-snowflake-navbar" src="assets/bar-item-icons/accor-snowflake.svg" width="45" height="45">`,
      true,
    ),
  ],
  [
    'accor-data-management',
    new NavbarItemV2(
      'Data Product Demand...',
      'accor-data-management',
      'https://forms.office.com/e/HZbjhXiq2Q',
      of(true),
      `<img alt="accor-data-management-navbar" src="assets/bar-item-icons/accor-data-management.svg" width="45" height="45">`,
      true,
    ),
  ],
  [
    'accor-cockpit',
    new NavbarItemV2(
      'Data Governance...',
      'accor-cockpit',
      'https://accor.sharepoint.com/:b:/s/digitalbusinessfactorycommunity/EcXqdXtRQr1Au0-9gKrwYswBA2OQlejqxdI3t96XPnFMVw?e=DlofSW',
      of(true),
      `<img alt="accor-cockpit-navbar" src="assets/bar-item-icons/accor-cockpit.svg" width="45" height="45">`,
      true,
    ),
  ],
  [
    'accor-data-academy',
    new NavbarItemV2(
      'Data Academy',
      'accor-data-academy',
      '/academy',
      of(true),
      `<img alt="accor-data-academy-navbar" src="assets/bar-item-icons/accor-data-academy.svg" width="45" height="45">`,
      false,
    ),
  ],
  [
    'accor-data-sharing',
    new NavbarItemV2(
      'Data Sharing And Publishing',
      'accor-data-sharing',
      'https://forms.office.com/e/HZbjhXiq2Q',
      of(true),
      `<img alt="accor-data-sharing-navbar" src="assets/bar-item-icons/accor-data-sharing.svg" width="45" height="45">`,
      true,
    ),
  ],
  [
    'accor-data-extracts',
    new NavbarItemV2(
      'Data Extracts',
      'accor-data-extracts',
      'https://myaccor.service-now.com/sp?id=sc_cat_item&sys_id=1cde43f31b089410d46e1028bd4bcb11&sysparm_category=6ac21e7b1b34b014414821b2b24bcbc2',
      of(true),
      `<img alt="accor-data-extracts-navbar" src="assets/bar-item-icons/accor-data-extracts.svg" width="45" height="45">`,
      true,
    ),
  ],
  [
    'accor-data-lab',
    new NavbarItemV2(
      'New Data Lab / Env',
      'accor-data-lab',
      'https://forms.office.com/e/HZbjhXiq2Q',
      of(true),
      `<img alt="accor-data-lab-navbar" src="assets/bar-item-icons/accor-data-lab.svg" width="45" height="45">`,
      true,
    ),
  ],
  [
    'admin',
    new NavbarItemV2(
      'Admin',
      'admin',
      () => {
        return document.location.href.includes('/sources') || !document.location.href.includes('leons')
          ? '/admin'
          : '/admin/leons';
      },
      of(true),
      `<img alt="admin-navbar" src="assets/bar-item-icons/admin.svg" width="45" height="45">`,
      false,
    ),
  ],
]);
