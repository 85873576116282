import type { OnChanges, SimpleChanges } from '@angular/core';
import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';

@Component({
  selector: 'dpg-guardian-preview-tooltip',
  templateUrl: './guardian-preview-tooltip.component.html',
})
export class GuardianPreviewTooltipComponent implements OnChanges {
  @Input() title: string;
  @Input() description: string;
  @Input() actionBtnDescription: string;
  @Input() isLoading = true;
  @Input() childTemplate: TemplateRef<any>;
  @Input() isWhitelisted: boolean;
  @Output() actionEvent: EventEmitter<void> = new EventEmitter();

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isLoading && changes.isLoading.currentValue) {
      this.isLoading = changes.isLoading.currentValue;
    }
  }

  triggerAction(): void {
    this.actionEvent.emit();
  }
}
