<svg class="dpg-icon" width="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
  <path
    class="dpg-fg-medium-fill"
    d="M463.9,32h-416c-26.5,0-48,21.5-48,48v352c0,26.5,21.5,48,48,48h416c26.5,0,48-21.5,48-48V80C511.9,53.5,490.4,32,463.9,32z
                            	 M479.9,432c0,8.8-7.2,16-16,16h-416c-8.8,0-16-7.2-16-16V80c0-8.8,7.2-16,16-16h416c8.8,0,16,7.2,16,16V432z"
  />
  <path
    class="dpg-fg-medium-fill"
    d="M82.7,137.1v57.3h21.7v-57.3c0-6,4.9-10.9,10.9-10.9h79v-21.7h-79C97.2,104.5,82.7,119.1,82.7,137.1z"
  />
  <path
    class="dpg-fg-medium-fill"
    d="M114.4,204.4H72.7v-67.3c0-23.5,19.1-42.6,42.6-42.6h89v41.7h-89c-0.5,0-0.9,0.4-0.9,0.9V204.4z M92.7,184.4h1.7v-47.3
                        		c0-11.5,9.4-20.9,20.9-20.9h69v-1.7h-69c-12.5,0-22.6,10.1-22.6,22.6V184.4z"
  />
  <path
    class="dpg-fg-medium-fill"
    d="M104.4,376v-57.3H82.7V376c0,18,14.6,32.6,32.6,32.6h79v-21.7h-79C109.2,386.9,104.4,382,104.4,376z"
  />
  <path
    class="dpg-fg-medium-fill"
    d="M204.3,418.6h-89c-23.5,0-42.6-19.1-42.6-42.6v-67.3h41.7V376c0,0.5,0.4,0.9,0.9,0.9h89V418.6z M92.7,328.7V376
                        		c0,12.5,10.1,22.6,22.6,22.6h69v-1.7h-69c-11.5,0-20.9-9.4-20.9-20.9v-47.3H92.7z"
  />
  <path
    class="dpg-fg-medium-fill"
    d="M408.1,376c0,6-4.9,10.9-10.9,10.9h-79v21.7h79c18,0,32.6-14.6,32.6-32.6v-57.3h-21.7V376z"
  />
  <path
    class="dpg-fg-medium-fill"
    d="M397.3,418.6h-89v-41.7h89c0.5,0,0.9-0.4,0.9-0.9v-67.3h41.7V376C439.8,399.5,420.7,418.6,397.3,418.6z M328.2,398.6h69
                        		c12.5,0,22.6-10.1,22.6-22.6v-47.3h-1.7V376c0,11.5-9.4,20.9-20.9,20.9h-69V398.6z"
  />
  <path
    class="dpg-fg-medium-fill"
    d="M397.2,104.5h-79v21.7h79c5.9,0,10.9,4.9,10.9,10.9v57.3h21.7v-57.3C429.8,119.1,415.3,104.5,397.2,104.5z"
  />
  <path
    class="dpg-fg-medium-fill"
    d="M439.8,204.4h-41.7v-67.3c0-0.5-0.5-0.9-0.9-0.9h-89V94.5h89c23.5,0,42.6,19.1,42.6,42.6V204.4z M418.1,184.4h1.7v-47.3
                        		c0-12.5-10.1-22.6-22.6-22.6h-69v1.7h69c11.5,0,20.9,9.4,20.9,20.9V184.4z"
  />
</svg>
