import type { IRootCategory,ISubCategory,ISourceCategory as SourceCategoryBack } from '@dataportal/types';
import { Entity } from '@decahedron/entity';

/**
 * Custom business domain
 */
export class CustomCategory extends Entity {
  id: string;
  name: string;
  color: string;
  portalId: string;
  root: boolean;
  subcategories: SubCategory[];

  fromJson(data: any): void {
    this.id = data.pk;
    this.name = data.name;
    this.color = data.color;
    this.portalId = data.portal_id;
    this.root = false;
    this.subcategories = [];
  }

  toJson(): { color: string; name: string } {
    return {
      name: this.name,
      color: this.color,
    };
  }
}

/**
 * Root business domain
 */
export class RootCategory extends Entity {
  id: string;
  name: string;
  color: string;
  root: boolean;
  subcategories: SubCategory[];

  fromJson(data: IRootCategory): void {
    this.id = data.pk;
    this.name = data.name;
    this.color = data.color;
    this.root = true;
    this.subcategories = [];
  }
}

export interface ISubCategoryEager {
  category: {
    pk: string;
    sk: string;
    name: string;
    parent: string;
  };
  portals: IPortalsSubCategoryUsage[];
}

export interface IPortalsSubCategoryUsage {
  nbSources: number;
  portal: {
    id: string;
    name: string;
    name_to_search: string;
    type: string;
    owners: string[];
    companies: { companyId: string; companyName: string }[];
    background: string;
  };
}

export interface ISourceCategory {
  id: string;
  name: string;
  parent: string;
  color: string;
}

export type SourcesCategoriesMap = Map<string, ISourceCategory[]>;

export class SourcesCategories extends Entity {
  items: SourcesCategoriesMap = new Map();

  fromJson(data: { [sourceId: string]: Array<SourceCategoryBack> }): void {
    Object.keys(data).forEach((sourceId) => {
      const items = data[sourceId];
      this.items.set(
        sourceId,
        items.map((item) => ({
          id: item.pk,
          name: item.name,
          parent: item.parent,
          color: item.color,
        })),
      );
    });
  }
}

/**
 * Sub-business domain
 */
export class SubCategory extends Entity {
  id: string;
  name: string;
  parent: string;
  portals?: IPortalsSubCategoryUsage[] = [];

  fromJson(data: ISubCategory): void {
    this.id = data.pk;
    this.name = data.name;
    this.parent = data.parent;
    // // legacy ?
    // this.portals = data.portals;
  }

  toJson(): { parent: string; name: string } {
    return {
      name: this.name,
      parent: this.parent,
    };
  }
}

export interface ISubCategoryGroupedByParent {
  parent: RootCategory | CustomCategory;
  disabled: SubCategory[];
  enabled: SubCategory[];
}
