/* eslint-disable @typescript-eslint/naming-convention */
export const SEARCH_MODE_USED_BEFORE_NEW_TAB = 'dc-used-mode-before-new-tab';

export const SEARCH_MODE_PREFERENCE_NAME = 'dc-search-mode';

export const RECENTLY_SEARCHED = 'dc-recently-searched';

export const forbiddenVisualizationItems = ['data_studio', 'datorama', 'reeport'];

export enum FiltersNamingIdsReference {
  powerbi = 'Power BI',
  qlik = 'Qlik',
  external_link = 'External',
  custom = 'Custom',
  tableau = 'Dashboard',
  source_ready_to_explore = '"Ready to Explore" Data sources',
  azure = 'Cloud Datalake',
  aws = 'Cloud Datalake',
  external = 'Other',
}

export const dataClassificationMapping = {
  'internal-pr': 'Accor data',
  'third-party': 'Third party data',
  'open': 'Open data',
  'mix': 'Data mix',
  'restricted-usage': 'Restricted usage',
  'no-restricted-usage': 'Free of usage restriction',
  'has-personal-data': 'Contains personal data',
  'has-no-personal-data': 'Free of personal data',
};

export const tooltipsMapping = {
  'open':
    'Is information or content made freely available to use, reuse and redistribute by anyone. For example, covid figure, weather...',
  'third-party':
    'External data purchased and integrated into Accor systems. For example, data from internet (social media, public information) or from data sources (email lists...), from supermarket.',
  'internal-pr': 'Data created and shared by Accor.',
  'mix': 'Mix of data between at least two types of data sourcing (open, third party or internal data).',
  'internal':
    "Information that can be safely distributed within Accor and/or to Accor's service provider; disclosure could be harmful, but is not likely to result in important financial loss, damage to reputation, or legal action.",
  'public':
    'Information that can be safely distributed to public audiences outside of Accor; disclosure does not cause any impactful harm.',
  'confidential':
    'Information that is considered critical to Accor; disclosure could cause important harm notably in terms of financial losses, damage to reputation, or legal action.',
};

export const dataTypeMapping = {
  'transactional-data': 'Transactional data',
  'application-log': 'Application log',
  'master-data': 'Master data',
};

export enum contactTypes {
  FUNCTIONAL_OWNERS = 'functionalOwners',
  TECHNICAL_OWNERS = 'technicalOwners',
  DATA_OWNER = 'dataOwner',
  DATA_ASSET_CREATOR = 'createdBy',
}

export type GTMCatalogEventType = 'dc_datalake_asset_viewed' | 'dc_dashboard_viewed';

export interface IGTMCatalogData {
  name?: string;
  format?: string;
}

export interface IGTMCatalogEvent {
  event: string;
  dc_mode?: string;
  dc_datalake_asset_name?: string;
  dc_dashboard_name?: string;
}

export enum FavoriteTypes {
  DATA_ASSET,
  DASHBOARD,
  DATALAKE,
}

export const VISUALIZATION_FILTER = 'PACKAGED INSIGHTS';
export const AFFILIATE_FILTER = 'BUSINESS AREA & PRODUCT FAMILY';
export const DATA_STORAGE_FILTER = 'DATA AS A PRODUCT';
export const CERTIFICATION_FILTER = 'CERTIFICATION';
export const BUSINESS_CATEGORY_FILTER = 'SCOPE';
export const PERMISSION_FILTER = 'PERMISSION';
export const CONFIDENTIALITY_FILTER = 'CONFIDENTIALITY';
export const CONTACT_FILTER = 'CONTACT';
export const METADATA_FILTER = 'METADATA';
export const DATES_FILTER = 'DATES';
export const DATA_SOURCING_FILTER = 'DATA SOURCING';
