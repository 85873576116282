<svg class="dpg-icon" width="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
  <path
    class="dpg-fg-medium-fill"
    d="M463.9,32h-416c-26.5,0-48,21.5-48,48v352c0,26.5,21.5,48,48,48h416c26.5,0,48-21.5,48-48V80C511.9,53.5,490.4,32,463.9,32z
                            	 M479.9,432c0,8.8-7.2,16-16,16h-416c-8.8,0-16-7.2-16-16V80c0-8.8,7.2-16,16-16h416c8.8,0,16,7.2,16,16V432z"
  />
  <path
    class="dpg-fg-medium-fill"
    d="M396.8,200l-1.8,1.8c-1.2,1.2-1.2,3.2,0,4.4l45.9,45.8h-96.6c-1.7,0-3.1,1.4-3.1,3.1v2.6c0,1.7,1.4,3.1,3.1,3.1h96.6
                            	L395,306.6c-1.2,1.2-1.2,3.2,0,4.4l1.8,1.8c1.2,1.2,3.2,1.2,4.4,0l54.2-54.2c1.2-1.2,1.2-3.2,0-4.4L401.2,200
                            	C399.9,198.8,398,198.8,396.8,200z"
  />
  <path
    class="dpg-fg-medium-fill"
    d="M399,321.2c-2.7,0-5.4-1-7.5-3.1l-1.8-1.8c-2-2-3.1-4.7-3.1-7.5s1.1-5.5,3.1-7.5l33.1-33h-78.5c-5.8,0-10.6-4.8-10.6-10.6
                            	v-2.6c0-5.8,4.8-10.6,10.6-10.6h78.5l-33.1-33c-4.1-4.1-4.1-10.9,0-15l1.8-1.8c4-4,10.5-4.1,14.8-0.2l0.2,0.2l54.2,54.2
                            	c4.1,4.1,4.1,10.9,0,15l-54.2,54.2C404.4,320.2,401.7,321.2,399,321.2z"
  />
  <path
    class="dpg-fg-medium-fill"
    d="M113.7,312.8l1.8-1.8c1.2-1.2,1.2-3.2,0-4.4l-45.9-45.8h96.6c1.7,0,3.1-1.4,3.1-3.1v-2.6c0-1.7-1.4-3.1-3.1-3.1H69.7
                            	l45.9-45.9c1.2-1.2,1.2-3.2,0-4.4l-1.8-1.8c-1.2-1.2-3.2-1.2-4.4,0l-54.2,54.2c-1.2,1.2-1.2,3.2,0,4.4l54.2,54.2
                            	C110.5,314,112.5,314,113.7,312.8z"
  />
  <path
    class="dpg-fg-medium-fill"
    d="M111.6,321.2c-0.1,0-0.3,0-0.4,0c-2.7-0.1-5.3-1.3-7.2-3.3l-54.1-54.1c-2-2-3.1-4.7-3.1-7.5c0-2.8,1.1-5.5,3.1-7.5
                            	l54.2-54.2c4.1-4.1,10.9-4.1,15,0l1.8,1.8c4.1,4.1,4.1,10.9,0,15l-33.1,33.1h78.4c5.8,0,10.6,4.8,10.6,10.6v2.6
                            	c0,5.8-4.8,10.6-10.6,10.6H87.7l33.1,33c4.1,4.1,4.1,10.9,0,15l-1.8,1.8C117,320.1,114.3,321.2,111.6,321.2z"
  />
  <path
    class="dpg-fg-medium-fill"
    d="M198.8,142.6l1.8,1.8c1.2,1.2,3.2,1.2,4.4,0l45.8-45.9v96.6c0,1.7,1.4,3.1,3.1,3.1h2.6c1.7,0,3.1-1.4,3.1-3.1V98.6
                            	l45.9,45.9c1.2,1.2,3.2,1.2,4.4,0l1.8-1.8c1.2-1.2,1.2-3.2,0-4.4L257.4,84c-1.2-1.2-3.2-1.2-4.4,0l-54.2,54.2
                            	C197.6,139.5,197.6,141.4,198.8,142.6z"
  />
  <path
    class="dpg-fg-medium-fill"
    d="M256.5,205.7h-2.6c-5.8,0-10.6-4.8-10.6-10.6v-78.5l-33,33.1c-2,2-4.7,3.1-7.5,3.1c-2.8,0-5.5-1.1-7.5-3.1l-1.8-1.8
                            	c-4-4-4.1-10.5-0.2-14.8l0.2-0.2l54.2-54.2c4.1-4.1,10.9-4.1,15,0L317,133c4.1,4.1,4.1,10.9,0,15l-1.8,1.8c-4.1,4.1-10.9,4.1-15,0
                            	l-33.1-33.1v78.4C267.1,200.9,262.3,205.7,256.5,205.7z"
  />
  <path
    class="dpg-fg-medium-fill"
    d="M311.6,370.1l-1.8-1.8c-1.2-1.2-3.2-1.2-4.4,0l-45.8,45.9v-96.6c0-1.7-1.4-3.1-3.1-3.1H254c-1.7,0-3.1,1.4-3.1,3.1v96.6
                            	L205,368.3c-1.2-1.2-3.2-1.2-4.4,0l-1.8,1.8c-1.2,1.2-1.2,3.2,0,4.4l54.2,54.2c1.2,1.2,3.2,1.2,4.4,0l54.2-54.2
                            	C312.8,373.3,312.8,371.3,311.6,370.1z"
  />
  <path
    class="dpg-fg-medium-fill"
    d="M255.2,437.1c-2.7,0-5.4-1-7.5-3.1l-54.2-54.2c-4.1-4.1-4.1-10.9,0-15l1.8-1.8c2-2,4.7-3.1,7.5-3.1c2.8,0,5.5,1.1,7.5,3.1
                            	l33.1,33.1v-78.5c0-5.8,4.8-10.6,10.6-10.6h2.5c5.8,0,10.6,4.8,10.6,10.6v78.5l33-33.1c2-2,4.7-3.1,7.5-3.1c2.8,0,5.5,1.1,7.5,3.1
                            	l1.8,1.8c4.1,4.1,4.1,10.9,0,15L262.7,434C260.6,436.1,257.9,437.1,255.2,437.1z"
  />
</svg>
