import { InjectionToken } from '@angular/core';

// Constants
export const FLATFILE_OPTIONS = new InjectionToken<IFlatfileOptions>('dataportal-front:flatfile:options');

// Types
export interface IFlatfileOptions {
  // Options
  environment: string;
  flatfileLicenseKey: string;
}
