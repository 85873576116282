<div class="modal-header pb5">
  <div class="modal-title">
    Metadata <strong>{{ source.name }}</strong>
  </div>

  <button type="button" class="close" data-dismiss="modal" (click)="dismiss()">&times;</button>
</div>

<div class="modal-body pt15">
  <div *ngIf="source.hasMetadata" id="metadata">
    <div style="margin-top: 10px" class="tab-map tac table-responsive">
      <table class="table table-front table-source">
        <thead class="thead-default">
          <tr>
            <th scope="col">Property</th>
            <th scope="col">Value</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">Data provider name</th>
            <th scope="row">{{ source?.dataProvider?.name }}</th>
          </tr>
          <tr>
            <th scope="row">Data provider type</th>
            <th scope="row">{{ source?.dataProvider?.type }}</th>
          </tr>
          <tr>
            <th scope="row">Business status</th>
            <th scope="row">{{ source?.status }}</th>
          </tr>
          <tr>
            <th scope="row">Type</th>
            <th scope="row">{{ source?.type }}</th>
          </tr>
          <tr *ngIf="source?.type === 'Business'">
            <th scope="row">Linked project</th>
            <th scope="row">{{ source?.linkedProject }}</th>
          </tr>
          <tr>
            <th scope="row">File granularity</th>
            <th scope="row">{{ source?.fileGranularity }}</th>
          </tr>
          <tr *ngIf="source?.fileGranularity === 'Full'">
            <th scope="row">Update frequency</th>
            <th scope="row">{{ source?.updateFrequency }}</th>
          </tr>
          <tr *ngIf="source?.fileGranularity === 'Incremental'">
            <th scope="row">Increment size</th>
            <th scope="row">{{ source?.incrementSize }}</th>
          </tr>
          <tr *ngIf="source?.createdBy">
            <th scope="row">Created by</th>
            <th scope="row">{{ source?.createdBy }}</th>
          </tr>
          <tr *ngIf="source?.createdAt">
            <th scope="row">Creation time</th>
            <th scope="row">
              {{ source.createdAt | date: 'dd/MM/yyyy HH:mm:ss' }}
            </th>
          </tr>
          <tr *ngIf="source?.updatedBy">
            <th scope="row">Updated by</th>
            <th scope="row">{{ source?.updatedBy }}</th>
          </tr>
          <tr *ngIf="source?.updatedAt">
            <th scope="row">Update time</th>
            <th scope="row">
              {{ source.updatedAt | date: 'dd/MM/yyyy HH:mm:ss' }}
            </th>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
