import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { AdlModule } from '@dataportal/adl';
import { ApiModule } from '@dataportal/front-api';
import { GraphApiModule } from '@dataportal/front-graph-api';
import { SharedModule } from '@dataportal/front-shared';
import { UsersModule } from '@dataportal/users';

import { RecommendationModalComponent } from './components/recommendation-modal/recommendation-modal.component';

@NgModule({
  imports: [AdlModule, ApiModule, GraphApiModule, CommonModule, MatDialogModule, SharedModule, UsersModule],
  declarations: [RecommendationModalComponent],
  exports: [RecommendationModalComponent],
  providers: [{ provide: MAT_DIALOG_DATA, useValue: {} }],
})
export class RecommendationsModule {}
