<div
  class="alert-title--{{ type }} flex items-center rounded-xl"
  [ngClass]="{
    'alert-title--show-border': showBorder
  }"
>
  <div class="flex items-center mr-4">
    <adl-image
      class="alert-title--svg mr-4"
      [src]="svgPath"
      [size]="'16px'"
      [shape]="'square'"
      [equalizeHeight]="true"
    ></adl-image>
    <span class="alert-title--title text-2xl font-medium">{{ title }}</span>
  </div>

  <div class="w-fit ml-auto">
    <adl-image
      class="alert-title--cross-svg"
      [src]="crossPath"
      [size]="'10px'"
      [shape]="'square'"
      [equalizeHeight]="true"
    ></adl-image>
  </div>
</div>
