import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ApiModule } from '@dataportal/front-api';
import { SharedModule } from '@dataportal/front-shared';

@NgModule({
  imports: [CommonModule, FormsModule, RouterModule, ApiModule, SharedModule],
})
export class ToolsModule {}
