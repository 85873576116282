import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'adl-search-bar-additional-filter',
  templateUrl: './additional-filter.component.html',
  styleUrls: ['./additional-filter.component.scss'],
})
export class SearchBarAdditionalFilterComponent<FilterType> {
  @Input() additionalFilterList: FilterType[];
  @Input() selectedFilter: FilterType;
  @Input() hasCaret: boolean;
  @Output() updateSelectedFilter = new EventEmitter<FilterType>();

  selectFilter(someFilter: FilterType) {
    this.selectedFilter = someFilter;
    this.updateSelectedFilter.emit(someFilter);
  }
}
