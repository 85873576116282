import { InjectionToken } from '@angular/core';

export type SupportedAuthProviders = 'azureAD' | 'cognito';

// Constants
export const AUTH_OPTIONS = new InjectionToken<IAuthOptions>('dataportal-front:auth:options');

// Types
export interface IAuthOptions {
  // Options
  /**
   * Dataportal URL
   */
  baseUrl: string;
  authUriSuffix?: string;
  /**
   * Telemetry URL for reporting auth issues
   */
  telemetryUrl: string;
  telemetryKey: string;
  /**
   * Is e2e environment
   * @deprecated
   * Please, use E2EService instead
   */
  e2e: boolean;
  /**
   * Is a production environment
   */
  production: boolean;
  supportLink?: string;
  requireImpersonationScopeRoutes?: string[];
  /**
   * AzureAD configuration
   */
  azureAD: {
    authority: string;
    clientID: string;
  };
  /**
   * Cognito configuration
   */
  cognitoConfig: {
    region: string;
    userPoolId: string;
    userPoolWebClientId: string;
    clientHash: string;
    responseType: 'token' | 'code';
    oauth: {
      scope: string[];
      domain: string;
      redirectSignIn: string;
    };
  };
  supportedAuthenticators: SupportedAuthProviders[];
}
