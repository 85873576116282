import { Inject, Injectable } from '@angular/core';
import type { ActivatedRouteSnapshot, CanActivate, CanLoad, RouterStateSnapshot } from '@angular/router';
import { Router } from '@angular/router';
import type { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PermissionsModule } from '../permissions.module';

import { PermissionsService } from '../services/permissions.service';

import type { Action } from '../entities/action';
import { ACTIONS } from '../entities/action';
import { IPermissionsOptions, PERMISSIONS_OPTIONS } from '../permissions-options';
import type { Permissions } from '../services/permissions.service';

// Guard
@Injectable({
  providedIn: PermissionsModule,
})
export class SourceOwnerGuard implements CanActivate, CanLoad {
  constructor(
    private readonly _permissionsService: PermissionsService,
    private readonly _router: Router,
    @Inject(PERMISSIONS_OPTIONS) private readonly _options: IPermissionsOptions,
  ) {}

  private _canAccess(): Observable<boolean> {
    return this._permissionsService.permissionsAdmin$.pipe(
      map((permissions: Permissions) => {
        if (permissions && permissions.sources) {
          return true;
        }

        this._router.navigate(this._options.errorRouterLink);

        return false;
      }),
    );
  }

  // Check if the user has access to a specific source
  canActivate(_: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const parts = new URL(state.url, location.origin).pathname.split('/');

    if (parts[2] === 'v1') {
      parts.splice(2, 1);
    }

    const action: Action = ACTIONS[parts[3]] || 'update';
    const path: string | undefined = parts[4] || (action === 'create' ? undefined : parts[3]);

    if (action === 'create') {
      return this._canAccess();
    }

    return this._permissionsService.isAuthorized(action, 'sources', path).pipe(map((authorized) => authorized));
  }

  // Check if the user has access to at least one source
  canLoad(): Observable<boolean> {
    return this._canAccess();
  }
}
