<button
  class="adl-button df a-center j-center"
  (click)="onClick($event)"
  type="button"
  [ngClass]="classList"
  [disabled]="disabled || notClickable"
  [ngStyle]="this.fixedHeight?.length && { height: this.fixedHeight }"
>
  <div *ngIf="hasWarningIcon" class="flex justify-center items-center">
    <adl-svg-icon icon="warning"></adl-svg-icon>
  </div>
  <ng-content></ng-content>
  <a *ngIf="hasInfoIcon" class="circular-icon flex justify-center items-center">
    <adl-svg-icon icon="circular-information-icon"></adl-svg-icon>
  </a>
</button>
