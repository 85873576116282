export interface ITrackCreateFlow {
  documentNumber: string;
}

export interface ITrackUpdateFlow {
  correlationId: string;
  status: string;
  businessStep: string;
  technicalStep: 'upload';
  messageContent: {
    data_asset_name: string;
    original_file_name: string;
    new_file_name: string;
    uploader: string;
    source_datalake_url: string;
    source_datalake_bucket: string;
    source_datalake_folders: string;
    target_datalake_url?: string;
    target_datalake_bucket?: string;
    target_datalake_folders?: string;
    guardian_check_id?: string;
    guardian_configurations?: string;
    guardian_result_status?: ItrackGuardianResultStatus;
    guardian_result_details?: string;
  };
}

export type ItrackGuardianResultStatus = 'Success' | 'Failed';

export interface ITrackAddFlowError extends ITrackErrorDetails {
  correlationId: string;
  businessStep: string;
  technicalStep: 'upload';
}

export interface ITrackErrorDetails {
  code: string | number;
  description: string;
  additionalInfo: string;
  details: string;
}

export type ItrackRequestType = ITrackCreateFlow | ITrackUpdateFlow | ITrackAddFlowError;

export enum ItrackBusinessSteps {
  UPLOAD_STARTED = 'Upload started',
  CHECK_TRIGGERED = 'File renamed & check triggered',
  FILE_CHECKED = 'File checked & moved',
}

export enum ItrackStatus {
  IN_PROGRESS = 'In Progress',
  COMPLETED = 'Completed',
}

export enum ItrackAPIActions {
  CREATE = 'createFlow',
  UPDATE = 'updateFlow',
  ERROR = 'addFlowError',
}

export interface ItrackCreateFlowResponse {
  itrackResponse: {
    correlationId: string;
    partner: string;
    sender: string;
    receiver: string;
    publishingSystem: string;
    subscribingSystem: string;
    documentNumber: string;
    transactionType: string;
    application: string;
    reportingSystem: string;
    dateSubmited: Date;
    version: string;
    sensitiveContent: false;
    referenceDocumentNumbers: string[];
  };
}
