<ng-select
  bindLabel="companyName"
  bindValue="pk"
  placeholder="Select a company"
  typeToSearchText="Type to search"
  [items]="list"
  [loading]="loading"
  [virtualScroll]="true"
  (clear)="clear()"
  (change)="setSelectedCompany($event)"
  [typeahead]="searchSubject"
>
</ng-select>
