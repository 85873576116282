import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocaleService {
  getLanguage() {
    return navigator.language || 'en';
  }
}
