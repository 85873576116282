import { InjectionToken } from '@angular/core';

// Constants
export const DATA_TOOLS_OPTIONS = new InjectionToken<IDataToolsOptions>('dataportal-front:datatools:options');

// Types
export interface IDataToolsOptions {
  // Options
  // - internal links
  datalakeRouterLink?: string[] | string;
  notebooksRouterLink?: string[] | string;

  // - external links
  azureDevOpsLink?: string;
  snowflakeLink?: string;
  dataAcademyLink?: string;
  supersetLink?: string;
  airflowLink?: string;
}
