<adl-card
  *ngFor="let choice of choices"
  (click)="selectCard(choice.value)"
  [active]="active === choice.value"
  [ngStyle]="styles"
  [centered]="true"
  [autoWidth]="!width"
  [ngClass]="{
    'active': active === choice.value,
    'c-not-allowed': disabled,
    'disabled': disabled
  }"
  class="c-pointer row-flex ml10 mr10 j-center a-center"
>
  <adl-text [size]="16" [color]="active === choice.value ? 'white' : 'blue'"> {{ choice.label }} </adl-text>
</adl-card>
