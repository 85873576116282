import { NgModule } from '@angular/core';
import type { Routes } from '@angular/router';
import { RouterModule } from '@angular/router';

import { AuthCallbackComponent } from './pages/auth-callback/auth-callback.component';
import { AuthLogoutComponent } from './pages/auth-logout/auth-logout.component';

// Routes
export const AUTH_ROUTES: Routes = [
  {
    path: 'callback',
    component: AuthCallbackComponent,
    data: { title: 'Login' },
  },
  {
    path: 'logout',
    component: AuthLogoutComponent,
    data: { title: 'Logout' },
  },
];

// Module
@NgModule({
  imports: [RouterModule.forChild(AUTH_ROUTES)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
