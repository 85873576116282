import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { IS_ACCOR, IS_JCD } from '@dataportal/front-environment';
import type { LimitedUser } from '@dataportal/users';
import { FormControl } from '@ngneat/reactive-forms';

import { Dashboard } from '../../../dashboards/entities/dashboard.model';

import { Source } from '../../../sources/entities/source';

// Types
export interface IRecommendationCreation {
  sourceId: string;
  dashboardName?: string;
  targetUser: string;
  customMessage: string;
}

// Components
@Component({
  selector: 'dpg-recommendation-modal',
  templateUrl: './recommendation-modal.component.html',
  styleUrls: ['./recommendation-modal.component.scss'],
})
export class RecommendationModalComponent {
  // Attributes
  @Input() source: Source;
  @Input() dashboard: Dashboard;

  limitedSelectedUser: LimitedUser;
  clearSelectList = new Subject<void>();
  customMessageControl = new FormControl<string>('');

  private _recommendationCreation: IRecommendationCreation;

  // Constructor
  constructor(
    private readonly _activeMatModal: MatDialogRef<RecommendationModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    data: {
      source: Source;
      dashboard: Dashboard;
    },
    @Inject(IS_ACCOR) readonly isAccor: boolean,
    @Inject(IS_JCD) readonly isJCD: boolean,
  ) {
    this.source = data?.source ? data.source : this.source;
    this.dashboard = data?.dashboard ? data.dashboard : this.dashboard;
  }

  // Methods
  cancel(): void {
    this._activeMatModal.close();
  }

  confirm(): void {
    this._recommendationCreation = {
      targetUser: this.limitedSelectedUser.id,
      sourceId: this.source.id,
      customMessage: this.customMessageControl.value,
    };

    if (this.dashboard) {
      this._recommendationCreation.dashboardName = this.dashboard.name;
    }

    this._activeMatModal.close(this._recommendationCreation);
  }
}
