import { Entity, Type } from '@decahedron/entity';

export class MinimalGroup extends Entity {
  constructor(pk: string, name: string, groupOwner: boolean) {
    super();
    this.pk = pk;
    this.name = name;
    this.groupOwner = groupOwner;
  }

  pk: string = null;
  name: string = null;
  groupOwner: boolean = null;
}

export class MinimalUser extends Entity {
  pk: string = null;
  name: string = null;
}

export interface IMinimalUser {
  pk: string;
  name: string;
}

export class MinimalPortal extends Entity {
  pk: string = null;
  name: string = null;
  @Type(MinimalUser) owners: MinimalUser[] = [];
}

export class Profile extends Entity {
  pk: string = null;
  name: string = null;
  @Type(MinimalGroup) groups: MinimalGroup[] = [];
  company?: string = null;
  jobTitle?: string = null;
  @Type(MinimalPortal) portal?: MinimalPortal = null;
}
