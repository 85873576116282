import type { Type } from '@angular/core';
import { Injectable } from '@angular/core';
import type { NavigationExtras, Params, Routes } from '@angular/router';
import { Router } from '@angular/router';
import { Logger } from '@dataportal/front-shared';

import { PAGE_METADATA } from '../navigator-token';

const tokenToMethod = {
  'catalog': () => 'catalog',
  'catalog-source': (params: { fileName: string }) => `sources/${params.fileName}`,
  'catalog-dashboard': (params: { fileName: string; dashboard: string }) =>
    `sources/${params.fileName}/dashboard/${params.dashboard}`,
  'error': () => 'error',
};

// TODO: Have to be reworked, does not work with Lazy loading
@Injectable()
export class Navigator {
  private readonly _routes = new Map<string, string>();

  constructor(private readonly _router: Router, private readonly _logger: Logger) {
    this._parseConfig('', this._router.config);
  }

  // Methods
  private _parseConfig(base: string, routes: Routes) {
    for (const route of routes) {
      const url = [base, route.path].filter((p) => p).join('/');

      if (route.component) {
        this._parseComponent(url, route.component);
      }

      if (route.children) {
        this._parseConfig(url, route.children);
      }
    }
  }

  private _parseComponent(url: string, component: Type<unknown>) {
    // Get token
    const token = Reflect.getMetadata(PAGE_METADATA, component) as string;

    if (token) {
      this._logger.debug(`[Navigator] /${url} => ${token} [${component.name}] (${this._parseUrl(url).join(', ')})`);
      const route = this._routes.get(token);

      if (route) {
        if (route !== '/' + url) {
          this._logger.warn(`[Navigator] conflict on token ${token} keeping previous url ...`);
        }
      } else {
        this._routes.set(token, '/' + url);
      }
    }
  }

  private _parseUrl(url: string): string[] {
    return (url.match(/:[a-z_]+/gi) || []).map((p) => p.slice(1));
  }

  buildUrl(token: string, params: Params = {}): string | null {
    const urlFunction = tokenToMethod[token];

    if (urlFunction) {
      return urlFunction(params);
    }

    // Get url
    let url = this._routes.get(token);

    if (!url) {
      this._logger.warn(`[Navigator] no route found for token ${token}`);

      return null;
    }

    // Build url
    for (const name of this._parseUrl(url)) {
      if (name in params) {
        const val = params[name]?.toString() || '';
        url = url.replace(`:${name}`, encodeURIComponent(val));
      } else {
        this._logger.warn(`[Navigator] missing parameter for ${token}: ${name}`);
      }
    }

    return url;
  }

  async navigate(token: string, params: Params = {}, extras?: NavigationExtras): Promise<boolean> {
    // Build url
    const url = this.buildUrl(token, params);

    if (url === null) {
      return false;
    }

    // Navigate !
    this._logger.debug(`[Navigator] navigate to ${token} => ${url}`);

    return this._router.navigate(url.split('/'), extras);
  }
}
