import { Entity } from '@decahedron/entity';

import { KpiInfo } from './kpiInfo';

export class Kpi extends Entity {
  sourceId: string = null;
  type: string = null;
  kpiInfo: KpiInfo = new KpiInfo();

  fromJson(data: any): any {
    this.sourceId = data.pk;
    this.type = data.type;
    this.kpiInfo = data.infos;

    return super.fromJson(data);
  }
}
