import type { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AlertService, Logger } from '@dataportal/front-shared';

import { ErrorHandlingService } from '../services/error-handling.service';

import type { IErrorHandlingOptions } from '../services/error-handling.service';

// Constants
export const ERROR_INTERCEPTOR_HEADER = 'X-Interceptor-Exception'.toLowerCase();

// Utils
function isLoginErrorResponse(res: HttpErrorResponse): boolean {
  return typeof res === 'string' && res === 'Login needed.';
}

// Interceptor
@Injectable()
export class ErrorToastsInterceptor implements HttpInterceptor {
  // Constructor
  constructor(
    private readonly _logger: Logger,
    private readonly _alertService: AlertService,
    private readonly _errorHandling: ErrorHandlingService,
  ) {}

  // Methods
  private _getToastOptions(handlingId: string, res: HttpErrorResponse): IErrorHandlingOptions {
    const options = handlingId && this._errorHandling.getErrorHandling(handlingId, res);

    if (options) {
      return options;
    }

    switch (res.status) {
      case 502:
      case 503:
        return {
          level: 'error',
          message: 'An error has occurred. Check your internet connection. If the error persists, please contact us.',
        };

      case 0:
      case 401:
        return {
          level: 'silent',
        };

      case 404:
        return {
          level: 'warning',
        };

      case 422: {
        let message: string = null;

        if (res.error && res.error.message === 'Validation error' && Array.isArray(res.error.data)) {
          message = res.error.data.map((data?: { message: string }) => (data?.message ? data.message : '')).join(', ');
        }

        return {
          level: 'warning',
          message,
        };
      }

      default:
        return {
          level: 'error',
        };
    }
  }

  private _displayToast(handlingId: string, res: HttpErrorResponse): void {
    if (isLoginErrorResponse(res)) {
      this._logger.debug('[Error Handling] Login needed. Skipping toast.');

      return;
    }

    // Extract error
    const extractedError = res.error?.error || res.error || {};

    if (!extractedError.message) {
      this._logger.debug('[Error Handling] No default error message for response', res);
      extractedError.message = 'An error has occurred';
    }

    // Toast options
    const requestId = extractedError['X-Request-Id'];
    const options = this._getToastOptions(handlingId, res);

    if (handlingId) {
      this._logger.debug('[Error Handling] Custom error handing', options);
    }

    if (options.level !== 'silent') {
      this._alertService.show(options.level, options.message || extractedError.message, requestId);
    }
  }

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const handlingId = req.headers.get(ERROR_INTERCEPTOR_HEADER);

    if (handlingId) {
      this._logger.debug('[Error Handling] Custom error handing enabled for', req.url);
      req = req.clone({
        headers: req.headers.delete(ERROR_INTERCEPTOR_HEADER),
      });
    }

    return next.handle(req).pipe(
      catchError((res: HttpErrorResponse) => {
        this._displayToast(handlingId, res);

        return throwError(res);
      }),
    );
  }
}
