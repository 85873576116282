import { Component, EventEmitter, Input, Output } from '@angular/core';

import type { GuardianCheck } from '../../entities/guardian.check';
import { IGuardianStatus } from '../../entities/guardian-form';

/**
 * Component that retrieve the list of all the run of a check
 */
@Component({
  selector: 'dpg-guardian-check-runs-list',
  templateUrl: './guardian-check-runs-list.component.html',
  styleUrls: ['./guardian-check-runs-list.component.scss'],
})
export class GuardianCheckRunsListComponent {
  // Inputs
  @Input() guardianStatus: IGuardianStatus;
  @Input() checks: GuardianCheck[];

  // Outputs
  @Output() selectedCheck = new EventEmitter<GuardianCheck>();

  showDetails(check: GuardianCheck): void {
    if (check.status === 'errored') {
      this.selectedCheck.emit(check);
    }
  }
}
