import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { of } from 'rxjs';

import { ApiUrlService } from './api-url.service';
import { ErrorHandlingService } from './error-handling.service';

import { ERROR_INTERCEPTOR_HEADER } from '../interceptors/error-toasts.interceptor';
import type { ErrorHandlingOptions } from './error-handling.service';

// Types
type HttpOptions = Extract<Parameters<HttpClient['get']>[1], { responseType?: 'json' }> & {
  /** @deprecated Use body argument instead */
  body?: any; // eslint-disable-line @typescript-eslint/no-explicit-any
};

export type ApiOptions = HttpOptions & {
  errorHandling?: ErrorHandlingOptions;
  queryStringParameters?: { [param: string]: string };
};

export interface IGraphAPIMetadata {
  limit?: number;
  query?: string;
  nextPage?: string;
}

export interface IGraphAPIRequesterState {
  apiOptions: ApiOptions;
  currentPage: number;
  currentSize: number;
  metadata: IGraphAPIMetadata;
}

// Utils
function buildOptions(options?: ApiOptions): HttpOptions | undefined {
  if (!options) {
    return undefined;
  }

  return {
    body: options.body,
    params: options.params,
    headers: options.headers,
    withCredentials: options.withCredentials,
    observe: options.observe,
    reportProgress: options.reportProgress,
    responseType: options.responseType,
  };
}

// Service
@Injectable({
  providedIn: 'root',
})
export class ApiService {
  // Constructor
  constructor(
    private readonly _http: HttpClient,
    private readonly _errorHandlingService: ErrorHandlingService,
    private readonly _urls: ApiUrlService,
  ) {}

  // Methods
  private _handlesErrors(options: ApiOptions): ApiOptions {
    const { errorHandling } = options;

    if (errorHandling) {
      const handlingId = this._errorHandlingService.registerErrorHandling(errorHandling);

      if (!options.headers) {
        options.headers = {};
      }

      if (options.headers instanceof HttpHeaders) {
        options.headers.set(ERROR_INTERCEPTOR_HEADER, handlingId);
      } else {
        options.headers[ERROR_INTERCEPTOR_HEADER] = handlingId;
      }
    }

    return options;
  }

  private _url(url: string, options: ApiOptions): string {
    const urlWithParams =
      options && options.queryStringParameters
        ? `${url}?${this._buildQueryParams(options.queryStringParameters)}`
        : url;

    return this._urls.buildUrl(urlWithParams);
  }

  private _buildQueryParams(params: { [param: string]: string }): string {
    return Object.keys(params)
      .map((key) => key + '=' + encodeURIComponent(params[key]))
      .join('&');
  }

  // - api calls
  private _request<T>(method: 'get' | 'delete' | undefined, url: string, options: ApiOptions = {}, fallback?: T) {
    options = this._handlesErrors(options || {});

    if (this._urls.isServiceAvailable(url)) {
      if (method === 'get') {
        return this._http.get<T>(this._url(url, options), buildOptions(options));
      } else if (method === 'delete') {
        return this._http.delete<T>(this._url(url, options), buildOptions(options));
      }
    }

    return of(fallback);
  }

  private _requestWithBody<T, P = unknown>(
    method: 'post' | 'put' | 'patch',
    url: string,
    body: P | null,
    options: ApiOptions = {},
    fallback?: T,
  ) {
    options = this._handlesErrors(options || {});

    if (this._urls.isServiceAvailable(url)) {
      return this._http[method]<T>(this._url(url, options), body, buildOptions(options));
    }

    return of(fallback);
  }

  get<T>(url: string, options: ApiOptions = {}, fallback?: T): Observable<T> {
    return this._request('get', url, options, fallback);
  }

  delete<T>(url: string, options: ApiOptions = {}, fallback?: T): Observable<T> {
    return this._request('delete', url, options, fallback);
  }

  post<T, P = unknown>(url: string, body: P | null, options: ApiOptions = {}, fallback?: T): Observable<T> {
    return this._requestWithBody('post', url, body, options, fallback);
  }

  put<T, P = unknown>(url: string, body: P | null, options: ApiOptions = {}, fallback?: T): Observable<T> {
    return this._requestWithBody('put', url, body, options, fallback);
  }

  patch<T, P = unknown>(url: string, body: P | null, options: ApiOptions = {}, fallback?: T): Observable<T> {
    return this._requestWithBody('patch', url, body, options, fallback);
  }
}
