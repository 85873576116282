import type { OnChanges, OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import { CommonSelectComponent } from '@dataportal/adl';

import { GroupsService } from '../../services/groups.service';

import type { Group } from '../../entities/group';

@Component({
  selector: 'adl-group-select',
  templateUrl: '../../../../../../modules/adl/src/lib/organisms/common-select/common-select.component.html',
})
export class GroupSelectComponent extends CommonSelectComponent<Group> implements OnInit, OnChanges {
  @Input() only: 'native' | 'ad';

  constructor(readonly groupsService: GroupsService) {
    super(groupsService);
    this.placeholder = 'Select a group';
  }

  ngOnInit(): void {
    super.ngOnInit();
    this._refreshOnly();
  }

  ngOnChanges(): void {
    this._refreshOnly();
    super.ngOnChanges();
  }

  private _refreshOnly(): void {
    const onlyAD = (group: Group): boolean => group.type === 'ad';
    const onlyNative = (group: Group): boolean => group.type === 'native';

    if (this.only === 'ad') {
      this.addPredicate('onlyAD', onlyAD);
      this.removePredicate('onlyNative');
    } else if (this.only === 'native') {
      this.addPredicate('onlyNative', onlyNative);
      this.removePredicate('onlyAD');
    } else {
      this.removePredicates(['onlyAD', 'onlyNative']);
    }
  }
}
