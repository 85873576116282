import type { OnChanges, OnDestroy, OnInit } from '@angular/core';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormControl } from '@ngneat/reactive-forms';

@Component({
  selector: 'adl-card-selector',
  templateUrl: './card-selector.component.html',
  styleUrls: ['./card-selector.component.scss'],
})
export class CardSelectorComponent<T = unknown> implements OnInit, OnChanges, OnDestroy {
  @Input() choices: Array<{ label: string; value: T }>;
  @Input() control: FormControl<T>;
  @Input() height: number;
  @Input() disabled: boolean;
  @Input() width: number;

  @Output() changed = new EventEmitter<T>();

  active: T;
  styles: { 'width.px'?: number; 'height.px'?: number } = {};

  private readonly _flagged$ = new Subject<void>();

  ngOnInit(): void {
    const initialValue = this.control.value;
    this.active = initialValue;
    this.control.value$.pipe(takeUntil(this._flagged$)).subscribe((currentValue) => {
      if (currentValue !== initialValue) {
        this.control.markAsDirty();
        this._flagged$.next();
      }
    });
    this._refreshStyles();
  }

  ngOnChanges(): void {
    this._refreshStyles();
  }

  private _refreshStyles(): void {
    this.styles = {};

    if (this.width) {
      this.styles['width.px'] = this.width;
    }

    if (this.height) {
      this.styles['height.px'] = this.height;
    }
  }

  selectCard(value: T): void {
    if (this.disabled) {
      return;
    }

    this.active = value;
    this.changed.emit(value);
    this.control.setValue(value);
    this.control.markAsDirty();
  }

  ngOnDestroy(): void {
    this._flagged$.next();
  }
}
