<div class="contact-box">
  <div class="contact-space">
    <div *ngIf="source.functionalOwners.length === 0" class="contact-title">No functional contact</div>
    <div *ngIf="source.functionalOwners.length > 0">
      <div class="contact-title first">Functional(s) contact(s)</div>
      <img *ngIf="loading" src="/assets/dpg/imgs/loading.gif" style="width: 40px" alt="loading" />
      <ul>
        <li *ngFor="let profile of functionalOwnersProfiles" [class.w-100]="functionalOwnersProfiles.length === 1">
          <div class="contact-card">
            <div class="contact-avatar">
              <img class="img-responsive" [src]="profile.picture" alt="{{ profile.name }}" />
            </div>
            <div class="contact-name">
              {{ profile.name }}
            </div>
            <div class="contact-job">
              {{ profile.title }}
            </div>
            <div class="contact-email">
              <a href="mailto:{{ profile.email }}">{{ profile.email }}</a>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>

  <div class="contact-space last">
    <div *ngIf="source.technicalOwners.length === 0" class="contact-title last">No technical contact</div>
    <div *ngIf="source.technicalOwners.length > 0">
      <div class="contact-title">Technical(s) contact(s)</div>
      <img *ngIf="loading" src="/assets/dpg/imgs/loading.gif" style="width: 40px" alt="loading" />
      <ul>
        <li *ngFor="let profile of technicalOwnersProfiles" [class.w-100]="technicalOwnersProfiles.length === 1">
          <div class="contact-card">
            <div class="contact-avatar">
              <img class="img-responsive" [src]="profile.picture" alt="{{ profile.name }}" />
            </div>
            <div class="contact-name">
              {{ profile.name }}
            </div>
            <div class="contact-job">
              {{ profile.title }}
            </div>
            <div class="contact-email">
              <a href="mailto:{{ profile.email }}">{{ profile.email }}</a>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>
