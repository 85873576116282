<div class="profile-card">
  <div *ngIf="header" class="flex header w-full items-center justify-between">
    <adl-text color="blue" [bold]="true" class="title ml30 a-center tal">{{ this.title }}</adl-text>
    <div *ngIf="editable" class="mr30">
      <div *ngIf="!isEdited">
        <adl-button (clicked)="edit()" type="edit-profile" class="tac a-center">
          <adl-text color="light-blue" size="14">Edit</adl-text>
        </adl-button>
      </div>
      <div *ngIf="isEdited" class="flex">
        <adl-button (clicked)="edit()" type="edit-profile" class="mr10 a-center">
          <adl-text color="light-blue" size="14">Cancel</adl-text>
        </adl-button>
        <adl-button (click)="save()" type="save-profile" class="tac a-center">
          <adl-text color="white" size="14">Save</adl-text>
        </adl-button>
      </div>
    </div>
    <adl-button
      *ngIf="topRightButtonLabel !== ''"
      (clicked)="topRightButtonClicked.emit()"
      class="action-button mr-8 my-4"
      type="secondary"
      size="small">
      {{topRightButtonLabel}}
    </adl-button>
  </div>
  <ng-content></ng-content>
</div>

