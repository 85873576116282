import { CommonModule } from '@angular/common';
import type { ModuleWithProviders } from '@angular/core';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthModule } from '@dataportal/auth';
import { LoggerModule } from 'ngx-logger';

// Module
// @dynamic
@NgModule({
  imports: [CommonModule, LoggerModule, RouterModule, AuthModule],
})
export class AnalyticsModule {
  // Statics
  static forRoot(): ModuleWithProviders<AnalyticsModule> {
    return {
      ngModule: AnalyticsModule,
    };
  }
}
