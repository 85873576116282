<div *ngIf="nbPages > 1 || isTotalPagesUnknown" class="paginator flex flex-row justify-center items-center w100">
  <adl-text
    class="previous-btn mr-10"
    [color]="isPreviousButtonDisabled ? 'grey-text-disable' : getTextColor(null)"
    (click)="informClickOnPrevious()"
    >Previous</adl-text
  >
  <ng-container *ngIf="nbPages <= realNbMaxPageButtonsToDisplay">
    <adl-text
      *ngFor="let page of pages"
      class="page-btn"
      [class.selected]="currentPageIndex === page"
      [size]="getFontSizeForPageIndex(page)"
      [color]="getTextColor(page)"
      (click)="informClickOnPageButton(page)"
    >
      {{ page }}
    </adl-text>
  </ng-container>
  <ng-container *ngIf="hasToDisplayEtc || isTotalPagesUnknown">
    <adl-text
      *ngIf="!isTotalPagesUnknown"
      class="page-btn"
      [class.selected]="currentPageIndex === first_page_index"
      [size]="getFontSizeForPageIndex(first_page_index)"
      [color]="getTextColor(first_page_index)"
      (click)="informClickOnPageButton(first_page_index)"
      >{{ first_page_index }}
    </adl-text>
    <adl-text
      *ngIf="hasToDisplayEtcLeft"
      class="page-btn etc"
      [color]="getTextColor(null)"
      (click)="informClickOnPrevious()"
      >...</adl-text
    >
    <adl-text
      *ngFor="let page of pagesToDisplayBetweenEtc"
      class="page-btn"
      [class.selected]="currentPageIndex === page"
      [size]="getFontSizeForPageIndex(page)"
      [color]="getTextColor(page)"
      (click)="informClickOnPageButton(page)"
      >{{ page }}</adl-text
    >
    <adl-text
      *ngIf="hasToDisplayEtcRight"
      class="page-btn etc"
      [color]="getTextColor(null)"
      (click)="informClickOnNext()"
      >...</adl-text
    >
    <adl-text
      *ngIf="!isTotalPagesUnknown"
      class="page-btn"
      [class.selected]="currentPageIndex === nbPages"
      [size]="getFontSizeForPageIndex(nbPages)"
      [color]="getTextColor(nbPages)"
      (click)="informClickOnPageButton(nbPages)"
      >{{ nbPages }}</adl-text
    >
  </ng-container>
  <adl-text
    class="next-btn ml-10"
    [color]="isNextButtonDisabled ? 'grey-text-disable' : getTextColor(null)"
    (click)="informClickOnNext()"
    >Next</adl-text
  >
</div>
