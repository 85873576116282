import type { OnDestroy, OnInit } from '@angular/core';
import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DialogsService } from '@dataportal/adl';
import { GoogleTagManagerService } from '@dataportal/analytics';
import { CurrentUserService } from '@dataportal/auth';
import { Logger } from '@dataportal/front-shared';
import { Navigator } from '@dataportal/navigator';

import { FavoritesSourcesService } from '../../../sources/services/favorites-sources.service';
import { DashboardExplorerService } from '../../services/dashboard-explorer.service';
import { DashboardsService } from '../../services/dashboards.service';
import { FavoritesDashboardsService } from '../../services/favorites-dashboards.service';

import { Source } from '../../../sources/entities/source';
import type { Dashboard } from '../../entities/dashboard.model';
import type { IDashboardFolder } from '../../entities/dashboard-folder.model';

export interface IDashboardsListComponentData {
  isWithToggleButton: boolean;
  source: Source;
  canBeOpened?: boolean;
  isSourceOwner?: boolean;
}

@Component({
  selector: 'dpg-dashboards-list',
  templateUrl: './dashboards-list.component.html',
  styleUrls: ['./dashboards-list.component.scss'],
})
export class DashboardsListComponent implements OnInit, OnDestroy {
  // Attributes
  @Input() isWithToggleButton; // boolean indicating if component will be displayed with toggle button or not
  @Input() source: Source;
  @Input() canBeOpened = true;
  @Input() isSourceOwner?;

  isInModalMode = false;

  isFavorite = false;
  isLoadingFavoriteDashboards = true;

  isFirstLoad = true;

  // data structures for dashboards
  favoriteDashboards: string[] = [];
  sortedDashboards: Dashboard[] = [];

  // data structures for dashboard folders
  dashboardsToDisplay: Dashboard[] = [];
  dashboardFoldersTree: IDashboardFolder[][] = [];

  // current level dashboard folders + dashboards
  currentLevelToDisplay: { dashboardFolders: IDashboardFolder[]; dashboards: Dashboard[] } = {
    dashboardFolders: [],
    dashboards: [],
  };

  private readonly _destroyed$ = new Subject<void>();

  // Constructor
  constructor(
    private readonly _router: Router,
    private readonly _dashboardService: DashboardsService,
    private readonly _navigator: Navigator,
    private readonly _logger: Logger,
    private readonly _favoritesDashboardsService: FavoritesDashboardsService,
    private readonly _favoritesSourcesService: FavoritesSourcesService,
    private readonly _dashboardExplorerService: DashboardExplorerService,
    private readonly _activeMatModal: MatDialogRef<unknown>,
    @Inject(MAT_DIALOG_DATA) data: IDashboardsListComponentData,
    private readonly _dialogsService: DialogsService,
    private readonly _currentUserService: CurrentUserService,
    private readonly _gtmService: GoogleTagManagerService,
  ) {
    if (data) {
      Object.keys(data).forEach((key) => {
        this[key] = data[key] ? data[key] : this[key];
      });
    }

    if (Object.keys(data || {})?.length) {
      this.isInModalMode = true;
    }
  }

  // Lifecycle
  ngOnInit(): void {
    this._favoritesSourcesService.refreshFavoritesSources();
    this._favoritesDashboardsService.refreshFavoritesDashboards();

    this._favoritesSourcesService.favoritesSources$.pipe(takeUntil(this._destroyed$)).subscribe(() => {
      this.isFavorite = this._favoritesSourcesService.isFavoriteSource(this.source?.id);
    });

    this._favoritesDashboardsService.favoritesDashboards$
      .pipe(takeUntil(this._destroyed$))
      .subscribe((dashboardFavorites) => {
        this.favoriteDashboards = dashboardFavorites.map((dashboardFavorite) => dashboardFavorite.dashboardName);
        this.sortedDashboards = this.source?.powerbi.sort(this._sortDashboard.bind(this));
        this.dashboardsToDisplay = this.isFavorite ? this.sortedDashboards : this.source?.powerbi;
        this.dashboardsToDisplay = this.dashboardsToDisplay.filter((dashboard) => !dashboard.isHidden);

        if (this.source?.dashboardFolders) {
          // building dashboardFolder/dashboard tree
          this._buildCleanedDashboardFoldersTree();
          // setting up root level display
          this._displayRoot();
        } else {
          this.currentLevelToDisplay = { dashboardFolders: [], dashboards: this.dashboardsToDisplay };
        }

        this.isLoadingFavoriteDashboards = false;
      });
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
  }

  // Properties
  get visibleDashboards(): Dashboard[] {
    return this.source?.powerbi.filter((dashboard) => !dashboard.isHidden);
  }

  // Methods
  isActiveDashboard(dashboard: Dashboard): boolean {
    return this.favoriteDashboards.includes(dashboard.name);
  }

  closeActiveModal(hasNoRouteBack?: boolean): void {
    this._activeMatModal.close(hasNoRouteBack);
  }

  async openDashboard(name: string, newTab = false): Promise<boolean> {
    if (!this.canBeOpened) {
      return false;
    }

    const dashboard = this.source?.powerbi.find((dash) => dash.name === name);
    const urlToOpen = dashboard.buildUrl(this.source?.id, name, this._navigator, this._router, newTab);

    this._logger.debug('[dpg-dashboards-list] Open dashboard', { type: dashboard.type, newTab, urlToOpen });
    this._currentUserService.currentUser$.subscribe((user) => {
      this._gtmService.pushEvent({
        event: 'dashboard_view',
        source_id: this.source?.id,
        dashboard_type: dashboard.type,
        dashboard_url: urlToOpen,
        user: user,
      });
    });

    if (dashboard.isExternal) {
      if (dashboard.maintenanceMessage && !this.isSourceOwner) {
        this._dialogsService.info({ message: '', innerHTML: dashboard.maintenanceMessage });
      } else {
        this._dashboardService.emitExternalLinkPageViewEvent(this.source);
        window.open(urlToOpen.toString(), '_blank');
      }

      return true;
    } else if (newTab) {
      window.open(urlToOpen.toString(), '_blank');

      return true;
    } else {
      if (this.isInModalMode) {
        this.closeActiveModal(true);
      }

      return this._router.navigate([urlToOpen]);
    }
  }

  private _sortDashboard(d1: Dashboard, d2: Dashboard): number {
    if (this.favoriteDashboards.includes(d1.name)) {
      return this.favoriteDashboards.includes(d2.name) ? 0 : -1;
    } else {
      return this.favoriteDashboards.includes(d2.name) ? 1 : 0;
    }
  }

  toggleFavoriteDashboard(dashboardName: string): void {
    if (!this.isLoadingFavoriteDashboards) {
      this.isLoadingFavoriteDashboards = true;

      if (!this.favoriteDashboards.includes(dashboardName)) {
        this._favoritesDashboardsService.addFavoriteDashboard(this.source.id, dashboardName);

        if (!this.isFavorite) {
          this._favoritesSourcesService.addFavoriteSource(this.source.id);
        }
      } else {
        this._favoritesDashboardsService.removeFavoriteDashboard(this.source.id, dashboardName);
      }
    }
  }

  private _buildCleanedDashboardFoldersTree(): void {
    // cleaning old variable
    this._dashboardExplorerService.resetLastDashboardFoldersRemoved();
    // building dashboard folders tree
    this.dashboardFoldersTree = this._dashboardExplorerService.buildDashboardFoldersTree(this.source.dashboardFolders);
    // tree shaking empty folders
    this.dashboardFoldersTree = this._dashboardExplorerService.treeShakeEmptyFolders(
      this.dashboardFoldersTree,
      this.dashboardsToDisplay,
    );
    // tree shaking first useless folders
    this.dashboardFoldersTree = this._dashboardExplorerService.treeShakeFirstUselessLevels(
      this.dashboardFoldersTree,
      this.dashboardsToDisplay,
    );
  }

  private _displayRoot(): void {
    if (this.isFirstLoad) {
      this.currentLevelToDisplay = this._dashboardExplorerService.exploreRoot(
        this.dashboardFoldersTree,
        this.dashboardsToDisplay,
      );
      this.isFirstLoad = false;
    }
  }

  get shouldDisplayPath(): boolean {
    return !!this.dashboardFoldersTree[0] && !!this.dashboardFoldersTree[0].length;
  }

  get currentPathToDisplay(): string {
    return this._dashboardExplorerService.currentPathToDisplay;
  }

  get currentWholeFormattedPathToDisplay(): { sourceName: string; foldersName: string } {
    return { sourceName: this.source?.name, foldersName: this.currentPathToDisplay.split('/').join(' > ') };
  }

  get isRoot(): boolean {
    return this._dashboardExplorerService.isRoot(this.currentPathToDisplay);
  }

  exploreFolderAndDisplay(dashboardFolder: IDashboardFolder, isBacking = false): void {
    this.currentLevelToDisplay = this._dashboardExplorerService.exploreFolder(
      dashboardFolder,
      isBacking,
      this.dashboardFoldersTree,
      this.dashboardsToDisplay,
    );
  }

  goBackFolderAndDisplay(): void {
    const currentLevelToDisplayFetched = this._dashboardExplorerService.goBackFolder(
      this.dashboardFoldersTree,
      this.dashboardsToDisplay,
    );

    if (!currentLevelToDisplayFetched) {
      return;
    }

    this.currentLevelToDisplay = currentLevelToDisplayFetched;
  }
}
