import type { OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OwnersType, Source, SourcesService } from '@dataportal/sources-dashboards-recommendations';
import type { IProfile } from '@dataportal/users';

@Component({
  selector: 'dpg-source-contact-box',
  templateUrl: './source-contact-box.component.html',
  styleUrls: ['./source-contact-box.component.scss'],
})
export class SourceContactBoxComponent implements OnInit, OnChanges {
  // Attributes
  @Input() source: Source;

  @Output() loaded = new EventEmitter<boolean>();

  loading = true;

  functionalOwnersProfiles: IProfile[] = [];
  technicalOwnersProfiles: IProfile[] = [];

  // Constructor
  constructor(private readonly _sourcesService: SourcesService) {}

  // Lifecycle
  ngOnInit(): void {
    this._refreshOwners();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.source) {
      const previous: Source = changes.source.previousValue;
      const current: Source = changes.source.currentValue;

      const hasOwnersChanged = (key: 'functionalOwners' | 'technicalOwners'): boolean => {
        if (!previous) {
          return true;
        }

        if (previous[key].length !== current[key.length]) {
          return true;
        }

        if (previous[key].some((owner) => !current[key].includes(owner))) {
          return true;
        }

        return current[key].some((owner) => !previous[key].includes(owner));
      };

      if (hasOwnersChanged('functionalOwners') || hasOwnersChanged('technicalOwners')) {
        this._refreshOwners();
      }
    }
  }

  // Methods
  private _refreshOwners(): void {
    this.loading = true;

    this._sourcesService.getProfiles(this.source).subscribe((profiles) => {
      this.functionalOwnersProfiles = profiles
        .filter((p) => p.profile && p.type === OwnersType.Functional)
        .map((p) => p.profile);

      this.technicalOwnersProfiles = profiles
        .filter((p) => p.profile && p.type === OwnersType.Technical)
        .map((p) => p.profile);

      this.loaded.emit(true);
      this.loading = false;
    });
  }
}
