<adl-loaded-item [loading]="loading">
  <adl-autocomplete
    [label]="label"
    [value]="value"
    [placeholder]="placeholder"
    [typeToSearchText]="searchText"
    [loading]="loading"
    [items]="list"
    [searchSubject]="searchSubject"
    [clear]="clear"
    (cleared)="cleared()"
    (changed)="changed($event)"
    (search)="search($event)"
  >
  </adl-autocomplete>
</adl-loaded-item>
