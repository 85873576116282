<ngx-charts-gauge
  *ngIf="kpi && kpi.type === 'gauge'"
  class="gaugeKpi"
  [view]="view"
  [scheme]="color"
  [results]="kpi.kpiInfo.gaugeInfo"
  [min]="kpi.kpiInfo.gaugeValue.min"
  [max]="kpi.kpiInfo.gaugeValue.max"
  [angleSpan]="240"
  [startAngle]="-120"
  [units]="kpi.kpiInfo.units"
  [showAxis]="false"
  [textValue]="kpi.kpiInfo.textValue"
>
</ngx-charts-gauge>

<div *ngIf="kpi && kpi.type === 'line'">
  <ngx-charts-line-chart
    [view]="viewline"
    [scheme]="color"
    [results]="kpi.kpiInfo.lineEntries"
    [gradient]="false"
    [autoScale]="autoScale"
  >
  </ngx-charts-line-chart>
  <p style="font-size: 1.5em" class="kpiText">
    {{ kpi.kpiInfo.textValue }}
    <span style="font-size: 1em">{{ kpi.kpiInfo.units }}</span>
  </p>
</div>

<div *ngIf="kpi && kpi.type === 'standard'" class="card-value">
  {{ kpi.kpiInfo.text }}
</div>
<div *ngIf="kpi && kpi.type === 'standard'" class="card-nb">
  {{ kpi.kpiInfo.value }}
</div>
